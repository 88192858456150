/* eslint-disable no-loop-func */
import { Button, Col, Icon, Layout, List, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
const { Content } = Layout;
const increaseQuantity = (props, item) => {
  item.qty = item.qty + 1;
  props.updateCart(item);
};
const decreaseQuantity = (props, item) => {
  item.qty = item.qty - 1;
  props.updateCart(item);
  if (item.qty === 0) {
    props.navToView('companyMenu');
  }
};
let deliveryCost = 0;
let setDeliveryCost;
const Cart = props => {
  [deliveryCost, setDeliveryCost] = useState(deliveryCost);
  useEffect(() => {
    if (props.address === 'Collect') {
      setDeliveryCost(0);
    } else {
      setDeliveryCost(
        props.selectedCompany.distance / 1000 >= 0 &&
          props.selectedCompany.distance / 1000 <= 5
          ? props.selectedCompany.delivery['5']
          : props.selectedCompany.distance / 1000 > 5 &&
            props.selectedCompany.distance / 1000 <= 10
          ? props.selectedCompany.delivery['10']
          : props.selectedCompany.delivery['15']
      );
      if (deliveryCost === 'false') {
        setDeliveryCost(0);
      }
    }

    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white', paddingBottom: 170 }}>
      <Row type="flex" justify="space-between">
        {' '}
        <Col span={24} style={{ marginBottom: '0.5em' }}>
          <h1
            style={{
              fontWeight: 500,
              textAlign: 'center',
              margin: 'auto',
              paddingTop: '0.5em',
              lineHeight: '1.3em',
              fontFamily: 'Roboto',
              fontSize: '1.7em'
            }}
          >
            {props.selectedCompany.name}
          </h1>
        </Col>
        <Col span={12}>
          <h2
            style={{
              fontSize: 18,
              paddingLeft: '1em',
              margin: 'auto'
            }}
          >
            Your order
          </h2>
        </Col>
        <Col span={12}>
          <a
            style={{
              float: 'right',
              paddingRight: '1em',
              paddingTop: '0.5em',
              fontFamily: 'Roboto',
              fontSize: '1em'
            }}
            onClick={e => props.navToView('companyMenu')}
          >
            <Icon type="plus-circle" /> Add Items
          </a>
        </Col>
      </Row>
      <Row>
        <List
          dataSource={props.cart.items}
          renderItem={item => (
            <List.Item
              style={{
                padding: '1em',
                borderRadius: '0.3em',
                backgroundColor: 'white',
                margin: '1em',
                border: '1px solid lightgrey',
                boxShadow: '1px 3px 3px 0px rgba(0,0,0,0.10)'
              }}
              key={item._id}
            >
              {' '}
              <Row>
                <List.Item.Meta
                  id="MenuItemName"
                  title={item.qty + 'x ' + item.name}
                  description={
                    <div>
                      <p style={{ color: 'black' }}>{item.description}</p>
                      {item.cookIns.length > 0 ? (
                        <p style={{ color: 'black' }}>
                          <span style={{ fontWeight: 700 }}> Extras: </span>{' '}
                          {item.cookIns.map((cook, index) => {
                            if (index + 1 === item.cookIns.length) {
                              return cook.name;
                            } else {
                              return cook.name + ', ';
                            }
                          })}
                        </p>
                      ) : null}
                    </div>
                  }
                />{' '}
              </Row>
              <div style={{ display: 'grid' }}>
                <Row
                  type="flex"
                  justify="space-around"
                  style={{
                    borderTop: '1px solid rgba(211, 211, 211, 0.781)',
                    paddingTop: '0.8em'
                  }}
                >
                  <Col
                    span={15}
                    style={{
                      textAlign: 'left'
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 700,
                        margin: 'auto',
                        paddingTop: '0.5em',
                        paddingRight: '1em',
                        fontSize: '1.4em',
                        color: 'black'
                      }}
                    >
                      R {(item.total * item.qty).toFixed(2)}
                    </span>
                  </Col>
                  <Col span={3} style={{ display: 'flex' }}>
                    <Icon
                      type="minus-circle"
                      onClick={e => decreaseQuantity(props, item)}
                      style={{
                        margin: 'auto',
                        fontSize: '2em',
                        marginRight: '0em',
                        color: '#00CC70'
                      }}
                    />
                  </Col>
                  <Col span={3} style={{ textAlign: 'center' }}>
                    <span
                      style={{
                        margin: 'auto',
                        fontSize: '1.5em',
                        color: 'black'
                      }}
                    >
                      {item.qty}
                    </span>
                  </Col>
                  <Col span={3} style={{ display: 'flex' }}>
                    <Icon
                      type="plus-circle"
                      onClick={e => increaseQuantity(props, item)}
                      style={{
                        margin: 'auto',
                        marginLeft: '0em',
                        fontSize: '2em',
                        color: '#00CC70'
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </List.Item>
          )}
        />
      </Row>
      <div
        style={{
          position: 'fixed',
          zIndex: 2,
          padding: '1em',
          boxShadow: '1px 3px 3px 0px rgba(0,0,0,0.10)',
          width: '100%',
          bottom: '5.9em',
          backgroundColor: 'white'
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={12}>
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              Subtotal
            </span>
          </Col>
          <Col span={12}>
            <span
              style={{
                float: 'right',

                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              R {props.cart.total.toFixed(2)}
            </span>
          </Col>
        </Row>
        {props.address !== 'Collect' ? (
          <Row type="flex" justify="space-between">
            <Col span={12}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                Delivery fee
              </span>
            </Col>
            <Col span={12}>
              {props.address === 'Collect' ? (
                <span
                  style={{
                    float: 'right',

                    fontFamily: 'Roboto',
                    fontSize: '1.2em',
                    color: 'black'
                  }}
                >
                  Collect
                </span>
              ) : (
                <span
                  style={{
                    float: 'right',

                    fontFamily: 'Roboto',
                    fontSize: '1.2em',
                    color: 'black'
                  }}
                >
                  {deliveryCost === 0 || deliveryCost === 'false'
                    ? 'Free'
                    : 'R ' + deliveryCost}
                </span>
              )}
            </Col>
          </Row>
        ) : null}
        <Row type="flex" justify="space-between">
          <Col span={12}>
            <span
              style={{
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: '1.4em',
                color: 'black'
              }}
            >
              Total
            </span>
          </Col>
          <Col span={12}>
            <span
              style={{
                fontWeight: 700,
                float: 'right',

                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              R{' '}
              {deliveryCost !== 'false'
                ? (props.cart.total + deliveryCost).toFixed(2)
                : props.cart.total.toFixed(2)}
            </span>
          </Col>
        </Row>{' '}
      </div>
      <Row
        style={{
          position: 'absolute',
          width: '100%'
        }}
        type="flex"
        justify="space-around"
      >
        <Button
          style={{
            zIndex: 999,
            position: 'Fixed',
            backgroundColor: '#00CC70',
            fontWeight: 700,
            height: '4.6em',
            fontFamily: 'Roboto',
            fontSize: '18px',
            color: 'white',
            width: '100%',
            bottom: 0,
            borderRadius: 0
          }}
          onClick={e => props.checkOut(deliveryCost)}
        >
          Checkout
        </Button>
      </Row>
    </Content>
  );
};

export default Cart;
