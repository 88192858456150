import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon, Input, Row, Spin, Table, Popover, Empty, ConfigProvider, Tabs, Radio } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import convert from 'convert-units';
import StyledTable from 'src/components/Table/StyledTable';
import {
  addQuoteCustomer,
  deleteQuoteCustomer,
  editQuoteCustomer,
  getQuoteCustomers
} from 'src/redux/actions/backend';
import styled, { css } from 'styled-components';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import {
  FiEdit
} from 'react-icons/fi';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import QuoteCustomersForm from 'src/views/Admin/Accounting/CustomersForm';
const { Column } = Table;
const { TabPane } = Tabs;
const StyledTabs = styled(Tabs)`
.ant-tabs-bar {
  border-bottom: 0px solid #e8e8e8;
  margin: 0 0px 20px 0 !important;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-tab{
  width:auto !important;
  font-size: 16px !important;
  margin-left:0.5em !important;
margin-top:0.5em !important;
  border-radius: 0.3em !important;
  padding: 0px 20px !important;
 height: 45px;
 font-weight:400 !important;
 color:rgb(19, 36, 64) !important;
 background: transparent !important;
 border: 0px solid #00CC70 !important;
}
 .ant-tabs-tab-active {
 width:auto !important;
  border-radius: 0.3em !important;
   padding: 0px 20px !important;
  height: 45px; 
  font-weight:600 !important;
  color: white !important;
  background: #00CC70 !important;
  border: 0px solid #00CC70 !important;
}
`;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    border: '1px solid grey',
    color: 'grey',

    borderRadius: '0.2em'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  }
});

const dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: []
};
const quoteCustomerObject = {
  name: '',
  contact: '',
  email: '',
  number: '',
  website: '',
  address1: '',
  address2: '',
  city: '',
  postal: '',
  province: '',
  country: '',
  note: '',
  submitInProgress: false
};
class QuoteCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ...quoteCustomerObject,
      main: true,
      mainSub: false,
      quoteCustomers: [],
      types: [],
      dimtypes: [],
      loading: true,
      userlisthead: 'Add Customer',
      userlistsub: 'Add a New Customer',
      userlistbutton: 'ADD CUSTOMER',
      alert: false,
      msg: [],
      head: '',
      activeFilter: true,
      bankDetails: false
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb)
    this.props.setBreadCrumbs(breadCrumbs)
  }
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop()
    this.props.setBreadCrumbs(breadCrumbs)
  }

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false)
      this.props.resetActionBreadCrumb()
      this.handleRemoveBreadCrumb()
    }
  }

  componentDidMount() {
    this.getQuoteCustomers();
  }

  handleOpen = open => {
    if (open) {

      this.setState({
        main: true
      });
    } else {
      this.handleAddBreadCrumb({ method: "handleOpenTable", crumb: "Customer" })
      this.setState({
        main: false,
        _id: '',
        ...quoteCustomerObject,
        userlisthead: 'Add Customer',
        userlistsub: 'Add a New Customer',
        userlistbutton: 'ADD CUSTOMER',
        pageSize: 25
      });
    }
  };

  handleOpenTable = open => {
    if (open) {
      this.setState({
        main: false
      });
    } else {
      this.handleRemoveBreadCrumb()
      this.setState({
        main: true
      });
    }
  };

  handleChange = event => {
    if (event.target.name === 'Type') {
      this.setState({ type: event.target.value });
    }
    if (event.target.name === 'dimension') {
      this.setState({
        dimension: event.target.value,
        dimtypes: dimSelectTypes[event.target.value]
      });
    }
    if (event.target.name === 'dimentiontype') {
      this.setState({ dimType: event.target.value });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Customers Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onComplete = res => {
    this.setState({
      _id: '',
      ...quoteCustomerObject,
      main: true
    });
    this.getQuoteCustomers('Customer Creation Successful');
  };

  submitForm = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true, submitInProgress: true });
    if (oEvent.currentTarget.innerText === 'ADD CUSTOMER') {
      this.props
        .addQuoteCustomer(this.state, token)
        .then(res => {
          this.handleRemoveBreadCrumb()
          this.onComplete(res);
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    } else if (oEvent.currentTarget.innerText === 'EDIT CUSTOMER') {
      this.props
        .editQuoteCustomer(this.state, token)
        .then(res => {
          this.handleRemoveBreadCrumb()
          this.setState({
            main: true,
            _id: '',
            ...quoteCustomerObject,
            userlisthead: 'Add Customer',
            userlistsub: 'Add a New Customer',
            userlistbutton: 'ADD CUSTOMER'
          });
          this.getQuoteCustomers('Customer updated successful');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  getQuoteCustomers = msg => {
    const token = this.props.local.user.token;
    this.props
      .getQuoteCustomers(token)
      .then(result => {
        this.setState({
          quoteCustomers: result.data.results,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onChangeEvt = e => {
    let target = this.state;
    target[e.target.id] = e.currentTarget.value;
    this.setState(target);
  };

  handleEditQuoteCustomer = oQuoteCustomer => {
    this.handleAddBreadCrumb({ method: "handleOpenTable", crumb: "Customer" })
    let tempMail = ""
    if (Array.isArray(oQuoteCustomer.email)) {
      for (var i = 0; i < oQuoteCustomer.email.length; i++) {
        tempMail = tempMail + oQuoteCustomer.email[i] + ";"
      }
      oQuoteCustomer.email = tempMail
    }
    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });
    this.setState({
      userlisthead: 'Edit Customer',
      userlistsub: 'Edit a Customer',
      userlistbutton: 'EDIT CUSTOMER',
      loading: false,
      main: false,
      pageSize: 25,
      ...oQuoteCustomer
    });
  };

  handleDeleteQuoteCustomer = oMenu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteQuoteCustomer(oMenu, token)
      .then(res => {
        this.getQuoteCustomers('Customer Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleRestoreQuoteCustomer = oMenu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteQuoteCustomer(oMenu, token)
      .then(res => {
        this.getQuoteCustomers('Customer Restored Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };
  handleFormatListQty = () => {
    this.setState({ activeFilterQty: true, activeFilter: ['true'] });
  };
  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps2 = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (value) {
        if (record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });
  switchBankDetails = () => {
    this.setState({ bankDetails: !this.state.bankDetails });
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState, currentPage: current, pageSize: pageSize
    }));
  };
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: "3em",
          marginBottom: "0.5em",
          height: 90,
        }} />

      <button
        style={{

          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',

          width: '12em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',

          borderRadius: '3em',
          marginTop: "1em",
          marginBottom: "1.5em",
        }}
        onClick={() => this.handleOpen(!this.state.main)}
        color="primary"
      >
        {'Add Customer'}
      </button>
    </div>
  );
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditQuoteCustomer: this.handleEditQuoteCustomer,
      handleDeleteQuoteCustomer: this.handleDeleteQuoteCustomer,
      handleRestoreQuoteCustomer: this.handleRestoreQuoteCustomer,
      onChangeEvt: this.onChangeEvt,
      handleChange: this.handleChange,
      submitForm: this.submitForm,
      handleOpenStock: this.handleOpenTable,
      onHelpMarker: this.onHelpMarker,
      switchBankDetails: this.switchBankDetails
    };
    const EyeContent = (
      <div style={{ width: "8em" }}>
        <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
          <Radio value={1}
            style={{
              paddingBottom: "0.5em",
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => this.handleFormatList(true)}
          >
            Active
        </Radio>
          <Radio value={2}
            style={{
              paddingBottom: "0.5em",
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => this.handleFormatList(false)}
          >
            Inactive
        </Radio>
          <Radio value={3}
            style={{
              paddingBottom: "0.5em",
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => this.handleFormatList(undefined)}
          >
            All
        </Radio>
        </Radio.Group>
      </div>
    );
    let quoteCustomers = [];
    for (var i = 0; i < this.state.quoteCustomers.length; i++) {
      if (this.state.activeFilter === true) {
        if (this.state.quoteCustomers[i].active) {
          quoteCustomers.push(this.state.quoteCustomers[i]);
        }
      } else if (this.state.activeFilter === false) {
        if (!this.state.quoteCustomers[i].active) {
          quoteCustomers.push(this.state.quoteCustomers[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        quoteCustomers.push(this.state.quoteCustomers[i]);
      }
    }
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.main ? (
          <div>
            {' '}
            <Button
              style={{
                position: 'fixed',
                right: '0.5em',
                bottom: '0.5em',
                background: '#132440',
                border: '1px solid transparent ',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em',
                fontSize: '1em',
                zIndex: 99
              }}
              onClick={() => this.handleOpen(!this.state.main)}
              color="primary"
            >
              <h1
                style={{
                  fontSize: '3em',
                  marginTop: '0.5em',
                  color: 'white'
                }}
              >
                +
              </h1>
            </Button>
            {this.state.quoteCustomers.length > 0 ? <Row>
              <button
                style={{
                  marginTop: '-0.7em',
                  backgroundColor: '#00CC70',
                  border: '0px solid rgb(30, 161, 169)',
                  color: 'white',

                  width: '12em',
                  height: '2.7em',
                  fontSize: 16,
                  fontFamily: '',

                  borderRadius: '3em',
                  zIndex: 99,
                  position: 'absolute'
                }}
                onClick={() => this.handleOpen(!this.state.main)}
                color="primary"
              >
                {'Add Customer'}
              </button>
            </Row> : null}
            <Row>
              <div
                style={{
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                <Popover overlayStyle={{ borderRadius: "3em" }}
                  placement="left"
                  content={EyeContent}
                  title="Table settings"
                  trigger="click"
                >
                  <Button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px solid grey',
                      color: 'white',
                      bottom: "-6em",
                      zIndex: 9,
                      float: 'right',
                      borderRadius: '5em',
                      boxShadow: "none",
                      width: '2em'
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      theme="filled"
                      type="setting"
                    />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Row>
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={quoteCustomers}
                  pagination={{
                    locale: { items_per_page: " Items" },
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (rowIndex % 2 === 0) {
                      return {
                        onClick: (e) => {
                          e.stopPropagation()
                          oState.handleEditQuoteCustomer(record)
                        }, style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: (e) => {
                          e.stopPropagation()
                          oState.handleEditQuoteCustomer(record)
                        },
                      }
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Customer Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getColumnSearchProps('contact')}
                    title="Contact"
                    dataIndex="contact"
                    key="contact"
                  />
                  <Column
                    {...this.getColumnSearchProps('email')}
                    title="Email"
                    dataIndex="email"
                    key="email"
                  />
                  <Column
                    {...this.getColumnSearchProps('number')}
                    title="Phone Number"
                    dataIndex="number"
                    key="number"
                  />
                  <Column
                    {...this.getColumnSearchProps('note')}
                    title="Note"
                    dataIndex="note"
                    key="note"
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div>
                        <IconButton style={{
                          padding: "8px",
                          border: '1px solid #21B41E'
                        }}
                          onClick={(e) => {
                            e.stopPropagation()
                            oState.handleEditQuoteCustomer(record)
                          }}
                        >
                          {
                            <FiEdit
                              style={{

                                fontSize: 16,

                                color: "#21B41E"
                              }}
                            />
                          }
                        </IconButton>
                        {record.active ?
                          <IconButton
                            style={{
                              border: '1px solid red',
                              padding: "16px",
                              marginLeft: '1em'
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              oState.handleDeleteQuoteCustomer(record)
                            }}
                          >
                            {
                              <Icon
                                type="delete"
                                style={{
                                  position: 'absolute',
                                  fontSize: '16px',
                                  color: 'red'
                                }}
                              />
                            }
                          </IconButton>
                          :
                          <IconButton
                            style={{
                              border: '1px solid green',
                              marginLeft: '1em', padding: '16px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              oState.handleDeleteQuoteCustomer(record)
                            }}
                          >
                            {
                              <Icon
                                type="rollback"
                                style={{

                                  position: 'absolute',
                                  fontSize: '15px',
                                  color: 'green',

                                }}
                              />
                            }
                          </IconButton>
                        }
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : (
            <QuoteCustomersForm {...oState} />
          )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  quoteCustomers: state.quoteCustomers,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addQuoteCustomer,
  getQuoteCustomers,
  editQuoteCustomer,
  deleteQuoteCustomer,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(QuoteCustomers));
