export function openSnackbar(message) {
    return {
        type: "TOAST_DASH_MESSAGE",
        payload: message
    };
}
export function closeSnackbar() {
    return {
        type: "TOAST_DASH_CLEAR"
    };
}