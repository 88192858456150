export const initialState = {
  notifications: [],
  orderCount: 0
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_FIND': {
      if (action.data) {
        const notifications = action.data.notifications;
        const orderCount = action.data.orderCount;
        return {
          ...state,
          notifications: notifications,
          orderCount: orderCount
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
