import React from "react";
import {
  Spin,
  Table,
  Input,
  Icon,
  Row,
  Col,
  Select,
  DatePicker,
  List,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  getStatementsCustomer,
  getBillItemsCashup,
  updateBill,
  createStatement,
  emailReceipt,
} from "src/redux/actions/backend";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "src/components/Grid/GridContainer.jsx";
import GridItem from "src/components/Grid/GridItem.jsx";
import Button from "src/components/CustomButtons/Button.jsx";
import CustomInput from "src/components/CustomInput/CustomInput.jsx";
import Card from "src/components/Card/Card.jsx";
import CardHeader from "src/components/Card/CardHeader.jsx";
import CardBody from "src/components/Card/CardBody.jsx";
import AlertDialog from "src/components/Dialogs/Alert.jsx";
import Snackbar from "src/components/Snackbar/Snackbar.jsx";
import { openSnackbar, closeSnackbar } from "src/redux/actions/snackbar";
import Highlighter from "react-highlight-words";
import StyledTable from "src/components/Table/StyledTable";
import { setHelpMarker } from "src/redux/actions/help";
import IconButton from "@material-ui/core/IconButton";
import {
  primaryColor,
  grayColor,
  defaultFont,
} from "src/assets/jss/material-dashboard-react.jsx";
import { AiOutlineMail } from "react-icons/ai";
const { Column } = Table;
const dateFormat = "YYYY/MM/DD";
const dateFormatSearch = "YYYYMMDD";
const { RangePicker } = DatePicker;

const { Option } = Select;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardExpand: {
    marginTop: "10px",
    marginBottom: "5px",
  },
  cardExpandBody: {
    margin: "0px",
  },
  cardExpandTop: {
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    height: "4.5em",
    marginTop: "0%",
    marginBottom: "0px",
  },
  cardEmployeeWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    fontSize: "25px",

    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTileButton: {
    display: "grid",
  },
  cardTileIcon: {
    color: "white",
    padding: 0,
  },
  show: {
    display: "inline",
  },
  hide: {
    display: "none",
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
  },
  cardBody: {
    "-webkit-transition": "1s",
    "-moz-transition": "1s",
    "-o-transition": "1s",
    transition: "1s",
    opacity: 1,
    height: "auto",
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    height: "150px",
    overflow: "auto",
  },
  tableScroll: {
    width: "100%",
    height: "45vh",
    overflow: "auto",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em",
    "& th": {
      "text-align": "center",
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    verticalAlign: "middle",
    "& td": {
      "word-break": "break-all",
      "text-align": "center",
    },
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.data * 3,
    overflowX: "auto",
  },
  snackbar: {
    color: "#fff",
    background: "#1ea1a9",
  },
  headingText: {
    fontSize: "20px",
    paddingTop: "10px",
  },
  buttonAdd: {
    height: "34px",
    marginTop: "43px",
    marginLeft: "-90%",
    width: "80%",
  },
  datePicker: {
    marginRight: "10px",
    "& span": {
      height: "45px",
    },
  },
});

class Statements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updateView: false,
      employee: {},
      items: [],
      full: true,
      datePicker: false,
      startDate: moment().format(dateFormatSearch),
      endDate: moment().format(dateFormatSearch),
      pickerStart: moment().startOf("month"),
      pickerEnd: moment(),
      alert: false,
      msg: [],
      head: "",
    };
  }

  componentDidMount() {
    this.getReceipts(false, false, false, true);
  }
  back = () => {
    this.setState({ showBill: false });
  };
  getReceipts = (msg, startDate, endDate, init) => {
    this.setState({
      loading: true,
    });
    const token = this.props.local.user.token;
    var start = startDate;
    var end = endDate;
    if (!start) {
      start = this.state.startDate;
    }
    if (!endDate) {
      end = this.state.endDate;
    }
    this.props
      .getStatementsCustomer(token, start, end, this.props.id, init)
      .then(result => {
        this.setState({
          items: result.data.results || [],
          loading: false,
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        startDate: value[0].format(dateFormatSearch),
        endDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1],
      });
    }
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === "yester") {
        this.setState(
          {
            startDate: moment().subtract(1, "days").format(dateFormatSearch),
            endDate: moment().subtract(1, "days").format(dateFormatSearch),
            datePicker: false,
            full: false,
          },
          this.getReceipts(
            false,
            moment().subtract(1, "days").format(dateFormatSearch),
            moment().subtract(1, "days").format(dateFormatSearch)
          )
        );
      } else if (value === "all") {
        this.setState({
          startDate: moment().format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          full: true,
          datePicker: false,
        });
        this.getReceipts(false, false, false, true);
      } else if (value === "today") {
        this.setState({
          startDate: moment().format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          full: false,
          datePicker: false,
        });
        this.getReceipts(
          false,
          moment().format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === "last7") {
        this.setState({
          startDate: moment().subtract(7, "d").format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          full: false,
          datePicker: false,
        });
        this.getReceipts(
          false,
          moment().subtract(7, "d").format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === "last30") {
        this.setState({
          startDate: moment().subtract(30, "d").format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          full: false,
          datePicker: false,
        });
        this.getReceipts(
          false,
          moment().subtract(30, "d").format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === "thism") {
        this.setState({
          startDate: moment().startOf("month").format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          full: false,
          datePicker: false,
        });
        this.getReceipts(
          false,
          moment().startOf("month").format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === "lastm") {
        this.setState({
          startDate: moment()
            .subtract(1, "months")
            .startOf("month")
            .format(dateFormatSearch),
          endDate: moment()
            .subtract(1, "months")
            .endOf("month")
            .format(dateFormatSearch),
          full: false,
          datePicker: false,
        });
        this.getReceipts(
          false,
          moment()
            .subtract(1, "months")
            .startOf("month")
            .format(dateFormatSearch),
          moment().subtract(1, "months").endOf("month").format(dateFormatSearch)
        );
      } else if (value === "lastm") {
        this.setState({
          startDate: moment()
            .subtract(1, "months")
            .startOf("month")
            .format(dateFormatSearch),
          endDate: moment()
            .subtract(1, "months")
            .endOf("month")
            .format(dateFormatSearch),
          full: false,
          datePicker: false,
        });
        this.getReceipts(
          false,
          moment()
            .subtract(1, "months")
            .startOf("month")
            .format(dateFormatSearch),
          moment().subtract(1, "months").endOf("month").format(dateFormatSearch)
        );
      } else if (value === "custom") {
        this.setState({
          full: false,
          datePicker: true,
        });
      }
    }
  };

  emailStatement = () => {
    this.setState({
      loading: true,
    });
    const token = this.props.local.user.token;
    const info = {};
    info.items = this.state.items.allValues;
    info.userid = this.props.id;
    info.balance = this.state.items.balance;
    this.props
      .createStatement(
        info,
        token,
        this.state.startDate,
        this.state.endDate,
        this.state.full
      )
      .then(result => {
        this.setState({
          loading: false,
        });
        this.props.openSnackbar("Statement Emailed");
      })
      .catch(err => {
        this.onError(err);
      });
  };

  emailBill = bill => {
    this.setState({
      loading: true,
    });
    const token = this.props.local.user.token;
    bill.orgname = this.props.local.user.orgname;
    bill.email = this.props.email;
    this.props
      .emailReceipt(token, bill, this.props.id)
      .then(result => {
        this.setState({
          loading: false,
        });
        this.props.openSnackbar("Receipt Emailed");
      })
      .catch(err => {
        this.onError(err);
      });
  };

  closeDialog = () => {
    this.setState({
      alert: false,
    });
  };

  onError = err => {
    this.setState({
      head: "Customer Error",
      alert: true,
      msg: [err],
      loading: false,
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  getBillItems = bill => {
    if (bill.type !== "Refund") {
      const token = this.props.local.user.token;
      this.props
        .getBillItemsCashup(token, bill._id)
        .then(result => {
          let resultBill = result.data.results.bill;
          resultBill.waiterName = bill.waiterName;
          resultBill.refund = bill.refund;
          this.setState({
            bill: resultBill,
            main: false,
            showBill: true,
            loading: false,
          });
        })
        .catch(err => {
          this.onError(err);
        });
    } else {
      this.setState({
        bill: bill,
        main: false,
        showBill: true,
        loading: false,
      });
    }
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize,
    }));
  };

  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker,
    };

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.showBill ? (
          <div>
            <Card style={{ marginTop: 0 }}>
              <CardBody>
                <GridContainer>
                  <Button
                    style={{ left: "10px", borderRadius: "2.5em" }}
                    onClick={() => this.back()}
                    color="primary"
                  >
                    Back
                  </Button>
                </GridContainer>
                <Card
                  style={{
                    borderRadius: "6px",
                    padding: "7px",
                    margin: "auto",
                    width: "45%",
                  }}
                >
                  <Row>
                    <Col
                      span={22}
                      style={{
                        fontFamily:
                          "Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif",
                        fontWeight: 600,
                        marginBottom: "0.5em",
                        fontSize: "19px",
                        textAlign: "center",
                        marginTop: 6,
                        color: "black",
                        fontFamily: "helvetica",
                      }}
                    >
                      Order #{this.state.bill.orderno}
                    </Col>
                    {this.props.email ? (
                      <Col span={2}>
                        <AiOutlineMail
                          style={{
                            position: "absolute",
                            top: "-5px",
                            fontSize: "46px",
                            backgroundColor: "rgba(229, 233, 242, 0.6)",
                            padding: "0.2em",
                            borderRadius: "3em",
                          }}
                          onClick={() => this.emailBill(this.state.bill)}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{
                      height: 33,
                      background: "#132440",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      border: "solid 1px lightgray",
                      borderRadius: "6px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                  >
                    <Col
                      span={4}
                      style={{
                        fontSize: "12px",
                        marginTop: 6,
                        color: "white",
                        fontFamily: "helvetica",
                      }}
                    >
                      {this.state.bill.table
                        ? "Table #" + this.state.bill.table
                        : this.state.bill.type}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: "12px",
                        marginTop: 6,
                        whiteSpace: "nowrap",
                        color: "white",
                        fontFamily: "helvetica",
                      }}
                    >
                      {this.state.bill.desc}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: "12px",
                        marginTop: 6,
                        color: "white",
                        fontFamily: "helvetica",
                      }}
                    >
                      {" "}
                      {this.state.bill.date
                        ? this.state.bill.date.slice(0, 4) +
                          "-" +
                          this.state.bill.date.slice(4, 6) +
                          "-" +
                          this.state.bill.date.slice(6, 8) +
                          " "
                        : "N/A"}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: "12px",
                        marginTop: 6,
                        color: "white",
                        fontFamily: "helvetica",
                      }}
                    >
                      {" "}
                      {this.state.bill.waiterName}
                    </Col>
                  </Row>
                  <List
                    style={{
                      background: "#f5f5f5",
                      border: "solid 1px lightgray",
                      borderRadius: "6px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                      marginTop: "0.4em",
                    }}
                    className="bill-item-list"
                    itemLayout="horizontal"
                    dataSource={this.state.bill.items}
                    renderItem={(item, iKey) => (
                      <List.Item>
                        <Row
                          style={{ width: "100%" }}
                          type="flex"
                          justify="space-around"
                        >
                          <Col
                            style={{
                              marginLeft: 5,
                              color: "black",
                              fontFamily:
                                "Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif",
                              fontWeight: 600,
                              fontSize: "16px",
                              marginLeft: "5px",
                            }}
                            span={14}
                          >
                            {item.name} <br />{" "}
                            <span
                              style={{
                                color: "black",
                                fontFamily:
                                  "Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif",
                                fontWeight: 450,
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              {"R " + (item.qty * item.price).toFixed(2)}
                            </span>
                          </Col>
                          <Col span={8}>
                            <span
                              style={{
                                float: "right",
                                marginRight: 20,
                                color: "black",
                                fontFamily:
                                  "Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif",
                                fontWeight: 500,
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              {item.qty}
                            </span>
                          </Col>
                          {/*} <Col span={2} >{'R ' + item.qty * item.price}</Col> */}
                        </Row>
                      </List.Item>
                    )}
                  />

                  <div
                    style={{
                      paddingTop: "0%",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      fontWeight: 600,
                    }}
                  >
                    <p
                      className="bill-sub-total"
                      style={{
                        color: "#00CC70",
                        marginTop: "0.2em",
                        fontSize: "18px",
                      }}
                    >
                      R {this.state.bill.total.toFixed(2)}
                    </p>
                    <p
                      className="bill-sub-label"
                      style={{
                        color: "#00CC70",
                        marginTop: "0.2em",
                        fontSize: "18px",
                      }}
                    >
                      Total
                    </p>
                  </div>
                  {this.state.bill.cash > 0 ? (
                    <div
                      style={{
                        paddingTop: "0%",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <p
                        className="bill-sub-total"
                        style={{
                          fontSize: "18px",
                          marginTop: "-0.2em",
                          fontWeight: 600,
                        }}
                      >
                        R {this.state.bill.cash.toFixed(2)}
                      </p>
                      <p
                        className="bill-sub-label"
                        style={{
                          fontSize: "18px",
                          marginTop: "-0.2em",
                          fontWeight: 600,
                        }}
                      >
                        Cash
                      </p>
                    </div>
                  ) : null}
                  {this.state.bill.card > 0 ? (
                    <div
                      style={{
                        paddingTop: "0%",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <p
                        className="bill-sub-total"
                        style={{
                          fontSize: "18px",
                          marginTop: "-0.2em",
                          fontWeight: 600,
                        }}
                      >
                        R {this.state.bill.card.toFixed(2)}
                      </p>
                      <p
                        className="bill-sub-label"
                        style={{
                          fontSize: "18px",
                          marginTop: "-0.2em",
                          fontWeight: 600,
                        }}
                      >
                        Card
                      </p>
                    </div>
                  ) : null}
                  {this.state.bill.tip > 0 ? (
                    <div
                      style={{
                        paddingTop: "0%",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <p
                        className="bill-sub-total"
                        style={{
                          fontSize: "18px",
                          marginTop: "-0.2em",
                          fontWeight: 600,
                        }}
                      >
                        R {this.state.bill.tip.toFixed(2)}
                      </p>
                      <p
                        className="bill-sub-label"
                        style={{
                          fontSize: "18px",
                          marginTop: "-0.2em",
                          fontWeight: 600,
                        }}
                      >
                        Tip
                      </p>
                    </div>
                  ) : null}
                  {this.state.bill.refund > 0 ? (
                    <div
                      style={{
                        paddingTop: "0%",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <p
                        className="bill-sub-total"
                        style={{
                          color: "red",
                          fontSize: "18px",
                          marginTop: "-0.2em",
                          fontWeight: 600,
                        }}
                      >
                        R -{this.state.bill.refund.toFixed(2)}
                      </p>
                      <p
                        className="bill-sub-label"
                        style={{
                          color: "red",
                          fontSize: "18px",
                          marginTop: "-0.2em",
                          fontWeight: 600,
                        }}
                      >
                        Refund
                      </p>
                    </div>
                  ) : null}
                </Card>
              </CardBody>
            </Card>
          </div>
        ) : (
          <div>
            <div
              style={{
                marginTop: "-10px",
                marginBottom: "30px",
              }}
            >
              <Select
                id="filterSelect"
                style={{
                  width: "150px",
                  paddingRight: "10px",
                }}
                defaultValue="all"
                onChange={this.handleChangeSelect}
              >
                <Option value="all">All</Option>
                <Option value="today">Today</Option>
                <Option value="yester">Yesterday</Option>
                <Option value="last7">Last 7 Days</Option>
                <Option value="last30">Last 30 Days</Option>
                <Option value="thism">This Month</Option>
                <Option value="lastm">Last Month</Option>
                <Option value="custom">Custom</Option>
              </Select>
              <Button
                style={{ left: "10px", borderRadius: "2.5em" }}
                onClick={() => this.props.backFromReceipts()}
                color="primary"
              >
                Back
              </Button>
              {this.props.email ? (
                <Button
                  style={{ left: "30px", borderRadius: "2.5em" }}
                  onClick={() => this.emailStatement()}
                  color="primary"
                >
                  Email Statement
                </Button>
              ) : null}
              {/* <div
                style={{
                  float: 'right',
                  padding: 20,
                  fontSize: 20,
                  fontWeight: 'bold'
                }}
              >
                <span>
                  Account Balance Due: R
                  {this.state.items.balancedue
                    ? this.state.items.balancedue.toFixed('2')
                    : '0.00'}
                </span>
              </div> */}
              <Row>
                <div
                  className={
                    this.state.datePicker
                      ? this.props.classes.show
                      : this.props.classes.hide
                  }
                  style={{
                    float: "left",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <RangePicker
                    allowClear={false}
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDate}
                    value={[this.state.pickerStart, this.state.pickerEnd]}
                    format={dateFormat}
                  />
                  <Button
                    style={{
                      backgroundColor: "#00CC70",
                      color: "white",
                      height: "40px",
                      width: "120px",
                      border: "1px solid #00CC70",
                      borderRadius: "3em",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                    onClick={() => this.getReceipts()}
                  >
                    {" "}
                    Get Statement
                  </Button>
                </div>
              </Row>
            </div>
            <div>
              <GridContainer>
                <Row style={{ width: "100%", padding: 15 }}>
                  <StyledTable
                    style={{ background: "white" }}
                    rowKey="_id"
                    size="middle"
                    dataSource={
                      this.state.items ? this.state.items.allValues || [] : []
                    }
                    pagination={{
                      locale: { items_per_page: " Items" },
                      showSizeChanger: true,
                      onShowSizeChange: this.onShowSizeChange,
                      pageSizeOptions: ["10", "25", "50", "100"],
                      hideOnSinglePage: true,
                      pageSize: this.state.pageSize || 25,
                      itemRender: (current, type, originalElement) => {
                        if (type === "prev") {
                          return <a>Previous</a>;
                        }
                        if (type === "next") {
                          return <a>Next</a>;
                        }
                        return originalElement;
                      },
                      showTotal: (total, range) =>
                        `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                      defaultCurrent: 1,
                    }}
                    onChange={this.handleSort}
                    onRow={(record, rowIndex) => {
                      if (rowIndex % 2 == 0) {
                        if (record.orderno) {
                          return {
                            onClick: e => {
                              e.stopPropagation();
                              this.getBillItems(record);
                            },
                            style: { background: "rgb(247, 247, 247)" },
                          };
                        } else {
                          return {
                            style: { background: "rgb(247, 247, 247)" },
                          };
                        }
                      } else {
                        if (record.orderno) {
                          return {
                            onClick: e => {
                              e.stopPropagation();
                              this.getBillItems(record);
                            },
                          };
                        }
                      }
                    }}
                  >
                    <Column
                      title="Date"
                      dataIndex="date"
                      key="date"
                      render={(text, record) => (
                        <span>
                          {text
                            ? text.slice(0, 4) +
                              "-" +
                              text.slice(4, 6) +
                              "-" +
                              text.slice(6, 8) +
                              " "
                            : "N/A"}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps("orderno")}
                      title="Description"
                      render={(text, record) => (
                        <span>
                          {record.open
                            ? "Opening Balance"
                            : record.type === "Refund"
                            ? " Refund Invoice# " + record.orderno
                            : record.orderno
                            ? " Invoice# " + record.orderno
                            : "Payment Received / " + record.type}
                        </span>
                      )}
                    />
                    <Column
                      title="Debit"
                      dataIndex="total"
                      key="total"
                      render={(text, record) => (
                        <span>
                          {"R" + parseFloat(text || "0").toFixed("2")}
                        </span>
                      )}
                    />
                    <Column
                      title="Credit"
                      dataIndex="payAmount"
                      key="payAmount"
                      render={(text, record) => (
                        <span>
                          {"R" + parseFloat(text || "0").toFixed("2")}
                        </span>
                      )}
                    />
                    <Column
                      title="Account Balance"
                      dataIndex="tabAmount"
                      key="tabAmount"
                      render={(text, record) => (
                        <span>{"R" + (text ? text.toFixed("2") : "0.00")}</span>
                      )}
                    />
                  </StyledTable>
                </Row>
              </GridContainer>
              <GridContainer>
                <GridItem xs={10} sm={10} md={10} />
                <GridItem xs={2} sm={2} md={2} style={{}}>
                  <CustomInput
                    labelText="Closing Balance for Period"
                    id="balancedue"
                    formControlProps={{
                      style: { margin: 0, minWidth: "15em", float: "right" },
                      fullWidth: true,
                    }}
                    inputProps={{
                      style: {
                        fontWeight: "bold",
                        fontSize: "25px",
                        color: "black",
                      },
                      disabled: true,
                      value:
                        "R" +
                        (this.state.items
                          ? this.state.items.balance
                            ? this.state.items.balance.toFixed("2")
                            : "0.00"
                          : "0.00"),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
});

const mapDispatchToProps = {
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  getStatementsCustomer,
  getBillItemsCashup,
  updateBill,
  createStatement,
  emailReceipt,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Statements));
