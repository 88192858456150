import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Icon,
  Input,
  Row,
  Col,
  Spin,
  Table,
  Switch,
  Popover,
  Radio,
  ConfigProvider,
  Empty,
  Upload,
  List,
  Modal,
  Card,
  Avatar,
  Button,
  Tooltip
} from 'antd';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import { FiEdit3 } from 'react-icons/fi';
//import Card from "src/components/Card/Card.jsx";
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
//import Button from "src/components/CustomButtons/Button.jsx";
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelectAuto from 'src/components/CustomSelectAuto/CustomSelectAuto.jsx';
// import CustomSelectIcons from 'src/components/CustomSelectAuto/CustomSelectIcons.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Resizer from 'react-image-file-resizer';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  addMenu,
  addMenuBtn,
  addMenuBtnCat,
  getMenuSubCat,
  addMenuBtnItemSubCat,
  addMenuBtnCatSubCat,
  deleteMenu,
  editMenu,
  getMenu,
  closeMenuHelpCard
} from 'src/redux/actions/backend';
import { FiEdit } from 'react-icons/fi';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import { } from 'src/redux/actions/subnav';
import styled from 'styled-components';
import iconsList from './iconsList.js';
import toysList from './toysList.js';
import beveragesList from './beveragesList.js';
import foodList from './foodList.js';
import colourList from './colourList.js';
import Select from 'react-select';
import StyledTable from 'src/components/Table/StyledTable';
import Loading from 'react-loading-bar';
import ContentLoader from 'react-content-loader';
import 'react-loading-bar/dist/index.css';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Column } = Table;
const { Meta } = Card;
const StyledSelect = styled(Select)``;
const StyledButton = styled(Button)`
  :focus {
    color: white !important;
    background-color: #00cc7060 !important;
  }
`;
const StyledSelectSmall = styled(Select)`
  width: 80px;
  margin-top: 2.5em;
  z-index: 2;
`;
const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 12px 16px;
    background-color: grey;
    color: rgba(0, 0, 0, 0.65);
  }
`;
const StyledModal = styled(Modal)`
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  margin: auto !important;
  padding-bottom: auto !important;
`;
const StyledCard = styled(Card)``;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '22px',
    marginBottom: '5px'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  showMain: {
    paddingLeft: '10px',
    display: 'inline-flex'
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '43px',
    fontSize: '13px'
  },
  editNameIcon: {
    marginTop: '35px',
    marginLeft: '-25%'
  }
});

const Skeleton = () => (
  <ContentLoader
    speed={0.5}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#c0c0c0"
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="46" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="92" rx="2" ry="2" width="100%" height="45" />
  </ContentLoader>
);

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}

const IconModal = props => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <span style={{ marginTop: '2em' }} type="primary" onClick={showModal}>
        {props.editbtnicon ? (
          <span>
            <Button
              style={{
                width: '45px',
                height: '40px',
                padding: '8px',
                paddingTop: '7px',
                marginTop: '2em',
                marginLeft: '0.8em',
                borderRadius: '0em'
              }}
              alt="icon"

            //cover={item.label}
            >
              {/*<Meta avatar={props.editbtnicon} description="" />*/}{' '}
              <img
                style={{ width: '100%' }}
                src={props.editbtnicon}
                alt="icon"
              />
            </Button>
            <FiEdit3
              style={{
                marginTop: '1em',
                color: 'rgb(0, 204, 112)',
                marginLeft: '0.5em'
              }}
            />
          </span>
        ) : (
          <Button
            style={{ marginTop: '2.1em' }}
            type="primary"
            onClick={showModal}
          >
            Select Icon
          </Button>
        )}
      </span>
      <StyledModal
        title={'Select Icon'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {' '}
        <Row>
          <List
            header={<div>Food</div>}
            dataSource={foodList}
            renderItem={(item, key) => (
              <Col span={1} style={{ width: '3.4em', marginTop: '-0.06em' }}>
                <List.Item style={{ padding: '0px' }}>
                  <Tooltip title={item.name}>
                    <StyledButton
                      style={{
                        //width: "40px",
                        border: '1px solid grey',
                        height: '3.2em',
                        width: '3.502em',
                        //padding: "9px",
                        //margin: "0.5em",
                        borderRadius: '0em'
                      }}
                      key={'card' + key}
                      onClick={e => props.onChangeEditIcon(item)}
                    //cover={item.label}
                    >
                      <Meta
                        style={{ padding: '0px', marginLeft: '-2px' }}
                        avatar={item.label}
                        description=""
                      />
                    </StyledButton>
                  </Tooltip>
                </List.Item>
              </Col>
            )}
          />{' '}
        </Row>
        <Row>
          <List
            header={<div>Beverages</div>}
            dataSource={beveragesList}
            renderItem={(item, key) => (
              <Col span={1} style={{ width: '3.4em', marginTop: '-0.06em' }}>
                <List.Item style={{ padding: '0px' }}>
                  <Tooltip title={item.name}>
                    <StyledButton
                      style={{
                        //width: "40px",
                        border: '1px solid grey',
                        height: '3.2em',
                        width: '3.502em',
                        //padding: "9px",
                        //margin: "0.5em",
                        borderRadius: '0em'
                      }}
                      key={'card' + key}
                      onClick={e => props.onChangeEditIcon(item)}
                    //cover={item.label}
                    >
                      <Meta
                        style={{ padding: '0px', marginLeft: '-2px' }}
                        avatar={item.label}
                        description=""
                      />
                    </StyledButton>{' '}
                  </Tooltip>
                </List.Item>
              </Col>
            )}
          />{' '}
        </Row>
        <Row>
          <List
            header={<div>Lifestyle</div>}
            dataSource={iconsList}
            renderItem={(item, key) => (
              <Col span={1} style={{ width: '3.4em', marginTop: '-0.06em' }}>
                <List.Item style={{ padding: '0px' }}>
                  <Tooltip title={item.name}>
                    <StyledButton
                      style={{
                        //width: "40px",
                        border: '1px solid grey',
                        height: '3.2em',
                        width: '3.502em',
                        //padding: "9px",
                        //margin: "0.5em",
                        borderRadius: '0em'
                      }}
                      key={'card' + key}
                      onClick={e => props.onChangeEditIcon(item)}
                    //cover={item.label}
                    >
                      <Meta
                        style={{ padding: '0px', marginLeft: '-2px' }}
                        avatar={item.label}
                        description=""
                      />
                    </StyledButton>{' '}
                  </Tooltip>
                </List.Item>
              </Col>
            )}
          />{' '}
        </Row>
        <Row>
          <List
            header={<div>Toys</div>}
            dataSource={toysList}
            renderItem={(item, key) => (
              <Col span={1} style={{ width: '3.4em', marginTop: '-0.06em' }}>
                <List.Item style={{ padding: '0px' }}>
                  <Tooltip title={item.name}>
                    <StyledButton
                      style={{
                        //width: "40px",
                        border: '1px solid grey',
                        height: '3.2em',
                        width: '3.502em',
                        //padding: "9px",
                        //margin: "0.5em",
                        borderRadius: '0em'
                      }}
                      key={'card' + key}
                      onClick={e => props.onChangeEditIcon(item)}
                    //cover={item.label}
                    >
                      <Meta
                        style={{ padding: '0px', marginLeft: '-2px' }}
                        avatar={item.label}
                        description=""
                      />
                    </StyledButton>{' '}
                  </Tooltip>
                </List.Item>
              </Col>
            )}
          />{' '}
        </Row>
      </StyledModal>
    </div>
  );
};

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      value: 1,
      main: true,
      mainInput: false,
      mainItems: false,
      mainItemsSubCat: false,
      namemenu: '',
      editnamemenu: '',
      btnnamemenu: '',
      btncatnamemenu: '',
      btncatcolormenu: undefined,
      editbtnname: '',
      editmenu: {},
      editmenubtn: {},
      menu: [],
      menubtns: [],
      menuitems: [],
      selectitem: undefined,
      selectitems: [],
      activeOnlyItems: [],
      fullItems: {},
      selectedMenuItem: '',
      loading: true,
      alert: false,
      pdopen: false,
      cdopen: false,
      msg: [],
      head: '',
      activeFilter: true,
      activeFilterBtn: true
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](true);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.getMenus();
  }

  getMenus = msg => {
    const token = this.props.local.user.token;
    this.props
      .getMenu(token)
      .then(result => {
        this.setState({
          menu: result.data.results.menu || [],
          selectitems: result.data.results.items,
          activeOnlyItems: result.data.results.activeOnlyItems,
          fullItems: result.data.results.fullItems,
          namemenu: '',
          mainInput: false,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onSaveMenu() {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .addMenu(this.state, token)
      .then(result => {
        this.getMenus('Catalogue Creation Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  onEditMenuName() {
    const token = this.props.local.user.token;
    if (!this.state.editnamemenu) return this.onError('Enter Name');
    var menu = this.state.editmenu;
    menu.name = this.state.editnamemenu;
    this.setState({ loading: true });
    this.props
      .addMenuBtn(menu, null, token)
      .then(result => {
        this.setState({
          editnamemenu: '',
          editmenu: menu,
          loading: false
        });
        this.props.openSnackbar('Catalogue Name Update Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  onEditMenuNameInline(e, record) {
    let value = e.target.value;
    const token = this.props.local.user.token;
    var menu = record;
    menu.name = value;
    this.setState({ loading: true });
    this.props
      .addMenuBtn(menu, null, token)
      .then(result => {
        this.setState({
          loading: false
        });
        // this.props.openSnackbar('Menu Name Update Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  onSaveMenuBtn() {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    if (!this.state.btnnamemenu) return this.onError('Enter Name');
    if (!this.state.selectedIcon) return this.onError('Select Icon');
    var menu = this.state.editmenu;
    var checkName = false;
    var catIndex = 0;
    //if (!this.state.selectedColour) return this.onError('Select Colour');
    for (var a = 0; a < menu.menubtns.length; a++) {
      if (menu.menubtns[a].name === this.state.btnnamemenu) {
        if (!menu.menubtns[a].active) {
          checkName = true;
          catIndex = a;
          break;
        } else {
          this.onError('Name found!');
          return;
        }
      }
    }
    if (!checkName) {
      function create_UUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
      }
      var menubtn = {
        id: create_UUID(),
        btncount: 0,
        slots: 20,
        active: true,
        name: this.state.btnnamemenu,
        delivery: false,
        menuitems: [],
        icon: this.state.selectedIcon,
        colour: this.state.selectedColour,
        index: menu.menubtns.length
      };
      menu.menubtns.push(menubtn);
    } else {
      if (menu.menubtns[catIndex]) {
        menu.menubtns[catIndex].btncount = 0;
        menu.menubtns[catIndex].slots = 20;
        menu.menubtns[catIndex].active = true;
        menu.menubtns[catIndex].name = this.state.btnnamemenu;
        menu.menubtns[catIndex].delivery = false;
        menu.menubtns[catIndex].menuitems = [];
        menu.menubtns[catIndex].icon = this.state.selectedIcon;
        menu.menubtns[catIndex].colour = this.state.selectedColour;
      }
    }

    this.props
      .addMenuBtn(menu, null, token)
      .then(result => {
        this.setState({
          menubtns: menu.menubtns || [],
          btnnamemenu: '',
          namemenu: '',
          main: false,
          mainInput: false,
          mainButton: false,
          mainItems: false,
          mainItemsSubCat: false,
          loading: false,
          selectedIcon: undefined,
          selectedColour: undefined
        });
        this.props.openSnackbar('Button Creation Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  onEditBtnName() {
    const token = this.props.local.user.token;
    if (!this.state.editbtnname) return this.onError('Enter Button Name');
    if (!this.state.editbtnicon) return this.onError('Select Icon');
    this.setState({ loading: true });
    var menu = this.state.editmenu;
    var menubtn = this.state.editmenubtn;
    let copyMenuBtn = Object.assign({}, this.state.editmenubtn);
    var check = false;
    if (menubtn.subcat) {
      menubtn.name = this.state.editbtnname;
      menubtn.colour = this.state.editbtncolour;
      if (menubtn.level === 1) {
        for (var a = 0; a < menu.menubtns.length; a++) {
          for (var j = 0; j < menu.menubtns[a].menuitems.length; j++) {
            if (menu.menubtns[a].menuitems[j].catid === menubtn.catid) {
              menu.menubtns[a].menuitems[j].name = this.state.editbtnname;
              if (this.state.editbtnicon !== 'subcat') {
                menu.menubtns[a].menuitems[j].icon = this.state.editbtnicon;
              }
              menu.menubtns[a].menuitems[j].colour = this.state.editbtncolour;
              check = true;
              break;
            }
          }
        }
      }
    } else {
      for (var i = 0; i < menu.menubtns.length; i++) {
        if (menu.menubtns[i].id === menubtn.id) {
          copyMenuBtn.oldName = menu.menubtns[i].name;
          copyMenuBtn.newName = this.state.editbtnname;
          menu.menubtns[i].name = this.state.editbtnname;
          if (this.state.editbtnicon !== 'subcat') {
            menu.menubtns[i].icon = this.state.editbtnicon;
          }
          menu.menubtns[i].colour = this.state.editbtncolour;
          menu.menubtns[i].delivery = this.state.editbtndely;
          check = true;
          break;
        }
      }
    }

    if (menubtn.subcat) {
      this.props
        .addMenuBtnCatSubCat(menu, menubtn, token, 'edit', check)
        .then(result => {
          this.setState({
            editmenu: menu,
            editmenubtn: menubtn,
            loading: false
          });
          this.props.openSnackbar('Item Button Creation Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    } else {
      this.props
        .addMenuBtn(menu, copyMenuBtn, token, 'edit')
        .then(result => {
          this.setState({
            editmenu: menu,
            editmenubtn: menubtn,
            loading: false
          });
          this.props.openSnackbar('Button Name Update Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    }
  }

  onSaveMenuItemSubCat(menu, menubtn, menubtnitem) {
    const token = this.props.local.user.token;
    var newItem = {};
    var check = true;
    if (menubtn.slots > 0) {
      menubtn.menuitems.push(menubtnitem);
      menubtn.btncount = menubtn.btncount + 1;
      menubtn.slots = menubtn.slots - 1;
      newItem.mainmenuid = menu._id;
      newItem.menubtnname = menubtn.name;
      newItem.itemid = menubtnitem.id;
    } else {
      check = false;
    }
    if (!check) {
      this.setState({ loading: false });
      this.onError('Item Button Limit Reached');
      return;
    }
    this.props
      .addMenuBtnItemSubCat(menubtn, newItem, token, 'add')
      .then(result => {
        var menuitems = result.data.results.subcat.menuitems;
        for (var i = 0; i < menuitems.length; i++) {
          menuitems[i].index = i;
        }
        this.setState({
          editmenubtn: result.data.results.subcat,
          menuitems: menuitems || [],
          selectitem: undefined,
          selectedMenuItem: '',
          main: false,
          mainInput: false,
          mainButton: false,
          mainItems: false,
          mainItemsSubCat: false,
          loading: false
        });
        this.props.openSnackbar('Item Button Creation Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  onSaveMenuItem() {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    var check = true;
    var menu = this.state.editmenu;
    var menubtn = this.state.editmenubtn;
    var menubtnitem = this.state.selectedMenuItem;
    var newItem = {};
    if (!menubtnitem) {
      this.setState({ loading: false });
      this.props.openSnackbar('Select Item');
      return;
    }
    if (menubtn.subcat) {
      this.onSaveMenuItemSubCat(menu, menubtn, menubtnitem);
    } else {
      var index = 0;
      for (var i = 0; i < menu.menubtns.length; i++) {
        if (menu.menubtns[i].id === menubtn.id) {
          if (menu.menubtns[i].slots > 0) {
            index = i;
            menu.menubtns[i].menuitems.push(menubtnitem);
            menu.menubtns[i].btncount = menu.menubtns[i].btncount + 1;
            menu.menubtns[i].slots = menu.menubtns[i].slots - 1;
            newItem.mainmenuid = menu._id;
            newItem.menubtnname = menu.menubtns[i].name;
            newItem.itemid = menubtnitem.id;
            break;
          } else {
            check = false;
            break;
          }
        }
      }
      if (!check) {
        this.setState({ loading: false });
        this.onError('Item Button Limit Reached');
        return;
      }
      this.props
        .addMenuBtn(menu, newItem, token, 'add')
        .then(result => {
          var menuitems = menu.menubtns[index].menuitems;
          for (var i = 0; i < menuitems.length; i++) {
            menuitems[i].index = i;
          }
          this.setState({
            menuitems: menuitems || [],
            menubtns: menu.menubtns || [],
            editmenu: menu,
            selectitem: undefined,
            selectedMenuItem: '',
            main: false,
            mainInput: false,
            mainButton: false,
            mainItems: false,
            mainItemsSubCat: false,
            loading: false
          });
          this.props.openSnackbar('Item Button Creation Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    }
  }

  onSaveMenuItemCatSubCat(menubtn, newmenubtn, subbutton) {
    const token = this.props.local.user.token;
    var check = true;
    subbutton.parentcatid = menubtn.catid;
    newmenubtn.parentcatid = menubtn.catid;
    if (menubtn.slots > 0) {
      subbutton.level = menubtn.level + 1;
      menubtn.menuitems.push(subbutton);
      menubtn.btncount = menubtn.btncount + 1;
      menubtn.slots = menubtn.slots - 1;
      if (menubtn.subcount) {
        menubtn.subcount = menubtn.subcount + 1;
      } else {
        menubtn.subcount = 1;
      }
    } else {
      check = false;
    }
    if (!check) {
      this.setState({ loading: false });
      this.onError('Item Button Limit Reached');
      return;
    }
    newmenubtn.level = menubtn.level + 1;
    this.props
      .addMenuBtnCatSubCat(menubtn, newmenubtn, token, 'add')
      .then(result => {
        this.setState({
          editmenubtn: menubtn,
          selectitem: undefined,
          selectedMenuItem: '',
          btncatnamemenu: '',
          main: false,
          mainInput: false,
          mainButton: false,
          mainItems: false,
          mainItemsSubCat: false,
          loading: false
        });
        this.props.openSnackbar('Item Button Creation Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  onSaveMenuItemCat() {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    var check = true;
    var menu = this.state.editmenu;
    var menubtn = this.state.editmenubtn;
    var menubtnitem = this.state.btncatnamemenu;
    if (!menubtnitem) {
      this.setState({ loading: false });
      this.props.openSnackbar('Enter name');
      return;
    }
    function create_UUID() {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
    }
    var newmenubtn = {
      catid: create_UUID(),
      menuid: menu._id,
      btncount: 0,
      slots: 20,
      active: true,
      name: menubtnitem,
      colour: this.state.btncatcolormenu,
      subcat: true,
      menuitems: [],
      level: 1
    };
    var subbutton = {
      catid: newmenubtn.catid,
      subcat: newmenubtn.subcat,
      level: newmenubtn.level,
      name: newmenubtn.name
    };
    if (menubtn.subcat) {
      this.onSaveMenuItemCatSubCat(menubtn, newmenubtn, subbutton);
    } else {
      var index = 0;
      for (var i = 0; i < menu.menubtns.length; i++) {
        if (menu.menubtns[i].id === menubtn.id) {
          if (menu.menubtns[i].slots > 0) {
            index = i;
            menu.menubtns[i].menuitems.push(subbutton);
            menu.menubtns[i].btncount = menu.menubtns[i].btncount + 1;
            menu.menubtns[i].slots = menu.menubtns[i].slots - 1;
            if (menu.menubtns[i].subcount) {
              menu.menubtns[i].subcount = menu.menubtns[i].subcount + 1;
            } else {
              menu.menubtns[i].subcount = 1;
            }
            break;
          } else {
            check = false;
            break;
          }
        }
      }
      if (!check) {
        this.setState({ loading: false });
        this.onError('Item Button Limit Reached');
        return;
      }
      this.props
        .addMenuBtnCat(menu, newmenubtn, token, 'add')
        .then(result => {
          var menuitems = menu.menubtns[index].menuitems;
          for (var i = 0; i < menuitems.length; i++) {
            menuitems[i].index = i;
          }
          this.setState({
            menuitems: menuitems || [],
            menubtns: menu.menubtns || [],
            editmenu: menu,
            selectitem: undefined,
            selectedMenuItem: '',
            main: false,
            mainInput: false,
            mainButton: false,
            mainItems: false,
            btncatnamemenu: '',
            btncatcolormenu: undefined,
            mainItemsSubCat: false,
            loading: false
          });
          this.props.openSnackbar('Item Button Creation Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    }
  }

  onEditMenu = menu => {
    this.handleAddBreadCrumb({
      method: 'onCancelBtn',
      crumb: 'Manage Categories'
    });
    this.setState({
      menubtns: menu.menubtns || [],
      editmenu: menu,
      editnamemenu: menu.name,
      namemenu: '',
      main: false,
      buttons: true,
      items: false,
      mainInput: false,
      mainButton: false,
      mainItems: false,
      mainItemsSubCat: false,
      loading: false,
      activeFilter: true,
      activeFilterBtn: true
    });
  };

  onEditBtn = menuitem => {
    this.handleAddBreadCrumb({ method: 'onCancelItem', crumb: 'Manage Items' });
    var menuitems = menuitem.menuitems;
    for (var i = 0; i < menuitems.length; i++) {
      menuitems[i].index = i;
    }
    this.setState({
      menuitems: menuitems || [],
      editmenubtn: menuitem,
      editbtnname: menuitem.name,
      editbtnicon: menuitem.icon,
      image: menuitem.image,
      editbtndely: menuitem.delivery || false,
      editbtncolour: menuitem.colour,
      selectitem: undefined,
      selectedMenuItem: '',
      main: false,
      buttons: false,
      items: true,
      mainInput: false,
      mainButton: false,
      mainItems: false,
      mainItemsSubCat: false,
      activeFilter: true,
      activeFilterBtn: true
    });
  };

  handleCheckDely = () => {
    this.setState({
      editbtndely: !this.state.editbtndely
    });
  };

  onEditBtnDown = index => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    var menubtns = this.state.menubtns;
    var f = menubtns.splice(index, 1)[0];
    var newindex = index + 1;
    f.index = newindex;
    menubtns.splice(newindex, 0, f);
    menubtns[index].index = index;
    var menu = this.state.editmenu;
    menu.menubtns = menubtns;
    this.props
      .addMenuBtn(menu, null, token)
      .then(result => {
        this.setState({
          menubtns: menubtns,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onEditBtnUp = index => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    var menubtns = this.state.menubtns;
    var f = menubtns.splice(index, 1)[0];
    var newindex = index - 1;
    f.index = newindex;
    menubtns.splice(newindex, 0, f);
    menubtns[index].index = index;
    var menu = this.state.editmenu;
    menu.menubtns = menubtns;
    this.props
      .addMenuBtn(menu, null, token)
      .then(result => {
        this.setState({
          menubtns: menubtns,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onEditBtnSubCat = subcat => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    this.props
      .getMenuSubCat(subcat, token)
      .then(result => {
        var menuitems = result.data.results[0].menuitems;
        for (var i = 0; i < menuitems.length; i++) {
          menuitems[i].index = i;
        }
        this.setState({
          menuitems: menuitems || [],
          editmenubtn: result.data.results[0],
          editbtnname: result.data.results[0].name,
          editbtncolour: result.data.results[0].colour,
          editbtnicon: result.data.results[0].subcat
            ? 'subcat'
            : result.data.results[0].icon,
          selectitem: undefined,
          selectedMenuItem: '',
          main: false,
          buttons: false,
          items: true,
          mainInput: false,
          mainButton: false,
          mainItems: false,
          mainItemsSubCat: false,
          activeFilter: true,
          activeFilterBtn: true,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onDelMenu = menu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    menu.active = !menu.active;
    this.props
      .addMenuBtn(menu, null, token)
      .then(result => {
        this.getMenus('Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onDelBtn = key => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    var menu = this.state.editmenu;
    menu.menubtns[key].active = !menu.menubtns[key].active;
    this.props
      .addMenuBtn(menu, null, token, null, menu.menubtns[key].name, true)
      .then(result => {
        this.setState({
          menubtns: menu.menubtns || [],
          editmenu: menu,
          namemenu: '',
          main: false,
          mainInput: false,
          mainItems: false,
          mainButton: false,
          loading: false
        });
        this.props.openSnackbar('Button Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onDelBtnCatSubCat(menu, menubtn, key) {
    const token = this.props.local.user.token;
    var newItem = {};
    var check = false;
    var newkey = 0;
    var newmenu = {};
    for (var i = 0; i < menu.menubtns.length; i++) {
      if (menu.menubtns[i].id === menubtn.id) {
        if (menu.menubtns[i].menuitems[key]) {
          if (menu.menubtns[i].menuitems[key].level === 1) {
            newItem.catid = menu.menubtns[i].menuitems[key].catid;
            newkey = i;
            newItem.mainmenuid = menu._id;
            newItem.menubtnname = menu.menubtns[i].name;
            newmenu = JSON.parse(JSON.stringify(menu));
            newmenu.menubtns[newkey].menuitems.splice(key, 1);
            newmenu.menubtns[newkey].btncount =
              menu.menubtns[newkey].btncount - 1;
            newmenu.menubtns[newkey].slots = menu.menubtns[newkey].slots + 1;
            check = true;
            break;
          } else {
            break;
          }
        } else {
          break;
        }
      }
    }
    if (check) {
      this.props
        .addMenuBtnCatSubCat(menu, newItem, token, 'del', check, newmenu)
        .then(result => {
          if (result.data.results === 'subfound') {
            this.onError('Sub Category Found');
          } else {
            var menuitems = newmenu.menubtns[newkey].menuitems;
            for (var i = 0; i < menuitems.length; i++) {
              menuitems[i].index = i;
            }
            this.setState({
              menuitems: menuitems || [],
              menubtns: newmenu.menubtns || [],
              editmenu: newmenu,
              selectitem: undefined,
              selectedMenuItem: '',
              btncatnamemenu: '',
              btncatcolormenu: undefined,
              main: false,
              mainInput: false,
              mainButton: false,
              mainItems: false,
              mainItemsSubCat: false,
              loading: false
            });
            this.props.openSnackbar('Item Button Delete Successful');
          }
        })
        .catch(err => {
          this.onError(err);
        });
    } else {
      newItem.catid = menubtn.menuitems[key].catid;
      newItem.menubtnname = menubtn.menuitems[key].name;
      this.props
        .addMenuBtnCatSubCat(menubtn, newItem, token, 'del')
        .then(result => {
          if (result.data.results === 'subfound') {
            this.onError('Sub Category Found');
          } else {
            var menuitems = result.data.results.subcat.menuitems;
            for (var i = 0; i < menuitems.length; i++) {
              menuitems[i].index = i;
            }
            this.setState({
              editmenubtn: result.data.results.subcat,
              menuitems: menuitems || [],
              selectitem: undefined,
              selectedMenuItem: '',
              btncatnamemenu: '',
              btncatcolormenu: undefined,
              main: false,
              mainInput: false,
              mainButton: false,
              mainItems: false,
              mainItemsSubCat: false,
              loading: false
            });
            this.props.openSnackbar('Item Button Delete Successful');
          }
        })
        .catch(err => {
          this.onError(err);
        });
    }
  }

  onDelBtnItem = key => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    var menu = this.state.editmenu;
    var menubtn = this.state.editmenubtn;
    if (menubtn.menuitems[key].subcat) {
      this.onDelBtnCatSubCat(menu, menubtn, key);
    } else {
      var newItem = {};
      if (menubtn.subcat) {
        newItem.itemid = menubtn.menuitems[key].id;
        newItem.menubtnname = menubtn.name;
        menubtn.menuitems.splice(key, 1);
        menubtn.btncount = menubtn.btncount - 1;
        menubtn.slots = menubtn.slots + 1;
        this.props
          .addMenuBtnItemSubCat(menubtn, newItem, token, 'del')
          .then(result => {
            var menuitems = result.data.results.subcat.menuitems;
            for (var i = 0; i < menuitems.length; i++) {
              menuitems[i].index = i;
            }
            this.setState({
              editmenubtn: result.data.results.subcat,
              menuitems: menuitems || [],
              selectitem: undefined,
              selectedMenuItem: '',
              btncatnamemenu: '',
              btncatcolormenu: undefined,
              main: false,
              mainInput: false,
              mainButton: false,
              mainItems: false,
              mainItemsSubCat: false,
              loading: false
            });
            this.props.openSnackbar('Item Button Delete Successful');
          })
          .catch(err => {
            this.onError(err);
          });
      } else {
        var index = 0;
        for (var i = 0; i < menu.menubtns.length; i++) {
          if (menu.menubtns[i].id === menubtn.id) {
            index = i;
            newItem.itemid = menu.menubtns[i].menuitems[key].id;
            menu.menubtns[i].menuitems.splice(key, 1);
            menu.menubtns[i].btncount = menu.menubtns[i].btncount - 1;
            menu.menubtns[i].slots = menu.menubtns[i].slots + 1;
            newItem.mainmenuid = menu._id;
            newItem.menubtnname = menu.menubtns[i].name;
            break;
          }
        }
        this.props
          .addMenuBtn(menu, newItem, token, 'del')
          .then(result => {
            var menuitems = menu.menubtns[index].menuitems;
            for (var i = 0; i < menuitems.length; i++) {
              menuitems[i].index = i;
            }
            this.setState({
              menuitems: menuitems || [],
              menubtns: menu.menubtns || [],
              editmenu: menu,
              selectitem: undefined,
              selectedMenuItem: '',
              main: false,
              mainInput: false,
              mainButton: false,
              mainItems: true,
              loading: false
            });
            this.props.openSnackbar('Item Button Delete Successful');
          })
          .catch(err => {
            this.onError(err);
          });
      }
    }
  };

  onDelDialogBtnItemClick = index => {
    this.setState({
      pdopen: false
    });
    this.onDelBtnItem(this.state.index);
  };

  onDelDialogBtnItem = index => {
    this.setState({
      index: index,
      pdopen: true
    });
  };
  // Delete Category
  onDelCatItemClick = index => {
    this.setState({
      cdopen: false
    });
    this.onDelBtn(this.state.index);
  };

  onDelCatBtnItem = index => {
    this.setState({
      index: index,
      cdopen: true
    });
  };
  onCancelBtn = () => {
    this.setState({
      loading: true,
      main: true,
      mainInput: false,
      buttons: false,
      items: false
    });
    this.handleRemoveBreadCrumb();
    this.getMenus();
  };

  onCancelItem = dontRemoveBreadCrumb => {
    this.setState({
      main: false,
      buttons: true,
      items: false,
      mainInput: false,
      mainItems: false
    });
    if (!dontRemoveBreadCrumb) {
      this.handleRemoveBreadCrumb();
    }
  };
  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value
    });
  };
  onChangeMainBtn = e => {
    this.setState({
      main: false,
      buttons: true,
      items: false
    });
    // document.getElementById('name').focus();
  };
  onAddMenu = e => {
    this.setState({
      mainInput: true
    });
  };
  onAddButton = e => {
    this.setState({
      mainButton: true
    });
  };

  onAddItem = e => {
    this.setState({
      mainItems: true
    });
  };
  onCloseCard = e => {
    this.props.closeMenuHelpCard();
  };
  onAddButtonCancel = e => {
    this.setState({
      mainInput: false,
      mainButton: false,
      mainItems: false,
      mainItemsSubCat: false,
      btncatnamemenu: '',
      btncatcolormenu: undefined
    });
  };

  onAddItemCancel = e => {
    this.setState({
      mainItems: true
    });
  };

  onAddSubCat = e => {
    this.setState({
      mainItemsSubCat: true
    });
  };

  onAddSubCatCancel = e => {
    this.setState({
      mainItemsSubCat: false
    });
  };

  onChangeEvtMenu(e) {
    if (e.target.id === 'name') {
      this.setState({ namemenu: e.currentTarget.value });
    }
    if (e.target.id === 'btnname') {
      this.setState({ btnnamemenu: e.currentTarget.value });
    }
    if (e.target.id === 'btncatname') {
      this.setState({ btncatnamemenu: e.currentTarget.value });
    }
    if (e.target.id === 'editname') {
      this.setState({ editnamemenu: e.currentTarget.value });
    }
    if (e.target.id === 'editbtnname') {
      this.setState({ editbtnname: e.currentTarget.value });
    }
  }

  onChangeIcon = e => {
    this.setState({ selectedIcon: e.value });
  };
  onChangeEditIcon = e => {
    this.setState({ editbtnicon: e.value });
  };

  onChangeColour(e) {
    this.setState({ selectedColour: e.value });
  }
  onChangeEditColour(e) {
    this.setState({ editbtncolour: e.value });
  }

  onChangeColourCat(e) {
    this.setState({ btncatcolormenu: e.value });
  }

  handleChangeSelect = (value, event) => {
    if (value) {
      var menuitem = {
        id: value,
        name: event.props.children
      };
      this.setState({
        selectitem: value,
        selectedMenuItem: menuitem
      });
    }
  };

  handleChangeSelectIcon = (value, event) => {
    if (value) {
      this.setState({
        selectedIcon: value
      });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  closeFormDialog = () => {
    this.setState({
      pdopen: false,
      cdopen: false,
    });
  };

  onError = err => {
    this.setState({
      head: 'Catalogue Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  handleFormatListBtn = format => {
    this.setState({ activeFilterBtn: format });
  };
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };

  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, 'png', 100, 0, uri => {
      callback(uri);
    });
  };

  handleImage = info => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    this.getBase64(info.file, imageUrl => {
      this.setState({ image: imageUrl });
      this.setState({ loading: true });
      var menu = this.state.editmenu;
      var menubtn = this.state.editmenubtn;
      let copyMenuBtn = Object.assign({}, this.state.editmenubtn);
      copyMenuBtn.image = imageUrl;
      const index = menu.menubtns.findIndex(
        button => button.id === copyMenuBtn.id
      );
      menu.menubtns[index] = copyMenuBtn;
      this.props
        .addMenuBtn(menu, copyMenuBtn, token, 'image')
        .then(result => {
          this.setState({
            editmenu: menu,
            editmenubtn: menubtn,
            loading: false
          });
          this.props.openSnackbar('Button Image Update Successful');
          this.getMenus();
        })
        .catch(err => {
          this.onError(err);
        });
    });
  };
  beforeUpload = file => {
    return false;
  };

  removeImage = index => {
    this.setState({ image: undefined });
  };
  render() {
    const length = this.state.menubtns.length - 1;
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker,
      onEditMenu: this.onEditMenu,
      onEditBtn: this.onEditBtn,
      onEditBtnUp: this.onEditBtnUp,
      onEditBtnDown: this.onEditBtnDown,
      onDelMenu: this.onDelMenu,
      onDelBtn: this.onDelBtn,
      onDelBtnItem: this.onDelBtnItem
    };

    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div style={{ width: '8em' }}>
        <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
          <Radio
            value={1}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => {
              this.handleFormatList(true);
            }}
          >
            Active
          </Radio>
          <Radio
            value={2}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(false)}
          >
            Inactive
          </Radio>
          <Radio
            value={3}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(undefined)}
          >
            All
          </Radio>
        </Radio.Group>
      </div>
    );
    const customizeRenderEmptyNewCategories = () => (
      <div style={{ textAlign: 'center' }}>
        <Empty
          imageStyle={{
            margin: '3em',
            marginBottom: '0.5em',
            height: 90
          }}
        />
        <button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid grey',
            color: 'white',
            width: '9em',
            height: '3em',
            fontWeight: 700,
            fontFamily: 'Mulish , sans-serif',
            borderRadius: '3em',
            zIndex: 1,
            left: 10,
            position: 'relative'
          }}
          onClick={() => this.onAddButton()}
          color="primary"
        >
          {'Add Category'}
        </button>
      </div>
    );
    const customizeRenderEmptyNoButton = () => (
      <div style={{ textAlign: 'center' }}>
        <Empty
          imageStyle={{
            margin: '3em',
            marginBottom: '0.5em',
            height: 90
          }}
        />
      </div>
    );
    const customizeRenderEmpty = () => (
      <div style={{ textAlign: 'center' }}>
        <Empty
          imageStyle={{
            margin: '3em',
            marginBottom: '0.5em',
            height: 90
          }}
        />
        <button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid grey',
            color: 'white',
            width: '9em',
            height: '3em',
            fontSize: '15px',
            fontWeight: 700,
            fontFamily: 'Mulish , sans-serif',
            borderRadius: '3em',
            zIndex: 1,
            marginTop: '1em',
            marginBottom: '1.5em'
          }}
          onClick={() => this.onAddMenu()}
          color="primary"
        >
          {'Add Catalogue'}
        </button>
      </div>
    );
    const EyeContentBtn = (
      <div style={{ width: '8em' }}>
        <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
          <Radio
            value={1}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => {
              this.handleFormatListBtn(true);
            }}
          >
            Active
          </Radio>
          <Radio
            value={2}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatListBtn(false)}
          >
            Inactive
          </Radio>
          <Radio
            value={3}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatListBtn(undefined)}
          >
            All
          </Radio>
        </Radio.Group>
      </div>
    );
    let menu = [];
    for (var i = 0; i < this.state.menu.length; i++) {
      if (this.state.activeFilter === true) {
        if (this.state.menu[i].active) {
          menu.push(this.state.menu[i]);
        }
      } else if (this.state.activeFilter === false) {
        if (!this.state.menu[i].active) {
          menu.push(this.state.menu[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        menu.push(this.state.menu[i]);
      }
    }
    let menubtns = [];
    let removeColours = [];
    for (var i = 0; i < this.state.menubtns.length; i++) {
      if (this.state.menubtns[i].colour) {
        removeColours.push(this.state.menubtns[i].colour);
      }
      if (this.state.activeFilterBtn === true) {
        if (this.state.menubtns[i].active) {
          menubtns.push(this.state.menubtns[i]);
        }
      } else if (this.state.activeFilterBtn === false) {
        if (!this.state.menubtns[i].active) {
          menubtns.push(this.state.menubtns[i]);
        }
      } else if (this.state.activeFilterBtn === undefined) {
        menubtns.push(this.state.menubtns[i]);
      }
    }
    let sortedColourList = [];
    for (var i = 0; i < colourList.length; i++) {
      if (removeColours.indexOf(colourList[i].value) === -1) {
        sortedColourList.push(colourList[i]);
      }
    }

    return (
      <div>
        <Loading
          show={this.state.loading}
          showSpinner={false}
          color="rgb(15, 135, 123)"
        />
        <AlertDialog {...oState} />
        <FormDialog
          head="Delete Catalogue Item"
          msg="Are you sure?"
          formElements={[]}
          open={this.state.pdopen}
          close={this.closeFormDialog}
          actionBtn="Yes"
          closeBtn="No"
          onClick={this.onDelDialogBtnItemClick}
        />
        <FormDialog
          head="Delete Category Item"
          msg="Are you sure?"
          formElements={[]}
          open={this.state.cdopen}
          close={this.closeFormDialog}
          actionBtn="Yes"
          closeBtn="No"
          onClick={this.onDelCatItemClick}
        />
        {this.state.main ? (
          <div>
            {/* <Button
              style={{
                position: 'fixed',
                right: '-0.6em',
                bottom: '5em',
                background: 'rgb(0, 204, 112)',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em 0em 0em 5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.onAddMenu()}
              color="primary"
            >
              <Icon type="plus"
                style={{
                  position: 'absolute',
                  paddingLeft: 3
                }}
              >
              </Icon >
              </Button> */}
            <Row>
              {this.props.local.menuHelpCard ? (
                <StyledCard
                  //title="Default size card"
                  //extra={<a href="#">More</a>}
                  style={{
                    padding: '30px',
                    marginTop: '0em',
                    marginBottom: '-2.5em'
                  }}
                >
                  <Row>
                    <Col span={15}>
                      <h2>Setup Catalogue</h2>
                      <p style={{ paddingRight: '3em' }}>
                        Click on the green add category button below to build
                        your own catalogue from scratch by adding your own
                        custom categories and then you can add items under
                        "Manage Product" in the left navigation bar for these
                        categories.
                      </p>
                    </Col>
                    <Col span={7} style={{ textAlign: 'right' }}>
                      <br />
                      <h3>Need Help ?</h3>
                      <p
                        onClick={e => {
                          window.Tawk_API.toggleVisibility();
                          window.Tawk_API.toggle();
                        }}
                        style={{
                          color: 'rgba(0, 204, 112, 0.898)',
                          fontWeight: 500,
                          cursor: 'pointer'
                        }}
                      >
                        Ask a VPOS Product Specialist.
                      </p>
                    </Col>
                    <Col span={2} />
                    <Icon
                      onClick={() => this.onCloseCard()}
                      style={{ position: 'absolute', right: 0, top: 0 }}
                      type="close"
                    />
                  </Row>
                  {menu && menu.length === 0 ? (
                    <button
                      style={{
                        display: this.state.mainInput ? 'none' : 'block',
                        marginTop: '1em',
                        backgroundColor: '#00CC70',
                        border: '0px solid grey',
                        color: 'white',
                        width: '10em',
                        height: '3em',
                        fontSize: '15px',
                        fontWeight: 700,
                        fontFamily: 'Mulish , sans-serif',
                        //fontWeight: 600,
                        borderRadius: '3em',
                        zIndex: 1
                      }}
                      onClick={() => this.onAddMenu()}
                      color="primary"
                    >
                      {'Add Catalogue'}
                    </button>
                  ) : null}
                  {this.state.mainInput ? (
                    <div style={{ marginTop: '-2em' }}>
                      <GridContainer
                        style={{
                          backgroundColor: 'transparent',
                          textAlign: 'center',
                          width: '80%'
                        }}
                      >
                        <GridItem xs={6} sm={6} md={8}>
                          <CustomInput
                            labelText="Catalogue Name"
                            id="name"
                            autoFocus={true}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: e => this.onChangeEvtMenu(e),
                              value: this.state.namemenu,
                              onClick: () => this.onHelpMarker('menuitemsqty'),
                              style: {
                                marginRight: '1em'
                              }
                            }}
                          />
                        </GridItem>{' '}
                        <Button
                          style={{
                            position: 'fixed',
                            right: '-0.6em',
                            bottom: '5em',
                            background: 'rgb(0, 204, 112)',
                            border: '1px solid transparent',
                            color: 'white',
                            padding: '0em',
                            width: '3.5em',
                            height: '3.5em',
                            borderRadius: '5em 0em 0em 5em',
                            fontSize: '1em',
                            zIndex: 1000
                          }}
                          onClick={() => this.onSaveMenu()}
                          color="primary"
                        >
                          <Icon
                            style={{
                              color: 'white'
                              //position: "absolute",
                              //paddingLeft: 3,
                            }}
                            type="check"
                          />
                        </Button>
                        <Button
                          onClick={() => this.onAddButtonCancel()}
                          className={this.props.classes.buttonAdd}
                          style={{
                            fontWeight: 700,
                            fontFamily: 'Mulish , sans-serif',
                            fontSize: '13px',
                            textTransform: 'initial',
                            boxShadow: 'none',
                            width: '7em',
                            marginRight: '1em',
                            color: 'black',
                            backgroundColor: 'rgba(229, 233, 242, 0.9)',
                            border: '0px solid rgba(229, 233, 242, 0.9)',
                            borderRadius: '3em',
                            height: '2.7em'
                          }}
                        >
                          {'Cancel'}
                        </Button>
                        <Button
                          onClick={() => this.onSaveMenu()}
                          style={{
                            fontWeight: 700,
                            fontFamily: 'Mulish , sans-serif',
                            fontSize: '13px',
                            marginLeft: '-0.4em',
                            textTransform: 'initial',
                            marginRight: '0em',
                            backgroundColor: '#00CC70',
                            border: '0px solid #00CC70',
                            color: 'white',
                            borderRadius: '3em',
                            height: '2.7em'
                          }}
                          className={this.props.classes.buttonAdd}
                        >
                          {'Save'}
                        </Button>
                      </GridContainer>
                    </div>
                  ) : null}
                </StyledCard>
              ) : (
                <div>
                  {menu && menu.length === 0 ? (
                    <button
                      style={{
                        backgroundColor: '#00CC70',
                        border: '0px solid grey',
                        color: 'white',
                        width: '9em',
                        height: '3em',
                        marginTop: '-0.4em',
                        fontSize: '15px',
                        fontWeight: 700,
                        fontFamily: 'Mulish , sans-serif',
                        borderRadius: '3em',
                        zIndex: 1,
                        position: 'absolute'
                      }}
                      onClick={() => this.onAddMenu()}
                      color="primary"
                    >
                      {'Add Catalogue'}
                    </button>
                  ) : null}
                  {this.state.mainInput ? (
                    <div>
                      <GridContainer
                        style={{
                          backgroundColor: 'transparent',
                          textAlign: 'center',
                          top: '-3em',
                          left: '13em',
                          position: 'absolute'
                        }}
                      >
                        <GridItem xs={6} sm={6} md={8}>
                          <CustomInput
                            labelText="Catalogue Name"
                            id="name"
                            autoFocus={true}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: e => this.onChangeEvtMenu(e),
                              value: this.state.namemenu,
                              onClick: () => this.onHelpMarker('menuitemsqty'),
                              style: {
                                marginRight: '1em'
                              }
                            }}
                          />
                        </GridItem>{' '}
                        <Button
                          style={{
                            position: 'fixed',
                            right: '-0.6em',
                            bottom: '5em',
                            background: 'rgb(0, 204, 112)',
                            border: '1px solid transparent',
                            color: 'white',
                            padding: '0em',
                            width: '3.5em',
                            height: '3.5em',
                            borderRadius: '5em 0em 0em 5em',
                            fontSize: '1em',
                            zIndex: 1000
                          }}
                          onClick={() => this.onSaveMenu()}
                          color="primary"
                        >
                          <Icon
                            style={{
                              color: 'white'
                              //position: "absolute",
                              //paddingLeft: 3,
                            }}
                            type="check"
                          />
                        </Button>
                        <GridItem xs={2} sm={2} md={2}>
                          <Button
                            onClick={() => this.onAddButtonCancel()}
                            className={this.props.classes.buttonAdd}
                            style={{
                              fontWeight: 700,
                              fontFamily: 'Mulish , sans-serif',
                              fontSize: '13px',
                              textTransform: 'initial',
                              boxShadow: 'none',
                              width: '7em',
                              marginRight: '9em',
                              color: 'black',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '0px solid rgba(229, 233, 242, 0.9)',
                              borderRadius: '3em',
                              height: '2.7em'
                            }}
                          >
                            {'Cancel'}
                          </Button>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                          <Button
                            onClick={() => this.onSaveMenu()}
                            style={{
                              fontWeight: 700,
                              fontFamily: 'Mulish , sans-serif',
                              fontSize: '13px',
                              textTransform: 'initial',
                              marginRight: '0em',
                              backgroundColor: '#00CC70',
                              border: '0px solid #00CC70',
                              color: 'white',
                              borderRadius: '3em',
                              height: '2.7em'
                            }}
                            className={this.props.classes.buttonAdd}
                          >
                            {'Save'}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : null}{' '}
                </div>
              )}
              <div
                style={{
                  float: 'right'
                }}
              >
                {/* <StyledPopover overlayStyle={{ borderRadius: "3em" }}

                  placement="left"
                  content={EyeContent}
                  title="Table settings"
                  trigger="click"
                >
                  <Button
                    style={{
                      position: 'fixed',
                      right: '-0.6em',
                      bottom: '9em',
                      background: 'rgba(229, 233, 242, 1)',
                      border: '1px solid transparent',
                      color: 'rgba(0, 0, 0, 0.54)',
                      padding: '0em',
                      width: '3.5em',
                      height: '3.5em',
                      boxShadow: "none",
                      borderRadius: '5em 0em 0em 5em',
                      fontSize: '1em',
                      zIndex: 1000
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      theme="filled"
                      type="setting"
                    />
                  </Button>
                </StyledPopover>*/}
                <StyledPopover
                  placement="left"
                  content={GearContent}
                  title=""
                  trigger="click"
                >
                  <Button
                    style={{
                      visibility: 'hidden',
                      backgroundColor: 'white',
                      border: '1px solid rgb(15, 135, 123)',
                      color: '#00CC70',
                      marginBottom: '0.8em',

                      marginLeft: '-0.27em',
                      borderRadius: '3em',
                      transform: 'scale(0.9)',
                      width: '2em'
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      type="setting"
                    />
                  </Button>
                </StyledPopover>
              </div>
            </Row>
            <Row>
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <StyledTable
                  locale={
                    this.state.loading
                      ? {
                        emptyText: <Skeleton />
                      }
                      : {}
                  }
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 25,
                    itemRender: itemRender,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  dataSource={menu}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (!record.active) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditMenu(record);
                        },
                        style: {
                          //backgroundColor: 'rgba(255, 4, 4, 0.062)',
                          color: 'red'
                        }
                      };
                    } else if (rowIndex % 2 == 1) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditMenu(record);
                        },
                        style: { background: 'rgba(114, 132, 140, 0.050)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditMenu(record);
                        }
                      };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                    render={(text, record) => {
                      return (
                        <CustomInput
                          noMargin={true}
                          formControlProps={{
                            fullWidth: true,
                            style: { margin: 0, padding: 0 }
                          }}
                          inputProps={{
                            onClick: e => {
                              e.stopPropagation();
                            },
                            onChange: e => {
                              this.onEditMenuNameInline(e, record);
                            },
                            value: record.name
                          }}
                        />
                      );
                    }}
                  />
                  <Column
                    title="No of Btn "
                    render={(text, record) => {
                      return (
                        <span
                          style={{
                            border: '0px solid lightgrey',
                            padding: '0.4em',
                            fontSize: '1.1em',
                            borderRadius: '0.3em',
                            fontWeight: 700
                          }}
                        >
                          {record.menubtns ? record.menubtns.length : 0}
                        </span>
                      );
                    }}
                    key="count"
                  />
                  <Column
                    {...this.getColumnSearchProps('active')}
                    title="Active"
                    render={(text, record) => {
                      return record.active ? (
                        <span
                          style={{
                            border: '1px solid rgba(0, 204, 112, 0.9)',
                            padding: '0.3em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            fontSize: '1em',
                            color: 'rgba(0, 204, 112, 0.9)',
                            borderRadius: '3em'
                          }}
                        >
                          Yes
                        </span>
                      ) : (
                        <span
                          style={{
                            border: '1px solid red',
                            padding: '0.3em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            fontSize: '1em',
                            color: 'red',
                            borderRadius: '3em'
                          }}
                        >
                          No
                        </span>
                      );
                    }}
                    dataIndex="active"
                    key="active"
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div
                        style={
                          {
                            // width: "18em",
                          }
                        }
                      >
                        <IconButton
                          style={{
                            width: '5em',
                            padding: '0.35em',
                            borderRadius: '3em',
                            backgroundColor: 'rgb(0, 204, 112)',
                            border: '0px solid rgb(0, 204, 112)'
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            oState.onEditMenu(record);
                          }}
                        >
                          {
                            <p
                              style={{
                                fontSize: 13,
                                color: 'white',
                                fontWeight: 700,
                                fontFamily: 'Mulish , sans-serif',
                                margin: 'auto'
                              }}
                            >
                              {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                              Add Category
                            </p>
                          }
                        </IconButton>
                        {record.active ? (
                          <IconButton
                            onClick={e => {
                              e.stopPropagation(e);
                              oState.onDelMenu(record);
                            }}
                            style={{
                              marginRight: '0em',
                              marginLeft: '0.4em',
                              borderRadius: '4em',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '1px solid rgba(229, 233, 242, 1)'
                            }}
                          >
                            {
                              <Icon
                                type="delete"
                                style={{
                                  fontSize: 15
                                }}
                              />
                            }
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{
                              padding: '0.35em',
                              marginRight: '0em',
                              marginLeft: '0.2em',
                              borderRadius: '4em',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '1px solid rgba(229, 233, 242, 1)'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              oState.onDelMenu(record);
                            }}
                          >
                            {
                              <Icon
                                type="rollback"
                                style={{
                                  fontSize: 13
                                }}
                              />
                            }
                          </IconButton>
                        )}
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : this.state.buttons ? (
          <Card className={this.props.classes.cardExpand}>
            {/*} <CardHeader
              style={{
                overflow: 'hidden',
                borderRadius: '0.5em',
                marginBottom: '-1em',
                backgroundColor: '#132440'
              }}
            >
              <h4
                style={{ color: 'white' }}
                className={this.props.classes.cardTitleWhite}
              >
                Manage Categories
              </h4>
            </CardHeader> */}
            <CardBody>
              {/* <div> */}
              {/*  <MdKeyboardBackspace
                  style={{ color: "#132440", fontSize: "25px" }}
                  onClick={() => this.onCancelBtn()}
                  color="primary"
                />

                <GridContainer>
                  <GridItem xs={5} sm={5} md={5} style={{ marginTop: "-1em" }}>
                    <CustomInput
                      labelText="Menu Name"
                      id="editname"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onClick={() => this.onEditMenuName()}
                        onChange: e => this.onChangeEvtMenu(e),
                        value: this.state.editnamemenu,
                        onClick: () => this.onHelpMarker('menuitemsqty')
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <IconButton style={{ marginLeft: "-2em", }}
                      onClick={() => this.onEditMenuName()}
                      className={this.props.classes.editNameIcon}
                    >
                      {
                        <FiEdit

                          style={{


                            position: 'absolute',
                            fontSize: '19px',
                            color: '#00CC70'
                          }}
                        />
                      }
                    </IconButton>
                  </GridItem>
                </GridContainer>*/}
              {/* </div> */}
              <MdKeyboardBackspace
                style={{ color: '#132440', fontSize: '25px' }}
                onClick={() => this.onCancelBtn()}
                color="primary"
              />
              <div>
                {this.state.mainButton ? null : (
                  <div>
                    <button
                      style={{
                        backgroundColor: '#00CC70',
                        border: '0px solid grey',
                        color: 'white',
                        width: '9em',
                        height: '3em',
                        fontSize: '15px',
                        fontWeight: 700,
                        fontFamily: 'Mulish , sans-serif',
                        borderRadius: '3em',
                        zIndex: 1,
                        left: 10,
                        position: 'relative'
                      }}
                      onClick={() => this.onAddButton()}
                      color="primary"
                    >
                      {'Add Category'}
                    </button>
                    <Button
                      style={{
                        position: 'fixed',
                        right: '-0.6em',
                        bottom: '5em',
                        background: 'rgb(0, 204, 112)',
                        border: '1px solid transparent',
                        color: 'white',
                        padding: '0em',
                        width: '3.5em',
                        height: '3.5em',
                        borderRadius: '5em 0em 0em 5em',
                        fontSize: '1em',
                        zIndex: 1000
                      }}
                      onClick={() => this.onAddButton()}
                      color="primary"
                    >
                      <Icon
                        type="plus"
                        style={{
                          // position: 'absolute',
                          // paddingLeft: 3
                        }}
                      />
                    </Button>
                  </div>
                )}
                {this.state.mainButton ? (
                  <div>
                    <Row style={{ minWidh: '75em' }}>
                      <Col
                        sm={24}
                        md={8}
                        style={{
                          marginLeft: '1.5em',
                          marginTop: '-0.7em'
                        }}
                      >
                        <CustomInput
                          labelText="Category Name"
                          id="btnname"
                          autoFocus={true}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => this.onChangeEvtMenu(e),
                            value: this.state.btnnamemenu,
                            onClick: () => this.onHelpMarker('menuitemsqty')
                          }}
                        />
                      </Col>
                      <Col
                        sm={24}
                        md={2}
                        style={{
                          marginLeft: '1.5em'
                        }}
                      >
                        <span
                          style={
                            {
                              //float: 'left',
                              //paddingTop: '0.5em'
                            }
                          }
                        >
                          {/*Button Icon*/}
                        </span>
                        {/*<StyledSelect
                          placeholder={"Select Icon"}
                          onChange={(e) => this.onChangeIcon(e)}
                          options={iconsList}
                        />*/}
                        <IconModal
                          onChangeEditIcon={this.onChangeIcon}
                          editbtnicon={this.state.selectedIcon}
                        />
                      </Col>
                      <Col
                        sm={24}
                        md={6}
                        style={{
                          marginLeft: '3em'
                        }}
                      >
                        <Button
                          style={{
                            marginTop: '2.1em',
                            fontWeight: 700,
                            fontFamily: 'Mulish , sans-serif',
                            fontSize: '13px',
                            textTransform: 'initial',
                            float: 'left',
                            backgroundColor: 'rgba(229, 233, 242, 0.9)',
                            border: '1px solid rgba(229, 233, 242, 0.9)',
                            color: 'black',
                            boxShadow: 'none',
                            borderRadius: '2.5em'
                          }}
                          onClick={() => this.onAddButtonCancel()}
                          color="primary"
                          className={this.props.classes.buttonAdd}
                        >
                          {'Cancel'}
                        </Button>
                        <Button
                          style={{
                            marginTop: '2.1em',
                            fontWeight: 700,
                            fontFamily: 'Mulish , sans-serif',
                            fontSize: '13px',
                            textTransform: 'initial',
                            float: 'left',
                            marginLeft: '0.6em',
                            backgroundColor: ' #00CC70',
                            border: '1px solid #00CC70',
                            boxShadow: 'none',
                            color: 'white',
                            borderRadius: '2.5em'
                          }}
                          onClick={() => this.onSaveMenuBtn()}
                          // onClick={() => this.onSaveMenu()}
                          color="primary"
                          className={this.props.classes.buttonAdd}
                        >
                          {'Save'}
                        </Button>
                        <Button
                          style={{
                            position: 'fixed',
                            right: '-0.6em',
                            bottom: '5em',
                            background: 'rgb(0, 204, 112)',
                            border: '1px solid transparent',
                            color: 'white',
                            padding: '0em',
                            width: '3.5em',
                            height: '3.5em',
                            borderRadius: '5em 0em 0em 5em',
                            fontSize: '1em',
                            zIndex: 1000
                          }}
                          //onClick={() => this.onSaveMenuBtn()}
                          onClick={() => this.onSaveMenu()}
                          color="primary"
                        >
                          <Icon
                            style={{
                              color: 'white'
                              //position: "absolute",
                              //paddingLeft: 3,
                            }}
                            type="check"
                          />
                        </Button>
                      </Col>
                    </Row>
                    <GridContainer
                      style={{
                        backgroundColor: 'transparent',
                        textAlign: 'center'
                      }}
                    >
                      {/*   <GridItem xs={6} sm={6} md={8}>
                        <div style={{ width: '100%' }}>
                          <span style={{ float: 'left' }}>Button Colour</span>
                          <StyledSelect
                            placeholder={''}
                            onChange={e => this.onChangeColour(e)}
                            options={sortedColourList}
                          />
                        </div>
                      </GridItem>  */}
                      <GridItem
                        xs={2}
                        sm={2}
                        md={6}
                        style={{ marginTop: '-2em' }}
                      />
                    </GridContainer>
                  </div>
                ) : null}
              </div>
              <StyledPopover
                placement="left"
                content={EyeContentBtn}
                title=""
                trigger="click"
              >
                <Button
                  style={{
                    position: 'fixed',
                    right: '-0.6em',
                    bottom: '9em',
                    background: 'rgba(229, 233, 242, 1)',
                    border: '1px solid transparent',
                    color: 'rgba(0, 0, 0, 0.54)',
                    padding: '0em',
                    width: '3.5em',
                    height: '3.5em',
                    boxShadow: 'none',
                    borderRadius: '5em 0em 0em 5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                >
                  <Icon
                    style={{
                      paddingLeft: '2px'
                    }}
                    theme="filled"
                    type="setting"
                  />
                </Button>
              </StyledPopover>
              <ConfigProvider renderEmpty={customizeRenderEmptyNoButton}>
                <StyledTable
                  style={{ background: 'white', marginTop: '0.8em' }}
                  rowKey="id"
                  size="middle"
                  // pagination={{
                  //   hideOnSinglePage: true,
                  //   pageSize: 25,
                  //   itemRender: itemRender,
                  //   showTotal: (total, range) =>
                  //     `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                  //   defaultCurrent: 1
                  // }}
                  pagination={false}
                  dataSource={menubtns}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (!record.active) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditBtn(record);
                        },
                        style: {
                          //background: 'rgba(255, 4, 4, 0.063)',
                          color: 'red'
                        }
                      };
                    } else if (rowIndex % 2 == 0) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditBtn(record);
                        },
                        style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditBtn(record);
                        }
                      };
                    }
                  }}
                >
                  {this.props.local.user.ecommerceActive ? (
                    <Column
                      title="Image"
                      render={(text, record) => {
                        if (record.image) {
                          return (
                            <img
                              style={{ maxHeight: 58, maxWidth: 58 }}
                              alt={record.name}
                              src={
                                record.image.search('base64') > -1
                                  ? record.image
                                  : server +
                                  'vpos/api/static/images/' +
                                  record.image
                              }
                            />
                          );
                        } else {
                          return 'N/A';
                        }
                      }}
                    />
                  ) : null}
                  <Column
                    title="Icon"
                    dataIndex="icon"
                    key="icon"
                    render={(text, record, key) => (
                      <img src={text} style={{ width: 15 }} />
                    )}
                  />
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  {/*   <Column
                  title="Colour"
                  dataIndex="colour"
                  key="colour"
                  render={(text, record, key) => (
                    <div style={{ width: 15, height: 15, background: text }} />
                  )}
                  />*/}
                  <Column
                    {...this.getColumnSearchProps('btncount')}
                    title="Buttons"
                    render={(text, record) => {
                      return (
                        <span>
                          {record.btncount === 0 ? '0' : record.btncount}
                        </span>
                      );
                    }}
                    dataIndex="btncount"
                    key="btncount"
                  />
                  <Column
                    {...this.getColumnSearchProps('slots')}
                    title="Slots"
                    render={(text, record) => {
                      return (
                        <span>{record.slots === 0 ? '0' : record.slots}</span>
                      );
                    }}
                    dataIndex="slots"
                    key="slots"
                  />
                  <Column
                    {...this.getColumnSearchProps('delivery')}
                    title="Delivery"
                    render={(text, record) => {
                      return record.delivery ? (
                        <span
                          style={{
                            border: '1px solid rgba(0, 204, 112, 0.9)',
                            padding: '0.3em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            fontSize: '1em',
                            color: 'rgba(0, 204, 112, 0.9)',
                            borderRadius: '3em'
                          }}
                        >
                          Yes
                        </span>
                      ) : (
                        <span
                          style={{
                            border: '1px solid red',
                            padding: '0.3em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            fontSize: '1em',
                            color: 'red',
                            borderRadius: '3em'
                          }}
                        >
                          No
                        </span>
                      );
                    }}
                    dataIndex="delivery"
                    key="delivery"
                  />
                  <Column
                    {...this.getColumnSearchProps('active')}
                    title="Active"
                    render={(text, record) => {
                      return record.active ? (
                        <span
                          style={{
                            border: '1px solid rgba(0, 204, 112, 0.9)',
                            padding: '0.3em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            fontSize: '1em',
                            color: 'rgba(0, 204, 112, 0.9)',
                            borderRadius: '3em'
                          }}
                        >
                          Yes
                        </span>
                      ) : (
                        <span
                          style={{
                            border: '1px solid red',
                            padding: '0.3em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            fontSize: '1em',
                            color: 'red',
                            borderRadius: '3em'
                          }}
                        >
                          No
                        </span>
                      );
                    }}
                    dataIndex="active"
                    key="active"
                  />
                  <Column
                    title="Order"
                    render={(text, record, key) => (
                      <div>
                        {key !== length ? (
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              oState.onEditBtnDown(record.index);
                            }}
                          >
                            {
                              <Icon
                                type="down"
                                style={{
                                  position: 'absolute',
                                  fontSize: '17px',
                                  color: 'grey'
                                }}
                              />
                            }
                          </IconButton>
                        ) : (
                          false
                        )}
                        {key !== 0 ? (
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              oState.onEditBtnUp(record.index);
                            }}
                          >
                            {
                              <Icon
                                type="up"
                                style={{
                                  position: 'absolute',
                                  fontSize: '17px',
                                  color: 'grey'
                                }}
                              />
                            }
                          </IconButton>
                        ) : (
                          false
                        )}
                      </div>
                    )}
                  />
                  <Column
                    title="Actions"
                    render={(text, record, key) => (
                      <div
                        style={{
                          width: '9em'
                        }}
                      >
                        <IconButton
                          style={{
                            width: '3em',
                            padding: '0.35em',
                            borderRadius: '3em',
                            backgroundColor: 'rgb(0, 204, 112)',
                            border: '0px solid rgb(0, 204, 112)'
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            oState.onEditBtn(record);
                          }}
                        >
                          {
                            <p
                              style={{
                                fontSize: 13,
                                color: 'white',
                                fontWeight: 700,
                                fontFamily: 'Mulish , sans-serif',
                                margin: 'auto'
                              }}
                            >
                              {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                              Edit
                            </p>
                          }
                        </IconButton>
                        {record.active ? (
                          <IconButton
                            // onClick={e => {
                            //   e.stopPropagation();
                            //   oState.onDelBtn(record.index);
                            // }}
                            onClick={e => {
                              e.stopPropagation();
                              this.onDelCatBtnItem(record.index);
                            }}
                            style={{
                              marginRight: '0em',
                              marginLeft: '0.4em',
                              borderRadius: '4em',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '1px solid rgba(229, 233, 242, 1)'
                            }}
                          >
                            {
                              <Icon
                                type="delete"
                                style={{
                                  fontSize: 15
                                }}
                              />
                            }
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{
                              padding: '0.35em',
                              marginRight: '0em',
                              marginLeft: '0.2em',
                              borderRadius: '4em',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '1px solid rgba(229, 233, 242, 1)'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              oState.onDelBtn(record.index);
                            }}

                          >
                            {
                              <Icon
                                type="rollback"
                                style={{
                                  fontSize: 13
                                }}
                              />
                            }
                          </IconButton>
                        )}
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </CardBody>
          </Card>
        ) : this.state.items ? (
          <Card className={this.props.classes.cardExpand}>
            {/*} <CardHeader
              style={{
                borderRadius: '0.5em',
                backgroundColor: '#132440'
              }}
            >
              <h4
                style={{ color: 'white' }}
                className={this.props.classes.cardTitleWhite}
              >
                Manage Items
              </h4>
            </CardHeader> */}
            <CardBody>
              <MdKeyboardBackspace
                style={{
                  color: '#132440',
                  fontSize: '25px',
                  marginBottom: '-0.5em'
                }}
                onClick={() => this.onCancelItem()}
                color="primary"
              />
              <div>
                <Row
                  style={{
                    // boxShadow: "rgb(229 233 242) 0px 5px 10px 2px",
                    // borderRadius: "1em",
                    //  padding: "1em"
                    marginBottom: '1em'
                  }}
                >
                  <Col sm={24} md={10}>
                    <CustomInput
                      labelText="Button Name"
                      id="editbtnname"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.onChangeEvtMenu(e),
                        value: this.state.editbtnname,
                        onClick: () => this.onHelpMarker('menuitemsqty')
                      }}
                    />
                  </Col>
                  <Col span={2} style={{ marginLeft: '9em', zIndex: 9 }}>
                    <IconButton
                      style={{
                        position: 'absolute',
                        marginLeft: '3.5em',
                        width: '3em',
                        padding: '0.35em',
                        borderRadius: '3em',
                        backgroundColor: 'rgb(0, 204, 112)',
                        border: '0px solid rgb(0, 204, 112)'
                      }}
                      onClick={() => this.onEditBtnName()}
                      className={this.props.classes.editNameIcon}
                    >
                      {
                        <p
                          style={{
                            fontWeight: 700,
                            fontFamily: 'Mulish , sans-serif',
                            fontSize: '13px',
                            color: 'white',
                            margin: 'auto'
                          }}
                        >
                          {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                          Save
                        </p>
                      }
                    </IconButton>
                  </Col>
                  {this.state.editbtnicon !== 'subcat' ? (
                    <div>
                      <Col sm={24} md={12} style={{ marginLeft: '1em' }}>
                        <div
                          style={{
                            width: '100%',
                            marginTop: '0.5em',
                            zIndex: 9
                          }}
                        >
                          {/*<StyledSelectSmall
                            value={{
                              value: this.state.editbtnicon,
                              label: (
                                <img
                                  src={this.state.editbtnicon}
                                  style={{ width: 20 }}
                                />
                              ),
                            }}
                            onChange={(e) => this.onChangeEditIcon(e)}
                            options={iconsList}
                          />*/}
                          <IconModal
                            onChangeEditIcon={this.onChangeEditIcon}
                            editbtnicon={this.state.editbtnicon}
                          />
                        </div>
                      </Col>
                      <Col
                        sm={24}
                        md={6}
                        style={{ margin: '0.5em', marginLeft: '10em' }}
                      >
                        <FormControlLabel
                          style={{
                            marginTop: '-4.5em'
                          }}
                          control={
                            <Switch
                              style={{}}
                              checked={this.state.editbtndely}
                              onChange={this.handleCheckDely}
                            />
                          }
                          label={
                            <span
                              style={{
                                position: 'absolute',
                                marginTop: '-3.15em',
                                marginLeft: '-3.2em'
                              }}
                            >
                              Delivery
                            </span>
                          }
                        />

                        {/*<IconButton
                            style={{ marginTop: '0.2em', marginLeft: '0.5em', }}
                            onClick={() => this.onEditBtnName()}
                            className={this.props.classes.editNameIcon}
                          >
                            {
                              <FiEdit
                                type="edit"
                                style={{
                                  position: 'absolute',
                                  fontSize: '19px',
                                  color: '#00CC70'
                                }}
                              />
                            }
                          </IconButton>*/}
                      </Col>
                    </div>
                  ) : null}

                  {this.props.local.user.ecommerceActive ? (
                    <GridItem xs={12} sm={12} md={4}>
                      {!this.state.image ? (
                        <div
                          style={{
                            width: 200,
                            height: 200,
                            border: '1px solid',
                            display: 'flex'
                          }}
                        >
                          <div style={{ margin: 'auto' }}>
                            {' '}
                            <Upload
                              name="image"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <div
                                style={{ display: 'grid', textAlign: 'center' }}
                              >
                                <Button
                                  style={{
                                    width: '12em',
                                    backgroundColor: '#00CC70',
                                    borderRadius: '0.3em',
                                    zIndex: 9,
                                    margin: 'auto'
                                  }}
                                  color="primary"
                                >
                                  Upload Image
                                </Button>
                                <span>or</span>
                                <span>Drop File Here</span>
                              </div>
                            </Upload>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: 200,
                            height: 200,
                            border: '1px solid',
                            display: 'flex'
                          }}
                        >
                          <img
                            style={{
                              margin: 'auto',
                              maxHeight: '99%',
                              maxWidth: '99%'
                            }}
                            alt={'image'}
                            src={
                              this.state.image.search('base64') > -1
                                ? this.state.image
                                : server +
                                'vpos/api/static/images/' +
                                this.state.image
                            }
                          />
                          <div
                            style={{
                              position: 'absolute',
                              marginLeft: 155,
                              top: 30
                            }}
                          >
                            <IconButton onClick={() => this.removeImage()}>
                              {
                                <Icon
                                  type="delete"
                                  style={{
                                    fontSize: '12px',
                                    color: 'red',
                                    border: '1px solid',
                                    borderRadius: '3em',
                                    padding: '0.4em'
                                  }}
                                />
                              }
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </GridItem>
                  ) : null}
                </Row>

                <GridContainer>
                  {/*    <GridItem xs={5} sm={5} md={5}>
                    <div style={{ width: '100%' }}>
                      <span style={{ float: 'left' }}>Button Colour</span>
                      <StyledSelect
                        value={{
                          value: this.state.editbtncolour,
                          label: (
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                background: this.state.editbtncolour
                              }}
                            />
                          )
                        }}
                        placeholder={''}
                        onChange={e => this.onChangeEditColour(e)}
                        options={sortedColourList}
                      />
                    </div>
                      </GridItem> 
                  <GridItem xs={2} sm={2} md={2}>
                    <IconButton
                      onClick={() => this.onEditBtnName()}
                      className={this.props.classes.editNameIcon}
                    >
                      {
                        <Icon
                          type="edit"
                          style={{
                            marginRight: '2em',
                            position: 'absolute',
                            fontSize: '17px',
                            color: 'green'
                          }}
                        />
                      }
                    </IconButton>
                  </GridItem>*/}
                </GridContainer>
              </div>
              <GridContainer
                style={{
                  borderTop: '1px solid rgba(229, 233, 242, 1)',
                  paddingTop: '0.8em'
                }}
              >
                {this.state.mainItems ? (
                  <GridContainer
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        // minWidth: '150px',
                        marginLeft: '1.5em',
                        marginTop: '-2em'
                      }}
                    >
                      <CustomSelectAuto
                        error={''}
                        errorText={''}
                        style={{ width: 'auto' }}
                        selectedItem={this.state.selectitem}
                        onChange={this.handleChangeSelect}
                        labelText="Select Catalogue Item"
                        id="menuitemcode"
                        items={this.state.activeOnlyItems}
                        formControlProps={{
                          style: { width: 'auto', minWidth: '180px' },
                          fullWidth: true,
                          onClick: () => this.onHelpMarker('menuitemsstockitem')
                        }}
                      />
                      <Button
                        style={{
                          fontWeight: 700,
                          fontFamily: 'Mulish , sans-serif',
                          fontSize: '13px',
                          marginTop: '1em',
                          textTransform: 'initial',
                          borderRadius: '3em',
                          marginLeft: '1.5em',
                          border: '1px solid rgba(229, 233, 242, 0.9)',
                          color: 'black',
                          backgroundColor: 'rgba(229, 233, 242, 0.9)',
                          boxShadow: 'none'
                        }}
                        onClick={() => this.onAddButtonCancel()}
                        color="primary"
                        className={this.props.classes.buttonAdd}
                      >
                        {'Cancel'}
                      </Button>
                      <Button
                        style={{
                          fontWeight: 700,
                          fontFamily: 'Mulish , sans-serif',
                          fontSize: '13px',
                          textTransform: 'initial',
                          marginTop: '1em',
                          marginLeft: '0.6em',
                          borderRadius: '3em',
                          border: '1px solid #00CC70',
                          color: 'white',
                          backgroundColor: ' #00CC70'
                        }}
                        onClick={() => this.onSaveMenuItem()}
                        color="primary"
                        className={this.props.classes.buttonAdd}
                      >
                        {'Add'}
                      </Button>
                    </GridItem>

                  </GridContainer>
                ) : (
                  <div>
                    {this.state.mainItemsSubCat ? (
                      <GridContainer style={{}}>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            // width: '200px',
                            marginLeft: '1.5em',
                            marginTop: '-2em'
                          }}
                        >
                          <CustomInput
                            labelText="Sub Category Name"
                            id="btncatname"
                            formControlProps={{
                              style: { width: 'auto', minWidth: '290px' },
                            }}
                            inputProps={{
                              onChange: e => this.onChangeEvtMenu(e),
                              value: this.state.btncatnamemenu,
                              onClick: () => this.onHelpMarker('menuitemsqty')
                            }}
                          />{' '}
                          <Button
                            style={{
                              fontWeight: 700,
                              fontFamily: 'Mulish , sans-serif',
                              fontSize: '13px',
                              marginLeft: '1.5em',
                              marginTop: '1em',
                              textTransform: 'initial',
                              borderRadius: '3em',
                              border: '1px solid rgba(229, 233, 242, 0.9)',
                              color: 'black',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              boxShadow: 'none'
                            }}
                            onClick={() => this.onAddButtonCancel()}
                            color="primary"
                            className={this.props.classes.buttonAdd}
                          >
                            {'Cancel'}
                          </Button>
                          <Button
                            style={{
                              fontWeight: 700,
                              fontFamily: 'Mulish , sans-serif',
                              fontSize: '13px',
                              marginTop: '1em',
                              marginLeft: '0.6em',
                              textTransform: 'initial',
                              borderRadius: '3em',
                              border: '1px solid #00CC70',
                              color: 'white',
                              backgroundColor: '#00CC70',
                              boxShadow: 'none'
                            }}
                            onClick={() => this.onSaveMenuItemCat()}
                            color="primary"
                            className={this.props.classes.buttonAdd}
                          >
                            {'Add'}
                          </Button>{' '}
                        </GridItem>

                      </GridContainer>
                    ) : (
                      // <GridContainer>
                      //      <GridItem xs={12} sm={12} md={12}>
                      //        <div style={{ width: '100%' }}>
                      //          <span style={{ float: 'left' }}>
                      //            Button Colour
                      //          </span>
                      //          <StyledSelect
                      //            value={{
                      //              value: this.state.btncatcolormenu,
                      //              label: (
                      //                <div
                      //                  style={{
                      //                    width: 20,
                      //                    height: 20,
                      //                    background: this.state.btncatcolormenu
                      //                  }}
                      //                />
                      //              )
                      //            }}
                      //            placeholder={''}
                      //            onChange={e => this.onChangeColourCat(e)}
                      //            options={sortedColourList}
                      //          />
                      //        </div>
                      //      </GridItem>
                      //          </GridContainer>

                      <div>
                        {' '}
                        <Button
                          style={{
                            fontWeight: 700,
                            fontFamily: 'Mulish , sans-serif',
                            fontSize: '15px',
                            width: '11em',
                            textTransform: 'initial',
                            left: '16px',
                            backgroundColor: '#00CC70',
                            border: '0px solid #00CC70',
                            color: 'white',
                            borderRadius: '3em'
                          }}
                          onClick={() => this.onAddSubCat()}
                          color="primary"
                        >
                          {'Add Sub Category'}
                        </Button>
                        <Button
                          style={{
                            fontWeight: 700,
                            fontFamily: 'Mulish , sans-serif',
                            fontSize: '15px',
                            width: '8.5em',
                            textTransform: 'initial',
                            left: '22px',
                            backgroundColor: '#00CC70',
                            border: '0px solid #00CC70',
                            color: 'white',
                            borderRadius: '3em'
                          }}
                          onClick={() => this.onAddItem()}
                          color="primary"
                        >
                          {'Add Item'}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </GridContainer>
              <Row>
                <div style={{ float: 'right' }}>
                  <StyledPopover
                    placement="bottomRight"
                    content={GearContent}
                    title=""
                    trigger="click"
                  >
                    <Button
                      style={{
                        display: 'none',
                        backgroundColor: '#1EA1A9',
                        border: '1px solid rgb(15, 135, 123)',
                        color: 'rgb(15, 135, 123)',
                        marginBottom: 'em',
                        height: '3.5em',
                        marginLeft: '-0.27em',
                        borderRadius: '3em',
                        transform: 'scale(0.9)',
                        width: '2em',
                        zIndex: 0
                      }}
                    >
                      <Icon
                        style={{
                          paddingLeft: '2px'
                        }}
                        type="setting"
                      />
                    </Button>
                  </StyledPopover>
                </div>
              </Row>
              <ConfigProvider renderEmpty={customizeRenderEmptyNoButton}>
                <StyledTable
                  style={{ background: 'white', marginTop: '0.8em' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={this.state.menuitems}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 25,
                    itemRender: itemRender,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (rowIndex % 2 === 0) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          if (record.subcat) {
                            this.onEditBtnSubCat(record);
                          }
                        },
                        style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          if (record.subcat) {
                            this.onEditBtnSubCat(record);
                          }
                        }
                      };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getColumnSearchProps('sellPrice')}
                    title="Price"
                    render={(text, record) => {
                      return (
                        <span>
                          {this.state.fullItems[record.id]
                            ? this.state.fullItems[record.id].sellPrice
                              ? parseFloat(
                                this.state.fullItems[record.id].sellPrice
                              ).toFixed('2')
                              : 'N/A'
                            : 'N/A'}
                        </span>
                      );
                    }}
                    key="price"
                  />
                  <Column
                    title="Colour"
                    dataIndex="colour"
                    key="colour"
                    render={(text, record, key) => (
                      <div>
                        {record.colour ? (
                          <div
                            style={{
                              width: 15,
                              height: 15,
                              background: record.colour
                            }}
                          />
                        ) : (
                          'N/A'
                        )}
                      </div>
                    )}
                  />
                  <Column
                    {...this.getColumnSearchProps('subcat')}
                    title="Category"
                    render={(text, record) => {
                      return record.subcat ? (
                        <span
                          style={{
                            border: '1px solid rgba(0, 204, 112, 0.9)',
                            padding: '0.3em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            fontSize: '1em',
                            color: 'rgba(0, 204, 112, 0.9)',
                            borderRadius: '3em'
                          }}
                        >
                          Yes
                        </span>
                      ) : (
                        <span
                          style={{
                            border: '1px solid red',
                            padding: '0.3em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            fontSize: '1em',
                            color: 'red',
                            borderRadius: '3em'
                          }}
                        >
                          No
                        </span>
                      );
                    }}
                    key="subcat"
                  />
                  <Column
                    title="Actions"
                    render={(text, record, key) => (
                      <div>
                        {record.subcat ? (
                          <IconButton
                            style={{
                              width: '3em',
                              padding: '0.35em',
                              borderRadius: '3em',
                              backgroundColor: 'rgb(0, 204, 112)',
                              border: '0px solid rgb(0, 204, 112)'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              this.onEditBtnSubCat(record);
                            }}
                          >
                            {
                              <p
                                style={{
                                  fontSize: 13,
                                  color: 'white',
                                  fontWeight: 700,
                                  fontFamily: 'Mulish , sans-serif',
                                  margin: 'auto'
                                }}
                              >
                                {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                                Edit
                              </p>
                            }
                          </IconButton>
                        ) : null}
                        <IconButton
                          style={{
                            marginRight: '0em',
                            marginLeft: '0.4em',
                            borderRadius: '4em',
                            backgroundColor: 'rgba(229, 233, 242, 0.9)',
                            border: '1px solid rgba(229, 233, 242, 1)'
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            this.onDelDialogBtnItem(record.index);
                          }}
                        >
                          {
                            <Icon
                              type="delete"
                              style={{
                                fontSize: 13
                              }}
                            />
                          }
                        </IconButton>
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </CardBody>
          </Card>
        ) : null}

        <Snackbar {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  setHelpMarker,
  openSnackbar,
  closeSnackbar,
  addMenuBtnCat,
  getMenuSubCat,
  addMenuBtnItemSubCat,
  addMenuBtnCatSubCat,
  getMenu,
  addMenu,
  editMenu,
  deleteMenu,
  addMenuBtn,
  setBreadCrumbs,
  resetActionBreadCrumb,
  closeMenuHelpCard
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Menu));
