import moment from 'moment';
import convert from 'convert-units';
import specialSvg from 'src/static/buttonIcons/special.svg';
import starsSvg from 'src/static/buttonIcons/stars.svg';
export const initialState = {
  menu: [],
  specials: {},
  customers: [],
  searchMenu: [],
  cooking: [],
  subCategories: [],
  lastbillno: '',
  lastdevicebillno: '',
  preBills: {},
  loggedIn: false,
  bill: {},
  billsRefund: [],
  billsReceipts: [],
  voidReasons: [],
  paymentReasons: [],
  payments: [],
  billitems: [],
  types: [],
  mainmenu: [],
  stockQtyList: {},
  syncBills: {},
  dashUser: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MENU': {
      if (
        action.data &&
        action.data.results &&
        action.data.results !== 'No Menus Found'
      ) {
        var fav = '';
        if (action.data.results.menu.menubtns.length > 0) {
          if (action.data.results.menu.menubtns[0].name === 'Favourites') {
            if (!action.showFav) {
              action.data.results.menu.menubtns.splice(0, 1);
            } else {
              action.data.results.menu.menubtns[0].icon = starsSvg;
              action.data.results.menu.menubtns[0].colour = 'rgb(33, 190, 117)';
              fav = action.data.results.menu.menubtns[0];
              action.data.results.menu.menubtns.splice(0, 1);
            }
          }
        }
        if (action.data.results.specials) {
          var date = moment().format('YYYYMMDD');
          var day = moment().day();
          var time = moment().format('HHmm');
          var specials = [];
          for (var prop in action.data.results.specials) {
            specials = [];
            if (action.data.results.specials[prop].type === 'onceoff') {
              if (
                action.data.results.specials[prop].startdate <= date &&
                action.data.results.specials[prop].enddate >= date &&
                action.data.results.specials[prop].starttime <= time &&
                action.data.results.specials[prop].endtime >= time
              ) {
                specials = action.data.results.specials[prop].btns;
              }
            } else if (action.data.results.specials[prop].type === 'week') {
              var startDate = action.data.results.specials[prop].startdate;
              var startTime = action.data.results.specials[prop].starttime;
              var endDate = action.data.results.specials[prop].enddate;
              var endTime = action.data.results.specials[prop].endtime;
              let checkStart =
                startDate <= day && startTime <= time ? true : false;
              let checkEnd = endDate >= day && endTime >= time ? true : false;
              if (checkStart && checkEnd) {
                specials = action.data.results.specials[prop].btns;
              }
            } else if (action.data.results.specials[prop].type === 'day') {
              if (
                action.data.results.specials[prop].starttime <= time &&
                action.data.results.specials[prop].endtime >= time
              ) {
                specials = action.data.results.specials[prop].btns;
              }
            }
            if (specials.length > 0) {
              var menuSpecial = {
                active: true,
                name: action.data.results.specials[prop].name,
                menuitems: specials,
                icon: specialSvg,
                colour: 'red',
                special: true
              };
              action.data.results.menu.menubtns.unshift(menuSpecial);
            }
          }
        }
        if (fav) {
          action.data.results.menu.menubtns.unshift(fav);
        }
        if (action.data.results.preBills) {
          return {
            ...state,
            menu: action.data.results.menu.menubtns,
            searchMenu: action.data.results.menu.menubtns,
            cooking: action.data.results.cooking,
            subCategories: action.data.results.catsub,
            customers: action.data.results.customers,
            specials: action.data.results.specials,
            stockQtyList: action.data.results.stockQtyList,
            preBills: action.data.results.preBills || state.preBills,
            billitems:
              action.data.results.preBills.billitems || state.billitems,
            paymentReasons:
              action.data.results.paymentReasons || state.paymentReasons,
            voidReasons: action.data.results.voidReasons || state.voidReasons,
            lastbillno: action.data.results.lastbillno
          };
        } else {
          return {
            ...state,
            menu: action.data.results.menu.menubtns,
            searchMenu: action.data.results.menu.menubtns,
            cooking: action.data.results.cooking,
            subCategories: action.data.results.catsub,
            customers: action.data.results.customers,
            specials: action.data.results.specials,
            stockQtyList: action.data.results.stockQtyList,
            paymentReasons:
              action.data.results.paymentReasons || state.paymentReasons,
            voidReasons: action.data.results.voidReasons || state.voidReasons
          };
        }
      } else if (
        action.data &&
        action.data.results &&
        action.data.results === 'No Menus Found'
      ) {
        return {
          ...state,
          menu: initialState.menu,
          searchMenu: initialState.searchMenu,
          cooking: initialState.cooking,
          subCategories: initialState.subCategories,
          customers: initialState.customers,
          specials: initialState.specials
        };
      }
      return state;
    }
    case 'GET_BILL_PRELOAD': {
      if (action.data && action.data.results && action.data.results.preBills) {
        return {
          ...state,
          preBills: action.data.results.preBills || state.preBills
        };
      }
      return state;
    }
    case 'UPDATE_REDUX_MENU': {
      if (action.data) {
        return {
          ...state,
          searchMenu: action.data
        };
      }
      return state;
    }
    case 'GET_ADDMENUITEMDATA': {
      if (action.data) {
        return {
          ...state,
          types: action.data.results.types,
          mainmenu: action.data.results.mainmenu
        };
      }
      return state;
    }
    case 'ADMIN_LOGIN': {
      return {
        ...state,
        loggedIn: true
      };
    }
    case 'ASSIGN_PREBILLS': {
      if (action.data) {
        if (state.syncBills[action.data._id]) {
          let billItems = [];
          for (var prop in state.syncBills[action.data._id]) {
            state.syncBills[action.data._id][prop].items.forEach(item => {
              billItems.push(item);
            });
          }
          var billToArray = [];
          for (var prop2 in state.syncBills[action.data._id]) {
            billToArray.push(state.syncBills[action.data._id][prop2]);
          }
          let objectBills = {};
          for (var i = 0; i < billToArray.length; i++) {
            objectBills[billToArray[i]._id] = billToArray[i];
          }
          return {
            ...state,
            dashUser: action.data,
            bill: objectBills,
            billitems: billItems
            //loggedIn: true
          };
        } else if (state.preBills[action.data._id]) {
          let billItems = [];
          for (var prop in state.preBills[action.data._id].bills) {
            state.preBills[action.data._id].bills[prop].items.forEach(item => {
              billItems.push(item);
            });
          }
          var billToArray = [];
          for (var prop2 in state.preBills[action.data._id].bills) {
            billToArray.push(state.preBills[action.data._id].bills[prop2]);
          }
          let objectBills = {};
          billToArray.forEach(bill => {
            objectBills[bill._id] = bill;
          });
          return {
            ...state,
            dashUser: action.data,
            bill: objectBills,
            billitems: billItems
            //loggedIn: true
          };
        }
        return {
          ...state,
          dashUser: action.data,
          bill: {},
          billitems: []
          //loggedIn: true
        };
      }
      return {
        ...state
        // dashUser: {},
        // bill: {},
        // billitems: [],
        //loggedIn: true
      };
    }
    case 'REMOVE_PREBILL_LOGIN': {
      return {
        ...state,
        loggedIn: false
      };
    }
    case 'SET_LOGGEDIN_FALSE': {
      return {
        ...state,
        loggedIn: false
      };
    }
    case 'GET_BILLS': {
      if (action.data) {
        return {
          ...state,
          bill: action.data.results.bill,
          billitems: action.data.results.billitems
        };
      } else {
        let allBills = state.bill;
        let billitems = state.billitems;
        for (var props in allBills) {
          allBills[props].items = [];
        }
        for (var j = 0; j < billitems.length; j++) {
          if (allBills[billitems[j].billid]) {
            if (allBills[billitems[j].billid].items) {
              allBills[billitems[j].billid].items.push(billitems[j]);
            }
          }
        }
        return {
          ...state,
          bill: allBills
        };
      }
    }
    case 'DASH_COMPARE_NEW_BILLS': {
      if (action.data) {
        return {
          ...state,
          bill: action.data.bill,
          billitems: action.data.billitems
        };
      }
      return state;
    }
    case 'GET_BILLS_RECEIPT': {
      if (action.data) {
        return {
          ...state,
          billsReceipts: action.data.results.bill || {},
          billsRefund: action.data.results.refunds || []
        };
      } else {
        return {
          ...state,
          billsReceipts: state.billsReceipts || {},
          billsRefund: state.billsRefund || []
        };
      }
    }
    case 'CREATE_BILL_REFUND': {
      if (action.data) {
        state.billsRefund.push(action.data);
        return {
          ...state,
          billsRefund: state.billsRefund
        };
      }
      return state;
    }
    case 'UPDATE_REDUX_BILLS': {
      if (action.data) {
        let stateBills = state.bill;
        let stateBillItems = state.billitems;
        let bills = action.data;
        let billItems = [];
        for (var bill in stateBills) {
          for (var prop in bills) {
            if (prop === bill) {
              bills[prop].userid = action.data2;
              bills[prop].updateTimestamp =
                bills[prop].updateTimestamp > 0
                  ? bills[prop].updateTimestamp + 1
                  : 1;
              stateBills[bill] = bills[prop];
              bills[prop].items.forEach((item, index) => {
                item.userid = action.data2;
                item.updateTimestamp =
                  item.updateTimestamp > 0 ? item.updateTimestamp + 1 : 1;
                bills[prop].items[index] = item;
                billItems.push(item);
              });
            }
          }
        }
        var billToArray = [];
        for (var prop in stateBills) {
          billToArray.push(stateBills[prop]);
        }
        let objectBills = {};
        for (var i = 0; i < billToArray.length; i++) {
          objectBills[billToArray[i]._id] = billToArray[i];
        }
        for (var i = 0; i < stateBillItems.length; i++) {
          if (
            billItems.findIndex(item => item._id === stateBillItems[i]._id) ===
            -1
          ) {
            billItems.push(stateBillItems[i]);
          }
        }
        return {
          ...state,
          bill: objectBills,
          billitems: billItems
        };
      }
      return state;
    }
    case 'UPDATE_SYNC_REDUX_BILLS': {
      if (action.data) {
        let bills = action.data.bills;
        let billItems = state.billitems;
        let syncBills = state.syncBills;
        for (var prop in bills) {
          bills[prop].userid = action.data.userid;
          bills[prop].items = [];
          bills[prop].updateTimestamp =
            bills[prop].updateTimestamp > 0
              ? bills[prop].updateTimestamp + 1
              : 1;
          billItems.forEach(item => {
            if (item.billid === bills[prop]._id) {
              item.userid = action.data.userid;
              item.updateTimestamp =
                item.updateTimestamp > 0 ? item.updateTimestamp + 1 : 1;
              bills[prop].items.push(item);
            }
          });
        }
        if (Object.keys(bills).length > 0) {
          syncBills[action.data.userid] = bills;
          return {
            ...state,
            syncBills: syncBills
          };
        } else {
          return state;
        }
      }
      return state;
    }
    case 'CLEAR_SYNC_BILLS': {
      var preBills = {};
      for (var userId in state.syncBills) {
        preBills[userId] = { bills: state.syncBills[userId] };
      }
      return {
        ...state,
        preBills: preBills,
        syncBills: {}
      };
    }
    case 'CLEAR_PAYMENTS': {
      return {
        ...state,
        payments: []
      };
    }
    case 'GET_VOID_REASONS': {
      if (action.data) {
        return {
          ...state,
          voidReasons: action.data.results
        };
      } else {
        let voidReasons = state.voidReasons;
        return {
          ...state,
          voidReasons: voidReasons
        };
      }
    }
    case 'GET_PAYMENT_REASONS': {
      if (action.data) {
        return {
          ...state,
          paymentReasons: action.data.results
        };
      } else {
        let paymentReasons = state.paymentReasons;
        return {
          ...state,
          paymentReasons: paymentReasons
        };
      }
    }
    case 'ADD_PAYMENT': {
      if (action.data) {
        let payments = state.payments;
        payments.push(action.data.payment);
        return {
          ...state,
          payments: payments,
          paymentReasons: action.data.paymentReasons
        };
      }
      return state;
    }
    case 'CREATE_BILL': {
      if (action.new) {
        let bill = state.bill;
        let newBill = action.new;
        if (newBill.oldbillid) {
          let oldbillid = newBill.oldbillid;
          delete newBill.oldbillid;
          for (var prop in bill) {
            if (bill[prop]._id === oldbillid) {
              bill[prop].state = 'deleted';
              bill[prop].updateTimestamp =
                bill[prop].updateTimestamp > 0
                  ? bill[prop].updateTimestamp + 1
                  : 1;
            }
          }
        }
        newBill.updateTimestamp =
          newBill.updateTimestamp > 0 ? newBill.updateTimestamp + 1 : 1;
        let preBill = {};
        preBill[newBill._id] = newBill;
        for (var prop in bill) {
          preBill[prop] = bill[prop];
        }
        var billToArray = [];
        for (var prop in preBill) {
          billToArray.push(preBill[prop]);
        }
        let objectBills = {};
        for (var i = 0; i < billToArray.length; i++) {
          objectBills[billToArray[i]._id] = billToArray[i];
        }
        return {
          ...state,
          bill: objectBills,
          lastdevicebillno: newBill.orderno,
          lastbillno: newBill.orderno
        };
      }
      return state;
    }
    case 'UPDATE_BILL': {
      if (action.data) {
        let bill = state.bill;
        let billitems = state.billitems;
        if (action.data.items) {
          for (var k = 0; k < action.data.items.length; k++) {
            for (var j = 0; j < billitems.length; j++) {
              if (billitems[j]._id === action.data.items[k]._id) {
                billitems[j].split = action.data.items[k].split;
                billitems[j].qtyLeft = action.data.items[k].qtyLeft;
                billitems[j].updateTimestamp =
                  billitems[j].updateTimestamp > 0
                    ? billitems[j].updateTimestamp + 1
                    : 1;
              }
            }
          }
        }

        if (bill[action.data.bill._id]._id === action.data.bill._id) {
          for (var prop in action.data.bill) {
            bill[action.data.bill._id][prop] = action.data.bill[prop];
          }
          bill[action.data.bill._id].updateTimestamp =
            bill[action.data.bill._id].updateTimestamp > 0
              ? bill[action.data.bill._id].updateTimestamp + 1
              : 1;
          bill[action.data.bill._id].items = [];
          for (var j = 0; j < billitems.length; j++) {
            if (billitems[j].billid === bill[action.data.bill._id]._id) {
              bill[action.data.bill._id].items.push(billitems[j]);
            }
          }
        }
        var billToArray = [];
        for (var prop in bill) {
          billToArray.push(bill[prop]);
        }
        let objectBills = {};
        for (var i = 0; i < billToArray.length; i++) {
          objectBills[billToArray[i]._id] = billToArray[i];
        }
        return {
          ...state,
          bill: objectBills,
          billitems: billitems
        };
      }
      return state;
    }
    case 'UPDATE_BILL_PROC': {
      if (action.data) {
        let bill = state.bill;
        let billitems = state.billitems;
        if (bill[action.data.bill._id]._id === action.data.bill._id) {
          bill[action.data.bill._id].updateTimestamp =
            bill[action.data.bill._id].updateTimestamp > 0
              ? bill[action.data.bill._id].updateTimestamp + 1
              : 1;
          bill[action.data.bill._id].processing = false;
        }
        for (var j = 0; j < billitems.length; j++) {
          if (billitems[j].billid === action.data.bill._id) {
            billitems[j].updateTimestamp =
              billitems[j].updateTimestamp > 0
                ? billitems[j].updateTimestamp + 1
                : 1;
            billitems[j].processed = true;
            billitems[j].procount = 0;
          }
        }
        var billToArray = [];
        for (var prop in bill) {
          billToArray.push(bill[prop]);
        }
        let objectBills = {};
        for (var i = 0; i < billToArray.length; i++) {
          objectBills[billToArray[i]._id] = billToArray[i];
        }
        return {
          ...state,
          bill: objectBills,
          billitems: billitems
        };
      }
      return state;
    }
    case 'UPDATE_BILL_SPLIT': {
      if (action.data) {
        let bill = state.bill;
        let billitems = state.billitems;
        if (bill[action.data.bill._id]._id === action.data.bill._id) {
          for (var prop in action.data.bill) {
            bill[action.data.bill._id][prop] = action.data.bill[prop];
          }
          bill[action.data.bill._id].updateTimestamp =
            bill[action.data.bill._id].updateTimestamp > 0
              ? bill[action.data.bill._id].updateTimestamp + 1
              : 1;
        }
        if (action.data.items) {
          for (var k = 0; k < action.data.items.length; k++) {
            for (var j = 0; j < billitems.length; j++) {
              if (billitems[j]._id === action.data.items[k]._id) {
                billitems[j].state = action.data.items[k].state;
                billitems[j].qtyLeft = action.data.items[k].qtyLeft;
                billitems[j].updateTimestamp =
                  billitems[j].updateTimestamp > 0
                    ? billitems[j].updateTimestamp + 1
                    : 1;
              }
            }
          }
        }
        var billToArray = [];
        for (var prop in bill) {
          billToArray.push(bill[prop]);
        }
        let objectBills = {};
        for (var i = 0; i < billToArray.length; i++) {
          objectBills[billToArray[i]._id] = billToArray[i];
        }
        return {
          ...state,
          bill: objectBills,
          billitems: billitems
        };
      }
      return state;
    }
    case 'ADD_ITEM': {
      if (action.data) {
        let billitems = state.billitems;
        let bill = state.bill;
        let item = action.data;
        let itemIndex = 0;
        let stockQtyList = state.stockQtyList;
        var qty = 1;
        var qtyToAdd = item.qtyToAdd;
        item.initQty = item.initQty || 1;
        if (item.qtyToAdd > 0) {
          qty = item.qtyToAdd;
        }
        delete item.qtyToAdd;

        if (action.response) {
          if (action.response.results.stockQtyCheck) {
            var stockQtyCheck = action.response.results.stockQtyCheck;
            for (var si = 0; si < stockQtyCheck.length; si++) {
              if (stockQtyList[stockQtyCheck[si].id]) {
                stockQtyList[stockQtyCheck[si].id].qty = stockQtyCheck[si].qty;
              }
            }
          }
        }

        let exist = false;
        for (var j = 0; j < billitems.length; j++) {
          if (
            billitems[j].itemid === action.data.itemid &&
            billitems[j].billid === action.data.billid &&
            JSON.stringify(billitems[j].cookIns) ===
              JSON.stringify(action.data.cookIns) &&
            billitems[j].initQty === action.data.initQty &&
            billitems[j].price === action.data.price &&
            (billitems[j].openIns
              ? JSON.stringify(billitems[j].openIns)
              : 'same') ===
              (action.data.openIns
                ? JSON.stringify(action.data.openIns)
                : 'same')
          ) {
            exist = true;
            item = billitems[j];
            itemIndex = j;
            break;
          }
        }

        if (exist) {
          var procCount = qty;
          if (item.initQty && item.openQty) {
            procCount = qty / item.initQty;
          }

          item.qty = parseFloat((item.qty + qty).toFixed(2));
          item.qtyLeft = parseFloat((item.qtyLeft + qty).toFixed(2));
          item.procount = item.procount + procCount;
          item.sequence = new Date().getTime();
          item.updateTimestamp =
            item.updateTimestamp > 0 ? item.updateTimestamp + 1 : 1;
          item.processed = false;
          billitems[itemIndex] = item;
        } else {
          var procCount = qty;
          if (item.openQty) {
            procCount = qty / item.initQty;
          }

          item.active = true;
          item.state = 'open';
          item.qty = qty;
          item.qtyLeft = qty;
          item.date = moment().format('YYYYMMDDHHmm');
          item.procount = procCount;
          item.processed = false;
          item.sequence = new Date().getTime();
          item.create = new Date().getTime();
          item.updateTimestamp =
            item.updateTimestamp > 0 ? item.updateTimestamp + 1 : 1;
          billitems.unshift(item);
        }

        if (bill[action.data.billid]._id === action.data.billid) {
          bill[action.data.billid].total = parseFloat(
            (
              bill[action.data.billid].total +
              Math.round(item.price * qtyToAdd * 100) / 100
            ).toFixed(2)
          );

          bill[action.data.billid].amountLeft = parseFloat(
            (
              bill[action.data.billid].amountLeft +
              Math.round(item.price * qtyToAdd * 100) / 100
            ).toFixed(2)
          );
          bill[action.data.billid].updateTimestamp =
            bill[action.data.billid].updateTimestamp > 0
              ? bill[action.data.billid].updateTimestamp + 1
              : 1;
          bill[action.data.billid].processing = true;
        }
        let totalCheck = 0;
        let amountLeftCheck = 0;
        if (bill[action.data.billid]._id) {
          for (var bil = 0; bil < billitems.length; bil++) {
            if (
              billitems[bil].billid === bill[action.data.billid]._id &&
              billitems[bil].qty
            ) {
              totalCheck =
                totalCheck + billitems[bil].price * billitems[bil].qty;
            }
          }
          if (bill[action.data.billid].total !== totalCheck) {
            bill[action.data.billid].total = totalCheck;
            if (bill[action.data.billid].amountLeft !== totalCheck) {
              if (bill[action.data.billid].tip)
                amountLeftCheck =
                  amountLeftCheck + bill[action.data.billid].tip;
              if (bill[action.data.billid].discount)
                amountLeftCheck =
                  amountLeftCheck + bill[action.data.billid].discount;
              if (bill[action.data.billid].cash)
                amountLeftCheck =
                  amountLeftCheck + bill[action.data.billid].cash;
              if (bill[action.data.billid].card)
                amountLeftCheck =
                  amountLeftCheck + bill[action.data.billid].card;
              if (bill[action.data.billid].tab)
                amountLeftCheck =
                  amountLeftCheck + bill[action.data.billid].tab;
              if (bill[action.data.billid].yoco)
                amountLeftCheck =
                  amountLeftCheck + bill[action.data.billid].yoco;
              bill[action.data.billid].amountLeft =
                totalCheck - amountLeftCheck;
            }
          }
        }
        return {
          ...state,
          bill: bill,
          billitems: billitems,
          stockQtyList: stockQtyList
        };
      }
      return state;
    }
    case 'REMOVE_ITEM': {
      if (action.data) {
        //let stockQtyList = state.stockQtyList;
        let billitems = state.billitems;
        let bill = state.bill;
        let stockQtyList = state.stockQtyList;
        let item;
        let itemIndex;

        if (action.response) {
          if (action.response.results.stockQtyCheck) {
            var stockQtyCheck = action.response.results.stockQtyCheck;
            for (var si = 0; si < stockQtyCheck.length; si++) {
              if (stockQtyList[stockQtyCheck[si].id]) {
                stockQtyList[stockQtyCheck[si].id].qty = stockQtyCheck[si].qty;
              }
            }
          }
        }

        for (var j = 0; j < billitems.length; j++) {
          if (
            billitems[j].itemid === action.data.itemid &&
            billitems[j].billid === action.data.billid &&
            JSON.stringify(billitems[j].cookIns) ===
              JSON.stringify(action.data.cookIns) &&
            billitems[j].initQty === action.data.initQty &&
            billitems[j].price === action.data.price &&
            (billitems[j].openIns
              ? JSON.stringify(billitems[j].openIns)
              : 'same') ===
              (action.data.openIns
                ? JSON.stringify(action.data.openIns)
                : 'same')
          ) {
            item = billitems[j];
            itemIndex = j;
            break;
          }
        }
        //stockQtyList[item.stockId].qty - 1;
        item.initQty = item.initQty || 1;
        item.qty = parseFloat((item.qty - item.initQty).toFixed(2));
        item.qtyLeft = parseFloat((item.qtyLeft - item.initQty).toFixed(2));
        item.updateTimestamp =
          item.updateTimestamp > 0 ? item.updateTimestamp + 1 : 1;

        if (item.procount > 0) {
          item.procount = item.procount - 1;
          if (!item.procount) {
            item.processed = true;
            let processingCheck = false;
            for (var bil = 0; bil < billitems.length; bil++) {
              if (billitems[bil].billid === bill[action.data.billid]._id) {
                if (!billitems[bil].processed) {
                  processingCheck = true;
                }
              }
            }
            bill[action.data.billid].processing = processingCheck;
          }
        }
        billitems[itemIndex] = item;

        if (bill[action.data.billid]._id === action.data.billid) {
          bill[action.data.billid].total = parseFloat(
            (
              bill[action.data.billid].total -
              Math.round(item.price * item.initQty * 100) / 100
            ).toFixed(2)
          );

          bill[action.data.billid].amountLeft = parseFloat(
            (
              bill[action.data.billid].amountLeft -
              Math.round(item.price * item.initQty * 100) / 100
            ).toFixed(2)
          );
          bill[action.data.billid].updateTimestamp =
            bill[action.data.billid].updateTimestamp > 0
              ? bill[action.data.billid].updateTimestamp + 1
              : 1;
        }
        return {
          ...state,
          bill: bill,
          billitems: billitems,
          stockQtyList: stockQtyList
        };
      }
      return state;
    }
    case 'VOID_ITEM': {
      if (action.data) {
        let billitems = state.billitems;
        let bill = state.bill;
        let item;
        for (var j = 0; j < billitems.length; j++) {
          if (
            billitems[j].itemid === action.data.itemid &&
            billitems[j].billid === action.data.billid &&
            JSON.stringify(billitems[j].cookIns) ===
              JSON.stringify(action.data.cookIns) &&
            billitems[j].initQty === action.data.initQty &&
            billitems[j].price === action.data.price &&
            (billitems[j].openIns
              ? JSON.stringify(billitems[j].openIns)
              : 'same') ===
              (action.data.openIns
                ? JSON.stringify(action.data.openIns)
                : 'same')
          ) {
            item = billitems[j];
            break;
          }
        }
        item.initQty = item.initQty || 1;
        item.void = true;
        item.qty = item.qty - action.data.voidQty;
        item.qtyLeft = item.qtyLeft - action.data.voidQty;
        item.updateTimestamp =
          item.updateTimestamp > 0 ? item.updateTimestamp + 1 : 1;
        if (item.voidReasons) {
          var newVoidQty = action.data.voidQty;
          if (action.data.initQty) {
            newVoidQty = action.data.voidQty / action.data.initQty;
          }
          for (var i = 0; i < newVoidQty; i++) {
            item.voidReasons.push({
              reason: action.data.reason,
              waste: action.data.waste
            });
          }
        } else {
          item.voidReasons = [];
          var newVoidQty = action.data.voidQty;
          if (action.data.initQty) {
            newVoidQty = action.data.voidQty / action.data.initQty;
          }
          for (var i = 0; i < newVoidQty; i++) {
            item.voidReasons.push({
              reason: action.data.reason,
              waste: action.data.waste
            });
          }
        }
        for (var j = 0; j < billitems.length; j++) {
          if (
            billitems[j].itemid === action.data.itemid &&
            billitems[j].billid === action.data.billid &&
            JSON.stringify(billitems[j].cookIns) ===
              JSON.stringify(action.data.cookIns) &&
            billitems[j].initQty === action.data.initQty &&
            billitems[j].price === action.data.price &&
            (billitems[j].openIns
              ? JSON.stringify(billitems[j].openIns)
              : 'same') ===
              (action.data.openIns
                ? JSON.stringify(action.data.openIns)
                : 'same')
          ) {
            billitems[j] = item;
            break;
          }
        }
        if (bill[action.data.billid]._id === action.data.billid) {
          bill[action.data.billid].total = parseFloat(
            (
              bill[action.data.billid].total -
              (Math.round(item.price * item.initQty * 100) / 100) *
                action.data.voidQty
            ).toFixed(2)
          );

          bill[action.data.billid].amountLeft = parseFloat(
            (
              bill[action.data.billid].amountLeft -
              (Math.round(item.price * item.initQty * 100) / 100) *
                action.data.voidQty
            ).toFixed(2)
          );
          bill[action.data.billid].updateTimestamp =
            bill[action.data.billid].updateTimestamp > 0
              ? bill[action.data.billid].updateTimestamp + 1
              : 1;
        }
        var billToArray = [];
        for (var prop in bill) {
          billToArray.push(bill[prop]);
        }
        let objectBills = {};
        for (var i = 0; i < billToArray.length; i++) {
          objectBills[billToArray[i]._id] = billToArray[i];
        }
        return {
          ...state,
          bill: objectBills,
          billitems: billitems
        };
      }
      return state;
    }
    case 'REMOVE_BILL': {
      if (action.data) {
        let bill = state.bill;
        if (bill[action.data.billid]._id === action.data.billid) {
          bill[action.data.billid].updateTimestamp =
            bill[action.data.billid].updateTimestamp > 0
              ? bill[action.data.billid].updateTimestamp + 1
              : 1;
          bill[action.data.billid].state = 'deleted';
        }
        var billToArray = [];
        for (var prop in bill) {
          billToArray.push(bill[prop]);
        }
        let objectBills = {};
        for (var i = 0; i < billToArray.length; i++) {
          objectBills[billToArray[i]._id] = billToArray[i];
        }
        return {
          ...state,
          bill: objectBills
        };
      }
      return state;
    }
    case 'REMOVE_SYNCDATA': {
      let lastState = {
        lastbillno: state.lastbillno,
        menu: state.menu,
        searchMenu: state.searchMenu,
        cooking: state.cooking,
        subCategories: state.subCategories,
        customers: state.customers,
        specials: state.specials,
        stockQtyList: state.stockQtyList,
        syncBills: state.syncBills,
        preBills: state.preBills,
        voidReasons: state.voidReasons,
        paymentReasons: state.paymentReasons,
        payments: state.payments
      };
      return {
        ...initialState,
        ...lastState
      };
    }
    default:
      return state;
  }
};
