import React from 'react';
import Button from 'src/components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = props => {
  const {
    head,
    msg,
    formElements,
    open,
    close,
    actionBtn,
    closeBtn,
    onClick,
    fullWidth,
    maxWidth
  } = props;

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{head}</DialogTitle>
        <DialogContent>
          <DialogContentText>{msg}</DialogContentText>
          {formElements &&
            formElements.map((elem, key) => <div key={key}>{elem}</div>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            {closeBtn ? closeBtn : 'Cancel'}
          </Button>
          <Button onClick={onClick} color="primary">
            {actionBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
