export const initialState = {
  loading: false,
  info: null,
  error: null,
  success: null,
  downloadProgress: 0,
  themeMode: "dark"
}

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case 'SWITCH_LIGHT_DARK': {
      if (action.data) {
        return {
          ...state,
          themeMode: action.data,
        };
      }
    }
    case "STATUS_REPLACE": {
      return {
        ...state,
        downloadProgress: action.downloadProgress || 0,
        loading: action.loading || false,
        info: action.info || null,
        error: action.error || null,
        success: action.success || null,
      }
    }
    default:
      return state
  }
}
