import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import customSelectStyle from 'src/assets/jss/material-dashboard-react/components/customSelectStyle.jsx';
import FormHelperText from '@material-ui/core/FormHelperText';

const CustomSelect = memo(({ ...props }) => {
  const {
    classes,
    formControlProps,
    noMargin,
    labelText,
    id,
    labelProps,
    inputProps,
    innerInputProps,
    menuItems,
    error,
    errorText,
    success
  } = props;

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined && !noMargin
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        classes={{
          root: marginTop,
          disabled: classes.disabled
        }}
        name={id}
        {...inputProps}
        input={
          <Input
            classes={{
              underline: underlineClasses
            }}
            {...innerInputProps}
          />
        }
      >
        {menuItems ? menuItems.map((items, key) => (
          <MenuItem key={key} value={items.value || items._id}>
            {items.name}
          </MenuItem>
        )): null}
      </Select>
      {error ? (
        <FormHelperText error={error}>{errorText}</FormHelperText>
    ) : success ? (
        <FormHelperText>{success}</FormHelperText>
    ) : null}
    </FormControl>
  );
}
);

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  menuItems: PropTypes.array,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};

export default withStyles(customSelectStyle)(CustomSelect);
