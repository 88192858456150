import $ from 'jquery';
import moment from 'moment';
const ObjectID = require('bson-objectid');
const apiCall = 'vpos/api/vpos/';
const server = process.env.REACT_APP_GLOBAL_SERVER;

export function switchLightDark(type) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'SWITCH_LIGHT_DARK',
          data: type
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function compareBills(data) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'DASH_COMPARE_NEW_BILLS',
          data: data
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function resetSync() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'REMOVE_SYNCDATA'
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function clearSyncBills() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'CLEAR_SYNC_BILLS'
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function clearPayments() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'CLEAR_PAYMENTS'
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function setPreBills(user) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'ASSIGN_PREBILLS',
          data: user
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function setAdminLogin() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'ADMIN_LOGIN'
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function updateReduxBills(bills, userid) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (userid) {
        return resolve(
          dispatch({
            type: 'UPDATE_REDUX_BILLS',
            data: bills,
            data2: userid
          })
        );
      } else {
        return resolve(
          dispatch({
            type: 'UPDATE_REDUX_BILLS',
            data: bills
          })
        );
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function updateSyncReduxBills(bills, userid) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'UPDATE_SYNC_REDUX_BILLS',
          data: { bills: bills, userid: userid }
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function updateReduxSearchMenu(items) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'UPDATE_REDUX_MENU',
          data: items
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function getStockItems(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'stockitems/getstockitemsdash',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addStockAdjust(state, token) {
  let { name, type, desc, oStockitems } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!type) return reject({ message: 'Select Reason' });
      // if (!desc) return reject({ message: 'Enter Note' });
      var aStockItems = [];
      var count = 0;
      Object.keys(oStockitems).forEach(function(key) {
        if (oStockitems[key].select) {
          var newObject = {};
          newObject._id = oStockitems[key]._id;
          newObject.name = oStockitems[key].name;
          newObject.amount = oStockitems[key].amount;
          if (type === 'Stock Count') {
            newObject.diff = oStockitems[key].amount
              ? (oStockitems[key].amount || 0) - (oStockitems[key].qty || 0)
              : oStockitems[key].qty;
          }
          newObject.dim = oStockitems[key].dimType
            ? oStockitems[key].dimType
            : oStockitems[key].dimension;
          newObject.qty = oStockitems[key].qty;
          newObject.type = oStockitems[key].type;
          newObject.newamount = oStockitems[key].newamount;
          aStockItems.push(newObject);
          count = count + 1;
        }
      });
      if (count === 0) return reject({ message: 'Selected Stock To Adjust' });

      const formDetails = {
        name: name,
        type: type,
        desc: desc,
        stockitems: aStockItems
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'stockaudit/addstockauditdash',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getMenuDash(token, showFav) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'GET_MENU'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCall + 'menu/getmenudash',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'GET_MENU',
                data: data,
                showFav: showFav
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'GET_MENU'
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function getMenuDashPreload(token, showFav) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'GET_MENU'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCall + 'menu/getmenudashpreload',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'GET_MENU',
                data: data,
                showFav: showFav
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'GET_MENU'
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function getBillDashPreload(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'GET_BILL_PRELOAD'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCall + 'menu/getbilldashpreload',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'GET_BILL_PRELOAD',
                data: data
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'GET_BILL_PRELOAD'
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function getAddMenuItemData(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'GET_ADDMENUITEMDATA'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCall + 'menu/getaddmenuitemdata',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'GET_ADDMENUITEMDATA',
                data: data
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'GET_ADDMENUITEMDATA'
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function getBillDash(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'GET_BILLS'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCall + 'bill/getbilldash',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'GET_BILLS',
                data: data
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'GET_BILLS'
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function getBillReceiptDash(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'GET_BILLS_RECEIPT'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCall + 'bill/getbillreceiptdash',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'GET_BILLS_RECEIPT',
                data: data
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'GET_BILLS_RECEIPT'
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function createBillRefundDash(token, newbill) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'CREATE_BILL_REFUND',
            data: newbill
          })
        );
      } else {
        $.ajax({
          type: 'POST',
          url: server + apiCall + 'refund/addbillrefunddash',
          data: JSON.stringify(newbill),
          contentType: 'application/json',
          dataType: 'json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'CREATE_BILL_REFUND',
                data: data.results
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            if (xhr.responseText) {
              return reject({ message: xhr.responseText });
            } else {
              return reject({ message: 'No Connection Found!' });
            }
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function createBillDash(token, newbill, oldbillid) {
  if (oldbillid) {
    newbill.oldbillid = oldbillid;
  }
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!window.offline) {
        $.ajax({
          type: 'POST',
          url: server + apiCall + 'bill/addbilldash',
          data: JSON.stringify(newbill),
          contentType: 'application/json',
          dataType: 'json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'CREATE_BILL',
                data: data.results
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            if (xhr.responseText) {
              return reject({ message: xhr.responseText });
            } else {
              return reject({ message: 'No Connection Found!' });
            }
          }
        });
      } else {
        return resolve(false);
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function createBillDashPlatform(token, state, userid, oldbillid) {
  let {
    billType,
    billDesc,
    billTable,
    billPpl,
    billCusId,
    address,
    customerNumber,
    customerName,
    orderno
  } = state;

  var newbill = {
    _id: ObjectID().toHexString(),
    userid: userid,
    orderno: orderno,
    table: billTable,
    type: billType,
    peopleCount: billPpl,
    desc: billDesc,
    custId: billCusId,
    active: true,
    state: 'open',
    items: [],
    total: 0.0,
    amountLeft: 0.0,
    tip: 0.0,
    discount: 0.0,
    cash: 0.0,
    card: 0.0,
    tab: 0.0,
    payed: 0.0,
    date: moment().format('YYYYMMDDHHmm'),
    moddate: moment().format('YYYYMMDDHHmm')
  };
  if (billCusId) {
    newbill.tabTrans = false;
  }
  if (address) {
    newbill.address = address;
    newbill.customerName = customerName;
    newbill.customerNumber = customerNumber;
  }
  if (oldbillid) {
    newbill.oldbillid = oldbillid;
  }
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/addbilldashplat',
        data: JSON.stringify(newbill),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          // return resolve(
          //   dispatch({
          //     type: 'CREATE_BILL',
          //     data: data.results
          //   })
          // );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          // return resolve(
          //   dispatch({
          //     type: 'CREATE_BILL',
          //     new: newbill
          //   })
          // );
        }
      });
      return resolve(
        dispatch({
          type: 'CREATE_BILL',
          new: newbill
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function updateBillDash(token, bill, state, items) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var form = {
        bill: bill,
        items: items
      };
      if (bill._id.length < 24 || window.offline) {
        return resolve(
          dispatch({
            type: 'UPDATE_BILL',
            data: form
          })
        );
      } else {
        $.ajax({
          type: 'POST',
          url: server + apiCall + 'bill/updatebilldash',
          data: JSON.stringify(form),
          contentType: 'application/json',
          dataType: 'json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'UPDATE_BILL',
                data: form
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'UPDATE_BILL',
                data: form
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function updateBillProcDash(token, bill, kitchen) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var form = {
        bill: bill,
        kitchen: kitchen
      };
      return resolve(
        dispatch({
          type: 'UPDATE_BILL_PROC',
          data: form
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function updateBillSplitChargeDash(token, bill, items, state) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      delete bill.key;
      var form = {
        bill: bill,
        items: items,
        email: state
      };
      return resolve(
        dispatch({
          type: 'UPDATE_BILL_SPLIT',
          data: form
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function addToBillDash(token, item, billid, qty, userid, sync) {
  let {
    itemid,
    code,
    name,
    cookIns,
    desc,
    price,
    type,
    printto,
    initQty,
    openQty,
    useDim,
    useDimType,
    openIns
  } = item;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!desc) {
        desc = '';
      }
      const formDetails = {
        _id: ObjectID().toHexString(),
        userid: userid,
        billid: billid,
        itemid: itemid,
        name: name,
        code: code,
        price: price,
        type: type,
        cookIns: cookIns,
        desc: desc,
        printto: printto,
        qtyToAdd: qty,
        initQty: initQty || 1
      };
      if (openIns && openIns.length) {
        formDetails.openIns = openIns;
      }
      if (openQty) {
        formDetails.openQty = openQty;
      }
      if (useDim) {
        formDetails.useDim = useDim;
        formDetails.useDimType = useDimType ? useDimType : '';
      }
      return resolve(
        dispatch({
          type: 'ADD_ITEM',
          data: formDetails,
          response: { results: { ok: 1 } }
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function addToBillDashOnline(token, item, billid, qty, userid) {
  let {
    itemid,
    name,
    cookIns,
    desc,
    price,
    type,
    printto,
    initQty,
    openQty,
    useDim,
    useDimType,
    openIns
  } = item;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!desc) {
        desc = '';
      }

      const formDetails = {
        _id: ObjectID().toHexString(),
        userid: userid,
        billid: billid,
        itemid: itemid,
        name: name,
        price: price,
        type: type,
        cookIns: cookIns,
        desc: desc,
        printto: printto,
        qtyToAdd: qty,
        initQty: initQty || 1
      };
      if (openIns && openIns.length) {
        formDetails.openIns = openIns;
      }
      if (openQty) {
        formDetails.openQty = openQty;
      }
      if (useDim) {
        formDetails.useDim = useDim;
        formDetails.useDimType = useDimType ? useDimType : '';
      }

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'billitems/addtobilldash',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: 'ADD_ITEM',
              data: formDetails,
              response: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          return reject('Failed to add items');
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function removeFromBillDash(token, item, billid, sync) {
  let { itemid, name, price, type, cookIns, openIns, initQty } = item;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        billid: billid,
        itemid: itemid,
        name: name,
        price: price,
        type: type,
        cookIns: cookIns,
        initQty: initQty || 1
      };
      if (openIns && openIns.length) {
        formDetails.openIns = openIns;
      }
      // if (window.offline || sync) {
      return resolve(
        dispatch({
          type: 'REMOVE_ITEM',
          data: formDetails
        })
      );
      // } else {
      //   $.ajax({
      //     type: 'POST',
      //     url: server + apiCall + 'billitems/removefrombilldash',
      //     data: JSON.stringify(formDetails),
      //     contentType: 'application/json',
      //     dataType: 'json',
      //     crossDomain: true,
      //     beforeSend: function(xhr) {
      //       xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      //     },
      //     success: async data => {
      //       return resolve(
      //         dispatch({
      //           type: 'REMOVE_ITEM',
      //           data: formDetails,
      //           response: data
      //         })
      //       );
      //     },
      //     error: async (xhr, ajaxOptions, thrownError) => {
      //       return resolve(
      //         dispatch({
      //           type: 'REMOVE_ITEM',
      //           data: formDetails
      //         })
      //       );
      //     }
      //   });
      // }
    }).catch(async err => {
      throw err.message;
    });
}

export function voidBillItem(token, item, state, showKitch) {
  let {
    itemid,
    name,
    price,
    type,
    waste,
    voidQty,
    cookIns,
    openIns,
    initQty
  } = item;
  let { billid, selectedVoidReason } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        billid: billid,
        itemid: itemid,
        name: name,
        price: price,
        type: type,
        reason: selectedVoidReason,
        waste: waste,
        voidQty: voidQty,
        cookIns: cookIns,
        showKitch: showKitch,
        initQty: initQty || 1
      };
      if (openIns && openIns.length) {
        formDetails.openIns = openIns;
      }
      // if (window.offline) {
      return resolve(
        dispatch({
          type: 'VOID_ITEM',
          data: formDetails
        })
      );
      // } else {
      //   $.ajax({
      //     type: 'POST',
      //     url: server + apiCall + 'billitems/voidbillitem',
      //     data: JSON.stringify(formDetails),
      //     contentType: 'application/json',
      //     dataType: 'json',
      //     crossDomain: true,
      //     timeout: 5000,
      //     beforeSend: function(xhr) {
      //       xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      //     },
      //     success: async data => {
      //       return resolve(
      //         dispatch({
      //           type: 'VOID_ITEM',
      //           data: formDetails
      //         })
      //       );
      //     },
      //     error: async (xhr, ajaxOptions, thrownError) => {
      //       return resolve(
      //         dispatch({
      //           type: 'VOID_ITEM',
      //           data: formDetails
      //         })
      //       );
      //     }
      //   });
      // }
    }).catch(async err => {
      throw err.message;
    });
}

export function removeBillDash(token, state) {
  let { billid } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        billid: billid
      };
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'REMOVE_BILL',
            data: formDetails
          })
        );
      } else {
        $.ajax({
          type: 'POST',
          url: server + apiCall + 'bill/removebilldash',
          data: JSON.stringify(formDetails),
          contentType: 'application/json',
          dataType: 'json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'REMOVE_BILL',
                data: formDetails
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'REMOVE_BILL',
                data: formDetails
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function syncData(token, syncData) {
  let refunds = JSON.parse(JSON.stringify(syncData));
  if (syncData.billsRefund && syncData.billsRefund.length) {
    let arrayRefunds = [];
    for (var j = 0; j < refunds.billsRefund.length; j++) {
      if (!refunds.billsRefund[j]._id) {
        arrayRefunds.push(refunds.billsRefund[j]);
      }
    }
    refunds.billsRefund = arrayRefunds;
  }
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/syncdata',
        data: JSON.stringify(refunds),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve();
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getVoidReasons(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'GET_VOID_REASONS'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCall + 'voidreasons/getvoidreasons',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'GET_VOID_REASONS',
                data: data
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'GET_VOID_REASONS'
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function getPaymentReasons(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'GET_PAYMENT_REASONS'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCall + 'paymentreasons/getpaymentreasons',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'GET_PAYMENT_REASONS',
                data: data
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'GET_PAYMENT_REASONS'
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function addExpensePayment(token, values, paymentReasons) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      values.transactionType = 'Cash Payment';
      if (window.offline) {
        return resolve(
          dispatch({
            type: 'ADD_PAYMENT',
            data: {
              payment: values,
              paymentReasons: paymentReasons
            }
          })
        );
      } else {
        $.ajax({
          type: 'POST',
          url: server + apiCall + 'expense/addexpensepayment',
          data: JSON.stringify({
            payment: values,
            paymentReasons: paymentReasons
          }),
          contentType: 'application/json',
          dataType: 'json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            return resolve(
              dispatch({
                type: 'ADD_PAYMENT',
                response: data
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            return resolve(
              dispatch({
                type: 'ADD_PAYMENT',
                data: {
                  payment: values,
                  paymentReasons: paymentReasons
                }
              })
            );
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

//*********KITCHEN**********/

export function getBillKitchen(token, billCount, role) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      let collection = 'kitchen';
      if (role && role.isBar) {
        collection = 'bar';
      }
      var form = {
        billCount: billCount
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + collection + '/getbillkitchen',
        data: JSON.stringify(form),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateBillKitchen(token, bill, role) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      let collection = 'kitchen';
      if (role && role.isBar) {
        collection = 'bar';
      }
      $.ajax({
        type: 'POST',
        url: server + apiCall + collection + '/updatebillkitchen',
        data: JSON.stringify(bill),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function setLoggedInFalse() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'SET_LOGGEDIN_FALSE',
          data: ''
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}
