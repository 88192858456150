/* eslint-disable no-loop-func */
import { Button, Col, Layout, notification, Radio, Row } from 'antd';
import { InfoWindow, Map, Marker } from 'google-maps-react';
import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/views/Customer/customer-styles.css';
const apiCall = 'vpos/api/customer/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Content } = Layout;
const MyMapComponent = props => {
  const markerRef = useRef();
  return (
    <Map
      google={window.google}
      style={{
        width: '100%'
      }}
      initialCenter={{
        lat: props.selectedCompany.location.coordinates[1],
        lng: props.selectedCompany.location.coordinates[0]
      }}
      zoom={16}
      disableDefaultUI={true}
    >
      <Marker
        ref={markerRef}
        position={{
          lat: props.selectedCompany.location.coordinates[1],
          lng: props.selectedCompany.location.coordinates[0]
        }}
        id={1}
      />
      <InfoWindow visible={true} marker={markerRef}>
        <div>
          <p>{props.selectedCompany.address}</p>
        </div>
      </InfoWindow>
    </Map>
  );
};
const changeView = view => {
  setView(view.target.value);
};

let view = 'menu';
let setView;
const CompanyMenu = props => {
  [view, setView] = useState(view);
  useEffect(() => {
    props.createCart(
      props.selectedCompany.orgid,
      props.user._id,
      props.selectedCompany.name,
      props.selectedCompany.average
    );
    $.ajax({
      type: 'GET',
      url:
        server +
        apiCall +
        'getcompanymenu?orgid=' +
        props.selectedCompany.orgid,
      dataType: 'json',
      crossDomain: true,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
      },
      success: async data => {
        props.setMenu(data.results);
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          notification.error({
            message: 'Error',
            description: xhr.responseText
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Error retrieving list'
          });
        }
      }
    });
    return () => {};
  }, []);
  return (
    <Content style={{ height: 'calc(100vh-80px)', background: 'white' }}>
      <Row
        span={24}
        style={{
          background: 'white',
          position: 'sticky',
          top: '1em',
          zIndex: 2
        }}
      >
        <Col span={24}>
          <div>
            <div
              style={{
                boxShadow: 'inset  0 -85px 80px -6px  white',
                position: 'relative',
                width: '100%',

                marginBottom: '-7em',
                height: '310px',
                overflow: 'hidden',
                fontFamily: 'Nunito',
                display: 'flex',
                backgroundPosition: '40% center',
                backgroundSize: 'cover',
                backgroundImage:
                  'url(' +
                  process.env.REACT_APP_GLOBAL_SERVER +
                  props.selectedCompany.mainImage +
                  ')'
              }}
            >
              {/*<img
                style={{ margin: "auto" }}
                src={
                  process.env.REACT_APP_GLOBAL_SERVER +
                  props.selectedCompany.mainImage
                }
                alt="logo"
              />*/}
            </div>
            <div
              style={{
                lineHeight: '20px',
                position: 'relative',
                backgroundColor: 'rgba(255, 255, 255, 0.055)',
                backdropFilter: 'blur(2.5px)',
                width: '100%',
                margin: 'auto',
                border: '0px solid#ffffff46',
                marginBottom: '1em',
                textAlign: 'Center',
                fontFamily: 'Nunito',
                borderRadius: '0em',
                paddingTop: 20,
                paddingBottom: 10,
                paddingLeft: 30,
                paddingRight: 30
              }}
            >
              <h1 style={{ fontWeight: 700 }}>{props.selectedCompany.name}</h1>
              <p
                style={{
                  fontSize: '11px',
                  color: '#383838'
                }}
              >
                {props.selectedCompany.categories.map((category, index) => {
                  return (
                    <span key={index}>
                      {index + 1 !== props.selectedCompany.categories.length
                        ? category + ','
                        : category}{' '}
                    </span>
                  );
                })}
              </p>{' '}
              <p
                style={{
                  fontSize: '11px',
                  color: '#383838',
                  marginTop: '-1em'
                }}
              >
                {(props.selectedCompany.distance / 1000).toFixed(1)}km away
              </p>
            </div>
          </div>
        </Col>

        <Col span={24}>
          <Radio.Group
            id="RadioSelect"
            value={view}
            onChange={e => changeView(e)}
            style={{ width: '100%' }}
            buttonStyle="solid"
          >
            <Radio.Button
              style={{
                paddingTop: '0.2em',
                textAlign: 'center',
                width: '50%',
                height: '2.5em',
                borderRadius: 0,
                border: 'none',
                color: 'grey',
                backgroundColor: 'transparent'
              }}
              value="menu"
            >
              Menu
            </Radio.Button>
            <Radio.Button
              style={{
                paddingTop: '0.2em',
                textAlign: 'center',
                width: '50%',
                height: '2.5em',
                color: 'grey',
                borderRadius: 0,
                backgroundColor: 'transparent',
                border: 'none'
              }}
              value="info"
            >
              Info
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {view === 'menu' ? (
        <div
          style={{
            height: '50vh',
            backgroundColor: 'rgba(214, 214, 214, 0.123)'
          }}
        >
          <Row
            style={{ marginTop: '1em', paddingBottom: 70 }}
            type="flex"
            justify="space-around"
          >
            {props.menu
              ? props.menu.menu.menubtns.map((button, index) => {
                  // if (button.delivery && button.active)
                  return (
                    <Col key={index} xs={12} md={12} style={{ padding: 0 }}>
                      <Button
                        onClick={e => props.selectMenu(button)}
                        style={{
                          border: 'none',
                          boxShadow: '3px 2px 8px -2px rgba(232,232,232,1)',
                          borderRadius: '0em',
                          width: '100%',
                          marginLeft: '-0em',
                          background: 'white',
                          height: '4em',
                          fontWeight: 700,
                          fontSize: '15px'
                        }}
                      >
                        {button.name}
                      </Button>
                    </Col>
                  );
                })
              : null}
          </Row>
        </div>
      ) : (
        <div
          style={{
            height: '50vh',
            backgroundColor: 'rgba(214, 214, 214, 0.123)'
          }}
        >
          <Row style={{ paddingBottom: 70 }}>
            <Col style={{ margin: 10, marginTop: 30, lineHeight: '15px' }}>
              <h2
                style={{
                  fontWeight: 700,
                  fontSize: '18px',
                  fontFamily: 'Nunito'
                }}
              >
                {props.selectedCompany.name}
              </h2>
              <p>{props.selectedCompany.description}</p>
              <p>
                <span
                  style={{
                    fontWeight: 700,
                    fontFamily: 'Nunito'
                  }}
                >
                  Address :{' '}
                </span>
                <span>{props.selectedCompany.address}</span>
              </p>
              <p>
                <span
                  style={{
                    marginTop: '-0.5em',
                    fontWeight: 700,
                    fontFamily: 'Nunito'
                  }}
                >
                  Phone :{' '}
                </span>
                <span>
                  <a href={'tel:' + props.selectedCompany.mobile}>
                    {props.selectedCompany.mobile.substr(0, 3) +
                      ' ' +
                      props.selectedCompany.mobile.substr(3, 3) +
                      ' ' +
                      props.selectedCompany.mobile.substr(6, 4)}
                  </a>
                </span>
              </p>
            </Col>
            <Row
              style={{
                height: 200,
                borderRadius: 10,
                margin: 10,
                overflow: 'hidden'
              }}
            >
              <MyMapComponent selectedCompany={props.selectedCompany} />
            </Row>
          </Row>
        </div>
      )}
    </Content>
  );
};

export default CompanyMenu;
