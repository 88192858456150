export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.addEventListener('message', (e, s) => {
    // eslint-disable-line no-restricted-globals
    if (!e) return;
    var sIp = e.data.sIp;
    var pSize = e.data.size;
    var receipt = [];
    try {
      if (pSize === 'small') {
        if (sIp.head && sIp.heading) {
          if (sIp.heading.length > 0) {
            if (typeof sIp.heading === 'string') {
              var heading = sIp.heading.split('\n');
              receipt = heading;
            } else {
              for (var head = 0; head < sIp.heading.length; head++) {
                var preText = '{left}';
                if (!head) {
                  preText = '';
                }
                var postText = '';
                if (sIp.heading[head].bold) {
                  preText += '{b}';
                  postText += '{/b}';
                }
                if (sIp.heading[head].size) {
                  preText += '{h}{w}';
                  postText += '{/h}{/w}';
                }
                receipt.push(preText + sIp.heading[head].text + postText)
              }
            }
          }
        }
        receipt.push('-------------------------------\n');
        receipt.push('{left}Invoice # : 00010 0101\n');
        if (sIp.desc) {
          receipt.push('{left}Desc: Test Print \n');
        }
        receipt.push('{left}Qty Item               Price\n');
        receipt.push('-------------------------------\n');
        receipt.push('\n');
        receipt.push('-------------------------------\n');
        if (sIp.vat) {
          receipt.push('{left}VAT: R 0.00 \n');
        }
        receipt.push('{left}Discount: R0.00 \n');
        receipt.push('{left}{b}Total:R0.00{/b} \n');
        if (sIp.subs) {
          receipt.push('{left}' + '-------------------------------\n');
          receipt.push('{left}' + '\n');
          receipt.push('{left}' + 'Gratuity :___________________\n');
          receipt.push('{left}' + '\n');
          receipt.push('{left}' + 'Total Due:___________________\n');
          receipt.push('{left}' + '\n');
        }
        if (sIp.foot && sIp.footer) {
          if (sIp.footer.length > 0) {
            if (typeof sIp.footer === 'string') {
              var footer = sIp.footer.split('\n');
              for (var i = 0; i < footer.length; i++) {
                receipt.push(footer[i]);
              }
            } else {
              for (var foot = 0; foot < sIp.footer.length; foot++) {
                var preText = '{left}';
                var postText = '';
                if (sIp.footer[foot].bold) {
                  preText += '{b}';
                  postText += '{/b}';
                }
                if (sIp.footer[foot].size) {
                  preText += '{h}{w}';
                  postText += '{/h}{/w}';
                }
                receipt.push(preText + sIp.footer[foot].text + postText)
              }
            }
          }
        }
      } else {
        if (sIp.head && sIp.heading) {
          if (sIp.heading.length > 0) {
            if (typeof sIp.heading === 'string') {
              var heading = sIp.heading.split('\n');
              receipt = heading;
            } else {
              for (var head = 0; head < sIp.heading.length; head++) {
                var preText = '{left}';
                var postText = '';
                if (sIp.heading[head].bold) {
                  preText += '{b}';
                  postText += '{/b}';
                }
                if (sIp.heading[head].size) {
                  preText += '{h}{w}';
                  postText += '{/h}{/w}';
                }
                receipt.push(preText + sIp.heading[head].text + postText)
              }
            }
          }
        }
        receipt.push('----------------------------------------\n');
        receipt.push('{left}Invoice # : 00010 0101\n');
        if (sIp.desc) {
          receipt.push('{left}Desc: Test Print \n');
        }
        receipt.push('{left}Item                        Price\n');
        receipt.push('----------------------------------------\n');
        receipt.push('\n');
        receipt.push('----------------------------------------\n');
        if (sIp.vat) {
          receipt.push('{left}VAT: R 0.00 \n');
        }
        receipt.push('{left}Discount: R0.00 \n');
        receipt.push('{left}{b}Total:R0.00{/b} \n');
        if (sIp.subs) {
          receipt.push('{left}' + '----------------------------------------\n');
          receipt.push('{left}' + '\n');
          receipt.push('{left}' + 'Gratuity :___________________\n');
          receipt.push('{left}' + '\n');
          receipt.push('{left}' + 'Total Due:___________________\n');
          receipt.push('{left}' + '\n');
        }
        if (sIp.foot && sIp.footer) {
          if (sIp.footer.length > 0) {
            if (typeof sIp.footer === 'string') {
              var footer = sIp.footer.split('\n');
              for (var i = 0; i < footer.length; i++) {
                receipt.push(footer[i]);
              }
            } else {
              for (var foot = 0; foot < sIp.footer.length; foot++) {
                var preText = '{left}';
                var postText = '';
                if (sIp.footer[foot].bold) {
                  preText += '{b}';
                  postText += '{/b}';
                }
                if (sIp.footer[foot].size) {
                  preText += '{h}{w}';
                  postText += '{/h}{/w}';
                }
                receipt.push(preText + sIp.footer[foot].text + postText)
              }
            }
          }
        }
      }
      for (var i = 0; i < 4; i++) {
        receipt.push('\n');
      }
      var data = {
        aData: receipt,
        logo: sIp.logo,
        sIp: sIp.printbill,
        cash: true
      };
      postMessage(data);
    } catch (error) {
      var data = {
        error: error
      };
      postMessage(data);
    }
  });
};
