import { Button, Col, Drawer, Icon, Input, Layout, Menu, Row } from 'antd';

import React, { memo } from 'react';
import { FiPower } from 'react-icons/fi';
import menuIcon from 'src/static/menu-icon.png';
import logo from 'src/static/vpos-logo-white1.png';

import styled from 'styled-components';
const { Header, Content } = Layout;
const { SubMenu } = Menu;
const { Search } = Input;
const StyledSearch = styled(Search)`
  .ant-input {
    border: 1px solid rgba(15, 135, 123, 0.725);
    border-radius: 0.4em;
    color: black !important;
  }
  .ant-input::placeholder {
    opacity: 0.4;
    color: grey;
  }
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
  }
`;
const DashHead = ({ ...oState }) => {
  return (
    <div>
      <Header
        className="kitchen-header-mobile"
        style={{
          top: 0,
          height: 40,
          lineHeight: '40px',
          paddingLeft: 5,
          paddingRight: 5,
          backgroundColor: 'rgb(17, 20, 26)',
          zIndex: 101,
          position: 'fixed',
          width: '100vw',
          padding: '0 50px',
          boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.205)',
          marginBottom: 8
        }}
      >
        <Row style={{ height: 40 }}>
          <Col span={1} style={{ display: 'flex', height: '100%' }}>
            <img
              style={{
                position: 'absolute',
                height: '34px',
                top: '0.2em',
                filter: 'brightness(100)',
                left: '-3em',
                margin: 'auto',
                paddingLeft: '0em',
                cursor: 'pointer'
              }}
              src={menuIcon}
              alt="Logo"
              onClick={oState.toggleCollapsed}
            />{' '}
            <img
              style={{
                margin: 'auto',
                height: '25px',
                top: '0.535em'
              }}
              src={logo}
              alt="Logo"
            />
          </Col>
          <Col span={3} />
          <Col span={10} />
          <Col span={5} style={{ display: 'flex' }} />
          <p
            style={{
              position: 'absolute',
              right: '6.7em',
              color: 'white',
              fontSize: 16,
              fontWeight: '600'
            }}
          >
            {oState.props.user.name}
          </p>
          <StyledDrawer
            placement="left"
            closable={false}
            drawerStyle={{ backgroundColor: '#132440be', zIndex: 101 }}
            visible={oState.state.collapsed}
            style={{ top: '40px' }}
            onClose={oState.onCloseDrawer}
          >
            <Button
              type="primary"
              onClick={oState.endSession}
              style={{
                marginBottom: 0,
                width: '100%',
                height: '40px',
                backgroundColor: 'rgb(200, 57, 57)',
                borderRadius: '3em'
              }}
            >
              <Icon type="logout" />
              End Session
            </Button>
          </StyledDrawer>
        </Row>
        <Button
          type="primary"
          onClick={oState.switchToDash}
          style={{
            position: 'absolute',
            right: '4em',
            borderRadius: '3em',
            color: 'white',
            top: 3,
            height: '35px'
          }}
        >
          <Icon type="swap" /> Sales
        </Button>
        <FiPower
          className="logout-btn"
          style={{
            position: 'absolute',
            right: '5px',
            margin: 'auto',
            fontSize: 35,
            top: '3px',
            padding: '0.2em',
            borderRadius: '3em',
            backgroundColor: 'white',
            color: ' rgb(19, 36, 64)'
          }}
          onClick={e => {
            if (oState.state.offline) {
              oState.onError(
                'You can not log out while offline. Please restore the connection first.'
              );
            } else {
              oState.onLogout();
            }
          }}
        />
      </Header>
      <Row
        style={{
          height: 40,
          width: '100vw',
          maxWidth: '40em',
          textAlign: 'center',
          margin: 'auto',
          top: '45px',
          position: 'absolute',
          zIndex: 100
        }}
      >
        <Col span={6}>
          <Button
            className="kitchen-button-mobile"
            style={{
              backgroundColor: 'rgb(17, 20, 26)',
              color: 'white',
              fontWeight: 700,
              width: '95%',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '3em'
            }}
            onClick={() => oState.onFilterClick('open')}
          >
            {' '}
            Pending{' '}
            {oState.state.opencount > 0
              ? '(' + oState.state.opencount + ')'
              : ''}
          </Button>{' '}
        </Col>{' '}
        <Col span={6}>
          <Button
            className="kitchen-button-mobile"
            style={{
              backgroundColor: 'rgb(17, 20, 26)',
              color: 'white',
              fontWeight: 700,
              width: '95%',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '3em'
            }}
            onClick={() => oState.onFilterClick('proc')}
          >
            Busy
            {oState.state.proccount > 0
              ? '(' + oState.state.proccount + ')'
              : ''}
          </Button>
        </Col>{' '}
        <Col span={6}>
          <Button
            className="kitchen-button-mobile"
            style={{
              backgroundColor: 'rgb(17, 20, 26)',
              color: 'white',
              fontWeight: 700,
              width: '95%',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '3em'
            }}
            onClick={() => oState.onFilterClick('comp')}
          >
            Completed{' '}
            {oState.state.compcount > 0
              ? '(' + oState.state.compcount + ')'
              : ''}
          </Button>
        </Col>{' '}
        <Col span={6}>
          <Button
            className="kitchen-button-mobile"
            style={{
              backgroundColor: 'rgb(17, 20, 26)',
              color: 'white',
              fontWeight: 700,
              width: '95%',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '3em'
            }}
            onClick={() => oState.onFilterClick('void')}
          >
            Rejected{' '}
            {oState.state.voidcount > 0
              ? '(' + oState.state.voidcount + ')'
              : ''}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
const DashHeader = memo(DashHead);
export default DashHeader;
