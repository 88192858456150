import IconButton from '@material-ui/core/IconButton';
import { Button, Col, Icon, Input, Row, Select, Table } from 'antd';
import React from 'react';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelectAuto from 'src/components/CustomSelectAuto/CustomSelectAuto.jsx';
import StyledTableNoPagi from 'src/components/Table/StyledTableNoPagi';
const { Column } = Table;
const InputGroup = Input.Group;
const { Option } = Select;
const TableBody = ({ ...oState }) => {
  return (
    <StyledTableNoPagi
      style={{ background: 'white' }}
      rowKey="_id"
      size="middle"
      pagination={false}
      dataSource={oState.state.items}
    >
      <Column
        title="Name"
        render={(text, record, key) => (
          <Row>
            <Col span={9}>
              <CustomSelectAuto
                selectedItem={record.name}
                onChange={(value, event) =>
                  oState.handleChangeSelect(value, event, key)
                }
                labelText="Item Search"
                id="Menuitemcode"
                items={oState.state.selectitems}
                formControlProps={{
                  style: { padding: 0, margin: 0, marginBottom: '1em' },
                  fullWidth: true,
                  onClick: () => oState.onHelpMarker('menuitemsstockitem')
                }}
              />
            </Col>
            {oState.state.items.length === key + 1 ? (
              <div>
                <Col
                  span={2}
                  style={{ paddingTop: '25px', textAlign: 'center' }}
                >
                  {' '}
                  Or{' '}
                </Col>
                <Col
                  span={11}
                  style={{ paddingTop: '20px', whiteSpace: 'nowrap' }}
                >
                  <InputGroup compact>
                    <Select
                      value={oState.state.catSelect.value}
                      placeholder="Add to Category"
                      style={{ width: '55%' }}
                      onChange={e => oState.handleChangeSelectCat(e, key)}
                    >
                      {oState.state.catList &&
                        oState.state.catList.map((items, mkey) => {
                          return (
                            <Option key={key} value={items.value}>
                              {items.name}
                            </Option>
                          );
                        })}
                    </Select>
                    <Input
                      type="number"
                      placeholder="Discount"
                      value={oState.state.catSelect.perc}
                      onChange={e => oState.handleChangePercCat(e)}
                      style={{ width: '50%' }}
                      suffix={'%'}
                    />
                    <Button
                      style={{
                        fontWeight: 700,
                        fontFamily: 'Mulish , sans-serif',
                        fontSize: '13px',
                        marginLeft: '0.8em',
                        textTransform: 'initial',
                        color: 'white',
                        width: '5em',
                        padding: '0.35em',
                        borderRadius: '3em',
                        backgroundColor: 'rgb(0, 204, 112)',
                        border: '0px solid rgb(0, 204, 112)'
                      }}
                      onClick={oState.handleChangeAddCat}
                    >
                      Add
                    </Button>
                  </InputGroup>
                </Col>
              </div>
            ) : null}
          </Row>
        )}
        dataIndex="name"
        key="name"
      />
      <Column
        title="Special Price"
        width="25vw"
        align="center"
        render={(text, record, key) => (
          <div>
            {record.name ? (
              <CustomInput
                labelText=""
                id="price"
                formControlProps={{
                  style: { maxWidth: '15em' },
                  fullWidth: true
                }}
                autoFocus={record.price <= 0 && record.name ? true : false}
                inputProps={{
                  style: { padding: 0, marginTop: '-1em' },
                  onChange: e => oState.onChangeEvtMenuPrice(e, key),
                  value: record.price,
                  onClick: () => oState.onHelpMarker('menuitemsqty')
                }}
              />
            ) : null}
          </div>
        )}
        dataIndex="price"
        key="price"
      />
      <Column
        title="Action"
        width="10vw"
        align="center"
        render={(text, record, key) => (
          <div>
            {record.name ? (
              <IconButton
                style={{
                  marginRight: '0em',
                  marginLeft: '0em',
                  borderRadius: '4em',
                  backgroundColor: 'rgba(229, 233, 242, 0.9)',
                  border: '1px solid rgba(229, 233, 242, 1)'
                }}
                onClick={() => oState.handleRemoveMenuItem(key)}
              >
                {
                  <Icon
                    type="delete"
                    style={{
                      fontSize: 15
                    }}
                  />
                }
              </IconButton>
            ) : null}
          </div>
        )}
      />
    </StyledTableNoPagi>
  );
};

export default TableBody;
