import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Card as AntCard,
  Icon,
  Row,
  Spin,
  Table,
  ConfigProvider,
  Empty,
  Button,
  Col,
  Statistic,
  Tooltip,
  DatePicker,
  Select
} from 'antd';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import { FaCoins, FaWallet } from 'react-icons/fa';
import {
  AiFillCreditCard,
  AiOutlineMail,
  AiOutlinePrinter
} from 'react-icons/ai';

import { BsSubtract } from 'react-icons/bs';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CustButton from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  RiDatabase2Line,
  RiDeleteBin6Line,
  RiHandCoinLine
} from 'react-icons/ri';

import {
  doCashup,
  getUserCashupTotal,
  getUsersCashup,
  doCashupReset,
  emailCashup,
  emailCashupTotals,
  getUsersCashupHistory,
  getUserCashupTotalMulti,
  getBillsCashup
} from 'src/redux/actions/backend';
import { setHelpMarker } from 'src/redux/actions/help';
import { openSnackbar, closeSnackbar } from 'src/redux/actions/snackbar';
import StyledTable from 'src/components/Table/StyledTable';
import styled, { css } from 'styled-components';
import Loading from 'react-loading-bar';
import ContentLoader from 'react-content-loader';
import CashupBills from './CashupBills';
import CashupRefundBills from './CashupRefundBills';
import CashupVoidBills from './CashupVoidBills';
import CashupTotalVoidBills from './CashupTotalVoidBills';
import CashupCashPayments from './CashupCashPayments';
import CashupCardPayments from './CashupCardPayments';
import CashupCount from './CashupCount';
import moment from 'moment';
import { PrintJS } from 'src/config/PrintScript';

import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import { Tabs } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

const StyledStatistic = styled(Statistic)`
  .ant-statistic-title {
    white-space: nowrap;
    position: relative;
    top: -10px;
    box-shadow: 0 6px 4px -2px rgb(220, 225, 238);
    margin-bottom: -0.7em;
    padding-bottom: 1em;
    border-bottom: 5px solid grey;
    color: black;
    font-size: 19px;
    font-weight: 600;
  }
`;
const StyledCheckbox = styled(Checkbox)`
  ${props => {
    if (props.disabled) {
      return css`
        transform: scale(0.4);
        background-color: rgb(0 204 112 / 100%) !important;
        color: rgb(0 204 112 / 0%) !important;
      `;
    } else {
      return css`
        transform: scale(0.4);
        background-color: red !important;
        color: red !important;
      `;
    }
  }}
  padding:0 !important;
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    border-bottom: 0px solid #e8e8e8;
    margin: 0 0px 20px 0 !important;
    outline: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-tab {
    font-size: 16px !important;
    margin-left: 0.5em !important;
    width: 8em !important;
    border-radius: 3em !important;
    padding: 0px !important;
    height: 45px;
    color: #00cc70 !important;
    background: white !important;
    border: 1px solid #00cc70 !important;
  }
  .ant-tabs-tab-active {
    width: 8em !important;
    border-radius: 3em !important;
    padding: 0px !important;
    height: 45px;
    color: white !important;
    background: #00cc70 !important;
    border: 1px solid #00cc70 !important;
  }
`;
const { TabPane } = Tabs;
const { Column } = Table;

const StyledButton = styled(Button)`
  .ant-btn:hover .ant-btn:focus .ant-btn:active .ant-btn.active {
    border-radius: 3em;
    color: white !important;
    background-color: #132440 !important;
    border-color: #132440;
  }
`;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'inline'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'whitesmoke',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  },
  datePicker: {
    marginRight: '10px',
    height: '2.5em',
    '& span': {
      height: '45px'
    }
  }
});

const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';

const Skeleton = () => (
  <ContentLoader
    speed={0.5}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#c0c0c0"
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="46" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="92" rx="2" ry="2" width="100%" height="45" />
  </ContentLoader>
);

const Inputs = ({ ...oState }) => {
  var length = oState.state.inputCount - 1;
  var instruc = 'Reason ';
  var inputs = [];
  for (let i = 0; i < oState.state.inputCount; i++) {
    inputs.push(
      <div key={'divinputs' + i}>
        <GridContainer key={'gridinputs' + i}>
          <GridItem xs={6} sm={6} md={6}>
            <CustomInput
              labelText={instruc + (i + 1) + '*'}
              id={instruc + i}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeName(e, i),
                value: oState.state.cashouts[i].name || ''
              }}
            />
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
            <CustomInput
              labelText={
                <span
                  style={{
                    color: 'black',
                    fontSize: '17px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {' '}
                  Amount{' '}
                </span>
              }
              id={'p' + i}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: 'number',
                onChange: e => oState.onChangeEvtPrice(e, i),
                value: oState.state.cashouts[i].price || ''
              }}
            />
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
            <CustButton
              style={{
                fontSize: '20px',
                width: '10px',
                height: '10px',
                top: '40px'
              }}
              onClick={e => oState.removeInput(i)}
              className={oState.props.classes.buttonAddInputCooking}
              color="primary"
            >
              -
            </CustButton>
          </GridItem>
          {/* <GridItem xs={2} sm={2} md={2}>
            <CustButton
              style={{ fontSize: '20px', width: '10px', height: '10px', top: '40px', left:' -30px' }}
              onClick={() => oState.addInput()}
              className={oState.props.classes.buttonAddInputCooking}
              color="primary"
            >
              +
            </CustButton>
            </GridItem> */}
        </GridContainer>
      </div>
    );
  }
  return inputs;
};

class Cashup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user: {},
      totals: {},
      cashouts: [],
      printer: {},
      mails: {},
      inputCount: 0,
      pettycash: 0,
      pickerStart: moment().startOf('month'),
      pickerEnd: moment(),
      selectAll: false,
      showChashupButton: false,
      main: true,
      showBills: false,
      showVoidBills: false,
      showRefundBills: false,
      cashup: false,
      cashupCount: false,
      cashCountValue: 0,
      showCashCountInput: false,
      loading: true,
      alert: false,
      pdopen: false,
      changeView: true,
      msg: [],
      head: '',
      datePicker: false,
      todayCashupTotal: {
        total: 0,
        cash: 0,
        card: 0,
        tab: 0,
        discount: 0,
        tip: 0,
        void: 0,
        refund: 0,
        paidRefund: 0,
        cardPayments: 0,
        cashPayments: 0,
        acc: 0
      }
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.getUsers(false, 0);
  }

  getUsers = (msg, number, custom) => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    this.props
      .getUsersCashup(token, number, custom)
      .then(result => {
        this.handleRemoveBreadCrumb();
        result.data.results.todayCashup.forEach((cashup, index) => {
          cashup.user.history = true;
          cashup.user.timestamp = cashup.timestamp;
          cashup.user._id = index;
          cashup.user.totals = cashup.totals;
          result.data.results.users.push(cashup.user);
        });
        this.setState({
          user: {},
          totals: {},
          main: true,
          showBills: false,
          showVoidBills: false,
          showRefundBills: false,
          cashup: false,
          users: result.data.results.users,
          usersHist: result.data.results.usersHist,
          printer: result.data.results.printer,
          mails: result.data.results.mails || {},
          todayCashup: result.data.results.todayCashup,
          todayCashupTotal: result.data.results.todayCashupTotal,
          loading: false,
          changeView: true,
          cashupCount: false,
          cashCountValue: 0,
          showCashCountInput: false,
          cashouts: [],
          inputCount: 0,
          pettycash: 0,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  getReports = (start, end) => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    this.props
      .getUsersCashupHistory(token, start, end)
      .then(result => {
        this.setState({
          usersHist: result.data.results.usersHist,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'day') {
        this.setState({
          datePicker: false
        });
        this.getUsers(false, 0);
      } else if (value === 'yester') {
        this.setState({
          datePicker: false
        });
        this.getUsers(false, 1);
      } else if (value === 'seven') {
        this.setState({
          datePicker: false
        });
        this.getUsers(false, 7);
      } else if (value === 'thir') {
        this.setState({
          datePicker: false
        });
        this.getUsers(false, 30);
      } else if (value === 'showcustom') {
        this.setState({
          datePicker: true
        });
      } else if (value === 'custom') {
        let date = this.state.pickerStart;
        let days = date.diff(moment(), 'days');
        days = Math.abs(days);
        this.getUsers(false, days, true);
      }
    }
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        pickerStart: value
      });
    }
  };

  printTotalsCashup = email => {
    var receipt = [];

    receipt.push('{B}2{S}  Cashup Totals\n');
    receipt.push(' \n');
    receipt.push('Date - ' + moment().format('YYYY/MM/DD HH:mm') + '\n');
    receipt.push('\n');
    receipt.push('{B}Card Sales \n');
    receipt.push('\n');
    receipt.push(
      '{B}                     R' +
        this.state.todayCashupTotal.card.toFixed('2') +
        ' \n'
    );
    if (this.state.todayCashupTotal.yoco) {
      receipt.push('\n');
      receipt.push('{B}Yoco Sales \n');
      receipt.push('\n');
      receipt.push(
        '{B}                     R' +
          this.state.todayCashupTotal.yoco.toFixed('2') +
          ' \n'
      );
    }
    receipt.push('\n');
    receipt.push('{B}Cash Sales \n');
    receipt.push('\n');
    receipt.push(
      '{B}                     R' +
        this.state.todayCashupTotal.cash.toFixed('2') +
        ' \n'
    );
    receipt.push('\n');
    receipt.push('{B}Voids Totals \n');
    receipt.push('\n');
    receipt.push(
      '{B}                     R' +
        this.state.todayCashupTotal.void.toFixed('2') +
        ' \n'
    );
    receipt.push('\n');
    receipt.push('{B}Tab Totals \n');
    receipt.push('\n');
    receipt.push(
      '{B}                     R' +
        this.state.todayCashupTotal.tab.toFixed('2') +
        ' \n'
    );
    receipt.push('\n');
    receipt.push('{B}Grand Total \n');
    receipt.push('\n');
    receipt.push(
      '{B}                     R' +
        this.state.todayCashupTotal.total.toFixed('2') +
        ' \n'
    );
    receipt.push('\n');

    receipt.push(' \n');
    receipt.push(' \n');
    receipt.push(' \n');
    receipt.push(' \n');
    receipt.push(' \n');
    receipt.push(' \n');

    if (email) {
      this.emailCashupTotals(receipt);
    } else {
      let printersInfo = JSON.parse(JSON.stringify(this.state.printer));
      let printer = {};
      if (
        printersInfo &&
        printersInfo.printers &&
        printersInfo.printers.length
      ) {
        for (var i = 0; i < printersInfo.printers.length; i++) {
          if (
            printersInfo.printers[i].printList.includes('Cashup') &&
            printersInfo.printers[i].address
          ) {
            printer = printersInfo.printers[i];
            break;
          }
        }
      }
      if (printer.address) {
        if (printer.type === 'ethernet') {
          this.toPrinter(receipt, printer.address, printer.model);
        } else {
          this.toPrinterBT(receipt, printer.address);
        }
      } else {
        this.onError('No Bill Printer found');
      }
    }
  };

  processBillSpace = value => {
    if (value.toString().length === 13) {
      return '';
    }
    if (value.toString().length === 12) {
      return ' ';
    }
    if (value.toString().length === 11) {
      return '  ';
    }
    if (value.toString().length === 10) {
      return '   ';
    }
    if (value.toString().length === 9) {
      return '    ';
    }
    if (value.toString().length === 8) {
      return '     ';
    }
    if (value.toString().length === 7) {
      return '      ';
    }
    return '       ';
  };

  processValueSpace = value => {
    if (value.toString().length === 9) {
      return '        ';
    }
    if (value.toString().length === 8) {
      return '         ';
    }
    if (value.toString().length === 7) {
      return '          ';
    }
    if (value.toString().length === 6) {
      return '           ';
    }
    if (value.toString().length === 5) {
      return '            ';
    }
    if (value.toString().length === 4) {
      return '             ';
    }
    return '      ';
  };

  printCashup = email => {
    var receipt = [];
    receipt.push('{B}2{S}  Cashup Summary\n');
    receipt.push(this.props.local.user.orgname + '\n');
    receipt.push('\n');
    receipt.push('Cashier name: ' + this.state.user.name + ' \n');
    receipt.push('\n');
    receipt.push('{B}Session\n');
    receipt.push(
      'Shift started : ' +
        (this.state.user.session
          ? this.state.user.session.start.slice(0, 4) +
            '-' +
            this.state.user.session.start.slice(4, 6) +
            '-' +
            this.state.user.session.start.slice(6, 8) +
            ' ' +
            this.state.user.session.start.slice(8, 10) +
            ':' +
            this.state.user.session.start.slice(10, 12)
          : ' ') +
        '\n'
    );
    receipt.push(
      'Shift ended : ' +
        (this.state.user.session
          ? this.state.user.session.end.slice(0, 4) +
            '-' +
            this.state.user.session.end.slice(4, 6) +
            '-' +
            this.state.user.session.end.slice(6, 8) +
            ' ' +
            this.state.user.session.end.slice(8, 10) +
            ':' +
            this.state.user.session.end.slice(10, 12)
          : ' ') +
        '\n'
    );
    var startTime = this.state.user.session.start;
    var endTime = this.state.user.session.end;
    var s = moment
      .duration(
        moment(endTime, 'YYYYMMDDHHmm').diff(moment(startTime, 'YYYYMMDDHHmm'))
      )
      .asSeconds();
    var duration = moment.duration(s, 'seconds');
    var workHours = duration
      .toString()
      .replace('PT', '')
      .replace('H', ':')
      .split('M')[0];
    if (workHours.search('S') > -1) {
      workHours = '00:00';
    } else if (workHours.length === 1) {
      workHours = '00:0' + workHours;
    } else if (workHours.length === 2) {
      workHours = '00:' + workHours;
    }
    receipt.push('-------------------------------\n');
    receipt.push('{B}Total hours: ' + workHours + '\n');
    receipt.push('-------------------------------\n');
    receipt.push('\n');
    if (this.state.totals.card) {
      receipt.push('{B}1{S}Card Sales \n');
      receipt.push('Inv#                      Bill     \n');
      this.state.user.bills.forEach(bill => {
        if (bill.card > 0) {
          receipt.push(
            bill.orderno +
              this.processBillSpace(bill.orderno) +
              // this.processValueSpace(bill.card.toFixed('2')) +
              //bill.card.toFixed(2) +
              // this.processValueSpace(bill.tip.toFixed('2')) +
              //bill.tip.toFixed(2) +
              this.processValueSpace(bill.card.toFixed('2')) +
              bill.card.toFixed(2) +
              ' \n'
          );
        }
      });
      receipt.push('              -----------------\n');
      receipt.push(
        '             ' +
          // this.processValueSpace(this.state.totals.card.toFixed('2')) +
          //this.state.totals.card.toFixed('2') +
          // this.processValueSpace(this.state.totals.tip.toFixed('2')) +
          //this.state.totals.tip.toFixed('2') +
          this.processValueSpace(this.state.totals.card.toFixed(2)) +
          this.state.totals.card.toFixed(2) +
          ' \n'
      );
      receipt.push('              -----------------\n');
    }
    if (this.state.totals.yoco) {
      receipt.push('{B}1{S}Yoco Sales \n');
      receipt.push('Inv#                      Bill     \n');
      this.state.user.bills.forEach(bill => {
        if (bill.yoco > 0) {
          receipt.push(
            bill.orderno +
              this.processBillSpace(bill.orderno) +
              // this.processValueSpace(bill.yoco.toFixed('2')) +
              //bill.yoco.toFixed(2) +
              // this.processValueSpace(bill.tip.toFixed('2')) +
              //bill.tip.toFixed(2) +
              this.processValueSpace(bill.yoco.toFixed('2')) +
              bill.yoco.toFixed(2) +
              ' \n'
          );
        }
      });
      receipt.push('              -----------------\n');
      receipt.push(
        '             ' +
          // this.processValueSpace(this.state.totals.yoco.toFixed('2')) +
          //this.state.totals.yoco.toFixed('2') +
          // this.processValueSpace(this.state.totals.tip.toFixed('2')) +
          //this.state.totals.tip.toFixed('2') +
          this.processValueSpace(this.state.totals.yoco.toFixed(2)) +
          this.state.totals.yoco.toFixed(2) +
          ' \n'
      );
      receipt.push('              -----------------\n');
    }
    if (this.state.totals.cash) {
      receipt.push('{B}1{S}Cash Sales \n');
      receipt.push('Inv#                      Bill     \n');
      this.state.user.bills.forEach(bill => {
        if (bill.cash > 0) {
          receipt.push(
            bill.orderno +
              this.processBillSpace(bill.orderno) +
              // this.processValueSpace(bill.cash.toFixed('2')) +
              //bill.cash.toFixed(2) +
              // this.processValueSpace(bill.tip.toFixed('2')) +
              //bill.tip.toFixed(2) +
              this.processValueSpace(bill.cash.toFixed('2')) +
              bill.cash.toFixed(2) +
              ' \n'
          );
        }
      });
      receipt.push('              -----------------\n');
      receipt.push(
        '             ' +
          // this.processValueSpace(this.state.totals.cash.toFixed('2')) +
          //this.state.totals.cash.toFixed('2') +
          // this.processValueSpace(this.state.totals.tip.toFixed('2')) +
          //this.state.totals.tip.toFixed('2') +
          this.processValueSpace(this.state.totals.cash.toFixed(2)) +
          this.state.totals.cash.toFixed(2) +
          ' \n'
      );
      receipt.push('              -----------------\n');
    }
    receipt.push('\n');
    receipt.push('              -----------------\n');
    receipt.push(
      'Totals       ' +
        // this.processValueSpace(
        //   (
        //     this.state.totals.cash +
        //     this.state.totals.card +
        //     this.state.totals.yoco
        //   ).toFixed(2)
        // ) +
        // (
        //   this.state.totals.cash +
        //   this.state.totals.card +
        //   this.state.totals.yoco
        // ).toFixed(2) +
        // this.processValueSpace(this.state.totals.tip.toFixed(2)) +
        // this.state.totals.tip.toFixed(2) +
        this.processValueSpace(
          (
            this.state.totals.cash +
            this.state.totals.card +
            this.state.totals.yoco
          ).toFixed(2)
        ) +
        (
          this.state.totals.cash +
          this.state.totals.card +
          this.state.totals.yoco
        ).toFixed(2) +
        '\n'
    );
    receipt.push('              -----------------\n');
    receipt.push('\n');
    receipt.push('\n');
    receipt.push('_____________________________\n');
    receipt.push('Manager Signature\n');
    receipt.push('\n');
    receipt.push('\n');
    receipt.push('_____________________________\n');
    receipt.push('Waiter Signature\n');
    receipt.push('\n');
    receipt.push('\n');
    receipt.push('Note - Managers are responsible for\n');
    receipt.push('counting the card sale\n');
    receipt.push('\n');
    receipt.push('\n');
    receipt.push('\n');
    receipt.push('\n');
    debugger;
    if (email) {
      this.emailCashup(receipt);
    } else {
      let printersInfo = JSON.parse(JSON.stringify(this.state.printer));
      let printer = {};
      if (
        printersInfo &&
        printersInfo.printers &&
        printersInfo.printers.length
      ) {
        for (var i = 0; i < printersInfo.printers.length; i++) {
          if (
            printersInfo.printers[i].printList.includes('Cashup') &&
            printersInfo.printers[i].address
          ) {
            printer = printersInfo.printers[i];
            break;
          }
        }
      }
      if (printer.address) {
        if (printer.type === 'ethernet') {
          this.toPrinter(receipt, printer.address, printer.model);
        } else {
          this.toPrinterBT(receipt, printer.address);
        }
      } else {
        this.onError('No Bill Printer found');
      }
    }
  };

  toPrinterBT = (receipt, ip) => {
    var aData = receipt;
    var sIp = ip;
    if (window.device) {
      if (
        window.device.platform === 'Android' ||
        window.device.platform === 'iOS'
      ) {
        window.DatecsPrinter.connect(
          sIp,
          function() {
            var printmsg = '';
            for (var i = 0; i < aData.length; i++) {
              aData[i] = aData[i].replace('\\n', '{br}');
              aData[i] = aData[i].replace('\n', '{br}');
              aData[i] = aData[i].replace('{B}1{S}', '');
              aData[i] = aData[i].replace('{B}2{S}', '');
              printmsg += aData[i];
            }
            var spacing = 5;
            for (var j = 0; j < spacing.length; j++) {
              aData.push('{br}');
            }
            window.DatecsPrinter.printText(
              printmsg,
              'ISO-8859-1',
              function() {}
            );
          },
          function(error) {
            alert(error);
          }
        );
      }
    }
  };

  toPrinter = (receipt, ip, model) => {
    var aData = receipt;
    var sIp = ip;
    if (window.device) {
      if (
        window.device.platform === 'Android' ||
        window.device.platform === 'iOS'
      ) {
        if (window.device.platform === 'Android' && model === 'impact') {
          for (var i = 0; i < aData.length; i++) {
            aData[i] = aData[i].replace('\\n', '\n');
            if (aData[i].search('{B}') > -1) {
              aData[i] = `<b>${aData[i].replace('{B}', '')}</b>`;
            }
            if (aData[i].search('3{S}') > -1) {
              aData[i] = `<font size='big'>${aData[i].replace(
                '3{S}',
                ''
              )}</font>`;
            }
            if (aData[i].search('2{S}') > -1) {
              aData[i] = `<font size='big'>${aData[i].replace(
                '2{S}',
                ''
              )}</font>`;
            }
            aData[i] = aData[i].replace('1{S}', '');
            aData[i] = aData[i].replace('{L}', '');
          }
          aData.push(' \n');
          aData.push(' \n');
          aData.push(' \n');
          aData.push(' \n');
          aData.push(' \n');
          window.ThermalPrinter.printFormattedTextAndCut(
            {
              type: 'tcp',
              address: sIp,
              port: 9100,
              id: sIp,
              text: aData.toString().replace(/,/g, ''),
              printerWidthMM: 80
            },
            function() {
              window.ThermalPrinter.disconnectPrinter(
                {
                  type: 'tcp',
                  address: sIp,
                  port: 9100,
                  id: sIp
                },
                function() {},
                function(error) {
                  alert(error);
                }
              );
            },
            function(error) {
              window.ThermalPrinter.disconnectPrinter(
                {
                  type: 'tcp',
                  address: sIp,
                  port: 9100,
                  id: sIp
                },
                function() {
                  alert(error);
                },
                function(error) {
                  alert(error);
                }
              );
            }
          );
        } else {
          var printReprat = 1;
          const sendToPrinter = () => {
            window.epos2.connectPrinter(
              sIp,
              function() {
                for (var i = 0; i < aData.length; i++) {
                  aData[i] = aData[i].replace('\\n', '\n');
                }
                window.epos2.print(
                  aData,
                  function(output) {
                    window.epos2.disconnectPrinter();
                  },
                  function(error) {
                    alert(error);
                  }
                );
              },
              function(error) {
                alert(error);
              }
            );
          };
          sendToPrinter();
        }
      } else {
        PrintJS(aData, sIp, false, function(error, msg) {
          if (error) {
            alert(error);
          }
        });
      }
    } else {
      PrintJS(aData, sIp, false, function(error, msg) {
        if (error) {
          alert(error);
        }
      });
    }
  };

  emailCashup = receipt => {
    const token = this.props.local.user.token;
    this.props
      .emailCashup(token, receipt)
      .then(result => {
        if (result.data.results === 'NoEmail') {
          this.onError('Cashup Email Not Found');
        } else {
          alert('Cashup Email Sent');
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  emailCashupTotals = receipt => {
    const token = this.props.local.user.token;
    this.props
      .emailCashupTotals(token, receipt)
      .then(result => {
        if (result.data.results === 'NoEmail') {
          this.onError('Cashup Email Not Found');
        } else {
          alert('Cashup Email Sent');
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleOpenTable = open => {
    if (open) {
      this.props.handleOpenTableUser();
    } else {
      this.props.handleCloseTableUser();
    }
  };

  addInput = () => {
    var cashouts = this.state.cashouts;
    cashouts.push({
      name: '',
      price: 0
    });
    this.setState({
      inputCount: this.state.inputCount + 1,
      cashouts: cashouts
    });
  };

  removeInput = eN => {
    var e = eN;
    var cashouts = this.state.cashouts;
    var pettycash = this.state.pettycash;
    var price = cashouts[e].price;
    pettycash = pettycash - price;
    if (this.state.inputCount > 0) {
      cashouts.splice(e, 1);
      this.setState({
        inputCount: this.state.inputCount - 1,
        cashouts: cashouts,
        pettycash: pettycash
      });
    }
  };

  onChangeName = (eN, iN) => {
    var e = eN;
    var i = iN;
    const newIds = this.state.cashouts.slice();
    newIds[i].name = e.currentTarget.value;
    this.setState({ cashouts: newIds });
  };

  onChangeEvtPrice = (eN, iN) => {
    var e = eN;
    var i = iN;
    const newIds = this.state.cashouts.slice();
    var pettycash = this.state.pettycash;
    var price = Math.round(e.currentTarget.value * 100) / 100;
    pettycash = pettycash - newIds[i].price;
    newIds[i].price = price;
    pettycash = pettycash + price;
    this.setState({ cashouts: newIds, pettycash: pettycash });
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  closeFormDialog = () => {
    this.setState({
      pdopen: false
    });
  };

  onError = err => {
    this.setState({
      head: 'User Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  handleEditUser = oUser => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    if (oUser.history) {
      this.handleAddBreadCrumb({ method: 'back', crumb: 'Details' });
      var startDate = '';
      var endDate = '';
      var id = oUser._id;
      startDate = oUser.session.start;
      endDate = oUser.session.end;
      id = oUser.session.userid;

      this.props
        .getBillsCashup(token, id, startDate, endDate)
        .then(result => {
          oUser.bills = result.data.results.bills;
          this.setState({
            selectAll: false,
            showChashupButton: false,
            user: oUser,
            totals: oUser.totals,
            main: false,
            showBills: false,
            showVoidBills: false,
            showRefundBills: false,
            cashup: true,
            loading: false,
            cashupCount: false,
            cashCountValue: 0,
            showCashCountInput: false,
            cashouts: [],
            inputCount: 0,
            pettycash: 0
          });
        })
        .catch(err => {
          this.onError(err);
        });
    } else {
      this.props
        .getUserCashupTotal(token, oUser._id)
        .then(result => {
          result.data.results.acc = result.data.results.total;
          if (oUser.session.float > 0) {
            result.data.results.total =
              result.data.results.total + oUser.session.float;
          }
          this.handleAddBreadCrumb({ method: 'back', crumb: 'Details' });
          var startDate = '';
          var endDate = '';
          var id = oUser._id;
          startDate = oUser.session.start;
          endDate = oUser.session.end;
          id = oUser.session.userid;
          this.props
            .getBillsCashup(token, id, startDate, endDate)
            .then(result2 => {
              oUser.bills = result2.data.results.bills;
              this.setState({
                selectAll: false,
                showChashupButton: false,
                user: oUser,
                totals: result.data.results,
                main: false,
                showBills: false,
                showVoidBills: false,
                showRefundBills: false,
                cashup: true,
                loading: false,
                cashupCount: false,
                cashCountValue: 0,
                showCashCountInput: false,
                cashouts: [],
                inputCount: 0,
                pettycash: 0
              });
            })
            .catch(err => {
              this.onError(err);
            });
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  handleEditUserMulti = () => {
    this.props.closeSnackbar();
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    let aUsers = [];
    let oUser = {
      name: '',
      session: {
        float: 0,
        sessionidarray: []
      }
    };
    for (var i = 0; i < this.state.users.length; i++) {
      if (this.state.users[i].selected) {
        aUsers.push(this.state.users[i]._id);
        oUser.name += this.state.users[i].name + '; ';
        oUser.session.sessionidarray.push(this.state.users[i].session._id);
        oUser.session.float += this.state.users[i].session.float;
        oUser.session.start = this.state.users[i].session.start;
        oUser.session.end = this.state.users[i].session.end;
        oUser.session.cardTransactions = (
          parseFloat(oUser.session.cardTransactions || '0') +
          parseFloat(this.state.users[i].session.cardTransactions || '0')
        ).toString();
      }
    }
    oUser.useridarray = aUsers;
    this.props
      .getUserCashupTotalMulti(token, aUsers)
      .then(result => {
        if (result.data.results === 'NoBills') {
          if (oUser.session.float > 0) {
            var cashup = {
              total: oUser.session.float || 0,
              cash: 0,
              card: 0,
              yoco: 0,
              discount: 0,
              tip: 0,
              tab: 0,
              void: 0,
              refund: 0,
              paidRefund: 0,
              waste: 0,
              acc: 0,
              cashPayment: 0,
              cardPayment: 0
            };
            this.setState({
              selectAll: false,
              showChashupButton: false,
              user: oUser,
              totals: cashup,
              main: false,
              showBills: false,
              showVoidBills: false,
              showRefundBills: false,
              cashup: true,
              loading: false,
              cashupCount: false,
              cashCountValue: 0,
              showCashCountInput: false,
              cashouts: [],
              inputCount: 0,
              pettycash: 0
            });
          } else {
            this.props.openSnackbar('No Bills to Cashup');
            this.cashupNoBill(oUser);
          }
        } else {
          result.data.results.acc = result.data.results.total;
          if (oUser.session.float > 0) {
            result.data.results.total =
              result.data.results.total + oUser.session.float;
          }
          this.handleAddBreadCrumb({ method: 'back', crumb: 'Details' });
          this.setState({
            selectAll: false,
            showChashupButton: false,
            user: oUser,
            totals: result.data.results,
            main: false,
            showBills: false,
            showVoidBills: false,
            showRefundBills: false,
            cashup: true,
            loading: false,
            cashupCount: false,
            cashCountValue: 0,
            showCashCountInput: false,
            cashouts: [],
            inputCount: 0,
            pettycash: 0
          });
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  cashup = () => {
    this.setState({ loading: true, pdopen: false });
    const token = this.props.local.user.token;
    this.props
      .doCashup(
        token,
        this.state.user,
        this.state.totals.acc,
        this.state.totals,
        this.state.cashCountValue,
        this.state.pettycash
      )
      .then(result => {
        this.getUsers();
      })
      .catch(err => {
        this.onError(err);
      });
  };

  cashupReset = user => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    if (!user) {
      user = this.state.user;
    }
    this.props
      .doCashupReset(token, user)
      .then(result => {
        this.getUsers();
      })
      .catch(err => {
        this.onError(err);
      });
  };

  cashupNoBill = oUser => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    this.props
      .doCashup(token, oUser, this.state.totals.acc, this.state.totals)
      .then(result => {
        this.getUsers();
      })
      .catch(err => {
        this.onError(err);
      });
  };

  viewBills = user => {
    this.handleAddBreadCrumb({ method: 'back', crumb: 'Bills' });
    this.setState({
      user: user,
      main: false,
      showBills: true,
      showVoidBills: false,
      showRefundBills: false,
      cashup: false
    });
  };

  viewRefundBills = user => {
    this.setState({
      main: false,
      showBills: false,
      showVoidBills: false,
      showRefundBills: true,
      cashup: false
    });
  };

  viewVoidBills = user => {
    this.setState({
      main: false,
      showBills: false,
      showVoidBills: true,
      showRefundBills: false,
      cashup: false
    });
  };

  viewTotalVoidBills = user => {
    this.setState({
      main: false,
      showBills: false,
      showTotalVoidBills: true,
      cashup: false
    });
  };

  viewCashPayments = user => {
    this.setState({
      main: false,
      showBills: false,
      showCashPayments: true,
      cashup: false
    });
  };

  viewCardPayments = user => {
    this.setState({
      main: false,
      showBills: false,
      showCardPayments: true,
      cashup: false
    });
  };

  tableAmountCheck = (record, e, key) => {
    this.state.users[key].selected = !this.state.users[key].selected;
    var check = false;
    var count = 0;
    for (var i = 0; i < this.state.users.length; i++) {
      if (this.state.users[i].selected) {
        check = true;
        count = count + 1;
      }
    }
    if (count < 2) {
      check = false;
    }
    this.setState({
      users: this.state.users,
      showChashupButton: check
    });
  };

  tableAmountCheckAll = () => {
    this.state.selectAll = !this.state.selectAll;
    var check = this.state.selectAll;
    if (this.state.users.length < 2) {
      check = false;
    }
    let count = 0;
    for (var i = 0; i < this.state.users.length; i++) {
      if (!this.state.users[i].history) {
        this.state.users[i].selected = this.state.selectAll;
        count++;
      }
    }
    if (count > 0) {
      this.setState({
        users: this.state.users,
        selectAll: this.state.selectAll,
        showChashupButton: check
      });
    }
  };

  back = () => {
    this.setState({ loading: true });
    this.getUsers();
  };

  backFromVoid = () => {
    this.setState({
      main: false,
      showBills: false,
      showVoidBills: false,
      showRefundBills: false,
      cashup: true
    });
  };

  backFromTotalVoid = () => {
    this.setState({
      main: true,
      showBills: false,
      showTotalVoidBills: false,
      cashup: false
    });
  };

  backFromCashPayments = () => {
    this.setState({
      main: false,
      showBills: false,
      showCashPayments: false,
      cashup: true
    });
  };

  backFromCardPayments = () => {
    this.setState({
      main: false,
      showBills: false,
      showCardPayments: false,
      cashup: true
    });
  };

  doCashupCount = () => {
    this.setState({
      cashupCount: true,
      cashCountValue: 0,
      showCashCountInput: false
    });
  };

  backToCashup = () => {
    this.setState({
      cashupCount: false,
      cashCountValue: 0,
      showCashCountInput: false
    });
  };

  countComplete = value => {
    this.setState({
      cashupCount: false,
      cashCountValue: value,
      showCashCountInput: true
    });
  };

  onChangeCardTarns = eN => {
    let event = Object.assign({}, eN);
    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        session: {
          ...prevState.user.session,
          cardTransactions: (
            Math.round(event.currentTarget.value * 100) / 100
          ).toString()
        }
      }
    }));
  };

  changeViewCard = value => {
    this.setState({
      changeView: !this.state.changeView
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center', marginBottom: '1em' }}>
      <Empty
        imageStyle={{
          margin: '1em',
          marginBottom: '0.5em',
          height: 90
        }}
      />
    </div>
  );
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditUser: this.handleEditUser,
      backToCashup: this.backToCashup,
      countComplete: this.countComplete,
      onChangeName: this.onChangeName,
      onChangeEvtPrice: this.onChangeEvtPrice,
      addInput: this.addInput,
      removeInput: this.removeInput
    };

    return (
      <div>
        <Loading
          show={this.state.loading}
          showSpinner={false}
          color="rgb(15, 135, 123)"
        />
        <AlertDialog {...oState} />
        <FormDialog
          head="Cashup"
          msg="Are you sure the Cashup is fine?"
          formElements={[]}
          open={this.state.pdopen}
          close={this.closeFormDialog}
          actionBtn="Yes"
          closeBtn="No"
          onClick={this.cashup}
        />

        {this.state.main ? (
          <div>
            <div>
              <IconButton
                style={{
                  marginTop: '-0.8em',
                  marginBottom: '0.4em',
                  width: '2.05em',
                  height: '2.05em',
                  position: 'relative',
                  backgroundColor: 'white',
                  boxShadow: 'rgb(220,225,238) 0px 5px 10px 3px',
                  display: 'none'
                }}
                onClick={() => this.changeViewCard()}
              >
                {
                  <Icon
                    type="eye"
                    style={{
                      boxShadow: 'rgb(220,225,238)  0px 2px 3px 1px',
                      borderRadius: '3em',
                      padding: '0.3em',

                      backgroundColor: !this.state.changeView
                        ? 'rgba(0, 204, 112, 0.898)'
                        : 'white',
                      color: !this.state.changeView
                        ? 'white'
                        : 'rgba(0, 204, 112, 0.898)'
                    }}
                  />
                }
              </IconButton>
              <IconButton
                style={{
                  //margin: '27px 0 0 0',
                  marginTop: '-0.8em',
                  marginBottom: '0.4em',
                  marginLeft: '0.4em',
                  width: '2.05em',
                  height: '2.05em',
                  position: 'relative',
                  //  paddingBottom: 10,
                  // verticalAlign: 'unset',
                  backgroundColor: 'white',
                  display: 'none',
                  boxShadow: 'rgb(220,225,238) 0px 5px 10px 3px'
                }}
                onClick={() => this.printTotalsCashup()}
              >
                {
                  <Icon
                    type="printer"
                    style={{
                      boxShadow: 'rgb(220,225,238)  0px 2px 3px 1px',
                      borderRadius: '3em',
                      padding: '0.3em',

                      backgroundColor: 'white',
                      color: 'rgba(0, 204, 112, 0.898)'
                    }}
                  />
                }
              </IconButton>
              <div
                style={{
                  marginTop: '-10px',
                  marginBottom: '30px'
                }}
              >
                <Select
                  id="filterSelect"
                  style={{
                    width: '150px',
                    paddingRight: '10px'
                  }}
                  defaultValue="day"
                  onChange={this.handleChangeSelect}
                >
                  <Option value="day">Today</Option>
                  <Option value="yester">Yesterday</Option>
                  <Option value="seven">Last 7 Days</Option>
                  <Option value="thir">Last 30 Days</Option>
                  <Option value="showcustom">Custom</Option>
                </Select>
                <div
                  className={
                    this.state.datePicker
                      ? this.props.classes.show
                      : this.props.classes.hide
                  }
                >
                  <DatePicker
                    style={{
                      borderRadius: '3em',
                      marginBottom: '1em',
                      border: 'none',
                      boxShadow: 'none'
                    }}
                    allowClear={false}
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDate}
                    value={this.state.pickerStart}
                    format={dateFormat}
                  />
                  <StyledButton
                    style={{
                      width: '7.5em',
                      height: '2.5em',
                      borderRadius: '3em',
                      marginBottom: '1em',
                      left: '5px',
                      backgroundColor: '#00CC70',
                      color: 'white',
                      border: 'none',
                      boxShadow: 'none'
                    }}
                    onClick={() => this.handleChangeSelect('custom')}
                  >
                    Get Custom
                  </StyledButton>
                </div>
              </div>
              <div style={{ display: 'none' }}>
                <StyledButton
                  style={{
                    width: '7em',
                    height: '2.5em',
                    borderRadius: '3em',
                    marginBottom: '1.5em',
                    left: '10px',
                    backgroundColor: '#00CC70',
                    color: 'white',
                    border: 'none',
                    boxShadow: 'none',
                    top: '-0.7em'
                  }}
                  onClick={() => this.handleChangeSelect('day')}
                >
                  Today
                </StyledButton>
                <StyledButton
                  style={{
                    width: '7em',
                    height: '2.5em',
                    borderRadius: '3em',
                    marginBottom: '1em',
                    left: '17px',
                    backgroundColor: '#00CC70',
                    color: 'white',
                    border: 'none',
                    boxShadow: 'none',
                    top: '-0.7em'
                  }}
                  onClick={() => this.handleChangeSelect('yester')}
                >
                  Yesterday
                </StyledButton>
                <StyledButton
                  style={{
                    width: '7em',
                    height: '2.5em',
                    borderRadius: '3em',
                    marginBottom: '1em',
                    left: '24px',
                    backgroundColor: '#00CC70',
                    color: 'white',
                    border: 'none',
                    boxShadow: 'none',
                    top: '-0.7em'
                  }}
                  onClick={() => this.handleChangeSelect('seven')}
                >
                  Last 7 Days
                </StyledButton>
                <StyledButton
                  style={{
                    width: '7.5em',
                    height: '2.5em',
                    borderRadius: '3em',
                    marginBottom: '1em',
                    left: '31px',
                    backgroundColor: '#00CC70',
                    color: 'white',
                    border: 'none',
                    boxShadow: 'none',
                    top: '-0.7em'
                  }}
                  onClick={() => this.handleChangeSelect('thir')}
                >
                  Last 30 Days
                </StyledButton>
                <DatePicker
                  style={{
                    borderRadius: '3em',
                    marginBottom: '1em',
                    left: '38px',
                    border: 'none',
                    boxShadow: 'none',
                    top: '-0.7em'
                  }}
                  allowClear={false}
                  className={this.props.classes.datePicker}
                  onChange={this.handleChangeDate}
                  value={this.state.pickerStart}
                  format={dateFormat}
                />
                <StyledButton
                  style={{
                    width: '7.5em',
                    height: '2.5em',
                    borderRadius: '3em',
                    marginBottom: '1em',
                    left: '40px',
                    backgroundColor: '#00CC70',
                    color: 'white',
                    border: 'none',
                    boxShadow: 'none',
                    top: '-0.7em'
                  }}
                  onClick={() => this.handleChangeSelect('custom')}
                >
                  Get Custom
                </StyledButton>
              </div>
            </div>
            <div
              className="stat-display-card"
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              {this.state.changeView ? (
                <Row type="flex">
                  <Col span={6} style={{}}>
                    <AntCard
                      className="stat-display-card-today"
                      style={{
                        textAlign: 'center',
                        overflow: 'hidden',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white',
                        position: 'absolute',
                        display: 'none'
                      }}
                    >
                      <StyledStatistic
                        className="stat-display-card-today-sales"
                        title={<span>Total Cash</span>}
                        value={
                          this.state.todayCashupTotal.cash
                            ? this.state.todayCashupTotal.cash.toFixed(2)
                            : '0.00'
                        }
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R"
                      />{' '}
                    </AntCard>
                  </Col>
                  <Col span={6} style={{}}>
                    <AntCard
                      className="stat-display-icon-gross"
                      style={{
                        textAlign: 'center',
                        overflow: 'hidden',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white',
                        position: 'absolute',
                        display: 'none'
                      }}
                    >
                      <StyledStatistic
                        className="stat-display-card-content"
                        title={<span>Sales Person Payouts</span>}
                        value={
                          (this.state.todayCashupTotal.cash || 0) +
                            (this.state.todayCashupTotal.card -
                              (this.state.todayCashupTotal.cardPayments || 0)) -
                            (this.state.todayCashupTotal.cashPayments || 0) -
                            (this.state.todayCashupTotal.cardTransactions ||
                              0) <
                          0
                            ? (
                                (this.state.todayCashupTotal.cash || 0) +
                                (this.state.todayCashupTotal.card -
                                  (this.state.todayCashupTotal.cardPayments ||
                                    0)) -
                                (this.state.todayCashupTotal.cashPayments ||
                                  0) -
                                (this.state.todayCashupTotal.cardTransactions ||
                                  0)
                              )
                                .toFixed(2)
                                .replace('-', '')
                            : '0.00'
                        }
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R"
                      />{' '}
                      <CardFooter
                        className="stat-display-card-footer"
                        style={{
                          marginTop: '10px',
                          marginLeft: -25,
                          width: '125%',
                          height: '3em'
                        }}
                      />
                    </AntCard>
                  </Col>
                  <Col span={6} style={{}}>
                    <AntCard
                      className="stat-display-icon-gross"
                      style={{
                        textAlign: 'center',
                        overflow: 'hidden',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white',
                        position: 'absolute',
                        display: 'none'
                      }}
                    >
                      <StyledStatistic
                        className="stat-display-card-content"
                        title={<span>Total Cash Payments</span>}
                        value={(
                          this.state.todayCashupTotal.cashout || 0
                        ).toFixed(2)}
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R"
                      />{' '}
                      <CardFooter
                        className="stat-display-card-footer"
                        style={{
                          marginTop: '10px',
                          marginLeft: -25,
                          width: '125%',
                          height: '3em'
                        }}
                      />
                    </AntCard>
                  </Col>
                  <Col span={6} style={{}}>
                    <AntCard
                      className="stat-display-card-net"
                      style={{
                        textAlign: 'center',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        overflow: 'hidden',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white',
                        display: 'none'
                      }}
                    >
                      <StyledStatistic
                        className="stat-display-card-content"
                        title={<span>Total Cash Left</span>}
                        value={(
                          (this.state.todayCashupTotal.cash || 0) -
                          (this.state.todayCashupTotal.cashPayments || 0) +
                          (this.state.todayCashupTotal.card -
                            (this.state.todayCashupTotal.cardPayments || 0) -
                            (this.state.todayCashupTotal.cardTransactions ||
                              0) <
                          0
                            ? this.state.todayCashupTotal.card -
                              (this.state.todayCashupTotal.cardPayments || 0) -
                              (this.state.todayCashupTotal.cardTransactions ||
                                0)
                            : 0)
                        ).toFixed(2)}
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R "
                      />
                    </AntCard>
                  </Col>
                </Row>
              ) : (
                <Row type="flex">
                  <Col style={{ width: '20%' }}>
                    <AntCard
                      className="stat-display-card-today"
                      style={{
                        textAlign: 'center',
                        overflow: 'hidden',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white',
                        position: 'absolute'
                      }}
                    >
                      <StyledStatistic
                        className="stat-display-card-today-sales"
                        title={<span>Card Sales</span>}
                        value={
                          this.state.todayCashupTotal.card
                            ? this.state.todayCashupTotal.card.toFixed(2)
                            : '0.00'
                        }
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R"
                      />{' '}
                    </AntCard>
                  </Col>
                  <Col style={{ width: '20%' }}>
                    <AntCard
                      className="stat-display-icon-gross"
                      style={{
                        textAlign: 'center',
                        overflow: 'hidden',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white',
                        position: 'absolute'
                      }}
                    >
                      <StyledStatistic
                        className="stat-display-card-content"
                        value={(
                          (this.state.todayCashupTotal.cash || 0) -
                          (this.state.todayCashupTotal.float || 0)
                        ).toFixed(2)}
                        title={<span>Cash Sales</span>}
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R"
                      />{' '}
                      <CardFooter
                        className="stat-display-card-footer"
                        style={{
                          marginTop: '10px',
                          marginLeft: -25,
                          width: '125%',
                          height: '3em'
                        }}
                      />
                    </AntCard>
                  </Col>
                  <Col style={{ width: '20%' }}>
                    <AntCard
                      className="stat-display-card-net"
                      style={{
                        textAlign: 'center',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        overflow: 'hidden',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white'
                      }}
                    >
                      <StyledStatistic
                        className="stat-display-card-open-bills"
                        title={<span>Tab Totals</span>}
                        value={this.state.todayCashupTotal.tab.toFixed(2)}
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R "
                      />
                    </AntCard>
                  </Col>
                  <Col style={{ width: '20%' }}>
                    <AntCard
                      onClick={() => this.viewTotalVoidBills()}
                      className="stat-display-card-profit"
                      style={{
                        textAlign: 'center',
                        overflow: 'hidden',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white',
                        cursor: 'pointer'
                      }}
                    >
                      {' '}
                      <StyledStatistic
                        className="stat-display-card-profit"
                        title={<span>Voids Totals</span>}
                        value={this.state.todayCashupTotal.void.toFixed(2)}
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R "
                      />{' '}
                    </AntCard>
                  </Col>
                  <Col style={{ width: '20%' }}>
                    <AntCard
                      className="stat-display-card-net"
                      style={{
                        textAlign: 'center',
                        boxShadow: '0px 5px 10px 2px #E5E9F2',
                        overflow: 'hidden',
                        borderRadius: '1.2em',
                        height: '120px',
                        width: '97%',
                        border: '0px solid lightgrey',
                        background: 'white'
                      }}
                    >
                      <StyledStatistic
                        className="stat-display-card-open-bills"
                        title={<span>Grand Total</span>}
                        value={this.state.todayCashupTotal.total.toFixed(2)}
                        valueStyle={{
                          fontFamily: 'Montserrat,sans-serif!important',
                          color: '#4A4040',
                          position: 'relative',
                          bottom: -10,
                          fontWeight: 600
                        }}
                        prefix="R "
                      />
                    </AntCard>
                  </Col>
                </Row>
              )}
            </div>
            <div>
              {this.state.showChashupButton ? (
                <Button
                  style={{
                    left: '5px',
                    borderRadius: '3em',
                    fontWeight: 600,
                    fontSize: '15px',
                    marginTop: '-0.2em',
                    marginBottom: '0.5em',
                    height: '2.8em',
                    width: '10.5em',
                    color: 'white',
                    backgroundColor: 'rgba(0, 204, 112, 0.898)'
                  }}
                  onClick={() => this.handleEditUserMulti()}
                  color="primary"
                >
                  Cashup Selected{' '}
                </Button>
              ) : null}
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  locale={
                    this.state.loading
                      ? {
                          emptyText: <Skeleton />
                        }
                      : {}
                  }
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={this.state.users}
                  pagination={{
                    locale: { items_per_page: ' Items' },
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: e => {
                        e.stopPropagation();
                        this.handleEditUser(record);
                      }
                    };
                  }}
                >
                  <Column
                    render={(text, record, key) => (
                      <div>
                        <StyledCheckbox
                          onChange={event =>
                            this.tableAmountCheck(record, event, key)
                          }
                          disabled={record.history ? true : false}
                          checked={record.selected || false}
                        />
                      </div>
                    )}
                    key="select"
                  />
                  <Column title="Sales Person" dataIndex="name" key="name" />
                  <Column
                    title="Hours Worked"
                    render={(text, record) => {
                      return (
                        <span>
                          {record.session
                            ? moment
                                .utc(
                                  moment(
                                    record.session.end.toString(),
                                    'YYYYMMDDHHmm'
                                  ).diff(
                                    moment(
                                      record.session.start.toString(),
                                      'YYYYMMDDHHmm'
                                    )
                                  )
                                )
                                .format('HH:mm')
                            : 0}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title="Card"
                    render={(text, record) => (
                      <span>
                        {' '}
                        {'R ' + (record.card ? record.card : 0).toFixed('2')}
                      </span>
                    )}
                  />
                  <Column
                    title="Yoco"
                    render={(text, record) => (
                      <span>
                        {' '}
                        {'R ' + (record.yoco ? record.yoco : 0).toFixed('2')}
                      </span>
                    )}
                  />
                  <Column
                    title="Cash"
                    render={(text, record) => (
                      <span>
                        {' '}
                        {'R ' + (record.cash ? record.cash : 0).toFixed('2')}
                      </span>
                    )}
                  />
                  <Column
                    title="Tab"
                    render={(text, record) => (
                      <span>
                        {' '}
                        {'R ' + (record.tab ? record.tab : 0).toFixed('2')}
                      </span>
                    )}
                  />
                  <Column
                    title="Refunds"
                    render={(text, record) => (
                      <span>
                        {' '}
                        {'R ' +
                          (record.paidRefund ? record.paidRefund : 0).toFixed(
                            '2'
                          )}
                      </span>
                    )}
                  />
                  <Column
                    title="Total"
                    render={(text, record) => (
                      <span>
                        {' '}
                        {'R ' + (record.total ? record.total : 0).toFixed('2')}
                      </span>
                    )}
                  />
                  <Column
                    title="Timestamp"
                    render={(text, record) => {
                      if (record.timestamp) {
                        return (
                          <span>
                            {moment(record.timestamp._d).format(
                              'YYYY/MM/DD HH:mm'
                            )}
                          </span>
                        );
                      } else {
                        return <span>Cashup Outstanding</span>;
                      }
                    }}
                  />
                </StyledTable>
              </ConfigProvider>
            </div>
          </div>
        ) : null}
        {this.state.showBills ? (
          <CashupBills
            user={this.state.user}
            local={this.props.local}
            back={this.backFromVoid}
          />
        ) : null}
        {this.state.showRefundBills ? (
          <CashupRefundBills
            user={this.state.user}
            local={this.props.local}
            back={this.backFromVoid}
          />
        ) : null}
        {this.state.showVoidBills ? (
          <CashupVoidBills
            user={this.state.user}
            local={this.props.local}
            back={this.backFromVoid}
          />
        ) : null}
        {this.state.showTotalVoidBills ? (
          <CashupTotalVoidBills
            user={this.state.user}
            local={this.props.local}
            back={this.backFromTotalVoid}
          />
        ) : null}
        {this.state.showCashPayments ? (
          <CashupCashPayments
            user={this.state.user}
            local={this.props.local}
            back={this.backFromCashPayments}
          />
        ) : null}
        {this.state.showCardPayments ? (
          <CashupCardPayments
            user={this.state.user}
            local={this.props.local}
            back={this.backFromCardPayments}
          />
        ) : null}
        {this.state.cashup ? (
          <div>
            {this.state.cashupCount ? (
              <CashupCount {...oState} />
            ) : (
              <Card>
                <CardBody style={{ paddingTop: '2em' }}>
                  <CustButton
                    style={{
                      position: 'absolute',
                      marginLeft: '-1em',
                      top: '0.1em',
                      left: '1.8em',
                      background: '#00CC70',
                      border: '1px solid transparent',
                      color: 'white',
                      padding: '0em',
                      width: '3.4em',
                      height: '3.3em',
                      borderRadius: '5em',
                      boxShadow: 'none',
                      fontSize: '1em',
                      zIndex: 9
                    }}
                    onClick={() => this.back()}
                    color="primary"
                  >
                    <Icon
                      style={{
                        position: 'absolute',
                        paddingLeft: 3
                      }}
                      type="arrow-left"
                    />
                  </CustButton>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              color: 'black',
                              fontSize: '17px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Name
                          </span>
                        }
                        id="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: ' ' + this.state.user.name
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              color: 'black',
                              fontSize: '17px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Session Start{' '}
                          </span>
                        }
                        id="start"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={
                          this.state.user.session
                            ? {
                                disabled: true,
                                value:
                                  '' +
                                  this.state.user.session.start.slice(6, 8) +
                                  '/' +
                                  this.state.user.session.start.slice(4, 6) +
                                  '/' +
                                  this.state.user.session.start.slice(0, 4) +
                                  ' ' +
                                  this.state.user.session.start.slice(8, 10) +
                                  'h' +
                                  this.state.user.session.start.slice(10, 12)
                              }
                            : { value: ' ' }
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              color: 'black',
                              fontSize: '17px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Session End{' '}
                          </span>
                        }
                        id="start"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={
                          this.state.user.session
                            ? {
                                disabled: true,
                                value:
                                  this.state.user.session.end.slice(6, 8) +
                                  '/' +
                                  this.state.user.session.end.slice(4, 6) +
                                  '/' +
                                  this.state.user.session.end.slice(0, 4) +
                                  ' ' +
                                  this.state.user.session.end.slice(8, 10) +
                                  'h' +
                                  this.state.user.session.end.slice(10, 12)
                              }
                            : { value: ' ' }
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              color: 'black',
                              fontSize: '17px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {' '}
                            Total Hours{' '}
                          </span>
                        }
                        id="hours"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={
                          this.state.user.session
                            ? {
                                disabled: true,
                                value:
                                  ' ' +
                                  moment
                                    .utc(
                                      moment(
                                        this.state.user.session.end.toString(),
                                        'YYYYMMDDHHmm'
                                      ).diff(
                                        moment(
                                          this.state.user.session.start.toString(),
                                          'YYYYMMDDHHmm'
                                        )
                                      )
                                    )
                                    .format('HH:mm')
                              }
                            : { disabled: true, value: ' 0' }
                        }
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      {this.state.user && !this.state.user.history ? (
                        <h2>
                          {' '}
                          <FaCoins
                            style={{
                              color: 'rgba(0, 204, 112, 0.898)',
                              marginRight: '0.4em',
                              marginBottom: '-0.11em'
                            }}
                          />
                          Cash
                          <Button
                            style={{
                              float: 'right',
                              marginLeft: '0.5em',
                              marginTop: '0.1em',
                              marginBottom: '-0.4em',
                              textAlign: 'center',
                              fontSize: '15px',
                              fontWeight: 500,
                              height: '2.2em',
                              //  width: "6.4em",
                              top: '0px',
                              color: 'rgba(0, 204, 112, 0.898)',
                              border: '1px solid rgba(0, 204, 112, 0.898)',
                              backgroundColor: 'white',
                              borderRadius: '3em'
                            }}
                            onClick={() => this.doCashupCount()}
                          >
                            Cash Count
                          </Button>
                        </h2>
                      ) : (
                        <h2>
                          {' '}
                          <FaCoins
                            style={{
                              color: 'rgba(0, 204, 112, 0.898)',
                              marginRight: '0.4em',
                              marginBottom: '-0.11em'
                            }}
                          />
                          Cash
                        </h2>
                      )}
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText={
                            <span
                              style={{
                                color: 'black',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Total Cash Sales on VPOS
                            </span>
                          }
                          id="cash"
                          showPrefix={'R'}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={
                            this.state.totals.cash
                              ? {
                                  disabled: true,
                                  value: this.state.totals.cash.toFixed('2'),
                                  style: { color: 'black' }
                                }
                              : { disabled: true, value: '0.00' }
                          }
                        />
                      </GridItem>
                      {this.state.totals.cashPayments ? (
                        <GridItem
                          style={{ display: 'flex' }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CustomInput
                            labelText={
                              <span
                                style={{
                                  color: 'black',
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                {' '}
                                Receipt Purchases with Cash{' '}
                              </span>
                            }
                            id="cashPayments"
                            showPrefix={'R'}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={
                              this.state.totals.cashPayments
                                ? {
                                    disabled: true,
                                    value: this.state.totals.cashPayments.toFixed(
                                      '2'
                                    )
                                  }
                                : { disabled: true, value: '0.00' }
                            }
                          />
                          <IconButton
                            style={{
                              margin: '27px 0 0 0',
                              position: 'relative',
                              paddingBottom: 10,
                              verticalAlign: 'unset'
                            }}
                            onClick={() => this.viewCashPayments()}
                          >
                            {
                              <Icon
                                type="eye"
                                style={{
                                  color: 'green'
                                }}
                              />
                            }
                          </IconButton>
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText={
                            <span
                              style={{
                                color: 'black',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Float
                            </span>
                          }
                          id="float"
                          showPrefix={'R'}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={
                            this.state.user.session
                              ? this.state.user.session.float
                                ? {
                                    disabled: true,
                                    value: this.state.user.session.float.toFixed(
                                      '2'
                                    )
                                  }
                                : { disabled: true, value: '0.00' }
                              : { disabled: true, value: '0.00' }
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {this.state.showCashCountInput ? (
                          <CustomInput
                            labelText={
                              <span
                                style={{
                                  color: 'black',
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Cash Count
                              </span>
                            }
                            id="count"
                            showPrefix={'R'}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: this.state.cashCountValue.toFixed('2')
                            }}
                          />
                        ) : null}
                      </GridItem>
                    </GridItem>
                    <GridItem
                      style={{ borderLeft: '1px solid lightgrey' }}
                      xs={12}
                      sm={12}
                      md={4}
                    >
                      <h2>
                        {' '}
                        <AiFillCreditCard
                          style={{
                            color: 'rgba(0, 204, 112, 0.898)',
                            marginBottom: '-0.11em',
                            marginRight: '0.4em'
                          }}
                        />
                        Card
                      </h2>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText={
                            <span
                              style={{
                                color: 'black',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Total Card sales on VPOS
                            </span>
                          }
                          id="card"
                          showPrefix={'R'}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={
                            this.state.totals.card
                              ? {
                                  disabled: true,
                                  value: this.state.totals.card.toFixed('2'),
                                  style: { color: 'black' }
                                }
                              : {
                                  disabled: true,
                                  value: '0.00',
                                  style: { color: 'black' }
                                }
                          }
                        />
                      </GridItem>
                      {this.state.totals.cardPayments ? (
                        <GridItem
                          style={{ display: 'flex' }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CustomInput
                            labelText={
                              <span
                                style={{
                                  color: 'black',
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Receipt Purchases with Card{' '}
                              </span>
                            }
                            id="cardPayments"
                            showPrefix={'R'}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={
                              this.state.totals.cardPayments
                                ? {
                                    disabled: true,
                                    value: this.state.totals.cardPayments.toFixed(
                                      '2'
                                    )
                                  }
                                : { disabled: true, value: '0.00' }
                            }
                          />
                          <IconButton
                            style={{
                              margin: '27px 0 0 0',
                              position: 'relative',
                              paddingBottom: 10,
                              verticalAlign: 'unset'
                            }}
                            onClick={() => this.viewCardPayments()}
                          >
                            {
                              <Icon
                                type="eye"
                                style={{
                                  color: 'green'
                                }}
                              />
                            }
                          </IconButton>
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText={
                            <span
                              style={{
                                color: 'black',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Total Card Transactions Counted By Manager
                            </span>
                          }
                          id="cardTransactions"
                          formControlProps={{
                            fullWidth: true
                          }}
                          showPrefix={'R'}
                          inputProps={{
                            onChange: e => this.onChangeCardTarns(e),
                            type: 'number',
                            value: this.state.user.session.cardTransactions,
                            disabled:
                              this.state.user && !this.state.user.history
                                ? false
                                : true
                          }}
                        />
                      </GridItem>
                      {this.state.totals.yoco ? (
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText={
                              <span
                                style={{
                                  color: 'black',
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Total Yoco sales on VPOS
                              </span>
                            }
                            id="card"
                            showPrefix={'R'}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={
                              this.state.totals.yoco
                                ? {
                                    disabled: true,
                                    value: this.state.totals.yoco.toFixed('2'),
                                    style: { color: 'black' }
                                  }
                                : {
                                    disabled: true,
                                    value: '0.00',
                                    style: { color: 'black' }
                                  }
                            }
                          />
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText={
                            <span
                              style={{
                                color: 'black',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Total Card Tip
                            </span>
                          }
                          id="cardTransactions"
                          formControlProps={{
                            fullWidth: true
                          }}
                          showPrefix={'R'}
                          inputProps={{
                            //onChange: e => this.onChangeCardTarns(e),
                            value: (
                              this.state.user.session.cardTransactions -
                              this.state.totals.card
                            ).toFixed('2'),
                            disabled: true,
                            style: { color: 'black' }
                          }}
                        />
                      </GridItem>
                    </GridItem>
                    <GridItem
                      style={{ borderLeft: '1px solid lightgrey' }}
                      xs={12}
                      sm={12}
                      md={4}
                    >
                      <h2>
                        {' '}
                        <BsSubtract
                          style={{
                            color: 'rgba(0, 204, 112, 0.898)',
                            marginRight: '0.4em'
                          }}
                        />
                        Other
                      </h2>
                      <GridItem xs={12} sm={12} md={12}>
                        {this.state.totals.tab > 0 ? (
                          <CustomInput
                            labelText={
                              <span
                                style={{
                                  color: 'black',
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Tab
                              </span>
                            }
                            id="tab"
                            showPrefix={'R'}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={
                              this.state.totals.tab
                                ? {
                                    disabled: true,
                                    value: this.state.totals.tab.toFixed('2')
                                  }
                                : { disabled: true, value: '0.00' }
                            }
                          />
                        ) : null}
                      </GridItem>
                      <GridItem
                        style={{ display: 'flex' }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <CustomInput
                          labelText={
                            <span
                              style={{
                                color: 'black',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Voids per user
                            </span>
                          }
                          id="void"
                          formControlProps={{
                            fullWidth: true
                          }}
                          showPrefix={'R'}
                          inputProps={
                            this.state.totals.void
                              ? {
                                  disabled: true,
                                  value: this.state.totals.void.toFixed('2')
                                }
                              : { disabled: true, value: '0.00' }
                          }
                        />
                        {this.state.user && this.state.totals.void > 0 ? (
                          <IconButton
                            style={{
                              margin: '27px 0 0 0',
                              position: 'relative',
                              paddingBottom: 10,
                              verticalAlign: 'unset'
                            }}
                            onClick={() => this.viewVoidBills()}
                          >
                            {
                              <Icon
                                type="eye"
                                style={{
                                  color: 'green'
                                }}
                              />
                            }
                          </IconButton>
                        ) : null}
                      </GridItem>
                      <GridItem
                        style={{ display: 'flex' }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <CustomInput
                          labelText={
                            <span
                              style={{
                                color: 'black',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Refunds per user
                            </span>
                          }
                          id="void"
                          formControlProps={{
                            fullWidth: true
                          }}
                          showPrefix={'R'}
                          inputProps={
                            this.state.totals.paidRefund
                              ? {
                                  disabled: true,
                                  value: this.state.totals.paidRefund.toFixed(
                                    '2'
                                  )
                                }
                              : { disabled: true, value: '0.00' }
                          }
                        />
                        {this.state.user && this.state.totals.paidRefund > 0 ? (
                          <IconButton
                            style={{
                              margin: '27px 0 0 0',
                              position: 'relative',
                              paddingBottom: 10,
                              verticalAlign: 'unset'
                            }}
                            onClick={() => this.viewRefundBills()}
                          >
                            {
                              <Icon
                                type="eye"
                                style={{
                                  color: 'green'
                                }}
                              />
                            }
                          </IconButton>
                        ) : null}
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem
                      style={{ marginTop: '2em' }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <h2
                        style={{
                          textAlign: 'center',
                          textDecoration: 'underline',
                          fontSize: '25px'
                          // textDecorationColor: "rgb(0, 204, 112)"
                        }}
                      >
                        Summary
                      </h2>
                      <GridContainer>
                        <GridItem xs={0} sm={0} md={4} />
                        <GridItem xs={0} sm={0} md={4} />
                        <GridItem xs={0} sm={0} md={4} />
                        <GridItem xs={12} sm={12} md={12}>
                          <Tooltip
                            placement="top"
                            title={
                              (this.state.totals.cash +
                                (this.state.user.session.float || 0) -
                                this.state.totals.cashPayments || 0) < 0
                                ? 'The System takes the Card Transactions Amount minus the Card Amount then deducts it from Cash Amount plus Float if any. If the Amount is Less than Zero the amount is owed to ' +
                                  this.state.user.name
                                : 'The System takes the Card Transactions Amount minus the Card Amount then deducts it from Cash Amount plus Float if any. If the Amount is Greater than Zero the amount is owed to ' +
                                  this.props.local.user.orgname
                            }
                          >
                            <h2 style={{ textAlign: 'center' }}>
                              {(this.state.totals.cash +
                                (this.state.user.session.float || 0) -
                                this.state.totals.cashPayments || 0) < 0
                                ? this.props.local.user.orgname +
                                  ' owes ' +
                                  this.state.user.name
                                : this.state.user.name +
                                  ' owes ' +
                                  this.props.local.user.orgname}
                              <span
                                style={
                                  (this.state.totals.cash +
                                    (this.state.user.session.float || 0) -
                                    this.state.totals.cashPayments || 0) < 0
                                    ? { color: 'green' }
                                    : { color: 'red' }
                                }
                              >
                                {' R ' +
                                  (
                                    this.state.totals.cash +
                                      (this.state.user.session.float || 0) -
                                      this.state.totals.cashPayments || 0
                                  )
                                    .toFixed('2')
                                    .replace('-', '')}
                              </span>
                            </h2>
                          </Tooltip>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <Inputs {...oState} />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {this.state.user && !this.state.user.history ? (
                      <div>
                        <Button
                          style={{
                            left: '25px',
                            fontSize: '18px',
                            height: '2.2em',
                            fontWeight: 500,
                            width: '6.8em',
                            marginTop: '2em',
                            color: 'white',
                            border: '1px solid #00CC70',
                            backgroundColor: '#00CC70',
                            borderRadius: '3em'
                          }}
                          onClick={() => {
                            this.setState({
                              pdopen: true
                            });
                          }}
                          color="primary"
                        >
                          Cashup
                        </Button>
                        <RiDeleteBin6Line
                          style={{
                            margin: '0.5em',
                            right: '0.2em',
                            position: 'absolute',
                            top: '0px',
                            position: 'absolute',
                            fontSize: '25px',
                            top: '0px',
                            border: '0px solid green',
                            backgroundColor: 'white',
                            color: 'red',
                            margin: ' 0.13em 0.1em 0.05em auto',
                            fontSize: '46px',
                            padding: '0.2em',
                            borderRadius: '3em',
                            backgroundColor: 'rgb(289, 233, 242)'
                          }}
                          onClick={() => this.cashupReset()}
                        />
                      </div>
                    ) : null}
                    <div>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          this.viewBills(this.state.user);
                        }}
                        style={{
                          margin: '0.5em',
                          right: '5em',
                          position: 'absolute',
                          top: '0px',
                          position: 'absolute',
                          top: '0px',
                          border: '0px solid green',
                          backgroundColor: 'white',
                          color: 'rgb(19, 36, 64)',
                          margin: ' 0.13em 0.1em 0.05em auto',
                          fontSize: '35px',
                          padding: '0.2em',
                          borderRadius: '3em'
                        }}
                      >
                        {
                          <Icon
                            type="eye"
                            style={{
                              color: 'green'
                            }}
                          />
                        }
                      </IconButton>
                      <AiOutlineMail
                        style={{
                          margin: '0.5em',
                          right: '2.6em',
                          position: 'absolute',
                          top: '0px',
                          position: 'absolute',
                          fontSize: '25px',
                          top: '0px',
                          border: '0px solid green',
                          backgroundColor: 'white',
                          color: 'rgb(19, 36, 64)',
                          margin: ' 0.13em 0.1em 0.05em auto',
                          fontSize: '46px',
                          padding: '0.2em',
                          borderRadius: '3em',
                          backgroundColor: 'rgb(229, 233, 242, 0.6)'
                        }}
                        onClick={() => this.printCashup(true)}
                      />
                      <AiOutlinePrinter
                        style={{
                          margin: '0.5em',
                          right: '1.41em',
                          position: 'absolute',
                          top: '0px',
                          position: 'absolute',
                          fontSize: '25px',
                          top: '0px',
                          border: '0px solid green',
                          backgroundColor: 'white',
                          color: 'rgb(19, 36, 64)',
                          margin: ' 0.13em 0.1em 0.05em auto',
                          fontSize: '46px',
                          padding: '0.2em',
                          borderRadius: '3em',
                          backgroundColor: 'rgb(229, 233, 242, 0.6)'
                        }}
                        onClick={() => this.printCashup()}
                      />
                    </div>
                  </GridContainer>
                </CardBody>
              </Card>
            )}
          </div>
        ) : null}
        <Snackbar {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  users: state.users,
  orgData: state.backend.orgname,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  getUsersCashup,
  getUserCashupTotal,
  setHelpMarker,
  doCashup,
  doCashupReset,
  openSnackbar,
  emailCashup,
  emailCashupTotals,
  setBreadCrumbs,
  resetActionBreadCrumb,
  getUsersCashupHistory,
  getUserCashupTotalMulti,
  closeSnackbar,
  getBillsCashup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Cashup));
