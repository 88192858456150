import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';

function PositionedSnackbar({ ...oProps }) {
  const { opensnackbar, snackmsg } = oProps.snackbar.dash;
  const vertical = 'bottom';
  const horizontal = 'right';
  return (
    <div>
      <Snackbar
        open={opensnackbar}
        anchorOrigin={{ vertical, horizontal }}
        onClose={oProps.closeSnackbar}
        key={`${vertical},${horizontal}`}
        autoHideDuration={3000}
        TransitionComponent={Fade}
        style={{
          color: '#fff',
          background: '#00CC70'
        }}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: oProps.classes
              ? oProps.classes.snackbar
              : {
                  color: '#fff',
                  background: '#00CC70'
                }
          }
        }}
        message={<span id="message-id">{snackmsg}</span>}
      />
    </div>
  );
}

export default PositionedSnackbar;
