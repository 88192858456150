import React, { memo, useEffect, useState, useRef } from 'react';
import { Select, Radio, Icon, Tooltip, Switch, Row } from 'antd';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridItem from 'src/components/Grid/GridItem.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import CustomAddSelect from 'src/components/CustomAddSelect/CustomAddSelect.jsx';
import CustomSelectMulti from 'src/components/CustomSelectMulti/CustomSelectMulti.jsx';
import { formaterCheck } from 'src/utils/formater.js';
import Button from 'src/components/CustomButtons/Button.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import IconButton from '@material-ui/core/IconButton';
import convert from 'convert-units';
import Barcode from 'react-barcode';
import { exportComponentAsPNG } from 'react-component-export-image';

const { Option } = Select;

var dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: [
    {
      name: 'Unit',
      value: 'unit'
    }
  ]
};

const ComponentToPrint = React.forwardRef((props, ref) => (
  <div
    style={{ display: 'flex', minWidth: '75mm', maxWidth: '78mm' }}
    ref={ref}
  >
    <Barcode displayValue={true} value={props.codemenu} />
  </div>
));

const MenuItems = allprops => {
  const mainForm = useRef();
  const printBarCompRef = useRef();
  let {
    state,
    props,
    handleOpenMenu,
    submitForm,
    onError,
    changeView,
    handleDeleteMenuItem,
    handleRestoreMenuItem,
    onUseDimCheck,
    updateMenuItemCost,
    onResetState
  } = allprops;

  const onChangeEvtMenu = eN => {
    let e = Object.assign({}, eN);
    if (e.target.id === 'qty') {
      var value = e.currentTarget.value;
      if (value[value.length - 1] === ',') {
        value = value.substr(0, value.length - 1) + '.';
      }
      if (!value) {
        value = '0';
      }
      if (!isNaN(value)) {
        if (value !== '0.0') {
          value = (Math.round(parseFloat(value) * 100) / 100).toString();
        }
        setState(prevState => ({
          ...prevState,
          qtymenu: value
        }));
      }
    } else if (e.target.id === 'code') {
      mainForm.current['codemenu'].value = e.currentTarget.value;
      menuitemState.codemenu = e.currentTarget.value;
    } else if (e.target.id === 'description') {
      mainForm.current['descriptionmenu'].value = e.currentTarget.value;
      menuitemState.descriptionmenu = e.currentTarget.value;
    } else if (e.target.id === 'name') {
      if (e.currentTarget.value.length === 1) {
        e.currentTarget.value = e.currentTarget.value.toUpperCase();
      }
      if (e.currentTarget.value.length > 30) {
        e.currentTarget.value = e.currentTarget.value.slice(0, -1);
      }
      mainForm.current['namemenu'].value = e.currentTarget.value;
      menuitemState.namemenu = e.currentTarget.value;
      menuitemState.productNameError = false;
    } else if (e.target.id === 'sellPrice') {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        mainForm.current['sellPrice'].value = value;
        menuitemState.sellPrice = parseFloat(value);
      }
    } else if (e.target.id === 'agentPrice') {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        mainForm.current['agentPrice'].value = value;
        menuitemState.agentPrice = parseFloat(value);
      }
    } else if (e.target.id === 'specialPrice') {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        mainForm.current['specialPrice'].value = value;
        menuitemState.specialPrice = parseFloat(value);
      }
    } else if (e.target.id === 'costPrice') {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        mainForm.current['costPrice'].value = value;
        menuitemState.costPrice = value;
      }
    }
  };

  const onHelpMarker = (props, marker) => {
    props.setHelpMarker(marker);
  };

  const handleChangeType = (valueN, eventN, menuitemState, props, onError) => {
    let value = valueN;
    let event = Object.assign({}, eventN);
    if (value) {
      if (value !== 'Add ' + event.key + ' Type') {
        var menuItems = menuitemState.menuitems;
        var skuheader = value
          .replace(/\s/g, '')
          .slice(0, 3)
          .toUpperCase();
        var footer = '0000';
        var counter = 0;
        for (var i = 0; i < menuItems.length; i++) {
          if (
            menuItems[i].code &&
            menuItems[i].code.slice &&
            menuItems[i].code.slice(0, 3).toUpperCase() === skuheader
          ) {
            counter = counter + 1;
          }
        }
        footer =
          footer.slice(0, -(counter + 1).toString().length) +
          (counter + 1).toString();
        skuheader = skuheader + footer;
        setState(prevState => ({
          ...prevState,
          typemenuid: event.key,
          typemenu: value,
          codemenu: skuheader
        }));
      } else {
        const token = props.user.token;
        menuitemState.typeName = event.key;
        props
          .addTypes(menuitemState, token)
          .then(result => {
            if (
              result.data.results === 'A type with this name already exists.'
            ) {
              this.setState({
                loading: false
              });
              onError(result.data.results);
            } else if (result.data.results === 'Type name cannot be blank.') {
              this.setState({
                loading: false
              });
              onError(result.data.results);
            } else {
              var menuItems = menuitemState.menuitems;
              var skuheader = result.data.results.newType.name
                .replace(/\s/g, '')
                .slice(0, 3)
                .toUpperCase();
              var footer = '0000';
              var counter = 0;
              for (var i = 0; i < menuItems.length; i++) {
                if (
                  menuItems[i].code &&
                  menuItems[i].code.slice &&
                  menuItems[i].code.slice(0, 3).toUpperCase() === skuheader
                ) {
                  counter = counter + 1;
                }
              }
              footer =
                footer.slice(0, -(counter + 1).toString().length) +
                (counter + 1).toString();
              skuheader = skuheader + footer;
              setState(prevState => ({
                ...prevState,
                mainTypeInput: [],
                typeName: '',
                types: result.data.results.types,
                stockTableTypes: result.data.results.modtypes,
                typemenuid: result.data.results.newType._id,
                typemenu: result.data.results.newType.name,
                loading: false,
                cookingmenuitemState: {
                  ...prevState.cookingmenuitemState,
                  types: result.data.results.modtypes
                },
                codemenu: skuheader
              }));
              props.openSnackbar('Type Creation Success');
            }
          })
          .catch(err => {
            onError(err);
          });
      }
    }
  };

  const handleChangeBrand = (valueN, eventN, menuitemState, props, onError) => {
    let value = valueN;
    let event = Object.assign({}, eventN);
    if (value) {
      if (value !== 'Add ' + event.key + ' Brand') {
        setState(prevState => ({
          ...prevState,
          brandmenuid: event.key,
          brandmenu: value
        }));
      } else {
        const token = props.user.token;
        menuitemState.brandName = event.key;
        props
          .addBrands(menuitemState, token)
          .then(result => {
            setState(prevState => ({
              ...prevState,
              mainBrandInput: [],
              brandName: '',
              brands: result.data.results.brands,
              brandmenuid: result.data.results.newBrand._id,
              brandmenu: result.data.results.newBrand.name,
              loading: false
            }));
            props.openSnackbar('Brand Creation Success');
          })
          .catch(err => {
            onError(err);
          });
      }
    }
  };

  const addTypeFilter = (valueN, typeID, menuitemState) => {
    let value = valueN;
    let mainTypeInput = [];
    let typeList = Object.assign([], menuitemState.types);
    let typemenu = '';
    let typemenuid = typeID || '';
    if (value) {
      typemenu = value[0].toUpperCase() + value.slice(1);
      for (var i = 0; i < typeList.length; i++) {
        if (typeList[i].name.toUpperCase().includes(value.toUpperCase())) {
          mainTypeInput.push(typeList[i]);
        }
      }
      if (mainTypeInput.length === 0) {
        mainTypeInput.push({
          name: 'Add ' + value[0].toUpperCase() + value.slice(1) + ' Type',
          key: value[0].toUpperCase() + value.slice(1)
        });
      }
    } else {
      mainTypeInput = typeList;
    }

    setState(prevState => ({
      ...prevState,
      mainTypeInput: mainTypeInput,
      typemenuid: typemenuid,
      typemenu: typemenu
    }));
  };

  const addBrandFilter = (valueN, menuitemState) => {
    let value = valueN;
    let mainBrandInput = [];
    let brandList = Object.assign([], menuitemState.brands);
    let brandmenu = '';
    if (value) {
      brandmenu = value[0].toUpperCase() + value.slice(1);
      for (var i = 0; i < brandList.length; i++) {
        if (brandList[i].name.toUpperCase().includes(value.toUpperCase())) {
          mainBrandInput.push(brandList[i]);
        }
      }
      if (mainBrandInput.length === 0) {
        mainBrandInput.push({
          name: 'Add ' + value[0].toUpperCase() + value.slice(1) + ' Brand',
          key: value[0].toUpperCase() + value.slice(1)
        });
      }
    } else {
      mainBrandInput = brandList;
    }

    setState(prevState => ({
      ...prevState,
      mainBrandInput: mainBrandInput,
      brandmenuid: '',
      brandmenu: brandmenu
    }));
  };

  const handleChange = eventN => {
    let event = Object.assign({}, eventN);
    if (event.target.name === 'Type') {
      setState(prevState => ({
        ...prevState,
        type: event.target.value
      }));
    }
    if (event.target.name === 'supplier') {
      var value = event.target.value;
      if (!value[value.length - 1]) {
        setState(prevState => ({
          ...prevState,
          multiOpenSup: false
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          supplier: value
        }));
      }
    }
    if (event.target.name === 'dimension') {
      var serving = '';
      if (event.target.value === 'unit') {
        serving = '1';
      }
      setState(prevState => ({
        ...prevState,
        serving: serving,
        dimension: event.target.value,
        dimtypes: dimSelectTypes[event.target.value],
        servingType: dimSelectTypes[event.target.value][1]
          ? dimSelectTypes[event.target.value][1].value
          : '',
        dimType: dimSelectTypes[event.target.value][1]
          ? dimSelectTypes[event.target.value][1].value
          : ''
      }));
    }
    if (event.target.name === 'dimentiontype') {
      setState(prevState => ({
        ...prevState,
        dimType: event.target.value
      }));
    }
    if (event.target.name === 'servingtype') {
      setState(prevState => ({
        ...prevState,
        servingType: event.target.value
      }));
    }
  };

  const handleChangeMenu = (eventN, key, menuitemState, props) => {
    let event = Object.assign({}, eventN);
    if (event.target.name === 'color') {
      setState(prevState => ({
        ...prevState,
        colormenu: event.target.value
      }));
    }
    if (event.target.name === 'mainmenu') {
      if (event.target.value === 'No Catalogue') {
        if (menuitemState.userlistbuttonmenu === 'EDIT PRODUCT') {
          props.openSnackbar('Cant change Product to Stock Item');
          return;
        }
        setState(prevState => ({
          ...prevState,
          mainmenusmenu: event.target.value
        }));
      } else {
        if (menuitemState.mainmenu[key.key].menubtns) {
          if (menuitemState.mainmenu[key.key].menubtns[0]) {
            if (
              menuitemState.mainmenu[key.key].menubtns[0].name !== 'No Category'
            ) {
              menuitemState.mainmenu[key.key].menubtns.unshift({
                _id: 'No Category',
                name: 'No Category'
              });
            }
          } else {
            menuitemState.mainmenu[key.key].menubtns = [
              {
                _id: 'No Category',
                name: 'No Category'
              }
            ];
          }
        }
        setState(prevState => ({
          ...prevState,
          mainbtnsmenu: [],
          menubuttons: menuitemState.mainmenu[key.key].menubtns,
          mainmenusmenu: event.target.value
        }));
      }
    }
  };

  const handleChangeRoles = eventN => {
    let event = Object.assign({}, eventN);
    if (!event.target.value[event.target.value.length - 1]) {
      setState(prevState => ({
        ...prevState,
        multiOpen: false
      }));
    } else {
      for (var i = 0; i < event.target.value.length; i++) {
        if (event.target.value[i] === 'No Category') {
          if (event.target.value[i + 1]) {
            event.target.value = [event.target.value[i + 1]];
          } else {
            event.target.value = ['No Category'];
          }
          break;
        }
      }
      setState(prevState => ({
        ...prevState,
        mainbtnsmenu: event.target.value
      }));
    }
  };

  const printBrother = recordN => {
    let record = Object.assign({}, recordN);
    if (window.device) {
      if (window.device.platform === 'Android') {
        record.orientation = 'PORTRAIT';
        window.plugin.brotherPrinter.setPrinter(
          record,
          () => {
            function printImage(imageData) {
              let separatorIdx = parseInt(imageData.indexOf(','));
              if (separatorIdx !== -1) {
                imageData = imageData.substring(separatorIdx + 1);
              }
              window.plugin.brotherPrinter.printViaSDK(
                imageData, //base64
                callbackValue => {
                  alert(JSON.stringify(callbackValue));
                },
                error => {
                  alert(JSON.stringify(error));
                }
              );
            }
            try {
              var canvas = document.createElement('canvas');
              exportComponentAsPNG(printBarCompRef, {
                filename: false,
                html2CanvasOptions: { canvas: canvas }
              })
                .then(result => {
                  printImage(canvas.toDataURL('image/bmp'));
                })
                .catch(err => {
                  alert(JSON.stringify(err));
                });
            } catch (error) {
              alert(error);
            }
          },
          error => {
            alert(JSON.stringify(error));
          }
        );
      } else {
        alert('Android Device not Detected');
      }
    } else {
      alert('Android Device not Detected');
    }
  };

  const handleChangeListing = eventN => {
    let event = Object.assign({}, eventN);
    onResetState(setState, event.target.value);
  };

  let socketListner = null;
  const componentDidMount = () => {
    if (
      window.cordova &&
      window.device &&
      window.device.platform === 'iOS' &&
      window.socketscan &&
      window.socketscan.addListener
    ) {
      socketListner = null;
      socketListner = window.socketscan.addListener;
      socketListner(
        'notifications',
        success => {
          const notification = JSON.parse(success);
          if (notification.name === 'initializeComplete') {
            console.log('Capture initialization completed');
          } else if (notification.name === 'deviceArrival') {
            console.log('device arrival: ', notification.deviceName);
          } else if (notification.name === 'deviceRemoval') {
            console.log('no device connected');
          } else if (notification.name === 'decodedData') {
            const decodedData = notification.decodedData
              .map(c => String.fromCharCode(c))
              .join('');
            console.log('decodedData: ', decodedData);
            mainForm.current['codemenu'].value = decodedData.toString();
            setState(prevState => ({
              ...prevState,
              codemenu: decodedData.toString()
            }));
          }
        },
        error => {
          console.log('notification error: ', error);
        }
      );
    }
  };

  const componentWillUnmount = () => {
    socketListner = null;
  };

  const [menuitemState, setState] = useState(state);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      costPrice: state.costPrice
    }));
    componentDidMount();
    return () => componentWillUnmount();
  }, [state.costPrice]);

  return (
    <Card className={props.classes.cardExpand}>
      <CardBody style={{ paddingRight: '4em', zIndex: 1 }}>
        {menuitemState.userlistbuttonmenu === 'EDIT PRODUCT' ? (
          <div
            style={{
              right: '1em',
              marginTop: '0.5em',
              position: 'absolute',
              zIndex: 1
            }}
          >
            {menuitemState.active ? (
              <Tooltip
                placement="top"
                title={'Delete Product From Product List'}
              >
                <div
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteMenuItem(menuitemState);
                  }}
                  style={{
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '15px'
                  }}
                >
                  <Button
                    style={{
                      textTransform: 'initial',
                      border: '1.5px solid red',
                      marginLeft: '2px',
                      color: 'red',
                      marginTop: '-1em',
                      padding: '1em',
                      width: '3.5em',
                      backgroundColor: 'white',
                      borderRadius: '3em'
                    }}
                  >
                    <Icon
                      type="delete"
                      style={{
                        marginTop: '-0.1em',
                        marginRight: '-0.2em',
                        fontSize: '16px',
                        color: 'red'
                      }}
                    />
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                placement="top"
                title={'Restore Product To Product List'}
              >
                <div
                  onClick={e => {
                    e.stopPropagation();
                    handleRestoreMenuItem(menuitemState);
                  }}
                  style={{
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '15px'
                  }}
                >
                  Restore
                  <IconButton
                    style={{
                      border: '1px solid green',
                      marginLeft: '2px',
                      padding: '16px'
                    }}
                  >
                    {
                      <Icon
                        type="rollback"
                        style={{
                          position: 'absolute',
                          fontSize: '17px',
                          color: 'green'
                        }}
                      />
                    }
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </div>
        ) : null}
        <Card className={props.classes.cardExpandBody}>
          <CardBody>
            <form ref={mainForm}>
              {props.user.ecommerceActive ? (
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} style={{ marginTop: '-1em' }}>
                    <CustomSelect
                      labelText="Product Listing"
                      id="listing"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled:
                          menuitemState.userlistbuttonmenu === 'EDIT PRODUCT'
                            ? true
                            : false,
                        onClick: () => onHelpMarker(props, 'listing'),
                        onChange: (e, key) => handleChangeListing(e),
                        value: menuitemState.listing
                      }}
                      menuItems={[
                        {
                          value: 'menu',
                          name: 'Point of Sale'
                        },
                        {
                          value: 'ecommerce',
                          name: 'E-commerce'
                        }
                      ]}
                    />
                  </GridItem>
                  <GridItem
                    xs={8}
                    sm={8}
                    md={8}
                    style={{ marginTop: '-1em' }}
                  />
                </GridContainer>
              ) : null}
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ marginTop: '-1em' }}>
                  <CustomInput
                    labelText="Product Name"
                    id="name"
                    autoFocus={true}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={
                      (!menuitemState.namemenu ||
                        menuitemState.productNameError) &&
                      menuitemState.submitInProgress
                    }
                    errorText={
                      menuitemState.productNameError
                        ? 'Duplicate Product Name found'
                        : 'Product name required'
                    }
                    inputProps={{
                      name: 'namemenu',
                      defaultValue: menuitemState.namemenu,
                      onClick: () => onHelpMarker(props, 'menuitemsname'),
                      onChange: e => onChangeEvtMenu(e)
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ marginTop: '-1em' }}>
                  <CustomInput
                    labelText="Product Description"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: 'descriptionmenu',
                      defaultValue: menuitemState.descriptionmenu,
                      onClick: () => onHelpMarker(props, 'menuitemsdesc'),
                      onChange: e => onChangeEvtMenu(e),
                      maxLength: 10
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {menuitemState.listing !== 'menu' ? (
                  <GridItem xs={3} sm={3} md={3}>
                    <CustomAddSelect
                      id="productbrandadd"
                      dataSource={menuitemState.mainBrandInput.map(
                        (item, key) =>
                          item && (
                            <Option key={item.key} value={item.name}>
                              {item.name}
                            </Option>
                          )
                      )}
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={
                        !menuitemState.brandmenuid &&
                        menuitemState.submitInProgress
                      }
                      errorText={'Product Brand required'}
                      style={{ width: '100%' }}
                      optionLabelProp="value"
                      selectProps={{
                        value: menuitemState.brandmenu
                      }}
                      onSelect={(v, e) =>
                        handleChangeBrand(v, e, menuitemState, props, onError)
                      }
                      onSearch={v => addBrandFilter(v, menuitemState)}
                      onFocus={() => addBrandFilter(false, menuitemState)}
                      placeholder={'Product Brand'}
                    />
                  </GridItem>
                ) : null}
                <GridItem xs={3} sm={3} md={3}>
                  <CustomAddSelect
                    id="producttypeadd"
                    dataSource={menuitemState.mainTypeInput.map(
                      (item, key) =>
                        item && (
                          <Option key={item.key} value={item.name}>
                            {item.name}
                          </Option>
                        )
                    )}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={
                      !menuitemState.typemenuid &&
                      menuitemState.submitInProgress
                    }
                    errorText={'Product Type required'}
                    style={{ width: '100%' }}
                    optionLabelProp="value"
                    selectProps={{
                      value: menuitemState.typemenu
                    }}
                    onSelect={(v, e) =>
                      handleChangeType(v, e, menuitemState, props, onError)
                    }
                    onSearch={v => addTypeFilter(v, false, menuitemState)}
                    onFocus={() =>
                      addTypeFilter(
                        menuitemState.typemenu,
                        menuitemState.typemenuid,
                        menuitemState
                      )
                    }
                    placeholder={'Product Type'}
                  />
                </GridItem>
                <GridItem
                  xs={4}
                  sm={4}
                  md={4}
                  style={{ marginTop: '-1em' }}
                  key={'code' + menuitemState.codemenu}
                >
                  <Row>
                    <CustomInput
                      labelText="SKU (Stock Keeping Unit)"
                      id="code"
                      formControlProps={{
                        fullWidth: false
                      }}
                      error={
                        !menuitemState.codemenu &&
                        menuitemState.submitInProgress
                      }
                      errorText={'Product SKU required'}
                      inputProps={{
                        name: 'codemenu',
                        defaultValue: menuitemState.codemenu,
                        onClick: () => onHelpMarker(props, 'menuitemscode'),
                        onChange: e => onChangeEvtMenu(e)
                      }}
                    />

                    <Tooltip
                      placement="top"
                      title={
                        'Use the semicolon (;) character to separate multiple SKU codes'
                      }
                    >
                      <IconButton
                      //style={{ marginTop: '1.8em', marginLeft: '-1.2em' }}
                      >
                        <Icon
                          type="info-circle"
                          style={{
                            fontSize: '17px',
                            color: 'grey'
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {state.printer && menuitemState.codemenu && (
                      <Button onClick={() => printBrother(state.printer)}>
                        Print
                      </Button>
                    )}
                  </Row>
                </GridItem>
                <GridItem xs={3} sm={3} md={3} style={{ marginTop: '-1em' }}>
                  {false &&
                  menuitemState.userlistbuttonmenu !== 'EDIT PRODUCT' ? (
                    <CustomSelectMulti
                      labelText="Suppliers"
                      id="supplier"
                      valueObject={menuitemState.oSupplier || false}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        open: menuitemState.multiOpenSup,
                        value: menuitemState.supplier,
                        onChange: e => handleChange(e),
                        onClose: e =>
                          setState(prevState => ({
                            ...prevState,
                            multiOpenSup: false
                          })),
                        onOpen: e =>
                          setState(prevState => ({
                            ...prevState,
                            multiOpenSup: true
                          }))
                      }}
                      menuItems={menuitemState.suppliers || []}
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem
                  xs={3}
                  sm={3}
                  md={3}
                  style={{ marginTop: '-1em' }}
                  key={'costPrice' + menuitemState.costPrice}
                >
                  <CustomInput
                    labelText={
                      menuitemState.menuadd1
                        ? 'Automated Cost Price'
                        : 'Cost Price'
                    }
                    id="costPrice"
                    showPrefix={'R'}
                    error={
                      menuitemState.costPrice < 0 &&
                      menuitemState.submitInProgress
                    }
                    errorText={'Product Cost required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: 'costPrice',
                      defaultValue: menuitemState.costPrice,
                      onClick: () => onHelpMarker(props, 'menuitemssell'),
                      onChange: e => onChangeEvtMenu(e)
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={3}
                  sm={3}
                  md={3}
                  style={{ marginTop: '-1em' }}
                  key={'sellPrice' + menuitemState.sellPrice}
                >
                  <Tooltip
                    placement="top"
                    title={
                      'Leave the field blank to manually add the price upon Sale'
                    }
                  >
                    <CustomInput
                      labelText="Sell Price"
                      id="sellPrice"
                      showPrefix={'R'}
                      success={
                        'Leave the field blank to manually add the price upon Sale'
                      }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: 'sellPrice',
                        defaultValue: menuitemState.sellPrice,
                        onClick: () => onHelpMarker(props, 'menuitemssell'),
                        onChange: e => onChangeEvtMenu(e)
                      }}
                    />
                  </Tooltip>
                </GridItem>
                {menuitemState.listing === 'ecommerce' &&
                props.user.orgid === 'arôfc666498' ? (
                  <GridItem
                    xs={3}
                    sm={3}
                    md={3}
                    style={{ marginTop: '-1em' }}
                    key={'agentPrice' + menuitemState.agentPrice}
                  >
                    <Tooltip placement="top">
                      <CustomInput
                        labelText="Agent Price"
                        id="agentPrice"
                        showPrefix={'R'}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          name: 'agentPrice',
                          defaultValue: menuitemState.agentPrice,
                          onClick: () => onHelpMarker(props, 'menuitemssell'),
                          onChange: e => onChangeEvtMenu(e)
                        }}
                      />
                    </Tooltip>
                  </GridItem>
                ) : null}
                {menuitemState.listing !== 'ecommerce' ? (
                  <GridItem xs={3} sm={3} md={3} style={{ marginTop: '1em' }}>
                    <Tooltip
                      placement="top"
                      title={'Sell the product by Weight / Volume.'}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            style={{ margin: '1em' }}
                            checked={menuitemState.useDim}
                            onChange={e => {
                              var check = onUseDimCheck(e);
                              if (check) {
                                onError(check, 'Product Error');
                                return;
                              }
                              setState(prevState => ({
                                ...prevState,
                                useDim: e,
                                itemasstockformformenuitem: false,
                                itemasmenulast: false,
                                cooking: false
                              }));
                            }}
                          />
                        }
                        label="Sold By Weight / Volume"
                      />
                    </Tooltip>
                  </GridItem>
                ) : (
                  <GridItem
                    xs={3}
                    sm={3}
                    md={3}
                    style={{ marginTop: '-1em' }}
                    key={'specialPrice' + menuitemState.specialPrice}
                  >
                    {' '}
                    <Tooltip placement="top">
                      <CustomInput
                        labelText="Special Price"
                        id="specialPrice"
                        showPrefix={'R'}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          name: 'specialPrice',
                          defaultValue: menuitemState.specialPrice,
                          onClick: () => onHelpMarker(props, 'menuitemssell'),
                          onChange: e => onChangeEvtMenu(e)
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            style={{ margin: '1em' }}
                            checked={menuitemState.specialActive}
                            onChange={e => {
                              setState(prevState => ({
                                ...prevState,
                                specialActive: e
                              }));
                            }}
                          />
                        }
                        label="Special Active"
                      />
                    </Tooltip>
                  </GridItem>
                )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4} style={{ marginTop: '-1em' }}>
                  <CustomSelect
                    labelText="Catalogue"
                    id="mainmenu"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => onHelpMarker(props, 'menuitems'),
                      onChange: (e, key) =>
                        handleChangeMenu(e, key, menuitemState, props),
                      value: menuitemState.mainmenusmenu
                    }}
                    menuItems={menuitemState.mainmenu}
                  />
                </GridItem>
                <GridItem xs={8} sm={8} md={8} style={{ marginTop: '-1em' }}>
                  {menuitemState.mainmenusmenu &&
                  menuitemState.mainmenusmenu !== 'No Catalogue' ? (
                    <GridContainer>
                      <GridItem xs={9} sm={9} md={9}>
                        <CustomSelectMulti
                          labelText="Category"
                          id="menu"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => handleChangeRoles(e),
                            value: menuitemState.mainbtnsmenu,
                            open: menuitemState.multiOpen,
                            onClose: e =>
                              setState(prevState => ({
                                ...prevState,
                                multiOpen: false
                              })),
                            onOpen: e =>
                              setState(prevState => ({
                                ...prevState,
                                multiOpen: true
                              }))
                          }}
                          menuItems={menuitemState.menubuttons}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        {menuitemState.mainbtnsmenu.length > 0 &&
                        menuitemState.mainbtnsmenu[0] !== 'No Category' ? (
                          <CustomSelect
                            labelText="Colour"
                            id="color"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onClick: () =>
                                onHelpMarker(props, 'menuitemstype'),
                              onChange: e =>
                                handleChangeMenu(e, null, menuitemState, props),
                              value: menuitemState.colormenu
                            }}
                            menuItems={[
                              {
                                value: '#2E67F8',
                                name: ' Lightsaber Blue'
                              },
                              {
                                value: '#08FF08',
                                name: 'Fluorecent Green'
                              },
                              {
                                value: '#FFEF00',
                                name: 'Canary Yellow'
                              },
                              {
                                value: '#FC4C02',
                                name: 'Halloween Orange'
                              },
                              {
                                value: '#D6001C',
                                name: 'Christmas Red'
                              },
                              {
                                value: '#1FCECB',
                                name: 'Robin Egg Blue'
                              },
                              {
                                value: '#C724B1',
                                name: 'Neon Purple'
                              },
                              {
                                value: '#FB48C4',
                                name: 'Neon Pink'
                              },
                              {
                                value: '#26D07C',
                                name: 'Spring Green'
                              },
                              {
                                value: '#DBE2E9',
                                name: 'Chrome'
                              },
                              {
                                value: '#E0707C',
                                name: 'Grapefruit Pink'
                              },
                              {
                                value: '#A2E4B8',
                                name: 'Mint Green'
                              },
                              {
                                value: '#11FFEE',
                                name: 'Fluorescent Blue'
                              },
                              {
                                value: '#9873AC',
                                name: 'Lavender'
                              }
                            ]}
                          />
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  ) : null}
                </GridItem>
              </GridContainer>
              <div style={{ right: '25px', marginTop: '2em' }}>
                {menuitemState.userlistbuttonmenu !== 'EDIT PRODUCT' ? (
                  <div style={{ display: 'grid' }}>
                    <FormControlLabel
                      style={{ marginTop: '1em' }}
                      control={
                        <Radio.Group
                          onChange={e => {
                            setState(prevState => ({
                              ...prevState,
                              itemasmenucompo: e.target.value,
                              itemasstockformformenuitem: false,
                              cooking: false,
                              itemasmenulast: false,
                              stockitems: []
                            }));
                          }}
                          value={menuitemState.itemasmenucompo}
                          style={{ marginLeft: '1em' }}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      }
                      labelPlacement="start"
                      label={
                        menuitemState.useDim
                          ? 'Will this product use a Stock Item ? '
                          : 'Will this product use existing products already created ? '
                      }
                    />
                    {!menuitemState.itemasmenucompo && !menuitemState.useDim ? (
                      <FormControlLabel
                        style={{ marginTop: '1em' }}
                        control={
                          <Radio.Group
                            onChange={e => {
                              setState(prevState => ({
                                ...prevState,
                                itemasstockformformenuitem: e.target.value,
                                itemasmenulast: !e.target.value,
                                itemasmenucompo: false
                              }));
                            }}
                            value={menuitemState.itemasstockformformenuitem}
                            style={{ marginLeft: '1em' }}
                          >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        }
                        labelPlacement="start"
                        label="Do you want to manage stock levels of this product ? "
                      />
                    ) : null}
                    {!menuitemState.itemasmenucompo && !menuitemState.useDim ? (
                      <div style={{ display: 'grid' }}>
                        <FormControlLabel
                          style={{ marginTop: '1em' }}
                          control={
                            <Radio.Group
                              onChange={e => {
                                setState(prevState => ({
                                  ...prevState,
                                  cooking: e.target.value,
                                  itemasmenulast: prevState.itemasstockformformenuitem
                                    ? false
                                    : e.target.value
                                }));
                              }}
                              value={menuitemState.cooking}
                              style={{ marginLeft: '1em' }}
                            >
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          }
                          labelPlacement="start"
                          label={
                            menuitemState.listing === 'ecommerce'
                              ? 'Will this Product have Variants ? '
                              : 'Will this Product have Modifiers ? '
                          }
                        />
                      </div>
                    ) : null}
                    {menuitemState.itemasmenucompo && !menuitemState.useDim ? (
                      <div style={{ display: 'grid' }}>
                        <FormControlLabel
                          style={{ marginTop: '1em' }}
                          control={
                            <Radio.Group
                              onChange={e =>
                                setState(prevState => ({
                                  ...prevState,
                                  cooking: e.target.value
                                }))
                              }
                              value={menuitemState.cooking}
                              style={{ marginLeft: '1em' }}
                            >
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          }
                          labelPlacement="start"
                          label={
                            menuitemState.listing === 'ecommerce'
                              ? 'Will this Product have Variants ? '
                              : 'Will this Product have Modifiers ? '
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div style={{ display: 'grid' }}>
                    {menuitemState.mainmenusmenu !== 'No Catalogue' &&
                    menuitemState.mainmenusmenu &&
                    menuitemState.listing !== 'ecommerce' ? (
                      <div style={{ display: 'grid' }}>
                        {/*
                                                <FormControlLabel
                                                    style={{ marginTop: "1em" }}
                                                    control={
                                                        <Radio.Group
                                                            onChange={(e) => {
                                                                setState(prevState => ({
                                                                    ...prevState, itemasmenucompo: e.target.value, itemasmenulast: false
                                                                }))
                                                            }}
                                                            value={menuitemState.itemasmenucompo}
                                                            style={{ marginLeft: "1em" }}>
                                                            <Radio value={true}>Yes</Radio>
                                                            <Radio value={false}>No</Radio>
                                                        </Radio.Group>
                                                    }
                                                    labelPlacement="start"
                                                    label="Will this product use existing products already created ? "
                                                />
                                                <FormControlLabel
                                                    style={{ marginTop: "1em" }}
                                                    control={
                                                        <Radio.Group
                                                            onChange={(e) => {
                                                                if (!e.target.value) {
                                                                    onError('This will remove all existing Modifiers', 'Warning');
                                                                }
                                                                setState(prevState => ({
                                                                    ...prevState, cooking: e.target.value, itemasmenulast: prevState.itemasmenucompo ? false : e.target.value
                                                                }))
                                                            }
                                                            }
                                                            value={menuitemState.cooking}
                                                            style={{ marginLeft: "1em" }}>
                                                            <Radio value={true}>Yes</Radio>
                                                            <Radio value={false}>No</Radio>
                                                        </Radio.Group>
                                                    }
                                                    labelPlacement="start"
                                                    label="Will this Product have Modifiers ? "
                                                />
                                                */}
                        <FormControlLabel
                          style={{ marginTop: '1em' }}
                          control={
                            <Radio.Group
                              value={menuitemState.qtycheck}
                              onChange={e =>
                                setState(prevState => ({
                                  ...prevState,
                                  qtycheck: e.target.value
                                }))
                              }
                              style={{ marginLeft: '1em' }}
                            >
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          }
                          labelPlacement="start"
                          label="Will this product be sold in quantities of more than one at a time ? "
                        />
                        <FormControlLabel
                          style={{ marginTop: '1em' }}
                          control={
                            <Radio.Group
                              value={menuitemState.delivery}
                              onChange={e =>
                                setState(prevState => ({
                                  ...prevState,
                                  delivery: e.target.value
                                }))
                              }
                              style={{ marginLeft: '1em' }}
                            >
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          }
                          labelPlacement="start"
                          label="Are you going to deliver this product to the client ? "
                        />
                      </div>
                    ) : null}
                  </div>
                )}
                <div
                  style={{
                    margin: 'auto',
                    marginTop: '1.5em',
                    paddingTop: '1em',
                    borderTop: '1px solid rgba(229, 233, 242, 1)'
                  }}
                >
                  {menuitemState.userlistbuttonmenu !== 'EDIT PRODUCT' ? (
                    <Button
                      disabled={menuitemState.loading}
                      onClick={e => {
                        setState(prevState => ({
                          ...prevState,
                          submitInProgress: true,
                          productNameError: false
                        }));
                        if (!menuitemState.namemenu) {
                          props.openSnackbar('Name not found');
                          return;
                        }
                        if (
                          menuitemState.listing === 'ecommerce' &&
                          !menuitemState.brandmenu
                        ) {
                          props.openSnackbar('Brand not found');
                          return;
                        }
                        if (
                          menuitemState.namemenu &&
                          menuitemState.userlistbuttonmenu !== 'EDIT PRODUCT'
                        ) {
                          if (menuitemState.menuitems) {
                            var nameCheck = false;
                            for (
                              var j = 0;
                              j < menuitemState.menuitems.length;
                              j++
                            ) {
                              if (
                                menuitemState.menuitems[j].name ===
                                menuitemState.namemenu
                              ) {
                                nameCheck = true;
                                break;
                              }
                            }
                          }
                          if (nameCheck) {
                            setState(prevState => ({
                              ...prevState,
                              productNameError: true
                            }));
                            props.openSnackbar('Duplicate Product Name found');
                            return;
                          }
                        }
                        if (!menuitemState.typemenuid) {
                          props.openSnackbar('Type not found');
                          return;
                        }
                        if (!menuitemState.codemenu) {
                          props.openSnackbar('SKU not found');
                          return;
                        }
                        if (menuitemState.costPrice < 0) {
                          props.openSnackbar('Cost not found');
                          return;
                        }
                        if (menuitemState.sellPrice < 0) {
                          props.openSnackbar('Sell Price not found');
                          return;
                        }
                        if (!menuitemState.colormenu) {
                          menuitemState.colormenu = 'grey';
                        }
                        if (
                          menuitemState.itemasstockformformenuitem ||
                          menuitemState.cooking ||
                          menuitemState.itemasmenucompo ||
                          menuitemState.listing === 'ecommerce'
                        ) {
                          if (
                            menuitemState.itemasmenucompo &&
                            menuitemState.useDim
                          ) {
                            var stockitemsList =
                              menuitemState.cookingState.stockitems;
                            var stockitems = [];
                            var costTotal = menuitemState.costPrice;
                            var totalCostPrice = menuitemState.totalCostPrice;
                            var alterStockState = {};
                            var check = false;
                            for (var stockid in stockitemsList) {
                              if (
                                stockitemsList[stockid] &&
                                stockitemsList[stockid].name ===
                                  menuitemState.namemenu
                              ) {
                                check = true;
                                var stock = {};
                                stock._id = stockitemsList[stockid]._id;
                                stock.name = stockitemsList[stockid].name;
                                stock.dimension =
                                  stockitemsList[stockid].dimension;
                                stock.dimType =
                                  dimSelectTypes[
                                    stockitemsList[stockid].dimension
                                  ][1].value;
                                stock.qty = stockitemsList[stockid].servingType
                                  ? stockitemsList[stockid].serving.toString()
                                  : '1';
                                stock.code = stockitemsList[stockid].code;
                                stock.active = true;
                                var metricAmount = stock.qty;
                                if (stock.dimension !== 'unit') {
                                  metricAmount = convert(stock.qty)
                                    .from(stock.dimType)
                                    .to(stockitemsList[stockid].dimType);
                                }
                                var costPrice = stock._id
                                  ? stockitemsList[stockid].buyPrice
                                  : '0';
                                var totalCost =
                                  parseFloat(costPrice) *
                                  parseFloat(metricAmount);
                                stock.cost = totalCost.toFixed('2');
                                stockitems.push(stock);
                                costTotal = 0;
                                if (stockitems.length > 0) {
                                  for (var i = 0; i < stockitems.length; i++) {
                                    if (stockitems[i].cost) {
                                      costTotal += parseFloat(
                                        stockitems[i].cost
                                      );
                                    }
                                  }
                                  totalCostPrice = costTotal;
                                }
                                break;
                              }
                            }
                            if (!check) {
                              var skuheader = menuitemState.typemenu
                                .replace(/\s/g, '')
                                .slice(0, 3)
                                .toUpperCase();
                              var footer = '10000';
                              var counter = 0;
                              Object.keys(stockitemsList).map(
                                (stockid, key) => {
                                  if (
                                    stockitemsList[stockid].code
                                      .slice(0, 3)
                                      .toUpperCase() === skuheader
                                  ) {
                                    counter = counter + 1;
                                  }
                                }
                              );
                              footer =
                                footer.slice(
                                  0,
                                  -(counter + 1).toString().length
                                ) + (counter + 1).toString();
                              skuheader = skuheader + footer;
                              let newStockMenu = {
                                _id: 'addnew',
                                name: '',
                                active: true,
                                qty: '1',
                                dimension: 'mass',
                                dimType: 'g',
                                code: '',
                                cost: ''
                              };
                              stockitems.push(newStockMenu);
                              alterStockState = {
                                type: menuitemState.typemenu || '',
                                code: skuheader,
                                name: menuitemState.namemenu || '',
                                description: '',
                                supplier: menuitemState.supplier || [],
                                packSize: 1,
                                buyPrice: '0',
                                packBuyPrice: '0',
                                dimension: 'mass',
                                dimType: 'g',
                                serving: '',
                                servingType: '',
                                qty: '',
                                lowqty: '10',
                                stockSize: false,
                                sizes: []
                              };
                            }
                            menuitemState.stockitems = stockitems;
                            menuitemState.costTotal = costTotal;
                            menuitemState.costPrice = costTotal;
                            menuitemState.totalCostPrice = totalCostPrice;
                            menuitemState.alterStockState = alterStockState;
                          }
                          changeView(menuitemState);
                        } else {
                          submitForm(e, menuitemState);
                        }
                      }}
                      color="primary"
                      style={{
                        textTransform: 'initial',
                        fontSize: '13px',
                        marginLeft: '0.5em',
                        borderRadius: '3.5em',
                        float: 'right',
                        boxShadow: 'none',
                        border: '1px solid #00CC70'
                      }}
                    >
                      {menuitemState.listing !== 'ecommerce' &&
                      !menuitemState.itemasstockformformenuitem &&
                      !menuitemState.cooking &&
                      !menuitemState.itemasmenucompo
                        ? menuitemState.userlistbuttonmenu
                            .charAt(0)
                            .toUpperCase() +
                          menuitemState.userlistbuttonmenu.slice(1)
                        : 'Continue Setup'}
                    </Button>
                  ) : null}
                  {menuitemState.userlistbuttonmenu === 'EDIT PRODUCT' ? (
                    <Button
                      disabled={menuitemState.loading}
                      onClick={e => {
                        setState(prevState => ({
                          ...prevState,
                          submitInProgress: true,
                          productNameError: false
                        }));
                        if (!menuitemState.namemenu) {
                          props.openSnackbar('Name not found');
                          return;
                        }
                        if (
                          menuitemState.namemenu &&
                          menuitemState.userlistbuttonmenu !== 'EDIT PRODUCT'
                        ) {
                          if (menuitemState.menuitems) {
                            var nameCheck = false;
                            for (
                              var j = 0;
                              j < menuitemState.menuitems.length;
                              j++
                            ) {
                              if (
                                menuitemState.menuitems[j].name ===
                                menuitemState.namemenu
                              ) {
                                nameCheck = true;
                                break;
                              }
                            }
                          }
                          if (nameCheck) {
                            setState(prevState => ({
                              ...prevState,
                              productNameError: true
                            }));
                            props.openSnackbar('Duplicate Product Name found');
                            return;
                          }
                        }
                        if (!menuitemState.typemenuid) {
                          props.openSnackbar('Type not found');
                          return;
                        }
                        if (!menuitemState.codemenu) {
                          props.openSnackbar('SKU not found');
                          return;
                        }
                        if (menuitemState.costPrice < 0) {
                          props.openSnackbar('Cost not found');
                          return;
                        }
                        if (menuitemState.sellPrice < 0) {
                          props.openSnackbar('Sell Price not found');
                          return;
                        }
                        if (!menuitemState.colormenu) {
                          menuitemState.colormenu = 'grey';
                        }
                        submitForm(e, menuitemState);
                      }}
                      color="primary"
                      style={{
                        textTransform: 'initial',
                        fontSize: '13px',
                        marginLeft: '0.5em',
                        borderRadius: '3.5em',
                        float: 'right',
                        boxShadow: 'none',
                        border: '1px solid #00CC70'
                      }}
                    >
                      {'Edit Product'}
                    </Button>
                  ) : null}

                  <Button
                    onClick={() => handleOpenMenu(false, menuitemState)}
                    color="primary"
                    style={{
                      textTransform: 'initial',
                      fontSize: '13px',
                      float: 'right',
                      borderRadius: '3.5em',
                      backgroundColor: 'rgba(229, 233, 242, 0.9)',
                      border: '1px solid rgba(229, 233, 242, 0.9)',
                      color: 'black',
                      boxShadow: 'none'
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </CardBody>
      <div style={{ zIndex: -1 }}>
        <ComponentToPrint
          codemenu={menuitemState.codemenu}
          ref={printBarCompRef}
        />
      </div>
    </Card>
  );
};

export default memo(MenuItems);
