import React, { useEffect, useState } from 'react';
import GridItem from 'src/components/Grid/GridItem.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';

const dimSelectTypes = {
    volume: [
        {
            name: 'L',
            value: 'l'
        },
        {
            name: 'ml',
            value: 'ml'
        }
    ],
    mass: [
        {
            name: 'Kg',
            value: 'kg'
        },
        {
            name: 'g',
            value: 'g'
        }
    ],
    length: [
        {
            name: 'm',
            value: 'm'
        },
        {
            name: 'cm',
            value: 'cm'
        },
        {
            name: 'mm',
            value: 'mm'
        }
    ],
    unit: []
};

const handleChange = eventN => {
    let event = Object.assign({}, eventN);
    if (event.target.name === 'Type') {
        setState(prevState => ({
            ...prevState,
            type: event.target.value
        }));
    }
    if (event.target.name === 'supplier') {
        setState(prevState => ({
            ...prevState,
            supplier: event.target.value
        }));
    }
    if (event.target.name === 'dimension') {
        var serving = '';
        if (event.target.value === 'unit') {
            serving = '1';
        }
        setState(prevState => ({
            ...prevState,
            stockSize: false,
            sizes: [],
            serving: serving,
            packSize: 1,
            buyPrice: '0',
            packBuyPrice: '0',
            dimension: event.target.value,
            dimtypes: dimSelectTypes[event.target.value],
            servingType: dimSelectTypes[event.target.value][1]
                ? dimSelectTypes[event.target.value][1].value
                : '',
            dimType: dimSelectTypes[event.target.value][1]
                ? dimSelectTypes[event.target.value][1].value
                : ''
        }));
    }
    if (event.target.name === 'dimentiontype') {
        setState(prevState => ({
            ...prevState,
            dimType: event.target.value
        }));
    }
    if (event.target.name === 'servingtype') {
        setState(prevState => ({
            ...prevState,
            servingType: event.target.value
        }));
    }
};

const onChangeEvt = eN => {
    let e = Object.assign({}, eN);
    if (e.target.id === 'qty') {
        var value = e.currentTarget.value;
        if (value[value.length - 1] === ',') {
            value = value.substr(0, value.length - 1) + '.';
        }
        if (!value) {
            value = '0';
        }
        if (!isNaN(value)) {
            if (value !== '0.0') {
                value = (Math.round(parseFloat(value) * 100) / 100).toString();
            }
            setState(prevState => ({
                ...prevState, qty: value
            }));
        }
    } else if (e.target.id === 'lowqty') {
        var value = e.currentTarget.value;
        if (value[value.length - 1] === ',') {
            value = value.substr(0, value.length - 1) + '.';
        }
        if (!value) {
            value = '0';
        }
        if (!isNaN(value)) {
            if (value !== '0.0') {
                value = (Math.round(parseFloat(value) * 100) / 100).toString();
            }
            setState(prevState => ({
                ...prevState,
                lowqty: value
            }));
        }
    } else if (e.target.id === 'serving') {
        var value = e.currentTarget.value;
        if (value[value.length - 1] === ',') {
            value = value.substr(0, value.length - 1) + '.';
        }
        if (!value) {
            value = '0';
        }
        if (!isNaN(value)) {
            if (value !== '0.0') {
                value = (Math.round(parseFloat(value) * 100) / 100).toString();
            }
            setState(prevState => ({
                ...prevState,
                serving: value
            }));
        }
    } else if (e.target.id === 'code') {
        setState(prevState => ({
            ...prevState,
            code: e.currentTarget.value
        }));
    } else if (e.target.id === 'description') {
        setState(prevState => ({
            ...prevState,
            description: e.currentTarget.value
        }));
    } else if (e.target.id === 'name') {
        if (e.currentTarget.value.length === 1) {
            e.currentTarget.value = e.currentTarget.value.toUpperCase();
        }
        setState(prevState => ({
            ...prevState,
            name: e.currentTarget.value
        }));
    } else if (e.target.id === 'buyPrice') {
        setState(prevState => ({
            ...prevState,
            buyPrice: e.currentTarget.value
        }));
    }
};

const submitForm = (props, onError, onCompleteStockAdd, key, modKey, cookInsKey, stockKey, cookState, stockVariant) => {
    setState(prevState => ({
        ...prevState,
        loading: true
    }));
    let stockitem = oState.state;
    const token = props.user.token;
    if (!stockitem.name) {
        props.openSnackbar('No Name Found');
        return;
    }
    if (!stockitem.type) {
        props.openSnackbar('No Type Found');
        return;
    }
    if (stockitem.packBuyPrice < 1) {
        stockitem.packBuyPrice = '0';
    }
    if (stockitem.packSize < 1) {
        stockitem.packSize = 1;
    }
    if (stockitem.dimension !== 'unit' && stockitem.packBuyPrice > 0) {
        stockitem.buyPrice = (parseFloat(stockitem.packBuyPrice) / parseFloat(stockitem.packSize) / 1).toString();
    } else {
        stockitem.buyPrice = stockitem.packBuyPrice;
    }
    delete stockitem.key;
    delete stockitem.types;
    delete stockitem.dimtypes;
    delete stockitem.modKeyList;
    if (!stockitem.buyPrice) stockitem.buyPrice = '0';
    if (!stockitem.dimension) stockitem.dimension = 'unit';
    if (stockitem.dimension !== 'unit' && !stockitem.dimType) stockitem.dimension = 'unit';
    if (!stockitem.qty) stockitem.qty = '0';
    if (!stockitem.serving) stockitem.serving = '1';
    if (!stockitem.lowqty) stockitem.lowqty = '10';
    if (!stockitem.code) stockitem.code = '';
    if (stockitem.supplier === 'none') stockitem.supplier = [];
    for (var su = 0; su < stockitem.supplier.length; su++) {
        if (stockitem.supplier[su] === "none") {
            stockitem.supplier = [];
            break;
        }
    }
    if (stockitem.dimension !== 'unit' && stockitem.serving && !stockitem.servingType) stockitem.servingType = stockitem.dimType;
    props
        .addStockItem(stockitem, token)
        .then(res => {
            setState(prevState => ({
                ...prevState,
                loading: false
            }));
            if (res.data.results.error) {
                onError(res.data.results.error);
            } else {
                onCompleteStockAdd(res.data.results, key, modKey, cookInsKey, stockKey, cookState, stockVariant);
            }
        })
        .catch(err => {
            setState(prevState => ({
                ...prevState,
                loading: false
            }));
            onError(err);
        });
};

let oState = {};
let setState;

let initState = {
    type: '',
    code: '',
    name: '',
    description: '',
    supplier: [],
    packSize: 1,
    buyPrice: '0',
    packBuyPrice: '0',
    dimension: 'unit',
    dimType: '',
    serving: '',
    servingType: '',
    qty: '',
    lowqty: '10',
    stockSize: false,
    sizes: []
};
const MiniFormBody = allprops => {
    let {
        resetFilterOnCancel,
        stockitemKey,
        stockname,
        props,
        types,
        onError,
        onCompleteStockAdd,
        modKey,
        cookInsKey,
        stockKey,
        cookState,
        stockVariant
    } = allprops;
    initState.name = stockname;
    [oState.state, setState] = useState(initState);
    return (
        <Card className={props.classes.cardExpand}>
            <CardHeader
                style={{ height: '3em' }}
            >
                {' '}
                <div style={{ display: 'inline-flex'}}>
                    <h4 style={{color:"black" }}
                        className={props.classes.cardTitleWhite}
                    >
                        {'Add Stock Item'}
                    </h4>
                </div>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6} style={{ marginTop: "-2.5em" }}>
                        <CustomInput
                            labelText="Item Name*"
                            id="stockname"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e =>
                                    onChangeEvt(e),
                                value: oState.state.name
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} style={{ marginTop: "-2.5em" }}>
                        <CustomSelect
                            labelText="Type*"
                            id="Type"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e => handleChange(e),
                                value: oState.state.type
                            }}
                            menuItems={types}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4} style={{ marginTop: "-2.5em" }}>
                        <CustomInput
                            labelText="SKU"
                            id="code"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e =>
                                    onChangeEvt(e),
                                value: oState.state.code
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} style={{ marginTop: "-2.5em" }}>
                        <CustomInput
                            labelText="Initial Stock*"
                            id="qty"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e =>
                                    onChangeEvt(e),
                                value: oState.state.qty
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} style={{ marginTop: "-2.5em" }}>
                        <CustomSelect
                            labelText="Dimension*"
                            id="dimension"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e =>
                                    handleChange(e),
                                value: oState.state.dimension
                            }}
                            menuItems={[
                                {
                                    name: 'Volume',
                                    value: 'volume'
                                },
                                {
                                    name: 'Mass',
                                    value: 'mass'
                                },
                                {
                                    name: 'Length',
                                    value: 'length'
                                },
                                {
                                    name: 'Unit',
                                    value: 'unit'
                                }
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </CardBody>
            <CardFooter style={{ marginLeft: '2em', marginTop: '-0.5em',display:"block" }}>
                <GridContainer style={{display:"block"}}>
                    <Button style={{ textTransform: 'initial', borderRadius: "3em", float: 'right',boxShadow:"none",marginLeft:"0.8em",marginRight:"1em" }}
                     onClick={() =>
                        submitForm(
                            props,
                            onError,
                            onCompleteStockAdd,
                            stockitemKey,
                            modKey,
                            cookInsKey,
                            stockKey,
                            cookState,
                            stockVariant
                        )} color="primary" loading={oState.state.loading}>
                        {'Create Stock Item'}
                    </Button>
                    <Button
                    onClick={() => resetFilterOnCancel(stockitemKey, modKey, cookInsKey, stockKey, cookState)}
                    color="primary" style={{ textTransform: 'initial', backgroundColor: "rgba(229, 233, 242, 0.9)", color: 'black', borderRadius: "3em", float: 'right',boxShadow:"none" }}
                >
                    Cancel
    </Button>
                </GridContainer>
            </CardFooter>
        </Card>
    );
};

export default MiniFormBody;