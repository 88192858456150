import PropTypes from 'prop-types';
import React, { createRef, useState, useEffect, memo } from 'react';
import PinItem from './PinItem';

/**
 */
const PinInput = memo((props) => {
  let values = props.value;
  let length = [];
  let elements = [];
  for (var i = 0; i < props.length; i += 1) {
    length.push(i);
  }

  const componentDidMount = () => {
    
  }

  const focus = () => {
    if (props.length) elements[0].focus();
  }

  const onBackspace = (index) => {
    if (index > 0) {
      elements[index - 1].focus();
    }
  }

  useEffect(() => {
    componentDidMount()
}, []);

  return (
    <div style={props.style} className='pincode-input-container'>
      {length.map((e, i) => (
        <PinItem
          initialValue={values[i] ? values[i] : ''}
          ref={n => (elements[i] = n)}
          key={i}
          disabled={props.disabled}
          onBackspace={() => onBackspace(i)}
          secret={props.secret || false}
          type={props.type}
          inputMode={props.inputMode}
          validate={props.validate}
          inputStyle={props.inputStyle}
          inputFocusStyle={props.inputFocusStyle}
          autoSelect={props.autoSelect}
          regexCriteria={props.regexCriteria}
        />
      ))}
    </div>
  );
});

PinInput.propTypes = {
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    length: PropTypes.number.isRequired,
    type: PropTypes.string,
    onComplete: PropTypes.func,
    validate: PropTypes.func,
    secret: PropTypes.bool,
    disabled: PropTypes.bool,
    focus: PropTypes.bool,
    onChange: PropTypes.func,
    inputMode: PropTypes.string,
    style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    inputStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    inputFocusStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    autoSelect: PropTypes.bool,
    regexCriteria: PropTypes.any,
  };

PinInput.defaultProps = {
  initialValue: '',
  type: 'numeric',
  secret: false,
  validate: null,
  focus: false,
  disabled: false,
  onChange: () => { },
  onComplete: () => { },
  inputMode: undefined,
  style: {},
  inputStyle: {},
  inputFocusStyle: {},
  autoSelect: true,
  regexCriteria: /^[a-zA-Z0-9]+$/,
};

export default PinInput;