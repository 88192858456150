import React, { memo, useEffect, useState } from 'react';
import { Icon, Table, Input, Select } from 'antd';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import StyledTableNoPagi from 'src/components/Table/StyledTableNoPagi';
import CustomAddSelect from 'src/components/CustomAddSelect/CustomAddSelect.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import ObjectID from 'bson-objectid';
import convert from 'convert-units';
import { formaterCheck } from 'src/utils/formater.js';
const { Column } = Table;
const { Option } = Select;
const InputGroup = Input.Group;

const dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: []
};

let oVariantsRef = {};
const TableBody = allprops => {
  let oState;
  let setState;
  const removeVariantStock = (nameN) => {
    let stockName = Object.assign({}, nameN);
    let stockVariants = oState.state.stockVariants;
    oState.removeVariantPI(stockVariants[stockName.key]);
    stockVariants.splice(stockName.key, 1);
    for (let j = 0; j < stockVariants.length; j++) {
      stockVariants[j].key = j;
    };
    if (stockVariants.length === 0) {
      addVariantStock(stockVariants);
    } else {
      setState(prevState => ({
        ...prevState,
        stockVariants: stockVariants
      }));
    }

  };

  const addVariantStock = (stockVariantsN) => {
    let stockVariants = stockVariantsN || oState.state.stockVariants;
    var type = 'Default';
    if (stockVariants.length > 0) {
      type = stockVariants[0].type;
    } else if (oState.state.stockTableTypes[0]) {
      type = oState.state.stockTableTypes[0].value;
    }
    let stockitems = oState.state.stockList;
    var skuheader = type.replace(/\s/g, '').slice(0, 3).toUpperCase();
    var footer = '10000';
    var counter = 0;
    Object.keys(stockitems).map(
      (stockid, key) => {
        if (stockitems[stockid].code.slice(0, 3).toUpperCase() === skuheader) {
          counter = counter + 1;
        }
      }
    );
    for (var i = 0; i < stockVariants.length; i++) {
      if (stockVariants[i].code.slice(0, 3).toUpperCase() === skuheader) {
        counter = counter + 1;
      }
    }
    if (oState.state.codemenu) {
      if (oState.state.codemenu.slice(0, 3).toUpperCase() === skuheader) {
        counter = counter + 1;
      }
    }
    footer =
      footer.slice(0, -(counter + 1).toString().length) +
      (counter + 1).toString();
    skuheader = skuheader + footer;
    var stock = {
      _id: ObjectID().toHexString(),
      name: '',
      key: stockVariants.length,
      type: type,
      code: skuheader,
      description: '',
      supplier: [],
      packSize: 1,
      buyPrice: '0',
      packBuyPrice: '0',
      dimension: 'unit',
      dimtypes: [],
      dimType: '',
      serving: '1',
      servingType: '',
      qty: '1',
      lowqty: '10',
      stockSize: false,
      sizes: [],
      newVarient: true
    }
    let currentPageStock = parseInt((stockVariants.length / 10).toString().split('.')[0]) + 1;
    stockVariants.push(stock);
    setState(prevState => ({
      ...prevState,
      stockVariants: stockVariants,
      currentPageStock: currentPageStock
    }));
  };

  const handleChange = (eventN, nameN) => {
    let stockName = Object.assign({}, nameN);
    let event = Object.assign({}, eventN);
    let stockVariants = oState.state.stockVariants;
    if (event.target.name === 'Type') {
      let stockitems = oState.state.stockList;
      var skuheader = event.currentTarget.firstChild.data.replace(/\s/g, '').slice(0, 3).toUpperCase();
      var footer = '10000';
      var counter = 0;
      Object.keys(stockitems).map(
        (stockid, key) => {
          if (stockitems[stockid].code.slice(0, 3).toUpperCase() === skuheader) {
            counter = counter + 1;
          }
        }
      );
      for (var i = 0; i < stockVariants.length; i++) {
        if (stockVariants[i].code.slice(0, 3).toUpperCase() === skuheader) {
          counter = counter + 1;
        }
      }
      if (oState.state.codemenu) {
        if (oState.state.codemenu.slice(0, 3).toUpperCase() === skuheader) {
          counter = counter + 1;
        }
      }
      footer =
        footer.slice(0, -(counter + 1).toString().length) +
        (counter + 1).toString();
      skuheader = skuheader + footer;
      stockVariants[stockName.key].type = event.target.value;
      stockVariants[stockName.key].code = skuheader;
      setState(prevState => ({
        ...prevState,
        stockVariants: stockVariants
      }));
    }
    if (event.target.name === 'dimension') {
      var serving = '1';
      if (event.target.value === 'unit') {
        serving = '1';
      }
      stockVariants[stockName.key].stockSize = event.target.value;
      stockVariants[stockName.key].sizes = [];
      stockVariants[stockName.key].serving = serving;
      stockVariants[stockName.key].dimension = event.target.value;
      stockVariants[stockName.key].dimtypes = dimSelectTypes[event.target.value];
      stockVariants[stockName.key].servingType = dimSelectTypes[event.target.value][1]
        ? dimSelectTypes[event.target.value][1].value
        : '';
      stockVariants[stockName.key].dimType = dimSelectTypes[event.target.value][1]
        ? dimSelectTypes[event.target.value][1].value
        : '';
      oState.updateVariantDim(stockVariants[stockName.key], true);
      setState(prevState => ({
        ...prevState,
        stockVariants: stockVariants
      }));
    }
    if (event.target.name === 'dimentiontype') {
      stockVariants[stockName.key].dimType = event.target.value;
      oState.updateVariantDim(stockVariants[stockName.key]);
      setState(prevState => ({
        ...prevState,
        stockVariants: stockVariants
      }));
    }
  };

  const onChangeEvt = (eN, nameN, keyN) => {
    let rowKey = keyN;
    let e = Object.assign({}, eN);
    let stockName = Object.assign({}, nameN);
    let stockVariants = oState.state.stockVariants;
    if (e.target.id.includes('intqty')) {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        oVariantsRef["intqty" + rowKey].value = value;
        stockVariants[stockName.key].qty = value;
      }
    } else if (e.target.id === 'packsize') {
      var value = e.currentTarget.value || '0';
      oVariantsRef["serving" + rowKey].value = Math.round(parseFloat(value) * 100) / 100;
      stockVariants[stockName.key].packSize = Math.round(parseFloat(value) * 100) / 100;
    } else if (e.target.id.includes('code')) {
      oVariantsRef["code" + rowKey].value = e.currentTarget.value;
      stockVariants[stockName.key].code = e.currentTarget.value;
    } else if (e.target.id.includes('name')) {
      if (e.currentTarget.value.length === 1) {
        e.currentTarget.value = e.currentTarget.value.toUpperCase();
      }
      oVariantsRef["name" + rowKey].value = e.currentTarget.value;
      stockVariants[stockName.key].name = e.currentTarget.value;
    } else if (e.target.id.includes('buyPrice')) {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        oVariantsRef["buyPrice" + rowKey].value = value;
        stockVariants[stockName.key].packBuyPrice = value;
        oState.updateVariantStockCost(stockVariants[stockName.key]);
      }
    } else if (e.target.id.includes('sellPrice')) {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        oVariantsRef["sellPrice" + rowKey].value = value;
        stockVariants[stockName.key].sellPrice = parseFloat(value);
      }
    }
  };

  const changePage = page => {
    setState(prevState => ({
      ...prevState, currentPageStock: page
    }));
  };

  const handleChangeSelect = (value, eventN, key, stockName) => {
    let event = Object.assign({}, eventN);
    let stockVariants = oState.state.stockVariants;
    if (value) {
      if (value !== 'Add ' + event.key + ' Variant') {
        var newvalue = event.key.split('|');
        var stockitem = oState.state.stockList[newvalue[0]]
        stockitem.key = stockName.key;
        stockVariants[stockName.key] = stockitem;
        setState(prevState => ({
          ...prevState,
          stockVariants: stockVariants
        }));
      } else {
        stockName.name = event.key;
        delete stockName.newVarient;
        stockVariants[stockName.key] = stockName;
        setState(prevState => ({
          ...prevState,
          stockVariants: stockVariants
        }));
      }
    }
  };

  const handleCompoSelectFilter = (valueN, keyN, stockName) => {
    let value = valueN;
    let selectitemsFilter = [];
    let key = keyN;
    let stockVariants = oState.state.stockVariants;
    let selectitems = Object.assign([], oState.state.cookingState.selectitems);
    if (value) {
      stockVariants[stockName.key].name = value[0].toUpperCase() + value.slice(1);
      for (var i = 0; i < selectitems.length; i++) {
        if (selectitems[i].value.toUpperCase().includes(value.toUpperCase())) {
          selectitemsFilter.push(selectitems[i]);
        }
      }
      if (selectitemsFilter.length === 0) {
        selectitemsFilter.push({
          value: 'Add ' + value[0].toUpperCase() + value.slice(1) + ' Variant',
          key: value[0].toUpperCase() + value.slice(1)
        });
      }
    } else {
      stockVariants[stockName.key].name = '';
      selectitemsFilter = selectitems;
    }
    setItemsFilter(selectitemsFilter);
    setState(prevState => ({
      ...prevState,
      stockVariants: stockVariants
    }));
  };

  let itemsFilter = [];
  let setItemsFilter;
  oState = {
    ...allprops
  };
  oState.state.currentPageStock = 1;
  oState.state.unitCheck = false;
  [itemsFilter, setItemsFilter] = useState([]);
  [oState.state, setState] = useState(oState.state);

  useEffect(() => {
    let check = false;
    for (var j = 0; j < oState.state.stockVariants.length; j++) {
      if (oState.state.stockVariants[j].dimension !== 'unit') {
        check = true;
      }
    }
    setState(prevState => ({
      ...prevState,
      unitCheck: check
    }));
  }, [JSON.stringify(oState.state.stockVariants)]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ display: 'inline-flex' }}>
            <h2>{'Creating ' + oState.state.namemenu}</h2>
            <div
              style={{ padding: '7px', zIndex: 8 }}
            > {'(' + oState.state.stockVariants.length + ' Variants)'}</div>
          </div>
        </GridItem>
      </GridContainer>
      <StyledTableNoPagi
        style={{ background: 'white' }}
        rowKey="_id"
        size="middle"
        pagination={{
          onChange: changePage,
          current: oState.state.currentPageStock,
          hideOnSinglePage: true,
          pageSize: 25,
          itemRender: (current, type, originalElement) => {
            if (type === 'prev') {
              return <a>Previous</a>;
            }
            if (type === 'next') {
              return <a>Next</a>;
            }
            return originalElement;
          },
          showTotal: (total, range) =>
            `Showing ${range[0]} - ${range[1]}  of ${total} items`
        }}
        dataSource={oState.state.stockVariants}
        footer={() =>
          <div style={{
            textAlign: 'center'
          }}>
            <a onClick={e => addVariantStock()} style={{
              textAlign: 'center',
              fontSize: '15px',
              color: 'green'
            }}>
              <Icon
                type="plus"
                style={{
                  fontSize: '16px',
                  color: 'green'
                }}
              />{" Add Variant"}</a>
          </div>
        }
      >
        <Column
          style={{ 'marginTop': '-0.5em' }}
          title="Variant Name"
          width="15em"
          align="left"
          render={(text, record, key) => (
            <div key={'name' + key} >
              {record.newVarient ?
                <CustomAddSelect
                  dataSource={itemsFilter.map(
                    (item) =>
                      item && (
                        <Option
                          key={item.key}
                          value={item.value}
                        >
                          {item.value}
                        </Option>
                      )
                  )}
                  formControlProps={{
                    fullWidth: true
                  }}
                  style={{ width: '100%' }}
                  optionLabelProp="value"
                  selectProps={{
                    value: record.name
                  }}
                  onSelect={(v, e) => handleChangeSelect(v, e, key, record)}
                  onSearch={(v) => handleCompoSelectFilter(v, key, record)}
                  onFocus={() => handleCompoSelectFilter(null, key, record)}
                  placeholder={"Variant name"}
                />
                /* <CustomInput
                   labelText=""
                   id={"name" + key}
                   formControlProps={{
                     style: { 'marginTop': '-0.5em' },
                     fullWidth: true
                   }}
                   inputRef={el => oVariantsRef["name" + key] = el}
                   inputProps={{
                     defaultValue: record.name,
                     onChange: e => onChangeEvt(e, record, key),
                   }}
                 /> */
                : <span>{record.name}</span>}
            </div>
          )}
          dataIndex="name"
          key="name"
        />
        <Column
          title="Type*"
          width="10em"
          render={(text, record, key) => (
            <div>
              {!record.newVarient ?
                <CustomSelect
                  labelText=""
                  id="Type"
                  formControlProps={{
                    style: { 'marginTop': '-0.5em' },
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => handleChange(e, record),
                    value: record.type
                  }}
                  menuItems={oState.state.stockTableTypes}
                />
                : null}
            </div>
          )}
        />
        <Column
          title="SKU"
          width="10em"
          render={(text, record, key) => (
            <div key={record.code}>
              {!record.newVarient ?
                <CustomInput
                  labelText=""
                  id={"code" + key}
                  formControlProps={{
                    style: { 'marginTop': '-0.5em' },
                    fullWidth: true
                  }}
                  inputRef={el => oVariantsRef["code" + key] = el}
                  inputProps={{
                    defaultValue: record.code,
                    onChange: e => onChangeEvt(e, record, key),
                  }}
                />
                : null}
            </div>
          )}
        />
        <Column
          title={oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' ? "Initial Stock*" : "In Stock"}
          width="6em"
          render={(text, record, key) => (
            <div>
              {!record.newVarient ?
                <CustomInput
                  labelText=""
                  id={"intqty" + key}
                  formControlProps={{
                    style: { 'marginTop': '-0.5em' },
                    fullWidth: true
                  }}
                  inputRef={el => oVariantsRef["intqty" + key] = el}
                  inputProps={{
                    disabled: oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' || record.newVarient ? false : true,
                    defaultValue: record.qty,
                    onChange: e => onChangeEvt(e, record, key),
                  }}
                />
                : null}
            </div>
          )}
        />
        {oState.state.listing === "ecommerce" ?
        <Column
          title="Initial Price"
          width="6em"
          render={(text, record, key) => (
            <div>
              {!record.newVarient ?
                <CustomInput
                  labelText=""
                  id={"sellPrice" + key}
                  showPrefix={'R'}
                  formControlProps={{
                    style: { 'marginTop': '-0.5em' },
                    fullWidth: true
                  }}
                  inputRef={el => oVariantsRef["sellPrice" + key] = el}
                  inputProps={{
                    defaultValue: record.sellPrice,
                    onChange: e => onChangeEvt(e, record, key)
                  }}
                />
                : null}
            </div>
          )}
        /> : null}
        <Column
          title="Initial Cost"
          width="6em"
          render={(text, record, key) => (
            <div>
              {!record.newVarient ?
                <CustomInput
                  labelText=""
                  id={"buyPrice" + key}
                  showPrefix={'R'}
                  formControlProps={{
                    style: { 'marginTop': '-0.5em' },
                    fullWidth: true
                  }}
                  inputRef={el => oVariantsRef["buyPrice" + key] = el}
                  inputProps={{
                    defaultValue: record.packBuyPrice,
                    onChange: e => onChangeEvt(e, record, key)
                  }}
                />
                : null}
            </div>
          )}
        />
        <Column
          title="Dimension*"
          width="6em"
          render={(text, record, key) => (
            <div>
              {!record.newVarient ?
                <CustomSelect
                  labelText=""
                  id="dimension"
                  formControlProps={{
                    style: { 'marginTop': '-0.5em' },
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e =>
                      handleChange(e, record),
                    value: record.dimension
                  }}
                  menuItems={[
                    {
                      name: 'Volume',
                      value: 'volume'
                    },
                    {
                      name: 'Mass',
                      value: 'mass'
                    },
                    {
                      name: 'Length',
                      value: 'length'
                    },
                    {
                      name: 'Unit',
                      value: 'unit'
                    }
                  ]}
                />
                : null}
            </div>
          )}
        />
        {oState.state.unitCheck ?
          <Column
            title="Pack Size*"
            width="7em"
            render={(text, record, key) => (
              <div>
                {!record.newVarient ?
                  <InputGroup compact style={record.dimension === 'unit' ? { display: 'none' } : {}}>
                    <CustomInput
                      labelText=""
                      id="packsize"
                      formControlProps={{
                        style: { width: '70%', 'marginTop': '-0.5em' },
                        fullWidth: false
                      }}
                      inputRef={el => oVariantsRef["serving" + key] = el}
                      inputProps={{
                        defaultValue: record.packSize,
                        onChange: e => onChangeEvt(e, record, key)
                      }}
                    />
                    <CustomSelect
                      labelText=""
                      id="dimentiontype"
                      formControlProps={{
                        style: { width: '20%', 'marginTop': '-0.5em' },
                        fullWidth: false
                      }}
                      inputProps={{
                        onChange: e => handleChange(e, record),
                        value: record.dimType
                      }}
                      menuItems={record.dimtypes}
                    />
                  </InputGroup>
                  : null}
              </div>
            )}
          />
          : null}
        <Column
          title="Action"
          width="5em"
          render={(text, record, key) => (
            <div>
              <Icon
                type="delete"
                style={{
                  fontSize: '25px',
                  color: 'red',
                  marginLeft: '1em'
                }}
                onClick={e => removeVariantStock(record)}
              />
            </div>
          )}
        />
      </StyledTableNoPagi>
    </div>
  );
};

export default memo(TableBody);
