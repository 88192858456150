const HelpMarkers = () => {
  return {
    /*******************  USERS ***********************/
    adduser: {
      name: 'Add User',
      awnser: 'Add a New User by filling in the form'
    },
    listuser: {
      name: 'List',
      awnser: 'A List of all the Users added with the Add User Form'
    },
    usersclerk: {
      name: 'Clerk',
      awnser: 'A Clerk user will be able to access the Dashboard Only'
    },
    usersadmin: {
      name: 'Admin',
      awnser:
        'A Admin user will be able to access the Admin Dashboard and have access to everything besides Users'
    },
    usersaupadmin: {
      name: 'Super Admin',
      awnser:
        'A Super Admin user will be able to access the Admin Dashboard and have access to everything'
    },
    usersname: {
      name: 'Name',
      awnser: 'The Name of the user to be added'
    },
    usersemail: {
      name: 'Email',
      awnser: 'The Email of the user and the login Username to be added'
    },
    usersnumber: {
      name: 'Number',
      awnser: 'The Mobile Number of the user to be added'
    },
    /******************** STOCK ITEMS ********************/
    addstockitem: {
      name: 'Add Stock Item',
      awnser: 'Add a New Stock Item by filling in the form'
    },
    liststockitem: {
      name: 'List',
      awnser: 'A List of all the Stock Items added with the Add Stock Item Form'
    },
    stockitemtype: {
      name: 'Type',
      awnser:
        'The Type of Stock Item and the Category the Stock Item will be placed'
    },
    stockitemcode: {
      name: 'Code',
      awnser:
        'The Code or Barcode that will be used to search for The Stock Item'
    },
    stockitemname: {
      name: 'Name',
      awnser: 'The Name that will be used to search for The Stock Item'
    },
    stockitemdesc: {
      name: 'Description',
      awnser: 'The Description that will be used for The Stock Item'
    },
    stockitembuy: {
      name: 'Buy',
      awnser:
        'The Price you bought The Stock Item per ( ml ) or ( g ) or ( mm ) or ( unit ), Depends on the Stock Item'
    },
    stockitemhand: {
      name: 'Stock',
      awnser:
        'The Amount of Stock you have on hand for The Stock Item per ( ml ) or ( g ) or ( mm ) or ( unit ), Depends on the Stock Item'
    },
    /******************** Products ********************/
    addmenuitem: {
      name: 'Add Menu Item',
      awnser: 'Add a New Menu Item by filling in the form'
    },
    listmenuitem: {
      name: 'List',
      awnser: 'A List of all the Menu Items added with the Add Menu Items Form'
    },
    menuitemstype: {
      name: 'Type',
      awnser:
        'The Type of Menu Item and the Category the Menu Item will be placed'
    },
    menuitemscode: {
      name: 'Code',
      awnser:
        'The Code or Barcode that will be used to search for The Menu Item'
    },
    menuitemsname: { 
      name: 'Name',
      awnser: 'The Name that will be used to search for The Menu Item'
    },
    menuitemssell: {
      name: 'Sell Price',
      awnser: 'The Sell Price you selling The Menu Item'
    },
    menuitemsdesc: {
      name: 'Description',
      awnser: 'The Description that will be used for The Menu Item'
    },
    menuitemsstockitem: {
      name: 'Stock Item',
      awnser:
        'The Stock Item that will be used for The Menu Item stock usesage list'
    },
    menuitemsqty: {
      name: 'Stock QTY',
      awnser:
        'The Amount of Stock to be usesd for The Menu Item per ( ml ) or ( g ) or ( mm ) or ( unit ), Depends on the Stock Item'
    }
  };
};

export default HelpMarkers;
