import $ from 'jquery';
import moment from 'moment';
const apiCall = 'vpos/api/vpos/';
const apiCallNon = 'vpos/api/non/vpos/';
const apiCalluser = 'vpos/api/user/';
const apiCallCustomer = 'vpos/api/customer/';
const apiCallEcomCustomer = 'vpos/api/ecomcustomer/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
console.log(process.env.REACT_APP_GLOBAL_SERVER);

export function resetOrg() {
  return dispatch =>
    dispatch({
      type: 'REMOVE_ORG_LOGIN'
    });
}

export function closeMenuHelpCard() {
  return dispatch =>
    dispatch({
      type: 'CLOSE_MENU_HELP'
    });
}
export function closeCookingHelpCard() {
  return dispatch =>
    dispatch({
      type: 'CLOSE_COOKING_HELP'
    });
}
export function closeSpecialHelpCard() {
  return dispatch =>
    dispatch({
      type: 'CLOSE_SPECIAL_HELP'
    });
}
export function updateToken(data) {
  return dispatch =>
    dispatch({
      type: 'UPDATE_TOKEN',
      data: data
    });
}

export function resetUser(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!token) {
        return resolve(
          dispatch({
            type: 'REMOVE_LOGIN'
          })
        );
      } else {
        $.ajax({
          type: 'GET',
          url: server + apiCalluser + 'logout',
          contentType: 'application/json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          },
          success: async data => {
            // dispatch({
            //   type: 'REMOVE_PREBILL_LOGIN'
            // })
            return resolve(
              dispatch({
                type: 'REMOVE_LOGIN'
              })
            );
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            if (xhr.responseText) {
              return reject({ message: xhr.responseText });
            } else if (!xhr.responseText) {
              return reject({ message: 'No Connection Found!' });
            }
          }
        });
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function switchKitchen() {
  return dispatch =>
    dispatch({
      type: 'SWITCH_TO_KITCHEN'
    });
}

export function clearDashCache(user, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/clearreset',
        data: JSON.stringify(user),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: 'REMOVE_SYNCDATA'
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else if (!xhr.responseText) {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function endSession(token, amount, isKitchen) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'users/sessionend?amount=' +
          amount +
          '&iskitchen=' +
          isKitchen,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          $.ajax({
            type: 'GET',
            url: server + apiCall + 'users/getuserscashupsummary',
            contentType: 'application/json',
            crossDomain: true,
            beforeSend: function(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: async data => {
              return resolve(
                dispatch({
                  type: '',
                  data: data
                })
              );
            },
            error: async (xhr, ajaxOptions, thrownError) => {
              if (xhr.responseText) {
                return reject({ message: xhr.responseText });
              } else {
                return reject({ message: 'No Connection Found!' });
              }
            }
          });
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function removeLogin() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      dispatch({
        type: 'REMOVE_SYNCDATA'
      });
      return resolve(
        dispatch({
          type: 'REMOVE_LOGIN'
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function resetPass(state) {
  let { email } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!email) return reject({ message: 'Please enter Email' });

      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'resetrequest',
        data: JSON.stringify(state),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        success: async data => {
          return resolve(
            dispatch({
              type: 'PASSWORD_RESET'
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText && xhr.status !== 200) {
            return reject({ message: xhr.responseText });
          } else if (!xhr.responseText) {
            return reject({ message: 'No Connection Found!' });
          } else {
            return resolve(
              dispatch({
                type: 'PASSWORD_RESET'
              })
            );
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function renewOrgPass(state, token) {
  let { repass } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        password: repass
      };

      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'resetorgpassword',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function login(state) {
  let { username, password } = state;
  let uuid;
  if (window.cordova) {
    uuid = window.device.uuid;
  } else {
    uuid = navigator.platform;
  }
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!username) return reject({ message: 'Enter Username' });
      if (!password) return reject({ message: 'Enter Password' });
      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'signinorg',
        // url: "http://192.168.0.181:3018/" + apiCalluser + 'signinorg',
        data: JSON.stringify({
          username: username,
          password: password,
          uuid: uuid
        }),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        success: async data => {
          var dis = '';
          if (password === 'pass') {
            dis = dispatch({
              type: '',
              data: data
            });
          } else {
            dis = dispatch({
              type: 'ORG_LOGIN',
              data: data
            });
          }
          return resolve(dis);
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function signupCustomer(data) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCallCustomer + 'signup',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function signinCustomer(data) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCallCustomer + 'signin',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        success: async data => {
          return resolve(
            dispatch({
              type: 'CUSTOMER_LOGIN',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateCustomer(data, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCallCustomer + 'updateuser',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: 'CUSTOMER_UPDATE',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function loginUser(state, token, users, deviceno, version) {
  let { username, password, qrCode } = state;
  let ios = false;
  if (navigator) {
    if (!navigator.serviceWorker) {
      ios = true;
    }
  }
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!username) return reject({ message: 'Enter Username' });
      if (!qrCode) {
        if (!password) return reject({ message: 'Enter Password' });
      }
      let query;
      if (qrCode) {
        query = {
          username: username,
          qrCode: qrCode,
          deviceno: deviceno,
          version: version,
          ios: ios
        };
      } else {
        query = {
          username: username,
          password: password,
          deviceno: deviceno,
          version: version,
          ios: ios
        };
      }
      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'signinuser',
        data: JSON.stringify(query),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          if (data.userDetails.role) {
            if (
              data.userDetails.role.isAdmin ||
              data.userDetails.role.isSupAdmin
            ) {
              data.userDetails.session = true;
            }
          }
          return resolve(
            dispatch({
              type: 'USER_LOGIN',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          const bcrypt = require('bcryptjs');
          const userIndex = users.findIndex(user => username === user.username);
          if (
            userIndex !== -1 &&
            users[userIndex] &&
            users[userIndex].role.isClerk
          ) {
            bcrypt.compare(
              password,
              users[userIndex].password,

              async (err, isMatch) => {
                if (err) console.log(err);
                if (isMatch) {
                  let data = {
                    userDetails: users[userIndex],
                    token: 'offline'
                  };
                  var dis = '';
                  if (password === '0123') {
                    dis = dispatch({
                      type: '',
                      data: data
                    });
                  } else {
                    if (data.userDetails.role) {
                      if (
                        data.userDetails.role.isAdmin ||
                        data.userDetails.role.isSupAdmin
                      ) {
                        data.userDetails.session = true;
                      }
                    }
                    dis = dispatch({
                      type: 'USER_LOGIN',
                      data: data
                    });
                  }
                  return resolve(dis);
                } else {
                  if (users[userIndex].password === password) {
                    let data = {
                      userDetails: users[userIndex],
                      token: 'offline'
                    };
                    var dis = '';
                    if (password === '0123') {
                      dis = dispatch({
                        type: '',
                        data: data
                      });
                    } else {
                      if (data.userDetails.role) {
                        if (
                          data.userDetails.role.isAdmin ||
                          data.userDetails.role.isSupAdmin
                        ) {
                          data.userDetails.session = true;
                        }
                      }
                      dis = dispatch({
                        type: 'USER_LOGIN',
                        data: data
                      });
                    }
                    return resolve(dis);
                  } else {
                    return reject({
                      message:
                        'Oops, it seems that you entered the wrong details'
                    });
                  }
                }
              }
            );
          } else if (
            userIndex !== -1 &&
            users[userIndex] &&
            !users[userIndex].role.isClerk
          ) {
            $.ajax({
              type: 'POST',
              url: server + apiCalluser + 'signinuser',
              data: JSON.stringify(query),
              contentType: 'application/json',
              dataType: 'json',
              crossDomain: true,
              beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
              },
              success: async data => {
                var dis = '';
                if (password === '0123') {
                  data.token = 'offline';
                  dis = dispatch({
                    type: '',
                    data: data
                  });
                } else {
                  if (data.userDetails.role) {
                    if (
                      data.userDetails.role.isAdmin ||
                      data.userDetails.role.isSupAdmin
                    ) {
                      data.userDetails.session = true;
                    }
                  }
                  dis = dispatch({
                    type: 'USER_LOGIN',
                    data: data
                  });
                }
                return resolve(dis);
              },
              error: async (xhr, ajaxOptions, thrownError) => {
                if (xhr.responseText) {
                  return reject({ message: xhr.responseText });
                } else {
                  return reject({ message: 'No Connection Found!' });
                }
              }
            });
          } else {
            return reject({ message: 'Something went wrong please try again' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function sessionStart(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'users/sessionstart',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: 'USER_LOGIN_SESSION',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function sessionStartFloat(token, float) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/sessionstartfloat',
        data: JSON.stringify({ float: float }),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: 'USER_LOGIN_SESSION',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function saveUserSettings(token, state) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var formData = {
        bill: state.bill,
        bar: state.bar,
        kitchen: state.kitchen
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/updateusersettings',
        data: JSON.stringify(formData),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: 'USER_PRINTER_SETTINGS',
              data: formData
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

// ***********ADMIN*************//
export function updateProfile(state, token) {
  let { email, name, mobile, password, orgid } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!email) return reject({ message: 'Enter Email' });
      if (!name) return reject({ message: 'Enter Name' });
      if (!mobile) return reject({ message: 'Enter Cell Number' });

      var formData = {
        name: name,
        email: email.toLowerCase(),
        mobile: mobile
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'user/updateprofile',
        data: JSON.stringify(formData),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          if (password && password !== '') {
            $.ajax({
              type: 'POST',
              url: server + apiCalluser + 'resetorgpassword',
              data: JSON.stringify({ orgid: orgid, password: password }),
              contentType: 'application/json',
              dataType: 'json',
              crossDomain: true,
              beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
              },
              success: async data => {
                var dis = '';
                dis = dispatch({
                  type: 'USER_UPDATE',
                  data: formData
                });
                return resolve(dis);
              },
              error: async (xhr, ajaxOptions, thrownError) => {
                if (xhr.responseText) {
                  return reject({ message: xhr.responseText });
                } else {
                  return reject({ message: 'No Connection Found!' });
                }
              }
            });
          } else {
            var dis = '';
            dis = dispatch({
              type: 'USER_UPDATE',
              data: formData
            });
            return resolve(dis);
          }
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getReports(token, start, end) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var formDetails = {
        startDate: start,
        endDate: end
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'report/getreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getUsersCashup(token, number, custom) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var formDetails = {
        startDate: number,
        custom: custom
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/getuserscashup',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getUsersCashupHistory(token, start, end) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var formDetails = {
        startDate: start,
        endDate: end
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/getuserscashuphist',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getUsersCashupReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/getuserscashupreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getUsersVoidReport(token, startDate, endDate, userId) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate,
        id: userId
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/getusersvoidreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getUserCashupTotal(token, id) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        id: id
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getusercashuptotal',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getUserCashupTotalMulti(token, ids) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        userids: ids
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getusercashuptotalmulti',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function emailCashup(token, receipt) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        receipt: receipt
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/emailcashup',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function emailCashupTotals(token, receipt) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        receipt: receipt
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/emailcashuptotals',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function doCashup(
  token,
  user,
  total,
  totals,
  cashCountValue,
  pettycash
) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        session: user.session,
        totals: totals,
        cashCountValue: cashCountValue || 0,
        user: user,
        session: user.session,
        total: total || 0,
        pettycash: pettycash || 0
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/docashup',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function doCashupReset(token, user) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        userid: user._id,
        sessionid: user.session._id
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/docashupreset',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getGroups(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'users/getgroups',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addUserGroup(state, token) {
  let { selectGroupRoles, groupName, role, direct } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!groupName) return reject({ message: '' });
      if (!selectGroupRoles.length > 0 && role === 'isAdmin')
        return reject({ message: 'Select atleast one Access Value' });

      const formDetails = {
        name: groupName,
        modes: selectGroupRoles,
        role: role,
        direct: direct
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'groups/addusergroup',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editGroup(state, token) {
  let { _id, groupName, selectGroupRoles, role, direct } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!groupName) return reject({ message: '' });
      if (!selectGroupRoles.length > 0 && role === 'isAdmin')
        return reject({ message: 'Select atleast one Access Value' });

      const formDetails = {
        _id: _id,
        name: groupName,
        modes: selectGroupRoles,
        role: role,
        direct: direct
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'groups/updategroup',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteGroup(state, token) {
  let { _id, active } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'groups/deletegroup',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getUsers(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'users/getusers',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addUser(state, token, orgid) {
  let {
    username,
    email,
    name,
    mobile,
    selectitem,
    loginType,
    userGroupRolesObject,
    canDiscount,
    canQRBill,
    QRCode
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!username) return reject({ message: '' });
      if (!name) return reject({ message: '' });
      if (!selectitem) return reject({ message: '' });
      var role = userGroupRolesObject[selectitem].role;
      if (!email && role === 'isDriver') {
        return reject({ message: '' });
      } else if (!email) {
        email = orgid + username;
      }

      const formDetails = {
        username: username,
        email: email.toLowerCase(),
        loginType: loginType,
        name: name,
        mobile: mobile,
        groupid: selectitem,
        canDiscount: canDiscount,
        canQRBill: canQRBill,
        QRCode: QRCode
      };

      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'adduser',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editUser(state, token, orgid) {
  let {
    _id,
    email,
    name,
    username,
    mobile,
    selectitem,
    userGroupRolesObject,
    loginType,
    canDiscount,
    canQRBill
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });
      if (!selectitem) return reject({ message: '' });
      var role;
      if (selectitem === 'isSuperAdmin') {
        role = 'isSuperAdmin';
      } else {
        role = userGroupRolesObject[selectitem].role;
      }
      if (!email && role === 'isDriver') {
        return reject({ message: '' });
      } else if (!email) {
        email = orgid + username;
      }
      if (role === 'isDriver') {
        username = email;
      }
      const formDetails = {
        _id: _id,
        email: email.toLowerCase(),
        name: name,
        mobile: mobile,
        groupid: selectitem,
        canDiscount: canDiscount,
        canQRBill: canQRBill,
        loginType: loginType
      };

      if (selectitem !== 'isSuperAdmin') {
        formDetails.groupid = selectitem;
      } else {
        delete formDetails.groupid;
      }

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/updateuser',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function resetUserPass(state, token) {
  let { _id, loginType } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        loginType: loginType
      };

      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'resetuserpassword',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function renewPass(state, token) {
  let { passReset } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        password: passReset
      };

      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'resetpassword',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: 'UPDATE_PASSWORD',
              data: state
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteUser(state, token) {
  let { _id, active } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'users/deleteuser',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addImportCustomer(resp, state, token) {
  let {
    createTabs,
    columnName,
    columnID,
    columnMobile,
    columnEmail,
    columnBalance
  } = state;

  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        resp: resp,
        tempState: {
          columnName: columnName,
          columnID: columnID,
          columnMobile: columnMobile,
          columnEmail: columnEmail,
          columnBalance: columnBalance
        },
        compo: createTabs
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'tabs/addimportcustomer',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getCustomers(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'tabs/getcustomers',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function createStatement(data, token, start, end, full) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      data.period = 'Full Period';
      if (!full) {
        data.period =
          start.slice(0, 4) +
          '/' +
          start.slice(4, 6) +
          '/' +
          start.slice(6, 8) +
          ' to ' +
          (end.slice(0, 4) + '/' + end.slice(4, 6) + '/' + end.slice(6, 8));
      }

      const formDetails = data;

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'accountpayment/createstatement',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addCustomers(state, token) {
  let { email, name, mobile, idno, tabAmount, tab, tabNo } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });

      const formDetails = {
        idno: idno,
        tabno: tabNo,
        tabAmount: tabAmount,
        tab: tab,
        email: email,
        name: name,
        mobile: mobile,
        active: true
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'tabs/addcustomers',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editCustomers(state, token, pay) {
  let {
    _id,
    email,
    name,
    mobile,
    idno,
    tabNo,
    tab,
    newTabAmount,
    payAmount,
    type
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });

      const formDetails = {
        _id: _id,
        idno: idno,
        tabno: tabNo,
        tab: tab,
        email: email,
        name: name,
        mobile: mobile,
        pay: pay
      };
      if (pay) {
        formDetails.tabAmount = newTabAmount;
        formDetails.payAmount = payAmount;
        formDetails.type = type;
      }

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'tabs/updatecustomers',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteCustomers(state, token) {
  let { _id, active } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: !active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'tabs/deletecustomers',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getReceiptsCustomer(token, startDate, endDate, id) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        id: id,
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getreceiptscustomer',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getStatementsCustomer(token, startDate, endDate, id, init) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        id: id,
        startDate: startDate,
        endDate: endDate,
        init: init
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'accountpayment/getstatementscustomer',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function emailReceipt(token, bill, _id) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        bill: bill
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/emailcustomerreceipt',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEcomCustomers(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'ecomcustomers/getecomcustomers',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addEcomCustomers(data, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCallEcomCustomer + 'signup',
        data: JSON.stringify(data),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editEcomCustomers(state, token, pay) {
  let { _id, username, name, mobile } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });

      const formDetails = {
        _id: _id,
        username: username,
        name: name,
        mobile: mobile
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecomcustomers/updateecomcustomers',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteEcomCustomers(state, token) {
  let { _id, active } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: !active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecomcustomers/deleteecomcustomers',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addStockItem(state, token) {
  let {
    type,
    code,
    name,
    description,
    supplier,
    buyPrice,
    packBuyPrice,
    packSize,
    qty,
    lowqty,
    dimension,
    dimType,
    serving,
    servingType,
    stockSize,
    sizes
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });
      if (!type) return reject({ message: '' });
      if (!dimension) return reject({ message: '' });
      if (dimension !== 'unit' && !dimType)
        return reject({ message: 'Select Dimension Type' });
      if (packBuyPrice < 0) return reject({ message: '' });
      if (dimension !== 'unit' && serving && !servingType)
        return reject({ message: 'Select Serving Type' });
      if (packSize < 1) packSize = 1;
      if (!qty) qty = '0';
      if (!serving) serving = '0';
      if (!lowqty) lowqty = '10';
      if (!code) code = '';

      if (dimension !== 'unit' && serving && !servingType) {
        servingType = dimType;
      }

      for (var su = 0; su < supplier.length; su++) {
        if (supplier[su] === 'none') {
          supplier = [];
          break;
        }
      }

      if (stockSize) {
        var check = false;
        var checkName = '';
        for (var j = 0; j < sizes.length; j++) {
          if (!sizes[j].name) {
            return reject({
              message: ''
            });
            break;
          }
          if (!sizes[j].qty) {
            sizes[j].qty = 1;
          }
          if (sizes[j].packSize) {
            sizes[j].dimType = dimType;
            sizes[j].qty = sizes[j].qty * packSize;
          }
          if (sizes[j].dimension !== 'unit' && !sizes[j].dimType) {
            sizes[j].dimType = dimType;
          }
          delete sizes[j].dimtypes;
        }
      } else {
        sizes = [];
      }

      let formDetails = {
        type: type,
        code: code,
        name: name,
        description: description,
        supplier: supplier,
        packBuyPrice: packBuyPrice,
        buyPrice: buyPrice,
        dimension: dimension,
        dimType: dimType,
        serving: parseFloat(serving),
        servingType: servingType,
        qty: parseFloat(qty),
        lowqty: parseFloat(lowqty),
        packSize: packSize,
        stockSize: stockSize,
        sizes: sizes
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'stockitems/addstockitem',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getStockItems(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'stockitems/getstockitems',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editStockItem(state, token) {
  let {
    _id,
    type,
    code,
    name,
    description,
    buyPrice,
    packBuyPrice,
    qty,
    lowqty,
    dimension,
    dimType,
    serving,
    servingType,
    supplier,
    packSize,
    stockSize,
    sizes
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: 'Enter Name' }, true);
      if (!type) return reject({ message: 'Select Type' });
      if (!dimension) return reject({ message: 'Select Dimension' });
      if (dimension !== 'unit' && !dimType)
        return reject({ message: 'Select Dimension Type' });
      if (packBuyPrice < 0) return reject({ message: 'Enter Buy Price' });
      if (dimension !== 'unit' && serving && !servingType)
        return reject({ message: 'Select Serving Type' });
      if (packSize < 1) packSize = 1;
      if (!qty) qty = '0';
      if (!serving) serving = '0';
      if (!lowqty) lowqty = '10';
      if (!code) code = '';

      if (dimension !== 'unit' && serving && !servingType) {
        servingType = dimType;
      }

      for (var su = 0; su < supplier.length; su++) {
        if (supplier[su] === 'none') {
          supplier = [];
          break;
        }
      }

      if (stockSize) {
        var check = false;
        var checkName = '';
        for (var j = 0; j < sizes.length; j++) {
          if (!sizes[j].name) {
            return reject({
              message: ''
            });
            break;
          }
          if (!sizes[j].qty) {
            sizes[j].qty = 1;
          }
          if (sizes[j].packSize) {
            sizes[j].dimType = dimType;
            sizes[j].qty = sizes[j].qty * packSize;
          }
          if (sizes[j].dimension !== 'unit' && !sizes[j].dimType) {
            sizes[j].dimType = dimType;
          }
          delete sizes[j].dimtypes;
        }
      } else {
        sizes = [];
      }

      const formDetails = {
        _id: _id,
        type: type,
        code: code,
        name: name,
        description: description,
        buyPrice: buyPrice,
        packBuyPrice: packBuyPrice,
        dimension: dimension,
        dimType: dimType,
        serving: parseFloat(serving),
        servingType: servingType,
        supplier: supplier,
        qty: parseFloat(qty),
        lowqty: parseFloat(lowqty),
        packSize: packSize,
        stockSize: stockSize,
        sizes: sizes
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'stockitems/updatestockitem',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteStockItem(state, token) {
  let { _id, active } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'stockitems/deletestockitem',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function handleEditStockAdjust(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'stockaudit/getstockaudit',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addStockAdjust(state, token) {
  let {
    _id,
    user,
    date,
    time,
    name,
    type,
    desc,
    oStockitems,
    stockadjustview
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!type) return reject({ message: '' });
      // if (!desc) return reject({ message: 'Enter Note' });
      var aStockItems = [];
      if (stockadjustview.length === 0) {
        var count = 0;
        Object.keys(oStockitems).forEach(function(key) {
          if (oStockitems[key].select) {
            var newObject = {};
            newObject._id = oStockitems[key]._id;
            newObject.name = oStockitems[key].name;
            newObject.amount = oStockitems[key].amount;
            if (type === 'Stock Count') {
              newObject.diff = oStockitems[key].amount
                ? (oStockitems[key].amount || 0) -
                  (oStockitems[key].stockQty || 0)
                : oStockitems[key].stockQty;
            }
            newObject.dim = oStockitems[key].dimType
              ? oStockitems[key].dimType
              : oStockitems[key].dimension;
            newObject.qty = oStockitems[key].stockQty;
            newObject.type = oStockitems[key].type;
            newObject.newamount = oStockitems[key].newamount;
            newObject.orgDimType = oStockitems[key].orgDimType;
            aStockItems.push(newObject);
            count = count + 1;
          }
        });
        if (count === 0) return reject({ message: 'Selected Stock To Adjust' });
      } else {
        if (stockadjustview.length > 0) {
          aStockItems = stockadjustview;
        } else {
          return reject({ message: 'Selected Stock To Adjust' });
        }
      }

      const formDetails = {
        _id: _id || '',
        name: name,
        type: type,
        desc: desc,
        date: date,
        time: time,
        user: user || 'Admin',
        waiting: false,
        stockitems: aStockItems
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'stockaudit/addstockaudit',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getOrderItems(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'purchaseorder/getpurchaseorder?startDate=' +
          startDate +
          '&endDate=' +
          endDate,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getOrders(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'orders/getecomorders?startDate=' +
          startDate +
          '&endDate=' +
          endDate,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateOrder(data, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = data;

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'orders/updateorder',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function createEcomInvoice(data, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = data;

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecominvoices/createecominvoice',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function createEcomShipment(data, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = data;

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecomshipments/createecomshipment',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEcomInvoices(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'ecominvoices/getecominvoices?startDate=' +
          startDate +
          '&endDate=' +
          endDate,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEcomShipments(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'ecomshipments/getecomshipments?startDate=' +
          startDate +
          '&endDate=' +
          endDate,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateEcomInvoice(data, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = data;

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecominvoices/updateecominvoice',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateEcomShipment(data, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = data;

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecomshipments/updateecomshipment',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getQuotes(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'quotes/getquotes?startDate=' +
          startDate +
          '&endDate=' +
          endDate,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getQuoteItems(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'quotes/getquoteitems',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getSupplierItems(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'suppliers/getsupplieritems',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEcomProducts(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'menuitems/getecomproducts',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function addQuote(state, token, sendMail) {
  let {
    name,
    email,
    customer,
    customername,
    quoteItems,
    ref,
    note,
    total,
    startDate,
    quoteId,
    _id
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (customer === 'none') return reject({ message: 'Select a customer' });
      if (!email && sendMail) return reject({ message: 'Enter email' });
      if (quoteItems.length === 0)
        return reject({ message: 'Select items to quote' });
      var aRemail = [];
      if (!Array.isArray(email)) {
        var splitMails = email.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          if (splitMails[i]) {
            aRemail.push(splitMails[i].replace(/\s/g, ''));
          }
        }
      }
      if (aRemail.length > 0) {
        email = aRemail;
      }
      const formDetails = {
        quoteId: quoteId,
        name: name,
        email: email,
        customer: customer,
        customername: customername,
        ref: ref,
        note: note,
        total: total,
        startDate: startDate,
        quoteItems: quoteItems,
        status: sendMail ? 'Awaiting Acceptance' : 'Draft',
        sendMail: sendMail
      };
      if (_id) {
        formDetails.quoteId = _id;
      }
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'quotes/addquote',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addOrderItem(state, token, sendMail) {
  let {
    name,
    email,
    supplier,
    supname,
    ref,
    note,
    total,
    startDate,
    expDate,
    stock,
    orderId
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!supplier) return reject({ message: 'Select Supplier' });
      if (!email && sendMail) return reject({ message: 'Enter email' });
      if (!validateEmail(email) && sendMail)
        return reject({ message: 'Enter A valid Email' });
      var aOrderItems = [];
      var count = 0;

      if (stock[supplier]) {
        Object.keys(stock[supplier]).forEach(function(key) {
          if (stock[supplier][key]) {
            if (stock[supplier][key].select) {
              if (!stock[supplier][key].total) {
                stock[supplier][key].total = 0;
              }
              stock[supplier][key].received = 0;
              aOrderItems.push(stock[supplier][key]);
              count = count + 1;
            }
          } else {
            return reject({
              message: 'Supplier item not Found:' + stock[supplier][key]
            });
          }
        });
      } else {
        return reject({ message: 'Supplier not Found' });
      }

      if (count === 0)
        return reject({ message: 'Select # Stock To Order with Total value' });
      var aRemail = [];
      if (email) {
        var splitMails = email.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          if (splitMails[i]) {
            aRemail.push(splitMails[i].replace(/\s/g, ''));
          }
        }
      }
      if (aRemail.length > 0) {
        email = aRemail;
      }
      const formDetails = {
        orderId: orderId,
        name: name,
        email: email,
        supplier: supplier,
        supname: supname,
        ref: ref,
        note: note,
        total: total,
        startDate: startDate,
        expDate: expDate,
        stockitems: aOrderItems,
        status: sendMail ? 'Awaiting Approval' : 'Draft',
        sendMail: sendMail
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'purchaseorder/addpurchaseorder',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addEcomOrder(state, token, sendMail) {
  let {
    name,
    email,
    customer,
    customerUsername,
    customerDetails,
    customerName,
    orderType,
    ref,
    note,
    total,
    startDate,
    shippingDate,
    items,
    orderId
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!customer) return reject({ message: 'Select Customer' });
      if (!email && sendMail) return reject({ message: 'Enter email' });
      if (!validateEmail(email) && sendMail)
        return reject({ message: 'Enter A valid Email' });
      if (items.length === 0)
        return reject({ message: 'No products in order' });
      let aRemail = [];
      if (email) {
        var splitMails = email.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          if (splitMails[i]) {
            aRemail.push(splitMails[i].replace(/\s/g, ''));
          }
        }
      }
      if (aRemail.length > 0) {
        email = aRemail;
      }
      const formDetails = {
        orderId: orderId,
        name: name,
        email: email,
        customer: customer,
        orderType: orderType,
        customerUsername: customerUsername,
        customerDetails: customerDetails,
        customerName: customerName,
        ref: ref,
        note: note,
        total: total,
        startDate: startDate,
        shippingDate: shippingDate,
        items: items,
        status: 'Pending',
        sendMail: sendMail
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'orders/addecomorder',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function convertPOToDraft(state, token, sendMail) {
  let { email, supplier, orderId } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!supplier) return reject({ message: 'Select Supplier' });
      if (!email && sendMail) return reject({ message: 'Enter email' });
      const formDetails = {
        orderId: orderId,
        status: 'Draft',
        sendMail: false
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'purchaseorder/addpurchaseorder',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function resendPurchaseOrder(id, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        id: id
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'purchaseorder/resendpurchaseorder',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function resendEcomInvoice(id, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        id: id
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecominvoices/resendecominvoice',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function resendEcomShipment(id, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        id: id
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecomshipments/resendecomshipment',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function resendQuote(id, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        id: id
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'purchaseorder/resendpurchaseorder',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function sendProofOfPayment(id, pop, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        id: id,
        pop: pop
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'purchaseorder/sendproofofpayment',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updatePo(state, token) {
  let { _id, status, stockadjustview } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        status: 'Complete',
        stockitems: stockadjustview
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'purchaseorder/updatepo',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addMultiMods(state, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'cooking/addmultimodifiers',
        data: JSON.stringify(state),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addMultiStockItems(state, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'stockitems/addmultistockitems',
        data: JSON.stringify(state),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addMenuItem(state, token) {
  let {
    listing,
    typemenu,
    typemenuid,
    brandmenu,
    brandmenuid,
    images,
    qtycheck,
    delivery,
    cooking,
    codemenu,
    namemenu,
    descriptionmenu,
    colormenu,
    sellPrice,
    agentPrice,
    specialPrice,
    specialActive,
    costPrice,
    stockitems,
    mainmenusmenu,
    mainbtnsmenu,
    cookIns,
    inputCount,
    variants,
    useDim
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!typemenu) return reject({ message: 'Select Type' });
      // if (!descriptionmenu) return reject({ message: 'Enter Description' });
      if (!namemenu) return reject({ message: 'Enter Name' });
      if (sellPrice < 0) return reject({ message: 'Enter Sell Price' });
      if (!costPrice) return reject({ message: 'Enter Cost Price' });
      if (!codemenu) codemenu = '';

      var check = false;
      var checkName = '';

      for (var j = 0; j < stockitems.length; j++) {
        if (stockitems[j].qty === '0' || stockitems[j].qty === '') {
          stockitems[j].qty = 1;
        } else {
          stockitems[j].qty = parseFloat(stockitems[j].qty);
        }
        if (!stockitems[j].cost) {
          stockitems[j].cost = '0';
        }
        if (!stockitems[j].name) {
          checkName = ' Blank Selection';
          check = true;
          break;
        } else if (!stockitems[j].dimension) {
          checkName = stockitems[j].name + ' Dimention';
          check = true;
          break;
        } else if (
          stockitems[j].dimension !== 'unit' &&
          !stockitems[j].dimType
        ) {
          checkName = stockitems[j].name + ' Dim Type';
          check = true;
          break;
        }
      }
      if (check) {
        return reject({
          message: 'Stock Item ' + checkName + ' is not Selected'
        });
      }

      if (mainbtnsmenu[0] === 'No Category') {
        mainbtnsmenu = [];
      }

      var newcooklist = [];
      for (var a = 0; a < cookIns.length; a++) {
        if (cookIns[a].cookid) {
          newcooklist.push(cookIns[a]);
        }
      }

      if (newcooklist.length === 0) {
        cooking = false;
      }

      const formDetails = {
        listing: listing,
        type: typemenu,
        brand: brandmenu,
        typeid: typemenuid,
        brandid: brandmenuid,
        images: images,
        qtycheck: qtycheck,
        delivery: delivery,
        cooking: cooking,
        code: codemenu,
        name: namemenu,
        description: descriptionmenu,
        color: colormenu,
        sellPrice: sellPrice,
        agentPrice: agentPrice,
        specialPrice: specialPrice,
        specialActive: specialActive,
        costPrice: costPrice,
        stockitems: stockitems,
        mainmenu: mainmenusmenu,
        menubtns: mainbtnsmenu,
        cookinslist: newcooklist,
        inputCount: cookIns.length,
        useDim: useDim,
        variants: variants || []
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menuitems/addmenuitem',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addImportMenuItem(resp, tempState, state, token) {
  let { mainmenusmenu, mainmenuscompo } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        resp: resp,
        mainmenu: mainmenusmenu,
        tempState: tempState,
        compo: mainmenuscompo
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menuitems/addimportmenuitem',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addImportMenuItemEcom(resp, tempState, state, token) {
  let { mainmenusmenu } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        resp: resp,
        mainmenu: mainmenusmenu,
        tempState: tempState
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menuitems/addimportmenuitemecom',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getMenuItems(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'menuitems/getmenuitems',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editMenuItem(state, token) {
  let {
    _idmenu,
    listing,
    typemenu,
    typemenuid,
    brandmenu,
    brandmenuid,
    images,
    qtycheck,
    delivery,
    cooking,
    codemenu,
    namemenu,
    descriptionmenu,
    colormenu,
    sellPrice,
    agentPrice,
    specialPrice,
    specialActive,
    costPrice,
    stockitems,
    mainmenusmenu,
    mainbtnsmenu,
    cookIns,
    inputCount,
    variants,
    useDim
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!typemenu) return reject({ message: 'Select Type' });
      // if (!descriptionmenu) return reject({ message: 'Enter Description' });
      if (!namemenu) return reject({ message: 'Enter Name' });
      if (sellPrice < 0) return reject({ message: 'Enter Sell Price' });
      if (!costPrice) return reject({ message: 'Enter Cost Price' });
      if (!codemenu) codemenu = '';

      var check = false;
      var checkName = '';
      for (var j = 0; j < stockitems.length; j++) {
        if (stockitems[j].qty === '0' || stockitems[j].qty === '') {
          stockitems[j].qty = 1;
        } else {
          stockitems[j].qty = parseFloat(stockitems[j].qty);
        }
        if (!stockitems[j].name) {
          checkName = ' Blank Selection';
          check = true;
          break;
        } else if (!stockitems[j].dimension) {
          checkName = stockitems[j].name + ' Dimention';
          check = true;
          break;
        } else if (
          stockitems[j].dimension !== 'unit' &&
          !stockitems[j].dimType
        ) {
          checkName = stockitems[j].name + ' Dim Type';
          check = true;
          break;
        }
      }
      if (check) {
        return reject({
          message: 'Stock Item ' + checkName + ' is not Selected'
        });
      }

      if (mainbtnsmenu[0] === 'No Category') {
        mainbtnsmenu = [];
      }

      var newcooklist = [];
      for (var a = 0; a < cookIns.length; a++) {
        if (cookIns[a].cookid) {
          newcooklist.push(cookIns[a]);
        }
      }

      if (newcooklist.length === 0) {
        cooking = false;
      }
      const formDetails = {
        _id: _idmenu,
        listing: listing,
        type: typemenu,
        brand: brandmenu,
        typeid: typemenuid,
        brandid: brandmenuid,
        images: images,
        qtycheck: qtycheck,
        delivery: delivery,
        cooking: cooking,
        code: codemenu,
        name: namemenu,
        description: descriptionmenu,
        color: colormenu,
        sellPrice: sellPrice,
        agentPrice: agentPrice,
        specialPrice: specialPrice,
        specialActive: specialActive,
        costPrice: costPrice,
        stockitems: stockitems,
        mainmenu: mainmenusmenu,
        menubtns: mainbtnsmenu,
        cookinslist: newcooklist,
        inputCount: cookIns.length,
        useDim: useDim,
        variants: variants,
        dimChange: false
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menuitems/updatemenuitem',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getVoidReasons(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'voidreasons/getvoidreasons',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addVoidReason(state, token) {
  let { name, description } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });
      //if (!description) return reject({ message: 'Enter Description' });

      const formDetails = {
        name: name,
        description: description
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'voidreasons/addvoidreason',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editVoidReason(state, token) {
  let { _id, name, description } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });
      //if (!description) return reject({ message: '' });

      const formDetails = {
        _id: _id,
        name: name,
        description: description
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'voidreasons/updatevoidreason',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteVoidReason(state, token) {
  let { _id } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: false
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'voidreasons/deletevoidreason',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function restoreVoidReason(state, token) {
  let { _id } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: true
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'voidreasons/deletevoidreason',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editMenuItemTable(record, token) {
  var formObject = {
    _id: record._id,
    name: record.name,
    type: record.type,
    typeid: record.typeid,
    brand: record.brand,
    brandid: record.brandid,
    mainmenu: record.mainmenu,
    menubtns: record.menubtns
  };

  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menuitems/updatemenuitem',
        data: JSON.stringify(formObject),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteMenuItem(state, token) {
  let { _id } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: false
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menuitems/deletemenuitem',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function restoreMenuItem(state, token) {
  let { _id } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: true
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menuitems/deletemenuitem',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addMenu(state, token) {
  let { namemenu } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!namemenu) return reject({ message: 'Enter Name' });

      const formDetails = {
        name: namemenu,
        active: true,
        menubtns: []
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menu/addmenu',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateBill(bill, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var form = {
        bill: bill
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/updatebill',
        data: JSON.stringify(form),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addMenuBtn(state, newItem, token, add, name, changeState) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var form = {
        menu: state,
        item: newItem,
        add: add,
        name: name,
        changeState: changeState
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menu/addmenubtn',
        data: JSON.stringify(form),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addMenuBtnItemSubCat(state, newItem, token, add) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var form = {
        menu: state,
        item: newItem,
        add: add
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menu/addmenubtnitemsubcat',
        data: JSON.stringify(form),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addMenuBtnCat(state, newmenubtn, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var form = {
        menu: state,
        newmenubtn: newmenubtn
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menu/addmenubtncat',
        data: JSON.stringify(form),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addMenuBtnCatSubCat(
  state,
  newmenubtn,
  token,
  add,
  check,
  newmenu
) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      var form = {
        menu: state,
        newmenubtn: newmenubtn,
        add: add,
        check: check,
        newmenu: newmenu
      };
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menu/addmenubtncatsubcat',
        data: JSON.stringify(form),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getMenuSubCat(subcat, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menu/getmenubtncat',
        data: JSON.stringify(subcat),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getMenu(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'menu/getmenu',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editMenu(state, token) {
  let {
    _id,
    typemenu,
    codemenu,
    namemenu,
    descriptionmenu,
    sellPrice,
    stockitems
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!typemenu) return reject({ message: 'Select Type' });
      // if (!descriptionmenu) return reject({ message: 'Enter Description' });
      if (!namemenu) return reject({ message: 'Enter Name' });
      if (!sellPrice) return reject({ message: 'Enter Sell Price' });
      if (stockitems.length <= 0)
        return reject({ message: 'Select Stock Items' });
      if (!codemenu) codemenu = '';

      const formDetails = {
        _id: _id,
        type: typemenu,
        code: codemenu,
        name: namemenu,
        description: descriptionmenu,
        sellPrice: sellPrice,
        stockitems: stockitems
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menu/updatemenu',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteMenu(state, token) {
  let { _id } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'menu/deletemenu',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getOrg(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'orgs/getorg',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getOrgUsers(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'users/getorgusers',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: 'GET_ORG_USERS',
              data: data.results
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            if (xhr.responseText.includes('Login Expired')) {
              return reject({ message: xhr.responseText });
            } else {
              // OFFLINE
              return resolve(
                dispatch({
                  type: 'GET_ORG_USERS'
                })
              );
            }
          } else {
            // OFFLINE
            return resolve(
              dispatch({
                type: 'GET_ORG_USERS'
              })
            );
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateOrg(state, token) {
  let {
    _id,
    name,
    email,
    accountantEmail,
    address,
    mobile,
    tabcreation,
    yocopayment,
    addItemRequests,
    showFav,
    industry,
    categories,
    radius,
    location,
    showKitch,
    addTipA,
    stockAlert,
    mainImage,
    mainBase64,
    logo,
    logoBase64,
    startMonFri,
    start,
    end,
    endMonFri,
    startSat,
    endSat,
    startSun,
    endSun,
    delivery,
    average,
    voidPin,
    voidPinNumber
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: 'Enter Name' });
      if (!email) return reject({ message: 'Enter Email' });
      // if (!address) return reject({ message: 'Enter Address' });
      if (address && !location.type)
        return reject({ message: 'Address Location Not Found' });
      if (!mobile) return reject({ message: 'Enter Number' });
      if (!industry) return reject({ message: 'Select Industry' });
      if (industry === 'food' && !categories)
        return reject({ message: 'Select Categories' });
      if (delivery[5] !== 'false') {
        delivery[5] = parseFloat(delivery[5]);
      }
      if (delivery[10] !== 'false') {
        delivery[10] = parseFloat(delivery[10]);
      }
      if (delivery[15] !== 'false') {
        delivery[15] = parseFloat(delivery[15]);
      }
      const formDetails = {
        _id: _id,
        name: name,
        email: email.toLowerCase(),
        accountantEmail: accountantEmail.toLowerCase(),
        address: address,
        mobile: mobile,
        industry: industry,
        categories: categories,
        location: location,
        radius: radius,
        tabcreation: tabcreation,
        yocopayment: yocopayment,
        addItemRequests: addItemRequests,
        mainImage: mainImage,
        mainBase64: mainBase64,
        logo: logo,
        logoBase64: logoBase64,
        startMonFri: startMonFri,
        start: start,
        end: end,
        endMonFri: endMonFri,
        startSat: startSat,
        endSat: endSat,
        startSun: startSun,
        endSun: endSun,
        showFav: showFav || false,
        showKitch: showKitch || false,
        addTipA: addTipA || false,
        stockAlert: stockAlert || false,
        delivery: delivery,
        average: average,
        voidPin: voidPin,
        voidPinNumber: voidPinNumber
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'orgs/updateorg',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getBilling(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'billing/getbilling',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateBilling(state, token) {
  let {
    _id,
    paymentType,
    status,
    price,
    subscriptionStart,
    subscriptionEnd,
    history
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const historyObject = {
        transactionId: '12345',
        paymentType: paymentType,
        amount: price,
        date: new moment().format('YYYY-MM-DD')
      };
      history.push(historyObject);
      const formDetails = {
        _id: _id,
        paymentType: paymentType,
        status: 'Subscribed',
        price: price,
        active: true,
        subscriptionStart: subscriptionStart,
        subscriptionEnd: subscriptionEnd,
        history: history
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'billing/updatebilling',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getCooking(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'cooking/getcooking',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addCooking(state, token) {
  let { cookName, type, cookObject } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!cookName) return reject({ message: 'Enter Name' });

      const formDetails = {
        name: cookName,
        type: type,
        cookIns: [cookObject],
        inputCount: 1,
        desc: '',
        active: true
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'cooking/addcooking',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editCooking(state, token, del) {
  let { _id, cookName, type, desc, cookIns, inputCount, active } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!cookName && del !== 'del') return reject({ message: 'Enter Name' });
      if (cookIns.length <= 0 && del !== 'del')
        return reject({ message: 'Create Instruction' });

      if (inputCount <= 0) {
        inputCount = cookIns.length;
      }

      const formDetails = {
        _id: _id,
        name: cookName,
        type: type,
        cookIns: cookIns,
        inputCount: inputCount,
        desc: desc,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'cooking/updatecooking',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getSpecials(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'specials/getspecials',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addSpecials(state, token) {
  let {
    specialName,
    type,
    startdate,
    enddate,
    starttime,
    endtime,
    items,
    count,
    active
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!specialName) return reject({ message: '' });
      if (items.length <= 0) return reject({ message: 'Add Catalogue Item' });

      var newItems = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].name && items[i]._id) {
          if (!items[i].price) {
            items[i].price = 0;
          }
          newItems.push(items[i]);
        }
      }

      for (let j = 0; j < newItems.length; j++) {
        newItems[j].price = parseFloat(newItems[j].price);
      }

      if (newItems.length <= 0)
        return reject({ message: 'Add Catalogue Item' });

      count = newItems.length;

      const formDetails = {
        name: specialName,
        type: type,
        startdate: startdate,
        enddate: enddate,
        starttime: starttime,
        endtime: endtime,
        items: newItems,
        count: count,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'specials/addspecials',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editSpecials(state, token) {
  let {
    _id,
    specialName,
    type,
    startdate,
    enddate,
    starttime,
    endtime,
    items,
    count,
    active
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!specialName) return reject({ message: '' });
      if (items.length <= 0) return reject({ message: 'Add Catalogue Item' });

      var newItems = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].name && items[i]._id) {
          if (!items[i].price) {
            items[i].price = 0;
          }
          newItems.push(items[i]);
        }
      }

      for (let j = 0; j < newItems.length; j++) {
        newItems[j].price = parseFloat(newItems[j].price);
      }

      if (newItems.length <= 0)
        return reject({ message: 'Add Catalogue Item' });

      count = newItems.length;
      const formDetails = {
        _id: _id,
        name: specialName,
        type: type,
        startdate: startdate,
        enddate: enddate,
        starttime: starttime,
        endtime: endtime,
        items: newItems,
        count: count,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'specials/updatespecials',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getTypes(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'types/gettypes',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addTypes(state, token) {
  let { typeName, printto, image } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!typeName) return reject({ message: 'Enter Name' });

      if (!printto) {
        printto = 'None';
      }

      const formDetails = {
        name: typeName,
        printto: printto,
        image: image,
        active: true
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'types/addtypes',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editTypes(state, token) {
  let { _id, prevName, typeName, printto, active, image } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!typeName) return reject({ message: 'Enter Name' });
      if (!printto) {
        printto = 'None';
      }

      const formDetails = {
        _id: _id,
        name: typeName,
        image: image,
        prevName: prevName,
        printto: printto,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'types/updatetypes',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getBrands(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'brands/getbrands',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addBrands(state, token) {
  let { brandName, printto, image } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!brandName) return reject({ message: 'Enter Name' });

      if (!printto) {
        printto = 'None';
      }

      const formDetails = {
        name: brandName,
        image: image,
        printto: printto,
        active: true
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'brands/addbrands',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editBrands(state, token) {
  let { _id, prevName, brandName, printto, active, image } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!brandName) return reject({ message: 'Enter Name' });
      if (!printto) {
        printto = 'None';
      }

      const formDetails = {
        _id: _id,
        name: brandName,
        image: image,
        prevName: prevName,
        printto: printto,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'brands/updatebrands',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getSalesReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getsalesreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getPOReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getporeport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEcomOrderReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'orders/getecomorderreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEcomInvoiceReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecominvoices/getecominvoicereport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEcomShipmentReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'ecomshipments/getecomshipmentreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getQuoteReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'quotes/getquotereport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getCategoryReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getcategoryreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEmployeeReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getemployeereport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getOpenBillReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getopenbillreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getReceipts(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getreceipts',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getCookingReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getcookingreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getCashoutReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getcashoutreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getPaymentTypeReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getpaymenttypereport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getStockReport(token, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        startDate: startDate,
        endDate: endDate
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'bill/getstockreport',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getPrinterSettings(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'printer/getprinters',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function savePrinterSettings(state, token) {
  let {
    printqr,
    printbill,
    printbar,
    printkitch,
    papSize,
    qrType,
    billType,
    barType,
    kitchType,
    logo,
    heading,
    heading80,
    heading58,
    footer,
    footer80,
    footer58,
    qr,
    printers,
    bill,
    bar,
    kitch,
    head,
    foot,
    vat,
    subs,
    desc,
    sku,
    ask
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!heading80) heading80 = [];
      if (!heading58) heading58 = [];
      head = heading80.length && heading58.length ? true : false;
      if (!footer80) heading80 = [];
      if (!footer58) heading58 = [];
      foot = footer80.length && footer58.length ? true : false;
      const formDetails = {
        printqr: printqr,
        printbill: '',
        printbar: '',
        printkitch: '',
        papSize: papSize,
        qrType: qrType,
        billType: '',
        barType: '',
        kitchType: '',
        logo: logo,
        heading: '',
        heading80: heading80,
        heading58: heading58,
        footer: footer,
        footer80: footer80,
        footer58: footer58,
        qr: qr,
        printers: printers,
        bill: bill,
        bar: bar,
        kitch: kitch,
        head: head,
        foot: foot,
        vat: vat,
        subs: subs,
        desc: desc,
        sku: sku,
        ask: ask
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'printer/addprinters',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getEmailSettings(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'orgmails/getemail',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function saveEmailSettings(state, token) {
  let {
    remail,
    rhour,
    report,
    semail,
    shour,
    stock,
    lemail,
    lhour,
    lowstock,
    cashup,
    cemail
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (cashup && !cemail) return reject({ message: 'Enter Cashup Email' });
      if (report && !remail) return reject({ message: 'Enter Sales Email' });
      if (report && !rhour)
        return reject({ message: 'Select Time to Email Sales Report' });
      if (stock && !semail) return reject({ message: 'Enter Stock Email' });
      if (stock && !shour)
        return reject({ message: 'Select Time to Email Stock Report' });
      if (lowstock && !lemail)
        return reject({ message: 'Enter Low Stock Email' });
      if (lowstock && !lhour)
        return reject({ message: 'Select Time to Email Low Stock Report' });

      var aRemail = [];
      if (remail) {
        var splitMails = remail.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          aRemail.push(splitMails[i].replace(/\s/g, ''));
        }
      }
      if (aRemail.length > 0) {
        remail = aRemail;
      }

      var aSemail = [];
      if (semail) {
        var splitMails = semail.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          aSemail.push(splitMails[i].replace(/\s/g, ''));
        }
      }
      if (aSemail.length > 0) {
        semail = aSemail;
      }

      var aLemail = [];
      if (lemail) {
        var splitMails = lemail.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          aLemail.push(splitMails[i].replace(/\s/g, ''));
        }
      }
      if (aLemail.length > 0) {
        lemail = aLemail;
      }

      var aCemail = [];
      if (cemail) {
        var splitMails = cemail.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          aCemail.push(splitMails[i].replace(/\s/g, ''));
        }
      }
      if (aCemail.length > 0) {
        cemail = aCemail;
      }

      const formDetails = {
        remail: remail,
        rhour: rhour,
        report: report,
        semail: semail,
        shour: shour,
        stock: stock,
        lemail: lemail,
        lhour: lhour,
        lowstock: lowstock,
        cemail: cemail,
        cashup: cashup
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'orgmails/addemail',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function paySubscription(billing, orgid, token) {
  if (orgid === "k'vb6ac73c8") {
    orgid = 'kvb6ac73c8';
  }
  var params = {
    merchant_id: '16715249',
    merchant_key: 'efqchn9v9hibn',
    return_url: server + 'vpos/webapp?completed',
    cancel_url: server + 'vpos/webapp?cancelled',
    notify_url: server + apiCallNon + 'billing/updatebilling',
    amount: (
      (parseFloat(billing.subscription.monthlyPrice) / moment().daysInMonth()) *
      (moment().daysInMonth() - moment().date())
    ).toFixed(2),
    item_name: 'VPOS Subscription',
    item_description: 'VPOS Monthly Subscription Payment',
    custom_str1: billing._id,
    custom_str2: orgid,
    subscription_type: '1',
    billing_date: moment()
      .endOf('month')
      .add(1, 'day')
      .format('YYYY-MM-DD'),
    recurring_amount: parseFloat(billing.subscription.monthlyPrice).toFixed(2),
    frequency: '3',
    cycles: '0'
  };

  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'gethash',
        data: JSON.stringify(params),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          params.signature = data.hash;
          var form = document.createElement('form');
          form.setAttribute('method', 'post');
          form.setAttribute('action', 'https://www.payfast.co.za/eng/process');
          // form.setAttribute('action', 'https://sandbox.payfast.co.za/eng/process');
          for (var key in params) {
            if (params.hasOwnProperty(key)) {
              var hiddenField = document.createElement('input');
              hiddenField.setAttribute('type', 'hidden');
              hiddenField.setAttribute('name', key);
              hiddenField.setAttribute('value', params[key]);
              form.appendChild(hiddenField);
            }
          }
          if (window.cordova) {
            form.setAttribute('id', 'payForm');
            var pageContent =
              '<html><head></head><body>' +
              form.outerHTML +
              '<script type="text/javascript">document.getElementById("payForm").submit();</script></body></html>';
            var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);

            var inAppBrowserRef = window.cordova.InAppBrowser.open(
              pageContentUrl,
              '_blank',
              'hidden=no,location=no,hardwareback=no'
            );
            inAppBrowserRef.addEventListener('loadstart', loadStartCallBack);
            inAppBrowserRef.addEventListener('exit', exitCallBack);
            function loadStartCallBack(event) {
              if (event.url === server + 'vpos/webapp?cancelled') {
                inAppBrowserRef.removeEventListener('exit', exitCallBack);
                inAppBrowserRef.close();
                inAppBrowserRef = undefined;
                return resolve({ message: 'cancelled' });
              } else if (event.url === server + 'vpos/webapp?completed') {
                inAppBrowserRef.removeEventListener('exit', exitCallBack);
                inAppBrowserRef.close();
                inAppBrowserRef = undefined;
                return resolve({ message: 'completed' });
              }
            }
            function exitCallBack(event) {
              inAppBrowserRef = undefined;
              return resolve({ message: 'cancelled' });
            }
          } else {
            document.body.appendChild(form);
            form.submit();
          }
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function cancelSubscription(billing, orgid, token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        token: billing.token
      };

      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'unsubscribe',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function activateSubscription(billing, orgid, token) {
  if (orgid === "k'vb6ac73c8") {
    orgid = 'kvb6ac73c8';
  }
  var params = {
    merchant_id: '16715249',
    merchant_key: 'efqchn9v9hibn',
    return_url: server + 'vpos/webapp?completed',
    cancel_url: server + 'vpos/webapp?cancelled',
    notify_url: server + apiCallNon + 'billing/updatebilling',
    amount: '0.00',
    item_name: 'VPOS Subscription',
    item_description: 'VPOS Monthly Subscription Payment',
    custom_str1: billing._id,
    custom_str2: orgid,
    subscription_type: '1',
    billing_date: billing.subscriptionEnd,
    recurring_amount: parseFloat(billing.subscription.monthlyPrice).toFixed(2),
    frequency: '3',
    cycles: '0'
  };

  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'gethash',
        data: JSON.stringify(params),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          params.signature = data.hash;
          var form = document.createElement('form');
          form.setAttribute('method', 'post');
          form.setAttribute('action', 'https://www.payfast.co.za/eng/process');
          // form.setAttribute('action', 'https://sandbox.payfast.co.za/eng/process');
          for (var key in params) {
            if (params.hasOwnProperty(key)) {
              var hiddenField = document.createElement('input');
              hiddenField.setAttribute('type', 'hidden');
              hiddenField.setAttribute('name', key);
              hiddenField.setAttribute('value', params[key]);
              form.appendChild(hiddenField);
            }
          }
          if (window.cordova) {
            form.setAttribute('id', 'payForm');
            var pageContent =
              '<html><head></head><body>' +
              form.outerHTML +
              '<script type="text/javascript">document.getElementById("payForm").submit();</script></body></html>';
            var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);

            var inAppBrowserRef = window.cordova.InAppBrowser.open(
              pageContentUrl,
              '_blank',
              'hidden=no,location=no,hardwareback=no'
            );
            inAppBrowserRef.addEventListener('loadstart', loadStartCallBack);
            inAppBrowserRef.addEventListener('exit', exitCallBack);
            function loadStartCallBack(event) {
              if (event.url === server + 'vpos/webapp?cancelled') {
                inAppBrowserRef.removeEventListener('exit', exitCallBack);
                inAppBrowserRef.close();
                inAppBrowserRef = undefined;
                return resolve({ message: 'cancelled' });
              } else if (event.url === server + 'vpos/webapp?completed') {
                inAppBrowserRef.removeEventListener('exit', exitCallBack);
                inAppBrowserRef.close();
                inAppBrowserRef = undefined;
                return resolve({ message: 'completed' });
              }
            }
            function exitCallBack(event) {
              inAppBrowserRef = undefined;
              return resolve({ message: 'cancelled' });
            }
          } else {
            document.body.appendChild(form);
            form.submit();
          }
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addSupplier(state, token) {
  let {
    name,
    contact,
    email,
    number,
    address1,
    city,
    country,
    postal,
    province,
    note,
    bankDetails,
    bankName,
    bankAccount,
    bankBranch,
    bankAccountType,
    vatNumber
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });
      var aRemail = [];
      if (email) {
        var splitMails = email.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          aRemail.push(splitMails[i].replace(/\s/g, ''));
        }
      }
      if (aRemail.length > 0) {
        email = aRemail;
      }
      const formDetails = {
        name: name,
        contact: contact,
        email: email,
        number: number,
        address1: address1,
        city: city,
        country: country,
        postal: postal,
        province: province,
        note: note,
        bankDetails: bankDetails,
        bankName: bankName,
        bankAccount: bankAccount,
        bankBranch: bankBranch,
        bankAccountType: bankAccountType,
        vatNumber: vatNumber
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'suppliers/addsupplier',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getSuppliers(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'suppliers/getsuppliers',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editSupplier(state, token) {
  let {
    _id,
    name,
    contact,
    email,
    number,
    address1,
    city,
    country,
    postal,
    province,
    note,
    bankDetails,
    bankName,
    bankAccount,
    bankBranch,
    bankAccountType,
    vatNumber
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });
      var aRemail = [];
      if (email) {
        var splitMails = email.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          aRemail.push(splitMails[i].replace(/\s/g, ''));
        }
      }
      if (aRemail.length > 0) {
        email = aRemail;
      }
      const formDetails = {
        _id: _id,
        name: name,
        contact: contact,
        email: email,
        number: number,
        address1: address1,
        city: city,
        country: country,
        postal: postal,
        province: province,
        note: note,
        bankDetails: bankDetails,
        bankName: bankName,
        bankAccount: bankAccount,
        bankBranch: bankBranch,
        bankAccountType: bankAccountType,
        vatNumber: vatNumber
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'suppliers/updatesupplier',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteSupplier(state, token) {
  let { _id, active } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'suppliers/deletesupplier',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function addQuoteCustomer(state, token) {
  let {
    name,
    contact,
    email,
    number,
    address1,
    city,
    country,
    postal,
    province,
    note,
    vatNumber
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });
      var aRemail = [];
      if (email) {
        var splitMails = email.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          aRemail.push(splitMails[i].replace(/\s/g, ''));
        }
      }
      if (aRemail.length > 0) {
        email = aRemail;
      }
      const formDetails = {
        name: name,
        contact: contact,
        email: email,
        number: number,
        address1: address1,
        city: city,
        country: country,
        postal: postal,
        province: province,
        note: note,
        vatNumber: vatNumber
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'quotecustomers/addquotecustomer',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getQuoteCustomers(token) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'quotecustomers/getquotecustomers',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function editQuoteCustomer(state, token) {
  let {
    _id,
    name,
    contact,
    email,
    number,
    address1,
    city,
    country,
    postal,
    province,
    note,
    vatNumber
  } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      if (!name) return reject({ message: '' });
      var aRemail = [];
      if (email) {
        var splitMails = email.split(';');
        for (let i = 0; i < splitMails.length; i++) {
          aRemail.push(splitMails[i].replace(/\s/g, ''));
        }
      }
      if (aRemail.length > 0) {
        email = aRemail;
      }
      const formDetails = {
        _id: _id,
        name: name,
        contact: contact,
        email: email,
        number: number,
        address1: address1,
        city: city,
        country: country,
        postal: postal,
        province: province,
        note: note,
        vatNumber: vatNumber
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'quotecustomers/updatequotecustomer',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function deleteQuoteCustomer(state, token) {
  let { _id, active } = state;
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const formDetails = {
        _id: _id,
        active: active
      };

      $.ajax({
        type: 'POST',
        url: server + apiCall + 'quotecustomers/deletequotecustomer',
        data: JSON.stringify(formDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getBillsCashup(token, id, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'bill/getbillscashup?id=' +
          id +
          '&startDate=' +
          startDate +
          '&endDate=' +
          endDate,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getRefundBillsCashup(token, id, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'bill/getrefundbillscashup?id=' +
          id +
          '&startDate=' +
          startDate +
          '&endDate=' +
          endDate,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getVoidBillsCashup(token, id, startDate, endDate) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'bill/getvoidbillscashup?id=' +
          id +
          '&startDate=' +
          startDate +
          '&endDate=' +
          endDate,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getTotalVoidBillsCashup(token, id) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'bill/gettotalvoidbillscashup',
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getCashPaymentsCashup(token, id, date) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'bill/getcashpaymentscashup?id=' +
          id +
          '&date=' +
          date,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getCardPaymentsCashup(token, id, date) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url:
          server +
          apiCall +
          'bill/getcardpaymentscashup?id=' +
          id +
          '&date=' +
          date,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function getBillItemsCashup(token, id) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: server + apiCall + 'bill/getbillitemscashup?id=' + id,
        contentType: 'application/json',
        crossDomain: true,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        },
        success: async data => {
          return resolve(
            dispatch({
              type: '',
              data: data
            })
          );
        },
        error: async (xhr, ajaxOptions, thrownError) => {
          if (xhr.responseText) {
            return reject({ message: xhr.responseText });
          } else {
            return reject({ message: 'No Connection Found!' });
          }
        }
      });
    }).catch(async err => {
      throw err.message;
    });
}
