import React, { useState, useEffect, useRef } from 'react';
import { Spin, Select, Checkbox, Icon, Input, Upload, Tabs } from 'antd';
import { connect } from 'react-redux';
import {
  getPrinterSettings,
  savePrinterSettings
} from 'src/redux/actions/backend';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'src/components/Grid/GridItem.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import { openSnackbar, closeSnackbar } from 'src/redux/actions/snackbar';
import workerImp from 'src/config/PrintWorker/TestReceipt.Worker.js';
import workerBTImp from 'src/config/PrintWorker/TestReceiptBT.Worker.js';
import WebWorker from 'src/WebWorker';
import { receiptformatter } from 'src/utils/formater.js';
import { PrintJS } from 'src/config/PrintScript';
import styled, { css } from 'styled-components';
import ReceiptCreator from 'src/views/Admin/Settings/Printing/Receipt';
import PrinterTable from 'src/views/Admin/Settings/Printing/PrinterTable';
import { setHelpMarker } from 'src/redux/actions/help';

import {
  primaryColor,
  grayColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx';
import Resizer from 'react-image-file-resizer';
import { b64toBlob } from 'src/utils/formater';
import Barcode from 'react-barcode';
import { exportComponentAsPNG } from 'react-component-export-image';

const server = process.env.REACT_APP_GLOBAL_SERVER;
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

let worker = {};
let workerBT = {};

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    border-bottom: 0px solid #e8e8e8;
    margin: 0 0px 20px 0 !important;
    outline: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-tab {
    width: auto !important;
    font-size: 16px !important;
    margin-left: 0.5em !important;
    margin-top: 0.5em !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    font-weight: 400 !important;
    color: rgb(19, 36, 64) !important;
    background: transparent !important;
    border: 0px solid #00cc70 !important;
  }
  .ant-tabs-tab-active {
    width: auto !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    color: #00cc70 !important;
    border-bottom: 1px solid #00cc70 !important;
  }
`;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '3.66mm',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  checkboxtop: {
    display: 'inlineBlock',
    paddingTop: '10px'
  },
  checkboxtopoverlap: {
    display: 'inlineBlock',
    paddingTop: '25px',
    marginLeft: '0.5em'
  }
});

const Printing = allprops => {
  let state = {};
  let props = {};
  let setState;
  const componentRef = useRef();

  const componentDidMount = () => {
    worker = new WebWorker(workerImp);
    workerBT = new WebWorker(workerBTImp);
    worker.addEventListener('message', printET);
    workerBT.addEventListener('message', printBT);
    getPrinterSettings();
  };

  const componentWillUnmount = () => {
    worker.removeEventListener('message', printET);
    workerBT.removeEventListener('message', printBT);
    worker = {};
    workerBT = {};
  };

  const printImpact = event => {
    event.aData.push(' ');
    if (event.aData.toString().search('Cash: R') > -1) {
      window.ThermalPrinter.printFormattedTextAndOpenCashBox(
        {
          type: 'tcp',
          address: event.sIp,
          port: 9100,
          id: event.sIp,
          text: event.aData.toString().replace(/,/g, ''),
          printerWidthMM: 80
        },
        function() {
          window.ThermalPrinter.disconnectPrinter(
            {
              type: 'tcp',
              address: event.sIp,
              port: 9100,
              id: event.sIp
            },
            function() {
              console.log('Successfully printed!');
            },
            function(error) {
              console.error('Printing error', error);
            }
          );
        },
        function(error) {
          window.ThermalPrinter.disconnectPrinter(
            {
              type: 'tcp',
              address: event.sIp,
              port: 9100,
              id: event.sIp
            },
            function() {
              console.log('Successfully printed!');
            },
            function(error) {
              console.error('Printing error', error);
            }
          );
        }
      );
    } else {
      window.ThermalPrinter.printFormattedTextAndCut(
        {
          type: 'tcp',
          address: event.sIp,
          port: 9100,
          id: event.sIp,
          text: event.aData.toString().replace(/,/g, ''),
          printerWidthMM: 80
        },
        function() {
          window.ThermalPrinter.disconnectPrinter(
            {
              type: 'tcp',
              address: event.sIp,
              port: 9100,
              id: event.sIp
            },
            function() {
              console.log('Successfully printed!');
            },
            function(error) {
              console.error('Printing error', error);
            }
          );
        },
        function(error) {
          window.ThermalPrinter.disconnectPrinter(
            {
              type: 'tcp',
              address: event.sIp,
              port: 9100,
              id: event.sIp
            },
            function() {
              console.log('Successfully printed!');
            },
            function(error) {
              console.error('Printing error', error);
            }
          );
        }
      );
    }
  };

  const printET = eventN => {
    if (eventN.data.error) {
      alert(eventN.data.error);
      return;
    }

    let event = Object.assign({}, eventN.data);
    if (window.device) {
      if (
        window.device.platform === 'Android' ||
        window.device.platform === 'iOS'
      ) {
        if (event.model === 'impact' && window.device.platform === 'Android') {
          if (event.logo && event.logo.base64) {
            window.ThermalPrinter.bitmapToHexadecimalString(
              {
                type: 'tcp',
                address: event.sIp,
                port: 9100,
                id: event.sIp,
                base64: event.logo.base64.replace(
                  /^data:image\/(png|jpg|jpeg);base64,/,
                  ''
                )
              },
              function(suc) {
                event.aData.unshift(`[C]<img>${suc}</img>\\n`);
                window.ThermalPrinter.disconnectPrinter(
                  {
                    type: 'tcp',
                    address: event.sIp,
                    port: 9100,
                    id: event.sIp
                  },
                  function() {
                    console.log('Successfully created image!');
                    for (var i = 0; i < event.aData.length; i++) {
                      event.aData[i] = event.aData[i].replace('\\n', '\n');
                      if (event.aData[i].search('{B}') > -1) {
                        event.aData[i] = `<b>${event.aData[i].replace(
                          '{B}',
                          ''
                        )}</b>`;
                      }
                      if (event.aData[i].search('3{S}') > -1) {
                        event.aData[i] = `<font size='big'>${event.aData[
                          i
                        ].replace('3{S}', '')}</font>`;
                      }
                      if (event.aData[i].search('2{S}') > -1) {
                        event.aData[i] = `<font size='big'>${event.aData[
                          i
                        ].replace('2{S}', '')}</font>`;
                      }
                      event.aData[i] = event.aData[i].replace('1{S}', '');
                      event.aData[i] = event.aData[i].replace('{L}', '');
                    }
                    printImpact(event);
                  },
                  function(error) {
                    console.error('Printing error', error);
                  }
                );
              },
              function(error) {
                console.error('Printing error', error);
              }
            );
          } else {
            for (var i = 0; i < event.aData.length; i++) {
              event.aData[i] = event.aData[i].replace('\\n', '\n');
              if (event.aData[i].search('{B}') > -1) {
                event.aData[i] = `<b>${event.aData[i].replace('{B}', '')}</b>`;
              }
              if (event.aData[i].search('3{S}') > -1) {
                event.aData[i] = `<font size='big'>${event.aData[i].replace(
                  '3{S}',
                  ''
                )}</font>`;
              }
              if (event.aData[i].search('2{S}') > -1) {
                event.aData[i] = `<font size='big'>${event.aData[i].replace(
                  '2{S}',
                  ''
                )}</font>`;
              }
              event.aData[i] = event.aData[i].replace('1{S}', '');
              event.aData[i] = event.aData[i].replace('{L}', '');
            }
            printImpact(event);
          }
        } else {
          window.epos2.connectPrinter(
            event.sIp,
            function() {
              for (var i = 0; i < event.aData.length; i++) {
                event.aData[i] = event.aData[i].replace('\\n', '\n');
              }
              if (event.logo && event.logo.base64 && window.epos2.printImage) {
                var check = false;
                window.epos2.printImage(
                  [],
                  function() {
                    check = true;
                  },
                  function(error) {
                    check = true;
                  }
                );
                setTimeout(function() {
                  if (check) {
                    window.epos2.printImage(
                      [
                        event.logo.base64.replace(
                          /^data:image\/(png|jpg|jpeg);base64,/,
                          ''
                        )
                      ],
                      function() {
                        window.epos2.print(
                          event.aData,
                          function(output) {
                            window.epos2.disconnectPrinter(
                              function(output) {},
                              function(error) {
                                alert('' + error);
                              }
                            );
                          },
                          function(error) {
                            alert('' + error);
                          }
                        );
                      },
                      function(error) {
                        alert(JSON.stringify(error));
                      }
                    );
                  } else {
                    window.epos2.print(
                      event.aData,
                      function(output) {
                        window.epos2.disconnectPrinter(
                          function(output) {
                            alert('Done');
                          },
                          function(error) {
                            alert('' + error);
                          }
                        );
                      },
                      function(error) {
                        alert('' + error);
                      }
                    );
                  }
                }, 500);
              } else {
                window.epos2.print(
                  event.aData,
                  function(output) {
                    window.epos2.disconnectPrinter(
                      function(output) {
                        alert('Done');
                      },
                      function(error) {
                        alert('' + error);
                      }
                    );
                  },
                  function(error) {
                    alert('' + error);
                  }
                );
              }
            },
            function(error) {
              alert('' + error);
            }
          );
        }
      } else {
        PrintJS(
          event.aData,
          event.sIp,
          event.cash,
          function(error, msg) {
            if (error) {
              alert(error);
            } else {
              alert('Done');
            }
          },
          event.logo
        );
      }
    } else {
      PrintJS(
        event.aData,
        event.sIp,
        event.cash,
        function(error, msg) {
          if (error) {
            alert(error);
          } else {
            alert('Done');
          }
        },
        event.logo
      );
    }
  };

  const printBT = eventN => {
    if (eventN.data.error) {
      alert(eventN.data.error);
      return;
    }

    let event = Object.assign({}, eventN.data);
    if (window.device) {
      if (
        window.device.platform === 'Android' ||
        window.device.platform === 'iOS'
      ) {
        window.DatecsPrinter.connect(
          event.sIp,
          function() {
            var printmsg = '';
            for (var i = 0; i < event.aData.length; i++) {
              event.aData[i] = event.aData[i].replace('\\n', '{br}');
              event.aData[i] = event.aData[i].replace('\n', '{br}');
              printmsg += event.aData[i];
            }
            var spacing = 3;
            for (var j = 0; j < spacing; j++) {
              printmsg += '{br}';
            }
            if (event.logo && event.logo.base64) {
              function printImage(imageData, height, width) {
                window.DatecsPrinter.printImage(
                  imageData.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''), //base64
                  width,
                  height,
                  1,
                  function() {
                    window.DatecsPrinter.printText(
                      printmsg,
                      'ISO-8859-1',
                      function() {
                        alert('Done');
                      }
                    );
                  },
                  function(error) {
                    alert(JSON.stringify(error));
                  }
                );
              }
              var blob = b64toBlob(event.logo.base64);
              Resizer.imageFileResizer(blob, 350, 350, 'jpg', 100, 0, uri => {
                var image = new Image();
                image.onload = function() {
                  var canvas = document.createElement('canvas');
                  canvas.height = image.height;
                  canvas.width = image.width;
                  var context = canvas.getContext('2d');
                  context.drawImage(image, 0, 0);
                  var imageData = canvas.toDataURL('image/jpeg');
                  printImage(imageData, canvas.height, canvas.width);
                };
                image.src = uri;
              });
            } else {
              window.DatecsPrinter.printText(
                printmsg,
                'ISO-8859-1',
                function() {
                  alert('Done');
                }
              );
            }
          },
          function(error) {
            alert(JSON.stringify(error));
          }
        );
      } else {
        alert('Mobile Device not Detected');
      }
    } else {
      alert('Mobile Device not Detected');
    }
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div
      style={{ display: 'flex', minWidth: '75mm', maxWidth: '78mm' }}
      ref={componentRef}
    >
      <Barcode displayValue={true} value={state.printCurrent.modelName} />
    </div>
  ));

  const printBrother = (eventN, recordN) => {
    setState(prevState => ({
      ...prevState,
      printCurrent: recordN.printDetails,
      loading: true
    }));
    let record = Object.assign({}, recordN);
    if (window.device) {
      if (window.device.platform === 'Android') {
        record.printDetails.orientation = 'PORTRAIT';
        window.plugin.brotherPrinter.setPrinter(
          record.printDetails,
          () => {
            function printImage(imageData) {
              let separatorIdx = parseInt(imageData.indexOf(','));
              if (separatorIdx !== -1) {
                imageData = imageData.substring(separatorIdx + 1);
              }
              window.plugin.brotherPrinter.printViaSDK(
                imageData, //base64
                callbackValue => {
                  setState(prevState => ({
                    ...prevState,
                    printCurrent: '',
                    loading: false
                  }));
                  alert(JSON.stringify(callbackValue));
                },
                error => {
                  setState(prevState => ({
                    ...prevState,
                    printCurrent: '',
                    loading: false
                  }));
                  alert(JSON.stringify(error));
                }
              );
            }
            try {
              var canvas = document.createElement('canvas');
              exportComponentAsPNG(componentRef, {
                filename: false,
                html2CanvasOptions: { canvas: canvas }
              })
                .then(result => {
                  printImage(canvas.toDataURL('image/bmp'));
                })
                .catch(err => {
                  setState(prevState => ({
                    ...prevState,
                    printCurrent: '',
                    loading: false
                  }));
                  alert(JSON.stringify(err));
                });
            } catch (error) {
              setState(prevState => ({
                ...prevState,
                printCurrent: '',
                loading: false
              }));
              alert(JSON.stringify(error));
            }
          },
          error => {
            setState(prevState => ({
              ...prevState,
              printCurrent: '',
              loading: false
            }));
            alert(JSON.stringify(error));
          }
        );
      } else {
        setState(prevState => ({
          ...prevState,
          printCurrent: '',
          loading: false
        }));
        alert('Android Device not Detected');
      }
    } else {
      setState(prevState => ({
        ...prevState,
        printCurrent: '',
        loading: false
      }));
      alert('Android Device not Detected');
    }
  };

  const getPrinterSettings = msg => {
    const token = props.local.user.token;
    props
      .getPrinterSettings(token)
      .then(result => {
        if (
          result.data.results &&
          result.data.results.settings.length &&
          !result.data.results.settings[0].printers
        ) {
          result.data.results.settings[0].printers = [];
          if (result.data.results.settings[0].printbill) {
            result.data.results.settings[0].printers.push({
              name: 'Bill',
              type: result.data.results.settings[0].billType,
              size:
                result.data.results.settings[0].billType === 'bluetooth'
                  ? 'small'
                  : 'big',
              printList: ['Bill'],
              address: result.data.results.settings[0].printbill
            });
          }
          if (result.data.results.settings[0].printbar) {
            result.data.results.settings[0].printers.push({
              name: 'Bar',
              type: result.data.results.settings[0].barType,
              size:
                result.data.results.settings[0].barType === 'bluetooth'
                  ? 'small'
                  : 'big',
              printList: [],
              address: result.data.results.settings[0].printbar
            });
          }
          if (result.data.results.settings[0].printkitch) {
            result.data.results.settings[0].printers.push({
              name: 'Kitchen',
              type: result.data.results.settings[0].kitchType,
              size:
                result.data.results.settings[0].kitchType === 'bluetooth'
                  ? 'small'
                  : 'big',
              printList: [],
              address: result.data.results.settings[0].printkitch
            });
          }
        }
        var heading80 =
          result.data.results &&
          result.data.results.settings.length &&
          (result.data.results.settings[0].heading80 ||
            (result.data.results.settings[0].heading.length > 0 &&
              typeof result.data.results.settings[0].heading === 'string'))
            ? result.data.results.settings[0].heading.length > 0 &&
              typeof result.data.results.settings[0].heading === 'string'
              ? receiptformatter(result.data.results.settings[0].heading)
              : result.data.results.settings[0].heading80
            : [
                {
                  text: '----------------------------------------\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                },
                {
                  text: 'Your Heading here\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                }
              ];

        var heading58 =
          result.data.results &&
          result.data.results.settings.length &&
          result.data.results.settings[0].heading58
            ? result.data.results.settings[0].heading58
            : result.data.results &&
              result.data.results.settings.length &&
              result.data.results.settings[0].heading.length > 0 &&
              typeof result.data.results.settings[0].heading === 'string'
            ? heading80
            : [
                {
                  text: '-------------------------------\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                },
                {
                  text: 'Your Heading here\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                }
              ];

        var footer80 =
          result.data.results &&
          result.data.results.settings.length &&
          (result.data.results.settings[0].footer80 ||
            (result.data.results.settings[0].footer.length > 0 &&
              typeof result.data.results.settings[0].footer === 'string'))
            ? result.data.results.settings[0].footer.length > 0 &&
              typeof result.data.results.settings[0].footer === 'string'
              ? receiptformatter(result.data.results.settings[0].footer)
              : result.data.results.settings[0].footer80
            : [
                {
                  text: '----------------------------------------\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                },
                {
                  text: 'Your Footer here\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                },
                {
                  text: '----------------------------------------\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                }
              ];

        var footer58 =
          result.data.results &&
          result.data.results.settings.length &&
          result.data.results.settings[0].footer58
            ? result.data.results.settings[0].footer58
            : [
                {
                  text: '--------------------------------\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                },
                {
                  text: 'Your Footer here\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                },
                {
                  text: '-------------------------------\\n',
                  bold: false,
                  size: false,
                  style: {
                    width: '100%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }
                }
              ];

        setState(prevState => ({
          ...prevState,
          types:
            result.data.results && result.data.results.settings
              ? result.data.results.types || state.types
              : state.types,
          papSize:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].papSize || '89mm'
              : '89mm',
          printqr:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].printqr
              : state.printqr,
          printbill:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].printbill
              : state.printbill,
          printbar:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].printbar
              : state.printbar,
          printkitch:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].printkitch
              : state.printkitch,
          logo:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].logo
              : state.logo,
          heading: '',
          heading80: heading80,
          heading58: heading58,
          footer: '',
          footer80: footer80,
          footer58: footer58,
          printers:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].printers || state.printers
              : state.printers,
          qr:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].qr
              : state.qr,
          bill:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].bill
              : state.bill,
          bar:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].bar
              : state.bar,
          kitch:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].kitch
              : state.kitch,
          qrType:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].qrType
                ? result.data.results.settings[0].qrType
                : state.qrType
              : state.qrType,
          billType:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].billType
                ? result.data.results.settings[0].billType
                : state.billType
              : state.billType,
          barType:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].barType
                ? result.data.results.settings[0].barType
                : state.barType
              : state.barType,
          kitchType:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].kitchType
                ? result.data.results.settings[0].kitchType
                : state.kitchType
              : state.kitchType,
          head:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].head
              : state.head,
          foot:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].foot
              : state.foot,
          vat:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].vat
              : state.vat,
          subs:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].subs !== false
                ? true
                : false
              : state.subs,
          desc:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].desc
              : state.desc,
          sku:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].sku
              : state.sku,
          ask:
            result.data.results && result.data.results.settings.length
              ? result.data.results.settings[0].ask
              : state.ask,
          loading: false
        }));
        if (msg) {
          props.openSnackbar(msg);
        }
      })
      .catch(err => {
        onError(err);
      });
  };

  const savePrinterSettings = () => {
    const token = props.local.user.token;
    props
      .savePrinterSettings(state, token)
      .then(result => {
        setState(prevState => ({
          ...prevState,
          loading: false
        }));
        props.openSnackbar('Save Complete');
      })
      .catch(err => {
        onError(err);
      });
  };

  const testPrint = (type, printer, size, record) => {
    var stateCopy = JSON.parse(JSON.stringify(state));
    stateCopy.printbill = printer;
    if (size === 'big') {
      stateCopy.heading = stateCopy.heading80;
      stateCopy.footer = stateCopy.footer80;
    } else {
      stateCopy.heading = stateCopy.heading58;
      stateCopy.footer = stateCopy.footer58;
    }
    var data = {
      sIp: stateCopy,
      size: size,
      model: record.model
    };

    if (type === 'bluetooth') {
      workerBT.postMessage(data);
    } else if (type === 'brother') {
      printBrother(stateCopy, record);
    } else {
      worker.postMessage(data);
    }
  };

  const closeDialog = () => {
    setState(prevState => ({
      ...prevState,
      alert: false,
      pdopen: false,
      delKey: 0
    }));
  };

  const onError = err => {
    setState(prevState => ({
      ...prevState,
      head: 'Printer Error',
      alert: true,
      msg: [err],
      loading: false
    }));
  };

  const onChangeEvtMenu = eN => {
    let e = Object.assign({}, eN);
    if (e.target.id === 'printqr') {
      if (state.billType === 'bluetooth') {
        var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
          str = e.currentTarget.value.replace(/[^a-f0-9]/gi, '');

        while (r.test(str)) {
          str = str.replace(r, '$1' + ':' + '$2');
        }

        e.currentTarget.value = str.slice(0, 17);
      }
      setState(prevState => ({
        ...prevState,
        printqr: e.currentTarget.value
      }));
    }
    if (e.target.id === 'printbill') {
      if (state.billType === 'bluetooth') {
        var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
          str = e.currentTarget.value.replace(/[^a-f0-9]/gi, '');

        while (r.test(str)) {
          str = str.replace(r, '$1' + ':' + '$2');
        }

        e.currentTarget.value = str.slice(0, 17);
      }
      setState(prevState => ({
        ...prevState,
        printbill: e.currentTarget.value
      }));
    }
    if (e.target.id === 'printbar') {
      if (state.billType === 'bluetooth') {
        var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
          str = e.currentTarget.value.replace(/[^a-f0-9]/gi, '');

        while (r.test(str)) {
          str = str.replace(r, '$1' + ':' + '$2');
        }

        e.currentTarget.value = str.slice(0, 17);
      }
      setState(prevState => ({
        ...prevState,
        printbar: e.currentTarget.value
      }));
    }
    if (e.target.id === 'printkitch') {
      if (state.billType === 'bluetooth') {
        var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
          str = e.currentTarget.value.replace(/[^a-f0-9]/gi, '');

        while (r.test(str)) {
          str = str.replace(r, '$1' + ':' + '$2');
        }

        e.currentTarget.value = str.slice(0, 17);
      }
      setState(prevState => ({
        ...prevState,
        printkitch: e.currentTarget.value
      }));
    }
    if (e.target.id === 'footer') {
      setState(prevState => ({
        ...prevState,
        footer: e.currentTarget.value
      }));
    }
  };

  const ltrim = str => {
    if (!str) return str;
    return str.replace(/^\s+/g, '');
  };

  const onChange = eN => {
    let e = Object.assign({}, eN);
    if (e.target.id === 'qr') {
      setState(prevState => ({
        ...prevState,
        qr: e.target.checked
      }));
    }
    if (e.target.id === 'bill') {
      setState(prevState => ({
        ...prevState,
        bill: e.target.checked
      }));
    }
    if (e.target.id === 'bar') {
      setState(prevState => ({
        ...prevState,
        bar: e.target.checked
      }));
    }
    if (e.target.id === 'kitch') {
      setState(prevState => ({
        ...prevState,
        kitch: e.target.checked
      }));
    }
    if (e.target.id === 'head') {
      setState(prevState => ({
        ...prevState,
        head: e.target.checked
      }));
    }
    if (e.target.id === 'foot') {
      setState(prevState => ({
        ...prevState,
        foot: e.target.checked
      }));
    }
    if (e.target.id === 'vat') {
      setState(prevState => ({
        ...prevState,
        vat: e.target.checked
      }));
    }
    if (e.target.id === 'subs') {
      setState(prevState => ({
        ...prevState,
        subs: e.target.checked
      }));
    }
    if (e.target.id === 'desc') {
      setState(prevState => ({
        ...prevState,
        desc: e.target.checked
      }));
    }
    if (e.target.id === 'sku') {
      setState(prevState => ({
        ...prevState,
        sku: e.target.checked
      }));
    }
    if (e.target.id === 'ask') {
      setState(prevState => ({
        ...prevState,
        ask: e.target.checked
      }));
    }
  };

  const changeTab = e => {
    setState(prevState => ({
      ...prevState,
      papSize: state.papSize
    }));
  };

  const handleChange = eventN => {
    let event = Object.assign({}, eventN);
    if (event.target.name === 'qrtype') {
      setState(prevState => ({
        ...prevState,
        qrType: event.target.value
      }));
    }
    if (event.target.name === 'billtype') {
      setState(prevState => ({
        ...prevState,
        billType: event.target.value
      }));
    }
    if (event.target.name === 'papSize') {
      setState(prevState => ({
        ...prevState,
        papSize: event.target.value
      }));
    }
    if (event.target.name === 'bartype') {
      setState(prevState => ({
        ...prevState,
        barType: event.target.value
      }));
    }
    if (event.target.name === 'kitchtype') {
      setState(prevState => ({
        ...prevState,
        kitchType: event.target.value
      }));
    }
  };

  const onHelpMarker = marker => {
    props.setHelpMarker(marker);
  };

  const initialState = {
    printqr: '',
    printbill: '',
    printbar: '',
    printkitch: '',
    logo: {
      base64: '',
      width: 100,
      height: 100
    },
    heading: [],
    heading80: [],
    heading58: [],
    footer: [],
    footer80: [],
    footer58: [],
    printers: [
      {
        name: '',
        type: 'ethernet',
        size: 'big',
        printList: [],
        address: '',
        model: ''
      }
    ],
    types: [],
    qrType: 'bluetooth',
    billType: 'bluetooth',
    barType: 'bluetooth',
    kitchType: 'bluetooth',
    papSize: '89mm',
    qr: false,
    bill: false,
    bar: false,
    kitch: false,
    head: true,
    foot: true,
    vat: false,
    subs: true,
    desc: false,
    sku: false,
    ask: false,
    loading: true,
    loadFetch: false,
    userlisthead: 'Printer Settings',
    userlistsub: 'Printer and Receipt Settings',
    alert: false,
    pdopen: false,
    delKey: 0,
    msg: [],
    activeFilter: true
  };

  props = allprops;
  state.props = allprops;
  [state, setState] = useState(initialState);

  useEffect(() => {
    componentDidMount();
    return () => componentWillUnmount();
  }, []);

  return (
    <Spin spinning={state.loading}>
      <AlertDialog state={state} props={props} closeDialog={closeDialog} />
      <Card className={props.classes.cardExpand}>
        <CardBody style={{ marginLeft: '3%' }}>
          <StyledTabs defaultActiveKey="1" type="card" onChange={changeTab}>
            <TabPane tab="Printers" key="1">
              <GridContainer>
                <PrinterTable
                  closeDialog={closeDialog}
                  state={state}
                  props={props}
                  testPrint={testPrint}
                  onError={onError}
                  setState={setState}
                />
              </GridContainer>
            </TabPane>
            <TabPane tab="Receipt Layout" key="2">
              <GridContainer>
                <GridItem
                  xs={2}
                  sm={2}
                  md={4}
                  style={{
                    marginTop: '-0.8em',
                    marginBottom: '0.5em'
                    //marginLeft: state.papSize === "89mm" ? "-2em" : "0.7em",
                  }}
                >
                  <div style={{ marginLeft: '0.5em', marginBottom: '1em' }}>
                    <Checkbox
                      style={{
                        position: 'absolute',
                        marginLeft:
                          state.papSize === '89mm' ? '28.7em' : '22.7em',
                        marginTop: '7em'
                      }}
                      id="ask"
                      onChange={onChange}
                      checked={state.ask}
                      className={props.classes.checkboxtop}
                    >
                      Ask before printing receipt
                    </Checkbox>

                    <CustomSelect
                      style={{ marginTop: '-1em' }}
                      labelText="Printer Paper Size"
                      id="papSize"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        style: {
                          width: state.papSize === '89mm' ? '89mm' : '70mm'
                        },
                        onChange: e => handleChange(e),
                        value: state.papSize
                      }}
                      menuItems={[
                        {
                          name: '58mm',
                          value: '70mm'
                        },
                        {
                          name: '80mm',
                          value: '89mm'
                        }
                      ]}
                    />
                  </div>
                  <ReceiptCreator
                    state={state}
                    props={props}
                    onError={onError}
                    setState={setState}
                    onChange={onChange}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ marginBottom: '1em' }} />
            </TabPane>
          </StyledTabs>
          <GridContainer
            style={{ borderTop: '1px solid rgba(229, 233, 242, 1)' }}
          >
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{
                  marginTop: '2.5em',
                  float: 'right',
                  backgroundColor: '#00CC70',
                  border: '0px solid grey',
                  color: 'white',
                  width: '9em',
                  height: '3em',
                  fontWeight: 700,
                  fontFamily: 'Mulish , sans-serif',
                  borderRadius: '3em',
                  textTransform: 'initial'
                }}
                onClick={() => savePrinterSettings()}
                className={props.classes.buttonAddInput}
              >
                {' '}
                Save Settings
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Snackbar {...props} />
      {state.printCurrent && state.printCurrent.modelName && (
        <ComponentToPrint ref={componentRef} />
      )}
    </Spin>
  );
};

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems
});

const mapDispatchToProps = {
  getPrinterSettings,
  savePrinterSettings,
  openSnackbar,
  closeSnackbar,
  setHelpMarker
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Printing));
