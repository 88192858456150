import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ConfigProvider,
  Empty,
  Icon,
  Input,
  Popover,
  Radio,
  Row,
  Spin,
  Table,
  Upload
} from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { FiEdit } from 'react-icons/fi';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { addBrands, editBrands, getBrands } from 'src/redux/actions/backend';
import {
  resetActionBreadCrumb,
  setBreadCrumbs
} from 'src/redux/actions/breadcrumbs';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
// import Table from 'src/views/Admin/Settings/ItemBrand/Table';

import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import { setHelpMarker } from 'src/redux/actions/help';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Column } = Table;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  panel: {
    width: '400px'
  },
  cardExpand: {
    marginTop: '22px',
    marginBottom: '5px'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  showMain: {
    paddingLeft: '10px',
    display: 'inline-flex'
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  buttonAddInput: {
    height: '35px',
    margin: '45px'
  }
});

class Brand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      main: true,
      mainInput: false,
      _id: '',
      brands: [],
      brandName: '',
      printto: '',
      print: [
        {
          key: 'None',
          value: 'None'
        },
        {
          key: 'Bar',
          value: 'Bar'
        },
        {
          key: 'Kitchen',
          value: 'Kitchen'
        }
      ],
      inputCount: 0,
      loading: false,
      alert: false,
      msg: [],
      head: '',
      activeFilter: ['true']
    };
  }
  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value
    });
  };
  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](true);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.getBrands();
  }

  getBrands = msg => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .getBrands(token)
      .then(result => {
        this.setState({
          brands: result.data.results,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  addBrands = () => {
    this.setState({ loading: true, submitInProgress: true });
    const token = this.props.local.user.token;
    const state = this.state;
    if (state.brandName === '') {
      this.setState({ loading: false });
      this.props.openSnackbar('Enter Brand name');
      return;
    }
    this.props
      .addBrands(state, token)
      .then(result => {
        this.handleRemoveBreadCrumb();
        this.setState({
          mainInput: false,
          brandName: '',
          image: undefined,
          printto: '',
          submitInProgress: false
        });
        this.getBrands('Save Complete');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  editBrands = (del, record) => {
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    var state = this.state;
    if (del) {
      state = {
        _id: record._id,
        brandName: record.name,
        printto: record.printo,
        prevName: record.prevName
      };
      if (del === 'res') {
        state.active = true;
      } else {
        state.active = false;
      }
    } else {
      state.active = true;
    }
    this.props
      .editBrands(state, token)
      .then(result => {
        this.handleRemoveBreadCrumb();
        this.setState({
          main: true,
          _id: '',
          brandName: '',
          image: undefined,
          prevName: '',
          printto: ''
        });
        this.getBrands('Update Complete');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onEditMenu = brand => {
    this.handleAddBreadCrumb({ method: 'handleCloseMenu', crumb: 'Brands' });
    this.setState({
      _id: brand._id,
      brandName: brand.name,
      image: brand.image,
      prevName: brand.prevName,
      printto: brand.printto,
      main: false
    });
  };

  handleCloseMenu = () => {
    this.setState({
      main: true,
      _id: '',
      brandName: '',
      printto: ''
    });
    this.handleRemoveBreadCrumb();
  };

  onChangeMainBtn = sw => {
    this.setState({
      mainInput: sw,
      main: false
    });
  };

  onChangeEvt(e) {
    if (e.target.id === 'name') {
      this.setState({ brandName: e.currentTarget.value });
    }
    if (e.target.id === 'nameedit') {
      this.setState({ brandName: e.currentTarget.value });
    }
  }

  onChangeEvtPrint(e, event) {
    if (e) {
      this.setState({ printto: event.key });
    }
  }

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Brand Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        description={false}
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />

      <button
        style={{
          marginTop: '-0.7em',
          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',
          width: '9em',
          height: '2.8em',
          fontSize: 16,
          fontFamily: '',
          borderRadius: '3em',
          zIndex: 99,
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => this.addBrands()}
        color="primary"
      >
        {'Add Brand'}
      </button>
    </div>
  );
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };

  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, 'png', 100, 0, uri => {
      callback(uri);
    });
  };

  handleImage = info => {
    this.setState({ loading: true });
    this.getBase64(info.file, imageUrl => {
      this.setState({ loading: false, image: imageUrl });
    });
  };
  beforeUpload = file => {
    return false;
  };

  removeImage = index => {
    this.setState({ image: undefined });
  };
  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker,
      onEditMenu: this.onEditMenu
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div style={{ width: '8em' }}>
        <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
          <Radio
            value={1}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(['true'])}
          >
            Active
          </Radio>
          <Radio
            value={2}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(['false'])}
          >
            Inactive
          </Radio>
          <Radio
            value={3}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(false)}
          >
            All
          </Radio>
        </Radio.Group>
      </div>
    );
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.main ? (
          <div>
            {' '}
            <Button
              style={{
                position: 'fixed',
                right: '0.5em',
                bottom: '0.5em',
                background: '#132440',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                boxShadow: 'none',
                height: '3.5em',
                borderRadius: '5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.addBrands()}
              color="primary"
            >
              <Icon
                style={{
                  position: 'absolute',
                  paddingLeft: 3
                }}
                type="check"
              />
            </Button>
            <Row style={{ marginBottom: '-0.9em' }}>
              <div
                style={{
                  left: '13em',
                  marginTop: '-3.3em',
                  position: 'absolute',
                  zIndex: 99
                }}
              >
                <CustomInput
                  labelText="Name"
                  id="name"
                  autoFocus={true}
                  error={!this.state.brandName && this.state.submitInProgress}
                  errorText={'Name required'}
                  formControlProps={{
                    fullWidth: false
                  }}
                  inputProps={{
                    onChange: e => this.onChangeEvt(e),
                    value: this.state.brandName,
                    onClick: () => this.onHelpMarker('menuitemsqty')
                  }}
                />
              </div>

              {this.state.brands.length > 0 ? (
                <button
                  style={{
                    marginTop: '-0.7em',
                    backgroundColor: '#00CC70',
                    border: '0px solid rgb(30, 161, 169)',
                    color: 'white',
                    width: '9em',
                    height: '2.8em',
                    fontSize: 16,
                    fontFamily: '',
                    borderRadius: '3em',
                    zIndex: 99,
                    position: 'absolute'
                  }}
                  onClick={() => this.addBrands()}
                  color="primary"
                >
                  {'Add Brand'}
                </button>
              ) : null}
            </Row>
            <Row>
              <div
                style={{
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                {/*  <Button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgb(15, 135, 123)",
                    borderRight: "0px solid rgba(0, 128, 128, 0.548)",
                    boxShadow: "none",
                    color: "rgb(15, 135, 123)",
                    marginBottom: "-1em",
                    height: "3.5em",
                    borderRadius: "0em",
                    borderTopLeftRadius: "0.3em",
                    borderBottomLeftRadius: "0.3em",
                    transform: "scale(0.9)"
                  }}
                  onClick={() => {
                    this.handleFormatList(true);
                  }}
                >
                  Active
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgb(15, 135, 123)",
                    borderLeft: "0px solid rgba(0, 128, 128, 0.548)",
                    boxShadow: "none",
                    color: "rgb(15, 135, 123)",
                    marginBottom: "-1em",
                    height: "3.5em",
                    borderRadius: "0em",
                    transform: "scale(0.9)",
                    marginLeft: "-2em"
                  }}
                  onClick={() => this.handleFormatList(false)}
                >
                  Inactive
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgb(15, 135, 123)",
                    borderLeft: "0px solid rgba(0, 128, 128, 0.548)",
                    boxShadow: "none",
                    color: "rgb(15, 135, 123)",
                    marginBottom: "-1em",
                    height: "3.5em",
                    borderRadius: "0em",
                    borderTopRightRadius: "0.3em",
                    borderBottomRightRadius: "0.3em",
                    transform: "scale(0.9)",
                    marginLeft: "-2em"
                  }}
                  onClick={() => this.handleFormatList(undefined)}
                >
                  All
                </Button>
              </div>{" "}
              <div style={{ float: "right" }}>
                 <Button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgb(15, 135, 123)",
                    borderRight: "0px solid rgba(0, 128, 128, 0.548)",
                    color: "rgb(15, 135, 123)",
                    marginBottom: "-0.5em",
                    height: "3.5em",
                    borderRadius: "0em",
                    borderTopLeftRadius: "0.3em",
                    borderBottomLeftRadius: "0.3em",
                    transform: "scale(0.9)",
                    marginRight: "-2.4em",
                    boxShadow: "none"
                  }}
                >
                  <Icon brand="up-circle" />
                  Export
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgb(15, 135, 123)",
                    borderLeft: "0px solid rgba(0, 128, 128, 0.548)",
                    color: "rgb(15, 135, 123)",
                    marginRight: "0em",
                    marginBottom: "-0.5em",
                    height: "3.5em",
                    borderRadius: "0em",
                    borderTopRightRadius: "0.3em",
                    borderBottomRightRadius: "0.3em",
                    transform: "scale(0.9)",
                    boxShadow: "none"
                  }}
                >
                  <Icon brand="down-circle" /> Import
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgb(15, 135, 123)",
                    color: "rgb(15, 135, 123)",
                    marginBottom: "-0.5em",
                    height: "3.5em",
                    marginLeft: "-0.27em",
                    borderRadius: "0.3em",
                    transform: "scale(0.9)",
                    width: "2em"
                  }}
                >
                  {" "}
                  <Icon brand="search" />
                </Button>*/}

                <Popover
                  overlayStyle={{ borderRadius: '3em' }}
                  placement="left"
                  content={EyeContent}
                  title="Table settings"
                  trigger="click"
                >
                  <Button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px solid grey',
                      color: 'white',
                      bottom: '-6.1em',
                      right: '-0.5em',
                      zIndex: 9,

                      float: 'right',
                      borderRadius: '5em',
                      boxShadow: 'none',
                      width: '2em'
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      theme="filled"
                      type="setting"
                    />
                  </Button>
                </Popover>
                <Popover
                  placement="bottomRight"
                  content={GearContent}
                  title=""
                  trigger="click"
                >
                  <Button
                    style={{
                      display: 'none',
                      backgroundColor: 'white',
                      border: '1px solid rgb(15, 135, 123)',
                      color: 'rgb(15, 135, 123)',
                      marginBottom: '-0.5em',
                      height: '3.5em',
                      marginLeft: '-0.27em',
                      borderRadius: '0.3em',
                      transform: 'scale(0.9)',
                      width: '2em'
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      type="setting"
                    />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Row style={{ marginTop: '1em' }}>
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={this.state.brands}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 25,
                    itemRender: (current, brand, originalElement) => {
                      if (brand === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (brand === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (!record.active) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          this.onEditMenu(record);
                        },
                        style: { background: 'lightcoral' }
                      };
                    } else if (rowIndex % 2 == 0) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          this.onEditMenu(record);
                        },
                        style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          this.onEditMenu(record);
                        }
                      };
                    }
                  }}
                >
                  {this.props.local.user.ecommerceActive ? (
                    <Column
                      title="Image"
                      render={(text, record) => {
                        if (record.image) {
                          return (
                            <img
                              style={{ maxHeight: 58, maxWidth: 58 }}
                              alt={record.name}
                              src={
                                server +
                                'vpos/api/static/images/' +
                                record.image
                              }
                            />
                          );
                        } else {
                          return 'N/A';
                        }
                      }}
                    />
                  ) : null}
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getActiveSearchProps('active')}
                    filterDropdownVisible={false}
                    title="Active"
                    filtered={true}
                    filteredValue={this.state.activeFilter}
                    dataIndex="active"
                    render={(text, record) => (
                      <span>{record.active.toString()}</span>
                    )}
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div>
                        <IconButton
                          style={{
                            padding: '8px',
                            border: '1px solid #21B41E'
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            this.onEditMenu(record);
                          }}
                        >
                          {
                            <FiEdit
                              type="edit"
                              style={{
                                fontSize: 16,

                                color: '#21B41E'
                              }}
                            />
                          }
                        </IconButton>
                        {record.active ? (
                          <IconButton
                            style={{
                              border: '1px solid red',
                              padding: '16px',
                              marginLeft: '1em'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              this.editBrands(true, record);
                            }}
                          >
                            {
                              <Icon
                                type="delete"
                                style={{
                                  position: 'absolute',
                                  fontSize: '16px',
                                  color: 'red'
                                }}
                              />
                            }
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{
                              border: '1px solid green',
                              marginLeft: '1em',
                              padding: '16px'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              this.editBrands('res', record);
                            }}
                          >
                            {
                              <Icon
                                type="rollback"
                                style={{
                                  position: 'absolute',
                                  fontSize: '15px',
                                  color: 'green'
                                }}
                              />
                            }
                          </IconButton>
                        )}
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : (
          <div
            className={
              this.state.main
                ? this.props.classes.hide
                : this.props.classes.show
            }
          >
            <Card>
              {/*} <CardHeader
                style={{
                  borderRadius: '0.5em',
                  backgroundColor: '#1EA1A9'
                }}
              >
                <h4
                  style={{ color: 'white' }}
                  className={this.props.classes.cardTitleWhite}
                >
                  Brands
                </h4>
              </CardHeader> */}
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={5}
                    style={{ marginTop: '0.2em' }}
                  >
                    <CustomInput
                      labelText="Name*"
                      id="nameedit"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.onChangeEvt(e),
                        value: this.state.brandName,
                        onClick: () => this.onHelpMarker('usersname')
                      }}
                    />
                  </GridItem>
                  {this.props.local.user.ecommerceActive ? (
                    <GridItem xs={12} sm={12} md={5}>
                      {!this.state.image ? (
                        <div
                          style={{
                            width: 200,
                            height: 200,
                            border: '1px solid',
                            display: 'flex'
                          }}
                        >
                          <div style={{ margin: 'auto' }}>
                            {' '}
                            <Upload
                              name="image"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <div
                                style={{ display: 'grid', textAlign: 'center' }}
                              >
                                <Button
                                  style={{
                                    width: '12em',
                                    backgroundColor: '#00CC70',
                                    borderRadius: '0.3em',
                                    zIndex: 9,
                                    margin: 'auto'
                                  }}
                                  color="primary"
                                >
                                  Upload Image
                                </Button>
                                <span>or</span>
                                <span>Drop File Here</span>
                              </div>
                            </Upload>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: 200,
                            height: 200,
                            border: '1px solid',
                            display: 'flex'
                          }}
                        >
                          <img
                            style={{
                              margin: 'auto',
                              maxHeight: '99%',
                              maxWidth: '99%'
                            }}
                            alt={'image'}
                            src={
                              this.state.image.search('base64') > -1
                                ? this.state.image
                                : server +
                                  'vpos/api/static/images/' +
                                  this.state.image
                            }
                          />
                          <div
                            style={{
                              position: 'absolute',
                              marginLeft: 155,
                              top: 10
                            }}
                          >
                            <IconButton onClick={() => this.removeImage()}>
                              {
                                <Icon
                                  type="delete"
                                  style={{
                                    fontSize: '12px',
                                    color: 'red',
                                    border: '1px solid',
                                    borderRadius: '3em',
                                    padding: '0.4em'
                                  }}
                                />
                              }
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </GridItem>
                  ) : null}
                </GridContainer>
                <CardFooter style={{ marginLeft: '-0.2em', display: 'inline' }}>
                  <Button
                    onClick={() => this.editBrands()}
                    color="primary"
                    style={{
                      width: '8.5em',
                      borderRadius: '3.5em',
                      marginRight: '1em',
                      marginLeft: '1em',
                      float: 'right',
                      border: '1px solid #00CC70'
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      borderRadius: '3.5em',
                      border: '1px solid #00CC70',
                      color: '#00CC70',
                      float: 'right',
                      backgroundColor: 'white'
                    }}
                    onClick={() => this.handleCloseMenu()}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </div>
          // <div
          //   className={
          //     !this.state.main ? this.props.classes.hide : this.props.classes.show
          //   }
          // >
          //   <Card className={this.props.classes.cardExpand}>
          //     <CardHeader color="primary">
          //       <h4 className={this.props.classes.cardTitleWhite}>
          //         Manage Item Brands
          //       </h4>
          //       <p className={this.props.classes.cardCategoryWhite}>
          //         List of Item Brands
          //       </p>
          //     </CardHeader>
          //     <CardBody>
          //       <GridContainer>
          //         <div
          //           className={
          //             this.state.mainInput
          //               ? this.props.classes.hide
          //               : this.props.classes.show
          //           }
          //         >
          //           <Button
          //             style={{ left: '10px' }}
          //             onClick={() => this.onChangeMainBtn(!this.state.mainInput)}
          //             color="primary"
          //           >
          //             {'ADD TYPE'}
          //           </Button>
          //         </div>
          //         <div
          //           className={
          //             this.state.mainInput
          //               ? this.props.classes.showMain
          //               : this.props.classes.hide
          //           }
          //         >
          //           <GridContainer
          //             style={{
          //               backgroundColor: 'whitesmoke',
          //               textAlign: 'center'
          //             }}
          //           >
          //             <GridItem xs={12} sm={12} md={5}>
          //               <CustomInput
          //                 labelText="Name"
          //                 id="name"
          //                 formControlProps={{
          //                   fullWidth: true
          //                 }}
          //                 inputProps={{
          //                   onChange: e => this.onChangeEvt(e),
          //                   value: this.state.brandName,
          //                   onClick: () => this.onHelpMarker('menuitemsqty')
          //                 }}
          //               />
          //             </GridItem>

          //             <GridItem xs={12} sm={12} md={3}>
          //               <CustomSelectAuto
          //                 selectedItem={oState.state.printto || undefined}
          //                 onChange={(e, event) => this.onChangeEvtPrint(e, event)}
          //                 labelText="Print To"
          //                 id="printto"
          //                 items={oState.state.print}
          //                 formControlProps={{
          //                   style: { paddingRight: '5px' },
          //                   fullWidth: true,
          //                   onClick: () =>
          //                     oState.onHelpMarker('menuitemsstockitem')
          //                 }}
          //               />
          //             </GridItem>
          //             <GridItem xs={2} sm={2} md={2}>
          //               <Button
          //                 onClick={() => this.addBrands()}
          //                 color="primary"
          //                 className={this.props.classes.buttonAdd}
          //               >
          //                 {'SAVE'}
          //               </Button>
          //             </GridItem>
          //             <GridItem xs={2} sm={2} md={2}>
          //               <Button
          //                 onClick={() =>
          //                   this.onChangeMainBtn(!this.state.mainInput)
          //                 }
          //                 color="primary"
          //                 className={this.props.classes.buttonAdd}
          //               >
          //                 {'CANCEL'}
          //               </Button>
          //             </GridItem>
          //           </GridContainer>
          //         </div>
          //         <Table {...oState} />
          //       </GridContainer>
          //     </CardBody>
          //   </Card>
          // </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addBrands,
  editBrands,
  getBrands,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Brand));
