import React, { Component } from 'react';
import { Route } from 'react-router';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import { Icon } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import {
  sendNotification,
  findNotifications,
  removeNotification
} from 'src/redux/actions/notifications';
import {
  setBreadCrumbs,
  actionBreadCrumb,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import {
  initLoader,
  removeLoader
} from 'src/redux/actions/initdashloader';
import { resetHelpMarker } from 'src/redux/actions/help';
import { resetOrg, resetUser, switchKitchen, getBilling } from 'src/redux/actions/backend';
import moment from 'moment';
import DrawerL from 'src/components/Layout/Admin/DrawerL';
import DrawerS from 'src/components/Layout/Admin/DrawerS';
import Wrapper from 'src/components/Layout/Wrapper';
import Style from 'src/components/Layout/Admin/Style';
import AdminRoutes from 'src/config/AdminRoutes';
var Routes = '';

const getRoutes = routes => {
  Routes = routes;
};

const DrawerComp = ({ ...propstate }) => {
  // const size = useMediaQuery('(min-width:600px)');
  // return (
  //   <div>{size ? <DrawerL {...propstate} /> : <DrawerS {...propstate} />}</div>
  // );
  return (
    <div><DrawerL {...propstate} /></div>
  );
};

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openSub: false,
      close: true,
      openMini: false,
      closeMini: true,
      openHelp: false,
      helpheadname: '',
      subheadname: '',
      subs: [],
      profileVisible: false,
      notifications: [],
      orderCount: 0,
      expired: false
    };
  }

  componentWillMount = () => {
    var element = document.getElementById('root');
    element.classList.remove('dash-root');
    const token = this.props.user.token;
    this.props.findNotifications(token).then(() => {
      this.setState({ notifications: this.props.notifications });
    });
    if (this.props.user && this.props.user.role && !this.props.user.role.isSupAdmin && this.props.user.mode && !this.props.user.mode["Dashboard"]) {
      this.props.history.push('/admin/none');
    }
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack, false);
    }
    this.props.getBilling(token).then((result) => {
      var billing = result.data.results;
      if (billing.paymentType === "Monthly" && (billing.status === "Subscribed" || billing.status === "Cancelled")) {
        if (moment().add(-3,"day").startOf("day").isAfter(billing.subscriptionEnd)) {
          this.setState({ expired: true }, () =>{
            if (this.state.expired) {
              this.props.history.push("/admin/billing")
            }
            if (window.location.search.search("completed") > -1 || window.location.search.search("cancelled") > -1) {
              this.props.history.push("/admin/billing")
            }
          });
        }
      } else if (billing.paymentType === "Monthly" && billing.status === "No Subscription") {
        this.setState(
          { expired: true }, () =>{
            if (this.state.expired) {
              this.props.history.push("/admin/billing")
            }
            if (window.location.search.search("completed") > -1 || window.location.search.search("cancelled") > -1) {
              this.props.history.push("/admin/billing")
            }
          });
      } else if (billing.paymentType === "Trial") {
        if (moment().startOf("day").isAfter(billing.subscriptionEnd)) {
          this.setState({ expired: true }, () =>{
            if (this.state.expired) {
              this.props.history.push("/admin/billing")
            }
            if (window.location.search.search("completed") > -1 || window.location.search.search("cancelled") > -1) {
              this.props.history.push("/admin/billing")
            }
          });
        }
      } else if (billing.paymentType === "Cash" && billing.status === "No Subscription") {
        this.setState({ expired: true }, () =>{
          if (this.state.expired) {
            this.props.history.push("/admin/billing")
          }
          if (window.location.search.search("completed") > -1 || window.location.search.search("cancelled") > -1) {
            this.props.history.push("/admin/billing")
          }
        });
      } else {
        this.setState({ expired: false }, () =>{
          if (this.state.expired) {
            this.props.history.push("/admin/billing")
          }
          if (window.location.search.search("completed") > -1 || window.location.search.search("cancelled") > -1) {
            this.props.history.push("/admin/billing")
          }
        });
      }
    });
  }
  openSnackbar = msg => {
    this.setState({ snackOpen: true, snackMessage: msg })
  };
  handleBack = () => {
    if (this.props.history.location.pathname === "/") {
      this.openSnackbar("Press back again to close the app")
      if (this.exit) {
        navigator.app.exitApp()
      }
      this.exit = true;
      setTimeout(() => {
        this.exit = false;
      }, 2000)
    } else {
      this.props.history.goBack()
    }
  }
  checkOnlineInterval;
  componentWillUnmount = () => {
    clearInterval(this.checkOnlineInterval)
    document.removeEventListener('offline', this.setOffline, false);
    document.removeEventListener('online', this.setOnline, false);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack, false);
    }
  }

  handleNotificationClick = e => {
    const token = this.props.user.token;
    let item = JSON.parse(e.currentTarget.getAttribute('data'));
    if (item.message.type === 'itemAddRequest') {
      this.props.history.push('/admin/menuitems?requests=true');
    }
    if (item.message.type === 'stockAdjust') {
      this.props.history.push('/admin/stockadjust');
    }
    this.props.removeNotification(item._id, token).then(() => {
      this.props.findNotifications(token).then(() => {
        this.setState({ notifications: this.props.notifications });
      });
    });
  };
  clearNotifications = e => {
    const token = this.props.user.token;
    this.props.removeNotification(undefined, token).then(() => {
      this.props.findNotifications(token).then(() => {
        this.setState({ notifications: this.props.notifications });
      });
    });
  };

  componentDidMount() {
    this.props.resetActionBreadCrumb()
    this.props.setBreadCrumbs([])
    this.props.resetHelpMarker();
    const token = this.props.user.token;
    this.props.removeLoader();
    window.pubnub.addListener({
      message: msg => {
        this.props.findNotifications(token).then(() => {
          this.setState({ notifications: this.props.notifications });
        });
      }
    });
    if (window.cordova) {
      window.FirebasePlugin.onMessageReceived(
        notif => {
          notif.message = JSON.parse(notif.message);
          if (notif.tap === 'background') {
            const token = this.props.user.token;
            if (notif.message.type === 'itemAddRequest') {
              this.props.history.push('/admin/menuitems?requests=true');
            }
            this.props.removeNotification(notif.notId, token).then(() => {
              this.props.findNotifications(token).then(() => {
                this.setState({ notifications: this.props.notifications });
              });
            });
          }
        },
        error => {
          console.error(error);
        }
      );
    }
    document.addEventListener('offline', this.setOffline, false);
    document.addEventListener('online', this.setOnline, false);
    if (!window.cordova) {
      this.checkOnlineInterval = setInterval(() => {
        if (navigator) {
          if (!this.state.offline !== navigator.onLine) {
            this.setState({ offline: !this.state.offline })
            if (navigator.onLine) {
              this.setOnline();
            } else {
              this.setOffline();
            }
          }
        }
      }, 1000);
    }
  }

  setOffline = () => {
    window.offline = true;
    this.setState({ offline: true });
  };

  setOnline = () => {
    window.offline = false;
    this.setState({ offline: false });
  };

  handleDrawerOpen = () => {
    this.setState({
      open: true,
      close: false,
      openHelp: false
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false,
      openSub: false,
      openHelp: false,
      subheadname: '',
      close: true
    });
  };

  handleDrawerOpenSub = prop => {
    this.setState({
      openSub: true,
      subheadname: prop.name,
      subs: prop.subs
    });
  };

  handleDrawerCloseSub = () => {
    this.setState({ openSub: false });
  };

  handleDrawerOpenHelp = () => {
    this.setState({
      openHelp: true,
      open: false,
      openSub: false,
      subheadname: '',
      close: true
    });
  };

  handleDrawerCloseHelp = () => {
    this.setState({ openHelp: false });
  };

  handleDrawerOpenMini = () => {
    this.setState({
      openMini: true,
      closeMini: false
    });
  };

  handleDrawerCloseMini = () => {
    this.setState({
      openMini: false,
      closeMini: true
    });
  };

  handleSwitch = () => {
    this.props.history.push('/');
    this.props.switchKitchen();
  };

  handleLogout = () => {
    this.props.history.push('/');
    if (this.props.user.pack.isDely) {
      this.props.resetOrg();
    } else {
      this.props.resetUser();
    }
  };

  handleLogoutOrg = () => {
    let store = this.props.reduxStore;
    store.purge().then(() => {
      window.location.reload();
    });
    //this.props.history.push('/');
    //this.props.resetOrg();
  };
  setProfileVisible = e => {
    this.setState({ profileVisible: e });
  };
  render = () => {
    const vertical = 'bottom';
    const horizontal = 'right';
    const props = this.props;
    const state = this.state;
    const { classes } = props;
    const propstate = {
      props: props,
      state: state,
      handleDrawerOpen: this.handleDrawerOpen,
      handleDrawerClose: this.handleDrawerClose,
      handleDrawerOpenSub: this.handleDrawerOpenSub,
      handleDrawerCloseSub: this.handleDrawerCloseSub,
      handleDrawerOpenHelp: this.handleDrawerOpenHelp,
      handleDrawerCloseHelp: this.handleDrawerCloseHelp,
      handleDrawerOpenMini: this.handleDrawerOpenMini,
      handleDrawerCloseMini: this.handleDrawerCloseMini,
      handleSwitch: this.handleSwitch,
      handleLogout: this.handleLogout,
      handleLogoutOrg: this.handleLogoutOrg,
      setProfileVisible: this.setProfileVisible,
      handleNotificationClick: this.handleNotificationClick,
      clearNotifications: this.clearNotifications
    };

    return (
      <div className={classes.root}>
        <AdminRoutes newobjectroutes={getRoutes} />
        <div className={classes.backgroundimage} />
        <CssBaseline />
        <DrawerComp expired={this.state.expired} {...propstate} />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: this.state.open
          })}
        >
          <Wrapper>
            {Object.keys(Routes).map((route, i) => (
              <Route key={i} {...Routes[route]} />
            ))}
          </Wrapper>
        </main>
        {this.state.offline ? (
          <div
            style={{
              position: 'fixed',
              textAlign: "center",
              paddingRight: "3em",
              width: "100vw",
              top: 65,
              left: "79px",
              fontSize: 16,
              zIndex: 999,
              backgroundColor: 'rgba(255, 0, 0, 0.63)',
              color: "white"
            }}
          >
            <Icon style={{ paddingRight: "0.2em", }} type="exclamation-circle" theme="filled" /> You are currently offline! Any changes will not be saved.
          </div>
        ) : null}
        <Snackbar
          open={this.state.snackOpen}
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          autoHideDuration={2000}
          onClose={() =>
            this.setState({
              snackOpen: false,
              snackMessage: ''
            })
          }
          TransitionComponent={Fade}
          style={{
            color: '#fff',
            background: '#00CC70'
          }}
          ContentProps={{
            'aria-describedby': 'message-id',
            style: {
              color: '#fff',
              background: '#00CC70'
            }
          }}
          message={<span id="message-id">{this.state.snackMessage}</span>}
        />
      </div>
    );
  };
}

const mapStateToProps = state => ({
  local: state,
  user: state.backend.user,
  notifications: state.notifications.notifications,
  orderCount: state.notifications.orderCount,
  breadCrumbs: state.breadcrumbs.breadCrumbs
});

const mapDispatchToProps = {
  resetHelpMarker,
  switchKitchen,
  resetOrg,
  resetUser,
  sendNotification,
  findNotifications,
  removeNotification,
  setBreadCrumbs,
  actionBreadCrumb,
  resetActionBreadCrumb,
  initLoader,
  removeLoader,
  getBilling
};

export default compose(
  withRouter,
  withStyles(Style, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Admin);
