import { Col, Icon, Input, Row } from 'antd';
import React, { useState, useEffect, memo } from 'react';
import NumPad from 'react-numpad';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';

let setFloat = undefined;
var props = {};
var notes = [10, 20, 50, 100, 200];
const UserFloat = memo(({ ...oState }) => {
  var coins = [
    {
      prefix: '',
      suffix: 'c',
      value: '10'
    },
    {
      prefix: '',
      suffix: 'c',
      value: '20'
    },
    {
      prefix: '',
      suffix: 'c',
      value: '50'
    },
    {
      prefix: 'R',
      suffix: '',
      value: 1
    },
    {
      prefix: 'R',
      suffix: '',
      value: 2
    },
    {
      prefix: 'R',
      suffix: '',
      value: 5
    }
  ];

  const spanStyle = {
    margin: 'auto',
    fontSize: 15,
    color: 'rgba(0, 204, 112, 0.898)'
  };
  const col1Style = {
    boxShadow: '0px 3px 5px 0px #e2e2e2',
    borderRadius: 3,
    height: 40,
    display: 'flex'
  };
  const buttonStyle = {
    color: 'rgba(0, 204, 112, 0.898)',
    background: 'transparent',
    margin: 'auto',
    borderRadius: '3em',
    border: '1px solid rgba(0, 204, 112, 0.898)',
    height: 40,
    lineHeight: 0,
    width: '100%'
  };
  const inputStyle = {
    height: 40,
    textAlign: 'center',
    border: 'none',
    color: 'black',
    fontSize: '22px'
  };
  const floatButton = {
    transition: '0.3s',
    backgroundColor: 'rgba(15, 135, 123, 0.725)',
    textAlign: 'center',
    width: '80%',
    color: 'white !important',
    borderRadius: 5,
    height: 59,
    fontSize: '25px !important'
  };
  const numpadTheme = {
    header: {
      primaryColor: '#263238',
      secondaryColor: '#f9f9f9',
      highlightColor: '#FFC107',
      backgroundColor: '#607D8B'
    },
    body: {},
    panel: {},
    global: {}
  };
  return (
    <div
      style={{
        backgroundColor: 'white',
        height: '60vh',
        overflow: 'hiddin'
      }}
    >
      <div
        style={{
          top: '1em',
          position: 'absolute',
          right: '5%',
          float: 'right',
          fontSize: '20px',
          fontWeight: '700',
          color: 'red'
        }}
        onClick={props.backToCashup}
      >
        <Icon type="left-circle" /> Cancel
      </div>
      <span
        className="float-screen"
        style={{
          position: 'absolute',
          fontSize: '32px',
          fontWeight: '700',
          paddingLeft: '1.5em',
          top: '0.5em'
        }}
      >
        Cash Count
      </span>{' '}
      <Card style={{ marginTop: '-0.5em' }}>
        <Row style={{ padding: 30 }} type="flex" justify="space-between">
          {' '}
          <Col span={24}>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <CardHeader
                  className="float-screen"
                  style={{
                    height: '1em',
                    fontSize: '19px',
                    fontWeight: '700',
                    color: 'rgba(0, 204, 112, 0.898)',
                    marginBottom: '20px',
                    marginTop: '-20px',
                    paddingBottom: '2em',
                    borderBottom: '1px solid grey'
                  }}
                >
                  Notes
                </CardHeader>
                <CardBody className="float-buttons">
                  {notes.map((note, index) => (
                    <Row
                      key={'note' + index}
                      style={{ padding: 8 }}
                      type="flex"
                      justify="space-between"
                    >
                      <Col span={7} style={col1Style}>
                        <span style={spanStyle}>R{note}</span>
                      </Col>
                      <Col span={7}>
                        <NumPad.Number
                          theme={numpadTheme}
                          position="startBottomRight"
                          confirm={e => {}}
                          update={e => {}}
                          onChange={e =>
                            oState.floatInputChangeNoteInputsNumpad(
                              e,
                              index,
                              note
                            )
                          }
                          value={
                            oState.state.floatInputsNoteValues[index].amount
                          }
                          inline={false}
                          decimal={false}
                          negative={false}
                        >
                          <Input
                            style={inputStyle}
                            value={
                              oState.state.floatInputsNoteValues[index].amount
                            }
                          />
                        </NumPad.Number>
                      </Col>
                      <Col span={4}>
                        <Button
                          className="float-button-subtract"
                          style={buttonStyle}
                          onClick={e =>
                            oState.floatInputChangeNoteInputs(
                              'subtract',
                              index,
                              note
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>-</span>
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          className="float-button-add"
                          style={buttonStyle}
                          onClick={e =>
                            oState.floatInputChangeNoteInputs(
                              'add',
                              index,
                              note
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>+</span>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Col>

              <Col span={12}>
                <CardHeader
                  className="float-screen"
                  style={{
                    height: '1em',
                    fontSize: '19px',
                    fontWeight: '700',
                    color: 'rgba(0, 204, 112, 0.898)',
                    marginBottom: '20px',
                    marginTop: '-20px',
                    paddingBottom: '2em',
                    borderBottom: '1px solid grey'
                  }}
                >
                  Coins
                </CardHeader>
                <CardBody className="float-buttons">
                  {coins.map((coin, index) => (
                    <Row
                      key={'coins' + index}
                      style={{ padding: 8 }}
                      type="flex"
                      justify="space-between"
                    >
                      <Col span={7} style={col1Style}>
                        <span style={spanStyle}>
                          {coin.prefix + coin.value + coin.suffix}
                        </span>
                      </Col>
                      <Col span={7}>
                        <NumPad.Number
                          theme={numpadTheme}
                          position="startBottomRight"
                          confirm={e => {}}
                          update={e => {}}
                          onChange={e =>
                            oState.floatInputChangeCoinInputsNumpad(
                              e,
                              index,
                              coin
                            )
                          }
                          value={
                            oState.state.floatInputsCoinValues[index].amount
                          }
                          inline={false}
                          decimal={false}
                          negative={false}
                        >
                          <Input
                            style={inputStyle}
                            value={
                              oState.state.floatInputsCoinValues[index].amount
                            }
                          />
                        </NumPad.Number>
                      </Col>
                      <Col span={4}>
                        <Button
                          className="float-button-subtract"
                          style={buttonStyle}
                          onClick={e =>
                            oState.floatInputChangeCoinInputs(
                              'subtract',
                              index,
                              coin.value
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>-</span>
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          className="float-button-add"
                          style={buttonStyle}
                          onClick={e =>
                            oState.floatInputChangeCoinInputs(
                              'add',
                              index,
                              coin.value
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>+</span>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          className="float-screen-button"
        >
          <Col span={24}>
            <Card
              style={{
                background: 'transparent',
                height: '15em',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '-8em',
                maxWidth: '30em',
                boxShadow: 'none'
              }}
            >
              <CardBody style={{ display: 'flex' }}>
                <div
                  style={{
                    margin: 'auto',
                    textAlign: 'center',
                    width: '100%',
                    marginTop: '4em'
                  }}
                >
                  <span style={{ fontSize: 60, fontWeight: '700' }}>
                    R{oState.state.float.toFixed(2)}
                  </span>
                  {/*} <Button
                    style={floatButton}
                    onClick={() => props.countComplete(oState.state.float)}
                  >
                    {"Done"}
            </Button>  */}{' '}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>{' '}
      </Card>{' '}
      <Button
        style={{
          position: 'fixed',
          right: '0.5em',
          bottom: '0.5em',
          background: 'rgba(0, 204, 112, 0.898)',
          border: '1px solid transparent',
          color: 'white',
          boxShadow:"none",
          padding: '0em',
          width: '3.5em',
          height: '3.5em',
          borderRadius: '5em',
          fontSize: '1em',
          zIndex: 1000
        }}
        onClick={() => props.countComplete(oState.state.float)}
        color="primary"
      >
        <Icon
          style={{
            position: 'absolute',
            paddingLeft: 3
          }}
          type="check"
        />
      </Button>
    </div>
  );
});

const componentDidMount = () => {
  setFloat(prevState => ({
    ...prevState,
    float: 0,
    floatInputsNoteValues: [
      { value: 10, amount: 0 },
      { value: 20, amount: 0 },
      { value: 50, amount: 0 },
      { value: 100, amount: 0 },
      { value: 200, amount: 0 }
    ],
    floatInputsCoinValues: [
      { value: 0.1, amount: 0 },
      { value: 0.2, amount: 0 },
      { value: 0.5, amount: 0 },
      { value: 1, amount: 0 },
      { value: 2, amount: 0 },
      { value: 5, amount: 0 }
    ]
  }));
};

const floatInputChangeNoteInputs = (e, key, amount) => {
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  var inputs = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsNoteValues)
  );
  var inputs2 = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsCoinValues)
  );
  if (e === 'add') {
    inputs[key].amount = inputs[key].amount + 1;
  } else if (e === 'subtract') {
    inputs[key].amount =
      inputs[key].amount === 0 ? inputs[key].amount : inputs[key].amount - 1;
  } else {
    inputs[key].amount = parseInt(e.currentTarget.value);
  }
  let total = 0;
  for (var i = 0; i < inputs.length; i++) {
    total = total + inputs[i].amount * inputs[i].value;
  }
  for (var j = 0; j < inputs2.length; j++) {
    total = total + inputs2[j].amount * inputs2[j].value;
  }
  setFloat(prevState => ({
    ...prevState,
    float: total,
    floatInputsNoteValues: inputs
  }));
};

const floatInputChangeNoteInputsNumpad = (e, key, amount) => {
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  var inputs = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsNoteValues)
  );
  var inputs2 = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsCoinValues)
  );
  inputs[key].amount = parseInt(e);
  let total = 0;
  for (var i = 0; i < inputs.length; i++) {
    total = total + inputs[i].amount * inputs[i].value;
  }
  for (var j = 0; j < inputs2.length; j++) {
    total = total + inputs2[j].amount * inputs2[j].value;
  }
  setFloat(prevState => ({
    ...prevState,
    float: total,
    floatInputsNoteValues: inputs
  }));
};

const floatInputChangeCoinInputs = (e, key, amount) => {
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  var inputs = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsCoinValues)
  );
  var inputs2 = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsNoteValues)
  );
  if (e === 'add') {
    inputs[key].amount = inputs[key].amount + 1;
  } else if (e === 'subtract') {
    inputs[key].amount =
      inputs[key].amount === 0 ? inputs[key].amount : inputs[key].amount - 1;
  } else {
    inputs[key].amount = parseInt(e.currentTarget.value);
  }
  let total = 0;
  for (var i = 0; i < inputs.length; i++) {
    total = total + inputs[i].amount * inputs[i].value;
  }
  for (var j = 0; j < inputs2.length; j++) {
    total = total + inputs2[j].amount * inputs2[j].value;
  }
  setFloat(prevState => ({
    ...prevState,
    float: total,
    floatInputsCoinValues: inputs
  }));
};

const floatInputChangeCoinInputsNumpad = (e, key, amount) => {
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  var inputs = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsCoinValues)
  );
  var inputs2 = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsNoteValues)
  );
  inputs[key].amount = parseInt(e);
  let total = 0;
  for (var i = 0; i < inputs.length; i++) {
    total = total + inputs[i].amount * inputs[i].value;
  }
  for (var j = 0; j < inputs2.length; j++) {
    total = total + inputs2[j].amount * inputs2[j].value;
  }
  setFloat(prevState => ({
    ...prevState,
    float: total,
    floatInputsCoinValues: inputs
  }));
};

var stateObject = {
  float: 0,
  floatInputsNoteValues: [
    { value: 10, amount: 0 },
    { value: 20, amount: 0 },
    { value: 50, amount: 0 },
    { value: 100, amount: 0 },
    { value: 200, amount: 0 }
  ],
  floatInputsCoinValues: [
    { value: 0.1, amount: 0 },
    { value: 0.2, amount: 0 },
    { value: 0.5, amount: 0 },
    { value: 1, amount: 0 },
    { value: 2, amount: 0 },
    { value: 5, amount: 0 }
  ]
};

const userFloatProps = {
  state: {
    floatInputsNoteValues: stateObject.floatInputsNoteValues,
    floatInputsCoinValues: stateObject.floatInputsCoinValues,
    float: stateObject.float
  },
  floatInputChangeNoteInputs: floatInputChangeNoteInputs,
  floatInputChangeNoteInputsNumpad: floatInputChangeNoteInputsNumpad,
  floatInputChangeCoinInputs: floatInputChangeCoinInputs,
  floatInputChangeCoinInputsNumpad: floatInputChangeCoinInputsNumpad
};

const CashupCount = allprops => {
  [userFloatProps.state, setFloat] = useState(userFloatProps.state);

  props = allprops;

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <div>
      <UserFloat {...userFloatProps} />
    </div>
  );
};

export default CashupCount;
