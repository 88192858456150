import styled from 'styled-components';
import { Table } from 'antd';
const StyledTable = styled(Table)`
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #00cc709d !important;
  background-color:  #00cc709d  !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: white !important;
  background:  #00cc709d  !important;
  background-color:  #00cc709d  !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: white !important;
  background:  #00cc709d  !important;
  background-color:  #00cc709d  !important;
}
.ant-table-thead > tr > th .anticon-filter, .ant-table-thead > tr > th .ant-table-filter-icon {
  background-color: transparent!important;
}
.ant-table-thead > tr > th .anticon-filter > svg, .ant-table-thead > tr > th .ant-table-filter-icon > svg {
  color: white;
}
  .ant-table-thead > th {
  }
  .ant-table-thead > tr > th {
    background: transparent;
    color: white;
    height: 3.4em;
    border-color: transparent;
  }
  thead { 
    border-radius: 0.28em;
    background:#272930;
    border: 1px solid rgb(61, 72, 82) !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
   font-family:Roboto;
   color:black;
    font-weight:500;
    line-height: 1.45;
 }
.ant-table {
  border-radius: 0.27em;
  border: none !important;
  }
  tbody {
    box-shadow: none;
    border: 1px solid whitesmoke!important;
  }
  .ant-pagination-item {
    height: 35px !important;
  }
  .ant-pagination-disabled a,
  .ant-pagination-disabled:hover a,
  .ant-pagination-disabled:focus a,
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: grey;
  }
  .ant-pagination-prev a,
  .ant-pagination-next a {
    /* color: transparent; */
  }
  .ant-pagination-item a {
    height: 35px !important;
    border-radius: 4px;
    width: 33px;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    margin: 1em;
  }
  .ant-pagination.mini .ant-pagination-item {
    line-height: 35px;
  }
  .ant-pagination-item-active a {
    color: grey !important;
    border: 2px solid rgb(229, 233, 242) !important;
    border-radius: 4em;
    display: inline;
    background: rgb(229, 233, 242);
    padding: 0.4em;
    padding-left: 0.7em;
    padding-right: 0.75em;
  }
  .ant-select-selection {
    border-radius: 3em;
    padding-left: 0.5em;
   
    padding-top: 0.25em;
    height: 2.5em;
    text-align: center;

    color: rgb(0, 204, 112);
    border: 1.5px solid rgb(0, 204, 112);
 
}
.ant-select-arrow svg {
  color: rgb(0, 204, 112);

}
  .ant-pagination-item-active {
    border-color: transparent !important;
    color: #ffffff !important;
  }
  .ant-pagination.mini .ant-pagination-options {
    margin-left: 2px;
    position: absolute;
    float: left;
    left: 0;
    margin-top: 1em;
}
  .ant-pagination {
    float: right;
    margin-top: 0px !important;

    list-style: none;
    -webkit-font-feature-settings: 'tnum', 'tnum';
    font-feature-settings: 'tnum', 'tnum';
  }
  .ant-table-placeholder {
    position: relative;
    z-index: 1;
    margin-top: -1px;
    padding: 0;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    text-align: center;
    background: #ffffff;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
  }
`;
export default StyledTable;
