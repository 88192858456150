import React, { memo } from "react";
import Avatar from "@material-ui/core/Avatar";
import { Badge } from "antd";

const getInitials = function(sName) {
  var names = sName.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const UserButtons = memo(
  ({ user, newkey, usertobuttonstate, props, setUser }) => {
    if (props.syncBills[user._id] && !user.role.isKitchen && !user.role.isBar) {
      user.openBills = 0;
      for (var prop in props.syncBills[user._id]) {
        if (props.syncBills[user._id][prop].state === "open") {
          user.openBills++;
        }
      }
    }
    return (
      <div
        key={"UB" + newkey}
        style={
          user.cashup
            ? {
                textAlign: "center",
                wordSpacing: "7px",
                fontWeight: "700",
                borderRadius: "0.8em",
                overflow: "hidden",
                float: "left",
              }
            : {}
        }
      >
        {user.session || user.cashup ? (
          <div
            style={{
              overflow: "hidden",
              float: "left",
              marginLeft: "0em",
              color: "white",
            }}
          >
            <Avatar
              className="avatar-style"
              onClick={() => setUser(user)}
              key={"AV" + newkey}
              style={
                user.cashup
                  ? {
                      // border: "2px solid orange",
                      //   marginLeft: '0em',
                      // marginTop: '0em',
                      height: "2em",
                      width: "2em",
                      backgroundColor: "transparent",
                      color: "white",
                    }
                  : {
                      // border: "2px solid #00CC70",
                      //   marginLeft: '0em',
                      //   marginTop: '0em',
                      height: "2em",
                      width: "2em",
                      backgroundColor: "transparent",
                      color: "white",
                    }
              }
              alt="Logo"
            >
              {usertobuttonstate.state &&
              usertobuttonstate.state.topSale &&
              usertobuttonstate.state.topSale.username === user.username ? (
                <div className="fav">
                  <svg className="fav-star" viewBox="0 0 114 110">
                    <path d="M48.7899002,5.95077319 L39.3051518,35.1460145 L8.60511866,35.1460145 C4.87617094,35.1519931 1.57402643,37.5554646 0.422104463,41.1020351 C-0.7298175,44.6486057 0.529798011,48.5337314 3.54354617,50.7297298 L28.3840111,68.7758317 L18.8992627,97.971073 C17.7496089,101.520283 19.0141379,105.406227 22.0323508,107.599168 C25.0505637,109.792109 29.1370771,109.794067 32.1573906,107.604021 L56.9864557,89.5693186 L81.8269206,107.615421 C84.8472342,109.805467 88.9337475,109.803509 91.9519605,107.610568 C94.9701734,105.417627 96.2347024,101.531683 95.0850486,97.9824729 L85.6003001,68.7986315 L110.440765,50.7525296 C113.466376,48.5582894 114.732852,44.663975 113.576698,41.1097771 C112.420545,37.5555791 109.105303,35.1516627 105.367793,35.1574144 L74.6677595,35.1574144 L65.1830111,5.96217312 C64.0286485,2.41064527 60.7208743,0.00457304502 56.9864557,5.53367114e-06 C53.2527571,-0.00420898295 49.9421526,2.39931752 48.7899002,5.95077319 Z" />
                  </svg>

                  <span className="fav-round" />
                  <span className="fav-sparkle">
                    <span className="fav-sparkle-i" />
                    <span className="fav-sparkle-i" />
                    <span className="fav-sparkle-i" />
                    <span className="fav-sparkle-i" />
                    <span className="fav-sparkle-i" />
                  </span>
                </div>
              ) : null}
              <div
                className="user-initials"
                style={{ 
                  lineHeight: "16px",
                  fontWeight: "700",
                  fontSize: "28px",
                  marginLeft:"0.3em"
                }}
                onClick={() => setUser(user)}
              >
                {getInitials(user.name)}
              </div>
              <span
                style={{
                  marginLeft: "1em",
                  fontWeight: "300",
                  wordSpacing: "1px",
                  fontSize: "12px",
                }}
              >
                {user.name}
                <br />
                <span
                className="user-roll"
                style={{
                  fontWeight: 500,
                  wordSpacing: "1px",
                  fontSize: "11px",
                  color:user.cashup? "orange":"#DCCA26",
                  display:"flex"
                }}
              >
             
                  {user.role
                  ? !user.role.isAdmin && !user.role.isSupAdmin
                    ? !user.role.isKitchen && !user.role.isBar
                      ? user.openBills === 0
                        ? "No Sales"
                        : user.openBills === 1
                        ? user.openBills + " Sale"
                        : user.openBills + " Sales"
                      : user.openBills === 0
                      ? "No Orders"
                      : user.openBills === 1
                      ? user.openBills + " Order"
                      : user.openBills + " Orders"
                    : ""
                  : ""}
              </span>
              </span>

         <div  
          style={{
             textTransform: "none",
             fontSize: "11px",
             fontWeight: "700",
             margin: "auto",
            marginRight: "0em",
    // paddingLeft: "0.5em",
            //  backgroundColor: "user.cashup? "rgb(251, 206, 177, 0.05)":"rgb(0, 204, 112, 0.05)"",
            backgroundColor: "#272930",
             borderLeft:"2px solid rgb(17, 20, 26)",
             height:"100%",
             width:"6em"
           }}>
              <h3
                className="user-bills users-role"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "11px",
                  fontWeight: 700,
                 paddingTop:"2.5em",
                  color: "white",
                  textAlign:"center"
                }}
              >
                 {user.role
                  ? user.role.isClerk
                    ? "Sales"
                    : user.role.isKitchen
                    ? "Kitchen"
                    : user.role.isBar
                    ? "Bar"
                    : user.role.isDriver
                    ? "Driver"
                    : user.role.isAdmin
                    ? "Manager"
                    : user.role.isSupAdmin
                    ? "Admin"
                    : ""
                  : ""}
              </h3>
              </div>
            </Avatar>
            {/* {!user.role.isAdmin && !user.role.isSupAdmin ? (
              user.cashup ? (
                <Badge
                  id="AvatarStatus"
                  status="warning"
                  style={{
                    visibility: "hidden",
                    marginLeft: "1.28em",
                    marginTop: "-0em",
                  }}
                />
              ) : (
                <Badge
                  id="AvatarStatus"
                  status="success"
                  style={{
                    visibility: "hidden",
                    marginLeft: "6em",
                    marginTop: "-2em",
                  }}
                />
              )
            ) : (
              <Badge />
            )} */}
            {!user.role.isAdmin && !user.role.isSupAdmin ? (
              user.cashup ? (
                <h1
                  className="user-text-status"
                  style={{
                    position: "absolute",
                    fontFamily: "Roboto",
                    fontSize: "11px",
                    fontWeight: 700,
                    marginTop: "-4.5em",
                    marginLeft: "16.7em",

                  }}
                >
                  Cashup
                </h1>
              ) : (
                <h1
                  className="user-text-status"
                  style={{
                    position: "absolute",
                    fontFamily: "Roboto",
                    fontSize: "11px",
                    fontWeight: 700,
                    marginLeft: "15px",
                    marginTop: "-4.5em",
                    marginLeft: "16.7em",
                  }}
                >
                  Login
                </h1>
              )
            ) : (
              <Badge />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
);

const UserDisplayClosed = memo(
  ({ user, newkey, usertobuttonstate, props, setUser }) => {
    return (
      <div key={"UDC" + newkey} style={{marginTop:"0.5em"}}>
        <div
          style={{
            overflow: "hidden",
            float: "left",
            marginLeft: "0em",
            color: "white",
            display:"flex"
          }}
        >
          <Avatar
            className="avatar-style"
            onClick={() => setUser(user)}
            key={"UDAV" + newkey}
            style={{
              // border:
              //   !user.role.isAdmin && !user.role.isSupAdmin
              //     ? "2px solid #DCCA26"
              //     : "2px solid #0390BC",
                  height: "2em",
                  width: "2em",
                  backgroundColor: "transparent",
                  color: "white",
            }}
            alt="Logo"
          >
            {usertobuttonstate.state &&
            usertobuttonstate.state.topSale &&
            usertobuttonstate.state.topSale.username === user.username ? (
              <div className="fav">
                <svg className="fav-star" viewBox="0 0 114 110">
                  <path d="M48.7899002,5.95077319 L39.3051518,35.1460145 L8.60511866,35.1460145 C4.87617094,35.1519931 1.57402643,37.5554646 0.422104463,41.1020351 C-0.7298175,44.6486057 0.529798011,48.5337314 3.54354617,50.7297298 L28.3840111,68.7758317 L18.8992627,97.971073 C17.7496089,101.520283 19.0141379,105.406227 22.0323508,107.599168 C25.0505637,109.792109 29.1370771,109.794067 32.1573906,107.604021 L56.9864557,89.5693186 L81.8269206,107.615421 C84.8472342,109.805467 88.9337475,109.803509 91.9519605,107.610568 C94.9701734,105.417627 96.2347024,101.531683 95.0850486,97.9824729 L85.6003001,68.7986315 L110.440765,50.7525296 C113.466376,48.5582894 114.732852,44.663975 113.576698,41.1097771 C112.420545,37.5555791 109.105303,35.1516627 105.367793,35.1574144 L74.6677595,35.1574144 L65.1830111,5.96217312 C64.0286485,2.41064527 60.7208743,0.00457304502 56.9864557,5.53367114e-06 C53.2527571,-0.00420898295 49.9421526,2.39931752 48.7899002,5.95077319 Z" />
                </svg>

                <span className="fav-round" />
                <span className="fav-sparkle">
                  <span className="fav-sparkle-i" />
                  <span className="fav-sparkle-i" />
                  <span className="fav-sparkle-i" />
                  <span className="fav-sparkle-i" />
                  <span className="fav-sparkle-i" />
                </span>
              </div>
            ) : null}
         <div
                className="user-initials"
                style={{ 
                  lineHeight: "16px",
                  fontWeight: "700",
                  fontSize: "28px",
                  marginLeft:"0.3em"
                }}
                onClick={() => setUser(user)}
              >
                {getInitials(user.name)}
              </div>
              <span
                style={{
                  marginLeft: "1em",
                  fontWeight: "300",
                  wordSpacing: "1px",
                  fontSize: "12px",
                }}
              >
                {user.name}
                <br />
                <span
                className="user-roll"
                style={{
                  fontWeight: 500,
                  wordSpacing: "1px",
                  fontSize: "11px",
                  color:  !user.role.isAdmin && !user.role.isSupAdmin
                  ? "#00CC70"
                  : "#0390BC",
                }}
              >
              {user.role
                ? user.role.isClerk
                  ? "Sales"
                  : user.role.isKitchen
                  ? "Kitchen"
                  : user.role.isBar
                  ? "Bar"
                  : user.role.isDriver
                  ? "Driver"
                  : user.role.isAdmin
                  ? "Manager"
                  : user.role.isSupAdmin
                  ? "Admin"
                  : ""
                : ""}
            </span>
              </span>
              <div 
          style={{
             textTransform: "none",
             fontSize: "11px",
             fontWeight: "700",
             margin: "auto",
            marginRight: "0em",
    // paddingLeft: "0.5em",
            //  backgroundColor: !user.role.isAdmin && !user.role.isSupAdmin
            //  ? "rgb(0, 204, 112, 0.05)"
            //  : "#0390BC",
            backgroundColor: "#272930",
             borderLeft:  "2px solid rgb(17, 20, 26)",
             height:"100%",
             width:"6em"
           }}>
              <h3
                className={user.role.isSupAdmin ||  user.role.isAdmin ?"user-bills":"user-bills users-role"}
                style={{
                  fontFamily: "Roboto",
                  fontSize: "11px",
                  fontWeight: 700,
                 paddingTop:"2.5em",
                  color: "white",
                  textAlign:"center"
                }}
              >
                 {user.role
                  ? user.role.isClerk
                    ? "Sales"
                    : user.role.isKitchen
                    ? "Kitchen"
                    : user.role.isBar
                    ? "Bar"
                    : user.role.isDriver
                    ? "Driver"
                    : user.role.isAdmin
                    ? "Manager"
                    : user.role.isSupAdmin
                    ? "Admin"
                    : ""
                  : ""}
              </h3>
              </div>
            </Avatar>
          {/* {!user.role.isAdmin && !user.role.isSupAdmin ? (
            user.cashup ? (
              <Badge
                id="AvatarStatus"
                status="warning"
                style={{
                  visibility: "hidden",
                  marginLeft: "1.95em",
                  marginTop: "-0em",
                }}
              />
            ) : (
              <Badge
                id="AvatarStatus"
                status="error"
                style={{
                  visibility: "hidden",
                  marginLeft: "8.1em",
                  marginTop: "-2em",
                }}
              />
            )
          ) : (
            <Badge />
          )}{" "} */}
          {!user.role.isAdmin && !user.role.isSupAdmin ? (
            user.cashup ? (
              ""
            ) : (
              <h1
              className="user-text-status"
              style={{
                position: "absolute",
                    fontFamily: "Roboto",
                    fontSize: "11px",
                    fontWeight: 700,
                    marginTop: "2.9em",
                    marginLeft: "16.7em",
                }}
              >
                Ready
              </h1>
            )
          ) : (
            <Badge />
          )}
        </div>
      </div>
    );
  }
);

export { UserButtons, UserDisplayClosed };
