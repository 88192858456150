export const initialState = {
    loading: false,
    percent: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'INIT_LOADER': {
            if (action.percent) {
                return {
                    ...state,
                    loading: true,
                    percent: action.percent
                };
            }
        }
        case 'REMOVE_LOADER': {
            return {
                ...state,
                loading: false,
                percent: 0
            };
        }
        default:
            return state
    }
}