import { Col, Drawer, Icon, Input, Layout, Menu, Row } from 'antd';
import React, { useState } from 'react';
import {
  AiOutlineArrowLeft,
  AiOutlineFileText,
  AiOutlineHome,
  AiOutlineLogout,
  AiOutlineUser
} from 'react-icons/ai';
import { MdLocationOn } from 'react-icons/md';
import LogoGrey from 'src/static/vpos-logo-grey.png';
import WaveBot from 'src/static/wave-bot.png';
import WaveMid from 'src/static/wave-mid.png';
import WaveTop from 'src/static/wave-top.png';
import styled from 'styled-components';

const { Search } = Input;
const { Header } = Layout;
let searchVisible = false;
let setSearchVisible;
let drawerVisible = false;
let setDrawerVisible;
const StyledInput = styled(Input)`
  .ant-input {
    padding-bottom: 7px;
  }
`;
const CustomerHeader = props => {
  [searchVisible, setSearchVisible] = useState(searchVisible);
  [drawerVisible, setDrawerVisible] = useState(drawerVisible);
  if (props.currentView !== 'companyList') {
    if (searchVisible) {
      setSearchVisible(false);
    }
  }
  return (
    <Header
      style={{
        height: 50,
        width: '100%',
        position: 'fixed',
        background: 'white',
        lineHeight: '50px',
        padding: '0px 5px',
        zIndex: 9,

        color: '#00CC70',
        boxShadow: '0px 3px 10px -4px lightgrey'
      }}
    >
      {!props.search ? (
        <Row style={{}} type="flex" justify="space-between">
          <Col style={{}} span={18}>
            {props.currentView === 'menuItems' ? (
              <div
                style={{
                  width: '82vw',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <AiOutlineArrowLeft
                  onClick={e => props.navToView('companyMenu')}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  style={{
                    fontSize: '14px',
                    color: 'black',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                  onClick={e => props.navToView('companyMenu')}
                >
                  {props.selectedCompany.name} - Menu
                </span>
              </div>
            ) : props.currentView === 'itemInstructions' ? (
              <div>
                <AiOutlineArrowLeft
                  onClick={e => props.navToView('menuItems')}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  onClick={e => props.navToView('menuItems')}
                  style={{
                    fontSize: '15px',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                >
                  Build your item
                </span>
              </div>
            ) : props.currentView === 'cart' ? (
              <div>
                <AiOutlineArrowLeft
                  onClick={e => props.navToView('companyMenu')}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  onClick={e => props.navToView('companyMenu')}
                  style={{
                    fontSize: '15px',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                >
                  Your Cart
                </span>
              </div>
            ) : props.currentView === 'checkout' ? (
              <div>
                <AiOutlineArrowLeft
                  onClick={e => props.navToView('cart')}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  onClick={e => props.navToView('cart')}
                  style={{
                    fontSize: '15px',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                >
                  Confirm Order
                </span>
              </div>
            ) : props.currentView === 'myOrders' ? (
              <div>
                <AiOutlineArrowLeft
                  onClick={e => props.navToView(props.previousView)}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  onClick={e => props.navToView(props.previousView)}
                  style={{
                    fontSize: '15px',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                >
                  My Orders
                </span>
              </div>
            ) : props.currentView === 'orderDetails' ? (
              <div>
                <AiOutlineArrowLeft
                  onClick={e => props.navToView(props.previousView)}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  onClick={e => props.navToView(props.previousView)}
                  style={{
                    fontSize: '15px',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                >
                  Order Details
                </span>
              </div>
            ) : props.currentView === 'track' ? (
              <div>
                <AiOutlineArrowLeft
                  onClick={e => props.navToView('status')}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  onClick={e => props.navToView('status')}
                  style={{
                    fontSize: '15px',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                >
                  Delivery Status
                </span>
              </div>
            ) : props.currentView === 'status' ? (
              <div>
                <AiOutlineArrowLeft
                  onClick={e => props.navToView('myOrders')}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  onClick={e => props.navToView('myOrders')}
                  style={{
                    fontSize: '15px',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                >
                  Order Status
                </span>
              </div>
            ) : props.currentView === 'profile' ? (
              <div>
                <AiOutlineArrowLeft
                  onClick={e => props.navToView(props.previousView)}
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
                <span
                  onClick={e => props.navToView(props.previousView)}
                  style={{
                    fontSize: '15px',
                    marginLeft: 9,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                >
                  My Profile
                </span>
              </div>
            ) : (
              <div>
                <MdLocationOn
                  style={{ marginBottom: '-4px', fontSize: '20px' }}
                  onClick={props.backToLocation}
                />
                <span
                  style={{
                    fontSize: '14px',
                    color: 'black',
                    marginLeft: 3,
                    fontWeight: 700,
                    color: '#383838',
                    fontFamily: 'Nunito'
                  }}
                  onClick={props.backToLocation}
                >
                  {props.address !== 'Collect'
                    ? props.address.split(', ')[0] +
                      ', ' +
                      props.address.split(', ')[1]
                    : props.address}
                </span>
              </div>
            )}
          </Col>

          <Col style={{ fontSize: 26 }} span={6}>
            <Icon
              style={{
                float: 'right',
                marginTop: 14,
                marginRight: 10,
                fontSize: '0.9em'
              }}
              onClick={e => setDrawerVisible(!drawerVisible)}
              type="menu"
            />{' '}
            <Drawer
              id="CustomerMenuDrawer"
              placement={'left'}
              closable={true}
              visible={drawerVisible}
              onClose={e => setDrawerVisible(!drawerVisible)}
            >
              {' '}
              <img
                src={LogoGrey}
                alt="Logo"
                style={{
                  width: '60%',
                  minWidth: '5em',
                  marginTop: '3em',
                  marginLeft: '2.5em',
                  marginRight: 'auto',
                  display: 'block',

                  zIndex: 9
                }}
              />
              <Menu
                onClick={e => {
                  props.navToView(e.key);
                  setDrawerVisible(!drawerVisible);
                }}
                style={{
                  width: 256,
                  paddingLeft: 0,
                  paddingTop: 40,
                  backgroundColor: 'transparent'
                }}
                mode="inline"
              >
                <Menu.Item
                  style={{
                    fontSize: '22px',
                    color: 'white',
                    zIndex: 99,
                    backgroundColor: 'transparent'
                  }}
                  key="companyList"
                >
                  {' '}
                  <AiOutlineHome
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 23,
                      marginBottom: '-0.09em',
                      marginRight: '0.5em'
                    }}
                  />
                  Home
                </Menu.Item>
                <Menu.Item
                  style={{
                    fontSize: '22px',
                    color: 'white',
                    zIndex: 99,
                    backgroundColor: 'transparent'
                  }}
                  key="myOrders"
                >
                  <AiOutlineFileText
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 23,
                      marginBottom: '-0.09em',
                      marginRight: '0.3em'
                    }}
                  />{' '}
                  My Orders
                </Menu.Item>
                <Menu.Item
                  style={{
                    fontSize: '22px',
                    color: 'white',
                    zIndex: 99,
                    backgroundColor: 'transparent'
                  }}
                  key="profile"
                >
                  <AiOutlineUser
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 23,
                      marginBottom: '-0.09em',
                      marginRight: '0.3em'
                    }}
                  />{' '}
                  Profile
                </Menu.Item>
                <Menu.Item
                  style={{
                    fontSize: '22px',
                    color: 'white',
                    zIndex: 99,
                    backgroundColor: 'transparent'
                  }}
                  key="signOut"
                >
                  {' '}
                  <AiOutlineLogout
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 23,
                      marginBottom: '-0.09em',
                      marginRight: '0.3em'
                    }}
                  />{' '}
                  Sign Out
                </Menu.Item>
              </Menu>{' '}
              <div className="waveWrapper waveAnimation">
                <div className="waveWrapperInner bgTop">
                  <div
                    className="wave waveTop"
                    style={{
                      backgroundImage: 'url(' + WaveTop + ')'
                    }}
                  />
                </div>
                <div className="waveWrapperInner bgMiddle">
                  <div
                    className="wave waveMiddle"
                    style={{
                      backgroundImage: 'url(' + WaveMid + ')'
                    }}
                  />
                </div>
                <div className="waveWrapperInner bgBottom">
                  <div
                    className="wave waveBottom"
                    style={{
                      backgroundImage: 'url(' + WaveBot + ')'
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 10,
                  fontSize: 10
                }}
              >
                {process.env.REACT_APP_VERSION_CONTROL}
              </div>
            </Drawer>
          </Col>
        </Row>
      ) : (
        <StyledInput
          autoFocus
          style={{
            left: 0,
            top: 0,
            padding: 0,
            width: '100%',
            height: 50,
            fontSize: 15,
            position: 'fixed'
          }}
          suffix={
            <Icon
              type="close"
              onClick={e => {
                props.searchPlace({
                  value: undefined,
                  search: false
                });
                props.setSearch(false);
              }}
            />
          }
          placeholder="Search place / category"
          onChange={e =>
            props.searchPlace({
              value: e.currentTarget.value,
              search: true
            })
          }
        />
      )}
    </Header>
  );
};
export default CustomerHeader;
