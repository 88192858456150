import {
  Button,
  Card,
  ConfigProvider,
  Empty,
  Icon,
  Input,
  List,
  Menu,
  Row,
  Select,
  Skeleton
} from 'antd';
import React, { memo } from 'react';
import circleTeal from 'src/static/circle-teal.png';
import expand from 'src/static/expand.png';
import styled, { css } from 'styled-components';

import './Bills.css';
const ButtonGroup = Button.Group;
const StyledList = styled(List)`
  ${props => css`
    height: calc(100vh - (${props.pixels}) - 35px);
    @media (max-height: 550px) {
      height: calc(100vh - (${props.pixels}));
    }
    @media (max-height: 550px) {
      height: calc(100vh - (${props.pixels}));
    }
  `}
`;
const StyledSelect = styled(Select)`
  color: #00cc70 !important;
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    color: #00cc70 !important;
  }
  .ant-select-selection {
    background-color: transparent;
    color: #00cc70 !important;
    border: none;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 3em;
  background-color: transparent;
  color: white;
`;
const PhoneStyledList = styled(List)`
  p {
    color: black;
  }
  .ant-list-item-meta-description {
    color: black;
  }
`;

const dimSelectTypesFormat = {
  l: 'L',
  kg: 'Kg',
  unit: 'Each'
};

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <Empty
      imageStyle={{
        margin: '3em',
        marginBottom: '0.5em',
        height: 90
      }}
    />
    {/* <button
      style={{
        marginTop: "1em",
        backgroundColor: '#00CC70',
        border: '0px solid grey',
        color: 'white',
        width: '9em',
        height: '2.5em',
        fontSize: "16px",
        fontFamily:
          'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif',

        fontWeight: 600,
        borderRadius: '3em',
        zIndex: 1,

        position: 'relative'
      }}

      color="primary"
    >
      {'Add Item'}
    </button> */}
  </div>
);
const customizeRenderEmptyMobile = () => (
  <div style={{ textAlign: 'center' }}>
    <Empty
      imageStyle={{
        margin: '3em',
        marginBottom: '0.5em',
        height: 90
      }}
    />
  </div>
);
const BillsMenu = ({ state, props }) => {
  return (
    <div
      style={{
        transition: '0.3s',
        //backgroundColor: 'white',
        transitionProperty: 'all',
        height: 'calc(100vh - 50px)',
        border: ' 0px solid lightgrey',
        borderRadius: '15',
        marginRight: '0.5em',
        marginLeft: '0.5em'
      }}
    >
      {' '}
      <h3
        onClick={props.cardMenuSwitch}
        style={{
          zIndex: 9,
          lineHeight: '2',
          fontWeight: '700',
          padding: '0px',
          marginBottom: 0,
          textAlign: 'center',
          fontSize: '18px',
          color: 'white',
          backgroundColor: 'rgba(207, 229, 255, 0.282)',
          borderRadius: '15px 15px 0px 0px',
          borderBottom: 'solid 1px white'
        }}
      >
        <img
          onClick={props.cardMenuSwitch}
          style={{
            marginTop: '-0.2em',

            textAlign: 'center',
            width: '1.5em'
          }}
          src={expand}
        />
      </h3>{' '}
    </div>
  );
};

const PhoneBillsMenu = ({ state, props, receiptView }) => {
  return (
    <div style={{}}>
      {' '}
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <Menu
        className="collapsed-bill"
        onClick={props.getBillIdMenu}
        style={{
          width: '100%',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 100px)',
          paddingBottom: 50,
          backgroundColor: '#132440'
        }}
      >
        {' '}
        {state.bills &&
          Object.keys(state.bills).map((billid, key) => (
            <Menu.Item
              key={key}
              style={
                key === state.selectedMenuKey
                  ? {
                      width: '96%',
                      margin: 'auto',
                      opacity: 1,
                      background: '#00CC70',
                      color: 'white',
                      lineHeight: 1,
                      height: 55,
                      borderRadius: 3,
                      padding: 5,
                      marginBottom: 10
                    }
                  : {
                      width: '96%',
                      margin: 'auto',
                      backgroundColor: 'rgba(0, 204, 112, 0.5)',
                      color: 'white',
                      lineHeight: 1,
                      height: 55,
                      borderRadius: 3,
                      padding: 5,
                      marginBottom: 10
                    }
              }
              onClick={e => props.cardMenuSwitch(true)}
            >
              <Row type="flex" justify="space-between">
                <span
                  style={
                    key === state.selectedMenuKey
                      ? {
                          fontWeight: '600',
                          fontSize: 13,
                          color: 'white'
                        }
                      : {
                          fontWeight: '600',
                          fontSize: 13
                        }
                  }
                >
                  {state.bills[billid].orderno
                    ? 'Order #' +
                      state.bills[billid].orderno.split('-')[0] +
                      '-' +
                      state.bills[billid].orderno.split('-')[2]
                    : 'N/A'}
                </span>
                <span
                  style={
                    key === state.selectedMenuKey
                      ? {
                          fontWeight: '600',
                          fontSize: 13,
                          color: 'white'
                        }
                      : {
                          fontWeight: '600',
                          fontSize: 13
                        }
                  }
                >
                  {state.bills[billid].table
                    ? 'Table #' + state.bills[billid].table
                    : ''}
                </span>
                <span
                  style={
                    key === state.selectedMenuKey
                      ? {
                          fontWeight: '600',
                          fontSize: 13,
                          color: 'white'
                        }
                      : {
                          fontWeight: '600',
                          fontSize: 13
                        }
                  }
                >
                  {state.bills[billid].date
                    ? state.bills[billid].date.slice(0, 4) +
                      '-' +
                      state.bills[billid].date.slice(4, 6) +
                      '-' +
                      state.bills[billid].date.slice(6, 8) +
                      ' ' +
                      state.bills[billid].date.slice(8, 10) +
                      ':' +
                      state.bills[billid].date.slice(10, 12)
                    : 'N/A'}
                </span>
              </Row>
              <Row type="flex" justify="space-between">
                <span
                  style={
                    key === state.selectedMenuKey
                      ? {
                          marginTop: 15,
                          fontWeight: '600',
                          fontSize: 13,
                          color: 'white'
                        }
                      : {
                          marginTop: 15,
                          fontWeight: '600',
                          fontSize: 13
                        }
                  }
                >
                  {state.bills[billid].type}
                </span>
                <span
                  style={
                    key === state.selectedMenuKey
                      ? {
                          marginTop: 15,
                          fontWeight: '600',
                          fontSize: 13,
                          color: 'white'
                        }
                      : {
                          marginTop: 15,
                          fontWeight: '600',
                          fontSize: 13
                        }
                  }
                >
                  {state.bills[billid].desc}
                </span>
                <span
                  key={key}
                  style={
                    key === state.selectedMenuKey
                      ? {
                          marginTop: 15,
                          fontWeight: '600',
                          fontSize: 15,
                          color: 'white'
                        }
                      : {
                          marginTop: 15,
                          fontWeight: '600',
                          fontSize: 15
                        }
                  }
                >
                  R {state.bills[billid].total.toFixed(2)}
                </span>
              </Row>
            </Menu.Item>
          ))}
      </Menu>
    </div>
  );
};

const Bills = ({
  billid,
  newindex,
  state,
  props,
  themeMode,
  receiptView,
  user
}) => {
  var deleteReady = false;
  var count = 0;
  for (var j = 0; j < state.bills[billid].items.length; j++) {
    if (state.bills[billid].items[j].qty === 0) {
      count = count + 1;
    }
  }
  if (count === state.bills[billid].items.length) {
    deleteReady = true;
  }
  return (
    <div className={'sales-bill-div-' + themeMode} key={billid}>
      <Card
        bodyStyle={{
          padding: 0,
          paddingTop: '0.8em',
          width: '95%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        style={{
          backgroundColor: 'transparent',
          border: 0,
          padding: '0px',
          fontSize: 25,
          fontWeight: 400,
          borderRadius: '16px'
        }}
        title={
          <div
            style={{
              height: '40px'
            }}
          >
            <div
              className="bill-number-display"
              style={{
                position: 'absolute',
                right: '0%',
                top: 6,
                width: '100%'
              }}
            >
              <span className={'sales-bill-number-' + themeMode}>
                Bill #
                {state.bills[billid].orderno
                  ? state.bills[billid].orderno.split('-')[0] +
                    '-' +
                    state.bills[billid].orderno.split('-')[2]
                  : 'N/A'}
              </span>
            </div>
          </div>
        }
        key={billid}
      >
        <div className={'sales-bill-header-' + themeMode}>
          <Row type="flex" justify="space-between">
            <span style={{ fontSize: 15, marginTop: 2 }}>
              {state.bills[billid].table
                ? 'Table #' + state.bills[billid].table
                : state.bills[billid].type}
            </span>
            <span style={{ fontSize: 15, marginTop: 2 }}>
              {state.bills[billid].desc}
            </span>
            <span style={{ fontSize: 15, marginTop: 2 }}>
              {state.bills[billid].date
                ? state.bills[billid].date.slice(0, 4) +
                  '-' +
                  state.bills[billid].date.slice(4, 6) +
                  '-' +
                  state.bills[billid].date.slice(6, 8) +
                  ' ' +
                  state.bills[billid].date.slice(8, 10) +
                  ':' +
                  state.bills[billid].date.slice(10, 12)
                : 'N/A'}
            </span>
          </Row>
        </div>

        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <StyledList
            pixels={
              state.bills[billid].discount > 0 &&
              state.bills[billid].amountLeft < state.bills[billid].total
                ? '283px'
                : state.bills[billid].discount > 0 ||
                  state.bills[billid].amountLeft < state.bills[billid].total
                ? '263px'
                : '238px'
            }
            style={{
              background: 'rgb(255, 255, 255)',

              border: 'solid 0px rgba(211, 211, 211, 0.14)',
              borderRadius: '10px',
              overflow: 'auto',
              marginTop: 0
            }}
            className="bill-item-list"
            itemLayout="horizontal"
            dataSource={state.bills[billid].items}
            renderItem={(item, iKey) =>
              item.qty > 0 ? (
                <List.Item>
                  <Skeleton title={'Items'} loading={false} active>
                    <List.Item.Meta
                      title={
                        <p>
                          {item.name + ' @ R' + item.price}{' '}
                          {item.useDim && item.useDimType
                            ? 'Per (' +
                              (dimSelectTypesFormat[item.useDimType]
                                ? dimSelectTypesFormat[item.useDimType]
                                : item.useDimType) +
                              ')'
                            : ''}
                        </p>
                      }
                      description={
                        item.price ? (
                          item.desc ? (
                            <div onClick={() => props.showCooking(item, iKey)}>
                              R {(item.price * item.qty).toFixed(2) + '  '}
                              <div
                                style={{
                                  color: 'rgb(0, 204, 112)'
                                }}
                              >
                                {item.desc}
                              </div>
                              {item.show ? (
                                <div>
                                  <List
                                    size="small"
                                    dataSource={
                                      item.cookIns && item.cookIns.length
                                        ? item.cookIns
                                        : [' ']
                                    }
                                    renderItem={item => (
                                      <List.Item
                                        style={{
                                          padding: 0,
                                          border: 0
                                        }}
                                      >
                                        {item.name ? (
                                          <div>
                                            {item.name} @ R{' '}
                                            {item.price
                                              ? item.price.toFixed(2)
                                              : '0.00'}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </List.Item>
                                    )}
                                    style={{
                                      display: 'block',
                                      fontSize: '11px'
                                    }}
                                  />
                                  <List
                                    size="small"
                                    dataSource={item.openIns || [' ']}
                                    renderItem={item => (
                                      <List.Item
                                        style={{
                                          padding: 0,
                                          border: 0
                                        }}
                                      >
                                        {item != ' ' ? item + ' @ R 0.00' : ''}
                                      </List.Item>
                                    )}
                                    style={{
                                      display: 'block',
                                      fontSize: '11px'
                                    }}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            'R ' + (item.price * item.qty).toFixed(2)
                          )
                        ) : (
                          ''
                        )
                      }
                    />
                    {item.state === 'payed' ? (
                      <div>Paid</div>
                    ) : (
                      <div>
                        <ButtonGroup className="bill-items-btn-group">
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              color: 'red',
                              border: '1px solid red',
                              borderRadius: '120px',
                              height: '27px',
                              width: '27px',
                              transform: 'scale(0.8)',
                              padding: '3px'
                            }}
                            onClick={() => props.decline(item, iKey)}
                          >
                            <Icon
                              type="minus"
                              style={{
                                fontSize: '17px',
                                paddingTop: '1px'
                              }}
                            />
                          </button>
                          <div
                            style={{
                              display: 'inline-block',
                              backgroundColor: 'transparent',
                              //color: 'white',
                              borderRadius: '0px',
                              boxShadow: 'none',
                              top: '-4px',
                              width: '35px',
                              fontSize: '1.1em',
                              margin: '10px',
                              fontWeight: '600',
                              textAlign: 'center'
                            }}
                          >
                            {' '}
                            {item.qty}
                            {item.useDim && item.useDimType
                              ? dimSelectTypesFormat[item.useDimType]
                                ? dimSelectTypesFormat[item.useDimType]
                                : item.useDimType
                              : ''}
                          </div>
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              color: 'rgb(0, 204, 112)',
                              border: '1px solid rgb(0, 204, 112)',
                              transform: 'scale(0.8)',
                              borderRadius: '200px',
                              height: '27px',
                              width: '27px',

                              padding: '3px'
                            }}
                            onClick={() => props.increase(item, iKey)}
                          >
                            <Icon
                              type="plus"
                              style={{
                                paddingTop: '1px',
                                fontSize: '17px'
                              }}
                            />
                          </button>
                        </ButtonGroup>
                      </div>
                    )}
                  </Skeleton>
                </List.Item>
              ) : (
                <div />
              )
            }
          />
        </ConfigProvider>
      </Card>
      <Row style={{ width: '95%', margin: 'auto' }}>
        <div
          style={{
            paddingTop: '0%',
            paddingLeft: '0px',
            paddingRight: '0px',
            fontWeight: 600
          }}
        >
          <p
            className={'sales-bill-totals-' + themeMode}
            style={{ float: 'right' }}
          >
            R {state.bills[billid].total.toFixed('2')}
          </p>
          <p className={'sales-bill-totals-' + themeMode}>Total</p>
        </div>
      </Row>
      {!receiptView ? (
        <div style={{ textAlign: 'center' }}>
          <Button
            className="bill-pay-btn"
            style={{
              backgroundColor: '#00CC70',
              width: '100%',
              borderRadius: '0px 0px 15px 15px',
              // boxShadow:"rgb(229, 233, 242) 2px 2px 15px",
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            onClick={() => props.payBill(state.bills[billid], billid, newindex)}
          >
            {state.payBtn}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const PhoneBills = ({
  billid,
  state,
  props,
  setCurrentView,
  receiptView,
  printSlip,
  user
}) => {
  var deleteReady = false;
  var count = 0;
  for (var j = 0; j < state.bills[billid].items.length; j++) {
    if (state.bills[billid].items[j].qty === 0) {
      count = count + 1;
    }
  }
  if (count === state.bills[billid].items.length) {
    deleteReady = true;
  }
  return (
    <div
      style={
        {
          // background: 'rgba(207, 229, 255, 0.282)'
        }
      }
      className="bill-tab"
      key={billid}
    >
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div>
        <Row
          style={{
            background: 'rgba(207, 229, 255, 0.282)',
            height: 38,
            padding: 4
          }}
          type="flex"
          justify="space-between"
        >
          <span
            style={{
              fontWeight: '600',
              fontSize: 13,
              color: 'white',
              margin: 'auto 0'
            }}
          >
            {state.bills[billid].type}
          </span>
          <span
            style={{
              fontWeight: '600',
              fontSize: 13,
              color: 'white',
              margin: 'auto 0'
            }}
          >
            {state.bills[billid].desc}
          </span>
          <span
            style={{
              fontWeight: '600',
              fontSize: 13,
              color: 'white',
              margin: 'auto 0'
            }}
          >
            {state.bills[billid].table
              ? 'Table #' + state.bills[billid].table
              : ''}
          </span>
          <span
            style={{
              color: 'white',
              fontSize: 13,
              marginTop: 2,
              margin: 'auto 0'
            }}
          >
            {state.bills[billid].date
              ? state.bills[billid].date.slice(0, 4) +
                '-' +
                state.bills[billid].date.slice(4, 6) +
                '-' +
                state.bills[billid].date.slice(6, 8) +
                ' ' +
                state.bills[billid].date.slice(8, 10) +
                ':' +
                state.bills[billid].date.slice(10, 12)
              : 'N/A'}
          </span>
        </Row>
      </div>
      {state.selectedVoidItem ? (
        <PhoneStyledList
          style={{
            textAlign: 'center',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            marginTop: 5,
            padding: 10
          }}
          className="bill-item-list"
          itemLayout="horizontal"
        >
          <List.Item>
            <List.Item.Meta
              title={
                <p
                  style={{
                    color: 'white'
                  }}
                >
                  {state.selectedVoidItem.name} @ R{' '}
                  {state.selectedVoidItem.price}
                </p>
              }
              description={
                <ButtonGroup className="bill-items-btn-group-void">
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      color: 'red',
                      border: '1px solid red',
                      borderRadius: '120px',
                      height: '27px',
                      width: '27px',
                      transform: 'scale(0.8)',
                      padding: '3px'
                    }}
                    onClick={() => props.declineVoid(state.selectedVoidItem)}
                  >
                    <Icon
                      type="minus"
                      style={{
                        fontSize: '17px',
                        paddingTop: '1px'
                      }}
                    />
                  </button>
                  <div
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'transparent',
                      color: 'white',
                      borderRadius: '0px',
                      boxShadow: 'none',
                      top: '-4px',
                      width: '28px',
                      fontSize: '1.1em',
                      margin: '10px',
                      fontWeight: '600',
                      textAlign: 'center'
                    }}
                  >
                    {' '}
                    {state.selectedVoidItem.voidQty}
                  </div>
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      color: 'rgb(0, 204, 112)',
                      border: '1px solid rgb(0, 204, 112)',
                      transform: 'scale(0.8)',
                      borderRadius: '200px',
                      height: '27px',
                      width: '27px',

                      padding: '3px'
                    }}
                    onClick={() => props.increaseVoid(state.selectedVoidItem)}
                  >
                    <Icon
                      type="plus"
                      style={{
                        paddingTop: '1px',
                        fontSize: '17px'
                      }}
                    />
                  </button>
                </ButtonGroup>
              }
            />
          </List.Item>
          <Button
            style={{
              fontSize: '20px',
              color: '#00CC70',
              boxShadow: 'none',
              width: '6.5em',
              border: '1px solid #00CC70',
              borderRadius: '3em',
              backgroundColor: 'transparent',
              border: '1px solid #00CC70',
              marginBottom: '0.5em'
            }}
            className={
              state.selectedVoidItem.waste === true
                ? 'active-green pay-method-button'
                : 'pay-method-button'
            }
            onClick={() => props.selectVoidType(true)}
          >
            Made
          </Button>
          <Button
            style={{
              fontSize: '20px',
              color: '#00CC70',
              boxShadow: 'none',
              width: '6.5em',
              backgroundColor: 'transparent',
              border: '1px solid #00CC70',
              borderRadius: '3em',
              marginBottom: '0.5em'
            }}
            className={
              state.selectedVoidItem.waste === false
                ? 'active-green pay-method-button'
                : 'pay-method-button'
            }
            onClick={() => props.selectVoidType(false)}
          >
            Un-Made
          </Button>
          <Row type="flex" justify="space-around">
            <StyledSelect
              value={state.selectedVoidReason}
              onChange={e => props.selectVoidReason(e)}
              style={{
                width: '73%',
                marginBottom: '1em',
                marginLeft: '-0em',
                marginTop: '1em'
              }}
              placeholder="Select void reason"
            >
              {state.voidReasons.map((reason, index) => {
                return (
                  <Select.Option key={reason.name} value={reason.name}>
                    {reason.name}
                  </Select.Option>
                );
              })}
            </StyledSelect>
          </Row>
          {user.voidPin ? (
            <Row type="flex" justify="space-around">
              <StyledInput
                maxLength={4}
                autoFocus
                style={{
                  display: 'inline-block',
                  width: '50%',
                  padding: '20px',
                  margin: '10px 0 10px',
                  border: '1px solid #00CC70'
                }}
                autocomplete="new-password"
                type="password"
                onChange={e => props.setVoidPin(e.target.value)}
                placeholder="Void PIN"
                value={state.voidPinNumber}
              />
            </Row>
          ) : null}
          <Button
            className="sales-bill-void-cancel-dark"
            style={{
              marginTop: '1em',

              fontSize: '14px',
              color: 'white',
              border: '1px solid white',
              borderRadius: 20,
              backgroundColor: 'transparent',
              width: '6.5em'
            }}
            onClick={e => props.handleSelectItem()}
          >
            Cancel
          </Button>
          <Button
            className="sales-bill-void-button-dark"
            onClick={e => props.voidSelectedVoidItem()}
          >
            Void
          </Button>
        </PhoneStyledList>
      ) : (
        <ConfigProvider renderEmpty={customizeRenderEmptyMobile}>
          <PhoneStyledList
            style={{
              // height: "calc(100vh - 140px)",
              overflowY: 'scroll',
              //  borderTopLeftRadius: 0,
              //  borderTopRightRadius: 0,
              borderRadius: '5px',
              margin: '0.4em',
              // marginTop: 0,
              height: '100vh',
              paddingBottom: 200
            }}
            className="bill-item-list"
            itemLayout="horizontal"
            dataSource={state.bills[billid].items}
            renderItem={(item, iKey) =>
              item.qty > 0 ? (
                <List.Item style={{ padding: 5, backgroundColor: 'white' }}>
                  <Skeleton title={'Items'} loading={false} active>
                    <List.Item.Meta
                      title={<p>{item.name + ' @ R' + item.price}</p>}
                      description={
                        item.price ? (
                          item.desc ? (
                            <div onClick={() => props.showCooking(item, iKey)}>
                              R {(item.price * item.qty).toFixed(2) + '  '}
                              <div
                                style={{
                                  color: 'rgb(0, 204, 112)'
                                }}
                              >
                                {item.desc}
                              </div>
                              {item.show ? (
                                <List
                                  size="small"
                                  dataSource={item.cookIns}
                                  renderItem={item => (
                                    <List.Item
                                      style={{
                                        // color: 'white',
                                        padding: 0,
                                        border: 0
                                      }}
                                    >
                                      {item.name} @ R
                                      {item.price
                                        ? item.price.toFixed(2)
                                        : '0.00'}
                                    </List.Item>
                                  )}
                                  style={{
                                    display: 'block',
                                    fontSize: '11px'
                                  }}
                                />
                              ) : null}
                            </div>
                          ) : (
                            'R ' + (item.price * item.qty).toFixed(2)
                          )
                        ) : (
                          ''
                        )
                      }
                    />
                    {item.state === 'payed' ? (
                      <div>Paid</div>
                    ) : (
                      <div>
                        <ButtonGroup className="bill-items-btn-group">
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              color: 'red',
                              border: '1px solid red',
                              borderRadius: '120px',
                              height: '27px',
                              width: '27px',
                              transform: 'scale(0.8)',
                              padding: '3px'
                            }}
                            onClick={() => props.decline(item, iKey)}
                          >
                            <Icon
                              type="minus"
                              style={{
                                fontSize: '17px',
                                paddingTop: '1px'
                              }}
                            />
                          </button>
                          <div
                            style={{
                              display: 'inline-block',
                              backgroundColor: 'transparent',
                              // color: 'white',
                              borderRadius: '0px',
                              boxShadow: 'none',
                              top: '-4px',
                              width: '28px',
                              fontSize: '1.1em',
                              margin: '10px',
                              fontWeight: '600',
                              textAlign: 'center'
                            }}
                          >
                            {' '}
                            {item.qty}
                          </div>
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              color: 'rgb(0, 204, 112)',
                              border: '1px solid rgb(0, 204, 112)',
                              transform: 'scale(0.8)',
                              borderRadius: '200px',
                              height: '27px',
                              width: '27px',

                              padding: '3px'
                            }}
                            onClick={() => props.increase(item, iKey)}
                          >
                            <Icon
                              type="plus"
                              style={{
                                paddingTop: '1px',
                                fontSize: '17px'
                              }}
                            />
                          </button>
                        </ButtonGroup>
                      </div>
                    )}
                  </Skeleton>
                </List.Item>
              ) : (
                <div />
              )
            }
          />
        </ConfigProvider>
      )}
    </div>
  );
};

const SlideDots = ({ dotsState, props, themeMode }) => {
  return (
    <div type="flex" justify="space-around" className="slider-extra">
      {Object.keys(dotsState.bills).length >= 2 ? (
        <ul>
          {dotsState.bills &&
            Object.keys(dotsState.bills).map((billid, key) => (
              <li key={billid} className="slick-dots">
                <div
                  style={{ height: 20, width: 40 }}
                  onClick={() => props.onDotsClick(key, billid)}
                  className={
                    billid === dotsState.billid
                      ? 'sales-slick-active-dark'
                      : null
                  }
                >
                  <img
                    className={'sales-slick-' + themeMode}
                    src={circleTeal}
                  />
                </div>
              </li>
            ))}
        </ul>
      ) : null}
    </div>
  );
};

const MemoSlideDots = memo(SlideDots);
const MemoBills = memo(Bills);
const MemoPhoneBills = memo(PhoneBills);
const MemoBillsMenu = memo(BillsMenu);
const MemoPhoneBillsMenu = memo(PhoneBillsMenu);

export {
  MemoBills,
  MemoBillsMenu,
  MemoPhoneBills,
  MemoPhoneBillsMenu,
  MemoSlideDots
};
