export function initLoader(perc) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'INIT_LOADER',
          percent: perc
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}

export function removeLoader() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return resolve(
        dispatch({
          type: 'REMOVE_LOADER'
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}