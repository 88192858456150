/* eslint-disable no-loop-func */
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  Avatar,
  Button,
  Card,
  Col,
  Icon,
  Layout,
  List,
  Modal,
  Row
} from 'antd';
import React, { useEffect, useState } from 'react';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
const { Content } = Layout;
const { confirm } = Modal;
let passwordFields = {
  password: '',
  confirm: '',
  showPass: false,
  showConfirm: false
};
let setPasswordFields;
const changePassField = e => {
  const v = e.currentTarget.value;
  setPasswordFields(prev => ({
    ...prev,
    password: v
  }));
};
const changeConfirmField = e => {
  const v = e.currentTarget.value;
  setPasswordFields(prev => ({
    ...prev,
    confirm: v
  }));
};
const changePass = () => {
  setPasswordFields(prev => ({
    ...prev,
    open: true
  }));
};
const confirmChangePass = props => {
  setSubmitRequest(true);
  if (
    passwordFields.confirm === passwordFields.password &&
    passwordFields.password !== ''
  ) {
    let user = props.user;
    user.password = passwordFields.password;
    props.handleUpdateCustomer(user);
    setPasswordFields(prev => ({
      ...prev,
      open: false
    }));
  }
};
let userDetails;
let setUserDetails;
let submitRequest = false;
let setSubmitRequest;
let favourites = [];
let setFavourites;
const handleSave = props => {
  setSubmitRequest(true);
  if (userDetails.name !== '' && userDetails.number !== '') {
    let user = userDetails;
    props.handleUpdateCustomer(user);
  }
};
const handleFavourite = (e, company, props) => {
  let user = props.user;
  delete user.favourites[company.orgid];
  props.handleUpdateCustomer(user);
};
const Cart = props => {
  let partFavourites = [];
  for (var prop in props.user.favourites) {
    partFavourites.push(props.user.favourites[prop]);
  }
  [favourites, setFavourites] = useState(favourites);
  [userDetails, setUserDetails] = useState(props.user);
  [submitRequest, setSubmitRequest] = useState(submitRequest);
  [passwordFields, setPasswordFields] = useState(passwordFields);
  if (favourites.length !== partFavourites.length) {
    setFavourites(partFavourites);
  }
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white' }}>
      <Row type="flex" justify="space-between">
        <Col style={{ display: 'flex' }} span={12}>
          <h2
            style={{
              fontSize: 17,
              paddingTop: '0.5em',
              margin: 'auto',
              marginBottom: '0.5em'
            }}
          >
            {/*<img
              style={{
                marginTop: '-0.2em',
                marginLeft: '-1.5em',
                width: '1.2em'
              }}
              src={personImg}
            />{' '}*/}
            Personal Details
          </h2>
        </Col>
      </Row>
      <Row>
        <Card
          style={{
            width: '95%',
            margin: 'auto',
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.137)',
            borderRadius: '0.3em'
          }}
        >
          <Row gutter={24}>
            <Col style={{ marginTop: '-2em' }} span={24}>
              <CustomInput
                labelText="Full name"
                id="name"
                formControlProps={{
                  fullWidth: true
                }}
                error={userDetails.name === '' && submitRequest}
                errorText={'Name required'}
                inputProps={{
                  onChange: e => {
                    const value = e.currentTarget.value;
                    setUserDetails(prev => ({
                      ...prev,
                      name: value
                    }));
                  },
                  value: userDetails.name
                }}
              />
            </Col>
            <Col style={{ marginTop: '-2em', marginBottom: '1em' }} span={24}>
              <CustomInput
                labelText="Number"
                id="number"
                formControlProps={{
                  fullWidth: true
                }}
                error={userDetails.number === '' && submitRequest}
                errorText={'Number required'}
                inputProps={{
                  onChange: e => {
                    const value = e.currentTarget.value;
                    setUserDetails(prev => ({
                      ...prev,
                      number: value
                    }));
                  },
                  value: userDetails.number
                }}
              />{' '}
            </Col>{' '}
            <Col
              style={{
                margin: 'auto',
                textAlign: 'center',
                marginBottom: '-0.5em'
              }}
              span={24}
            >
              <Button
                style={{
                  fontSize: '16px',
                  width: '45%',
                  height: '2.7em',
                  margin: 'auto',
                  backgroundColor: '#00CC70',
                  color: 'white',
                  border: 'none',
                  borderRadius: '3em'
                }}
                onClick={e => handleSave(props)}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row type="flex" justify="space-between">
        <Col style={{ display: 'flex' }} span={12}>
          <h2
            style={{
              fontSize: 17,
              paddingTop: '0.5em',
              margin: 'auto',
              marginBottom: '0.5em'
            }}
          >
            {/*<img
              style={{
                marginTop: '-0.3em',
                marginLeft: '-1.5em',
                width: '1.2em'
              }}
              src={gearImg}
            />{' '}*/}
            Account Details
          </h2>
        </Col>
      </Row>
      <Row>
        <Card
          style={{
            width: '95%',
            margin: 'auto',
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.137)',
            borderRadius: '0.3em'
          }}
        >
          {' '}
          <Row gutter={24}>
            <Col style={{ marginTop: '-2em' }} span={24}>
              <CustomInput
                labelText="Username"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                error={userDetails.username === '' && submitRequest}
                errorText={'Username required'}
                inputProps={{
                  disabled: true,
                  onChange: e => {
                    setUserDetails(prev => ({
                      ...prev,
                      username: e.currentTarget.value
                    }));
                  },
                  value: userDetails.username
                }}
              />{' '}
            </Col>

            <Col style={{ marginTop: '-2em' }} span={24}>
              <CustomInput
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  onClick: changePass,
                  value: 'Click to change'
                }}
              />
            </Col>
          </Row>
        </Card>
      </Row>
      {favourites.length > 0 ? (
        <Row type="flex" justify="space-between">
          <Col style={{ display: 'flex' }} span={12}>
            <h2
              style={{
                fontSize: 17,
                paddingTop: '0.6em',
                margin: 'auto',
                marginBottom: '0.5em'
              }}
            >
              {/*<img
                style={{
                  marginTop: '-0.3em',
                  marginLeft: '-1.5em',
                  width: '1.2em'
                }}
                src={favImg}
              />{' '}*/}
              Your Favourites
            </h2>
          </Col>
        </Row>
      ) : null}
      {favourites.length > 0 ? (
        <Row
          style={{
            paddingBottom: '4em'
          }}
        >
          <Card
            style={{
              width: '95%',
              margin: 'auto',
              boxShadow: '0 1px 4px rgba(0, 0, 0, 0.137)',
              borderRadius: '0.3em'
            }}
          >
            <List
              dataSource={favourites}
              renderItem={item => (
                <List.Item
                  style={{ border: 'none' }}
                  actions={[
                    <Icon
                      style={{ paddingBottom: '0.75em' }}
                      onClick={e => handleFavourite(e, item, props)}
                      type="close"
                    />
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{ marginTop: '-0.4em', marginRight: '-0.4em' }}
                        src={process.env.REACT_APP_GLOBAL_SERVER + item.logo}
                      />
                    }
                    title={item.name}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Row>
      ) : null}
      <FormDialog
        head="Change Password"
        formElements={[
          <CustomInput
            labelText="Enter a new password"
            id="password"
            formControlProps={{
              fullWidth: true
            }}
            error={passwordFields.password === '' && submitRequest}
            errorText={'Password required'}
            inputProps={{
              onChange: e => changePassField(e),
              type: !passwordFields.showPass ? 'password' : '',
              value: passwordFields.password,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setPasswordFields(prev => ({
                        ...prev,
                        showPass: !prev.showPass
                      }))
                    }
                  >
                    {passwordFields.showPass ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />,
          <CustomInput
            labelText="Confirm password"
            id="confirm"
            formControlProps={{
              fullWidth: true
            }}
            error={
              passwordFields.confirm !== passwordFields.password &&
              submitRequest
            }
            errorText={'Passwords do not match'}
            inputProps={{
              onChange: e => changeConfirmField(e),
              type: !passwordFields.showConfirm ? 'password' : '',
              value: passwordFields.confirm,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setPasswordFields(prev => ({
                        ...prev,
                        showConfirm: !prev.showConfirm
                      }))
                    }
                  >
                    {passwordFields.showConfirm ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        ]}
        open={passwordFields.open}
        close={e => {
          setPasswordFields(prev => ({
            ...prev,
            open: false
          }));
        }}
        actionBtn="Confirm"
        onClick={e => confirmChangePass(props)}
      />
    </Content>
  );
};

export default Cart;
