import React from 'react';
import Button from 'src/components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = props => {
  const { head, msg, formElements, open, close, actionBtn, actionBtn2, onClick } = props;

  return (
    <div>
      <Dialog style={{padding:"1em",textAlign:"center"}} open={open} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{head}</DialogTitle>
        <DialogContent>
          <DialogContentText>{msg}</DialogContentText>
          {formElements &&
            formElements.map((elem, key) => <div key={key}>{elem}</div>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} style={{
            fontFamily:"sans-serif",
            position: "absolute",
            top: "0.5em",
            maxWidth: "0.2em",
            right: "0.6em",
            padding: 0,
            fontSize: "1.2em",
            color: "black",
            background: "transparent",
            boxShadow: "none"
          }}>
            x
          </Button>
          {actionBtn ?

            <Button  style={{ width: "50%", margin: "auto" ,border: "1px solid white",boxShadow:"none",
            borderRadius: "3em"}} key={0} onClick={actionBtn.click} color="primary">
              {actionBtn.name}
            </Button>
            : null}
      {actionBtn2 ? <h1 style={{fontSize:"13px"}}> or </h1> : null}
          {actionBtn2 ?

            <Button style={{ margin: "auto" ,width: "35%",border: "1px solid white",boxShadow:"none",
            borderRadius: "3em"}} key={1} onClick={actionBtn2.click} color="primary">
              {actionBtn2.name}
            </Button>
            : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
