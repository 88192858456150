import React, { useState, useEffect, memo } from "react";
import PatternLock from "react-pattern-lock";
import PinInput from "src/components/PinInput/PinInput.jsx";
import NumberInput from "src/components/PinInput/Number.jsx";
import Redo from "src/static/redo-alt.png";

const inputStyle = {
  height: 50,
 
  backgroundColor: "transparent",
  border: "0",
  color: "white",
  fontSize: "3em",
};
const inputStyle2 = {
  height: 60,
  textAlign: "center",
  backgroundColor: "rgba(255, 0, 0, 0.2)",
  border: "0",
  color: "white",
  fontSize: "3em",
};
const pinLength = 4;

const NumberButtons = memo(({ numberState, submitForm, userLoginState }) => {
  const [state, setButtons] = useState({
    password: numberState.password,
  });
  const setPassword = (number) => {
    setButtons((prevState) => ({
      ...prevState,
      password: number,
    }));
  };

  const setPasswordNumpad = (number) => {
    if (state.password.length < pinLength) {
      let copyState = JSON.parse(JSON.stringify(state));
      copyState.password.push(number);
      setButtons((prevState) => ({
        ...prevState,
        password: copyState.password,
      }));
      if (copyState.password.length === pinLength) {
        submitForm(null, copyState, userLoginState);
      }
    }
  };

  const clear = () => {
    setButtons((prevState) => ({
      ...prevState,
      password: [],
    }));
  };

  const backspace = () => {
    let copyState = JSON.parse(JSON.stringify(state));
    copyState.password.splice(-1);
    setButtons((prevState) => ({
      ...prevState,
      password: copyState.password,
    }));
  };

  useEffect(() => {
    if (!numberState.password.length) {
      setButtons((prevState) => ({
        ...prevState,
        password: [],
      }));
    }
  }, [numberState.password]);
  return (
    <div>
      {userLoginState.loginType === "Pin" ? (
        <div>
          <div style={{ paddingBottom: "15px" }}>
            <PinInput
              disabled
              secret
              value={state.password}
              style={
                userLoginState.loginError === true ? inputStyle2 : inputStyle
              }
              length={pinLength}
              type="numeric"
            />
          </div>
          <div>
            <NumberInput
              click={setPasswordNumpad}
              clear={clear}
              backspace={backspace}
            />
          </div>
        </div>
      ) : (
        <div>
          <PatternLock
            size={4}
            onChange={setPassword}
            path={state.password}
            connectorThickness={5}
            pointActiveSize={50}
            allowJumping={false}
            error={numberState.loginError}
            success={numberState.loginSuccess}
            onFinish={(e) => submitForm(null, state, userLoginState)}
            invisible={true}
          />
        </div>
      )}
    </div>
  );
});

const NumberButtonsChange = memo(
  ({ numberState, doRenewPass, classes, backToLogin, userLoginState }) => {
    const [state, setButtons] = useState({
      passReset: numberState.passReset,
    });
    const setPasswordRest = (number) => {
      setButtons((prevState) => ({
        ...prevState,
        passReset: number,
      }));
    };

    const submitFormClear = () => {
      setButtons((prevState) => ({
        ...prevState,
        passReset: [],
      }));
    };

    const setPasswordNumpadReset = (number) => {
      if (state.passReset.length < pinLength) {
        let copyState = JSON.parse(JSON.stringify(state));
        copyState.passReset.push(number);
        setButtons((prevState) => ({
          ...prevState,
          passReset: copyState.passReset,
        }));
      }
    };

    const clear = () => {
      setButtons((prevState) => ({
        ...prevState,
        passReset: [],
      }));
    };

    const backspace = () => {
      let copyState = JSON.parse(JSON.stringify(state));
      copyState.passReset.splice(-1);
      setButtons((prevState) => ({
        ...prevState,
        passReset: copyState.passReset,
      }));
    };

    useEffect(() => {
      if (!numberState.passReset.length) {
        setButtons((prevState) => ({
          ...prevState,
          passReset: [],
        }));
      }
    }, [numberState.passReset]);

    return (
      <div>
        <div
          style={{
            maxWidth: "23em",
            minWidth: "23em",
            overflow: "hidden",
            marginLeft: "2em",
          }}
        >
          {userLoginState.loginType === "Pin" ? (
            <div>
              <div style={{ paddingBottom: "15px" }}>
                <PinInput
                  disabled
                  value={state.passReset}
                  style={inputStyle}
                  length={pinLength}
                  type="numeric"
                />
              </div>
              <div>
                <NumberInput
                  click={setPasswordNumpadReset}
                  clear={clear}
                  backspace={backspace}
                />
              </div>
            </div>
          ) : (
            <PatternLock
              size={4}
              onChange={setPasswordRest}
              path={state.passReset}
              connectorThickness={5}
              pointActiveSize={50}
            />
          )}
        </div>
        <div className="user-reset-area-numbers">
          <div style={{ display: "flex", marginLeft: "10%" }}>
            <button
              onClick={(e) => doRenewPass(state)}
              color="primary"
              style={{
                marginTop: "0.7em",
                marginLeft: "1em",
                fontSize: "17px",
                fontWeight: "700",
                width: "100%",
                height: "50px",
                border: "0px solid rgba(38, 167, 139, 0.39)",
                boxShadow: "4px 3px 4px 0px rgba(0, 0, 0, 0.14)",
                background: "#ffffff31",
                color: "white",
                borderRadius: "4em",
              }}
            >
              Change Password
            </button>
            <button
              style={{
                width: "3.3em",
                height: "55px",
                marginTop: "0.8em",
                marginLeft: "0.4em",
                marginRight: "0em",
                left: "0px",
                fontSize: "27px",
                fontWeight: "500",
                transform: "scale(0.9)",
                background: "#ffffff31",
                color: "white",
                borderRadius: "20em",
                margin: "10px",
                boxShadow: "4px 3px 4px 0px rgba(0, 0, 0, 0.14)",
                border: "3px solid transparent",
              }}
              onClick={submitFormClear}
              color="primary"
            >
              <img
                alt="icon"
                style={{ width: "0.8em", marginTop: "-0.25em" }}
                src={Redo}
              />
            </button>
          </div>
          <div style={{ display: "flex", marginLeft: "10%" }}>
            <button
              onClick={backToLogin}
              color="primary"
              style={{
                margin:"auto",
                fontSize: "17px",
                fontWeight: "700",
                width: "90%",
                height: "40px",
                border: "0px solid rgba(38, 167, 139, 0.39)",
                boxShadow: "4px 3px 4px 0px rgba(0, 0, 0, 0.14)",
                background: "red",
                color: "white",
                borderRadius: "4em",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export { NumberButtons, NumberButtonsChange };
