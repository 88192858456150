import { FaWindows } from 'react-icons/fa';

export const initialState = {
  users: [],
  topSale: false,
  menuHelpCard: true,
  specialHelpCard: true,
  cookingHelpCard: true,
  user: {
    _id: '',
    orgtoken: '',
    orgname: '',
    ecommerceActive: false,
    orgid: '',
    canDiscount: false,
    canQRBill: false,
    deviceid: '',
    deviceno: '',
    token: '',
    name: '',
    email: '',
    mobile: '',
    role: '',
    direct: false,
    mode: '',
    printer: '',
    userPrint: {},
    session: false,
    sessionid: '',
    tabcreation: false,
    yocopayment: false,
    addItemRequests: false,
    showFav: false,
    showKitch: false,
    addTipA: false,
    deliveryActive: false,
    pack: {},
    voidPin: false,
    voidPinNumber: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PASSWORD': {
      let users = state.users;
      users.forEach(user => {
        if (user.username === action.data.username) {
          user.password = action.data.passReset;
        }
      });
      return {
        ...state,
        users: users
      };
    }
    case 'ORG_LOGIN': {
      var token = '';
      var _id = '';
      var role = '';
      var mode = '';
      var name = '';
      var email = '';
      var mobile = '';
      var oUser = {};
      if (!action.data.userDetails.role) {
        action.data.userDetails.role = {};
      }
      if (action.data.userDetails.role.isDriver) {
        /*eslint-disable */
        if (!window.pubnub) {
          window.pubnub = new PubNub({
            publishKey: 'pub-c-dba083a8-729c-4413-aea8-0473e0fe9677',
            subscribeKey: 'sub-c-4528b54e-77df-11ea-808e-bad180999bc3'
          });
        }
        /*eslint-enable */
        window.pubnub.setUUID(action.data.userDetails._id);
        let channels = [action.data.userDetails.orgid + 'driver'];
        window.pubnub.subscribe({
          channels: channels
        });
        if (window.cordova) {
          window.FirebasePlugin.getToken(
            token => {
              window.pubnub.push.addChannels(
                {
                  channels: channels,
                  device: token,
                  pushGateway: 'gcm' // apns, gcm, mpns
                },
                function(status) {
                  if (status.error) {
                    console.log('operation failed w/ error:', status);
                  }
                }
              );
            },
            error => {
              console.error(error);
            }
          );
        }
        let printer = action.data.userDetails.printer;
        let printerList = [];
        let addressList = [];
        if (
          window.device &&
          printer &&
          printer.printers &&
          printer.printers.length
        ) {
          if (
            window.device.platform === 'Android' ||
            window.device.platform === 'iOS'
          ) {
            if (window.DatecsPrinter) {
              window.DatecsPrinter.listBluetoothDevices(function(devices) {
                if (devices && devices.length) {
                  for (var i = 0; i < devices.length; i++) {
                    if (devices[i].address) {
                      addressList.push(devices[i].address);
                    }
                  }
                }
                if (addressList.length) {
                  for (var j = 0; j < printer.printers.length; j++) {
                    if (
                      printer.printers[j].type === 'bluetooth' &&
                      printer.printers[j].address
                    ) {
                      for (var k = 0; k < addressList.length; k++) {
                        if (
                          addressList[k] &&
                          printer.printers[j].address === addressList[k]
                        ) {
                          printerList.push(printer.printers[j]);
                        }
                      }
                    } else {
                      if (printer.printers[j].address) {
                        printerList.push(printer.printers[j]);
                      }
                    }
                  }
                  printer.printers = printerList;
                }
              });
            }
          }
        }
        oUser = {
          _id: action.data.userDetails._id,
          orgtoken: true,
          orgname: action.data.userDetails.orgname,
          orgid: action.data.userDetails.orgid,
          ecommerceActive: action.data.userDetails.ecommerceActive,
          deviceid: '',
          deviceno: '',
          token: action.data.token,
          name: action.data.userDetails.name,
          email: action.data.userDetails.email,
          mobile: action.data.userDetails.mobile,
          role: action.data.userDetails.role,
          direct: false,
          mode: action.data.userDetails.mode,
          printer: printer,
          userPrint: {},
          session: true,
          sessionid: action.data.userDetails.sessionid,
          tabcreation: action.data.userDetails.tabcreation,
          yocopayment: action.data.userDetails.yocopayment,
          addItemRequests: action.data.userDetails.addItemRequests,
          showFav: action.data.userDetails.showFav,
          showKitch: action.data.userDetails.showKitch,
          addTipA: action.data.userDetails.addTipA,
          deliveryActive: action.data.userDetails.deliveryActive
        };
      } else if (action.data.userDetails.pack.isDely) {
        token = action.data.usertoken;
        role = action.data.userDetails.roles;
        mode = action.data.userDetails.mode;
        // mode.home = true;
        _id = action.data.userDetails.super._id;
        name = action.data.userDetails.super.name;
        email = action.data.userDetails.super.email;
        mobile = action.data.userDetails.super.mobile;
        oUser = {
          _id: _id,
          orgtoken: action.data.token,
          orgname: action.data.userDetails.orgname,
          ecommerceActive: action.data.userDetails.ecommerceActive,
          orgid: action.data.userDetails.orgid,
          deviceid: action.data.userDetails.deviceid,
          deviceno: action.data.userDetails.deviceno,
          token: token,
          name: name,
          email: email,
          mobile: mobile,
          role: role,
          direct: false,
          mode: mode,
          printer: '',
          userPrint: {},
          session: token ? true : false,
          sessionid: '',
          tabcreation: false,
          yocopayment: false,
          addItemRequests: false,
          showFav: false,
          showKitch: false,
          addTipA: false,
          deliveryActive: false,
          pack: action.data.userDetails.pack || {}
        };
      } else {
        oUser = {
          _id: _id,
          orgtoken: action.data.token,
          orgname: action.data.userDetails.orgname,
          ecommerceActive: action.data.userDetails.ecommerceActive,
          orgid: action.data.userDetails.orgid,
          deviceid: action.data.userDetails.deviceid,
          deviceno: action.data.userDetails.deviceno,
          token: token,
          name: name,
          email: email,
          mobile: mobile,
          role: role,
          direct: false,
          mode: mode,
          printer: '',
          userPrint: {},
          session: token ? true : false,
          sessionid: '',
          tabcreation: false,
          yocopayment: false,
          addItemRequests: false,
          showFav: false,
          showKitch: false,
          addTipA: false,
          deliveryActive: false,
          pack: action.data.userDetails.pack || {}
        };
      }
      return {
        ...state,
        user: oUser
      };
    }
    case 'REMOVE_ORG_LOGIN': {
      return {
        ...state,
        user: initialState.user
      };
    }
    case 'UPDATE_TOKEN': {
      return {
        ...state,
        user: {
          ...state.user,
          token: action.data.token
        }
      };
    }
    case 'GET_ORG_USERS': {
      let users = [];
      let topSale = false;
      if (action.data) {
        topSale = action.data.topSale;
        users = action.data.users;
      } else {
        topSale = state.topSale;
        users = state.users;
      }
      return {
        ...state,
        users: users,
        topSale: topSale
      };
    }
    case 'USER_LOGIN': {
      /*eslint-disable */
      if (!window.pubnub) {
        window.pubnub = new PubNub({
          publishKey: 'pub-c-dba083a8-729c-4413-aea8-0473e0fe9677',
          subscribeKey: 'sub-c-4528b54e-77df-11ea-808e-bad180999bc3'
        });
      }
      /*eslint-enable */
      window.pubnub.setUUID(action.data.userDetails._id);

      let channels = [
        action.data.userDetails._id,
        action.data.userDetails.orgid,
        action.data.userDetails.orgid + 'kitchen'
      ];
      if (action.data.userDetails.role) {
        if (action.data.userDetails.role.isSupAdmin) {
          channels.push(action.data.userDetails.orgid + 'admin');
        }
      }
      window.pubnub.subscribe({
        channels: channels,
        withPresence: true
      });
      if (window.cordova) {
        window.FirebasePlugin.getToken(
          token => {
            window.pubnub.push.addChannels(
              {
                channels: channels,
                device: token,
                pushGateway: 'gcm' // apns, gcm, mpns
              },
              function(status) {
                if (status.error) {
                  console.log('operation failed w/ error:', status);
                }
              }
            );
          },
          error => {
            console.error(error);
          }
        );
      }
      let printer = action.data.userDetails.printer;
      let printerList = [];
      let addressList = [];
      if (
        window.device &&
        printer &&
        printer.printers &&
        printer.printers.length
      ) {
        if (
          window.device.platform === 'Android' ||
          window.device.platform === 'iOS'
        ) {
          if (window.DatecsPrinter) {
            window.DatecsPrinter.listBluetoothDevices(function(devices) {
              if (devices && devices.length) {
                for (var i = 0; i < devices.length; i++) {
                  if (devices[i].address) {
                    addressList.push(devices[i].address);
                  }
                }
              }
              if (addressList.length) {
                for (var j = 0; j < printer.printers.length; j++) {
                  if (
                    printer.printers[j].type === 'bluetooth' &&
                    printer.printers[j].address
                  ) {
                    for (var k = 0; k < addressList.length; k++) {
                      if (
                        addressList[k] &&
                        printer.printers[j].address === addressList[k]
                      ) {
                        printerList.push(printer.printers[j]);
                      }
                    }
                  } else {
                    if (printer.printers[j].address) {
                      printerList.push(printer.printers[j]);
                    }
                  }
                }
                printer.printers = printerList;
              }
            });
          }
        }
      }
      return {
        ...state,
        user: {
          _id: action.data.userDetails._id,
          orgtoken: state.user.orgtoken,
          orgname: state.user.orgname,
          ecommerceActive: action.data.userDetails.ecommerceActive,
          orgid: state.user.orgid,
          deviceid: state.user.deviceid,
          deviceno: state.user.deviceno,
          token: action.data.token,
          name: action.data.userDetails.name,
          email: action.data.userDetails.email,
          mobile: action.data.userDetails.mobile,
          role: action.data.userDetails.role,
          direct: action.data.userDetails.direct || false,
          mode: action.data.userDetails.mode,
          printer: printer,
          userPrint: action.data.userDetails.userPrint || {},
          session: action.data.userDetails.session,
          sessionid: action.data.userDetails.sessionid,
          tabcreation: action.data.userDetails.tabcreation,
          yocopayment: action.data.userDetails.yocopayment,
          addItemRequests: action.data.userDetails.addItemRequests,
          showFav: action.data.userDetails.showFav,
          showKitch: action.data.userDetails.showKitch,
          addTipA: action.data.userDetails.addTipA,
          deliveryActive: action.data.userDetails.deliveryActive,
          pack: state.user.pack,
          stockAlert: action.data.userDetails.stockAlert,
          canDiscount: action.data.userDetails.canDiscount,
          canQRBill: action.data.userDetails.canQRBill,
          voidPin: action.data.userDetails.voidPin,
          voidPinNumber: action.data.userDetails.voidPinNumber
        }
      };
    }
    case 'USER_LOGIN_SESSION': {
      return {
        ...state,
        user: {
          _id: state.user._id,
          orgtoken: state.user.orgtoken,
          orgname: state.user.orgname,
          ecommerceActive: state.user.ecommerceActive,
          orgid: state.user.orgid,
          canDiscount: state.user.canDiscount,
          canQRBill: state.user.canQRBill,
          deviceid: state.user.deviceid,
          deviceno: state.user.deviceno,
          token: state.user.token,
          name: state.user.name,
          email: state.user.email,
          mobile: state.user.mobile,
          role: state.user.role,
          direct: state.user.direct,
          mode: state.user.mode,
          printer: state.user.printer,
          userPrint: state.user.userPrint,
          session: !state.user.session,
          sessionid: action.data.results.insertedIds[0],
          tabcreation: state.user.tabcreation,
          yocopayment: state.user.yocopayment,
          addItemRequests: state.user.addItemRequests,
          showFav: state.user.showFav,
          showKitch: state.user.showKitch,
          addTipA: state.user.addTipA,
          deliveryActive: state.user.deliveryActive,
          pack: state.user.pack,
          voidPin: state.user.voidPin,
          voidPinNumber: state.user.voidPinNumber
        }
      };
    }
    case 'USER_PRINTER_SETTINGS': {
      return {
        ...state,
        user: {
          _id: state.user._id,
          orgtoken: state.user.orgtoken,
          orgname: state.user.orgname,
          ecommerceActive: state.user.ecommerceActive,
          orgid: state.user.orgid,
          canDiscount: state.user.canDiscount,
          canQRBill: state.user.canQRBill,
          deviceid: state.user.deviceid,
          deviceno: state.user.deviceno,
          token: state.user.token,
          name: state.user.name,
          email: state.user.email,
          mobile: state.user.mobile,
          role: state.user.role,
          direct: state.user.direct,
          mode: state.user.mode,
          printer: state.user.printer,
          userPrint: action.data,
          session: state.user.session,
          sessionid: state.user.sessionid,
          tabcreation: state.user.tabcreation,
          yocopayment: state.user.yocopayment,
          addItemRequests: state.user.addItemRequests,
          showFav: state.user.showFav,
          showKitch: state.user.showKitch,
          addTipA: state.user.addTipA,
          deliveryActive: state.user.deliveryActive,
          pack: state.user.pack,
          voidPin: state.user.voidPin,
          voidPinNumber: state.user.voidPinNumber
        }
      };
    }
    case 'USER_UPDATE': {
      return {
        ...state,
        user: {
          _id: state.user._id,
          orgtoken: state.user.orgtoken,
          orgname: state.user.orgname,
          ecommerceActive: state.user.ecommerceActive,
          orgid: state.user.orgid,
          canDiscount: state.user.canDiscount,
          canQRBill: state.user.canQRBill,
          deviceid: state.user.deviceid,
          deviceno: state.user.deviceno,
          token: state.user.token,
          name: action.data.name,
          email: action.data.email,
          mobile: action.data.mobile,
          role: state.user.role,
          direct: state.user.direct,
          mode: state.user.mode,
          printer: state.user.printer,
          userPrint: state.user.userPrint,
          session: state.user.session,
          sessionid: state.user.sessionid,
          tabcreation: state.user.tabcreation,
          yocopayment: state.user.yocopayment,
          addItemRequests: state.user.addItemRequests,
          showFav: state.user.showFav,
          showKitch: state.user.showKitch,
          addTipA: state.user.addTipA,
          deliveryActive: state.user.deliveryActive,
          pack: state.user.pack,
          voidPin: state.user.voidPin,
          voidPinNumber: state.user.voidPinNumber
        }
      };
    }
    case 'CUSTOMER_LOGIN': {
      /*eslint-disable */
      if (!window.pubnub) {
        window.pubnub = new PubNub({
          publishKey: 'pub-c-d683308a-5d30-4f43-9b8d-3e3064ba4f5d',
          subscribeKey: 'sub-c-a9521a1c-cd13-11eb-9d78-4e51a9db8267'
        });
      }
      /*eslint-enable */
      window.pubnub.setUUID(action.data.userDetails._id);
      let channels = [action.data.userDetails._id];
      window.pubnub.subscribe({
        channels: channels
      });
      if (window.cordova) {
        window.FirebasePlugin.getToken(
          token => {
            window.pubnub.push.addChannels(
              {
                channels: channels,
                device: token,
                pushGateway: 'gcm' // apns, gcm, mpns
              },
              function(status) {
                if (status.error) {
                  console.log('operation failed w/ error:', status);
                }
              }
            );
          },
          error => {
            console.error(error);
          }
        );
      }
      action.data.userDetails.token = action.data.token;
      return {
        ...state,
        user: action.data.userDetails
      };
    }
    case 'CUSTOMER_UPDATE': {
      let user = action.data.user;
      user.token = state.user.token;
      return {
        ...state,
        user: action.data.user
      };
    }
    case 'REMOVE_LOGIN': {
      if (window.pubnub) {
        window.pubnub.unsubscribe({
          channels: [
            state.user._id,
            state.user.orgid,
            state.user.orgid + 'kitchen',
            state.user.orgid + 'driver'
          ]
        });
      }

      if (window.cordova && window.pubnub) {
        window.FirebasePlugin.getToken(
          token => {
            window.pubnub.push.deleteDevice(
              {
                device: token,
                pushGateway: 'gcm' // apns, gcm, mpns
              },
              function(status) {
                if (status.error) {
                  console.log('operation failed w/ error:', status);
                }
              }
            );
          },
          error => {
            console.error(error);
          }
        );
      }
      return {
        ...state,
        user: {
          _id: '',
          orgtoken: state.user.orgtoken,
          orgname: state.user.orgname,
          ecommerceActive: state.user.ecommerceActive,
          orgid: state.user.orgid,
          deviceid: state.user.deviceid,
          deviceno: state.user.deviceno,
          name: '',
          email: '',
          mobile: '',
          token: '',
          role: '',
          direct: false,
          mode: '',
          printer: '',
          userPrint: {},
          canDiscount: false,
          canQRBill: false,
          session: false,
          sessionid: '',
          tabcreation: false,
          yocopayment: false,
          addItemRequests: false,
          showFav: state.user.showFav,
          showKitch: false,
          addTipA: false,
          deliveryActive: false,
          pack: state.user.pack,
          voidPin: false,
          voidPinNumber: ''
        }
      };
    }
    case 'SWITCH_TO_KITCHEN': {
      /*eslint-disable */
      if (!window.pubnub) {
        window.pubnub = new PubNub({
          publishKey: 'pub-c-dba083a8-729c-4413-aea8-0473e0fe9677',
          subscribeKey: 'sub-c-4528b54e-77df-11ea-808e-bad180999bc3'
        });
      }
      /*eslint-enable */
      window.pubnub.setUUID(state.user._id);
      let channels = [
        state.user._id,
        state.user.orgid,
        state.user.orgid + 'kitchen'
      ];

      window.pubnub.subscribe({
        channels: channels
      });
      if (window.cordova) {
        window.FirebasePlugin.getToken(
          token => {
            window.pubnub.push.addChannels(
              {
                channels: channels,
                device: token,
                pushGateway: 'gcm' // apns, gcm, mpns
              },
              function(status) {
                if (status.error) {
                  console.log('operation failed w/ error:', status);
                }
              }
            );
          },
          error => {
            console.error(error);
          }
        );
      }
      return {
        ...state,
        user: {
          _id: state.user._id,
          orgtoken: state.user.orgtoken,
          orgname: state.user.orgname,
          ecommerceActive: state.user.ecommerceActive,
          orgid: state.user.orgid,
          deviceid: state.user.deviceid,
          deviceno: state.user.deviceno,
          token: state.user.token,
          name: state.user.name,
          email: state.user.email,
          mobile: state.user.mobile,
          role: { isKitchen: true },
          direct: state.user.direct,
          mode: state.user.mode,
          printer: state.user.printer,
          userPrint: state.user.userPrint,
          session: state.user.session,
          sessionid: state.user.sessionid,
          tabcreation: state.user.tabcreation,
          yocopayment: state.user.yocopayment,
          addItemRequests: state.user.addItemRequests,
          showFav: state.user.showFav,
          showKitch: state.user.showKitch,
          addTipA: state.user.addTipA,
          deliveryActive: state.user.deliveryActive,
          pack: state.user.pack,
          voidPin: state.user.voidPin,
          voidPinNumber: state.user.voidPinNumber
        }
      };
    }
    case 'CLOSE_MENU_HELP': {
      return {
        ...state,
        menuHelpCard: false
      };
    }
    case 'CLOSE_COOKING_HELP': {
      return {
        ...state,
        cookingHelpCard: false
      };
    }
    case 'CLOSE_SPECIAL_HELP': {
      return {
        ...state,
        specialHelpCard: false
      };
    }
    default:
      return state;
  }
};
