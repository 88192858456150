import React from 'react';
import {
  Spin,
  Table,
  Input,
  Icon,
  Row,
  Col,
  Select,
  DatePicker,
  Statistic,
  Button
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { getSalesReport } from 'src/redux/actions/backend';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import { CSVLink } from 'react-csv';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { openSnackbar, closeSnackbar } from 'src/redux/actions/snackbar';
import Highlighter from 'react-highlight-words';
import StyledTable from 'src/components/Table/StyledTable';
import { setHelpMarker } from 'src/redux/actions/help';
import styled from 'styled-components';
import {
  primaryColor,
  grayColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx';
import { Chart, Geom, Axis, Tooltip } from 'bizcharts';
import CardFooter from 'src/components/Card/CardFooter';
import CardHeader from 'src/components/Card/CardHeader';
import salesreport from './salesreport.js';

const { Column } = Table;
const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { RangePicker } = DatePicker;

const { Option } = Select;

const StyledStatistic = styled(Statistic)`
  .ant-statistic-title {
    position: relative;
    top: 10px;
  }
`;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'inline'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    height: '150px',
    overflow: 'auto'
  },
  tableScroll: {
    width: '100%',
    height: '45vh',
    overflow: 'auto'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  datePicker: {
    marginRight: '10px',
    marginTop: '1em',
    '& span': {
      height: '45px'
    }
  }
});

// const cols = {
//   value: {
//     min: 0
//   },
//   date: {
//     range: [0, 1]
//   }
// };

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sales: {},
      items: [],
      datePicker: false,
      startDate: moment().format(dateFormatSearch),
      endDate: moment().format(dateFormatSearch),
      pickerStart: moment(),
      pickerEnd: moment(),
      alert: false,
      msg: [],
      head: ''
    };
  }

  componentDidMount() {
    this.getSalesReport(this.state.startDate, this.state.endDate);
  }

  getSalesReport = (start, end) => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    this.props
      .getSalesReport(token, start, end)
      .then(result => {
        if (result.data.results === 'NoBills') {
          this.setState({
            csvData: undefined,
            sales: {},
            items: [],
            report: [],
            loading: false
          });
        } else {
          let tableBody = [];
          tableBody.push([
            'Product Name',
            'Category',
            'Items Sold',
            'Voids',
            'Net Sale',
            'Cost of Goods',
            'Profit'
          ]);
          result.data.results.items.forEach(record => {
            let lineItem = [];

            lineItem.push(record.name);
            lineItem.push(record.type ? record.type : 'Modifier');
            lineItem.push(record.qty);
            lineItem.push(
              record.voidCount ? record.voidCount.toFixed('2') : '0.00'
            );
            lineItem.push(record.price.toFixed('2'));
            lineItem.push(
              (record.cost ? parseFloat(record.cost) : 0).toFixed('2')
            );
            lineItem.push(
              record.type
                ? (
                    record.price - (record.cost ? parseFloat(record.cost) : 0)
                  ).toFixed('2')
                : ''
            );

            tableBody.push(lineItem);
          });
          this.setState({
            csvData: tableBody,
            sales: result.data.results,
            items: result.data.results.items,
            report: result.data.results.report,
            loading: false
          });
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        startDate: value[0].format(dateFormatSearch),
        endDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1]
      });
    }
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'yester') {
        this.setState(
          {
            startDate: moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            endDate: moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            datePicker: false
          },
          this.getSalesReport(
            moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            moment()
              .subtract(1, 'days')
              .format(dateFormatSearch)
          )
        );
      } else if (value === 'today') {
        this.setState(
          {
            startDate: moment().format(dateFormatSearch),
            endDate: moment().format(dateFormatSearch),
            datePicker: false
          },
          this.getSalesReport(
            moment().format(dateFormatSearch),
            moment().format(dateFormatSearch)
          )
        );
      } else if (value === 'last7') {
        this.setState(
          {
            startDate: moment()
              .subtract(7, 'd')
              .format(dateFormatSearch),
            endDate: moment().format(dateFormatSearch),
            datePicker: false
          },
          this.getSalesReport(
            moment()
              .subtract(7, 'd')
              .format(dateFormatSearch),
            moment().format(dateFormatSearch)
          )
        );
      } else if (value === 'last30') {
        this.setState(
          {
            startDate: moment()
              .subtract(30, 'd')
              .format(dateFormatSearch),
            endDate: moment().format(dateFormatSearch),
            datePicker: false
          },
          this.getSalesReport(
            moment()
              .subtract(30, 'd')
              .format(dateFormatSearch),
            moment().format(dateFormatSearch)
          )
        );
      } else if (value === 'thism') {
        this.setState(
          {
            startDate: moment()
              .startOf('month')
              .format(dateFormatSearch),
            endDate: moment().format(dateFormatSearch),
            datePicker: false
          },
          this.getSalesReport(
            moment()
              .startOf('month')
              .format(dateFormatSearch),
            moment().format(dateFormatSearch)
          )
        );
      } else if (value === 'lastm') {
        this.setState(
          {
            startDate: moment()
              .subtract(1, 'months')
              .startOf('month')
              .format(dateFormatSearch),
            endDate: moment()
              .subtract(1, 'months')
              .endOf('month')
              .format(dateFormatSearch),
            datePicker: false
          },
          this.getSalesReport(
            moment()
              .subtract(1, 'months')
              .startOf('month')
              .format(dateFormatSearch),
            moment()
              .subtract(1, 'months')
              .endOf('month')
              .format(dateFormatSearch)
          )
        );
      } else if (value === 'alltime') {
        this.getSalesReport(value, moment().format(dateFormatSearch));
      } else if (value === 'custom') {
        this.setState({
          datePicker: true
        });
      }
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Menu Items Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };

  exportPDF = () => {
    this.setState({ loading: true });
    let chart = document.getElementsByClassName('chartRender')[0].children[0]
      .children[0].children[0];
    const pdfMake = require('pdfmake/build/pdfmake');
    const vfsFonts = require('pdfmake/build/vfs_fonts');
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    const canvas = document.createElement('canvas');
    canvas.height = chart.height;
    canvas.width = chart.width;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(chart, 0, 0);
    const image = canvas.toDataURL('image/jpeg');
    let pdfDocGenerator = pdfMake.createPdf(
      salesreport(
        this.state.currentTableData
          ? this.state.currentTableData
          : this.state.items,
        this.state.sales,
        image,
        this.state.startDate,
        this.state.endDate
      )
    );
    pdfDocGenerator.download(
      'Sales Summary - ' +
        moment(this.state.startDate, 'YYYYMMDD').format('YYYY/MM/DD') +
        ' to ' +
        moment(this.state.endDate, 'YYYYMMDD').format('YYYY/MM/DD')
    );
    this.setState({ loading: false });
    // var $a = document.createElement('a');
    // $a.setAttribute("href", image);
    // $a.setAttribute("download", "titile");
    // $a.click();
  };

  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker
    };
    let filters = [];
    for (var i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].type) {
        if (
          filters.findIndex(
            filter => filter.text === this.state.items[i].type
          ) === -1
        ) {
          filters.push({
            text: this.state.items[i].type,
            value: this.state.items[i].type
          });
        }
      }
    }

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <div>
          <Row>
            <div
              style={{
                float: window.isPhone ? '' : 'left',
                textAlign: window.isPhone ? 'center' : '',
                marginBottom: window.isPhone ? '1em' : ''
              }}
            >
              <Select
                id="filterSelect"
                style={{
                  width: '150px',
                  paddingRight: '10px'
                }}
                defaultValue="today"
                onChange={this.handleChangeSelect}
              >
                <Option value="today">Today</Option>
                <Option value="yester">Yesterday</Option>
                <Option value="last7">Last 7 Days</Option>
                <Option value="last30">Last 30 Days</Option>
                <Option value="thism">This Month</Option>
                <Option value="lastm">Last Month</Option>
                <Option value="alltime">From Start</Option>
                <Option value="custom">Custom</Option>
              </Select>

              {this.state.datePicker ? (
                <Button
                  style={{
                    backgroundColor: '#00CC70',
                    color: 'white',
                    float: 'right',
                    height: '40px',
                    border: '1px solid #00CC70',
                    borderRadius: '3em',
                    paddingBottom: '0.1em'
                  }}
                  onClick={() =>
                    this.getSalesReport(
                      this.state.startDate,
                      this.state.endDate
                    )
                  }
                >
                  Get Report
                </Button>
              ) : null}
              {this.state.datePicker ? (
                <div>
                  <RangePicker
                    allowClear={false}
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDate}
                    value={[this.state.pickerStart, this.state.pickerEnd]}
                    format={dateFormat}
                  />
                </div>
              ) : null}
            </div>
            <div
              style={{
                display: window.isPhone ? 'none' : '',
                float: 'right'
              }}
            >
              <Button
                onClick={this.exportPDF}
                style={{
                  backgroundColor: '#00CC70',
                  border: '0px solid rgb(30, 161, 169)',
                  color: 'white',
                  marginBottom: '-0.5em',
                  height: '40px',
                  marginLeft: '-0.27em',
                  borderRadius: '3em'
                }}
              >
                Export to PDF
              </Button>
            </div>
            <div
              style={{
                display: window.isPhone ? 'none' : '',
                float: 'right'
              }}
            >
              <Button
                // onClick={this.exportPDF}
                style={{
                  backgroundColor: '#00CC70',
                  border: '0px solid rgb(30, 161, 169)',
                  color: 'white',
                  marginBottom: '-0.5em',
                  height: '40px',
                  marginRight: 20,
                  borderRadius: '3em'
                }}
              >
                <CSVLink
                  filename={
                    'Sales Summary - ' +
                    moment(this.state.startDate, 'YYYYMMDD').format(
                      'YYYY/MM/DD'
                    ) +
                    ' to ' +
                    moment(this.state.endDate, 'YYYYMMDD').format(
                      'YYYY/MM/DD'
                    ) +
                    '.csv'
                  }
                  data={
                    this.state.csvData ? this.state.csvData : [['No', 'Data']]
                  }
                >
                  Export to CSV
                </CSVLink>
              </Button>
            </div>
          </Row>
          <Row>
            <div>
              <Col span={window.isPhone ? 12 : 4}>
                <Card
                  style={{
                    marginTop: window.isPhone ? '0em' : '',
                    marginBottom: window.isPhone ? '0em' : '',
                    border: '0px solid lightgrey',
                    height: '80px',
                    textAlign: 'center',
                    boxShadow: 'none'
                  }}
                >
                  {' '}
                  <StyledStatistic
                    title="Gross Sales"
                    value={
                      this.state.sales.total
                        ? this.state.sales.total.toFixed('2')
                        : '0.00'
                    }
                    precision={2}
                    valueStyle={{
                      position: 'relative',
                      top: 5,
                      fontSize: 25
                    }}
                    prefix="R "
                  />
                  <CardFooter
                    style={{
                      marginTop: '10px',
                      marginLeft: -0,
                      width: '100%'
                      //  background:
                      // '#007A96'
                    }}
                  />
                </Card>
              </Col>
              <Col span={window.isPhone ? 12 : 4}>
                <Card
                  style={{
                    marginTop: window.isPhone ? '0em' : '',
                    marginBottom: window.isPhone ? '0em' : '',
                    border: '0px solid lightgrey',
                    height: '80px',
                    textAlign: 'center',
                    boxShadow: 'none'
                  }}
                >
                  <StyledStatistic
                    title="Discount"
                    value={
                      this.state.sales.discount
                        ? this.state.sales.discount.toFixed('2')
                        : '0.00'
                    }
                    precision={2}
                    valueStyle={{
                      position: 'relative',
                      top: 5,
                      fontSize: 25
                    }}
                    prefix="R "
                  />
                  <CardFooter
                    style={{
                      marginTop: '10px',
                      marginLeft: -0,
                      width: '100%'

                      //   background:
                      //  '#007A96'
                    }}
                  />
                </Card>
              </Col>
              <Col span={window.isPhone ? 0 : 4}>
                <Card
                  style={{
                    marginTop: window.isPhone ? '0em' : '',
                    marginBottom: window.isPhone ? '0em' : '',
                    border: '0px solid lightgrey',
                    height: '80px',
                    textAlign: 'center',
                    boxShadow: 'none'
                  }}
                >
                  <StyledStatistic
                    title="Refund"
                    value={
                      this.state.sales.refund
                        ? this.state.sales.refund.toFixed('2')
                        : '0.00'
                    }
                    precision={2}
                    valueStyle={{
                      position: 'relative',
                      top: 5,
                      fontSize: 25,
                      color: this.state.sales.refund ? 'red' : 'black'
                    }}
                    prefix="R "
                  />
                  <CardFooter
                    style={{
                      marginTop: '10px',
                      marginLeft: -0,
                      width: '100%'

                      //   background:
                      //  '#007A96'
                    }}
                  />
                </Card>
              </Col>
              <Col span={window.isPhone ? 12 : 4}>
                <Card
                  style={{
                    marginTop: window.isPhone ? '0em' : '',
                    marginBottom: window.isPhone ? '0em' : '',
                    border: '0px solid lightgrey',
                    height: '80px',
                    textAlign: 'center',
                    boxShadow: 'none'
                  }}
                >
                  <StyledStatistic
                    title="Net Sales"
                    value={
                      this.state.sales.total
                        ? (
                            this.state.sales.total -
                            (this.state.sales.discount || 0)
                          ).toFixed('2')
                        : '0.00'
                    }
                    precision={2}
                    valueStyle={{
                      position: 'relative',
                      top: 5,
                      fontSize: 25
                    }}
                    prefix="R "
                  />
                  <CardFooter
                    style={{
                      marginTop: '10px',
                      marginLeft: -0,
                      width: '100%'

                      //background:
                      //'#00CC70'
                    }}
                  />
                </Card>
              </Col>
              <Col span={window.isPhone ? 12 : 4}>
                <Card
                  style={{
                    marginTop: window.isPhone ? '0em' : '',
                    marginBottom: window.isPhone ? '0em' : '',
                    border: '0px solid lightgrey',
                    height: '80px',
                    textAlign: 'center',
                    boxShadow: 'none'
                  }}
                >
                  <StyledStatistic
                    title="Gross Profit"
                    value={
                      this.state.sales.total
                        ? (
                            this.state.sales.total -
                            (this.state.sales.cost || 0) -
                            (this.state.sales.discount || 0)
                          ).toFixed('2')
                        : '0.00'
                    }
                    precision={2}
                    valueStyle={{
                      position: 'relative',
                      top: 5,
                      fontSize: 25
                    }}
                    prefix="R "
                  />{' '}
                  <CardFooter
                    style={{
                      marginTop: '10px',
                      marginLeft: -0,
                      width: '100%'
                      //   background:
                      //  '#007A96'
                    }}
                  />
                </Card>
              </Col>
            </div>
          </Row>
          {window.isPhone ? null : (
            <Row>
              <div>
                <Chart
                  style={{
                    backgroundColor: 'white',
                    marginTop: '-1.9em'
                  }}
                  className="chartRender"
                  height={300}
                  data={this.state.report}
                  forceFit
                >
                  <Axis name="_id" />
                  <Axis name="Sales" />
                  <Tooltip
                    crosshairs={{
                      type: 'y'
                    }}
                  />
                  <Geom
                    type="line"
                    position="_id*Sales"
                    size={3}
                    tooltip={[
                      '_id*Sales',
                      (_id, sales) => {
                        return {
                          name: 'Sales',
                          title: 'Date ' + _id,
                          value: 'R' + sales.toFixed('2')
                        };
                      }
                    ]}
                  />
                  <Geom
                    type="point"
                    position="_id*Sales"
                    size={4}
                    shape={'circle'}
                    style={{
                      stroke: '#fff',
                      lineWidth: 1
                    }}
                  />
                </Chart>
              </div>
            </Row>
          )}
          <Card className={this.props.classes.cardExpand}>
            <CardBody>
              <GridContainer>
                <Row style={{ width: '100%' }}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="key"
                    size="middle"
                    dataSource={this.state.items}
                    pagination={{
                      locale: { items_per_page: ' Items' },
                      showSizeChanger: true,
                      onShowSizeChange: this.onShowSizeChange,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      hideOnSinglePage: true,
                      pageSize: this.state.pageSize || 25,
                      itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                          return <a>Previous</a>;
                        }
                        if (type === 'next') {
                          return <a>Next</a>;
                        }
                        return originalElement;
                      },
                      showTotal: (total, range) =>
                        `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                      defaultCurrent: 1
                    }}
                    onChange={(a, b, c, currentData) => {
                      let tableBody = [];
                      tableBody.push([
                        'Name',
                        'Type',
                        'Opening Stock',
                        'Sold',
                        'Sold Stock Value',
                        'Closing Stock',
                        'Retail Price',
                        'Total Retail'
                      ]);
                      currentData.currentDataSource.forEach(record => {
                        let lineItem = [];

                        lineItem.push(record.name);
                        lineItem.push(record.type);
                        lineItem.push(
                          (((record.qty ? record.qty : 0) + record.stockUsed)
                            .toFixed('2')
                            .includes('.00')
                            ? (
                                (record.qty ? record.qty : 0) + record.stockUsed
                              ).toFixed('0')
                            : (
                                (record.qty ? record.qty : 0) + record.stockUsed
                              ).toFixed('2')) +
                            ' ' +
                            (record.dimType ? record.dimType : record.dimension)
                        );
                        lineItem.push(
                          ((record.stockUsed ? record.stockUsed : 0)
                            .toFixed('2')
                            .includes('.00')
                            ? (record.stockUsed ? record.stockUsed : 0).toFixed(
                                '0'
                              )
                            : (record.stockUsed ? record.stockUsed : 0).toFixed(
                                '2'
                              )) +
                            ' ' +
                            (record.dimType ? record.dimType : record.dimension)
                        );
                        lineItem.push(
                          (
                            (record.buyPrice
                              ? parseFloat(record.buyPrice)
                              : 0) * (record.stockUsed ? record.stockUsed : 0)
                          ).toFixed('2')
                        );
                        lineItem.push(
                          ((record.qty ? record.qty : 0)
                            .toFixed('2')
                            .includes('.00')
                            ? (record.qty ? record.qty : 0).toFixed('0')
                            : (record.qty ? record.qty : 0).toFixed('2')) +
                            ' ' +
                            (record.dimType ? record.dimType : record.dimension)
                        );
                        lineItem.push(
                          record.sellPrice
                            ? (record.sellPrice ? record.sellPrice : 0)
                                .toFixed('2')
                                .includes('.00')
                            : '0.00'
                        );
                        lineItem.push(
                          record.sellPrice
                            ? (record.stockUsed
                                ? record.sellPrice * record.stockUsed
                                : 0
                              )
                                .toFixed('2')
                                .includes('.00')
                            : '0.00'
                        );
                        tableBody.push(lineItem);
                      });
                      this.setState({
                        currentTableData: currentData.currentDataSource,
                        csvData: tableBody
                      });
                    }}
                  >
                    <Column
                      {...this.getColumnSearchProps('name')}
                      title="Product Name"
                      render={(text, record) => (
                        <span
                          style={
                            !record.type
                              ? { paddingLeft: '40px' }
                              : { fontWeight: 900 }
                          }
                        >
                          {record.name}
                        </span>
                      )}
                      dataIndex="name"
                      key="name"
                    />
                    {window.isPhone ? null : (
                      <Column
                        //{...this.getColumnSearchProps("type")}
                        filters={filters}
                        onFilter={(value, record) => {
                          if (record.type) {
                            return record.type.includes(value);
                          } else return false;
                        }}
                        title="Category"
                        render={(text, record) => (
                          <span style={!record.type ? {} : { fontWeight: 900 }}>
                            {record.type ? record.type : 'Modifier'}
                          </span>
                        )}
                        dataIndex="type"
                        key="type"
                      />
                    )}
                    <Column
                      {...this.getColumnSearchProps('qty')}
                      title="Items Sold"
                      render={(text, record) => (
                        <span style={!record.type ? {} : { fontWeight: 900 }}>
                          {record.qty}
                        </span>
                      )}
                      dataIndex="qty"
                      key="qty"
                    />
                    {window.isPhone ? null : (
                      <Column
                        title="Voids"
                        render={(text, record) => (
                          <span style={!record.type ? {} : { fontWeight: 900 }}>
                            {record.voidCount
                              ? 'R ' + record.voidCount.toFixed('2')
                              : 'R 0.00'}
                          </span>
                        )}
                      />
                    )}
                    <Column
                      title="Net Sale"
                      render={(text, record) => (
                        <span style={!record.type ? {} : { fontWeight: 900 }}>
                          {'R ' + record.price.toFixed('2')}
                        </span>
                      )}
                    />{' '}
                    {window.isPhone ? null : (
                      <Column
                        title="Cost of Goods"
                        render={(text, record) => (
                          <span style={!record.type ? {} : { fontWeight: 900 }}>
                            {' '}
                            {'R ' +
                              (record.cost
                                ? parseFloat(record.cost)
                                : 0
                              ).toFixed('2')}
                          </span>
                        )}
                      />
                    )}
                    {window.isPhone ? null : (
                      <Column
                        title="Profit"
                        render={(text, record) => (
                          <span style={!record.type ? {} : { fontWeight: 900 }}>
                            {' '}
                            {record.type
                              ? 'R ' +
                                (
                                  record.price -
                                  (record.cost ? parseFloat(record.cost) : 0)
                                ).toFixed('2')
                              : ''}
                          </span>
                        )}
                      />
                    )}
                  </StyledTable>
                </Row>
              </GridContainer>
              {/*<GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Discount"
                    id="discount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.sales.discount
                        ? 'R ' + this.state.sales.discount.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Tip"
                    id="tip"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.sales.tip
                        ? 'R ' + this.state.sales.tip.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Cash Total"
                    id="cash"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.sales.cash
                        ? 'R ' + this.state.sales.cash.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Card Total"
                    id="card"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.sales.card
                        ? 'R ' + this.state.sales.card.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                 <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Yoco Total"
                    id="yoco"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.sales.yoco
                        ? 'R ' + this.state.sales.yoco.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Total"
                    id="total"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.sales.total
                        ? 'R ' + this.state.sales.total.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                  </GridContainer> */}
            </CardBody>
          </Card>
        </div>
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar
});

const mapDispatchToProps = {
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  getSalesReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Reports));
