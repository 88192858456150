import moment from 'moment';
const salesreport = (items, sales, image, startDate, endDate) => {
  let tableBody = [];
  tableBody.push([{text: 'Product Name', color: "white", bold: true}, 
  {text: 'Category', color: "white", bold: true}, 
  {text: 'Items Sold', color: "white", bold: true}, 
  {text: 'Voids', color: "white", bold: true}, 
  {text: 'Net Sale', color: "white", bold: true}, 
  {text: 'Cost of Goods', color: "white", bold: true},
  {text: 'Profit', color: "white", bold: true}])
  items.forEach(record => {
    let lineItem = [];
    if (!record.type){
      lineItem.push({text: record.name, marginLeft: 10}) 
      lineItem.push({text: record.type ? record.type : 'Modifier'}) 
      lineItem.push({text: record.qty}) 
      lineItem.push({text: record.voidCount
        ? 'R ' + record.voidCount.toFixed('2')
        : 'R 0.00'}) 
        lineItem.push({text: 'R ' + record.price.toFixed('2')}) 
        lineItem.push({text: 'R ' +
        (record.cost ? parseFloat(record.cost) : 0).toFixed(
          '2'
        )}) 
        lineItem.push({text: record.type ? 'R ' + (record.price - (record.cost ? parseFloat(record.cost) : 0)).toFixed('2')
        : ''}) 
    } else {
      lineItem.push({text: record.name, bold:true})
      lineItem.push({text: record.type ? record.type : 'Modifier', bold:true})
      lineItem.push({text: record.qty, bold:true})
      lineItem.push({text: record.voidCount
        ? 'R ' + record.voidCount.toFixed('2')
        : 'R 0.00', bold:true})
        lineItem.push({text: 'R ' + record.price.toFixed('2'), bold:true})
        lineItem.push({text: 'R ' +
        (record.cost ? parseFloat(record.cost) : 0).toFixed(
          '2'
        ), bold:true})
        lineItem.push({text: record.type ? 'R ' + (record.price - (record.cost ? parseFloat(record.cost) : 0)).toFixed('2')
        : '', bold:true})
    }
    tableBody.push(lineItem)
  });
  return {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    content: [
      {
        text: "Sales Summary ",
        style: "header",
      },
      {
        text: moment(startDate , "YYYYMMDD").format("YYYY/MM/DD") + " - " + moment(endDate , "YYYYMMDD").format("YYYY/MM/DD"),
        style: "subheader",
      },
      "\n\n",
      {
        columns: [
          {
            width: "20%",
            text: [
              { text: "Gross Sales", bold: true, alignment:"center" },
              "\t\n",
              { text: sales.total
                ? "R " + sales.total.toFixed('2')
                : "R " + '0.00', color: "grey", alignment:"center" },
            ],
          },
          {
            width: "20%",
            text: [
              { text: "Discount", bold: true, alignment:"center" },
              "\t\n",
              { text: sales.discount
                ? "R " + sales.discount.toFixed('2')
                : "R " + '0.00', color: "grey", alignment:"center" },
            ],
          },
          {
            width: "20%",
            text: [
              { text: "Refund", bold: true, alignment:"center" },
              "\t\n",
              { text: sales.refund
                ? "R " + sales.refund.toFixed('2')
                : "R " + '0.00', color: "grey", alignment:"center" },
            ],
          },
          {
            width: "20%",
            text: [
              { text: "Net Sales", bold: true, alignment:"center" },
              "\t\n",
              { text: sales.total
                ? "R " + (
                  sales.total -
                  (sales.discount || 0)
                ).toFixed('2')
                : "R " + '0.00', color: "grey", alignment:"center" },
            ],
          },
          {
            width: "20%",
            text: [
              { text: "Gross Profit", bold: true, alignment:"center" },
              "\t\n",
              { text: sales.total
                ? "R " + (
                  sales.total -
                  (sales.cost || 0) -
                  (sales.discount || 0)
                ).toFixed('2')
                : "R " + '0.00', color: "grey", alignment:"center" },
            ],
          },
        ],
      },
      "\n\n",
      {
        image: image,
        width: 760
      },
      "\n\n",
      {
			style: 'tableExample',
			table: {
			    widths: ['auto', 'auto', '*', '*', '*', '*', '*'],
			    heights: [20],
				headerRows: 1,
				body: tableBody
			},
			layout: {
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 1 : 0;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 1 : 0;
				},
				// paddingTop: function(i, node) { 
				//     return (i === 0) ? 8 : 2; 
				// },
				fillColor: function (rowIndex, node, columnIndex) { 
				    return (rowIndex === 0) ? "#132440" : null;
				}
			}
		},
      
    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment:"center"
      },

      subheader: {
        fontSize: 14,
        bold: true,
        alignment:"center"
      },
    },
  }
};
export default salesreport
