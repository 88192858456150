
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Col,
  DatePicker,
  Icon,
  Input,
  Popover,
  Row,
  Spin,
  Table,
  Card,
  Radio,
  Empty,
  ConfigProvider,
  Button,
  Select,
  Statistic,
  Upload,
  Checkbox,
  Tooltip,
  Popconfirm
} from 'antd';
import CustomAddSelect from 'src/components/CustomAddSelect/CustomAddSelect.jsx';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import styled from 'styled-components';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  getEcomCustomers,
  addEcomOrder,
  updateOrder,
  createEcomInvoice,
  createEcomShipment,
  getOrders,
  getEcomProducts,
  updatePo,
  getEcomOrderReport,
  resendPurchaseOrder,
  sendProofOfPayment,
  convertPOToDraft
} from 'src/redux/actions/backend';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import convert from 'convert-units';
import { formaterCheck } from 'src/utils/formater.js';

const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const StyledStatistic = styled(Statistic)`
  .ant-statistic-title {
    position: relative;
    top: -10px;
    box-shadow: 0 6px 4px -2px rgb(220, 225, 238);
    margin-bottom:-0.7em;
    padding-bottom:1em;
    border-bottom:5px solid grey;
    color: black;
    font-size: 19px;
    font-weight:600;
  }
`;
const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(0, 204, 112, 0.898);
   
  }
 
`;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  tableScroll: {
    width: '100%',
    height: '55vh',
    overflow: 'auto'
  }
});


const dimTypesFormat = {
  'l': ' L',
  'ml': ' ml',
  'kg': ' Kg',
  'g': ' g',
  'm': ' m',
  'cm': ' cm',
  'mm': ' mm',
  'unit': ' Units'
};

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}
class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      _id: '',
      name: '',
      username: '',
      customer: '',
      customerName: '',
      email: '',
      ref: '',
      note: '',
      orderType:"",
      total: 0,
      orders: [],
      suppliers: [],
      supplierStock: [],
      searchStock: [],
      orderItems: [],
      types: [],
      items: [],
      stockitems: {},
      stock: {},
      supStock: {},
      startDate: moment().format(dateFormatSearch),
      shippingDate: moment().format(dateFormatSearch),
      reportStartDate: moment()
        .subtract(30, 'd')
        .format(dateFormatSearch),
      reportEndDate: moment().format(dateFormatSearch),
      pickerStart: moment(),
      pickerExp: moment(),
      loading: true,
      main: true,
      switch: false,
      userlisthead: 'Add Order',
      userlistsub: 'Add a New Order',
      userlistbutton: 'ADD PURCHASE ORDER',
      alert: false,
      msg: [],
      head: '',
      activeFilter: true,
      activeFilterStatus: [''],
      activeFilterQty: false,
      itemFilters: [],
      filteredInfo: {},
      report: {
        totalAmount: "0",
        totalCount: "0",
        pendingAmount: "0",
        pendingCount: "0",
        completeAmount: "0",
        completeCount: "0"
      },
    };
  }
  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb)
    this.props.setBreadCrumbs(breadCrumbs)
  }
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop()
    this.props.setBreadCrumbs(breadCrumbs)
  }

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false)
      this.props.resetActionBreadCrumb()
      this.handleRemoveBreadCrumb()
    }
  }

  componentDidMount() {
    this.getOrderItems();
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    this.setState({ isScrolling: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 2000);
  };
  getOrderItems = msg => {
    const token = this.props.local.user.token;
    this.getEcomOrderReport()
    this.props
      .getOrders(token, this.state.reportStartDate, this.state.reportEndDate)
      .then(result => {
        this.handleRemoveBreadCrumb()
        this.setState({
          orders: result.data.results,
          name: '',
          username: '',
          supplier: '',
          customerName: '',
          ref: '',
          note: '',
          total: 0,
          orderType: "Deliver",
          startDate: moment().format(dateFormatSearch),
          shippingDate: moment().format(dateFormatSearch),
          pickerStart: moment(),
          pickerExp: moment(),
          supplierStock: [],
          main: true,
          switch: false,
          loading: false,
          activeFilter: true,
          activeFilterQty: false,
          orderId: undefined
        });
        this.props.getEcomCustomers(token)
          .then(result => {
            this.setState({ customers: result.data.results })
          })
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  resendPurchaseOrder = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .resendPurchaseOrder(this.state._id, token)
      .then(res => {
        this.getOrderItems('PO resent to supplier');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  addEcomOrders = username => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.state.items.pop();
    this.props
      .addEcomOrder(this.state, token, username)
      .then(res => {
        this.getOrderItems('Order successfully added');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  convertPOToDaft = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .convertPOToDraft(this.state, token, false)
      .then(res => {
        this.props
          .getEcomProducts(token)
          .then(result => {
            let supplierStock = [];
            if (result.data.results.supstock[this.state.customer]) {
              supplierStock = result.data.results.supstock[this.state.customer].stock || [];
              this.state.stockitems.forEach(stock => {
                result.data.results.stock[this.state.customer][
                  stock.stockid
                ] = stock
              })
            } else {
              supplierStock = this.state.stockitems;
              this.state.stockitems.forEach(stock => {
                result.data.results.stock[this.state.customer][
                  stock.stockid
                ] = stock
              })
            }
            this.setState({
              supplierStock: supplierStock,
              suppliers: result.data.results.customers,
              stock: result.data.results.stock,
              supStock: result.data.results.supstock,
              stockitems: result.data.results.stockitems,
              filteredInfo: {},
              main: false,
              switch: false,
              activeFilter: undefined,
              activeFilterQty: false,
              loading: false,
              pageSize: 25
            });
          })
          .catch(err => {
            this.onError(err);
          });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  updatePo = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .updatePo(this.state, token)
      .then(res => {
        this.getOrderItems('Stock Update Success');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  getEcomProducts = msg => {
    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });
    this.props
      .getEcomProducts(token)
      .then(result => {
        this.handleAddBreadCrumb({ method: "switch", crumb: "Order" });
        this.setState({
          supplier: '',
          customerName: '',
          ref: '',
          note: '',
          startDate: moment().format(dateFormatSearch),
          shippingDate: moment().format(dateFormatSearch),
          pickerStart: moment(),
          pickerExp: moment(),
          supplierStock: [],
          name: result.data.results.name,
          orderType: "Deliver",
          username: '',
          total: 0,
          suppliers: result.data.results.customers,
          stock: result.data.results.stock,
          supStock: result.data.results.supstock,
          stockitems: result.data.results.stockitems,
          filteredInfo: {},
          main: false,
          switch: false,
          activeFilter: undefined,
          activeFilterQty: false,
          loading: false,
          pageSize: 25,
          items: [{ name: undefined }]
        });
        var sup = {};
        sup.total = 0;
        sup.customer = 'none';
        sup.customerName = 'none';
        sup.username = '';
        sup.customerStock = [];

        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  showOrder = r => {
    this.handleAddBreadCrumb({ method: "switch", crumb: "Order" })

    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });

    let tempMail = ""
    if (Array.isArray(r.email)) {
      for (var i = 0; i < r.email.length; i++) {
        tempMail = tempMail + r.email[i] + ";"
      }
      r.email = tempMail
    }
    this.setState({
      stockadjustview: r.stockitems,
      record: r,
      orderId: r._id,
      supplier: r.customer,
      customerName: r.customerName,
      orderType: r.orderType,
      _id: r._id,
      ref: r.ref,
      note: r.note,
      items: r.items,
      invoiced: r.invoiced,
      shipped: r.shipped,
      paid: r.paid,
      startDate: r.startDate,
      shippingDate: r.shippingDate,
      pickerStart: moment(),
      pickerExp: moment(),
      name: r.name,
      email: r.email,
      total: r.total,
      status: r.status,
      stockitems: r.stockitems,
      main: true,
      switch: true,
      loading: false
    });


  };

  switch = () => {
    this.getOrderItems();
  };

  handleChangeDateStart = value => {
    if (value) {
      this.setState({
        startDate: value.format(dateFormatSearch),
        pickerStart: value
      });
    }
  };

  handleChangeDateExp = value => {
    if (value) {
      this.setState({
        shippingDate: value.format(dateFormatSearch),
        pickerExp: value
      });
    }
  };

  handleCheck = () => {
    this.setState({ cooking: !this.state.cooking });
  };

  handleChange = event => {
    if (event.target.name === 'customer') {
      let sup = {}
      sup.total = this.state.total;
      sup.customer = event.target.value;
      sup.customerName = event.currentTarget.textContent;
      const index = this.state.customers.findIndex(customer => customer._id === event.target.value)
      sup.customerUsername = this.state.customers[index].username;
      sup.customerDetails = this.state.customers[index];
      sup.email = this.state.customers[index].username;
      this.setState(sup);
    }
    if (event.target.name === 'orderType') {
      this.setState({orderType:event.target.value});
    }
  };

  tableAmountCheck = (record, e) => {
    var stock = this.state.stock;
    var supplier = this.state.customer;
    stock[this.state.customer][record.stockid].select = e.target.checked;
    var ttotal = 0;
    Object.keys(stock[supplier]).forEach(function (key) {
      if (stock[supplier][key].select) {
        if (stock[supplier][key].total) {
          ttotal = ttotal + stock[supplier][key].total;
        }
      }
    });
    this.setState({ supStock: stock, total: ttotal });
  };

  pressQtyEnter = (record, event) => {
    if (event.target.parentElement.parentElement.parentElement.nextSibling) {
      event.target.parentElement.parentElement.parentElement.nextSibling.childNodes[5].children[0].children[0].focus()
    }
  }

  pressQtyEnter2 = (record, event) => {
    if (event.target.parentElement.parentElement.parentElement.nextSibling) {
      event.target.parentElement.parentElement.parentElement.nextSibling.childNodes[4].children[0].children[0].focus()
    }
  }

  tableAmount = (record, e, dimChange) => {
    let { value, name } = e.target;
    var stock = this.state.stock;
    var supplier = this.state.customer;
    if (value || dimChange) {
      if (dimChange) {
        stock[supplier][record.stockid].amount = '1';
        stock[supplier][record.stockid].cost = '';
        stock[supplier][record.stockid].dimTypeOrder = dimChange;
      } else {
        value = formaterCheck(value || '0');
        if (value >= 0) {
          if (e.target.name === 'qty') {
            stock[supplier][record.stockid].amount = value;
          }
          if (e.target.name === 'cost') {
            if (stock[supplier][record.stockid].costChange > 0) {
              stock[supplier][record.stockid].costChange = value;
            } else {
              stock[supplier][record.stockid].cost = value;
            }
          }
          if (e.target.name === 'tax') {
            stock[supplier][record.stockid].tax = value;
          }
        }
      }
      var costPrice = '0';
      if (stock[supplier][record.stockid].dimTypeOrder === 'packsize') {
        costPrice = this.state.stockitems[record.stockid].packBuyPrice;
        stock[supplier][record.stockid].costChange = false;
      } else if (stock[supplier][record.stockid].dimTypeOrder === 'unit') {
        costPrice = stock[supplier][record.stockid].cost || this.state.stockitems[record.stockid].buyPrice || '0';
        stock[supplier][record.stockid].costChange = false;
      } else if (record.stockSize && record.sizeObject[stock[supplier][record.stockid].dimTypeOrder] && record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].packSize) {
        costPrice = stock[supplier][record.stockid].cost || this.state.stockitems[record.stockid].buyPrice || '0';
        stock[supplier][record.stockid].costChange = parseFloat(this.state.stockitems[record.stockid].packBuyPrice) * (record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].qty / this.state.stockitems[record.stockid].packSize);
      } else {
        if (stock[supplier][record.stockid].dimTypeOrder !== this.state.stockitems[record.stockid].dimType && dimTypesFormat[stock[supplier][record.stockid].dimTypeOrder]) {
          var fromMetric = stock[supplier][record.stockid].dimTypeOrder || this.state.stockitems[record.stockid].dimType;
          var toMetric = this.state.stockitems[record.stockid].dimType;
          var metricCost = convert(1)
            .from(fromMetric)
            .to(toMetric);
          costPrice = stock[supplier][record.stockid].cost || this.state.stockitems[record.stockid].buyPrice || '0';
          stock[supplier][record.stockid].costChange = parseFloat(costPrice) * parseFloat(metricCost);
        } else {
          costPrice = stock[supplier][record.stockid].cost || this.state.stockitems[record.stockid].buyPrice || '0';
          stock[supplier][record.stockid].costChange = false;
        }
      }

      stock[supplier][record.stockid].cost = costPrice;

      var metricAmount = stock[supplier][record.stockid].amount;
      if (record.stockSize && record.sizeObject[stock[supplier][record.stockid].dimTypeOrder] && record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].packSize) {
        metricAmount = record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].qty * (stock[supplier][record.stockid].amount || 1);
      } else if (stock[supplier][record.stockid].dimension !== 'unit') {
        var qty = stock[supplier][record.stockid].amount || 1;
        var fromMetric = stock[supplier][record.stockid].dimTypeOrder !== 'packsize' ? stock[supplier][record.stockid].dimTypeOrder : this.state.stockitems[record.stockid].dimType;
        var toMetric = this.state.stockitems[record.stockid].dimType;
        if (record.stockSize && record.sizeObject[stock[supplier][record.stockid].dimTypeOrder]) {
          qty = record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].qty * metricAmount;
          fromMetric = record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].dimType;
        }
        metricAmount = convert(qty)
          .from(fromMetric)
          .to(toMetric);
      } else {
        if (record.stockSize && record.sizeObject[stock[supplier][record.stockid].dimTypeOrder]) {
          metricAmount = record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].qty * metricAmount;
        }
      }

      var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
      stock[supplier][record.stockid].total = totalCost;

      if (stock[supplier][record.stockid].total > 0 || stock[supplier][record.stockid].amount !== "") {
        stock[supplier][record.stockid].select = true;
      } else {
        stock[supplier][record.stockid].select = false;
      }
      var ttotal = 0;
      Object.keys(stock[supplier]).forEach(function (key) {
        if (stock[supplier][key].select) {
          if (stock[supplier][key].total) {
            ttotal = ttotal + stock[supplier][key].total;
          }
        }
      });

      this.setState({ supStock: stock, total: ttotal });
    } else {
      if (e.target.name === 'qty') {
        stock[supplier][record.stockid].amount = '';
      }
      if (e.target.name === 'cost') {
        stock[supplier][record.stockid].cost = '';
      }
      if (e.target.name === 'tax') {
        stock[supplier][record.stockid].tax = '15';
      }
      var cost = stock[supplier][record.stockid].cost || '0';
      var amount = stock[supplier][record.stockid].amount || '0';
      var tax = stock[supplier][record.stockid].tax || '15';
      var total = parseFloat(cost) * parseFloat(amount);
      var taxt = (total * parseFloat(tax)) / 100;
      stock[supplier][record.stockid].total = total;
      if (stock[supplier][record.stockid].total > 0 || stock[supplier][record.stockid].amount !== "") {
        stock[supplier][record.stockid].select = true;
      } else {
        stock[supplier][record.stockid].select = false;
      }
      var ttotal = 0;
      Object.keys(stock[supplier]).forEach(function (key) {
        if (stock[supplier][key].select) {
          if (stock[supplier][key].total) {
            ttotal = ttotal + stock[supplier][key].total;
          }
        }
      });

      this.setState({ supStock: stock, total: ttotal });
    }
  };

  tableAmountPo = (record, e) => {
    var stock = this.state.stockadjustview;
    const { value } = e.target;
    if (value) {
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        for (var i = 0; i < stock.length; i++) {
          if (stock[i].stockid === record.stockid) {
            if (
              parseFloat(value) >
              (stock[i].canSupply ? parseFloat(stock[i].canSupply) : 0)
            ) {
              break;
            } else {
              stock[i].received = parseFloat(value);
              stock[i].torec =
                (stock[i].canSupply ? parseFloat(stock[i].canSupply) : 0) -
                stock[i].received;
              break;
            }
          }
        }
        this.setState({ stockadjustview: stock });
      }
    } else {
      for (var i = 0; i < stock.length; i++) {
        if (stock[i].stockid === record.stockid) {
          stock[i].received = 0;
          stock[i].torec = stock[i].Qty ? parseFloat(stock[i].Qty) : 0;
          break;
        }
      }
      this.setState({ stockadjustview: stock });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Order Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  submitForm = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    if (oEvent.currentTarget.innerText === 'ADD PURCHASE ORDER') {
      this.props
        .addMenuItem(this.state, token)
        .then(res => {
          this.setState({
            main: true,
            loading: false
          });
          this.getOrderItems('Order Creation Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    } else if (oEvent.currentTarget.innerText === 'EDIT PURCHASE ORDER') {
      this.props
        .editMenuItem(this.state, token)
        .then(res => {
          this.setState({
            main: true,
            loading: false
          });
          this.getOrderItems('Order Update Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  onChangeEvt = e => {
    if (e.target.id === 'email') {
      this.setState({ email: e.currentTarget.value });
    }
    if (e.target.id === 'description') {
      this.setState({ note: e.currentTarget.value });
    }
    if (e.target.id === 'reference') {
      this.setState({ ref: e.currentTarget.value });
    }
  };

  handleEditMenuItem = oMenu => {
    this.props.handleOpenAddMenuItems();
    this.setState({
      main: false,
      filteredInfo: {},
      userlisthead: 'Edit Order',
      userlistsub: 'Edit a Order',
      userlistbutton: 'EDIT PURCHASE ORDER',
      pageSize: 25
    });
  };

  handleFormatListQty = () => {
    this.setState({ activeFilterQty: true, activeFilter: true });
  };
  handleFormatList = format => {
    this.setState({ activeFilter: format, activeFilterQty: false });
  };

  handleFormatListStatus = format => {
    this.setState({ activeFilterStatus: format });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSort = (pagination, filters, sorter) => {
    this.setState(prevState => ({
      ...prevState,
      filteredInfo: filters,
      sortedInfo: sorter
    }));
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnFilterProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <Select
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Filter ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e ? [e] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {this.state.types.map((items, key) => {
              return (
                <Option key={key} value={items.value || items._id}>
                  {items.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >Filter</Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >Reset</Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (!visible) {
        setTimeout(() => this.searchInput.rcSelect.setOpenState(false))
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: "3em",
          marginBottom: "0.5em",
          height: 90,
        }} />

      <button
        style={{

          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',
          width: '12em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',
          borderRadius: '3em',
          marginTop: "1em",
          marginBottom: "1.5em",
        }}
        onClick={() => this.getEcomProducts()}
        color="primary"
      >
        {'Add Order'}
      </button>
    </div>
  );
  getEcomOrderReport = (start, end) => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    this.props
      .getEcomOrderReport(token, this.state.reportStartDate, this.state.reportEndDate)
      .then(result => {
        if (result.data.results === 'NoBills') {
          this.setState({
            report: {
              totalAmount: "0",
              totalCount: "0",
              pendingAmount: "0",
              pendingCount: "0",
              completeAmount: "0",
              completeCount: "0"
            },
            loading: false
          });
        } else {
          this.setState({
            report: result.data.results,
            loading: false
          });
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        reportStartDate: value[0].format(dateFormatSearch),
        reportEndDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1]
      });
    }
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'yester') {
        this.setState({
          reportStartDate: moment().subtract(1, 'days').format(dateFormatSearch),
          reportEndDate: moment().subtract(1, 'days').format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getOrderItems()
        });
      } else if (value === 'today') {
        this.setState({
          reportStartDate: moment().format(dateFormatSearch),
          reportEndDate: moment().format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getOrderItems()
        });
      } else if (value === 'last7') {
        this.setState({
          reportStartDate: moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          reportEndDate: moment().format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getOrderItems()
        });
      } else if (value === 'last30') {
        this.setState({
          reportStartDate: moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          reportEndDate: moment().format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getOrderItems()
        });
      } else if (value === 'thism') {
        this.setState({
          reportStartDate: moment()
            .startOf('month')
            .format(dateFormatSearch),
          reportEndDate: moment().format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getOrderItems()
        });
      } else if (value === 'lastm') {
        this.setState({
          reportStartDate: moment()
            .subtract(1, 'months')
            .startOf('month')
            .format(dateFormatSearch),
          reportEndDate: moment()
            .subtract(1, 'months')
            .endOf('month')
            .format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getOrderItems()
        });
      } else if (value === 'custom') {
        this.setState({
          datePicker: true
        });
      }
    }
  };

  sendProofOfPayment = ({ onSuccess, file }) => {
    const reader = new FileReader();
    const token = this.props.local.user.token;
    this.setState({ loading: true })
    reader.addEventListener('load', event => {
      let b64file = event.target.result;
      this.props
        .sendProofOfPayment(this.state._id, b64file.split("base64,")[1], token)
        .then(res => {
          this.setState({
            main: true,
            loading: false
          });
          this.getOrderItems('Successfully sent proof of payment to supplier');
        })
        .catch(err => {
          this.onError(err);
        });
    });
    reader.readAsDataURL(file);
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState, currentPage: current, pageSize: pageSize
    }));
  };
  createInvoice = record => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    let data = { _id: record._id, invoiced: true, moddate: moment().format("YYYYMMDD") }
    record.invoiced = true;
    this.props
      .updateOrder(data, token)
      .then(res => {
        this.props
          .createEcomInvoice(record, token)
          .then(res => {
            this.getOrderItems('Successfully created invoice');
          })
          .catch(err => {
            this.onError(err);
          });
      })
      .catch(err => {
        this.onError(err);
      });
  }

  createShipment = record => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    let data = { _id: record._id, shipped: true, moddate: moment().format("YYYYMMDD"), status: "Complete" }
    record.shipped = true;
    record.waybill = this.state.waybill;
    this.setState({ waybill: undefined });
    this.props
      .updateOrder(data, token)
      .then(res => {
        this.props
          .createEcomShipment(record, token)
          .then(res => {
            this.getOrderItems('Successfully created shipment');
          })
          .catch(err => {
            this.onError(err);
          });
      })
      .catch(err => {
        this.onError(err);
      });
  }

  readyToCollect = record => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    let data = { _id: record._id, orderType: record.orderType, shipped: true, moddate: moment().format("YYYYMMDD"), status: "Complete" }
    record.shipped = true;
    this.setState({ waybill: undefined });
    this.props
      .updateOrder(data, token)
      .then(res => {
        this.getOrderItems('Order ready for collection');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  updateOrder = (order, type) => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    let data = { _id: order._id, moddate: moment().format("YYYYMMDD") }
    if (type === "Void") {
      data.status = "Void"
    } else if (type === "Paid") {
      data.paid = true;
    } else if (type === "Shipped") {
      data.shipped = true
      data.status = "Complete"
    }
    this.props
      .updateOrder(data, token)
      .then(res => {
        this.getOrderItems('Successfully updated order');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  searchItem = (e, record) => {
    let searchStock;
    let find = this.state.stock.filter(item => item.name.toLowerCase().search(e.toLowerCase()) > -1 || item.code.toLowerCase().search(e.toLowerCase()) > -1)
    Array.isArray(find) ? searchStock = find : searchStock = [find];
    let items = this.state.items;
    items[items.length - 1].name = e;
    this.setState(prevState => ({
      ...prevState, searchStock: searchStock, items: items
    }));
  }
  selectItem = (e, record) => {
    let selectedItem = this.state.stock.find(item => item._id === record.key);
    let items = this.state.items;
    selectedItem.total = 0
    items[items.length - 1] = selectedItem;
    items.push({ name: undefined })
    this.setState(prevState => ({
      ...prevState, items: items, searchStock: []
    }));
  }
  deleteItem = (index) => {
    let items = this.state.items;
    items.splice(index, 1)
    let total = 0;
    items.forEach(item => {
      total = item.total ? total + item.total : total + 0
    })
    this.setState(prevState => ({
      ...prevState, items: items, total: total
    }));
  }

  changeItemAmount = (e, record, index) => {
    let items = this.state.items;
    items[index].amount = parseInt(e.target.value);
    items[index].total = parseInt(e.target.value) * items[index].sellPrice;
    let total = 0;
    items.forEach(item => {
      total = item.total ? total + item.total : total + 0
    })
    this.setState(prevState => ({
      ...prevState, items: items, total: total
    }));
  }

  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onChangeEvt: this.onChangeEvt,
      onHelpMarker: this.onHelpMarker
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',
            color: 'rgb(15, 135, 123)',
            height: "2.5em",
            width: "6em",
            margin: "0.3em",
            borderRadius: '3em',
            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',
            color: 'rgb(15, 135, 123)',
            height: "2.5em",
            width: "6em",
            margin: "0.3em",
            borderRadius: '3em',
            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: "2.5em",
            width: "6.5em",
            margin: "0.3em",
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['All'])}
        >
          All
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: "2.5em",
            width: "6.5em",
            margin: "0.3em",
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['Invoiced'])}
        >
          Invoiced
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: "2.5em",
            width: "6.5em",
            margin: "0.3em",
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['Paid'])}
        >
          Paid
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: "2.5em",
            width: "6em",
            margin: "0.3em",
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['Shipped'])}
        >
          Shipped
        </Button>
      </div>
    );

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <div />
        {this.state.main ? (
          <div>
            {!this.state.switch ? (
              <div>
                <div>
                  <Row>
                    <div
                      style={{
                        float: 'left'
                      }}
                    >
                      {this.state.datePicker ?
                        <div>
                          <RangePicker allowClear={false}
                            className={this.props.classes.datePicker}
                            onChange={this.handleChangeDate}
                            value={[this.state.pickerStart, this.state.pickerEnd]}
                            format={dateFormat}
                          />
                        </div>
                        : null}

                      {this.state.datePicker ?
                        <Button
                          style={{
                            backgroundColor: '#00CC70',
                            color: 'white',
                            top: '-0.05em',
                            height: '40px',
                            width: '120px',
                            border: '1px solid #00CC70',
                            borderRadius: '3em',
                            fontSize: '12px',
                            paddingBottom: "0.1em",
                            fontWeight: '600'
                          }}
                          onClick={() => this.getOrderItems()}
                        >
                          Get Report
              </Button>
                        : null}
                    </div>
                  </Row>

                  <Row>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-today-sales"
                          title={
                            <span>Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{
                                marginTop: "-0.1em",
                                backgroundColor: "#00CC70",
                                float: "right",
                                paddingRight: "0.77em",
                                paddingTop: "0.2em",
                                borderRadius: "3em",
                                paddingBottom: "0.4em",
                                paddingLeft: "0.77em"
                              }}><span style={{
                                color: 'white',
                              }}>{this.state.report.totalCount > 0 ? this.state.report.totalCount : "0"}</span></div></span>}
                          value={
                            this.state.report.totalAmount
                          }
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: "nowrap",
                            bottom: -10,
                            fontWeight: 600,
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-profit"
                          title={
                            <span>Pending &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{
                                marginTop: "-0.1em",
                                backgroundColor: "#1FA9F6",
                                float: "right",
                                paddingRight: "0.77em",
                                paddingTop: "0.2em",
                                borderRadius: "3em",
                                paddingBottom: "0.4em",
                                paddingLeft: "0.77em"
                              }}><span style={{
                                color: 'white',
                              }}>{this.state.report.pendingCount > 0 ? this.state.report.pendingCount : "0"}</span></div></span>}
                          value={
                            this.state.report.pendingAmount
                          }
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: "nowrap",
                            bottom: -10,
                            fontWeight: 600,
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-open-bills"
                          title={
                            <span>Completed &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{
                                marginTop: "-0.1em",
                                backgroundColor: "#1D51A7",
                                float: "right",
                                paddingRight: "0.77em",
                                paddingTop: "0.2em",
                                borderRadius: "3em",
                                paddingBottom: "0.4em",
                                paddingLeft: "0.77em"
                              }}><span style={{
                                color: 'white',
                              }}>{this.state.report.completeCount > 0 ? this.state.report.completeCount : "0"}</span></div></span>}
                          value={
                            this.state.report.completeAmount
                          }
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: "nowrap",
                            bottom: -10,
                            fontWeight: 600,
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>

                  </Row>
                </div>
                <Button
                  style={{
                    position: 'fixed',
                    right: '-0.6em',
                    bottom: '5em',
                    background: 'rgb(0, 204, 112)',
                    border: '1px solid transparent',
                    color: 'white',
                    padding: '0em',
                    width: '3.5em',
                    height: '3.5em',
                    borderRadius: '5em 0em 0em 5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                  onClick={() => this.getEcomProducts()}
                  color="primary"
                >
                  <Icon type="plus"
                    style={{
                      paddingTop: "0.2em",
                      fontSize: '1.3em',

                    }}
                  >

                  </Icon >
                </Button>

                {/*} <Button
                  style={{
                    position: 'fixed',
                    right: '0.5em',
                    bottom: '0.5em',
                    background: '#132440',
                    border: '1px solid transparent',
                    color: 'white',
                    padding: '0em',
                    width: '3.5em',
                    height: '3.5em',
                    borderRadius: '5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                  onClick={() => this.getEcomProducts()}
                  color="primary"
                >
                  <Icon type="plus"
                    style={{
                      position: 'absolute',
                      paddingLeft: 3
                    }}
                  >
                  </Icon >
                  </Button> */}
                {this.state.orders.length > 0 ? <Row style={{ float: "right", marginRight: "17.5em" }}>
                  <button
                    style={{
                      marginTop: '1em',
                      backgroundColor: '#00CC70',
                      border: '0px solid rgb(30, 161, 169)',
                      color: 'white',

                      width: '12em',
                      height: '2.7em',
                      fontSize: 16,
                      fontFamily: '',

                      borderRadius: '3em',
                      zIndex: 99,
                      position: 'absolute'
                    }}
                    onClick={() => this.getEcomProducts()}
                    color="primary"
                  >
                    {'Add Order'}
                  </button>
                </Row> : null}
                <Row>
                  <Select
                    id="filterSelect"
                    style={{
                      width: '150px',
                      paddingRight: '10px',
                      marginTop: "1em",
                      marginLeft: "1em",
                    }}
                    defaultValue="last30"
                    onChange={this.handleChangeSelect}
                  >
                    <Option value="today">Today</Option>
                    <Option value="yester">Yesterday</Option>
                    <Option value="last7">Last 7 Days</Option>
                    <Option value="last30">Last 30 Days</Option>
                    <Option value="thism">This Month</Option>
                    <Option value="lastm">Last Month</Option>
                    <Option value="custom">Custom</Option>
                  </Select>
                  <div
                    style={{
                      float: 'right',
                      marginTop: '1em',
                      marginBottom: '1em'
                    }}
                  >
                    <Popover
                      placement="left"
                      content={EyeContent}
                      title=""
                      trigger="click"
                    >
                      <Button
                        style={{
                          backgroundColor: '#00CC70',
                          border: '0px solid rgb(30, 161, 169)',
                          color: 'white',
                          marginBottom: '-0.5em',
                          height: '3.2em',
                          marginLeft: '-0.27em',
                          borderRadius: '3em',

                          width: '3.2em'
                        }}
                      >
                        <Icon
                          style={{

                          }}
                          type="setting"
                        />
                      </Button>
                    </Popover>
                    <Popover
                      placement="bottomRight"
                      content={GearContent}
                      title=""
                      trigger="click"
                    >
                      <Button
                        style={{
                          display: 'none',
                          backgroundColor: 'white',
                          border: '1px solid rgb(15, 135, 123)',
                          color: 'rgb(15, 135, 123)',
                          marginBottom: '-0.5em',
                          height: '3.5em',
                          marginLeft: '-0.27em',
                          borderRadius: '0.3em',
                          transform: 'scale(0.9)',
                          width: '2em'
                        }}
                      >
                        <Icon
                          style={{

                          }}
                          type="setting"
                        />
                      </Button>
                    </Popover>
                  </div>
                </Row>
                <Row>
                  <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                    <StyledTable
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      dataSource={this.state.orders}
                      pagination={{
                        locale: { items_per_page: " Items" },
                        showSizeChanger: true,
                        onShowSizeChange: this.onShowSizeChange,
                        pageSizeOptions: ['10', '25', '50', '100'],
                        hideOnSinglePage: true,
                        pageSize: this.state.pageSize || 25,
                        itemRender: (current, type, originalElement) => {
                          if (type === 'prev') {
                            return <a>Previous</a>;
                          }
                          if (type === 'next') {
                            return <a>Next</a>;
                          }
                          return originalElement;
                        },
                        showTotal: (total, range) =>
                          `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                        defaultCurrent: 1
                      }}
                      onChange={this.handleSort}
                      onRow={r => ({
                        onClick: () => this.showOrder(r)
                      })}
                    >
                      <Column
                        {...this.getColumnSearchProps('name')}
                        sorter={(a, b) => a.name.localeCompare(b.name)}
                        sortDirections={['descend', 'ascend']}
                        title="Order No."
                        dataIndex="name"
                        key="name"
                      />
                      <Column
                        {...this.getColumnSearchProps('customerUsername')}
                        sorter={(a, b) => a.customerUsername.localeCompare(b.customerUsername)}
                        sortDirections={['descend', 'ascend']}
                        title="Customer"
                        dataIndex="customerUsername"
                        key="customerUsername"
                      />
                      <Column
                        title="Created"
                        sorter={(a, b, sortOrder) => {
                          if (a.startDate && b.startDate) {
                            return a.startDate.localeCompare(b.startDate);
                          }
                          if (a.startDate && !b.startDate) {
                            return sortOrder === 'ascend' ? -1 : 1;
                          }
                          if (b.startDate && !a.startDate) {
                            return sortOrder === 'ascend' ? 1 : -1;
                          }
                          return 0;
                        }}
                        sortDirections={['descend', 'ascend']}
                        render={(text, record) => (
                          <span>
                            {record.startDate
                              ? record.startDate.slice(0, 4) +
                              '/' +
                              record.startDate.slice(4, 6) +
                              '/' +
                              record.startDate.slice(6, 8)
                              : 'No Date'}
                          </span>
                        )}
                        dataIndex="startDate"
                        key="startDate"
                      />
                      <Column
                        title="Shipment Date"
                        sorter={(a, b, sortOrder) => {
                          if (a.shippingDate && b.shippingDate) {
                            return a.shippingDate.localeCompare(b.shippingDate);
                          }
                          if (a.shippingDate && !b.shippingDate) {
                            return sortOrder === 'ascend' ? -1 : 1;
                          }
                          if (b.shippingDate && !a.shippingDate) {
                            return sortOrder === 'ascend' ? 1 : -1;
                          }
                          return 0;
                        }}
                        sortDirections={['descend', 'ascend']}
                        render={(text, record) => (
                          <span>
                            {record.shippingDate
                              ? record.shippingDate.slice(0, 4) +
                              '/' +
                              record.shippingDate.slice(4, 6) +
                              '/' +
                              record.shippingDate.slice(6, 8)
                              : 'No Date'}
                          </span>
                        )}
                        dataIndex="shippingDate"
                        key="shippingDate"
                      />
                      <Column
                        title="Last Updated"
                        sorter={(a, b, sortOrder) => {
                          if (a.moddate && b.moddate) {
                            return a.moddate.localeCompare(b.moddate);
                          }
                          if (a.moddate && !b.moddate) {
                            return sortOrder === 'ascend' ? -1 : 1;
                          }
                          if (b.moddate && !a.moddate) {
                            return sortOrder === 'ascend' ? 1 : -1;
                          }
                          return 0;
                        }}
                        sortDirections={['descend', 'ascend']}
                        render={(text, record) => (
                          <span>
                            {record.moddate
                              ? record.moddate.slice(0, 4) +
                              '/' +
                              record.moddate.slice(4, 6) +
                              '/' +
                              record.moddate.slice(6, 8)
                              : 'No Date'}
                          </span>
                        )}
                        dataIndex="moddate"
                        key="moddate"
                      />
                      <Column
                        {...this.getColumnSearchProps('orderType')}
                        title="Type"
                        dataIndex="orderType"
                        key="orderType"
                      />
                      { this.props.local.user.orgid === "arôfc666498" ? <Column
                        title="Security Fee"
                        dataIndex="securityOption"
                        key="securityOption"
                        render={(text, record) => {
                          return record.securityOption ? <span>Yes</span> : <span>No</span>
                        }}
                      /> : null}
                      <Column
                        title="Status"
                        align="left"
                        render={(text, record) => {
                          return record.status === "Complete" ? <div style={{ whiteSpace: "nowrap", border: '1px solid rgb(0, 204, 112)', color: "rgb(0, 204, 112)", padding: 6, borderRadius: "3em", width: "6.7em", textAlign: "center", marginLeft: "-1em" }}>
                            <span>{'Completed'}</span>
                          </div> : <div style={{ whiteSpace: "nowrap", border: '1px solid rgb(31, 169, 246)', color: "rgb(31, 169, 246)", padding: 6, borderRadius: "3em", width: "6.7em", textAlign: "center", marginLeft: "-1em" }}>
                            <span>{'Active'}</span>
                          </div>
                        }}
                      />
                      <Column
                        title="Total"
                        render={(text, record) => (
                          <span>
                            {'R' + parseFloat(record.total).toFixed(2)}
                          </span>
                        )}
                        dataIndex="total"
                        key="total"
                      />
                      <Column
                        title="Invoiced"
                        align="center"
                        dataIndex="invoiced"
                        key="invoiced"
                        render={(text, record) => {
                          return record.invoiced ? <div style={{ margin: "auto", width: 20, height: 20, borderRadius: "50%", background: "rgba(0, 204, 112, 0.898)", border: "1px solid rgba(0, 204, 112, 0.898)" }}></div>
                            : <div style={{ margin: "auto", width: 20, height: 20, borderRadius: "50%", border: "1px solid rgb(31, 169, 246)" }}></div>
                        }}
                      />
                      <Column
                        title="Paid"
                        align="center"
                        dataIndex="paid"
                        key="paid"
                        render={(text, record) => {
                          return record.paid ? <div style={{ margin: "auto", width: 20, height: 20, borderRadius: "50%", background: "rgba(0, 204, 112, 0.898)", border: "1px solid rgba(0, 204, 112, 0.898)" }}></div>
                            : <div style={{ margin: "auto", width: 20, height: 20, borderRadius: "50%", border: "1px solid rgb(31, 169, 246)" }}></div>
                        }}
                      />
                      <Column
                        title="Shipped / Collection Ready"
                        align="center"
                        dataIndex="shipped"
                        key="shipped"
                        render={(text, record) => {
                          return record.shipped ? <div style={{ margin: "auto", width: 20, height: 20, borderRadius: "50%", background: "rgba(0, 204, 112, 0.898)", border: "1px solid rgba(0, 204, 112, 0.898)" }}></div>
                            : <div style={{ margin: "auto", width: 20, height: 20, borderRadius: "50%", border: "1px solid rgb(31, 169, 246)" }}></div>
                        }}
                      />
                      <Column
                        title="Action"
                        align="center"
                        render={(text, record, key) => (
                          <div style={{
                            whiteSpace: "nowrap"
                          }}>
                            {!record.invoiced ? <div><button
                              style={{ margin: "auto 10px auto auto", cursor: "pointer", backgroundColor: '#00CC70', whiteSpace: "nowrap", border: '1px solid rgb(0, 204, 112)', color: "white", padding: 6, borderRadius: "3em", width: "8em", textAlign: "center" }}
                              onClick={(e) => {
                                e.stopPropagation()
                                this.createInvoice(record)
                              }}
                              color="primary"
                            >
                              Create Invoice
                            </button><button
                                style={{ margin: "auto", cursor: "pointer", backgroundColor: '#00CC70', whiteSpace: "nowrap", border: '1px solid rgb(0, 204, 112)', color: "white", padding: 6, borderRadius: "3em", width: "8em", textAlign: "center" }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  this.updateOrder(record, "Void")
                                }}
                                color="primary"
                              >
                                Void
                            </button></div> : null}
                            {record.invoiced && !record.shipped && !record.paid ? <button
                              style={{ margin: "auto", cursor: "pointer", backgroundColor: '#00CC70', whiteSpace: "nowrap", border: '1px solid rgb(0, 204, 112)', color: "white", padding: 6, borderRadius: "3em", width: "8em", textAlign: "center" }}
                              onClick={(e) => {
                                e.stopPropagation()
                                this.updateOrder(record, "Paid")
                              }}
                              color="primary"
                            >
                              Set Paid
                            </button> : null}
                            {record.paid && !record.shipped ? record.orderType === "Deliver" ? <Popconfirm
                              title={<CustomInput
                                labelText="Waybill / Tracking number"
                                id="waybill"
                                formControlProps={{
                                  fullWidth: true,
                                  style: { margin: 0, padding: 0 }
                                }}
                                inputProps={{
                                  onChange: e => this.setState({ waybill: e.currentTarget.value }),
                                  onClick: e => e.stopPropagation(),
                                  value: this.state.waybill
                                }}
                              />}
                              onConfirm={(e) => {
                                e.stopPropagation()
                                this.createShipment(record)
                              }}
                              onCancel={(e) => {
                                e.stopPropagation()
                              }}
                              okText="Create Shipment"
                              icon={null}
                            >
                              <button
                                style={{ margin: "auto", cursor: "pointer", backgroundColor: '#00CC70', whiteSpace: "nowrap", border: '1px solid rgb(0, 204, 112)', color: "white", padding: 6, borderRadius: "3em", textAlign: "center" }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                                color="primary"
                              >
                                Create Shipment
                            </button>
                            </Popconfirm> : <button
                              style={{ margin: "auto", cursor: "pointer", backgroundColor: '#00CC70', whiteSpace: "nowrap", border: '1px solid rgb(0, 204, 112)', color: "white", padding: 6, borderRadius: "3em", textAlign: "center" }}
                              onClick={(e) => {
                                e.stopPropagation()
                                this.readyToCollect(record)
                              }}
                              color="primary"
                            >
                              Ready to Collect
                            </button> : null}
                          </div>
                        )}
                      />
                    </StyledTable>
                  </ConfigProvider>
                </Row>
              </div>
            ) : (
              <div style={{ paddingBottom: "5em" }}>
                <Row>
                  <h4 className={this.props.classes.cardTitleWhite}>
                    {this.state.name}
                  </h4>
                </Row>
                <Row>
                  <Col span={6}>
                    <CustomInput
                      labelText="Customer"
                      id="supplier"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.handleChange(e),
                        onClick: () => this.onHelpMarker('stockitemtype'),
                        value: this.state.customerName
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CustomInput
                      labelText="Issued on"
                      id="Issued"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.startDate
                          ? this.state.startDate.slice(0, 4) +
                          '-' +
                          this.state.startDate.slice(4, 6) +
                          '-' +
                          this.state.startDate.slice(6, 8) +
                          ' '
                          : 'N/A'
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={6}>
                    <CustomInput
                      labelText="Shipment Date"
                      id="Shipment Date"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.shippingDate
                          ? this.state.shippingDate.slice(0, 4) +
                          '-' +
                          this.state.shippingDate.slice(4, 6) +
                          '-' +
                          this.state.shippingDate.slice(6, 8) +
                          ' '
                          : 'N/A'
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CustomInput
                      labelText="Reference"
                      id="reference"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.ref
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={6}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.email
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={6}>
                    <CustomInput
                      labelText="Order Note"
                      id="description"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.note
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ top: '5px' }}>
                    <h2>{'TOTAL: R' + this.state.total.toFixed('2')}</h2>
                  </Col>
                </Row>

                <Row>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.items}
                    pagination={{
                      locale: { items_per_page: " Items" },
                      showSizeChanger: true,
                      onShowSizeChange: this.onShowSizeChange,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      hideOnSinglePage: true,
                      pageSize: this.state.pageSize || 25,
                      itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                          return <a>Previous</a>;
                        }
                        if (type === 'next') {
                          return <a>Next</a>;
                        }
                        return originalElement;
                      },
                      showTotal: (total, range) =>
                        `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                      defaultCurrent: 1
                    }}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps('name')}
                      sorter={(a, b) => a.name.localeCompare(b.name)}
                      sortDirections={['descend', 'ascend']}
                      title="Item Name"
                      dataIndex="name"
                      key="name"
                    />
                    <Column
                      title="Price"
                      render={(text, record) => {
                        return text ? "R " + text.toFixed(2) : "R 0.00"
                      }}
                      dataIndex="sellPrice"
                      key="sellPrice"
                    />
                    <Column
                      title="In Stock"
                      render={(text, record) => {
                        return (
                          <span>
                            {(Math.round(record.qty * 1000) / 1000) + (dimTypesFormat[record.dimType] ? dimTypesFormat[record.dimType] : ' Units')}
                          </span>
                        );
                      }}
                      dataIndex="qty"
                      key="qty"
                    />
                    <Column
                      title="Qty"
                      width="7em"
                      align="center"
                      key="amount"
                      dataIndex="amount"
                    />
                    <Column
                      title="Total"
                      width="10em"
                      render={(text, record) => {
                        return (
                          <span>
                            {'R ' +
                              (record.total
                                ? record.total.toFixed('2')
                                : '0.00')}
                          </span>
                        );
                      }}
                      key="total"
                    />
                  </StyledTable>
                </Row>
                <div
                  style={{
                    transition: "1s",
                    opacity: this.state.isScrolling ? 0 : 1,
                    position: "fixed",
                    right: 5,
                    bottom: 5,
                    background: "rgb(19, 36, 64)",
                    padding: 0,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderRadius: "3em",
                    // width: "calc(100% - 80px)",
                    height: "3.4em",
                    zIndex: 99
                  }}
                  color="primary"
                >
                  <Button onClick={() => this.switch()} style={{
                    float: "right", marginTop: 8,
                    color: "white",
                    borderRadius: "3em",
                    border: "1px solid white",
                    backgroundColor: "transparent",
                    width: "6em"
                  }} color="primary">
                    Back
                  </Button>
                  {!this.state.invoiced ? <Button
                    onClick={() => this.createInvoice(this.state.record)}
                    style={{
                      float: "right", marginTop: 8, marginRight: 8, color: "white",
                      borderRadius: "3em",
                      border: "1px solid rgba(0, 255, 112, 0.898)",
                      backgroundColor: "transparent"
                    }}
                    color="primary"

                  >
                    Create Invoice
                  </Button> : null}
                  {this.state.invoiced && !this.state.shipped && !this.state.paid ? <Button
                    onClick={() => this.updateOrder(this.state, "Paid")}
                    style={{
                      float: "right", marginTop: 8, marginRight: 8, color: "white",
                      borderRadius: "3em",
                      border: "1px solid rgba(0, 255, 112, 0.898)",
                      backgroundColor: "transparent"
                    }}
                    color="primary"
                  >
                    Set Paid
                  </Button> : null}
                  {this.state.paid && !this.state.shipped ? this.state.record.orderType === "Deliver" ? <Popconfirm
                    title={<CustomInput
                      labelText="Waybill / Tracking number"
                      id="waybill"
                      formControlProps={{
                        fullWidth: true,
                        style: { margin: 0, padding: 0 }
                      }}
                      inputProps={{
                        onChange: e => this.setState({ waybill: e.currentTarget.value }),
                        onClick: e => e.stopPropagation(),
                        value: this.state.waybill
                      }}
                    />}
                    onConfirm={(e) => {
                      e.stopPropagation()
                      this.createShipment(this.state.record)
                    }}
                    okText="Create Shipment"
                    icon={null}
                  >
                    <Button

                      style={{
                        float: "right", marginTop: 8, marginRight: 8, color: "white",
                        borderRadius: "3em",
                        border: "1px solid rgba(0, 255, 112, 0.898)",
                        backgroundColor: "transparent"
                      }}
                      color="primary"
                    >
                      Create Shipment
                  </Button>
                  </Popconfirm> : <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      this.readyToCollect(this.state.record)
                    }}
                    style={{
                      float: "right", marginTop: 8, marginRight: 8, color: "white",
                      borderRadius: "3em",
                      border: "1px solid rgba(0, 255, 112, 0.898)",
                      backgroundColor: "transparent"
                    }}
                    color="primary"
                  >
                    Ready to Collect
</Button> : null}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {' '}
            <Card style={{ paddingBottom: "5em" }}>
              <Row>
                <Col span={6}>
                  <CustomInput
                    labelText="Order"
                    id="purchaseordername"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.name
                    }}
                  />
                </Col>
                <Col span={1} />
                <Col span={6}>
                  <CustomSelect
                    labelText="Customer"
                    id="customer"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.handleChange(e),
                      onClick: () => this.onHelpMarker('stockitemtype'),
                      value: this.state.customer
                    }}
                    menuItems={this.state.customers}
                  />
                </Col>
                <Col span={1} />
                <Col span={6}>
                  <CustomSelect
                    labelText="Order Type"
                    id="orderType"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.handleChange(e),
                      onClick: () => this.onHelpMarker('stockitemtype'),
                      value: this.state.orderType
                    }}
                    menuItems={[{value:"Deliver",name:"Deliver"}, {value:"Collect",name:"Collect"}]}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ top: '15px', width: '20em' }}>
                  {'Issue Date '}
                  <DatePicker
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDateStart}
                    value={this.state.pickerStart}
                    format={dateFormat}
                  />
                </Col>
                <Col span={1} />
                <Col span={6} style={{ top: '15px', width: '20em' }}>
                  {'Shipment Date '}
                  <DatePicker
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDateExp}
                    value={this.state.pickerExp}
                    format={dateFormat}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <CustomInput
                    labelText="Reference"
                    id="reference"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      onClick: () => this.onHelpMarker('stockitemdesc'),
                      value: this.state.ref
                    }}
                  />
                </Col>
                <Col span={1} />
                <Col span={5}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      onClick: () => this.onHelpMarker('stockitemdesc'),
                      value: this.state.email
                    }}
                  />
                </Col>
                <Col span={1}>
                  <Tooltip
                    placement="top"
                    title={
                      'Use the semicolon (;) character to separate multiple username addresses'
                    }
                  >
                    <IconButton
                      style={{ marginTop: '1.6em' }}
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: '17px',
                          color: 'grey'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Col>
                <Col span={6}>
                  <CustomInput
                    labelText="Notes To Customer"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      onClick: () => this.onHelpMarker('stockitemdesc'),
                      value: this.state.note
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ top: '5px' }}>
                  <h2>{'TOTAL: R' + this.state.total.toFixed('2')}</h2>
                </Col>
              </Row>
              <Row>
              </Row>
              <Row>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  pagination={{
                    locale: { items_per_page: " Items" },
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: itemRender,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  dataSource={this.state.items}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (rowIndex % 2 == 0) {
                      return { style: { background: 'rgb(247, 247, 247)' } };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    sorter={(a, b) => a.name.localeCompare(b.name)}
                    sortDirections={['descend', 'ascend']}
                    title="Item Name"
                    dataIndex="name"
                    key="name"
                    render={(text, record) => {
                      if (!record.qty || record.qty === 0) {
                        return (<CustomAddSelect
                          id='productadd'
                          dataSource={this.state.searchStock.map(
                            (item, key) =>
                              item && (
                                <Option
                                  key={item._id}
                                  value={item.name}
                                >
                                  {item.name}
                                </Option>
                              )
                          )}
                          formControlProps={{
                            fullWidth: true,
                            style: { margin: 0, padding: 0 }
                          }}
                          style={{ width: '100%' }}
                          optionLabelProp="value"
                          selectProps={{
                            value: record.name
                          }}
                          onSelect={(v, e) => this.selectItem(v, e)}
                          onSearch={(v) => this.searchItem(v, record)}
                          placeholder={"Start by typing product name / SKU"}
                        />)
                      } else {
                        return text
                      }
                    }}
                  />
                  <Column
                    title="Price"
                    render={(text, record) => {
                      if (record.qty || record.qty === 0) {
                        return text ? "R " + text.toFixed(2) : "R 0.00"
                      } else {
                        return ""
                      }
                    }}
                    dataIndex="sellPrice"
                    key="sellPrice"
                  />
                  <Column
                    title="In Stock"
                    render={(text, record) => {
                      if (record.qty || record.qty === 0) {
                        return (
                          <span>
                            {(Math.round(record.qty * 1000) / 1000) + (dimTypesFormat[record.dimType] ? dimTypesFormat[record.dimType] : ' Units')}
                          </span>
                        );
                      } else {
                        return ""
                      }
                    }}
                    dataIndex="qty"
                    key="qty"
                  />
                  <Column
                    title="Qty"
                    width="7em"
                    align="center"
                    render={(text, record, index) => {
                      if (record.qty || record.qty === 0) {
                        return <div>
                          <Input
                            name="qty"
                            placeholder="Input Qty"
                            value={record.amount}
                            onChange={e => this.changeItemAmount(e, record, index)}
                          />
                        </div>
                      } else {
                        return ""
                      }
                    }}
                    key="amount"
                  />
                  <Column
                    title="Total"
                    width="10em"
                    render={(text, record) => {
                      if (record.qty || record.qty === 0) {
                        return (
                          <span>
                            {'R ' +
                              (record.total
                                ? record.total.toFixed('2')
                                : '0.00')}
                          </span>
                        );
                      } else {
                        return ""
                      }
                    }}
                    key="total"
                  />
                  <Column
                    title="Actions"
                    render={(text, record, index) => {
                      if (record.qty || record.qty === 0) {
                        return <IconButton
                          style={{
                            border: '1px solid red',
                            marginLeft: '1em', padding: '16px',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            this.deleteItem(index)
                          }}
                        >
                          {
                            <Icon
                              type="delete"
                              style={{

                                position: 'absolute',
                                fontSize: '15px',
                                color: 'red',

                              }}
                            />
                          }
                        </IconButton>
                      } else {
                        return ""
                      }
                    }}
                  />
                </StyledTable>
              </Row>{' '}
            </Card>
            <div
              style={{
                transition: "1s",
                opacity: this.state.isScrolling ? 0 : 1,
                position: "fixed",
                right: 5,
                bottom: 5,
                background: "rgb(19, 36, 64)",
                padding: 0,
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "3em",
                // width: "calc(100% - 80px)",
                height: "3.4em",
                zIndex: 99
              }}
              color="primary"
            >
              <Button style={{
                float: "right", marginTop: 8,
                color: "white",
                borderRadius: "3em",
                border: "1px solid white",
                backgroundColor: "transparent",
                width: "6em"
              }} onClick={() => this.getOrderItems()} color="primary">
                Cancel
                </Button>
              <Button
                style={{
                  float: "right", marginTop: 8, marginRight: 8, color: "white",
                  borderRadius: "3em",
                  border: "1px solid rgba(0, 255, 112, 0.898)",
                  backgroundColor: "transparent"
                }}
                onClick={() => this.addEcomOrders(true)}
                color="primary"
              >
                Create Order
                </Button>
              {/* {this.state.email !== "" ? <Button
                style={{
                  float: "right", marginTop: 8, marginRight: 8, color: "white",
                  borderRadius: "3em",
                  border: "1px solid rgba(0, 255, 112, 0.898)",
                  backgroundColor: "transparent"
                }}
                onClick={() => this.addEcomOrders(true)}
                color="primary"
              >
                Create Order and Send
                </Button> : null} */}
            </div>
          </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  getEcomCustomers,
  getOrders,
  getEcomProducts,
  addEcomOrder,
  updateOrder,
  createEcomInvoice,
  createEcomShipment,
  resendPurchaseOrder,
  updatePo,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  getEcomOrderReport,
  sendProofOfPayment,
  convertPOToDraft
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Orders));
