import React, { memo } from 'react';
import { Card, Button, List, Skeleton, Icon, Col } from 'antd';
import styled, { css, keyframes } from 'styled-components';

var blink = keyframes`

`;

const animationRule = css`
  ${blink} .5s step-end infinite alternate;
`;
const shadowPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px #C83939;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulseGreen = keyframes`
  0% {
    box-shadow: 0 0 0 0px #39C86A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const Bills = ({ billid, bills, state, props }) => {
  if (bills[billid].items.length > 0) {
    let AnCard = styled(Card)`
      .ant-card ::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
      }
      .ant-card-head {
        border-bottom: 0px solid #e8e8e8;
      }
      animation: ${animationRule};
      .ant-card-body {
        margin-top: -8px;
        border-top: 1px solid #132440;
        padding: 10px;
        padding-top: 0.2em;
        zoom: 1;
      }
    `;
    if (bills[billid].state !== 'open') {
      AnCard = styled(Card)`
        .ant-card ::-webkit-scrollbar {
          display: none !important;
          width: 0 !important;
        }
        .ant-card-head {
          border-bottom: 0px solid #e8e8e8;
        }
        .ant-card-body {
          margin-top: -10px;
          padding: 10px;
          zoom: 1;
        }
      `;
    }

    if (bills[billid].state === 'open') {
      AnCard = styled(Card)`
        animation: ${shadowPulseGreen} 1s infinite;
      `;
    }
    if (bills[billid].type === 'Void') {
      AnCard = styled(Card)`
        animation: ${shadowPulse} 1s infinite;
        .ant-card ::-webkit-scrollbar {
          display: none !important;
          width: 0 !important;
        }
        .ant-card-head {
          border-bottom: 0px solid #e8e8e8;
        }
        .ant-card-body {
          margin-top: -10px;
          padding: 10px;
          zoom: 1;
        }
      `;
    }
    return (
      <Col sm={8} md={8}>
        <div key={billid} style={{}}>
          <AnCard
            style={{
              borderRadius: '5px 5px 5px 8px',
              height: '55vh',
              maxHeight: '35em',
              border: 'none',
              overflow: 'auto',
              overflowX: 'hidden',
              paddingBottom: '6em',
              backgroundColor: '#272930 ',
              margin: '0.8em'
            }}
            title={
              <div
                style={{
                  height: '2.7em',
                  borderRadius: '5px 5px 0px 0px',
                  top: 0,
                  //  backgroundColor: 'rgba(207, 229, 255, 0.282)',
                  position: 'absolute',
                  right: '0%',
                  paddingTop: '0.7em',
                  width: '100%'
                }}
              >
                <span
                  style={{
                    paddingLeft: '0.7em',
                    paddingRight: '1em',
                    color: 'white',
                    borderRadius: '0.3em',
                    fontSize: '16px'
                  }}
                >
                  Bill #
                  {bills[billid].orderno
                    ? bills[billid].orderno.split('-')[0] +
                      '-' +
                      bills[billid].orderno.split('-')[2]
                    : 'N/A'}
                </span>
                <p
                  style={{
                    color: 'white',
                    marginRight: '5em',

                    borderRadius: '0.3em',
                    marginLeft: '0px',
                    fontSize: '14px',
                    float: 'right',
                    zIndex: 7
                  }}
                >
                  {' '}
                  {bills[billid].date
                    ? bills[billid].date.slice(0, 4) +
                      '-' +
                      bills[billid].date.slice(4, 6) +
                      '-' +
                      bills[billid].date.slice(6, 8) +
                      ' ' +
                      bills[billid].date.slice(8, 10) +
                      ':' +
                      bills[billid].date.slice(10, 12)
                    : 'N/A'}
                </p>
                <div
                  style={{
                    right: '4%',
                    position: 'absolute',
                    top: '0.4em',
                    zIndex: 8,
                    fontWeight: 'bold',
                    fontSize: '1.5em',
                    color: 'white'
                  }}
                  onClick={e => props.printKitchenBill(bills[billid])}
                >
                  <Icon
                    style={{
                      color:
                        bills[billid].type === 'Void'
                          ? 'rgb(255, 66, 66)'
                          : '#00CC70'
                    }}
                    type="printer"
                  />
                </div>{' '}
              </div>
            }
            key={billid}
          >
            <span
              style={
                bills[billid].type === 'Void'
                  ? {
                      color: 'rgb(255, 66, 66)',
                      fontWeight: '700',
                      fontFamily: 'Roboto',
                      fontSize: '20px'
                      // border: '5px solid #C83939'
                    }
                  : {
                      color: 'white',
                      fontWeight: '700',
                      fontFamily: 'Roboto',
                      fontSize: '20px'
                    }
              }
            >
              {bills[billid].type ? bills[billid].type : 'Quick Sale'}{' '}
              {bills[billid].table ? 'Table #' + bills[billid].table : ''}
            </span>{' '}
            <div
              style={{
                color: 'white',
                fontWeight: '700',
                fontFamily: 'Roboto',
                fontSize: '14px'
              }}
            >
              Created by &nbsp;
              {bills[billid].username ? bills[billid].username : 'N/A'}
            </div>
            <div
              style={{
                color: 'white',
                fontWeight: '700',
                fontFamily: 'Roboto',
                fontSize: '14px'
              }}
            >
              {bills[billid].kituser
                ? 'Accepted by ' + bills[billid].kituser
                : ''}
            </div>
            {/*<span
              style={{
                marginTop: '-1.3em',
                color: '#00CC70',
                float: 'right',
                fontWeight: '500',
                fontFamily: 'Roboto',
                fontSize: '14px'
              }}
            >
              View all
            </span>{' '}*/}
            {bills[billid].instruction ? (
              <div
                style={{
                  //background: 'rgba(01, 1, 21, 0.1)',
                  width: '100%',
                  marginTop: '0.5em',
                  border: 'solid 0px lightgrey'
                }}
              >
                <p
                  style={{
                    color: 'rgb(0, 204, 112)',
                    fontWeight: '600',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    marginBottom: '-0em'
                  }}
                >
                  Delivery instructions
                </p>
                <div>{bills[billid].instruction || 'N/A'}</div>
              </div>
            ) : null}
            <List
              style={{
                background: 'rgb(45, 50, 60)',
                width: '100vw',
                marginLeft: '-0.8em',
                padding: 'none',
                border: 'solid 0px lightgrey',
                borderRadius: '6px',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                marginTop: '0.4em'
              }}
              itemLayout="horizontal"
              dataSource={bills[billid].items}
              renderItem={(item, iKey) => (
                <List.Item>
                  <Skeleton title={'Items'} loading={false} active>
                    <List.Item.Meta
                      title={
                        <p
                          style={{
                            textDecoration:
                              bills[billid].type === 'Void'
                                ? 'line-through'
                                : 'none',
                            textDecorationColor: 'rgb(255, 66, 66)',
                            color: 'white',
                            fontWeight: '700',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            paddingLeft: '1em',
                            marginBottom: '-0em'
                          }}
                        >
                          {(item.tokitchen > 0 ? item.tokitchen : item.qty) +
                            ' x ' +
                            item.name}
                        </p>
                      }
                      description={
                        <div>
                          {item.cookIns && item.cookIns.length > 0 ? (
                            <List
                              size="small"
                              dataSource={item.cookIns}
                              renderItem={item => (
                                <List.Item
                                  style={{
                                    fontSize: 15,
                                    color: 'white',
                                    padding: 0,
                                    paddingLeft: '2.3em',
                                    marginBottom: '-1em',
                                    border: 0
                                  }}
                                >
                                  <p>{item.name}</p>
                                </List.Item>
                              )}
                              style={{
                                display: 'block',
                                fontSize: 7
                              }}
                            />
                          ) : null}{' '}
                          {item.openIns && item.openIns.length > 0 ? (
                            <div>
                              <span
                                style={{
                                  fontSize: 15,
                                  color: 'white',
                                  padding: 0,
                                  paddingLeft: '1.3em',
                                  marginBottom: '-1em',
                                  border: 0
                                }}
                              >
                                Special Instruction:
                              </span>
                              <List
                                size="small"
                                dataSource={item.openIns}
                                renderItem={item => (
                                  <List.Item
                                    style={{
                                      fontSize: 15,
                                      color: 'white',
                                      padding: 0,
                                      paddingLeft: '2.3em',
                                      marginBottom: '-1em',
                                      border: 0
                                    }}
                                  >
                                    <p>{item}</p>
                                  </List.Item>
                                )}
                                style={{
                                  display: 'block',
                                  fontSize: 7
                                }}
                              />
                            </div>
                          ) : null}{' '}
                          {item.desc ? (
                            <div
                              style={{
                                paddingLeft: '1em',
                                //background: 'rgba(01, 1, 21, 0.1)',
                                width: '100%',
                                marginTop: '0.5em',
                                marginBottom: '0em',
                                border: 'solid 0px lightgrey'
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    bills[billid].type === 'Void'
                                      ? 'rgb(255, 66, 66)'
                                      : '#00CC70',
                                  fontWeight: '600',
                                  fontFamily: 'Roboto',
                                  fontSize: '12px',
                                  paddingLeft: '0em',
                                  marginBottom: '-0em'
                                }}
                              >
                                {bills[billid].type === 'Void'
                                  ? 'Void Reason'
                                  : ''}
                              </p>
                              {bills[billid].type === 'Void' ? (
                                <div style={{ color: 'white' }}>
                                  {item.desc || 'N/A'}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />{' '}
          </AnCard>{' '}
          <div
            style={{
              textAlign: 'center',
              whiteSpace: 'nowrap'
            }}
          >
            {bills[billid].state === 'proc' ||
            bills[billid].state === 'preparing' ? (
              <div
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  width: '100%',
                  height: 10,
                  fontSize: 25,
                  borderRadius: 0,
                  bottom: 65,
                  left: 0,
                  position: 'absolute',
                  backgroundColor: 'transparent'
                }}
              >
                <div style={{ marginTop: '1.3em' }}>
                  <Button
                    style={{
                      width: '99%',
                      height: 40,
                      borderRadius: '0px 0px 10px 10px',
                      display: 'inline-block',
                      textAlign: 'center',
                      touchAction: 'manipulation',
                      cursor: 'pointer',
                      border: 'none',
                      backgroundImage: 'none',
                      backgroundColor: '#00CC70',
                      color: 'white'
                    }}
                    disabled={state.loadKitch}
                    onClick={() =>
                      props.updateBill(
                        bills[billid],
                        bills[billid].type === 'Collect'
                          ? 'collect'
                          : bills[billid].type === 'Delivery'
                          ? 'delivery'
                          : 'comp'
                      )
                    }
                  >
                    Complete
                  </Button>
                </div>
              </div>
            ) : bills[billid].state === 'open' ||
              bills[billid].state === 'paid' ||
              bills[billid].state === 'void' ? (
              <div
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  width: '100%',
                  height: 10,
                  fontSize: 25,
                  borderRadius: 0,
                  bottom: 65,
                  left: 0,
                  position: 'absolute',
                  backgroundColor: 'transparent'
                }}
              >
                {bills[billid].state === 'open' &&
                bills[billid].type === 'Void' ? (
                  <div style={{ marginTop: '1.3em' }}>
                    <Button
                      style={{
                        width: '99%',
                        height: 40,
                        borderRadius: '0px 0px 10px 10px',
                        display: 'inline-block',
                        textAlign: 'center',
                        touchAction: 'manipulation',
                        cursor: 'pointer',
                        border: 'none',
                        backgroundImage: 'none',
                        backgroundColor: '#C83939',
                        color: 'white'
                      }}
                      disabled={state.loadKitch}
                      onClick={() =>
                        props.updateBill(bills[billid], 'preparing')
                      }
                    >
                      OK
                    </Button>
                  </div>
                ) : null}
                {bills[billid].state === 'open' &&
                bills[billid].type !== 'Void' ? (
                  <div style={{ marginTop: '1.3em' }}>
                    <Button
                      style={{
                        width: '49.5%',
                        height: 40,
                        borderRadius: '0px 0px 0px 10px',
                        border: 'none',
                        display: 'inline-block',
                        textAlign: 'center',
                        touchAction: 'manipulation',
                        cursor: 'pointer',
                        backgroundImage: 'none',
                        backgroundColor: '#00CC70',
                        color: 'white'
                      }}
                      disabled={state.loadKitch}
                      onClick={() =>
                        bills[billid].customerId &&
                        bills[billid].paymentType !== 'Cash' &&
                        bills[billid].paymentType !== 'Card'
                          ? props.updateBill(bills[billid], 'await payment')
                          : props.updateBill(bills[billid], 'paid')
                      }
                    >
                      Accept
                    </Button>
                    <Button
                      style={{
                        width: '49.5%',
                        height: 40,
                        borderRadius: '0px 0px 10px 0px',
                        border: 'none',
                        display: 'inline-block',
                        textAlign: 'center',
                        touchAction: 'manipulation',
                        cursor: 'pointer',
                        backgroundImage: 'none',
                        backgroundColor: '#C83939',
                        color: 'white'
                      }}
                      disabled={state.loadKitch}
                      onClick={() => props.updateBill(bills[billid], 'void')}
                    >
                      Reject
                    </Button>
                  </div>
                ) : bills[billid].state === 'paid' ||
                  bills[billid].state === 'void' ? (
                  <div style={{ marginTop: '1.3em' }}>
                    <Button
                      style={{
                        width: '99%',
                        height: 40,
                        borderRadius: '0px 0px 10px 10px',
                        border: 'none',
                        display: 'inline-block',
                        textAlign: 'center',
                        touchAction: 'manipulation',
                        cursor: 'pointer',
                        backgroundImage: 'none',
                        backgroundColor: '#00CC70',
                        color: 'white'
                      }}
                      disabled={state.loadKitch}
                      onClick={() =>
                        props.updateBill(bills[billid], 'preparing')
                      }
                    >
                      Prepare
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>{' '}
      </Col>
    );
  } else {
    return;
  }
};

const MemoBills = memo(Bills);

export { MemoBills };
