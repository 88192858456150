const initialState = {
  nav: {
    usergroupadd: false,
    grouptable: true
  }
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case 'RESET_GROUPS': {
      return {
        nav: {
          usergroupadd: false,
          grouptable: true
        }
      };
    }
    case 'OPEN_ADD_GOUP_USER': {
      return {
        nav: {
          usergroupadd: true,
          grouptable: false
        }
      };
    }
    case 'CLOSE_ADD_GOUP_USER': {
      return {
        nav: {
          usergroupadd: false,
          grouptable: false
        }
      };
    }
    case 'OPEN_TABLE_GROUP': {
      return {
        nav: {
          usergroupadd: false,
          grouptable: true
        }
      };
    }
    case 'CLOSE_TABLE_GROUP': {
      return {
        nav: {
          usergroupadd: false,
          grouptable: false
        }
      };
    }
    default:
      return state;
  }
}
