import { Col, Layout, Icon, Popover, Input, Row, Drawer, Menu } from 'antd';
import React, { useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { GiSteeringWheel } from 'react-icons/gi';
const { Search } = Input;
const { Header } = Layout;
let searchVisible = false;
let setSearchVisible;
let drawerVisible = false;
let setDrawerVisible;
const CustomerHeader = props => {
  [searchVisible, setSearchVisible] = useState(searchVisible);
  [drawerVisible, setDrawerVisible] = useState(drawerVisible);
  if (props.currentView !== 'companyList') {
    if (searchVisible) {
      setSearchVisible(false);
    }
  }
  return (
    <Header
      style={{
        height: 50,
        width: '100%',
        position: 'fixed',
        background: 'white',
        lineHeight: '50px',
        padding: '0px 5px',
        zIndex: 9,

        color: '#21ABB6',
        boxShadow: '0px 3px 10px -4px lightgrey'
      }}
    >
      <Row style={{}} type="flex" justify="space-between">
        <Col style={{}} span={18}>
          {props.currentView === 'vieworder' ? (
            <div>
              <AiOutlineArrowLeft
                onClick={e => props.navToView(props.previousView)}
                style={{ verticalAlign: -5.5, fontSize: '22px' }}
              />
              <span
                onClick={e => props.navToView(props.previousView)}
                style={{
                  marginLeft: 5,
                  fontWeight: 700,
                  color: '#383838',
                  fontFamily: 'Roboto'
                }}
              >
                Order Details
              </span>
            </div>
          ) : props.currentView === 'deliver' ? (
            <div>
              <AiOutlineArrowLeft
                onClick={e => props.navToView(props.previousView)}
                style={{ verticalAlign: -5.5, fontSize: '22px' }}
              />
              <span
                onClick={e => props.navToView(props.previousView)}
                style={{
                  marginLeft: 5,
                  fontWeight: 700,
                  color: '#383838',
                  fontFamily: 'Roboto'
                }}
              >
                Delivery
              </span>
            </div>
          ) : (
            <div>
              <GiSteeringWheel style={{ verticalAlign: -2 }} />
              <span
                style={{ marginLeft: 5, fontWeight: 700, fontFamily: 'Roboto' }}
              >
                {props.user.name}
              </span>
            </div>
          )}
        </Col>

        <Col style={{ fontSize: 26 }} span={6}>
          <Icon
            style={{ float: 'right', marginTop: 12, marginRight: 10 }}
            onClick={e => setDrawerVisible(!drawerVisible)}
            type="menu"
          />{' '}
          <Drawer
            placement={'left'}
            closable={true}
            visible={drawerVisible}
            onClose={e => setDrawerVisible(!drawerVisible)}
          >
            <Menu
              onClick={e => {
                props.navToView(e.key);
                setDrawerVisible(!drawerVisible);
              }}
              style={{ width: 256, paddingTop: 30 }}
              mode="inline"
            >
              <Menu.Item style={{ width: 200, background: 'whitesmoke' }} key="orders">Orders</Menu.Item>
              <Menu.Item style={{ width: 200, background: 'whitesmoke' }} key="4">Profile</Menu.Item>
              <Menu.Item style={{ width: 200, background: 'whitesmoke' }} key="signOut">Sign Out</Menu.Item>
            </Menu>
          </Drawer>
          <Popover
            overlayStyle={{
              left: 0,
              padding: 0,
              width: '100%',
              height: 50,
              position: 'fixed'
            }}
            placement="left"
            visible={searchVisible}
            content={
              <Search
                prefix={
                  <Icon
                    type="close"
                    onClick={e => {
                      props.searchPlace({
                        value: undefined,
                        search: false
                      });
                      setSearchVisible(false);
                    }}
                  />
                }
                allowClear
                placeholder="search place / category"
                onChange={e =>
                  props.searchPlace({
                    value: e.currentTarget.value,
                    search: true
                  })
                }
                onSearch={e =>
                  props.searchPlace({
                    value: e,
                    search: true
                  })
                }
                style={{ width: '100%', height: 35 }}
              />
            }
          >
            {' '}
            {props.currentView === 'companyList' ? (
              <Icon
                style={{ float: 'right', marginTop: 12, marginRight: 10 }}
                onClick={e => {
                  props.searchPlace({
                    value: undefined,
                    search: true
                  });
                  setSearchVisible(true);
                }}
                type="search"
              />
            ) : null}
          </Popover>{' '}
        </Col>
      </Row>
    </Header>
  );
};
export default CustomerHeader;
