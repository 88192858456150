import React, { memo } from 'react';
import classNames from 'classnames';
import { AutoComplete } from 'antd';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import customSelectStyle from 'src/assets/jss/material-dashboard-react/components/customSelectStyle.jsx';

const CustomAddSelect = memo(({ ...props }) => {
    const {
        classes,
        formControlProps,
        labelProps,
        id,
        style,
        optionLabelProp,
        onDropdownVisibleChange,
        value,
        onSelect,
        onSearch,
        onFocus,
        inputRef,
        ref,
        inputProps,
        selectProps,
        placeholder,
        dataSource,
        error,
        success,
        errorText,
        disabled
    } = props;
    const labelClasses = classNames({
        [' ' + classes.labelRootError]: error,
        [' ' + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
    });
    return (
        <FormControl
            {...formControlProps}
            className={formControlProps.className + ' ' + classes.formControl}
        >
            <InputLabel
                className={classes.labelRoot + labelClasses}
                htmlFor={id}
                style={{
                    zIndex: 1,
                    lineHeight: '0',
                    transform: (selectProps ? selectProps.value : false) || (selectProps ? selectProps.defaultValue : false) ? 'translate(0, -4px) scale(0.75)' : 'translate(0, 20px) scale(1)',
                    transformOrigin: (selectProps ? selectProps.value : false) || (selectProps ? selectProps.defaultValue : false) ? 'top left' : ''
                }}
                {...labelProps}
            >
                {placeholder}
            </InputLabel>
            <AutoComplete
                disabled={disabled}
                name={id}
                id={id}
                ref={ref}
                style={style}
                optionLabelProp={optionLabelProp}
                onSelect={onSelect}
                onSearch={onSearch}
                onFocus={onFocus}
                dataSource={dataSource}
                onDropdownVisibleChange={onDropdownVisibleChange || ''}
                {...selectProps}
            >
                <Input
                    inputRef={inputRef}
                    classes={{
                        underline: underlineClasses
                    }}
                    {...inputProps}
                />
            </AutoComplete>
            {error ? (
                <FormHelperText error={error}>{errorText}</FormHelperText>
            ) : success ? (
                <FormHelperText>{success}</FormHelperText>
            ) : null}
        </FormControl>
    );
}
);

CustomAddSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    formControlProps: PropTypes.object
};

export default withStyles(customSelectStyle)(CustomAddSelect);
