import React from 'react';
import { render } from 'react-dom';
import { isTablet, isMobileOnly, deviceType } from 'react-device-detect';
import App from 'src/components/App/AppWrapper';
import * as serviceWorker from 'src/serviceWorker';
import 'src/index.scss';
import 'src/utils/typography';
import { timeout } from 'q';
import swConfig from './swConfig';

const startApp = () => {
  if (window.cordova) {
    if (window.location.pathname.search('user') > -1) {
      window.screen.orientation.lock('portrait');
    } else {
      // window.screen.orientation.lock('landscape');
      if (window.innerWidth <= 440 || window.innerHeight <= 440) {
        window.screen.orientation.lock('portrait');
      } else {
        window.screen.orientation.lock('landscape');
      }
    }
    if (window.device) {
      if (window.device.platform === "iOS" || window.device.platform === "Android") {
        
        if (window.yoco && window.yoco.initYoco) {
          window.yoco.initYoco("START", function (CB) {
            console.log(CB)
          });
        }
        if (window.socketscan && window.socketscan.useCaptureBasic) {
          const appInfo = {
            appId: 'ios:io.cordova.vpos',
            developerId: '7aacc45e-f3f9-eb11-94ef-000d3a339705',
            appKey: 'MC4CFQCoifYmZ+gNKuMCXxuGCQhm9dq7QwIVAJUhcSEbEqKCxK2wBvhW2mzJ43FU'
          };
          window.socketscan.useCaptureBasic(appInfo, (success) => {
            console.log('useCaptureBasic returns: ', success);
          }, (error) => {
            console.log('useCaptureBasic returns an error: ', error);
          });
        }
      }
    }
  }
  if (window.innerWidth <= 440 || window.innerHeight <= 440) {
    window.isPhone = true;
  } else {
    window.isPhone = false;
  }
  const root = document.getElementById('root');

  render(<App />, root);

  if (module.hot) {
    module.hot.accept('src/components/App/AppWrapper', () => {
      const NextApp = require('src/components/App/AppWrapper').default;
      render(<NextApp />, root);
    });
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  if (process.env.NODE_ENV === 'production') {
    console.log(
      `running in ${process.env.NODE_ENV} environment @ ${process.env.PUBLIC_URL
      }`
    );
    serviceWorker.register(swConfig);
  } else {
    console.log(
      `running in ${process.env.NODE_ENV} environment @ ${process.env.PUBLIC_URL
      }`
    );
    serviceWorker.register(swConfig);
  }
};

var count = 0;

const initApp = () => {
  if (window.cordova) {
    console.log('Found Cordova');
    document.addEventListener('deviceready', startApp, false);
  } else {
    startApp();
  }
};

initApp();
