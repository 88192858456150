import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Spin,
  Icon,
  Switch,
  Select,
  TimePicker,
  Input,
  Upload,
  Row,
  Col,
  Tabs
} from 'antd';
import Resizer from 'react-image-file-resizer';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import CustomSelectMulti from 'src/components/CustomSelectMulti/CustomSelectMulti.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { getOrg, updateOrg } from 'src/redux/actions/backend';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import styled, { css } from 'styled-components';
import moment from 'moment';
import {
  AiOutlineUser,
  AiOutlineAppstoreAdd,
  AiOutlineCreditCard
} from 'react-icons/ai';
import { FiAlertTriangle } from 'react-icons/fi';
import { GrFavorite } from 'react-icons/gr';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';
import { MdDialpad } from 'react-icons/md';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';

const { Option } = Select;
const { TabPane } = Tabs;

const server = process.env.REACT_APP_GLOBAL_SERVER;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  tableScroll: {
    width: '100%',
    height: '55vh',
    overflow: 'auto'
  }
});

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    border-bottom: 0px solid #e8e8e8;
    margin: 0 0px 20px 0 !important;
    outline: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-tab {
    width: auto !important;
    font-size: 16px !important;
    margin-left: 0.5em !important;
    margin-top: 0.5em !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    font-weight: 400 !important;
    color: rgb(19, 36, 64) !important;
    background: transparent !important;
    border: 0px solid #00cc70 !important;
  }
  .ant-tabs-tab-active {
    width: auto !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    color: #00cc70 !important;
    border-bottom: 1px solid #00cc70 !important;
  }
`;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
};

let setState;
let setAlert;
let props;
const normalTimeFormat = 'HH:mm';
const dateFormatQuerTime = 'HHmm';

const handleAddBreadCrumb = crumb => {
  let breadCrumbs = props.breadCrumbs || [];
  breadCrumbs.push(crumb);
  props.setBreadCrumbs(breadCrumbs);
};

const handleRemoveBreadCrumb = () => {
  let breadCrumbs = props.breadCrumbs || [];
  breadCrumbs.pop();
  props.setBreadCrumbs(breadCrumbs);
};

const componentDidMount = () => {
  getSettings();
  setState(prevState => ({
    ...prevState,
    main: true
  }));
};

const getSettings = msg => {
  const token = props.local.user.token;
  props
    .getOrg(token)
    .then(result => {
      var res = result.data.results[0];
      if (res.delivery) {
        if (res.delivery[5] !== 'false') {
          res.delivery[5] = res.delivery[5].toString();
        }
        if (res.delivery[10] !== 'false') {
          res.delivery[10] = res.delivery[10].toString();
        }
        if (res.delivery[15] !== 'false') {
          res.delivery[15] = res.delivery[15].toString();
        }
      }
      setState(prevState => ({
        ...prevState,
        ...res,
        loading: false
      }));
      if (msg) {
        props.openSnackbar(msg);
      }
    })
    .catch(err => {
      onError(err);
    });
};

const handleChangeAddressSearch = event => {
  let copy = event;
  setState(prevState => ({
    ...prevState,
    address: copy
  }));
};

const handleChangeAddress = address => {
  let copyAddress = address;
  if (copyAddress) {
    // eslint-disable-next-line
    const service = new google.maps.Geocoder();
    service.geocode({ address: copyAddress }, result => {
      let copyResult = result;
      setState(prevState => ({
        ...prevState,
        address: copyAddress,
        location: {
          type: 'Point',
          coordinates: [
            copyResult[0].geometry.location.lng(),
            copyResult[0].geometry.location.lat()
          ]
        }
      }));
    });
  }
};

const handleChangeRoles = event => {
  if (!event.target.value[event.target.value.length - 1]) {
    setState(prevState => ({
      ...prevState,
      multiOpen: false
    }));
  } else {
    setState(prevState => ({
      ...prevState,
      categories: event.target.value
    }));
  }
};

const beforeUpload = file => {
  const isLt2M = file.size / 1024 / 1024 < 4;
  if (!isLt2M) {
    onError('Image Lager then 2M');
  }
  return false;
};

const handleImageUpload = ({ onSuccess, file }) => {
  onSuccess(true, file);
};

const getBase64Main = (img, callback) => {
  Resizer.imageFileResizer(img, 400, 400, 'jpg', 100, 0, uri => {
    callback(uri);
  });
};

const getBase64 = (img, callback) => {
  Resizer.imageFileResizer(img, 200, 200, 'png', 100, 0, uri => {
    callback(uri);
  });
};

const handleMainImage = info => {
  setState(prevState => ({
    ...prevState,
    loadingUpload: true
  }));
  getBase64Main(info.file, imageUrl => {
    setState(prevState => ({
      ...prevState,
      loadingUpload: false,
      mainImage: imageUrl,
      mainBase64: imageUrl
    }));
  });
};

const handleLogoImage = info => {
  setState(prevState => ({
    ...prevState,
    loadingUpload: true
  }));
  getBase64(info.file, imageUrl => {
    setState(prevState => ({
      ...prevState,
      loadingUpload: false,
      logo: imageUrl,
      logoBase64: imageUrl
    }));
  });
};

const onSwitchTime = (value, day) => {
  var start = state.start;
  var startMonFri = state.startMonFri;
  var startSat = state.startSat;
  var startSun = state.startSun;
  var end = state.end;
  var endMonFri = state.endMonFri;
  var endSat = state.endSat;
  var endSun = state.endSun;
  if (!day && !value) {
    start[1] = value;
    start[2] = value;
    start[3] = value;
    start[4] = value;
    start[5] = value;
    startMonFri = '0000';
    end[1] = value;
    end[2] = value;
    end[3] = value;
    end[4] = value;
    end[5] = value;
    endMonFri = '0000';
  } else if (day === 'sat' && !value) {
    start[6] = value;
    startSat = '0000';
    end[6] = value;
    endSat = '0000';
  } else if (day === 'sun' && !value) {
    start[0] = value;
    startSun = '0000';
    end[0] = value;
    endSun = '0000';
  }
  setState(prevState => ({
    ...prevState,
    startMonFri: startMonFri,
    startSat: startSat,
    startSun: startSun,
    start: start,
    endMonFri: endMonFri,
    endSat: endSat,
    endSun: endSun,
    end: end
  }));
};

const onChangeTimeStartMonFri = (date, dateString, day) => {
  var time = parseInt(
    moment(dateString, normalTimeFormat).format(dateFormatQuerTime)
  );
  var timeNorm = moment(dateString, normalTimeFormat).format(
    dateFormatQuerTime
  );
  var start = state.start;
  var startMonFri = state.startMonFri;
  var startSat = state.startSat;
  var startSun = state.startSun;
  if (!day) {
    start[1] = time;
    start[2] = time;
    start[3] = time;
    start[4] = time;
    start[5] = time;
    startMonFri = timeNorm;
  } else if (day === 'sat') {
    start[6] = time;
    startSat = timeNorm;
  } else if (day === 'sun') {
    start[0] = time;
    startSun = timeNorm;
  }
  setState(prevState => ({
    ...prevState,
    startMonFri: startMonFri,
    startSat: startSat,
    startSun: startSun,
    start: start
  }));
};

const onChangeTimeEndMonFri = (date, dateString, day) => {
  var time = parseInt(
    moment(dateString, normalTimeFormat).format(dateFormatQuerTime)
  );
  var timeNorm = moment(dateString, normalTimeFormat).format(
    dateFormatQuerTime
  );
  var end = state.end;
  var endMonFri = state.endMonFri;
  var endSat = state.endSat;
  var endSun = state.endSun;
  if (!day) {
    end[1] = time;
    end[2] = time;
    end[3] = time;
    end[4] = time;
    end[5] = time;
    endMonFri = timeNorm;
  } else if (day === 'sat') {
    end[6] = time;
    endSat = timeNorm;
  } else if (day === 'sun') {
    end[0] = time;
    endSun = timeNorm;
  }
  setState(prevState => ({
    ...prevState,
    endMonFri: endMonFri,
    endSat: endSat,
    endSun: endSun,
    end: end
  }));
};

const onChangeEventRadius = e => {
  let value = e.target.value;
  let delivery = state.delivery;
  if (value === 5) {
    delivery[5] = '0';
    delivery[10] = 'false';
    delivery[15] = 'false';
  } else if (value === 10) {
    delivery[5] = '0';
    delivery[10] = '0';
    delivery[15] = 'false';
  } else if (15) {
    delivery[5] = '0';
    delivery[10] = '0';
    delivery[15] = '0';
  }
  setState(prevState => ({
    ...prevState,
    radius: value,
    delivery: delivery
  }));
};

const onSwitchCollectOnly = e => {
  let value = e;
  let delivery = state.delivery;
  if (value) {
    delivery[5] = 'false';
    delivery[10] = 'false';
    delivery[15] = 'false';
    setState(prevState => ({
      ...prevState,
      radius: 0,
      delivery: delivery
    }));
  } else {
    delivery[5] = '0';
    delivery[10] = 'false';
    delivery[15] = 'false';
    setState(prevState => ({
      ...prevState,
      radius: 5,
      delivery: delivery
    }));
  }
};

const onSwitchLogo = e => {
  setState(prevState => ({
    ...prevState,
    showLogo: !state.showLogo
  }));
};

const onSwitchHours = e => {
  setState(prevState => ({
    ...prevState,
    showTreding: !state.showTreding
  }));
};

const onChangeEvents = e => {
  let value = e.currentTarget.value;
  let id = e.target.id;
  if (id === 'name') {
    setState(prevState => ({
      ...prevState,
      name: value
    }));
  } else if (id === 'email') {
    setState(prevState => ({
      ...prevState,
      email: value
    }));
  } else if (id === 'accountantEmail') {
    setState(prevState => ({
      ...prevState,
      accountantEmail: value
    }));
  } else if (id === 'mobile') {
    if (value.length > 10) {
      value = value.substring(0, value.length - 1);
    }
    setState(prevState => ({
      ...prevState,
      mobile: value
    }));
  } else if (id === 'delivery5') {
    var delivery = state.delivery;
    delivery[5] = value;
    setState(prevState => ({
      ...prevState,
      delivery: delivery
    }));
  } else if (id === 'delivery10') {
    var delivery = state.delivery;
    delivery[10] = value;
    setState(prevState => ({
      ...prevState,
      delivery: delivery
    }));
  } else if (id === 'delivery15') {
    var delivery = state.delivery;
    delivery[15] = value;
    setState(prevState => ({
      ...prevState,
      delivery: delivery
    }));
  } else if (id === 'average') {
    setState(prevState => ({
      ...prevState,
      average: value
    }));
  }
};

const closeDialog = () => {
  setAlert(prevState => ({
    ...prevState,
    alert: false
  }));
};

const onError = err => {
  setState(prevState => ({
    ...prevState,
    loading: false
  }));
  setAlert(prevState => ({
    ...prevState,
    head: 'Settingss Error',
    alert: true,
    msg: [err]
  }));
};

const submitFormSettings = oEvent => {
  const token = props.local.user.token;
  setState(prevState => ({
    ...prevState,
    loading: true
  }));
  props
    .updateOrg(state, token)
    .then(res => {
      getSettings('Settings Update Successful');
    })
    .catch(err => {
      onError(err);
    });
};

const initState = {
  _id: '',
  name: '',
  email: '',
  accountantEmail: '',
  address: '',
  mobile: '',
  industry: '',
  categories: [],
  location: {},
  radius: 15,
  tabcreation: false,
  yocopayment: false,
  addItemRequests: false,
  showFav: false,
  showKitch: false,
  addTipA: false,
  delivery: {
    5: '0',
    10: '0',
    15: '0'
  },
  start: {},
  end: {},
  startMonFri: '0000',
  endMonFri: '0000',
  startSat: '0000',
  endSat: '0000',
  startSun: '0000',
  endSun: '0000',
  mainImage: '',
  mainBase64: '',
  logo: '',
  logoBase64: '',
  loading: true,
  loadingUpload: false,
  main: true,
  userlisthead: 'Add Settings',
  userlistsub: 'Add a New Settings',
  userlistbutton: 'ADD SETTINGS',
  multiOpen: false,
  activeFilter: true,
  state: {
    alert: false,
    msg: [],
    head: ''
  },
  props: props,
  closeDialog: closeDialog,
  submitForm: submitFormSettings,
  handleChangeRoles: handleChangeRoles,
  voidPin: false,
  voidPinNumber: '',
  deliveryActive: false
};

let state = {};
let alert = {};

const Settings = allprops => {
  state = {
    _id: initState._id,
    name: initState.name,
    email: initState.email,
    accountantEmail: initState.accountantEmail,
    address: initState.address,
    mobile: initState.mobile,
    industry: initState.industry,
    categories: initState.categories,
    location: initState.location,
    radius: initState.radius,
    delivery: initState.delivery,
    tabcreation: initState.tabcreation || false,
    yocopayment: initState.yocopayment || false,
    addItemRequests: initState.addItemRequests,
    showFav: initState.showFav,
    showKitch: initState.showKitch,
    addTipA: initState.addTipA,
    startMonFri: initState.startMonFri,
    start: initState.start,
    end: initState.end,
    endMonFri: initState.endMonFri,
    startSat: initState.startSat,
    endSat: initState.endSat,
    startSun: initState.startSun,
    endSun: initState.endSun,
    mainImage: initState.mainImage,
    mainBase64: initState.mainBase64,
    logo: initState.logo,
    logoBase64: initState.logoBase64,
    loading: initState.loading,
    loadingUpload: initState.loadingUpload,
    main: initState.main,
    userlisthead: initState.userlisthead,
    userlistsub: initState.userlistsub,
    userlistbutton: initState.userlistbutton,
    multiOpen: initState.multiOpen,
    activeFilter: initState.activeFilter,
    props: initState.props,
    closeDialog: initState.closeDialog,
    submitForm: initState.submitForm,
    handleChangeRoles: initState.handleChangeRoles,
    voidPin: initState.voidPin,
    voidPinNumber: initState.voidPinNumber,
    deliveryActive: initState.deliveryActive
  };

  alert = {
    state: initState.state,
    props: initState.props,
    closeDialog: initState.closeDialog,
    submitForm: initState.submitForm,
    handleChangeRoles: initState.handleChangeRoles
  };

  props = allprops;
  state.props = props;
  alert.props = props;
  [state, setState] = useState(state);
  [alert.state, setAlert] = useState(alert.state);

  useEffect(() => {
    componentDidMount();
    return () => {};
  }, []);

  return (
    <Spin spinning={state.loading}>
      <AlertDialog {...alert} />
      {state._id ? (
        <Card style={{ marginTop: '-0.7em' }}>
          {/* <CardHeader
            style={{
              borderRadius: "0.5em",
              background:
                "linear-gradient(90deg, rgba(38,125,117,1) 0%, rgba(12,152,139,1) 35%, rgba(19,173,159,1) 100%)",
            }}
          >
            <h4
              style={{ color: "white" }}
              className={state.props.classes.cardTitleWhite}
            >
              General Settings
            </h4>
            <p
              style={{ color: "rgba(255,255,255,.62" }}
              className={state.props.classes.cardCategoryWhite}
            >
              General company settings
            </p>
          </CardHeader> */}

          <CardBody>
            <StyledTabs
              defaultActiveKey="1"
              type="card"
              onChange={e => {
                if (e === '1') handleRemoveBreadCrumb();
                if (e === '2') handleAddBreadCrumb({ crumb: 'Features' });
                if (e === '3') handleAddBreadCrumb({ crumb: 'Sale Options' });
                if (e === '4') handleAddBreadCrumb({ crumb: 'Delivery' });
              }}
            >
              <TabPane tab="General Settings" key="1">
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Company Name*"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => onChangeEvents(e),
                        value: state.name
                      }}
                    />
                  </GridItem>{' '}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Email*"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => onChangeEvents(e),
                        value: state.email
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Number*"
                      id="mobile"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'number',
                        onChange: e => onChangeEvents(e),
                        value: state.mobile
                      }}
                    />
                  </GridItem>
                </GridContainer>{' '}
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Accountant Email"
                      id="accountantEmail"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => onChangeEvents(e),
                        value: state.accountantEmail
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} />
                </GridContainer>{' '}
                <GridContainer>
                  {' '}
                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    style={{ marginBottom: '3em', marginTop: '1em' }}
                  >
                    <GridItem
                      style={{
                        fontSize: '16px',
                        fontWeight: 500,
                        marginBottom: '-2em',
                        marginLeft: '-0.7em'
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {'Company Address'}
                    </GridItem>{' '}
                    <PlacesAutocomplete
                      value={state.address}
                      onChange={e => handleChangeAddressSearch(e)}
                      onSelect={e => handleChangeAddress(e)}
                      searchOptions={{
                        componentRestrictions: { country: 'za' },
                        types: ['address']
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => {
                        return (
                          <div style={{ width: '100%', display: 'grid' }}>
                            <Input
                              value={state.address}
                              style={{
                                width: '100%',
                                margin: 'auto',
                                zIndex: 9,
                                position: 'relative',
                                top: 40
                              }}
                              placeholder="Enter street address"
                              prefix={<Icon type="search" />}
                              {...getInputProps()}
                            />
                            <Select
                              style={{
                                opacity: 0,
                                width: '90%',
                                position: 'relative',
                                margin: 'auto',
                                top: 4
                              }}
                              dropdownStyle={{ width: '90%', margin: 'auto' }}
                              showSearch={false}
                              open={suggestions.length > 0}
                            >
                              {suggestions.map(suggestion => {
                                return (
                                  <Option
                                    {...getSuggestionItemProps(suggestion)}
                                    key={suggestion.id}
                                    value={suggestion.description}
                                  >
                                    {suggestion.description}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        );
                      }}
                    </PlacesAutocomplete>
                  </GridItem>{' '}
                </GridContainer>
              </TabPane>
              <TabPane tab="Features" key="2">
                <br />
                <div style={{ margin: 'auto' }}>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        borderBottom: '1px solid rgba(229, 233, 242, 0.9)',
                        maxWidth: '65em'
                      }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: '1.5em',
                          whiteSpace: 'nowrap',
                          float: 'right'
                        }}
                        control={
                          <Switch
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                            checked={state.tabcreation}
                            onChange={e =>
                              setState(prevState => ({
                                ...prevState,
                                tabcreation: e
                              }))
                            }
                          />
                        }
                        label={
                          <div
                            style={{
                              position: 'absolute',
                              left: 25,
                              marginTop: '-0.7em'
                            }}
                          >
                            <p style={{ fontSize: '0.975rem' }}>
                              <AiOutlineUser
                                style={{
                                  fontSize: '1.3em',
                                  marginRight: '0.3em',
                                  marginBottom: '-0.2em'
                                }}
                              />
                              Tab Creation
                            </p>
                            {/*<br /><p style={{marginTop:"-2.7em",fontSize:"0.775rem",color:"grey"}}>Allow your sales force to access tab creation on the sales screen </p>*/}
                          </div>
                        }
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        borderBottom: '1px solid rgba(229, 233, 242, 0.9)',
                        maxWidth: '65em'
                      }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: '1.5em',
                          marginTop: '1.5em',
                          whiteSpace: 'nowrap',
                          float: 'right'
                        }}
                        control={
                          <Switch
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                            checked={state.yocopayment}
                            onChange={e =>
                              setState(prevState => ({
                                ...prevState,
                                yocopayment: e
                              }))
                            }
                          />
                        }
                        label={
                          <div
                            style={{
                              position: 'absolute',
                              left: 25,
                              marginTop: '-0.7em'
                            }}
                          >
                            <p style={{ fontSize: '0.975rem' }}>
                              <AiOutlineCreditCard
                                style={{
                                  fontSize: '1.3em',
                                  marginRight: '0.3em',
                                  marginBottom: '-0.2em'
                                }}
                              />
                              Yoco Payment
                            </p>
                            {/*<br /><p style={{marginTop:"-2.7em",fontSize:"0.775rem",color:"grey"}}>Allow your sales force to access tab creation on the sales screen </p>*/}
                          </div>
                        }
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        borderBottom: '1px solid rgba(229, 233, 242, 0.9)',
                        maxWidth: '65em'
                      }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: '1.5em',
                          marginTop: '1.5em',
                          whiteSpace: 'nowrap',
                          float: 'right'
                        }}
                        control={
                          <Switch
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                            checked={state.addItemRequests}
                            onChange={e =>
                              setState(prevState => ({
                                ...prevState,
                                addItemRequests: e
                              }))
                            }
                          />
                        }
                        label={
                          <div
                            style={{
                              position: 'absolute',
                              left: 25,
                              marginTop: '-0.7em'
                            }}
                          >
                            <p style={{ fontSize: '0.975rem' }}>
                              <AiOutlineAppstoreAdd
                                style={{
                                  fontSize: '1.3em',
                                  marginRight: '0.3em',
                                  marginBottom: '-0.2em'
                                }}
                              />
                              Add Item Requests
                            </p>
                          </div>
                        }
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        borderBottom: '1px solid rgba(229, 233, 242, 0.9)',
                        maxWidth: '65em'
                      }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: '1.5em',
                          marginTop: '1.5em',
                          whiteSpace: 'nowrap',
                          float: 'right'
                        }}
                        control={
                          <Switch
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                            checked={state.showFav}
                            onChange={e =>
                              setState(prevState => ({
                                ...prevState,
                                showFav: e
                              }))
                            }
                          />
                        }
                        label={
                          <div
                            style={{
                              position: 'absolute',
                              left: 25,
                              marginTop: '-0.7em'
                            }}
                          >
                            <p style={{ fontSize: '0.975rem' }}>
                              <GrFavorite
                                style={{
                                  fontSize: '1.1em',
                                  marginRight: '0.3em',
                                  marginBottom: '-0.1em'
                                }}
                              />
                              Show Favourite
                            </p>
                          </div>
                        }
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        borderBottom: '1px solid rgba(229, 233, 242, 0.9)',
                        maxWidth: '65em'
                      }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: '1.5em',
                          marginTop: '1.5em',
                          whiteSpace: 'nowrap',
                          float: 'right'
                        }}
                        control={
                          <Switch
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                            checked={state.showKitch}
                            onChange={e =>
                              setState(prevState => ({
                                ...prevState,
                                showKitch: e
                              }))
                            }
                          />
                        }
                        label={
                          <div
                            style={{
                              position: 'absolute',
                              left: 25,
                              marginTop: '-0.7em'
                            }}
                          >
                            <p style={{ fontSize: '0.975rem' }}>
                              <BsLayoutTextSidebarReverse
                                style={{
                                  fontSize: '1em',
                                  marginRight: '0.3em',
                                  marginBottom: '-0.1em'
                                }}
                              />
                              Create Kitchen/Bar Orders
                            </p>
                          </div>
                        }
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        borderBottom: '1px solid rgba(229, 233, 242, 0.9)',
                        maxWidth: '65em'
                      }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: '1.5em',
                          marginTop: '1.5em',
                          whiteSpace: 'nowrap',
                          float: 'right'
                        }}
                        control={
                          <Switch
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                            checked={state.stockAlert}
                            onChange={e =>
                              setState(prevState => ({
                                ...prevState,
                                stockAlert: e
                              }))
                            }
                          />
                        }
                        label={
                          <div
                            style={{
                              position: 'absolute',
                              left: 25,
                              marginTop: '-0.7em'
                            }}
                          >
                            <p style={{ fontSize: '0.975rem' }}>
                              <FiAlertTriangle
                                style={{
                                  fontSize: '1.3em',
                                  marginRight: '0.3em',
                                  marginBottom: '-0.2em'
                                }}
                              />
                              Negative Stock Alerts
                            </p>
                          </div>
                        }
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        borderBottom: '1px solid rgba(229, 233, 242, 0.9)',
                        maxWidth: '65em'
                      }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: '1.5em',
                          marginTop: '1.5em',
                          whiteSpace: 'nowrap',
                          float: 'right'
                        }}
                        control={
                          <Switch
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                            checked={state.addTipA}
                            onChange={e =>
                              setState(prevState => ({
                                ...prevState,
                                addTipA: e
                              }))
                            }
                          />
                        }
                        label={
                          <div
                            style={{
                              position: 'absolute',
                              left: 25,
                              marginTop: '-0.7em'
                            }}
                          >
                            <p style={{ fontSize: '0.975rem' }}>
                              <BsLayoutTextSidebarReverse
                                style={{
                                  fontSize: '1.3em',
                                  marginRight: '0.3em',
                                  marginBottom: '-0.2em'
                                }}
                              />
                              Add 10% Graduity Automatically
                            </p>
                          </div>
                        }
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ maxWidth: '65em' }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: '1.5em',
                          marginTop: '1.5em',
                          whiteSpace: 'nowrap',
                          float: 'right'
                        }}
                        control={
                          <Switch
                            style={{ marginRight: '1em', marginLeft: '1em' }}
                            checked={state.voidPin}
                            onChange={e =>
                              setState(prevState => ({
                                ...prevState,
                                voidPin: e,
                                voidPinNumber: Math.floor(
                                  1000 + Math.random() * 9000
                                )
                              }))
                            }
                          />
                        }
                        label={
                          <div
                            style={{
                              position: 'absolute',
                              left: 25,
                              marginTop: '-0.7em'
                            }}
                          >
                            <p style={{ fontSize: '0.975rem' }}>
                              <MdDialpad
                                style={{
                                  fontSize: '1.3em',
                                  marginRight: '0.3em',
                                  marginBottom: '-0.2em'
                                }}
                              />
                              Void / Refund PIN
                            </p>
                          </div>
                        }
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {state.voidPin > 0 ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Generated Void / Refund PIN"
                          id="voidPinNumber"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: state.voidPinNumber,
                            type: 'number'
                          }}
                        />
                      </GridItem>
                    ) : null}
                  </GridContainer>
                </div>
              </TabPane>
              {/* <TabPane tab="Sale Options" key="3">
                <h1>Coming soon</h1>
              </TabPane> */}
              {state.deliveryActive ? (
                <TabPane tab="Delivery" key="4">
                  <br />
                  <GridContainer>
                    <GridItem
                      style={{
                        display: 'flex',
                        marginBottom: '1em',
                        marginLeft: '1em'
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            style={{ marginRight: '0.5em' }}
                            checked={state.showLogo}
                            onChange={e => onSwitchLogo(e)}
                          />
                        }
                        label="Show Images"
                      />
                    </GridItem>{' '}
                  </GridContainer>{' '}
                  <Row>
                    {state.showLogo ? (
                      <div>
                        <Col
                          style={{
                            border: '1px solid lightgrey',
                            borderRadius: '0.5em',
                            margin: 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                            height: '20em',
                            display: 'flex'
                          }}
                          xs={11}
                          sm={11}
                          md={11}
                        >
                          <Upload
                            name="logo"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            customRequest={handleImageUpload}
                            onChange={handleLogoImage}
                            loading={state.loadingUpload}
                            accept="image/*"
                          >
                            <Button
                              style={{
                                width: '12em',
                                backgroundColor: '#00CC70',
                                borderRadius: '0.3em',
                                zIndex: 9,
                                marginLeft: '0.5em'
                              }}
                              color="primary"
                            >
                              Edit Logo
                            </Button>
                          </Upload>{' '}
                          <br />
                          {state.logo || state.logoBase64 ? (
                            <img
                              className={'image-appear-admin'}
                              src={
                                state.logoBase64
                                  ? state.logoBase64
                                  : server + state.logo
                              }
                              alt="avatar"
                              style={{ borderRadius: '0.3em' }}
                            />
                          ) : null}{' '}
                        </Col>
                        <Col span={1} />
                        <Col
                          style={{
                            border: '1px solid lightgrey',
                            borderRadius: '0.5em',
                            margin: 'auto',
                            textAlign: 'center',
                            overflow: 'hidden',
                            height: '20em',
                            display: 'flex'
                          }}
                          xs={11}
                          sm={11}
                          md={11}
                        >
                          <Upload
                            name="mainImage"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            customRequest={handleImageUpload}
                            onChange={handleMainImage}
                            loading={state.loadingUpload}
                            accept="image/*"
                          >
                            <Button
                              style={{
                                marginLeft: '0.5em',
                                width: '12em',
                                backgroundColor: '#00CC70',
                                borderRadius: '0.3em',
                                zIndex: 9
                              }}
                              color="primary"
                            >
                              Edit Head Image
                            </Button>
                          </Upload>{' '}
                          <br />
                          {state.mainImage || state.mainBase64 ? (
                            <img
                              className={'image-appear-admin'}
                              src={
                                state.mainBase64
                                  ? state.mainBase64
                                  : server + state.mainImage
                              }
                              alt="avatar"
                              style={{
                                borderRadius: '0.5em'
                              }}
                            />
                          ) : null}{' '}
                        </Col>
                      </div>
                    ) : null}
                  </Row>
                  <GridContainer>
                    <GridItem xs={3} sm={3} md={3}>
                      <CustomSelect
                        labelText="Industry"
                        id="industry"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e =>
                            setState(prevState => ({
                              ...prevState,
                              industry: e.target.value
                            })),
                          value: state.industry
                        }}
                        menuItems={[
                          {
                            value: 'butchery',
                            name: 'Butchery'
                          },
                          {
                            value: 'hardware',
                            name: 'Hardware'
                          },
                          {
                            value: 'food',
                            name: 'Food'
                          },
                          {
                            value: 'retail',
                            name: 'Retail'
                          },
                          {
                            value: 'carwash',
                            name: 'Carwash'
                          }
                        ]}
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <CustomSelect
                        labelText="Delivery Radius"
                        id="radius"
                        formControlProps={{
                          fullWidth: true
                        }}
                        disabled={state.radius === 0 ? true : false}
                        inputProps={{
                          onChange: e => onChangeEventRadius(e),
                          value: state.radius,
                          disabled: state.radius === 0 ? true : false
                        }}
                        menuItems={[
                          {
                            value: 5,
                            name: '5 KM'
                          },
                          {
                            value: 10,
                            name: '10 KM'
                          },
                          {
                            value: 15,
                            name: '15 KM'
                          }
                        ]}
                      />
                    </GridItem>
                    <GridItem style={{ display: 'flex' }} xs={3} sm={3} md={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            style={{ marginRight: '0.5em' }}
                            checked={state.radius === 0 ? true : false}
                            onChange={e => onSwitchCollectOnly(e)}
                          />
                        }
                        label="Collect Only"
                      />
                    </GridItem>
                    <GridItem xs={7} sm={7} md={7}>
                      {state.industry === 'food' ? (
                        <CustomSelectMulti
                          labelText="Select Categories"
                          id="foodtypes"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => handleChangeRoles(e),
                            value: state.categories || [],
                            open: state.multiOpen,
                            onClose: e =>
                              setState(prevState => ({
                                ...prevState,
                                multiOpen: false
                              })),
                            onOpen: e =>
                              setState(prevState => ({
                                ...prevState,
                                multiOpen: true
                              }))
                          }}
                          menuItems={[
                            { value: 'Breakfast', name: 'Breakfast' },
                            { value: 'Burgers', name: 'Burgers' },
                            { value: 'Chicken', name: 'Chicken' },
                            { value: 'Chinese', name: 'Chinese' },
                            { value: 'Dessert', name: 'Dessert' },
                            { value: 'Indian Food', name: 'Indian Food' },
                            { value: 'Italian', name: 'Italian' },
                            { value: 'Liquor', name: 'Liquor' },
                            { value: 'Pizza', name: 'Pizza' },
                            { value: 'Seafood', name: 'Seafood' },
                            { value: 'Water', name: 'Water' }
                          ]}
                        />
                      ) : null}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {state.radius > 0 ? (
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="0km - 5km Delivery Fee"
                          id="delivery5"
                          formControlProps={{
                            style: {
                              minWidth: '11em'
                            },
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => onChangeEvents(e),
                            value: state.delivery[5],
                            type: 'number'
                          }}
                        />
                      </GridItem>
                    ) : null}
                    {state.radius > 5 ? (
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="5km - 10km Delivery Fee"
                          id="delivery10"
                          formControlProps={{
                            style: {
                              minWidth: '11em'
                            },
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => onChangeEvents(e),
                            value: state.delivery[10],
                            type: 'number'
                          }}
                        />
                      </GridItem>
                    ) : null}
                    {state.radius > 10 ? (
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="10km - 15km Delivery Fee"
                          id="delivery15"
                          formControlProps={{
                            style: {
                              minWidth: '12em'
                            },
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => onChangeEvents(e),
                            value: state.delivery[15],
                            type: 'number'
                          }}
                        />
                      </GridItem>
                    ) : null}
                  </GridContainer>
                  <GridContainer>
                    {state.radius > 0 ? (
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="Average delivery time"
                          id="average"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => onChangeEvents(e),
                            value: state.average,
                            type: 'number'
                          }}
                        />
                      </GridItem>
                    ) : null}
                  </GridContainer>
                  <GridContainer>
                    <GridItem
                      style={{ fontSize: '20px', fontWeight: 700 }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {'Trading Hours'}
                    </GridItem>
                  </GridContainer>{' '}
                  <br />{' '}
                  <GridContainer>
                    <GridItem
                      style={{ fontSize: '16px', fontWeight: 500 }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {'Mon-Fri'}
                    </GridItem>{' '}
                  </GridContainer>
                  <GridContainer style={{ whiteSpace: 'nowrap' }}>
                    <GridItem xs={3} sm={3} md={3}>
                      {'Start'}
                      <TimePicker
                        style={{ marginLeft: '0.5em' }}
                        value={moment(state.startMonFri, normalTimeFormat)}
                        format={normalTimeFormat}
                        onChange={(date, dateString) =>
                          onChangeTimeStartMonFri(date, dateString)
                        }
                        allowClear={false}
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      {'End'}
                      <TimePicker
                        style={{ marginLeft: '0.5em' }}
                        value={moment(state.endMonFri, normalTimeFormat)}
                        format={normalTimeFormat}
                        onChange={(date, dateString) =>
                          onChangeTimeEndMonFri(date, dateString)
                        }
                        allowClear={false}
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            style={{ marginRight: '0.5em' }}
                            checked={
                              state.start
                                ? state.start[1]
                                  ? true
                                  : false
                                : false
                            }
                            onChange={e => onSwitchTime(e)}
                          />
                        }
                        label="Open"
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem
                      style={{ fontSize: '16px', fontWeight: 500 }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {'Saturday'}
                    </GridItem>{' '}
                  </GridContainer>
                  <GridContainer style={{ whiteSpace: 'nowrap' }}>
                    <GridItem xs={3} sm={3} md={3}>
                      {'Start'}
                      <TimePicker
                        style={{ marginLeft: '0.5em' }}
                        value={moment(state.startSat, normalTimeFormat)}
                        format={normalTimeFormat}
                        onChange={(date, dateString) =>
                          onChangeTimeStartMonFri(date, dateString, 'sat')
                        }
                        allowClear={false}
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      {'End'}
                      <TimePicker
                        style={{ marginLeft: '0.5em' }}
                        value={moment(state.endSat, normalTimeFormat)}
                        format={normalTimeFormat}
                        onChange={(date, dateString) =>
                          onChangeTimeEndMonFri(date, dateString, 'sat')
                        }
                        allowClear={false}
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            style={{ marginRight: '0.5em' }}
                            checked={
                              state.start
                                ? state.start[6]
                                  ? true
                                  : false
                                : false
                            }
                            onChange={e => onSwitchTime(e, 'sat')}
                          />
                        }
                        label="Open"
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem
                      style={{ fontSize: '16px', fontWeight: 500 }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {'Sunday'}
                    </GridItem>{' '}
                  </GridContainer>
                  <GridContainer style={{ whiteSpace: 'nowrap' }}>
                    <GridItem xs={3} sm={3} md={3}>
                      {'Start'}
                      <TimePicker
                        style={{ marginLeft: '0.5em' }}
                        value={moment(state.startSun, normalTimeFormat)}
                        format={normalTimeFormat}
                        onChange={(date, dateString) =>
                          onChangeTimeStartMonFri(date, dateString, 'sun')
                        }
                        allowClear={false}
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      {'End'}
                      <TimePicker
                        style={{ marginLeft: '0.5em' }}
                        value={moment(state.endSun, normalTimeFormat)}
                        format={normalTimeFormat}
                        onChange={(date, dateString) =>
                          onChangeTimeEndMonFri(date, dateString, 'sun')
                        }
                        allowClear={false}
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            style={{ marginRight: '0.5em' }}
                            checked={
                              state.start
                                ? state.start[0]
                                  ? true
                                  : false
                                : false
                            }
                            onChange={e => onSwitchTime(e, 'sun')}
                          />
                        }
                        label="Open"
                      />
                    </GridItem>
                  </GridContainer>
                  <br />{' '}
                </TabPane>
              ) : null}
            </StyledTabs>
            <CardFooter
              style={{
                float: 'right',
                borderTop: '1px solid rgba(229, 233, 242, 0.9)',
                display: 'block',
                width: '99%',
                textAlign: 'right'
              }}
            >
              <Button
                style={{
                  textTransform: 'initial',
                  paddingLeft: '3em',
                  paddingRight: '3em',
                  marginLeft: '-0.7em',
                  marginTop: '1em',
                  backgroundColor: '#00CC70',
                  borderRadius: '5em'
                }}
                onClick={state.submitForm}
                color="primary"
              >
                {'Save'}
              </Button>
            </CardFooter>
          </CardBody>
        </Card>
      ) : null}
      <Snackbar {...props} />
    </Spin>
  );
};

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems
});

const mapDispatchToProps = {
  getOrg,
  updateOrg,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  geocodeByAddress,
  setBreadCrumbs,
  resetActionBreadCrumb
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Settings));
