/* eslint-disable no-loop-func */
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Icon, Layout, Select, Spin, Table } from 'antd';
import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
// import { MemoBillsMenuReceipt, MemoBillsReceipt } from './Receipts.jsx';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineTeam
} from 'react-icons/ai';
import { connect } from 'react-redux';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
import NumPad from 'src/components/NumPad';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  addMenuItem,
  endSession,
  removeLogin,
  resetUser,
  updateToken
} from 'src/redux/actions/backend';
import {
  addExpensePayment,
  addToBillDashOnline,
  createBillDash,
  createBillDashPlatform,
  getAddMenuItemData,
  getBillDash,
  getBillReceiptDash,
  getMenuDash,
  getPaymentReasons,
  getVoidReasons,
  removeBillDash,
  removeFromBillDash,
  resetSync,
  switchLightDark,
  syncData,
  updateBillDash,
  updateBillProcDash,
  updateBillSplitChargeDash,
  updateReduxBills,
  updateReduxSearchMenu,
  updateSyncReduxBills,
  voidBillItem
} from 'src/redux/actions/dashboard';
import { initLoader, removeLoader } from 'src/redux/actions/initdashloader';
import {
  findNotifications,
  removeNotification,
  sendNotification
} from 'src/redux/actions/notifications';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import styled from 'styled-components';
import './Bills.css';
import {
  MemoBills,
  MemoBillsMenu,
  MemoPhoneBills,
  MemoPhoneBillsMenu
} from './Bills.jsx';
import DashHeader from './Header.jsx';
import MemoMenus from './ItemsMenu.jsx';
import PhoneFooter from './PhoneFooter.jsx';
import PhoneHeader from './PhoneHeader.jsx';

const apiCall = 'vpos/api/vpos/';
const apiCalluser = 'vpos/api/user/';
const server = process.env.REACT_APP_GLOBAL_SERVER;

const { Option } = Select;
const { Number } = NumPad;
const { Content } = Layout;

const styles = () => ({
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  }
});

const PayItemsColumns = [
  {
    title: 'Item',
    dataIndex: 'name',
    key: 'name',
    align: 'left'
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    width: 80,
    align: 'center',
    render: text => <div>R{text.toFixed(2)}</div>
  },
  {
    title: 'QTY',
    dataIndex: 'qty',
    key: 'qty',
    width: 80,
    align: 'center'
  }
  // {
  //   title: '',
  //   dataIndex: 'disc',
  //   key: 'disc',
  //   width: 10,
  //   align: 'center'
  // },
  // {
  //   title: 'Subtotal',
  //   dataIndex: 'subtotal',
  //   key: 'subtotal',
  //   width: 80,
  //   align: 'center',
  //   render: text => <div>R{text.toFixed(2)}</div>
  // }
];
let initState = true;
let setAlert = undefined;
let setReceipt = undefined;
let setDash = undefined;
let setPayView = undefined;
let setAddMenuItem = undefined;
let setHeader = undefined;
let setBills = undefined;
//let setMenus = undefined;
let props = {};
const splitColours = [
  '#00CC70',
  'grey',
  '#00CC70',
  'grey',
  '#00CC70',
  'grey',
  '#00CC70',
  'grey',
  '#00CC70'
];
let init = true;
var syncDelay = false;
var syncLockCheck;
var payed = false;
let timer = 0;
const autoTime = 180;
var autoLogoutInterval;
let loginSessionPingInterval;
var checkOnlineInterval;
var carousel;

const getMenus = (msg, bill, index) => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const showFav = props.local.user.showFav;

  props
    .getMenuDash(token, showFav)
    .then(() => {
      if (msg) {
        props.removeLoader();
        setDash(prevState => ({ ...prevState, loading: false }));
        props.openSnackbar(msg);
      } else {
        getBills(bill, false, index);
      }
    })
    .catch(err => {
      props.removeLoader();
      if (err.includes('Login Expired')) {
        onError(err);
        props.resetSync();
        props.resetUser(token);
      } else {
        onError(err);
      }
    });
};

const cardMenuSwitch = navToMenu => {
  setBills(prevState => ({
    ...prevState,
    selectedVoidItem: undefined
  }));
  setDash(prevState => ({
    ...prevState,
    billMenu: !dashState.billMenu,
    itemsWidth: dashState.itemsWidth ? '' : '81vw'
  }));
  if (navToMenu && window.isPhone) {
    navToView('bill');
  }
};

const getBillId = index => {
  if (index >= 0) {
    setBills(prevState => ({
      ...prevState,
      billid: billsState.bills[Object.keys(billsState.bills)[index]]._id,
      currentSlide: index,
      goToSlide: index,
      selectedMenuKey: index
    }));
  }
};

const getBillIdMenu = sKey => {
  var key = sKey;
  if (key) {
    key = parseInt(key.key);
    if (carousel.current) {
      carousel.current.slide(key ? key : 0, 0);
    }
    setBills(prevState => ({
      ...prevState,
      billid: billsState.bills[Object.keys(billsState.bills)[key]]._id,
      currentSlide: key,
      goToSlide: key,
      selectedMenuKey: key
    }));
  }
};

var syncFirst = false;
const getBills = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const billid = searchParams.get('billid');
  const token = searchParams.get('token');
  props
    .getBillDash(token)
    .then(() => {
      let openBills = {};
      for (var prop in props.bill) {
        if (props.bill[prop].state === 'open') {
          openBills[props.bill[prop]._id] = props.bill[prop];
        }
      }
      if (openBills[billid]) {
        props.removeLoader();
        let orderBill = {};
        orderBill[billid] = openBills[billid];
        orderBill[billid].items = [];
        orderBill[billid].total = 0;
        setBills(prevState => ({
          ...prevState,
          bills: orderBill || {},
          goToSlide: 0,
          billid: orderBill[billid]._id,
          currentSlide: 0,
          selectedMenuKey: 0
        }));
        setStateBillCount(Object.keys(orderBill).length);
        setDash(prevState => ({
          ...prevState,
          loading: false,
          foolsLoader: false
        }));
        window.lockSync = false;
        props.splash();
        setClosed(false);
      } else {
        onError('This bill is closed. You cannot add any items.');
        setDash(prevState => ({
          ...prevState,
          loading: false,
          foolsLoader: false
        }));
        setClosed(true);
        props.splash();
      }
    })
    .catch(err => {
      onError(err);
    });
};

const addToBillQty = (selectedItem, setMenus) => {
  setBills(prevState => ({
    ...prevState,
    selectedVoidItem: undefined
  }));
  setMenus(prevState => ({
    ...prevState,
    selectedItem: selectedItem,
    showQtyNumber: true,
    cookingItems: {},
    subCatItem: false,
    initCooking: false
  }));
};

const numpadTheme = {
  header: {
    primaryColor: '#00CC70',
    secondaryColor: 'transparent',
    highlightColor: 'transparent',
    backgroundColor: 'transparent'
  },
  body: {
    primaryColor: 'transparent',
    secondaryColor: 'transparent',
    highlightColor: 'transparent',
    backgroundColor: 'transparent'
  },
  panel: {
    backgroundColor: 'transparent'
  },
  global: {}
};

const payBill = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const billid = searchParams.get('billid');
  const token = searchParams.get('token');
  const waiter = searchParams.get('waiter');
  let bills = JSON.parse(JSON.stringify(billsState.bills));
  const total = bills[billid].items.length;
  if (total === 0) {
    props.openSnackbar('No items to add');
    return;
  }
  setDash(prevState => ({ ...prevState, loading: true }));
  $.ajax({
    type: 'GET',
    url: server + apiCalluser + 'checkloggedin?billid=' + billid,
    contentType: 'application/json',
    crossDomain: true,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    },
    success: async response => {
      if (response === 'Not logged in') {
        let current = 0;
        for (var i = 0; i < bills[billid].items.length; i++) {
          props
            .addToBillDashOnline(
              token,
              bills[billid].items[i],
              billid,
              bills[billid].items[i].qty,
              waiter
            )
            .then(result => {
              current++;
              if (total === current) {
                if (window.isPhone) {
                  navToView('bill');
                }
                props.openSnackbar('Successfully added the items');
                getMenus(null, billsState.billid, billsState.currentSlide);
                props.sendNotification(
                  waiter,
                  [waiter],
                  'A customer has added items to order ' +
                    bills[billid].orderno,
                  'customerAddedItems',
                  true,
                  'Customer Added Items',
                  token
                );
                window.pubnub.signal(
                  {
                    channel: waiter,
                    message: 'CustomerAdded'
                  },
                  function() {}
                );
              }
            })
            .catch(err => {
              onError(
                'Failed to add items. Make sure you have an active internet connection.'
              );
            });
        }
      } else if (response === 'Bill is closed') {
        onError('This bill is closed. You cannot add any items.');
        setDash(prevState => ({
          ...prevState,
          loading: false,
          foolsLoader: false
        }));
        setClosed(true);
      } else {
        window.pubnub.signal(
          {
            channel: waiter,
            message: 'OnlineCheckPing'
          },
          function() {}
        );
        let checks = 0;
        let checkInterval = setInterval(() => {
          checks++;
          if (checks === 5) {
            clearInterval(checkInterval);
            onError(
              "Failed to add items. The waiter's device is in offline mode please try again later."
            );
            return;
          }
          if (isOnline) {
            isOnline = false;
            clearInterval(checkInterval);
            let current = 0;
            for (var i = 0; i < bills[billid].items.length; i++) {
              props
                .addToBillDashOnline(
                  token,
                  bills[billid].items[i],
                  billid,
                  bills[billid].items[i].qty,
                  waiter
                )
                .then(result => {
                  current++;
                  if (total === current) {
                    if (window.isPhone) {
                      navToView('bill');
                    }
                    props.openSnackbar('Successfully added the items');
                    getMenus(null, billsState.billid, billsState.currentSlide);
                    props.sendNotification(
                      waiter,
                      [waiter],
                      'A customer has added items to order ' +
                        bills[billid].orderno,
                      'customerAddedItems',
                      true,
                      'Customer Added Items',
                      token
                    );
                    window.pubnub.signal(
                      {
                        channel: waiter,
                        message: 'CustomerAdded'
                      },
                      function() {}
                    );
                  }
                })
                .catch(err => {
                  onError(
                    'Failed to add items. Make sure you have an active internet connection.'
                  );
                });
            }
          }
        }, 1000);
      }
    },
    error: async xhr => {
      onError(
        'Failed to add items. Make sure you have an active internet connection.'
      );
    }
  });
};

const addToBill = async (
  selectedItem,
  add,
  key,
  addQty,
  setMenus,
  _itemMenuStatesN,
  priceCheck,
  openQtyAdd
) => {
  if (selectedItem.qtycheck && !add && !addQty) {
    if (key >= 0) {
      selectedItem.key = key;
    }
    addToBillQty(selectedItem, setMenus);
    return;
  }
  var item = selectedItem;
  var billid = billsState.billid;
  var menu = false;
  if (key >= 0) {
    item.key = key;
  }
  if (!item.itemid) {
    item.itemid = item._id;
  }
  var stockCheck = {
    stock: true,
    name: ''
  };
  let bills = JSON.parse(JSON.stringify(billsState.bills));
  var check = false;
  var qty = 1;
  if (add) {
    if (item.initQty > 0) {
      qty = item.initQty;
    }
    check = true;
    if (item.sellPrice) {
      item.price = Math.round(bills[billid].items[key].price * 100) / 100;
    }
    if (bills[billid].items[key].procount) {
      bills[billid].items[key].procount = bills[billid].items[key].procount + 1;
    } else {
      bills[billid].items[key].procount = 1;
    }
    bills[billid].items[key].processed = false;
    bills[billid].items[key].qty = parseFloat(
      (bills[billid].items[key].qty + qty).toFixed(2)
    );
    bills[billid].items[key].qtyLeft = parseFloat(
      (bills[billid].items[key].qtyLeft + qty).toFixed(2)
    );
    bills[billid].total = parseFloat(
      (bills[billid].total + bills[billid].items[key].price * qty).toFixed(2)
    );

    bills[billid].amountLeft = parseFloat(
      (bills[billid].amountLeft + bills[billid].items[key].price * qty).toFixed(
        2
      )
    );
    if (!bills[billid].items[key].cookIns) {
      bills[billid].items[key].cookIns = [];
    }
  } else {
    if (addQty > 0) {
      qty = addQty;
    }
    menu = true;
    if (bills[billid]._id === billsState.billid) {
      var itemBillCheck = false;
      var localKey = 0;
      for (var i = 0; i < bills[billid].items.length; i++) {
        if (
          bills[billid].items[i].itemid === item.itemid &&
          bills[billid].items[i].price === parseFloat(item.sellPrice)
        ) {
          localKey = i;
          itemBillCheck = true;
          break;
        }
      }
      if (itemBillCheck) {
        if (item.cookIns && bills[billid].items[localKey].cookIns) {
          if (
            JSON.stringify(item.cookIns) !==
              JSON.stringify(bills[billid].items[localKey].cookIns) ||
            bills[billid].items[localKey].state === 'payed'
          ) {
            check = false;
          } else {
            if (
              JSON.stringify(item.cookIns) ===
                JSON.stringify(bills[billid].items[localKey].cookIns) &&
              (item.initQty
                ? openQtyAdd && item.initQty === qty
                  ? true
                  : !openQtyAdd
                  ? true
                  : false
                : false)
            ) {
              check = true;
              if (item.sellPrice) {
                item.price =
                  Math.round(bills[billid].items[localKey].price * 100) / 100;
              }
              var procCount = qty;
              if (item.initQty && item.initQty !== qty && openQtyAdd) {
                procCount = qty / item.initQty;
              }
              if (bills[billid].items[localKey].procount) {
                bills[billid].items[localKey].procount =
                  bills[billid].items[localKey].procount + procCount;
              } else {
                bills[billid].items[localKey].procount = procCount;
              }
              bills[billid].items[localKey].processed = false;
              if (bills[billid].items[localKey].qty === 0) {
                if (openQtyAdd) {
                  bills[billid].items[localKey].initQty = qty;
                  bills[billid].items[localKey].openQty = openQtyAdd;
                  item.initQty = qty;
                  item.openQty = openQtyAdd;
                } else {
                  item.initQty = 1;
                  bills[billid].items[localKey].initQty = 1;
                }
              }
              bills[billid].items[localKey].qty = parseFloat(
                (bills[billid].items[localKey].qty + qty).toFixed(2)
              );
              bills[billid].items[localKey].qtyLeft = parseFloat(
                (bills[billid].items[localKey].qtyLeft + qty).toFixed(2)
              );
              bills[billid].total = parseFloat(
                (
                  bills[billid].total +
                  bills[billid].items[localKey].price * qty
                ).toFixed(2)
              );

              bills[billid].amountLeft = parseFloat(
                (
                  bills[billid].amountLeft +
                  bills[billid].items[localKey].price * qty
                ).toFixed(2)
              );
            } else {
              check = false;
            }
          }
        } else {
          if (
            bills[billid].items[localKey].state === 'payed' ||
            item.initQty !== qty
          ) {
            check = false;
          } else {
            if (
              item.initQty
                ? openQtyAdd && item.initQty === qty
                  ? true
                  : !openQtyAdd
                  ? true
                  : false
                : false
            ) {
              check = true;
              if (item.sellPrice) {
                item.price =
                  Math.round(bills[billid].items[localKey].price * 100) / 100;
              }
              var procCount = qty;
              if (item.initQty && item.initQty !== qty && openQtyAdd) {
                procCount = qty / item.initQty;
              }
              if (bills[billid].items[localKey].procount) {
                bills[billid].items[localKey].procount =
                  bills[billid].items[localKey].procount + procCount;
              } else {
                bills[billid].items[localKey].procount = procCount;
              }
              bills[billid].items[localKey].processed = false;
              if (bills[billid].items[localKey].qty === 0) {
                if (openQtyAdd) {
                  bills[billid].items[localKey].initQty = qty;
                  bills[billid].items[localKey].openQty = openQtyAdd;
                  item.initQty = qty;
                  item.openQty = openQtyAdd;
                } else {
                  bills[billid].items[localKey].initQty = 1;
                  item.initQty = 1;
                }
              }
              bills[billid].items[localKey].qty = parseFloat(
                (bills[billid].items[localKey].qty + qty).toFixed(2)
              );
              bills[billid].items[localKey].qtyLeft = parseFloat(
                (bills[billid].items[localKey].qtyLeft + qty).toFixed(2)
              );
              bills[billid].total = parseFloat(
                (
                  bills[billid].total +
                  bills[billid].items[localKey].price * qty
                ).toFixed(2)
              );

              bills[billid].amountLeft = parseFloat(
                (
                  bills[billid].amountLeft +
                  bills[billid].items[localKey].price * qty
                ).toFixed(2)
              );
            } else {
              check = false;
            }
          }
        }
      }
    }
    if (!check) {
      if (bills[billid]._id === billsState.billid) {
        if (openQtyAdd) {
          item.initQty = qty;
          item.openQty = openQtyAdd;
        } else {
          item.initQty = 1;
        }
        var procCount = qty;
        if (openQtyAdd) {
          procCount = qty / item.initQty;
        }
        item.price = Math.round(item.sellPrice * 100) / 100;
        item.qty = qty;
        item.procount = procCount;
        item.qtyLeft = qty;
        item.itemid = item._id;
        if (item.useDim) {
          item.useDimType =
            item.stockitems && item.stockitems[0]
              ? item.stockitems[0].dimType
              : '';
        }
        if (item.cookIns) {
          if (item.cookIns.length > 0) {
            item.desc = 'With Instructions';
            for (var j = 0; j < item.cookIns.length; j++) {
              item.price = item.price + item.cookIns[j].price;
            }
            bills[billid].items.unshift(item);
            bills[billid].total = parseFloat(
              (bills[billid].total + item.price * qty).toFixed(2)
            );
            bills[billid].amountLeft = parseFloat(
              (bills[billid].amountLeft + item.price * qty).toFixed(2)
            );
          } else {
            item.cookIns = [];
            bills[billid].items.unshift(item);
            bills[billid].total = parseFloat(
              (bills[billid].total + item.price * qty).toFixed(2)
            );
            bills[billid].amountLeft = parseFloat(
              (bills[billid].amountLeft + item.price * qty).toFixed(2)
            );
          }
        } else {
          item.cookIns = [];
          bills[billid].items.unshift(item);
          bills[billid].total = parseFloat(
            (bills[billid].total + item.price * qty).toFixed(2)
          );
          bills[billid].amountLeft = parseFloat(
            (bills[billid].total + item.price * qty).toFixed(2)
          );
        }
      }
    }
  }
  if (!item.cookIns) {
    item.cookIns = [];
  }
  bills[billid].processing = true;
  setBills(prevState => ({
    ...prevState,
    bills: bills
  }));
  if (menu && setMenus) {
    setMenus(prevState => ({
      ...prevState,
      selectedItem: {},
      selectedCooking: [],
      cookingItems: {},
      showQtyNumber: false,
      initCooking: false
    }));
  }
  if (window.isPhone) {
    navToView('bill');
  }
  setUpdateStockCheck(updateStockCheck + 1);
};

const removeFromBill = async (selectedItem, key, _mKey, _iKey, all) => {
  var billno = billsState.billid;
  var item = selectedItem;
  let bills = JSON.parse(JSON.stringify(billsState.bills));
  let amountToRemove;
  var qty = 1;
  if (item.initQty > 0) {
    qty = item.initQty;
  }
  if (all) {
    amountToRemove = bills[billno].items[key].qty;
    for (var i = 0; i < bills[billno].items[key].qty; i++) {
      bills[billno].total = parseFloat(
        (bills[billno].total - bills[billno].items[key].price * qty).toFixed(2)
      );

      bills[billno].amountLeft = parseFloat(
        (
          bills[billno].amountLeft -
          bills[billno].items[key].price * qty
        ).toFixed(2)
      );
    }
    bills[billno].items.splice(key, 1);
  } else if (
    bills[billno].items[key].qty > qty ||
    bills[billno].items[key].void
  ) {
    if (
      bills[billno].items[key].processing ||
      bills[billno].items[key].procount > 0
    ) {
      bills[billno].items[key].procount = bills[billno].items[key].procount - 1;
      if (bills[billno].items[key].procount < 1) {
        bills[billno].items[key].processing = false;
      }
    }
    bills[billno].items[key].qty = parseFloat(
      (bills[billno].items[key].qty - qty).toFixed(2)
    );
    bills[billno].items[key].qtyLeft = parseFloat(
      (bills[billno].items[key].qtyLeft - qty).toFixed(2)
    );
    bills[billno].total = parseFloat(
      (bills[billno].total - bills[billno].items[key].price * qty).toFixed(2)
    );

    bills[billno].amountLeft = parseFloat(
      (bills[billno].amountLeft - bills[billno].items[key].price * qty).toFixed(
        2
      )
    );
  } else {
    bills[billno].total = parseFloat(
      (bills[billno].total - bills[billno].items[key].price * qty).toFixed(2)
    );

    bills[billno].amountLeft = parseFloat(
      (bills[billno].amountLeft - bills[billno].items[key].price * qty).toFixed(
        2
      )
    );
    if (!bills[billno].items[key].void) {
      bills[billno].items.splice(key, 1);
    }
  }
  setBills(prevState => ({
    ...prevState,
    bills: bills
  }));
  window.lockSync = true;
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  if (all) {
    for (var i = 0; i < amountToRemove; i++) {
      setUpdateStockCheck(updateStockCheck + 1);
    }
  } else {
    setUpdateStockCheck(updateStockCheck + 1);
  }
};

const increase = (item, key) => {
  addToBill(item, true, key);
};

const decline = (item, key) => {
  removeFromBill(item, key);
};

const search = value => {
  var items = JSON.parse(JSON.stringify(props.menu));
  var subcats = JSON.parse(JSON.stringify(props.subCategories));
  if (!value) {
    props.updateReduxSearchMenu(items);
    return;
  }
  var searchItems = [
    {
      active: true,
      id: '056b5a5fbbaf6ba00',
      menuitems: [],
      name: 'Search'
    }
  ];
  for (var prop in subcats) {
    let interimItems = subcats[prop].menuitems.filter(function(menuitem) {
      return menuitem.name.toLowerCase().includes(value.toLowerCase());
    });
    searchItems[0].menuitems = searchItems[0].menuitems.concat(interimItems);
  }
  for (var i = 0; i < items.length; i++) {
    let interimItems = items[i].menuitems.filter(function(menuitem) {
      return menuitem.name.toLowerCase().includes(value.toLowerCase());
    });
    searchItems[0].menuitems = searchItems[0].menuitems.concat(interimItems);
  }
  props.updateReduxSearchMenu(searchItems);
};

const showCooking = (selectedItem, key) => {
  var item = JSON.parse(JSON.stringify(selectedItem));
  let bills = JSON.parse(JSON.stringify(billsState.bills));
  var billid = billsState.billid;
  if (item.show) {
    delete item.show;
  } else {
    item.show = true;
  }
  bills[billid].items[key] = item;
  setBills(prevState => ({
    ...prevState,
    bills: bills
  }));
};

const backToSales = () => {
  timer = autoTime;
  let bills = props.bill;
  let openBills = {};
  for (var prop in bills) {
    if (updatedPayViewBill._id === prop) {
      bills[prop] = updatedPayViewBill;
    }
  }
  for (var prop in bills) {
    if (bills[prop].state === 'open') {
      openBills[bills[prop]._id] = bills[prop];
    }
  }
  setBills(prevState => ({
    ...prevState,
    bills: openBills
  }));
  window.lockSync = false;
  setDash(prevState => ({ ...prevState, payView: false }));
};

const StyledTable = styled(Table)`
  .ant-table-fixed-header
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body {
  }
  .ant-table-header {
    border-radius: 0.28em;
    background: rgb(76, 87, 92);
    border: 1px solid rgba(207, 229, 255, 0.282) !important;
  }
  .ant-table-thead > tr > th {
    background: transparent;
    color: white;
    height: 3.4em;

    border-color: transparent;
  }
  thead {
    border-radius: 0.28em;
    background: rgba(207, 229, 255, 0.282);
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 600;
    line-height: 1.45;
  }
  .ant-table {
    border-radius: 0.27em;
    border: 1px solid lightgrey !important;
  }
  tbody {
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.05);
  }
  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    margin-bottom: -19px !important;
  }
`;

const backToBills = () => {
  timer = autoTime;
  getBills();
  setDash(prevState => ({ ...prevState, receiptView: false }));
  if (window.isPhone) {
    navToView('bills');
  }
};

const noStock = () => {
  props.openSnackbar('No Stock Left');
};

const toggleCollapsed = () => {
  timer = autoTime;
  setHeader(prevState => ({
    ...prevState,
    collapsed: !headerProps.state.collapsed
  }));
};

const onCloseDrawer = () => {
  timer = autoTime;
  setHeader(prevState => ({ ...prevState, collapsed: false }));
};

const closeDialog = () => {
  setAlert(prevState => ({ ...prevState, alert: false }));
};

const closeFormDialog = () => {
  setDash(prevState => ({
    ...prevState,
    pdopen: false,
    fdopen: false,
    errorMsg: '',
    actions: []
  }));
};

const onError = err => {
  setAlert(prevState => ({
    ...prevState,
    head: 'Sales Error',
    alert: true,
    msg: [err]
  }));
  setDash(prevState => ({ ...prevState, loading: false, foolsLoader: false }));
};

const componentWillMount = () => {
  /*eslint-disable */
  window.pubnub = new PubNub({
    publishKey: 'pub-c-dba083a8-729c-4413-aea8-0473e0fe9677',
    subscribeKey: 'sub-c-4528b54e-77df-11ea-808e-bad180999bc3'
  });
  /*eslint-enable */
  const searchParams = new URLSearchParams(window.location.search);
  window.pubnub.setUUID(
    searchParams.get('waiter') + searchParams.get('billid')
  );
  let channels = [];
  channels.push('customer' + searchParams.get('waiter'));
  window.pubnub.subscribe({
    channels: channels
  });
  window.pubnub.addListener(pubnubListner);
  const token = searchParams.get('token');
  setDash(prevState => ({ ...prevState, loading: true }));
  componentDidMount();
};

var waitingPing = false;
let isOnline;
var pubnubListner = {
  signal: function(event) {
    if (event.message === 'OnlineCheckPong') {
      isOnline = true;
    }
  }
};

const componentDidMount = () => {
  document.addEventListener('offline', setOffline, false);
  document.addEventListener('online', setOnline, false);
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  if (!window.cordova) {
    checkOnlineInterval = setInterval(() => {
      if (navigator) {
        if (!dashState.offline !== navigator.onLine) {
          setDash(prevState => ({
            ...prevState,
            offline: !dashState.offline
          }));
          if (navigator.onLine) {
            setOnline();
          } else {
            setOffline();
          }
        }
      }
    }, 1000);
  }
  window.addEventListener('resize', handleResize);
  getMenus();
  props.removeLoader();
};

const setOffline = () => {
  if (!syncLockCheck) {
    clearInterval(syncLockCheck);
    syncLockCheck = undefined;
  }
  syncFirst = false;
  window.offline = true;
  setDash(prevState => ({ ...prevState, offline: true }));
};

const setOnline = () => {
  window.offline = false;
  setDash(prevState => ({ ...prevState, offline: false }));
  const searchParams = new URLSearchParams(window.location.search);
  let channels = [];
  channels.push('customer' + searchParams.get('waiter'));

  window.pubnub.subscribe({
    channels: channels
  });
};

var pingTimeout;

const setOnlineSync = () => {
  syncFirst = true;
};

const componentWillUnmount = () => {
  clearInterval(checkOnlineInterval);
  window.pubnub.removeListener(pubnubListner);
  window.removeEventListener('resize', handleResize);
  document.removeEventListener('offline', setOffline, false);
  document.removeEventListener('online', setOnline, false);
};

const handleResize = () => {
  setDash(prevState => ({ ...prevState, windowHeight: window.innerHeight }));
};

const onChangeEvtMenu = e => {
  var value = e.currentTarget.value;
  if (e.target.id === 'qty') {
    if (value[value.length - 1] === ',') {
      value = value.substr(0, value.length - 1) + '.';
    }
    if (!value) {
      value = '0';
    }
    if (!isNaN(value)) {
      if (value !== '0.0') {
        value = (Math.round(parseFloat(value) * 100) / 100).toString();
      }
      setAddMenuItem(prevState => ({ ...prevState, qtymenu: value }));
    }
  } else if (e.target.id === 'code') {
    setAddMenuItem(prevState => ({ ...prevState, codemenu: value }));
  } else if (e.target.id === 'description') {
    setAddMenuItem(prevState => ({ ...prevState, descriptionmenu: value }));
  } else if (e.target.id === 'name') {
    if (value.length === 1) {
      value = value.toUpperCase();
    }
    setAddMenuItem(prevState => ({ ...prevState, namemenu: value }));
  } else if (e.target.id === 'sellPrice') {
    setAddMenuItem(prevState => ({ ...prevState, sellPrice: value }));
  } else if (e.target.id === 'costPrice') {
    setAddMenuItem(prevState => ({ ...prevState, costPrice: value }));
  }
};

const handleChangeType = event => {
  if (event.target.name) {
    setAddMenuItem(prevState => ({
      ...prevState,
      typemenuid: event.target.value,
      typemenu: event.currentTarget.textContent
    }));
  }
};

const handleChangeMenu = (event, key) => {
  if (event.target.name === 'color') {
    setAddMenuItem(prevState => ({
      ...prevState,
      colormenu: event.target.value
    }));
  }
  if (event.target.name === 'mainmenu') {
    setAddMenuItem(prevState => ({
      ...prevState,
      menubuttons: props.mainmenu[key.key].menubtns,
      mainmenusmenu: event.target.value
    }));
  }
};

const handleChangeRoles = event => {
  if (!event.target.value[event.target.value.length - 1]) {
    setAddMenuItem(prevState => ({ ...prevState, multiOpen: false }));
  } else {
    setAddMenuItem(prevState => ({
      ...prevState,
      mainbtnsmenu: event.target.value
    }));
  }
};

const tabCreationCheck = () => {
  return props.local.user.tabcreation;
};

const yocoPaymentCheck = () => {
  return props.local.user.yocopayment;
};

const orgIDCheck = () => {
  return props.local.user.orgid;
};

const initFoolsLoader = value => {
  setDash(prevState => ({ ...prevState, foolsLoader: value }));
};

var stateObject = {
  windowHeight: window.innerHeight,
  itemsWidth: '',
  splitExpanded: false,
  loading: true,
  foolsLoader: false,
  menu: [],
  searchMenu: [],
  cooking: {},
  customers: [],
  subCategories: {},
  subCatItems: [],
  subCatItem: false,
  initCooking: false,
  selectedCooking: [],
  cookingItems: {},
  selectedItem: {},
  showQtyNumber: false,
  tables: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  bills: {},
  billsReceipts: [],
  billid: '',
  billType: '',
  billTable: '',
  billDesc: '',
  billCusId: '',
  billPpl: '1',
  payItemsColumn: PayItemsColumns,
  payBill: { items: [] },
  currentSlide: 0,
  goToSlide: 0,
  goToSlideReceipt: 0,
  selectedMenuKey: 0,
  count: 1,
  tabPosition: 'left',
  billMenu: false,
  payView: false,
  addItemView: false,
  receiptView: false,
  billMenuReceipt: false,
  hide: 'hide',
  payBtn: 'Add Selected Items',
  processBtn: 'Process Order',
  payCash: false,
  payCard: false,
  paySplitPerson: false,
  paySplitItem: false,
  personCount: [],
  itemCount: [],
  fdopen: false,
  errorMsg: '',
  actions: [],
  pdopen: false,
  alert: false,
  msg: [],
  head: '',
  carouselReceipt: '',
  carouselLoadedReceipt: false,
  date: new Date().toLocaleString().substr(0, 10),
  collapsed: false,
  activePanels: {
    tender: '-1',
    received: '-1',
    tip: '-1',
    discount: '-1'
  },
  selectedVoidItem: undefined,
  offline: false,
  namemenu: '',
  typemenuid: '',
  types: [],
  costPrice: '',
  sellPrice: '',
  codemenu: '',
  colormenu: '',
  mainmenusmenu: '',
  mainmenu: [],
  mainbtnsmenu: [],
  multiOpen: false,
  menubuttons: [],
  userlistbuttonmenu: '',
  notifications: [],
  voidBusy: false
};

let dashState = {
  windowHeight: stateObject.windowHeight,
  loading: stateObject.loading,
  foolsLoader: stateObject.foolsLoader,
  payView: stateObject.payView,
  addItemView: stateObject.addItemView,
  receiptView: stateObject.receiptView,
  billMenu: stateObject.billMenu,
  itemsWidth: stateObject.itemsWidth,
  pdopen: stateObject.pdopen,
  offline: stateObject.offline,
  fdopen: stateObject.fdopen,
  errorMsg: stateObject.errorMsg,
  actions: stateObject.actions,
  voidBusy: stateObject.voidBusy
};

let billsProps = {
  getBills: getBills,
  getBillId: getBillId,
  getBillIdMenu: getBillIdMenu,
  cardMenuSwitch: cardMenuSwitch,
  showCooking: showCooking,
  decline: decline,
  increase: increase,
  backToBills: backToBills,
  payBill: payBill
};

let billsState = {
  currentSlide: stateObject.currentSlide,
  billMenu: dashState.billMenu,
  voidBusy: dashState.voidBusy,
  voidPinNumber: '',
  bills: stateObject.bills,
  billid: stateObject.billid,
  selectedMenuKey: stateObject.selectedMenuKey,
  goToSlide: stateObject.goToSlide,
  selectedVoidItemKey: stateObject.selectedVoidItemKey,
  selectedVoidItem: stateObject.selectedVoidItem,
  selectVoidType: stateObject.selectVoidType,
  selectedVoidReason: stateObject.selectedVoidReason,
  voidReasons: stateObject.voidReasons,
  processBtn: stateObject.processBtn,
  payBtn: stateObject.payBtn
};

let itemMenuProps = {
  addToBill: addToBill,
  addToBillQty: addToBillQty,
  noStock: noStock,
  tabCreationCheck: tabCreationCheck,
  yocoPaymentCheck: yocoPaymentCheck,
  orgIDCheck: orgIDCheck
};

let itemMenuStates = {
  billType: stateObject.billType,
  billTable: stateObject.billTable,
  billPpl: stateObject.billPpl,
  cooking: stateObject.cooking,
  customers: stateObject.customers,
  tables: stateObject.tables,
  billDesc: stateObject.billDesc,
  billCusId: stateObject.billCusId,
  subCategories: stateObject.subCategories,
  menu: stateObject.menu,
  selectedCooking: stateObject.selectedCooking,
  selectedItem: stateObject.selectedItem,
  cookingItems: stateObject.cookingItems,
  showQtyNumber: stateObject.showQtyNumber,
  subCatItem: stateObject.subCatItem,
  tabPosition: stateObject.tabPosition,
  searchMenu: stateObject.searchMenu,
  windowHeight: dashState.windowHeight,
  initCooking: stateObject.initCooking,
  stockQtyList: {}
};

let headerProps = {
  state: {
    collapsed: stateObject.collapsed,
    notifications: stateObject.notifications
  },
  props: props,
  toggleCollapsed: toggleCollapsed,
  onCloseDrawer: onCloseDrawer,
  search: search,
  onError: onError
};

let alertDialogProps = {
  state: {
    alert: stateObject.alert,
    head: stateObject.head,
    msg: stateObject.msg
  },
  closeDialog: closeDialog
};

let loadTimeout = true;
let setLoadTimeout;

const handleSwitchLightDark = mode => {
  props.switchLightDark(mode);
};

let payViewBill;
let setPayViewBill;
let updatedPayViewBill;
let setUpdatedPayViewBill;
let paymentVisible;
let setPaymentVisible;
let mergeView;
let setMergeView;
let transferView;
let setTransferView;
let stockAdjustView;
let setStockAdjustView;
let setDoDeliveryBill;
let doDeliveryBill;
let currentView;
let setCurrentView;
let searchActive;
let setSearchActive;
const navToView = view => {
  if (window.isPhone) {
    timer = autoTime;

    if (view === 'search') {
      setSearchActive(true);
      setCurrentView('menu');
    } else {
      if (currentView === 'pay') {
        setPaymentType(undefined);
        backToSales();
      }
      setSearchActive(false);
      setCurrentView(view);
    }
  }
};

let paymentType;
let setPaymentType;
let createBillState;
let updateStockCheck;
let setUpdateStockCheck;
let stateBillCount;
let setStateBillCount;
let dashboardTempDiscount;
let setDashboardTempDiscount;

let closed;
let setClosed;
const CustomerOrderDashboard = allprops => {
  if (timer !== 'stop') {
    timer = autoTime;
  }
  carousel = useRef();
  headerProps.props = allprops;
  [loadTimeout, setLoadTimeout] = useState(true);
  [closed, setClosed] = useState(false);
  [dashState, setDash] = useState(dashState);
  [alertDialogProps.state, setAlert] = useState(alertDialogProps.state);
  [headerProps.state, setHeader] = useState(headerProps.state);
  [stateBillCount, setStateBillCount] = useState(0);
  [updateStockCheck, setUpdateStockCheck] = useState(0);
  [billsState, setBills] = useState(billsState);
  [currentView, setCurrentView] = useState('none');
  const [fabActive, setFabActive] = useState(false);
  const [fabActiveAnimate, setFabActiveAnimate] = useState(false);
  const [payFabActive, setPayFabActive] = useState(false);
  const [payFabActiveAnimate, setPayFabActiveAnimate] = useState(false);
  const [backFromTakeAwayType, setBackFromTakeAwayType] = useState(false);
  [paymentType, setPaymentType] = useState();
  const [tenderView, setTenderView] = useState(false);
  const [splitView, setSplitView] = useState(false);
  [searchActive, setSearchActive] = useState(false);
  [doDeliveryBill, setDoDeliveryBill] = useState(false);
  itemMenuProps.user = allprops.local.user;
  props = allprops;

  useEffect(() => {
    setCurrentView('menu');
    setBills(prevState => ({
      ...prevState,
      bills: props.bill ? Object.assign({}, props.bill) : {},
      selectedVoidItem: undefined,
      currentSlide: 0,
      goToSlide: 0
    }));
    setTimeout(() => {
      componentWillMount();
    }, 500);

    return () => componentWillUnmount();
  }, []);
  if (closed) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          background: '#132440',
          display: 'flex'
        }}
      >
        <AlertDialog {...alertDialogProps} />
        <h2 style={{ margin: 'auto', color: 'white' }}>
          This bill is closed. You can close this view.
        </h2>
      </div>
    );
  } else {
    return (
      <div style={{}} id="Dashboard">
        <Spin spinning={dashState.loading}>
          <AlertDialog {...alertDialogProps} />
          {dashState.foolsLoader ? (
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                background: ' rgba(0, 0, 0, 0.01)',
                zIndex: '999999999'
              }}
            />
          ) : null}
          {fabActive ? (
            <div
              onClick={e => {
                e.stopPropagation();
                setFabActiveAnimate(false);
                setTimeout(() => {
                  setFabActive(false);
                }, 300);
              }}
              style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                left: 0,
                zIndex: 1001,
                background: 'rgba(0, 0, 0, 0.81)'
              }}
            >
              <div
                onClick={e => {
                  e.stopPropagation();
                  setFabActiveAnimate(false);
                  setTimeout(() => {
                    setFabActive(false);
                  }, 300);
                }}
                style={{
                  margin: 'auto',
                  background: '#00CC70',
                  borderRadius: '50%',
                  width: 54,
                  height: 54,
                  top: 'calc(100vh - 140px)',
                  position: 'relative',
                  display: 'flex',
                  zIndex: 1001
                }}
              >
                <AiOutlineClose
                  style={{
                    margin: 'auto',
                    color: 'white',
                    fontSize: 25,
                    zIndex: 1001
                  }}
                />
              </div>
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100vw',
                  top: 'calc(100vh - 110px)',
                  left: 0,
                  zIndex: 11
                }}
              >
                <div
                  onClick={e => {
                    if (
                      billsState.bills[billsState.billid] &&
                      billsState.bills[billsState.billid].processing &&
                      billsState.bills[billsState.billid].processing === true
                    ) {
                      e.stopPropagation();
                    }
                    let bill;
                    if (billsState.billid !== '') {
                      bill = billsState.bills[billsState.billid];
                    } else {
                      bill = billsState.bills[Object.keys(billsState.bills)[0]];
                    }
                    payBill(
                      bill,
                      billsState.billid,
                      billsState.selectedMenuKey
                    );
                  }}
                  style={{
                    margin: 'auto',
                    marginRight: 0,
                    background: '#00CC70',
                    borderRadius: '50%',
                    width: 54,
                    height: 54,
                    opacity: fabActiveAnimate ? 1 : 0,
                    bottom: fabActiveAnimate ? 80 : 39,
                    left: fabActiveAnimate ? 0 : 70,
                    transition: '0.3s all',
                    position: 'relative',
                    display: 'flex'
                  }}
                >
                  <AiOutlineCheck
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 25
                    }}
                  />
                  <span
                    style={{
                      color: 'white',
                      position: 'absolute',
                      top: 55,
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    {'Add'}
                  </span>
                </div>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    setFabActiveAnimate(false);
                    setTimeout(() => {
                      setFabActive(false);
                    }, 300);
                    navToView('bill');
                  }}
                  style={{
                    margin: 'auto',
                    background: '#00CC70',
                    borderRadius: '50%',
                    width: 54,
                    height: 54,
                    opacity: fabActiveAnimate ? 1 : 0,
                    bottom: fabActiveAnimate ? 80 : 39,
                    right: fabActiveAnimate ? 0 : 70,
                    transition: '0.3s all',
                    position: 'relative',
                    display: 'flex'
                  }}
                >
                  <AiOutlineEye
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 25
                    }}
                  />
                  <span
                    style={{
                      color: 'white',
                      position: 'absolute',
                      top: 55,
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    View
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          {payFabActive ? (
            <div
              onClick={e => {
                e.stopPropagation();
                setPayFabActiveAnimate(false);
                setTimeout(() => {
                  setPayFabActive(false);
                }, 300);
              }}
              style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                left: 0,
                zIndex: 1001,
                background: 'rgba(0, 0, 0, 0.81)'
              }}
            >
              <div
                onClick={e => {
                  e.stopPropagation();
                  setPayFabActiveAnimate(false);
                  setTimeout(() => {
                    setPayFabActive(false);
                  }, 300);
                }}
                style={{
                  margin: 'auto',
                  background: '#00CC70',
                  borderRadius: '50%',
                  width: 54,
                  height: 54,
                  top: 'calc(100vh - 79px)',
                  position: 'relative',
                  display: 'flex',
                  zIndex: 1001
                }}
              >
                <AiOutlineClose
                  style={{
                    margin: 'auto',
                    color: 'white',
                    fontSize: 25,
                    zIndex: 1001
                  }}
                />
              </div>
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  width: '100vw',
                  top: 'calc(100vh - 40px)',
                  left: 0,
                  zIndex: 11
                }}
              >
                <div
                  onClick={_e => {
                    setSplitView(false);
                    setTenderView(true);
                  }}
                  style={{
                    margin: 'auto',
                    marginRight: 0,
                    background: '#00CC70',
                    borderRadius: '50%',
                    width: 54,
                    height: 54,
                    opacity:
                      (payFabActiveAnimate && paymentType) ||
                      (payFabActiveAnimate && payViewBill.custId)
                        ? 1
                        : 0,
                    bottom:
                      (payFabActiveAnimate && paymentType) ||
                      (payFabActiveAnimate && payViewBill.custId)
                        ? 80
                        : 39,
                    left:
                      (payFabActiveAnimate && paymentType) ||
                      (payFabActiveAnimate && payViewBill.custId)
                        ? 0
                        : 70,
                    transition: '0.3s all',
                    position: 'relative',
                    display: 'flex'
                  }}
                >
                  <AiOutlineCheck
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 25
                    }}
                  />
                  <span
                    style={{
                      color: 'white',
                      position: 'absolute',
                      top: 55,
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    Tender
                  </span>
                </div>
                <div
                  onClick={_e => {
                    setTenderView(false);
                    setSplitView(true);
                  }}
                  style={{
                    margin: 'auto',
                    marginRight: 0,
                    background: '#00CC70',
                    borderRadius: '50%',
                    width: 54,
                    height: 54,
                    opacity:
                      payFabActiveAnimate &&
                      paymentType &&
                      paymentType !== 'account'
                        ? 1
                        : 0,
                    bottom:
                      payFabActiveAnimate &&
                      paymentType &&
                      paymentType !== 'account'
                        ? 130
                        : 39,
                    transition: '0.3s all',
                    position: 'relative',
                    display: 'flex'
                  }}
                >
                  <AiOutlineTeam
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 25
                    }}
                  />
                  <span
                    style={{
                      color: 'white',
                      position: 'absolute',
                      top: 55,
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    Split Bill
                  </span>
                </div>
                <div
                  onClick={_e => {}}
                  style={{
                    margin: 'auto',
                    background: '#00CC70',
                    borderRadius: '50%',
                    width: 54,
                    height: 54,
                    opacity: payFabActiveAnimate ? 1 : 0,
                    bottom: payFabActiveAnimate ? 80 : 39,
                    right: payFabActiveAnimate ? 0 : 70,
                    transition: '0.3s all',
                    position: 'relative',
                    display: 'flex'
                  }}
                >
                  <Icon
                    type="printer"
                    style={{
                      margin: 'auto',
                      color: 'white',
                      fontSize: 25
                    }}
                  />
                  <span
                    style={{
                      color: 'white',
                      position: 'absolute',
                      top: 55,
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    Print Bill
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          {!window.isPhone ? (
            <Layout>
              <DashHeader
                themeMode={props.themeMode}
                handleSwitchLightDark={handleSwitchLightDark}
                {...headerProps}
              />
              <Layout>
                <Content className={'layout-shift-left '}>
                  <div id="stars" />
                  <div id="stars2" />
                  <div id="stars3" />

                  {!dashState.receiptView ? (
                    <Col
                      span={
                        Object.keys(billsState.bills).length > 0
                          ? dashState.itemsWidth !== ''
                            ? 20
                            : 16
                          : 24
                      }
                    >
                      <div className={'shift-left'} id="MenuTabs">
                        <div>
                          <MemoMenus
                            collapsedBill={dashState.itemsWidth}
                            props={itemMenuProps}
                            billProps={props.bill}
                            state={itemMenuStates}
                            createBillBool={createBillState}
                            stateBillCount={stateBillCount}
                            updateStockCheck={updateStockCheck}
                          />
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col span={16} />
                  )}
                  {Object.keys(billsState.bills).length > 0 ||
                  dashState.receiptView ? (
                    <Col span={dashState.itemsWidth !== '' ? 4 : 8}>
                      <div
                        className={'bill-shift-left'}
                        style={
                          createBillState && !dashState.receiptView
                            ? {
                                overflowX: 'hidden',
                                pointerEvents: 'none',
                                opacity: '0.7'
                              }
                            : {
                                transition: '0.3s all',
                                // opacity: loadTimeout ? 0.1 : 1,
                                overflowX: 'hidden'
                              }
                        }
                        id="BillTab"
                      >
                        <div>
                          {!dashState.billMenu ? (
                            <React.Suspense fallback={<div>Loading</div>}>
                              <div>
                                {billsState.bills &&
                                  Object.keys(billsState.bills).map(
                                    (billid, key) => (
                                      <div key={key}>
                                        <MemoBills
                                          themeMode={props.themeMode}
                                          billid={billid}
                                          newindex={key}
                                          state={billsState}
                                          props={billsProps}
                                          user={props.local.user}
                                        />
                                      </div>
                                    )
                                  )}
                              </div>
                            </React.Suspense>
                          ) : (
                            <MemoBillsMenu
                              state={billsState}
                              props={billsProps}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </Content>
                {/* </Spin> */}
              </Layout>
            </Layout>
          ) : (
            <Layout>
              <PhoneHeader
                backToBills={backToBills}
                themeMode={props.themeMode}
                toggleCollapsed={toggleCollapsed}
                navToView={navToView}
                backToSales={backToSales}
                searchActive={searchActive}
                bill={billsState.bills[billsState.billid]}
                itemMenuStates={itemMenuStates}
                currentView={currentView}
                {...headerProps}
              />
              <Layout>
                <Content className={'layout-shift-left '}>
                  <Col span={24}>
                    {createBillState ? (
                      <div className={'shift-left'} id="MenuTabs">
                        <MemoMenus
                          collapsedBill={dashState.itemsWidth}
                          billProps={props.bill}
                          props={itemMenuProps}
                          state={itemMenuStates}
                          stateBillCount={stateBillCount}
                          updateStockCheck={updateStockCheck}
                        />
                      </div>
                    ) : (currentView === 'receipts' ||
                        currentView === 'bills') &&
                      billsState.bills ? (
                      <MemoPhoneBillsMenu
                        receiptView={dashState.receiptView}
                        state={billsState}
                        props={billsProps}
                      />
                    ) : currentView === 'bill' && billsState.bills ? (
                      <MemoPhoneBills
                        setCurrentView={navToView}
                        billid={billsState.billid}
                        state={billsState}
                        props={billsProps}
                        user={props.local.user}
                      />
                    ) : currentView === 'menu' && billsState.bills ? (
                      <div className={'shift-left'} id="MenuTabs">
                        <MemoMenus
                          bill={
                            billsState.bills[billsState.billid]
                              ? billsState.bills[billsState.billid]
                              : billsState.bills[
                                  Object.keys(billsState.bills)[0]
                                ]
                          }
                          collapsedBill={dashState.itemsWidth}
                          billProps={props.bill}
                          props={itemMenuProps}
                          state={itemMenuStates}
                          stateBillCount={stateBillCount}
                          updateStockCheck={updateStockCheck}
                        />
                      </div>
                    ) : null}
                  </Col>
                </Content>
              </Layout>
              <PhoneFooter
                onError={onError}
                offline={dashState.offline}
                search={search}
                setSearchActive={setSearchActive}
                searchActive={searchActive}
                bill={billsState.bills[billsState.billid]}
                itemMenuStates={itemMenuStates}
                currentView={currentView}
                navToView={navToView}
                bills={Object.keys(billsState.bills).length}
                setFabActive={setFabActive}
                setFabActiveAnimate={setFabActiveAnimate}
                fabActive={fabActive}
                snackBar={props.openSnackbar}
              />
            </Layout>
          )}
          <Snackbar {...props} />
          {dashState.offline ? (
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                right: 70,
                fontSize: 10,
                zIndex: 9999999,
                color: 'red'
              }}
            >
              OFFLINE
            </div>
          ) : null}
        </Spin>
      </div>
    );
  }
};

// OPTIMISE CHANGES //

const mapStateToProps = oState => ({
  customers: oState.dashboard.customers || [],
  local: oState.backend,
  snackbar: oState.snackbar,
  bill: oState.dashboard.bill || [],
  menu: oState.dashboard.menu || [],
  lastbillno: oState.dashboard.lastbillno || [],
  billitems: oState.dashboard.billitems || [],
  billsReceipts: oState.dashboard.billsReceipts || [],
  voidReasons: oState.dashboard.voidReasons || [],
  paymentReasons: oState.dashboard.paymentReasons || [],
  types: oState.dashboard.types || [],
  mainmenu: oState.dashboard.mainmenu || [],
  notifications: oState.notifications.notifications || [],
  subCategories: oState.dashboard.subCategories || {},
  themeMode: oState.status.themeMode || 'dark',
  syncBills: oState.dashboard.syncBills || {},
  users: oState.backend.users || []
});

const mapDispatchToProps = {
  resetUser,
  endSession,
  openSnackbar,
  closeSnackbar,
  getMenuDash,
  createBillDashPlatform,
  createBillDash,
  getBillDash,
  getBillReceiptDash,
  addToBillDashOnline,
  updateBillDash,
  updateBillProcDash,
  removeFromBillDash,
  removeBillDash,
  updateBillSplitChargeDash,
  syncData,
  getVoidReasons,
  getPaymentReasons,
  voidBillItem,
  getAddMenuItemData,
  addMenuItem,
  sendNotification,
  findNotifications,
  removeNotification,
  resetSync,
  addExpensePayment,
  updateReduxBills,
  updateReduxSearchMenu,
  initLoader,
  removeLoader,
  switchLightDark,
  removeLogin,
  updateToken,
  updateSyncReduxBills
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CustomerOrderDashboard));
