const initialState = {
  help: {
    breadcrum: '',
    helpheadname: '',
    showHelpIcon: false
  }
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case 'RESET_HELP_MARKER': {
      return {
        help: {
          breadcrum: '',
          helpheadname: '',
          showHelpIcon: false
        }
      };
    }
    case 'SET_HELP_MARKER': {
      return {
        help: {
          breadcrum: state.breadcrum,
          helpheadname: action.payload,
          showHelpIcon: true
        }
      };
    }
    case 'SET_BREAD_CRUM': {
      var crum = action.payload;
      if(!crum){
        crum = '';
      }
      return {
        help: {
          breadcrum: crum,
          helpheadname: state.helpheadname,
          showHelpIcon: state.showHelpIcon
        }
      };
    }
    default:
      return state;
  }
}
