import moment from 'moment';
export const initialState = {
  cart: {},
  bill: {},
  selectedCompany: undefined,
  selectedDelivery: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART': {
      if (action.data) {
        let cart = state.cart;
        action.data.item.total = action.data.itemTotal;
        cart.items.push(action.data.item);
        cart.total = cart.total + action.data.itemTotal * action.data.item.qty;
        return {
          ...state,
          cart: cart
        };
      }
      return state;
    }
    case 'CREATE_CART': {
      if (action.data) {
        let newCart = action.data;
        let cart = state.cart;
        if (!action.data.orgid) {
          newCart = {};
        } else if (action.clear) {
          
        } else if (newCart.orgid === cart.orgid) {
          newCart = cart;
        }
        return {
          ...state,
          cart: newCart
        };
      }
      return state;
    }
    case 'UPDATE_CART': {
      if (action.data) {
        let cart = state.cart;
        let total = 0;
        if (action.data.qty === 0) {
          for (var i = 0; i < cart.items.length; i++) {
            if (cart.items[i]._id === action.data._id) {
              cart.items.splice(i,1);
              break;
            }
          }
          for (var i = 0; i < cart.items.length; i++) {
            total = total + cart.items[i].total * cart.items[i].qty;
          }
        } else {
          for (var i = 0; i < cart.items.length; i++) {
            if (cart.items[i]._id === action.data._id) {
              cart.items[i] = action.data;
            }
            total = total + cart.items[i].total * cart.items[i].qty;
          }
        }
        cart.total = total;
        return {
          ...state,
          cart: cart
        };
      }
      return state;
    }
    case 'SET_COMPANY': {
      if (action.data) {
        return {
          ...state,
          selectedCompany: action.data
        };
      }
      return state;
    }
    case 'SET_DELIVERY': {
      if (action.data) {
        return {
          ...state,
          selectedDelivery: action.data
        };
      }
      return state;
    }
    case 'CREATE_BILL': {
      let cart = state.cart;
      cart.deliveryCost = action.data;
      return {
        ...state,
        bill: cart
      };
    }
    case 'CUSTOMER_UPDATE_BILL': {
      let bill = action.data;
      return {
        ...state,
        bill: bill
      };
    }
    default:
      return state;
  }
};
