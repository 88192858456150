import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'antd';
import Quotes from 'src/views/Admin/Accounting/Quotes';
import QuoteCustomers from 'src/views/Admin/Accounting/Customers';
import Profile from 'src/views/Admin/Profile';
import Groups from 'src/views/Admin/Groups/Groups';
import Home from 'src/views/Admin/Home/AdminHome';
import Shift from 'src/views/Admin/Shift/Shift';
import Cashup from 'src/views/Admin/Cashup/Cashup';
import Users from 'src/views/Admin/Users/Users';
import Customers from 'src/views/Admin/Customers/Customers';
import EcomCustomers from 'src/views/Admin/Ecommerce/Customers/Customers';
import Orders from 'src/views/Admin/Ecommerce/Orders/Orders';
import EcomInvoices from 'src/views/Admin/Ecommerce/Invoices/Invoices';
import EcomShipments from 'src/views/Admin/Ecommerce/Shipments/Shipments';
import SalesReport from 'src/views/Admin/Reports/SalesReport/Reports';
import CategoryReport from 'src/views/Admin/Reports/CategoryReport/Reports';
import EmployeeReport from 'src/views/Admin/Reports/EmployeeReport/Reports';
import PaymentTypeReport from 'src/views/Admin/Reports/PaymentTypeReport/Reports';
import StockReport from 'src/views/Admin/Reports/StockReport/Reports';
import CookReport from 'src/views/Admin/Reports/CookingReport/Reports';
import CashoutReport from 'src/views/Admin/Reports/CashoutReport/Reports';
import VoidReport from 'src/views/Admin/Reports/VoidReport/Void';
import OpenBills from 'src/views/Admin/Reports/OpenBillReport/Reports';
import Receipts from 'src/views/Admin/Reports/Receipts/Receipts';
import Stockitems from 'src/views/Admin/Stockitems/Stockitems';
import Suppliers from 'src/views/Admin/Suppliers/Suppliers';
import StockAdjust from 'src/views/Admin/StockAdjust/StockAdjust';
import StockAdjustPhone from 'src/views/Admin/StockAdjust/StockAdjustPhone';
import PurchaseOrder from 'src/views/Admin/PurchaseOrder/PurchaseOrder';
import Billing from 'src/views/Admin/Billing/Billing';
import GeneralSettings from 'src/views/Admin/GeneralSettings/GeneralSettings';
import Soon from 'src/views/Admin/Soon';
import Menuitems from 'src/views/Admin/Menuitems/Menuitems';
import Production from 'src/views/Admin/Production/Production';
import Void from 'src/views/Admin/Void/Void';
import Menu from 'src/views/Admin/Menu/Menu';
import Specials from 'src/views/Admin/Specials/Specials';
import Cooking from 'src/views/Admin/Settings/Cooking/Cooking';
import Type from 'src/views/Admin/Settings/ItemType/Type';
import Brand from 'src/views/Admin/Settings/ItemBrand/Brand';
import Printing from 'src/views/Admin/Settings/Printing/Printing';
import Email from 'src/views/Admin/Settings/Email/Email';
import Kitchen from 'src/views/Kitchen/Kitchen';
import HomePic from 'src/static/adminIcons/home.png';
import MenuPic from 'src/static/adminIcons/menu.png';
import TrolleyPic from 'src/static/adminIcons/trolley.png';
import ChartPic from 'src/static/adminIcons/chart.png';
import PeoplePic from 'src/static/adminIcons/people.png';
import SettingsPic from 'src/static/adminIcons/settings.png';
import KitchenPic from 'src/static/adminIcons/kitchen.png';
import LogoutPic from 'src/static/adminIcons/logout.png';
import CoinPic from 'src/static/adminIcons/coin.png';
import {
  AiOutlineShop
} from 'react-icons/ai';
import {
  RiBook2Line
} from 'react-icons/ri';
import { connect } from 'react-redux';
import {
  handleOpenAddGroupUser,
  handleOpenAddUser,
  handleOpenTableUser,
  handleOpenAddStockItems,
  handleOpenTableStockItems,
  handleOpenAddMenuItems,
  handleOpenTableMenuItems,

} from 'src/redux/actions/subnav';
import {
  BsHouseDoor,
  BsColumns,
  BsPeople,
  BsGear,
  BsClipboardData,
  BsLayoutTextSidebarReverse,
  BsBoxArrowLeft
} from 'react-icons/bs';
import {
  FiPieChart,
  FiPower
} from 'react-icons/fi';

import {
  AiOutlineLogout,
  AiOutlineLineChart
} from 'react-icons/ai';

const MenuSvg = () =>
  <BsColumns
    style={{
      margin: 'auto 0.1em -0.1em',
   fontSize:"23px",
      marginLeft: "0.1em",
      marginRight: "0.1em",
      color: "white"
    }}
  />;
const KitchenSvg = () => <BsLayoutTextSidebarReverse
  style={{
    margin: 'auto',
  
    marginLeft: "0.1em",
    marginRight: "0.1em",
    color: "white"
  }}
/>;
const HomeSvg = () => (
  <BsHouseDoor
    style={{
      margin: 'auto 0.1em -0.1em',
     
      color: "white"
    }}
  />
);
const TrolleySvg = () => (
  <BsClipboardData
    style={{
      margin: 'auto 0.1em -0.1em',
      fontSize:"24px",
      marginLeft: "0.1em",
      marginRight: "0.1em",
      color: "white"
    }}
  />
);
const PeopleSvg = () => (
  <BsPeople
    style={{
      margin: 'auto 0.1em -0.1em',
    
      marginLeft: "0.1em",
      marginRight: "0.1em",
      color: "white"
    }}
  />
);
const SettingsSvg = () => (
  <BsGear
    style={{
      margin: 'auto 0.1em -0.1em',
      marginLeft: "0.1em",
      marginRight: "0.1em",
      color: "white"
    }}
  />
);
const LogoutSvg = () => (
  <FiPower
    style={{
      margin: window.isPhone ? "2px auto auto" : 'auto',
      fontSize: window.isPhone ? 34 : 40,
      padding: "0.2em",
      borderRadius: "3em",
      backgroundColor: "rgb(229, 233, 242 ,0.5)",
      boxShadow: "rgb(229, 233, 242) 0px 5px 10px 2px",
      color: " rgb(19, 36, 64)",
    }}
  />
);
const cashupSvg = () => (
  <AiOutlineShop
    style={{
      color: "#00CC70",
      width: '1.8em',
      fontSize: "18px",
      margiBottom: '-0em',
      marginRight: '-0.4em',
      marginLeft: '-0.4em'
    }}

  />
);
const ChartSvg = () => (
  <AiOutlineLineChart
    style={{
      margin: 'auto 0.1em -0.1em',
      fontSize:"26px",
      color: "white"
    }}
  />
);
const AccountSvg = () => (
  <RiBook2Line
    style={{
      opacity:0.99,
      margin: 'auto 0.1em -0.1em',
      fontSize:"25.5px",
      color: "white"
    }}
  />
);
const EcommerceIcon = () => (
  <Icon
    type="shopping-cart"
    style={{
      marginLeft: -3,
      fontSize:"24px",
      color: "white"
    }}
  />
);
class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  routes = () => {
    this.props.newobjectroutes({
      profile: {
        title: <FormattedMessage id="nav.profile" />,
        path: '/admin/profile',
        name: 'Profile',
        icon: 'user',
        component: Profile,
        exact: true,
        shouldRender: false,
        header: true
      },
      logout: {
        title: <FormattedMessage id="nav.logout" />,
        path: '/',
        name: 'Logout',
        icon: 'logout',
        exact: true,
        shouldRender: true,
        shouldRenderPhone: true
      },
      home: {
        title: <FormattedMessage id="nav.home" />,
        path: '/',
        name: 'Dashboard',
        icon: false,
        customicon: HomeSvg,
        component: Home,
        exact: true,
        shouldRender: true,
        shouldRenderPhone: true,
        sub: false
      },
      menuitems: {
        title: <FormattedMessage id="nav.menuitemsnav" />,
        path: '/admin/menuitemsnav',
        name: 'Products',
        icon: false,
        customicon: MenuSvg,
        exact: true,
        shouldRender: true,
        sub: true,
        subs: [
          {
            path: '/admin/menu',
            name: 'My Catalogue',
            icon: 'profile',
            sublink: true
          },
          {
            path: '/admin/menuitems',
            name: 'Manage Products',
            icon: 'block',
            sublink: true
          },
          {
            path: '/admin/specials',
            name: 'Specials',
            icon: 'tag',
            sublink: true
          },
          {
            path: '/admin/cooking',
            name: 'Modifiers',
            icon: 'read',
            sublink: true
          },
          /*
          {
            path: '/admin/production',
            name: 'Production',
            icon: 'block',
            sublink: true
          },
          */
          {
            path: '/admin/brand',
            name: 'Item Brand',
            icon: 'trademark',
            ecommerceLink: true,
            sublink: true
          },
          {
            path: '/admin/type',
            name: 'Item Type',
            icon: 'solution',
            sublink: true
          },
          {
            path: '/admin/void',
            name: 'Void Reasons',
            icon: 'stop',
            sublink: true
          }
        ]
      },
      stockitems: {
        title: <FormattedMessage id="nav.stocknav" />,
        path: '/admin/stocknav',
        name: 'Stock Control',
        icon: false,
        customicon: TrolleySvg,
        exact: true,
        shouldRender: true,
        shouldRenderPhone: true,
        sub: true,
        subs: [
          {
            path: '/admin/stockitem',
            name: 'Stock Items',
            icon: 'reconciliation',
            sublink: true
          },
          {
            path: '/admin/stockadjust',
            name: 'Stock Adjustment',
            icon: 'form',
            sublink: true,
            shouldRenderPhone: true,
          },
          {
            path: '/admin/purchaseorder',
            name: 'Purchase orders',
            icon: 'shopping',
            sublink: true
          },
          {
            path: '/admin/suppliers',
            name: 'Suppliers',
            icon: 'shopping-cart',
            sublink: true
          }
        ]
      },
      ecommerce: {
        title: <FormattedMessage id="nav.ecommerce" />,
        path: '/admin/ecommerce',
        name: 'E-commerce',
        icon: false,
        customicon: EcommerceIcon,
        exact: true,
        shouldRender: true,
        sub: true,
        subs: [
          {
            path: '/admin/orders',
            name: 'Orders',
            icon: 'shopping',
            sublink: true
          },
          {
            path: '/admin/ecominvoices',
            name: 'Invoices',
            icon: 'form',
            sublink: true
          },
          {
            path: '/admin/ecomshipments',
            name: 'Shipments',
            icon: 'car',
            sublink: true
          }
        ]
      },
      reports: {
        title: <FormattedMessage id="nav.reports" />,
        path: '/admin/reports',
        name: 'Reports',
        icon: false,
        customicon: ChartSvg,
        exact: true,
        shouldRender: true,
        sub: true,
        subs: [
          {
            path: '/admin/reportsale',
            name: 'Sales Summary',
            icon: 'bar-chart',
            sublink: true
          },
          {
            path: '/admin/reportstock',
            name: 'Stock Report',
            icon: 'rise',
            sublink: true
          },
          {
            path: '/admin/reportcategory',
            name: 'Sales by Category',
            icon: 'apartment',
            sublink: true
          },
          {
            path: '/admin/reportpaymenttype',
            name: 'Sales by Payment Type',
            icon: 'credit-card',
            sublink: true
          },
          {
            path: '/admin/reportemployee',
            name: 'Sales by People',
            icon: 'idcard',
            sublink: true
          },
          {
            path: '/admin/cashoutreport',
            name: 'Cashout Report',
            icon: 'money-collect',
            sublink: true
          },
          {
            path: '/admin/shift',
            name: 'Shifts',
            icon: 'contacts',
            sublink: true
          },
          {
            path: '/admin/voidreport',
            name: 'Void Report',
            icon: 'stop',
            sublink: true
          },
          {
            path: '/admin/reportreceipts',
            name: 'Receipts',
            icon: 'area-chart',
            sublink: true
          }
        ]
      },
      
      orders: {
        title: <FormattedMessage id="nav.orders" />,
        path: '/admin/orders',
        name: 'Orders',
        main: 'E-commerce',
        icon: 'contacts',
        component: Orders,
        exact: true,
        shouldRender: false,
        sub: false
      },
      ecominvoices: {
        title: <FormattedMessage id="nav.ecominvoices" />,
        path: '/admin/ecominvoices',
        name: 'Invoices',
        main: 'E-commerce',
        icon: 'contacts',
        component: EcomInvoices,
        exact: true,
        shouldRender: false,
        sub: false
      },
      ecomshipments: {
        title: <FormattedMessage id="nav.ecomshipments" />,
        path: '/admin/ecomshipments',
        name: 'Shipments',
        main: 'E-commerce',
        icon: 'contacts',
        component: EcomShipments,
        exact: true,
        shouldRender: false,
        sub: false
      },
      customer: {
        title: <FormattedMessage id="nav.customer" />,
        path: '/admin/customer',
        name: 'Accounts',
        main: 'People',
        icon: 'contacts',
        component: Customers,
        exact: true,
        shouldRender: false,
        sub: false
      },
      ecomcustomer: {
        title: <FormattedMessage id="nav.ecomcustomer" />,
        path: '/admin/ecomcustomer',
        name: 'E-commerce Customers',
        main: 'People',
        icon: 'contacts',
        component: EcomCustomers,
        exact: true,
        shouldRender: false,
        sub: false
      },
      specials: {
        title: <FormattedMessage id="nav.specials" />,
        path: '/admin/specials',
        name: 'Specials',
        main: 'Products',
        icon: 'tag',
        component: Specials,
        exact: true,
        shouldRender: false,
        sub: false
      },
      users: {
        title: <FormattedMessage id="nav.users" />,
        path: '/admin/users',
        name: 'People',
        main: 'People',
        subName: 'People',
        icon: false,
        customicon: PeopleSvg,
        component: Users,
        exact: true,
        shouldRender: true,
        sub: true,
        subs: [
          {
            path: '/admin/groups',
            name: 'Groups',
            icon: 'usergroup-add',
            sublink: true
          },
          {
            path: '/admin/users',
            name: 'People',
            icon: 'user-add',
            sublink: true
          },
          {
            path: '/admin/cashup',
            name: 'Cashup',
            icon: false,
            customicon: cashupSvg,
            sublink: true
          },
          {
            path: '/admin/customer',
            name: 'Accounts',
            icon: 'usergroup-add',
            sublink: true
          },
          {
            path: '/admin/ecomcustomer',
            name: 'E-commerce Customers',
            icon: 'usergroup-add',
            sublink: true,
            ecommerceLink: true,
          }
        ]
      },
      accounting: {
        title: <FormattedMessage id="nav.accounting" />,
        path: '/admin/accounting',
        name: 'Accounting',
        icon: false,
        customicon: AccountSvg,
        exact: true,
        shouldRender: true,
        sub: true,
          
          subs: [ {
            path: '/admin/quotecustomers',
            name: 'Customers',
            icon: 'usergroup-add',
            sublink: true
          },
          {
            path: '/admin/quotes',
            name: 'Quotes',
            icon: 'usergroup-add',
            sublink: true
          }
        ]
      },
      quotes: {
        title: <FormattedMessage id="nav.quotes" />,
        path: '/admin/quotes',
        name: 'Quotes',
        main: 'Accounting',
        icon: 'usergroup-add',
        component: Quotes,
        exact: true,
        shouldRender: false,
        sub: false
      },
      quotecustomer: {
        title: <FormattedMessage id="nav.quotecsutomers" />,
        path: '/admin/quotecustomers',
        name: 'Customers',
        main: 'Accounting',
        icon: 'usergroup-add',
        component: QuoteCustomers,
        exact: true,
        shouldRender: false,
        sub: false
      },
      shift: {
        title: <FormattedMessage id="nav.shift" />,
        path: '/admin/shift',
        name: 'Shifts',
        main: 'Reports',
        icon: 'contacts',
        component: Shift,
        exact: true,
        shouldRender: false,
        sub: false
      },
      cashup: {
        title: <FormattedMessage id="nav.cashup" />,
        path: '/admin/cashup',
        name: 'Cashup',
        main: 'People',
        icon: 'contacts',
        component: Cashup,
        exact: true,
        shouldRender: false,
        sub: false
      },
      groups: {
        title: <FormattedMessage id="nav.groups" />,
        path: '/admin/groups',
        name: 'Groups',
        main: 'People',
        icon: 'team',
        component: Groups,
        exact: true,
        shouldRender: false,
        sub: false
      },
      reportsale: {
        title: <FormattedMessage id="nav.reportsale" />,
        path: '/admin/reportsale',
        name: 'Sales Report',
        main: 'Reports',
        icon: 'area-chart',
        component: SalesReport,
        exact: true,
        shouldRender: false,
        sub: false
      },
      reportstock: {
        title: <FormattedMessage id="nav.reportstock" />,
        path: '/admin/reportstock',
        name: 'Stock Report',
        main: 'Reports',
        icon: 'area-chart',
        component: StockReport,
        exact: true,
        shouldRender: false,
        sub: false
      },
      reportcategory: {
        title: <FormattedMessage id="nav.reportcategory" />,
        path: '/admin/reportcategory',
        name: 'Sales by Category Report',
        main: 'Reports',
        icon: 'area-chart',
        component: CategoryReport,
        exact: true,
        shouldRender: false,
        sub: false
      },
      reportemployee: {
        title: <FormattedMessage id="nav.reportemployee" />,
        path: '/admin/reportemployee',
        name: 'Sales by People Report',
        main: 'Reports',
        icon: 'area-chart',
        component: EmployeeReport,
        exact: true,
        shouldRender: false,
        sub: false
      },
      reportpaymenttype: {
        title: <FormattedMessage id="nav.reportpaymenttype" />,
        path: '/admin/reportpaymenttype',
        name: 'Sales by Payment Type Report',
        main: 'Reports',
        icon: 'area-chart',
        component: PaymentTypeReport,
        exact: true,
        shouldRender: false,
        sub: false
      },
      reportopenbills: {
        title: <FormattedMessage id="nav.reportopenbills" />,
        path: '/admin/reportopenbills',
        name: 'Open Sales',
        icon: 'area-chart',
        component: OpenBills,
        exact: true,
        shouldRender: false,
        sub: false
      },
      reportreceipts: {
        title: <FormattedMessage id="nav.reportreceipts" />,
        path: '/admin/reportreceipts',
        name: 'Receipts',
        icon: 'area-chart',
        component: Receipts,
        exact: true,
        shouldRender: false,
        sub: false
      },
      cookingreport: {
        title: <FormattedMessage id="nav.cookingreport" />,
        path: '/admin/cookingreport',
        name: 'Modifiers Report',
        main: 'Reports',
        icon: 'area-chart',
        component: CookReport,
        exact: true,
        shouldRender: false,
        sub: false
      },
      cashoutreport: {
        title: <FormattedMessage id="nav.cashoutreport" />,
        path: '/admin/cashoutreport',
        name: 'Cashout Report',
        main: 'Reports',
        icon: 'area-chart',
        component: CashoutReport,
        exact: true,
        shouldRender: false,
        sub: false
      },
      voidreport: {
        title: <FormattedMessage id="nav.voidreport" />,
        path: '/admin/voidreport',
        name: 'Void Bills Report',
        main: 'Reports',
        icon: 'area-chart',
        component: VoidReport,
        exact: true,
        shouldRender: false,
        sub: false
      },
      menu: {
        title: <FormattedMessage id="nav.menu" />,
        path: '/admin/menu',
        name: 'My Catalogue',
        icon: 'read',
        main: 'Products',
        component: Menu,
        exact: true,
        shouldRender: false,
        sub: false
      },
      menuitem: {
        title: <FormattedMessage id="nav.menuitems" />,
        path: '/admin/menuitems',
        name: 'Manage Products',
        main: 'Products',
        icon: false,
        customicon: MenuSvg,
        component: Menuitems,
        exact: true,
        shouldRender: false,
        sub: false
      },
      void: {
        title: <FormattedMessage id="nav.void" />,
        path: '/admin/void',
        name: 'Void Reasons',
        main: 'Products',
        icon: 'read',
        component: Void,
        exact: true,
        shouldRender: false,
        sub: false
      },
      stockitem: {
        title: <FormattedMessage id="nav.stockitem" />,
        path: '/admin/stockitem',
        name: 'Stock Items',
        main: 'Stock Control',
        icon: 'apartment',
        component: Stockitems,
        exact: true,
        shouldRender: false,
        sub: false
      },
      stockadjust: {
        title: <FormattedMessage id="nav.stockadjust" />,
        path: '/admin/stockadjust',
        name: 'Stock Adjustment',
        main: 'Stock Control',
        icon: 'form',
        component: window.isPhone ? StockAdjustPhone : StockAdjust,
        exact: true,
        shouldRender: false,
        shouldRenderPhone: true,
        sub: false
      },
      purchaseorder: {
        title: <FormattedMessage id="nav.purchaseorder" />,
        path: '/admin/purchaseorder',
        name: 'Purchase Orders',
        main: 'Stock Control',
        icon: 'credit-card',
        component: PurchaseOrder,
        exact: true,
        shouldRender: false,
        sub: false
      },
      cooking: {
        title: <FormattedMessage id="nav.cooking" />,
        path: '/admin/cooking',
        name: 'Modifiers',
        main: 'Products',
        icon: 'read',
        component: Cooking,
        exact: true,
        shouldRender: false,
        sub: false
      },
      production: {
        title: <FormattedMessage id="nav.production" />,
        path: '/admin/production',
        name: 'Production',
        main: 'Products',
        icon: 'read',
        component: Production,
        exact: true,
        shouldRender: false,
        sub: false
      },
      brand: {
        title: <FormattedMessage id="nav.brand" />,
        path: '/admin/brand',
        name: 'Item Brand',
        main: 'Products',
        icon: 'trademark',
        component: Brand,
        exact: true,
        shouldRender: true,
        sub: false
      },
      type: {
        title: <FormattedMessage id="nav.tyoe" />,
        path: '/admin/type',
        name: 'Item Type',
        main: 'Products',
        icon: 'read',
        component: Type,
        exact: true,
        shouldRender: true,
        sub: false
      },
      billing: {
        title: <FormattedMessage id="nav.billing" />,
        path: '/admin/billing',
        name: 'Billing & Subscription',
        icon: 'dollar',
        component: Billing,
        exact: true,
        shouldRender: false,
        sub: false
      },
      suppliers: {
        title: <FormattedMessage id="nav.suppliers" />,
        path: '/admin/suppliers',
        name: 'Suppliers',
        main: 'Stock Control',
        icon: 'stock',
        component: Suppliers,
        exact: true,
        shouldRender: false,
        sub: false
      },
      printing: {
        title: <FormattedMessage id="nav.printing" />,
        path: '/admin/printing',
        name: 'Printer Settings',
        main: 'My Settings',
        icon: 'read',
        component: Printing,
        exact: true,
        shouldRender: true,
        sub: false
      },
      email: {
        title: <FormattedMessage id="nav.email" />,
        path: '/admin/email',
        name: 'Email Settings',
        main: 'My Settings',
        icon: 'mail',
        component: Email,
        exact: true,
        shouldRender: true,
        sub: false
      },
      settings: {
        title: <FormattedMessage id="nav.settings" />,
        path: '/admin/settings',
        name: 'General Settings',
        main: 'My Settings',
        icon: false,
        customicon: SettingsSvg,
        component: GeneralSettings,
        exact: true,
        shouldRender: false,
        sub: false
      },
      mainSettings: {
        title: <FormattedMessage id="nav.mainsettings" />,
        path: '/admin/mainsettings',
        name: 'My Settings',
        main: 'General Settings',
        icon: false,
        customicon: SettingsSvg,
        component: GeneralSettings,
        exact: true,
        shouldRender: false,
        sub: true,
        subs: [
          {
            path: '/admin/email',
            name: 'Email Settings',
            icon: 'mail',
            sublink: true
          },
          {
            path: '/admin/printing',
            name: 'Printer Settings',
            icon: 'printer',
            sublink: true
          },
          {
            path: '/admin/settings',
            name: 'General Settings',
            icon: 'setting',
            sublink: true
          },
          {
            path: '/admin/billing',
            name: 'Billing & Subscription',
            icon: 'container',
            sublink: true
          }
        ]
      },
      soon: {
        title: <FormattedMessage id="nav.soon" />,
        path: '/admin/soon',
        name: 'Coming Soon',
        icon: 'read',
        component: Soon,
        exact: true,
        shouldRender: true,
        sub: false
      },
      kitchen: {
        title: <FormattedMessage id="nav.kitchen" />,
        path: '/admin/kitchen',
        name: 'My Orders',
        icon: false,
        customicon: KitchenSvg,
        component: Kitchen,
        exact: true,
        shouldRender: true,
        sub: true
      },
      logoutorg: {
        title: <FormattedMessage id="nav.logoutorg" />,
        path: '/',
        name: 'Sign Out',
        icon: false,
        customicon: LogoutSvg,
        exact: true,
        shouldRender: true
      }
    });
  };

  render() {
    return <div>{this.routes()}</div>;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  handleOpenAddGroupUser,
  handleOpenAddUser,
  handleOpenTableUser,
  handleOpenAddStockItems,
  handleOpenTableStockItems,
  handleOpenAddMenuItems,
  handleOpenTableMenuItems
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
