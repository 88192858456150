import React, { memo, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import customSelectStyle from 'src/assets/jss/material-dashboard-react/components/customSelectStyle.jsx';
import InputLabel from '@material-ui/core/InputLabel';

const { Option } = Select;
const Selection = styled(Select)`
.ant-select-focused .ant-select-selection .ant-select-selection:focused  {
 box-shadow:none !important;
}
  .ant-select-selection {
    border:0;
    border-radius:0;
    border-bottom: 1px solid #bdb2b2;
    height: 35px;
    font-size: 15px;
  }
  .ant-select-selection__placeholder {
    color: black;
    font-size: 15px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    font-size: 15px;
  }
`;
const Complete = memo(({ ...props }) => {
  const {
    classes,
    formControlProps,
    labelText,
    onChange,
    labelProps,
    id,
    selectedItem,
    onInputKeyDown,
    inputProps,
    error,
    success,
    errorText
  } = props;

  let [trigger, setTrigger] = useState(false);
  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
      onFocus={() => setTrigger(true)}
      onBlur={() => setTrigger(false)}
    >
      <InputLabel
        className={classes.labelRoot + labelClasses}
        htmlFor={id}
        style={{
          zIndex: 1,
          lineHeight: '0',
          transform: trigger || selectedItem ? 'translate(0, 10px) scale(0.75)' : 'translate(0, 35px) scale(1)',
          transformOrigin: trigger || selectedItem ? 'top left' : ''
        }}
        {...labelProps}
      >
        {labelText}
      </InputLabel>
      <Selection
        value={selectedItem}
        className={classes.marginTop}
        id={id}
        showSearch
        placeholder=''
        optionFilterProp="children"
        onChange={onChange}
        onInputKeyDown={onInputKeyDown}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        {...inputProps}
      >
        {props.items.map(
          (item, key) =>
            item && (
              <Option
                key={item.key || key}
                value={item.id || item.value || item._id}
              >
                {item.value || item.name || item.text}
              </Option>
            )
        )}
      </Selection>
      {error ? (
        <FormHelperText error={error}>{errorText}</FormHelperText>
      ) : success ? (
        <FormHelperText>{success}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
);

Complete.propTypes = {
  classes: PropTypes.object.isRequired,
  formControlProps: PropTypes.object
};

export default withStyles(customSelectStyle)(Complete);
