/* eslint-disable no-loop-func */
import { Col, Layout, List, Row } from 'antd';
import React, { useEffect } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
const { Content } = Layout;

const OrderDetails = props => {
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white', paddingBottom: 170 }}>
      <Row type="flex" justify="space-between">
        {' '}
        <Col span={20}>
          <div>
            <p style={{ margin: 0 }}>
              <strong>Order:</strong> {props.order.orderno}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Name:</strong> {props.order.customerDetails.name}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Address:</strong> {props.order.address}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Building Type:</strong> {props.order.buildingType}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Address Details:</strong> {props.order.addressDetails}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Number:</strong> {props.order.customerDetails.number}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Alt Number:</strong> {props.order.altNumber}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Instruction:</strong> {props.order.deliveryType}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Instruction 2:</strong> {props.order.instruction}
            </p>
          </div>
        </Col>
        <Col span={24}>
          <h2
            style={{
              fontSize: 17,
              paddingLeft: '1em',
              margin: 'auto',
              marginBottom: '0.7em'
            }}
          >
            Order Items
          </h2>
        </Col>
      </Row>
      <Row>
        <List
          dataSource={props.order.items}
          renderItem={item => (
            <List.Item
              style={{
                padding: '1em',
                backgroundColor: 'white',
                marginBottom: '0.5em',
                boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.205)'
              }}
              key={item._id}
            >
              <List.Item.Meta
                id="MenuItemName"
                title={item.qty + 'x ' + item.name}
                description={
                  <div>
                    <p>{item.description}</p>
                    {item.cookIns.length > 0 ? (
                      <p>
                        Extras:{' '}
                        {item.cookIns.map((cook, index) => {
                          if (index + 1 === item.cookIns.length) {
                            return cook.name;
                          } else {
                            return cook.name + ', ';
                          }
                        })}
                      </p>
                    ) : null}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Row>
    </Content>
  );
};

export default OrderDetails;
