const initialState = {
  nav: {
    addmenuitem: false,
    addstockitem: false,
    menuitemlist: true
  }
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case 'RESET_MENU_ITEMS': {
      return {
        nav: {
          addmenuitem: false,
          addstockitem: false,
          menuitemlist: true
        }
      };
    }
    case 'OPEN_ADD_MENU_ITEMS': {
      return {
        nav: {
          addmenuitem: true,
          addstockitem: false,
          menuitemlist: false
        }
      };
    }
    case 'CLOSE_ADD_MENU_ITEMS': {
      return {
        nav: {
          addmenuitem: false,
          addstockitem: false,
          menuitemlist: false
        }
      };
    }
    case 'OPEN_ADD_MENU_STOCK_ITEMS': {
      return {
        nav: {
          addmenuitem: false,
          addstockitem: true,
          menuitemlist: false
        }
      };
    }
    case 'CLOSE_ADD_MENU_STOCK_ITEMS': {
      return {
        nav: {
          addmenuitem: true,
          addstockitem: false,
          menuitemlist: false
        }
      };
    }
    case 'OPEN_TABLE_MENU_ITEMS': {
      return {
        nav: {
          addmenuitem: false,
          addstockitem: false,
          menuitemlist: true
        }
      };
    }
    case 'CLOSE_TABLE_MENU_ITEMS': {
      return {
        nav: {
          addmenuitem: false,
          addstockitem: false,
          menuitemlist: false
        }
      };
    }
    default:
      return state;
  }
}
