import withStyles from '@material-ui/core/styles/withStyles';
import { Layout, Spin } from 'antd';
import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  sendNotification,
  findNotifications,
  removeNotification
} from 'src/redux/actions/notifications';
import { initLoader, removeLoader } from 'src/redux/actions/initdashloader';
import { endSession, resetUser, removeLogin } from 'src/redux/actions/backend';
import {
  getBillDash,
  getBillKitchen,
  updateBillKitchen,
  resetSync
} from 'src/redux/actions/dashboard';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import moment from 'moment';
import { PrintJS } from 'src/config/PrintScript';
import { MemoBills } from './Bills.jsx';
import DashHeader from './Header.jsx';
import AdminHeader from './AdminHeader.jsx';

const { Content } = Layout;

const styles = theme => ({
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  }
});

let setAlert = undefined;
let setDash = undefined;
let setHeader = undefined;
let setBills = undefined;
var props = {};

let timerKitchen = 0;
const autoTimeKitchen = 180;
let autoDataFetch;

const pubnubListner = {
  signal: function(event) {
    getBills();
  }
};

const getBills = billCount => {
  const token = props.user.token;
  props.getBillDash(token);
  props.getBillKitchen(token, billCount, props.user.role).then(result => {
    props.removeLoader();
    timerKitchen = autoTimeKitchen;
    if (!result.data.results.nonewbill) {
      setBills(prevState => ({
        ...prevState,
        openbill: result.data.results.openbill || {},
        procbill: result.data.results.procbill || {},
        compbill: result.data.results.compbill || {},
        voidbill: result.data.results.voidbill || {},
        loadKitch: false
      }));
      setHeader(prevState => ({
        ...prevState,
        opencount: result.data.results.opencount || 0,
        proccount: result.data.results.proccount || 0,
        compcount: result.data.results.compcount || 0,
        voidcount: result.data.results.voidcount || 0
      }));
    }
    setDash(prevState => ({
      ...prevState,
      loading: false,
      printer: result.data.results.printer || {}
    }));
  });
};

const updateBill = (bill, type) => {
  setBills(prevState => ({
    ...prevState,
    loadKitch: true
  }));
  bill.state = type;
  bill.kituser = props.user.name;
  bill.kitid = props.user._id;
  const token = props.user.token;
  props.updateBillKitchen(token, bill, props.user.role).then(result => {
    props.findNotifications(token);
    if (type === 'comp') {
      props.sendNotification(
        bill.userid,
        [bill.userid],
        'Order ' + bill.orderno + ' is ready for collection.',
        'orderReady',
        false,
        'Order ' + bill.orderno + ' Ready',
        token,
        bill._id
      );
    }
    getBills();
  });
};

const switchToDash = () => {
  props.initLoader(25);
  props.switchToDash();
};

const setOffline = () => {
  setDash(prevState => ({ ...prevState, offline: true }));
};

const setOnline = () => {
  setDash(prevState => ({ ...prevState, offline: false }));
};

const endSes = () => {
  if (dashState.offline) {
    onError(
      'You can not end session while offline. Please restore the connection first.'
    );
    return;
  }
  setHeader(prevState => ({
    ...prevState,
    collapsed: !headerProps.state.collapsed
  }));
  var check = false;
  for (var prop in props.bill) {
    if (props.bill[prop].state === 'open') {
      check = true;
    }
  }
  if (check) {
    props.openSnackbar('Open Sale Bills Found');
    return;
  }
  window.pubnub.removeListener(pubnubListner);
  clearInterval(autoDataFetch);
  const token = props.user.token;
  props
    .endSession(token, null, true)
    .then(result => {
      props.removeLogin();
    })
    .catch(err => {
      onError(err);
    });
};

const search = value => {};

const toggleCollapsed = () => {
  setHeader(prevState => ({
    ...prevState,
    collapsed: !headerProps.state.collapsed
  }));
};

const onFilterClick = filter => {
  if (filter === 'open') {
    setDash(prevState => ({
      ...prevState,
      openshow: true,
      procshow: false,
      compshow: false,
      voidshow: false
    }));
  } else if (filter === 'proc') {
    setDash(prevState => ({
      ...prevState,
      openshow: false,
      procshow: true,
      compshow: false,
      voidshow: false
    }));
  } else if (filter === 'comp') {
    setDash(prevState => ({
      ...prevState,
      openshow: false,
      procshow: false,
      compshow: true,
      voidshow: false
    }));
  } else if (filter === 'void') {
    setDash(prevState => ({
      ...prevState,
      openshow: false,
      procshow: false,
      compshow: false,
      voidshow: true
    }));
  }
};

const printKitchenBill = bill => {
  var copyBill = bill;
  var settings = dashState.printer;
  let printRole = 'Kitchen';
  if (props.user.role.isBar) {
    printRole = 'Bar';
  }
  var receipt = [];
  receipt.push(printRole + ' Order\n');
  receipt.push('========================================\n');
  receipt.push(
    'Date   :' +
      moment(copyBill.date, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm') +
      '\n'
  );
  receipt.push('Order #:' + copyBill.orderno + '\n');
  receipt.push('\n');
  receipt.push('Type   :' + copyBill.type + '\n');
  receipt.push('========================================\n');
  receipt.push('Qty  Item\n');
  receipt.push('========================================\n');
  for (var i = 0; i < copyBill.items.length; i++) {
    var length = copyBill.items[i].name.length + 1;
    var item =
      copyBill.items[i].qty +
      ' X ' +
      copyBill.items[i].name +
      '                         '.slice(0, -length) +
      '\n';
    receipt.push(item);
    if (copyBill.items[i].cookIns) {
      if (copyBill.items[i].cookIns.length > 0) {
        for (var j = 0; j < copyBill.items[i].cookIns.length; j++) {
          receipt.push('      ' + copyBill.items[i].cookIns[j].name + '\n');
        }
      }
    }
  }
  receipt.push('========================================\n');
  receipt.push('\n');
  receipt.push('\n');
  receipt.push('\n');
  receipt.push('\n');
  receipt.push('\n');
  receipt.push('\n');

  let printersInfo = JSON.parse(JSON.stringify(settings));
  let printer = {};
  if (printersInfo && printersInfo.printers && printersInfo.printers.length) {
    for (var i = 0; i < printersInfo.printers.length; i++) {
      if (
        printersInfo.printers[i].printList.includes(printRole) &&
        printersInfo.printers[i].address
      ) {
        printer = printersInfo.printers[i];
        break;
      }
    }
  }
  if (printer.address) {
    if (printer.type === 'ethernet') {
      toPrinter(receipt, printer.address, printer.model);
    } else {
      toPrinterBT(receipt, printer.address);
    }
  } else {
    onError('No ' + printRole + ' Order Printer found');
  }
};

const toPrinterBT = (receipt, ip) => {
  var aData = receipt;
  var sIp = ip;
  if (window.device) {
    if (
      window.device.platform === 'Android' ||
      window.device.platform === 'iOS'
    ) {
      window.DatecsPrinter.connect(
        sIp,
        function() {
          var printmsg = '';
          for (var i = 0; i < aData.length; i++) {
            aData[i] = aData[i].replace('\\n', '{br}');
            aData[i] = aData[i].replace('\n', '{br}');
            aData[i] = aData[i].replace('{B}1{S}', '');
            aData[i] = aData[i].replace('{B}2{S}', '');
            printmsg += aData[i];
          }
          var spacing = 5;
          for (var j = 0; j < spacing.length; j++) {
            aData.push('{br}');
          }
          window.DatecsPrinter.printText(printmsg, 'ISO-8859-1', function() {});
        },
        function(error) {
          alert(error);
        }
      );
    }
  }
};

const printImpact = (receipt, ip) => {
  receipt.push(' ');
  if (receipt.toString().search('Cash: R') > -1) {
    window.ThermalPrinter.printFormattedTextAndOpenCashBox(
      {
        type: 'tcp',
        address: ip,
        port: 9100,
        id: ip,
        text: receipt.toString().replace(/,/g, ''),
        printerWidthMM: 80
      },
      function() {
        window.ThermalPrinter.disconnectPrinter(
          {
            type: 'tcp',
            address: ip,
            port: 9100,
            id: ip
          },
          function() {
            console.log('Successfully printed!');
          },
          function(error) {
            console.error('Printing error', error);
          }
        );
      },
      function(error) {
        window.ThermalPrinter.disconnectPrinter(
          {
            type: 'tcp',
            address: ip,
            port: 9100,
            id: ip
          },
          function() {
            console.log('Successfully printed!');
          },
          function(error) {
            console.error('Printing error', error);
          }
        );
      }
    );
  } else {
    window.ThermalPrinter.printFormattedTextAndCut(
      {
        type: 'tcp',
        address: ip,
        port: 9100,
        id: ip,
        text: receipt.toString().replace(/,/g, ''),
        printerWidthMM: 80
      },
      function() {
        window.ThermalPrinter.disconnectPrinter(
          {
            type: 'tcp',
            address: ip,
            port: 9100,
            id: ip
          },
          function() {
            console.log('Successfully printed!');
          },
          function(error) {
            console.error('Printing error', error);
          }
        );
      },
      function(error) {
        window.ThermalPrinter.disconnectPrinter(
          {
            type: 'tcp',
            address: ip,
            port: 9100,
            id: ip
          },
          function() {
            console.log('Successfully printed!');
          },
          function(error) {
            console.error('Printing error', error);
          }
        );
      }
    );
  }
};

const toPrinter = (receipt, ip, model) => {
  var aData = receipt;
  var sIp = ip;
  if (window.device) {
    if (
      window.device.platform === 'Android' ||
      window.device.platform === 'iOS'
    ) {
      if (window.device.platform === 'Android' && model === 'impact') {
        for (var i = 0; i < receipt.length; i++) {
          receipt[i] = receipt[i].replace('\\n', '\n');
          if (receipt[i].search('{B}') > -1) {
            receipt[i] = `<b>${receipt[i].replace('{B}', '')}</b>`;
          }
          if (receipt[i].search('3{S}') > -1) {
            receipt[i] = `<font size='big'>${receipt[i].replace(
              '3{S}',
              ''
            )}</font>`;
          }
          if (receipt[i].search('2{S}') > -1) {
            receipt[i] = `<font size='big'>${receipt[i].replace(
              '2{S}',
              ''
            )}</font>`;
          }
          receipt[i] = receipt[i].replace('1{S}', '');
          receipt[i] = receipt[i].replace('{L}', '');
        }
        printImpact(receipt, ip);
      } else {
        var printReprat = 1;
        const sendToPrinter = () => {
          window.epos2.connectPrinter(
            sIp,
            function() {
              for (var i = 0; i < aData.length; i++) {
                aData[i] = aData[i].replace('\\n', '\n');
              }
              window.epos2.print(
                aData,
                function(output) {
                  window.epos2.disconnectPrinter();
                },
                function(error) {
                  alert(error);
                }
              );
            },
            function(error) {
              alert(error);
            }
          );
        };
        sendToPrinter();
      }
    } else {
      PrintJS(aData, sIp, false, function(error, msg) {
        if (error) {
          alert(error);
        }
      });
    }
  } else {
    PrintJS(aData, sIp, false, function(error, msg) {
      if (error) {
        alert(error);
      }
    });
  }
};

const onLogout = () => {
  const token = props.user.token;
  props.setLoggedIn(async function() {
    window.pubnub.removeListener(pubnubListner);
    document.removeEventListener('offline', setOffline, false);
    document.removeEventListener('online', setOnline, false);
    clearInterval(autoDataFetch);
    props.resetSync();
    props.resetUser(token);
  });
};
const onCloseDrawer = () => {
  setHeader(prevState => ({ ...prevState, collapsed: false }));
};

const closeDialog = () => {
  setAlert(prevState => ({ ...prevState, alert: false }));
};

const onError = err => {
  setAlert(prevState => ({
    ...prevState,
    head: 'Order Error',
    alert: true,
    msg: [err]
  }));
  setDash(prevState => ({ ...prevState, loading: false }));
};

var stateObject = {
  windowHeight: window.innerHeight,
  itemsWidth: '',
  splitExpanded: false,
  loading: true,
  loadKitch: false,
  openshow: true,
  procshow: false,
  compshow: false,
  voidshow: false,
  printer: {},
  openbill: {},
  procbill: {},
  compbill: {},
  voidbill: {},
  opencount: 0,
  proccount: 0,
  compcount: 0,
  voidcount: 0,
  billid: '',
  billCount: 0,
  payBill: { items: [] },
  pdopen: false,
  alert: false,
  msg: [],
  head: ''
};

let dashState = {};

let billsProps = {};

let billsState = {};

let alertDialogProps = {};

let headerProps = {};

const Kitchen = allprops => {
  const componentWillMount = () => {
    setDash(prevState => ({ ...prevState, loading: true }));
    window.removeEventListener('offline', setOffline, false);
    window.removeEventListener('online', setOnline, false);
    window.addEventListener('offline', setOffline, false);
    window.addEventListener('online', setOnline, false);
    window.pubnub.removeListener(pubnubListner);
    window.pubnub.addListener(pubnubListner);
    timerKitchen = autoTimeKitchen;
    autoDataFetch = setInterval(() => {
      timerKitchen = timerKitchen - 1;
      if (timerKitchen === 0) {
        timerKitchen = autoTimeKitchen;
        getBills();
      }
    }, 1000);
    getBills();
  };

  const componentWillUnmount = () => {
    window.pubnub.removeListener(pubnubListner);
    window.removeEventListener('offline', setOffline, false);
    window.removeEventListener('online', setOnline, false);
    clearInterval(autoDataFetch);
  };

  timerKitchen = autoTimeKitchen;
  dashState = {
    windowHeight: stateObject.windowHeight,
    loading: stateObject.loading,
    openshow: stateObject.openshow,
    procshow: stateObject.procshow,
    compshow: stateObject.compshow,
    voidshow: stateObject.voidshow,
    pdopen: stateObject.pdopen,
    printer: stateObject.printer
  };

  billsProps = {
    getBills: getBills,
    updateBill: updateBill,
    printKitchenBill: printKitchenBill
  };

  billsState = {
    openbill: stateObject.openbill,
    procbill: stateObject.procbill,
    compbill: stateObject.compbill,
    voidbill: stateObject.voidbill,
    billid: stateObject.billid,
    loadKitch: stateObject.loadKitch
  };

  alertDialogProps = {
    state: {
      alert: stateObject.alert,
      head: stateObject.head,
      msg: stateObject.msg
    },
    closeDialog: closeDialog
  };

  headerProps = {
    state: {
      collapsed: stateObject.collapsed,
      notifications: stateObject.notifications,
      opencount: stateObject.opencount,
      proccount: stateObject.proccount,
      compcount: stateObject.compcount,
      voidcount: stateObject.voidcount
    },
    props: props,
    switchToDash: switchToDash,
    onFilterClick: onFilterClick,
    onLogout: onLogout,
    toggleCollapsed: toggleCollapsed,
    onCloseDrawer: onCloseDrawer,
    endSession: endSes,
    search: search,
    onError: onError
  };

  headerProps.props = allprops;
  [dashState, setDash] = useState(dashState);
  [alertDialogProps.state, setAlert] = useState(alertDialogProps.state);
  [headerProps.state, setHeader] = useState(headerProps.state);
  [billsState, setBills] = useState(billsState);

  props = allprops;

  useEffect(() => {
    componentWillMount();
    return () => componentWillUnmount();
  }, []);

  return (
    <div style={{}}>
      <AlertDialog {...alertDialogProps} />
      <Layout>
        {props.user.role.isAdmin || props.user.role.isSupAdmin ? (
          <AdminHeader {...headerProps} />
        ) : (
          <DashHeader {...headerProps} />
        )}
        <Layout
          style={{
            backgroundColor: 'rgb(17, 20, 26)',
            minHeight: '100vh',
            zIndex: 0
          }}
        >
          <Spin spinning={dashState.loading}>
            <Content>
              {dashState.openshow ? (
                <div
                  style={{
                    paddingTop: 80
                  }}
                >
                  {/*<div className='animation-wrapper' style={{ zIndex: -1 }}>
                    <div id='stars'></div>
                    <div id='stars2'></div>
                    <div id='stars3'></div>
                  </div>*/}
                  {Object.keys(billsState.openbill).map((billid, key) => (
                    <div style={{}} key={key}>
                      <MemoBills
                        billid={billid}
                        bills={billsState.openbill}
                        state={billsState}
                        props={billsProps}
                      />
                    </div>
                  ))}
                </div>
              ) : dashState.procshow ? (
                <div
                  style={{
                    paddingTop: 80
                  }}
                >
                  {billsState.procbill &&
                    Object.keys(billsState.procbill).map((billid, key) => (
                      <div style={{}} key={key}>
                        <MemoBills
                          billid={billid}
                          bills={billsState.procbill}
                          state={billsState}
                          props={billsProps}
                        />
                      </div>
                    ))}
                </div>
              ) : dashState.compshow ? (
                <div
                  style={{
                    paddingTop: 80
                  }}
                >
                  {billsState.compbill &&
                    Object.keys(billsState.compbill).map((billid, key) => (
                      <div style={{}} key={key}>
                        <MemoBills
                          billid={billid}
                          bills={billsState.compbill}
                          state={billsState}
                          props={billsProps}
                        />
                      </div>
                    ))}
                </div>
              ) : dashState.voidshow ? (
                <div
                  style={{
                    paddingTop: 80
                  }}
                >
                  {billsState.voidbill &&
                    Object.keys(billsState.voidbill).map((billid, key) => (
                      <div style={{}} key={key}>
                        <MemoBills
                          billid={billid}
                          bills={billsState.voidbill}
                          state={billsState}
                          props={billsProps}
                        />
                      </div>
                    ))}
                </div>
              ) : null}
            </Content>
          </Spin>
          <Snackbar {...props} />
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = oState => ({
  user: oState.backend.user,
  snackbar: oState.snackbar,
  bill: oState.dashboard.bill || [],
  notifications: oState.notifications.notifications || []
});

const mapDispatchToProps = {
  resetSync,
  resetUser,
  removeLogin,
  endSession,
  openSnackbar,
  closeSnackbar,
  getBillDash,
  getBillKitchen,
  updateBillKitchen,
  sendNotification,
  findNotifications,
  removeNotification,
  initLoader,
  removeLoader
};

export default memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(Kitchen))
);
