import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  DatePicker,
  Icon,
  Input,
  Row,
  Select,
  Spin,
  Table
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import { getPaymentTypeReport } from 'src/redux/actions/backend';

import Highlighter from 'react-highlight-words';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';

import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
const { Column } = Table;
const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { RangePicker } = DatePicker;

const { Option } = Select;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardPaymentTypeWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'inline'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    height: '150px',
    overflow: 'auto'
  },
  tableScroll: {
    width: '100%',
    height: '45vh',
    overflow: 'auto'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  datePicker: {
    marginRight: '10px',
    '& span': {
      height: '45px'
    }
  }
});

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      paymentType: {},
      items: [],
      datePicker: false,
      startDate: moment().format(dateFormatSearch),
      endDate: moment().format(dateFormatSearch),
      pickerStart: moment().startOf('month'),
      pickerEnd: moment(),
      alert: false,
      msg: [],
      head: ''
    };
  }

  componentDidMount() {
    this.getPaymentTypeReport();
  }

  getPaymentTypeReport = (msg, startDate, endDate) => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    var start = startDate;
    var end = endDate;
    if (!start) {
      start = this.state.startDate;
    }
    if (!endDate) {
      end = this.state.endDate;
    }
    this.props
      .getPaymentTypeReport(token, start, end)
      .then(result => {
        if (result.data.results === 'NoBills') {
          this.setState({
            paymentType: {},
            items: [],
            loading: false
          });
        } else {
          this.setState({
            paymentType: result.data.results,
            items: result.data.results.items,
            loading: false
          });
        }
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        startDate: value[0].format(dateFormatSearch),
        endDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1]
      });
    }
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'today') {
        this.setState({
          startDate: moment().format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getPaymentTypeReport(
          false,
          moment().format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'yester') {
        this.setState({
          startDate: moment()
            .subtract(1, 'days')
            .format(dateFormatSearch),
          endDate: moment()
            .subtract(1, 'days')
            .format(dateFormatSearch),
          datePicker: false
        });
        this.getPaymentTypeReport(
          false,
          moment()
            .subtract(1, 'days')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'last7') {
        this.setState({
          startDate: moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getPaymentTypeReport(
          false,
          moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'last30') {
        this.setState({
          startDate: moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getPaymentTypeReport(
          false,
          moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'thism') {
        this.setState({
          startDate: moment()
            .startOf('month')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getPaymentTypeReport(
          false,
          moment()
            .startOf('month')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'lastm') {
        this.setState({
          startDate: moment()
            .subtract(1, 'months')
            .startOf('month')
            .format(dateFormatSearch),
          endDate: moment()
            .subtract(1, 'months')
            .endOf('month')
            .format(dateFormatSearch),
          datePicker: false
        });
        this.getPaymentTypeReport(
          false,
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .format(dateFormatSearch),
          moment()
            .subtract(1, 'months')
            .endOf('month')
        );
      } else if (value === 'custom') {
        this.setState({
          datePicker: true
        });
      }
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Menu Items Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };
  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker
    };

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <div>
          <div
            style={{
              float: 'left',
              marginTop: '-10px',
              marginBottom: '10px'
            }}
          >
            <div
              className={
                this.state.datePicker
                  ? this.props.classes.show
                  : this.props.classes.hide
              }
            >
              <RangePicker
                allowClear={false}
                className={this.props.classes.datePicker}
                onChange={this.handleChangeDate}
                value={[this.state.pickerStart, this.state.pickerEnd]}
                format={dateFormat}
              />
              <Button
                style={{
                  backgroundColor: '#00CC70',
                  color: 'white',
                  top: '-0.05em',
                  height: '40px',
                  width: '120px',
                  border: '1px solid #00CC70',
                  borderRadius: '3em',
                  fontSize: '12px',
                  paddingBottom: '0.1em',
                  fontWeight: '600'
                }}
                onClick={() => this.getPaymentTypeReport()}
              >
                Get Report
              </Button>
            </div>
            <Select
              id="filterSelect"
              style={{
                width: '150px',
                paddingRight: '10px'
              }}
              defaultValue="today"
              onChange={this.handleChangeSelect}
            >
              <Option value="today">Today</Option>
              <Option value="yester">Yesterday</Option>
              <Option value="last7">Last 7 Days</Option>
              <Option value="last30">Last 30 Days</Option>
              <Option value="thism">This Month</Option>
              <Option value="lastm">Last Month</Option>
              <Option value="custom">Custom</Option>
            </Select>
          </div>
          <Card className={this.props.classes.cardExpand}>
            <CardBody>
              <GridContainer>
                <Row style={{ width: '100%' }}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.items}
                    pagination={{
                      locale: { items_per_page: ' Items' },
                      showSizeChanger: true,
                      onShowSizeChange: this.onShowSizeChange,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      hideOnSinglePage: true,
                      pageSize: this.state.pageSize || 25,
                      itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                          return <a>Previous</a>;
                        }
                        if (type === 'next') {
                          return <a>Next</a>;
                        }
                        return originalElement;
                      },
                      showTotal: (total, range) =>
                        `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                      defaultCurrent: 1
                    }}
                    onChange={this.handleSort}
                    onRow={(record, rowIndex) => {
                      if (record.qty <= record.lowqty) {
                        return { style: { backgroundColor: 'lightyellow' } };
                      } else if (rowIndex % 2 == 0) {
                        return { style: { background: 'rgb(247, 247, 247)' } };
                      }
                    }}
                  >
                    <Column
                      {...this.getColumnSearchProps('type')}
                      title="Payment Type"
                      dataIndex="type"
                      key="type"
                    />
                    <Column
                      title="Payment Transactions"
                      render={(text, record) => <span>{record.count}</span>}
                    />
                    <Column
                      title="Payment Amount"
                      render={(text, record) => (
                        <span>{'R ' + record.total.toFixed('2')}</span>
                      )}
                    />
                  </StyledTable>
                </Row>
              </GridContainer>
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Discount"
                    id="discount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.paymentType.discount
                        ? 'R ' + this.state.paymentType.discount.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Tip"
                    id="tip"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.paymentType.tip
                        ? 'R ' + this.state.paymentType.tip.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer> */}
              <GridContainer justify="space-between">
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Cash Total"
                    id="cash"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.paymentType.cash
                        ? 'R ' + this.state.paymentType.cash.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Card Total"
                    id="card"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.paymentType.card
                        ? 'R ' + this.state.paymentType.card.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Tab Total"
                    id="tab"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.paymentType.tab
                        ? 'R ' + this.state.paymentType.tab.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Discount Total"
                    id="discount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.paymentType.discount
                        ? 'R ' + this.state.paymentType.discount.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Total"
                    id="total"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onClick: () => this.onHelpMarker('menuitemsname'),
                      value: this.state.paymentType.total
                        ? 'R ' + this.state.paymentType.total.toFixed('2')
                        : 'R 0.00',
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </div>
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar
});

const mapDispatchToProps = {
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  getPaymentTypeReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Reports));
