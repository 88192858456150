import status from './status';
import backend from './backend';
import breadcrumbs from './breadcrumbs';
import snackbar from './snackbar';
import help from './help';
import groups from './subnav/groups';
import users from './subnav/users';
import stockitems from './subnav/stockitems';
import menuitems from './subnav/menuitems';
import dashboard from './dashboard';
import notifications from './notifications';
import customer from './customer';
import initdashloader from './initdashloader';

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload) {
        if (action.payload.backend) {
          if (action.payload.backend.user) {
            if (action.payload.backend.user._id !== '') {
              if (action.payload.backend.user.deliveryLocations) {
                /*eslint-disable */
                if (!window.pubnub) {
                  window.pubnub = new PubNub({
                    publishKey: 'pub-c-d683308a-5d30-4f43-9b8d-3e3064ba4f5d',
                    subscribeKey: 'sub-c-a9521a1c-cd13-11eb-9d78-4e51a9db8267'
                  });
                }
                /*eslint-enable */
              } else {
                /*eslint-disable */
                if (!window.pubnub) {
                  window.pubnub = new PubNub({
                    publishKey: 'pub-c-dba083a8-729c-4413-aea8-0473e0fe9677',
                    subscribeKey: 'sub-c-4528b54e-77df-11ea-808e-bad180999bc3'
                  });
                }
                /*eslint-enable */
              }

              window.pubnub.setUUID(action.payload.backend.user._id);
              let channels = [];
              channels.push(action.payload.backend.user._id);
              if (action.payload.backend.user.role) {
                if (action.payload.backend.user.role.isSupAdmin) {
                  channels.push(action.payload.backend.user.orgid + 'admin');
                }
              }
              window.pubnub.subscribe({
                channels: channels
              });
              if (window.cordova) {
                document.addEventListener(
                  'deviceready',
                  () => {
                    window.FirebasePlugin.getToken(
                      token => {
                        window.pubnub.push.addChannels(
                          {
                            channels: channels,
                            device: token,
                            pushGateway: 'gcm' // apns, gcm, mpns
                          },
                          function(status) {
                            if (status.error) {
                              console.log('operation failed w/ error:', status);
                            }
                          }
                        );
                      },
                      error => {
                        console.error(error);
                      }
                    );
                  },
                  false
                );
              }
            }
          }
        }
      }
      return true;
    default:
      return state;
  }
};

export default {
  rehydrated,
  status,
  backend,
  breadcrumbs,
  snackbar,
  help,
  groups,
  users,
  stockitems,
  menuitems,
  dashboard,
  notifications,
  customer,
  initdashloader
};
