import moment from 'moment';
const categoryreport = (items, startDate, endDate) => {
  let tableBody = [];
  tableBody.push([
    { text: 'Category', color: 'white', bold: true },
    { text: 'Items Sold', color: 'white', bold: true },
    { text: 'Net Sale', color: 'white', bold: true },
    { text: 'Cost of Goods', color: 'white', bold: true },
    { text: 'Profit Margin', color: 'white', bold: true },
    { text: 'Profit', color: 'white', bold: true }
  ]);
  items.forEach(record => {
    let lineItem = [];
    lineItem.push({ text: record._id, bold: true });
    lineItem.push({ text: record.qty, bold: true });
    lineItem.push({
      text: 'R ' + record.price.toFixed('2')
    });
    lineItem.push({
      text:
        'R ' +
        ((record.cost ? parseFloat(record.cost) : 0) * record.qty).toFixed('2'),
      bold: true
    });
    lineItem.push({
      text:
        (
          ((record.price - (record.cost || 0) * record.qty) / record.price) *
          100
        ).toFixed() + '%',
      bold: true
    });
    lineItem.push({
      text:
        'R ' +
        (
          record.price -
          (record.cost ? parseFloat(record.cost) : 0) * record.qty
        ).toFixed('2'),
      bold: true
    });
    tableBody.push(lineItem);
  });
  return {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    content: [
      {
        text: 'Sales by Category Report ',
        style: 'header'
      },
      {
        text:
          moment(startDate, 'YYYYMMDD').format('YYYY/MM/DD') +
          ' - ' +
          moment(endDate, 'YYYYMMDD').format('YYYY/MM/DD'),
        style: 'subheader'
      },
      '\n\n',
      {
        style: 'tableExample',
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          heights: [20],
          headerRows: 1,
          body: tableBody
        },
        layout: {
          hLineWidth: function(i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function(i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 0;
          },
          // paddingTop: function(i, node) {
          //     return (i === 0) ? 8 : 2;
          // },
          fillColor: function(rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#132440' : null;
          }
        }
      }
    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'center'
      },

      subheader: {
        fontSize: 14,
        bold: true,
        alignment: 'center'
      }
    }
  };
};
export default categoryreport;
