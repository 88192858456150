import React from 'react';
import { Icon, TreeSelect, Switch } from 'antd';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import CustomSelectMulti from 'src/components/CustomSelectMulti/CustomSelectMulti.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const { SHOW_PARENT } = TreeSelect;

const treeData = [
  {
    title: 'Dashboard',
    value: 'Dashboard',
    key: 'Dashboard',
    children: [],
  },
  {
    title: 'Products',
    value: 'Products',
    key: 'Products',
    children: [
      {
        title: 'My Catalogue',
        value: 'Products - My Catalogue',
        key: 'My Catalogue',
      },
      {
        title: 'Manage Products',
        value: 'Products - Manage Products',
        key: 'Manage Products',
      },
      {
        title: 'Specials',
        value: 'Products - Specials',
        key: 'Specials',
      },
      {
        title: 'Modifiers',
        value: 'Products - Modifiers',
        key: 'Modifiers',
      },
      {
        title: 'Item Type',
        value: 'Products - Item Type',
        key: 'Item Type',
      },
      {
        title: 'Void Reasons',
        value: 'Products - Void Reasons',
        key: 'Void Reasons',
      },
    ],
  },
  {
    title: 'Stock Control',
    value: 'Stock Control',
    key: 'Stock Control',
    children: [
      {
        title: 'Stock Items',
        value: 'Stock Control - Stock Items',
        key: 'Stock Items',
      },
      {
        title: 'Stock Adjustment',
        value: 'Stock Control - Stock Adjustment',
        key: 'Stock Adjustment',
      },
      {
        title: 'Purchase orders',
        value: 'Stock Control - Purchase orders',
        key: 'Purchase orders',
      },
      {
        title: 'Suppliers',
        value: 'Stock Control - Suppliers',
        key: 'Suppliers',
      },
    ],
  },
  {
    title: 'Reports',
    value: 'Reports',
    key: 'Reports',
    children: [
      {
        title: 'Sales Summary',
        value: 'Reports - Sales Summary',
        key: 'Sales Summary',
      },
      {
        title: 'Stock Report',
        value: 'Reports - Stock Report',
        key: 'Stock Report',
      },
      {
        title: 'Sales by Category',
        value: 'Reports - Sales by Category',
        key: 'Sales by Category',
      },
      {
        title: 'Sales by Payment Type',
        value: 'Reports - Sales by Payment Type',
        key: 'Sales by Payment Type',
      },
      {
        title: 'Sales by People',
        value: 'Reports - Sales by People',
        key: 'Sales by People',
      },
      {
        title: 'Modifiers Report',
        value: 'Reports - Modifiers Report',
        key: 'Modifiers Report',
      },
      {
        title: 'Shifts',
        value: 'Reports - Shifts',
        key: 'Shifts',
      },
      {
        title: 'Void Report',
        value: 'Reports - Void Report',
        key: 'Void Report',
      },
      {
        title: 'Receipts',
        value: 'Reports - Receipts',
        key: 'Receipts',
      },
    ],
  },
  {
    title: 'People',
    value: 'People',
    key: 'People',
    children: [
      {
        title: 'Groups',
        value: 'People - Groups',
        key: 'Groups',
      },
      {
        title: 'People',
        value: 'People - People',
        key: 'People ',
      },
      {
        title: 'Cashup',
        value: 'People - Cashup',
        key: 'Cashup',
      },
      {
        title: 'Tab Customers',
        value: 'People - Tab Customers',
        key: 'Tab Customers',
      },
    ],
  },
  {
    title: 'General Settings',
    value: 'General Settings',
    key: 'General Settings',
    children: [
      {
        title: 'Email Settings',
        value: 'General Settings - Email Settings',
        key: 'Email Settings',
      },
      {
        title: 'Printer Settings',
        value: 'General Settings - Printer Settings',
        key: 'Printer Settings',
      },
      {
        title: 'General Settings',
        value: 'General Settings - General Settings',
        key: 'General Settings1',
      },
      {
        title: 'Billing & Subscription',
        value: 'General Settings - Billing & Subscription',
        key: 'Billing & Subscription',
      }
    ],
  },
];
const FormBody = ({ ...oState }) => {
  return (
    <Card>
      {/*}  <CardHeader
        style={{
          borderRadius: '0.5em',
          background: 'rgb(30,161,169)'
        }}
      >
        {' '}
        <h4
          style={{ color: 'white' }}
          className={oState.props.classes.cardTitleWhite}
        >
          {oState.state.userlisthead}
        </h4>
        <p
          style={{ color: 'rgba(255,255,255,.62)' }}
          className={oState.props.classes.cardCategoryWhite}
        >
          {oState.state.userlistsub}
        </p>
      </CardHeader> */}
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Group Name*"
              id="groupname"
              autoFocus={true}
              error={!oState.state.groupName && oState.state.submitInProgress}
              errorText={'Group name required'}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                value: oState.state.groupName,
                onClick: () => oState.onHelpMarker('usersname')
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CustomSelect
              labelText="Role"
              id="role"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onClick: () => oState.onHelpMarker('menuitemstype'),
                onChange: e => oState.handleChangeRoleType(e),
                value: oState.state.role
              }}
              menuItems={oState.roles}
            />
          </GridItem>
          {oState.state.role === 'isClerk' ? (
            <GridItem xs={12} sm={12} md={4} style={{marginTop: '3em',marginLeft:"1.5em"}}>
              <FormControlLabel
                control={
                  <Switch
                    style={{ marginRight: '1em' }}
                    checked={oState.state.direct}
                    onChange={(e) => oState.handleSwitchGroup(e)}
                  />
                }
                label="Direct Sales"
              />
            </GridItem>
          ) : null}
        </GridContainer>
        {oState.state.role === 'isAdmin' ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TreeSelect treeData={treeData}
                value={oState.state.selectGroupRoles.length > 0 ? oState.state.selectGroupRoles : undefined}
                onChange={e => oState.handleChangeRoles(e)}
                treeCheckable={true}
                showCheckedStrategy={SHOW_PARENT}
                placeholder='Access To'
                style={{
                  marginTop: "1em",
                  marginBottom: "1em",
                  width: '100%',
                }} />
              {/* <CustomSelectMulti
                labelText="Access To:*"
                id="Access"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: oState.state.role === 'isAdmin' ? false : true,
                  onClick: () => oState.onHelpMarker('menuitemstype'),
                  onChange: e => oState.handleChangeRoles(e),
                  value: oState.state.selectGroupRoles,
                  open: oState.state.multiOpen,
                  onClose: e => oState.changeMultiOpen(false),
                  onOpen: e => oState.changeMultiOpen(true)
                }}
                menuItems={oState.state.groupRoles}
              /> */}
            </GridItem>
          </GridContainer>
        ) : null}
        <CardFooter style={{margin: "auto",marginTop:"1em", borderTop: "1px solid rgba(229, 233, 242, 1)" }}>
        <GridItem xs={12} sm={12} md={12} >
          <Button style={{textTransform: 'initial', fontSize: "13px", marginLeft: '0.5em', borderRadius: "3.5em", float: "right", boxShadow: "none", border: "1px solid #00CC70"  }} onClick={oState.submitFormGroup} color="primary">
            {oState.state.userlistbutton}
          </Button>
          <Button style={{ textTransform: 'initial', fontSize: "13px", float: "right", borderRadius: "3.5em", backgroundColor: "rgba(229, 233, 242, 1)", border: "1px solid rgba(229, 233, 242, 1)", color: "black", boxShadow: "none" }} onClick={oState.handleCloseGroup} color="primary">
            Cancel
          </Button>
          </GridItem>
        </CardFooter>
      </CardBody>
      <Button
        style={{
          position: 'fixed',
          right: '0em',
          bottom: '4em',
          background: '#132440',
          border: '1px solid transparent',
          color: 'white',
          padding: '0em',
          width: '3.5em',
          height: '3.5em',
          boxShadow: "none",
          borderRadius: '5em 0em 0em 5em',
          fontSize: '1em',
          zIndex: 1000
        }}
        onClick={oState.submitFormGroup}
        color="primary"
      >
        <Icon
          style={{
            position: 'absolute',
            paddingLeft: 3
          }}
          type="check"
        />
      </Button>
    </Card>
  );
};

export default FormBody;
