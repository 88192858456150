
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Col,
  DatePicker,
  Icon,
  Input,
  Popover,
  Row,
  Spin,
  Table,
  Card,
  Radio,
  Empty,
  ConfigProvider,
  Button,
  Select,
  Statistic,
  Upload,
  Checkbox,
  Tooltip,
  AutoComplete
} from 'antd';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import styled from 'styled-components';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  addQuote,
  getQuotes,
  getQuoteItems,
  updatePo,
  getQuoteReport,
  resendQuote,
  convertPOToDraft,
  getQuoteCustomers
} from 'src/redux/actions/backend';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import convert from 'convert-units';
import { formaterCheck } from 'src/utils/formater.js';

const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const StyledStatistic = styled(Statistic)`
  .ant-statistic-title {
    position: relative;
    top: -10px;
    box-shadow: 0 6px 4px -2px rgb(220, 225, 238);
    margin-bottom:-0.7em;
    padding-bottom:1em;
    border-bottom:5px solid grey;
    color: black;
    font-size: 19px;
    font-weight:600;
  }
`;
const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(0, 204, 112, 0.898);
   
  }
 
`;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  tableScroll: {
    width: '100%',
    height: '55vh',
    overflow: 'auto'
  }
});


const dimTypesFormat = {
  'l': ' L',
  'ml': ' ml',
  'kg': ' Kg',
  'g': ' g',
  'm': ' m',
  'cm': ' cm',
  'mm': ' mm',
  'unit': ' Units'
};

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}
class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      _id: '',
      name: '',
      email: '',
      customer: '',
      customername: '',
      ref: '',
      note: '',
      total: 0,
      quotes: [],
      customers: [],
      menuItems: [],
      types: [],
      stockitems: {},
      stock: {},
      quoteItems: [],
      supStock: {},
      startDate: moment().format(dateFormatSearch),
      expDate: moment().format(dateFormatSearch),
      reportStartDate: moment()
        .subtract(30, 'd')
        .format(dateFormatSearch),
      reportEndDate: moment().format(dateFormatSearch),
      pickerStart: moment(),
      pickerExp: moment(),
      loading: true,
      main: true,
      switch: false,
      userlisthead: 'Add Quote',
      userlistsub: 'Add a New Quote',
      userlistbutton: 'ADD PURCHASE ORDER',
      alert: false,
      msg: [],
      head: '',
      activeFilter: true,
      activeFilterStatus: [''],
      activeFilterQty: false,
      itemFilters: [],
      filteredInfo: {},
      report: {
        totalAmount: "0",
        totalCount: "0",
        pendingAmount: "0",
        pendingCount: "0",
        completeAmount: "0",
        completeCount: "0"
      },
      itemSearchOptions: []
    };
  }
  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb)
    this.props.setBreadCrumbs(breadCrumbs)
  }
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop()
    this.props.setBreadCrumbs(breadCrumbs)
  }

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false)
      this.props.resetActionBreadCrumb()
      this.handleRemoveBreadCrumb()
    }
  }

  componentDidMount() {
    this.getQuotes();
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    this.setState({ isScrolling: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 2000);
  };
  getQuotes = msg => {
    const token = this.props.local.user.token;
    this.getQuoteReport()
    this.props
      .getQuotes(token, this.state.reportStartDate, this.state.reportEndDate)
      .then(result => {
        this.handleRemoveBreadCrumb()
        let itemFiltersPrep = [];
        let types = result.data.results.types;
        for (var i = 0; i < types.length; i++) {
          if (itemFiltersPrep.indexOf(types[i].name) === -1) {
            itemFiltersPrep.push(types[i].name);
          }
        }
        let itemFilters = [];
        for (var i = 0; i < itemFiltersPrep.length; i++) {
          itemFilters.push({
            text: itemFiltersPrep[i],
            value: itemFiltersPrep[i]
          });
        }
        this.setState({
          quotes: result.data.results.quotes,
          types: result.data.results.types,
          itemFilters: itemFilters,
          name: '',
          email: '',
          customer: '',
          customername: '',
          ref: '',
          note: '',
          total: 0,
          startDate: moment().format(dateFormatSearch),
          expDate: moment().format(dateFormatSearch),
          pickerStart: moment(),
          pickerExp: moment(),
          menuItems: [],
          main: true,
          switch: false,
          loading: false,
          activeFilter: true,
          activeFilterQty: false,
          quoteId: undefined
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  resendQuote = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .resendQuote(this.state._id, token)
      .then(res => {
        this.getQuotes('Quote resent to customer');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  addQuotes = email => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .addQuote(this.state, token, email)
      .then(res => {
        this.getQuotes('Quote Success');
      })
      .catch(err => {
        this.onError(err);
      });
  }

  updateQuote = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .updatePo(this.state, token)
      .then(res => {
        this.getQuotes('Quote Update Success');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  getQuoteItems = msg => {
    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });
    this.props.getQuoteCustomers(token)
      .then(result => {
        this.props
          .getQuoteItems(token)
          .then(result2 => {
            this.handleAddBreadCrumb({ method: "switch", crumb: "Quote" })
            var name = 'Q0000';
            name =
              this.state.quotes.length > 0
                ? name.slice(0, -(this.state.quotes.length + 1).toString().length) +
                (this.state.quotes.length + 1).toString()
                : 'Q0001';
            this.setState({
              customer: '',
              customername: '',
              ref: '',
              note: '',
              startDate: moment().format(dateFormatSearch),
              expDate: moment().format(dateFormatSearch),
              pickerStart: moment(),
              pickerExp: moment(),
              menuItems: [],
              name: name,
              email: '',
              total: 0,
              customers: result.data.results,
              quoteItems: [],
              filteredInfo: {},
              main: false,
              switch: false,
              activeFilter: undefined,
              activeFilterQty: false,
              loading: false,
              pageSize: 25,
              status: ""
            });
            var sup = {};
            sup.total = 0;
            sup.customer = 'none';
            sup.customername = 'none';
            sup.email = '';
            sup.menuItems = [];
            if (result2.data.results) {
              sup.menuItems = result2.data.results;
            }
            this.setState(sup);
            if (msg) {
              this.props.openSnackbar(msg);
            }
          })
          .catch(err => {
            this.onError(err);
          });
      })

  };

  showOrder = r => {
    this.handleAddBreadCrumb({ method: "switch", crumb: "Quote" })
    if (r.status === "Draft") {
      const token = this.props.local.user.token;
      this.setState({
        loading: true
      });
      this.props.getQuoteCustomers(token)
        .then(result => {
          this.props
            .getQuoteItems(token)
            .then(result2 => {
              this.setState({
                customer: r.customer,
                customername: r.customername,
                _id: r._id,
                ref: r.ref,
                note: r.note,
                startDate: r.startDate,
                pickerStart: moment(),
                pickerExp: moment(),
                menuItems: result2.data.results || [],
                name: r.name,
                email: r.email,
                total: r.total,
                status: r.status,
                customers: result.data.results,
                quoteItems: r.quoteItems,
                main: false,
                switch: false,
                loading: false
              });
            })
            .catch(err => {
              this.onError(err);
            });
        })
        .catch(err => {
          this.onError(err);
        });
    } else {
      const token = this.props.local.user.token;
      this.setState({
        loading: true
      });
      this.props.getQuoteCustomers(token)
        .then(result => {
          this.props
            .getQuoteItems(token)
            .then(result2 => {
              this.setState({
                customer: r.customer,
                customername: r.customername,
                _id: r._id,
                ref: r.ref,
                note: r.note,
                startDate: r.startDate,
                pickerStart: moment(),
                pickerExp: moment(),
                menuItems: result2.data.results || [],
                name: r.name,
                email: r.email,
                total: r.total,
                status: r.status,
                customers: result.data.results,
                quoteItems: r.quoteItems,
                main: false,
                switch: false,
                loading: false
              });
            })
            .catch(err => {
              this.onError(err);
            });
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  switch = () => {
    this.getQuotes();
  };

  handleChangeDateStart = value => {
    if (value) {
      this.setState({
        startDate: value.format(dateFormatSearch),
        pickerStart: value
      });
    }
  };

  handleChangeDateExp = value => {
    if (value) {
      this.setState({
        expDate: value.format(dateFormatSearch),
        pickerExp: value
      });
    }
  };

  handleCheck = () => {
    this.setState({ cooking: !this.state.cooking });
  };

  handleChange = event => {
    if (event.target.name === 'customer') {
      var sup = {};
      sup.customer = event.target.value;
      sup.customername = event.currentTarget.textContent;
      sup.email = '';
      const customerIndex = this.state.customers.findIndex(customer => customer._id === event.target.value)
      if (customerIndex > -1) {
        sup.email = this.state.customers[customerIndex].email || '';
      }
      this.setState(sup);
    }
  };

  tableAmountCheck = (record, e) => {
    var stock = this.state.stock;
    var customer = this.state.customer;
    stock[this.state.customer][record.stockid].select = e.target.checked;
    var ttotal = 0;
    Object.keys(stock[customer]).forEach(function (key) {
      if (stock[customer][key].select) {
        if (stock[customer][key].total) {
          ttotal = ttotal + stock[customer][key].total;
        }
      }
    });
    this.setState({ supStock: stock, total: ttotal });
  };

  pressQtyEnter = (record, event) => {
    if (event.target.parentElement.parentElement.parentElement.nextSibling) {
      event.target.parentElement.parentElement.parentElement.nextSibling.childNodes[5].children[0].children[0].focus()
    }
  }

  pressQtyEnter2 = (record, event) => {
    if (event.target.parentElement.parentElement.parentElement.nextSibling) {
      event.target.parentElement.parentElement.parentElement.nextSibling.childNodes[4].children[0].children[0].focus()
    }
  }

  tableAmount = (record, e, dimChange) => {
    let { value, name } = e.target;
    var stock = this.state.stock;
    var customer = this.state.customer;
    if (value || dimChange) {
      if (dimChange) {
        stock[customer][record.stockid].amount = '1';
        stock[customer][record.stockid].cost = '';
        stock[customer][record.stockid].dimTypeOrder = dimChange;
      } else {
        value = formaterCheck(value || '0');
        if (value >= 0) {
          if (e.target.name === 'qty') {
            stock[customer][record.stockid].amount = value;
          }
          if (e.target.name === 'cost') {
            if (stock[customer][record.stockid].costChange > 0) {
              stock[customer][record.stockid].costChange = value;
            } else {
              stock[customer][record.stockid].cost = value;
            }
          }
          if (e.target.name === 'tax') {
            stock[customer][record.stockid].tax = value;
          }
        }
      }
      var costPrice = '0';
      if (stock[customer][record.stockid].dimTypeOrder === 'packsize') {
        costPrice = this.state.stockitems[record.stockid].packBuyPrice;
        stock[customer][record.stockid].costChange = false;
      } else if (stock[customer][record.stockid].dimTypeOrder === 'unit') {
        costPrice = stock[customer][record.stockid].cost || this.state.stockitems[record.stockid].buyPrice || '0';
        stock[customer][record.stockid].costChange = false;
      } else if (record.stockSize && record.sizeObject[stock[customer][record.stockid].dimTypeOrder] && record.sizeObject[stock[customer][record.stockid].dimTypeOrder].packSize) {
        costPrice = stock[customer][record.stockid].cost || this.state.stockitems[record.stockid].buyPrice || '0';
        stock[customer][record.stockid].costChange = parseFloat(this.state.stockitems[record.stockid].packBuyPrice) * (record.sizeObject[stock[customer][record.stockid].dimTypeOrder].qty / this.state.stockitems[record.stockid].packSize);
      } else {
        if (stock[customer][record.stockid].dimTypeOrder !== this.state.stockitems[record.stockid].dimType && dimTypesFormat[stock[customer][record.stockid].dimTypeOrder]) {
          var fromMetric = stock[customer][record.stockid].dimTypeOrder || this.state.stockitems[record.stockid].dimType;
          var toMetric = this.state.stockitems[record.stockid].dimType;
          var metricCost = convert(1)
            .from(fromMetric)
            .to(toMetric);
          costPrice = stock[customer][record.stockid].cost || this.state.stockitems[record.stockid].buyPrice || '0';
          stock[customer][record.stockid].costChange = parseFloat(costPrice) * parseFloat(metricCost);
        } else {
          costPrice = stock[customer][record.stockid].cost || this.state.stockitems[record.stockid].buyPrice || '0';
          stock[customer][record.stockid].costChange = false;
        }
      }

      stock[customer][record.stockid].cost = costPrice;

      var metricAmount = stock[customer][record.stockid].amount;
      if (record.stockSize && record.sizeObject[stock[customer][record.stockid].dimTypeOrder] && record.sizeObject[stock[customer][record.stockid].dimTypeOrder].packSize) {
        metricAmount = record.sizeObject[stock[customer][record.stockid].dimTypeOrder].qty * (stock[customer][record.stockid].amount || 1);
      } else if (stock[customer][record.stockid].dimension !== 'unit') {
        var qty = stock[customer][record.stockid].amount || 1;
        var fromMetric = stock[customer][record.stockid].dimTypeOrder !== 'packsize' ? stock[customer][record.stockid].dimTypeOrder : this.state.stockitems[record.stockid].dimType;
        var toMetric = this.state.stockitems[record.stockid].dimType;
        if (record.stockSize && record.sizeObject[stock[customer][record.stockid].dimTypeOrder]) {
          qty = record.sizeObject[stock[customer][record.stockid].dimTypeOrder].qty * metricAmount;
          fromMetric = record.sizeObject[stock[customer][record.stockid].dimTypeOrder].dimType;
        }
        metricAmount = convert(qty)
          .from(fromMetric)
          .to(toMetric);
      } else {
        if (record.stockSize && record.sizeObject[stock[customer][record.stockid].dimTypeOrder]) {
          metricAmount = record.sizeObject[stock[customer][record.stockid].dimTypeOrder].qty * metricAmount;
        }
      }

      var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
      stock[customer][record.stockid].total = totalCost;

      if (stock[customer][record.stockid].total > 0 || stock[customer][record.stockid].amount !== "") {
        stock[customer][record.stockid].select = true;
      } else {
        stock[customer][record.stockid].select = false;
      }
      var ttotal = 0;
      Object.keys(stock[customer]).forEach(function (key) {
        if (stock[customer][key].select) {
          if (stock[customer][key].total) {
            ttotal = ttotal + stock[customer][key].total;
          }
        }
      });

      this.setState({ supStock: stock, total: ttotal });
    } else {
      if (e.target.name === 'qty') {
        stock[customer][record.stockid].amount = '';
      }
      if (e.target.name === 'cost') {
        stock[customer][record.stockid].cost = '';
      }
      if (e.target.name === 'tax') {
        stock[customer][record.stockid].tax = '15';
      }
      var cost = stock[customer][record.stockid].cost || '0';
      var amount = stock[customer][record.stockid].amount || '0';
      var tax = stock[customer][record.stockid].tax || '15';
      var total = parseFloat(cost) * parseFloat(amount);
      var taxt = (total * parseFloat(tax)) / 100;
      stock[customer][record.stockid].total = total;
      if (stock[customer][record.stockid].total > 0 || stock[customer][record.stockid].amount !== "") {
        stock[customer][record.stockid].select = true;
      } else {
        stock[customer][record.stockid].select = false;
      }
      var ttotal = 0;
      Object.keys(stock[customer]).forEach(function (key) {
        if (stock[customer][key].select) {
          if (stock[customer][key].total) {
            ttotal = ttotal + stock[customer][key].total;
          }
        }
      });

      this.setState({ supStock: stock, total: ttotal });
    }
  };

  tableAmountQuote = (record, e) => {
    var stock = this.state.stockadjustview;
    const { value } = e.target;
    if (value) {
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        for (var i = 0; i < stock.length; i++) {
          if (stock[i].stockid === record.stockid) {
            if (
              parseFloat(value) >
              (stock[i].canSupply ? parseFloat(stock[i].canSupply) : 0)
            ) {
              break;
            } else {
              stock[i].received = parseFloat(value);
              stock[i].torec =
                (stock[i].canSupply ? parseFloat(stock[i].canSupply) : 0) -
                stock[i].received;
              break;
            }
          }
        }
        this.setState({ stockadjustview: stock });
      }
    } else {
      for (var i = 0; i < stock.length; i++) {
        if (stock[i].stockid === record.stockid) {
          stock[i].received = 0;
          stock[i].torec = stock[i].Qty ? parseFloat(stock[i].Qty) : 0;
          break;
        }
      }
      this.setState({ stockadjustview: stock });
    }
  };

  searchItem = (value, index) => {
    let quoteItems = this.state.quoteItems;
    quoteItems[index].name = value
    this.setState({ quoteItems: quoteItems })
    if (value.length > 2) {
      let itemSearchOptions = []
      let itemSearchPrices = []
      this.state.menuItems.forEach(item => {
        if (item.name.toLowerCase().search(value.toLowerCase()) > -1) {
          let itemName = item.variantName ? item.code + " - " + item.name + " - " + item.variantName : item.code + " - " + item.name
          itemSearchOptions.push(itemName)
          itemSearchPrices.push({ code: item.code, price: parseFloat(item.price) })
        } else if (item.code.toLowerCase().search(value.toLowerCase()) > -1) {
          let itemName = item.variantName ? item.code + " - " + item.name + " - " + item.variantName : item.code + " - " + item.name
          itemSearchOptions.push(itemName)
          itemSearchPrices.push({ code: item.code, price: parseFloat(item.price) })
        } else if (item.variantName && item.variantName.toLowerCase().search(value.toLowerCase()) > -1) {
          let itemName = item.variantName ? item.code + " - " + item.name + " - " + item.variantName : item.code + " - " + item.name
          itemSearchOptions.push(itemName)
          itemSearchPrices.push({ code: item.code, price: parseFloat(item.price) })
        }
      })
      this.setState({ itemSearchOptions: itemSearchOptions, itemSearchPrices: itemSearchPrices })
    };
  };

  changeQty = (record, event, index) => {
    let { value } = event.target;
    let quoteItems = this.state.quoteItems;
    quoteItems[index].qty = parseInt(value)
    quoteItems[index].total = parseInt(value) * quoteItems[index].price
    let total = 0;
    quoteItems.forEach(item => {
      total = total + item.total;
    })
    this.setState({ quoteItems: quoteItems, total: total })
  }

  changePrice = (record, event, index) => {
    let { value } = event.target;
    let quoteItems = this.state.quoteItems;
    quoteItems[index].price = parseFloat(value)
    quoteItems[index].total = quoteItems[index].qty * quoteItems[index].price
    let total = 0;
    quoteItems.forEach(item => {
      total = total + item.total;
    })
    this.setState({ quoteItems: quoteItems, total: total })
  }

  onSelectItem = (e, index) => {
    let quoteItems = this.state.quoteItems;
    this.state.itemSearchPrices.forEach(item => {
      if (item.code = e.split(" - ")[0]) {
        quoteItems[index].price = item.price
      }
    })
    quoteItems[index].code = e.split(" - ")[0]
    quoteItems[index].name = e.split(" - ")[2] ? e.split(" - ")[1] + " - " + e.split(" - ")[2] : e.split(" - ")[1]
    quoteItems[index].total = quoteItems[index].qty * quoteItems[index].price || 0
    let total = 0;
    quoteItems.forEach(item => {
      total = total + item.total;
    })
    this.setState({ quoteItems: quoteItems, total: total, itemSearchOptions: [], itemSearchPrices: [] })
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Quote Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  submitForm = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    if (oEvent.currentTarget.innerText === 'ADD PURCHASE ORDER') {
      this.props
        .addMenuItem(this.state, token)
        .then(res => {
          this.setState({
            main: true,
            loading: false
          });
          this.getQuotes('Quote Creation Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    } else if (oEvent.currentTarget.innerText === 'EDIT PURCHASE ORDER') {
      this.props
        .editMenuItem(this.state, token)
        .then(res => {
          this.setState({
            main: true,
            loading: false
          });
          this.getQuotes('Quote Update Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  onChangeEvt = e => {
    if (e.target.id === 'email') {
      this.setState({ email: e.currentTarget.value });
    }
    if (e.target.id === 'description') {
      this.setState({ note: e.currentTarget.value });
    }
    if (e.target.id === 'reference') {
      this.setState({ ref: e.currentTarget.value });
    }
  };

  handleEditMenuItem = oMenu => {
    this.props.handleOpenAddMenuItems();
    this.setState({
      main: false,
      filteredInfo: {},
      userlisthead: 'Edit Quote',
      userlistsub: 'Edit a Quote',
      userlistbutton: 'EDIT PURCHASE ORDER',
      pageSize: 25
    });
  };

  handleFormatListQty = () => {
    this.setState({ activeFilterQty: true, activeFilter: true });
  };
  handleFormatList = format => {
    this.setState({ activeFilter: format, activeFilterQty: false });
  };

  handleFormatListStatus = format => {
    this.setState({ activeFilterStatus: format });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSort = (pagination, filters, sorter) => {
    this.setState(prevState => ({
      ...prevState,
      filteredInfo: filters,
      sortedInfo: sorter
    }));
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnFilterProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <Select
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Filter ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e ? [e] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {this.state.types.map((items, key) => {
              return (
                <Option key={key} value={items.value || items._id}>
                  {items.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >Filter</Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >Reset</Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (!visible) {
        setTimeout(() => this.searchInput.rcSelect.setOpenState(false))
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });

  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: "3em",
          marginBottom: "0.5em",
          height: 90,
        }} />

      <button
        style={{

          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',
          width: '12em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',
          borderRadius: '3em',
          marginTop: "1em",
          marginBottom: "1.5em",
        }}
        onClick={() => this.getQuoteItems()}
        color="primary"
      >
        {'Add Quote'}
      </button>
    </div>
  );
  getQuoteReport = (start, end) => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    this.props
      .getQuoteReport(token, this.state.reportStartDate, this.state.reportEndDate)
      .then(result => {
        if (result.data.results === 'NoBills') {
          this.setState({
            report: {
              totalAmount: "0",
              totalCount: "0",
              pendingAmount: "0",
              pendingCount: "0",
              completeAmount: "0",
              completeCount: "0"
            },
            loading: false
          });
        } else {
          this.setState({
            report: result.data.results,
            loading: false
          });
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        reportStartDate: value[0].format(dateFormatSearch),
        reportEndDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1]
      });
    }
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'yester') {
        this.setState({
          reportStartDate: moment().subtract(1, 'days').format(dateFormatSearch),
          reportEndDate: moment().subtract(1, 'days').format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getQuotes()
        });
      } else if (value === 'today') {
        this.setState({
          reportStartDate: moment().format(dateFormatSearch),
          reportEndDate: moment().format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getQuotes()
        });
      } else if (value === 'last7') {
        this.setState({
          reportStartDate: moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          reportEndDate: moment().format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getQuotes()
        });
      } else if (value === 'last30') {
        this.setState({
          reportStartDate: moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          reportEndDate: moment().format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getQuotes()
        });
      } else if (value === 'thism') {
        this.setState({
          reportStartDate: moment()
            .startOf('month')
            .format(dateFormatSearch),
          reportEndDate: moment().format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getQuotes()
        });
      } else if (value === 'lastm') {
        this.setState({
          reportStartDate: moment()
            .subtract(1, 'months')
            .startOf('month')
            .format(dateFormatSearch),
          reportEndDate: moment()
            .subtract(1, 'months')
            .endOf('month')
            .format(dateFormatSearch),
          datePicker: false
        }, () => {
          this.getQuotes()
        });
      } else if (value === 'custom') {
        this.setState({
          datePicker: true
        });
      }
    }
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState, currentPage: current, pageSize: pageSize
    }));
  };

  addItem = () => {
    let quoteItems = this.state.quoteItems;
    quoteItems.push({
      code: '',
      name: '',
      qty: 1,
      price: 0,
      total: 0
    })
    this.setState({ quoteItems: quoteItems })
  }

  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onChangeEvt: this.onChangeEvt,
      onHelpMarker: this.onHelpMarker
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',
            color: 'rgb(15, 135, 123)',
            height: "2.5em",
            width: "6em",
            margin: "0.3em",
            borderRadius: '3em',
            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',
            color: 'rgb(15, 135, 123)',
            height: "2.5em",
            width: "6em",
            margin: "0.3em",
            borderRadius: '3em',
            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: "2.5em",
            margin: "0.3em",
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['Awaiting Acceptance'])}
        >
          Awaiting Acceptance
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: "2.5em",
            width: "6.5em",
            margin: "0.3em",
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['Complete'])}
        >
          Accepted
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: "2.5em",
            width: "6em",
            margin: "0.3em",
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus([''])}
        >
          All
        </Button>
      </div>
    );
    let menuItems = this.state.menuItems;
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <div />
        {this.state.main ? (
          <div>
            {!this.state.switch ? (
              <div>
                <div>
                  <Row>
                    <div
                      style={{
                        float: 'left'
                      }}
                    >
                      {this.state.datePicker ?
                        <div>
                          <RangePicker allowClear={false}
                            className={this.props.classes.datePicker}
                            onChange={this.handleChangeDate}
                            value={[this.state.pickerStart, this.state.pickerEnd]}
                            format={dateFormat}
                          />
                        </div>
                        : null}

                      {this.state.datePicker ?
                        <Button
                          style={{
                            backgroundColor: '#00CC70',
                            color: 'white',
                            top: '-0.05em',
                            height: '40px',
                            width: '120px',
                            border: '1px solid #00CC70',
                            borderRadius: '3em',
                            fontSize: '12px',
                            paddingBottom: "0.1em",
                            fontWeight: '600'
                          }}
                          onClick={() => this.getQuotes()}
                        >
                          Get Report
              </Button>
                        : null}
                    </div>
                  </Row>

                  <Row>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-today-sales"
                          title={
                            <span>Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{
                                marginTop: "-0.1em",
                                backgroundColor: "#00CC70",
                                float: "right",
                                paddingRight: "0.77em",
                                paddingTop: "0.2em",
                                borderRadius: "3em",
                                paddingBottom: "0.4em",
                                paddingLeft: "0.77em"
                              }}><span style={{
                                color: 'white',
                              }}>{this.state.report.totalCount > 0 ? this.state.report.totalCount : "0"}</span></div></span>}
                          value={
                            this.state.report.totalAmount
                          }
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: "nowrap",
                            bottom: -10,
                            fontWeight: 600,
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-profit"
                          title={
                            <span>Awaiting Acceptance &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{
                                marginTop: "-0.1em",
                                backgroundColor: "#1FA9F6",
                                float: "right",
                                paddingRight: "0.77em",
                                paddingTop: "0.2em",
                                borderRadius: "3em",
                                paddingBottom: "0.4em",
                                paddingLeft: "0.77em"
                              }}><span style={{
                                color: 'white',
                              }}>{this.state.report.pendingCount > 0 ? this.state.report.pendingCount : "0"}</span></div></span>}
                          value={
                            this.state.report.pendingAmount
                          }
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: "nowrap",
                            bottom: -10,
                            fontWeight: 600,
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-open-bills"
                          title={
                            <span>Accepted &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{
                                marginTop: "-0.1em",
                                backgroundColor: "#1D51A7",
                                float: "right",
                                paddingRight: "0.77em",
                                paddingTop: "0.2em",
                                borderRadius: "3em",
                                paddingBottom: "0.4em",
                                paddingLeft: "0.77em"
                              }}><span style={{
                                color: 'white',
                              }}>{this.state.report.completeCount > 0 ? this.state.report.completeCount : "0"}</span></div></span>}
                          value={
                            this.state.report.completeAmount
                          }
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: "nowrap",
                            bottom: -10,
                            fontWeight: 600,
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>

                  </Row>
                </div>
                <Button
                  style={{
                    position: 'fixed',
                    right: '0.8em',
                    bottom: '0.8em',
                    background: '#132440',
                    border: '1px solid transparent',
                    color: 'white',
                    padding: '0em',
                    width: '3.3em',
                    height: '3.3em',
                    borderRadius: '5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                  onClick={() => this.getStockItems()}
                  color="primary"
                >
                  <Icon type="plus"
                    style={{
                      paddingTop: "0.2em",
                      fontSize: '1.3em',

                    }}
                  >

                  </Icon >
                </Button>
                {this.state.quotes.length > 0 ? <Row style={{ float: "right", marginRight: "17.5em" }}>
                  <button
                    style={{
                      marginTop: '1em',
                      backgroundColor: '#00CC70',
                      border: '0px solid rgb(30, 161, 169)',
                      color: 'white',

                      width: '12em',
                      height: '2.7em',
                      fontSize: 16,
                      fontFamily: '',

                      borderRadius: '3em',
                      zIndex: 99,
                      position: 'absolute'
                    }}
                    onClick={() => this.getQuoteItems()}
                    color="primary"
                  >
                    {'Add Quote'}
                  </button>
                </Row> : null}
                <Row>
                  <Select
                    id="filterSelect"
                    style={{
                      width: '150px',
                      paddingRight: '10px',
                      marginTop: "1em",
                      marginLeft: "1em",
                    }}
                    defaultValue="last30"
                    onChange={this.handleChangeSelect}
                  >
                    <Option value="today">Today</Option>
                    <Option value="yester">Yesterday</Option>
                    <Option value="last7">Last 7 Days</Option>
                    <Option value="last30">Last 30 Days</Option>
                    <Option value="thism">This Month</Option>
                    <Option value="lastm">Last Month</Option>
                    <Option value="custom">Custom</Option>
                  </Select>
                  <div
                    style={{
                      float: 'right',
                      marginTop: '1em',
                      marginBottom: '1em'
                    }}
                  >
                    <Popover
                      placement="left"
                      content={EyeContent}
                      title=""
                      trigger="click"
                    >
                      <Button
                        style={{
                          backgroundColor: '#00CC70',
                          border: '0px solid rgb(30, 161, 169)',
                          color: 'white',
                          marginBottom: '-0.5em',
                          height: '3.2em',
                          marginLeft: '-0.27em',
                          borderRadius: '3em',

                          width: '3.2em'
                        }}
                      >
                        <Icon
                          style={{

                          }}
                          type="setting"
                        />
                      </Button>
                    </Popover>
                    <Popover
                      placement="bottomRight"
                      content={GearContent}
                      title=""
                      trigger="click"
                    >
                      <Button
                        style={{
                          display: 'none',
                          backgroundColor: 'white',
                          border: '1px solid rgb(15, 135, 123)',
                          color: 'rgb(15, 135, 123)',
                          marginBottom: '-0.5em',
                          height: '3.5em',
                          marginLeft: '-0.27em',
                          borderRadius: '0.3em',
                          transform: 'scale(0.9)',
                          width: '2em'
                        }}
                      >
                        <Icon
                          style={{

                          }}
                          type="setting"
                        />
                      </Button>
                    </Popover>
                  </div>
                </Row>
                <Row>
                  <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                    <StyledTable
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      dataSource={this.state.quotes}
                      pagination={{
                        locale: { items_per_page: " Items" },
                        showSizeChanger: true,
                        onShowSizeChange: this.onShowSizeChange,
                        pageSizeOptions: ['10', '25', '50', '100'],
                        hideOnSinglePage: true,
                        pageSize: this.state.pageSize || 25,
                        itemRender: (current, type, originalElement) => {
                          if (type === 'prev') {
                            return <a>Previous</a>;
                          }
                          if (type === 'next') {
                            return <a>Next</a>;
                          }
                          return originalElement;
                        },
                        showTotal: (total, range) =>
                          `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                        defaultCurrent: 1
                      }}
                      onChange={this.handleSort}
                      onRow={r => ({
                        onClick: () => this.showOrder(r)
                      })}
                    >
                      <Column
                        {...this.getColumnSearchProps('name')}
                        title="Quote No."
                        dataIndex="name"
                        key="name"
                      />
                      <Column
                        {...this.getColumnSearchProps('startDate')}
                        title="Date"
                        render={(text, record) => (
                          <span>
                            {record.startDate
                              ? record.startDate.slice(0, 4) +
                              '-' +
                              record.startDate.slice(4, 6) +
                              '-' +
                              record.startDate.slice(6, 8)
                              : 'N/A'}
                          </span>
                        )}
                        dataIndex="startDate"
                        key="startDate"
                      />
                      <Column
                        {...this.getColumnSearchProps('customername')}
                        title="Customer"
                        dataIndex="customername"
                        key="customername"
                      />
                      <Column
                        {...this.getColumnSearchProps('status')}
                        title="Status"
                        filtered={true}
                        filteredValue={this.state.activeFilterStatus}
                        dataIndex="status"
                        key="status"
                      />
                      <Column
                        {...this.getColumnSearchProps('total')}
                        title="Total"
                        render={(text, record) => (
                          <span>
                            {record.total
                              ? 'R ' + record.total.toFixed('2')
                              : 'R 0.00'}
                          </span>
                        )}
                        dataIndex="total"
                        key="total"
                      />
                    </StyledTable>
                  </ConfigProvider>
                </Row>
              </div>
            ) : null}
          </div>
        ) : (
            <div>
              {' '}
              <Card style={{ paddingBottom: "5em" }}>
                <Row>
                  <Col span={6}>
                    <CustomInput
                      labelText="Quote"
                      id="quotename"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.name
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={6}>
                    <CustomSelect
                      labelText="Customer"
                      id="customer"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.handleChange(e),
                        onClick: () => this.onHelpMarker('stockitemtype'),
                        value: this.state.customer
                      }}
                      menuItems={this.state.customers}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ top: '15px', width: '20em' }}>
                    {'Date '}
                    <DatePicker
                      className={this.props.classes.datePicker}
                      onChange={this.handleChangeDateStart}
                      value={this.state.pickerStart}
                      format={dateFormat}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={6} style={{ top: '15px', width: '20em' }}>

                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CustomInput
                      labelText="Reference"
                      id="reference"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.ref
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={5}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.email
                      }}
                    />
                  </Col>
                  <Col span={1}>
                    <Tooltip
                      placement="top"
                      title={
                        'Use the semicolon (;) character to separate multiple email addresses'
                      }
                    >
                      <IconButton
                        style={{ marginTop: '1.6em' }}
                      >
                        <Icon
                          type="info-circle"
                          style={{
                            fontSize: '17px',
                            color: 'grey'
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Col>
                  <Col span={6}>
                    <CustomInput
                      labelText="Notes To Customer"
                      id="description"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.note
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ top: '5px' }}>
                    <h2>{'TOTAL: R' + this.state.total.toFixed('2')}</h2>
                  </Col>
                </Row>
                <Row>
                </Row>
                <Row>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    pagination={{
                      locale: { items_per_page: " Items" },
                      showSizeChanger: true,
                      onShowSizeChange: this.onShowSizeChange,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      hideOnSinglePage: true,
                      pageSize: this.state.pageSize || 25,
                      itemRender: itemRender,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                      defaultCurrent: 1
                    }}
                    dataSource={this.state.quoteItems}
                    onChange={this.handleSort}
                    onRow={(record, rowIndex) => {
                      if (rowIndex % 2 == 0) {
                        return { style: { background: 'rgb(247, 247, 247)' } };
                      }
                    }}
                    footer={() => (
                      <div style={{ width: '100%', display: 'flex' }}>
                        <Button onClick={this.addItem} style={{ margin: 'auto' }}>Add item</Button>
                      </div>
                    )}
                  >
                    <Column
                      title="Item Code"
                      dataIndex="code"
                      key="code"
                    />

                    <Column
                      title="Item Name"
                      dataIndex="name"
                      key="name"
                      render={(text, record, index) => (
                        <div>
                          <AutoComplete
                          style={{width: "100%"}}
                            dropdownMatchSelectWidth={false}
                            dataSource={this.state.itemSearchOptions}
                            value={record.name}
                            onSelect={e => this.onSelectItem(e, index)}
                            onSearch={e => this.searchItem(e, index)}
                            placeholder="Start typing item code or name"
                          />
                          {/* <Input
                            name="name"
                            placeholder="Start typing item code or name"
                            value={record.name}
                            onPressEnter={(event) => this.pressQtyEnter(record, event)}
                            onChange={event => this.searchItem(record, event, index)}
                          /> */}
                        </div>
                      )}
                    />
                    {/* <Column
                      title="In Stock"
                      render={(text, record) => {
                        return (
                          <span>
                            {(Math.round(record.qty * 1000) / 1000) + (dimTypesFormat[record.dimType] ? dimTypesFormat[record.dimType] : ' Units')}
                          </span>
                        );
                      }}
                      dataIndex="qty"
                      key="stock"
                    /> */}

                    <Column
                      title="Qty"
                      width="7em"
                      align="center"
                      render={(text, record, index) => (
                        <div>
                          <Input
                            name="qty"
                            placeholder="Input Qty"
                            value={record.qty}
                            onChange={event => this.changeQty(record, event, index)}
                          />
                        </div>
                      )}
                      key="qty"
                    />
                    <Column
                      title="Price"
                      width="13em"
                      align="center"
                      render={(text, record, index) => (
                        <div>
                          <Input
                            name="cost"
                            placeholder="Input Cost"
                            prefix="R"
                            value={record.price}
                            onChange={event => this.changePrice(record, event, index)}
                          />
                        </div>
                      )}
                      key="cost"
                    />
                    {/*<Column
                  title="Tax %"
                  render={(text, record) => (
                    <div>
                      <Input
                        name="tax"
                        placeholder="Input Tax %"
                        value={
                          this.state.stock[this.state.customer][record.stockid]
                            .tax
                        }
                        onChange={event => this.tableAmount(record, event)}
                      />
                    </div>
                  )}
                  key="tax"
                      /> */}
                    <Column
                      title="Total"
                      width="10em"
                      render={(text, record) => {
                        return (
                          <span>
                            {'R ' + record.total.toFixed(2) || "0.00"}
                          </span>
                        );
                      }}
                      key="total"
                    />
                  </StyledTable>
                </Row>{' '}
              </Card>
              <div
                style={{
                  transition: "1s",
                  opacity: this.state.isScrolling ? 0 : 1,
                  position: "fixed",
                  right: 5,
                  bottom: 5,
                  background: "rgb(19, 36, 64)",
                  padding: 0,
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "3em",
                  // width: "calc(100% - 80px)",
                  height: "3.4em",
                  zIndex: 99
                }}
                color="primary"
              >
                <Button style={{
                  float: "right", marginTop: 8,
                  color: "white",
                  borderRadius: "3em",
                  border: "1px solid white",
                  backgroundColor: "transparent",
                  width: "6em"
                }} onClick={() => this.getQuotes()} color="primary">
                  Cancel
                </Button>
                <Button
                  style={{
                    float: "right", marginTop: 8, marginRight: 8, color: "white",
                    borderRadius: "3em",
                    border: "1px solid rgba(0, 255, 112, 0.898)",
                    backgroundColor: "transparent"
                  }}
                  onClick={() => this.addQuotes(false)}
                  color="primary"
                >
                  {this.state.status === "Awaiting Acceptance" ? "Save" : "Save As Draft"}
                </Button>
                {this.state.email !== "" ? <Button
                  style={{
                    float: "right", marginTop: 8, marginRight: 8, color: "white",
                    borderRadius: "3em",
                    border: "1px solid rgba(0, 255, 112, 0.898)",
                    backgroundColor: "transparent"
                  }}
                  onClick={() => this.addQuotes(true)}
                  color="primary"
                >
                  {this.state.status === "Awaiting Acceptance" ? "Save And Resend" : "Save And Send"}
                </Button> : null}
              </div>
            </div>
          )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  getQuotes,
  getQuoteItems,
  addQuote,
  resendQuote,
  updatePo,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  getQuoteReport,
  convertPOToDraft,
  getQuoteCustomers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Quote));
