/* eslint-disable no-loop-func */
import { Avatar, Col, Icon, Layout, List, notification, Row, Spin } from 'antd';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { FaHamburger, FaPizzaSlice } from 'react-icons/fa';
import { GiBeerStein, GiShrimp, GiSteak } from 'react-icons/gi';
import { MdFavorite } from 'react-icons/md';

import 'src/components/Layout/Dashboard/extra.css';
import 'src/views/Customer/customer-styles.css';
const apiCall = 'vpos/api/customer/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Content } = Layout;
let activeCategory = 'Pizza';
let setActiveCategory;
let locationState = {
  lat: 0.0,
  lng: 0.0
};
let changeCenter = {
  lat: 0.0,
  lng: 0.0
};

const onMarkerClick = (props, marker, e, f) => {
  setLocation(prevState => ({
    ...prevState,
    activeMarker: marker,
    showingInfoWindow: true
  }));
};

let setLocation;
let setCenter;
let infoState = {
  visible: false,
  marker: undefined
};
let setInfo;

let companies = [];
let setCompanies;
const selectCategory = (name, props) => {
  setActiveCategory(name);
  setCompanies([]);
  setImagesLoaded({});
  if (name === 'Favourites') {
    let partFavourites = [];
    let images = {};
    for (var prop in props.user.favourites) {
      images[props.user.favourites[prop]._id] = 0;
      partFavourites.push(props.user.favourites[prop]);
    }
    setTimeout(() => {
      setImagesLoaded(images);
      setCompanies(partFavourites);
    }, 100);
  } else {
    $.ajax({
      type: 'GET',
      url:
        server +
        apiCall +
        'getcompanylist?lat=' +
        props.selectedDelivery.lat +
        '&lng=' +
        props.selectedDelivery.lng +
        '&category=' +
        name +
        '&type=' +
        props.selectedDelivery.address,
      dataType: 'json',
      crossDomain: true,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
      },
      success: async data => {
        let images = {};
        data.results.forEach(company => {
          images[company._id] = 0;
        });
        setImagesLoaded(images);
        setCompanies(data.results);
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          notification.error({
            message: 'Error',
            description: xhr.responseText
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Error retrieving list'
          });
        }
      }
    });
  }
};
let imagesLoaded = {};
let setImagesLoaded;
const loadedImage = id => {
  let newSet = {};
  newSet[id] = 1;
  setImagesLoaded(prevState => ({
    ...prevState,
    ...newSet
  }));
};
const search = (value, props) => {
  setActiveCategory(undefined);
  setCompanies([]);
  setImagesLoaded({});
  $.ajax({
    type: 'GET',
    url:
      server +
      apiCall +
      'getcompanylist?lat=' +
      props.selectedDelivery.lat +
      '&lng=' +
      props.selectedDelivery.lng +
      '&search=' +
      value +
      '&type=' +
      props.selectedDelivery.address,
    dataType: 'json',
    crossDomain: true,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
    },
    success: async data => {
      let images = {};
      data.results.forEach(company => {
        images[company._id] = 0;
      });
      setImagesLoaded(images);
      setCompanies(data.results);
    },
    error: async (xhr, ajaxOptions, thrownError) => {
      if (xhr.responseText) {
        notification.error({
          message: 'Error',
          description: xhr.responseText
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error retrieving list'
        });
      }
    }
  });
};
const handleFavourite = (e, company, props) => {
  e.stopPropagation();
  let user = props.user;
  if (user.favourites && user.favourites[company.orgid]) {
    delete user.favourites[company.orgid];
  } else {
    if (user.favourites) {
      user.favourites[company.orgid] = company;
    } else {
      user.favourites = {};
      user.favourites[company.orgid] = company;
    }
  }
  props.handleUpdateCustomer(user, 'Your favourites have been updated');
  if (activeCategory === 'Favourites') {
    if (Object.keys(props.user.favourites).length === 0) {
      selectCategory('Pizza', props);
    } else {
      setCompanies([]);
      setImagesLoaded({});
      let partFavourites = [];
      let images = {};
      for (var prop in user.favourites) {
        images[user.favourites[prop]._id] = 0;
        partFavourites.push(user.favourites[prop]);
      }
      setTimeout(() => {
        setImagesLoaded(images);
        setCompanies(partFavourites);
      }, 100);
    }
  }
};
const CompanyList = props => {
  [activeCategory, setActiveCategory] = useState(activeCategory);
  [companies, setCompanies] = useState(companies);
  [imagesLoaded, setImagesLoaded] = useState(imagesLoaded);
  // search(props.searchValue, props)
  if (props.searchValue.value !== undefined) {
    let value = props.searchValue.value;
    props.setSearchValue({
      search: true,
      value: undefined
    });
    setTimeout(() => {
      search(value, props);
    }, 100);
  }
  useEffect(() => {
    const name = 'Pizza';
    setActiveCategory(name);
    setCompanies([]);
    setImagesLoaded({});
    $.ajax({
      type: 'GET',
      url:
        server +
        apiCall +
        'getcompanylist?lat=' +
        props.selectedDelivery.lat +
        '&lng=' +
        props.selectedDelivery.lng +
        '&category=' +
        name +
        '&type=' +
        props.selectedDelivery.address,
      dataType: 'json',
      crossDomain: true,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
      },
      success: async data => {
        let images = {};
        data.results.forEach(company => {
          images[company._id] = 0;
        });
        setImagesLoaded(images);
        setCompanies(data.results);
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          notification.error({
            message: 'Error',
            description: xhr.responseText
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Error retrieving list'
          });
        }
      }
    });
    return () => {};
  }, []);
  const categories = [
    {
      name: 'Favourites',
      icon: (
        <MdFavorite
          style={{
            color:
              activeCategory === 'Favourites'
                ? 'rgb(0, 204, 112)'
                : 'lightgrey',
            marginLeft: '0.3em',
            marginTop: '0.3em',
            fontSize: 25
          }}
        />
      ),
      color: '#00CC70'
    },
    // {
    //   name: 'Water',
    //   icon: (
    //     <IoIosWater
    //       style={{
    //         color: activeCategory === 'Water' ? 'rgb(0, 204, 112)' : 'lightgrey',
    //         marginLeft: '0.8em',
    //         marginTop: '0.25em',
    //         fontSize: 25
    //       }}
    //     />
    //   ),
    //   color: '#00CC70'
    // },
    {
      name: 'Pizza',
      icon: (
        <FaPizzaSlice
          style={{
            color:
              activeCategory === 'Pizza' ? 'rgb(0, 204, 112)' : 'lightgrey',
            marginLeft: '0.8em',
            marginTop: '0.25em',
            fontSize: 25
          }}
        />
      ),
      color: '#00CC70'
    },
    {
      name: 'Burgers',
      icon: (
        <FaHamburger
          style={{
            color:
              activeCategory === 'Burgers' ? 'rgb(0, 204, 112)' : 'lightgrey',
            marginLeft: '0.8em',
            marginTop: '0.25em',
            fontSize: 25
          }}
        />
      ),
      color: '#00CC70'
    },
    {
      name: 'Steaks',
      icon: (
        <GiSteak
          style={{
            color:
              activeCategory === 'Steaks' ? 'rgb(0, 204, 112)' : 'lightgrey',
            marginLeft: '0.8em',
            marginTop: '0.25em',
            fontSize: 25
          }}
        />
      ),
      color: '#00CC70'
    },
    {
      name: 'Liquor',
      icon: (
        <GiBeerStein
          style={{
            color:
              activeCategory === 'Liquor' ? 'rgb(0, 204, 112)' : 'lightgrey',
            marginLeft: '0.8em',
            marginTop: '0.25em',
            fontSize: 25
          }}
        />
      ),
      color: '#00CC70'
    },
    {
      name: 'Seafood',
      icon: (
        <GiShrimp
          style={{
            color:
              activeCategory === 'Seafood' ? 'rgb(0, 204, 112)' : 'lightgrey',
            marginLeft: '0.8em',
            marginTop: '0.25em',
            fontSize: 25
          }}
        />
      ),
      color: '#00CC70'
    }
  ];
  return (
    <Content
      style={{
        marginTop: 50,
        height: 'calc(100vh-80px)',
        background: 'white',
        paddingBottom: 70
      }}
    >
      {!props.searchValue.search ? (
        <Row
          className="category-items"
          style={{
            overflow: 'scroll',
            maxWidth: '55em',
            margin: 'auto',
            backgroundColor: 'white',
            zIndex: 7,
            position: 'sticky',
            top: '3.6em'
          }}
        >
          <Col
            span={24}
            style={{
              marginTop: 10,
              display: 'flex',
              textAlign: 'center'
            }}
          >
            {categories.map(category => {
              if (
                category.name === 'Favourites' &&
                props.user.favourites &&
                Object.keys(props.user.favourites).length > 0
              ) {
                return (
                  <div
                    key={category.name}
                    style={{ padding: 5, margin: 'auto' }}
                  >
                    <div
                      onClick={e => selectCategory(category.name, props)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        style={{
                          background: 'white',
                          width: 130,
                          height: 45,
                          borderRadius: '3em',
                          display: 'flex',

                          border:
                            activeCategory === category.name
                              ? '2.5px solid rgb(0, 204, 112)'
                              : '2.5px solid ' + 'white'
                        }}
                      >
                        {category.icon}{' '}
                        <span
                          style={{
                            fontSize: 15,
                            margin: 'auto',
                            marginLeft: '0.4em',
                            color:
                              activeCategory === category.name
                                ? 'rgb(0, 204, 112)'
                                : '',

                            fontWeight: '600',
                            fontFamily: 'Roboto'
                          }}
                        >
                          {category.name}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              } else if (category.name !== 'Favourites') {
                return (
                  <div
                    key={category.name}
                    style={{ padding: 5, margin: 'auto' }}
                  >
                    <div
                      onClick={e => selectCategory(category.name, props)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        style={{
                          background: 'white',
                          width: 130,
                          height: 45,
                          borderRadius: '3em',
                          display: 'flex',

                          border:
                            activeCategory === category.name
                              ? '2.5px solid rgb(0, 204, 112)'
                              : '2.5px solid ' + 'white'
                        }}
                      >
                        {category.icon}{' '}
                        <span
                          style={{
                            fontSize: 15,
                            margin: 'auto',
                            marginLeft: '0.6em',
                            color:
                              activeCategory === category.name
                                ? 'rgb(0, 204, 112)'
                                : '',

                            fontWeight: '600',
                            fontFamily: 'Roboto'
                          }}
                        >
                          {category.name}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </Col>
        </Row>
      ) : null}

      <Row
        span={24}
        style={{
          background: 'white',
          margin: 'auto',
          maxWidth: '80em'
        }}
      >
        <List
          dataSource={companies}
          renderItem={company => (
            <div onClick={e => props.selectCompany(company)}>
              <Col
                style={{
                  padding: 15
                }}
                sm={24}
                md={8}
              >
                <div
                  style={{
                    // padding: 30,
                    position: 'relative',
                    width: '100%',
                    borderRadius: '12px 12px 0px 0px',
                    marginBottom: '-1em',
                    height: '145px',
                    overflow: 'hidden',
                    fontFamily: 'Roboto',
                    display: 'flex',
                    background: '#f3f3f3'
                    // backgroundPosition: "40% center",
                    // backgroundSize: "cover",
                    // backgroundImage: (
                    //   <ReactImageAppear
                    //     src={
                    //       process.env.REACT_APP_GLOBAL_SERVER +
                    //       company.mainImage
                    //     }
                    //   />
                    // )
                    // 'url(' +
                    // process.env.REACT_APP_GLOBAL_SERVER +
                    // company.mainImage +
                    // ')'
                  }}
                >
                  <Spin
                    style={{
                      width: '100%',
                      height: 145,
                      transform: 'translateY(50px)'
                    }}
                    spinning={imagesLoaded[company._id] === 0 ? true : false}
                  />
                  <img
                    style={{
                      borderRadius: '12px 12px 0px 0px',
                      transition: 'all 0.5s',
                      opacity: imagesLoaded[company._id]
                    }}
                    className={'image-appear'}
                    onLoad={e => loadedImage(company._id)}
                    src={
                      process.env.REACT_APP_GLOBAL_SERVER + company.mainImage
                    }
                  />
                </div>
                <div
                  style={{
                    position: 'relative',
                    backgroundColor: 'white',
                    lineHeight: '10px',
                    fontSize: '0.8em',

                    paddingTop: 14,
                    paddingBottom: 2,
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 12,
                    boxShadow: '0px 5px 20px -10px'
                  }}
                >
                  <div
                    style={{
                      marginLeft: '-1.5em'
                    }}
                  >
                    <Avatar
                      style={{
                        width: '3em',
                        height: '3em',
                        marginBottom: '-2em',
                        marginRight: '0.5em',
                        borderRadius: '100px'
                      }}
                      src={process.env.REACT_APP_GLOBAL_SERVER + company.logo}
                      alt="logo"
                    />

                    <h1
                      style={{
                        display: 'block',
                        lineHeight: '18px',
                        paddingBottom: '0.7em',
                        marginTop: '-1em',
                        width: '70%',
                        marginLeft: '3.35em'
                      }}
                    >
                      {company.name}
                    </h1>
                  </div>
                  <p
                    style={{
                      marginLeft: '3.65em',
                      lineHeight: 1.2,
                      width: '58vw',
                      color: '#131313',
                      marginTop: '-1em'
                    }}
                  >
                    {company.categories.map((category, index) => {
                      return (
                        <span key={index}>
                          {index + 1 !== company.categories.length
                            ? category + ','
                            : category}{' '}
                        </span>
                      );
                    })}
                  </p>
                  <p
                    style={{
                      marginLeft: '3.65em',
                      color: '#A0A0A0',
                      paddingTop: '0.3em'
                    }}
                  >
                    {(company.distance / 1000).toFixed(1)}km away
                  </p>
                  <Icon
                    onClick={e => handleFavourite(e, company, props)}
                    style={{
                      fontSize: '1.2em',
                      marginTop: '-1.8em',
                      marginRight: '-0.7em',
                      color: '#FF3541',
                      float: 'right'
                    }}
                    theme={
                      props.user.favourites &&
                      props.user.favourites[company.orgid]
                        ? 'filled'
                        : ''
                    }
                    type="heart"
                  />
                  <p
                    style={{
                      position: 'absolute',
                      top: '1em',
                      right: '1em',
                      fontWeight: 500,
                      color: '#131313',
                      textAlign: 'center',
                      fontSize: '13px',

                      paddingTop: '0em'
                    }}
                  >
                    {company.radius === 0 ? 'Collect' : 'Delivery'}
                    {company.radius > 0 ? (
                      <span
                        style={{
                          padding: '0.5em',
                          marginTop: '0.3em',
                          borderRadius: '1em',
                          fontWeight: 500,
                          color: 'white',
                          backgroundColor: '#00CC70',
                          textAlign: 'center',
                          fontSize: '12px',
                          display: 'block'
                        }}
                      >
                        {company.average ? company.average + 'min' : '30min'}
                      </span>
                    ) : null}
                  </p>
                </div>
              </Col>{' '}
            </div>
          )}
        />
      </Row>
    </Content>
  );
};

export default CompanyList;
