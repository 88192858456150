import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Icon,
  Input,
  Row,
  Select,
  Spin,
  Table
} from 'antd';
import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
const apiCall = 'vpos/api/vpos/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { Column } = Table;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  tableScroll: {
    width: '100%',
    height: '55vh',
    overflow: 'auto'
  }
});

const dimTypesFormat = {
  l: 'L',
  ml: 'ml',
  kg: 'Kg',
  g: 'g',
  m: 'm',
  cm: 'cm',
  mm: 'mm'
};

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}
class PurchaseOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      _id: '',
      name: '',
      email: '',
      supplier: '',
      supname: '',
      ref: '',
      note: '',
      total: 0,
      orders: [],
      suppliers: [],
      supplierStock: [],
      types: [],
      stockitems: {},
      stock: {},
      supStock: {},
      startDate: moment().format(dateFormatSearch),
      expDate: moment().format(dateFormatSearch),
      pickerStart: moment(),
      pickerExp: moment(),
      loading: true,
      main: true,
      switch: false,
      userlisthead: 'Add Purchase Order',
      userlistsub: 'Add a New Purchase Order',
      userlistbutton: 'ADD PURCHASE ORDER',
      alert: false,
      msg: [],
      head: '',
      activeFilter: true,
      activeFilterStatus: [''],
      activeFilterQty: false,
      report: {
        totalAmount: '0',
        totalCount: '0',
        pendingAmount: '0',
        pendingCount: '0',
        completeAmount: '0',
        completeCount: '0'
      }
    };
  }
  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value
    });
  };
  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.props.splash();
    this.getPurchaseOrder();
  }

  getPurchaseOrder = msg => {
    let searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    const id = searchParams.get('id');
    $.ajax({
      type: 'GET',
      url: server + apiCall + 'purchaseorder/getverifypurchaseorder?id=' + id,
      contentType: 'application/json',
      crossDomain: true,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: async result => {
        let data = result.results;
        if (data.error) {
          this.setState({ loading: false, doneView: true });
          this.onError(data.message);
        } else {
          for (var i = 0; i < data.stockitems.length; i++) {
            data.stockitems[i].canSupply = data.stockitems[i].amount;
          }
          this.setState({
            stockadjustview: data.stockitems,
            _id: data._id,
            name: data.name,
            email: data.email,
            supname: data.supname,
            ref: data.ref,
            note: data.note,
            total: data.total,
            status: data.status,
            startDate: data.startDate,
            expDate: data.expDate,
            loading: false
          });
        }
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          this.onError(xhr.responseText);
        } else if (!xhr.responseText) {
          this.onError('No Connection Found!');
        }
      }
    });
  };

  verifyAndSend = () => {
    let searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    this.setState({ loading: true });
    let stockitems = this.state.stockadjustview;
    for (var i = 0; i < stockitems.length; i++) {
      stockitems[i].amount = parseInt(stockitems[i].amount);
      stockitems[i].cost = parseFloat(stockitems[i].cost);
      stockitems[i].total = parseFloat(stockitems[i].total.toFixed(2));
    }
    const formDetails = {
      _id: this.state._id,
      stockitems: stockitems,
      status: 'Awaiting Payment',
      total: this.state.total
    };

    $.ajax({
      type: 'POST',
      url: server + apiCall + 'purchaseorder/verifypurchaseorder',
      data: JSON.stringify(formDetails),
      contentType: 'application/json',
      dataType: 'json',
      crossDomain: true,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: async data => {
        this.setState({ loading: false, doneView: true });
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          this.onError(xhr.responseText);
        } else if (!xhr.responseText) {
          this.onError('No Connection Found!');
        }
      }
    });
  };

  handleChangeDateStart = value => {
    if (value) {
      this.setState({
        startDate: value.format(dateFormatSearch),
        pickerStart: value
      });
    }
  };

  handleChangeDateExp = value => {
    if (value) {
      this.setState({
        expDate: value.format(dateFormatSearch),
        pickerExp: value
      });
    }
  };

  handleCheck = () => {
    this.setState({ cooking: !this.state.cooking });
  };

  handleChange = event => {
    if (event.target.name === 'supplier') {
      var sup = {};
      sup.total = 0;
      sup.supplier = event.target.value;
      sup.supname = event.currentTarget.textContent;
      sup.email = '';
      sup.supplierStock = [];
      if (this.state.supStock[event.target.value]) {
        sup.email = this.state.supStock[event.target.value].email || '';
        sup.supplierStock = this.state.supStock[event.target.value].stock || [];
      }
      this.setState(sup);
    }
  };

  tableAmountCheck = (record, e) => {
    var stock = this.state.stock;
    var supplier = this.state.supplier;
    stock[this.state.supplier][record.stockid].select = e.target.checked;
    var ttotal = 0;
    Object.keys(stock[supplier]).forEach(function(key) {
      if (stock[supplier][key].select) {
        if (stock[supplier][key].total) {
          ttotal = ttotal + stock[supplier][key].total;
        }
      }
    });
    this.setState({ supStock: stock, total: ttotal });
  };

  tableAmountPo = (record, e) => {
    var stock = this.state.stockadjustview;
    let { value } = e.target;
    let total = this.state.total;
    if (value) {
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        total = 0;
        for (var i = 0; i < stock.length; i++) {
          if (stock[i].stockid === record.stockid) {
            if (parseFloat(value) > stock[i].amount) {
            } else {
              let realvalue = value;
              if (value[0] === '0') {
                realvalue = value[1];
              }
              stock[i].canSupply = parseFloat(realvalue);
              stock[i].total =
                parseFloat(record.costChange || stock[i].cost) *
                stock[i].canSupply;
            }
          }
          total = total + stock[i].total;
        }
        total = parseFloat(total.toFixed(2));
        this.setState({ stockadjustview: stock, total: total });
      }
    } else {
      total = 0;
      for (var i = 0; i < stock.length; i++) {
        if (stock[i].stockid === record.stockid) {
          stock[i].canSupply = 0;
          stock[i].total = parseFloat(stock[i].cost) * stock[i].canSupply;
        }
        total = total + stock[i].total;
      }
      total = parseFloat(total.toFixed(2));
      this.setState({ stockadjustview: stock, total: total });
    }
  };

  changeCostAmount = (record, e) => {
    var stock = this.state.stockadjustview;
    let { value } = e.target;
    let total = this.state.total;
    if (value) {
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if (
        (!isNaN(value) && reg.test(value)) ||
        value === '' ||
        value === '-' ||
        value.search('.') > -1
      ) {
        total = 0;
        for (var i = 0; i < stock.length; i++) {
          if (stock[i].stockid === record.stockid) {
            let realvalue = value;
            if (value[0] === '0') {
              realvalue = value[1];
            }
            if (realvalue.search('.') > -1) {
              stock[i].cost = realvalue;
              stock[i].total = parseFloat(realvalue) * stock[i].canSupply;
            } else {
              stock[i].cost = parseFloat(realvalue);
              stock[i].total = stock[i].cost * stock[i].canSupply;
            }
            stock[i].costChange = false;
          }
          total = total + stock[i].total;
        }
        total = parseFloat(total.toFixed(2));
        this.setState({ stockadjustview: stock, total: total });
      }
    } else {
      total = 0;
      for (var i = 0; i < stock.length; i++) {
        if (stock[i].stockid === record.stockid) {
          stock[i].cost = 0;
          stock[i].total = stock[i].cost * stock[i].canSupply;
        }
        total = total + stock[i].total;
      }
      total = parseFloat(total.toFixed(2));
      this.setState({ stockadjustview: stock, total: total });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Purchase Order Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnFilterProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <Select
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Filter ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e ? [e] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {this.state.types.map((items, key) => {
              return (
                <Option key={key} value={items.value || items._id}>
                  {items.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="sm"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="sm"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (!visible) {
        setTimeout(() => this.searchInput.rcSelect.setOpenState(false));
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onChangeEvt: this.onChangeEvt,
      onHelpMarker: this.onHelpMarker
    };
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.doneView ? (
          <div style={{ display: 'flex', height: '100vh' }}>
            <h1 style={{ margin: 'auto' }}>
              All done here! You can close this page
            </h1>
          </div>
        ) : (
          <div>
            {' '}
            <Card>
              <Row>
                <Col span={6}>
                  <CustomInput
                    labelText="Purchase Order"
                    id="purchaseordername"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.name
                    }}
                  />
                </Col>
                <Col span={1} />
              </Row>
              <Row>
                <Col span={6} style={{ top: '15px', width: '20em' }}>
                  {'Issued on '}
                  <DatePicker
                    disabled={true}
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDateStart}
                    value={this.state.pickerStart}
                    format={dateFormat}
                  />
                </Col>
                <Col span={1} />
                <Col span={6} style={{ top: '15px', width: '20em' }}>
                  {'Expected on '}
                  <DatePicker
                    disabled={true}
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDateExp}
                    value={this.state.pickerExp}
                    format={dateFormat}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <CustomInput
                    labelText="Reference"
                    id="reference"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      onChange: e => this.onChangeEvt(e),
                      onClick: () => this.onHelpMarker('stockitemdesc'),
                      value: this.state.ref
                    }}
                  />
                </Col>
                <Col span={1} />
                <Col span={6}>
                  <CustomInput
                    labelText="Notes"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      onChange: e => this.onChangeEvt(e),
                      onClick: () => this.onHelpMarker('stockitemdesc'),
                      value: this.state.note ? this.state.note : 'None'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ top: '5px' }}>
                  <h2>{'TOTAL: R' + this.state.total.toFixed('2')}</h2>
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={this.state.stockadjustview}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getColumnSearchProps('amount')}
                    title="Ordered"
                    render={(text, record) => {
                      return <span>{record.amount ? record.amount : '0'}</span>;
                    }}
                    dataIndex="amount"
                    key="amount"
                  />
                  <Column
                    {...this.getColumnSearchProps('dimTypeOrder')}
                    title="Dim"
                    render={(text, record) => {
                      return (
                        <span>
                          {record.dimTypeOrder
                            ? record.dimTypeOrder !== 'packsize'
                              ? record.sizeObject &&
                                record.sizeObject[record.dimTypeOrder]
                                ? record.dimTypeOrder
                                : dimTypesFormat[record.dimTypeOrder]
                                ? dimTypesFormat[record.dimTypeOrder]
                                : record.dimTypeOrder || ' Units'
                              : record.packSize +
                                (dimTypesFormat[record.dimType]
                                  ? dimTypesFormat[record.dimType]
                                  : record.dimType || ' Units')
                            : 'Unit'}
                        </span>
                      );
                    }}
                    dataIndex="dimTypeOrder"
                    key="dimTypeOrder"
                  />
                  <Column
                    title="Requested"
                    render={(text, record) => {
                      return (
                        <span>
                          {record.torec
                            ? record.torec
                            : record.amount && record.received === 0
                            ? parseFloat(record.amount)
                            : 0}
                        </span>
                      );
                    }}
                    dataIndex="toreceive"
                    key="Receive"
                  />
                  <Column
                    title="Can Supply"
                    render={(text, record) => (
                      <div>
                        <Input
                          disabled={
                            this.state.status === 'Complete' ? true : false
                          }
                          name="canSupply"
                          placeholder="Can supply"
                          value={record.canSupply}
                          onChange={event => this.tableAmountPo(record, event)}
                        />
                      </div>
                    )}
                    key="received"
                  />

                  <Column
                    title="Cost per item"
                    render={(text, record) => (
                      <div>
                        <Input
                          prefix="R "
                          name="received"
                          placeholder="Cost per item"
                          value={
                            Math.round(
                              (record.costChange > 0
                                ? record.costChange
                                : record.cost) * 100
                            ) / 100
                          }
                          onChange={event =>
                            this.changeCostAmount(record, event)
                          }
                        />
                      </div>
                    )}
                  />
                  <Column
                    title="Total"
                    render={(text, record) => {
                      return (
                        <span>
                          {record.total
                            ? 'R ' + record.total.toFixed(2)
                            : 'R 0.00'}
                        </span>
                      );
                    }}
                  />
                </StyledTable>
              </Row>{' '}
            </Card>
            <div
              style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                background: 'rgb(19, 36, 64)',
                padding: 0,
                width: '100%',
                height: '3.5em',
                zIndex: 99
              }}
              color="primary"
            >
              <Button
                style={{ float: 'right', marginTop: 8, marginRight: 8 }}
                onClick={() => this.verifyAndSend()}
                color="primary"
              >
                Verify And Request Payment
              </Button>
            </div>
          </div>
        )}

        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  snackbar: state.snackbar
});

const mapDispatchToProps = {
  openSnackbar,
  closeSnackbar,
  setHelpMarker
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PurchaseOrder));
