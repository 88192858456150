import { Col, Icon, Input, Row, Spin } from 'antd';
import { saveAs } from 'file-saver';
import $ from 'jquery';
import React, { createRef, memo, useEffect, useState } from 'react';
import NumPad from 'react-numpad';
import { connect } from 'react-redux';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import FormDialog from 'src/components/Dialogs/SessionDialog.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import {
  clearDashCache,
  getOrgUsers,
  loginUser,
  renewPass,
  resetOrg,
  resetPass,
  resetUser,
  sessionStart,
  sessionStartFloat,
  updateToken
} from 'src/redux/actions/backend';
import {
  clearPayments,
  clearSyncBills,
  compareBills,
  getBillDashPreload,
  getMenuDashPreload,
  setAdminLogin,
  setLoggedInFalse,
  setPreBills,
  syncData
} from 'src/redux/actions/dashboard';
import { initLoader, removeLoader } from 'src/redux/actions/initdashloader';
import Logo from 'src/static/vpos-logo-white-1.png';
import Kitchen from 'src/views/Kitchen/Kitchen';
import { UserButtons, UserDisplayClosed } from 'src/views/LoginUser/UserBlocks';
import { PhoneUserLogin, UserLogin } from 'src/views/LoginUser/UserLogin';
const dashboardPromise = import('src/views/Dashboard/Dashboard');
const Dashboard = React.lazy(() => dashboardPromise);

const minusButtonStyle = {
  color: 'red',
  background: 'transparent',
  margin: 'auto',
  border: '1px solid red',
  height: 40,
  width: '40px',
  borderRadius: '3em',
  padding: '0em',
  lineHeight: 0
};

const setUserLogin = React.createContext();
const setButtons = React.createContext();
//let setUserLogin = undefined;
//let setButtons = undefined;
let setDash = undefined;
let setAlert = undefined;
let setFloat = undefined;
let setUserState = undefined;
let setLocalUserState = undefined;
let localUserState = '';
var props = {};

var notes = [10, 20, 50, 100, 200];

const UserFloat = memo(({ ...oState }) => {
  var coins = [
    {
      prefix: '',
      suffix: 'c',
      value: '10'
    },
    {
      prefix: '',
      suffix: 'c',
      value: '20'
    },
    {
      prefix: '',
      suffix: 'c',
      value: '50'
    },
    {
      prefix: 'R',
      suffix: '',
      value: 1
    },
    {
      prefix: 'R',
      suffix: '',
      value: 2
    },
    {
      prefix: 'R',
      suffix: '',
      value: 5
    }
  ];

  const spanStyle = {
    margin: 'auto',
    fontSize: 20,
    fontWeight: 700,
    color: '#00CC70'
  };
  const col1Style = {
    boxShadow: 'none',
    borderRadius: 3,
    height: 40,

    display: 'flex'
  };
  const buttonStyle = {
    color: '#00CC70',
    background: 'transparent',
    margin: 'auto',
    border: '1px solid #00CC70',
    height: 40,
    width: '40px',
    borderRadius: '3em',
    padding: '0em',
    lineHeight: 0
  };

  const inputStyle = {
    height: 40,
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: '1px solid white',
    color: 'white'
  };
  const floatButton = {
    transition: '0.3s',
    backgroundColor: '#00CC70',
    textAlign: 'center',
    width: '80%',
    color: 'white !important',
    borderRadius: 50,
    height: 59,
    fontSize: 20
  };
  const numpadTheme = {
    header: {
      primaryColor: '#263238',
      secondaryColor: '#f9f9f9',
      highlightColor: '#FFC107',
      backgroundColor: '#607D8B'
    },
    body: {},
    panel: {},
    global: {}
  };
  return (
    <div
      style={{
        backgroundColor: '#11141A',
        height: '100vh',
        overflow: 'scroll'
      }}
    >
      {/*<div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>*/}
      <div
        style={{
          top: '1em',
          position: 'absolute',
          right: '5%',
          float: 'right',
          fontSize: '20px',
          fontWeight: '700',
          color: 'red'
        }}
        onClick={oState.back}
      >
        <Icon type="left-circle" /> Cancel
      </div>
      <span
        className="float-screen"
        style={{
          position: 'absolute',
          fontSize: '32px',
          fontWeight: '700',
          color: 'white',
          paddingLeft: '1.5em',
          top: '0.5em'
        }}
      >
        Cash Float
      </span>{' '}
      <span
        className="float-screen"
        style={{
          position: 'absolute',
          fontSize: '17px',
          fontWeight: '400',
          paddingLeft: '2em',
          left: '1em',
          top: '3.2em',
          color: 'white'
        }}
      >
        Enter your cash amount received
      </span>
      <Row style={{ padding: 40 }} type="flex" justify="space-between">
        <Col span={24}>
          <Row type="flex" justify="space-between">
            <Col span={9}>
              <Card
                style={{
                  backgroundColor: 'transparent',
                  width: '45vw',
                  paddingRight: '15px',
                  marginTop: '3em',
                  boxShadow: 'none'
                }}
              >
                <CardHeader
                  className="float-screen"
                  style={{
                    height: '1em',
                    fontSize: '22px',
                    fontWeight: '700',
                    color: 'white',
                    marginBottom: '20px',
                    paddingBottom: '2em',
                    borderBottom: '1px solid lightgrey'
                  }}
                >
                  Notes
                </CardHeader>
                <CardBody className="float-buttons">
                  {notes.map((note, index) => (
                    <Row
                      style={{ padding: 8 }}
                      type="flex"
                      justify="space-between"
                    >
                      <Col span={6} style={col1Style}>
                        <span style={spanStyle}>R{note}</span>
                      </Col>
                      <Col span={6}>
                        <NumPad.Number
                          theme={numpadTheme}
                          position="startBottomRight"
                          confirm={e => {}}
                          update={e => {}}
                          onChange={e =>
                            oState.floatInputChangeNoteInputsNumpad(
                              e,
                              index,
                              note
                            )
                          }
                          value={
                            oState.state.floatInputsNoteValues[index].amount
                          }
                          inline={false}
                          decimal={false}
                          negative={false}
                        >
                          <Input
                            style={inputStyle}
                            value={
                              oState.state.floatInputsNoteValues[index].amount
                            }
                          />
                        </NumPad.Number>
                      </Col>
                      <Col span={2}>
                        <Button
                          className="float-button-subtract"
                          style={minusButtonStyle}
                          onClick={e =>
                            oState.floatInputChangeNoteInputs(
                              'subtract',
                              index,
                              note
                            )
                          }
                        >
                          <span style={{ fontSize: 30 }}>-</span>
                        </Button>
                      </Col>
                      <Col span={2}>
                        <Button
                          className="float-button-add"
                          style={buttonStyle}
                          onClick={e =>
                            oState.floatInputChangeNoteInputs(
                              'add',
                              index,
                              note
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>+</span>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <Col span={12}>
              <Card
                style={{
                  backgroundColor: 'transparent',
                  marginTop: '3em',
                  width: '45vw',
                  paddingRight: '15px',
                  boxShadow: 'none'
                }}
              >
                <CardHeader
                  className="float-screen"
                  style={{
                    height: '1em',
                    fontSize: '22px',
                    fontWeight: '700',
                    color: 'white',
                    marginBottom: '20px',
                    paddingBottom: '2em',
                    borderBottom: '1px solid lightgrey'
                  }}
                >
                  Coins
                </CardHeader>
                <CardBody className="float-buttons">
                  {coins.map((coin, index) => (
                    <Row
                      style={{ padding: 8 }}
                      type="flex"
                      justify="space-between"
                    >
                      <Col span={6} style={col1Style}>
                        <span style={spanStyle}>
                          {coin.prefix + coin.value + coin.suffix}
                        </span>
                      </Col>
                      <Col span={6}>
                        <NumPad.Number
                          theme={numpadTheme}
                          position="startBottomRight"
                          confirm={e => {}}
                          update={e => {}}
                          onChange={e =>
                            oState.floatInputChangeCoinInputsNumpad(
                              e,
                              index,
                              coin
                            )
                          }
                          value={
                            oState.state.floatInputsCoinValues[index].amount
                          }
                          inline={false}
                          decimal={false}
                          negative={false}
                        >
                          <Input
                            style={inputStyle}
                            value={
                              oState.state.floatInputsCoinValues[index].amount
                            }
                          />
                        </NumPad.Number>
                      </Col>
                      <Col span={2}>
                        <Button
                          className="float-button-subtract"
                          style={minusButtonStyle}
                          onClick={e =>
                            oState.floatInputChangeCoinInputs(
                              'subtract',
                              index,
                              coin.value
                            )
                          }
                        >
                          <span style={{ fontSize: 30 }}>-</span>
                        </Button>
                      </Col>
                      <Col span={2}>
                        <Button
                          className="float-button-add"
                          style={buttonStyle}
                          onClick={e =>
                            oState.floatInputChangeCoinInputs(
                              'add',
                              index,
                              coin.value
                            )
                          }
                        >
                          <span style={{ fontSize: 30 }}>+</span>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" className="float-screen-button">
        <Col span={24}>
          <Card
            style={{
              background: 'transparent',
              height: '15em',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '-8em',
              maxWidth: '30em',
              boxShadow: 'none'
            }}
          >
            <CardBody style={{ display: 'flex' }}>
              <div
                style={{ margin: 'auto', textAlign: 'center', width: '100%' }}
              >
                <span
                  style={{ fontSize: 50, fontWeight: '700', color: 'white' }}
                >
                  R{oState.state.float.toFixed(2)}
                </span>
                <Button
                  style={floatButton}
                  onClick={oState.loginSessionAndFloat}
                >
                  {'Assign Float'}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
});

const PhoneUserFloat = memo(({ ...oState }) => {
  var coins = [
    {
      prefix: '',
      suffix: 'c',
      value: '10'
    },
    {
      prefix: '',
      suffix: 'c',
      value: '20'
    },
    {
      prefix: '',
      suffix: 'c',
      value: '50'
    },
    {
      prefix: 'R',
      suffix: '',
      value: 1
    },
    {
      prefix: 'R',
      suffix: '',
      value: 2
    },
    {
      prefix: 'R',
      suffix: '',
      value: 5
    }
  ];

  const spanStyle = {
    margin: 'auto',
    fontSize: 18,
    fontWeight: 700,
    color: '#00CC70'
  };
  const col1Style = {
    boxShadow: 'none',
    borderRadius: 3,
    height: 40,
    display: 'flex'
  };
  const buttonStyle = {
    color: '#00CC70',
    background: 'transparent',
    margin: 'auto',
    border: '1px solid #00CC70',
    height: 40,
    width: '40px',
    borderRadius: '3em',
    padding: '0em',
    lineHeight: 0
  };
  const minusButtonStyle = {
    maxWidth: '100%',
    color: 'red',
    background: 'transparent',
    margin: 'auto',
    border: '1px solid red',
    height: 40,
    width: '40px',
    borderRadius: '3em',
    padding: '0em',
    lineHeight: 0
  };
  const inputStyle = {
    height: 40,
    width: 'calc(100% - 20px)',
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: '1px solid white',
    color: 'white'
  };
  const floatButton = {
    transition: '0.3s',
    backgroundColor: '#00CC70',
    textAlign: 'center',
    width: '80%',
    color: 'white !important',
    borderRadius: 50,
    height: 59,
    fontSize: 20
  };
  const numpadTheme = {
    header: {
      primaryColor: '#00CC70',
      secondaryColor: '#f9f9f9',
      highlightColor: '#FFC107',
      backgroundColor: '#607D8B'
    },
    body: {},
    panel: {},
    global: {}
  };
  return (
    <div
      style={{
        backgroundColor: '#11141A',
        height: '100vh',
        overflow: 'scroll'
      }}
    >
      <div
        style={{
          paddingTop: '0.2em',
          paddingRight: '0.5em',
          //position: 'absolute',
          //right: '5%',
          float: 'right',
          fontSize: '20px',
          fontWeight: '700',
          color: 'red'
        }}
        onClick={oState.back}
      >
        <Icon type="left-circle" /> Cancel
      </div>
      <span
        className="float-screen"
        style={{
          color: 'white',
          // position: 'absolute',
          fontSize: '25px',
          fontWeight: '700',
          paddingLeft: '0.5em'
          //top: '0.5em',
          //left: "-1.2em"
        }}
      >
        Cash Float
      </span>{' '}
      <br />
      <span
        className="float-screen"
        style={{
          color: 'white',
          // position: 'absolute',
          fontSize: '16px',
          fontWeight: '400',
          paddingLeft: '0.85em',
          left: '0.85em',
          top: '3.2em'
        }}
      >
        Enter your cash amount received
      </span>
      <Row style={{ paddingTop: 80 }} type="flex" justify="space-between">
        {/*<div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>*/}

        <Col span={24}>
          <Row type="flex" justify="space-between">
            <Col span={24}>
              <Card
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  marginTop: -60,
                  boxShadow: 'none'
                }}
              >
                <CardHeader
                  className="float-screen"
                  style={{
                    height: '1em',
                    fontSize: '20px',
                    fontWeight: '700',
                    color: 'rgb(0, 204, 112)',
                    marginBottom: '20px',
                    paddingBottom: '2em',
                    borderBottom: '1px solid lightgrey'
                  }}
                >
                  Notes
                </CardHeader>
                <CardBody style={{ padding: 0 }} className="float-buttons">
                  {notes.map((note, index) => (
                    <Row
                      style={{ padding: 8 }}
                      type="flex"
                      justify="space-between"
                    >
                      <Col span={7} style={col1Style}>
                        <span style={spanStyle}>R{note}</span>
                      </Col>
                      <Col span={7}>
                        <NumPad.Number
                          theme={numpadTheme}
                          position="startBottomRight"
                          confirm={e => {}}
                          update={e => {}}
                          onChange={e =>
                            oState.floatInputChangeNoteInputsNumpad(
                              e,
                              index,
                              note
                            )
                          }
                          value={
                            oState.state.floatInputsNoteValues[index].amount
                          }
                          inline={false}
                          decimal={false}
                          negative={false}
                        >
                          <Input
                            style={inputStyle}
                            value={
                              oState.state.floatInputsNoteValues[index].amount
                            }
                          />
                        </NumPad.Number>
                      </Col>
                      <Col span={5}>
                        <Button
                          className="float-button-subtract"
                          style={minusButtonStyle}
                          onClick={e =>
                            oState.floatInputChangeNoteInputs(
                              'subtract',
                              index,
                              note
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>-</span>
                        </Button>
                      </Col>
                      <Col span={5}>
                        <Button
                          className="float-button-add"
                          style={buttonStyle}
                          onClick={e =>
                            oState.floatInputChangeNoteInputs(
                              'add',
                              index,
                              note
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>+</span>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <Col span={24}>
              <Card
                style={{
                  marginTop: 0,
                  width: '100%',
                  boxShadow: 'none',
                  backgroundColor: 'transparent'
                }}
              >
                <CardHeader
                  className="float-screen"
                  style={{
                    height: '1em',
                    fontSize: '20px',
                    fontWeight: '700',
                    color: 'rgb(0, 204, 112)',
                    marginBottom: '20px',
                    paddingBottom: '2em',
                    borderBottom: '1px solid lightgrey'
                  }}
                >
                  Coins
                </CardHeader>
                <CardBody style={{ padding: 0 }} className="float-buttons">
                  {coins.map((coin, index) => (
                    <Row
                      style={{ padding: 8 }}
                      type="flex"
                      justify="space-between"
                    >
                      <Col span={7} style={col1Style}>
                        <span style={spanStyle}>
                          {coin.prefix + coin.value + coin.suffix}
                        </span>
                      </Col>
                      <Col span={7}>
                        <NumPad.Number
                          theme={numpadTheme}
                          position="startBottomRight"
                          confirm={e => {}}
                          update={e => {}}
                          onChange={e =>
                            oState.floatInputChangeCoinInputsNumpad(
                              e,
                              index,
                              coin
                            )
                          }
                          value={
                            oState.state.floatInputsCoinValues[index].amount
                          }
                          inline={false}
                          decimal={false}
                          negative={false}
                        >
                          <Input
                            style={inputStyle}
                            value={
                              oState.state.floatInputsCoinValues[index].amount
                            }
                          />
                        </NumPad.Number>
                      </Col>
                      <Col span={5}>
                        <Button
                          className="float-button-subtract"
                          style={minusButtonStyle}
                          onClick={e =>
                            oState.floatInputChangeCoinInputs(
                              'subtract',
                              index,
                              coin.value
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>-</span>
                        </Button>
                      </Col>
                      <Col span={5}>
                        <Button
                          className="float-button-add"
                          style={buttonStyle}
                          onClick={e =>
                            oState.floatInputChangeCoinInputs(
                              'add',
                              index,
                              coin.value
                            )
                          }
                        >
                          <span style={{ fontSize: 25 }}>+</span>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" className="float-screen-button">
        <Col span={24}>
          <Card
            style={{
              background: 'transparent',
              // height: '15em',
              marginLeft: 'auto',
              marginRight: 'auto',
              // marginTop: '-7em',
              maxWidth: '30em',
              boxShadow: 'none'
            }}
          >
            <CardBody style={{ display: 'flex' }}>
              <div
                style={{
                  margin: 'auto',
                  textAlign: 'center',
                  width: '100%',
                  color: 'white',
                  marginTop: '-6em'
                }}
              >
                <span style={{ fontSize: 50, fontWeight: '700' }}>
                  R{oState.state.float.toFixed(2)}
                </span>
                <Button
                  style={floatButton}
                  onClick={oState.loginSessionAndFloat}
                >
                  {'Assign Float'}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
});

const LogoImage = memo(() => {
  return (
    <img
      className="login-Logo-pic"
      src={Logo}
      alt="Logo"
      style={{
        width: '8.7em',
        marginTop: '1em',
        paddingLeft: '1em',
        opacity: 1
      }}
    />
  );
});

const Header = ({ ...oState }) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: '#171A20',
          paddingTop: '0.5em',
          // backgroundImage: 'url(' + waveBackImg + ')',
          backgroundPosition: 'right',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      >
        {/* <CardHeader
                className="user-login-area"
                style={{width: '61%',maxWidth: '60em',marginLeft:"35px",marginTop:"1em",marginBottom:"0.5em",borderRadius:"1em",backgroundImage: 'url(' + waveBackImg + ')',backgroundPosition:"right",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"}}
                // color="transparent"
              > */}

        {/* <LogoImage /> */}
        <h4
          style={{
            width: '100%',
            paddingTop: '0.5em',
            // paddingBottom: '0.5em',
            paddingLeft: '0.5em',
            marginLeft: '0.1em',
            marginBottom: '0em',
            paddingBottom: '0.5em',
            color: 'White',
            fontWeight: '600',
            fontSize: '25px',
            // borderBottom: '1.5px solid #ffffff59',
            fontFamily: 'Nunito'
          }}
        >
          {oState.dashState.loading ? (
            'Getting Session Ready'
          ) : (
            <span style={{ color: '#00CC70' }}>
              Launch <span />
              Session
            </span>
          )}
          <span
            className="sub-head-login"
            style={{
              fontWeight: '100',
              fontSize: '14px',
              marginLeft: '0.5em',
              fontFamily: 'Nunito'
            }}
          >
            {oState.dashState.loading
              ? '.....'
              : 'Select your profile to start a session'}
          </span>
        </h4>

        {oState.state.users &&
          oState.state.users.map((user, key) => (
            <div key={'user' + key}>
              {!user.role.isDriver ? (
                <div
                  style={
                    dashState.loading
                      ? {
                          pointerEvents: 'none',
                          opacity: '0.7'
                        }
                      : {}
                  }
                >
                  {!user.session && !user.cashup ? (
                    <UserDisplayClosed
                      user={user}
                      newkey={key}
                      usertobuttonstate={oState}
                      setUser={setUser}
                      props={props}
                    />
                  ) : (
                    <UserButtons
                      user={user}
                      newkey={key}
                      usertobuttonstate={oState}
                      setUser={setUser}
                      props={props}
                    />
                  )}
                </div>
              ) : null}
            </div>
          ))}
      </div>
    </div>
  );
};

const PhoneHeader = memo(({ ...oState }, setUser) => {
  [localUserState, setLocalUserState] = useState('');
  // let users = [];
  // oState.state.users.forEach(user => {
  //   if (user.name !== "Super Admin") {
  //     users.push(user)
  //   }
  // })
  return (
    <div>
      <Card
        style={{
          overflow: 'hidden',
          width: '100vw',
          fontSize: '16px',
          marginTop: '0em',
          borderRadius: '0em',
          borderRight: '0px solid rgba(63, 63, 63, 0.904)'
        }}
      >
        {' '}
        <GridContainer style={{ padding: '0% 0%', flexWrap: 'unset' }}>
          <div className="area">
            <Card
              style={{
                left: '0',
                marginTop: '-0.5em',
                background: '#11141A',
                color: '#333333',
                boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.0)',
                borderRadius: '0em',
                minWidth: '100vw',
                overflow: 'hidden'
              }}
            >
              <div className="p p-1" />
              <div className="p p-2" />
              <div className="p p-3" />
              {/*<div id='stars'></div>
              <div id='stars2'></div>
              <div id='stars3'></div>*/}{' '}
              <CardHeader
                className="user-login-area"
                // color="transparent"
              >
                {' '}
                <LogoImage />
                <h4
                  style={{
                    textAlign: 'center',
                    paddingTop: '1em',
                    paddingBottom: '0.5em',
                    color: 'White',
                    fontWeight: '600',
                    fontSize: '25px',
                    borderBottom: '1.5px solid #ffffff59',
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  {oState.dashState.loading ? (
                    'Getting Session Ready'
                  ) : (
                    <span style={{ color: '#00CC70' }}>
                      Launch <span />
                      Session
                    </span>
                  )}
                </h4>
                <h2
                  className="sub-head-login"
                  style={{
                    textAlign: 'center',
                    fontWeight: 100,
                    fontSize: 14,
                    fontFamily: 'Arial, Helvetica, sans-serif'
                  }}
                >
                  {oState.dashState.loading
                    ? '.....'
                    : 'Enter your username to start a session'}
                </h2>
                <p
                  style={{
                    color: 'rgba(255,255,255,.62)',
                    margin: '0',
                    fontSize: '14px',
                    marginTop: '-2em',
                    marginBottom: '0'
                  }}
                />
              </CardHeader>
              <CardBody
                className="user-login-area-icons"
                style={{
                  padding: '0rem 50px',
                  height: '95vh',
                  overflow: 'auto',
                  paddingBottom: '9em'
                }}
              >
                {/* <PhoneUserLoginHeader
                  localUserState={localUserState}
                  setLocalUserState={setLocalUserState}
                  userLoginState={initUserLoginState}
                  setUser={oState.setUser}
                  state={oState.state} />*/}
                <CustomSelect
                  labelText={
                    <span style={{ color: 'white' }}>Select User</span>
                  }
                  id="mainmenu"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => {
                      const value = e.target.value;
                      oState.setUser(value, true);
                    },
                    value: localUserState,
                    style: { zIndex: 9, color: 'white' }
                  }}
                  menuItems={oState.state.users}
                />
                {/*  <CustomInput
                    labelText={<span style={{color:"white"}}>Select username</span>}
                    id="username"
                    style={{zIndex:9}}
                    formControlProps={{
                      fullWidth: true
                    }}
                    // error={
                    //   this.state.username === '' && this.state.submitRequest
                    // }
                    errorText={'Username required'}
                    inputProps={{
                      style:{zIndex:9, color:"white"},
                      onChange: e => {
                        const value = e
                        oState.setUser(value)
                      },
                      autoFocus: true
                    }}
                  /> */}
              </CardBody>
            </Card>
          </div>
        </GridContainer>
      </Card>
    </div>
  );
});

let checkOnlineInterval;
let getPrebillInterval;
const componentDidMount = () => {
  const token = props.local.user.orgtoken;
  const showFav = props.local.user.showFav;
  if (checkOnlineInterval) {
    clearInterval(checkOnlineInterval);
  }
  if (getPrebillInterval) {
    clearInterval(getPrebillInterval);
  }

  document.removeEventListener('offline', setOffline, false);
  document.removeEventListener('online', setOnline, false);
  document.addEventListener('offline', setOffline, false);
  document.addEventListener('online', setOnline, false);
  if (!window.cordova) {
    checkOnlineInterval = setInterval(() => {
      if (navigator) {
        if (!window.offline !== navigator.onLine) {
          if (navigator.onLine) {
            setOnline();
          } else {
            setOffline();
          }
        }
      }
    }, 1000);
  }
  if (!window.offline) {
    if (Object.keys(props.syncBills).length > 0 || props.payments.length > 0) {
      let syncDataObject = {};
      if (Object.keys(props.syncBills).length > 0) {
        var billToArray = [];
        var billItems = [];
        let syncBills = JSON.parse(JSON.stringify(props.syncBills));
        for (var prop in syncBills) {
          for (var prop2 in syncBills[prop]) {
            if (
              syncBills[prop][prop2].userid === '' ||
              !syncBills[prop][prop2].userid
            ) {
              syncBills[prop][prop2].userid = prop;
            }
            billToArray.push(syncBills[prop][prop2]);
            syncBills[prop][prop2].items.forEach(item => {
              if (item.userid === '' || !item.userid) {
                item.userid = prop;
              }
              billItems.push(item);
            });
          }
        }
        syncDataObject.bill = billToArray;
        syncDataObject.billitems = billItems;
      }
      if (props.payments.length > 0) {
        syncDataObject.payments = props.payments;
        syncDataObject.paymentReasons = props.paymentReasons;
      }

      props
        .syncData(token, syncDataObject)
        .then(() => {
          props.resetUser(token);
          props.clearSyncBills();
          props.clearPayments();
          props.getMenuDashPreload(token, showFav).then(result => {
            if (getPrebillInterval) {
              clearInterval(getPrebillInterval);
            }
            getUsers();
            getPrebillInterval = setInterval(() => {
              props.getBillDashPreload(token);
              getUsers();
            }, 5000);
          });
        })
        .catch(e => {
          componentDidMount();
        });
    } else {
      props.resetUser(token);
      props.getMenuDashPreload(token, showFav).then(result => {
        if (getPrebillInterval) {
          clearInterval(getPrebillInterval);
        }
        getUsers();
        getPrebillInterval = setInterval(() => {
          props.getBillDashPreload(token);
          getUsers();
        }, 5000);
      });
    }
  }
};

const componentWillUnmount = () => {
  if (checkOnlineInterval) {
    clearInterval(checkOnlineInterval);
  }
  if (getPrebillInterval) {
    clearInterval(getPrebillInterval);
  }
  document.removeEventListener('offline', setOffline, false);
  document.removeEventListener('online', setOnline, false);
};

const setOffline = () => {
  window.offline = true;
};

const setOnline = () => {
  window.offline = false;
  const token = props.local.user.orgtoken;
  const showFav = props.local.user.showFav;
  if (getPrebillInterval) {
    clearInterval(getPrebillInterval);
  }
  if (Object.keys(props.syncBills).length > 0 || props.payments.length > 0) {
    let syncDataObject = {};
    if (Object.keys(props.syncBills).length > 0) {
      var billToArray = [];
      var billItems = [];
      let syncBills = JSON.parse(JSON.stringify(props.syncBills));
      for (var prop in syncBills) {
        for (var prop2 in syncBills[prop]) {
          if (
            syncBills[prop][prop2].userid === '' ||
            !syncBills[prop][prop2].userid
          ) {
            syncBills[prop][prop2].userid = prop;
          }
          billToArray.push(syncBills[prop][prop2]);
          syncBills[prop][prop2].items.forEach(item => {
            if (item.userid === '' || !item.userid) {
              item.userid = prop;
            }
            billItems.push(item);
          });
        }
      }
      syncDataObject.bill = billToArray;
      syncDataObject.billitems = billItems;
    }
    if (props.payments.length > 0) {
      syncDataObject.payments = props.payments;
      syncDataObject.paymentReasons = props.paymentReasons;
    }
    props
      .syncData(token, syncDataObject)
      .then(() => {
        props.resetUser(token);
        props.clearSyncBills();
        props.clearPayments();
        props.getMenuDashPreload(token, showFav).then(result => {
          if (getPrebillInterval) {
            clearInterval(getPrebillInterval);
          }
          getUsers();
          getPrebillInterval = setInterval(() => {
            getUsers();
            props.getBillDashPreload(token);
          }, 5000);
        });
      })
      .catch(e => {
        setOnline();
      });
  } else {
    props.resetUser(token);
    if (!loggedIn) {
      props.getMenuDashPreload(token, showFav).then(result => {
        if (getPrebillInterval) {
          clearInterval(getPrebillInterval);
        }
        getUsers();
        getPrebillInterval = setInterval(() => {
          getUsers();
          props.getBillDashPreload(token);
        }, 5000);
      });
    }
  }
};

const getUsers = () => {
  const token = props.local.user.orgtoken;
  //props.removeLoader();
  setDash(prevState => ({
    ...prevState,
    loading: false
  }));
  props.getOrgUsers(token).then(
    result => {
      setUserState(prevState => ({
        ...prevState,
        topSale: props.local.topSale,
        users: props.users,
        user: {}
      }));
      $('#splash-screen')[0].style.opacity = '0';
      setTimeout(function() {
        $('#splash-screen')[0].style.display = 'none';
      }, 2000);
    },
    function(error) {
      if (error.includes('Login Expired')) {
        onError(error);
        props.resetOrg();
      } else {
        onError(error);
      }
    }
  );
};

const getFile = url => {
  return new Promise(async (resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: url,
      crossDomain: true,
      xhrFields: {
        responseType: 'blob'
      },
      success: async data => {
        return resolve(data);
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          return reject({ message: xhr.responseText });
        } else if (!xhr.responseText) {
          return reject({ message: 'No Connection Found!' });
        }
      }
    });
  }).catch(async err => {
    throw err.message;
  });
};

const downloadFile = e => {
  if (e && e.preventDefault) {
    e.preventDefault();
  }
  setDash(prevState => ({
    ...prevState,
    loading: false,
    updateDialogOpen: false,
    ldopen: true
  }));
  let link = process.env.REACT_APP_VERSION_CONTROL.includes('DEV')
    ? 'https://www.devvcsappcloud.com/vposbuild.apk'
    : 'https://www.vcsappcloud.com/vpos/android/vpos.apk';

  getFile(link).then(
    result => {
      let endResult = result;
      let filename = link.split('/');
      filename = filename[filename.length - 1];
      var blob = new Blob([endResult], {
        type: endResult.type
      });

      if (!window.cordova) {
        setDash(prevState => ({
          ...prevState,
          loading: false,
          updateDialogOpen: false,
          ldopen: false
        }));
        saveAs(blob, filename);
      } else {
        try {
          var storageLocation = '';

          switch (window.device.platform) {
            case 'Android':
              storageLocation = 'file:///storage/emulated/0/Download/';
              break;

            case 'iOS':
              storageLocation = window.cordova.file.documentsDirectory;
              break;
          }
          var folderPath = storageLocation;
          window.resolveLocalFileSystemURL(
            folderPath,
            function(dir) {
              dir.getFile(
                filename,
                {
                  create: true
                },
                function(file) {
                  file.createWriter(
                    function(fileWriter) {
                      var bufferBlob = sliceFile(blob, 10);
                      var count = 0;
                      var bufLength = bufferBlob.length;
                      var first = true;
                      fileWriter.onwriteend = function() {
                        if (!first) {
                          count = count + 1;
                          if (count < bufLength) {
                            appendWrite(bufferBlob[count]);
                          } else {
                            var url = file.toURL();
                            window.copen.open(
                              url,
                              '_system',
                              'location=no,hidden=yes'
                            );
                            setDash(prevState => ({
                              ...prevState,
                              loading: false,
                              updateDialogOpen: false,
                              ldopen: false
                            }));
                          }
                        } else {
                          first = false;
                          appendWrite(bufferBlob[count]);
                        }
                      };
                      fileWriter.onerror = function(err) {
                        alert('Unable to download');
                        console.error(err);
                        setDash(prevState => ({
                          ...prevState,
                          loading: false,
                          updateDialogOpen: false,
                          ldopen: false
                        }));
                      };
                      function appendWrite(chunkBlob) {
                        try {
                          var newblob = new Blob([chunkBlob], {
                            type: endResult.type
                          });
                          fileWriter.seek(fileWriter.length);
                          fileWriter.write(newblob);
                        } catch (err) {
                          alert(err);
                        }
                      }
                      if (bufLength > 0) {
                        fileWriter.write('');
                      }
                    },
                    function(err) {
                      alert('Unable to download');
                      console.error(err);
                      setDash(prevState => ({
                        ...prevState,
                        loading: false,
                        updateDialogOpen: false,
                        ldopen: false
                      }));
                    }
                  );
                },
                function(err) {
                  alert('Unable to download');
                  console.error(err);
                  setDash(prevState => ({
                    ...prevState,
                    loading: false,
                    updateDialogOpen: false,
                    ldopen: false
                  }));
                }
              );
            },
            function(err) {
              alert('Unable to download');
              console.error(err);
              setDash(prevState => ({
                ...prevState,
                loading: false,
                updateDialogOpen: false,
                ldopen: false
              }));
            }
          );
        } catch (err) {
          alert(err.message);
          setDash(prevState => ({
            ...prevState,
            loading: false,
            updateDialogOpen: false,
            ldopen: false
          }));
        }
      }
    },
    function(error) {
      alert(error);
      setDash(prevState => ({
        ...prevState,
        loading: false,
        updateDialogOpen: false,
        ldopen: false
      }));
    }
  );
};

const sliceFile = (file, chunksAmount) => {
  var byteIndex = 0;
  var chunks = [];

  for (var i = 0; i < chunksAmount; i += 1) {
    var byteEnd = Math.ceil((file.size / chunksAmount) * (i + 1));
    chunks.push(file.slice(byteIndex, byteEnd));
    byteIndex += byteEnd - byteIndex;
  }

  return chunks;
};

const closeDialog = () => {
  setAlert(prevState => ({
    ...prevState,
    alert: false
  }));
};

const closeFormDialog = e => {
  if (e && e.preventDefault) {
    e.preventDefault();
  } else {
    props.resetUser(props.local.user.token);
  }
  setDash(prevState => ({
    ...prevState,
    fdopen: false,
    updateDialogOpen: false,
    ldopen: false
  }));
};

const onError = err => {
  //props.removeLoader();
  setDash(prevState => ({
    ...prevState,
    loading: false
  }));
  setAlert(prevState => ({
    ...prevState,
    alert: true,
    msg: [err]
  }));
};

const loginSession = () => {
  const token = props.local.user.token;
  setDash(prevState => ({
    ...prevState,
    fdopen: false
  }));
  props
    .sessionStart(token)
    .then(res => {
      // props.removeLoader();
      props.getOrgUsers(token).then(() => {
        setLoggedIn(true);
        // setDash(prevState => ({
        //   ...prevState,
        //   loading: false
        // }));
      });
    })
    .catch(err => {
      // OFFLINE
      //onError(err);
      onError(
        'You need to be online to create a session. Offline login requires an existing session.'
      );
    });
};

const loginSessionAndFloat = () => {
  const token = props.local.user.token;
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  // setDash(prevState => ({
  //   ...prevState,
  //   loading: true
  // }));
  setDash(prevState => ({
    ...prevState,
    main: true,
    fdopen: false
  }));
  props
    .sessionStartFloat(token, float)
    .then(res => {
      // props.removeLoader();
      props.getOrgUsers(token).then(() => {
        setLoggedIn(true);
        // setDash(prevState => ({
        //   ...prevState,
        //   loading: false
        // }));
      });
    })
    .catch(err => {
      // OFFLINE
      //onError(err);
      onError(
        'You need to be online to create a session. Offline login requires an existing session.'
      );
    });
};

const loginSessionFloat = () => {
  setDash(prevState => ({
    ...prevState,
    main: false,
    fdopen: false
  }));
};

const back = () => {
  setDash(prevState => ({
    ...prevState,
    main: true,
    fdopen: true
  }));
};

const submitForm = (qrCode, numberButtonsProps, userLoginState) => {
  props.setLoggedInFalse();
  if (window.offline && userLoginState.name === 'Super Admin') {
    setButtons.Provider(prevState => ({
      ...prevState,
      loginError: true
    }));
    setUserLogin.Provider(prevState => ({
      ...prevState,
      loginError: true
    }));
    setTimeout(() => {
      setButtons.Provider(prevState => ({
        ...prevState,
        password: [],
        passReset: [],
        loginError: false
      }));
      setUserLogin.Provider(prevState => ({
        ...prevState,
        loginError: false
      }));
    }, 500);
    onError('No Internet Connection Found');
    return;
  }
  if (
    userLoginState.name !== 'Super Admin' &&
    props.menu &&
    !props.menu.length
  ) {
    setButtons.Provider(prevState => ({
      ...prevState,
      loginError: true
    }));
    setUserLogin.Provider(prevState => ({
      ...prevState,
      loginError: true
    }));
    setTimeout(() => {
      setButtons.Provider(prevState => ({
        ...prevState,
        password: [],
        passReset: [],
        loginError: false
      }));
      setUserLogin.Provider(prevState => ({
        ...prevState,
        loginError: false
      }));
    }, 500);
    onError('No Categories Found');
    return;
  }
  const token = props.local.user.orgtoken;
  props.resetUser(token);
  //TODO setDash(prevState => ({
  //   ...prevState,
  //   loading: true
  // }));
  //TODOprops.initLoader(10);
  var copyState = userLoginState;
  var copsyStatePass = numberButtonsProps.password;
  if (qrCode) {
    copyState.qrCode = qrCode;
  } else {
    delete copyState.qrCode;
    copyState.password = copsyStatePass.toString().replace(/,/g, '');
  }
  props
    .loginUser(
      copyState,
      token,
      props.local.users,
      props.local.user.deviceno,
      process.env.REACT_APP_VERSION_CONTROL
    )
    .then(res => {
      //TODO setDash(prevState => ({
      //   ...prevState,
      //   loading: false
      // }));
      //TODOprops.initLoader(50);
      //TODO setUserLogin.Provider(prevState => ({
      //   ...prevState,
      //   username: '',
      //   name: ''
      // }));
      //props.setPreBills(res.data.userDetails._id);
      if (!res.data.userDetails.mode) {
        res.data.userDetails.mode = {};
      }
      if (res.data.userDetails.role && res.data.userDetails.role.isSupAdmin) {
        res.data.userDetails.mode.home = true;
      }
      var result = res;
      if (res.data.userDetails.reset) {
        props
          .clearDashCache(res.data.userDetails, res.data.token)
          .then(ress => {
            doLogin(result, copyState);
          })
          .catch(err => {
            onError(err);
          });
      } else {
        if (
          res.data.userDetails.role &&
          !res.data.userDetails.role.isAdmin &&
            !res.data.userDetails.role.isSupAdmin
        ) {
          if (
            (res.data.response &&
              res.data.response.bill &&
              JSON.stringify(res.data.response.bill) !==
                JSON.stringify(props.bill)) ||
            (res.data.response &&
              res.data.response.billitems &&
              JSON.stringify(res.data.response.billitems) !==
                JSON.stringify(props.billitems))
          ) {
            props.compareBills(res.data.response);
          }
        }
        setButtons.Provider(prevState => ({
          ...prevState,
          password: [],
          passReset: []
        }));
        doLogin(result, copyState);
      }
    })
    .catch(err => {
      if (
        err.search('Another device') > -1 ||
        err.search('You need') > -1 ||
        err.search('You have') > -1 ||
        err.search('Admin') > -1 ||
        err.search('Expired') > -1
      ) {
        //TODO setDash(prevState => ({
        //   ...prevState,
        //   loading: false
        // }));
        setButtons.Provider(prevState => ({
          ...prevState,
          loginError: true
        }));
        setUserLogin.Provider(prevState => ({
          ...prevState,
          loginError: true
        }));
        setTimeout(() => {
          // setDash(prevState => ({
          //   ...prevState,
          //   loading: false
          // }));
          setButtons.Provider(prevState => ({
            ...prevState,
            password: [],
            passReset: [],
            loginError: false
          }));
          setUserLogin.Provider(prevState => ({
            ...prevState,
            loginError: false
          }));
        }, 500);
        onError(err);
      } else if (err.search('outdated') > -1) {
        if (window.cordova && navigator.serviceWorker) {
          navigator.notification.alert(
            'Update ready the app will restart now.',
            update,
            'Update Available',
            'Ok'
          );
        } else if (
          window.cordova &&
          (window.device.platform === 'iOS' ||
            window.device.platform === 'iPadOS')
        ) {
          setButtons.Provider(prevState => ({
            ...prevState,
            password: [],
            passReset: [],
            loginError: false
          }));
          setUserLogin.Provider(prevState => ({
            ...prevState,
            loginError: false
          }));
          navigator.notification.alert(
            'There is an update available on the app store. Please update the VPOS app before logging in.',
            null,
            'Update Available',
            'Ok'
          );
        } else {
          alert('Update ready the app will restart now.');
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
              });
              window.location.reload();
            });
          });
        }
        function update() {
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
              });
              window.location.reload();
            });
          });
        }
      } else if (qrCode) {
        //TODO setDash(prevState => ({
        //   ...prevState,
        //   loading: false
        // }));
        setButtons.Provider(prevState => ({
          ...prevState,
          loginError: true
        }));
        setUserLogin.Provider(prevState => ({
          ...prevState,
          loginError: true
        }));
        setTimeout(() => {
          // setDash(prevState => ({
          //   ...prevState,
          //   loading: false
          // }));
          setButtons.Provider(prevState => ({
            ...prevState,
            password: [],
            passReset: [],
            loginError: false
          }));
          setUserLogin.Provider(prevState => ({
            ...prevState,
            loginError: false
          }));
        }, 500);
        onError(err);
      } else {
        //TODO props.removeLoader();
        //TODO setDash(prevState => ({
        //   ...prevState,
        //   loading: false
        // }));
        setButtons.Provider(prevState => ({
          ...prevState,
          loginError: true
        }));
        setUserLogin.Provider(prevState => ({
          ...prevState,
          loginError: true
        }));
        setTimeout(() => {
          // setDash(prevState => ({
          //   ...prevState,
          //   loading: false
          // }));
          setButtons.Provider(prevState => ({
            ...prevState,
            password: [],
            passReset: [],
            loginError: false
          }));
          setUserLogin.Provider(prevState => ({
            ...prevState,
            loginError: false
          }));
        }, 500);
      }
    });
};
const apiCalluser = 'vpos/api/user/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const doLogin = async (res, copyState) => {
  if (copyState.password === '0123') {
    if (res.data.token === 'offline') {
      await $.ajax({
        type: 'POST',
        url: server + apiCalluser + 'generatetoken',
        data: JSON.stringify(res.data.userDetails),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        success: async results => {
          props.updateToken(results);
          res.data.token = results.token;
        },
        error: async xhr => {}
      });
    }
    //TODO props.removeLoader();
    setDash(prevState => ({
      ...prevState,
      loading: false,
      reset: true
    }));
    setUserLogin.Provider(prevState => ({
      ...prevState,
      token: res.data.token,
      username: res.data.userDetails.username
    }));
    props.resetUser(res.data.token);
  } else if (res.data.userDetails.session === true) {
    //TODO setDash(prevState => ({
    //   ...prevState,
    //   loading: false
    // }));
    if (localUserState) {
      setLocalUserState('');
    }
    setUserLogin.Provider(prevState => ({
      ...prevState,
      username: '',
      name: ''
    }));
    if (
      res.data.userDetails.role.isSupAdmin ||
      res.data.userDetails.role.isAdmin
    ) {
      props.setAdminLogin();
    } else {
      setLoggedIn(true);
    }
  } else if (res.data.userDetails.cashup) {
    //TODO props.removeLoader();
    //TODO setDash(prevState => ({
    //   ...prevState,
    //   loading: false
    // }));
    props.resetUser(res.data.token);
    onError('Cashup not Complete');
  } else {
    var actions = [{ name: 'Start', click: loginSession }];
    if (
      res.data.userDetails.role.isClerk ||
      res.data.userDetails.role.isBar ||
      res.data.userDetails.role.isKitchen
    ) {
      actions = [
        { name: 'Start Session', click: loginSession },
        { name: 'Add Float', click: loginSessionFloat }
      ];
    }
    setDash(prevState => ({
      ...prevState,
      loading: false,
      fdopen: true,
      actions: actions
    }));
    setUserState(prevState => ({
      ...prevState,
      user: res.data.userDetails
    }));
    setFloat(prevState => ({
      ...prevState,
      float: 0,
      floatInputsNoteValues: [
        { value: 10, amount: 0 },
        { value: 20, amount: 0 },
        { value: 50, amount: 0 },
        { value: 100, amount: 0 },
        { value: 200, amount: 0 }
      ],
      floatInputsCoinValues: [
        { value: 0.1, amount: 0 },
        { value: 0.2, amount: 0 },
        { value: 0.5, amount: 0 },
        { value: 1, amount: 0 },
        { value: 2, amount: 0 },
        { value: 5, amount: 0 }
      ]
    }));
    // setLoggedIn(true)
  }
};

const setUser = (user, setLocalState) => {
  if (Object.keys(props.syncBills).length > 0) {
    alert('The device is busy syncing data please try again in 5 seconds');
  } else if (!setLocalState) {
    setUserLogin.Provider(prevState => ({
      ...prevState,
      username: user.username,
      name: user.name,
      loginType: user.loginType
    }));
    props.setPreBills(user);
  } else {
    userButtonsProps.state.users.forEach(item => {
      if (item._id === user) {
        setUserLogin.Provider(prevState => ({
          ...prevState,
          username: item.username,
          name: item.name,
          loginType: item.loginType,
          id: item._id
        }));
        setLocalUserState(item._id);
        props.setPreBills(item);
      }
    });
  }
  setButtons.Provider(prevState => ({
    ...prevState,
    password: [],
    passReset: []
  }));
  // userLoginState.state.inputRef.current.style.setProperty(
  //   'text-align',
  //   'center',
  //   'important'
  // );
  // if (!window.cordova) {
  //   userLoginState.state.inputRef.current.focus();
  // }
};

const floatInputChangeNoteInputs = (e, key, amount) => {
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  var inputs = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsNoteValues)
  );
  var inputs2 = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsCoinValues)
  );
  if (e === 'add') {
    inputs[key].amount = inputs[key].amount + 1;
  } else if (e === 'subtract') {
    inputs[key].amount =
      inputs[key].amount === 0 ? inputs[key].amount : inputs[key].amount - 1;
  } else {
    inputs[key].amount = parseInt(e.currentTarget.value);
  }
  let total = 0;
  for (var i = 0; i < inputs.length; i++) {
    total = total + inputs[i].amount * inputs[i].value;
  }
  for (var j = 0; j < inputs2.length; j++) {
    total = total + inputs2[j].amount * inputs2[j].value;
  }
  setFloat(prevState => ({
    ...prevState,
    float: total,
    floatInputsNoteValues: inputs
  }));
};

const floatInputChangeNoteInputsNumpad = (e, key, amount) => {
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  var inputs = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsNoteValues)
  );
  var inputs2 = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsCoinValues)
  );
  inputs[key].amount = parseInt(e);
  let total = 0;
  for (var i = 0; i < inputs.length; i++) {
    total = total + inputs[i].amount * inputs[i].value;
  }
  for (var j = 0; j < inputs2.length; j++) {
    total = total + inputs2[j].amount * inputs2[j].value;
  }
  setFloat(prevState => ({
    ...prevState,
    float: total,
    floatInputsNoteValues: inputs
  }));
};

const floatInputChangeCoinInputs = (e, key, amount) => {
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  var inputs = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsCoinValues)
  );
  var inputs2 = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsNoteValues)
  );
  if (e === 'add') {
    inputs[key].amount = inputs[key].amount + 1;
  } else if (e === 'subtract') {
    inputs[key].amount =
      inputs[key].amount === 0 ? inputs[key].amount : inputs[key].amount - 1;
  } else {
    inputs[key].amount = parseInt(e.currentTarget.value);
  }
  let total = 0;
  for (var i = 0; i < inputs.length; i++) {
    total = total + inputs[i].amount * inputs[i].value;
  }
  for (var j = 0; j < inputs2.length; j++) {
    total = total + inputs2[j].amount * inputs2[j].value;
  }
  setFloat(prevState => ({
    ...prevState,
    float: total,
    floatInputsCoinValues: inputs
  }));
};

const floatInputChangeCoinInputsNumpad = (e, key, amount) => {
  var float = JSON.parse(JSON.stringify(userFloatProps.state.float));
  var inputs = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsCoinValues)
  );
  var inputs2 = JSON.parse(
    JSON.stringify(userFloatProps.state.floatInputsNoteValues)
  );
  inputs[key].amount = parseInt(e);
  let total = 0;
  for (var i = 0; i < inputs.length; i++) {
    total = total + inputs[i].amount * inputs[i].value;
  }
  for (var j = 0; j < inputs2.length; j++) {
    total = total + inputs2[j].amount * inputs2[j].value;
  }
  setFloat(prevState => ({
    ...prevState,
    float: total,
    floatInputsCoinValues: inputs
  }));
};

var stateObject = {
  username: '',
  name: '',
  id: '',
  email: '',
  actions: [],
  reset: false,
  passReset: [],
  float: 0,
  floatInputsNoteValues: [
    { value: 10, amount: 0 },
    { value: 20, amount: 0 },
    { value: 50, amount: 0 },
    { value: 100, amount: 0 },
    { value: 200, amount: 0 }
  ],
  floatInputsCoinValues: [
    { value: 0.1, amount: 0 },
    { value: 0.2, amount: 0 },
    { value: 0.5, amount: 0 },
    { value: 1, amount: 0 },
    { value: 2, amount: 0 },
    { value: 5, amount: 0 }
  ],
  main: true,
  loading: false,
  alert: false,
  fdopen: false,
  msg: [],
  head: 'Login',
  users: [],
  user: {},
  numbers1: [1, 2, 3],
  numbers2: [4, 5, 6],
  numbers3: [7, 8, 9],
  inputRef: createRef()
};

let dashState = {
  loading: stateObject.loading,
  main: stateObject.main,
  reset: stateObject.reset,
  fdopen: stateObject.fdopen,
  actions: stateObject.actions,
  updateDialogOpen: false,
  ldopen: false
};

const alertDialogProps = {
  state: {
    alert: stateObject.alert,
    head: stateObject.head,
    msg: stateObject.msg
  },
  closeDialog: closeDialog
};

const initUserLoginState = {
  token: stateObject.token,
  reset: dashState.reset,
  username: stateObject.username,
  name: stateObject.name,
  id: stateObject.id,
  email: stateObject.email,
  inputRef: stateObject.inputRef,
  loginError: stateObject.loginError
};

const userFloatProps = {
  state: {
    floatInputsNoteValues: stateObject.floatInputsNoteValues,
    floatInputsCoinValues: stateObject.floatInputsCoinValues,
    float: stateObject.float
  },

  floatInputChangeNoteInputs: floatInputChangeNoteInputs,
  floatInputChangeNoteInputsNumpad: floatInputChangeNoteInputsNumpad,
  floatInputChangeCoinInputs: floatInputChangeCoinInputs,
  floatInputChangeCoinInputsNumpad: floatInputChangeCoinInputsNumpad,
  loginSessionAndFloat: loginSessionAndFloat,
  back: back
};

const userButtonsProps = {
  state: {
    users: stateObject.users,
    user: stateObject.user
  },
  setUser: setUser
};

const initNumberState = {
  password: [],
  passReset: [],
  loginError: false,
  loginSuccess: false
};

let loggedIn;
let setLoggedIn;
let oldState;
const Login = allprops => {
  [dashState, setDash] = useState(dashState);
  [alertDialogProps.state, setAlert] = useState(alertDialogProps.state);
  [userFloatProps.state, setFloat] = useState(userFloatProps.state);
  [userButtonsProps.state, setUserState] = useState(userButtonsProps.state);
  [loggedIn, setLoggedIn] = useState(false);
  props = allprops;
  const [switchView, setSwitchView] = useState(false);
  const backToLogin = () => {
    setDash(prevState => ({
      ...prevState,
      loading: false,
      reset: false
    }));
  };
  const switchToDash = () => {
    setSwitchView(true);
  };

  const switchToKitchen = () => {
    setSwitchView(false);
  };

  const doLogout = callback => {
    setLoggedIn(false);
    setTimeout(async function() {
      callback('Done');
    }, 1000);
  };
  useEffect(() => {
    if (!loggedIn) {
      componentDidMount();
    } else if (loggedIn) {
      componentWillUnmount();
    }
  }, [loggedIn]);

  useEffect(() => {
    componentDidMount();
    return () => componentWillUnmount();
  }, []);

  const oState = {
    state: userButtonsProps.state,
    dashState: dashState
  };
  return (
    <div style={{ overflow: 'hidden' }}>
      <Spin spinning={false}>
        <div hidden={loggedIn}>
          {dashState.main ? (
            !window.isPhone ? (
              <Row
                style={{
                  overflow: 'hidden',
                  height: '100vh',
                  background: '#11141A',
                  border: '1px solid rgba(63, 63, 63, 0.904)'
                }}
              >
                <Col span={15}>
                  {!dashState.reset ? (
                    <Header
                      {...oState}
                      syncBills={props.syncBills}
                      orgid={allprops.local.user.orgid}
                    />
                  ) : null}
                </Col>
                <Col
                  span={9}
                  style={{ height: '100vh', backgroundColor: '#171A20' }}
                >
                  <UserLogin
                    setUserLogin={setUserLogin}
                    setButtons={setButtons}
                    onError={onError}
                    renewPass={props.renewPass}
                    resetUser={props.resetUser}
                    userLoginState={initUserLoginState}
                    dashState={dashState}
                    backToLogin={backToLogin}
                    numberState={initNumberState}
                    setDash={setDash}
                    submitForm={submitForm}
                  />
                </Col>
              </Row>
            ) : (
              <div
                style={{
                  overflow: 'hidden',
                  height: '100vh',
                  background: '#11141A',
                  border: '1px solid rgba(63, 63, 63, 0.904)'
                }}
              >
                {!dashState.reset ? (
                  <PhoneHeader setUser={setUser} {...oState} />
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <PhoneUserLogin
                    setUserLogin={setUserLogin}
                    setButtons={setButtons}
                    onError={onError}
                    renewPass={props.renewPass}
                    resetUser={props.resetUser}
                    userLoginState={initUserLoginState}
                    dashState={dashState}
                    numberState={initNumberState}
                    setDash={setDash}
                    submitForm={submitForm}
                  />
                </GridItem>
              </div>
            )
          ) : null}
          {!dashState.main ? (
            window.isPhone ? (
              <PhoneUserFloat {...userFloatProps} />
            ) : (
              <UserFloat {...userFloatProps} />
            )
          ) : null}
          <AlertDialog {...alertDialogProps} />

          <FormDialog
            head="Start A new Session"
            msg="Would you like to"
            formElements={[]}
            open={dashState.fdopen}
            close={closeFormDialog}
            actionBtn={dashState.actions[0]}
            actionBtn2={dashState.actions[1]}
            onClick={loginSession}
          />
        </div>
      </Spin>

      <div
        hidden={
          (!props.local.user.role.isKitchen &&
            !props.local.user.role.isBar &&
            loggedIn) ||
          (loggedIn &&
            (props.local.user.role.isKitchen || props.local.user.role.isBar) &&
            switchView)
            ? false
            : true
        }
      >
        <Dashboard
          user={props.local.user}
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          Mode={allprops.mode}
          switchToKitchen={switchToKitchen}
        />
      </div>
      {loggedIn &&
      (props.local.user.role.isKitchen || props.local.user.role.isBar) &&
      !switchView ? (
        <Kitchen
          user={props.local.user}
          loggedIn={loggedIn}
          setLoggedIn={doLogout}
          Mode={allprops.mode}
          switchToDash={switchToDash}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    local: state.backend,
    initLoaderState: state.initdashloader,
    syncBills: state.dashboard.syncBills || {},
    users: state.backend.users || [],
    payments: state.dashboard.payments || [],
    paymentReasons: state.dashboard.paymentReasons || [],
    bill: state.dashboard.bill || {},
    billitems: state.dashboard.billitems || [],
    menu: state.dashboard.menu || []
  };
};

const mapDispatchToProps = {
  loginUser,
  resetPass,
  renewPass,
  sessionStart,
  sessionStartFloat,
  resetUser,
  clearDashCache,
  resetOrg,
  getMenuDashPreload,
  getBillDashPreload,
  setPreBills,
  initLoader,
  removeLoader,
  getOrgUsers,
  syncData,
  clearSyncBills,
  clearPayments,
  setAdminLogin,
  compareBills,
  updateToken,
  setLoggedInFalse
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(Login));
