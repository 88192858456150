import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  DatePicker,
  Icon,
  Input,
  Row,
  Select,
  Spin,
  Table
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getUsersCashupReport } from 'src/redux/actions/backend';

import Highlighter from 'react-highlight-words';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import { openSnackbar } from 'src/redux/actions/snackbar';
const { Option } = Select;
const { Column } = Table;
const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { RangePicker } = DatePicker;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'whitesmoke',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  },
  show: {
    display: 'inline'
  },
  datePicker: {
    marginRight: '10px',
    '& span': {
      height: '45px'
    }
  }
});

class Shift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      datePicker: false,
      startDate: moment().format(dateFormatSearch),
      endDate: moment().format(dateFormatSearch),
      pickerStart: moment().startOf('month'),
      pickerEnd: moment(),
      loading: true,
      alert: false,
      msg: [],
      head: ''
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = (msg, startDate, endDate) => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    var start = startDate;
    var end = endDate;
    if (!start) {
      start = this.state.startDate;
    }
    if (!endDate) {
      end = this.state.endDate;
    }
    this.props
      .getUsersCashupReport(token, start, end)
      .then(result => {
        result.data.results.users.sort((a, b) => b.session - a.session);
        let sortedUsers = [];
        for (let i = 0; i < result.data.results.users.length; i++) {
          if (result.data.results.users[i].workhours !== 'No Session') {
            sortedUsers.push(result.data.results.users[i]);
          }
        }
        this.setState({
          users: sortedUsers,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'yester') {
        this.setState(
          {
            startDate: moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            endDate: moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            datePicker: false
          },
          this.getUsers(
            false,
            moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            moment()
              .subtract(1, 'days')
              .format(dateFormatSearch)
          )
        );
      } else if (value === 'today') {
        this.setState({
          startDate: moment().format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getUsers(
          false,
          moment().format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'last7') {
        this.setState({
          startDate: moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getUsers(
          false,
          moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'last30') {
        this.setState({
          startDate: moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getUsers(
          false,
          moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'thism') {
        this.setState({
          startDate: moment()
            .startOf('month')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getUsers(
          false,
          moment()
            .startOf('month')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'lastm') {
        this.setState({
          startDate: moment()
            .subtract(1, 'months')
            .startOf('month')
            .format(dateFormatSearch),
          endDate: moment()
            .subtract(1, 'months')
            .endOf('month')
            .format(dateFormatSearch),
          datePicker: false
        });
        this.getUsers(
          false,
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .format(dateFormatSearch),
          moment()
            .subtract(1, 'months')
            .endOf('month')
        );
      } else if (value === 'custom') {
        this.setState({
          datePicker: true
        });
      }
    }
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        startDate: value[0].format(dateFormatSearch),
        endDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1]
      });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'User Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  back = () => {
    this.setState({ loading: true });
    this.getUsers();
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditUser: this.handleEditUser
    };

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <div>
          <Row>
            <div
              style={{
                float: 'left',
                marginTop: '-10px',
                marginBottom: '10px'
              }}
            >
              <div
                className={
                  this.state.datePicker
                    ? this.props.classes.show
                    : this.props.classes.hide
                }
              >
                <RangePicker
                  allowClear={false}
                  className={this.props.classes.datePicker}
                  onChange={this.handleChangeDate}
                  value={[this.state.pickerStart, this.state.pickerEnd]}
                  format={dateFormat}
                />
                <Button
                  style={{
                    backgroundColor: '#00CC70',
                    color: 'white',
                    top: '-0.05em',
                    height: '40px',
                    width: '120px',
                    border: '1px solid #00CC70',
                    borderRadius: '3em',
                    fontSize: '12px',
                    paddingBottom: '0.1em',
                    fontWeight: '600'
                  }}
                  onClick={() => this.getUsers()}
                >
                  Get Report
                </Button>
              </div>
              <Select
                id="filterSelect"
                style={{
                  width: '150px',
                  paddingRight: '10px'
                }}
                defaultValue="today"
                onChange={this.handleChangeSelect}
              >
                <Option value="today">Today</Option>
                <Option value="yester">Yesterday</Option>
                <Option value="last7">Last 7 Days</Option>
                <Option value="last30">Last 30 Days</Option>
                <Option value="thism">This Month</Option>
                <Option value="lastm">Last Month</Option>
                <Option value="custom">Custom</Option>
              </Select>
            </div>
          </Row>
          <Row>
            <StyledTable
              style={{ background: 'white' }}
              rowKey="_id"
              size="middle"
              dataSource={this.state.users}
              pagination={{
                hideOnSinglePage: true,
                pageSize: 25,
                itemRender: (current, type, originalElement) => {
                  if (type === 'prev') {
                    return <a>Previous</a>;
                  }
                  if (type === 'next') {
                    return <a>Next</a>;
                  }
                  return originalElement;
                },
                showTotal: (total, range) =>
                  `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                defaultCurrent: 1
              }}
              onChange={this.handleSort}
              onRow={(record, rowIndex) => {}}
            >
              <Column
                {...this.getColumnSearchProps('name')}
                title="Name"
                dataIndex="name"
                key="name"
              />
              <Column
                {...this.getColumnSearchProps('username')}
                title="Username"
                dataIndex="username"
                key="username"
              />
              <Column
                {...this.getColumnSearchProps('role')}
                title="Role"
                dataIndex="role"
                key="role"
              />
              <Column
                {...this.getColumnSearchProps('session')}
                title="Current Session"
                render={(text, record) => {
                  return <span>{record.session ? 'Open' : 'Closed'}</span>;
                }}
                dataIndex="session"
                key="session"
              />
              <Column
                {...this.getColumnSearchProps('workhours')}
                title="Work Hours"
                render={(text, record) => {
                  return (
                    <span>
                      {record.workhours.length === 2
                        ? '00:' + record.workhours
                        : record.workhours}
                    </span>
                  );
                }}
                dataIndex="workhours"
                key="workhours"
              />
            </StyledTable>
          </Row>
        </div>
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  users: state.users,
  orgData: state.backend.orgname
});

const mapDispatchToProps = {
  getUsersCashupReport,
  openSnackbar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Shift));
