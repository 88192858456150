import React from 'react';
import Button from 'src/components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = props => {
  const { head, msg, formElements, open, close, actionBtn, onClick } = props;

  return (
    <div>
      <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{head}</DialogTitle>
        <DialogContent>
          <DialogContentText>{msg}</DialogContentText>
          {formElements &&
            formElements.map((elem, key) => <div key={key}>{elem}</div>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          {actionBtn &&
            actionBtn.map((elem, key) => (
              <Button key={key} onClick={elem.click} color="primary">
                {elem.name}
              </Button>
            ))}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
