import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Icon,
  Input,
  Popover,
  Row,
  Spin,
  Switch,
  Table,
  TimePicker
} from 'antd';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import CustomSelectAuto from 'src/components/CustomSelectAuto/CustomSelectAuto.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import {
  addSpecials,
  closeSpecialHelpCard,
  editSpecials,
  getSpecials
} from 'src/redux/actions/backend';
import styled from 'styled-components';

import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  resetActionBreadCrumb,
  setBreadCrumbs
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import { formaterCheck } from 'src/utils/formater.js';
import SpecialTable from 'src/views/Admin/Specials/SpecialTable';
const { Column } = Table;
const StyledCard = styled(Card)``;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  panel: {
    width: '400px'
  },
  cardExpand: {
    marginTop: '22px',
    marginBottom: '5px'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  showMain: {
    paddingLeft: '10px',
    display: 'inline-flex'
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '15px',
    width: '90%'
  },
  buttonAddInput: {
    marginTop: '43px',
    height: '35px'
  }
});

const normalDateFormat = 'YYYY/MM/DD';
const normalTimeFormat = 'HH:mm';

const dateFormatQuery = 'YYYYMMDD';
const dateFormatQuerTime = 'HHmm';

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const weekDaysList = [
  {
    name: 'Sunday',
    id: '0'
  },
  { name: 'Monday', id: '1' },
  { name: 'Tuesday', id: '2' },
  { name: 'Wednesday', id: '3' },
  { name: 'Thursday', id: '4' },
  { name: 'Friday', id: '5' },
  { name: 'Saturday', id: '6' }
];

const typeFormat = {
  onceoff: 'Once Off',
  day: 'Daily',
  week: 'Weekly'
};

const types = [
  {
    value: 'onceoff',
    name: 'Once Off'
  },
  { value: 'day', name: 'Daily' },
  { value: 'week', name: 'Weekly' }
];

var initState = {
  main: true,
  specials: [],
  _id: '',
  type: 'onceoff',
  date: 0,
  count: 0,
  items: [],
  catSelect: { value: undefined, key: 0, perc: undefined },
  active: false,
  specialName: '',
  loading: false,
  alert: false,
  msg: [],
  head: '',
  activeFilter: ['true']
};

class Specials extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }
  onCloseCard = e => {
    this.props.closeSpecialHelpCard();
  };
  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](true);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.getSpecials();
  }

  getSpecials = msg => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .getSpecials(token)
      .then(result => {
        this.handleRemoveBreadCrumb();
        this.setState(initState);
        this.setState({
          specials: result.data.results.specials,
          selectitems: result.data.results.menuitems,
          catList: result.data.results.catList,
          catObject: result.data.results.catObject
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  addSpecial = () => {
    this.handleAddBreadCrumb({ method: 'handleClose', crumb: 'Add Special' });
    this.setState({
      main: false,
      _id: '',
      type: 'onceoff',
      catSelect: { value: undefined, key: 0, perc: undefined },
      startdate: moment().format(dateFormatQuery),
      starttime: moment().format(dateFormatQuerTime),
      enddate: moment().format(dateFormatQuery),
      endtime: moment().format(dateFormatQuerTime),
      count: 0,
      items: [
        {
          _id: '',
          name: undefined,
          price: null
        }
      ],
      active: true,
      specialName: ''
    });
  };

  updateSpecialActive = record => {
    this.setState({ loading: true });
    var copyRecord = JSON.parse(JSON.stringify(record));
    copyRecord.active = !copyRecord.active;
    copyRecord.specialName = copyRecord.name;
    const token = this.props.local.user.token;
    this.props
      .editSpecials(copyRecord, token)
      .then(result => {
        this.getSpecials('Update Complete');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  updateSpecial = () => {
    this.setState({ loading: true, submitInProgress: true });
    var copyState = JSON.parse(JSON.stringify(this.state));
    copyState.active = true;
    const token = this.props.local.user.token;
    if (copyState._id) {
      this.props
        .editSpecials(copyState, token)
        .then(result => {
          this.getSpecials('Update Complete');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    } else {
      this.props
        .addSpecials(copyState, token)
        .then(result => {
          this.getSpecials('Save Complete');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  onEditSpecial = record => {
    this.handleAddBreadCrumb({ method: 'handleClose', crumb: 'Add Special' });
    record.items.push({
      _id: '',
      name: undefined,
      price: null
    });
    this.setState({
      main: false,
      _id: record._id,
      type: record.type,
      startdate: record.startdate,
      starttime: record.starttime,
      enddate: record.enddate,
      endtime: record.endtime,
      time: record.time,
      count: record.count,
      items: record.items,
      catSelect: { value: undefined, key: 0, perc: undefined },
      active: record.active,
      specialName: record.name
    });
  };

  onChangeDateStart = (date, dateString) => {
    this.setState({
      startdate: moment(dateString, normalDateFormat).format(dateFormatQuery)
    });
  };

  onChangeDateEnd = (date, dateString) => {
    this.setState({
      enddate: moment(dateString, normalDateFormat).format(dateFormatQuery)
    });
  };

  onChangeTimeStart = (date, dateString) => {
    this.setState({
      starttime: moment(dateString, normalTimeFormat).format(dateFormatQuerTime)
    });
  };

  onChangeTimeEnd = (date, dateString) => {
    this.setState({
      endtime: moment(dateString, normalTimeFormat).format(dateFormatQuerTime)
    });
  };

  onChangeEvt(e) {
    if (e.target.id === 'name') {
      if (e.currentTarget.value.length <= 30) {
        this.setState({ specialName: e.currentTarget.value });
      }
    }
  }

  handleChangeType = (event, key) => {
    if (event.target.name === 'typeSpecial') {
      var start = moment().format(dateFormatQuery);
      var end = moment().format(dateFormatQuery);
      var startTime = moment().format(dateFormatQuerTime);
      var endTime = moment().format(dateFormatQuerTime);
      if (event.target.value === 'day') {
        start = 0;
        end = 0;
      } else if (event.target.value === 'week') {
        start = moment().day();
        end = moment().day();
      }
      this.setState({
        type: event.target.value,
        startdate: start,
        enddate: end,
        starttime: startTime,
        endtime: endTime
      });
    }
  };

  handleChangeDateStart = value => {
    this.setState({
      startdate: parseInt(value)
    });
  };

  handleChangeDateEnd = value => {
    this.setState({
      enddate: parseInt(value)
    });
  };

  handleChangeSelect = (value, event, key) => {
    if (value) {
      var array = this.state.items;
      array[key]._id = value;
      array[key].name = event.props.children;
      var newItem = {
        _id: '',
        name: undefined,
        price: null
      };
      if (!array[key + 1]) {
        array.push(newItem);
      }
      this.setState({
        items: array
      });
    }
  };

  handleChangeSelectCat = (value, nkey) => {
    if (value) {
      let catSelect = this.state.catSelect;
      catSelect.value = value;
      catSelect.key = nkey;
      catSelect.perc = undefined;
      this.setState({
        catSelect: catSelect
      });
    }
  };

  handleChangePercCat = ne => {
    let value = ne.currentTarget.value;
    if (!isNaN(value)) {
      let catSelect = this.state.catSelect;
      value = (Math.round(parseFloat(value.toString()) * 100) / 100).toString();
      catSelect.perc = parseFloat(value);
      this.setState({
        catSelect: catSelect
      });
    } else {
      let catSelect = this.state.catSelect;
      catSelect.perc = 0;
      this.setState({
        catSelect: catSelect
      });
    }
  };

  handleChangeAddCat = () => {
    let value = this.state.catSelect.value;
    let key = this.state.catSelect.key;
    let perc = this.state.catSelect.perc;
    if (value) {
      this.setState({ loading: true });
      var array = this.state.items;
      var arrayItems = this.state.catObject[value].items;
      if (arrayItems.length) {
        for (var i = 0; i < arrayItems.length; i++) {
          let percPrice = 0;
          if (perc && arrayItems[i].sellPrice) {
            percPrice =
              arrayItems[i].sellPrice - (arrayItems[i].sellPrice / 100) * perc;
          }
          if (i === 0) {
            array[key]._id = arrayItems[i]._id;
            array[key].name = arrayItems[i].name;
            if (percPrice) {
              array[key].price = parseFloat(percPrice.toFixed(2));
            }
          } else {
            var newItem = { _id: arrayItems[i]._id, name: arrayItems[i].name };
            if (percPrice) {
              newItem.price = parseFloat(percPrice.toFixed(2));
            }
            array.push(newItem);
          }
        }
        var newItem = {
          _id: '',
          name: undefined,
          price: null
        };
        array.push(newItem);
        this.setState({
          items: array,
          catSelect: { value: undefined, key: 0, perc: undefined },
          loading: false
        });
      }
    }
  };

  onChangeEvtMenuPrice = (event, key) => {
    var array = this.state.items;
    var item = array[key];
    var check = false;
    var value = formaterCheck(event.currentTarget.value || '0');
    if (value >= 0) {
      item.price = value;
      array[key] = item;
      this.setState({
        items: array
      });
    }
  };

  handleRemoveMenuItem = key => {
    var array = this.state.items;
    array.splice(key, 1);
    this.setState({
      items: array
    });
  };

  handleClose = () => {
    this.handleRemoveBreadCrumb();
    this.getSpecials();
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Special Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        description={false}
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />
      <button
        style={{
          backgroundColor: '#00CC70',
          border: '0px solid grey',
          color: 'white',
          width: '10em',
          height: '3em',
          fontSize: '15px',
          fontWeight: 700,
          fontFamily: 'Mulish , sans-serif',
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => this.addSpecial()}
        color="primary"
      >
        {'Add Special'}
      </button>
    </div>
  );
  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker,
      handleRemoveMenuItem: this.handleRemoveMenuItem,
      onChangeEvtMenuPrice: this.onChangeEvtMenuPrice,
      handleChangeSelect: this.handleChangeSelect,
      handleChangeSelectCat: this.handleChangeSelectCat,
      handleChangePercCat: this.handleChangePercCat,
      handleChangeAddCat: this.handleChangeAddCat
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px rgb(30, 161, 169)',

            color: 'white',

            height: '100%',
            borderRadius: '0.3em',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(30, 161, 169)',

            color: 'white',

            height: '100%',
            borderRadius: '0.3em',

            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(['true'])}
        >
          Active
        </Button>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(['false'])}
        >
          Inactive
        </Button>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(false)}
        >
          All
        </Button>
      </div>
    );
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />

        {this.state.main ? (
          <div>
            <Button
              style={{
                position: 'fixed',
                right: '-0.6em',
                bottom: '5em',
                background: 'rgb(0, 204, 112)',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em 0em 0em 5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.addSpecial()}
              color="primary"
            >
              <Icon
                type="plus"
                style={{
                  position: 'absolute',
                  paddingLeft: 3
                }}
              />
            </Button>
            {this.state.specials.length > 0 ? (
              <Row>
                {this.props.local.specialHelpCard ? (
                  <StyledCard
                    //title="Default size card"
                    //extra={<a href="#">More</a>}
                    style={{
                      padding: '30px',
                      marginTop: '0em',
                      marginBottom: '-2.5em'
                    }}
                  >
                    <Row>
                      <Col span={15}>
                        <h2>Creating Product Specials</h2>
                        <p style={{ paddingRight: '3em' }}>
                          Add Item or Category timed specials that automatically
                          display on set times that put you in full control with
                          the "Add Special" panel.
                        </p>
                      </Col>
                      <Col span={7} style={{ textAlign: 'right' }}>
                        <br />
                        <h3>Need Help ?</h3>
                        <p
                          onClick={e => {
                            window.Tawk_API.toggleVisibility();
                            window.Tawk_API.toggle();
                          }}
                          style={{
                            color: 'rgba(0, 204, 112, 0.898)',
                            fontWeight: 500,
                            cursor: 'pointer'
                          }}
                        >
                          Ask a VPOS Product Specialist.
                        </p>
                      </Col>
                      <Col span={2} />
                      <Icon
                        onClick={() => this.onCloseCard()}
                        style={{ position: 'absolute', right: 0, top: 0 }}
                        type="close"
                      />
                    </Row>
                    <button
                      style={{
                        display: this.state.mainInput ? 'none' : 'block',
                        marginTop: '1em',
                        backgroundColor: '#00CC70',
                        border: '0px solid grey',
                        color: 'white',
                        width: '10em',
                        height: '3em',
                        fontSize: '15px',
                        fontWeight: 700,
                        fontFamily: 'Mulish , sans-serif',
                        //fontWeight: 600,
                        borderRadius: '3em',
                        zIndex: 1
                      }}
                      onClick={() => this.addSpecial()}
                      color="primary"
                    >
                      {'Add Special'}
                    </button>
                  </StyledCard>
                ) : (
                  <button
                    style={{
                      backgroundColor: '#00CC70',
                      border: '0px solid grey',
                      color: 'white',
                      width: '9em',
                      height: '3em',
                      marginTop: '-0.4em',
                      fontSize: '15px',
                      fontWeight: 700,
                      fontFamily: 'Mulish , sans-serif',
                      borderRadius: '3em',
                      zIndex: 1,
                      position: 'absolute'
                    }}
                    onClick={() => this.addSpecial()}
                    color="primary"
                  >
                    {'Add Special'}
                  </button>
                )}
              </Row>
            ) : null}

            <Row>
              <div
                style={{
                  visibility: 'hidden',
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                <Popover
                  placement="left"
                  // content={EyeContent}
                  title=""
                  trigger="click"
                >
                  <Button
                    style={{
                      backgroundColor: 'rgb(30, 161, 169)',
                      border: '1px solid rgb(30, 161, 169)',
                      color: 'white',
                      marginBottom: '-0.5em',
                      height: '3.5em',
                      marginLeft: '-0.27em',
                      borderRadius: '3em',

                      width: '2em'
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      type="eye"
                    />
                  </Button>
                </Popover>
                <Popover
                  placement="bottomRight"
                  content={GearContent}
                  title=""
                  trigger="click"
                >
                  <Button
                    style={{
                      display: 'none',
                      backgroundColor: 'white',
                      border: '1px solid rgb(15, 135, 123)',
                      color: 'rgb(15, 135, 123)',
                      marginBottom: '-0.5em',
                      height: '3.5em',
                      marginLeft: '-0.27em',
                      borderRadius: '0.3em',
                      transform: 'scale(0.9)',
                      width: '2em'
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      type="setting"
                    />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Row>
              {' '}
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={this.state.specials}
                  onChange={this.handleSort}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onRow={(record, rowIndex) => {
                    if (!record.active) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          this.onEditSpecial(record);
                        },
                        style: {
                          background: 'rgba(255, 4, 4, 0.063)',
                          color: 'red'
                        }
                      };
                    } else if (rowIndex % 2 == 0) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          this.onEditSpecial(record);
                        },
                        style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          this.onEditSpecial(record);
                        }
                      };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getActiveSearchProps('type')}
                    title="Type"
                    render={(text, record) => {
                      return <span>{typeFormat[record.type]}</span>;
                    }}
                    dataIndex="type"
                    key="type"
                  />
                  <Column
                    {...this.getActiveSearchProps('startdate')}
                    title="Start"
                    render={(text, record) => {
                      return (
                        <span>
                          {(record.type === 'onceoff'
                            ? moment(record.startdate, dateFormatQuery).format(
                                normalDateFormat
                              )
                            : record.type === 'week'
                            ? weekDays[record.startdate]
                            : '') +
                            ' ' +
                            moment(record.starttime, dateFormatQuerTime).format(
                              normalTimeFormat
                            )}
                        </span>
                      );
                    }}
                    dataIndex="startdate"
                    key="startdate"
                  />
                  <Column
                    {...this.getActiveSearchProps('enddate')}
                    title="End"
                    render={(text, record) => {
                      return (
                        <span>
                          {(record.type === 'onceoff'
                            ? moment(record.enddate, dateFormatQuery).format(
                                normalDateFormat
                              )
                            : record.type === 'week'
                            ? weekDays[record.enddate]
                            : '') +
                            ' ' +
                            moment(record.endtime, dateFormatQuerTime).format(
                              normalTimeFormat
                            )}
                        </span>
                      );
                    }}
                    dataIndex="enddate"
                    key="enddate"
                  />
                  <Column title="Items" dataIndex="count" key="items" />
                  <Column
                    {...this.getActiveSearchProps('active')}
                    title="Active"
                    dataIndex="active"
                    render={(text, record) => {
                      return (
                        <Switch
                          style={{ marginRight: '1em' }}
                          checked={record.active}
                          onClick={(a, e) => {
                            e.stopPropagation();
                          }}
                          onChange={e => {
                            this.updateSpecialActive(record);
                          }}
                        />
                      );
                    }}
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div>
                        <IconButton
                          style={{
                            width: '3em',
                            padding: '0.35em',
                            borderRadius: '3em',
                            backgroundColor: 'rgb(0, 204, 112, 0.9)',
                            border: '0px solid rgb(0, 204, 112)'
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            this.onEditSpecial(record);
                          }}
                        >
                          {
                            <p
                              style={{
                                fontSize: 13,
                                color: 'white',
                                fontWeight: 700,
                                fontFamily: 'Mulish , sans-serif',
                                margin: 'auto'
                              }}
                            >
                              {/*<FiEdit
                            style={{
                              fontSize: 12,
                              color: "white",
                              margin: "auto"
                            }}
                          />*/}
                              Edit
                            </p>
                          }
                        </IconButton>
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : (
          <div>
            <Card>
              <CardBody style={{ marginTop: '-2em' }}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Name*"
                      id="name"
                      autoFocus={!this.state.specialName ? true : false}
                      error={
                        !this.state.specialName && this.state.submitInProgress
                      }
                      errorText={'Special name required'}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.onChangeEvt(e),
                        value: this.state.specialName,
                        onClick: () => this.onHelpMarker('usersname')
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomSelect
                      labelText="Type*"
                      id="typeSpecial"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onClick: () => this.onHelpMarker('menuitemstype'),
                        onChange: (event, key) =>
                          this.handleChangeType(event, key),
                        value: this.state.type
                      }}
                      menuItems={types}
                    />
                  </GridItem>
                </GridContainer>
                {this.state.type !== 'week' ? (
                  <div>
                    <h4 style={{ color: 'black' }}> Start </h4>
                    <GridContainer>
                      {this.state.type === 'onceoff' ||
                      this.state.type === 'month' ? (
                        <GridItem
                          xs={12}
                          sm={12}
                          md={3}
                          style={{ marginBottom: '0.5em' }}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            value={moment(
                              this.state.startdate,
                              dateFormatQuery
                            )}
                            format={normalDateFormat}
                            onChange={this.onChangeDateStart}
                            allowClear={false}
                          />
                        </GridItem>
                      ) : null}
                      <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ marginBottom: '0.5em' }}
                      >
                        <TimePicker
                          style={{ width: '100%' }}
                          value={moment(
                            this.state.starttime,
                            dateFormatQuerTime
                          )}
                          format={normalTimeFormat}
                          onChange={this.onChangeTimeStart}
                          allowClear={false}
                        />
                      </GridItem>
                    </GridContainer>
                    <span>
                      {' '}
                      <h4 style={{ color: 'black' }}> End </h4>
                    </span>
                    <GridContainer>
                      {this.state.type === 'onceoff' ||
                      this.state.type === 'month' ? (
                        <GridItem
                          xs={12}
                          sm={12}
                          md={3}
                          style={{ marginBottom: '0.5em' }}
                        >
                          {this.state.type === 'onceoff' ||
                          this.state.type === 'month' ? (
                            <DatePicker
                              style={{ width: '100%' }}
                              value={moment(
                                this.state.enddate,
                                dateFormatQuery
                              )}
                              format={normalDateFormat}
                              onChange={this.onChangeDateEnd}
                              allowClear={false}
                            />
                          ) : this.state.type === 'week' ? (
                            <CustomSelectAuto
                              selectedItem={this.state.enddate.toString()}
                              onChange={this.handleChangeDateEnd}
                              labelText="Select Day"
                              id="daySpecialsart"
                              items={weekDaysList}
                              formControlProps={{
                                style: { padding: 0, 'margin-top': '-15px' },
                                fullWidth: true,
                                onClick: () =>
                                  this.onHelpMarker('menuitemsstockitem')
                              }}
                            />
                          ) : null}
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={3} style={{}}>
                        <TimePicker
                          style={{ width: '100%' }}
                          value={moment(this.state.endtime, dateFormatQuerTime)}
                          format={normalTimeFormat}
                          onChange={this.onChangeTimeEnd}
                          allowClear={false}
                        />
                      </GridItem>
                    </GridContainer>
                  </div>
                ) : (
                  <div>
                    <h3 style={{ color: 'black' }}> Dates</h3>
                    <h4 style={{ color: 'black' }}> Start Date</h4>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                      >
                        <CustomSelectAuto
                          selectedItem={this.state.startdate.toString()}
                          onChange={this.handleChangeDateStart}
                          labelText="Select Day"
                          id="daySpecialsart"
                          items={weekDaysList}
                          formControlProps={{
                            style: { padding: 0, 'margin-top': '-15px' },
                            fullWidth: true,
                            onClick: () =>
                              this.onHelpMarker('menuitemsstockitem')
                          }}
                        />
                      </GridItem>

                      <span>
                        {' '}
                        <h4
                          style={{
                            color: 'black',
                            position: 'absolute',
                            marginTop: '-2em',
                            marginLeft: '1em'
                          }}
                        >
                          {' '}
                          End Date
                        </h4>
                      </span>

                      <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                      >
                        <CustomSelectAuto
                          selectedItem={this.state.enddate.toString()}
                          onChange={this.handleChangeDateEnd}
                          labelText="Select Day"
                          id="daySpecialsart"
                          items={weekDaysList}
                          formControlProps={{
                            style: { padding: 0, 'margin-top': '-15px' },
                            fullWidth: true,
                            onClick: () =>
                              this.onHelpMarker('menuitemsstockitem')
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <h3 style={{ color: 'black', marginTop: '5px' }}> Times</h3>
                    <h4 style={{ color: 'black' }}> Start Time</h4>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ marginBottom: '0.5em' }}
                      >
                        <TimePicker
                          style={{ width: '100%' }}
                          value={moment(
                            this.state.starttime,
                            dateFormatQuerTime
                          )}
                          format={normalTimeFormat}
                          onChange={this.onChangeTimeStart}
                          allowClear={false}
                        />
                      </GridItem>

                      <span>
                        {' '}
                        <h4
                          style={{
                            color: 'black',
                            position: 'absolute',
                            marginTop: '-2em',
                            marginLeft: '1em'
                          }}
                        >
                          {' '}
                          End Time
                        </h4>
                      </span>

                      <GridItem xs={12} sm={12} md={3} style={{}}>
                        <TimePicker
                          style={{ width: '100%' }}
                          value={moment(this.state.endtime, dateFormatQuerTime)}
                          format={normalTimeFormat}
                          onChange={this.onChangeTimeEnd}
                          allowClear={false}
                        />
                      </GridItem>
                    </GridContainer>
                  </div>
                )}
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginTop: '0.5em', marginBottom: '1em' }}
                  >
                    <Button
                      onClick={() => this.handleClose()}
                      color="primary"
                      style={{
                        fontWeight: 700,
                        fontFamily: 'Mulish , sans-serif',
                        fontSize: '13px',
                        textTransform: 'initial',
                        borderRadius: '3em',
                        border: '1px solid rgba(229, 233, 242, 0.9)',
                        color: 'black',
                        backgroundColor: 'rgba(229, 233, 242, 0.9)',
                        boxShadow: 'none'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => this.updateSpecial()}
                      color="primary"
                      style={{
                        fontWeight: 700,
                        fontFamily: 'Mulish , sans-serif',
                        fontSize: '13px',
                        marginLeft: '0.8em',
                        textTransform: 'initial',
                        borderRadius: '3em',
                        border: '1px solid #00CC70',
                        color: 'white',
                        backgroundColor: '#00CC70',
                        boxShadow: 'none'
                      }}
                    >
                      Save
                    </Button>
                  </GridItem>
                </GridContainer>
                <SpecialTable {...oState} />
                <CardFooter style={{ display: 'block' }} />
              </CardBody>
              <Button
                style={{
                  position: 'fixed',
                  right: '-0.6em',
                  bottom: '5em',
                  background: 'rgb(0, 204, 112)',
                  border: '1px solid transparent',
                  color: 'white',
                  padding: '0em',
                  width: '3.5em',
                  height: '3.5em',
                  borderRadius: '5em 0em 0em 5em',
                  fontSize: '1em',
                  zIndex: 1000
                }}
                onClick={() => this.updateSpecial()}
                color="primary"
              >
                <Icon
                  style={{
                    color: 'white',
                    position: 'absolute',
                    paddingLeft: 3
                  }}
                  type="check"
                />
              </Button>
            </Card>
          </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addSpecials,
  editSpecials,
  getSpecials,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  closeSpecialHelpCard
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Specials));
