import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ConfigProvider,
  Empty,
  Icon,
  Input,
  Popover,
  Radio,
  Row,
  Spin,
  Table,
  Tabs
} from 'antd';
import convert from 'convert-units';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  addSupplier,
  deleteSupplier,
  editSupplier,
  getSupplierItems,
  getSuppliers
} from 'src/redux/actions/backend';
import {
  resetActionBreadCrumb,
  setBreadCrumbs
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import SuppliersForm from 'src/views/Admin/Suppliers/SuppliersForm';
import styled from 'styled-components';
const { Column } = Table;
const { TabPane } = Tabs;
const StyledTabs = styled(Tabs)`
  overflow: visble !important;
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 15px;
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    margin: 7px 0px 0 0;
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-nav-scroll {
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .ant-tabs-bar {
    padding-top: 0.3em;
    border-bottom: 0px solid #e8e8e8;
    margin: 0 0px 10px 0 !important;
    outline: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;
const StyledTabsMain = styled(Tabs)`
  overflow: visble !important;
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: blue !important;
    border: 2px solid rgba(0, 204, 112, 0.898);
    color: rgba(0, 204, 112, 0.898);
  }
  .ant-tabs-bar {
    border-bottom: 0px solid #e8e8e8;
    margin: 0 0px 10px 0 !important;
    outline: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-tab {
    width: auto !important;
    font-size: 16px !important;
    margin-left: 0.5em !important;
    margin-top: 0.5em !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    font-weight: 400 !important;
    color: rgb(19, 36, 64) !important;
    background: transparent !important;
    border: 0px solid #00cc70 !important;
  }
  .ant-tabs-tab-active {
    width: auto !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    color: #00cc70 !important;
  }
`;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    border: '1px solid grey',
    color: 'grey',

    borderRadius: '0.2em'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  }
});

const dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: []
};
const supplierObject = {
  name: '',
  contact: '',
  email: '',
  number: '',
  website: '',
  address1: '',
  address2: '',
  city: '',
  postal: '',
  province: '',
  country: '',
  note: '',
  submitInProgress: false
};
class Suppliers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ...supplierObject,
      main: true,
      mainSub: false,
      suppliers: [],
      types: [],
      dimtypes: [],
      loading: true,
      userlisthead: 'Add Supplier',
      userlistsub: 'Add a New Supplier',
      userlistbutton: 'ADD SUPPLIER',
      alert: false,
      msg: [],
      head: '',
      activeFilter: true,
      bankDetails: false
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.getSuppliers();
  }

  handleOpen = open => {
    if (open) {
      this.setState({
        main: true
      });
    } else {
      this.handleAddBreadCrumb({
        method: 'handleOpenTable',
        crumb: 'Supplier'
      });
      this.setState({
        main: false,
        _id: '',
        ...supplierObject,
        supplierStock: [],
        userlisthead: 'Add Supplier',
        userlistsub: 'Add a New Supplier',
        userlistbutton: 'ADD SUPPLIER',
        pageSize: 25
      });
    }
  };

  handleOpenTable = open => {
    if (open) {
      this.setState({
        main: false
      });
    } else {
      this.handleRemoveBreadCrumb();
      this.setState({
        main: true
      });
    }
  };

  handleChange = event => {
    if (event.target.name === 'Type') {
      this.setState({ type: event.target.value });
    }
    if (event.target.name === 'dimension') {
      this.setState({
        dimension: event.target.value,
        dimtypes: dimSelectTypes[event.target.value]
      });
    }
    if (event.target.name === 'dimentiontype') {
      this.setState({ dimType: event.target.value });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Suppliers Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onComplete = res => {
    this.setState({
      _id: '',
      ...supplierObject,
      main: true
    });
    this.getSuppliers('Supplier Creation Successful');
  };

  submitForm = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true, submitInProgress: true });
    if (oEvent.currentTarget.innerText === 'ADD SUPPLIER') {
      this.props
        .addSupplier(this.state, token)
        .then(res => {
          this.handleRemoveBreadCrumb();
          this.onComplete(res);
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    } else if (oEvent.currentTarget.innerText === 'EDIT SUPPLIER') {
      this.props
        .editSupplier(this.state, token)
        .then(res => {
          this.handleRemoveBreadCrumb();
          this.setState({
            main: true,
            _id: '',
            ...supplierObject,
            userlisthead: 'Add Supplier',
            userlistsub: 'Add a New Supplier',
            userlistbutton: 'ADD SUPPLIER'
          });
          this.getSuppliers('Supplier updated successful');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  getSuppliers = msg => {
    const token = this.props.local.user.token;
    this.props
      .getSuppliers(token)
      .then(result => {
        this.setState({
          suppliers: result.data.results,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onChangeEvt = e => {
    let target = this.state;
    target[e.target.id] = e.currentTarget.value;
    this.setState(target);
  };

  handleEditSupplier = oSupplier => {
    this.handleAddBreadCrumb({ method: 'handleOpenTable', crumb: 'Supplier' });
    let tempMail = '';
    if (Array.isArray(oSupplier.email)) {
      for (var i = 0; i < oSupplier.email.length; i++) {
        tempMail = tempMail + oSupplier.email[i] + ';';
      }
      oSupplier.email = tempMail;
    }
    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });
    this.props
      .getSupplierItems(token)
      .then(result => {
        let supplierStock = [];
        if (result.data.results.supstock[oSupplier._id]) {
          supplierStock =
            result.data.results.supstock[oSupplier._id].stock || [];
        }
        this.setState({
          userlisthead: 'Edit Supplier',
          userlistsub: 'Edit a Supplier',
          userlistbutton: 'EDIT SUPPLIER',
          supplierStock: supplierStock,
          loading: false,
          main: false,
          pageSize: 25,
          ...oSupplier
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleDeleteSupplier = oMenu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteSupplier(oMenu, token)
      .then(res => {
        this.getSuppliers('Supplier Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleRestoreSupplier = oMenu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteSupplier(oMenu, token)
      .then(res => {
        this.getSuppliers('Supplier Restored Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };
  handleFormatListQty = () => {
    this.setState({ activeFilterQty: true, activeFilter: ['true'] });
  };
  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps2 = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (value) {
        if (
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  switchBankDetails = () => {
    this.setState({ bankDetails: !this.state.bankDetails });
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />

      <button
        style={{
          backgroundColor: '#00CC70',
          border: '0px solid grey',
          color: 'white',
          width: '9.5em',
          height: '3em',
          fontSize: '15px',
          fontWeight: 700,
          fontFamily: 'Mulish , sans-serif',
          borderRadius: '3em',
          zIndex: 1,
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => this.handleOpen(!this.state.main)}
        color="primary"
      >
        {'Add Supplier'}
      </button>
    </div>
  );
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditSupplier: this.handleEditSupplier,
      handleDeleteSupplier: this.handleDeleteSupplier,
      handleRestoreSupplier: this.handleRestoreSupplier,
      onChangeEvt: this.onChangeEvt,
      handleChange: this.handleChange,
      submitForm: this.submitForm,
      handleOpenStock: this.handleOpenTable,
      onHelpMarker: this.onHelpMarker,
      switchBankDetails: this.switchBankDetails
    };
    const EyeContent = (
      <div style={{ width: '8em' }}>
        <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
          <Radio
            value={1}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(true)}
          >
            Active
          </Radio>
          <Radio
            value={2}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(false)}
          >
            Inactive
          </Radio>
          <Radio
            value={3}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(undefined)}
          >
            All
          </Radio>
        </Radio.Group>
      </div>
    );
    let suppliers = [];
    for (var i = 0; i < this.state.suppliers.length; i++) {
      if (this.state.activeFilter === true) {
        if (this.state.suppliers[i].active) {
          suppliers.push(this.state.suppliers[i]);
        }
      } else if (this.state.activeFilter === false) {
        if (!this.state.suppliers[i].active) {
          suppliers.push(this.state.suppliers[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        suppliers.push(this.state.suppliers[i]);
      }
    }
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.main ? (
          <div>
            {' '}
            <Button
              style={{
                position: 'fixed',
                right: '-0.6em',
                bottom: '5em',
                background: 'rgb(0, 204, 112)',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em 0em 0em 5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.handleOpen(!this.state.main)}
              color="primary"
            >
              <h1
                style={{
                  fontSize: '3em',
                  marginTop: '0.5em',
                  color: 'white'
                }}
              >
                +
              </h1>
            </Button>
            {this.state.suppliers.length > 0 ? (
              <Row>
                <button
                  style={{
                    marginTop: '-0.5em',
                    marginBottom: '0.5em',
                    backgroundColor: '#00CC70',
                    border: '0px solid grey',
                    color: 'white',
                    width: '10em',
                    height: '3em',
                    fontSize: '15px',
                    fontWeight: 700,
                    fontFamily: 'Mulish , sans-serif',
                    //fontWeight: 600,
                    borderRadius: '3em',
                    zIndex: 1
                  }}
                  onClick={() => this.handleOpen(!this.state.main)}
                  color="primary"
                >
                  {'Add Supplier'}
                </button>
              </Row>
            ) : null}
            <Row>
              <div
                style={{
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                <Popover
                  overlayStyle={{ borderRadius: '3em' }}
                  placement="left"
                  content={EyeContent}
                  title="Table settings"
                  trigger="click"
                >
                  <Button
                    style={{
                      position: 'fixed',
                      right: '-0.6em',
                      bottom: '9em',
                      background: 'rgba(229, 233, 242, 1)',
                      border: '1px solid transparent',
                      color: 'rgba(0, 0, 0, 0.54)',
                      padding: '0em',
                      width: '3.5em',
                      height: '3.5em',
                      boxShadow: 'none',
                      borderRadius: '5em 0em 0em 5em',
                      fontSize: '1em',
                      zIndex: 1000
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      theme="filled"
                      type="setting"
                    />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Row>
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={suppliers}
                  pagination={{
                    locale: { items_per_page: ' Items' },
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (rowIndex % 2 === 0) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.handleEditSupplier(record);
                        },
                        style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.handleEditSupplier(record);
                        }
                      };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Supplier Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getColumnSearchProps('contact')}
                    title="Contact"
                    dataIndex="contact"
                    key="contact"
                  />
                  <Column
                    {...this.getColumnSearchProps('email')}
                    title="Email"
                    dataIndex="email"
                    key="email"
                  />
                  <Column
                    {...this.getColumnSearchProps('number')}
                    title="Phone Number"
                    dataIndex="number"
                    key="number"
                  />
                  <Column
                    align="center"
                    title="Linked Items"
                    dataIndex="stockCount"
                    key="stockCount"
                  />
                  {/* <Column
                  {...this.getColumnSearchProps('website')}
                  title="Website"
                  dataIndex="website"
                  key="website"
                /> */}
                  <Column
                    {...this.getColumnSearchProps('note')}
                    title="Note"
                    dataIndex="note"
                    key="note"
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div>
                        <IconButton
                          style={{
                            width: '3em',
                            padding: '0.35em',
                            borderRadius: '3em',
                            backgroundColor: 'rgb(0, 204, 112)',
                            border: '0px solid rgb(0, 204, 112)'
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            oState.handleEditSupplier(record);
                          }}
                        >
                          {
                            <p
                              style={{
                                fontSize: 13,
                                color: 'white',
                                fontWeight: 700,
                                fontFamily: 'Mulish , sans-serif',
                                margin: 'auto'
                              }}
                            >
                              {/*<FiEdit
                          style={{
                            fontSize: 12,
                            color: "white",
                            margin: "auto"
                          }}
                        />*/}
                              Edit
                            </p>
                          }
                        </IconButton>
                        {record.active ? (
                          <IconButton
                            style={{
                              marginRight: '0em',
                              marginLeft: '0.4em',
                              borderRadius: '4em',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '1px solid rgba(229, 233, 242, 1)'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              oState.handleDeleteSupplier(record);
                            }}
                          >
                            {
                              <Icon
                                type="delete"
                                style={{
                                  fontSize: 15
                                }}
                              />
                            }
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{
                              border: '1px solid green',
                              marginLeft: '1em',
                              padding: '16px'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              oState.handleDeleteSupplier(record);
                            }}
                          >
                            {
                              <Icon
                                type="rollback"
                                style={{
                                  position: 'absolute',
                                  fontSize: '15px',
                                  color: 'green'
                                }}
                              />
                            }
                          </IconButton>
                        )}
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : (
          <StyledTabsMain defaultActiveKey="1" type="card">
            <TabPane
              tab={<p style={{ fontWeight: 600 }}>Supplier Details</p>}
              key="1"
            >
              <SuppliersForm {...oState} />
            </TabPane>
            <TabPane
              tab={<p style={{ fontWeight: 600 }}>Supplier Stock</p>}
              key="2"
            >
              <StyledTable
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                pagination={{
                  locale: { items_per_page: ' Items' },
                  showSizeChanger: true,
                  onShowSizeChange: this.onShowSizeChange,
                  pageSizeOptions: ['10', '25', '50', '100'],
                  hideOnSinglePage: true,
                  pageSize: this.state.pageSize || 25,
                  itemRender: (current, type, originalElement) => {
                    if (type === 'prev') {
                      return <a>Previous</a>;
                    }
                    if (type === 'next') {
                      return <a>Next</a>;
                    }
                    return originalElement;
                  },
                  showTotal: (total, range) =>
                    `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                  defaultCurrent: 1
                }}
                dataSource={this.state.supplierStock}
                onChange={this.handleSort}
                onRow={(record, rowIndex) => {
                  if (!record.active) {
                    if (!this.state.activeFilterQty) {
                      return {
                        style: {
                          background: 'rgba(255, 4, 4, 0.063)',
                          color: 'red'
                        }
                      };
                    }
                  }
                  if (this.state.activeFilterQty) {
                    if (record.active && record.qty <= record.lowqty) {
                      if (record.qty <= 0) {
                        return {
                          style: {
                            background: 'rgba(255, 4, 4, 0.063)',
                            color: 'red'
                          }
                        };
                      } else {
                        return {
                          style: { background: 'lightyellow' }
                        };
                      }
                    }
                  }
                  if (record.qty <= record.lowqty) {
                    if (record.qty <= 0) {
                      return {
                        style: {
                          background: 'rgba(255, 4, 4, 0.063)',
                          color: 'red'
                        }
                      };
                    } else {
                      return {
                        style: { background: 'lightyellow' }
                      };
                    }
                  } else if (rowIndex % 2 == 0) {
                    return {
                      style: { background: 'rgb(247, 247, 247)' }
                    };
                  }
                }}
              >
                <Column
                  {...this.getColumnSearchProps2('name')}
                  sorter={(a, b) => a.name.localeCompare(b.name)}
                  sortDirections={['descend', 'ascend']}
                  filteredValue={[this.state.searchText]}
                  title="Name"
                  dataIndex="name"
                  key="name"
                />
                <Column
                  filters={this.state.itemFilters}
                  filteredValue={
                    this.state.filteredInfo
                      ? this.state.filteredInfo.type
                      : null
                  }
                  onFilter={(value, record) => record.type.includes(value)}
                  sorter={(a, b) => a.type.localeCompare(b.type)}
                  sortDirections={['descend', 'ascend']}
                  title="Type"
                  dataIndex="type"
                  key="type"
                />
                <Column
                  {...this.getColumnSearchProps2('buyPrice')}
                  title="Buy Price"
                  render={(text, record) => (
                    <span>
                      {record.buyPrice && record.packSize > 1
                        ? 'R ' +
                          (record.buyPrice * record.packSize).toFixed('2')
                        : record.buyPrice
                        ? 'R ' + parseFloat(record.buyPrice).toFixed('2')
                        : 'R 0.00'}
                    </span>
                  )}
                  dataIndex="buyPrice"
                  key="buyPrice"
                />
                <Column
                  {...this.getColumnSearchProps2('qty')}
                  title="In Stock"
                  render={(text, record) => {
                    return (
                      <span>
                        {record.qty === 0
                          ? '0 ' +
                            (record.dimType
                              ? record.dimType
                              : record.dimension === 'unit'
                              ? ' Unit'
                              : record.dimension)
                          : record.dimension === 'unit'
                          ? record.qty > 1
                            ? record.qty + ' Units'
                            : record.qty + ' Unit'
                          : record.packSize > 1 && record.qty > record.packSize
                          ? Math.round((record.qty / record.packSize) * 100) /
                              100 +
                            'x' +
                            record.packSize +
                            record.dimType
                          : record.qty > 999
                          ? (convert(record.qty)
                              .from(record.dimType)
                              .to(dimSelectTypes[record.dimension][0].value)
                              .toFixed('2')
                              .includes('.00')
                              ? convert(record.qty)
                                  .from(record.dimType)
                                  .to(dimSelectTypes[record.dimension][0].value)
                                  .toFixed('0')
                              : convert(record.qty)
                                  .from(record.dimType)
                                  .to(dimSelectTypes[record.dimension][0].value)
                                  .toFixed('2')) +
                            ' ' +
                            dimSelectTypes[record.dimension][0].name
                          : (record.qty.toFixed('2').includes('.00')
                              ? record.qty.toFixed('0')
                              : record.qty.toFixed('2')) +
                            ' ' +
                            (record.dimType
                              ? record.dimType
                              : record.dimension)}
                      </span>
                    );
                  }}
                  dataIndex="qty"
                  key="qty"
                />
                <Column
                  {...this.getColumnSearchProps2('serving')}
                  title="Servings"
                  render={(text, record) => {
                    return (
                      <span>
                        {!record.serving || !record.qty
                          ? '0'
                          : record.dimType && record.servingType
                          ? (
                              convert(record.qty)
                                .from(record.dimType)
                                .to(record.servingType) / record.serving
                            ).toFixed('0')
                          : record.qty / record.serving}
                      </span>
                    );
                  }}
                  dataIndex="serving"
                  key="serving"
                />
                <Column
                  title="Low Stock"
                  render={(text, record) => (
                    <span>
                      {record.lowqty === 0
                        ? '0 ' +
                          (record.dimType ? record.dimType : record.dimension)
                        : record.dimension === 'unit'
                        ? record.lowqty > 1
                          ? record.lowqty + ' Units'
                          : record.lowqty + ' Unit'
                        : record.lowqty > 999
                        ? (convert(record.lowqty)
                            .from(record.dimType)
                            .to(dimSelectTypes[record.dimension][0].value)
                            .toFixed('2')
                            .includes('.00')
                            ? convert(record.lowqty)
                                .from(record.dimType)
                                .to(dimSelectTypes[record.dimension][0].value)
                                .toFixed('0')
                            : convert(record.lowqty)
                                .from(record.dimType)
                                .to(dimSelectTypes[record.dimension][0].value)
                                .toFixed('2')) +
                          ' ' +
                          dimSelectTypes[record.dimension][0].name
                        : (record.lowqty.toFixed('2').includes('.00')
                            ? record.lowqty.toFixed('0')
                            : record.lowqty.toFixed('2')) +
                          ' ' +
                          (record.dimType ? record.dimType : record.dimension)}
                    </span>
                  )}
                />
                <Column
                  title="Last Modified"
                  render={(text, record) => (
                    <span>
                      {record.moddate
                        ? record.moddate.slice(0, 4) +
                          '/' +
                          record.moddate.slice(4, 6) +
                          '/' +
                          record.moddate.slice(6, 8)
                        : 'No Date'}
                    </span>
                  )}
                  sorter={(a, b) =>
                    a.moddate ? a.moddate.localeCompare(b.moddate) : true
                  }
                  sortDirections={['descend', 'ascend']}
                  dataIndex="moddate"
                  key="moddate"
                />
              </StyledTable>
            </TabPane>
          </StyledTabsMain>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  suppliers: state.suppliers,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addSupplier,
  getSuppliers,
  editSupplier,
  deleteSupplier,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  getSupplierItems
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Suppliers));
