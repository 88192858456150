import React from 'react';
import { Provider } from 'react-redux';
import AppLayout from 'src/components/Layout/App';
import configureStore from 'src/redux/store';
import './App.scss';


const { store, persistor } = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <AppLayout reduxStore={persistor}/>
    </Provider>
  );
};

export default App;
