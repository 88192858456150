import { Col, Layout, Row } from 'antd';
import React from 'react';
import {
  AiOutlineCreditCard,
  AiOutlineFileText,
  AiOutlineSearch,
  AiOutlineShoppingCart
} from 'react-icons/ai';
import { GiCash } from 'react-icons/gi';
const { Footer } = Layout;
const PhoneFooter = props => {
  return (
    <Footer
      style={{
        zIndex: 1000,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: 'rgb(19, 36, 70)',
        lineHeight: '40px',
        padding: 0,
        boxShadow: 'black 0px 11px 20px 0'
      }}
    >
      <Row type="flex" justify="space-around" style={{}}>
        <Col span={4} style={{ display: 'flex' }}>
          <div
            onClick={e => {
              if (props.searchActive) {
                props.search();
                props.setSearchActive(false);
              }
              props.navToView('menu');
            }}
            style={{
              pointerEvents:
                props.bills === 0 ||
                props.currentView === 'pay' ||
                props.receiptView
                  ? 'none'
                  : 'all',
              margin: 'auto',
              marginBottom: '-0.2em',
              display: 'grid',
              cursor: 'pointer',
              paddingBottom: '0.3em'
            }}
          >
            <AiOutlineFileText
              style={{
                opacity:
                  props.bills === 0 ||
                  props.currentView === 'pay' ||
                  props.receiptView
                    ? 0.5
                    : 1.0,
                margin: 'auto',
                color: props.currentView === 'menu' ? '#35D752' : 'white',
                fontSize: 20
              }}
            />
            <span
              style={{
                opacity:
                  props.bills === 0 ||
                  props.currentView === 'pay' ||
                  props.receiptView
                    ? 0.5
                    : 1.0,
                marginTop: -11,
                fontSize: 12,
                color: props.currentView === 'menu' ? '#35D752' : 'white'
              }}
            >
              Menu
            </span>
          </div>
        </Col>
        <Col span={4} style={{ display: 'flex' }}>
          <div
            onClick={e => {
              if (props.searchActive) {
                props.search();
                props.setSearchActive(false);
              } else if (props.currentView === 'menu') {
                props.navToView('search');
              }
            }}
            style={{
              pointerEvents:
                props.bills === 0 ||
                props.currentView === 'pay' ||
                props.receiptView
                  ? 'none'
                  : 'all',
              margin: 'auto',
              marginBottom: '-0.2em',
              display: 'grid',
              paddingBottom: '0.3em'
            }}
          >
            <AiOutlineSearch
              style={{
                opacity:
                  props.bills === 0 ||
                  props.currentView === 'pay' ||
                  props.receiptView
                    ? 0.5
                    : 1.0,
                margin: 'auto',
                color: props.searchActive ? '#35D752' : 'white',
                fontSize: 20
              }}
            />
            <span
              style={{
                opacity:
                  props.bills === 0 ||
                  props.currentView === 'pay' ||
                  props.receiptView
                    ? 0.5
                    : 1.0,
                marginTop: -11,
                fontSize: 12,
                color: props.searchActive ? '#35D752' : 'white'
              }}
            >
              Search
            </span>
          </div>
        </Col>
        <Col span={4} style={{ display: 'flex' }}>
          {!props.fabActive && !props.payFabActive ? (
            <div
              onClick={e => {
                if (props.receiptView) {
                } else if (props.currentView === 'pay') {
                  // if (props.paymentType) {
                  props.setPayFabActive(true);
                  setTimeout(() => {
                    props.setPayFabActiveAnimate(true);
                  }, 50);
                  // } else {
                  //   props.snackBar('Select a payment type')
                  // }
                } else if (props.bills > 0) {
                  props.setFabActive(true);
                  setTimeout(() => {
                    props.setFabActiveAnimate(true);
                  }, 50);
                } else {
                  props.snackBar('There are no bills');
                }
              }}
              style={{
                margin: 'auto',
                background: '#00CC70',
                borderRadius: '50%',
                width: 60,
                height: 60,
                minWidth: 60,
                bottom: 29,
                position: 'relative',
                display: 'flex'
              }}
            >
              {props.paymentType === 'cash' ? (
                <GiCash
                  style={{
                    margin: 'auto',
                    color: 'white',
                    fontSize: 25,
                    zIndex: 1001
                  }}
                />
              ) : props.paymentType === 'card' ? (
                <AiOutlineCreditCard
                  style={{
                    margin: 'auto',
                    color: 'white',
                    fontSize: 25,
                    zIndex: 1001
                  }}
                />
              ) : (
                <AiOutlineShoppingCart
                  style={{
                    pointerEvents: props.receiptView ? 'none' : 'all',
                    opacity: props.receiptView ? 0.5 : 1.0,
                    margin: 'auto',
                    color: 'white',
                    fontSize: 30
                  }}
                />
              )}
            </div>
          ) : null}
          {props.bill && props.currentView !== 'pay' && !props.receiptView ? (
            <div
              style={{
                position: 'absolute',
                bottom: -5,
                fontWeight: 700,
                width: '104%',
                whiteSpace: 'nowrap',
                color: 'white',
                textAlign: 'center'
              }}
            >
              <span>
                R{' '}
                {(
                  props.bill.total -
                  props.bill.discount +
                  props.bill.tip
                ).toFixed(2)}
              </span>
            </div>
          ) : null}
        </Col>
        <Col span={4} style={{ display: 'flex' }}>
          {/* <div
            onClick={e => props.navToView('bills')}
            style={{ pointerEvents: props.bills === 0 || props.currentView === "pay" || props.receiptView ? "none" : "all", margin: 'auto', marginBottom: "-0.2em", display: 'grid', paddingBottom: "0.3em" }}
          >
            <AiOutlineFileText
              style={{
                opacity: props.bills === 0 || props.currentView === "pay" || props.receiptView ? 0.5 : 1.0,
                margin: 'auto',
                color:
                  props.currentView === 'bills' ? '#35D752' : 'white',
                fontSize: 20
              }}
            />
            <span
              style={{
                opacity: props.bills === 0 || props.currentView === "pay" || props.receiptView ? 0.5 : 1.0,
                marginTop: -11,
                fontSize: 12,
                color: props.currentView === 'bills' ? '#35D752' : 'white'
              }}
            >
              My Bills
            </span>
          </div>
        */}
        </Col>
        <Col span={4} style={{ display: 'flex' }}>
          {/* <div
            onClick={e => {
                props.onLogout();
            }}
            style={{ pointerEvents: props.currentView === "pay" || props.receiptView ? "none" : "all", margin: 'auto', marginBottom: "-0.2em", display: 'grid', paddingBottom: "0.3em" }}
          >
            <IoIosLogOut
              style={{
                opacity: props.currentView === "pay" || props.receiptView ? 0.5 : 1.0,
                margin: 'auto',
                color: props.currentView === 'profile' ? '#35D752' : 'white',
                fontSize: 20
              }}
            />
            <span
              style={{
                opacity: props.currentView === "pay" || props.receiptView ? 0.5 : 1.0,
                marginTop: -11,
                fontSize: 12,
                color: props.currentView === 'profile' ? '#35D752' : 'white'
              }}
            >
              Logout
            </span>
          </div>
         */}
        </Col>
      </Row>
    </Footer>
  );
};
export default PhoneFooter;
