import React from 'react';
import { Spin, Table, Input, Icon, Row, Col, Popover, Switch, ConfigProvider, Empty, Button } from 'antd';
import { connect } from 'react-redux';
import {
  getEcomCustomers,
  addEcomCustomers,
  editEcomCustomers,
  deleteEcomCustomers
} from 'src/redux/actions/backend';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Highlighter from 'react-highlight-words';
import GridItem from 'src/components/Grid/GridItem.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';

import Card from 'src/components/Card/Card.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { openSnackbar, closeSnackbar } from 'src/redux/actions/snackbar';
import IconButton from '@material-ui/core/IconButton';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import {
  FiEdit
} from 'react-icons/fi';
import { RiDatabase2Line } from "react-icons/ri";
import { setHelpMarker } from 'src/redux/actions/help';

import {
  primaryColor,
  grayColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import Loading from 'react-loading-bar';
import ContentLoader from 'react-content-loader';

const { Column } = Table;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  }
});

const Skeleton = () => (
  <ContentLoader
    speed={0.5}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#c0c0c0"
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="46" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="92" rx="2" ry="2" width="100%" height="45" />
  </ContentLoader>
);

class EcomCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      orgName: this.props.orgData,
      idno: '',
      tabNo: '',
      tab: false,
      tabAmount: 0.0,
      payAmount: 0,
      newTabAmount: 0.0,
      email: '',
      name: '',
      mobile: '',
      active: false,
      customers: [],
      loading: true,
      userlisthead: 'Add Customer',
      userlistsub: 'Add a New Customer',
      userlistbutton: 'Add Customer',
      alert: false,
      msg: [],
      head: '',
      main: true,
      payView: false,
      activeFilter: true,
      submitInProgress: false
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb)
    this.props.setBreadCrumbs(breadCrumbs)
  }
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop()
    this.props.setBreadCrumbs(breadCrumbs)
  }

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false)
      this.props.resetActionBreadCrumb()
      this.handleRemoveBreadCrumb()
    }
  }

  componentDidMount() {
    this.getEcomCustomers();
  }

  getEcomCustomers = msg => {
    const token = this.props.local.user.token;
    this.props
      .getEcomCustomers(token)
      .then(result => {
        this.setState({
          customers: result.data.results,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
        this.handleRemoveBreadCrumb()
      })
      .catch(err => {
        this.onError(err);
      });
  };

  cancelAddCustomer = () => {
    this.handleRemoveBreadCrumb()
    this.setState({ main: true })
  }

  cancelPayCustomer = () => {
    this.handleRemoveBreadCrumb()
    this.setState({ payView: false })
  }

  handleOpen = open => {
    this.handleAddBreadCrumb({ method: "cancelAddCustomer", crumb: "Customer" })
    var name = 'CUST0000';
    name =
      this.state.customers.length > 0
        ? name.slice(0, -(this.state.customers.length + 1).toString().length) +
        (this.state.customers.length + 1).toString()
        : 'CUST0001';
    this.setState({
      _id: '',
      idno: '',
      tabNo: name,
      tab: false,
      tabAmount: 0.0,
      payAmount: 0,
      newTabAmount: 0.0,
      email: '',
      name: '',
      mobile: '',
      active: false,
      userlisthead: 'Add Customer',
      userlistsub: 'Add a New Customer',
      userlistbutton: 'Add Customer',
      main: open,
      submitInProgress: false
    });
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'User Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onComplete = res => {
    this.setState({
      name: '',
      number: '',
      username: '',
      shipAddress1: '',
      shipAddress2: '',
      shipSuburb: '',
      shipCity: '',
      shipProvince: '',
      shipPostal: '',
      billAddress1: '',
      billAddress2: '',
      billSuburb: '',
      billCity: '',
      billProvince: '',
      billPostal: '',
      billSame: false,
      active: false,
      customers: [],
      head: 'Customer Details',
      main: true,
      payView: false,
      submitInProgress: false
    });
    this.getEcomCustomers('Customer Creation Successful');
  };

  submitForm = (oEvent, pay) => {
    const token = this.props.local.user.token;
    this.setState({ loading: true, submitInProgress: true });
    if (oEvent.currentTarget.innerText === 'Add Customer') {
      const data = {
        orgid: this.props.local.user.orgid,
        name: this.state.name,
        number: this.state.number,
        username: this.state.username,
        shipAddress1: this.state.shipAddress1,
        shipAddress2: this.state.shipAddress2,
        shipSuburb: this.state.shipSuburb,
        shipCity: this.state.shipCity,
        shipProvince: this.state.shipProvince,
        shipPostal: this.state.shipPostal,
        billAddress1: this.state.billAddress1,
        billAddress2: this.state.billAddress2,
        billSuburb: this.state.billSuburb,
        billCity: this.state.billCity,
        billProvince: this.state.billProvince,
        billPostal: this.state.billPostal,
        active: true,
        billSame: this.state.billSame
      }
      this.props
        .addEcomCustomers(data, token)
        .then(res => {
          if (res.data === 'username') {
            this.onError('E-mail already exists');
          } else {
            this.onComplete(res);
          }
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    } else if (oEvent.currentTarget.innerText === 'EDIT CUSTOMER') {
      const data = {
        _id: this.state._id,
        orgid: this.props.local.user.orgid,
        name: this.state.name,
        number: this.state.number,
        username: this.state.username,
        shipAddress1: this.state.shipAddress1,
        shipAddress2: this.state.shipAddress2,
        shipSuburb: this.state.shipSuburb,
        shipCity: this.state.shipCity,
        shipProvince: this.state.shipProvince,
        shipPostal: this.state.shipPostal,
        billAddress1: this.state.billAddress1,
        billAddress2: this.state.billAddress2,
        billSuburb: this.state.billSuburb,
        billCity: this.state.billCity,
        billProvince: this.state.billProvince,
        billPostal: this.state.billPostal,
        active: true,
        billSame: this.state.billSame
      }
      this.props
        .editEcomCustomers(data, token)
        .then(res => {
          this.setState({
            _id: '',
            name: '',
            number: '',
            username: '',
            shipAddress1: '',
            shipAddress2: '',
            shipSuburb: '',
            shipCity: '',
            shipProvince: '',
            shipPostal: '',
            billAddress1: '',
            billAddress2: '',
            billSuburb: '',
            billCity: '',
            billProvince: '',
            billPostal: '',
            billSame: false,
            active: false,
            userlisthead: 'Add Customer',
            userlistsub: 'Add a New Customer',
            userlistbutton: 'Add Customer',
            main: true,
            payView: false,
            submitInProgress: false
          });
          this.getEcomCustomers('Customer Update Successful');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  handleEditUser = (oUser, pay) => {
      this.handleAddBreadCrumb({ method: "cancelAddCustomer", crumb: "Customer" })
      this.setState({
        _id: oUser._id,
        name: oUser.name,
        number: oUser.number,
        username: oUser.username,
        shipAddress1: oUser.shipAddress1,
        shipAddress2: oUser.shipAddress2,
        shipSuburb: oUser.shipSuburb,
        shipCity: oUser.shipCity,
        shipProvince: oUser.shipProvince,
        shipPostal: oUser.shipPostal,
        billAddress1: oUser.billAddress1,
        billAddress2: oUser.billAddress2,
        billSuburb: oUser.billSuburb,
        billCity: oUser.billCity,
        billProvince: oUser.billProvince,
        billPostal: oUser.billPostal,
        active: oUser.active,
        userlisthead: 'Edit Customer',
        userlistsub: 'Edit a Customer Details',
        userlistbutton: 'EDIT CUSTOMER',
        main: false,
        payView: false
      });
    
  };

  handleDeleteCustomer = oUser => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteEcomCustomers(oUser, token)
      .then(res => {
        this.getEcomCustomers('Customer Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: "3em",
          marginBottom: "0.5em",
          height: 90,
        }} />

      <button
        style={{
          backgroundColor: '#00CC70',
          border: '0px solid rgb(30,161,169)',
          color: 'white',
          width: '12em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',
          borderRadius: '3em',
          marginTop: "1em",
          marginBottom: "1.5em",

        }}
        onClick={() => this.handleOpen(!this.state.main)}
        color="primary"
      >
        {'Add Customer'}
      </button>
    </div>
  );

  render() {
    const EyeContent = (
      <div>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30,161,169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => {
            this.handleFormatList(true);
          }}
        >
          Active
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30,161,169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(false)}
        >
          Inactive
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30,161,169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList('tab')}
        >
          Tab
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30,161,169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(undefined)}
        >
          All
        </Button>
      </div>
    );
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditUser: this.handleEditUser
    };

    let customers = [];
    let stateEcomCustomers = this.state.customers;
    for (var i = 0; i < stateEcomCustomers.length; i++) {
      if (this.state.activeFilter === true) {
        if (stateEcomCustomers[i].active) {
          customers.push(stateEcomCustomers[i]);
        }
      } else if (this.state.activeFilter === false) {
        if (!stateEcomCustomers[i].active) {
          customers.push(stateEcomCustomers[i]);
        }
      } else if (this.state.activeFilter === 'tab') {
        if (stateEcomCustomers[i].tab) {
          customers.push(stateEcomCustomers[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        customers.push(stateEcomCustomers[i]);
      }
    }

    return (
      <div>
        <Loading
          show={this.state.loading}
          showSpinner={false}
          color="rgb(15, 135, 123)"
        />
        <AlertDialog {...oState} />
        {this.state.main ? (
          <div>
            {this.state.payView ? (
              <Card>
                {/*  <CardHeader
                  style={{
                    borderRadius: '0.5em',
                    background: 'rgb(30,161,169)'
                  }}
                >
                 
                  <p
                    style={{ color: 'rgba(255,255,255,.62)' }}
                    className={this.props.classes.cardCategoryWhite}
                  >
                    Tab Payment
                  </p>
                </CardHeader> */}
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        style={{ color: 'black' }}
                        className={this.props.classes.cardTitleWhite}
                      >
                        {'Payment'}  <span style={{ color: "#00CC70" }}> {this.state.tabNo} </span>
                      </h4>
                      <h4
                        style={{ color: 'black' }}
                        className={this.props.classes.cardTitleWhite}
                      >
                        {'Tab Total: '} <span style={{ color: "#00CC70" }}>R {this.state.newTabAmount.toFixed('2')} </span>
                      </h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Payment Amount"
                        id="payamount"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => this.onChangeEvt(e),
                          type: 'number',
                          value: this.state.payAmount,
                          onClick: () => this.onHelpMarker('usersnumber')
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <CardFooter>
                    <Button style={{

                      backgroundColor: 'white', border: "1px solid #00CC70", color: "#00CC70", width: '9em', height: "2.5em", fontSize: "16px", fontWeight: 600,
                      borderRadius: '3em',

                    }}
                      onClick={e => this.submitForm(e, true)}
                      color="primary"
                    >
                      Pay Amount
                    </Button>
                    <Button style={{
                      backgroundColor: 'white', border: "1px solid red", color: "red", width: '8em', height: "2.5em", fontSize: "16px", fontWeight: 600,
                      borderRadius: '3em',

                    }}
                      onClick={() => this.cancelPayCustomer()}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            ) : (
              <div>
                {' '}
                <Button
                  style={{
                    position: 'fixed',
                    right: '0.5em',
                    bottom: '0.9em',
                    background: 'white',
                    border: '1.5px solid rgb(19, 36, 64) ',

                    padding: '0em',
                    width: '3.5em',
                    height: '3.5em',
                    borderRadius: '5em',
                    fontSize: '1em',
                    zIndex: 99
                  }}
                  onClick={() => this.handleOpen(!this.state.main)}
                  color="primary"
                >
                  <h1
                    style={{

                      color: 'rgb(19, 36, 64)'
                    }}
                  >
                    +
                  </h1>
                </Button>
                {customers.length > 0 ? <Row>
                  <button
                    style={{
                      marginTop: '-0.7em',
                      backgroundColor: '#00CC70',
                      border: '0px solid rgb(30,161,169)',
                      color: 'white',
                      width: '12em',
                      height: '2.7em',
                      fontSize: 16,
                      fontFamily: '',
                      borderRadius: '3em',
                      zIndex: 99,
                      position: 'absolute'
                    }}
                    onClick={() => this.handleOpen(!this.state.main)}
                    color="primary"
                  >
                    {'Add Customer'}
                  </button>
                </Row> : null}
                <Row>
                  <div
                    style={{
                      float: 'right',
                      marginTop: '-1em',
                      marginBottom: '1.6em'
                    }}
                  >
                    <Popover
                      placement="left"
                      content={EyeContent}
                      title=""
                      trigger="click"
                    >
                      <Button
                        style={{
                          backgroundColor: '#00CC70',
                          border: '0px solid rgb(30,161,169)',
                          color: 'white',
                          marginBottom: '-0.5em',
                          height: '3.5em',
                          marginLeft: '-0.27em',
                          borderRadius: '3em',

                        }}
                      >
                        <Icon
                          style={{
                            padding: '1px',
                            fontSize: "16px",
                          }}
                          type="setting"
                        />
                      </Button>
                    </Popover>
                  </div>
                </Row>
                <Row>
                  <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                    <StyledTable
                      locale={
                        this.state.loading
                          ? {
                            emptyText: <Skeleton />
                          }
                          : {}
                      }
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      dataSource={customers}
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: 25,
                        itemRender: (current, type, originalElement) => {
                          if (type === 'prev') {
                            return <a>Previous</a>;
                          }
                          if (type === 'next') {
                            return <a>Next</a>;
                          }
                          return originalElement;
                        },
                        showTotal: (total, range) =>
                          `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                        defaultCurrent: 1
                      }}
                      onChange={this.handleSort}
                      onRow={(record, rowIndex) => {
                        if (!record.active) {
                          return {
                            onClick: (e) => {
                              e.stopPropagation()
                              this.handleEditUser(record)
                            },
                            style: { background: 'rgba(255, 4, 4, 0.063)', color: 'red' }
                          };
                        } else if (rowIndex % 2 === 0) {
                          return {
                            onClick: (e) => {
                              e.stopPropagation()
                              this.handleEditUser(record)
                            }, style: { background: 'rgb(247, 247, 247)' }
                          };
                        } else {
                          return {
                            onClick: (e) => {
                              e.stopPropagation()
                              this.handleEditUser(record)
                            }
                          };
                        }
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('name')}
                        title="Name"
                        dataIndex="name"
                        key="name"
                      />
                      <Column
                        {...this.getColumnSearchProps('number')}
                        title="Mobile"
                        dataIndex="number"
                        key="number"
                      />
                      <Column
                        {...this.getColumnSearchProps('username')}
                        title="Email"
                        dataIndex="username"
                        key="username"
                      />
                      <Column
                        title="Actions"
                        render={(text, record) => (
                          <div>
                            <IconButton style={{
                              padding: "8px",
                              border: '1px solid #21B41E'
                            }}
                              onClick={(e) => {
                                e.stopPropagation()
                                this.handleEditUser(record)
                              }}
                            >
                              {
                                <FiEdit
                                  type="edit"
                                  style={{

                                    fontSize: 16,

                                    color: "#21B41E"
                                  }}
                                />
                              }
                            </IconButton>
                            {record.active ? (
                              <IconButton
                                style={{
                                  border: '1px solid red',
                                  marginLeft: '1em',
                                  padding: "16px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  this.handleDeleteCustomer(record)
                                }}
                              >
                                {
                                  <Icon
                                    type="delete"
                                    style={{
                                      position: 'absolute',
                                      fontSize: '17px',
                                      color: 'red'
                                    }}
                                  />
                                }
                              </IconButton>
                            ) : (
                              <IconButton style={{
                                border: '1px solid green',
                                marginLeft: '1em', padding: '16px',
                              }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  this.handleDeleteCustomer(record)
                                }}
                              >
                                {
                                  <Icon
                                    type="rollback"
                                    style={{

                                      position: 'absolute',
                                      fontSize: '15px',
                                      color: 'green',

                                    }}
                                  />
                                }
                              </IconButton>
                            )}
                          </div>
                        )}
                      />
                    </StyledTable>
                  </ConfigProvider>
                </Row>
              </div>
            )}
          </div>
        ) : (
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Full Name"
                    id="name"
                    error={!this.state.name && this.state.submitInProgress}
                    errorText={'Full name required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ name: e.currentTarget.value }),
                      value: this.state.name,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address"
                    id="email"
                    error={!this.state.username && this.state.submitInProgress}
                    errorText={'E-mail required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ username: e.currentTarget.value }),
                      type: 'email',
                      value: this.state.username,
                      onClick: () => this.onHelpMarker('usersemail')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Number"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ number: e.currentTarget.value }),
                      type: 'number',
                      value: this.state.number,
                      onClick: () => this.onHelpMarker('usersnumber')
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h2>Shipping Address</h2>
                  <CustomInput
                    labelText="Address Line 1"
                    id="address1"
                    error={!this.state.shipAddress1 && this.state.submitInProgress}
                    errorText={'Address required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ shipAddress1: e.currentTarget.value }),
                      value: this.state.shipAddress1,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="Address Line 2"
                    id="address2"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ shipAddress2: e.currentTarget.value }),
                      value: this.state.shipAddress2,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="Suburb"
                    id="suburb"
                    error={!this.state.shipSuburb && this.state.submitInProgress}
                    errorText={'Suburb required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ shipSuburb: e.currentTarget.value }),
                      value: this.state.shipSuburb,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="City"
                    id="city"
                    error={!this.state.shipCity && this.state.submitInProgress}
                    errorText={'City required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ shipCity: e.currentTarget.value }),
                      value: this.state.shipCity,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="Province"
                    id="province"
                    error={!this.state.shipProvince && this.state.submitInProgress}
                    errorText={'Province required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ shipProvince: e.currentTarget.value }),
                      value: this.state.shipProvince,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="Postal Code"
                    id="postal"
                    error={!this.state.shipPostal && this.state.submitInProgress}
                    errorText={'Postal code required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.setState({ shipPostal: e.currentTarget.value }),
                      value: this.state.shipPostal,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <h2>Billing Address</h2>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            style={{ marginRight: '1em' }}
                            checked={this.state.billSame}
                            onChange={e => this.setState({ billSame: e, billAddress1: this.state.shipAddress1,
                              billAddress2: this.state.shipAddress2,
                              billSuburb: this.state.shipSuburb,
                              billCity: this.state.shipCity,
                              billProvince: this.state.shipProvince,
                              billPostal: this.state.shipPostal, })}
                          />
                        }
                        label=" Same as shipping address"
                      />
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    labelText="Address Line 1"
                    id="address1"
                    error={!this.state.billAddress1 && this.state.submitInProgress}
                    errorText={'Address required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: this.state.billSame,
                      onChange: e => this.setState({ billAddress1: e.currentTarget.value }),
                      value: this.state.billAddress1,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="Address Line 2"
                    id="address2"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: this.state.billSame,
                      onChange: e => this.setState({ billAddress2: e.currentTarget.value }),
                      value: this.state.billAddress2,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="Suburb"
                    id="suburb"
                    error={!this.state.billSuburb && this.state.submitInProgress}
                    errorText={'Suburb required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: this.state.billSame,
                      onChange: e => this.setState({ billSuburb: e.currentTarget.value }),
                      value: this.state.billSuburb,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="City"
                    id="city"
                    error={!this.state.billCity && this.state.submitInProgress}
                    errorText={'City required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: this.state.billSame,
                      onChange: e => this.setState({ billCity: e.currentTarget.value }),
                      value: this.state.billCity,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="Province"
                    id="province"
                    error={!this.state.billProvince && this.state.submitInProgress}
                    errorText={'Province required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: this.state.billSame,
                      onChange: e => this.setState({ billProvince: e.currentTarget.value }),
                      value: this.state.billProvince,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                  <CustomInput
                    labelText="Postal Code"
                    id="postal"
                    error={!this.state.billPostal && this.state.submitInProgress}
                    errorText={'Postal code required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: this.state.billSame,
                      onChange: e => this.setState({ billPostal: e.currentTarget.value }),
                      value: this.state.billPostal,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                </GridItem>
              </GridContainer>
              <CardFooter style={{ display: "inline" }}>
                <Button style={{ marginLeft: "1em", float: "right", borderRadius: "3.5em", border: "1px solid #00CC70", backgroundColor: "#00CC70", width: "10em", height: "2.8em", color: "white" }} onClick={this.submitForm} color="primary">
                  {this.state.userlistbutton}
                </Button>
                <Button style={{ width: "8em", height: "2.8em", float: "right", borderRadius: "3.5em", border: "1px solid red", backgroundColor: "white", color: "red" }}
                  onClick={() => this.cancelAddCustomer()}
                  color="primary"
                >
                  Cancel
                </Button>
              </CardFooter>
            </CardBody>
          </Card>
        )}
        <Snackbar {...this.props} />{' '}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  users: state.users,
  orgData: state.backend.orgname,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  getEcomCustomers,
  addEcomCustomers,
  editEcomCustomers,
  deleteEcomCustomers,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(EcomCustomers));
