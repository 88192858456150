import { Collapse, Icon } from 'antd';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import CustomSelectAuto from 'src/components/CustomSelectAuto/CustomSelectAuto.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';

import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';

const { Panel } = Collapse;

const Inputs = ({ ...oState }) => {
  var length = oState.state.inputCount - 1;
  var instruc = 'Option Name ';
  var inputs = [];
  for (let i = 0; i < oState.state.inputCount; i++) {
    inputs.push(
      <div key={'divinputs' + i}>
        <GridContainer key={'gridinputs' + i}>
          <GridItem xs={5} sm={5} md={5}>
            <CustomInput
              labelText={instruc + (i + 1) + '*'}
              id={instruc + i}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeInt(e, i),
                value: oState.state.cookIns[i].name || ''
              }}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <CustomInput
              labelText="Price"
              id={'p' + i}
              showPrefix={'R'}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvtPrice(e, i),
                value: oState.state.cookIns[i].price || '',
                onClick: () => oState.onHelpMarker('menuitemsqty')
              }}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <CustomInput
              labelText="Cost"
              id={'C' + i}
              showPrefix={'R'}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvtCost(e, i),
                value: oState.state.cookIns[i].cost || '',
                onClick: () => oState.onHelpMarker('menuitemsqty')
              }}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <Button
              style={{ fontSize: '22px' }}
              onClick={e => oState.removeInput(i)}
              className={oState.props.classes.buttonAddInputCooking}
              color="primary"
            >
              -
            </Button>
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <Button
              style={{ fontSize: '20px' }}
              onClick={() => oState.addInput()}
              className={oState.props.classes.buttonAddInputCooking}
              color="primary"
            >
              +
            </Button>
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
            <div style={{ paddingTop: '30%' }}>
              {i !== length ? (
                <IconButton onClick={() => oState.onEditBtnDown(i)}>
                  {
                    <Icon
                      type="down"
                      style={{
                        color: 'green'
                      }}
                    />
                  }
                </IconButton>
              ) : (
                false
              )}
              {i !== 0 ? (
                <IconButton onClick={() => oState.onEditBtnUp(i)}>
                  {
                    <Icon
                      type="up"
                      style={{
                        color: 'green'
                      }}
                    />
                  }
                </IconButton>
              ) : (
                false
              )}
            </div>
          </GridItem>
        </GridContainer>
        {oState.state.cookIns[i].stockList &&
          Object.keys(oState.state.cookIns[i].stockList).map((stock, key) => (
            <GridContainer
              key={'gridinputsAction' + i + key}
              style={{ paddingTop: '10px' }}
            >
              <GridItem xs={5} sm={5} md={5}>
                <Collapse accordion key={'collapsinputs' + key}>
                  <Panel
                    header={
                      oState.state.cookIns[i].stockList[key] &&
                      oState.state.cookIns[i].stockList[key].stock
                        ? oState.state.cookIns[i].stockList[key].stock
                        : 'Stock item ' + (key + 1)
                    }
                    key={key}
                    extra={
                      <div>
                        {/*<Icon
                     type="minus-circle"
                    style={  {fontSize: '25px', color:"red",marginTop: "-0.1em" } }
                    onClick={e => oState.removeInputStock(i, key)}
                  >
                  </Icon>          */}
                      </div>
                    }
                  >
                    <div
                      style={{
                        padding: '1.5em',
                        margin: 'auto'
                      }}
                    >
                      <GridContainer style={{}}>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ marginTop: '-2em', marginBottom: '-1.5em' }}
                        >
                          <CustomSelectAuto
                            selectedItem={
                              oState.state.cookIns[i].stockList[key].stock ||
                              undefined
                            }
                            onChange={(e, event) =>
                              oState.onChangeEvtStock(e, event, i, key)
                            }
                            labelText="Select Stock Item"
                            id={'s' + i}
                            items={oState.state.selectitems}
                            formControlProps={{
                              fullWidth: true,
                              onClick: () =>
                                oState.onHelpMarker('menuitemsstockitem')
                            }}
                          />
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                          <CustomInput
                            labelText="QTY"
                            id={'q' + i}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              lang: 'en-US',
                              type: 'number',
                              disabled: oState.state.cookIns[i].stockList[key]
                                .stock
                                ? false
                                : true,
                              onChange: e => oState.onChangeEvtQTY(e, i, key),
                              value:
                                oState.state.cookIns[i].stockList[key].qty ||
                                '',
                              onClick: () => oState.onHelpMarker('menuitemsqty')
                            }}
                          />
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                          <CustomSelect
                            labelText="Dim*"
                            id="dimentiontype"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: oState.state.cookIns[i].stockList[key]
                                .stock
                                ? false
                                : true,
                              onChange: e =>
                                oState.handleSelectStockDim(e, i, key),
                              value:
                                oState.state.cookIns[i].stockList[key]
                                  .dimType ||
                                oState.state.cookIns[i].stockList[key]
                                  .dimension ||
                                ''
                            }}
                            menuItems={
                              oState.state.cookIns[i].stockList[key].dimtypes ||
                              []
                            }
                          />
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                          <CustomInput
                            labelText="Cost Price"
                            id="cost"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              name: 'cost',
                              disabled: oState.state.cookIns[i].stockList[key]
                                .stock
                                ? false
                                : true,
                              onChange: e =>
                                oState.handleSelectStockDim(e, i, key),
                              value:
                                oState.state.cookIns[i].stockList[key].cost ||
                                ''
                            }}
                          />
                        </GridItem>
                        <GridItem
                          xs={3}
                          sm={3}
                          md={3}
                          style={{ marginTop: '3.3em' }}
                        >
                          <Icon
                            type="delete"
                            style={{
                              position: 'absolute',
                              fontSize: '25px',
                              color: 'red'
                            }}
                            onClick={e => oState.deleteStockItem(i, key)}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                  </Panel>
                </Collapse>
              </GridItem>
              {/*    <GridItem xs={1} sm={1} md={1}>
                  <Button
                    style={{ fontSize: '22px', height: '35px' }}
                    onClick={e => oState.removeInputStock(i, key)}
                    color="primary"
                  >
                    -
            </Button>
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <Button
                    style={{ fontSize: '20px', height: '35px' }}
                    onClick={() => oState.addInputStock(i)}
                    color="primary"
                  >
                    +
            </Button>
                            </GridItem> */}
            </GridContainer>
          ))}
        <Button
          style={{
            boxShadow: 'none',
            height: '35px',
            marginTop: '1em',
            border: '1px solid #00CC70',
            color: '#00CC70',
            backgroundColor: 'white'
          }}
          onClick={() => oState.addInputStock(i)}
          color="primary"
        >
          <Icon style={{ color: '#00CC70' }} type="plus-circle" /> Add Stock
          Item
        </Button>
      </div>
    );
  }
  return inputs;
};

const CookingBody = ({ ...oState }) => {
  return (
    <div>
      <Card>
        {/*} <CardHeader
          style={{
            overflow: 'hidden',
            borderRadius: '0.5em',
            backgroundColor: '#1EA1A9'
          }}
        >
          {' '}
          <h4
            style={{ color: 'white' }}
            className={oState.props.classes.cardTitleWhite}
          >
            Instructions
          </h4>
        </CardHeader> */}
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} style={{ marginTop: '-1em' }}>
              <CustomInput
                labelText="Name*"
                id="nameedit"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => oState.onChangeEvt(e),
                  value: oState.state.cookName,
                  onClick: () => oState.onHelpMarker('usersname')
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8} style={{ marginTop: '-1em' }}>
              <CustomInput
                labelText="Description*"
                id="desc"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => oState.onChangeEvt(e),
                  value: oState.state.desc,
                  onClick: () => oState.onHelpMarker('usersname')
                }}
              />
            </GridItem>
          </GridContainer>
          <Inputs {...oState} />
          <CardFooter
            style={{
              display: 'block',
              margin: 'auto',
              borderTop: '1px solid rgba(229, 233, 242, 1)',
              marginTop: '2em'
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontSize: '13px',
                marginLeft: '0.5em',
                borderRadius: '3.5em',
                float: 'right',
                boxShadow: 'none',
                border: '1px solid #00CC70'
              }}
              onClick={() => oState.editCooking()}
              color="primary"
            >
              Save
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontSize: '13px',
                float: 'right',
                borderRadius: '3.5em',
                backgroundColor: 'rgba(229, 233, 242, 1)',
                border: '1px solid rgba(229, 233, 242, 1)',
                color: 'black',
                boxShadow: 'none'
              }}
              onClick={() => oState.handleCloseMenu()}
              color="primary"
            >
              Cancel
            </Button>
          </CardFooter>
        </CardBody>
        <Button
          style={{
            position: 'fixed',
            right: '-0.6em',
            bottom: '5em',
            background: 'rgb(0, 204, 112)',
            border: '1px solid transparent',
            color: 'white',
            padding: '0em',
            width: '3.5em',
            height: '3.5em',
            borderRadius: '5em 0em 0em 5em',
            fontSize: '1em',
            zIndex: 1000
          }}
          onClick={() => oState.editCooking()}
          color="primary"
        >
          <Icon
            style={{
              position: 'absolute',
              paddingLeft: 3
            }}
            type="check"
          />
        </Button>
      </Card>
    </div>
  );
};

export default CookingBody;
