/* eslint-disable no-loop-func */
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon, Input, Layout, Radio, Select } from 'antd';
import { InfoWindow, Map, Marker } from 'google-maps-react';
import React, { useEffect, useRef, useState } from 'react';
import { MdLocationOn, MdMyLocation } from 'react-icons/md';
import PlacesAutocomplete from 'react-places-autocomplete';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import 'src/components/Layout/Dashboard/extra.css';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import DeliveryBack from 'src/static/delivery-login-back.png';
import markerIcon from 'src/static/marker-icon.svg';
import Logo from 'src/static/vpos-logo-grey.png';
import 'src/views/Customer/customer-styles.css';
const { Content } = Layout;
const { Option } = Select;
const styles = theme => ({
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '250px'
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: '350px'
    },
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '450px'
    },
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: '0.02'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    background: 'transparent',
    color: 'black',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '100',
    fontSize: '25px',
    fontFamily: 'Nunito',
    marginBottom: '3px',
    textDecoration: 'none',
    textAlign: 'center'
  },
  loginBackground: {
    backgroundImage: 'url(' + DeliveryBack + ')',

    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh'
  },
  cardTileButton: {
    color: 'red',
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },

  card: {
    [theme.breakpoints.down('sm')]: {
      top: '5em',
      margin: '10% 10%',
      width: '80%',
      boxShadow: 'none'
    },
    [theme.breakpoints.up('md')]: {
      margin: '10% 20%',
      width: '60%',
      boxShadow: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '10% 30% ',
      width: '40%',
      boxShadow: 'none'
    }
  }
});

const radioStyle = {
  marginTop: '1.5em',
  marginBottom: '2em',
  display: 'block',
  height: '30px',
  lineHeight: '30px'
};

let locationState = {
  lat: 0.0,
  lng: 0.0
};
let changeCenter = {
  lat: 0.0,
  lng: 0.0
};
let options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};
const updateInitialPosition = position => {
  if (position) {
    locationState = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
    changeCenter = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
  }
};

const clickMap = (props, map, c, marker) => {
  setLoading(true);
  // eslint-disable-next-line
  const service = new google.maps.Geocoder();
  service.geocode(
    { location: { lat: c.latLng.lat(), lng: c.latLng.lng() } },
    result => {
      setLocation(prevState => ({
        ...prevState,
        address: result[0].formatted_address,
        lat: c.latLng.lat(),
        lng: c.latLng.lng()
      }));
      setInfo({
        marker: marker.current.marker,
        visible: true
      });
      setCenter({
        lat: c.latLng.lat(),
        lng: c.latLng.lng()
      });
      setLoading(false);
    }
  );
};

const fetchAddress = (props, map, marker) => {
  setLoading(true);
  // eslint-disable-next-line
  const service = new google.maps.Geocoder();
  navigator.geolocation.getCurrentPosition(
    position => {
      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
      service.geocode(
        { location: { lat: locationState.lat, lng: locationState.lng } },
        result => {
          setLocation(prevState => ({
            ...prevState,
            address: result[0].formatted_address,
            activeMarker: marker.current.marker,
            showingInfoWindow: true
          }));
          setInfo({
            marker: marker.current.marker,
            visible: true
          });
          setLoading(false);
        }
      );
    },
    error => {
      console.log(error);
    },
    options
  );
};

const onMarkerClick = (props, marker, e, f) => {
  setLocation(prevState => ({
    ...prevState,
    activeMarker: marker,
    showingInfoWindow: true
  }));
};
let setLocation;
let setCenter;
let infoState = {
  visible: false,
  marker: undefined
};
let setInfo;
const dragMap = (a, c, marker) => {
  // eslint-disable-next-line
  const service = new google.maps.Geocoder();
  service.geocode(
    { location: { lat: c.center.lat(), lng: c.center.lng() } },
    result => {
      setLocation(prevState => ({
        ...prevState,
        address: result[0].formatted_address,
        lat: c.center.lat(),
        lng: c.center.lng()
      }));
      setInfo({
        marker: marker.current.marker,
        visible: true
      });
      setCenter({
        lat: c.center.lat(),
        lng: c.center.lng()
      });
    }
  );
};
const MyMapComponent = props => {
  const markerRef = props.markerRef;
  [infoState, setInfo] = useState(infoState);
  return (
    <Map
      onDragend={(props, map) => dragMap(props, map, markerRef)}
      google={window.google}
      onClick={(props, map, c) => clickMap(props, map, c, markerRef)}
      onReady={(props, map) => fetchAddress(props, map, markerRef)}
      style={{
        width: '100%',
        height: 'calc(100% - 40px)',
        top: 40
      }}
      // google={props.google}
      initialCenter={locationState}
      center={locationState}
      zoom={16}
      disableDefaultUI={true}
    >
      <Marker
        ref={markerRef}
        icon={markerIcon}
        position={locationState}
        onClick={(props, marker, e) =>
          onMarkerClick(props, marker, e, markerRef)
        }
        id={1}
      />
      <InfoWindow visible={infoState.visible} marker={infoState.marker}>
        <div>
          <p>{locationState.address}</p>
        </div>
      </InfoWindow>
    </Map>
  );
};

const handleChangeAddressSelect = (address, marker) => {
  setAutoCompleteValue(address);
  // eslint-disable-next-line
  const service = new google.maps.Geocoder();
  service.geocode({ address: address }, result => {
    setLocation(prevState => ({
      ...prevState,
      address: result[0].formatted_address,
      lat: result[0].geometry.location.lat(),
      lng: result[0].geometry.location.lng()
    }));
    setInfo({
      marker: marker.current.marker,
      visible: true
    });
    setCenter({
      lat: result[0].geometry.location.lat(),
      lng: result[0].geometry.location.lng()
    });
  });
};
const handleChangeAddressSearch = value => {
  setAutoCompleteValue(value);
};

let autoCompleteValue = '';
let setAutoCompleteValue;
let loading = false;
let setLoading;

const SelectDelivery = props => {
  [locationState, setLocation] = useState(locationState);
  [loading, setLoading] = useState(loading);
  [changeCenter, setCenter] = useState(changeCenter);
  [autoCompleteValue, setAutoCompleteValue] = useState(autoCompleteValue);
  const [gpsCheck, setGpsCheck] = useState(false);
  const markerRef = useRef();

  const gpsCheckTimeout = () => {
    setTimeout(function() {
      window.cordova.plugins.diagnostic.isGpsLocationEnabled(function(enabled) {
        if (enabled) {
          setGpsCheck(true);
        } else {
          gpsCheckTimeout();
        }
      });
    }, 3000);
  };

  useEffect(() => {
    if (window.cordova) {
      window.cordova.plugins.diagnostic.isGpsLocationEnabled(
        function(enabled) {
          if (enabled) {
            setGpsCheck(true);
          } else {
            props.checkLocationGranted();
            gpsCheckTimeout();
          }
        },
        function(error) {
          alert('The following error occurred: ' + error);
        }
      );
    } else {
      setGpsCheck(true);
    }
    return () => {
      setGpsCheck(false);
    };
  }, []);
  return (
    <Content className={props.classes.loginBackground}>
      {!props.mapOpen ? (
        <img
          src={Logo}
          alt="Logo"
          style={{
            width: '50%',
            minWidth: '5em',
            left: '4%',
            top: '2%',
            marginTop: '10vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            //position: 'absolute',
            zIndex: 9
          }}
        />
      ) : null}
      {!props.mapOpen ? (
        <Card
          style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            opacity: 1,
            zIndex: 999,
            marginTop: '10%'
          }}
          className={props.classes.card}
        >
          <CardHeader className="sign-in-header">
            <h4
              style={{
                fontWeight: 700,
                marginTop: '2em',
                marginBottom: '-0.5em',
                lineHeight: '1.2em'
              }}
              className={props.classes.cardTitleWhite}
            >
              Select delivery <span style={{ color: '#00CC70' }}>location</span>{' '}
              or <span style={{ color: '#00CC70' }}>collect</span>
            </h4>
            <p className={props.classes.cardCategoryWhite} />
          </CardHeader>
          <CardBody style={{ zIndex: 9 }} className="sign-in-body">
            <div
              className={props.classes.background}
              style={{ backgroundImage: 'url(' + +')' }}
            />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Radio.Group
                  disabled={props.locationLoading.all}
                  onChange={e => {
                    if (e.target.value !== 2) {
                      props.onChangeDelivery(e, props);
                    }
                  }}
                  value={props.value}
                >
                  {/* <Radio style={radioStyle} value={0}>
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: props.locationLoading.all ? '' : '#383838'
                      }}
                    >
                      My Location
                    </span>
                    {props.locationLoading.my ? (
                      <Icon
                        style={{ marginLeft: 10, color: 'black' }}
                        type="loading"
                      />
                    ) : null}
                    <span
                      style={{
                        position: 'absolute',
                        top: 20,
                        fontSize: 13,
                        left: 24,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '190%'
                      }}
                    >
                      {props.myAddress}
                    </span>
                  </Radio> */}
                  <span style={{ color: 'red' }}>
                    {!gpsCheck ? 'Location not found. Turn on Location.' : ''}
                  </span>
                  <Radio
                    style={radioStyle}
                    value={1}
                    disabled={gpsCheck ? false : true}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: props.locationLoading.all ? '' : '#383838'
                      }}
                    >
                      My Location
                    </span>
                    {props.locationLoading.my ? (
                      <Icon
                        style={{ marginLeft: 10, color: 'black' }}
                        type="loading"
                      />
                    ) : null}
                    <span
                      style={{
                        position: 'absolute',
                        top: 20,
                        fontSize: 13,
                        left: 24,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '190%'
                      }}
                    >
                      {props.myAddress}
                    </span>
                  </Radio>
                  <Radio
                    disabled={gpsCheck ? false : true}
                    onClick={e => {
                      props.onChangeDelivery(
                        {
                          target: { value: parseInt(e.currentTarget.value) }
                        },
                        props
                      );
                    }}
                    style={radioStyle}
                    value={2}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: props.locationLoading.all ? '' : '#383838'
                      }}
                    >
                      New Location
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        top: 20,
                        fontSize: 13,
                        left: 24,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '190%'
                      }}
                    >
                      {props.newAddress}
                    </span>
                  </Radio>
                  <Radio style={radioStyle} value={3}>
                    <span
                      style={{
                        marginLeft: '-0.2em',
                        fontSize: '16px',
                        fontWeight: 600,
                        color: props.locationLoading.all ? '' : '#383838'
                      }}
                    >
                      {' '}
                      Collect
                    </span>
                    {props.locationLoading.collect ? (
                      <Icon
                        style={{ marginLeft: 10, color: 'black' }}
                        type="loading"
                      />
                    ) : null}
                  </Radio>
                </Radio.Group>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} style={{ marginTop: '2em' }}>
                <Button
                  className="sign-in-button"
                  disabled={!props.selectedDelivery && !props.newAddress}
                  onClick={props.confirmDeliveryAddress}
                  color="transparent"
                  style={{
                    textTransform: 'initial',
                    backgroundColor: 'rgb(0, 204, 112)',
                    color: 'white',
                    letterSpacing: '0.04em',
                    fontWeight: 600,
                    fontFamily: 'Nunito',
                    height: '3.5em',
                    borderRadius: '3em',
                    width: '100%',
                    marginTop: '0em',
                    fontSize: '16px',
                    zIndex: 999
                  }}
                >
                  Continue
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Button
                  className="sign-in-button"
                  onClick={props.signOut}
                  color="transparent"
                  style={{
                    textTransform: 'initial',
                    backgroundColor: 'white',
                    color: 'rgb(0, 204, 112)',
                    border: '1px solid rgb(0, 204, 112)',
                    letterSpacing: '0.04em',
                    fontWeight: 600,
                    fontFamily: 'Nunito',
                    height: '3.4em',
                    borderRadius: '3em',
                    width: '100%',
                    marginTop: '0.5em',
                    fontSize: '16px',
                    zIndex: 999
                  }}
                >
                  Sign Out
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      ) : null}
      {props.mapOpen ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '4em',
              zIndex: 9,

              display: 'flex'
            }}
          >
            <PlacesAutocomplete
              value={autoCompleteValue}
              onChange={handleChangeAddressSearch}
              onSelect={e => handleChangeAddressSelect(e, markerRef)}
              searchOptions={{
                componentRestrictions: { country: 'za' },
                types: ['address']
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'grid'
                    }}
                  >
                    <Input
                      value={autoCompleteValue}
                      allowClear
                      style={{
                        border: 'none',
                        width: '101vw',
                        margin: 'auto',
                        marginLeft: '-0.2em',
                        height: '3.9em',
                        zIndex: 9,
                        position: 'relative',
                        top: 0
                      }}
                      placeholder="Enter street address"
                      prefix={<Icon type="search" />}
                      {...getInputProps()}
                    />
                    <Select
                      style={{
                        opacity: 0,
                        width: '90%',
                        position: 'relative',
                        margin: 'auto',
                        top: -32
                      }}
                      dropdownStyle={{ width: '90%', margin: 'auto' }}
                      showSearch={false}
                      open={suggestions.length > 0}
                    >
                      {suggestions.map(suggestion => {
                        return (
                          <Option
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.id}
                            value={suggestion.description}
                          >
                            {suggestion.description}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                );
              }}
            </PlacesAutocomplete>
          </div>
          <MyMapComponent markerRef={markerRef} />
          <div
            style={{
              position: 'absolute',
              right: 20,
              fontSize: 40,

              textAlign: 'center',
              padding: 2,
              width: 55,
              borderRadius: 50,
              height: 55,
              bottom: 70,
              margin: 'auto'
            }}
            onClick={e => fetchAddress(null, null, markerRef)}
          >
            <MdMyLocation />
          </div>
          <Button
            disabled={loading}
            loading={loading}
            style={{
              position: 'relative',
              top: '46vh',
              width: '100%',
              height: '5em',
              margin: 'auto',
              borderRadius: 0,
              background: '#00CC70'
            }}
            onClick={e => props.setDelivery(locationState)}
          >
            <MdLocationOn />
            Use this location
          </Button>
        </div>
      ) : null}
      <Snackbar {...props} />
      {/*{!props.mapOpen ? (
        <div className="waveWrapper waveAnimation">
          <div className="waveWrapperInner bgTop">
            <div
              className="wave waveTop"
              style={{
                backgroundImage: 'url(' + WaveTop + ')'
              }}
            />
          </div>
          <div className="waveWrapperInner bgMiddle">
            <div
              className="wave waveMiddle"
              style={{
                backgroundImage: 'url(' + WaveMid + ')'
              }}
            />
          </div>
          <div className="waveWrapperInner bgBottom">
            <div
              className="wave waveBottom"
              style={{
                backgroundImage: 'url(' + WaveBot + ')'
              }}
            />
          </div>
        </div>
      ) : null}*/}
    </Content>
  );
};

export default withStyles(styles, { withTheme: true })(SelectDelivery);
