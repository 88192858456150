/* eslint-disable no-loop-func */
import { Col, Layout, Row } from 'antd';
import { Map, Marker } from 'google-maps-react';
import React, { useEffect, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
import markerIcon from 'src/static/marker-icon.svg';

const apiCall = 'vpos/api/customer/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Content } = Layout;
let bill;
let setBill;
let user;
let mapRef;
const pubnubHandler = {
  signal: msg => {
    if (msg.message === 'delivered') {
      navToView('status');
    }
    let coord = {
      lat: parseFloat(msg.message.split(',')[0]),
      lng: parseFloat(msg.message.split(',')[1])
    };
    if (locationState === undefined) {
      // eslint-disable-next-line
      bounds.extend(new google.maps.LatLng(coord.lat, coord.lng));
      mapRef.fitBounds(bounds);
    }
    // eslint-disable-next-line
    locationState = new google.maps.LatLng(coord.lat, coord.lng);
    setLoading(!loading);
  }
};
let locationState;
let bounds;
let marker;
let loading = false;
let setLoading;
const MyMapComponent = props => {
  [loading, setLoading] = useState(loading);
  if (marker) {
    marker.setPosition(locationState);
  }
  useEffect(() => {
    bounds = new window.google.maps.LatLngBounds();
    bounds.extend(
      new window.google.maps.LatLng(bill.location.lat, bill.location.lng)
    );
    bounds.extend(
      new window.google.maps.LatLng(
        bill.travelMatrix.initialLocation.lat,
        bill.travelMatrix.initialLocation.lng
      )
    );
    window.pubnub.addListener(pubnubHandler);
    return () => {
      marker = undefined;
      window.pubnub.removeListener(pubnubHandler);
    };
  }, []);
  return (
    <Map
      google={window.google}
      onReady={(props, map) => {
        mapRef = map;
        if (!locationState) {
          locationState = new window.google.maps.LatLng(
            bill.travelMatrix.initialLocation.lat,
            bill.travelMatrix.initialLocation.lng
          );
        }
        marker = new window.google.maps.Marker({
          map: mapRef,
          icon: markerIcon,
          position: locationState
        });
        map.fitBounds(bounds);
      }}
      style={{
        width: '100%',
        height: '100%'
      }}
      initialCenter={bill.location}
      disableDefaultUI={true}
    >
      <Marker position={bill.location} id={1} />
    </Map>
  );
};
let navToView;
const Track = props => {
  bill = props.bill;
  setBill = props.setBill;
  user = props.user;
  navToView = props.navToView;
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white' }}>
      <Row type="flex" justify="space-between">
        {' '}
        <Col span={24}>
          <h1
            style={{
              fontWeight: 500,
              textAlign: 'center',
              margin: 'auto',
              paddingTop: '0.5em',
              paddingBottom: '1em',
              fontFamily: 'Roboto',
              fontSize: '1.5em'
            }}
          >
            {props.bill.orgName}
          </h1>
        </Col>
        <Col span={12}>
          <h2
            style={{
              fontSize: 17,
              paddingLeft: '1em',
              margin: 'auto',
              marginBottom: '0.7em'
            }}
          >
            Order #{props.bill.orderno}
          </h2>
        </Col>
        <Col span={12}>
          <a
            style={{
              float: 'right',
              paddingRight: '1em',
              paddingTop: '0.5em',
              fontFamily: 'Roboto',
              fontSize: '1em'
            }}
            onClick={e => props.navToView('companyMenu')}
          >
            View Order
          </a>
        </Col>
      </Row>
      <Row
        style={{ height: 'calc(100vh - 180px)' }}
        type="flex"
        justify="space-around"
      >
        <Col span={24}>
          <MyMapComponent />
        </Col>
      </Row>
      <Row
        style={{
          position: 'absolute',
          width: '100%'
        }}
        type="flex"
        justify="space-around"
      />
      <Row
        style={{
          position: 'absolute',
          width: '100%'
        }}
        type="flex"
        justify="space-around"
      >
        <div
          style={{
            zIndex: 999,
            position: 'Fixed',
            backgroundColor: '#00CC70',
            fontWeight: 700,
            fontFamily: 'Roboto',
            fontSize: '18px',
            textAlign: 'center',
            color: 'white',
            width: '100%',
            bottom: 0
          }}
        >
          <p style={{ margin: 0 }}>Estimated Delivery Time</p>
          <p style={{ margin: 0 }}>{bill.travelMatrix.estimateArrival}</p>
          <p style={{ margin: 0 }}>{bill.deliveryType}</p>
        </div>
      </Row>
    </Content>
  );
};

export default Track;
