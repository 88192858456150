import React, { useEffect, useState, useRef } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Icon,
  Input,
  Row,
  Table,
  Tabs,
  Popover,
  Switch,
  Radio,
  Select,
  ConfigProvider,
  Empty,
  Tooltip,
  Modal
} from 'antd';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { FiEdit } from 'react-icons/fi';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelectMulti from 'src/components/CustomSelectMulti/CustomSelectMulti.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import UiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CSVLink } from 'react-csv';
import {
  addImportMenuItem,
  addImportMenuItemEcom,
  addMenuItem,
  addStockItem,
  deleteMenuItem,
  editMenuItem,
  getMenuItems,
  restoreMenuItem,
  editMenuItemTable,
  addTypes,
  editCooking,
  addMultiMods,
  addMultiStockItems,
  addBrands
} from 'src/redux/actions/backend';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import Loading from 'react-loading-bar';
import ContentLoader from 'react-content-loader';
import convert from 'convert-units';
import _ from 'lodash';

import styled, { css } from 'styled-components';
import { ExcelRenderer } from 'react-excel-renderer';
import { FileDrop } from 'react-file-drop';
import ObjectID from 'bson-objectid';

import ImportTemp from 'src/static/templates/VPOSMenuItemsTemplate.xlsx';
import ImportTempEcom from 'src/static/templates/VPOSMenuItemsTemplateEcom.xlsx';
import VarientsBody from 'src/views/Admin/Menuitems/VariantsCreator';
import StockTable from 'src/views/Admin/Menuitems/StockTable';
import CompoStockItem from 'src/views/Admin/Menuitems/CompoStockItem';
import Images from 'src/views/Admin/Menuitems/Images';
import MenuItem from 'src/views/Admin/Menuitems/MenuitemView';
import { InputsModifier } from 'src/views/Admin/Menuitems/CookingView';
import { createUUIDLink, createUUID } from 'src/utils/formater';

import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Column } = Table;
const { Option } = Select;
const InputGroup = Input.Group;
const { TabPane } = Tabs;
const { Search } = Input;

const StyledPopover = styled(Popover)`
  .ant-popover-inner {
    background-color: red !important;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  }
`;

const StyledRadio = styled(Radio)`
  .ant-popover-inner {
    background: transparent !important;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    border-bottom: 0px solid #e8e8e8;
    margin: 0 0px 20px 0 !important;
    outline: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-tab {
    width: auto !important;
    font-size: 16px !important;
    margin-left: 0.5em !important;
    margin-top: 0.5em !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    font-weight: 400 !important;
    color: rgb(19, 36, 64) !important;
    background: transparent !important;
    border: 0px solid #00cc70 !important;
  }
  .ant-tabs-tab-active {
    width: auto !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    color: #00cc70 !important;
    border-bottom: 1px solid #00cc70 !important;
  }
`;
const StyledSearch = styled(Search)`
  .ant-input-clear-icon {
    font-size: 15px;
    margin-right: 0.3em;
  }
  .ant-input-search-icon {
  }
  .ant-input {
    border-radius: 4em;
  }
  .ant-input::placeholder {
  }
`;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    boxShadow: 'none',
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'white',
    padding: '10px',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',
    color: 'white',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    borderRadius: '0.45em',
    backgroundColor: 'rgb(61, 72, 82)',
    color: 'white',
    border: '1px solid rgb(61, 72, 82)',
    height: '38px',
    width: '80%'
  },
  tableScroll: {
    width: '100%',
    height: '55vh',
    overflow: 'auto'
  },
  buttonAddInputCooking: {
    marginTop: '43px',
    height: '35px'
  },
  panel: {
    width: '500px'
  }
});

var dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: [
    {
      name: 'Unit',
      value: 'unit'
    }
  ]
};

const colSelectItems = [
  {
    name: 'NONE',
    value: 'none'
  },
  {
    name: 'Column 1',
    value: 0
  },
  {
    name: 'Column 2',
    value: 1
  },
  {
    name: 'Column 3',
    value: 2
  },
  {
    name: 'Column 4',
    value: 3
  },
  {
    name: 'Column 5',
    value: 4
  },
  {
    name: 'Column 6',
    value: 5
  },
  {
    name: 'Column 7',
    value: 6
  },
  {
    name: 'Column 8',
    value: 7
  },
  {
    name: 'Column 9',
    value: 8
  },
  {
    name: 'Column 10',
    value: 9
  },
  {
    name: 'Column 11',
    value: 10
  },
  {
    name: 'Column 12',
    value: 11
  },
  {
    name: 'Column 13',
    value: 12
  },
  {
    name: 'Column 14',
    value: 13
  },
  {
    name: 'Column 15',
    value: 14
  },
  {
    name: 'Column 16',
    value: 15
  },
  {
    name: 'Column 17',
    value: 16
  },
  {
    name: 'Column 18',
    value: 17
  },
  {
    name: 'Column 19',
    value: 18
  },
  {
    name: 'Column 20',
    value: 19
  }
];

const Menuitems = allprops => {
  const initialTempstate = {
    MainCategory: 0,
    SubCategory: 1,
    ItemName: 2,
    Type: 3,
    CostPrice: 4,
    SellPrice: 5,
    SKU: 6,
    start: 1,
    end: 10,
    pos: true
  };

  const initialTempstateEcom = {
    MainCategory: 0,
    Type: 1,
    Brand: 2,
    ProductName: 3,
    CostPrice: 4,
    SellPrice: 5,
    SKU: 6,
    InitStock: 7,
    start: 1,
    end: 10,
    ecom: true
  };
  const searchInputTop = useRef();
  const [tempState, setTempState] = useState(initialTempstateEcom);
  const [dialogState, setDialogState] = useState([]);

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />
      <button
        style={{
          backgroundColor: '#00CC70',
          border: '0px solid grey',
          color: 'white',
          width: '9em',
          height: '2.5em',
          fontSize: '16px',
          fontFamily:
            'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif',
          fontWeight: 600,
          borderRadius: '3em',
          zIndex: 99,
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => handleOpenMenu(true)}
        color="primary"
      >
        {'Add Product'}
      </button>
    </div>
  );

  const Skeleton = () => (
    <ContentLoader
      speed={0.5}
      width={'100%'}
      height={'100%'}
      viewBox="0 0 100% 100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#c0c0c0"
    >
      <rect x="0" y="0" rx="2" ry="2" width="100%" height="45" />
      <rect x="0" y="46" rx="2" ry="2" width="100%" height="45" />
      <rect x="0" y="92" rx="2" ry="2" width="100%" height="45" />
    </ContentLoader>
  );
  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }

  let menuitemsState = {};
  let filteredInfo = {};
  let state = {};
  let props = {};
  let modstate = {};
  let setModState;
  let setState;
  let setMenuItemsState;

  const componentDidMount = () => {
    props.resetActionBreadCrumb();
    initGetMenuItems();
  };

  const handleAddBreadCrumb = crumb => {
    let breadCrumbs = props.breadCrumbs || [];
    breadCrumbs.push(crumb);
    props.setBreadCrumbs(breadCrumbs);
  };

  const handleRemoveBreadCrumb = () => {
    let breadCrumbs = props.breadCrumbs || [];
    breadCrumbs.pop();
    props.setBreadCrumbs(breadCrumbs);
  };

  const initGetMenuItems = (msg, page, addname) => {
    const token = props.user.token;
    handleRemoveBreadCrumb();
    props
      .getMenuItems(token)
      .then(result => {
        if (msg) {
          props.openSnackbar(msg);
        }
        if (addname) {
          var key = 0;
          for (var i = 0; i < result.data.results.menuItems.length; i++) {
            if (result.data.results.menuItems[i].name === addname) {
              key = i;
              break;
            }
          }
          if (key > 10) {
            page = Math.ceil(key / 10);
          }
        }
        if (!page) {
          page = 1;
        }
        let categoryFiltersPrep = [];
        let mainmenu = result.data.results.mainmenu;
        for (var i = 0; i < mainmenu.length; i++) {
          for (var j = 0; j < mainmenu[i].menubtns.length; j++) {
            if (
              categoryFiltersPrep.indexOf(mainmenu[i].menubtns[j].name) ===
                -1 &&
              mainmenu[i].menubtns[j].active
            ) {
              categoryFiltersPrep.push(mainmenu[i].menubtns[j].name);
            }
          }
        }
        if (categoryFiltersPrep.length) {
          categoryFiltersPrep.sort();
        }
        let categoryFilters = [
          {
            text: 'No Category',
            value: 'No Category'
          }
        ];
        for (var i = 0; i < categoryFiltersPrep.length; i++) {
          categoryFilters.push({
            text: categoryFiltersPrep[i],
            value: categoryFiltersPrep[i]
          });
        }
        let itemFiltersPrep = [];
        let types = result.data.results.types;
        for (var i = 0; i < types.length; i++) {
          if (itemFiltersPrep.indexOf(types[i].name) === -1) {
            itemFiltersPrep.push(types[i].name);
          }
        }
        let itemFiltersPrepBrand = [];
        let brands = result.data.results.brands;
        for (var i = 0; i < brands.length; i++) {
          if (itemFiltersPrepBrand.indexOf(brands[i].name) === -1) {
            itemFiltersPrepBrand.push(brands[i].name);
          }
        }

        let itemFilters = [];
        for (var i = 0; i < itemFiltersPrep.length; i++) {
          itemFilters.push({
            text: itemFiltersPrep[i],
            value: itemFiltersPrep[i]
          });
        }
        let itemFiltersBrand = [];
        for (var i = 0; i < itemFiltersPrepBrand.length; i++) {
          itemFiltersBrand.push({
            text: itemFiltersPrepBrand[i],
            value: itemFiltersPrepBrand[i]
          });
        }
        let menuitemsCatMultiOpen = {};
        for (var i = 0; i < result.data.results.menuItems.length; i++) {
          if (
            result.data.results.menuItems[i].menubtns &&
            !result.data.results.menuItems[i].menubtns.length
          ) {
            result.data.results.menuItems[i].menubtns = ['No Category'];
          }
          menuitemsCatMultiOpen[result.data.results.menuItems[i]._id] = false;
        }
        result.data.results.mainmenu.unshift({
          _id: 'No Catalogue',
          name: 'No Catalogue'
        });
        result.data.results.suppliers.unshift({
          value: 'none',
          name: 'None'
        });
        let tableBody = [];
        tableBody.push([
          'Main Category',
          'Sub Category',
          'Item Name',
          'Type',
          'Cost Price',
          'Sell Price',
          'SKU'
        ]);
        result.data.results.menuItems.forEach(record => {
          if (record) {
            let lineItem = [];
            lineItem.push(
              record.menubtns && record.menubtns[0] ? record.menubtns[0] : ''
            );
            lineItem.push(
              record.menubtns && record.menubtns[1] ? record.menubtns[1] : ''
            );
            lineItem.push(record.name ? record.name : '');
            lineItem.push(record.type ? record.type : '');
            lineItem.push(
              record.costPrice && record.costPrice > 0 ? record.costPrice : ''
            );
            lineItem.push(
              record.sellPrice && record.sellPrice > 0
                ? parseFloat(record.sellPrice).toFixed('2')
                : ''
            );
            lineItem.push(record.code ? record.code : '');
            tableBody.push(lineItem);
          }
        });
        let printer = false;
        if (
          result.data.results.printer &&
          window.device &&
          window.device.platform === 'Android'
        ) {
          printer = result.data.results.printer;
        }
        setState(prevState => ({
          ...prevState,
          printer: printer,
          csvData: tableBody,
          menuitemsCatMultiOpen: menuitemsCatMultiOpen,
          categoryFilters: categoryFilters,
          itemFilters: itemFilters,
          itemFiltersBrand: itemFiltersBrand,
          categoryFiltersPrep: categoryFiltersPrep,
          itemFiltersPrep: itemFiltersPrep,
          itemFiltersPrepBrand: itemFiltersPrepBrand,
          mainmenu: result.data.results.mainmenu,
          menuitems: result.data.results.menuItems,
          selectitems: result.data.results.stockItems,
          stockList: result.data.results.stockList,
          types: result.data.results.types,
          brands: result.data.results.brands,
          images: result.data.results.images,
          stockTableTypes: result.data.results.modtypes,
          suppliers: result.data.results.suppliers,
          oSupplier: result.data.results.oSupplier || {},
          subcat: result.data.results.subcat || [],
          currentPage: page,
          selectitem: undefined,
          qtymenu: '',
          menuadd1: false,
          loading: false,
          main: true,
          cookingState: {
            ...prevState.cookingState,
            selectitems: result.data.results.stockItems,
            stockitems: result.data.results.stockList,
            types: result.data.results.modtypes
          }
        }));
        setModState(prevState => ({
          ...prevState,
          cookInsList: result.data.results.cooking,
          cookingObject: result.data.results.cookingObject || {}
        }));
        setTableFilter(result.data.results.menuItems);
        if (
          new URLSearchParams(props.history.location.search).has('requests')
        ) {
          handleFormatList('requests');
        }
      })
      .catch(err => {
        onError(err);
      });
  };

  const fileHandler = eventN => {
    let event = Object.assign({}, eventN);
    if (!event.target.files[0].type.includes('spreadsheetml')) {
      setState(prevState => ({
        ...prevState,
        filename: 'Drop File here!',
        file: '',
        loading: false
      }));
      onError('Wrong file Format');
      return;
    }
    let fileObj = event.target.files[0];
    setState(prevState => ({
      ...prevState,
      filename: fileObj.name,
      file: fileObj
    }));
  };

  const fileHandlerDrag = (file, event) => {
    if (!file[0].type.includes('spreadsheetml')) {
      setState(prevState => ({
        ...prevState,
        filename: 'Drop File here!',
        file: '',
        loading: false
      }));
      onError('Wrong file Format');
      return;
    }
    let fileObj = file[0];
    setState(prevState => ({
      ...prevState,
      filename: fileObj.name,
      file: fileObj
    }));
  };

  const previewFile = () => {
    if (state.filename === 'Drop File here!') {
      onError('No File Found?');
      return;
    }
    setState(prevState => ({
      ...prevState,
      loading: true
    }));
    ExcelRenderer(state.file, (err, resp) => {
      if (err) {
        setState(prevState => ({
          ...prevState,
          loading: false
        }));
        alert(err);
        console.log(err);
      } else {
        if (!resp.rows[0]) {
          setState(prevState => ({
            ...prevState,
            filename: 'Drop File here!',
            file: '',
            loading: false
          }));
          onError('Wrong file Format');
          return;
        }
        if (!resp.rows[1]) {
          setState(prevState => ({
            ...prevState,
            filename: 'Drop File here!',
            file: '',
            loading: false
          }));
          onError('Data not found');
          return;
        }
        let startRow = tempState.start - 1;
        let endRow = tempState.end - startRow;
        resp.rows = resp.rows.splice(startRow, endRow);
        setDialogState(resp.rows);
        setState(prevState => ({
          ...prevState,
          loading: false
        }));
      }
    });
  };

  const uploadFile = () => {
    if (!state.mainmenusmenu) {
      onError('Select Catalogue');
      return;
    } else if (state.mainmenusmenu === 'No Catalogue') {
      state.mainmenusmenu = '';
    }
    if (state.filename === 'Drop File here!') {
      onError('No File Found?');
      return;
    }
    setState(prevState => ({
      ...prevState,
      loading: true,
      upload: false
    }));
    ExcelRenderer(state.file, (err, resp) => {
      if (err) {
        setState(prevState => ({
          ...prevState,
          filename: 'Drop File here!',
          file: '',
          loading: false
        }));
        onError('Wrong file Format');
      } else {
        if (!resp.rows[0]) {
          setState(prevState => ({
            ...prevState,
            filename: 'Drop File here!',
            file: '',
            loading: false
          }));
          onError('Wrong file Format');
          return;
        }
        if (!resp.rows[1]) {
          setState(prevState => ({
            ...prevState,
            filename: 'Drop File here!',
            file: '',
            loading: false
          }));
          onError('Data not found');
          return;
        }
        const token = props.user.token;
        if (state.manualTemplate) {
          let startRow = tempState.start - 1;
          let endRow = tempState.end - startRow;
          resp.rows = resp.rows.splice(startRow, endRow);
          if (tempState.ecom) {
            props
              .addImportMenuItemEcom(resp, tempState, state, token)
              .then(res => {
                if (res.data.results.error) {
                  onError(res.data.results.error);
                } else {
                  setState(prevState => ({
                    ...prevState,
                    filename: 'Drop File here!',
                    file: '',
                    upload: false,
                    mainmenusmenu: '',
                    mainmenuscompo: false,
                    staticTemplate: false,
                    manualTemplate: false,
                    ecomTemp: false,
                    posTemp: false
                  }));
                  onComplete('Import Complete');
                }
              })
              .catch(err => {
                onError(err);
              });
          } else {
            props
              .addImportMenuItem(resp, tempState, state, token)
              .then(res => {
                if (res.data.results.error) {
                  onError(res.data.results.error);
                } else {
                  setState(prevState => ({
                    ...prevState,
                    filename: 'Drop File here!',
                    file: '',
                    upload: false,
                    mainmenusmenu: '',
                    mainmenuscompo: false,
                    staticTemplate: false,
                    manualTemplate: false,
                    ecomTemp: false,
                    posTemp: false
                  }));
                  onComplete('Import Complete');
                }
              })
              .catch(err => {
                onError(err);
              });
          }
        } else {
          var checkEcom =
            resp.rows[0][0] === 'Category' &&
            resp.rows[0][1] === 'Type' &&
            resp.rows[0][2] === 'Brand' &&
            resp.rows[0][3] === 'Product Name' &&
            resp.rows[0][4] === 'Cost Price' &&
            resp.rows[0][5] === 'Sell Price' &&
            resp.rows[0][6] === 'SKU' &&
            resp.rows[0][7] === 'Init Stock';
          var checkPOS =
            resp.rows[0][0] === 'Main Category' &&
            resp.rows[0][1] === 'Sub Category' &&
            resp.rows[0][2] === 'Item Name' &&
            resp.rows[0][3] === 'Type' &&
            resp.rows[0][4] === 'Cost Price' &&
            resp.rows[0][5] === 'Sell Price' &&
            resp.rows[0][6] === 'SKU';
          if (!checkEcom && !checkPOS) {
            setState(prevState => ({
              ...prevState,
              filename: 'Drop File here!',
              file: '',
              loading: false
            }));
            onError('Wrong Excel Template');
            return;
          }
          resp.rows = resp.rows.splice(1);
          if (checkEcom) {
            props
              .addImportMenuItemEcom(resp, initialTempstateEcom, state, token)
              .then(res => {
                if (res.data.results.error) {
                  onError(res.data.results.error);
                } else {
                  setState(prevState => ({
                    ...prevState,
                    filename: 'Drop File here!',
                    file: '',
                    upload: false,
                    mainmenusmenu: '',
                    mainmenuscompo: false,
                    staticTemplate: false,
                    manualTemplate: false,
                    ecomTemp: false,
                    posTemp: false
                  }));
                  onComplete('Import Complete');
                }
              })
              .catch(err => {
                onError(err);
              });
          } else {
            props
              .addImportMenuItem(resp, initialTempstate, state, token)
              .then(res => {
                if (res.data.results.error) {
                  onError(res.data.results.error);
                } else {
                  setState(prevState => ({
                    ...prevState,
                    filename: 'Drop File here!',
                    file: '',
                    upload: false,
                    mainmenusmenu: '',
                    mainmenuscompo: false,
                    staticTemplate: false,
                    manualTemplate: false,
                    ecomTemp: false,
                    posTemp: false
                  }));
                  onComplete('Import Complete');
                }
              })
              .catch(err => {
                onError(err);
              });
          }
        }
      }
    });
  };

  const switchUpload = change => {
    if (change) {
      setState(prevState => ({
        ...prevState,
        impPassDialog: true,
        impPass: ''
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        upload: !state.upload,
        filename: 'Drop File here!',
        file: '',
        mainmenusmenu: '',
        mainmenuscompo: false,
        staticTemplate: false,
        manualTemplate: false,
        ecomTemp: false,
        posTemp: false
      }));
    }
  };

  const switchUploadTemp = change => {
    let basic = false;
    let custom = false;
    if (change === 'basic') {
      basic = true;
    }
    if (change === 'custom') {
      custom = true;
    }
    setState(prevState => ({
      ...prevState,
      staticTemplate: basic,
      manualTemplate: custom
    }));
  };

  const switchUploadTypeTemp = change => {
    let pos = false;
    let ecom = false;
    if (change === 'pos') {
      pos = true;
      setTempState(initialTempstate);
    }
    if (change === 'ecom') {
      ecom = true;
      setTempState(initialTempstateEcom);
    }
    setState(prevState => ({
      ...prevState,
      ecomTemp: ecom,
      posTemp: pos
    }));
  };

  const handleChangeTempCol = (event, index) => {
    let copyTempState = JSON.parse(JSON.stringify(tempState));
    copyTempState[index] = parseFloat(event);
    setTempState(copyTempState);
  };

  const handleOpenMenu = open => {
    if (open) {
      handleAddBreadCrumb({ method: 'handleOpenMenu', crumb: 'Add Product' });
      setState(prevState => ({
        ...prevState,
        tabNumber: '1',
        main: false,
        mainTypeInput: [],
        typeName: '',
        mainBrandInput: [],
        brandName: '',
        mainSub: false,
        menuadd1: false,
        menuadd2: false,
        selectitem: undefined,
        itemasstockformformenuitem: false,
        itemasstockform: false,
        qtycheck: false,
        delivery: false,
        cooking: false,
        addtomenu: false,
        showadvanced: false,
        mainmenusmenu: state.mainmenu[1] ? state.mainmenu[1]._id : '',
        menubuttons: state.mainmenu[1] ? state.mainmenu[1].menubtns : '',
        listing: 'menu',
        mainbtnsmenu: [],
        addVariant: false,
        addModifier: false,
        addSizes: false,
        variants: [
          {
            values: []
          }
        ],
        stockVariants: [],
        colormenu: '',
        cookIns: [],
        productNameError: false,
        codemenu: '',
        qtymenu: '',
        userlistheadmenu: 'Add Product',
        userlistsubmenu: 'Add a New Product',
        userlistbuttonmenu: 'ADD PRODUCT',
        cookingState: {
          ...prevState.cookingState,
          selectitems: state.selectitems,
          stockitems: state.stockList
        },
        fromTable: false
      }));
      setModState(prevState => ({
        ...prevState,
        cookInsFilter: [],
        selectitemsFilter: [],
        modifiers: []
      }));
      setSizes([]);
    } else {
      setModState(prevState => ({
        ...prevState,
        selectitemsFilter: [],
        modifiers: []
      }));
      onComplete(null, state.currentPage);
    }
  };

  const handleChangeMenu = (eventN, key) => {
    let event = Object.assign({}, eventN);
    if (event.target.name === 'color') {
      setState(prevState => ({
        ...prevState,
        colormenu: event.target.value
      }));
    }
    if (event.target.name === 'mainmenu') {
      if (event.target.value === 'No Catalogue') {
        if (state.userlistbuttonmenu === 'EDIT PRODUCT') {
          props.openSnackbar('Cant change Product to Stock Item');
          return;
        }
        setState(prevState => ({
          ...prevState,
          mainmenusmenu: event.target.value
        }));
      } else {
        if (state.mainmenu[key.key.replace(".$", "")].menubtns) {
          if (state.mainmenu[key.key.replace(".$", "")].menubtns[0]) {
            if (state.mainmenu[key.key.replace(".$", "")].menubtns[0].name !== 'No Category') {
              state.mainmenu[key.key.replace(".$", "")].menubtns.unshift({
                _id: 'No Category',
                name: 'No Category'
              });
            }
          }
        }
        setState(prevState => ({
          ...prevState,
          menubuttons: state.mainmenu[key.key.replace(".$", "")].menubtns,
          mainmenusmenu: event.target.value
        }));
      }
    }
  };

  const handleChangeItemCategory = (eventN, record) => {
    let event = Object.assign({}, eventN);
    if (event.target.value.length === 0) {
      //  props.openSnackbar('Item categories cannot be empty');
      return;
    } else if (!event.target.value[event.target.value.length - 1]) {
      let data = state.menuitemsCatMultiOpen;
      data[record._id] = false;
      setState(prevState => ({
        ...prevState,
        menuitemsCatMultiOpen: data
      }));
    } else {
      record.menubtns = event.target.value;
      const token = props.user.token;
      let menuitems = state.menuitems;
      for (var i = 0; i < menuitems.length; i++) {
        if (menuitems[i]._id === record._id) {
          menuitems[i] = record;
        }
      }
      setState(prevState => ({
        ...prevState,
        loading: true,
        menuitems: menuitems
      }));
      record.menubtns = record.menubtns.filter(e => e !== 'No Category');
      props
        .editMenuItemTable(record, token)
        .then(res => {
          setState(prevState => ({
            ...prevState,
            loading: false
          }));
        })
        .catch(err => {
          onError(err);
        });
      return;
    }
  };

  const handleChangeItemType = (eventN, record) => {
    let event = Object.assign({}, eventN);
    record.typeid = event.target.value;
    record.type = event.currentTarget.textContent;
    const token = props.user.token;
    let menuitems = state.menuitems;
    for (var i = 0; i < menuitems.length; i++) {
      if (menuitems[i]._id === record._id) {
        menuitems[i] = record;
      }
    }
    setState(prevState => ({
      ...prevState,
      loading: true,
      menuitems: menuitems
    }));
    props
      .editMenuItemTable(record, token)
      .then(res => {
        setState(prevState => ({
          ...prevState,
          loading: false
        }));
      })
      .catch(err => {
        onError(err);
      });
    return;
  };
  const handleChangeItemBrand = (eventN, record) => {
    let event = Object.assign({}, eventN);
    record.brandid = event.target.value;
    record.brand = event.currentTarget.textContent;
    const token = props.user.token;
    let menuitems = state.menuitems;
    for (var i = 0; i < menuitems.length; i++) {
      if (menuitems[i]._id === record._id) {
        menuitems[i] = record;
      }
    }
    setState(prevState => ({
      ...prevState,
      loading: true,
      menuitems: menuitems
    }));
    props
      .editMenuItemTable(record, token)
      .then(res => {
        setState(prevState => ({
          ...prevState,
          loading: false
        }));
      })
      .catch(err => {
        onError(err);
      });
    return;
  };

  const closeDialog = () => {
    setState(prevState => ({
      ...prevState,
      alert: false,
      impPassDialog: false,
      impPass: ''
    }));
    setDialogState([]);
  };

  const onError = (err, newHead) => {
    var head = 'Product Error';
    if (newHead) {
      head = newHead;
    }
    setState(prevState => ({
      ...prevState,
      head: head,
      alert: true,
      msg: [err],
      loading: false
    }));
  };

  const onComplete = (res, page, addname) => {
    setSizes([]);
    setMenuItemsState(prevState => ({
      ...prevState,
      ...initMenuitemsState
    }));
    setState(prevState => ({
      ...prevState,
      ...initialState,
      filteredInfo: state.filteredInfo
    }));
    setModState(prevState => ({
      ...prevState,
      ...initialModstate
    }));
    setTempState(initialTempstateEcom);
    setDialogState([]);
    initGetMenuItems(res, page, addname);
  };

  const submitForm = async (oEvent, newState) => {
    const token = props.user.token;
    setState(prevState => ({
      ...prevState,
      loading: true
    }));

    var copyState = Object.assign({}, state);
    if (newState) {
      copyState = Object.assign({}, newState);
    }
    var copyModState = Object.assign({}, modstate);
    if (copyState.supplier === 'none' || !copyState.supplier) {
      copyState.supplier = [];
    }
    for (var su = 0; su < copyState.supplier.length; su++) {
      if (copyState.supplier[su] === 'none') {
        copyState.supplier = [];
        break;
      }
    }
    if (copyState.mainmenusmenu === 'No Catalogue') {
      copyState.mainmenusmenu = '';
    }
    copyState.variants = [];
    if (copyState.stockitems.length > 0) {
      var newStockArray = [];
      let stockitems = copyState.stockitems;
      for (var j = 0; j < stockitems.length; j++) {
        if (
          j === 0 &&
          copyState.useDim &&
          copyState.itemasmenucompo &&
          (stockitems[j]._id === 'addnew' ||
            !stockitems[j]._id ||
            !stockitems[j].name)
        ) {
          onError('Composite Item not found or InComplete', 'Add Product');
          return;
        }
        if (
          stockitems[j].name &&
          stockitems[j]._id &&
          stockitems[j]._id !== 'addnew'
        ) {
          if (stockitems[j].dimChange) {
            delete stockitems[j].dimChange;
          }
          if (stockitems[j].qty < 0 || stockitems[j].qty === '') {
            stockitems[j].qty = 1;
          } else {
            stockitems[j].qty = parseFloat(stockitems[j].qty || '1');
          }
          if (!stockitems[j].cost) {
            stockitems[j].cost = '0';
          }
          if (!stockitems[j].dimension) {
            stockitems[j].dimension = 'unit';
            stockitems[j].dimType = 'unit';
          }
          if (stockitems[j].dimension !== 'unit' && !stockitems[j].dimType) {
            stockitems[j].dimension = 'unit';
            stockitems[j].dimType = 'unit';
          }
          newStockArray.push(stockitems[j]);
        }
      }
      copyState.stockitems = newStockArray;
    }

    if (copyState.addModifier || copyState.stockVariants.length > 0) {
      if (copyState.stockVariants.length > 0) {
        let newStockVariants = [];
        copyState.stockVariants.forEach((stockitem, mIndex) => {
          if (stockitem.name && !stockitem.newVarient) {
            delete stockitem.key;
            delete stockitem.types;
            delete stockitem.dimtypes;
            delete stockitem.modKeyList;
            if (stockitem.packBuyPrice < 1) {
              stockitem.packBuyPrice = '0';
            }
            if (stockitem.packSize < 1) {
              stockitem.packSize = 1;
            }
            if (stockitem.dimension !== 'unit' && stockitem.packBuyPrice > 0) {
              stockitem.buyPrice = (
                parseFloat(stockitem.packBuyPrice) /
                parseFloat(stockitem.packSize) /
                1
              ).toString();
            } else {
              stockitem.buyPrice = stockitem.packBuyPrice;
            }
            if (!stockitem.buyPrice) stockitem.buyPrice = '0';
            if (!stockitem.sellPrice) stockitem.sellPrice = 0;
            if (!stockitem.agentPrice) stockitem.agentPrice = 0;
            if (!stockitem.specialPrice) stockitem.specialPrice = 0;
            if (!stockitem.dimension) stockitem.dimension = 'unit';
            if (stockitem.dimension !== 'unit' && !stockitem.dimType)
              stockitem.dimension = 'unit';
            if (!stockitem.qty) stockitem.qty = '0';
            if (!stockitem.serving) stockitem.serving = '1';
            if (!stockitem.lowqty) stockitem.lowqty = '10';
            if (!stockitem.code) stockitem.code = '';
            if (stockitem.supplier === 'none') stockitem.supplier = [];
            if (
              stockitem.dimension !== 'unit' &&
              stockitem.serving &&
              !stockitem.servingType
            )
              stockitem.servingType = stockitem.dimType;
            stockitem.serving = parseFloat(stockitem.serving);
            stockitem.qty = parseFloat(stockitem.qty);
            stockitem.lowqty = parseFloat(stockitem.lowqty);
            if (copyState.addSizes) {
              if (sizes.length > 0) {
                sizes.forEach((size, zIndex) => {
                  if (size.name) {
                    var newsize = {
                      dimension: stockitem.dimension,
                      dimType: stockitem.dimType,
                      qty: size.qty,
                      name: size.name
                    };
                    stockitem.sizes.push(newsize);
                    stockitem.stockSize = true;
                  }
                });
              } else {
                stockitem.stockSize = false;
              }
            } else {
              stockitem.stockSize = false;
            }
            copyState.variants.push(stockitem._id);
            newStockVariants.push(stockitem);
            //copyState.stockVariants[mIndex] = stockitem;

            if (
              copyState.stockVariants.length === 1 &&
              stockitem.name === copyState.namemenu &&
              copyState.stockitems.length === 0
            ) {
              let newStockMenu = {
                _id: stockitem._id,
                name: stockitem.name,
                active: true,
                qty: stockitem.serving,
                dimension: stockitem.dimension,
                dimType: stockitem.dimType,
                code: stockitem.code,
                cost: stockitem.buyPrice
              };
              copyState.stockitems = [];
              copyState.stockitems.push(newStockMenu);
            }
          }
        });

        if (newStockVariants.length > 0) {
          await props.addMultiStockItems(newStockVariants, token);
        }
      }

      if (copyState.addModifier) {
        if (modstate.modifiers.length > 0) {
          copyState.cookIns = [];
          let newModifiersAdd = [];
          copyModState.modifiers.forEach((modifier, mIndex) => {
            if (modifier.cookName && modifier.cookIns.length > 0) {
              modifier.name = modifier.cookName;
              delete modifier.cookName;
              delete modifier.modKey;
              delete modifier.setModState;
              if (!modifier.limit && modifier.limit !== 0) {
                modifier.limit = 1;
              }
              copyModState.modifiers[mIndex] = modifier;
              var oCook = {
                cookid: modifier._id,
                limit: modifier.limit >= 0 ? modifier.limit : 1,
                flow: true,
                force: false
              };
              newModifiersAdd.push(modifier);
              copyState.cookIns.push(oCook);
            }
          });
          if (newModifiersAdd.length > 0) {
            copyState.cooking = true;
            await props.addMultiMods(newModifiersAdd, token);
          }
        } else {
          copyState.cooking = false;
          copyState.cookIns = [];
        }
      } else {
        copyState.cooking = false;
        copyState.cookIns = [];
      }
      if (copyState.userlistbuttonmenu === 'ADD PRODUCT') {
        props
          .addMenuItem(copyState, token)
          .then(res => {
            if (res.data.results.error) {
              onError(res.data.results.error);
            } else {
              onComplete('Product Creation Successful', 0, copyState.namemenu);
            }
          })
          .catch(err => {
            onError(err);
          });
      } else if (copyState.userlistbuttonmenu === 'EDIT PRODUCT') {
        props
          .editMenuItem(copyState, token)
          .then(res => {
            onComplete('Item Update Successful', copyState.currentPage);
          })
          .catch(err => {
            onError(err);
          });
      }
    } else {
      if (copyState.userlistbuttonmenu === 'ADD PRODUCT') {
        props
          .addMenuItem(copyState, token)
          .then(res => {
            if (res.data.results.error) {
              onError(res.data.results.error);
            } else {
              onComplete('Product Creation Successful', 0, copyState.namemenu);
            }
          })
          .catch(err => {
            onError(err);
          });
      } else if (copyState.userlistbuttonmenu === 'EDIT PRODUCT') {
        copyState.cooking = false;
        copyState.cookIns = [];
        props
          .editMenuItem(copyState, token)
          .then(res => {
            onComplete('Item Update Successful', copyState.currentPage);
          })
          .catch(err => {
            onError(err);
          });
      }
    }
  };

  const handleEditMenuItem = (oMenu, tab) => {
    handleAddBreadCrumb({ method: 'handleOpenMenu', crumb: 'Edit Product' });
    let oMenuC = Object.assign({}, oMenu);
    var menuCheck = false;
    var totalCostPrice = 0;
    if (oMenuC.mainmenu) {
      menuCheck = true;
    }
    if (!oMenuC.variants) {
      oMenuC.variants = [];
    }
    if (!oMenuC.modifiers) {
      oMenuC.modifiers = [];
    }
    if (!oMenuC.stockitems) {
      oMenuC.stockitems = [];
    }
    if (oMenuC.stockitems.length > 0) {
      if (oMenuC.stockitems.length > 0) {
        for (var i = 0; i < oMenuC.stockitems.length; i++) {
          if (oMenuC.stockitems[i].cost) {
            totalCostPrice += parseFloat(oMenuC.stockitems[i].cost);
          }
        }
      }
    }
    setState(prevState => ({
      ...prevState,
      tabNumber: tab || '1',
      totalCostPrice: totalCostPrice,
      _idmenu: oMenuC._id,
      typemenu: oMenuC.type,
      brandmenu: oMenuC.brand,
      images: oMenuC.images,
      qtycheck: oMenuC.qtycheck || false,
      delivery: oMenuC.delivery || false,
      typemenuid: oMenuC.typeid,
      brandmenuid: oMenuC.brandid,
      cooking: oMenuC.cooking,
      cookIns: oMenuC.cookinslist || [],
      codemenu: oMenuC.code,
      namemenu: oMenuC.name,
      descriptionmenu: oMenuC.description,
      colormenu: oMenuC.color,
      mainmenusmenu: oMenuC.mainmenu || '',
      listing: oMenuC.listing || 'menu',
      mainbtnsmenu: oMenuC.menubtns || [],
      sellPrice: oMenuC.sellPrice,
      agentPrice: oMenuC.agentPrice,
      specialPrice: oMenuC.specialPrice,
      specialActive: oMenuC.specialActive,
      costPrice:
        oMenuC.costPrice && oMenuC.costPrice !== '0'
          ? parseFloat('' + oMenuC.costPrice).toFixed(2)
          : '0',
      stockitems:
        oMenuC.stockitems.length > 0
          ? oMenuC.stockitems
          : [
              {
                _id: '',
                name: '',
                active: true,
                qty: '1',
                dimension: '',
                dimType: '',
                code: '',
                cost: ''
              }
            ],
      menuadd1: oMenuC.stockitems
        ? oMenuC.stockitems.length > 0
          ? true
          : false
        : false,
      itemasmenucompo: oMenuC.stockitems.length > 0 ? true : false,
      qtymenu: '',
      main: false,
      mainTypeInput: [],
      typeName: '',
      mainBrandInput: [],
      brandName: '',
      useDim: oMenuC.useDim || false,
      itemasstock: false,
      itemasstockform: false,
      itemasmenulast:
        (oMenuC.variants.length > 0 || oMenuC.modifiers.length > 0) &&
        oMenuC.stockitems.length === 0
          ? true
          : false,
      itemasstockformformenuitem: oMenuC.variants.length > 0 ? true : false,
      addModifier: oMenuC.cooking,
      addVariant: oMenuC.variants.length > 0 ? true : false,
      addSizes: oMenuC.variants.length > 0 ? true : false,
      stockVariants: oMenuC.variants || [],
      addtomenu: menuCheck,
      showadvanced: false,
      active: oMenuC.active || false,
      userlistheadmenu: 'Edit Product',
      userlistsubmenu: 'Edit a Product',
      userlistbuttonmenu: 'EDIT PRODUCT',
      cookingState: {
        ...prevState.cookingState,
        selectitems: state.selectitems,
        stockitems: state.stockList
      }
    }));
    setModState(prevState => ({
      ...prevState,
      modifiers: oMenuC.modifiers || []
    }));
    if (oMenu.mainmenu) {
      for (var i = 0; i < state.mainmenu.length; i++) {
        if (state.mainmenu[i]._id === oMenu.mainmenu) {
          if (state.mainmenu[i].menubtns[0]) {
            if (state.mainmenu[i].menubtns[0].name !== 'No Category') {
              state.mainmenu[i].menubtns.unshift({
                _id: 'No Category',
                name: 'No Category'
              });
              setState(prevState => ({
                ...prevState,
                menubuttons: state.mainmenu[i].menubtns
              }));
              break;
            }
          } else {
            setState(prevState => ({
              ...prevState,
              menubuttons: [
                {
                  _id: 'No Category',
                  name: 'No Category'
                }
              ]
            }));
            break;
          }
        }
      }
    }
  };

  const handleFormatList = format => {
    setState(prevState => ({
      ...prevState,
      activeFilter: format
    }));
  };

  const handleDeleteMenuItem = oMenu => {
    const token = props.user.token;
    setState(prevState => ({
      ...prevState,
      loading: true
    }));
    if (oMenu._idmenu) {
      oMenu._id = oMenu._idmenu;
    }
    props
      .deleteMenuItem(oMenu, token)
      .then(res => {
        onComplete('Product Delete Successful', state.currentPage);
      })
      .catch(err => {
        onError(err);
      });
  };

  const handleRestoreMenuItem = oMenu => {
    const token = props.user.token;
    setState(prevState => ({
      ...prevState,
      loading: true
    }));
    if (oMenu._idmenu) {
      oMenu._id = oMenu._idmenu;
    }
    props
      .restoreMenuItem(oMenu, token)
      .then(res => {
        onComplete('Product Restored Successful', state.currentPage);
      })
      .catch(err => {
        onError(err);
      });
  };

  const onHelpMarker = marker => {
    props.setHelpMarker(marker);
  };

  const updateMenuItemCost = compoStateCost => {
    if (!compoStateCost) {
      compoStateCost = '0';
    }
    setState(prevState => ({
      ...prevState,
      costPrice: parseFloat(compoStateCost).toFixed(2)
    }));
  };

  /*******Stock Variant Generator********/
  const removeVariantPI = stockVariantN => {
    if (state.cooking) {
      let stockVariant = stockVariantN;
      let aStock = Object.assign([], state.cookingState.selectitems);
      let oStock = Object.assign({}, state.cookingState.stockitems);
      let stockKey = 0;
      for (let j = 0; j < aStock.length; j++) {
        if (aStock[j].key) {
          if (aStock[j].key.includes(stockVariant._id)) {
            stockKey = j;
            break;
          }
        }
      }
      aStock.splice(stockKey, 1);
      delete oStock[stockVariant._id];

      let newModifiers = Object.assign([], modstate.modifiers);
      const stockitems = oStock;
      if (newModifiers.length > 0) {
        newModifiers.forEach((modifier, mIndex) => {
          const newIds = modifier.cookIns.slice();
          if (newIds.length > 0) {
            newIds.forEach((cookIns, cIndex) => {
              if (cookIns.stockList) {
                if (cookIns.stockList.length > 0) {
                  cookIns.stockList.forEach((item, sIndex) => {
                    if (item.stockId === stockVariant._id) {
                      if (cookIns.stockList.length > 1) {
                        cookIns.stockList.splice(sIndex, 1);
                      } else {
                        newModifiers[mIndex].cookIns.splice(cIndex, 1);
                      }
                    }
                  });
                  if (cookIns.length > 0) {
                    newModifiers[mIndex].cookIns = cookIns;
                  }
                }
              }
            });
          }
        });
      }
      setState(prevState => ({
        ...prevState,
        cookingState: {
          ...prevState.cookingState,
          selectitems: aStock,
          stockitems: oStock
        }
      }));
      setModState(prevState => ({
        ...prevState,
        modifiers: newModifiers
      }));
    }
  };

  const updateVariantDim = (stockVariantN, changeDimCheck) => {
    if (state.cooking) {
      let stockVariant = stockVariantN;
      let aStock = Object.assign([], state.cookingState.selectitems);
      let oStock = Object.assign({}, state.cookingState.stockitems);
      let stockKey = 0;
      for (let j = 0; j < aStock.length; j++) {
        if (aStock[j].key) {
          if (aStock[j].key.includes(stockVariant._id)) {
            stockKey = j;
            break;
          }
        }
      }
      var oStockList = {
        key: stockVariant._id + '|' + stockVariant.dimension,
        value: stockVariant.name
      };
      aStock[stockKey] = oStockList;
      oStock[stockVariant._id] = stockVariant;
      let newModifiers = Object.assign([], modstate.modifiers);
      const stockitems = oStock;
      for (var i = 0; i < newModifiers.length; i++) {
        const newIds = newModifiers[i].cookIns.slice();
        for (var j = 0; j < newIds.length; j++) {
          for (var k = 0; k < newIds[j].stockList.length; k++) {
            if (newIds[j].stockList[k].stockId === stockVariant._id) {
              newIds[j].stockList[k].dimension = stockVariant.dimension;
              newIds[j].stockList[k].dimtypes =
                dimSelectTypes[stockVariant.dimension];
              if (changeDimCheck) {
                newIds[j].stockList[k].dimType = stockVariant.dimType;
              }
              newIds[j].stockList[k].qty = 1;
              var metricAmount = newIds[j].stockList[k].qty;
              if (newIds[j].stockList[k].dimension !== 'unit') {
                metricAmount = convert(newIds[j].stockList[k].qty)
                  .from(newIds[j].stockList[k].dimType)
                  .to(stockitems[newIds[j].stockList[k].stockId].dimType);
              }
              var costPrice = newIds[j].stockList[k].stockId
                ? stockitems[newIds[j].stockList[k].stockId].buyPrice
                : '0';
              var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
              newIds[j].stockList[k].cost = totalCost.toFixed('2');
              var costTotal = 0;
              if (newIds[j].stockList.length > 0) {
                for (var l = 0; l < newIds[j].stockList.length; l++) {
                  if (newIds[j].stockList[l].cost) {
                    costTotal += parseFloat(newIds[j].stockList[l].cost || '0');
                  }
                }
                newIds[j].cost = costTotal.toFixed('2');
              }
            }
          }
          newModifiers[i].cookIns = newIds;
        }
      }
      setState(prevState => ({
        ...prevState,
        cookingState: {
          ...prevState.cookingState,
          selectitems: aStock,
          stockitems: oStock
        }
      }));
      setModState(prevState => ({
        ...prevState,
        modifiers: newModifiers
      }));
    }
  };

  const updateVariantStockCost = stockVariantN => {
    let stockVariant = stockVariantN;
    if (state.cooking) {
      let stockVarientsModKeys = stockVariant.modKeyList;
      let oStock = state.cookingState.stockitems;
      let newModifiers = Object.assign([], modstate.modifiers);
      const stockitems = oStock;
      if (stockVarientsModKeys) {
        for (var j = 0; j < stockVarientsModKeys.length; j++) {
          var mod = stockVarientsModKeys[j].mod;
          var ops = stockVarientsModKeys[j].ops;
          var k = stockVarientsModKeys[j].stock;
          const newIds = newModifiers[mod].cookIns.slice();
          if (newIds[ops].stockList[k].stockId === stockVariant._id) {
            newIds[ops].stockList[k].dimension = stockVariant.dimension;
            newIds[ops].stockList[k].dimtypes =
              dimSelectTypes[stockVariant.dimension];
            newIds[ops].stockList[k].dimType =
              dimSelectTypes[stockVariant.dimension][0].value;
            newIds[ops].stockList[k].qty = 1;
            var metricAmount = newIds[ops].stockList[k].qty;
            if (newIds[ops].stockList[k].dimension !== 'unit') {
              metricAmount = convert(newIds[ops].stockList[k].qty)
                .from(newIds[ops].stockList[k].dimType)
                .to(stockitems[newIds[ops].stockList[k].stockId].dimType);
            }
            var costPrice = newIds[ops].stockList[k].stockId
              ? stockitems[newIds[ops].stockList[k].stockId].buyPrice
                ? stockitems[newIds[ops].stockList[k].stockId].buyPrice
                : '0'
              : '0';
            var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
            newIds[ops].stockList[k].cost = totalCost.toFixed('2');
            //newIds[ops].cost = totalCost.toFixed('2');
            var costTotal = 0;
            if (newIds[ops].stockList.length > 0) {
              for (var l = 0; l < newIds[ops].stockList.length; l++) {
                if (newIds[ops].stockList[l].cost) {
                  costTotal += newIds[ops].stockList[l].cost
                    ? parseFloat(newIds[ops].stockList[l].cost)
                    : 0;
                }
              }
              newIds[ops].cost = costTotal.toFixed('2');
            }
            if (newIds[ops].setModState) {
              newIds[ops].setModState(prevState => ({
                ...prevState,
                ...newIds[ops]
              }));
            }
          }
        }
      }
    }
    state.cookingState.stockitems[stockVariant._id] = stockVariant;
  };

  const allPossibleCases = arr => {
    if (arr.length === 0) {
      return [];
    } else if (arr.length === 1) {
      return arr[0];
    } else {
      var result = [];
      var allCasesOfRest = allPossibleCases(arr.slice(1)); // recur with the rest of array
      for (var c in allCasesOfRest) {
        for (var i = 0; i < arr[0].length; i++) {
          result.push(arr[0][i] + ' ' + allCasesOfRest[c]);
        }
      }
      return result;
    }
  };

  const createVariants = (varientState, editCheck) => {
    setState(prevState => ({
      ...prevState,
      ...varientState
    }));
    let variants = Object.assign([], varientState.variants);
    let aStock = Object.assign([], state.cookingState.selectitems);
    let oStock = Object.assign({}, state.cookingState.stockitems);
    let stockVariants = state.stockVariants || [];
    let oldmModifiers = modstate.modifiers || [];
    let name = state.namemenu;
    var menuItems = state.menuitems;
    var stockitems = state.stockList;
    var skuheader = state.typemenu
      .replace(/\s/g, '')
      .slice(0, 3)
      .toUpperCase();
    var footer = '10000';
    var counter = 0;
    Object.keys(stockitems).map((stockid, key) => {
      if (stockitems[stockid].code.slice(0, 3).toUpperCase() === skuheader) {
        counter = counter + 1;
      }
    });
    if (
      (state.itemasstockformformenuitem || editCheck) &&
      variants[0].values.length > 0
    ) {
      var arrayCollection = [];
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].values.length > 0) {
          arrayCollection.push(variants[i].values);
        }
      }
      var newArray = allPossibleCases(arrayCollection);
      var ckStockVariants = [];
      for (let svs = 0; svs < stockVariants.length; svs++) {
        var checkEx = false;
        for (let na = 0; na < newArray.length; na++) {
          if (stockVariants[svs].name === name + ' ' + newArray[na]) {
            checkEx = true;
          }
        }
        if (checkEx) {
          if (stockVariants[svs].code.slice(0, 3).toUpperCase() === skuheader) {
            counter = counter + 1;
            footer =
              footer.slice(0, -counter.toString().length) + counter.toString();
            stockVariants[svs].code = skuheader + footer;
          }
          stockVariants[svs].key = ckStockVariants.length;
          ckStockVariants.push(stockVariants[svs]);
        }
      }
      stockVariants = ckStockVariants;
      let newArrayLengthCheck = newArray.length;
      for (let i = 0; i < newArray.length; i++) {
        var check = false;
        if (stockVariants) {
          for (let sv = 0; sv < stockVariants.length; sv++) {
            if (stockVariants[sv].name === name + ' ' + newArray[i]) {
              check = true;
            }
          }
        }
        if (!check) {
          counter = counter + 1;
          footer =
            footer.slice(0, -counter.toString().length) + counter.toString();
          var stock = {
            _id: ObjectID().toHexString(),
            name: name + ' ' + newArray[i],
            key: stockVariants.length,
            type: state.typemenu,
            code: skuheader + footer,
            description: '',
            supplier:
              state.supplier &&
              state.supplier.length > 0 &&
              state.supplier[0] !== 'none'
                ? state.supplier
                : [],
            packSize: 1,
            buyPrice: '0',
            packBuyPrice: newArrayLengthCheck <= 1 ? state.costPrice : '0',
            dimension: 'unit',
            dimtypes: [],
            dimType: '',
            serving: '1',
            servingType: '',
            qty: '1',
            lowqty: '10',
            stockSize: false,
            sizes: []
          };
          stockVariants.push(stock);
          var oStockList = {
            key: stock._id + '|' + stock.dimension,
            value: stock.name
          };
          aStock.push(oStockList);
          oStock[stock._id] = stock;
        }
      }
      if (varientState.createModsFromVariants) {
        let modifiers = [
          {
            _id: ObjectID().toHexString(),
            modKey: 0,
            cookName: name + ' ' + 'Modifiers',
            cookIns: []
          }
        ];
        if (oldmModifiers && oldmModifiers[0]) {
          var ckoldmModifiers = [];
          for (let svs = 0; svs < oldmModifiers[0].cookIns.length; svs++) {
            var checkEx = false;
            for (let na = 0; na < stockVariants.length; na++) {
              if (
                oldmModifiers[0].cookIns[svs].name === stockVariants[na].name
              ) {
                checkEx = true;
              }
            }
            if (checkEx) {
              ckoldmModifiers.push(oldmModifiers[0].cookIns[svs]);
            }
          }
          oldmModifiers[0].cookIns = ckoldmModifiers;
        }
        if (oldmModifiers) {
          if (oldmModifiers[0]) {
            if (modifiers[0].cookName === oldmModifiers[0].cookName) {
              modifiers[0] = oldmModifiers[0];
            }
          }
        }
        for (var i = 0; i < stockVariants.length; i++) {
          var stockitem = stockVariants[i];
          stockVariants[i].modKeyList = [
            {
              mod: modifiers[0].modKey,
              ops: i,
              opsname: stockitem.name,
              stock: 0,
              varientid: stockitem._id
            }
          ];
          var check = false;
          if (oldmModifiers && oldmModifiers[0]) {
            for (let om = 0; om < oldmModifiers[0].cookIns.length; om++) {
              if (oldmModifiers[0].cookIns[om].name === stockitem.name) {
                check = true;
              }
            }
          }
          if (!check) {
            var newModifier = {
              name: stockitem.name,
              price: 0,
              cost: '0',
              stockList: []
            };
            var newStock = {
              stock: stockitem.name,
              stockId: stockitem._id,
              dimension: stockitem.dimension,
              dimtypes: dimSelectTypes[stockitem.dimension],
              dimType: stockitem.dimType || stockitem.dimension,
              cost: '0',
              qty: 1
            };
            newModifier.stockList.push(newStock);
            modifiers[0].cookIns.push(newModifier);
          }
        }
        setModState(prevState => ({
          ...prevState,
          modifiers: modifiers
        }));
        setState(prevState => ({
          ...prevState,
          cooking: true,
          addModifier: true
        }));
      } else if (state.listing === 'ecommerce') {
        let modifiers = [];
        variants.forEach((variant, index) => {
          modifiers.push({
            _id: ObjectID().toHexString(),
            modKey: index,
            cookName: name + ' ' + variant.option,
            cookIns: []
          });
        });
        if (oldmModifiers && oldmModifiers[0]) {
          var ckoldmModifiers = [];
          for (let svs = 0; svs < oldmModifiers[0].cookIns.length; svs++) {
            var checkEx = false;
            for (let na = 0; na < stockVariants.length; na++) {
              if (
                oldmModifiers[0].cookIns[svs].name === stockVariants[na].name
              ) {
                checkEx = true;
              }
            }
            if (checkEx) {
              ckoldmModifiers.push(oldmModifiers[0].cookIns[svs]);
            }
          }
          oldmModifiers[0].cookIns = ckoldmModifiers;
        }
        if (oldmModifiers) {
          for (var i = 0; i < oldmModifiers.length; i++) {
            if (oldmModifiers[i]) {
              for (var j = 0; j < modifiers.length; j++) {
                if (modifiers[j].cookName === oldmModifiers[i].cookName) {
                  modifiers[j] = oldmModifiers[i];
                }
              }
            }
          }
        }
        for (var i = 0; i < stockVariants.length; i++) {
          var stockitem = stockVariants[i];
          stockVariants[i].modKeyList = [];
          for (var j = 0; j < modifiers.length; j++) {
            stockVariants[i].modKeyList.push({
              mod: modifiers[j].modKey,
              ops: j,
              opsname: stockitem.name,
              stock: 0,
              varientid: stockitem._id
            });
          }
          var check = false;
          if (oldmModifiers) {
            for (var k = 0; k < oldmModifiers.length; k++) {
              if (oldmModifiers[k]) {
                for (let om = 0; om < oldmModifiers[k].cookIns.length; om++) {
                  if (oldmModifiers[k].cookIns[om].name === stockitem.name) {
                    check = true;
                  }
                }
              }
            }
          }
          if (!check) {
            var newModifier = {
              name: stockitem.name,
              price: 0,
              cost: '0',
              stockList: []
            };
            var newStock = {
              stock: stockitem.name,
              stockId: stockitem._id,
              dimension: stockitem.dimension,
              dimtypes: dimSelectTypes[stockitem.dimension],
              dimType: stockitem.dimType || stockitem.dimension,
              cost: '0',
              qty: 1
            };
            newModifier.stockList.push(newStock);
            for (var l = 0; l < modifiers.length; l++) {
              modifiers[l].cookIns.push(newModifier);
            }
          }
        }
        setModState(prevState => ({
          ...prevState,
          modifiers: modifiers
        }));
        setState(prevState => ({
          ...prevState,
          cooking: true,
          addModifier: true
        }));
      } else {
        if (!editCheck) {
          setState(prevState => ({
            ...prevState,
            addModifier: false
          }));
          setModState(prevState => ({
            ...prevState,
            modifiers: []
          }));
        }
      }
    } else {
      if (state.itemasstockformformenuitem || editCheck) {
        stockVariants = [];
        aStock = Object.assign([], state.selectitems);
        oStock = Object.assign({}, state.stockList);
        counter = counter + 1;
        footer =
          footer.slice(0, -counter.toString().length) + counter.toString();
        skuheader = skuheader + footer;
        var stock = {
          _id: ObjectID().toHexString(),
          name: name,
          key: 0,
          type: state.typemenu,
          code: skuheader,
          description: '',
          supplier:
            state.supplier &&
            state.supplier.length > 0 &&
            state.supplier[0] !== 'none'
              ? state.supplier
              : [],
          packSize: 1,
          buyPrice: '0',
          packBuyPrice: state.costPrice,
          dimension: 'unit',
          dimtypes: [],
          dimType: '',
          serving: '1',
          servingType: '',
          qty: '1',
          lowqty: '10'
        };
        stockVariants.push(stock);

        var oStockList = {
          key: stock._id + '|' + stock.dimension,
          value: stock.name
        };
        aStock.push(oStockList);
        oStock[stock._id] = stock;
        if (!editCheck) {
          setState(prevState => ({
            ...prevState,
            addModifier: false
          }));
          setModState(prevState => ({
            ...prevState,
            modifiers: []
          }));
        }
      }
    }
    setState(prevState => ({
      ...prevState,
      itemasmenulast: true,
      stockVariants: stockVariants,
      cookingState: {
        ...prevState.cookingState,
        selectitems: aStock,
        stockitems: oStock
      }
    }));
  };

  const createVariantsEcom = (varientState, editCheck) => {
    setState(prevState => ({
      ...prevState,
      ...varientState
    }));
    let variants = Object.assign([], varientState.variants);
    let stockVariants = state.stockVariants || [];
    let name = state.namemenu;
    var menuItems = state.menuitems;
    var stockitems = state.stockList;
    var skuheader = state.typemenu
      .replace(/\s/g, '')
      .slice(0, 3)
      .toUpperCase();
    var footer = '10000';
    var counter = 0;
    Object.keys(stockitems).map((stockid, key) => {
      if (stockitems[stockid].code.slice(0, 3).toUpperCase() === skuheader) {
        counter = counter + 1;
      }
    });
    if (
      (state.itemasstockformformenuitem || editCheck) &&
      variants[0].values.length > 0
    ) {
      var arrayCollection = [];
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].values.length > 0) {
          arrayCollection.push(variants[i].values);
        }
      }
      var newArray = allPossibleCases(arrayCollection);

      /* Gen Mods */
      let modifiers = [];
      variants.forEach((variant, index) => {
        let newMod = {
          _id: ObjectID().toHexString(),
          modKey: index,
          cookName: name + ' ' + variant.option,
          ecom: true,
          generated: true,
          cookIns: []
        };
        for (let vv = 0; vv < variant.values.length; vv++) {
          if (variant.values[vv]) {
            var newModifier = {
              name: variant.values[vv],
              price: 0,
              cost: '0',
              linkId: createUUID(1),
              generated: true,
              stockList: []
            };
            newMod.cookIns.push(newModifier);
          }
        }
        modifiers.push(newMod);
      });
      setModState(prevState => ({
        ...prevState,
        modifiers: modifiers
      }));
      setState(prevState => ({
        ...prevState,
        cooking: true,
        addModifier: true
      }));

      /* Gen Variants */
      var ckStockVariants = [];
      for (let svs = 0; svs < stockVariants.length; svs++) {
        var checkEx = false;
        for (let na = 0; na < newArray.length; na++) {
          if (stockVariants[svs].name === name + ' ' + newArray[na]) {
            checkEx = true;
          }
        }
        if (checkEx) {
          if (stockVariants[svs].code.slice(0, 3).toUpperCase() === skuheader) {
            counter = counter + 1;
            footer =
              footer.slice(0, -counter.toString().length) + counter.toString();
            stockVariants[svs].code = skuheader + footer;
          }
          stockVariants[svs].key = ckStockVariants.length;
          ckStockVariants.push(stockVariants[svs]);
        }
      }
      stockVariants = ckStockVariants;
      let newArrayLengthCheck = newArray.length;
      for (let i = 0; i < newArray.length; i++) {
        var check = false;
        if (stockVariants) {
          for (let sv = 0; sv < stockVariants.length; sv++) {
            if (stockVariants[sv].name === name + ' ' + newArray[i]) {
              check = true;
            }
          }
        }
        if (!check) {
          counter = counter + 1;
          footer =
            footer.slice(0, -counter.toString().length) + counter.toString();
          var stock = {
            _id: ObjectID().toHexString(),
            linkId: '',
            linkIdArray: [],
            name: name + ' ' + newArray[i],
            key: stockVariants.length,
            type: state.typemenu,
            code: skuheader + footer,
            description: '',
            supplier:
              state.supplier &&
              state.supplier.length > 0 &&
              state.supplier[0] !== 'none'
                ? state.supplier
                : [],
            packSize: 1,
            buyPrice: '0',
            packBuyPrice: newArrayLengthCheck <= 1 ? state.costPrice : '0',
            sellPrice: 0,
            agentPrice: 0,
            specialPrice: 0,
            specialActive: false,
            dimension: 'unit',
            dimtypes: [],
            dimType: '',
            serving: '1',
            servingType: '',
            qty: '1',
            lowqty: '10',
            stockSize: false,
            sizes: []
          };
          stockVariants.push(stock);
        }
      }
      /* add Gen mods linkId to Gen Variants */

      for (let svml = 0; svml < stockVariants.length; svml++) {
        stockVariants[svml].linkId = '';
        stockVariants[svml].linkIdArray = [];
        let linkCount = 0;
        for (let md = 0; md < modifiers.length; md++) {
          for (let ck = 0; ck < modifiers[md].cookIns.length; ck++) {
            if (
              stockVariants[svml].name.indexOf(
                modifiers[md].cookIns[ck].name
              ) !== -1
            ) {
              stockVariants[svml].linkId += modifiers[md].cookIns[ck].linkId;
              stockVariants[svml].linkIdArray.push(
                modifiers[md].cookIns[ck].linkId
              );
              break;
            }
          }
        }
      }
    } else {
      if (state.itemasstockformformenuitem || editCheck) {
        stockVariants = [];
        counter = counter + 1;
        footer =
          footer.slice(0, -counter.toString().length) + counter.toString();
        skuheader = skuheader + footer;
        var stock = {
          _id: ObjectID().toHexString(),
          name: name,
          key: 0,
          type: state.typemenu,
          code: skuheader,
          description: '',
          supplier:
            state.supplier &&
            state.supplier.length > 0 &&
            state.supplier[0] !== 'none'
              ? state.supplier
              : [],
          packSize: 1,
          buyPrice: '0',
          packBuyPrice: state.costPrice,
          sellPrice: 0,
          agentPrice: 0,
          specialPrice: 0,
          specialActive: false,
          dimension: 'unit',
          dimtypes: [],
          dimType: '',
          serving: '1',
          servingType: '',
          qty: '1',
          lowqty: '10'
        };
        stockVariants.push(stock);
        if (!editCheck) {
          setState(prevState => ({
            ...prevState,
            addModifier: false
          }));
          setModState(prevState => ({
            ...prevState,
            modifiers: []
          }));
        }
      }
    }
    setState(prevState => ({
      ...prevState,
      itemasmenulast: true,
      stockVariants: stockVariants
    }));
  };

  /**********Table Props************/
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setState(prevState => ({
      ...prevState,
      searchText: selectedKeys[0],
      confirmState: confirm
    }));
  };
  const handleReset = clearFilters => {
    clearFilters();
    setState(prevState => ({
      ...prevState,
      searchText: '',
      confirmState: ''
    }));
  };
  const clearFilters = () => {
    setState(prevState => ({
      ...prevState,
      filteredInfo: null
    }));
  };
  var searchInput;
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (value) {
        if (
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  const getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  const handleSort = (pagination, filters, sorter) => {
    setState(prevState => ({
      ...prevState,
      filteredInfo: filters,
      sortedInfo: sorter
    }));
  };
  const changePage = page => {
    setState(prevState => ({
      ...prevState,
      currentPage: page
    }));
  };

  const changeView = menuitemState => {
    if (
      !menuitemState.itemasstockformformenuitem &&
      !menuitemState.itemasmenucompo &&
      menuitemState.cooking
    ) {
      menuitemState.itemasmenulast = true;
    }
    let showImages = false;
    if (
      menuitemState.listing === 'ecommerce' &&
      !menuitemState.itemasmenulast
    ) {
      showImages = true;
    }
    setState(prevState => ({
      ...prevState,
      ...menuitemState,
      itemasmenu: !menuitemState.itemasmenu,
      productNameError: false,
      showImages: showImages
    }));
  };

  const changeViewMods = newCompotate => {
    setState(prevState => ({
      ...prevState,
      stockitems: newCompotate.stockitems,
      totalCostPrice: newCompotate.totalCostPrice,
      itemasmenulast: true
    }));
  };

  const onShowSizeChange = (current, pageSize) => {
    setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };

  const onUseDimCheck = bool => {
    var check = false;
    if (state.stockitems.length > 1 && bool) {
      check = 'More then One Composite Items Found';
    } else if (modstate.modifiers.length > 0 && bool) {
      check = 'Modifiers Found';
    } else {
      setState(prevState => ({
        ...prevState,
        useDim: bool
      }));
    }
    return check;
  };

  const initialState = {
    tabNumber: '1',
    _idmenu: '',
    typemenu: '',
    typemenuid: '',
    qtycheck: false,
    delivery: false,
    cooking: false,
    createModsFromVariants: false,
    combineStock: false,
    useDim: false,
    cookIns: [],
    codemenu: '',
    namemenu: '',
    descriptionmenu: '',
    colormenu: '',
    dimensionmenu: '',
    dimTypemenu: '',
    dimtypesmenu: [],
    mainmenusmenu: '',
    mainmenuscompo: false,
    listing: 'menu',
    mainbtnsmenu: [],
    sellPrice: 0,
    agentPrice: 0,
    specialPrice: 0,
    specialActive: false,
    costPrice: '0',
    totalCostPrice: 0,
    qtymenu: '',
    alterStockState: {},
    stockitems: [
      {
        _id: '',
        name: '',
        active: true,
        qty: '1',
        dimension: '',
        dimType: '',
        code: '',
        cost: ''
      }
    ],
    suppliers: [],
    subcat: [],
    stockitemobject: {},
    addVariant: false,
    addModifier: false,
    addSizes: false,
    variants: [
      {
        values: []
      }
    ],
    stockVariants: [],
    typeName: '',
    mainTypeInput: [],
    types: [],
    mainBrandInput: [],
    brandName: '',
    brands: [],
    images: [],
    stockTableTypes: [],
    _id: '',
    type: '',
    code: '',
    name: '',
    description: '',
    supplier: [],
    packSize: 1,
    buyPrice: '0',
    packBuyPrice: '0',
    dimension: '',
    dimType: '',
    serving: '',
    servingType: '',
    qty: '',
    lowqty: '10',
    dimtypes: [],
    mainmenu: [],
    menubuttons: [],
    menuitems: [],
    csvData: [],
    selectitem: undefined,
    selectitems: [],
    stockList: {},
    dimat: [],
    loading: true,
    main: true,
    itemasstock: false,
    itemasstockform: false,
    itemasstockformformenuitem: false,
    itemasmenu: false,
    itemasmenulast: false,
    itemasmenucompo: false,
    addtomenu: false,
    showadvanced: false,
    mainSub: false,
    menuadd1: false,
    menuadd2: false,
    multiOpenSup: false,
    userlisthead: 'Add Stock Item',
    userlistsub: 'Add a New Stock Item',
    userlistbutton: 'ADD STOCK ITEM',
    userlistheadmenu: 'Add Product',
    userlistsubmenu: 'Add a New Product',
    userlistbuttonmenu: 'ADD PRODUCT',
    alert: false,
    impPassDialog: false,
    impPass: '',
    msg: [],
    head: '',
    productNameError: false,
    activeFilter: true,
    multiOpen: false,
    filteredInfo: null,
    categoryFilters: [],
    itemFilters: [],
    itemFiltersBrand: [],
    categoryFiltersPrep: [],
    itemFiltersPrep: [],
    itemFiltersPrepBrand: [],
    filename: 'Drop File here!',
    upload: false,
    staticTemplate: false,
    manualTemplate: false,
    ecomTemp: false,
    posTemp: false,
    errors: {},
    currentPage: 1,
    /***Stock View***/
    stockSize: false,
    /***Cooking View*****/
    cookingState: {
      _id: '',
      desc: '',
      cookName: '',
      inputCount: 0,
      cookIns: [],
      selectitems: [],
      stockitems: {},
      types: [],
      cookObject: {
        name: '',
        price: 0,
        cost: '0',
        stockList: []
      }
    }
  };

  const initStockState = {
    type: '',
    code: '',
    name: '',
    description: '',
    supplier: [],
    packSize: 1,
    buyPrice: '0',
    packBuyPrice: '0',
    dimension: 'unit',
    dimType: '',
    serving: '',
    servingType: '',
    qty: '',
    lowqty: '10',
    stockSize: false,
    sizes: []
  };

  const initialModstate = {
    cookInsList: [],
    cookInsFilter: [],
    cookingView: false,
    cookingObject: {},
    selectitemsFilter: [],
    modifiers: []
  };

  const initProps = {
    props: props,
    closeDialog: closeDialog,
    onHelpMarker: onHelpMarker
  };

  let initMenuitemsState = {
    menuitems: []
  };

  const setTableFilter = menuitemsN => {
    let stateMenuItems = menuitemsN || state.menuitems;
    let menuitems = [];
    for (var i = 0; i < stateMenuItems.length; i++) {
      if (state.activeFilter === true) {
        if (stateMenuItems[i].active) {
          menuitems.push(stateMenuItems[i]);
        }
      } else if (state.activeFilter === false) {
        if (!stateMenuItems[i].active) {
          menuitems.push(stateMenuItems[i]);
        }
      } else if (state.activeFilter === 'requests') {
        if (stateMenuItems[i].request) {
          menuitems.push(stateMenuItems[i]);
        }
      } else if (state.activeFilter === undefined) {
        menuitems.push(stateMenuItems[i]);
      }
    }
    setMenuItemsState(prevState => ({
      ...prevState,
      menuitems: menuitems
    }));
  };

  const checkImportPassword = e => {
    if (state.impPass === '#VCS@Importer@Open@2021!#') {
      setState(prevState => ({
        ...prevState,
        impPass: '',
        impPassDialog: false
      }));
      switchUpload();
    }
  };

  let sizes = [];
  let setSizes;
  let processing;
  let setProcessing;
  const onResetState = (childSetState, value) => {
    childSetState(prevState => ({
      ...prevState,
      listing: value,
      variants: [
        {
          values: []
        }
      ],
      stockVariants: [],
      cookingState: {
        ...prevState.cookingState,
        selectitems: state.selectitems,
        stockitems: state.stockList
      }
    }));
    setState(prevState => ({
      ...prevState,
      variants: [
        {
          values: []
        }
      ],
      stockVariants: [],
      cookingState: {
        ...prevState.cookingState,
        selectitems: state.selectitems,
        stockitems: state.stockList
      }
    }));
    setModState(prevState => ({
      ...prevState,
      cookInsFilter: [],
      selectitemsFilter: [],
      modifiers: []
    }));
    setSizes([]);
    //setProcessing(true);
  };

  props = allprops;
  state.props = allprops;
  [sizes, setSizes] = useState([]);
  [state, setState] = useState(initialState);
  [modstate, setModState] = useState(initialModstate);
  [menuitemsState, setMenuItemsState] = useState(initMenuitemsState);
  [processing, setProcessing] = useState(false);
  filteredInfo = state.filteredInfo || {};
  useEffect(() => {
    componentDidMount();
  }, []);
  useEffect(() => {
    setTableFilter();
  }, [state.activeFilter]);
  useEffect(() => {
    setProcessing(false);
  }, [processing]);
  useEffect(() => {
    var actionCrumb = props.actionCrumb;
    if (actionCrumb) {
      if (actionCrumb === 'handleOpenMenu') {
        handleOpenMenu(false);
        props.resetActionBreadCrumb();
        handleRemoveBreadCrumb();
      }
    }
  }, [props.actionCrumb]);

  const EyeContent = (
    <div style={{ width: '8em' }}>
      <Radio.Group
        id="RadioSelectAdmin"
        buttonStyle="solid"
        defaultValue={2}
        style={{ backgroundColor: 'transparent' }}
      >
        <StyledRadio.Button
          value={1}
          onClick={() => handleFormatList(undefined)}
        >
          All
        </StyledRadio.Button>
        <StyledRadio.Button
          value={2}
          onClick={() => {
            handleFormatList(true);
          }}
        >
          Active
        </StyledRadio.Button>
        <StyledRadio.Button
          value={3}
          onClick={() => {
            handleFormatList(false);
          }}
        >
          Inactive
        </StyledRadio.Button>
        {/*<Radio.Button value={3}
          style={{
            paddingBottom: "0.5em",
            paddingBottom: "0.5em",
            backgroundColor: 'transparent',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'black',
            fontSize: "16px",
          }}
          onClick={() => handleFormatList('requests')}
        >
          Requests
    </Radio.Button>*/}

        {/*      <Button
          style={{
            width: "9.2em",
            backgroundColor: 'white',
            border: '1px solid #00CC70',

            color: '#00CC70',

            height: '100%',
            borderRadius: '0.3em',



            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
      Export
    </Button> */}
      </Radio.Group>
    </div>
  );
  const [searchItems, setSearchItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (searchValue) {
      let menuItems = menuitemsState.menuitems;
      let searchItems = [];
      menuItems.forEach(item => {
        if (
          item.name
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.menubtns
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.type
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          searchItems.push(item);
        }
      });
      setSearchItems(searchItems);
    }
  }, [menuitemsState.menuitems]);
  const searchTable = value => {
    if (value) {
      let menuItems = menuitemsState.menuitems;
      let searchItems = [];
      menuItems.forEach(item => {
        if (
          item.name
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.menubtns
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.type
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          searchItems.push(item);
        }
      });
      setSearchValue(value);
      setSearchItems(searchItems);
    } else {
      setSearchValue('');
      setSearchItems([]);
    }
  };
  return (
    <div>
      <Loading
        show={state.loading}
        showSpinner={false}
        color="rgb(15, 135, 123)"
      />
      {state.loading ? (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            background: ' rgba(0, 0, 0, 0.01)',
            zIndex: '999999999'
          }}
        />
      ) : null}
      <AlertDialog state={state} props={props} closeDialog={closeDialog} />
      <FormDialog
        head="Import Password"
        msg="Enter the VPOS Import Password"
        formElements={[
          <CustomInput
            labelText="Password"
            id="impPass"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: e => {
                setState(prevState => ({
                  ...prevState,
                  impPass: e.currentTarget.value
                }));
              },
              value: state.impPass,
              autoFocus: true
            }}
          />
        ]}
        open={state.impPassDialog}
        close={closeDialog}
        actionBtn="Open"
        onClick={e => checkImportPassword(e, this)}
      />
      <Modal
        width={window.innerWidth}
        title="Data Preview"
        visible={dialogState.length}
        onOk={closeDialog}
        onCancel={closeDialog}
      >
        <UiTable style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {Object.keys(tempState).map(
                (item, index) =>
                  index < 7 && (
                    <TableCell
                      style={{
                        maxWidth: '200px',
                        borderWidth: '1px',
                        borderColor: '#aaaaaa',
                        borderStyle: 'solid',
                        fontSize: '15px',
                        background: 'black',
                        color: 'white'
                      }}
                    >
                      {item}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {dialogState.map((item, index) => (
              <TableRow key={'tr' + index}>
                {Object.keys(tempState).map(
                  (temp, index) =>
                    index < 7 && (
                      <TableCell
                        style={{
                          maxWidth: '200px',
                          borderWidth: '1px',
                          borderColor: '#aaaaaa',
                          borderStyle: 'solid'
                        }}
                      >
                        {item[tempState[temp]]
                          ? item[tempState[temp]]
                          : 'No Data'}
                      </TableCell>
                    )
                )}
              </TableRow>
            ))}
          </TableBody>
        </UiTable>
      </Modal>
      <div>
        {/*  <Button
          style={{
            position: 'fixed',
            right: '0.5em',
            bottom: '0.5em',
            background: '#132440',
            border: '1px solid transparent ',
            color: 'white',
            padding: '0em',
            width: '3.5em',
            height: '3.5em',
            borderRadius: '5em',
            fontSize: '1em',
            zIndex: 99
          }}
          onClick={() => handleOpenMenu(true)}
          color="primary"
        >
          <h1
            style={{
              fontSize: '3em',
              marginTop: '0.5em',
              color: 'white'
            }}
          >
            +
              </h1>
          </Button> */}
        <div>
          {state.main && !processing ? (
            <div>
              <Row>
                <button
                  style={{
                    backgroundColor: '#00CC70',
                    border: '0px solid grey',
                    color: 'white',
                    width: '10em',
                    height: '3em',
                    fontSize: '15px',
                    fontWeight: 700,
                    fontFamily: 'Mulish , sans-serif',
                    borderRadius: '3em',
                    zIndex: 1
                  }}
                  onClick={() => handleOpenMenu(true)}
                  color="primary"
                >
                  {'Add Product'}
                </button>
                <Button
                  style={{
                    position: 'fixed',
                    right: '-0.6em',
                    bottom: '9em',
                    background: 'rgb(0, 204, 112)',
                    boxShadow: '0 1px 15px rgb(0 0 0 / 20%)',
                    border: '1px solid transparent',
                    color: 'white',
                    padding: '0em',
                    width: '3.5em',
                    height: '3.5em',
                    borderRadius: '5em 0em 0em 5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                  onClick={() => switchUpload(true)}
                  color="primary"
                >
                  <Icon
                    type="import"
                    style={{
                      position: 'absolute',
                      paddingLeft: 3
                    }}
                  />
                </Button>
                {/*<button
                    onClick={() => switchUpload(true)}
                    style={{
                      marginLeft: "0.8em",
                      backgroundColor: "#00CC70",
                      border: "0px solid grey",
                      color: "white",
                      width: "10em",
                      height: "3em",
                      fontSize: "15px",
                      fontWeight: 700,
                      fontFamily: "Mulish , sans-serif",
                      borderRadius: '3em',
                      zIndex: 1
                    }}
                  ><Icon type="import" style={{transform:"rotate(180deg)"}}/> Import</button>*/}
                <StyledSearch
                  ref={searchInputTop}
                  style={{
                    width: '33em',
                    borderRadius: '5em',
                    marginLeft: '1em'
                  }}
                  placeholder="Search Name, Category or Type"
                  allowClear={true}
                  size="large"
                  value={searchValue}
                  onChange={e => {
                    if (e.target.value === '') {
                      searchTable(e.target.value);
                    }
                    setSearchValue(e.target.value);
                  }}
                  onSearch={value => searchTable(value)}
                  //enterButton
                />
              </Row>
              <Row>
                <Button
                  onClick={e => {
                    searchInputTop.current.focus();
                  }}
                  style={{
                    position: 'fixed',
                    right: '-0.6em',
                    bottom: '5em',
                    background: 'rgba(229, 233, 242, 1)',
                    border: '1px solid transparent',
                    color: 'rgba(0, 0, 0, 0.54)',
                    padding: '0em',
                    width: '3.5em',
                    height: '3.5em',
                    boxShadow: '0 1px 15px rgb(0 0 0 / 20%)',
                    borderRadius: '5em 0em 0em 5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                >
                  <Icon
                    style={{
                      paddingLeft: '2px'
                    }}
                    type="search"
                  />
                </Button>
                <div
                  style={{
                    float: 'right',
                    height: '10px'
                  }}
                >
                  <StyledPopover
                    id="RadioSelectAdmin"
                    placement="right"
                    content={EyeContent}
                    title=""
                    trigger="click"
                    style={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none'
                    }}
                  >
                    <Button
                      style={{
                        position: 'fixed',
                        right: '-0.6em',
                        bottom: '13em',
                        background: 'rgba(229, 233, 242, 1)',
                        border: '1px solid transparent',
                        color: 'rgba(0, 0, 0, 0.54)',
                        padding: '0em',
                        width: '3.5em',
                        height: '3.5em',
                        boxShadow: '0 1px 15px rgb(0 0 0 / 20%)',
                        borderRadius: '5em 0em 0em 5em',
                        fontSize: '1em',
                        zIndex: 1000
                      }}
                    >
                      <Icon
                        style={{
                          paddingLeft: '2px'
                        }}
                        theme="filled"
                        type="setting"
                      />
                    </Button>
                  </StyledPopover>
                  {/*} <Popover
                      placement="left"
                      content={GearContent}
                      title=""
                      trigger="click"
                    >
                      <Button
                        style={{
                          backgroundColor: 'transparent',
                          border: '0px solid grey',
                          color: 'rgb(61, 72, 82)',
                          marginBottom: '0.8em',
                          height: '2.7em',
                          float: 'right',
                          borderRadius: '5em',
                          boxShadow: "none",
                          width: '2em'
                        }}
                      >
                        <Icon
                          style={{
                            paddingLeft: '2px'
                          }}
                          type="swap"
                        />
                      </Button>
                        </Popover> */}
                </div>
              </Row>
              {state.upload ? (
                <div>
                  <Row style={{ padding: '10px' }}>
                    <button
                      onClick={() => switchUploadTemp('basic')}
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid rgb(0, 204, 112)',
                        color: 'rgb(0, 204, 112)',
                        fontWeight: '500',
                        height: '2.7em',
                        fontSize: 16,
                        fontFamily: '',
                        borderRadius: '3em',
                        zIndex: 99
                      }}
                    >
                      <Icon type="import" /> VPOS Template
                    </button>
                    <span> OR </span>
                    <button
                      onClick={() => switchUploadTemp('custom')}
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid rgb(0, 204, 112)',
                        color: 'rgb(0, 204, 112)',
                        fontWeight: '500',
                        height: '2.7em',
                        fontSize: 16,
                        fontFamily: '',
                        borderRadius: '3em',
                        zIndex: 99
                      }}
                    >
                      <Icon type="import" /> Custom Template
                    </button>
                  </Row>
                  <Row style={{ padding: '10px' }}>
                    <button
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid rgb(0, 204, 112)',
                        color: 'rgb(0, 204, 112)',
                        fontWeight: '500',
                        height: '2.7em',
                        fontSize: 16,
                        fontFamily: '',
                        borderRadius: '3em',
                        zIndex: 99
                      }}
                    >
                      <CSVLink
                        filename={'VPOS-Products-Export.csv'}
                        data={state.csvData ? state.csvData : [['No', 'Data']]}
                      >
                        <Icon type="export" />
                        POS Export
                      </CSVLink>
                    </button>
                    <span> OR </span>
                    <button
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid rgb(0, 204, 112)',
                        color: 'rgb(0, 204, 112)',
                        fontWeight: '500',
                        height: '2.7em',
                        fontSize: 16,
                        fontFamily: '',
                        borderRadius: '3em',
                        zIndex: 99
                      }}
                    >
                      <CSVLink
                        filename={'ECOM-VPOS-Products-Export.csv'}
                        data={
                          state.csvDataEcom
                            ? state.csvDataEcom
                            : [['No', 'Data']]
                        }
                      >
                        <Icon type="export" />
                        ECOM Export
                      </CSVLink>
                    </button>
                  </Row>
                  {state.staticTemplate ? (
                    <Row>
                      <span style={{ fontSize: '15px' }}>
                        <Icon type="download" /> Download Template:{' '}
                        <a
                          href={ImportTemp}
                          download="VPOSMenuItemsTemplate.xlsx"
                        >
                          POS
                        </a>{' '}
                        OR{' '}
                        <a
                          href={ImportTempEcom}
                          download="VPOSMenuItemsTemplateEcom.xlsx"
                        >
                          Ecommerce
                        </a>{' '}
                      </span>
                      <GridContainer>
                        <GridItem xs={5} sm={5} md={5}>
                          <CustomSelect
                            labelText="Select Catalogue"
                            id="mainmenu"
                            formControlProps={{
                              fullWidth: true,
                              style: { margin: 0 }
                            }}
                            inputProps={{
                              onClick: () => onHelpMarker('menuitems'),
                              onChange: (e, key) => handleChangeMenu(e, key),
                              value: state.mainmenusmenu
                            }}
                            menuItems={state.mainmenu}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ margin: '10px' }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                style={{ marginRight: '1em' }}
                                checked={state.mainmenuscompo}
                                onChange={e => {
                                  setState(prevState => ({
                                    ...prevState,
                                    mainmenuscompo: e
                                  }));
                                }}
                              />
                            }
                            label={'Will the Products use Stock Items ?'}
                          />
                        </GridItem>
                      </GridContainer>
                      <div>
                        <FileDrop
                          onDrop={(files, event) =>
                            fileHandlerDrag(files, event)
                          }
                        >
                          <div
                            style={{
                              border: '1px solid black',
                              width: '340px',
                              color: 'black',
                              height: '100px',
                              textAlign: 'center'
                            }}
                          >
                            {state.filename}
                            <div>OR</div>
                            <div>
                              <input
                                type="file"
                                onChange={fileHandler.bind(this)}
                                style={{ padding: '10px' }}
                              />
                            </div>
                          </div>
                        </FileDrop>
                        <div>
                          <button
                            style={{
                              backgroundColor: 'white',
                              border: '1px solid rgb(0, 204, 112)',
                              color: 'rgb(0, 204, 112)',
                              fontWeight: '500',
                              width: '10.5em',
                              height: '2.7em',
                              fontSize: 16,
                              fontFamily: '',
                              marginTop: '0.8em',
                              marginBottom: '0.8em',
                              borderRadius: '3em',
                              zIndex: 99
                            }}
                            onClick={() => uploadFile()}
                            color="primary"
                          >
                            {'Import'}
                          </button>
                          <button
                            style={{
                              backgroundColor: 'white',
                              border: '1px solid red',
                              color: 'red',
                              fontWeight: '500',
                              width: '10.5em',
                              height: '2.7em',
                              fontSize: 16,
                              fontFamily: '',
                              marginTop: '0.8em',
                              marginLeft: '0.8em',
                              marginBottom: '0.8em',
                              borderRadius: '3em',
                              zIndex: 99
                            }}
                            onClick={() => switchUpload(false)}
                            color="primary"
                          >
                            {'Cancel'}
                          </button>
                        </div>
                      </div>
                    </Row>
                  ) : null}
                  {state.manualTemplate ? (
                    <Row>
                      <GridContainer style={{ paddingLeft: '12px' }}>
                        <GridItem xs={5} sm={5} md={5}>
                          <CustomSelect
                            labelText="Select Catalogue"
                            id="mainmenu"
                            formControlProps={{
                              fullWidth: true,
                              style: { margin: 0 }
                            }}
                            inputProps={{
                              onClick: () => onHelpMarker('menuitems'),
                              onChange: (e, key) => handleChangeMenu(e, key),
                              value: state.mainmenusmenu
                            }}
                            menuItems={state.mainmenu}
                          />
                        </GridItem>
                      </GridContainer>
                      <Row style={{ padding: '10px' }}>
                        <button
                          onClick={() => switchUploadTypeTemp('pos')}
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid rgb(15, 135, 123) ',
                            color: 'rgb(15, 135, 123)',
                            fontWeight: '700',
                            height: '2.7em',
                            fontSize: 16,
                            fontFamily: '',
                            borderRadius: '0.5em',
                            zIndex: 99
                          }}
                        >
                          <Icon type="import" />
                          POS
                        </button>
                        <span> OR </span>
                        <button
                          onClick={() => switchUploadTypeTemp('ecom')}
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid rgb(15, 135, 123) ',
                            color: 'rgb(15, 135, 123)',
                            fontWeight: '700',
                            height: '2.7em',
                            fontSize: 16,
                            fontFamily: '',
                            borderRadius: '0.5em',
                            zIndex: 99
                          }}
                        >
                          <Icon type="import" /> Ecommerce
                        </button>
                      </Row>
                      <div>
                        {state.ecomTemp ? (
                          <div>
                            <FileDrop
                              onDrop={(files, event) =>
                                fileHandlerDrag(files, event)
                              }
                            >
                              <div
                                style={{
                                  border: '1px solid black',
                                  width: '340px',
                                  color: 'black',
                                  height: '100px',
                                  textAlign: 'center'
                                }}
                              >
                                {state.filename}
                                <div>OR</div>
                                <div>
                                  <input
                                    type="file"
                                    onChange={fileHandler.bind(this)}
                                    style={{ padding: '10px' }}
                                  />
                                </div>
                              </div>
                            </FileDrop>
                            <div style={{ padding: '20px' }}>
                              <h3>
                                Select Excel Columns for Corresponding Values
                              </h3>
                              <p>* Values are Mandatory</p>
                              <Row
                                style={{ width: '240px', paddingBottom: '5px' }}
                              >
                                <span>Data Start Row</span>
                                <input
                                  type="number"
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  value={tempState.start}
                                  onChange={e => {
                                    setTempState(prevState => ({
                                      ...prevState,
                                      start: e.target.value
                                    }));
                                  }}
                                />
                              </Row>
                              <Row
                                style={{ width: '240px', paddingBottom: '5px' }}
                              >
                                <span>Data End Row</span>
                                <input
                                  type="number"
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  value={tempState.end}
                                  onChange={e => {
                                    setTempState(prevState => ({
                                      ...prevState,
                                      end: e.target.value
                                    }));
                                  }}
                                />
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Category</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 1'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'MainCategory')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Type</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 2'}
                                  onChange={e => handleChangeTempCol(e, 'Type')}
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Brand</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 3'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'Brand')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Product Name*</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 4'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'ProductName')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Cost Price</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 5'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'CostPrice')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Sell Price</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 6'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'SellPrice')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>SKU</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 7'}
                                  onChange={e => handleChangeTempCol(e, 'SKU')}
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Init Stock</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 8'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'InitStock')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <button
                                style={{
                                  backgroundColor: 'white',
                                  border: '1px solid rgb(15, 135, 123) ',
                                  color: 'rgb(15, 135, 123)',
                                  fontWeight: '700',
                                  width: '240px',
                                  height: '2.7em',
                                  fontSize: 16,
                                  fontFamily: '',
                                  borderRadius: '0.5em',
                                  zIndex: 99
                                }}
                                onClick={() => previewFile()}
                                color="primary"
                              >
                                {'Preview'}
                              </button>
                            </div>
                            <div>
                              <button
                                style={{
                                  backgroundColor: 'white',
                                  border: '1px solid rgb(15, 135, 123) ',
                                  color: 'rgb(15, 135, 123)',
                                  fontWeight: '700',
                                  width: '10.5em',
                                  height: '2.7em',
                                  fontSize: 16,
                                  fontFamily: '',
                                  borderRadius: '0.5em',
                                  zIndex: 99
                                }}
                                onClick={() => uploadFile()}
                                color="primary"
                              >
                                {'Import'}
                              </button>
                              <button
                                style={{
                                  backgroundColor: 'white',
                                  border: '1px solid rgb(15, 135, 123) ',
                                  color: 'rgb(15, 135, 123)',
                                  fontWeight: '700',
                                  width: '10.5em',
                                  height: '2.7em',
                                  fontSize: 16,
                                  fontFamily: '',
                                  borderRadius: '0.5em',
                                  zIndex: 99
                                }}
                                onClick={() => switchUpload(false)}
                                color="primary"
                              >
                                {'Cancel'}
                              </button>
                            </div>
                          </div>
                        ) : null}
                        {state.posTemp ? (
                          <div>
                            <Row>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{ margin: '10px' }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        style={{ marginRight: '1em' }}
                                        checked={state.mainmenuscompo}
                                        onChange={e => {
                                          setState(prevState => ({
                                            ...prevState,
                                            mainmenuscompo: e
                                          }));
                                        }}
                                      />
                                    }
                                    label={
                                      'Will this product use Stock Items ?'
                                    }
                                  />
                                </GridItem>
                              </GridContainer>
                            </Row>
                            <FileDrop
                              onDrop={(files, event) =>
                                fileHandlerDrag(files, event)
                              }
                            >
                              <div
                                style={{
                                  border: '1px solid black',
                                  width: '350px',
                                  color: 'black',
                                  height: '100px',
                                  textAlign: 'center'
                                }}
                              >
                                {state.filename}
                                <div>OR</div>
                                <div>
                                  <input
                                    type="file"
                                    onChange={fileHandler.bind(this)}
                                    style={{ padding: '10px' }}
                                  />
                                </div>
                              </div>
                            </FileDrop>
                            <div style={{ padding: '20px' }}>
                              <h3>
                                Select Excel Columns for Corresponding Values
                              </h3>
                              <p>* Values are Mandatory</p>
                              <Row
                                style={{ width: '240px', paddingBottom: '5px' }}
                              >
                                <span>Data Start Row</span>
                                <input
                                  type="number"
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  value={tempState.start}
                                  onChange={e => {
                                    let value = e.target.value;
                                    setTempState(prevState => ({
                                      ...prevState,
                                      start: value
                                    }));
                                  }}
                                />
                              </Row>
                              <Row
                                style={{ width: '240px', paddingBottom: '5px' }}
                              >
                                <span>Data End Row</span>
                                <input
                                  type="number"
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  value={tempState.end}
                                  onChange={e => {
                                    let value = e.target.value;
                                    setTempState(prevState => ({
                                      ...prevState,
                                      end: value
                                    }));
                                  }}
                                />
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Category</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 1'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'MainCategory')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Sub Category</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 2'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'SubCategory')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Product Name*</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 3'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'ItemName')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Type</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 4'}
                                  onChange={e => handleChangeTempCol(e, 'Type')}
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Cost Price</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 5'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'CostPrice')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>Sell Price</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 6'}
                                  onChange={e =>
                                    handleChangeTempCol(e, 'SellPrice')
                                  }
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <Row style={{ width: '240px' }}>
                                <span>SKU</span>
                                <Select
                                  style={{
                                    width: 120,
                                    float: 'right',
                                    top: '-3px'
                                  }}
                                  defaultValue={'Column 7'}
                                  onChange={e => handleChangeTempCol(e, 'SKU')}
                                >
                                  {colSelectItems.map(item => (
                                    <Option key={item.value}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Row>
                              <button
                                style={{
                                  backgroundColor: 'white',
                                  border: '1px solid rgb(15, 135, 123) ',
                                  color: 'rgb(15, 135, 123)',
                                  fontWeight: '700',
                                  width: '240px',
                                  height: '2.7em',
                                  fontSize: 16,
                                  fontFamily: '',
                                  borderRadius: '0.5em',
                                  zIndex: 99
                                }}
                                onClick={() => previewFile()}
                                color="primary"
                              >
                                {'Preview'}
                              </button>
                            </div>
                            <div>
                              <button
                                style={{
                                  backgroundColor: 'white',
                                  border: '1px solid rgb(15, 135, 123) ',
                                  color: 'rgb(15, 135, 123)',
                                  fontWeight: '700',
                                  width: '10.5em',
                                  height: '2.7em',
                                  fontSize: 16,
                                  fontFamily: '',
                                  borderRadius: '0.5em',
                                  zIndex: 99
                                }}
                                onClick={() => uploadFile()}
                                color="primary"
                              >
                                {'Import'}
                              </button>
                              <button
                                style={{
                                  backgroundColor: 'white',
                                  border: '1px solid rgb(15, 135, 123) ',
                                  color: 'rgb(15, 135, 123)',
                                  fontWeight: '700',
                                  width: '10.5em',
                                  height: '2.7em',
                                  fontSize: 16,
                                  fontFamily: '',
                                  borderRadius: '0.5em',
                                  zIndex: 99
                                }}
                                onClick={() => switchUpload(false)}
                                color="primary"
                              >
                                {'Cancel'}
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  ) : null}
                </div>
              ) : null}
              <Row>
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <StyledTable
                    // title={() => (
                    // <Input.Search
                    // style={{width:"33em",borderRadius:"5em",border:"1px solid rgba(0, 0, 0, 0.35)"}}
                    //  placeholder="Search Name, Category or Type"
                    //  allowClear={true}
                    //  size="large"
                    //  value={searchValue}
                    //  onChange={e => {
                    //    if (e.target.value === "") {
                    //      searchTable(e.target.value)
                    //    }
                    //    setSearchValue(e.target.value)
                    //  }}
                    //  onSearch={value => searchTable(value)}
                    // />)}
                    locale={
                      state.loading
                        ? {
                            emptyText: <Skeleton />
                          }
                        : {}
                    }
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    pagination={{
                      locale: { items_per_page: ' Items' },
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      onChange: changePage,
                      current: state.currentPage,
                      hideOnSinglePage: true,
                      pageSize: state.pageSize || 25,
                      itemRender: itemRender,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} - ${range[1]}  of ${total} items`
                    }}
                    dataSource={
                      searchItems.length > 0
                        ? searchItems
                        : menuitemsState.menuitems
                    }
                    onChange={handleSort}
                    onRow={(record, rowIndex) => {
                      if (!record.active) {
                        return {
                          onClick: e => {
                            e.stopPropagation();
                            handleEditMenuItem(record);
                          },
                          style: {
                            background: 'lightcoral',
                            color: 'white'
                          }
                        };
                      } else if (rowIndex % 2 == 0) {
                        return {
                          onClick: e => {
                            e.stopPropagation();
                            handleEditMenuItem(record);
                          },
                          style: { background: 'rgb(247, 247, 247)' }
                        };
                      } else {
                        return {
                          onClick: e => {
                            e.stopPropagation();
                            handleEditMenuItem(record);
                          }
                        };
                      }
                    }}
                  >
                    {props.user.ecommerceActive ? (
                      <Column
                        title="Image"
                        render={(text, record) => {
                          if (record.images && record.images.length > 0) {
                            return (
                              <img
                                style={{ maxHeight: 58, maxWidth: 58 }}
                                alt={record.name}
                                src={
                                  server +
                                  'vpos/api/static/images/' +
                                  record.images[0]
                                }
                              />
                            );
                          } else {
                            return 'N/A';
                          }
                        }}
                      />
                    ) : null}
                    <Column
                      {...getColumnSearchProps('name')}
                      sorter={(a, b) => a.name.localeCompare(b.name)}
                      sortDirections={['descend', 'ascend']}
                      filteredValue={[state.searchText]}
                      title="Name"
                      dataIndex="name"
                      key="name"
                    />
                    <Column
                      title="Category"
                      filters={state.categoryFilters}
                      filteredValue={filteredInfo.menubtns || null}
                      onFilter={(value, record) =>
                        record.menubtns.includes(value)
                      }
                      render={(text, record) => (
                        <div>
                          {record.mainmenu ? (
                            <CustomSelectMulti
                              noMargin={true}
                              id={record._id}
                              formControlProps={{
                                fullWidth: true,
                                style: { margin: 0, padding: 0 }
                              }}
                              inputProps={{
                                onClick: e => {
                                  e.stopPropagation();
                                },
                                onChange: e =>
                                  handleChangeItemCategory(e, record),
                                value: record.menubtns,
                                open: state.menuitemsCatMultiOpen[record._id],
                                onClose: e => {
                                  let data = state.menuitemsCatMultiOpen;
                                  data[record._id] = false;
                                  setState(prevState => ({
                                    ...prevState,
                                    menuitemsCatMultiOpen: data
                                  }));
                                },
                                onOpen: e => {
                                  let data = state.menuitemsCatMultiOpen;
                                  data[record._id] = true;
                                  setState(prevState => ({
                                    ...prevState,
                                    menuitemsCatMultiOpen: data
                                  }));
                                }
                              }}
                              menuItems={state.categoryFilters}
                            />
                          ) : (
                            'N/A'
                          )}
                        </div>
                      )}
                      dataIndex="menubtns"
                      key="menubtns"
                    />
                    {props.user.ecommerceActive ? (
                      <Column
                        title="Brand"
                        sorter={(a, b) =>
                          a.brand && b.brand
                            ? a.brand.localeCompare(b.brand)
                            : false
                        }
                        sortDirections={['ascend', 'descend']}
                        filters={state.itemFiltersBrand}
                        filteredValue={filteredInfo.brand || null}
                        onFilter={(value, record) =>
                          record.brand ? record.brand.includes(value) : false
                        }
                        render={(text, record) => {
                          if (record.brand) {
                            return (
                              <CustomSelect
                                noMargin={true}
                                id={record._id}
                                formControlProps={{
                                  fullWidth: true,
                                  style: { margin: 0, padding: 0 }
                                }}
                                inputProps={{
                                  onClick: e => {
                                    e.stopPropagation();
                                  },
                                  onChange: (event, key) => {
                                    event.stopPropagation();
                                    handleChangeItemBrand(event, record);
                                  },
                                  value: record.brandid
                                }}
                                menuItems={state.brands}
                              />
                            );
                          } else {
                            return 'N/A';
                          }
                        }}
                        dataIndex="brand"
                        key="brand"
                      />
                    ) : null}
                    <Column
                      title="Type"
                      sorter={(a, b) => a.type.localeCompare(b.type)}
                      sortDirections={['ascend', 'descend']}
                      filters={state.itemFilters}
                      filteredValue={filteredInfo.type || null}
                      onFilter={(value, record) => record.type.includes(value)}
                      render={(text, record) => (
                        <CustomSelect
                          noMargin={true}
                          id={record._id}
                          formControlProps={{
                            fullWidth: true,
                            style: { margin: 0, padding: 0 }
                          }}
                          inputProps={{
                            onClick: e => {
                              e.stopPropagation();
                            },
                            onChange: (event, key) => {
                              event.stopPropagation();
                              handleChangeItemType(event, record);
                            },
                            value: record.typeid
                          }}
                          menuItems={state.types}
                        />
                      )}
                      dataIndex="type"
                      key="type"
                    />
                    <Column
                      title="Variant"
                      key="Available"
                      render={(text, record, key) => (
                        <div>
                          {record.variants && record.variants.length > 0 ? (
                            <a
                              style={{
                                whiteSpace: 'nowrap',
                                border: '1px solid #00CC70',
                                borderRadius: '3em',
                                padding: '6px'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                handleEditMenuItem(record, '3');
                              }}
                            >
                              {record.variants.length > 1
                                ? record.variants.length + ' Variants'
                                : record.variants.length + ' Variant'}
                            </a>
                          ) : (
                            'None'
                          )}
                        </div>
                      )}
                    />
                    <Column
                      title="Linked Stock"
                      key="AvailableStock"
                      render={(text, record, key) => (
                        <div>
                          {record.stockitems && record.stockitems.length > 0 ? (
                            <a
                              style={{
                                whiteSpace: 'nowrap',
                                border: '1px solid #00CC70',
                                borderRadius: '3em',
                                padding: '6px'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                handleEditMenuItem(record, '2');
                              }}
                            >
                              {record.stockitems.length > 1
                                ? record.stockitems.length + ' Stock'
                                : record.stockitems.length + ' Stock'}
                            </a>
                          ) : (
                            'No Stock'
                          )}
                        </div>
                      )}
                    />
                    <Column
                      title="Colour"
                      dataIndex="colour"
                      key="colour"
                      render={(text, record, key) => (
                        <div>
                          {record.color && record.menubtns.length > 0 ? (
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                borderRadius: '3em',
                                background: record.color
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                borderRadius: '3em',
                                background: 'grey'
                              }}
                            />
                          )}
                        </div>
                      )}
                    />
                    {/* <Column
                      title="Delivery"
                      render={(text, record) => {
                        return <span>{record.delivery ? 'Yes' : 'No'}</span>;
                      }}
                      dataIndex="delivery"
                      key="delivery"
                    /> */}
                    {props.user.ecommerceActive &&
                    props.user.orgid === 'arôfc666498' ? (
                      <Column
                        title="Agent Price"
                        render={(text, record) => (
                          <span>
                            {record.agentPrice
                              ? 'R' + parseFloat(record.agentPrice).toFixed(2)
                              : 'R0.00'}
                          </span>
                        )}
                        dataIndex="agentPrice"
                        key="agentPrice"
                      />
                    ) : null}
                    <Column
                      title="Sell Price"
                      render={(text, record) => (
                        <span>
                          {record.sellPrice
                            ? 'R' + parseFloat(record.sellPrice).toFixed(2)
                            : 'R0.00'}
                        </span>
                      )}
                      dataIndex="sellPrice"
                      key="sellPrice"
                    />
                    {props.user.ecommerceActive ? (
                      <Column
                        title="Special Price"
                        render={(text, record) => (
                          <span>
                            {record.specialPrice
                              ? 'R' + parseFloat(record.specialPrice).toFixed(2)
                              : 'R0.00'}
                          </span>
                        )}
                        dataIndex="specialPrice"
                        key="specialPrice"
                      />
                    ) : null}
                    {props.user.ecommerceActive ? (
                      <Column
                        title="Special Active"
                        render={(text, record) => (
                          <span>{record.specialActive ? 'Yes' : 'No'}</span>
                        )}
                        dataIndex="specialActive"
                        key="specialActive"
                      />
                    ) : null}
                    <Column
                      title="Cost Price"
                      render={(text, record) => (
                        <span>
                          {record.costPrice
                            ? 'R' + parseFloat(record.costPrice).toFixed(2)
                            : 'R0.00'}
                        </span>
                      )}
                      dataIndex="costPrice"
                      key="costPrice"
                    />
                    <Column
                      title="Profit Margin"
                      render={(text, record) => (
                        <span>
                          {record.sellPrice > 0
                            ? (
                                ((record.sellPrice - (record.costPrice || 0)) /
                                  record.sellPrice) *
                                100
                              ).toFixed() + '%'
                            : '0%'}
                        </span>
                      )}
                      key="profit"
                    />
                    <Column
                      title="Last Modified"
                      sorter={(a, b, sortOrder) => {
                        if (a.moddate && b.moddate) {
                          return a.moddate.localeCompare(b.moddate);
                        }
                        if (a.moddate && !b.moddate) {
                          return sortOrder === 'ascend' ? -1 : 1;
                        }
                        if (b.moddate && !a.moddate) {
                          return sortOrder === 'ascend' ? 1 : -1;
                        }
                        return 0;
                      }}
                      sortDirections={['descend', 'ascend']}
                      render={(text, record) => (
                        <span>
                          {record.moddate
                            ? record.moddate.slice(0, 4) +
                              '/' +
                              record.moddate.slice(4, 6) +
                              '/' +
                              record.moddate.slice(6, 8)
                            : 'No Date'}
                        </span>
                      )}
                      dataIndex="moddate"
                      key="moddate"
                    />
                    {/*<Column
                  {...getColumnSearchProps('code')}
                  title="SKU"
                  dataIndex="code"
                  key="code"
                />*/}
                    <Column
                      title="Status"
                      align="left"
                      render={(text, record) => (
                        <div
                          style={
                            !record.dimChange
                              ? {
                                  whiteSpace: 'nowrap',
                                  border: '1px solid rgb(0, 204, 112)',
                                  color: 'rgb(0, 204, 112)',
                                  padding: 6,
                                  borderRadius: '3em',
                                  width: '6.7em',
                                  textAlign: 'center',
                                  marginLeft: '-1.5em'
                                }
                              : { whiteSpace: 'nowrap' }
                          }
                        >
                          {record.dimChange ? (
                            <Tooltip
                              placement="top"
                              title={
                                'Stock Item Changes Detected, Review Composite Items'
                              }
                            >
                              <IconButton
                                style={{
                                  border: '1px solid red',
                                  padding: '16px'
                                }}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleEditMenuItem(record, '2');
                                }}
                              >
                                {
                                  <Icon
                                    type="exclamation"
                                    style={{
                                      position: 'absolute',
                                      fontSize: '16px',
                                      color: 'coral'
                                    }}
                                  />
                                }
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <span>{'Ready'}</span>
                          )}
                        </div>
                      )}
                    />
                  </StyledTable>
                </ConfigProvider>
              </Row>
            </div>
          ) : (
            <div>
              {state.userlistbuttonmenu === 'EDIT PRODUCT' ? (
                <div>
                  <h4
                    style={{
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 700,
                      float: 'right',
                      marginLeft: '-9em',
                      marginTop: '0.5em',
                      fontSize: '25px'
                    }}
                    className={props.classes.cardTitleWhite}
                  >
                    {state.namemenu}
                  </h4>
                  <StyledTabs defaultActiveKey={state.tabNumber} type="card">
                    <TabPane tab="Product Details" key="1">
                      <MenuItem
                        state={state}
                        props={props}
                        handleOpenMenu={handleOpenMenu}
                        submitForm={submitForm}
                        onError={onError}
                        changeView={changeView}
                        handleDeleteMenuItem={handleDeleteMenuItem}
                        handleRestoreMenuItem={handleRestoreMenuItem}
                        onUseDimCheck={onUseDimCheck}
                        onResetState={onResetState}
                      />
                    </TabPane>
                    <TabPane
                      tab="Composite Items"
                      key="2"
                      style={{ marginTop: '-1.5em' }}
                      forceRender={true}
                    >
                      <CompoStockItem
                        state={state}
                        props={props}
                        onError={onError}
                        setState={setState}
                        handleOpenMenu={handleOpenMenu}
                        changeViewMods={changeViewMods}
                        submitForm={submitForm}
                        updateMenuItemCost={updateMenuItemCost}
                        initState={initStockState}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        state.listing === 'ecommerce'
                          ? 'Stock Variants'
                          : 'Variants'
                      }
                      key="3"
                      disabled={state.useDim}
                      forceRender={true}
                    >
                      {state.stockVariants.length === 0 ? (
                        <Card>
                          <CardBody>
                            <VarientsBody
                              state={state}
                              props={props}
                              sizes={sizes}
                              setSizes={setSizes}
                              createVariants={createVariants}
                              createVariantsEcom={createVariantsEcom}
                              setState={setState}
                            />
                          </CardBody>
                        </Card>
                      ) : (
                        <Card>
                          <CardBody>
                            <Card style={{ marginTop: '1em' }}>
                              <CardHeader
                                style={{
                                  height: '4em',
                                  background: 'transparent',
                                  borderBottom: '1px solid lightgrey',
                                  marginRight: '2em',
                                  marginLeft: '1.5em',
                                  marginBottom: '0.5em'
                                }}
                              >
                                {' '}
                                <div style={{ display: 'inline-flex' }}>
                                  <h4
                                    style={{
                                      color: 'rgba(0, 0, 0, 0.85)',
                                      marginLeft: '-0.8em',
                                      fontSize: '24px'
                                    }}
                                    className={props.classes.cardTitleWhite}
                                  >
                                    {state.listing === 'ecommerce'
                                      ? 'Stock Variant Overview'
                                      : 'Product Variant Overview'}
                                  </h4>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <StockTable
                                      state={state}
                                      props={props}
                                      updateVariantStockCost={
                                        updateVariantStockCost
                                      }
                                      updateVariantDim={updateVariantDim}
                                      removeVariantPI={removeVariantPI}
                                    />
                                  </GridItem>
                                </GridContainer>
                                <GridContainer>
                                  <GridItem xs={2} sm={2} md={2}>
                                    <Button
                                      onClick={() => handleOpenMenu(false)}
                                      color="primary"
                                      style={{
                                        textTransform: 'initial',
                                        backgroundColor:
                                          'rgba(229, 233, 242, 0.9)',
                                        border:
                                          '1px solid rgba(229, 233, 242, 0.9)',
                                        color: 'black',
                                        borderRadius: '3.5em',
                                        width: '9em',
                                        boxShadow: 'none',
                                        marginTop: '3em'
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </GridItem>
                                  <GridItem xs={8} sm={8} md={8} />
                                  <GridItem xs={2} sm={2} md={2}>
                                    <button
                                      disabled={state.loading}
                                      onClick={e => {
                                        submitForm(e);
                                      }}
                                      color="primary"
                                      style={{
                                        paddingTop: '0.6em',
                                        paddingBottom: '0.6em',
                                        marginLeft: '1.5em',
                                        marginRight: '0.5em',
                                        border: '1px solid #00CC70',
                                        backgroundColor: '#00CC70',
                                        borderRadius: '3.5em',
                                        width: '12em',
                                        float: 'right',
                                        color: 'white',
                                        marginTop: '3em'
                                      }}
                                    >
                                      {state.userlistbuttonmenu
                                        .toLowerCase()
                                        .replace(/^(.)|\s+(.)/g, c =>
                                          c.toUpperCase()
                                        )}
                                    </button>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </CardBody>
                        </Card>
                      )}
                    </TabPane>
                    <TabPane
                      tab={
                        state.listing === 'ecommerce'
                          ? 'Product Variants'
                          : 'Modifier Group'
                      }
                      key="4"
                      disabled={state.useDim}
                      forceRender={true}
                    >
                      <Card>
                        <CardBody>
                          <Card style={{ marginTop: '1em' }}>
                            <CardHeader
                              style={{
                                height: '4em',
                                background: 'transparent',
                                borderBottom: '1px solid lightgrey',
                                marginRight: '2em',
                                marginLeft: '2em',
                                marginBottom: '-1.5em'
                              }}
                            >
                              {' '}
                              <div style={{ display: 'inline-flex' }}>
                                <h4
                                  style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    marginLeft: '-1em',
                                    fontSize: '24px'
                                  }}
                                  className={props.classes.cardTitleWhite}
                                >
                                  {state.listing === 'ecommerce'
                                    ? 'Product Variants'
                                    : 'Modifier Group'}
                                </h4>
                                <p
                                  style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                                  className={props.classes.cardCategoryWhite}
                                >
                                  {
                                    'Create sets of options that can be applied to items.'
                                  }
                                </p>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{ marginTop: '1em' }}
                                >
                                  <div>
                                    <GridItem>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            style={{ marginRight: '1em' }}
                                            checked={state.addModifier}
                                            onChange={e => {
                                              var newCookIns = [];
                                              var newModifiers = [];
                                              if (e) {
                                                newCookIns = {
                                                  _id: ObjectID().toHexString(),
                                                  modKey: 0,
                                                  cookName: '',
                                                  cookIns: []
                                                };
                                                newModifiers.push(newCookIns);
                                              }
                                              setModState(prevState => ({
                                                ...prevState,
                                                modifiers: newModifiers
                                              }));
                                              setState(prevState => ({
                                                ...prevState,
                                                addModifier: e
                                              }));
                                            }}
                                          />
                                        }
                                        label={
                                          state.listing === 'ecommerce'
                                            ? 'This product has Variants'
                                            : 'This product has Modifiers'
                                        }
                                      />
                                    </GridItem>
                                    {modstate.modifiers.length > 0 ? (
                                      <div>
                                        <GridContainer>
                                          <GridItem
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            style={{ marginTop: '1em' }}
                                          >
                                            <InputsModifier
                                              {...initProps}
                                              {...props}
                                              listing={state.listing}
                                              modstate={modstate}
                                              cookingState={state.cookingState}
                                              stockVariants={
                                                state.stockVariants
                                              }
                                              onError={onError}
                                              types={state.stockTableTypes}
                                              setState={setState}
                                            />
                                          </GridItem>
                                        </GridContainer>
                                      </div>
                                    ) : null}
                                  </div>
                                </GridItem>
                              </GridContainer>
                              <CardFooter
                                style={{
                                  margin: 'auto',
                                  borderTop: '1px solid rgba(229, 233, 242, 1)',
                                  paddingTop: '0em',
                                  marginTop: '2em'
                                }}
                              >
                                <GridItem xs={12} sm={12} md={12}>
                                  <button
                                    disabled={state.loading}
                                    onClick={e => submitForm(e)}
                                    color="primary"
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: '13px',
                                      float: 'right',
                                      paddingTop: '0.6em',
                                      paddingBottom: '0.6em',
                                      marginLeft: '0.5em',
                                      marginRight: '0.5em',
                                      border: '3.8px solid #00CC70',
                                      backgroundColor: '#00CC70',
                                      borderRadius: '5em',
                                      width: '9em',
                                      float: 'right',
                                      color: 'white',
                                      marginTop: '2.25em'
                                    }}
                                  >
                                    {state.userlistbuttonmenu
                                      .toLowerCase()
                                      .replace(/^(.)|\s+(.)/g, c =>
                                        c.toUpperCase()
                                      )}
                                  </button>
                                  <Button
                                    onClick={() => handleOpenMenu(false)}
                                    color="primary"
                                    style={{
                                      float: 'right',
                                      textTransform: 'initial',
                                      backgroundColor:
                                        'rgba(229, 233, 242, 0.9)',
                                      border:
                                        '2px solid rgba(229, 233, 242, 0.9)',
                                      color: 'black',
                                      borderRadius: '3.5em',
                                      width: '9em',
                                      boxShadow: 'none',
                                      marginTop: '2.5em'
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </GridItem>
                              </CardFooter>
                            </CardBody>
                          </Card>
                        </CardBody>
                      </Card>
                    </TabPane>
                    {props.user.ecommerceActive ? (
                      <TabPane tab="Images" key="0">
                        <Images
                          state={state}
                          props={props}
                          handleOpenMenu={handleOpenMenu}
                          submitForm={submitForm}
                          onError={onError}
                          changeView={changeView}
                          handleDeleteMenuItem={handleDeleteMenuItem}
                          handleRestoreMenuItem={handleRestoreMenuItem}
                          onUseDimCheck={onUseDimCheck}
                        />
                      </TabPane>
                    ) : null}
                  </StyledTabs>
                </div>
              ) : (
                <div>
                  <div>
                    {!state.itemasmenu ? (
                      <MenuItem
                        state={state}
                        props={props}
                        handleOpenMenu={handleOpenMenu}
                        submitForm={submitForm}
                        onError={onError}
                        changeView={changeView}
                        onUseDimCheck={onUseDimCheck}
                        updateMenuItemCost={updateMenuItemCost}
                        onResetState={onResetState}
                      />
                    ) : null}
                  </div>
                  <div>
                    {!state.itemasmenulast &&
                    !state.itemasmenucompo &&
                    state.itemasmenu &&
                    state.itemasstockformformenuitem ? (
                      <Card>
                        <CardBody>
                          {state.itemasstockformformenuitem ? (
                            <VarientsBody
                              state={state}
                              props={props}
                              sizes={sizes}
                              setSizes={setSizes}
                              createVariants={createVariants}
                              createVariantsEcom={createVariantsEcom}
                              setState={setState}
                            />
                          ) : null}
                        </CardBody>
                      </Card>
                    ) : null}
                    {state.itemasmenulast &&
                    state.itemasmenu &&
                    (state.cooking || state.itemasstockformformenuitem) ? (
                      <Card>
                        <CardBody>
                          {state.itemasstockformformenuitem ? (
                            <Card>
                              <CardHeader
                                style={{
                                  height: '4em',
                                  background: 'transparent',
                                  borderBottom: '1px solid lightgrey',
                                  marginRight: '2em',
                                  marginLeft: '1.5em',
                                  marginBottom: '0.5em'
                                }}
                              >
                                {' '}
                                <div style={{ display: 'inline-flex' }}>
                                  <h4
                                    style={{
                                      color: 'rgba(0, 0, 0, 0.85)',
                                      marginLeft: '-0.8em',
                                      fontSize: '24px'
                                    }}
                                    className={props.classes.cardTitleWhite}
                                  >
                                    {state.listing === 'ecommerce'
                                      ? 'Stock Variant Overview'
                                      : 'Product Variant Overview'}
                                  </h4>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <StockTable
                                      state={state}
                                      props={props}
                                      updateVariantStockCost={
                                        updateVariantStockCost
                                      }
                                      updateVariantDim={updateVariantDim}
                                      removeVariantPI={removeVariantPI}
                                    />
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          ) : null}
                          {state.cooking ? (
                            <Card>
                              <CardHeader
                                style={{
                                  height: '4em',
                                  background: 'transparent',
                                  borderBottom: '1px solid lightgrey',
                                  marginRight: '2em',
                                  marginLeft: '2em',
                                  marginBottom: '0.5em'
                                }}
                              >
                                {' '}
                                <div style={{ display: 'inline-flex' }}>
                                  <h4
                                    style={{
                                      color: 'rgba(0, 0, 0, 0.85)',
                                      marginLeft: '-1em',
                                      fontSize: '24px'
                                    }}
                                    className={props.classes.cardTitleWhite}
                                  >
                                    {state.listing === 'ecommerce'
                                      ? 'Product Variants'
                                      : 'Modifier Group'}
                                  </h4>
                                  <p
                                    style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                                    className={props.classes.cardCategoryWhite}
                                  >
                                    {
                                      'Create sets of options that can be applied to items.'
                                    }
                                  </p>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <div>
                                      <GridItem>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              style={{ marginRight: '1em' }}
                                              checked={state.addModifier}
                                              onChange={e => {
                                                var newCookIns = [];
                                                var newModifiers = [];
                                                if (e) {
                                                  newCookIns = {
                                                    _id: ObjectID().toHexString(),
                                                    modKey: 0,
                                                    cookName: '',
                                                    cookIns: []
                                                  };
                                                  newModifiers.push(newCookIns);
                                                }
                                                setModState(prevState => ({
                                                  ...prevState,
                                                  modifiers: newModifiers
                                                }));
                                                setState(prevState => ({
                                                  ...prevState,
                                                  addModifier: e
                                                }));
                                              }}
                                            />
                                          }
                                          label={
                                            state.listing === 'ecommerce'
                                              ? 'This product has Variants'
                                              : 'This product has Modifiers'
                                          }
                                        />
                                      </GridItem>
                                      {modstate.modifiers.length > 0 ? (
                                        <div>
                                          <GridContainer>
                                            <GridItem
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              style={{ marginTop: '1em' }}
                                            >
                                              <InputsModifier
                                                {...initProps}
                                                {...props}
                                                listing={state.listing}
                                                modstate={modstate}
                                                cookingState={
                                                  state.cookingState
                                                }
                                                stockVariants={
                                                  state.stockVariants
                                                }
                                                onError={onError}
                                                types={state.stockTableTypes}
                                                setState={setState}
                                              />
                                            </GridItem>
                                          </GridContainer>

                                          {/* <GridContainer>
                                          <GridItem xs={3} sm={3} md={3} style={{ marginTop: '3em' }}>
                                            <Button
                                              onClick={() => {
                                                var newCookIns = [];
                                                var newModifiers = modstate.modifiers;
                                                newCookIns = {
                                                  modKey: (modstate.modifiers.length),
                                                  cookName: '',
                                                  cookIns: [],
                                                  limit: 1
                                                }
                                                newModifiers.push(newCookIns);
                                                setModState(prevState => ({
                                                  ...prevState, modifiers: newModifiers
                                                }))
                                              }}
                                              color="primary" style={{ border: "1px solid #00CC70", color: "#00CC70", backgroundColor: "white", marginTop: "-2em" }}>
                                              Add Another Modifier
                                                  </Button>
                                          </GridItem>
                                            </GridContainer> */}
                                        </div>
                                      ) : null}
                                    </div>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          ) : null}
                          <GridContainer>
                            <GridItem xs={2} sm={2} md={2}>
                              <Button
                                onClick={() => handleOpenMenu(false)}
                                color="primary"
                                style={{
                                  textTransform: 'initial',
                                  backgroundColor: 'white',
                                  border: '1px solid red',
                                  color: 'red',
                                  borderRadius: '3.5em',
                                  width: '9em',
                                  boxShadow: 'none'
                                }}
                              >
                                Cancel
                              </Button>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} />
                            <GridItem xs={2} sm={2} md={2}>
                              <Button
                                onClick={() => {
                                  let itemasmenu = state.itemasmenu;
                                  let itemasmenulast = !state.itemasmenulast;
                                  if (
                                    !state.itemasstockformformenuitem &&
                                    !state.itemasmenucompo
                                  ) {
                                    itemasmenu = false;
                                  }
                                  if (
                                    state.userlistbuttonmenu ===
                                      'EDIT PRODUCT' &&
                                    !state.itemasmenucompo
                                  ) {
                                    itemasmenulast = true;
                                    itemasmenu = false;
                                  }
                                  if (state.fromTable) {
                                    handleOpenMenu(false, state);
                                  } else {
                                    setState(prevState => ({
                                      ...prevState,
                                      itemasmenulast: itemasmenulast,
                                      itemasmenu: itemasmenu
                                    }));
                                  }
                                }}
                                color="primary"
                                style={{
                                  backgroundColor: 'white',
                                  border: '1px solid #00CC70',
                                  color: '#00CC70',
                                  borderRadius: '3em',
                                  width: '100%'
                                }}
                              >
                                Back
                              </Button>
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                              <Button
                                disabled={state.loading}
                                onClick={e => {
                                  if (
                                    state.listing !== 'ecommerce' &&
                                    state.userlistbuttonmenu === 'ADD PRODUCT'
                                  ) {
                                    submitForm(e);
                                  } else {
                                    setState(prevState => ({
                                      ...prevState,
                                      itemasmenulast: false,
                                      itemasstockformformenuitem: false,
                                      showImages: true,
                                      cooking: false
                                    }));
                                  }
                                }}
                                color="primary"
                                style={{
                                  backgroundColor: '#00CC70',
                                  borderRadius: '3em',
                                  width: '100%'
                                }}
                              >
                                {state.listing === 'ecommerce'
                                  ? 'Continue Setup'
                                  : state.userlistbuttonmenu}
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    ) : null}
                    {state.itemasmenucompo &&
                    !state.itemasmenulast &&
                    state.itemasmenu ? (
                      <CompoStockItem
                        state={state}
                        props={props}
                        onError={onError}
                        setState={setState}
                        handleOpenMenu={handleOpenMenu}
                        changeViewMods={changeViewMods}
                        submitForm={submitForm}
                        updateMenuItemCost={updateMenuItemCost}
                        initState={initStockState}
                      />
                    ) : null}
                    {!state.itemasstockformformenuitem &&
                    state.listing === 'ecommerce' &&
                    state.showImages ? (
                      <Images
                        setState={setState}
                        state={state}
                        props={props}
                        handleOpenMenu={handleOpenMenu}
                        submitForm={submitForm}
                        onError={onError}
                        changeViewMods={changeViewMods}
                        changeView={changeView}
                        handleDeleteMenuItem={handleDeleteMenuItem}
                        handleRestoreMenuItem={handleRestoreMenuItem}
                        onUseDimCheck={onUseDimCheck}
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Snackbar {...props} />
      </div>
    </div>
  );
};

const mapStateToProps = pState => ({
  user: pState.backend.user,
  snackbar: pState.snackbar,
  menuitems: pState.menuitems,
  breadCrumbs: pState.breadcrumbs.breadCrumbs,
  actionCrumb: pState.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addImportMenuItem,
  addImportMenuItemEcom,
  addMenuItem,
  getMenuItems,
  editMenuItem,
  editMenuItemTable,
  deleteMenuItem,
  addStockItem,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  restoreMenuItem,
  addTypes,
  addBrands,
  editCooking,
  addMultiMods,
  addMultiStockItems
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Menuitems));
