import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { Button, Col } from 'antd';
// import { updatePaymentProfile } from "@infonerd/redux/actions/profiles";
const MetaContainer = styled.div`
  min-height: 200px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
  @media screen and (min-width: 767px) {
    display: flex;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left-container {
    min-height:10em;
    flex-grow: 0.5;
    background-color: rgb(19, 36, 64);
  }
  .right-container {
    padding: 20px;
    flex-grow: 1;
  }
  .footer-container {
    text-align: center;
    width: 100%;
    .ant-btn {
      width: 90%;
    }
  }
  .meta-content {
    max-width: 100%;
    @media screen and (min-width: 767px) {
      width: 60%;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    .button {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .meta-pricing-content {
    max-width: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0.4;
  }
  .period-container {
    background-color: #ececec;
    font-size: 12px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
  }
  .color-primary {
    color: rgb(19, 36, 64);
  }
  .color-secondary {
    color: rgb(19, 36, 64);
  }
  .meta-header {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    .grade {
      font-weight: 300;
    }
    .sub-type {
      align-self: flex-end;
      color: white;
    }
    .price {
      color: white;
      font-size: 5rem;
    }
  }
  .pricing {
    justify-content: center !important;
    height: 80px;
  }
  .type {
    color: white;
    font-weight: 400;
  }
`;
class selection extends Component {
  state = {
    loading: false
  };
  componentWillMount = e => {
    var profile = this.props.paymentProfile;
    var date = moment();
    if (profile.status === 'Subscribed') {
      profile.paymentType = 'Annual';
      if (date.month() === 0) {
        profile.price = profile.devices * profile.pricePerDevice * 12;
      } else {
        profile.price = parseInt(
          (
            (12 - date.month()) *
            (profile.devices * profile.pricePerDevice)
          ).toFixed(0)
        );
      }
      profile.subscriptionStart = date.format('YYYY-MM-DD');
      profile.subscriptionEnd = date.endOf('year').format('YYYY-MM-DD');
    } else {
      profile.paymentType = 'Monthly';
      profile.price = profile.devices * profile.pricePerDevice;
      profile.subscriptionStart = date.format('YYYY-MM-DD');
      profile.subscriptionEnd = date.add(1, 'month').format('YYYY-MM-DD');
    }
    // this.props.updatePaymentProfile(profile);
  };
  handleTypeSelect = e => {
    var profile = this.props.paymentProfile;
    var date = moment();
    if (profile.paymentType === 'Monthly') {
      profile.paymentType = 'Annual';
      if (date.month() === 0) {
        profile.price = profile.devices * profile.pricePerDevice * 12;
      } else {
        profile.price = parseInt(
          (
            (12 - date.month()) *
            (profile.devices * profile.pricePerDevice)
          ).toFixed(0)
        );
      }
      profile.subscriptionStart = date.format('YYYY-MM-DD');
      profile.subscriptionEnd = date.endOf('year').format('YYYY-MM-DD');
    } else {
      profile.paymentType = 'Monthly';
      profile.price = profile.devices * profile.pricePerDevice;
      profile.subscriptionStart = date.format('YYYY-MM-DD');
      profile.subscriptionEnd = date.add(1, 'month').format('YYYY-MM-DD');
    }
    // this.props.updatePaymentProfile(profile);
    this.setState({ loading: false });
  };

  render() {
    const {
      subscription,
      name
    } = this.props.paymentProfile;
    const NextButton = () => this.props.nextButton;
    const CancelButton = () => this.props.cancelButton;
    return (
      <div style={{ maxWidth: '100%', padding: '50px 10px' }}>
        <MetaContainer>
          <div className="left-container">
            <div className="meta-pricing-content">
              <div className="meta-header pricing">
                <h3 className="price">R{subscription.monthlyPrice}</h3>
                <div className="sub-type">
                   / Month
                </div>
              </div>
            </div>
          </div>
          <div className="right-container ">
            <div className="meta-content">
              <div className="meta-header">
                <h3 className="color-primary grade">{name}</h3>
              </div>
              <div className="period-container">
                Subscription Start : 
                {moment().format('YYYY/MM/DD')}
              </div>
              <div className="footer-container">
                <Col span={12}>
                  <CancelButton block />
                </Col>
                <Col span={12}>
                  <NextButton />
                </Col>
              </div>
            </div>
          </div>
        </MetaContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  // updatePaymentProfile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(selection);
