import { Row, Table } from 'antd';
import React, { useState } from 'react';
import StyledTable from 'src/components/Table/StyledTable';
const { Column } = Table;

const changePage = page => {
  setState(prevState => ({
    ...prevState,
    currentPage: page
  }));
};

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}

let setState;
let state;
const MenuitemsTable = props => {
  [state, setState] = useState({ currentPage: 0 });
  return (
    <Row>
      <Row>
        <button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid grey',
            color: 'white',
            width: '9em',
            height: '2.5em',
            fontSize: '16px',
            fontFamily:
              'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif',

            fontWeight: 600,
            borderRadius: '3em',
            margin: 10,
            zIndex: 1
          }}
          onClick={() => props.switchBack()}
          color="primary"
        >
          {'Back'}
        </button>
      </Row>
      <Row>
        <StyledTable
          style={{ background: 'white' }}
          rowKey="_id"
          size="middle"
          pagination={{
            onChange: changePage,
            current: state.currentPage,
            hideOnSinglePage: true,
            pageSize: 25,
            itemRender: itemRender,
            showTotal: (total, range) =>
              `Showing ${range[0]} - ${range[1]}  of ${total} items`
          }}
          dataSource={props.menuitems}
          onRow={(record, rowIndex) => {
            if (!record.active) {
              return {
                style: {
                  background: 'lightcoral',
                  color: 'white'
                }
              };
            } else if (rowIndex % 2 == 0) {
              return {
                style: { background: 'rgb(247, 247, 247)' }
              };
            }
          }}
        >
          {' '}
          <Column title="Product Name" dataIndex="name" key="name" />
          <Column title="Category" dataIndex="menubtns" key="menubtns" />
          <Column title="Product Type" dataIndex="type" key="type" />
          <Column
            title="Available"
            key="Available"
            render={(text, record, key) => (
              <div>
                {record.variants && record.variants.length > 0 ? (
                  <a
                    style={{
                      border: '1px solid #00CC70',
                      borderRadius: '3em',
                      padding: '6px'
                    }}
                  >
                    {record.variants.length + ' Variants'}
                  </a>
                ) : (
                  'No Variants'
                )}
              </div>
            )}
          />
          <Column
            title="Colour"
            dataIndex="colour"
            key="colour"
            render={(text, record, key) => (
              <div>
                {record.color && record.menubtns.length > 0 ? (
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      background: record.color
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      background: 'grey'
                    }}
                  />
                )}
              </div>
            )}
          />
          <Column
            title="Delivery"
            render={(text, record) => {
              return <span>{record.delivery ? 'Yes' : 'No'}</span>;
            }}
            dataIndex="delivery"
            key="delivery"
          />
          <Column
            title="Sell Price"
            render={(text, record) => (
              <span>
                {record.sellPrice
                  ? 'R' + parseFloat(record.sellPrice).toFixed(2)
                  : 'R0.00'}
              </span>
            )}
            dataIndex="sellPrice"
            key="sellPrice"
          />
          <Column
            title="Cost Price"
            render={(text, record) => (
              <span>
                {record.costPrice
                  ? 'R' + parseFloat(record.costPrice).toFixed(2)
                  : 'R0.00'}
              </span>
            )}
            dataIndex="costPrice"
            key="costPrice"
          />
          <Column
            title="Profit Margin"
            render={(text, record) => (
              <span>
                {record.sellPrice > 0
                  ? (
                      ((record.sellPrice - (record.costPrice || 0)) /
                        record.sellPrice) *
                      100
                    ).toFixed() + '%'
                  : '0%'}
              </span>
            )}
            key="profit"
          />
        </StyledTable>
      </Row>
    </Row>
  );
};

export default MenuitemsTable;
