import { Col, Layout, Row } from 'antd';
import React from 'react';
import { AiOutlineFileText, AiOutlineUser } from 'react-icons/ai';

const { Footer } = Layout;
const CustomerFooter = props => {
  return (
    <Footer
      style={{
        height: 40,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: 'white',
        lineHeight: '40px',
        padding: 0,
        boxShadow: '0px 11px 20px 0'
      }}
    >
      <Row type="flex" justify="space-around" style={{ height: 40 }}>
        <Col span={12} style={{ display: 'flex' }}>
          <div
            onClick={e => props.navToView('orders')}
            style={{ margin: 'auto', display: 'grid' }}
          >
            <AiOutlineFileText
              style={{ margin: 'auto', color: 'darkgrey', fontSize: 20 }}
            />
            <span style={{ marginTop: -15, fontSize: 10 }}>Orders</span>
          </div>
        </Col>
        <Col span={12} style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', display: 'grid' }}>
            <AiOutlineUser
              style={{ margin: 'auto', color: 'darkgrey', fontSize: 20 }}
            />
            <span style={{ marginTop: -15, fontSize: 10 }}>Profile</span>
          </div>
        </Col>
      </Row>
    </Footer>
  );
};
export default CustomerFooter;
