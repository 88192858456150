import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ConfigProvider,
  Empty,
  Icon,
  Input,
  Popover,
  Radio,
  Row,
  Spin,
  Switch,
  Table
} from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { FiEdit2, FiPrinter } from 'react-icons/fi';
import { connect } from 'react-redux';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelectAuto from 'src/components/CustomSelectAuto/CustomSelectAuto.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  addUser,
  addUserGroup,
  deleteUser,
  editUser,
  getPrinterSettings,
  getUsers,
  resetUserPass
} from 'src/redux/actions/backend';
import {
  resetActionBreadCrumb,
  setBreadCrumbs
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import {
  handleCloseAddGroupUser,
  handleCloseAddUser,
  handleCloseTableUser,
  handleOpenAddGroupUser,
  handleOpenAddUser,
  handleOpenTableUser,
  resetUsers
} from 'src/redux/actions/subnav';

// import Table from 'src/views/Admin/Users/Table';

import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import GroupsForm from 'src/views/Admin/Groups/GroupForm';
const { Column } = Table;
const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <Empty
      imageStyle={{
        margin: '3em',
        marginBottom: '0.5em',
        height: 90
      }}
    />

    <button
      style={{
        backgroundColor: '#00CC70',
        border: '0px solid rgb(30,161,169)',
        color: 'white',
        width: '12em',
        height: '2.7em',
        fontSize: 16,
        fontFamily: '',

        borderRadius: '3em',
        zIndex: 9,
        marginTop: '1em',
        marginBottom: '1.5em'
      }}
      onClick={() => this.handleOpen(!this.state.main)}
      color="primary"
    >
      {'Add User'}
    </button>
  </div>
);
const style = { width: 200 };
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  }
});

let roles = [
  {
    value: 'isClerk',
    name: 'Sales'
  },
  {
    value: 'isAdmin',
    name: 'Admin'
  },
  {
    value: 'isKitchen',
    name: 'Kitchen'
  },
  {
    value: 'isBar',
    name: 'Bar'
  },
  {
    value: 'isDriver',
    name: 'Driver'
  }
];

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customize: true,
      _id: '',
      orgName: this.props.orgData,
      email: '',
      name: '',
      username: '',
      session: false,
      mobile: '',
      role: 'isClerk',
      userGroupRoles: [],
      userGroupRolesObject: {},
      groupName: '',
      direct: false,
      groupRoles: [],
      groupRolesAdmin: {},
      groupRolesClerk: {},
      selectGroupRoles: [],
      selectitem: undefined,
      loginType: 'Pin',
      users: [],
      loading: true,
      userhead: 'Add User',
      usersub: 'Add a New User',
      userbutton: 'ADD USER',
      userlisthead: 'Add Group',
      userlistsub: 'Add a New Group',
      userlistbutton: 'ADD GROUP',
      alert: false,
      msg: [],
      head: '',
      main: true,
      multiOpen: false,
      canDiscount: false,
      canQRBill: false,
      activeFilter: true
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.getPrinterSettings();
    this.getUsers();
    this.props.handleOpenTableUser();
  }

  getUsers = msg => {
    const token = this.props.local.user.token;
    this.props
      .getUsers(token)
      .then(result => {
        var aRoles = [];
        var oRoles = {};
        var roles = result.data.results.roles;
        for (var j = 0; j < roles.length; j++) {
          aRoles.push({
            name:
              roles[j].name.charAt(0).toUpperCase() + roles[j].name.slice(1),
            value: roles[j].name,
            id: roles[j]._id
          });
          oRoles[roles[j]._id] = { role: roles[j].role };
        }
        var oMode = result.data.results.mode[0].mode;
        var oRolesAdmin = [];
        Object.keys(oMode).map(
          key =>
            oMode[key] &&
            oRolesAdmin.push({
              name: key.charAt(0).toUpperCase() + key.slice(1),
              value: key
            })
        );
        this.setState({
          role: 'isClerk',
          groupRoles: oRolesAdmin,
          users: result.data.results.users,
          groupRolesAdmin: result.data.results.mode[0].mode,
          userGroupRoles: aRoles,
          userGroupRolesObject: oRoles,
          loading: false
        });
        this.handleRemoveBreadCrumb();
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleOpenGroup = open => {
    this.setState({
      role: this.props.local.user.pack.isFull ? 'isClerk' : 'isKitchen',
      groupName: '',
      direct: false,
      selectGroupRoles: [],
      addGroupVisible: true,
      main: false
    });
  };

  handleCloseGroup = open => {
    this.setState({
      addGroupVisible: false,
      main: false
    });
  };

  cancelAddUser = () => {
    this.handleRemoveBreadCrumb();
    this.setState({ main: true });
  };

  handleOpen = open => {
    if (open) {
      this.handleRemoveBreadCrumb();
      this.props.handleOpenAddUser();
    } else {
      this.handleAddBreadCrumb({ method: 'cancelAddUser', crumb: 'User' });
      this.props.handleCloseAddUser();
      this.setState({
        _id: '',
        email: '',
        name: '',
        username: '',
        session: false,
        mobile: '',
        canDiscount: false,
        canQRBill: false,
        selectitem: undefined,
        loginType: 'Pin',
        userhead: 'Add User',
        usersub: 'Add a New User',
        userbutton: 'ADD USER',
        main: false,
        addGroupVisible: false
      });
    }
  };

  handleOpenTable = open => {
    if (open) {
      this.props.handleOpenTableUser();
      this.setState({
        _id: '',
        email: '',
        name: '',
        username: '',
        session: false,
        mobile: '',
        canDiscount: false,
        canQRBill: false,
        selectitem: undefined,
        loginType: 'Pin',
        userhead: 'Add User',
        usersub: 'Add a New User',
        userbutton: 'ADD USER'
      });
    } else {
      this.props.handleCloseTableUser();
    }
  };

  handleChangeRoles = value => {
    this.setState({ selectGroupRoles: value });
  };

  changeMultiOpen = multi => {
    this.setState({ multiOpen: multi });
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'User Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onComplete = res => {
    var msg = [];
    msg.push('User Creation Successfull:');
    msg.push('Org ID: ' + res.data.orgid);
    msg.push('Username: ' + res.data.username);
    if (this.state.loginType === 'QR Code') {
      const code = (
        <QRCode
          size={200}
          style={{ margin: 'auto' }}
          renderAs="svg"
          value={res.data.password}
        />
      );
      msg.push(code);
    } else {
      msg.push('Pin: 0123');
    }

    this.setState({
      email: '',
      name: '',
      username: '',
      session: false,
      mobile: '',
      canDiscount: false,
      canQRBill: false,
      role: 'isClerk',
      selectitem: undefined,
      loginType: 'Pin',
      users: [],
      head: 'User Details',
      alert: true,
      msg: msg,
      main: true,
      submitInProgress: false
    });
    this.getUsers();
  };

  submitForm = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true, submitInProgress: true });
    if (this.state.userbutton === 'ADD USER') {
      this.props
        .addUser(this.state, token, this.props.local.user.orgid)
        .then(res => {
          this.onComplete(res);
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    } else if (this.state.userbutton === 'EDIT USER') {
      this.props
        .editUser(this.state, token, this.props.local.user.orgid)
        .then(res => {
          if (
            this.state.loginTypeHist &&
            this.state.loginTypeHist !== this.state.loginType
          ) {
            this.resetPassChange();
          } else {
            this.props.handleOpenTableUser();
            this.setState({
              _id: '',
              email: '',
              name: '',
              username: '',
              session: false,
              mobile: '',
              role: 'isClerk',
              selectitem: undefined,
              loginType: 'Pin',
              userhead: 'Add User',
              usersub: 'Add a New User',
              userbutton: 'ADD USER',
              canDiscount: false,
              canQRBill: false,
              main: true,
              submitInProgress: false
            });
            this.getUsers('User Update Successful');
          }
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  submitFormGroup = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .addUserGroup(this.state, token)
      .then(res => {
        var aRoles = [];
        var oRoles = {};
        var roles = res.data.results;
        for (var j = 0; j < roles.length; j++) {
          aRoles.push({
            name:
              roles[j].name.charAt(0).toUpperCase() + roles[j].name.slice(1),
            value: roles[j].name,
            id: roles[j]._id
          });
          oRoles[roles[j]._id] = { role: roles[j].role };
        }
        this.setState({
          role: 'isClerk',
          groupName: '',
          direct: false,
          selectGroupRoles: [],
          userGroupRoles: aRoles,
          userGroupRolesObject: oRoles,
          loading: false,
          addGroupVisible: false,
          main: false
        });
        this.props.openSnackbar('User Group Creation Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleSwitchGroup = on => {
    this.setState({
      direct: on
    });
  };

  resetPassChange = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .resetUserPass(this.state, token)
      .then(res => {
        this.props.handleOpenTableUser();
        this.setState({
          _id: '',
          email: '',
          name: '',
          username: '',
          session: false,
          mobile: '',
          role: 'isClerk',
          selectitem: undefined,
          loginType: 'Pin',
          userhead: 'Add User',
          usersub: 'Add a New User',
          userbutton: 'ADD USER',
          canDiscount: false,
          canQRBill: false,
          alert: true,
          msg: ['Pin reset to: 0123'],
          main: true,
          submitInProgress: false
        });
        this.getUsers();
      })
      .catch(err => {
        this.onError(err);
      });
  };

  resetPass = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .resetUserPass(this.state, token)
      .then(res => {
        this.setState({
          _id: '',
          email: '',
          name: '',
          username: '',
          session: false,
          mobile: '',
          canDiscount: false,
          canQRBill: false,
          role: 'isClerk',
          selectitem: undefined,
          loginType: 'Pin',
          userhead: 'Add User',
          usersub: 'Add a New User',
          userbutton: 'ADD USER',
          main: true
        });
        this.getUsers('User Password Reset Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  resetPassIcon = info => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .resetUserPass(info, token)
      .then(res => {
        this.setState({
          _id: '',
          email: '',
          name: '',
          username: '',
          session: false,
          mobile: '',
          canDiscount: false,
          canQRBill: false,
          role: 'isClerk',
          selectitem: undefined,
          loginType: 'Pin',
          userhead: 'Add User',
          usersub: 'Add a New User',
          userbutton: 'ADD USER',
          main: true
        });
        this.getUsers('User Password Reset Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onChangeEvt = e => {
    if (e.target.id === 'groupname') {
      this.setState({ groupName: e.currentTarget.value });
    }
    if (e.target.id === 'email') {
      this.setState({ email: e.currentTarget.value });
    }
    if (e.target.id === 'username') {
      this.setState({ username: e.currentTarget.value });
    }
    if (e.target.id === 'name') {
      this.setState({ name: e.currentTarget.value });
    }
    if (e.target.id === 'mobile') {
      if (e.currentTarget.value.length > 10) {
        e.currentTarget.value = e.currentTarget.value.substring(
          0,
          e.currentTarget.value.length - 1
        );
      }
      this.setState({ mobile: e.currentTarget.value });
    }
  };
  printJS = (data, ip, callback) => {
    var printer = null;
    const callback_createDevice = (deviceObj, errorCode) => {
      if (deviceObj === null) {
        callback('Fail to retrieve the Printer');
        return;
      }
      printer = deviceObj;
      printer.addTextStyle(false, false, false);
      printer.addTextLang('en');
      printer.addTextFont(printer.FONT_A);
      printer.addTextSmooth(false);
      printer.addSymbol(data.password, printer.SYMBOL_QRCODE_MODEL_1);
      printer.addCut();
      //Registers the print complete event
      printer.onerror = function(response) {
        callback('Printer : ' + response);
        function callback_deleteDevice(errorCode) {
          ePosDev.disconnect();
        }
        ePosDev.deleteDevice(printer, callback_deleteDevice);
      };
      printer.onreceive = function(response) {
        if (response.success) {
          function callback_deleteDevice(errorCode) {
            ePosDev.disconnect();
          }
          ePosDev.deleteDevice(printer, callback_deleteDevice);
          callback(null, { message: 'Done' });
        } else {
          callback('Printer : ' + response);
        }
      };
      if (ePosDev.isConnected) {
        printer.send();
      }
    };
    const callback_connect = resultConnect => {
      var deviceId = 'local_printer';
      var options = { crypto: true, buffer: false };
      if (resultConnect === 'OK' || resultConnect === 'SSL_CONNECT_OK') {
        ePosDev.createDevice(
          deviceId,
          ePosDev.DEVICE_TYPE_PRINTER,
          options,
          callback_createDevice
        );
      } else {
        callback('Printer : ' + resultConnect);
      }
    };
    var ePosDev = new window.epson.ePOSDevice();
    ePosDev.connect(ip, '9001', callback_connect);
  };
  getPrinterSettings = () => {
    const token = this.props.local.user.token;
    this.props
      .getPrinterSettings(token)
      .then(result => {
        this.setState({
          printqr:
            result.data.results.length > 0
              ? result.data.results[0].printqr
              : this.state.printqr,
          qr:
            result.data.results.length > 0
              ? result.data.results[0].qr
              : this.state.qr,
          qrType:
            result.data.results.length > 0
              ? result.data.results[0].qrType
                ? result.data.results[0].qrType
                : this.state.qrType
              : this.state.qrType,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  toPrinter = data => {
    if (window.device) {
      if (
        window.device.platform === 'Android' ||
        window.device.platform === 'iOS'
      ) {
        if (window.device.platform === 'Android' && data.model === 'impact') {
          window.ThermalPrinter.printFormattedTextAndCut(
            {
              type: 'tcp',
              address: data.ip,
              port: 9100,
              id: data.ip,
              text: `[C]<qrcode size='40'>${data.password}</qrcode>\n \n \n \n \n \n \n`,
              printerWidthMM: 80
            },
            function() {
              window.ThermalPrinter.disconnectPrinter(
                {
                  type: 'tcp',
                  address: data.ip,
                  port: 9100,
                  id: data.ip
                },
                function() {},
                function(error) {
                  console.log(error.toString());
                  this.onError(
                    'Connect Error: Please make sure that the Printer is Connected and Device is on the Printer Network and try again'
                  );
                }
              );
            },
            function(error) {
              window.ThermalPrinter.disconnectPrinter(
                {
                  type: 'tcp',
                  address: data.ip,
                  port: 9100,
                  id: data.ip
                },
                function() {
                  this.onError(
                    'Connect Error: Please make sure that the Printer is Connected and Device is on the Printer Network and try again'
                  );
                },
                function(error) {
                  console.log(error.toString());
                  this.onError(
                    'Connect Error: Please make sure that the Printer is Connected and Device is on the Printer Network and try again'
                  );
                }
              );
            }
          );
        } else {
          const sendToPrinter = () => {
            window.epos2.connectPrinter(
              data.ip,
              () => {
                window.epos2.print(
                  ['password', data.password],
                  () => {
                    window.epos2.disconnectPrinter();
                  },
                  error => {
                    console.log(error.toString());
                    this.onError(
                      'Connect Error: Please make sure that the Printer is Connected and Device is on the Printer Network and try again'
                    );
                  }
                );
              },
              error => {
                console.log(error.toString());
                this.onError(
                  'Connect Error: Please make sure that the Printer is Connected and Device is on the Printer Network and try again'
                );
              }
            );
          };
          sendToPrinter();
        }
      } else {
        this.printJS(data, data.ip, error => {
          if (error) {
            this.onError(error);
          }
        });
      }
    } else {
      this.printJS(data, data.ip, error => {
        if (error) {
          this.onError(error);
        }
      });
    }
  };

  toPrinterBT = data => {
    const QRCoder = QRCode;
    if (window.device) {
      if (
        window.device.platform === 'Android' ||
        window.device.platform === 'iOS'
      ) {
        window.DatecsPrinter.connect(
          data.ip,
          () => {
            QRCoder.toDataURL(
              data.password,
              { errorCorrectionLevel: 'H', width: 364 },
              (err, url) => {
                var image = new Image();
                image.onload = () => {
                  var canvas = document.createElement('canvas');
                  canvas.height = 364;
                  canvas.width = 364;
                  var context = canvas.getContext('2d');
                  context.drawImage(image, 0, 0);
                  var imageData = canvas
                    .toDataURL('image/jpeg')
                    .replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
                  window.DatecsPrinter.printImage(
                    imageData,
                    canvas.height,
                    canvas.width,
                    1,
                    () => {
                      window.DatecsPrinter.feedPaper(100, function() {});
                    },
                    error => {
                      this.onError(JSON.stringify(error));
                    }
                  );
                };
                image.src = url;
              }
            );
          },
          error => {
            this.onError(JSON.stringify(error));
          }
        );
      }
    }
  };
  handlePrintQR = record => {
    let printersInfo = JSON.parse(
      JSON.stringify(this.props.local.user.printer)
    );
    let printers = '';
    if (printersInfo && printersInfo.printers && printersInfo.printers.length) {
      for (var i = 0; i < printersInfo.printers.length; i++) {
        if (
          printersInfo.printers[i].printList.includes('qrcode') &&
          printersInfo.printers[i].address
        ) {
          printers = printersInfo.printers[i];
          break;
        }
      }
    }
    if (printers) {
      var data = {
        password: record.password,
        ip: printers.address,
        model: printers.model
      };
      if (printers.type === 'bluetooth') {
        this.toPrinterBT(data);
      } else {
        this.toPrinter(data);
      }
    } else {
      this.onError('No printer is set up for printing QR Codes');
    }
  };

  handleEditUser = oUser => {
    this.handleAddBreadCrumb({ method: 'cancelAddUser', crumb: 'User' });
    this.props.handleOpenAddUser();
    this.setState({
      _id: oUser._id,
      email: oUser.email && oUser.email.includes('@') ? oUser.email : '',
      name: oUser.name,
      username: oUser.username,
      mobile: oUser.mobile,
      canDiscount: oUser.canDiscount,
      session: oUser.session || false,
      canQRBill: oUser.canQRBill,
      selectitem: oUser.groupid || 'isSuperAdmin',
      loginType: oUser.loginType || 'Pin',
      loginTypeHist: oUser.loginType || '',
      userhead: 'Edit User',
      usersub: 'Edit a Users Details',
      userbutton: 'EDIT USER',
      main: false
    });
  };

  handleDeleteUser = oUser => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteUser(oUser, token)
      .then(res => {
        this.getUsers(
          oUser.active ? 'User Disabled Successful' : 'User Restored Successful'
        );
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeRoleType = event => {
    this.setState({ role: event.target.value });
  };

  handleChangeSelect = (value, event) => {
    if (value) {
      this.setState({
        selectitem: value
      });
    }
  };
  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };
  render() {
    const { customize } = this.state;
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditUser: this.handleEditUser,
      handleDeleteUser: this.handleDeleteUser,
      handleChangeRoleType: this.handleChangeRoleType,
      handleChangeRoles: this.handleChangeRoles,
      changeMultiOpen: this.changeMultiOpen,
      onHelpMarker: this.onHelpMarker,
      onChangeEvt: this.onChangeEvt,
      handleSwitchGroup: this.handleSwitchGroup,
      submitFormGroup: this.submitFormGroup,
      handleCloseGroup: this.handleCloseGroup,
      resetPassIcon: this.resetPassIcon,
      roles: roles
    };

    const { usergroupadd } = this.props.users.nav;
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );

    const EyeContent = (
      <div style={{ width: '8em' }}>
        <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
          <Radio
            value={1}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(true)}
          >
            Active
          </Radio>
          <Radio
            value={2}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(false)}
          >
            Inactive
          </Radio>
          <Radio
            value={3}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(undefined)}
          >
            All
          </Radio>
        </Radio.Group>
      </div>
    );

    let usersList = [];
    for (var i = 0; i < this.state.users.length; i++) {
      if (this.state.activeFilter === true) {
        if (this.state.users[i].active) {
          usersList.push(this.state.users[i]);
        }
      } else if (this.state.activeFilter === false) {
        if (!this.state.users[i].active) {
          usersList.push(this.state.users[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        usersList.push(this.state.users[i]);
      }
    }

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.main ? (
          <div>
            <Row>
              <div
                style={{
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                <Popover
                  overlayStyle={{ borderRadius: '3em' }}
                  placement="left"
                  content={EyeContent}
                  title="Table settings"
                  trigger="click"
                >
                  <Button
                    style={{
                      position: 'fixed',
                      right: '-0.6em',
                      bottom: '9em',
                      background: 'rgba(229, 233, 242, 1)',
                      border: '1px solid transparent',
                      color: 'rgba(0, 0, 0, 0.54)',
                      padding: '0em',
                      width: '3.5em',
                      height: '3.5em',
                      boxShadow: 'none',
                      borderRadius: '5em 0em 0em 5em',
                      fontSize: '1em',
                      zIndex: 1000
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      theme="filled"
                      type="setting"
                    />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Button
              style={{
                position: 'fixed',
                right: '-0.6em',
                bottom: '5em',
                background: 'rgb(0, 204, 112)',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em 0em 0em 5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.handleOpen(!this.state.main)}
              color="primary"
            >
              <Icon
                type="plus"
                style={{
                  position: 'absolute',
                  paddingLeft: 3
                }}
              />
            </Button>
            {this.state.users.length > 0 ? (
              <Row>
                <button
                  style={{
                    marginTop: '-0.7em',
                    backgroundColor: '#00CC70',
                    border: '0px solid rgb(30,161,169)',
                    color: 'white',
                    width: '8em',
                    height: '2.7em',
                    fontSize: 16,
                    borderRadius: '3em',
                    zIndex: 9,
                    position: 'absolute'
                  }}
                  onClick={() => this.handleOpen(!this.state.main)}
                  color="primary"
                >
                  <Icon
                    style={{ fontSize: '15px', marginRight: '0.3em' }}
                    type="plus-circle"
                  />
                  {'Add User'}
                </button>
              </Row>
            ) : null}
            <Row>
              <div
                style={{
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                <Popover
                  placement="bottomRight"
                  content={GearContent}
                  title=""
                  trigger="click"
                >
                  <Button
                    style={{
                      visibility: 'hidden',
                      backgroundColor: 'white',
                      border: '1px solid rgb(15, 135, 123)',
                      color: 'rgb(15, 135, 123)',
                      marginBottom: '-0.5em',
                      height: '3.5em',
                      marginLeft: '-0.27em',
                      borderRadius: '0.3em',
                      transform: 'scale(0.9)',
                      width: '2em'
                    }}
                  >
                    <Icon type="setting" />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Row>
              {' '}
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={usersList}
                  pagination={{
                    locale: { items_per_page: ' Items' },
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (rowIndex % 2 == 0) {
                      return {
                        style: { background: 'rgba(229, 233, 242, 0.5)' },
                        onClick: e => {
                          if (record.roles) {
                            if (record.roles.isSupAdmin) {
                              if (this.props.local.user.role.isSupAdmin) {
                                e.stopPropagation();
                                oState.handleEditUser(record);
                              }
                            } else {
                              e.stopPropagation();
                              oState.handleEditUser(record);
                            }
                          } else {
                            e.stopPropagation();
                            oState.handleEditUser(record);
                          }
                        }
                      };
                    } else {
                      return {
                        onClick: e => {
                          if (record.roles) {
                            if (record.roles.isSupAdmin) {
                              if (this.props.local.user.role.isSupAdmin) {
                                e.stopPropagation();
                                oState.handleEditUser(record);
                              }
                            } else {
                              e.stopPropagation();
                              oState.handleEditUser(record);
                            }
                          } else {
                            e.stopPropagation();
                            oState.handleEditUser(record);
                          }
                        }
                      };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getColumnSearchProps('mobile')}
                    title="Mobile"
                    dataIndex="mobile"
                    key="mobile"
                  />
                  <Column
                    {...this.getColumnSearchProps('email')}
                    title="Email"
                    render={(text, record) => (
                      <span>
                        {record.email && record.email.includes('@')
                          ? record.email
                          : ''}
                      </span>
                    )}
                    dataIndex="email"
                    key="email"
                  />
                  <Column
                    title="Group"
                    render={(text, record) => (
                      <span>
                        {record.roles.name ? record.roles.name : 'Super Admin'}
                      </span>
                    )}
                  />
                  <Column
                    title="Role"
                    render={(text, record) => (
                      <span>
                        {record.roles
                          ? record.roles.isClerk
                            ? 'Sales'
                            : record.roles.isKitchen
                            ? 'Kitchen'
                            : record.roles.isBar
                            ? 'Bar'
                            : record.roles.isDriver
                            ? 'Driver'
                            : record.roles.isAdmin
                            ? 'Manager'
                            : record.roles.isSupAdmin
                            ? 'Super Admin'
                            : ''
                          : ''}
                      </span>
                    )}
                  />
                  <Column
                    {...this.getColumnSearchProps('loginType')}
                    title="Login Type"
                    dataIndex="loginType"
                    key="loginType"
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div>
                        {record.roles ? (
                          !record.roles.isSupAdmin ? (
                            <IconButton
                              style={{
                                marginRight: '0em',
                                marginLeft: '-0.9em',
                                padding: '4.5px',
                                paddingRight: '0.5em',
                                paddingLeft: '0.5em',
                                borderRadius: '3em',
                                backgroundColor: 'rgb(0, 204, 112, 0.9)',
                                border: '0px solid rgb(0, 204, 112)'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                oState.resetPassIcon(record);
                              }}
                            >
                              {
                                <p
                                  style={{
                                    fontSize: 12,
                                    color: 'white',
                                    margin: 'auto'
                                  }}
                                >
                                  {/*<FiRotateCcw
                                style={{
                                  // fontSize: 15,
                                  marginBottom: "-0.15em",
                                  marginRight: "0.12em",
                                  color: "grey"
                                }}
                              />*/}
                                  Reset
                                </p>
                              }
                            </IconButton>
                          ) : this.props.local.user.role.isSupAdmin ? (
                            <IconButton
                              style={{
                                marginRight: '0em',
                                marginLeft: '-0.9em',
                                padding: '4.5px',
                                paddingRight: '0.5em',
                                paddingLeft: '0.5em',
                                borderRadius: '3em',
                                backgroundColor: 'rgb(0, 204, 112, 0.9)',
                                border: '0px solid rgb(0, 204, 112)'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                oState.resetPassIcon(record);
                              }}
                            >
                              {
                                <p
                                  style={{
                                    fontSize: 12,
                                    color: 'white',
                                    margin: 'auto'
                                  }}
                                >
                                  {/*<FiRotateCcw
                                style={{
                                  //  fontSize: 15,
                                  marginBottom: "-0.15em",
                                  marginRight: "0.12em",
                                  color: "grey"
                                }}
                              />*/}
                                  Reset
                                </p>
                              }
                            </IconButton>
                          ) : null
                        ) : null}
                        {record.loginType === 'QR Code' ? (
                          <IconButton
                            style={{
                              marginLeft: '0.3em',
                              borderRadius: '4em',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '1px solid rgba(229, 233, 242, 1)'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              this.handlePrintQR(record);
                            }}
                          >
                            {
                              <FiPrinter
                                style={{
                                  fontSize: 15
                                }}
                              />
                            }
                          </IconButton>
                        ) : null}
                        {record.roles ? (
                          !record.roles.isSupAdmin ? (
                            <IconButton
                              style={{
                                marginRight: '0em',
                                marginLeft: '0.4em',
                                borderRadius: '4em',
                                backgroundColor: 'rgba(229, 233, 242, 0.9)',
                                border: '1px solid rgba(229, 233, 242, 1)'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                oState.handleEditUser(record);
                              }}
                            >
                              {
                                <FiEdit2
                                  style={{
                                    fontSize: 15
                                  }}
                                />
                              }
                            </IconButton>
                          ) : this.props.local.user.role.isSupAdmin ? (
                            <IconButton
                              style={{
                                marginRight: '0em',
                                marginLeft: '0.4em',
                                borderRadius: '4em',
                                backgroundColor: 'rgba(229, 233, 242, 0.9)',
                                border: '1px solid rgba(229, 233, 242, 1)'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                oState.handleEditUser(record);
                              }}
                            >
                              {
                                <FiEdit2
                                  style={{
                                    fontSize: 15
                                  }}
                                />
                              }
                            </IconButton>
                          ) : null
                        ) : null}
                        {record.roles ? (
                          !record.roles.isSupAdmin && record.active ? (
                            <IconButton
                              style={{
                                marginRight: '0em',
                                marginLeft: '0.4em',
                                borderRadius: '4em',
                                backgroundColor: 'rgba(229, 233, 242, 0.9)',
                                border: '1px solid rgba(229, 233, 242, 1)'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                oState.handleDeleteUser(record);
                              }}
                            >
                              <Icon
                                type="delete"
                                style={{
                                  fontSize: 15
                                }}
                              />
                            </IconButton>
                          ) : !record.roles.isSupAdmin && !record.active ? (
                            <IconButton
                              style={{
                                marginRight: '0em',
                                marginLeft: '0.4em',
                                borderRadius: '4em',
                                backgroundColor: 'rgba(229, 233, 242, 0.9)',
                                border: '1px solid rgba(229, 233, 242, 1)'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                oState.handleDeleteUser(record);
                              }}
                            >
                              {
                                <Icon
                                  type="redo"
                                  style={{
                                    fontSize: 15
                                  }}
                                />
                              }
                            </IconButton>
                          ) : null
                        ) : null}
                      </div>
                    )}
                  />
                </StyledTable>{' '}
              </ConfigProvider>
            </Row>
          </div>
        ) : this.state.addGroupVisible ? (
          <GroupsForm {...oState} />
        ) : (
          <Card>
            {/* } <CardHeader
              style={{
                borderRadius: '0.5em',
                background: 'rgb(30,161,169)'
              }}
            >
              <h4
                style={{ color: 'white' }}
                className={this.props.classes.cardTitleWhite}
              >
                {this.state.userhead}
              </h4>
              <p
                style={{ color: 'rgba(255,255,255,.62)' }}
                className={this.props.classes.cardCategoryWhite}
              >
                {this.state.usersub}
              </p>
            </CardHeader> */}
            <CardBody style={{ marginLeft: '3em' }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Username*"
                    id="username"
                    autoFocus={true}
                    error={!this.state.username && this.state.submitInProgress}
                    errorText={'Username required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled:
                        this.state.userbutton === 'EDIT USER' ? true : false,
                      onChange: e => this.onChangeEvt(e),
                      value: this.state.username,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                </GridItem>{' '}
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Full Name*"
                    id="name"
                    error={!this.state.name && this.state.submitInProgress}
                    errorText={'Full Name required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled:
                        this.state.name === 'Super Admin' ? true : false,
                      onChange: e => this.onChangeEvt(e),
                      value: this.state.name,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address"
                    id="email"
                    error={
                      !this.state.email &&
                      this.state.userGroupRolesObject &&
                      this.state.userGroupRolesObject[this.state.selectitem] &&
                      this.state.userGroupRolesObject[this.state.selectitem]
                        .role === 'isDriver' &&
                      this.state.submitInProgress
                    }
                    errorText={'Email required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      type: 'email',
                      value: this.state.email,
                      onClick: () => this.onHelpMarker('usersemail')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Number"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      type: 'number',
                      value: this.state.mobile,
                      onClick: () => this.onHelpMarker('usersnumber')
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelectAuto
                    selectedItem={this.state.loginType}
                    onChange={e => {
                      this.setState({ loginType: e });
                    }}
                    labelText="Login Type"
                    items={[
                      {
                        name: 'Pin',
                        value: 'Pin',
                        id: 'Pin'
                      },
                      {
                        name: 'Pattern',
                        value: 'Pattern',
                        id: 'Pattern'
                      }
                    ]}
                    formControlProps={{
                      fullWidth: true
                      // onClick: () => this.onHelpMarker('menuitemsstockitem')
                    }}
                  />
                </GridItem>
                {this.state.selectitem === 'isSuperAdmin' ? null : (
                  <GridItem xs={9} sm={9} md={4}>
                    <CustomSelectAuto
                      selectedItem={this.state.selectitem}
                      onChange={this.handleChangeSelect}
                      labelText="Select Group*"
                      items={this.state.userGroupRoles}
                      error={
                        (!this.state.selectitem &&
                          this.state.submitInProgress) ||
                        this.state.session
                      }
                      errorText={
                        this.state.session
                          ? 'User Session Found'
                          : 'Group required'
                      }
                      formControlProps={{
                        fullWidth: true,
                        onClick: () => this.onHelpMarker('menuitemsstockitem')
                      }}
                      inputProps={{
                        disabled: this.state.session ? true : false
                      }}
                    />
                  </GridItem>
                )}
                {this.state.selectitem === 'isSuperAdmin' ? null : (
                  <GridItem xs={1} sm={1} md={1}>
                    <Button
                      style={{
                        marginLeft: '0em',
                        borderRadius: '0.2em',
                        paddingRight: '3em',
                        paddingLeft: '3em'
                      }}
                      onClick={() => this.handleOpenGroup(!usergroupadd)}
                      className={this.props.classes.buttonAdd}
                      color="primary"
                    >
                      <Icon
                        style={{
                          fontSize: '15px',
                          marginRight: '0.1em',
                          marginTop: '-0.05em'
                        }}
                        type="plus-circle"
                      />{' '}
                      New
                    </Button>
                  </GridItem>
                )}
              </GridContainer>

              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    marginLeft: '1em',
                    marginBottom: '1.5em',
                    marginTop: '1.5em'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        style={{ marginRight: '1em' }}
                        checked={this.state.canDiscount}
                        onChange={e => this.setState({ canDiscount: e })}
                      />
                    }
                    label=" Can add discount"
                  />
                </GridItem>{' '}
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    marginLeft: '1em',
                    marginBottom: '1.5em',
                    marginTop: '1.5em'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        style={{ marginRight: '1em' }}
                        checked={this.state.canQRBill}
                        onChange={e => this.setState({ canQRBill: e })}
                      />
                    }
                    label=" Can generate Bill QR Code"
                  />
                </GridItem>
              </GridContainer>
              <CardFooter
                style={{
                  margin: 'auto',
                  borderTop: '1px solid rgba(229, 233, 242, 1)'
                }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    style={{
                      textTransform: 'initial',
                      fontSize: '13px',
                      marginLeft: '0.5em',
                      borderRadius: '3.5em',
                      float: 'right',
                      boxShadow: 'none',
                      border: '1px solid #00CC70'
                    }}
                    onClick={this.submitForm}
                    color="primary"
                  >
                    {this.state.userbutton
                      .toLowerCase()
                      .replace(/^(.)|\s+(.)/g, c => c.toUpperCase())}
                  </Button>
                  {/*{this.state.userhead === 'Edit User' ? (
                        <Button style={{position:"absolute", top:0, right:0, textTransform: 'initial', fontSize:"13px", borderRadius: "3.5em", marginLeft: "-1em", backgroundColor: "transparent", border: "1px solid transparent", color: "crimson", boxShadow: "none" }} onClick={this.resetPass} color="primary">
                          Reset Password
                        </Button>
                      ) : null}{' '}*/}
                  <Button
                    style={{
                      textTransform: 'initial',
                      fontSize: '13px',
                      float: 'right',
                      borderRadius: '3.5em',
                      backgroundColor: 'rgba(229, 233, 242, 1)',
                      border: '1px solid rgba(229, 233, 242, 1)',
                      color: 'black',
                      boxShadow: 'none'
                    }}
                    onClick={() => {
                      this.cancelAddUser();
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </GridItem>
              </CardFooter>
            </CardBody>
          </Card>
        )}

        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  users: state.users,
  orgData: state.backend.orgname,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addUserGroup,
  getUsers,
  addUser,
  editUser,
  deleteUser,
  openSnackbar,
  closeSnackbar,
  resetUsers,
  resetUserPass,
  handleOpenAddGroupUser,
  handleCloseAddGroupUser,
  handleOpenAddUser,
  handleCloseAddUser,
  handleOpenTableUser,
  handleCloseTableUser,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  getPrinterSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Users));
