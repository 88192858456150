import React from 'react';
import { Spin, Icon } from 'antd';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'src/components/Grid/GridItem.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import { updateProfile } from 'src/redux/actions/backend';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
const styles = theme => ({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  }
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgid: '',
      username: '',
      email: '',
      name: '',
      mobile: '',
      alert: false,
      msg: [],
      head: '',
      loading: false,
      passwordConfirm: '',
      password: '',
      showpass: true,
      showpassConfirm: true,
      submitRequest: false
    };
  }
  componentDidMount() {
    this.getUser();
  }

  submitForm = () => {
    this.setState({ submitRequest: true });
    if (
      this.state.password !== '' &&
      this.state.password !== this.state.passwordConfirm
    ) {
      return;
    }
    this.setState({
      loading: true
    });
    const token = this.props.user.token;
    this.props
      .updateProfile(this.state, token)
      .then(result => {
        this.setState({
          loading: false,
          password: '',
          passwordConfirm: '',
          submitRequest: false
        });
        this.props.openSnackbar('Profile Update Success');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  getUser = () => {
    this.setState({
      orgid: this.props.user.orgid,
      email: this.props.user.email,
      name: this.props.user.name,
      mobile: this.props.user.mobile,
      loading: false
    });
  };

  onChangeEvt = (e) => {
    if (e.target.id === 'email') {
      this.setState({ email: e.currentTarget.value });
    }
    if (e.target.id === 'name') {
      this.setState({ name: e.currentTarget.value });
    }
    if (e.target.id === 'mobile') {
      if (e.currentTarget.value.length > 10) {
        e.currentTarget.value = e.currentTarget.value.substring(
          0,
          e.currentTarget.value.length - 1
        );
      }
      this.setState({ mobile: e.currentTarget.value });
    }
    if (e.target.id === 'password') {
      this.setState({ password: e.currentTarget.value });
    }
    if (e.target.id === 'passwordConfirm') {
      this.setState({ passwordConfirm: e.currentTarget.value });
    }
  }

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Profile Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog
    };
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <Card>
        {/*}  <CardHeader
            style={{
              borderRadius: '0.5em',
              background:
                'linear-gradient(90deg, rgba(38,125,117,1) 0%, rgba(12,152,139,1) 35%, rgba(19,173,159,1) 100%)'
            }}
          >
            <h4
              className={this.props.classes.cardTitleWhite}
              style={{ color: 'white' }}
            >
              Edit profile
            </h4>
            <p
              style={{ color: 'rgba(255,255,255,.62)' }}
              className={this.props.classes.cardCategoryWhite}
            >
              Complete your profile
            </p>
          </CardHeader> */}
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Full Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: this.state.name === 'Super Admin' ? true : false,
                    onChange: e => this.onChangeEvt(e),
                    value: this.state.name
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Cell Number"
                  id="mobile"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: 'number',
                    onChange: e => this.onChangeEvt(e),
                    value: this.state.mobile
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email address"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => this.onChangeEvt(e),
                    value: this.state.email
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                    labelText="Organization Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      type: this.state.showpass ? 'password' : '',
                      value: this.state.password,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState({ showpass: !this.state.showpass })
                            }
                          >
                            {this.state.showpass ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                    labelText="Confirm Password"
                    id="passwordConfirm"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={
                      this.state.password !== '' && this.state.passwordConfirm !== this.state.password && this.state.submitRequest
                    }
                    errorText={'Passwords do not match'}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      type: this.state.showpassConfirm ? 'password' : '',
                      value: this.state.passwordConfirm,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState({ showpassConfirm: !this.state.showpassConfirm })
                            }
                          >
                            {this.state.showpassConfirm ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button style={{ borderRadius:"3em"}} onClick={this.submitForm} color="primary">
              Update Profile
            </Button>
          </CardFooter>
        </Card>
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  getLocale: state,
  snackbar: state.snackbar,
  user: state.backend.user
});

const mapDispatchToProps = {
  updateProfile,
  closeSnackbar,
  openSnackbar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Profile));
