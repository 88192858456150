/* eslint-disable no-loop-func */
import { Layout, List, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/views/Customer/customer-styles.css';
const { Content } = Layout;
const changeView = view => {
  setView(view.target.value);
};
let view = 'menu';
let setView;
const MenuItems = props => {
  [view, setView] = useState(view);
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white', paddingBottom: 70 }}>
      <Row
        style={{ 
          overflow: 'scroll',
          backgroundColor: 'white',
          zIndex:9,
          position:"sticky",
          top:"3.6em"
        }}
      >
        <Radio.Group
          id="MenuItemCategory"
          style={{ width: 'max-content'}}
          value={view}
          onChange={e => changeView(e)}
        >
          {props.menu
            ? props.menu.menu.menubtns.map((button, index) => {
              // if (button.delivery && button.active) 
              return (
                  <Radio.Button
                    key={index}
                    onClick={e => props.selectMenu(button)}
                    style={{
                      textAlign: 'center',
                      borderRadius: 0,
                      height: 50
                    }}
                    value="menu"
                  >
                    <span style={{ position: 'relative', top: 9 }}>
                      {button.name}
                    </span>
                  </Radio.Button>
                );
              })
            : null}
        </Radio.Group>
      </Row>
      <Row >
        <List>
          {props.selectedMenu.menuitems.map(item => {
            let price = item.sellPrice
            if (item.cookinslist && item.cookinslist.length > 0 && parseFloat(item.sellPrice) === 0) {
              price = 10000000;
              for (var i = 0; i < item.cookinslist.length; i++) {
                for (var j = 0; j < props.menu.cooking[item.cookinslist[i].cookid].cookIns.length; j++) {
                  if (props.menu.cooking[item.cookinslist[i].cookid].cookIns[j].price < price && props.menu.cooking[item.cookinslist[i].cookid].cookIns[j].price !== 0) {
                    price = props.menu.cooking[item.cookinslist[i].cookid].cookIns[j].price
                  }
                }
              }
            }
            if ((!item.subcat || parseFloat(item.sellPrice) > 0))
              return (
                <List.Item
                  style={{
                    padding: '1em'
                  }}
                  onClick={e => props.selectItem(item)}
                  key={item._id}
                >
                  <List.Item.Meta
                    id="MenuItemName"
                    title={item.name}
                    description={
                      <div>
                        <p
                          style={{ color:"grey",
                            fontSize: '13px',
                            fontFamily: 'Roboto'
                          }}
                        >
                          {item.description}
                        </p>
                        <span style={{
                              color:"grey",
                             
                            }}>
                        {item.cookinslist.length > 0 ? "From " : ""}
                          <span
                            style={{
                              color: '#383838',
                              fontFamily: 'Roboto',
                              fontWeight: '500'
                            }}
                          >
                            R{parseFloat(price).toFixed(2)}
                          </span>
                        </span>
                      </div>
                    }
                  />
                </List.Item>
              );
          })}
        </List>
      </Row>
    </Content>
  );
};

export default MenuItems;
