import React, { memo, useEffect, useState } from 'react';
import { Spin, Icon, Table, Input, Select, TreeSelect } from 'antd';
import IconButton from '@material-ui/core/IconButton';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import StyledTableNoPagi from 'src/components/Table/StyledTableNoPagi';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import ObjectID from 'bson-objectid';
import convert from 'convert-units';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import { formaterCheck } from 'src/utils/formater.js';
const { Column } = Table;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const InputGroup = Input.Group;

const labelsSizes = [
  {
    name: 'W62RB',
    value: 'W62RB'
  },
  {
    name: 'W17H54',
    value: 'W17H54'
  },
  {
    name: 'W17H87',
    value: 'W17H87'
  },
  {
    name: 'W23H23',
    value: 'W23H23'
  },
  {
    name: 'W29H42',
    value: 'W29H42'
  },
  {
    name: 'W29H90',
    value: 'W29H90'
  },
  {
    name: 'W38H90',
    value: 'W38H90'
  },
  {
    name: 'W39H48',
    value: 'W39H48'
  },
  {
    name: 'W52H29',
    value: 'W52H29'
  },
  {
    name: 'W62H29',
    value: 'W62H29'
  },
  {
    name: 'W62H100',
    value: 'W62H100'
  },
  {
    name: 'W12',
    value: 'W12'
  },
  {
    name: 'W29',
    value: 'W29'
  },
  {
    name: 'W38',
    value: 'W38'
  },
  {
    name: 'W50',
    value: 'W50'
  },
  {
    name: 'W54',
    value: 'W54'
  },
  {
    name: 'W62',
    value: 'W62'
  },
  {
    name: 'W60H86',
    value: 'W60H86'
  },
  {
    name: 'W54H29',
    value: 'W54H29'
  }
];
const PrinterTable = allprops => {
  let oState;
  let setState;

  const removePrinter = () => {
    let printers = oState.state.printers;
    let key = oState.state.delKey;
    printers.splice(key, 1);
    if (!printers.length) {
      printers.push({
        name: '',
        type: 'ethernet',
        size: 'big',
        printList: [],
        address: ''
      });
    }
    oState.setState(prevState => ({
      ...prevState,
      printers: printers,
      pdopen: false,
      delKey: 0
    }));
  };

  const removePrinterDialog = key => {
    oState.setState(prevState => ({
      ...prevState,
      pdopen: true,
      delKey: key
    }));
  };

  const addPrinterStock = () => {
    let printers = oState.state.printers;
    printers.push({
      name: '',
      type: 'ethernet',
      size: 'big',
      printList: [],
      address: ''
    });
    oState.setState(prevState => ({
      ...prevState,
      printers: printers
    }));
  };

  const fetchBrotherdevices = key => {
    oState.setState(prevState => ({
      ...prevState,
      loadFetch: true
    }));
    let printers = oState.state.printers;
    if (window.device && window.plugin) {
      if (
        window.device.platform === 'Android' &&
        window.plugin.brotherPrinter &&
        window.plugin.brotherPrinter.findNetworkPrinters
      ) {
        window.plugin.brotherPrinter.findNetworkPrinters(
          devices => {
            if (devices && devices.length) {
              printers[key].printDetails = devices[0];
              printers[key].printDetails.paperLabelName = 'W62RB';
            } else {
              return;
            }
            printers[key].size = 'small';
            oState.setState(prevState => ({
              ...prevState,
              printers: printers,
              loadFetch: false
            }));
          },
          error => {
            alert(JSON.stringify(error));
            oState.setState(prevState => ({
              ...prevState,
              printers: printers,
              loadFetch: false
            }));
          }
        );
      } else {
        alert('Android Device not Detected');
        oState.setState(prevState => ({
          ...prevState,
          printers: printers,
          loadFetch: false
        }));
      }
    } else {
      alert('Android Device not Detected');
      oState.setState(prevState => ({
        ...prevState,
        printers: printers,
        loadFetch: false
      }));
    }
  };

  const fetchBTdevices = key => {
    oState.setState(prevState => ({
      ...prevState,
      loadFetch: true
    }));
    let printers = oState.state.printers;
    if (window.device) {
      if (
        window.device.platform === 'Android' ||
        window.device.platform === 'iOS'
      ) {
        window.DatecsPrinter.listBluetoothDevices(
          function(devices) {
            var devicesList = [
              {
                name: 'Refresh',
                value: 'refresh'
              },
              {
                name: 'Add Manually',
                value: 'add'
              }
            ];
            if (devices && devices.length) {
              for (var i = 0; i < devices.length; i++) {
                if (devices[i].address) {
                  devicesList.push({
                    name: devices[i].name,
                    value: devices[i].address
                  });
                }
              }
            }
            printers[key].btCheck = true;
            printers[key].size = 'small';
            oState.setState(prevState => ({
              ...prevState,
              btPrinters: devicesList,
              printers: printers,
              loadFetch: false
            }));
          },
          function(error) {
            alert(JSON.stringify(error));
            printers[key].btCheck = false;
            oState.setState(prevState => ({
              ...prevState,
              printers: printers,
              loadFetch: false
            }));
          }
        );
      } else {
        alert('Mobile Device not Detected');
        printers[key].btCheck = false;
        oState.setState(prevState => ({
          ...prevState,
          printers: printers,
          loadFetch: false
        }));
      }
    } else {
      alert('Mobile Device not Detected');
      printers[key].btCheck = false;
      oState.setState(prevState => ({
        ...prevState,
        printers: printers,
        loadFetch: false
      }));
    }
  };

  const handleChange = (eventN, key) => {
    let event = Object.assign({}, eventN);
    let printers = oState.state.printers;
    let btPrinters = oState.state.btPrinters;
    if (event.target.name === 'Type') {
      delete printers[key].printDetails;
      printers[key].type = event.target.value;
      printers[key].address = '';
      delete printers[key].btCheck;
      oState.setState(prevState => ({
        ...prevState,
        printers: printers
      }));
      if (printers[key].type === 'bluetooth') {
        if (btPrinters && btPrinters.length > 1) {
          printers[key].btCheck = true;
          oState.setState(prevState => ({
            ...prevState,
            printers: printers
          }));
        } else {
          fetchBTdevices(key);
        }
      } else if (printers[key].type === 'brother') {
        fetchBrotherdevices(key);
      }
    }
    if (event.target.name === 'Size') {
      if (printers[key].printDetails) {
        printers[key].printDetails.paperLabelName = event.target.value;
      }
      printers[key].size = event.target.value;
      oState.setState(prevState => ({
        ...prevState,
        printers: printers
      }));
    }
    if (event.target.name === 'Model') {
      printers[key].model = event.target.value;
      oState.setState(prevState => ({
        ...prevState,
        printers: printers
      }));
    }
    if (event.target.name === 'Printname') {
      if (event.target.value === 'refresh') {
        fetchBTdevices(key);
        return;
      }
      if (event.target.value === 'add') {
        delete printers[key].btCheck;
      } else {
        printers[key].address = event.target.value;
        printers[key].name = !printers[key].name
          ? event.currentTarget.innerText
          : printers[key].name;
        delete printers[key].btCheck;
      }
      oState.setState(prevState => ({
        ...prevState,
        printers: printers
      }));
    }
  };

  const onChangeEvt = (eN, nameN, keyN) => {
    let key = keyN;
    let e = Object.assign({}, eN);
    let stockName = Object.assign({}, nameN);
    let printers = oState.state.printers;
    if (e.target.id === 'printernamein') {
      printers[key].name = e.currentTarget.value;
    }
    if (e.target.id === 'address') {
      if (printers[key].type === 'bluetooth') {
        var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
          str = e.currentTarget.value.replace(/[^a-f0-9]/gi, '');

        while (r.test(str)) {
          str = str.replace(r, '$1' + ':' + '$2');
        }

        e.currentTarget.value = str.slice(0, 17);
      } else {
        var r = /([a-f0-9]{3})([a-f0-9]{3})/i,
          str = e.currentTarget.value.replace(/[^0-9.]/g, '');

        while (r.test(str)) {
          str = str.replace(r, '$1' + '.' + '$2');
        }

        e.currentTarget.value = str.slice(0, 15);
      }
      printers[key].address = e.currentTarget.value;
    }
    oState.setState(prevState => ({
      ...prevState,
      printers: printers
    }));
  };

  const handleChangeTypes = (value, key) => {
    let printers = oState.state.printers;
    printers[key].printList = value;
    oState.setState(prevState => ({
      ...prevState,
      printers: printers
    }));
  };

  oState = {
    ...allprops
  };

  return (
    <div style={{ margin: 'auto', marginTop: '1em' }}>
      <FormDialog
        head="Delete Printer"
        msg="Are you sure?"
        formElements={[]}
        open={oState.state.pdopen}
        close={oState.closeDialog}
        actionBtn="Yes"
        closeBtn="No"
        onClick={removePrinter}
      />
      <Spin spinning={oState.state.loadFetch}>
        <StyledTableNoPagi
          style={{ background: 'white' }}
          rowKey="_id"
          dataSource={oState.state.printers}
          footer={() => (
            <div
              style={{
                textAlign: 'center'
              }}
            >
              <a
                onClick={e => addPrinterStock()}
                style={{
                  textAlign: 'center',
                  fontSize: '15px',
                  color: 'rgb(0, 204, 112)'
                }}
              >
                <Icon
                  type="plus-circle"
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0, 204, 112)'
                  }}
                />
                {' Add Printer'}
              </a>
            </div>
          )}
        >
          <Column
            title="Type"
            width="8em"
            render={(text, record, key) => (
              <div>
                <CustomSelect
                  labelText=""
                  id="Type"
                  formControlProps={{
                    style: { marginTop: '0' },
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => handleChange(e, key),
                    value: record.type
                  }}
                  menuItems={[
                    {
                      name: 'Bluetooth',
                      value: 'bluetooth'
                    },
                    {
                      name: 'Ethernet',
                      value: 'ethernet'
                    },
                    {
                      name: 'Brother',
                      value: 'brother'
                    }
                  ]}
                />
              </div>
            )}
          />
          <Column
            title="Model"
            width="8em"
            render={(text, record, key) => (
              <div>
                <CustomSelect
                  labelText=""
                  id="Model"
                  formControlProps={{
                    style: { marginTop: '0' },
                    fullWidth: true,
                    disabled: record.type !== 'ethernet'
                  }}
                  inputProps={{
                    onChange: e => handleChange(e, key),
                    value: record.model
                  }}
                  menuItems={[
                    {
                      name: 'Impact',
                      value: 'impact'
                    },
                    {
                      name: 'Epson',
                      value: 'epson'
                    }
                  ]}
                />
              </div>
            )}
          />

          <Column
            title="Printer Name"
            width="13em"
            align="left"
            render={(text, record, key) => (
              <div>
                {record.btCheck ? (
                  <CustomSelect
                    labelText="Select Printer"
                    id="Printname"
                    formControlProps={{
                      style: { marginTop: '0' },
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => handleChange(e, key),
                      value: undefined
                    }}
                    menuItems={oState.state.btPrinters || []}
                  />
                ) : (
                  <CustomInput
                    labelText=""
                    id={'printernamein'}
                    formControlProps={{
                      style: { marginTop: '0' },
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: record.type === 'brother' ? true : false,
                      value:
                        record.type === 'brother'
                          ? record.printDetails
                            ? record.printDetails.modelName
                            : 'Not Found'
                          : record.name,
                      onChange: e => onChangeEvt(e, record, key)
                    }}
                  />
                )}
              </div>
            )}
            dataIndex="name"
            key="name"
          />
          <Column
            title="IP / MAC"
            width="13em"
            render={(text, record, key) => (
              <div>
                <CustomInput
                  labelText=""
                  id={'address'}
                  formControlProps={{
                    style: { marginTop: '0' },
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: record.type === 'brother' ? true : false,
                    value:
                      record.type === 'brother'
                        ? record.printDetails
                          ? record.printDetails.ipAddress
                          : 'Not Found'
                        : record.address,
                    onChange: e => onChangeEvt(e, record, key)
                  }}
                />
              </div>
            )}
          />
          <Column
            title="Size"
            width="5em"
            render={(text, record, key) => (
              <div>
                <CustomSelect
                  labelText=""
                  id="Size"
                  formControlProps={{
                    style: { marginTop: '0' },
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => handleChange(e, key),
                    value:
                      record.type === 'brother'
                        ? record.printDetails
                          ? record.printDetails.paperLabelName
                          : 'Not Found'
                        : record.size
                  }}
                  menuItems={
                    record.type === 'brother'
                      ? labelsSizes
                      : [
                          {
                            name: '80mm',
                            value: 'big'
                          },
                          {
                            name: '58mm',
                            value: 'small'
                          }
                        ]
                  }
                />
              </div>
            )}
          />
          <Column
            title="Type To Print"
            width="22em"
            render={(text, record, key) => (
              <div
                style={
                  record.type === 'brother'
                    ? { display: 'none' }
                    : { display: 'block' }
                }
              >
                <TreeSelect
                  treeData={oState.state.types || []}
                  value={record.printList}
                  onChange={e => handleChangeTypes(e, key)}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_PARENT}
                  placeholder="Select Types"
                  style={{
                    width: '100%'
                  }}
                />
              </div>
            )}
          />
          <Column
            title="Action"
            width="10em"
            align="center"
            render={(text, record, key) => (
              <div>
                {record.address ||
                (record.type === 'brother' &&
                  record.printDetails &&
                  record.printDetails.ipAddress) ? (
                  <IconButton
                    style={{
                      width: '3em',
                      padding: '0.35em',
                      borderRadius: '3em',
                      backgroundColor: 'rgb(0, 204, 112)',
                      border: '0px solid rgb(0, 204, 112)'
                    }}
                    onClick={e =>
                      oState.testPrint(
                        record.type,
                        record.address,
                        record.size,
                        record
                      )
                    }
                  >
                    {
                      <p
                        style={{
                          fontSize: 13,
                          color: 'white',
                          fontWeight: 700,
                          fontFamily: 'Mulish , sans-serif',
                          margin: 'auto'
                        }}
                      >
                        {/*<FiEdit
                                          style={{
                                            fontSize: 12,
                                            color: "white",
                                            margin: "auto"
                                          }}
                                        />*/}
                        Print
                      </p>
                    }
                    {/*<Icon
                                        type="printer"
                                        style={{

                                            fontSize: 15,
                                        }}
                                        onClick={e => oState.testPrint(record.type, record.address, record.size)}
                                    />*/}
                  </IconButton>
                ) : null}
                <IconButton
                  style={{
                    marginRight: '0em',
                    marginLeft: '0.4em',
                    borderRadius: '4em',
                    backgroundColor: 'rgba(229, 233, 242, 0.9)',
                    border: '1px solid rgba(229, 233, 242, 1)'
                  }}
                >
                  <Icon
                    type="delete"
                    style={{
                      fontSize: 15
                    }}
                    onClick={e => removePrinterDialog(key)}
                  />
                </IconButton>
              </div>
            )}
          />
        </StyledTableNoPagi>
      </Spin>
    </div>
  );
};

export default memo(PrinterTable);
