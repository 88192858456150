
import React, { memo } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Button from './KeypadButton';
import { media } from './media-templates.jsx';

const Content = styled(Paper)`
  display: flex;
  flex-direction: column;
  ${media.mobile`width: 100%;`} 
  
  ${props =>
    props.position === 'fullscreen'
      ? `
  width: 100vw;
  height: 100vh;
  font-size: 1.2em;
  `
      : `
      width: 315px;
    height: 300px;
  `}
  
  background-color: transparent !important;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;  
`;

const Keys = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  background-color: transparent !important;
`;

const KeyPad = ({ click, clear, backspace }) => {
  const keypadKeys = ['7', '8', '9', '4', '5', '6', '1', '2', '3', 'C', '0', '<<'];
  return (
    <Content square>
      <Keys>
        {keypadKeys.map(key => (
          <Button
            key={'buttonnum-' + key}
            click={key === 'C' ? clear : key === '<<' ? backspace : click}
            value={key}
          />
        ))}
      </Keys>
    </Content>
  );
};

export default memo(KeyPad);