/* eslint-disable no-loop-func */
import { Col, Layout, List, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
const { Content } = Layout;

let deliveryCost = 0;
let setDeliveryCost;
const Cart = props => {
  [deliveryCost, setDeliveryCost] = useState(deliveryCost);
  useEffect(() => {
    if (props.address === 'Collect') {
      setDeliveryCost(0);
    } else {
      setDeliveryCost(props.bill.deliveryCost);
    }

    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white', paddingBottom: 170 }}>
      <Row type="flex" justify="space-between">
        {' '}
        <Col span={24}>
          <h1
            style={{
              fontWeight: 500,
              textAlign: 'center',
              margin: 'auto',
              paddingTop: '0.5em',
              lineHeight: '1.3em',
              fontFamily: 'Roboto',
              fontSize: '1.7em'
            }}
          >
            {props.bill.orgName}
          </h1>
        </Col>
        <Col span={12}>
          <h2
            style={{
              fontSize: 18,
              paddingLeft: '1em',
              margin: 'auto'
            }}
          >
            Your order
          </h2>
        </Col>
        <Col span={12}>
          <span
            style={{
              float: 'right',
              paddingRight: '1em',
              paddingTop: '0.5em',
              fontFamily: 'Roboto',
              fontSize: '1em'
            }}
          >
            # {props.bill.orderno}
          </span>
        </Col>
      </Row>
      <Row>
        <List
          dataSource={props.bill.items}
          renderItem={item => (
            <List.Item
              style={{
                padding: '1em',
                borderRadius: '0.3em',
                backgroundColor: 'white',
                margin: '1em',
                border: '1px solid lightgrey',
                boxShadow: '1px 3px 3px 0px rgba(0,0,0,0.10)'
              }}
              key={item._id}
            >
              <List.Item.Meta
                id="MenuItemName"
                title={item.qty + 'x ' + item.name}
                description={
                  <div>
                    <p style={{ color: 'black' }}>{item.description}</p>
                    {item.cookIns.length > 0 ? (
                      <p style={{ color: 'black' }}>
                        <span style={{ fontWeight: 700 }}> Extras: </span>
                        {item.cookIns.map((cook, index) => {
                          if (index + 1 === item.cookIns.length) {
                            return cook.name;
                          } else {
                            return cook.name + ', ';
                          }
                        })}
                      </p>
                    ) : null}
                  </div>
                }
              />
              <div style={{ display: 'grid' }}>
                <Row
                  type="flex"
                  justify="space-around"
                  style={{
                    paddingTop: '0.8em'
                  }}
                >
                  <span
                    style={{
                      fontWeight: 700,
                      margin: 'auto',

                      fontSize: '1.3em',
                      color: 'black'
                    }}
                  >
                    R {(item.total * item.qty).toFixed(2)}
                  </span>
                </Row>
              </div>
            </List.Item>
          )}
        />
      </Row>{' '}
      <div
        style={{
          position: 'fixed',
          zIndex: 2,
          padding: '1em',
          boxShadow: '1px 3px 3px 0px rgba(0,0,0,0.10)',
          width: '100%',
          bottom: '3.6em',
          backgroundColor: 'white'
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={12}>
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              Subtotal
            </span>
          </Col>
          <Col span={12}>
            <span
              style={{
                float: 'right',

                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              R{' '}
              {deliveryCost !== 'false'
                ? (props.bill.total - props.bill.deliveryCost).toFixed(2)
                : props.bill.total.toFixed(2)}
            </span>
          </Col>
        </Row>
        {props.bill.tip > 0 ? (
          <Row style={{}} type="flex" justify="space-between">
            <Col span={12}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                Tip
              </span>
            </Col>
            <Col span={12}>
              <span
                style={{
                  float: 'right',

                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                {'R ' + props.bill.tip.toFixed(2)}
              </span>
            </Col>
          </Row>
        ) : null}
        {props.bill.address !== 'Collect' ? (
          <Row style={{}} type="flex" justify="space-between">
            <Col span={12}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                Delivery fee
              </span>
            </Col>
            <Col span={12}>
              {props.bill.address === 'Collect' ? (
                <span
                  style={{
                    float: 'right',

                    fontFamily: 'Roboto',
                    fontSize: '1.2em',
                    color: 'black'
                  }}
                >
                  Collect
                </span>
              ) : (
                <span
                  style={{
                    float: 'right',

                    fontFamily: 'Roboto',
                    fontSize: '1.2em',
                    color: 'black'
                  }}
                >
                  {deliveryCost === 0 || deliveryCost === 'false'
                    ? 'Free'
                    : 'R ' + deliveryCost}
                </span>
              )}
            </Col>
          </Row>
        ) : null}
        <Row style={{}} type="flex" justify="space-between">
          <Col span={12}>
            <span
              style={{
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: '1.4em',
                color: 'black'
              }}
            >
              Total
            </span>
          </Col>
          <Col span={12}>
            <span
              style={{
                fontWeight: 700,
                float: 'right',

                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              R{' '}
              {props.bill.tip > 0
                ? (props.bill.total + props.bill.tip).toFixed(2)
                : props.bill.total.toFixed(2)}
            </span>
          </Col>
        </Row>{' '}
      </div>
    </Content>
  );
};

export default Cart;
