export const formaterCheck = (value) => {
    var check = false;
    var validated = false;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if (!value) {
        value = '0';
    }
    if (value[value.length - 1] === ',' || value[value.length - 1] === '.') {
        value = value.replace(',', '').replace('.', '');
        check = true;
    }
    if (!value.includes('.0')) {
        value = (Math.round(parseFloat(value) * 1000) / 1000).toString();
    } else {
        let valueSplit = value.split('.')
        value = parseFloat(value).toString() + (valueSplit[1] && reg.test(valueSplit[1]) ? '.'+valueSplit[1] : '')
    }
    if (check && !value.includes('.')) {
        value = value + '.';
    }
    if (!isNaN(parseFloat(value) && reg.test(value))) {
        return value;
    } else {
        return -1;
    }
};

export const formaterCheckNum = (value) => {
    var check = false;
    var validated = false;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if (!value) {
        value = '0';
    }
    if (value[value.length - 1] === ',' || value[value.length - 1] === '.') {
        value = value.replace(',', '').replace('.', '');
        check = true;
    }
    if (!value.includes('.0')) {
        value = (Math.round(parseFloat(value) * 1000) / 1000).toString();
    } else {
        let valueSplit = value.split('.')
        value = parseFloat(value).toString() + (valueSplit[1] && reg.test(valueSplit[1]) ? '.'+valueSplit[1] : '')
    }
    if (check && !value.includes('.')) {
        value = value + '.';
    }
    if (!isNaN(parseFloat(value) && reg.test(value))) {
        return value;
    } else {
        return false;
    }
};

export const receiptformatter = (value) => {
    if (value) {
        value.replace('\\n', '\n');
        var receipt = value.split('\\n');
        value = [];
        for (var i = 0; i < receipt.length; i++) {
            var text = receipt[i];
            var bold = false;
            var size = false;
            let style = { width: '100%', height: '25px', border: 'none', paddingLeft: 0, paddingRight: 0, fontSize: '3.66mm', fontWeight: 400 };
            if (text.includes('{B}')) {
                text = text.replace('{B}', '');
                bold = true;
                style.fontWeight = 'bold';
            }
            if (text.includes('{S}')) {
                text = text.replace('3{S}', '');
                size = true;
                style.fontSize = '7.1mm';
            }
            value[i] = {
                text: text + '\\n',
                bold: bold,
                size: size,
                style: style
            };
        }
    }
    return value;
};

export const b64toBlob = (dataURI) => {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
};

export const createUUID = (count) => {
    var baseUUID = '';
    var dt = new Date().getTime();
    for (let i = 0; i < count; i++) { baseUUID += 'xxxxxx' }
    var uuid = baseUUID.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
}

export const createUUIDLink = (count) => {
    var aBaseUUID = [];
    var sBaseUUID = '';
    var dt = new Date().getTime();
    for (let a = 0; a < count; a++) { aBaseUUID.push('xxxxx') };
    for (let b = 0; b < aBaseUUID.length; b++) {
        let genID = aBaseUUID[b].replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        sBaseUUID += genID;
        aBaseUUID[b] = genID;
    }
    return {
        linkId: sBaseUUID,
        linkIdArray: aBaseUUID
    };
}