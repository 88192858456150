import React from 'react';
const colourList = [
  {
    value: '#21B683',
    label: <div style={{ width: 20, height: 20, background: '#21B683' }} />
  },
  {
    value: '#21B68F',
    label: <div style={{ width: 20, height: 20, background: '#21B68F' }} />
  },
  {
    value: '#21B69C',
    label: <div style={{ width: 20, height: 20, background: '#21B69C' }} />
  },
  {
    value: '#21B6A8',
    label: <div style={{ width: 20, height: 20, background: '#21B6A8' }} />
  },
  {
    value: '#21B6B4',
    label: <div style={{ width: 20, height: 20, background: '#21B6B4' }} />
  },
  {
    value: '#21ADB6',
    label: <div style={{ width: 20, height: 20, background: '#21ADB6' }} />
  },
  {
    value: '#219BB6',
    label: <div style={{ width: 20, height: 20, background: '#219BB6' }} />
  },
  {
    value: '#2193B6',
    label: <div style={{ width: 20, height: 20, background: '#2193B6' }} />
  },
  {
    value: '#2186B6',
    label: <div style={{ width: 20, height: 20, background: '#2186B6' }} />
  },
  {
    value: '#217AB6',
    label: <div style={{ width: 20, height: 20, background: '#217AB6' }} />
  },
  {
    value: 'rgb(33, 56, 121)',
    label: (
      <div style={{ width: 20, height: 20, background: 'rgb(33, 56, 121)' }} />
    )
  },
  {
    value: 'olive',
    label: <div style={{ width: 20, height: 20, background: 'olive' }} />
  },
  {
    value: 'purple',
    label: <div style={{ width: 20, height: 20, background: 'purple' }} />
  },
  {
    value: 'teal',
    label: <div style={{ width: 20, height: 20, background: 'teal' }} />
  },
  {
    value: '#02ac18',
    label: <div style={{ width: 20, height: 20, background: '#02ac18' }} />
  },
  {
    value: 'rgb(54, 170, 158)',
    label: (
      <div style={{ width: 20, height: 20, background: 'rgb(54, 170, 158)' }} />
    )
  },
  {
    value: 'darkcyan',
    label: <div style={{ width: 20, height: 20, background: 'darkcyan' }} />
  },
  {
    value: 'mediumseagreen',
    label: (
      <div style={{ width: 20, height: 20, background: 'mediumseagreen' }} />
    )
  },
  {
    value: 'crimson',
    label: <div style={{ width: 20, height: 20, background: 'crimson' }} />
  }
];
export default colourList;
