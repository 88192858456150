import { Icon, List } from 'antd';
import React, { Component } from 'react';
import styled from 'styled-components';
const { Item } = List;
const ListItem = styled(Item)`
  && {
    padding: 0px !important;
  }
`;

const MetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 200px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
  h {
    &1,
    &2,
    &3,
    &4,
    &5 {
      margin: 0;
    }
  }
  .title-container {
    height: 50px;
    background: rgb(19, 36, 64);
    color: white;
    padding: 22px;
    align-items: center;
    display: flex;
  }
  .meta-content,
  .footer-container {
    text-align: center;
    padding: 20px;
  }
  .color-primary {
    color: rgb(19, 36, 64);
  }
  .color-secondary {
    color: rgb(19, 36, 64);
  }
  .title {
    color: #00b0ad;
  }
`;
const completed = (props) => {
  const ContinueButton = () => props.continueButton;
  const CancelButton = () => props.cancelButton;
  const TryAgainButton = () => props.tryAgainButton;


  return (
    <div
      style={{
        width: 600,
        maxWidth: '100%',
        padding: '50px 10px',
        margin: 'auto'
      }}
    >
      <ListItem key={props.paymentProfile._id}>
        <MetaContainer>
          <div className="meta-content">
            {window.location.search === '?completed' || props.paymentProfile.resultStatus === "completed" ? (
              <Icon
                style={{ color: '#80cf38', fontSize: '6em' }}
                twoToneColor="#00b0ad"
                type="check-circle"
                theme="filled"
              />
            ) : (
                <Icon
                  style={{ color: 'rgb(214, 66, 66)', fontSize: '6em' }}
                  twoToneColor="#00b0ad"
                  type="close-circle"
                  theme="filled"
                />
              )}
            {window.location.search === '?completed' || props.paymentProfile.resultStatus === "completed"  ? (
              <h2 className="title">Payment Successful</h2>
            ) : (
                <h2 className="title">Payment Cancelled</h2>
              )}
            {window.location.search === '?completed' || props.paymentProfile.resultStatus === "completed" ? (
              <span>A receipt will be sent to your email.</span>
            ) : (
                <span>Please try again.</span>
              )}
          </div>
          <div className="footer-container">
            {window.location.search === '?completed' || props.paymentProfile.resultStatus === "completed" ? (
              <ContinueButton />
            ) : (
                ''
              )}
            {window.location.search === '?cancelled' || props.paymentProfile.resultStatus === "cancelled" ? (
              <CancelButton />
            ) : (
                ''
              )}
            {window.location.search === '?cancelled' || props.paymentProfile.resultStatus === "cancelled" ? (
              <TryAgainButton />
            ) : (
                ''
              )}
          </div>
        </MetaContainer>
      </ListItem>
    </div>
  );

}

export default completed;
