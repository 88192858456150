import React from 'react';
import { Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Style from 'src/components/Layout/Admin/Style';

import AdminRoutes from 'src/config/AdminRoutes';
var Routes = '';
const MenuComponent = ({ props }) => {
  const { classes, Mode } = props;

  return (
    <List>
      {Object.keys(Routes).map(
        key =>
          Mode[key] && (
            <NavLink
              to={Routes[key].path}
              className={classes.item}
              activeClassName="active"
              key={Routes[key].path}
            >
              <ListItem button>
                <Icon className={classes.itemIcon} type={Routes[key].icon} />
                <ListItemText
                  primary={Routes[key].name}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          )
      )}
    </List>
  );
};

const HeaderComponent = () => {
  return (
    <div>
      <IconButton
        component={NavLink}
        to={Routes.profile.path}
        activeClassName="active"
        key={Routes.profile.path}
        color="inherit"
      >
        {<Icon type={Routes.profile.icon} />}
      </IconButton>
    </div>
  );
};

const getRoutes = routes => {
  Routes = routes;
};

const MenuComponentLogout = ({ props }) => {
  const { classes } = props.props;
  return (
    <List>
      <NavLink
        to={Routes['logout'].path}
        className={classes.item}
        activeClassName="active"
        key={Routes['logout'].path}
      >
        <ListItem button onClick={() => props.handleLogout()}>
          <Icon className={classes.itemIcon} type={Routes['logout'].icon} />
          <ListItemText
            primary={Routes['logout'].name}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    </List>
  );
};

const MenuComponentLogoutOrg = ({ props }) => {
  const { classes } = props.props;
  return (
    <List>
      <NavLink
        to={Routes['logoutorg'].path}
        className={classes.item}
        activeClassName="active"
        key={Routes['logoutorg'].path}
      >
        <ListItem button onClick={() => props.handleLogoutOrg()}>
          <Icon className={classes.itemIcon} type={Routes['logoutorg'].icon} />
          <ListItemText
            primary={Routes['logoutorg'].name}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    </List>
  );
};

const DrawerS = ({ ...propstate }) => {
  const props = propstate.props;
  const state = propstate.state;
  const { classes, theme } = props;
  const { openMini, closeMini } = state;
  return (
    <div>
      <AdminRoutes newobjectroutes={getRoutes} />
      <AppBar position="fixed" className={classes.appBarS}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={propstate.handleDrawerOpenMini}
            className={classNames(classes.menuButton, openMini && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={propstate.handleDrawerCloseMini}
            className={classNames(
              classes.iconButton,
              closeMini && classes.hide
            )}
          >
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            {'VPOS ADMIN ' + props.user.name}
          </Typography>
          <div className={classes.personButton}>
            <HeaderComponent />
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.hide]: !openMini,
          [classes.drawerClose]: openMini
        })}
        classes={{
          paper: classNames(classes.drawerPaperMini, {
            [classes.hide]: !openMini,
            [classes.drawerClose]: openMini
          })
        }}
        ModalProps={{
          keepMounted: true
        }}
        open={openMini}
      >
        <div className={classes.backgroundMini} />
        <MenuComponent props={props} />
        <Divider />
        <MenuComponentLogout props={propstate} />
        <MenuComponentLogoutOrg props={propstate} />
      </Drawer>
    </div>
  );
};

DrawerS.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(Style, { withTheme: true })(DrawerS);
