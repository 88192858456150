export default {
  onUpdate: registration => {
    if (window.cordova) {
      navigator.notification.alert(
        'Update ready the app will restart now.', // message
        update, // callback
        'Update Available', // title
        'Ok' // buttonName
      );
    } else {
      alert('Update ready the app will restart now.');
      registration.unregister().then(() => {
        caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
        window.location.reload();
      });
    }
    function update() {
      registration.unregister().then(() => {
        caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
        window.location.reload();
      });
    }
  },
  onSuccess: registration => {}
};
