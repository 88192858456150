import React from 'react';
import {
  Spin,
  Icon,
  Table,
  Row,
  Input,
  Popover,
  Radio,
  ConfigProvider,
  Empty,
  Col
} from 'antd';
import { connect } from 'react-redux';
import {
  addCooking,
  editCooking,
  getCooking,
  closeCookingHelpCard
} from 'src/redux/actions/backend';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import Highlighter from 'react-highlight-words';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import Card from 'src/components/Card/Card.jsx';
import styled from 'styled-components';
import Button from 'src/components/CustomButtons/Button.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { openSnackbar, closeSnackbar } from 'src/redux/actions/snackbar';
import { formaterCheck } from 'src/utils/formater.js';
import MenuItemsTable from 'src/views/Admin/Settings/Cooking/MenuItemsTable';
import { FiEdit } from 'react-icons/fi';
import { setHelpMarker } from 'src/redux/actions/help';
import StyledTable from 'src/components/Table/StyledTable';
import CookingView from 'src/views/Admin/Settings/Cooking/CookingView';
import convert from 'convert-units';
import {
  primaryColor,
  grayColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx';
const { Column } = Table;
const StyledCard = styled(Card)``;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  panel: {
    width: '500px'
  },
  cardExpand: {
    marginTop: '22px',
    marginBottom: '5px'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  showMain: {
    paddingLeft: '10px',
    display: 'inline-flex'
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  buttonAddInputCooking: {
    marginTop: '43px',
    height: '35px'
  }
});

const StyledRadio = styled(Radio)`
  .ant-popover-inner {
    background: transparent !important;
  }
`;

const StyledPopover = styled(Popover)`
  .ant-popover-inner {
    background-color: red !important;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  }
`;

var dimSelectTypes = {
  volume: [
    {
      name: 'ml',
      value: 'ml'
    },
    {
      name: 'L',
      value: 'l'
    }
  ],
  mass: [
    {
      name: 'g',
      value: 'g'
    },
    {
      name: 'Kg',
      value: 'kg'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: [
    {
      name: 'Unit',
      value: 'unit'
    }
  ]
};

class Cooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      main: true,
      mainInput: false,
      selectitem: undefined,
      selectitems: [],
      stockitems: {},
      _id: '',
      cooking: [],
      menuitems: [],
      showMenuItems: false,
      cookName: '',
      desc: '',
      cookIns: [],
      types: [],
      type: '',
      cookObject: {
        name: '',
        price: 0,
        cost: '0',
        stockList: [
          {
            stock: '',
            qty: 0,
            cost: '0'
          }
        ]
      },
      inputCount: 0,
      mainInputType: false,
      typeName: '',
      printto: '',
      print: [
        {
          key: 'None',
          value: 'None'
        },
        {
          key: 'Bar',
          value: 'Bar'
        },
        {
          key: 'Kitchen',
          value: 'Kitchen'
        }
      ],
      loading: false,
      alert: false,
      msg: [],
      head: '',
      activeFilter: ['true'],
      currentPage: 1
    };
  }
  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value
    });
  };
  componentDidMount() {
    this.getCooking();
  }

  getCooking = msg => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .getCooking(token)
      .then(result => {
        this.setState({
          menuitems: [],
          showMenuItems: false,
          selectitems: result.data.results.stockSelect,
          stockitems: result.data.results.stockItems,
          cooking: result.data.results.cooking,
          types: result.data.results.types,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  addCooking = () => {
    this.setState({ loading: true, submitInProgress: true });
    if (!this.state.cookName) {
      this.setState({ loading: false });
      this.props.openSnackbar('Enter Name');
      return;
    }
    const token = this.props.local.user.token;
    const state = this.state;
    const name = this.state.cookName;
    // let nameCheck = false;
    // for (let i = 0; i < state.cooking.length; i++) {
    //   if (state.cooking[i].name.toLowerCase() === name.toLowerCase()) {
    //     nameCheck = true;
    //     break;
    //   }
    // }
    // if (nameCheck) {
    //   this.onError('Duplicate Instruction Name Found');
    //   return;
    // }
    this.props
      .addCooking(state, token)
      .then(result => {
        this.setState({
          cookName: '',
          type: '',
          cookIns: [],
          inputCount: 0,
          submitInProgress: false
        });
        this.getCooking('Save Complete');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  editCooking = (delN, recordN) => {
    var del = delN;
    var record = recordN;
    this.setState({ loading: true });
    const token = this.props.local.user.token;
    var state = '';
    if (del) {
      state = record;
      state.cookName = state.name;
      if (del === 'del') {
        state.active = false;
      } else {
        state.active = true;
      }
    } else {
      state = this.state;
      var cookingList = [];
      for (let i = 0; i < state.cookIns.length; i++) {
        if (!state.cookIns[i].name) {
          this.onError('Instruction ' + (i + 1) + ', name not found');
          return;
        } else {
          if (!state.cookIns[i].cost) {
            state.cookIns[i].cost = '0';
          }
          if (!state.cookIns[i].price) {
            state.cookIns[i].price = 0;
          } else {
            state.cookIns[i].price = parseFloat(state.cookIns[i].price);
          }
          var stockList = [];
          for (let j = 0; j < state.cookIns[i].stockList.length; j++) {
            if (
              state.cookIns[i].stockList[j].stock &&
              state.cookIns[i].stockList[j].dimType
            ) {
              delete state.cookIns[i].stockList[j].dimtypes;
              if (!state.cookIns[i].stockList[j].qty) {
                state.cookIns[i].stockList[j].qty = 1;
              }
              if (!state.cookIns[i].stockList[j].cost) {
                state.cookIns[i].stockList[j].cost = '0';
              }
              stockList.push(state.cookIns[i].stockList[j]);
            }
          }
          state.cookIns[i].stockList = stockList;
          cookingList.push(state.cookIns[i]);
        }
      }
      state.cookIns = cookingList;
      state.active = true;
      state.inputCount = state.cookIns.length;
    }

    this.props
      .editCooking(state, token, del)
      .then(result => {
        this.setState({
          main: true,
          _id: '',
          cookName: '',
          type: '',
          cookIns: [],
          inputCount: 0
        });
        this.getCooking('Update Complete');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onEditBtnDown = key => {
    var cookIns = this.state.cookIns;
    var f = cookIns.splice(key, 1)[0];
    key = key + 1;
    cookIns.splice(key, 0, f);
    this.setState({
      cookIns: cookIns
    });
  };

  onEditBtnUp = key => {
    var cookIns = this.state.cookIns;
    var f = cookIns.splice(key, 1)[0];
    key = key - 1;
    cookIns.splice(key, 0, f);
    this.setState({
      cookIns: cookIns
    });
  };

  handleCloseMenu = () => {
    this.setState({
      main: true,
      _id: '',
      cookName: '',
      type: '',
      cookIns: [],
      inputCount: 0
    });
    this.getCooking();
  };

  onEditMenu = cookitem => {
    for (let i = 0; i < cookitem.cookIns.length; i++) {
      if (cookitem.cookIns[i].stock) {
        cookitem.cookIns[i].stockList = [
          {
            stock: '',
            qty: 0,
            cost: '0'
          }
        ];
        cookitem.cookIns[i].stockList[0].stock = cookitem.cookIns[i].stock;
        cookitem.cookIns[i].stockList[0].stockId = cookitem.cookIns[i].stockId;
        cookitem.cookIns[i].stockList[0].dimension =
          cookitem.cookIns[i].dimension;
        cookitem.cookIns[i].stockList[0].dimType = cookitem.cookIns[i].dimType;
        cookitem.cookIns[i].stockList[0].cost = cookitem.cookIns[i].cost;
        cookitem.cookIns[i].stockList[0].qty = cookitem.cookIns[i].qty;
        delete cookitem.cookIns[i].stock;
        delete cookitem.cookIns[i].stockId;
        delete cookitem.cookIns[i].dimension;
        delete cookitem.cookIns[i].dimType;
        delete cookitem.cookIns[i].cost;
        delete cookitem.cookIns[i].qty;
      } else {
        if (!cookitem.cookIns[i].stockList) {
          cookitem.cookIns[i].stockList = [
            {
              stock: '',
              qty: 0,
              cost: '0'
            }
          ];
          delete cookitem.cookIns[i].stock;
          delete cookitem.cookIns[i].qty;
          delete cookitem.cookIns[i].cost;
        } else if (cookitem.cookIns[i].stockList.length === 0) {
          cookitem.cookIns[i].stockList = [
            {
              stock: '',
              qty: 0,
              cost: '0'
            }
          ];
        }
      }
      for (let j = 0; j < cookitem.cookIns[i].stockList.length; j++) {
        if (cookitem.cookIns[i].stockList[j].dimension) {
          cookitem.cookIns[i].stockList[j].dimtypes =
            dimSelectTypes[cookitem.cookIns[i].stockList[j].dimension];
        }
      }
    }
    this.setState({
      _id: cookitem._id,
      cookName: cookitem.name,
      type: cookitem.type,
      cookIns: cookitem.cookIns,
      inputCount: cookitem.inputCount,
      desc: cookitem.desc,
      menuitems: [],
      showMenuItems: false,
      main: false
    });
  };

  addInput = () => {
    var cooking = this.state.cookIns;
    cooking.push({ ...this.state.cookObject });
    this.setState({
      inputCount: this.state.inputCount + 1,
      cookIns: cooking
    });
  };

  removeInput = eN => {
    var e = eN;
    var cooking = this.state.cookIns;
    if (this.state.inputCount > 1) {
      cooking.splice(e, 1);
      this.setState({
        inputCount: this.state.inputCount - 1,
        cookIns: cooking
      });
    }
  };

  addInputStock = iN => {
    var i = iN;
    var cooking = this.state.cookIns;
    cooking[i].stockList.push({
      stock: '',
      qty: 0,
      cost: '0'
    });
    this.setState({
      cookIns: cooking
    });
  };

  removeInputStock = (eN, keyN) => {
    var e = eN;
    var key = keyN;
    var cooking = this.state.cookIns;
    if (this.state.cookIns[e].stockList.length > 1) {
      cooking[e].stockList.splice(key, 1);
    } else {
      cooking[e].stockList = [
        {
          stock: '',
          qty: 0,
          cost: '0'
        }
      ];
    }
    this.setState({
      cookIns: cooking
    });
  };

  onChangeMainBtn = swN => {
    var sw = swN;
    this.setState({
      mainInput: sw,
      main: false
    });
  };
  onAddInstr = e => {
    this.setState({
      mainInput: true
    });
  };
  onAddCancelInstr = e => {
    this.setState({
      mainInput: false
    });
  };
  handleChange = event => {
    if (event.target.name === 'Type') {
      this.setState({ type: event.target.value });
    }
  };

  onChangeEvt = eN => {
    var e = eN;
    if (e.target.id === 'name') {
      this.setState({ cookName: e.currentTarget.value });
    }
    if (e.target.id === 'desc') {
      this.setState({ desc: e.currentTarget.value });
    }
    if (e.target.id === 'nameedit') {
      this.setState({ cookName: e.currentTarget.value });
    }
    if (e.target.id === 'nametype') {
      this.setState({ typeName: e.currentTarget.value });
    }
  };

  onChangeInt = (eN, iN) => {
    var e = eN;
    var i = iN;
    const newIds = this.state.cookIns.slice();
    newIds[i].name = e.currentTarget.value;
    this.setState({ cookIns: newIds });
  };

  onChangeEvtPrice = (eN, iN) => {
    var e = eN;
    var i = iN;
    const newIds = this.state.cookIns.slice();
    var value = formaterCheck(e.currentTarget.value || '0');
    if (value >= 0) {
      newIds[i].price = value;
      this.setState({ cookIns: newIds });
    }
  };

  onChangeEvtCost = (eN, iN) => {
    var e = eN;
    var i = iN;
    const newIds = this.state.cookIns.slice();
    var value = formaterCheck(e.currentTarget.value || '0');
    if (value >= 0) {
      newIds[i].cost = value;
    }
    this.setState({ cookIns: newIds });
  };

  onChangeEvtStock = (eN, eventN, iN, keyN) => {
    var e = eN;
    var event = eventN;
    var i = iN;
    var key = keyN;
    if (e) {
      const stockitems = this.state.stockitems;
      const newIds = this.state.cookIns.slice();
      var newvalue = event.key.split('|');
      newIds[i].stockList[key].stock = e;
      newIds[i].stockList[key].stockId = newvalue[0];
      newIds[i].stockList[key].dimension = newvalue[1];
      newIds[i].stockList[key].dimtypes = dimSelectTypes[newvalue[1]];
      newIds[i].stockList[key].dimType = dimSelectTypes[newvalue[1]][0].value;
      newIds[i].stockList[key].qty = 1;
      var metricAmount = newIds[i].stockList[key].qty;
      if (newIds[i].stockList[key].dimension !== 'unit') {
        metricAmount = convert(newIds[i].stockList[key].qty)
          .from(newIds[i].stockList[key].dimType)
          .to(stockitems[newIds[i].stockList[key].stockId].dimType);
      }
      var costPrice = newIds[i].stockList[key].stockId
        ? stockitems[newIds[i].stockList[key].stockId].buyPrice
        : '0';
      var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
      newIds[i].stockList[key].cost = totalCost.toFixed('2');
      var costTotal = 0;
      if (newIds[i].stockList.length > 0) {
        for (var j = 0; j < newIds[i].stockList.length; j++) {
          if (newIds[i].stockList[j].cost) {
            costTotal += parseFloat(newIds[i].stockList[j].cost || '0');
          }
        }
        newIds[i].cost = costTotal.toFixed('2');
      }
      this.setState({ cookIns: newIds });
    }
  };

  onChangeEvtQTY = (eN, iN, keyN) => {
    var e = eN;
    var i = iN;
    var key = keyN;
    const newIds = this.state.cookIns.slice();
    const stockitems = this.state.stockitems;
    if (e.currentTarget.value) {
      newIds[i].stockList[key].qty = parseFloat(e.currentTarget.value);
      var metricAmount = newIds[i].stockList[key].qty;
      if (newIds[i].stockList[key].dimension !== 'unit') {
        metricAmount = convert(newIds[i].stockList[key].qty)
          .from(newIds[i].stockList[key].dimType)
          .to(stockitems[newIds[i].stockList[key].stockId].dimType);
      }
      var costPrice = newIds[i].stockList[key].stockId
        ? stockitems[newIds[i].stockList[key].stockId].buyPrice
        : '0';
      var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
      newIds[i].stockList[key].cost = totalCost.toFixed('2');
    } else {
      newIds[i].stockList[key].cost = '';
      newIds[i].stockList[key].qty = '';
    }
    var costTotal = 0;
    if (newIds[i].stockList.length > 0) {
      for (var j = 0; j < newIds[i].stockList.length; j++) {
        if (newIds[i].stockList[j].cost) {
          costTotal += parseFloat(newIds[i].stockList[j].cost || '0');
        }
      }
      newIds[i].cost = costTotal.toFixed('2');
    }
    this.setState({ cookIns: newIds });
  };

  handleSelectStockDim = (eN, iN, keyN) => {
    var e = eN;
    var i = iN;
    var key = keyN;
    let { value, name } = e.target;
    const stockitems = this.state.stockitems;
    const newIds = this.state.cookIns.slice();
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if (name === 'dimentiontype') {
      newIds[i].stockList[key].dimType = value;
      newIds[i].stockList[key].qty = 1;
      var metricAmount = newIds[i].stockList[key].qty;
      if (newIds[i].stockList[key].dimension !== 'unit') {
        metricAmount = convert(newIds[i].stockList[key].qty)
          .from(newIds[i].stockList[key].dimType)
          .to(stockitems[newIds[i].stockList[key].stockId].dimType);
      }
      var costPrice = newIds[i].stockList[key].stockId
        ? stockitems[newIds[i].stockList[key].stockId].buyPrice
        : '0';
      var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
      newIds[i].stockList[key].cost = totalCost.toFixed('2');
    } else if (value && name !== 'dimentiontype') {
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        if (name === 'cost') {
          newIds[i].stockList[key].cost = value;
        }
      }
    } else {
      newIds[i].stockList[key].cost = '';
    }
    var costTotal = 0;
    if (newIds[i].stockList.length > 0) {
      for (var j = 0; j < newIds[i].stockList.length; j++) {
        if (newIds[i].stockList[j].cost) {
          costTotal += parseFloat(newIds[i].stockList[j].cost || '0');
        }
      }
      newIds[i].cost = costTotal.toFixed('2');
    }
    this.setState({ cookIns: newIds });
  };
  deleteStockItem = (iN, keyN) => {
    let i = iN;
    let key = keyN;
    var cooking = this.state.cookIns;
    if (cooking[i].stockList.length > 1) {
      cooking[i].stockList.splice(key, 1);
    }
    var costTotal = 0;
    if (cooking[i].stockList.length > 0) {
      for (var j = 0; j < cooking[i].stockList.length; j++) {
        if (cooking[i].stockList[j].cost) {
          costTotal += parseFloat(cooking[i].stockList[j].cost || '0');
        }
      }
      cooking[i].cost = costTotal.toFixed('2');
    } else {
      cooking[i].cost = '0';
    }
    this.setState({ cookIns: cooking });
  };
  switchToMenuitems = record => {
    this.setState({
      menuitems: record.menuitems,
      showMenuItems: true,
      main: false
    });
  };
  switchBack = record => {
    this.setState({
      menuitems: [],
      showMenuItems: false,
      main: true
    });
  };
  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Product Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  changePage = page => {
    this.setState({ currentPage: page });
  };
  onCloseCard = e => {
    this.props.closeCookingHelpCard();
  };
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        description={false}
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />
      <button
        style={{
          marginTop: '-0.7em',
          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',

          width: '10.5em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',

          borderRadius: '3em',
          zIndex: 5,
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => this.onAddInstr()}
        color="primary"
      >
        {'Add Instruction'}
      </button>
    </div>
  );
  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker,
      onEditMenu: this.onEditMenu,
      onEditBtnUp: this.onEditBtnUp,
      onEditBtnDown: this.onEditBtnDown,
      editCooking: this.editCooking,
      onChangeEvt: this.onChangeEvt,
      handleCloseMenu: this.handleCloseMenu,
      onChangeInt: this.onChangeInt,
      onChangeEvtPrice: this.onChangeEvtPrice,
      onChangeEvtCost: this.onChangeEvtCost,
      onChangeEvtStock: this.onChangeEvtStock,
      onChangeEvtQTY: this.onChangeEvtQTY,
      handleSelectStockDim: this.handleSelectStockDim,
      addInput: this.addInput,
      removeInput: this.removeInput,
      addInputStock: this.addInputStock,
      removeInputStock: this.removeInputStock,
      deleteStockItem: this.deleteStockItem
    };

    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div style={{ width: '8em' }}>
        <Radio.Group
          id="RadioSelectAdmin"
          buttonStyle="solid"
          defaultValue={2}
          style={{ backgroundColor: 'transparent' }}
        >
          <StyledRadio.Button
            value={1}
            onClick={() => {
              this.handleFormatList(false);
            }}
          >
            All
          </StyledRadio.Button>
          <StyledRadio.Button
            value={2}
            onClick={() => {
              this.handleFormatList(['true']);
            }}
          >
            Active
          </StyledRadio.Button>
          <StyledRadio.Button
            value={3}
            onClick={() => {
              this.handleFormatList(['false']);
            }}
          >
            Inactive
          </StyledRadio.Button>
        </Radio.Group>
      </div>
    );
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />

        {this.state.main ? (
          <div>
            {' '}
            <Button
              style={{
                position: 'fixed',
                right: '-0.6em',
                bottom: '5em',
                background: 'rgb(0, 204, 112)',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em 0em 0em 5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.addCooking()}
              color="primary"
            >
              <Icon
                style={{
                  position: 'absolute',
                  paddingLeft: 3
                }}
                type="check"
              />
            </Button>
            <Row>
              {this.props.local.cookingHelpCard ? (
                <StyledCard
                  //title="Default size card"
                  //extra={<a href="#">More</a>}
                  style={{
                    padding: '30px',
                    marginTop: '0em',
                    marginBottom: '1em'
                  }}
                >
                  <Row>
                    <Col span={15}>
                      <h2>Adding Product Modifiers</h2>
                      <p style={{ paddingRight: '3em' }}>
                        Provide information about which items are used to make
                        each product to accurately keep track of your inventory
                        usage.
                      </p>
                    </Col>
                    <Col span={7} style={{ textAlign: 'right' }}>
                      <br />
                      <h3>Need Help ?</h3>
                      <p
                        onClick={e => {
                          window.Tawk_API.toggleVisibility();
                          window.Tawk_API.toggle();
                        }}
                        style={{
                          color: 'rgba(0, 204, 112, 0.898)',
                          fontWeight: 500,
                          cursor: 'pointer'
                        }}
                      >
                        Ask a VPOS Product Specialist.
                      </p>
                    </Col>
                    <Col span={2} />
                    <Icon
                      onClick={() => this.onCloseCard()}
                      style={{ position: 'absolute', right: 0, top: 0 }}
                      type="close"
                    />
                  </Row>
                  <Row style={{ marginTop: '2em' }}>
                    <Col
                      span={4}
                      style={{
                        width: '12.5em',
                        display: this.state.mainInput ? 'none' : 'block'
                      }}
                    >
                      <button
                        style={{
                          marginTop: '-0.5em',
                          marginBottom: '0.4em',
                          backgroundColor: '#00CC70',
                          border: '0px solid grey',
                          color: 'white',
                          width: '10.5em',
                          height: '3em',
                          fontSize: '15px',
                          fontWeight: 700,
                          fontFamily: 'Mulish , sans-serif',
                          borderRadius: '3em',
                          zIndex: 1
                        }}
                        onClick={() => this.onAddInstr()}
                        //onClick={() => this.addCooking()}
                        color="primary"
                      >
                        {'Add Instruction'}
                      </button>
                    </Col>
                    {this.state.mainInput ? (
                      <Row style={{ marginTop: '-1em' }}>
                        <Col span={8}>
                          <CustomInput
                            labelText="Name"
                            id="name"
                            autoFocus={false}
                            error={
                              !this.state.cookName &&
                              this.state.submitInProgress
                            }
                            errorText={'Name required'}
                            formControlProps={{
                              style: { marginTop: '-1em' },
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: e => this.onChangeEvt(e),
                              value: this.state.cookName,
                              onClick: () => this.onHelpMarker('menuitemsqty')
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <button
                            onClick={() => this.onAddCancelInstr()}
                            className={this.props.classes.buttonAdd}
                            style={{
                              fontWeight: 700,
                              fontFamily: 'Mulish , sans-serif',
                              fontSize: '13px',
                              textTransform: 'initial',
                              boxShadow: 'none',
                              width: '7em',
                              margin: 'auto',
                              marginLeft: '1em',
                              color: 'black',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '0px solid rgba(229, 233, 242, 0.9)',
                              borderRadius: '3em',
                              height: '2.7em'
                            }}
                          >
                            {'Cancel'}
                          </button>
                          <button
                            onClick={() => this.addCooking()}
                            style={{
                              fontWeight: 700,
                              fontFamily: 'Mulish , sans-serif',
                              fontSize: '13px',
                              textTransform: 'initial',
                              width: '7em',
                              margin: 'auto',
                              marginLeft: '0.8em',
                              backgroundColor: '#00CC70',
                              border: '0px solid #00CC70',
                              color: 'white',
                              borderRadius: '3em',
                              height: '2.7em'
                            }}
                            className={this.props.classes.buttonAdd}
                          >
                            {'Save'}
                          </button>
                        </Col>{' '}
                      </Row>
                    ) : null}
                  </Row>
                </StyledCard>
              ) : (
                <div>
                  <Row style={{ marginTop: '0.2em' }}>
                    <Col
                      span={4}
                      style={{
                        width: '12.5em',
                        display: this.state.mainInput ? 'none' : 'block'
                      }}
                    >
                      <button
                        style={{
                          marginTop: '-0.5em',
                          marginBottom: '0.4em',
                          backgroundColor: '#00CC70',
                          border: '0px solid grey',
                          color: 'white',
                          width: '10.5em',
                          height: '3em',
                          fontSize: '15px',
                          fontWeight: 700,
                          fontFamily: 'Mulish , sans-serif',
                          borderRadius: '3em',
                          zIndex: 1
                        }}
                        onClick={() => this.onAddInstr()}
                        //onClick={() => this.addCooking()}
                        color="primary"
                      >
                        {'Add Instruction'}
                      </button>
                    </Col>
                    {this.state.mainInput ? (
                      <Row style={{}}>
                        <Col span={8}>
                          <CustomInput
                            labelText="Name"
                            id="name"
                            autoFocus={false}
                            error={
                              !this.state.cookName &&
                              this.state.submitInProgress
                            }
                            errorText={'Name required'}
                            formControlProps={{
                              style: { marginTop: '-1em' },
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: e => this.onChangeEvt(e),
                              value: this.state.cookName,
                              onClick: () => this.onHelpMarker('menuitemsqty')
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <button
                            onClick={() => this.onAddCancelInstr()}
                            className={this.props.classes.buttonAdd}
                            style={{
                              fontWeight: 700,
                              fontFamily: 'Mulish , sans-serif',
                              fontSize: '13px',
                              textTransform: 'initial',
                              boxShadow: 'none',
                              width: '7em',
                              margin: 'auto',
                              marginLeft: '1em',
                              color: 'black',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '0px solid rgba(229, 233, 242, 0.9)',
                              borderRadius: '3em',
                              height: '2.7em'
                            }}
                          >
                            {'Cancel'}
                          </button>
                          <button
                            onClick={() => this.addCooking()}
                            style={{
                              fontWeight: 700,
                              fontFamily: 'Mulish , sans-serif',
                              fontSize: '13px',
                              textTransform: 'initial',
                              width: '7em',
                              margin: 'auto',
                              marginLeft: '0.8em',
                              backgroundColor: '#00CC70',
                              border: '0px solid #00CC70',
                              color: 'white',
                              borderRadius: '3em',
                              height: '2.7em'
                            }}
                            className={this.props.classes.buttonAdd}
                          >
                            {'Save'}
                          </button>
                        </Col>{' '}
                      </Row>
                    ) : null}
                  </Row>
                </div>
              )}
            </Row>
            <Row
              style={{
                marginTop: '-1em'
              }}
            >
              <div
                style={{
                  float: 'right',
                  height: '10px'
                }}
              >
                <StyledPopover
                  id="RadioSelectAdmin"
                  placement="right"
                  content={EyeContent}
                  title=""
                  trigger="click"
                  style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                >
                  <Button
                    style={{
                      position: 'fixed',
                      right: '-0.6em',
                      bottom: '9em',
                      background: 'rgba(229, 233, 242, 1)',
                      border: '1px solid transparent',
                      color: 'rgba(0, 0, 0, 0.54)',
                      padding: '0em',
                      width: '3.5em',
                      height: '3.5em',
                      boxShadow: 'none',
                      borderRadius: '5em 0em 0em 5em',
                      fontSize: '1em',
                      zIndex: 1000
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      type="setting"
                    />
                  </Button>
                </StyledPopover>
              </div>
            </Row>
            <Row style={{ marginTop: '1em' }}>
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={this.state.cooking}
                  onChange={this.handleSort}
                  pagination={{
                    onChange: this.changePage,
                    current: this.state.currentPage,
                    hideOnSinglePage: true,
                    pageSize: 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`
                  }}
                  onRow={(record, rowIndex) => {
                    if (!record.active) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditMenu(record);
                        },
                        style: {
                          background: 'rgba(255, 4, 4, 0.063)',
                          color: 'red'
                        }
                      };
                    } else if (rowIndex % 2 == 0) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditMenu(record);
                        },
                        style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.onEditMenu(record);
                        }
                      };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    title="Instructions"
                    dataIndex="cookIns.length"
                    key="cookIns.length"
                  />
                  <Column
                    {...this.getActiveSearchProps('active')}
                    filterDropdownVisible={false}
                    title="Active"
                    filtered={true}
                    filteredValue={this.state.activeFilter}
                    dataIndex="active"
                    render={(text, record) => (
                      <div>
                        {record.menuitems.length ? (
                          <a
                            style={{
                              border: '1px solid #00CC70',
                              borderRadius: '3em',
                              padding: '6px'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              this.switchToMenuitems(record);
                            }}
                          >
                            {record.menuitems.length + ' Products'}
                          </a>
                        ) : (
                          <span
                            style={{
                              border: '1px solid red',
                              color: 'red',
                              //color:"cadetblue",
                              borderRadius: '3em',
                              padding: '6px'
                            }}
                          >
                            0 Products
                          </span>
                        )}
                      </div>
                    )}
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            oState.onEditMenu(record);
                          }}
                          style={{
                            width: '3em',
                            padding: '0.35em',
                            borderRadius: '3em',
                            backgroundColor: 'rgb(0, 204, 112)',
                            border: '0px solid rgb(0, 204, 112)'
                          }}
                        >
                          {
                            <p
                              style={{
                                fontSize: 13,
                                color: 'white',
                                fontWeight: 700,
                                fontFamily: 'Mulish , sans-serif',
                                margin: 'auto'
                              }}
                            >
                              {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                              Edit
                            </p>
                          }
                        </IconButton>
                        {record.active ? (
                          <IconButton
                            style={{
                              marginRight: '0em',
                              marginLeft: '0.4em',
                              borderRadius: '4em',
                              backgroundColor: 'rgba(229, 233, 242, 0.9)',
                              border: '1px solid rgba(229, 233, 242, 1)'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              oState.editCooking('del', record);
                            }}
                          >
                            {
                              <Icon
                                type="delete"
                                style={{
                                  fontSize: 15
                                }}
                              />
                            }
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              oState.editCooking('res', record);
                            }}
                            style={{
                              border: '1px solid green',
                              marginLeft: '1em',
                              padding: '16px'
                            }}
                          >
                            {
                              <Icon
                                type="rollback"
                                style={{
                                  position: 'absolute',
                                  fontSize: '17px',
                                  color: 'green'
                                }}
                              />
                            }
                          </IconButton>
                        )}
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : (
          <div>
            {this.state.showMenuItems ? (
              <MenuItemsTable
                menuitems={this.state.menuitems}
                switchBack={this.switchBack}
              />
            ) : (
              <CookingView {...oState} />
            )}
          </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems
});

const mapDispatchToProps = {
  addCooking,
  editCooking,
  getCooking,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  closeCookingHelpCard
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Cooking));
