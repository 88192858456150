// ________GROUPS_________
export function resetGroups() {
  return function(dispatch) {
    dispatch({
      type: 'RESET_GROUPS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenAddGroupUser() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_ADD_GOUP_USER'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'addgroupuser'
    });
  };
}

export function handleCloseAddGroupUser() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_ADD_GOUP_USER'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenTableGroup() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_TABLE_GROUP'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'listuser'
    });
  };
}

export function handleCloseTableGroup() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_TABLE_GROUP'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}
// ________USERS_________
export function resetUsers() {
  return function(dispatch) {
    dispatch({
      type: 'RESET_USERS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenAddUser() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_ADD_USER'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'adduser'
    });
  };
}

export function handleCloseAddUser() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_ADD_USER'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenTableUser() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_TABLE_USER'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'listuser'
    });
  };
}

export function handleCloseTableUser() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_TABLE_USER'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

// ________STOCKITEMS_________
export function resetStockItems() {
  return function(dispatch) {
    dispatch({
      type: 'RESET_STOCK_ITEMS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenAddStockItems() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_ADD_STOCK_ITEMS'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'addstockitem'
    });
  };
}

export function handleCloseAddStockItems() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_ADD_STOCK_ITEMS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenTableStockItems() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_TABLE_STOCK_ITEMS'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'liststockitem'
    });
  };
}

export function handleCloseTableStockItems() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_TABLE_STOCK_ITEMS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

// ________MENUITEMS_________
export function resetMenuItems() {
  return function(dispatch) {
    dispatch({
      type: 'RESET_MENU_ITEMS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenAddMenuItems() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_ADD_MENU_ITEMS'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'addmenuitem'
    });
  };
}

export function handleCloseAddMenuItems() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_ADD_MENU_ITEMS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenAddMenuStockItems() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_ADD_MENU_STOCK_ITEMS'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'addstockitem'
    });
  };
}

export function handleCloseAddMenuStockItems() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_ADD_MENU_STOCK_ITEMS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}

export function handleOpenTableMenuItems() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_TABLE_MENU_ITEMS'
    });
    dispatch({
      type: 'SET_HELP_MARKER',
      payload: 'listmenuitem'
    });
  };
}

export function handleCloseTableMenuItems() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_TABLE_MENU_ITEMS'
    });
    dispatch({
      type: 'RESET_HELP_MARKER'
    });
  };
}
