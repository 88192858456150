/* eslint-disable no-loop-func */
import withStyles from '@material-ui/core/styles/withStyles';
import { Badge, Layout, Modal, notification } from 'antd';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { GiPayMoney, GiTrashCan } from 'react-icons/gi';
import { connect } from 'react-redux';
import 'src/components/Layout/Dashboard/extra.css';
import {
  endSession,
  resetUser,
  updateCustomer
} from 'src/redux/actions/backend';
import {
  addToCart,
  createBill,
  createCart,
  setSelectedCompany,
  setSelectedDelivery,
  updateBill,
  updateCart
} from 'src/redux/actions/customer';
import {
  findNotifications,
  removeNotification,
  sendNotification
} from 'src/redux/actions/notifications';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import 'src/views/Customer/customer-styles.css';
import Cart from './Cart.jsx';
import Checkout from './Checkout.jsx';
import CompanyList from './CompanyList.jsx';
import CompanyMenu from './CompanyMenu.jsx';
import CustomerFooter from './Footer.jsx';
import CustomerHeader from './Header.jsx';
import ItemInstructions from './ItemInstructions.jsx';
import MenuItems from './MenuItems.jsx';
import MyOrders from './MyOrders.jsx';
import OrderDetails from './OrderDetails.jsx';
import Profile from './Profile.jsx';
import SelectDelivery from './SelectDelivery.jsx';
import Status from './Status.jsx';
import Track from './Track.jsx';
const styles = theme => ({
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  }
});
const { confirm, error } = Modal;
let setAlert = undefined;
let setDash = undefined;
let setHeader = undefined;
var props = {};
var checkOnlineInterval;

const closeDialog = () => {
  setAlert(prevState => ({ ...prevState, alert: false }));
};

const componentWillMount = props => {
  const token = props.local.user.token;
  props.findNotifications(token).then(() => {
    setHeader(prevState => ({
      ...prevState,
      notifications: props.notifications
    }));
  });
  var element = document.getElementById('root');
  element.classList.add('dash-root');
  componentDidMount(props);
};

const componentDidMount = props => {
  document.addEventListener('offline', setOffline, false);
  document.addEventListener('online', setOnline, false);
  const token = props.local.user.token;
  window.pubnub.addListener({
    message: msg => {
      props.findNotifications(token).then(() => {
        setHeader(prevState => ({
          ...prevState,
          notifications: props.notifications
        }));
      });
    }
  });
  if (window.cordova) {
    window.FirebasePlugin.onMessageReceived(
      notif => {
        notif.message = JSON.parse(notif.message);
        if (notif.tap === 'background') {
          const token = props.local.user.token;
          props.removeNotification(notif.notId, token).then(() => {
            props.findNotifications(token).then(() => {
              setHeader(prevState => ({
                ...prevState,
                notifications: props.notifications
              }));
            });
          });
        }
      },
      error => {
        console.error(error);
      }
    );
  }
  window.addEventListener('resize', handleResize);
  document.addEventListener('backbutton', handleBackButton, false);
};
const handleBackButton = () => {
  if (selectDeliveryState.mapOpen) {
    setSelectDeliveryState(prevState => ({
      ...prevState,
      value: undefined,
      mapOpen: false
    }));
  } else if (previousView) {
    navToView(previousView);
  } else {
    signOut();
  }
};
// const handleNotificationClick = e => {
//   const token = props.local.user.token;
//   let item = JSON.parse(e.currentTarget.getAttribute('data'));
//   props.removeNotification(item._id, token).then(() => {
//     props.findNotifications(token).then(() => {
//       setHeader(prevState => ({
//         ...prevState,
//         notifications: props.notifications
//       }));
//     });
//   });
// };
// const clearNotifications = e => {
//   const token = props.local.user.token;
//   props.removeNotification(undefined, token).then(() => {
//     props.findNotifications(token).then(() => {
//       setHeader(prevState => ({
//         ...prevState,
//         notifications: props.notifications
//       }));
//     });
//   });
// };

const setOffline = () => {
  window.offline = true;
  setDash(prevState => ({ ...prevState, offline: true }));
};

const setOnline = () => {
  window.offline = false;
  setDash(prevState => ({ ...prevState, offline: false }));
};

const componentWillUnmount = () => {
  clearInterval(checkOnlineInterval);
  window.removeEventListener('resize', handleResize);
  document.removeEventListener('offline', setOffline, false);
  document.removeEventListener('online', setOnline, false);
};

const handleResize = e => {
  setDash(prevState => ({ ...prevState, windowHeight: window.innerHeight }));
};

let dashState = {
  windowHeight: window.innerHeight,
  delivery: true,
  loading: false,
  offline: false
};

const alertDialogProps = {
  state: {
    alert: undefined,
    head: undefined,
    msg: undefined
  },
  closeDialog: closeDialog
};
const onChangeDelivery = (e, props) => {
  checkLocationGranted()
    .then(() => {
      if (e.target.value === 1) {
        // eslint-disable-next-line
        const service = new google.maps.Geocoder();
        setLocationLoading(prevState => ({
          ...prevState,
          all: true,
          my: true
        }));
        navigator.geolocation.getCurrentPosition(
          position => {
            service.geocode(
              {
                location: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                }
              },
              result => {
                setSelectDeliveryState(prevState => ({
                  ...prevState,
                  value: e.target.value,
                  address: '',
                  myAddress: result[0].formatted_address.replace(
                    ', South Africa',
                    ''
                  ),
                  newAddress: '',
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                }));
                reduxProps.setSelectedDelivery({
                  address: result[0].formatted_address,
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                });
                setLocationLoading(prevState => ({
                  ...prevState,
                  all: false,
                  my: false
                }));
              }
            );
          },
          error => {
            console.log(error);
          }
        );
      } else if (e.target.value === 2) {
        setSelectDeliveryState(prevState => ({
          ...prevState,
          value: e.target.value,
          mapOpen: true
        }));
      } else {
        setLocationLoading(prevState => ({
          ...prevState,
          all: true,
          collect: true
        }));
        navigator.geolocation.getCurrentPosition(
          position => {
            setSelectDeliveryState(prevState => ({
              ...prevState,
              value: e.target.value,
              myAddress: '',
              address: 'Collect',
              newAddress: '',
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }));
            reduxProps.setSelectedDelivery({
              address: 'Collect',
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
            setLocationLoading(prevState => ({
              ...prevState,
              all: false,
              collect: false
            }));
          },
          error => {
            console.log(error);
          }
        );
      }
    })
    .catch(() => {
      setSelectDeliveryState(prevState => ({
        ...prevState,
        value: undefined
      }));
      notification.error({
        message: 'Error',
        description: 'You need to enable location to be able to continue'
      });
    });
};

const checkLocationGranted = () => {
  return new Promise(async (resolve, reject) => {
    if (!window.cordova) {
      return resolve();
    }
    function onError(error) {
      if (error) {
        notification.error({
          message: 'Error',
          description: 'You need to enable location to be able to continue'
        });
      }
    }

    function handleLocationAuthorizationStatus(status) {
      switch (status) {
        case window.cordova.plugins.diagnostic.permissionStatus.GRANTED:
          _makeRequest();
          break;
        case window.cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
          requestLocationAuthorization();
          break;
        case window.cordova.plugins.diagnostic.permissionStatus.DENIED:
          requestLocationAuthorization();
          break;
        case window.cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE:
          requestLocationAuthorization();
          break;
        case window.cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
          return reject();
          break;
        case window.cordova.plugins.diagnostic.permissionStatus
          .GRANTED_WHEN_IN_USE:
          _makeRequest();
          break;
      }
    }

    function requestLocationAuthorization() {
      window.cordova.plugins.diagnostic.requestLocationAuthorization(
        handleLocationAuthorizationStatus,
        onError
      );
    }

    function requestLocationAccuracy() {
      window.cordova.plugins.diagnostic.getLocationAuthorizationStatus(
        handleLocationAuthorizationStatus,
        onError
      );
    }

    function _makeRequest() {
      window.cordova.plugins.locationAccuracy.canRequest(function(canRequest) {
        if (canRequest) {
          window.cordova.plugins.locationAccuracy.request(
            function(success) {
              return resolve();
            },
            function(error) {
              if (error) {
                if (
                  error.code !==
                  window.cordova.plugins.locationAccuracy.ERROR_USER_DISAGREED
                ) {
                  if (
                    window.confirm(
                      "Failed to automatically set Location Mode to 'High Accuracy'. Would you like to switch to the Location Settings page and do this manually?"
                    )
                  ) {
                    window.cordova.plugins.diagnostic.switchToLocationSettings();
                  } else {
                    requestLocationAccuracy();
                  }
                } else {
                  requestLocationAccuracy();
                }
              } else {
                return resolve();
              }
            },
            window.cordova.plugins.locationAccuracy
              .REQUEST_PRIORITY_HIGH_ACCURACY // iOS will ignore this
          );
        } else {
          requestLocationAccuracy();
        }
      });
    }
    requestLocationAccuracy();
  }).catch(async err => {
    throw err.message;
  });
};

const closeMapDialog = () => {
  setSelectDeliveryState(prevState => ({
    ...prevState,
    mapOpen: false
  }));
};

let selectDeliveryState = {
  value: undefined,
  mapOpen: false
};
let setSelectDeliveryState = undefined;
const setDelivery = locationState => {
  closeMapDialog();
  setSelectDeliveryState(prevState => ({
    ...prevState,
    newAddress: locationState.address.replace(', South Africa', ''),
    address: '',
    myAddress: '',
    lat: locationState.lat,
    lng: locationState.lng
  }));
};
let currentView = 'delivery';
let setCurrentViewState;
let previousView = 'companyList';
let setPreviousView;
const apiCall = 'vpos/api/customer/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const confirmDeliveryAddress = () => {
  let address;
  if (selectDeliveryState.address !== '') {
    address = selectDeliveryState.address;
    reduxProps.setSelectedDelivery(selectDeliveryState);
  } else if (selectDeliveryState.myAddress !== '') {
    address = selectDeliveryState.myAddress;
    reduxProps.setSelectedDelivery({
      address: selectDeliveryState.myAddress,
      lat: selectDeliveryState.lat,
      lng: selectDeliveryState.lng
    });
  } else if (selectDeliveryState.newAddress !== '') {
    address = selectDeliveryState.newAddress;
    reduxProps.setSelectedDelivery({
      address: selectDeliveryState.newAddress,
      lat: selectDeliveryState.lat,
      lng: selectDeliveryState.lng
    });
  }
  $.ajax({
    type: 'GET',
    url:
      server +
      apiCall +
      'getcompanylist?lat=' +
      selectDeliveryState.lat +
      '&lng=' +
      selectDeliveryState.lng +
      '&search=' +
      '&type=' +
      address,
    dataType: 'json',
    crossDomain: true,
    beforeSend: function(xhr) {
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + reduxProps.local.user.token
      );
    },
    success: async data => {
      if (data.results.length === 0) {
        error({
          title: 'No Coverage',
          content: 'We are not covering your area yet',
          centered: true,
          onOk() {}
        });
      } else {
        setCurrentView('companyList');
      }
    },
    error: async (xhr, ajaxOptions, thrownError) => {
      if (xhr.responseText) {
        notification.error({
          message: 'Error',
          description: xhr.responseText
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error retrieving list'
        });
      }
    }
  });
};
const backToLocation = () => {
  if (reduxProps.cart.items && reduxProps.cart.items.length > 0) {
    confirm({
      title: 'Warning',
      content:
        'Selecting a new location will remove all items from your cart. Are you sure?',
      centered: true,
      onOk() {
        reduxProps.createCart();
        setCurrentView('delivery');
      },
      onCancel() {}
    });
  } else {
    setCurrentView('delivery');
  }
};

const clearBill = (setFabActive, setFabActiveAnimate, props) => {
  confirm({
    title: 'Warning',
    content: 'Are you sure you want to empty your cart?',
    centered: true,
    onOk() {
      setFabActiveAnimate(false);
      setTimeout(() => {
        setFabActive(false);
        reduxProps.createCart(
          props.selectedCompany.orgid,
          props.local.user._id,
          props.selectedCompany.name,
          props.selectedCompany.average,
          true
        );
      }, 300);
    },
    onCancel() {}
  });
};

const signOut = () => {
  localStorage.clear();
  window.location.reload();
};
const selectCompany = company => {
  reduxProps.setSelectedCompany(company);
  setCurrentView('companyMenu');
};
let selectedMenu;
let setSelectedMenu;
const selectMenu = menu => {
  setSelectedMenu(menu);
  setCurrentView('menuItems');
};
let selectedItem;
let setSelectedItem;
const selectItem = menu => {
  setSelectedItem(menu);
  setCurrentView('itemInstructions');
};
let menu;
let setMenu;
const navToView = (view, search, bill) => {
  if (bill) {
    reduxProps.updateBill(bill);
  }
  if (view === 'signOut') {
    signOut();
  } else if (
    view === 'companyList' &&
    reduxProps.cart.items &&
    reduxProps.cart.items.length > 0
  ) {
    confirm({
      okButtonProps: {
        style: {
          backgroundColor: '#00CC70',
          width: '7em',
          borderRadius: '3em',
          marginLeft: '0.5em'
        }
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'lightgrey',
          width: '7em',
          borderRadius: '3em'
        }
      },
      title: 'Warning',
      content:
        'Selecting a new restaurant will remove all items from your cart. Are you sure?',
      centered: true,
      onOk() {
        reduxProps.createCart();
        setCurrentView(view);
        if (search) {
          setSearch(true);
        } else {
          setSearch(false);
        }
      },
      onCancel() {}
    });
  } else {
    setCurrentView(view);
    if (search) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  }
};
let reduxProps;
const addItemToCart = (item, itemTotal) => {
  reduxProps.addToCart(item, itemTotal);
  setCurrentView('companyMenu');
};
const handleUpdateCart = item => {
  reduxProps.updateCart(item);
  setDash(prevState => ({
    ...prevState,
    loading: !prevState.loading
  }));
};
let searchValue = {
  search: false,
  value: undefined
};
let search = false;
let setSearch;
let setSearchValue;
const searchPlace = value => {
  setSearchValue(value);
};
const handleCheckout = deliveryCost => {
  reduxProps.createBill(deliveryCost);
  setCurrentView('checkout');
};
const setCurrentView = view => {
  setPreviousView(currentView);
  setCurrentViewState(view);
};
const setBill = bill => {
  reduxProps.updateBill(bill);
};
const handleUpdateCustomer = (user, msg) => {
  const token = user.token;
  reduxProps.updateCustomer(user, token).then(
    suc => {
      notification.success({
        message: 'Success',
        description: msg ? msg : 'Updated data'
      });
    },
    err => {
      notification.error({
        message: 'Error',
        description: 'Error updating data'
      });
    }
  );
};
let locationLoading = {
  all: false,
  my: false,
  collect: false
};
let setLocationLoading;
const Customer = props => {
  [currentView, setCurrentViewState] = useState(currentView);
  [previousView, setPreviousView] = useState(previousView);
  [selectDeliveryState, setSelectDeliveryState] = useState(selectDeliveryState);
  [selectedItem, setSelectedItem] = useState(selectedItem);
  [selectedMenu, setSelectedMenu] = useState(selectedMenu);
  [dashState, setDash] = useState(dashState);
  [alertDialogProps.state, setAlert] = useState(alertDialogProps.state);
  [menu, setMenu] = useState(menu);
  [searchValue, setSearchValue] = useState(searchValue);
  [search, setSearch] = useState(search);
  [locationLoading, setLocationLoading] = useState(locationLoading);
  const [fabActive, setFabActive] = useState(false);
  const [fabActiveAnimate, setFabActiveAnimate] = useState(false);
  reduxProps = props;
  useEffect(() => {
    componentWillMount(props);
    let search = new URLSearchParams(window.location.search);
    if (search.has('status')) {
      setCurrentView('status');
    } else if (props.cart.items && props.cart.items.length > 0) {
      setCurrentView('companyMenu');
    }
    return () => componentWillUnmount(props);
  }, []);
  return (
    <div>
      <Layout>
        {fabActive ? (
          <div
            onClick={e => {
              e.stopPropagation();
              setFabActiveAnimate(false);
              setTimeout(() => {
                setFabActive(false);
              }, 300);
            }}
            style={{
              width: '100vw',
              height: '100vh',
              position: 'fixed',
              left: 0,
              zIndex: 10,
              background: 'rgba(0, 0, 0, 0.81)'
            }}
          >
            <div
              onClick={e => {
                e.stopPropagation();
                setFabActiveAnimate(false);
                setTimeout(() => {
                  setFabActive(false);
                }, 300);
              }}
              style={{
                margin: 'auto',
                background: '#00CC70',
                borderRadius: '50%',
                width: 54,
                height: 54,
                top: 'calc(100vh - 79px)',
                position: 'relative',
                display: 'flex',
                zIndex: 99
              }}
            >
              {props.cart.items && props.cart.items.length > 0 ? (
                <div style={{ position: 'absolute', right: -5 }}>
                  <Badge
                    style={{
                      background: 'red',
                      borderRadius: '50%'
                    }}
                    count={props.cart.items.length}
                  />
                </div>
              ) : null}
              <AiOutlineClose
                style={{
                  margin: 'auto',
                  color: 'white',
                  fontSize: 25
                }}
              />
            </div>
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100vw',
                top: 'calc(100vh - 40px)',
                left: 0,
                zIndex: 11
              }}
            >
              <div
                onClick={e => {
                  e.stopPropagation();
                  setFabActiveAnimate(false);
                  setTimeout(() => {
                    setFabActive(false);
                    navToView('cart');
                  }, 300);
                }}
                style={{
                  margin: 'auto',
                  marginRight: 0,
                  background: '#00CC70',
                  borderRadius: '50%',
                  width: 54,
                  height: 54,
                  opacity: fabActiveAnimate ? 1 : 0,
                  bottom: fabActiveAnimate ? 80 : 39,
                  left: fabActiveAnimate ? 0 : 70,
                  transition: '0.3s all',
                  position: 'relative',
                  display: 'flex'
                }}
              >
                <GiPayMoney
                  style={{
                    margin: 'auto',
                    color: 'white',
                    fontSize: 25
                  }}
                />
                <span
                  style={{
                    color: 'white',
                    position: 'absolute',
                    top: 55,
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  Pay
                </span>
              </div>
              <div
                onClick={e => {
                  e.stopPropagation();
                  clearBill(setFabActive, setFabActiveAnimate, props);
                }}
                style={{
                  margin: 'auto',
                  background: '#00CC70',
                  borderRadius: '50%',
                  width: 54,
                  height: 54,
                  opacity: fabActiveAnimate ? 1 : 0,
                  bottom: fabActiveAnimate ? 80 : 39,
                  right: fabActiveAnimate ? 0 : 70,
                  transition: '0.3s all',
                  position: 'relative',
                  display: 'flex'
                }}
              >
                <GiTrashCan
                  style={{
                    margin: 'auto',
                    marginBottom: '0.7em',
                    color: 'white',
                    fontSize: 25
                  }}
                />
                <span
                  style={{
                    color: 'white',
                    position: 'absolute',
                    top: 55,
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  Clear
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {currentView !== 'delivery' ? (
          <CustomerHeader
            selectedCompany={props.selectedCompany}
            navToView={navToView}
            currentView={currentView}
            previousView={previousView}
            backToLocation={backToLocation}
            address={props.selectedDelivery.address}
            signOut={signOut}
            searchPlace={searchPlace}
            search={search}
            setSearch={setSearch}
          />
        ) : null}

        {currentView === 'delivery' ? (
          <SelectDelivery
            {...selectDeliveryState}
            selectedDelivery={props.selectedDelivery}
            closeMapDialog={closeMapDialog}
            deliveryLocations={props.local.user.deliveryLocations}
            previousLocation={
              props.local.user.deliveryLocations.length > 0
                ? props.local.user.deliveryLocations[0].address
                : 'No previous locations'
            }
            setSelectDeliveryState={setSelectDeliveryState}
            loading={dashState.loading}
            snackbar={props.snackbar}
            onChangeDelivery={onChangeDelivery}
            checkLocationGranted={checkLocationGranted}
            setDelivery={setDelivery}
            confirmDeliveryAddress={confirmDeliveryAddress}
            signOut={signOut}
            locationLoading={locationLoading}
          />
        ) : currentView === 'companyList' ? (
          <CompanyList
            handleUpdateCustomer={handleUpdateCustomer}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selectedDelivery={props.selectedDelivery}
            selectCompany={selectCompany}
            user={props.local.user}
          />
        ) : currentView === 'companyMenu' ? (
          <CompanyMenu
            createCart={props.createCart}
            selectedCompany={props.selectedCompany}
            selectMenu={selectMenu}
            setMenu={setMenu}
            menu={menu}
            user={props.local.user}
          />
        ) : currentView === 'menuItems' ? (
          <MenuItems
            selectedCompany={props.selectedCompany}
            menu={menu}
            selectMenu={selectMenu}
            selectedMenu={selectedMenu}
            selectItem={selectItem}
            user={props.local.user}
          />
        ) : currentView === 'itemInstructions' ? (
          <ItemInstructions
            selectedCompany={props.selectedCompany}
            menu={menu}
            selectedMenu={selectedMenu}
            selectedItem={selectedItem}
            user={props.local.user}
            addItemToCart={addItemToCart}
          />
        ) : currentView === 'cart' ? (
          <Cart
            updateCart={handleUpdateCart}
            address={props.selectedDelivery.address}
            selectedCompany={props.selectedCompany}
            navToView={navToView}
            cart={props.cart}
            user={props.local.user}
            addItemToCart={addItemToCart}
            checkOut={handleCheckout}
          />
        ) : currentView === 'checkout' ? (
          <Checkout
            createCart={props.createCart}
            address={props.selectedDelivery.address}
            location={props.selectedDelivery}
            selectedCompany={props.selectedCompany}
            navToView={navToView}
            bill={props.bill}
            setBill={setBill}
            user={props.local.user}
          />
        ) : currentView === 'status' ? (
          <Status
            setBill={setBill}
            navToView={navToView}
            bill={props.bill}
            user={props.local.user}
          />
        ) : currentView === 'track' ? (
          <Track
            setBill={setBill}
            navToView={navToView}
            bill={props.bill}
            user={props.local.user}
          />
        ) : currentView === 'myOrders' ? (
          <MyOrders
            navToView={navToView}
            user={props.local.user}
            setBill={setBill}
          />
        ) : currentView === 'orderDetails' ? (
          <OrderDetails
            setBill={setBill}
            navToView={navToView}
            bill={props.bill}
            user={props.local.user}
          />
        ) : currentView === 'profile' ? (
          <Profile
            handleUpdateCustomer={handleUpdateCustomer}
            navToView={navToView}
            user={props.local.user}
          />
        ) : null}

        {currentView !== 'delivery' ? (
          <CustomerFooter
            search={search}
            currentView={currentView}
            navToView={navToView}
            cart={props.cart}
            setFabActive={setFabActive}
            setFabActiveAnimate={setFabActiveAnimate}
            fabActive={fabActive}
          />
        ) : null}
      </Layout>
    </div>
  );
};

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  notifications: state.notifications.notifications || [],
  cart: state.customer.cart || {},
  bill: state.customer.bill || {},
  selectedCompany: state.customer.selectedCompany,
  selectedDelivery: state.customer.selectedDelivery
});

const mapDispatchToProps = {
  setSelectedCompany,
  setSelectedDelivery,
  createCart,
  createBill,
  updateBill,
  addToCart,
  updateCart,
  resetUser,
  endSession,
  openSnackbar,
  closeSnackbar,
  sendNotification,
  findNotifications,
  removeNotification,
  updateCustomer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Customer));
