import moment from 'moment';
export function addToCart(item, itemTotal) {
  return dispatch =>
    dispatch({
      type: 'ADD_TO_CART',
      data: { item: item, itemTotal: itemTotal }
    });
}

export function createCart(orgid, customerId, orgName, orgAverage, clear) {
  let cart = {
    orgid: orgid,
    orgName: orgName,
    userid: customerId,
    customerId: customerId,
    average: orgAverage,
    active: true,
    state: 'open',
    items: [],
    total: 0.0,
    amountLeft: 0.0,
    tip: 0.0,
    discount: 0.0,
    cash: 0.0,
    card: 0.0,
    tab: 0.0,
    payed: 0.0,
    date: moment().format('YYYYMMDDHHmm'),
    moddate: moment().format('YYYYMMDDHHmm')
  };
  return dispatch =>
    dispatch({
      type: 'CREATE_CART',
      data: cart,
      clear: clear
    });
}

export function updateCart(item) {
  return dispatch =>
    dispatch({
      type: 'UPDATE_CART',
      data: item
    });
}

export function setSelectedCompany(company) {
  return dispatch =>
    dispatch({
      type: 'SET_COMPANY',
      data: company
    });
}

export function setSelectedDelivery(location) {
  return dispatch =>
    dispatch({
      type: 'SET_DELIVERY',
      data: location
    });
}

export function createBill(deliveryCost) {
  return dispatch =>
    dispatch({
      type: 'CREATE_BILL',
      data: deliveryCost
    });
}

export function updateBill(bill) {
  return dispatch =>
    dispatch({
      type: 'CUSTOMER_UPDATE_BILL',
      data: bill
    });
}
