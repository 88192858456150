import React from 'react';
import {
  Spin,
  Table,
  Input,
  Checkbox,
  Icon,
  Row,
  Col,
  Popover,
  Card, ConfigProvider, Empty
} from 'antd';
import { connect } from 'react-redux';
import {
  getStockItems,
  handleEditStockAdjust,
  addStockAdjust
} from 'src/redux/actions/backend';
import withStyles from '@material-ui/core/styles/withStyles';
import StyledTable from 'src/components/Table/StyledTable';
import Button from 'src/components/CustomButtons/Button.jsx';
import Highlighter from 'react-highlight-words';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { openSnackbar, closeSnackbar } from 'src/redux/actions/snackbar';
import { setHelpMarker } from 'src/redux/actions/help';
import FormDialog from 'src/components/Dialogs/FormMultiButton.jsx';
import {
  primaryColor,
  grayColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
const { Column } = Table;
const { Search } = Input;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    border: '1px solid grey',
    color: 'grey',

    borderRadius: '0.2em'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'white',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  form: {
    margin: 0
  }
});

const dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
        name: 'm',
        value: 'm'
    },
    {
        name: 'cm',
        value: 'cm'
    },
    {
        name: 'mm',
        value: 'mm'
    }
],
  unit: []
};

const selectReason = [
  {
    name: 'Receive Stock',
    value: 'Receive Stock'
  },
  {
    name: 'Stock Count',
    value: 'Stock Count'
  },
  {
    name: 'Loss',
    value: 'Loss'
  },
  {
    name: 'Damage',
    value: 'Damage'
  }
];
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}
class Production extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      name: '',
      type: '',
      desc: '',
      date: '',
      time: '',
      user: 'Admin',
      waiting: false,
      stockadjust: [],
      stockadjustview: [],
      main: false,
      switch: false,
      mainSub: false,
      stock: {},
      stockitems: [],
      oStockitems: {},
      types: [],
      dimtypes: [],
      loading: true,
      fdopen: false,
      alert: false,
      msg: [],
      head: '',
      activeFilter: undefined,
      activeFilterDiff: false,
      activeFilterQty: false
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb)
    this.props.setBreadCrumbs(breadCrumbs)
  }
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop()
    this.props.setBreadCrumbs(breadCrumbs)
  }

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false)
      this.props.resetActionBreadCrumb()
      this.handleRemoveBreadCrumb()
    }
  }

  componentDidMount() {
    this.handleEditStockAdjust();
  }

  closeDialog = () => {
    this.setState({
      alert: false,
      fdopen: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Production Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  handleEditStockAdjust = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .handleEditStockAdjust(token)
      .then(res => {
        this.handleRemoveBreadCrumb()
        this.setState({
          _id: '',
          name: '',
          type: '',
          desc: '',
          date: '',
          time: '',
          stockadjust: res.data.results,
          stock: [],
          main: false,
          switch: false,
          loading: false,
          fdopen: false,
          activeFilter: undefined,
          activeFilterDiff: false,
          activeFilterQty: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleCancelCheck = () => {
    this.handleRemoveBreadCrumb()
    var stock = this.state.oStockitems;
    var check = false;
    for (var prop in stock) {
      if (stock[prop].select) {
        check = true;
        break;
      }
    }
    if (check) {
      this.setState({ fdopen: true });
    } else {
      this.handleEditStockAdjust();
    }
  };

  getStockItems = msg => {
    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });
    this.props
      .getStockItems(token)
      .then(result => {
        var stockitems = {};
        var name = 'PT0000';
        for (let i = 0; i < result.data.results.stockitems.length; i++) {
          stockitems[result.data.results.stockitems[i]._id] =
            result.data.results.stockitems[i];
        }

        name =
          this.state.stockadjust.length > 0
            ? name.slice(
              0,
              -(this.state.stockadjust.length + 1).toString().length
            ) + (this.state.stockadjust.length + 1).toString()
            : 'PT0001';
        this.handleAddBreadCrumb({ method: "handleCancelCheck", crumb: "Production" })
        this.setState({
          stockitems: result.data.results.stockitems,
          oStockitems: stockitems,
          types: result.data.results.types,
          _id: '',
          name: name,
          user: 'Admin',
          waiting: false,
          type: '',
          desc: '',
          dimension: '',
          dimType: '',
          qty: '',
          stock: {},
          main: true,
          switch: false,
          activeFilter: true,
          activeFilterDiff: false,
          activeFilterQty: false,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  showAdjust = r => {
    this.handleAddBreadCrumb({ method: "switch", crumb: "Production" })
    this.setState({
      stockadjustview: r.stockitems,
      _id: r._id,
      name: r.name,
      type: r.type,
      desc: r.desc,
      date: r.date,
      time: r.time,
      user: r.user || 'Admin',
      waiting: r.waiting,
      main: false,
      switch: true
    });
  };

  switch = () => {
    this.handleRemoveBreadCrumb()
    this.setState({
      stockadjustview: [],
      _id: '',
      name: '',
      type: '',
      desc: '',
      date: '',
      time: '',
      user: 'Admin',
      waiting: false,
      main: false,
      switch: false,
      activeFilter: undefined,
      activeFilterQty: false,
      activeFilterDiff: false
    });
  };

  handleChange = event => {
    if (event.target.name === 'type') {
      this.setState({ type: event.target.value });
    }
    if (event.target.name === 'dimentiontype') {
      this.setState({ dimType: event.target.value });
    }
  };

  onChangeEvt = e => {
    if (e.target.id === 'qty') {
      this.setState({ qty: e.currentTarget.value });
    }
    if (e.target.id === 'description') {
      this.setState({ desc: e.currentTarget.value });
    }
  };

  addStockAudit = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .addStockAdjust(this.state, token)
      .then(res => {
        this.handleEditStockAdjust();
        this.props.openSnackbar('Production Success');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  tableAmountCheck = (record, e) => {
    if (!this.state.type) {
      this.props.openSnackbar('Select Reason');
      return;
    }
    var stock = this.state.oStockitems;
    stock[record._id].select = e.target.checked;
    this.setState({ oStockitems: stock });
  };

  tableAmount = (record, e) => {
    if (!this.state.type) {
      this.props.openSnackbar('Select Reason');
      return;
    }
    let { value } = e.target;
    var stock = this.state.oStockitems;
    if (value) {
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        stock[record._id].amount = value;
        if (this.state.type === 'Receive Stock') {
          stock[record._id].newamount =
            stock[record._id].qty + parseFloat(value);
        }
        if (this.state.type === 'Stock Count') {
          stock[record._id].newamount = parseFloat(value);
        }
        if (this.state.type === 'Loss' || this.state.type === 'Damage') {
          stock[record._id].newamount =
            stock[record._id].qty - parseFloat(value);
        }
        stock[record._id].select = true;
        this.setState({ oStockitems: stock });
      }
    } else {
      stock[record._id].amount = '';
      stock[record._id].newamount = '';
      stock[record._id].select = false;
      this.setState({ oStockitems: stock });
    }
  };

  handleFormatListQty = () => {
    this.setState({
      activeFilterQty: true,
      activeFilter: true,
      activeFilterDiff: false
    });
  };
  handleFormatList = format => {
    this.setState({
      activeFilter: format,
      activeFilterQty: false,
      activeFilterDiff: false
    });
  };
  handleFormatListDiff = format => {
    this.setState({
      activeFilter: true,
      activeFilterQty: false,
      activeFilterDiff: format
    });
  };
  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="sm"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
        </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="sm"
            style={{ width: 90 }}
          >
            Reset
        </Button>
        </div>
      ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });
  customizeRenderEmptyNoButton = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty description={false}
        imageStyle={{
          margin: "3em",
          height: 90,
        }} />

    </div>
  );
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: "3em",
          marginBottom: "0.5em",
          height: 90,
        }} />
      <button
        style={{

          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',

          width: '10.5em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',

          borderRadius: '3em',
          zIndex: 99,
          marginTop: "1em",
          marginBottom: "1.5em",
        }}
        onClick={() => this.getStockItems()}
        color="primary"
      >
        Add Production
                  </button>
    </div>
  );
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditStockItem: this.handleEditStockItem,
      handleDeleteStockItem: this.handleDeleteStockItem,
      handleRestoreStockItem: this.handleRestoreStockItem,
      onChangeEvt: this.onChangeEvt,
      handleChange: this.handleChange,
      submitForm: this.submitForm,
      handleOpenStock: this.handleOpenTable,
      onHelpMarker: this.onHelpMarker
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div>
        {this.state.type === 'Stock Count' ? (
          <Button
            style={{
              backgroundColor: 'rgb(30, 161, 169)',
              border: '1px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'white',
              height: '100%',
              borderRadius: '0.3em'
            }}
            onClick={() => this.handleFormatListDiff(true)}
          >
            Difference
          </Button>
        ) : null}
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(true)}
        >
          Active
        </Button>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(false)}
        >
          Inactive
        </Button>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(undefined)}
        >
          All
        </Button>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatListQty()}
        >
          Low Stock
        </Button>
      </div>
    );
    const EyeContentCount = (
      <div>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatListDiff(true)}
        >
          Difference
          </Button>
        <Button
          style={{
            backgroundColor: 'rgb(30, 161, 169)',
            border: '1px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(undefined)}
        >
          All
        </Button>
      </div>
    );
    let stockitems = [];
    let stockitemsReport = [];
    if (this.state.stockadjustview.length > 0) {
      for (var i = 0; i < this.state.stockadjustview.length; i++) {
        if (this.state.activeFilterDiff && this.state.type === 'Stock Count') {
          var value =
            (this.state.stockadjustview[i].amount || 0) -
            (this.state.stockadjustview[i].qty || 0);
          if (
            value < 0 &&
            value !== 0 &&
            this.state.stockadjustview[i].amount
          ) {
            stockitemsReport.push(this.state.stockadjustview[i]);
          }
        } else if (this.state.activeFilter === undefined) {
          stockitemsReport.push(this.state.stockadjustview[i]);
        }
      }
    } else {
      for (var i = 0; i < this.state.stockitems.length; i++) {
        if (this.state.activeFilterDiff && this.state.type === 'Stock Count') {
          var value =
            (this.state.oStockitems[this.state.stockitems[i]._id].amount || 0) -
            (this.state.stockitems[i].qty || 0);
          if (
            value < 0 &&
            value !== 0 &&
            this.state.oStockitems[this.state.stockitems[i]._id].amount
          ) {
            stockitems.push(this.state.stockitems[i]);
          }
        } else if (this.state.activeFilterQty) {
          if (
            this.state.stockitems[i].active &&
            this.state.stockitems[i].qty <= this.state.stockitems[i].lowqty
          ) {
            stockitems.push(this.state.stockitems[i]);
          }
        } else if (this.state.activeFilter === true) {
          if (this.state.stockitems[i].active) {
            stockitems.push(this.state.stockitems[i]);
          }
        } else if (this.state.activeFilter === false) {
          if (!this.state.stockitems[i].active) {
            stockitems.push(this.state.stockitems[i]);
          }
        } else if (this.state.activeFilter === undefined) {
          stockitems.push(this.state.stockitems[i]);
        }
      }
    }
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.main ? (
          <div>
            {' '}
            <Card>
              {' '}
              <Row>
                <h4
                  style={{
                    marginTop: '0em',
                    paddingBottom: '0px',

                    color: 'rgba(0, 0, 0, 0.63)',
                    fontWeight: '600'
                  }}
                  className={this.props.classes.cardTitleWhite}
                >
                  {'Production ' + this.state.name}
                </h4>
              </Row>
              <Row style={{ marginTop: '-1em' }}>
                <Col span={6}>
                  <CustomSelect
                    labelText="Reason*"
                    id="type"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.handleChange(e),
                      onClick: () => this.onHelpMarker('stockitemtype'),
                      value: this.state.type
                    }}
                    menuItems={selectReason}
                  />
                </Col>
              </Row>
              <Row>
                <CustomInput
                  labelText="Notes*"
                  id="description"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => oState.onChangeEvt(e),
                    onClick: () => oState.onHelpMarker('stockitemdesc'),
                    value: oState.state.desc
                  }}
                />
              </Row>
              <Row>
                {' '}
                <Button
                  style={{
                    position: 'fixed',
                    right: '0.5em',
                    bottom: '0.5em',
                    background: '#132440',
                    border: '1px solid transparent',
                    color: 'white',
                    padding: '0em',
                    boxShadow:"none",
                    width: '3.5em',
                    height: '3.5em',
                    borderRadius: '5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                  onClick={() => this.addStockAudit()}
                  color="primary"
                >
                  <Icon
                    style={{
                      position: 'absolute',
                      paddingLeft: 3
                    }}
                    type="check"
                  />  </Button>
                <Button style={{ color: "#00CC70", border: "1px solid #00CC70", backgroundColor: "white", borderRadius: "3.5em" }}
                  onClick={() => this.handleCancelCheck()}
                  color="primary"
                >
                  Cancel
                </Button>

                <Button style={{ marginLeft: "1em", borderRadius: "3.5em", border: "1px solid #00CC70", width: "9em" }} onClick={() => this.addStockAudit()} color="primary">
                  Save
                </Button>

              </Row>{' '}
              <Row>
                <div
                  style={{
                    float: 'right',
                    marginTop: '-3em',
                    marginBottom: '1em'
                  }}
                >
                  <Popover
                    placement="left"
                    content={EyeContent}
                    title=""
                    trigger="click"
                  >
                    <Button
                      style={{
                        backgroundColor: '#00CC70',
                        border: '0px solid rgb(30, 161, 169)',
                        color: 'white',
                        marginBottom: '-0.5em',
                        height: '3.5em',
                        marginLeft: '-0.27em',
                        borderRadius: '3em',

                        width: '2em'
                      }}
                    >
                      <Icon
                        style={{
                          paddingLeft: '2px'
                        }}
                        type="eye"
                      />
                    </Button>
                  </Popover>
                  <Popover
                    placement="bottomRight"
                    content={GearContent}
                    title=""
                    trigger="click"
                  >
                    <Button
                      style={{
                        display: 'none',
                        backgroundColor: 'white',
                        border: '1px solid rgb(15, 135, 123)',
                        color: 'rgb(15, 135, 123)',
                        marginBottom: '-0.5em',
                        height: '3.5em',
                        marginLeft: '-0.27em',
                        borderRadius: '0.3em',
                        transform: 'scale(0.9)',
                        width: '2em'
                      }}
                    >
                      <Icon
                        style={{
                          paddingLeft: '2px'
                        }}
                        type="setting"
                      />
                    </Button>
                  </Popover>{' '}
                </div>
              </Row>
              <Row>
                <ConfigProvider renderEmpty={this.customizeRenderEmptyNoButton}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.type ? stockitems : []}
                    pagination={{
                      hideOnSinglePage: true,
                      pageSize: 25,
                      itemRender: itemRender,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                      defaultCurrent: 1
                    }}
                    onChange={this.handleSort}
                    onRow={(record, rowIndex) => {
                      if (
                        this.state.type === 'Stock Count' &&
                        this.state.oStockitems[record._id].amount
                      ) {
                        var value =
                          (this.state.oStockitems[record._id].amount || 0) -
                          (record.qty || 0);
                        if (value === 0) {
                          return { style: { background: 'lightgreen' } };
                        } else if (value < 0) {
                          return { style: { background: 'lightcoral' } };
                        } else if (value > 0) {
                          return { style: { background: 'lightyellow' } };
                        }
                      }
                      if (!record.active) {
                        if (!this.state.activeFilterQty) {
                          return {
                            style: { background: 'rgba(255, 4, 4, 0.063)', color: 'red' }
                          };
                        }
                      }
                      if (this.state.activeFilterQty) {
                        if (record.active && record.qty <= record.lowqty) {
                          return { style: { background: 'lightyellow' } };
                        }
                      }
                      if (record.qty <= record.lowqty) {
                        return { style: { backgroundColor: 'lightyellow' } };
                      } else if (rowIndex % 2 == 0) {
                        return { style: { background: 'rgb(247, 247, 247)' } };
                      }
                    }}
                  >
                    <Column
                      title="#"
                      render={(text, record) => (
                        <div>
                          <Checkbox
                            onChange={event =>
                              this.tableAmountCheck(record, event)
                            }
                            checked={
                              this.state.oStockitems[record._id].select || false
                            }
                          />
                        </div>
                      )}
                      key="select"
                    />
                    <Column
                      {...this.getColumnSearchProps('name')}
                      title="Item Name"
                      dataIndex="name"
                      key="name"
                    />
                    <Column
                      {...this.getColumnSearchProps('type')}
                      title="Type"
                      dataIndex="type"
                      key="type"
                    />
                    <Column
                      title="Dimension"
                      render={(text, record) => (
                        <span>
                          {record.dimType ? record.dimType : record.dimension}
                        </span>
                      )}
                      key="dim"
                    />
                    <Column
                      title="In Stock"
                      render={(text, record) => {
                        return <span>{record.qty === 0 ? '0' : record.qty}</span>;
                      }}
                      key="qty"
                    />
                    <Column
                      title={
                        this.state.type === 'Receive Stock'
                          ? 'Add Stock'
                          : this.state.type !== 'Stock Count'
                            ? 'Remove Stock'
                            : 'Counted Stock'
                      }
                      width="150px"
                      render={(text, record) => (
                        <div>
                          <Input
                            placeholder="Input amount"
                            value={this.state.oStockitems[record._id].amount}
                            onChange={event => this.tableAmount(record, event)}
                          />
                        </div>
                      )}
                      key="amount"
                    />
                    {this.state.type === 'Stock Count' ? (
                      <Column
                        title="Difference"
                        render={(text, record) => {
                          return (
                            <span>
                              {this.state.oStockitems[record._id].amount
                                ? (this.state.oStockitems[record._id].amount ||
                                  0) - (record.qty || 0) : record.qty}
                            </span>
                          );
                        }}
                        key="diff"
                      />
                    ) : (
                        ''
                      )}
                    <Column
                      title="New Stock"
                      render={(text, record) => {
                        return (
                          <span>
                            {this.state.oStockitems[record._id].newamount}
                          </span>
                        );
                      }}
                      key="newamount"
                    />
                    {this.state.type !== 'Stock Count' ? (
                      <Column
                        title="Cost"
                        render={(text, record) => {
                          return (
                            <span>
                              {record.buyPrice &&
                                this.state.oStockitems[record._id].amount
                                ? 'R ' +
                                (
                                  parseFloat(record.buyPrice) *
                                  parseFloat(
                                    this.state.oStockitems[record._id].amount
                                  )
                                ).toFixed('2')
                                : 'R 0.00'}
                            </span>
                          );
                        }}
                        key="cost"
                      />
                    ) : (
                        ''
                      )}
                  </StyledTable>
                </ConfigProvider>
              </Row>{' '}
            </Card>
          </div>
        ) : (
            <div>
              {!this.state.switch ? (
                <div>
                  {' '}
                  <Button
                    style={{
                      position: 'fixed',
                      right: '0.5em',
                      bottom: '0.5em',
                      background: '#132440',
                      border: '1px solid transparent ',
                      color: 'white',
                      padding: '0em',
                      width: '3.5em',
                      height: '3.5em',
                      borderRadius: '5em',
                      fontSize: '1em',
                      zIndex: 99
                    }}
                    onClick={() => this.getStockItems()}
                    color="primary"
                  >
                    <h1
                      style={{
                        fontSize: '3em',
                        marginTop: '0.5em',
                        color: 'white'
                      }}
                    >
                      +
                  </h1>
                  </Button>
                  {this.state.stockadjust.length > 0 ? <Row>
                    <button
                      style={{
                        marginTop: '-0.7em',
                        backgroundColor: '#00CC70',
                        border: '0px solid rgb(30, 161, 169)',
                        color: 'white',

                        width: '10.5em',
                        height: '2.7em',
                        fontSize: 16,
                        fontFamily: '',

                        borderRadius: '3em',
                        zIndex: 8,
                        position: 'absolute'
                      }}
                      onClick={() => this.getStockItems()}
                      color="primary"
                    >
                      Add Production
                  </button>
                  </Row> : null}
                  <Row>   <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                    <StyledTable
                      style={{ background: 'white', marginTop: '3em' }}
                      rowKey="_id"
                      size="middle"
                      dataSource={this.state.stockadjust}
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: 25,
                        itemRender: (current, type, originalElement) => {
                          if (type === 'prev') {
                            return <a>Previous</a>;
                          }
                          if (type === 'next') {
                            return <a>Next</a>;
                          }
                          return originalElement;
                        },
                        showTotal: (total, range) =>
                          `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                        defaultCurrent: 1
                      }}
                      onChange={this.handleSort}
                      onRow={r => ({
                        onClick: () => this.showAdjust(r)
                      })}
                    >
                      <Column
                        {...this.getColumnSearchProps('name')}
                        title="Name"
                        dataIndex="name"
                        key="name"
                      />
                      <Column
                        {...this.getColumnSearchProps('date')}
                        title="Date"
                        render={(text, record) => {
                          return (
                            <span>
                              {record.time && record.date
                                ? record.date.slice(0, 4) +
                                '-' +
                                record.date.slice(4, 6) +
                                '-' +
                                record.date.slice(6, 8) +
                                ' ' + record.time
                                : record.date
                                  ? record.date.slice(0, 4) +
                                  '-' +
                                  record.date.slice(4, 6) +
                                  '-' +
                                  record.date.slice(6, 8) +
                                  ' '
                                  : 'N/A'}
                            </span>
                          );
                        }}
                        dataIndex="date"
                        key="date"
                      />
                      <Column
                        {...this.getColumnSearchProps('type')}
                        title="Reason"
                        dataIndex="type"
                        key="type"
                      />
                      <Column
                        {...this.getColumnSearchProps('user')}
                        title="Person"
                        render={(text, record) => {
                          return (<span>{record.user ? record.user : 'Admin'}</span>)
                        }}
                        dataIndex="user"
                        key="user"
                      />
                      <Column
                        title="Complete"
                        render={(text, record) => {
                          return (<span>{record.waiting ? 'No' : 'Yes'}</span>)
                        }}
                        dataIndex="waiting"
                        key="waiting"
                      />
                      <Column
                        title="Notes"
                        dataIndex="desc"
                        key="desc"
                      />
                    </StyledTable>
                  </ConfigProvider>
                  </Row>
                </div>
              ) : (
                  <div>
                    {' '}
                    <Card>
                      <Row>
                        <h4 className={this.props.classes.cardTitleWhite}>
                          {this.state.date.slice(0, 4) +
                            '-' +
                            this.state.date.slice(4, 6) +
                            '-' +
                            this.state.date.slice(6, 8) +
                            ' ' +
                            this.state.name}
                        </h4>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <CustomInput
                            labelText="Reason"
                            id="type"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: this.state.type
                            }}
                          />
                        </Col>
                        <Col span={1} />
                        <Col span={6}>
                          <CustomInput
                            labelText="Person"
                            id="person"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: this.state.user ? this.state.user : 'Admin'
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <CustomInput
                          labelText="Notes"
                          id="description"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            onChange: e => this.onChangeEvt(e),
                            onClick: () => this.onHelpMarker('stockitemdesc'),
                            value: this.state.desc
                          }}
                        />
                      </Row>
                      <Row>
                        <Button style={{ marginLeft: "1em", borderRadius: "3.5em", border: "1px solid #00CC70", width: "9em" }} onClick={() => this.switch()} color="primary">
                          Back
                        </Button>
                        {this.state.waiting ?
                          <Button style={{ marginLeft: "1em", borderRadius: "3.5em", border: "1px solid #00CC70", width: "9em" }} onClick={() => this.addStockAudit()} color="primary">
                            Adjust Stock
                         </Button>
                          : null}
                        {this.state.type === 'Stock Count' ?
                          <Popover
                            placement="right"
                            content={EyeContentCount}
                            title=""
                            trigger="click"
                          >
                            <Button
                              style={{
                                backgroundColor: '#00CC70',
                                border: '0px solid rgb(30, 161, 169)',
                                color: 'white',
                                marginBottom: '-0.5em',
                                height: '3.5em',
                                marginLeft: '-0.27em',
                                borderRadius: '3em',
                                float: 'right',
                                width: '2em'
                              }}
                            >
                              <Icon
                                style={{
                                  paddingLeft: '2px'
                                }}
                                type="eye"
                              />
                            </Button>
                          </Popover>
                          : null}
                      </Row>
                      <Row>
                        <ConfigProvider renderEmpty={this.customizeRenderEmptyNoButton}>
                          <StyledTable
                            style={{ background: 'white' }}
                            rowKey="_id"
                            size="middle"
                            dataSource={stockitemsReport}
                            pagination={{
                              hideOnSinglePage: true,
                              pageSize: 25,
                              itemRender: (current, type, originalElement) => {
                                if (type === 'prev') {
                                  return <a>Previous</a>;
                                }
                                if (type === 'next') {
                                  return <a>Next</a>;
                                }
                                return originalElement;
                              },
                              showTotal: (total, range) =>
                                `Showing ${range[0]} - ${
                                range[1]
                                }  of ${total} items`,
                              defaultCurrent: 1
                            }}
                            onChange={this.handleSort}
                            onRow={(record, rowIndex) => {
                              if (
                                this.state.type === 'Stock Count' &&
                                record.amount
                              ) {
                                var value =
                                  (record.amount || 0) -
                                  (record.qty || 0);
                                if (value < 0) {
                                  return { style: { background: 'lightcoral' } };
                                } else if (value > 0) {
                                  return { style: { background: 'lightyellow' } };
                                }
                              }
                            }}
                          >
                            <Column
                              {...this.getColumnSearchProps('name')}
                              title="Item Name"
                              dataIndex="name"
                              key="name"
                            />
                            <Column
                              {...this.getColumnSearchProps('type')}
                              title="Type"
                              dataIndex="type"
                              key="type"
                            />
                            <Column
                              title="Dimension"
                              render={(text, record) => (
                                <span>
                                  {record.dim ? record.dim : 'N/A'}
                                </span>
                              )}
                              key="dim"
                            />
                            <Column
                              title="Opening Stock"
                              render={(text, record) => {
                                return <span>{record.qty ? record.qty : '0'}</span>;
                              }}
                              key="qty"
                            />
                            <Column
                              title="Counted Stock"
                              render={(text, record) => {
                                return (
                                  <span>
                                    {record.amount ? record.amount : '0'}
                                  </span>
                                );
                              }}
                              key="amount"
                            />
                            {this.state.type === 'Stock Count' ? (
                              <Column
                                title="Difference"
                                render={(text, record) => {
                                  return (
                                    <span>
                                      {record.diff ? record.diff : '0'}
                                    </span>
                                  );
                                }}
                                key="diff"
                              />
                            ) : (
                                ''
                              )}
                            <Column
                              title="Closing  Stock"
                              render={(text, record) => {
                                return (
                                  <span>
                                    {record.newamount ? record.newamount : '0'}
                                  </span>
                                );
                              }}
                              key="newamount"
                            />
                          </StyledTable>
                        </ConfigProvider>
                      </Row>{' '}
                    </Card>
                  </div>
                )}
            </div>
          )}
        <FormDialog
          head="Production Warning "
          msg="Changes has been found without Saving!"
          formElements={[]}
          open={this.state.fdopen}
          close={this.closeDialog}
          actionBtn={[
            { name: 'Exit', click: this.handleEditStockAdjust },
            { name: 'Save', click: this.addStockAudit }
          ]}
        />
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  stockitems: state.stockitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  getStockItems,
  handleEditStockAdjust,
  addStockAdjust,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Production));
