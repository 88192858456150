import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Icon,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Tabs
} from 'antd';
import convert from 'convert-units';
import React, { memo, useEffect, useRef, useState } from 'react';
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineCheck,
  AiOutlineClose
} from 'react-icons/ai';
import PlacesAutocomplete from 'react-places-autocomplete';
import { connect } from 'react-redux';
import NumPad from 'src/components/NumPad';
import cocktail from 'src/static/createBillIcons/cocktail.png';
import eat from 'src/static/createBillIcons/eat.png';
import tab from 'src/static/createBillIcons/tab.png';
import takeAway from 'src/static/createBillIcons/take-away.png';
import thunder from 'src/static/createBillIcons/thunder.png';
import styled, { keyframes } from 'styled-components';
import './ItemsMenu.css';
const ButtonGroup = Button.Group;
const colors = ['red', 'orange', 'green'];

const { TabPane } = Tabs;
const { Content } = Layout;
const { Option } = Select;
const { Number } = NumPad;
const numpadTheme = {
  header: {
    primaryColor: '#00CC70',
    secondaryColor: 'transparent',
    highlightColor: 'transparent',
    backgroundColor: 'transparent'
  },
  body: {
    primaryColor: 'transparent',
    secondaryColor: 'transparent',
    highlightColor: 'transparent',
    backgroundColor: 'transparent'
  },
  panel: {
    backgroundColor: 'transparent'
  },
  global: {}
};
const shadowPulseGreen = keyframes`
  0% {
    box-shadow: 0 0 0 0px #00CC70;
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px #00CC70;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
`;
const shadowPulseRed = keyframes`
  0% {
    box-shadow: 0 0 0 0px red;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
`;
const StyledButton = styled(Button)`
  animation: ${shadowPulse} 1s infinite;
`;
const StyledButtonGreen = styled(Button)`
  animation: ${shadowPulseGreen} 1s infinite;
`;
const StyledButtonRed = styled(Button)`
  animation: ${shadowPulseRed} 1s infinite;
`;
const StyledInput = styled(Input)`
  border-radius: 3em;
  background-color: transparent;
  color: white;
`;
const StyledSelect = styled(Select)`
  background-color: transparent;
  color: white;
  .ant-select-selection {
    border-radius: 3em;
    height: 3em;
    padding-top: 0.5em;
    border-top-width: 0px;
    background-color: transparent;
    border: 0px solid #e9e9e9;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    transition: 0.2s;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    transition: 0.2s;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 60%) !important;
    background-color: #00cc70 !important;
  }
`;
/*const StyledTabs = styled(Tabs)`

        .ant-tabs-tab {
          transition: 0.2s;
          border-left: 6px solid #00CC70!important;
        }
        .ant-tabs-nav .ant-tabs-tab-active {
          transition: 0.2s;
          background-color: #00CC70!important;
          text-align: center;
          border-left: 45px solid #00CC70 !important;
          border-top: 1px solid #00CC70 !important;
          border-bottom: 1px solid #00CC70 !important;
          border-right: 45px solid #00CC70 !important;
        }
     
        .ant-tabs-nav .ant-tabs-tab-active h1 {
          color: white !important;
        }
        
      `
  ;
/*const StyledTabs = styled(Tabs)`
  ${props =>
    props.menuButtons.map(
      (button, i) => css`
        .ant-tabs-tab:nth-child(${+0}) {
          transition: 0.2s;
          border-left: 6px solid #00CC70!important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+0}) {
          transition: 0.2s;
          background-color: #00CC70!important;
          text-align: center;
          border-left: 45px solid #00CC70 !important;
          border-top: 1px solid #00CC70 !important;
          border-bottom: 1px solid #00CC70 !important;
          border-right: 6px solid #00CC70 !important;
        }
        .ant-tabs-tab:nth-child(${+1}) {
          transition: 0.2s;
          border-left: 6px solid #01C46E !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+1}) {
          transition: 0.2s;
          background-color: #01C46E !important;
          text-align: center;
          border-left: 45px solid #01C46E !important;
          border-top: 1px solid #01C46E !important;
          border-bottom: 1px solid #01C46E !important;
          border-right: 6px solid #01C46E!important;
        }
        .ant-tabs-tab:nth-child(${+2}) {
          transition: 0.2s;
          border-left: 6px solid #02BB6B !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+2}) {
          transition: 0.2s;
          background-color: #02BB6B!important;
          text-align: center;
          border-left: 45px solid #02BB6B!important;
          border-top: 1px solid #02BB6B !important;
          border-bottom: 1px solid #02BB6B !important;
          border-right: 6px solid #02BB6B!important;
        }
        .ant-tabs-tab:nth-child(${+3}) {
          transition: 0.2s;
          border-left: 6px solid #03B369 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+3}) {
          transition: 0.2s;
          background-color: #03B369 !important;
          text-align: center;
          border-left: 45px solid #03B369 !important;
          border-top: 1px solid #03B369 !important;
          border-bottom: 1px solid #03B369 !important;
          border-right: 6px solid #03B369 !important;
        }
        .ant-tabs-tab:nth-child(${+4}) {
          transition: 0.2s;
          border-left: 6px solid #04AA66 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+4}) {
          transition: 0.2s;
          background-color: #04AA66 !important;
          text-align: center;
          border-left: 45px solid #04AA66 !important;
          border-top: 1px solid #04AA66 !important;
          border-bottom: 1px solid #04AA66 !important;
          border-right: 6px solid #04AA66 !important;
        }
        .ant-tabs-tab:nth-child(${+5}) {
          transition: 0.2s;
          border-left: 6px solid #05A264 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+5}) {
          transition: 0.2s;
          background-color: #05A264 !important;
          text-align: center;
          border-left: 45px solid #05A264 !important;
          border-top: 1px solid #05A264 !important;
          border-bottom: 1px solid #05A264 !important;
          border-right: 6px solid #05A264 !important;
        }
        .ant-tabs-tab:nth-child(${+6}) {
          transition: 0.2s;
          border-left: 6px solid #069A62 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+6}) {
          transition: 0.2s;
          background-color: #069A62 !important;
          text-align: center;
          border-left: 45px solid #069A62 !important;
          border-top: 1px solid #069A62 !important;
          border-bottom: 1px solid #069A62 !important;
          border-right: 6px solid #069A62 !important;
        }
        .ant-tabs-tab:nth-child(${+7}) {
          transition: 0.2s;
          border-left: 6px solid #07915F !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+7}) {
          transition: 0.2s;
          background-color: #07915F !important;
          text-align: center;
          border-left: 45px solid #07915F !important;
          border-top: 1px solid #07915F !important;
          border-bottom: 1px solid #07915F !important;
          border-right: 6px solid #07915F !important;
        }
        .ant-tabs-tab:nth-child(${+8}) {
          transition: 0.2s;
          border-left: 6px solid #08895D !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+8}) {
          transition: 0.2s;
          background-color: #08895D !important;
          text-align: center;
          border-left: 45px solid #08895D !important;
          border-top: 1px solid #08895D !important;
          border-bottom: 1px solid #08895D !important;
          border-right: 6px solid #08895D !important;
        }
        .ant-tabs-tab:nth-child(${+9}) {
          transition: 0.2s;
          border-left: 6px solid 	#09805A !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+9}) {
          transition: 0.2s;
          background-color: 	#09805A !important;
          text-align: center;
          border-left: 45px solid 	#09805A !important;
          border-top: 1px solid 	#09805A !important;
          border-bottom: 1px solid 	#09805A !important;
          border-right: 6px solid 	#09805A !important;
        }
        .ant-tabs-tab:nth-child(${+10}) {
          transition: 0.2s;
          border-left: 6px solid 	#0A7858 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+10}) {
          transition: 0.2s;
          background-color: 	#0A7858 !important;
          text-align: center;
          border-left: 45px solid 	#0A7858 !important;
          border-top: 1px solid 	#0A7858 !important;
          border-bottom: 1px solid 	#0A7858 !important;
          border-right: 6px solid 	#0A7858 !important;
        }
        .ant-tabs-tab:nth-child(${+11}) {
          transition: 0.2s;
          border-left: 6px solid #0B6B54 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+11}) {
          transition: 0.2s;
          background-color: #0A7056 !important;
          text-align: center;
          border-left: 45px solid #0A7056 !important;
          border-top: 1px solid #0A7056 !important;
          border-bottom: 1px solid #0A7056 !important;
          border-right: 6px solid #0A7056 !important;
        }
        .ant-tabs-tab:nth-child(${+12}) {
          transition: 0.2s;
          border-left: 6px solid #0B6753 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+12}) {
          transition: 0.2s;
          background-color: #0B6753 !important;
          text-align: center;
          border-left: 45px solid #0B6753 !important;
          border-top: 1px solid #0B6753 !important;
          border-bottom: 1px solid #0B6753 !important;
          border-right: 6px solid #0B6753 !important;
        }
        .ant-tabs-tab:nth-child(${+13}) {
          transition: 0.2s;
          border-left: 6px solid #0C5F51  !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+13}) {
          transition: 0.2s;
          background-color: #0C5F51  !important;
          text-align: center;
          border-left: 45px solid #0C5F51 !important;
          border-top: 1px solid #0C5F51 !important;
          border-bottom: 1px solid #0C5F51  !important;
          border-right: 6px solid #0C5F51  !important;
        }
        .ant-tabs-tab:nth-child(${+14}) {
          transition: 0.2s;
          border-left: 6px solid #0D564E!important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+14}) {
          transition: 0.2s;
          background-color: #0D564E !important;
          text-align: center;
          border-left: 45px solid #0D564E  !important;
          border-top: 1px solid #0D564E !important;
          border-bottom: 1px solid #0D564E  !important;
          border-right: 6px solid #0D564E !important;
        }
        .ant-tabs-tab:nth-child(${+15}) {
          transition: 0.2s;
          border-left: 6px solid #0E4E4C !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+15}) {
          transition: 0.2s;
          background-color: #0E4E4C !important;
          text-align: center;
          border-left: 45px solid #0E4E4C !important;
          border-top: 1px solid #0E4E4C !important;
          border-bottom: 1px solid #0E4E4C  !important;
          border-right: 6px solid #0E4E4C !important;
        }
        .ant-tabs-tab:nth-child(${+16}) {
          transition: 0.2s;
          border-left: 6px solid #0F464A  !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+16}) {
          transition: 0.2s;
          background-color: #0F464A  !important;
          text-align: center;
          border-left: 45px solid #0F464A  !important;
          border-top: 1px solid #0F464A !important;
          border-bottom: 1px solid #0F464A  !important;
          border-right: 6px solid #0F464A  !important;
        }
        .ant-tabs-tab:nth-child(${+17}) {
          transition: 0.2s;
          border-left: 6px solid #103D47  !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+17}) {
          transition: 0.2s;
          background-color: #103D47  !important;
          text-align: center;
          border-left: 45px solid #103D47  !important;
          border-top: 1px solid #103D47 !important;
          border-bottom: 1px solid #103D47 !important;
          border-right: 6px solid #103D47  !important;
        }
        .ant-tabs-tab:nth-child(${+18}) {
          transition: 0.2s;
          border-left: 6px solid #113545 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+18}) {
          transition: 0.2s;
          background-color: #113545 !important;
          text-align: center;
          border-left: 45px solid #113545  !important;
          border-top: 1px solid #113545  !important;
          border-bottom: 1px solid #113545  !important;
          border-right: 6px solid #113545  !important;
        }
        .ant-tabs-tab:nth-child(${+19}) {
          transition: 0.2s;
          border-left: 6px solid #122C42  !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+19}) {
          transition: 0.2s;
          background-color: #122C42  !important;
          text-align: center;
          border-left: 45px solid #122C42 !important;
          border-top: 1px solid #122C42  !important;
          border-bottom: 1px solid #122C42 !important;
          border-right: 6px solid #122C42  !important;
        }
        .ant-tabs-tab:nth-child(${+20}) {
          transition: 0.2s;
          border-left: 6px solid #132440  !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active:nth-child(${+20}) {
          transition: 0.2s;
          background-color: #132440  !important;
          text-align: center;
          border-left: 45px solid #132440 !important;
          border-top: 1px solid #132440  !important;
          border-bottom: 1px solid #132440  !important;
          border-right: 6px solid #132440 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active h1 {
          color: white !important;
        }
        
      `
    )} 
      `; */

const StyledCard = styled(Card)`
  .ant-card-head-title {
    padding: 12px 0;
  }
`;
const PhoneStyledTabs = styled(Tabs)`
  .ant-tabs-nav-scroll {
    overflow: scroll;
  }
`;

const StyledCategorySelect = styled(Select)`
  margin: 0px !important;
  margin-top: 0px !important;
  border: 0 !important;
  border-radius: 0 !important;

  .ant-select-selection {
    height: 2.5em;
    padding-top: 0.2em;
    background: #00cc70;
    color: white;
    font-size: 18px;
    border: 0;
    font-family: Roboto;
    font-weight: 700;
    border-radius: 0;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    color: white;
  }
  .ant-select-selection__rendered {
    display: flex;
  }
  .ant-select-selection-selected-value {
    margin: auto;
  }
  .ant-select-dropdown-menu {
    max-height: 100% !important;
  }
`;

const dimSelectTypesFormat = {
  l: 'L',
  kg: 'Kg',
  unit: 'Each'
};

const setTable = table => {
  setMenus(prevState => ({
    ...prevState,
    billTable: table
  }));
};

const setBillDesc = desc => {
  setMenus(prevState => ({
    ...prevState,
    billDesc: desc
  }));
};

const cancelCooking = () => {
  setMenus(prevState => ({
    ...prevState,
    subCatItems: [],
    subCatItem: false,
    selectedItem: {},
    selectedCooking: [],
    cookingItems: {},
    showQtyNumber: false,
    initCooking: false
  }));
};

const setBillPpl = (ppl, increase) => {
  if (increase) {
    if (ppl === 1 || itemMenuStates.billPpl >= 1) {
      setMenus(prevState => ({
        ...prevState,
        billPpl:
          parseInt(prevState.billPpl) > 0
            ? parseInt(prevState.billPpl) + parseInt(ppl)
            : parseInt(ppl)
      }));
    }
  } else {
    setMenus(prevState => ({
      ...prevState,
      billPpl: ppl
    }));
  }
};

const setBillCustomer = (value, event) => {
  setMenus(prevState => ({
    ...prevState,
    billDesc: event.props.children,
    billCusId: value
  }));
};

const cookingBack = () => {
  var cookingItems = { ...itemMenuStates.cookingItems };
  if (cookingItems.details.flow) {
    if (itemMenuStates.selectedCooking[cookingItems.key - 1]) {
      var cookItem = {
        items: itemMenuStates.selectedCooking[cookingItems.key - 1],
        key: cookingItems.key - 1,
        details: cookingItems.details
      };
      setMenus(prevState => ({
        ...prevState,
        cookingItems: cookItem
      }));
    }
  } else {
    setMenus(prevState => ({
      ...prevState,
      cookingItems: {}
    }));
  }
};

const cookingNext = () => {
  var cookingItems = { ...itemMenuStates.cookingItems };
  if (itemMenuStates.selectedCooking[cookingItems.key + 1]) {
    var cookItem = {
      items: itemMenuStates.selectedCooking[cookingItems.key + 1],
      key: cookingItems.key + 1,
      details: cookingItems.details
    };
    setMenus(prevState => ({
      ...prevState,
      cookingItems: cookItem
    }));
  }
};

let newQtyModal = undefined;
const addOpenQtyModal = (
  props,
  selectedItem,
  add,
  key,
  item,
  setMenus,
  itemMenuStates
) => {
  if (newQtyModal) {
    newQtyModal.destroy();
    newQtyModal = undefined;
  }
  newQtyModal = Modal.confirm({
    className: 'summary-modal-sales',
    icon: 'none',
    centered: true,
    style: {
      margin: 'auto',
      textAlign: 'center'
    },
    width: '100%',
    maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.71)' },
    okButtonProps: {
      style: {
        backgroundColor: '#00CC70',
        borderRadius: '0em 0em 1em 1em',
        width: '13.2em',
        height: '3em',
        fontSize: '20px',
        textShadow: 'none',
        margin: 'auto',
        marginTop: '-5em'
      }
    },
    cancelButtonProps: {
      style: {
        fontSize: '1.5em',
        transform: 'scale(0.8)',
        position: 'absolute',
        marginLeft: '10.7em',
        fontFamily: 'sans-serif',
        paddingLeft: '0.52em',
        paddingBottom: '1.6em',
        marginTop: '-17.8em',
        backgroundColor: 'transparent',
        borderRadius: '3em',
        height: '35px',
        width: '35px',
        textShadow: 'none',
        border: '1.5px solid red',
        color: 'white'
      }
    },
    okText: 'Enter',
    cancelText: 'x',
    content: (
      <div style={{ overflow: 'hidden' }}>
        <h2
          style={{
            paddingTop: '0.7em',
            paddingRight: '1.3em',
            fontSize: '18px',
            height: '3em',
            margin: 'auto',
            color: 'white',
            backgroundColor: '#132440',
            width: '14.7em',
            borderRadius: '0.5em 0.5em 0em 0em'
          }}
        >
          {selectedItem.useDim &&
          selectedItem.stockitems &&
          selectedItem.stockitems[0] &&
          selectedItem.stockitems[0].dimType
            ? 'Enter Product Qty in (' +
              (dimSelectTypesFormat[selectedItem.stockitems[0].dimType]
                ? dimSelectTypesFormat[selectedItem.stockitems[0].dimType]
                : selectedItem.stockitems[0].dimType) +
              ')'
            : 'Enter Product Qty'}
        </h2>
        <Number
          theme={numpadTheme}
          position="flex-end"
          confirm={e => {}}
          sync={true}
          update={e => {}}
          onChange={e => {
            item = e;
          }}
          value={''}
          inline={true}
          decimal={2}
        />
      </div>
    ),
    onOk(e) {
      let promise = new Promise(async (resolve, reject) => {
        if (item > 0) {
          resolve(parseFloat(item));
        } else {
          reject();
        }
      });
      promise
        .then(newitem => {
          props.addToBill(
            selectedItem,
            add,
            key,
            newitem,
            setMenus,
            itemMenuStates,
            false,
            true
          );
          newQtyModal.destroy();
          newQtyModal = undefined;
        })
        .catch(err => {
          return false;
        });
    },
    onCancel() {
      newQtyModal.destroy();
      newQtyModal = undefined;
    }
  });
};

const AddOpenInstInput = inputProp => {
  const myRef = useRef();
  useEffect(() => {
    if (myRef && myRef.current) {
      setTimeout(() => {
        myRef.current.focus();
      }, 500);
    }
  });
  return (
    <input
      ref={myRef}
      style={{ width: '265px', height: ' 50px' }}
      onChange={e => {
        inputProp.addValue(e.currentTarget.value);
      }}
    />
  );
};

let newInstModal = undefined;
const addOpenInstModal = (props, item) => {
  if (newInstModal) {
    newInstModal.destroy();
    newInstModal = undefined;
  }
  const addValue = value => {
    item = value;
  };
  newInstModal = Modal.confirm({
    className: 'summary-modal-sales',
    icon: 'none',
    centered: true,
    style: {
      margin: 'auto',
      textAlign: 'center'
    },
    width: '100%',
    maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.71)' },
    okButtonProps: {
      style: {
        backgroundColor: '#00CC70',
        borderRadius: '0em 0em 1em 1em',
        width: '13.2em',
        height: '3em',
        fontSize: '20px',
        textShadow: 'none',
        margin: 'auto',
        marginTop: '-5em'
      }
    },
    cancelButtonProps: {
      style: {
        fontSize: '1.5em',
        transform: 'scale(0.8)',
        fontFamily: 'sans-serif',
        backgroundColor: 'transparent',
        borderRadius: '3em',
        height: '35px',
        width: '35px',
        textShadow: 'none',
        border: '1.5px solid red',
        color: 'white',
        position: 'absolute',
        zIndex: 9,
        top: '1.5em',
        marginLeft: '11em',
        paddingLeft: '10px'
      }
    },
    okText: 'Add',
    cancelText: 'X',
    content: (
      <div style={{ overflow: 'hidden' }}>
        <h2
          style={{
            paddingTop: '0.7em',
            paddingRight: '1.3em',
            fontSize: '18px',
            height: '3em',
            margin: 'auto',
            color: 'white',
            backgroundColor: '#132440',
            width: '14.7em',
            borderRadius: '0.5em 0.5em 0em 0em'
          }}
        >
          {'Input Instruction'}
        </h2>
        <AddOpenInstInput addValue={addValue} />
      </div>
    ),
    onOk(e) {
      let promise = new Promise(async (resolve, reject) => {
        if (item) {
          resolve(item);
        } else {
          reject();
        }
      });
      promise
        .then(newItem => {
          addToBillOpenIns(newItem, props);
          newInstModal.destroy();
          newInstModal = undefined;
        })
        .catch(err => {
          return false;
        });
    },
    onCancel() {
      newInstModal.destroy();
      newInstModal = undefined;
    }
  });
};

const addToBillOpenIns = (openIns, props) => {
  var selected = itemMenuStates.selectedItem;
  var check = false;
  if (openIns) {
    if (selected.openIns && selected.openIns.length) {
      selected.openIns.push(openIns);
      check = true;
    } else {
      selected.openIns = [openIns];
      check = true;
    }
  }
  if (check) {
    selected.desc = 'With Instructions';
    setMenus(prevState => ({
      ...prevState,
      selectedItem: selected
    }));
  }
};

const addToBillCooking = (item, key, props) => {
  var selected = itemMenuStates.selectedItem;
  var cookingItems = { ...itemMenuStates.cookingItems };
  if (cookingItems.details.limit === 0) {
    cookingItems.details.limit = 10000;
  }
  if (cookingItems.items.details.limit === 0) {
    cookingItems.items.details.limit = 10000;
  }
  if (!cookingItems.items.cookIns[key].selected) {
    cookingItems.items.cookIns[key].selected = true;
    var count = 0;
    for (var i = 0; i < cookingItems.items.cookIns.length; i++) {
      if (cookingItems.items.cookIns[i].selected) {
        count = count + 1;
      }
    }
    if (count <= cookingItems.items.details.limit) {
      selected.cookIns.push(item);
      var cookItem = cookingItems;
      if (cookingItems.details.flow) {
        if (
          itemMenuStates.selectedCooking[cookingItems.key + 1] &&
          count === cookingItems.items.details.limit
        ) {
          cookItem = {
            items: itemMenuStates.selectedCooking[cookingItems.key + 1],
            key: cookingItems.key + 1,
            details: cookingItems.details
          };
        } else if (count === cookingItems.details.limit) {
          if (count >= cookingItems.items.details.limit) {
            if (cookingItems.details.limit === 10000) {
              cookingItems.details.limit = 0;
            }
            if (cookingItems.items.details.limit === 10000) {
              cookingItems.items.details.limit = 0;
            }
            props.addToBill(
              itemMenuStates.selectedItem,
              false,
              false,
              false,
              setMenus,
              itemMenuStates
            );
            return;
          }
        } else {
          if (count === cookingItems.items.details.limit) {
            if (cookingItems.details.limit === 10000) {
              cookingItems.details.limit = 0;
            }
            if (cookingItems.items.details.limit === 10000) {
              cookingItems.items.details.limit = 0;
            }
            props.addToBill(
              itemMenuStates.selectedItem,
              false,
              false,
              false,
              setMenus,
              itemMenuStates
            );
            return;
          }
        }
      }
      if (cookingItems.details.limit === 10000) {
        cookingItems.details.limit = 0;
      }
      if (cookingItems.items.details.limit === 10000) {
        cookingItems.items.details.limit = 0;
      }
      setMenus(prevState => ({
        ...prevState,
        selectedItem: selected,
        cookingItems: cookItem
      }));
    } else {
      cookingItems.items.cookIns[key].selected = false;
      if (cookingItems.details.limit === 10000) {
        cookingItems.details.limit = 0;
      }
      if (cookingItems.items.details.limit === 10000) {
        cookingItems.items.details.limit = 0;
      }
      setMenus(prevState => ({
        ...prevState,
        cookingItems: cookingItems
      }));
    }
  } else {
    if (cookingItems.details.limit === 10000) {
      cookingItems.details.limit = 0;
    }
    if (cookingItems.items.details.limit === 10000) {
      cookingItems.items.details.limit = 0;
    }
    cookingItems.items.cookIns[key].selected = false;
    for (var i = 0; i < selected.cookIns.length; i++) {
      if (selected.cookIns[i].name === item.name) {
        selected.cookIns.splice(i, 1);
        break;
      }
    }
    setMenus(prevState => ({
      ...prevState,
      selectedItem: selected,
      cookingItems: cookingItems
    }));
  }
};

const showCookingItems = (item, cooking) => {
  var cookItem = {
    items: itemMenuStates.selectedCooking[item],
    key: item,
    details: cooking.details
  };
  setMenus(prevState => ({
    ...prevState,
    cookingItems: cookItem
  }));
};

const subCatBack = () => {
  setMenus(prevState => ({
    ...prevState,
    subCatItems: [],
    subCatItem: false
  }));
};

const showSubCatItems = item => {
  var menuitems = [];
  if (itemMenuStates.subCategories[item.catid]) {
    menuitems = itemMenuStates.subCategories[item.catid].menuitems;
  }
  setMenus(prevState => ({
    ...prevState,
    subCatItems: menuitems,
    subCatItem: true
  }));
};

const cookingIns = (item, mKey, key) => {
  item.mkey = mKey;
  item.key = key;
  item.cookIns = [];
  var cooking = JSON.parse(JSON.stringify(itemMenuStates.cooking));
  var inst = [];
  if (item.cookinslist) {
    for (var i = 0; i < item.cookinslist.length; i++) {
      for (var h = 0; h < cooking.length; h++) {
        if (item.cookinslist[i].cookid === cooking[h]._id) {
          cooking[h].details = item.cookinslist[i];
          inst.push(cooking[h]);
        }
      }
    }
  }
  var check = false;
  if (inst.length > 0) {
    if (inst[0].details.flow) {
      check = true;
    }
  }
  if (check) {
    var cookItem = {
      items: inst[0],
      key: 0,
      details: inst[0].details
    };
    setMenus(prevState => ({
      ...prevState,
      cookingItems: cookItem,
      selectedItem: item,
      selectedCooking: inst,
      initCooking: true,
      subCatItem: false
    }));
  } else {
    setMenus(prevState => ({
      ...prevState,
      selectedItem: item,
      selectedCooking: inst,
      cookingItems: {},
      initCooking: true,
      subCatItem: false
    }));
  }
};

const menuClick = () => {
  setMenus(prevState => ({
    ...prevState,
    subCatItems: [],
    subCatItem: false,
    selectedItem: {},
    selectedCooking: [],
    cookingItems: {},
    showQtyNumber: false,
    initCooking: false
  }));
};

let timeoutClick = false;
let setTimeoutClick;
const handleChangeAddressSelect = (address, setAutoCompleteValue) => {
  setAutoCompleteValue(address);
  // eslint-disable-next-line
  const service = new google.maps.Geocoder();
  service.geocode({ address: address }, result => {
    setLocation(prevState => ({
      ...prevState,
      address: result[0].formatted_address,
      lat: result[0].geometry.location.lat(),
      lng: result[0].geometry.location.lng()
    }));
  });
};
const handleChangeAddressSearch = (value, setAutoCompleteValue) => {
  setAutoCompleteValue(value);
};
let locationState = {
  lat: 0.0,
  lng: 0.0
};
let setLocation;
const CreateBill = ({ state, props }) => {
  const [takeoutType, setTakeoutType] = useState();
  [locationState, setLocation] = useState(locationState);
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [billCustomerName, setBillCustomerName] = useState('');
  const [billCustomerNumber, setBillCustomerNumber] = useState('');
  var check = false;
  if (billCount > 0) {
    check = true;
  }
  return (
    <div style={{}}>
      {!state.billType && !props.user.direct ? (
        <div>
          <Row style={{ height: 'calc(100vh - 48px)', display: 'flex' }}>
            <Col
              span={24}
              className="bill-type-blocks"
              style={{
                overflow: 'visible',
                textAlign: 'center',
                margin: 'auto',
                position: 'unset'
              }}
            >
              <Col span={12} style={{ textAlign: 'right' }}>
                <StyledButtonGreen
                  className={'bill-type-blocks-' + themeMode}
                  style={{
                    margin: props.tabCreationCheck()
                      ? '0px 0px 0px 40px'
                      : '0px 0px 40px 40px',
                    borderRadius: props.tabCreationCheck() ? '5em' : '0.5em',
                    minWidth: props.tabCreationCheck() ? '140px' : '180px',
                    minHeight: props.tabCreationCheck() ? '140px' : '170px',
                    height: props.tabCreationCheck() ? '140px' : '170px'
                  }}
                  onClick={() =>
                    props.billType('Eatin', setMenus, itemMenuStates)
                  }
                >
                  <img
                    style={{
                      width: '40px',
                      height: '40px'
                    }}
                    src={eat}
                  />
                  <br />
                  Eat in
                </StyledButtonGreen>
              </Col>

              <Col span={12} style={{ textAlign: 'left' }}>
                <StyledButtonGreen
                  style={{
                    margin: props.tabCreationCheck()
                      ? '0px 0px 0px 40px'
                      : '0px 0px 40px 40px',
                    borderRadius: props.tabCreationCheck() ? '5em' : '0.5em',
                    minWidth: props.tabCreationCheck() ? '140px' : '180px',
                    minHeight: props.tabCreationCheck() ? '140px' : '170px',
                    height: props.tabCreationCheck() ? '140px' : '170px'
                  }}
                  className={'bill-type-blocks-' + themeMode}
                  onClick={() =>
                    props.billType('Takeout', setMenus, itemMenuStates)
                  }
                >
                  {' '}
                  <img
                    style={{
                      width: '50px',
                      height: '50px'
                    }}
                    src={takeAway}
                  />
                  <br />
                  Take Away
                </StyledButtonGreen>
              </Col>

              <Col
                span={props.tabCreationCheck() ? 24 : 12}
                style={{
                  textAlign: props.tabCreationCheck() ? 'center' : 'right'
                }}
              >
                <StyledButtonGreen
                  style={{
                    margin: props.tabCreationCheck()
                      ? '0px 0px 0px 40px'
                      : '0px 0px 40px 40px',
                    borderRadius: props.tabCreationCheck() ? '5em' : '0.5em',
                    minWidth: props.tabCreationCheck() ? '120px' : '180px',
                    minHeight: props.tabCreationCheck() ? '120px' : '170px',
                    height: props.tabCreationCheck() ? '120px' : '170px'
                  }}
                  className={'bill-type-blocks-' + themeMode}
                  onClick={() =>
                    props.billType('quick sale', setMenus, itemMenuStates)
                  }
                >
                  <img
                    style={{
                      width: '30px',
                      height: '50px'
                    }}
                    src={thunder}
                  />
                  <br />
                  Quick Sale
                </StyledButtonGreen>
              </Col>

              <Col
                span={12}
                style={{
                  textAlign: props.tabCreationCheck() ? 'right' : 'left'
                }}
              >
                <StyledButtonGreen
                  style={{
                    margin: props.tabCreationCheck()
                      ? '0px 0px 0px 40px'
                      : '0px 0px 40px 40px',
                    borderRadius: props.tabCreationCheck() ? '5em' : '0.5em',
                    minWidth: props.tabCreationCheck() ? '140px' : '180px',
                    minHeight: props.tabCreationCheck() ? '140px' : '170px',
                    height: props.tabCreationCheck() ? '140px' : '170px'
                  }}
                  className={'bill-type-blocks-' + themeMode}
                  onClick={() =>
                    props.billType('Bar', setMenus, itemMenuStates)
                  }
                >
                  <img
                    style={{
                      width: '40px',
                      height: '50px'
                    }}
                    src={cocktail}
                  />
                  <br />
                  Bar
                </StyledButtonGreen>
              </Col>

              <Col span={12} style={{ textAlign: 'left' }}>
                {props.tabCreationCheck() ? (
                  <StyledButtonGreen
                    style={{
                      margin: props.tabCreationCheck()
                        ? '0px 0px 0px 40px'
                        : '0px 0px 40px 40px',
                      borderRadius: props.tabCreationCheck() ? '5em' : '0.5em',
                      minWidth: props.tabCreationCheck() ? '140px' : '180px',
                      minHeight: props.tabCreationCheck() ? '140px' : '170px',
                      height: props.tabCreationCheck() ? '140px' : '170px'
                    }}
                    className={'bill-type-blocks-' + themeMode}
                    onClick={() =>
                      props.billType('Tab', setMenus, itemMenuStates)
                    }
                  >
                    <img
                      style={{
                        width: '53px',
                        height: '50px'
                      }}
                      src={tab}
                    />
                    <br />
                    Tab
                  </StyledButtonGreen>
                ) : null}{' '}
              </Col>

              {check ? (
                <Col span={24} style={{ margin: 'auto', textAlign: 'center' }}>
                  <div onClick={() => props.billBack(null, setMenus)}>
                    <Button
                      className={'cancel-button-' + themeMode}
                      type="primary"
                      style={{
                        bottom: '-1.5em',
                        width: '35%',
                        marginLeft: '40px'
                      }}
                    >
                      <Icon
                        style={{ fontSize: '1em', color: 'red', top: 15 }}
                        type="close-circle"
                      />
                      Cancel
                    </Button>
                  </div>
                  \
                </Col>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{ paddingTop: '2em' }}>
          {state.billType === 'Eatin' ? (
            <div>
              <Row>
                <Col span={5} />
                <Col
                  className="table-select"
                  style={{ textAlign: 'center', maxWidth: '40em' }}
                  span={14}
                >
                  <h1 style={{ fontSize: '25px', color: 'white' }}>
                    Select Table
                  </h1>
                  {state.tables &&
                    state.tables.map((table, key) => (
                      <Button
                        className={'table-number-button-' + themeMode}
                        key={key + 1}
                        onClick={() => setTable(key + 1)}
                      >
                        {key + 1}
                      </Button>
                    ))}

                  <StyledInput
                    maxLength={15}
                    autoFocus
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      padding: '20px',
                      margin: '10px 0 10px',
                      border: '1px solid #00CC70'
                    }}
                    onChange={e => setBillDesc(e.target.value)}
                    placeholder="Description"
                    value={state.billDesc}
                  />
                  <StyledSelect
                    showSearch
                    style={{
                      backgroundColor: 'transparent',
                      color: 'white',
                      borderRadius: '3em',
                      display: 'inline-block',
                      width: '100%',
                      margin: '10px 0 20px'
                    }}
                    onChange={e => setBillPpl(e)}
                    placeholder="Select number of customers"
                    //value={state.billPpl}
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                    <Option value="6">6</Option>
                    <Option value="7">7</Option>
                    <Option value="8">8</Option>
                    <Option value="9">9</Option>
                    <Option value="10">10</Option>
                  </StyledSelect>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      border: '1px solid #00CC70',
                      color: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.95)'
                    }}
                    onClick={() =>
                      props.addNewBill(
                        null,
                        null,
                        null,
                        null,
                        setMenus,
                        itemMenuStates
                      )
                    }
                  >
                    Create Bill
                  </Button>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      border: '1px solid #00CC70',
                      color: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.95)'
                    }}
                    onClick={() => props.billBack(true, setMenus)}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          ) : state.billType === 'Takeout' ? (
            !takeoutType ? (
              <div>
                <Row>
                  <Col span={5} />
                  <Col
                    className="create-bill-section"
                    style={{ textAlign: 'center', overflow: 'hidden' }}
                    span={14}
                  >
                    <Row>
                      <Col style={{ margin: 'auto' }} span={12}>
                        <Radio.Button
                          onClick={e => setTakeoutType('Collect')}
                          className={props.paySplitPerson ? 'active-green' : ''}
                          style={{
                            height: '3em',
                            width: '80%',
                            minWidth: '8em',
                            marginBottom: '1em',
                            marginTop: '0.7em',
                            borderRadius: '3em',
                            maxWidth: 200,
                            paddingTop: '0.4em',
                            fontWeight: '600',
                            fontSize: '15px',
                            marginRight: 'auto',
                            backgroundColor: 'transparent',
                            color: '#00CC70',
                            whiteSpace: 'nowrap',
                            border: '1px solid #00CC70'
                            // boxShadow: '0 4px 3px rgba(0, 0, 0, 0.15)'
                          }}
                          value="person"
                        >
                          Collect
                        </Radio.Button>
                      </Col>
                      <Col style={{ margin: 'auto' }} span={12}>
                        <Radio.Button
                          onClick={e => setTakeoutType('Delivery')}
                          className={props.paySplitItem ? 'active-green' : ''}
                          style={{
                            height: '3em',
                            minWidth: '8em',
                            width: '80%',
                            borderRadius: '3em',
                            maxWidth: 200,
                            paddingTop: '0.4em',
                            fontWeight: '600',
                            fontSize: '15px',
                            marginLeft: 'auto',
                            marginBottom: '1em',
                            marginTop: '0.7em',
                            backgroundColor: 'transparent',
                            color: '#00CC70',
                            border: '1px solid #00CC70'
                            // boxShadow: '0 4px 3px rgba(0, 0, 0, 0.15)'
                          }}
                          value="item"
                        >
                          Delivery
                        </Radio.Button>
                      </Col>
                    </Row>
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() => {
                        setAutoCompleteValue('');
                        setBillCustomerName('');
                        setBillCustomerNumber('');
                        setTakeoutType(undefined);
                        props.billBack(true, setMenus);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : takeoutType === 'Collect' ? (
              <div>
                <Row>
                  <Col span={5} />
                  <Col
                    className="create-bill-section"
                    style={{ textAlign: 'center', overflow: 'hidden' }}
                    span={14}
                  >
                    <StyledInput
                      maxLength={15}
                      autoFocus
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        padding: '20px',
                        margin: '10px 0 10px',
                        border: '1px solid #00CC70'
                      }}
                      onChange={e => setBillDesc(e.target.value)}
                      placeholder="Description"
                      value={state.billDesc}
                    />
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() =>
                        props.addNewBill(
                          takeoutType,
                          null,
                          null,
                          null,
                          setMenus,
                          itemMenuStates
                        )
                      }
                    >
                      Create Bill
                    </Button>
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() => {
                        setAutoCompleteValue('');
                        setBillCustomerName('');
                        setBillCustomerNumber('');
                        setTakeoutType(undefined);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <Row>
                  <Col span={5} />
                  <Col
                    className="create-bill-section"
                    style={{ textAlign: 'center', overflow: 'hidden' }}
                    span={14}
                  >
                    <PlacesAutocomplete
                      value={autoCompleteValue}
                      onChange={e =>
                        handleChangeAddressSearch(e, setAutoCompleteValue)
                      }
                      onSelect={e =>
                        handleChangeAddressSelect(e, setAutoCompleteValue)
                      }
                      searchOptions={{
                        componentRestrictions: { country: 'za' },
                        types: ['address']
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => {
                        return (
                          <div
                            style={{
                              width: '100%'
                            }}
                          >
                            <StyledInput
                              value={autoCompleteValue}
                              autoFocus
                              style={{
                                display: 'inline-block',
                                width: '100%',
                                padding: '20px',
                                zIndex: 9,
                                margin: '10px 0 10px',
                                border: '1px solid #00CC70'
                              }}
                              placeholder="Enter street address"
                              {...getInputProps()}
                            />
                            <Select
                              style={{
                                opacity: 0,
                                width: '100%',
                                position: 'absolute',
                                margin: 'auto',
                                top: 10
                              }}
                              dropdownStyle={{ width: '100%', margin: 'auto' }}
                              showSearch={false}
                              open={suggestions.length > 0}
                            >
                              {suggestions.map(suggestion => {
                                return (
                                  <Select.Option
                                    {...getSuggestionItemProps(suggestion)}
                                    key={suggestion.id}
                                    value={suggestion.description}
                                  >
                                    {suggestion.description}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </div>
                        );
                      }}
                    </PlacesAutocomplete>
                    <StyledInput
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        padding: '20px',
                        margin: '10px 0 10px',
                        border: '1px solid #00CC70'
                      }}
                      onChange={e => setBillCustomerName(e.target.value)}
                      placeholder="Customer Name"
                      value={billCustomerName}
                    />
                    <StyledInput
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        padding: '20px',
                        margin: '10px 0 10px',
                        border: '1px solid #00CC70'
                      }}
                      onChange={e => setBillCustomerNumber(e.target.value)}
                      placeholder="Customer Number"
                      value={billCustomerNumber}
                    />
                    <StyledInput
                      maxLength={15}
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        padding: '20px',
                        margin: '10px 0 10px',
                        border: '1px solid #00CC70'
                      }}
                      onChange={e => setBillDesc(e.target.value)}
                      placeholder="Description"
                      value={state.billDesc}
                    />
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() =>
                        props.addNewBill(
                          takeoutType,
                          autoCompleteValue,
                          billCustomerNumber,
                          billCustomerName,
                          setMenus,
                          itemMenuStates
                        )
                      }
                    >
                      Create Bill
                    </Button>
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() => {
                        setAutoCompleteValue('');
                        setBillCustomerName('');
                        setBillCustomerNumber('');
                        setTakeoutType(undefined);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            )
          ) : state.billType === 'Bar' ? (
            <div>
              <Row>
                <Col span={5} />
                <Col
                  className="create-bill-section"
                  style={{ textAlign: 'center', overflow: 'hidden' }}
                  span={14}
                >
                  <StyledInput
                    maxLength="15"
                    autoFocus
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      padding: '20px',
                      margin: '10px 0 10px',
                      border: '1px solid #00CC70'
                    }}
                    onChange={e => setBillDesc(e.target.value)}
                    placeholder="Description"
                    value={state.billDesc}
                  />
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.9)'
                    }}
                    onClick={() =>
                      props.addNewBill(
                        null,
                        null,
                        null,
                        null,
                        setMenus,
                        itemMenuStates
                      )
                    }
                  >
                    Create Bill
                  </Button>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.9)'
                    }}
                    onClick={() => props.billBack(true, setMenus)}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          ) : state.billType === 'Tab' ? (
            <div>
              <Row>
                <Col span={5} />
                <Col
                  className="create-bill-section"
                  style={{ textAlign: 'center', overflow: 'hidden' }}
                  span={14}
                >
                  <Select
                    showSearch
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      margin: '10px 0 20px'
                    }}
                    onChange={(v, e) => setBillCustomer(v, e)}
                    placeholder="Select Customer"
                  >
                    {state.customers.map((cust, key) => (
                      <Option key={cust._id} value={cust._id}>
                        {cust.name}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.9)'
                    }}
                    onClick={() =>
                      props.addNewBill(
                        null,
                        null,
                        null,
                        null,
                        setMenus,
                        itemMenuStates
                      )
                    }
                  >
                    Create Bill
                  </Button>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.9)'
                    }}
                    onClick={() => props.billBack(true, setMenus)}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <div />
          )}
        </div>
      )}
    </div>
  );
};

const phoneCreateBillButtonStyle = {
  backgroundColor: 'rgba(207, 229, 255, 0.282)',
  color: 'white',
  textAlign: 'center',
  fontWeight: '700',
  fontSize: '20px',
  display: 'inline-block',
  borderRadius: ' 0.3em',
  width: '95%',
  maxHeight: '100px',
  minHeight: '100px',
  border: '0px solid black',
  marginBottom: 10,
  boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.15)'
};

const phoneCreateBillImageStyle = {
  width: '50px',
  height: '50px',
  marginRight: '20%',
  marginLeft: '20%'
};

const PhoneCreateBill = ({
  state,
  props,
  setBackFromTakeAwayType,
  backFromTakeAwayType,
  addNewDeliveryBill,
  doDeliveryBill
}) => {
  const [takeoutType, setTakeoutType] = useState();
  [locationState, setLocation] = useState(locationState);
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [billCustomerName, setBillCustomerName] = useState('');
  const [billCustomerNumber, setBillCustomerNumber] = useState('');
  useEffect(() => {
    if (backFromTakeAwayType === false) {
      setAutoCompleteValue('');
      setBillCustomerName('');
      setBillCustomerNumber('');
      setTakeoutType(undefined);
    }
  }, [backFromTakeAwayType]);
  useEffect(() => {
    if (doDeliveryBill) {
      addNewDeliveryBill(
        takeoutType,
        autoCompleteValue,
        billCustomerNumber,
        billCustomerName,
        setMenus,
        itemMenuStates
      );
    }
  }, [doDeliveryBill]);
  return (
    <div style={{}}>
      {!state.billType ? (
        <div>
          <Row style={{ height: 'calc(100vh - 48px)', display: 'flex' }}>
            <Col
              className="bill-type-blocks"
              style={{
                overflow: 'auto',
                textAlign: 'center',
                margin: 'auto',
                position: 'unset',
                paddingBottom: '150px',
                height: '100vh'
              }}
            >
              <Button
                style={phoneCreateBillButtonStyle}
                onClick={() =>
                  props.billType('Eatin', setMenus, itemMenuStates)
                }
              >
                <div
                  style={{
                    position: 'absolute',
                    left: '15vw',
                    top: '0.9em',
                    width: '60px',
                    height: '60px',
                    border: '3px solid #00CC70',
                    padding: '0.5em',
                    borderRadius: '2em'
                  }}
                >
                  <img
                    style={{
                      width: '30px',
                      height: '30px'
                    }}
                    src={eat}
                  />{' '}
                </div>
                <span style={{ marginLeft: '2em', fontFamily: 'Roboto' }}>
                  Eat in
                </span>
              </Button>
              <Button
                style={phoneCreateBillButtonStyle}
                onClick={() =>
                  props.billType('Takeout', setMenus, itemMenuStates)
                }
              >
                {' '}
                <div
                  style={{
                    position: 'absolute',
                    left: '15vw',
                    top: '0.9em',
                    width: '60px',
                    height: '60px',
                    border: '3px solid #00CC70',
                    padding: '0.4em',
                    borderRadius: '2em'
                  }}
                >
                  <img
                    style={{
                      width: '38px',
                      height: '38px'
                    }}
                    src={takeAway}
                  />{' '}
                </div>
                <span style={{ marginLeft: '2em', fontFamily: 'Roboto' }}>
                  Take Away
                </span>
              </Button>
              <Button
                style={phoneCreateBillButtonStyle}
                onClick={() =>
                  props.billType('quick sale', setMenus, itemMenuStates)
                }
              >
                <div
                  style={{
                    position: 'absolute',
                    left: '15vw',
                    top: '0.9em',
                    width: '60px',
                    height: '60px',
                    border: '3px solid #00CC70',
                    padding: '0.4em',
                    borderRadius: '2em'
                  }}
                >
                  <img
                    style={{
                      width: '30px',
                      height: '40px'
                    }}
                    src={thunder}
                  />{' '}
                </div>
                <span style={{ marginLeft: '2em', fontFamily: 'Roboto' }}>
                  Quick Sale
                </span>
              </Button>
              <Button
                style={phoneCreateBillButtonStyle}
                onClick={() => props.billType('Bar', setMenus, itemMenuStates)}
              >
                <div
                  style={{
                    position: 'absolute',
                    left: '15vw',
                    top: '0.9em',
                    width: '60px',
                    height: '60px',
                    border: '3px solid #00CC70',
                    padding: '0.5em',
                    borderRadius: '2em'
                  }}
                >
                  <img
                    style={{
                      width: '30px',
                      height: '35px'
                    }}
                    src={cocktail}
                  />{' '}
                </div>
                <span style={{ marginLeft: '2em', fontFamily: 'Roboto' }}>
                  Bar
                </span>
              </Button>
              {props.tabCreationCheck() ? (
                <Button
                  style={phoneCreateBillButtonStyle}
                  onClick={() =>
                    props.billType('Tab', setMenus, itemMenuStates)
                  }
                >
                  <div
                    style={{
                      position: 'absolute',
                      left: '15vw',
                      top: '0.9em',
                      width: '60px',
                      height: '60px',
                      border: '3px solid #00CC70',
                      padding: '0.4em',
                      borderRadius: '2em'
                    }}
                  >
                    <img
                      style={{
                        width: '35px',
                        height: '35px'
                      }}
                      src={tab}
                    />{' '}
                  </div>
                  <span style={{ marginLeft: '2em', fontFamily: 'Roboto' }}>
                    Tab
                  </span>
                </Button>
              ) : null}
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{ marginTop: 15 }}>
          {state.billType === 'Eatin' ? (
            <div>
              <Row type="flex" justify="center">
                <Col span={22} style={{ textAlign: 'center' }}>
                  <div>
                    <img style={{ width: 50, height: 50 }} src={eat} />
                    <br />
                    <span
                      style={{
                        color: 'white',
                        fontWeight: '700',
                        fontSize: '20px'
                      }}
                    >
                      Eat in
                    </span>
                  </div>
                </Col>
                <Col
                  className="table-select"
                  style={{ textAlign: 'center' }}
                  span={22}
                >
                  <h1 style={{ fontSize: '15px', color: 'white' }}>
                    Select Table
                  </h1>
                  {state.tables &&
                    state.tables.map((table, key) => (
                      <Button
                        className={'table-number-button-' + themeMode}
                        key={key + 1}
                        onClick={() => setTable(key + 1)}
                      >
                        {key + 1}
                      </Button>
                    ))}
                  <div>
                    <Col
                      style={{ display: 'flex', height: 45, color: 'white' }}
                      span={12}
                    >
                      <span style={{ margin: 'auto' }}>Number of Persons</span>
                    </Col>
                    <Col span={12}>
                      <ButtonGroup className="bill-items-btn-group">
                        <button
                          style={{
                            backgroundColor: 'transparent',
                            color: 'red',
                            border: '1px solid red',
                            borderRadius: '120px',
                            height: '27px',
                            width: '27px',
                            transform: 'scale(0.8)',
                            padding: '3px'
                          }}
                          onClick={() => setBillPpl(-1, true)}
                        >
                          <Icon
                            type="minus"
                            style={{
                              fontSize: '17px',
                              paddingTop: '1px'
                            }}
                          />
                        </button>
                        <div
                          style={{
                            display: 'inline-block',
                            backgroundColor: 'transparent',
                            color: 'white',
                            borderRadius: '0px',
                            boxShadow: 'none',
                            top: '-4px',
                            width: '28px',
                            fontSize: '1.1em',
                            margin: '10px',
                            fontWeight: '600',
                            textAlign: 'center'
                          }}
                        >
                          {' '}
                          {state.billPpl ? state.billPpl : 1}
                        </div>
                        <button
                          style={{
                            backgroundColor: 'transparent',
                            color: 'green',
                            border: '1px solid green',
                            transform: 'scale(0.8)',
                            borderRadius: '200px',
                            height: '27px',
                            width: '27px',

                            padding: '3px'
                          }}
                          onClick={() => setBillPpl(1, true)}
                        >
                          <Icon
                            type="plus"
                            style={{
                              paddingTop: '1px',
                              fontSize: '17px'
                            }}
                          />
                        </button>
                      </ButtonGroup>
                    </Col>
                  </div>
                  <StyledInput
                    maxLength={15}
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      padding: '20px',
                      margin: '10px 0 10px',
                      border: '1px solid #00CC70'
                    }}
                    onChange={e => setBillDesc(e.target.value)}
                    placeholder="Description"
                    value={state.billDesc}
                  />
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      border: '1px solid #00CC70',
                      color: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.95)'
                    }}
                    onClick={() =>
                      props.addNewBill(
                        null,
                        null,
                        null,
                        null,
                        setMenus,
                        itemMenuStates
                      )
                    }
                  >
                    Create Bill
                  </Button>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      border: '1px solid #00CC70',
                      color: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.95)'
                    }}
                    onClick={() => props.billBack(true, setMenus)}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          ) : state.billType === 'Takeout' ? (
            !takeoutType ? (
              <div>
                <Row type="flex" justify="center">
                  <Col span={22} style={{ textAlign: 'center' }}>
                    <div>
                      <img style={{ width: 50, height: 50 }} src={takeAway} />
                      <br />
                      <span
                        style={{
                          color: 'white',
                          fontWeight: '700',
                          fontSize: '20px'
                        }}
                      >
                        Take Away
                      </span>
                    </div>
                  </Col>
                  <Col
                    className="create-bill-section"
                    style={{ textAlign: 'center', overflow: 'hidden' }}
                    span={22}
                  >
                    <Row>
                      <Col style={{ margin: 'auto' }} span={12}>
                        <Radio.Button
                          onClick={e => {
                            setBackFromTakeAwayType(true);
                            setTakeoutType('Collect');
                          }}
                          className={props.paySplitPerson ? 'active-green' : ''}
                          style={{
                            height: '3em',
                            width: '80%',
                            minWidth: '8em',
                            marginBottom: '1em',
                            marginTop: '0.7em',
                            borderRadius: '3em',
                            maxWidth: 200,
                            paddingTop: '0.4em',
                            fontWeight: '600',
                            fontSize: '15px',
                            marginRight: 'auto',
                            backgroundColor: 'transparent',
                            color: '#00CC70',
                            whiteSpace: 'nowrap',
                            border: '1px solid #00CC70'
                            // boxShadow: '0 4px 3px rgba(0, 0, 0, 0.15)'
                          }}
                          value="person"
                        >
                          Collect
                        </Radio.Button>
                      </Col>
                      <Col style={{ margin: 'auto' }} span={12}>
                        <Radio.Button
                          onClick={e => {
                            setBackFromTakeAwayType(true);
                            setTakeoutType('Delivery');
                          }}
                          className={props.paySplitItem ? 'active-green' : ''}
                          style={{
                            height: '3em',
                            minWidth: '8em',
                            width: '80%',
                            borderRadius: '3em',
                            maxWidth: 200,
                            paddingTop: '0.4em',
                            fontWeight: '600',
                            fontSize: '15px',
                            marginLeft: 'auto',
                            marginBottom: '1em',
                            marginTop: '0.7em',
                            backgroundColor: 'transparent',
                            color: '#00CC70',
                            border: '1px solid #00CC70'
                            // boxShadow: '0 4px 3px rgba(0, 0, 0, 0.15)'
                          }}
                          value="item"
                        >
                          Delivery
                        </Radio.Button>
                      </Col>
                    </Row>
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() => {
                        setAutoCompleteValue('');
                        setBillCustomerName('');
                        setBillCustomerNumber('');
                        setTakeoutType(undefined);
                        props.billBack(true, setMenus);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : takeoutType === 'Collect' ? (
              <div>
                <Row type="flex" justify="center">
                  <Col span={22} style={{ textAlign: 'center' }}>
                    <div>
                      <img style={{ width: 50, height: 50 }} src={takeAway} />
                      <br />
                      <span
                        style={{
                          color: 'white',
                          fontWeight: '700',
                          fontSize: '20px'
                        }}
                      >
                        Take Away
                      </span>
                    </div>
                  </Col>
                  <Col
                    className="create-bill-section"
                    style={{ textAlign: 'center', overflow: 'hidden' }}
                    span={22}
                  >
                    <StyledInput
                      maxLength={15}
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        padding: '20px',
                        margin: '10px 0 10px',
                        border: '1px solid #00CC70'
                      }}
                      onChange={e => setBillDesc(e.target.value)}
                      placeholder="Description"
                      value={state.billDesc}
                    />
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() =>
                        props.addNewBill(
                          takeoutType,
                          null,
                          null,
                          null,
                          setMenus,
                          itemMenuStates
                        )
                      }
                    >
                      Create Bill
                    </Button>
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() => {
                        setAutoCompleteValue('');
                        setBillCustomerName('');
                        setBillCustomerNumber('');
                        setTakeoutType(undefined);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <Row type="flex" justify="center">
                  <Col span={22} style={{ textAlign: 'center' }}>
                    <div>
                      <img style={{ width: 50, height: 50 }} src={takeAway} />
                      <br />
                      <span
                        style={{
                          color: 'white',
                          fontWeight: '700',
                          fontSize: '20px'
                        }}
                      >
                        Take Away
                      </span>
                    </div>
                  </Col>
                  <Col
                    className="create-bill-section"
                    style={{ textAlign: 'center', overflow: 'hidden' }}
                    span={22}
                  >
                    <PlacesAutocomplete
                      value={autoCompleteValue}
                      onChange={e =>
                        handleChangeAddressSearch(e, setAutoCompleteValue)
                      }
                      onSelect={e =>
                        handleChangeAddressSelect(e, setAutoCompleteValue)
                      }
                      searchOptions={{
                        componentRestrictions: { country: 'za' },
                        types: ['address']
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => {
                        return (
                          <div
                            style={{
                              width: '100%'
                            }}
                          >
                            <StyledInput
                              value={autoCompleteValue}
                              style={{
                                display: 'inline-block',
                                width: '100%',
                                padding: '20px',
                                zIndex: 9,
                                margin: '10px 0 10px',
                                border: '1px solid #00CC70'
                              }}
                              placeholder="Enter street address"
                              {...getInputProps()}
                            />
                            <Select
                              style={{
                                opacity: 0,
                                width: '100%',
                                position: 'absolute',
                                margin: 'auto',
                                top: 10
                              }}
                              dropdownStyle={{ width: '100%', margin: 'auto' }}
                              showSearch={false}
                              open={suggestions.length > 0}
                            >
                              {suggestions.map(suggestion => {
                                return (
                                  <Select.Option
                                    {...getSuggestionItemProps(suggestion)}
                                    key={suggestion.id}
                                    value={suggestion.description}
                                  >
                                    {suggestion.description}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </div>
                        );
                      }}
                    </PlacesAutocomplete>
                    <StyledInput
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        padding: '20px',
                        margin: '10px 0 10px',
                        border: '1px solid #00CC70'
                      }}
                      onChange={e => setBillCustomerName(e.target.value)}
                      placeholder="Customer Name"
                      value={billCustomerName}
                    />
                    <StyledInput
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        padding: '20px',
                        margin: '10px 0 10px',
                        border: '1px solid #00CC70'
                      }}
                      onChange={e => setBillCustomerNumber(e.target.value)}
                      placeholder="Customer Number"
                      value={billCustomerNumber}
                    />
                    <StyledInput
                      maxLength={15}
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        padding: '20px',
                        margin: '10px 0 10px',
                        border: '1px solid #00CC70'
                      }}
                      onChange={e => setBillDesc(e.target.value)}
                      placeholder="Description"
                      value={state.billDesc}
                    />
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() =>
                        props.addNewBill(
                          takeoutType,
                          autoCompleteValue,
                          billCustomerNumber,
                          billCustomerName,
                          setMenus,
                          itemMenuStates
                        )
                      }
                    >
                      Create Bill
                    </Button>
                    <Button
                      className="bill-pay-btn"
                      style={{
                        backgroundColor: '#00CC70',
                        width: '50%',
                        borderRadius: '3em',
                        transform: 'scale(0.9)'
                      }}
                      onClick={() => {
                        setAutoCompleteValue('');
                        setBillCustomerName('');
                        setBillCustomerNumber('');
                        setTakeoutType(undefined);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            )
          ) : state.billType === 'Bar' ? (
            <div>
              <Row type="flex" justify="center">
                <Col span={22} style={{ textAlign: 'center' }}>
                  <div>
                    <img style={{ width: 50, height: 50 }} src={cocktail} />
                    <br />
                    <span
                      style={{
                        color: 'white',
                        fontWeight: '700',
                        fontSize: '20px'
                      }}
                    >
                      Bar
                    </span>
                  </div>
                </Col>
                <Col
                  className="create-bill-section"
                  style={{ textAlign: 'center', overflow: 'hidden' }}
                  span={22}
                >
                  <StyledInput
                    maxLength={15}
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      padding: '20px',
                      margin: '10px 0 10px',
                      border: '1px solid #00CC70'
                    }}
                    onChange={e => setBillDesc(e.target.value)}
                    placeholder="Description"
                    value={state.billDesc}
                  />
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.9)'
                    }}
                    onClick={() =>
                      props.addNewBill(
                        null,
                        null,
                        null,
                        null,
                        setMenus,
                        itemMenuStates
                      )
                    }
                  >
                    Create Bill
                  </Button>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.9)'
                    }}
                    onClick={() => props.billBack(true, setMenus)}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          ) : state.billType === 'Tab' ? (
            <div>
              <Row type="flex" justify="center">
                <Col span={5} />
                <Col
                  className="create-bill-section"
                  style={{ textAlign: 'center', overflow: 'hidden' }}
                  span={14}
                >
                  <Select
                    showSearch
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      margin: '10px 0 20px'
                    }}
                    onChange={(v, e) => setBillCustomer(v, e)}
                    placeholder="Select Customer"
                  >
                    {state.customers.map((cust, key) => (
                      <Option key={cust._id} value={cust._id}>
                        {cust.name}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.9)'
                    }}
                    onClick={() =>
                      props.addNewBill(
                        null,
                        null,
                        null,
                        null,
                        setMenus,
                        itemMenuStates
                      )
                    }
                  >
                    Create Bill
                  </Button>
                  <Button
                    className="bill-pay-btn"
                    style={{
                      backgroundColor: '#00CC70',
                      width: '50%',
                      borderRadius: '3em',
                      transform: 'scale(0.9)'
                    }}
                    onClick={() => props.billBack(true, setMenus)}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <div />
          )}
        </div>
      )}
    </div>
  );
};

const CookingItems = ({ collapsedBill, state, props, cookingItems }) => {
  var stateClass = 'ant-badge-status-dot , state-';
  var check = false;
  if (state.selectedCooking[cookingItems.key + 1]) {
    check = true;
  }
  let subString =
    cookingItems.items.name
      .toLowerCase()
      .substr(cookingItems.items.name.toLowerCase().length - 1, 1) !== 's'
      ? 's'
      : '';
  var name =
    cookingItems.items.details.limit > 1
      ? 'Please choose up to ' +
        cookingItems.items.details.limit +
        ' ' +
        cookingItems.items.name.toLowerCase() +
        subString
      : cookingItems.items.details.limit === 1
      ? 'Please select only one of the following ' +
        cookingItems.items.name.toLowerCase() +
        subString
      : cookingItems.items.details.limit === 0
      ? 'Please choose any of the following ' +
        cookingItems.items.name.toLowerCase() +
        subString
      : '';
  var cookingItemArray = [];
  let navArray = [];
  if (cookingItems.items && cookingItems.items.cookIns.length > 0) {
    for (var key = 0; key < cookingItems.items.cookIns.length; key++) {
      if (stockQtyList && cookingItems.items.cookIns[key].stockId) {
        var qty = 11;
        if (stockQtyList[cookingItems.items.cookIns[key].stockId]) {
          var stockitem = stockQtyList[cookingItems.items.cookIns[key].stockId];
          var qtyToUse = cookingItems.items.cookIns[key].qty;
          if (
            stockitem.dimension !== 'unit' &&
            stockitem.dimType &&
            cookingItems.items.cookIns[key].dimType
          ) {
            if (cookingItems.items.cookIns[key].dimType !== 'unit') {
              qtyToUse = convert(qtyToUse)
                .from(cookingItems.items.cookIns[key].dimType)
                .to(stockitem.dimType);
            }
          }
          if (stockitem.qty >= qtyToUse && qty > 0) {
            qty = stockitem.qty / qtyToUse;
          } else {
            qty = 0;
          }
        }
      } else {
        if (stockQtyList && cookingItems.items.cookIns[key].stockList) {
          var qty = 11;
          for (
            var si = 0;
            si < cookingItems.items.cookIns[key].stockList.length;
            si++
          ) {
            if (
              stockQtyList[
                cookingItems.items.cookIns[key].stockList[si].stockId
              ]
            ) {
              var stockitem =
                stockQtyList[
                  cookingItems.items.cookIns[key].stockList[si].stockId
                ];
              var qtyToUse = cookingItems.items.cookIns[key].stockList[si].qty;
              if (
                stockitem.dimension !== 'unit' &&
                stockitem.dimType &&
                cookingItems.items.cookIns[key].stockList[si].dimType
              ) {
                if (
                  cookingItems.items.cookIns[key].stockList[si].dimType !==
                  'unit'
                ) {
                  qtyToUse = convert(qtyToUse)
                    .from(cookingItems.items.cookIns[key].stockList[si].dimType)
                    .to(stockitem.dimType);
                }
              }
              if (stockitem.qty >= qtyToUse && qty > 0) {
                qty = stockitem.qty / qtyToUse;
              } else {
                qty = 0;
              }
            }
          }
        }
      }
      const currentIndex = key;
      cookingItemArray.push(
        <div
          style={{
            padding: 5,
            opacity: props.user.stockAlert && qty === 0 ? 0.5 : 1,
            pointerEvents: props.user.stockAlert && qty === 0 ? 'none' : 'all'
          }}
          onClick={() =>
            addToBillCooking(
              cookingItems.items.cookIns[currentIndex],
              currentIndex,
              props
            )
          }
          key={key}
        >
          <StyledCard
            className={
              cookingItems.items.cookIns[key].selected
                ? 'modifier-selected-dark'
                : 'modifier-dark'
            }
            title={
              cookingItems.items.cookIns[key].name +
              ' ' /*+ cookingItems.items.name */
            }
          >
            <span
              style={{ position: 'absolute', bottom: 29 }}
              className={'card-price-' + themeMode}
            >
              {cookingItems.items.cookIns[key].price === 0
                ? ' '
                : 'R ' + cookingItems.items.cookIns[key].price}
            </span>
            <Badge
              className={
                qty > 10
                  ? stateClass + colors[2]
                  : qty > 0
                  ? stateClass + colors[1]
                  : stateClass + colors[0]
              }
            />{' '}
            {/* <p className={"card-price-" + themeMode}>{cookingItems.items.name}</p> */}
            <p className={'card-price-' + themeMode}>Modifiers</p>
          </StyledCard>
        </div>
      );
    }
  }

  if (cookingItems.items) {
    navArray.push(
      <div
        style={{ padding: 5 }}
        onClick={() => cancelCooking()}
        key={'cooking-back'}
      >
        <StyledButtonRed
          className={'cancel-button-' + themeMode}
          type="primary"
          style={{ width: '100%' }}
        >
          <Icon
            style={{ fontSize: '1em', color: 'red', top: 15 }}
            type="close-circle"
          />
          Cancel
        </StyledButtonRed>
      </div>
    );
  }

  if (
    cookingItems.items.details.flow &&
    (cookingItems.items.details.limit > 1 ||
      cookingItems.items.details.limit === 0)
  ) {
    if (cookingItems.key !== 0) {
      navArray.push(
        <div
          style={{ padding: 5 }}
          onClick={() => cookingBack()}
          key={'cooking-back4'}
        >
          <StyledButton
            type="primary"
            style={{
              width: '100%',
              height: 45,
              fontSize: '1.3em',
              fontWeight: 600,
              borderRadius: '3em'
            }}
          >
            <Icon
              style={{ fontSize: '1em', color: 'white', top: 15 }}
              type="arrow-left"
            />
            Back
          </StyledButton>
        </div>
      );
    }
    if (
      cookingItems.items.details.flow &&
      check &&
      (cookingItems.items.details.limit > 1 ||
        cookingItems.items.details.limit === 0)
    ) {
      navArray.push(
        <div
          style={{ padding: 5 }}
          onClick={() => cookingNext()}
          key={'cooking-back'}
        >
          <StyledButton
            type="primary"
            style={{
              backgroundColor: 'transparent',
              border: '1px solid rgba(53, 205, 81, 0.833)',
              width: '100%',
              color: 'white',
              height: 45,
              fontSize: '1.3em',
              fontWeight: 600,
              borderRadius: '3em'
            }}
          >
            Next
            <Icon
              style={{
                fontSize: '1em',
                color: 'rgba(53, 205, 81, 0.833)',
                top: 15
              }}
              type="right-circle"
            />
          </StyledButton>
        </div>
      );
    }
    if (
      cookingItems.items.details.limit > 1 ||
      cookingItems.items.details.limit === 0
    ) {
      navArray.push(
        <div
          style={{ padding: 5 }}
          onClick={() => {
            state.selectedItem.qtycheck
              ? props.addToBillQty(state.selectedItem, setMenus)
              : props.addToBill(
                  state.selectedItem,
                  null,
                  null,
                  null,
                  setMenus,
                  itemMenuStates
                );
          }}
          key={'cooking-back2'}
        >
          <StyledButton
            type="primary"
            style={{
              width: '100%',
              color: 'white',
              backgroundColor: 'transparent',
              border: '1px solid #00CC70',
              height: 45,
              fontSize: '1.3em',
              fontWeight: 600,
              borderRadius: '3em'
            }}
          >
            Done
            <Icon
              style={{ fontSize: '1em', color: '#00CC70', top: 15 }}
              type="check-circle"
            />
          </StyledButton>
        </div>
      );
    }
  } else {
    if (cookingItems.items.details.limit !== 1 && cookingItems.key !== 0) {
      navArray.push(
        <div
          style={{ padding: 5 }}
          onClick={() => cookingBack()}
          key={'cooking-back'}
        >
          <Button
            type="primary"
            style={{
              width: '100%',
              height: 45,
              fontSize: '1.3em',
              fontWeight: 600,
              borderRadius: '3em'
            }}
          >
            <Icon
              style={{ fontSize: '1em', color: 'white', top: 15 }}
              type="arrow-left"
            />
            Back
          </Button>
        </div>
      );
    }
  }

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '500',
          color: 'white'
        }}
      >
        {name}
      </div>
      <div style={{ height: 'calc(100vh - 150px)', overflow: 'scroll' }}>
        <Row type="flex">
          {cookingItemArray.map((item, index) => (
            <Col
              key={index}
              span={calculateBlocks(cookingItemArray.length, collapsedBill)}
            >
              {item}
            </Col>
          ))}
        </Row>
      </div>

      <div
        style={{
          overflow: 'visible',
          //background: 'white',
          marginLeft: '0.7em',
          marginRight: '0.5em',
          borderRadius: '3em',
          bottom: 5
        }}
      >
        <Row type="flex" justify="space-between">
          {navArray.map((item, index) => (
            <Col
              key={index}
              span={calculateBlocks(cookingItemArray.length, collapsedBill)}
            >
              {item}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

const PhoneCookingItems = ({ collapsedBill, state, props, cookingItems }) => {
  var stateClass = 'ant-badge-status-dot , state-';
  var check = false;
  if (state.selectedCooking[cookingItems.key + 1]) {
    check = true;
  }
  let subString =
    cookingItems.items.name
      .toLowerCase()
      .substr(cookingItems.items.name.toLowerCase().length - 1, 1) !== 's'
      ? 's'
      : '';
  var name =
    cookingItems.items.details.limit > 1
      ? 'Please choose up to ' +
        cookingItems.items.details.limit +
        ' ' +
        cookingItems.items.name.toLowerCase() +
        subString
      : cookingItems.items.details.limit === 1
      ? 'Please select only one of the following ' +
        cookingItems.items.name.toLowerCase() +
        subString
      : cookingItems.items.details.limit === 0
      ? 'Please choose any of the following ' +
        cookingItems.items.name.toLowerCase() +
        subString
      : '';
  var cookingItemArray = [];
  let navArray = [];
  if (cookingItems.items && cookingItems.items.cookIns.length > 0) {
    var qty = 11;
    for (var key = 0; key < cookingItems.items.cookIns.length; key++) {
      if (stockQtyList && cookingItems.items.cookIns[key].stockId) {
        if (stockQtyList[cookingItems.items.cookIns[key].stockId]) {
          var stockitem = stockQtyList[cookingItems.items.cookIns[key].stockId];
          var qtyToUse = cookingItems.items.cookIns[key].qty;
          if (
            stockitem.dimension !== 'unit' &&
            stockitem.dimType &&
            cookingItems.items.cookIns[key].dimType
          ) {
            if (cookingItems.items.cookIns[key].dimType !== 'unit') {
              qtyToUse = convert(qtyToUse)
                .from(cookingItems.items.cookIns[key].dimType)
                .to(stockitem.dimType);
            }
          }
          if (stockitem.qty >= qtyToUse && qty > 0) {
            qty = stockitem.qty / qtyToUse;
          } else {
            qty = 0;
          }
        }
      } else {
        if (stockQtyList && cookingItems.items.cookIns[key].stockList) {
          var qty = 11;
          for (
            var si = 0;
            si < cookingItems.items.cookIns[key].stockList.length;
            si++
          ) {
            if (
              stockQtyList[
                cookingItems.items.cookIns[key].stockList[si].stockId
              ]
            ) {
              var stockitem =
                stockQtyList[
                  cookingItems.items.cookIns[key].stockList[si].stockId
                ];
              var qtyToUse = cookingItems.items.cookIns[key].stockList[si].qty;
              if (
                stockitem.dimension !== 'unit' &&
                stockitem.dimType &&
                cookingItems.items.cookIns[key].stockList[si].dimType
              ) {
                if (
                  cookingItems.items.cookIns[key].stockList[si].dimType !==
                  'unit'
                ) {
                  qtyToUse = convert(qtyToUse)
                    .from(cookingItems.items.cookIns[key].stockList[si].dimType)
                    .to(stockitem.dimType);
                }
              }
              if (stockitem.qty >= qtyToUse && qty > 0) {
                qty = stockitem.qty / qtyToUse;
              } else {
                qty = 0;
              }
            }
          }
        }
      }

      const currentIndex = key;
      cookingItemArray.push(
        <div
          style={{
            padding: 5,
            opacity: props.user.stockAlert && qty === 0 ? 0.5 : 1,
            pointerEvents: props.user.stockAlert && qty === 0 ? 'none' : 'all'
          }}
          onClick={() =>
            addToBillCooking(
              cookingItems.items.cookIns[currentIndex],
              currentIndex,
              props
            )
          }
          key={key}
        >
          <StyledCard
            className={
              cookingItems.items.cookIns[key].selected
                ? 'modifier-selected-dark'
                : 'modifier-dark'
            }
            title={
              cookingItems.items.cookIns[key].name +
              ' ' /*+ cookingItems.items.name */
            }
          >
            <span
              style={{ position: 'absolute', bottom: 29 }}
              className={'card-price-' + themeMode}
            >
              {cookingItems.items.cookIns[key].price === 0
                ? ' '
                : 'R ' + cookingItems.items.cookIns[key].price}
            </span>
            <Badge
              className={
                qty > 10
                  ? stateClass + colors[2]
                  : qty > 0
                  ? stateClass + colors[1]
                  : stateClass + colors[0]
              }
            />{' '}
            {/* <p className={"card-price-" + themeMode}>{cookingItems.items.name}</p> */}
            <p className={'card-price-' + themeMode}>Modifiers</p>
          </StyledCard>
        </div>
      );
    }
  }

  if (cookingItems.items) {
    navArray.push(
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          width: '100vw',
          left: 0,
          height: 0
        }}
      >
        <div
          onClick={() => cancelCooking()}
          key={'cooking-back'}
          style={{
            margin: 'auto',
            background: 'rgba(255, 0, 0, 0.815)',
            borderRadius: '50%',
            width: 50,
            height: 50,
            top: -20,
            left: -70,
            position: 'relative',
            display: 'flex',
            zIndex: 1001
          }}
        >
          <AiOutlineClose
            style={{
              margin: 'auto',
              color: 'white',
              fontSize: 25,
              zIndex: 1001
            }}
          />
        </div>
      </div>
    );
  }

  if (
    cookingItems.items.details.flow &&
    (cookingItems.items.details.limit > 1 ||
      cookingItems.items.details.limit === 0)
  ) {
    if (cookingItems.key !== 0) {
      navArray.push(
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            width: '100vw',
            left: 0,
            height: 0
          }}
        >
          <div
            onClick={() => cookingBack()}
            key={'cooking-back4'}
            style={{
              margin: 'auto',
              background: 'rgba(53, 205, 81, 0.833)',
              borderRadius: '50%',
              width: 50,
              height: 50,
              right: 70,
              bottom: 30,
              position: 'relative',
              display: 'flex',
              zIndex: 1001
            }}
          >
            <AiOutlineArrowLeft
              style={{
                margin: 'auto',
                color: 'white',
                fontSize: 25,
                zIndex: 1001
              }}
            />
            <span
              style={{
                color: 'white',
                position: 'absolute',
                top: 55,
                width: '100%',
                textAlign: 'center'
              }}
            >
              Back
            </span>
          </div>
        </div>
      );
    }
    if (
      cookingItems.items.details.flow &&
      check &&
      (cookingItems.items.details.limit > 1 ||
        cookingItems.items.details.limit === 0)
    ) {
      navArray.push(
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            width: '100vw',
            left: 0,
            height: 0
          }}
        >
          <div
            onClick={() => cookingNext()}
            key={'cooking-back4'}
            style={{
              margin: 'auto',
              background: 'rgba(53, 205, 81, 0.833)',
              borderRadius: '50%',
              width: 50,
              height: 50,
              left: 0,
              bottom: 60,
              position: 'relative',
              display: 'flex',
              zIndex: 1001
            }}
          >
            <AiOutlineArrowRight
              style={{
                margin: 'auto',
                color: 'white',
                fontSize: 25,
                zIndex: 1001
              }}
            />
            <span
              style={{
                color: 'white',
                position: 'absolute',
                top: 48,
                width: '100%',
                textAlign: 'center'
              }}
            >
              Next
            </span>
          </div>
        </div>
      );
    }
    if (
      cookingItems.items.details.limit > 1 ||
      cookingItems.items.details.limit === 0
    ) {
      navArray.push(
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            width: '100vw',
            left: 0,
            height: 0
          }}
        >
          <div
            onClick={() => {
              state.selectedItem.qtycheck
                ? props.addToBillQty(state.selectedItem, setMenus)
                : props.addToBill(
                    state.selectedItem,
                    null,
                    null,
                    null,
                    setMenus,
                    itemMenuStates
                  );
            }}
            key={'cooking-back2'}
            style={{
              margin: 'auto',
              background: 'rgba(53, 205, 81, 0.833)',
              borderRadius: '50%',
              width: 50,
              height: 50,
              top: -20,
              left: 70,
              position: 'relative',
              display: 'flex',
              zIndex: 1001
            }}
          >
            <AiOutlineCheck
              style={{
                margin: 'auto',
                color: 'white',
                fontSize: 25,
                zIndex: 1001
              }}
            />
          </div>
        </div>
      );
    }
  } else {
    if (cookingItems.items.details.limit !== 1 && cookingItems.key !== 0) {
      navArray.push(
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            width: '100vw',
            left: 0,
            height: 0
          }}
        >
          <div
            onClick={() => cookingBack()}
            key={'cooking-back4'}
            style={{
              margin: 'auto',
              background: 'rgba(53, 205, 81, 0.833)',
              borderRadius: '50%',
              width: 50,
              height: 50,
              right: 70,
              bottom: 30,
              position: 'relative',
              display: 'flex',
              zIndex: 1001
            }}
          >
            <AiOutlineArrowLeft
              style={{
                margin: 'auto',
                color: 'white',
                fontSize: 25,
                zIndex: 1001
              }}
            />
            <span
              style={{
                color: 'white',
                position: 'absolute',
                top: 48,
                width: '100%',
                textAlign: 'center'
              }}
            >
              Back
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '500',
          color: 'white'
        }}
      >
        {name}
      </div>
      <div
        style={{
          height:
            navArray.length > 2 ? 'calc(100vh - 350px)' : 'calc(100vh - 330px)',
          overflowY: 'scroll'
        }}
      >
        <Row type="flex">
          {cookingItemArray.map((item, index) => (
            <Col
              key={index}
              span={calculateBlocks(cookingItemArray.length, collapsedBill)}
            >
              {item}
            </Col>
          ))}
        </Row>
      </div>
      <div
        style={{
          width: '100vw',
          position: 'fixed',
          left: 0,
          bottom: 105,
          zIndex: 1001
        }}
      >
        {navArray.map((item, index) => item)}
      </div>
      {/* <div
        style={{
          overflow: 'visible',
          // background: 'white',
          marginLeft: '0.7em',
          marginRight: '0.5em',
          borderRadius: '3em',
          bottom: 5
        }}
      >
        <Row type="flex" justify="space-between">
          {navArray.map((item, index) => (
            <Col
              key={index}
              span={calculateBlocks(cookingItemArray.length, collapsedBill)}
            >
              {item}
            </Col>
          ))}
        </Row>
      </div> */}
    </div>
  );
};

const CookingList = ({ collapsedBill, state, props }) => {
  var items = state.selectedCooking;
  var cookingItems = state.cookingItems;
  var check = false;
  if (items) {
    if (items[0]) {
      if (items[0].details.flow) {
        check = true;
      }
    }
  }
  return (
    <div>
      {!cookingItems.items && items ? (
        <Row type="flex">
          <Col key={'back'} span={calculateBlocks(items.length, collapsedBill)}>
            <div
              onClick={() => {
                state.selectedItem.qtycheck
                  ? props.addToBillQty(state.selectedItem, setMenus)
                  : props.addToBill(
                      state.selectedItem,
                      null,
                      null,
                      null,
                      setMenus,
                      itemMenuStates
                    );
              }}
              key={'cooking-done'}
            >
              <div
                style={{ height: calculateSize(items.length) }}
                className="card-animation"
              >
                <StyledCard
                  className={'starch-buttons-' + themeMode}
                  title={'Done'}
                />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {!cookingItems.items ? (
        items &&
        items.map((item, key) => (
          <div className="inline-all">
            {check && key > 0 ? null : (
              <div
                onClick={() => showCookingItems(key, items[key])}
                key={key}
                className="inline-all"
              >
                <Card className={'none'} title={item.name} />
              </div>
            )}
          </div>
        ))
      ) : window.isPhone ? (
        <div>
          <PhoneCookingItems
            collapsedBill={collapsedBill}
            state={state}
            props={props}
            cookingItems={cookingItems}
          />
        </div>
      ) : (
        <div>
          <CookingItems
            collapsedBill={collapsedBill}
            state={state}
            props={props}
            cookingItems={cookingItems}
          />
        </div>
      )}
    </div>
  );
};

const PhoneCookingList = ({ collapsedBill, state, props }) => {
  var items = state.selectedCooking;
  var cookingItems = state.cookingItems;
  var check = false;
  if (items) {
    if (items[0]) {
      if (items[0].details.flow) {
        check = true;
      }
    }
  }
  return (
    <div>
      {!cookingItems.items && items ? (
        <Row type="flex">
          <Col key={'back'} span={calculateBlocks(items.length, collapsedBill)}>
            <div
              onClick={() => {
                state.selectedItem.qtycheck
                  ? props.addToBillQty(state.selectedItem, setMenus)
                  : props.addToBill(
                      state.selectedItem,
                      null,
                      null,
                      null,
                      setMenus,
                      itemMenuStates
                    );
              }}
              key={'cooking-done'}
            >
              <div
                style={{ height: calculateSize(items.length) }}
                className="card-animation"
              >
                <StyledCard
                  className={'starch-buttons-' + themeMode}
                  title={'Done'}
                />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {!cookingItems.items ? (
        items &&
        items.map((item, key) => (
          <div className="inline-all">
            {check && key > 0 ? null : (
              <div
                onClick={() => showCookingItems(key, items[key])}
                key={key}
                className="inline-all"
              >
                <Card className={'none'} title={item.name} />
              </div>
            )}
          </div>
        ))
      ) : window.isPhone ? (
        <div>
          <PhoneCookingItems
            collapsedBill={collapsedBill}
            state={state}
            props={props}
            cookingItems={cookingItems}
          />
        </div>
      ) : (
        <div>
          <CookingItems
            collapsedBill={collapsedBill}
            state={state}
            props={props}
            cookingItems={cookingItems}
          />
        </div>
      )}
    </div>
  );
};

const MenuItemsSub = ({ collapsedBill, state, props, items, mkey }) => {
  var stateClass = 'ant-badge-status-dot , state-';
  var qty = [];
  if (items) {
    for (var i = 0; i < items.length; i++) {
      var check = 11;
      if (items[i].stockitems) {
        for (var j = 0; j < items[i].stockitems.length; j++) {
          if (items[i].stockitems[j].active) {
            if (stockQtyList[items[i].stockitems[j]._id]) {
              var stockitem = stockQtyList[items[i].stockitems[j]._id];
              var qtyToUse = items[i].stockitems[j].qty;
              if (
                stockitem.dimension !== 'unit' &&
                stockitem.dimType &&
                items[i].stockitems[j].dimType
              ) {
                if (items[i].stockitems[j].dimType !== 'unit') {
                  qtyToUse = convert(qtyToUse)
                    .from(items[i].stockitems[j].dimType)
                    .to(stockitem.dimType);
                }
              }
              if (stockitem.qty >= qtyToUse && check > 0) {
                check = stockitem.qty / qtyToUse;
              } else {
                check = 0;
              }
            }
          }
        }
      }
      qty.push(check);
    }
  }
  return (
    <div>
      <Row type="flex">
        <Col key={'back'} span={calculateBlocks(items.length, collapsedBill)}>
          <div
            onClick={() => subCatBack()}
            key={'sub-back'}
            style={{ height: calculateSize(items.length), padding: 5 }}
          >
            <StyledCard
              className={'starch-buttons-' + themeMode}
              title={
                <p className={'card-price-' + themeMode}>
                  <Icon type="left-circle" /> Back
                </p>
              }
            ></StyledCard>
          </div>
        </Col>
        {items &&
          items.map((item, key) => (
            <Col key={key} span={calculateBlocks(items.length, collapsedBill)}>
              {item.subcat ? (
                <div
                  style={{ padding: 5 }}
                  onClick={() => showSubCatItems(item)}
                  key={key}
                >
                  <div
                    style={{ height: calculateSize(items.length) }}
                    className="card-animation"
                  >
                    <StyledCard
                      style={{
                        width: '100%',
                        height: 105,
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}
                      className="sales-card-dark"
                      title={item.name}
                    >
                      <Icon />
                      <Badge />
                      <p className={'card-price-' + themeMode}>Category</p>
                    </StyledCard>
                  </div>
                </div>
              ) : (
                <div
                  // style={{ padding: 5, opacity: props.user.stockAlert && qty[key] === 0 ? 0.5 : 1, pointerEvents: props.user.stockAlert && qty[key] === 0 ? "none" : "all" }}
                  style={
                    timeoutClick
                      ? {
                          pointerEvents: 'none',
                          padding: 5,
                          opacity:
                            props.user.stockAlert && qty[key] === 0 ? 0.5 : 1,
                          pointerEvents:
                            props.user.stockAlert && qty[key] === 0
                              ? 'none'
                              : 'all'
                        }
                      : {
                          padding: 5,
                          opacity:
                            props.user.stockAlert && qty[key] === 0 ? 0.5 : 1,
                          pointerEvents:
                            props.user.stockAlert && qty[key] === 0
                              ? 'none'
                              : 'all'
                        }
                  }
                  onClick={() => {
                    item.cooking
                      ? qty[key] > 0
                        ? cookingIns(item, mkey, key)
                        : props.user.stockAlert
                        ? props.noStock()
                        : cookingIns(item, mkey, key)
                      : qty[key] < 1
                      ? props.user.stockAlert
                        ? props.noStock()
                        : props.addToBill(
                            item,
                            false,
                            key,
                            null,
                            setMenus,
                            itemMenuStates
                          )
                      : props.addToBill(
                          item,
                          false,
                          key,
                          null,
                          setMenus,
                          itemMenuStates
                        );
                    setTimeoutClick(true);
                    setTimeout(() => {
                      setTimeoutClick(false);
                    }, 100);
                  }}
                  key={key}
                >
                  <div
                    style={{ height: calculateSize(items.length) }}
                    className="card-animation"
                  >
                    <StyledCard
                      className={item.color + ' sales-card-dark'}
                      title={item.name}
                    >
                      <Icon type="star" className="star" theme="filled" />
                      <Badge
                        className={
                          item.stockitems && item.stockitems.length > 0
                            ? qty[key] > 10
                              ? stateClass + colors[2]
                              : qty[key] > 0
                              ? stateClass + colors[1]
                              : stateClass + colors[0]
                            : stateClass + colors[2]
                        }
                      />
                      <p className={'card-price-' + themeMode}>
                        {item.sellPrice === '0.00'
                          ? ' '
                          : 'R ' + item.sellPrice}
                      </p>
                    </StyledCard>
                  </div>
                </div>
              )}
            </Col>
          ))}
      </Row>
    </div>
  );
};

const MenuItemsNumbers = ({ state, props }) => {
  var items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  if (state.selectedItem && state.selectedItem.useDim) {
    addOpenQtyModal(
      props,
      state.selectedItem,
      false,
      false,
      null,
      setMenus,
      itemMenuStates
    );
  }
  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '500',
          color: 'white'
        }}
      >
        {'How many would you like?'}
      </div>
      <div style={{ height: 'calc(100vh - 150px)', overflow: 'scroll' }}>
        {items &&
          items.map((item, key) => (
            <div className="inline-all" key={'NO' + key}>
              <div
                onClick={() =>
                  props.addToBill(
                    state.selectedItem,
                    false,
                    false,
                    item,
                    setMenus,
                    itemMenuStates
                  )
                }
                key={key}
                className="inline-all"
              >
                <div className="card-animation">
                  <Avatar
                    size={70}
                    style={{
                      width: '80px',
                      fontSize: '25px',
                      margin: '0.5em',
                      fontWeight: 700,
                      backgroundColor: 'transparent',
                      color: '#00CC70',
                      lineHeight: '5em',
                      border: '4px solid #00CC70',
                      paddingBottom: '2.8em'
                    }}
                  >
                    {item}
                  </Avatar>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div
        style={{
          overflow: 'visible',
          //background: 'white',
          marginLeft: '0.7em',
          marginRight: '0.5em',
          borderRadius: '3em',
          bottom: 5
        }}
      >
        <Row type="flex" justify="space-between">
          <Col span={8}>
            <div
              style={{ padding: 5 }}
              onClick={() =>
                addOpenQtyModal(
                  props,
                  state.selectedItem,
                  false,
                  false,
                  null,
                  setMenus,
                  itemMenuStates
                )
              }
              key={'cooking-back'}
            >
              <StyledButtonGreen
                className={'open-qty-button-' + themeMode}
                type="primary"
                style={{ width: '100%' }}
              >
                <Icon
                  style={{ fontSize: '1em', color: '#00CC70', top: 15 }}
                  type="plus-circle"
                />
                Open Qty
              </StyledButtonGreen>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{ padding: 5 }}
              onClick={() => addOpenInstModal(props, null)}
              key={'cooking-back'}
            >
              <StyledButtonGreen
                className={'open-qty-button-' + themeMode}
                type="primary"
                style={{ width: '100%' }}
              >
                <Icon
                  style={{ fontSize: '1em', color: '#00CC70', top: 15 }}
                  type="plus-circle"
                />
                Open Instruction
              </StyledButtonGreen>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{ padding: 5 }}
              onClick={() => cancelCooking()}
              key={'cooking-back'}
            >
              <StyledButtonRed
                className={'cancel-button-' + themeMode}
                type="primary"
                style={{ width: '100%' }}
              >
                <Icon
                  style={{ fontSize: '1em', color: 'red', top: 15 }}
                  type="close-circle"
                />
                Cancel
              </StyledButtonRed>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const PhoneMenuItemsNumbers = ({ state, props }) => {
  var items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '500',
          color: 'white'
        }}
      >
        {'How many would you like?'}
      </div>
      <Row type="flex" justify="space-around">
        {items &&
          items.map((item, key) => (
            <div className="inline-all" key={'NO' + key}>
              <div
                onClick={() =>
                  props.addToBill(
                    state.selectedItem,
                    false,
                    false,
                    item,
                    setMenus,
                    itemMenuStates
                  )
                }
                key={key}
                className="inline-all"
              >
                <div className="card-animation">
                  <div
                    style={{
                      width: 70,
                      height: 70,
                      margin: '0.5em',
                      display: 'flex',
                      backgroundColor: 'transparent',
                      fontWeight: 700,
                      color: '#00CC70',
                      borderRadius: '50%',
                      border: '4px solid #00CC70'
                    }}
                  >
                    <span style={{ margin: 'auto', fontSize: 20 }}>{item}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Row>

      {/* <Row style={{
        position: "fixed",
        width: "100%",
        bottom: 80
      }} type="flex" justify="space-between">


        <div
          style={{ padding: 5, margin: "auto" }}
          onClick={() => cancelCooking()}
          key={'cooking-back'}
        >
          <StyledButtonRed
            type="primary"
            style={{
              backgroundColor: "transparent",
              border: "1px solid red",
              color: "white",
              width: '100%',
              height: 45,
              fontSize: '1.3em',
              fontWeight: 600,
              borderRadius: '3em'
            }}
          >
            <Icon
              style={{ fontSize: '1em', color: 'red', top: 15 }}
              type="close-circle"
            />
          Cancel
        </StyledButtonRed>
        </div>


      </Row> */}
      <div
        style={{
          width: '100vw',
          position: 'fixed',
          left: 0,
          bottom: 105,
          zIndex: 1001
        }}
      >
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            width: '100vw',
            left: 0,
            height: 0
          }}
        >
          <div
            onClick={() => cancelCooking()}
            key={'cooking-back'}
            style={{
              margin: 'auto',
              background: 'red',
              borderRadius: '50%',
              width: 70,
              height: 70,
              top: 8,
              // left:-7,
              position: 'relative',
              display: 'flex',
              zIndex: 1001
            }}
          >
            <AiOutlineClose
              style={{
                margin: 'auto',
                color: 'white',
                fontSize: 25,
                zIndex: 1001
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const calculateBlocks = (amount, collapsedBill) => {
  if (window.isPhone) {
    return 12;
  }
  if (
    (window.innerHeight / 150) * 2 > amount &&
    window.innerWidth < 1250 &&
    collapsedBill === ''
  ) {
    if (amount === 3) {
      return 8;
    } else {
      return 8;
    }
  } else if (
    (window.innerHeight / 110) * 2 > amount &&
    window.innerWidth < 1250 &&
    collapsedBill === ''
  ) {
    return 8;
  } else {
    if (collapsedBill !== '') {
      if (window.innerWidth > 1250) {
        return 4;
      } else {
        return 6;
      }
    } else {
      if (window.innerWidth > 1250) {
        return 6;
      } else {
        return 8;
      }
    }
  }
};
const calculateSize = amount => {
  return 105;
};
const MenuItems = ({
  collapsedBill,
  state,
  props,
  items,
  mkey,
  cooking,
  name,
  special
}) => {
  var stateClass = 'ant-badge-status-dot , state-';
  var qty = [];
  if (items) {
    for (var i = 0; i < items.length; i++) {
      var check = 11;
      if (items[i].stockitems) {
        for (var j = 0; j < items[i].stockitems.length; j++) {
          if (items[i].stockitems[j].active) {
            if (stockQtyList[items[i].stockitems[j]._id]) {
              var stockitem = stockQtyList[items[i].stockitems[j]._id];
              var qtyToUse = items[i].stockitems[j].qty;
              if (
                stockitem.dimension !== 'unit' &&
                stockitem.dimType &&
                items[i].stockitems[j].dimType
              ) {
                if (items[i].stockitems[j].dimType !== 'unit') {
                  qtyToUse = convert(qtyToUse)
                    .from(items[i].stockitems[j].dimType)
                    .to(stockitem.dimType);
                }
              }
              if (stockitem.qty >= qtyToUse && check > 0) {
                check = stockitem.qty / qtyToUse;
              } else {
                check = 0;
              }
            }
          }
        }
      }
      qty.push(check);
    }
  }
  return (
    <div>
      {' '}
      {(!cooking && !state.subCatItem && !state.showQtyNumber) ||
      createBillState ? (
        <div>
          <div
            style={createBillState ? { display: 'block' } : { display: 'none' }}
          >
            <CreateBill state={state} props={props} />
          </div>
          <Row
            type="flex"
            style={createBillState ? { display: 'none' } : { display: 'block' }}
          >
            {items &&
              items.map((item, key) => (
                <Col
                  key={key}
                  span={calculateBlocks(items.length, collapsedBill)}
                >
                  {item.subcat ? (
                    <div
                      style={{ padding: 5 }}
                      onClick={() => showSubCatItems(item)}
                      key={key}
                    >
                      <div
                        style={{ height: calculateSize(items.length) }}
                        className="card-animation"
                      >
                        <StyledCard
                          style={{}}
                          className="sales-card-dark"
                          title={
                            <span style={{ fontSize: '1.1em' }}>
                              {item.name}
                            </span>
                          }
                        >
                          <Badge />
                          <p className={'card-price-' + themeMode}>
                            Category{' '}
                            <Icon
                              theme="filled"
                              className={'card-category-arrow-' + themeMode}
                              type="tags"
                            />{' '}
                          </p>{' '}
                        </StyledCard>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={
                        timeoutClick
                          ? {
                              pointerEvents: 'none',
                              padding: 5,
                              opacity:
                                props.user.stockAlert && qty[key] === 0
                                  ? 0.5
                                  : 1,
                              pointerEvents:
                                props.user.stockAlert && qty[key] === 0
                                  ? 'none'
                                  : 'all'
                            }
                          : {
                              padding: 5,
                              opacity:
                                props.user.stockAlert && qty[key] === 0
                                  ? 0.5
                                  : 1,
                              pointerEvents:
                                props.user.stockAlert && qty[key] === 0
                                  ? 'none'
                                  : 'all'
                            }
                      }
                      onClick={() => {
                        item.cooking
                          ? qty[key] > 0
                            ? cookingIns(item, mkey, key)
                            : props.user.stockAlert
                            ? props.noStock()
                            : cookingIns(item, mkey, key)
                          : qty[key] < 1
                          ? props.user.stockAlert
                            ? props.noStock()
                            : props.addToBill(
                                item,
                                false,
                                key,
                                null,
                                setMenus,
                                itemMenuStates
                              )
                          : props.addToBill(
                              item,
                              false,
                              key,
                              null,
                              setMenus,
                              itemMenuStates
                            );
                        setTimeoutClick(true);
                        setTimeout(() => {
                          setTimeoutClick(false);
                        }, 100);
                      }}
                      key={key}
                    >
                      <div
                        style={{ height: calculateSize(items.length) }}
                        className="card-animation"
                      >
                        <StyledCard
                          className={item.color + ' sales-card-dark'}
                          title={
                            <span style={{ fontSize: '1.1em' }}>
                              {item.name}
                            </span>
                          }
                        >
                          <span className={'card-price-' + themeMode}>
                            {item.sellPrice === '0.00'
                              ? ' '
                              : 'R ' + item.sellPrice}
                          </span>
                          <Badge
                            style={{ float: 'right', top: 3 }}
                            className={
                              item.stockitems && item.stockitems.length > 0
                                ? qty[key] > 10
                                  ? stateClass + colors[2]
                                  : qty[key] > 0
                                  ? stateClass + colors[1]
                                  : stateClass + colors[0]
                                : stateClass + colors[2]
                            }
                          />
                          {item.fav ? (
                            <Icon type="star" className="star" theme="filled" />
                          ) : null}
                        </StyledCard>
                      </div>
                    </div>
                  )}
                </Col>
              ))}
          </Row>
        </div>
      ) : (
        <div>
          {!createBillState && !state.subCatItem && !state.showQtyNumber ? (
            window.isPhone ? (
              <div>
                <PhoneCookingList
                  collapsedBill={collapsedBill}
                  state={state}
                  props={props}
                />
              </div>
            ) : (
              <div>
                <CookingList
                  collapsedBill={collapsedBill}
                  state={state}
                  props={props}
                />
              </div>
            )
          ) : (
            <div>
              {state.subCatItem ? (
                <MenuItemsSub
                  collapsedBill={collapsedBill}
                  state={state}
                  props={props}
                  items={state.subCatItems}
                  mkey={mkey}
                />
              ) : state.showQtyNumber ? (
                window.isPhone ? (
                  <div>
                    <PhoneMenuItemsNumbers state={state} props={props} />
                  </div>
                ) : (
                  <div>
                    <MenuItemsNumbers state={state} props={props} />
                  </div>
                )
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const PhoneMenuItems = ({
  collapsedBill,
  doDeliveryBill,
  addNewDeliveryBill,
  setBackFromTakeAwayType,
  backFromTakeAwayType,
  state,
  props,
  items,
  mkey,
  cooking,
  name,
  special
}) => {
  var stateClass = 'ant-badge-status-dot , state-';
  var qty = [];
  if (items) {
    for (var i = 0; i < items.length; i++) {
      var check = 11;
      if (items[i].stockitems) {
        for (var j = 0; j < items[i].stockitems.length; j++) {
          if (items[i].stockitems[j].active) {
            if (stockQtyList[items[i].stockitems[j]._id]) {
              var stockitem = stockQtyList[items[i].stockitems[j]._id];
              var qtyToUse = items[i].stockitems[j].qty;
              if (
                stockitem.dimension !== 'unit' &&
                stockitem.dimType &&
                items[i].stockitems[j].dimType
              ) {
                if (items[i].stockitems[j].dimType !== 'unit') {
                  qtyToUse = convert(qtyToUse)
                    .from(items[i].stockitems[j].dimType)
                    .to(stockitem.dimType);
                }
              }
              if (stockitem.qty >= qtyToUse && check > 0) {
                check = stockitem.qty / qtyToUse;
              } else {
                check = 0;
              }
            }
          }
        }
      }
      qty.push(check);
    }
  }
  return (
    <div>
      {' '}
      {!cooking &&
      !createBillState &&
      !state.subCatItem &&
      !state.showQtyNumber ? (
        <Row
          type="flex"
          style={{ maxHeight: 'calc(100vh - 180px)', overflowY: 'scroll' }}
        >
          {items &&
            items.map((item, key) => (
              <Col key={key} span={12}>
                {item.subcat ? (
                  <div
                    style={{ padding: 5 }}
                    onClick={() => showSubCatItems(item)}
                    key={key}
                  >
                    <div
                      style={{ height: calculateSize(items.length) }}
                      className="card-animation"
                    >
                      <StyledCard
                        className={item.color + ' sales-card-dark'}
                        title={
                          <span style={{ fontSize: '1.1em' }}>{item.name}</span>
                        }
                      >
                        <Badge />
                        <p className={'card-price-' + themeMode}>
                          Category{' '}
                          <Icon
                            theme="filled"
                            className={'card-category-arrow-' + themeMode}
                            type="tags"
                          />{' '}
                        </p>{' '}
                      </StyledCard>
                    </div>
                  </div>
                ) : (
                  <div
                    style={
                      timeoutClick
                        ? {
                            pointerEvents: 'none',
                            padding: 5,
                            opacity:
                              props.user.stockAlert && qty[key] === 0 ? 0.5 : 1,
                            pointerEvents:
                              props.user.stockAlert && qty[key] === 0
                                ? 'none'
                                : 'all'
                          }
                        : {
                            padding: 5,
                            opacity:
                              props.user.stockAlert && qty[key] === 0 ? 0.5 : 1,
                            pointerEvents:
                              props.user.stockAlert && qty[key] === 0
                                ? 'none'
                                : 'all'
                          }
                    }
                    onClick={() => {
                      item.cooking
                        ? qty[key] > 0
                          ? cookingIns(item, mkey, key)
                          : props.user.stockAlert
                          ? props.noStock()
                          : cookingIns(item, mkey, key)
                        : qty[key] < 1
                        ? props.user.stockAlert
                          ? props.noStock()
                          : props.addToBill(
                              item,
                              false,
                              key,
                              null,
                              setMenus,
                              itemMenuStates
                            )
                        : props.addToBill(
                            item,
                            false,
                            key,
                            null,
                            setMenus,
                            itemMenuStates
                          );
                      setTimeoutClick(true);
                      setTimeout(() => {
                        setTimeoutClick(false);
                      }, 100);
                    }}
                    key={key}
                  >
                    <div
                      style={{ height: calculateSize(items.length) }}
                      className="card-animation"
                    >
                      <StyledCard
                        className={item.color + ' sales-card-dark'}
                        title={
                          <span style={{ fontSize: '1.1em' }}>{item.name}</span>
                        }
                      >
                        <span className={'card-price-' + themeMode}>
                          {item.sellPrice === '0.00'
                            ? ' '
                            : 'R ' + item.sellPrice}
                        </span>
                        <Badge
                          style={{ float: 'right', top: 3 }}
                          className={
                            item.stockitems && item.stockitems.length > 0
                              ? qty[key] > 10
                                ? stateClass + colors[2]
                                : qty[key] > 0
                                ? stateClass + colors[1]
                                : stateClass + colors[0]
                              : stateClass + colors[2]
                          }
                        />
                        {item.fav ? (
                          <Icon type="star" className="star" theme="filled" />
                        ) : null}
                      </StyledCard>
                    </div>
                  </div>
                )}
              </Col>
            ))}
        </Row>
      ) : (
        <div>
          {!createBillState && !state.subCatItem && !state.showQtyNumber ? (
            window.isPhone ? (
              <div>
                <PhoneCookingList
                  collapsedBill={collapsedBill}
                  state={state}
                  props={props}
                />
              </div>
            ) : (
              <div>
                <CookingList
                  collapsedBill={collapsedBill}
                  state={state}
                  props={props}
                />
              </div>
            )
          ) : (
            <div>
              {createBillState ? (
                <PhoneCreateBill
                  doDeliveryBill={doDeliveryBill}
                  addNewDeliveryBill={addNewDeliveryBill}
                  setBackFromTakeAwayType={setBackFromTakeAwayType}
                  backFromTakeAwayType={backFromTakeAwayType}
                  state={state}
                  props={props}
                />
              ) : state.subCatItem ? (
                // <div>
                <MenuItemsSub
                  collapsedBill={collapsedBill}
                  state={state}
                  props={props}
                  items={state.subCatItems}
                  mkey={mkey}
                />
              ) : // </div>
              state.showQtyNumber ? (
                window.isPhone ? (
                  <div>
                    <PhoneMenuItemsNumbers state={state} props={props} />
                  </div>
                ) : (
                  <div>
                    <MenuItemsNumbers state={state} props={props} />
                  </div>
                )
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
let refresh = false;
let setRefresh;

const refreshView = () => {
  if (!refresh) {
    setRefresh(true);
    return;
  }
  if (refresh) {
    setRefresh(false);
    if (document.getElementsByClassName('ant-tabs')[0]) {
      document.getElementsByClassName('ant-tabs')[0].style.height = '100%';
      setTimeout(() => {
        document.getElementsByClassName('ant-tabs')[0].style.height =
          'calc(100vh - 48px)';
      }, 50);
    }
  }
};

let setMenus;
let itemMenuStates;
let createBillState;
let billCount;
let stockQtyList = {};
let themeMode;
const Menus = allprops => {
  let {
    state,
    props,
    collapsedBill,
    setBackFromTakeAwayType,
    backFromTakeAwayType,
    addNewDeliveryBill,
    doDeliveryBill,
    bill,
    searchMenu,
    createBillBool,
    stateBillCount,
    billProps
  } = allprops;
  stockQtyList = allprops.stockQty;
  billCount = stateBillCount;
  createBillState = createBillBool;
  [refresh, setRefresh] = useState(refresh);
  [timeoutClick, setTimeoutClick] = useState(timeoutClick);
  [itemMenuStates, setMenus] = useState(state);
  const [currentMenu, setCurrentMenu] = useState(0);
  themeMode = allprops.themeMode;
  useEffect(() => {
    setMenus(prevState => ({
      ...prevState,
      menu: allprops.menu,
      searchMenu: allprops.searchMenu,
      cooking: allprops.cooking,
      subCategories: allprops.subCategories,
      customers: allprops.customers,
      subCatItems: [],
      subCatItem: false,
      selectedItem: {},
      selectedCooking: [],
      cookingItems: {},
      showQtyNumber: false,
      initCooking: false
    }));
  }, [searchMenu]);
  useEffect(() => {
    setRefresh(false);
    setTimeoutClick(false);
    window.addEventListener('resize', refreshView);
    return () => {
      window.removeEventListener('resize', refreshView);
      if (newInstModal) {
        newInstModal.destroy();
        newInstModal = undefined;
      }
      if (newQtyModal) {
        newQtyModal.destroy();
        newQtyModal = undefined;
      }
    };
  }, []);
  useEffect(() => {
    if (props.user.direct && billProps && !Object.keys(billProps).length) {
      props.billType('direct sale', setMenus, itemMenuStates);
    }
  }, [JSON.stringify(billProps)]);
  useEffect(() => {
    setMenus(prevState => ({
      ...prevState,
      subCatItems: [],
      subCatItem: false,
      selectedItem: {},
      selectedCooking: [],
      cookingItems: {},
      showQtyNumber: false,
      initCooking: false
    }));
  }, [createBillBool]);
  return !window.isPhone ? (
    <StyledTabs
      tabBarStyle={
        createBillBool
          ? {
              pointerEvents: 'none',
              display: 'none',
              opacity: 0
            }
          : searchMenu[0] && searchMenu[0].name === 'Search'
          ? { display: 'none' }
          : {}
      }
      menuButtons={searchMenu}
      tabPosition={'left'}
      onChange={() => menuClick()}
    >
      {searchMenu &&
        searchMenu.map((items, key) =>
          items.active ? (
            <TabPane
              forceRender={false}
              tab={
                <div
                  className={'menu-item-tab-' + themeMode}
                  style={{ padding: '13%' }}
                >
                  <h1 className={'menu-item-title-' + themeMode}>
                    <Avatar
                      alt=" "
                      className={'menu-item-icon-' + themeMode}
                      style={{}}
                    >
                      {' '}
                      <img
                        alt=" "
                        style={{
                          width: 25,
                          height: 25,
                          marginLeft: '0em'
                          //marginTop: '0.2em'
                        }}
                        src={items.icon}
                      />
                    </Avatar>{' '}
                    {items.name}
                  </h1>
                </div>
              }
              key={key}
            >
              <Content
                style={{
                  maxHeight: state.windowHeight + 130,
                  overflow: 'auto',
                  paddingBottom: '15em'
                }}
              >
                {' '}
                <MenuItems
                  collapsedBill={collapsedBill}
                  state={itemMenuStates}
                  props={props}
                  items={items.menuitems}
                  mkey={key}
                  cooking={itemMenuStates.initCooking}
                  name={items.name}
                  special={items.special}
                />
              </Content>
            </TabPane>
          ) : null
        )}
    </StyledTabs>
  ) : bill ? (
    <div style={{ height: 'calc(100vh - 100px)' }}>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      {!createBillState ? (
        <Row
          style={{
            background: 'rgba(207, 229, 255, 0.282)',
            height: 38,
            padding: 4
          }}
          type="flex"
          justify="space-between"
        >
          <span
            style={{
              fontWeight: '600',
              fontSize: 13,
              color: 'white',
              margin: 'auto 0'
            }}
          >
            {bill.orderno
              ? 'Order #' +
                bill.orderno.split('-')[0] +
                '-' +
                bill.orderno.split('-')[2]
              : 'N/A'}
          </span>
          <span
            style={{
              fontWeight: '600',
              fontSize: 13,
              color: 'white',
              margin: 'auto 0'
            }}
          >
            {bill.type}
          </span>
          <span
            style={{
              fontWeight: '600',
              fontSize: 13,
              color: 'white',
              margin: 'auto 0'
            }}
          >
            {bill.desc}
          </span>
          <span
            style={{
              fontWeight: '600',
              fontSize: 13,
              color: 'white',
              margin: 'auto 0'
            }}
          >
            {bill.table ? 'Table #' + bill.table : ''}
          </span>
          <span
            style={{
              color: 'white',
              fontSize: 13,
              marginTop: 2,
              margin: 'auto 0'
            }}
          >
            {bill.date
              ? bill.date.slice(0, 4) +
                '-' +
                bill.date.slice(4, 6) +
                '-' +
                bill.date.slice(6, 8) +
                ' ' +
                bill.date.slice(8, 10) +
                ':' +
                bill.date.slice(10, 12)
              : 'N/A'}
          </span>
        </Row>
      ) : null}
      {!createBillState ? (
        <StyledCategorySelect
          dropdownMenuStyle={{ maxHeight: '100%' }}
          size="large"
          onChange={e => {
            menuClick();
            setCurrentMenu(parseInt(e));
          }}
          value={currentMenu}
        >
          {searchMenu &&
            searchMenu.map((items, key) =>
              items.active ? (
                <Option
                  style={{
                    fontSize: '17px',
                    height: '2.5em',
                    paddingTop: '0.5em'
                  }}
                  value={key}
                  key={key}
                >
                  {items.name}
                </Option>
              ) : null
            )}
        </StyledCategorySelect>
      ) : null}
      <Content>
        <PhoneMenuItems
          doDeliveryBill={doDeliveryBill}
          addNewDeliveryBill={addNewDeliveryBill}
          setBackFromTakeAwayType={setBackFromTakeAwayType}
          backFromTakeAwayType={backFromTakeAwayType}
          collapsedBill={collapsedBill}
          state={itemMenuStates}
          props={props}
          items={searchMenu[currentMenu].menuitems}
          mkey={currentMenu}
          cooking={itemMenuStates.initCooking}
          name={searchMenu[currentMenu].name}
          special={searchMenu[currentMenu].special}
        />
      </Content>
    </div>
  ) : null;
};

const mapStateToProps = oState => ({
  snackbar: oState.snackbar,
  menu: oState.dashboard.menu || [],
  specials: oState.dashboard.specials || {},
  customers: oState.dashboard.customers || [],
  searchMenu: oState.dashboard.searchMenu || [],
  cooking: oState.dashboard.cooking || [],
  subCategories: oState.dashboard.subCategories || {},
  stockQty: oState.dashboard.stockQtyList || {},
  types: oState.dashboard.types || [],
  themeMode: oState.status.themeMode || 'dark'
});

const mapDispatchToProps = {};
const MemoMenus = memo(Menus);

export default connect(mapStateToProps, mapDispatchToProps)(Menus);
