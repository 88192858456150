import withStyles from '@material-ui/core/styles/withStyles';
import {
  Checkbox,
  Col,
  ConfigProvider,
  Empty,
  Icon,
  Input,
  Row,
  Select,
  Spin,
  Table
} from 'antd';
import convert from 'convert-units';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import FormDialog from 'src/components/Dialogs/FormMultiButton.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  addStockAdjust,
  getStockItems,
  handleEditStockAdjust
} from 'src/redux/actions/backend';
import {
  resetActionBreadCrumb,
  setBreadCrumbs
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import count from './count.svg';
import damage from './damage.svg';
import loss from './loss.svg';
import receive from './receive.svg';
const { Column } = Table;
const { Search } = Input;
const { Option } = Select;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    border: '1px solid grey',
    color: 'grey',

    borderRadius: '0.2em'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'white',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  form: {
    margin: 0
  }
});

const dimSelectTypesFormat = {
  l: 'L',
  kg: 'Kg',
  unit: 'Each'
};

const dimSelectTypes = {
  volume: [
    {
      name: 'ml',
      value: 'ml'
    },
    {
      name: 'L',
      value: 'l'
    }
  ],
  mass: [
    {
      name: 'g',
      value: 'g'
    },
    {
      name: 'Kg',
      value: 'kg'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: [
    {
      name: 'Each',
      value: 'unit'
    }
  ]
};

const selectReason = [
  {
    name: 'Receive Stock',
    value: 'Receive Stock'
  },
  {
    name: 'Stock Count',
    value: 'Stock Count'
  },
  {
    name: 'Loss',
    value: 'Loss'
  },
  {
    name: 'Damage',
    value: 'Damage'
  }
];
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}
class StockAdjust extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      name: '',
      type: '',
      desc: '',
      date: '',
      time: '',
      user: 'Admin',
      waiting: false,
      stockadjust: [],
      stockadjustview: [],
      main: false,
      switch: false,
      mainSub: false,
      stock: {},
      stockitems: [],
      oStockitems: {},
      types: [],
      dimtypes: [],
      loading: true,
      fdopen: false,
      alert: false,
      msg: [],
      head: '',
      itemFilters: [],
      activeFilter: undefined,
      activeFilterDiff: false,
      activeFilterQty: false,
      filteredInfo: {},
      pageSize: 25
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    // this.props.setBreadCrumbs(breadCrumbs)
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    // this.props.setBreadCrumbs(breadCrumbs)
  };

  componentDidUpdate() {
    // if (this.props.actionCrumb) {
    //   this[this.props.actionCrumb](false)
    //   this.props.resetActionBreadCrumb()
    //   this.handleRemoveBreadCrumb()
    // }
  }

  componentDidMount() {
    this.handleEditStockAdjust();
    this.getStockItems();
  }

  closeDialog = () => {
    this.setState({
      alert: false,
      fdopen: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Stock Adjust Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  handleEditStockAdjust = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .handleEditStockAdjust(token)
      .then(res => {
        this.handleRemoveBreadCrumb();
        this.setState({
          _id: '',
          name: '',
          type: '',
          desc: '',
          date: '',
          time: '',
          oStockitems: res.data.results.stockitems || {},
          stockadjust: res.data.results.oResultStock,
          stock: [],
          main: true,
          switch: false,
          loading: false,
          fdopen: false,
          activeFilter: undefined,
          activeFilterDiff: false,
          activeFilterQty: false,
          submitInProgress: false,
          pageSize: 25
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleCancelCheck = () => {
    this.handleRemoveBreadCrumb();
    var stock = this.state.oStockitems;
    var check = false;
    for (var prop in stock) {
      if (stock[prop].select) {
        check = true;
        break;
      }
    }
    if (check) {
      this.setState({ fdopen: true });
    } else {
      this.handleEditStockAdjust();
      this.getStockItems();
    }
  };

  getStockItems = msg => {
    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });
    this.props
      .getStockItems(token)
      .then(result => {
        var stockitems = {};
        var name = 'SA0000';
        for (let i = 0; i < result.data.results.stockitems.length; i++) {
          if (!result.data.results.stockitems[i].dimType) {
            result.data.results.stockitems[i].dimType = 'unit';
          }
          if (!result.data.results.stockitems[i].servingType) {
            result.data.results.stockitems[i].servingType = 'unit';
          }
          var dimTypes = dimSelectTypes[
            result.data.results.stockitems[i].dimension
          ]
            ? Object.assign(
                [],
                dimSelectTypes[result.data.results.stockitems[i].dimension]
              )
            : [];
          if (
            result.data.results.stockitems[i].serving > 1 &&
            result.data.results.stockitems[i].servingType
          ) {
            dimTypes.push({
              name:
                result.data.results.stockitems[i].serving +
                (dimSelectTypesFormat[
                  result.data.results.stockitems[i].servingType
                ] || result.data.results.stockitems[i].servingType),
              value: result.data.results.stockitems[i]._id
            });
          }
          if (
            result.data.results.stockitems[i].packSize > 1 &&
            result.data.results.stockitems[i].dimType
          ) {
            dimTypes.push({
              name:
                result.data.results.stockitems[i].packSize +
                (dimSelectTypesFormat[
                  result.data.results.stockitems[i].dimType
                ] || result.data.results.stockitems[i].dimType),
              value: 'packsize'
            });
            result.data.results.stockitems[i].packValue =
              result.data.results.stockitems[i].packSize +
              (dimSelectTypesFormat[
                result.data.results.stockitems[i].dimType
              ] || result.data.results.stockitems[i].dimType);
            result.data.results.stockitems[i].orgDimType =
              result.data.results.stockitems[i].dimType;
            result.data.results.stockitems[i].dimType = 'packsize';
          }
          result.data.results.stockitems[i].dimtypes = dimTypes;
          result.data.results.stockitems[i].stockQty =
            result.data.results.stockitems[i].qty;
          result.data.results.stockitems[i].index = i;
          stockitems[result.data.results.stockitems[i]._id] =
            result.data.results.stockitems[i];
        }

        let itemFiltersPrep = [];
        let types = result.data.results.types;
        for (var i = 0; i < types.length; i++) {
          if (itemFiltersPrep.indexOf(types[i].name) === -1) {
            itemFiltersPrep.push(types[i].name);
          }
        }
        let itemFilters = [];
        for (var i = 0; i < itemFiltersPrep.length; i++) {
          itemFilters.push({
            text: itemFiltersPrep[i],
            value: itemFiltersPrep[i]
          });
        }

        name =
          this.state.stockadjust.length > 0
            ? name.slice(
                0,
                -(this.state.stockadjust.length + 1).toString().length
              ) + (this.state.stockadjust.length + 1).toString()
            : 'SA0001';
        // this.handleAddBreadCrumb({ method: "handleCancelCheck", crumb: "Adjustment" })
        this.setState({
          stockitems: result.data.results.stockitems,
          oStockitems: stockitems,
          types: result.data.results.types,
          itemFilters: itemFilters,
          _id: '',
          name: name,
          user: 'Admin',
          waiting: false,
          type: '',
          desc: '',
          dimension: '',
          dimType: '',
          qty: '',
          stock: {},
          main: true,
          switch: false,
          activeFilter: true,
          activeFilterDiff: false,
          activeFilterQty: false,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  showAdjust = r => {
    // this.handleAddBreadCrumb({ method: "switch", crumb: "Adjustment" })
    this.setState({
      stockadjustview: r.stockitems,
      _id: r._id,
      name: r.name,
      type: r.type,
      desc: r.desc,
      date: r.date,
      time: r.time,
      user: r.user || 'Admin',
      waiting: r.waiting,
      main: false,
      switch: true,
      pageSize: false,
      pageSize: 25
    });
  };

  switch = () => {
    // this.handleRemoveBreadCrumb()
    this.setState({
      stockadjustview: [],
      _id: '',
      name: '',
      type: '',
      desc: '',
      date: '',
      time: '',
      user: 'Admin',
      waiting: false,
      main: false,
      switch: false,
      activeFilter: undefined,
      activeFilterQty: false,
      activeFilterDiff: false,
      pageSize: 25
    });
  };

  handleChange = event => {
    if (event.target.name === 'type') {
      this.setState({ type: event.target.value });
    }
    if (event.target.name === 'dimentiontype') {
      this.setState({ dimType: event.target.value });
    }
  };

  handleChangeReason = value => {
    this.setState({ type: value, main: false });
  };

  onChangeEvt = e => {
    if (e.target.id === 'qty') {
      this.setState({ qty: e.currentTarget.value });
    }
    if (e.target.id === 'description') {
      this.setState({ desc: e.currentTarget.value });
    }
  };

  addStockAudit = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true, submitInProgress: true });
    this.props
      .addStockAdjust(this.state, token)
      .then(res => {
        this.handleEditStockAdjust();
        this.getStockItems();
        this.props.openSnackbar('Stock Adjust Success');
      })
      .catch(err => {
        if (err) {
          this.onError(err);
        } else {
          this.setState({ loading: false });
        }
      });
  };

  tableAmountCheck = (record, e) => {
    if (!this.state.type) {
      this.props.openSnackbar('Select Reason');
      return;
    }
    var stock = this.state.oStockitems;
    stock[record._id].select = e.target.checked;
    this.setState({ oStockitems: stock });
  };

  tableAmount = (record, e) => {
    if (!this.state.type) {
      this.props.openSnackbar('Select Reason');
      return;
    }
    let { value } = e.target;
    var stock = this.state.oStockitems;
    if (value) {
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        stock[record._id].amount = value;
        if (this.state.type === 'Receive Stock') {
          stock[record._id].newamount =
            stock[record._id].stockQty + parseFloat(value);
        }
        if (this.state.type === 'Stock Count') {
          stock[record._id].newamount = parseFloat(value);
        }
        if (this.state.type === 'Loss' || this.state.type === 'Damage') {
          stock[record._id].newamount =
            stock[record._id].stockQty - parseFloat(value);
        }
        stock[record._id].select = true;
        this.setState({ oStockitems: stock });
      }
    } else {
      stock[record._id].amount = '';
      stock[record._id].newamount = '';
      stock[record._id].select = false;
      this.setState({ oStockitems: stock });
    }
  };

  handleSelectStockSizeDim = (eN, recordN) => {
    var e = eN;
    var record = recordN;
    let value = e;
    const newIds = this.state.stockitems.slice();
    newIds[record.index].orgDimType = !newIds[record.index].orgDimType
      ? newIds[record.index].dimType
      : newIds[record.index].orgDimType;
    if (value === 'packsize') {
      newIds[record.index].stockQty =
        newIds[record.index].qty / record.packSize;
    } else if (this.state.oStockitems[value]) {
      newIds[record.index].stockQty =
        newIds[record.index].qty / this.state.oStockitems[value].serving;
    } else if (
      newIds[record.index].dimension !== 'unit' &&
      newIds[record.index].orgDimType !== value &&
      newIds[record.index].qty > 0
    ) {
      var metricAmount = convert(newIds[record.index].qty)
        .from(newIds[record.index].orgDimType)
        .to(value);
      newIds[record.index].stockQty = metricAmount;
    } else {
      newIds[record.index].stockQty = newIds[record.index].qty;
    }
    newIds[record.index].amount = '';
    newIds[record.index].newamount = '';
    newIds[record.index].dimType = value;

    this.setState(prevState => ({
      ...prevState,
      stockitems: newIds
    }));
  };

  handleFormatListQty = () => {
    this.setState({
      activeFilterQty: true,
      activeFilter: true,
      activeFilterDiff: false
    });
  };
  handleFormatList = format => {
    this.setState({
      activeFilter: format,
      activeFilterQty: false,
      activeFilterDiff: false
    });
  };
  handleFormatListDiff = format => {
    this.setState({
      activeFilter: true,
      activeFilterQty: false,
      activeFilterDiff: format
    });
  };
  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnFilterProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <Select
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Filter ${dataIndex}`}
            value={selectedKeys ? selectedKeys[0] : ''}
            onChange={e => setSelectedKeys(e ? [e] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {this.state.types.map((items, key) => {
              return (
                <Option key={key} value={items.value || items._id}>
                  {items.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="sm"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="sm"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (!visible) {
        setTimeout(() => this.searchInput.rcSelect.setOpenState(false));
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  customizeRenderEmptyNoButton = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        description={false}
        imageStyle={{
          margin: '3em',
          height: 90
        }}
      />
    </div>
  );
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />
      <button
        style={{
          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',

          width: '10.5em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',

          borderRadius: '3em',
          zIndex: 99,
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => this.getStockItems()}
        color="primary"
      >
        Add Adjustment
      </button>
    </div>
  );
  handleSort = (pagination, filters, sorter) => {
    this.setState(prevState => ({
      ...prevState,
      filteredInfo: filters,
      sortedInfo: sorter
    }));
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };
  pressQtyEnter = (record, event) => {
    if (event.target.parentElement.parentElement.parentElement.nextSibling) {
      event.target.parentElement.parentElement.parentElement.nextSibling.childNodes[5].children[0].children[0].focus();
    }
  };
  render() {
    let filteredInfo = {};
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditStockItem: this.handleEditStockItem,
      handleDeleteStockItem: this.handleDeleteStockItem,
      handleRestoreStockItem: this.handleRestoreStockItem,
      onChangeEvt: this.onChangeEvt,
      handleChange: this.handleChange,
      submitForm: this.submitForm,
      handleOpenStock: this.handleOpenTable,
      onHelpMarker: this.onHelpMarker
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div>
        {this.state.type === 'Stock Count' ? (
          <Button
            style={{
              backgroundColor: '#00CC70',

              boxShadow: 'none',
              color: 'white',
              height: '100%',
              borderRadius: '3em'
            }}
            onClick={() => this.handleFormatListDiff(true)}
          >
            Difference
          </Button>
        ) : null}
        <Button
          style={{
            backgroundColor: '#00CC70',

            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatList(true)}
        >
          Active
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',

            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatList(false)}
        >
          Inactive
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',

            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatList(undefined)}
        >
          All
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',

            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListQty()}
        >
          Low Stock
        </Button>
      </div>
    );
    const EyeContentCount = (
      <div>
        <Button
          style={{
            backgroundColor: '#00CC70',

            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListDiff(true)}
        >
          Difference
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',

            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatList(undefined)}
        >
          All
        </Button>
      </div>
    );
    let stockitems = [];
    let stockitemsReport = [];
    if (this.state.stockadjustview.length > 0) {
      for (var i = 0; i < this.state.stockadjustview.length; i++) {
        if (this.state.activeFilterDiff && this.state.type === 'Stock Count') {
          var value =
            (this.state.stockadjustview[i].amount || 0) -
            (this.state.stockadjustview[i].qty || 0);
          if (
            value < 0 &&
            value !== 0 &&
            this.state.stockadjustview[i].amount
          ) {
            stockitemsReport.push(this.state.stockadjustview[i]);
          }
        } else if (this.state.activeFilter === undefined) {
          stockitemsReport.push(this.state.stockadjustview[i]);
        }
      }
    } else {
      for (var i = 0; i < this.state.stockitems.length; i++) {
        if (this.state.activeFilterDiff && this.state.type === 'Stock Count') {
          var value =
            (this.state.oStockitems[this.state.stockitems[i]._id].amount || 0) -
            (this.state.stockitems[i].qty || 0);
          if (
            value < 0 &&
            value !== 0 &&
            this.state.oStockitems[this.state.stockitems[i]._id].amount
          ) {
            stockitems.push(this.state.stockitems[i]);
          }
        } else if (this.state.activeFilterQty) {
          if (
            this.state.stockitems[i].active &&
            this.state.stockitems[i].qty <= this.state.stockitems[i].lowqty
          ) {
            stockitems.push(this.state.stockitems[i]);
          }
        } else if (this.state.activeFilter === true) {
          if (this.state.stockitems[i].active) {
            stockitems.push(this.state.stockitems[i]);
          }
        } else if (this.state.activeFilter === false) {
          if (!this.state.stockitems[i].active) {
            stockitems.push(this.state.stockitems[i]);
          }
        } else if (this.state.activeFilter === undefined) {
          stockitems.push(this.state.stockitems[i]);
        }
      }
    }
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.main ? (
          <div>
            {' '}
            {/* <Card> */}{' '}
            <Row>
              <h4
                style={{
                  marginTop: '0em',
                  paddingBottom: '0px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.63)',
                  fontWeight: '600'
                }}
                className={this.props.classes.cardTitleWhite}
              >
                Reason
              </h4>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              style={{ marginTop: '1em' }}
            >
              <Col style={{ padding: 20 }} span={12}>
                <div
                  onClick={e => this.handleChangeReason(selectReason[0].value)}
                  style={{
                    margin: 'auto',
                    width: 150,
                    height: 130,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 15px #E5E9F2',
                    borderRadius: 8
                  }}
                >
                  <h3
                    style={{ padding: 10, textAlign: 'center', fontSize: 16 }}
                  >
                    Receive Stock
                  </h3>
                  <div
                    style={{ display: 'flex', width: '100%', height: '50%' }}
                  >
                    <img style={{ margin: 'auto' }} src={receive} />
                  </div>
                </div>
              </Col>
              <Col style={{ padding: 20 }} span={12}>
                <div
                  onClick={e => this.handleChangeReason(selectReason[1].value)}
                  style={{
                    margin: 'auto',
                    width: 150,
                    height: 130,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 15px #E5E9F2',
                    borderRadius: 8
                  }}
                >
                  <h3
                    style={{ padding: 10, textAlign: 'center', fontSize: 16 }}
                  >
                    Stock Count
                  </h3>
                  <div
                    style={{ display: 'flex', width: '100%', height: '50%' }}
                  >
                    <img style={{ margin: 'auto' }} src={count} />
                  </div>
                </div>
              </Col>
              <Col style={{ padding: 20 }} span={12}>
                <div
                  onClick={e => this.handleChangeReason(selectReason[2].value)}
                  style={{
                    margin: 'auto',
                    width: 150,
                    height: 130,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 15px #E5E9F2',
                    borderRadius: 8
                  }}
                >
                  <h3
                    style={{ padding: 10, textAlign: 'center', fontSize: 16 }}
                  >
                    Loss
                  </h3>
                  <div
                    style={{ display: 'flex', width: '100%', height: '50%' }}
                  >
                    <img style={{ margin: 'auto' }} src={loss} />
                  </div>
                </div>
              </Col>
              <Col style={{ padding: 20 }} span={12}>
                <div
                  onClick={e => this.handleChangeReason(selectReason[3].value)}
                  style={{
                    margin: 'auto',
                    width: 150,
                    height: 130,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 15px #E5E9F2',
                    borderRadius: 8
                  }}
                >
                  <h3
                    style={{ padding: 10, textAlign: 'center', fontSize: 16 }}
                  >
                    Damage
                  </h3>
                  <div
                    style={{ display: 'flex', width: '100%', height: '50%' }}
                  >
                    <img style={{ margin: 'auto' }} src={damage} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            {' '}
            {/* <Card> */}{' '}
            <Row>
              <h4
                style={{
                  marginTop: '0em',
                  paddingBottom: '0px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.63)',
                  fontWeight: '600'
                }}
                className={this.props.classes.cardTitleWhite}
              >
                {this.state.type}
              </h4>
            </Row>
            <Row style={{ marginBottom: 50 }}>
              <ConfigProvider renderEmpty={this.customizeRenderEmptyNoButton}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={this.state.type ? stockitems : []}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: itemRender,
                    defaultCurrent: 1,
                    showLessItems: true
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (
                      this.state.type === 'Stock Count' &&
                      this.state.oStockitems[record._id].amount
                    ) {
                      var value =
                        (this.state.oStockitems[record._id].amount || 0) -
                        (record.stockQty || 0);
                      if (value === 0) {
                        return { style: { background: 'lightgreen' } };
                      } else if (value < 0) {
                        return { style: { background: 'lightcoral' } };
                      } else if (value > 0) {
                        return { style: { background: 'rgb(255, 249, 237)' } };
                      }
                    }
                    if (!record.active) {
                      if (!this.state.activeFilterQty) {
                        return {
                          style: {
                            background: 'rgba(255, 4, 4, 0.063)',
                            color: 'red'
                          }
                        };
                      }
                    }
                    if (this.state.activeFilterQty) {
                      if (record.active && record.qty <= record.lowqty) {
                        return { style: { background: 'rgb(255, 249, 237)' } };
                      }
                    }
                    if (record.qty <= record.lowqty) {
                      return {
                        style: { backgroundColor: 'rgb(255, 249, 237)' }
                      };
                    } else if (rowIndex % 2 == 0) {
                      return { style: { background: 'rgb(247, 247, 247)' } };
                    }
                  }}
                >
                  <Column
                    title="#"
                    render={(text, record) => (
                      <div>
                        <Checkbox
                          onChange={event =>
                            this.tableAmountCheck(record, event)
                          }
                          checked={
                            this.state.oStockitems[record._id].select || false
                          }
                        />
                      </div>
                    )}
                    key="select"
                  />
                  <Column
                    {...this.getColumnSearchProps('name')}
                    sorter={(a, b) => a.name.localeCompare(b.name)}
                    sortDirections={['descend', 'ascend']}
                    title="Item Name"
                    dataIndex="name"
                    key="name"
                  />

                  <Column
                    title="Unit Size"
                    align="center"
                    render={(text, record) => {
                      if (record.dimtypes) {
                        return (
                          <CustomSelect
                            id="unit"
                            noMargin={true}
                            formControlProps={{
                              fullWidth: true,
                              style: { margin: 0, padding: 0 }
                            }}
                            inputProps={{
                              onChange: e => {
                                e.stopPropagation();
                                this.handleSelectStockSizeDim(
                                  e.target.value,
                                  record
                                );
                              },
                              value: record.dimType
                            }}
                            menuItems={record.dimtypes}
                          />
                        );
                      }
                    }}
                  />
                  <Column
                    title={
                      this.state.type === 'Receive Stock'
                        ? 'Add Stock'
                        : this.state.type !== 'Stock Count'
                        ? 'Remove Stock'
                        : 'Counted Stock'
                    }
                    width="120px"
                    render={(text, record) => (
                      <div>
                        <Input
                          placeholder=""
                          value={this.state.oStockitems[record._id].amount}
                          onPressEnter={event =>
                            this.pressQtyEnter(record, event)
                          }
                          onChange={event => this.tableAmount(record, event)}
                          suffix={
                            record.dimType !== 'unit'
                              ? record.dimType === 'packsize'
                                ? '/ ' + record.packValue
                                : this.state.oStockitems[record.dimType]
                                ? '/ ' +
                                  this.state.oStockitems[record.dimType]
                                    .serving +
                                  this.state.oStockitems[record.dimType]
                                    .servingType
                                : '/ ' +
                                  (dimSelectTypesFormat[record.dimType] ||
                                    record.dimType)
                              : ''
                          }
                        />
                      </div>
                    )}
                    key="amount"
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>{' '}
            <Row
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                background: 'white',
                width: '100%',
                display: 'flex',
                height: 50
              }}
            >
              <Button
                style={{
                  margin: 'auto',
                  borderRadius: '3.5em',
                  border: '1px solid #00CC70',
                  width: '9em'
                }}
                onClick={() => this.addStockAudit()}
                color="primary"
              >
                Save
              </Button>
              <Button
                style={{
                  margin: 'auto',
                  color: '#00CC70',
                  border: '1px solid #00CC70',
                  backgroundColor: 'white',
                  borderRadius: '3.5em'
                }}
                onClick={() => this.handleCancelCheck()}
                color="primary"
              >
                Cancel
              </Button>
            </Row>
          </div>
        )}
        <FormDialog
          head="Stock Adjust Warning "
          msg="Changes has been found without Saving!"
          formElements={[]}
          open={this.state.fdopen}
          close={this.closeDialog}
          actionBtn={[
            { name: 'Exit', click: this.handleEditStockAdjust },
            { name: 'Save', click: this.addStockAudit }
          ]}
        />
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  stockitems: state.stockitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  getStockItems,
  handleEditStockAdjust,
  addStockAdjust,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(StockAdjust));
