import React, { memo } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const ButtonWrapper = ({ value, click }) => {
  return <Button style={{
    fontSize: '2em',
    padding: '0px',
    borderRadius: 0,
    width: '33.3%',
    background: 'rgb(17, 20, 26)',
    border: '3px solid rgb(23, 26, 32)',
    color: 'white'
  }} onClick={() => click(value)}>{value}</Button>;
};

export default memo(ButtonWrapper);
