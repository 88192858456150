import $ from 'jquery';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MemoryRouter } from 'react-router';
import { Progress } from 'antd';
import Admin from 'src/components/Layout/Admin/Admin';
import Customer from 'src/views/Customer/Customer';
import Driver from 'src/views/Driver/Driver';
// import Dashboard from 'src/views/Dashboard/Dashboard';
import Kitchen from 'src/views/Kitchen/Kitchen';
import Login from 'src/views/Login';
import LoginApp from 'src/views/LoginApp';
import MemoLoginUser from 'src/views/LoginUser';
import VerifyPurchaseOrder from 'src/views/VerifyPurchaseOrder';
import CustomerOrderDashboard from 'src/views/CustomerOrder/Dashboard';
const AutoLogin = props => {
  if (props.role === 'customer') {
    props.splash();
    return <Customer />;
  } else if ((props.role.isSupAdmin || props.role.isAdmin) && props.loggedIn) {
    props.splash();
    return <Admin Mode={props.mode} reduxStore={props.reduxStore} />;
  } else if (props.role.isDriver) {
    props.splash();
    return <Driver Mode={props.mode} />;
  }
};

const AppLayout = props => {
  const [state, setState] = useState();
  const [switchView, setSwitchView] = useState(false);
  const removeSplash = () => {
    $('#splash-screen')[0].style.opacity = '0';
    setTimeout(function() {
      $('#splash-screen')[0].style.display = 'none';
    }, 700);
  };

  const changeViewOrg = details => {
    setState(prevState => ({
      ...prevState,
      View: <MemoLoginUser Mode={details.Mode} />
    }));
  };

  const changeViewLoginCustomer = () => {
    setState(prevState => ({
      ...prevState,
      View: <Customer />
    }));
  };

  return (
    <div>
      {/* {props.initLoaderState.loading ?
        <div>
          <div style={{
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            background: ' black',
            zIndex: '99999',
            opacity: 0.6
          }}>
          </div>
          <Progress strokeColor={'#00CC70'} type="circle" percent={props.initLoaderState.percent} style={{
            position: 'absolute',
            zIndex: '999999',
            opacity: 1,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }} />
        </div>
        : null} */}
      <MemoryRouter>
        {props.loaded ? (
          <React.Suspense fallback={null}>
            {window.location.pathname.search('customerorder') > -1 ? (
              <CustomerOrderDashboard splash={removeSplash} />
            ) : window.location.pathname.search('verifypurchaseorder') > -1 ? (
              <VerifyPurchaseOrder splash={removeSplash} />
            ) : props.user.orgtoken ? (
              !props.user.token ? (
                <MemoLoginUser Mode={props.mode} />
              ) : !props.user.session ? (
                <MemoLoginUser Mode={props.mode} />
              ) : (props.user.role && props.user.role.isDriver) ||
                (props.loggedIn &&
                  props.user.role &&
                  (props.user.role.isSupAdmin ||
                    props.user.role.isAdmin ||
                    props.user.role === 'customer')) ? (
                <AutoLogin
                  loggedIn={props.loggedIn}
                  role={props.user.role}
                  mode={props.user.mode}
                  splash={removeSplash}
                  reduxStore={props.reduxStore}
                />
              ) : (
                <MemoLoginUser Mode={props.mode} />
              )
            ) : window.location.pathname.search('user') > -1 ? (
              props.user.deliveryLocations ? (
                <AutoLogin role={'customer'} splash={removeSplash} />
              ) : (
                <LoginApp changeViewLoginCustomer={changeViewLoginCustomer} />
              )
            ) : (
              <Login changeView={changeViewOrg} Mode={props.mode} />
            )}
            {window.location.pathname.search('user') === -1 &&
            window.location.pathname.search('verifypurchaseorder') === -1 ? (
              <div
                style={{
                  position: 'fixed',
                  bottom: '0',
                  right: '5px',
                  fontSize: '9px',
                  color: '#F4F5FA',
                  zIndex: 98
                }}
              >
                {process.env.REACT_APP_VERSION_CONTROL}
              </div>
            ) : null}
          </React.Suspense>
        ) : null}
      </MemoryRouter>
    </div>
  );
};

const mapStateToProps = state => ({
  loaded: state.rehydrated,
  loggedIn: state.dashboard.loggedIn,
  // initLoaderState: state.initdashloader,
  user: state.backend.user
});

export default connect(mapStateToProps)(AppLayout);
