import React from "react";
import DataSet from "@antv/data-set";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "react-loading-bar";
import Yoco1 from "src/static/yoco-1.png";
import "react-loading-bar/dist/index.css";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import {
  Card,
  Row,
  Col,
  Icon,
  Layout,
  Statistic,
  Popover,
  Button,
  List,
  ConfigProvider,
  Empty,
  Collapse,
  Progress,
  Radio,
  Tabs,
} from "antd";
import { Axis, Chart, Geom, Legend, Tooltip } from "bizcharts";
import { connect } from "react-redux";
import CardHeader from "src/components/Card/CardHeader.jsx";
import Page from "src/components/Layout/Page";
import { getReports, resetUser } from "src/redux/actions/backend";
import Wave4 from "src/static/wave4.png";
import Bubbles from "src/static/bubbles-pic.png";
import Bank from "src/static/banknotes.png";
import YocoIcon from "src/static/yoco-icon.png";
import YocoImg from "src/static/yoco-img.png";
import styled from "styled-components";
import CardFooter from "src/components/Card/CardFooter";
import ContentLoader from "react-content-loader";
import {
  setBreadCrumbs,
  resetActionBreadCrumb,
} from "src/redux/actions/breadcrumbs";
import { FaCoins, FaWallet, FaFire } from "react-icons/fa";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";
import { AiFillFund } from "react-icons/ai";

const dateFormat = "YYYY/MM/DD";
const dateFormatSearch = "YYYYMMDD";
const { Panel } = Collapse;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}
const StyledTabs = styled(Tabs)`
  overflow: visble !important;
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 15px;
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    margin: 7px 0px 0 0;
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-nav-scroll {
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .ant-tabs-bar {
    padding-top: 0.3em;
    border-bottom: 0px solid #e8e8e8;
    margin: 0 0px 10px 0 !important;
    outline: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

const StyledTabsMain = styled(Tabs)`
  overflow: visble !important;
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: blue !important;
    border: 2px solid rgba(0, 204, 112, 0.898);
    color: rgba(0, 204, 112, 0.898);
  }
  .ant-tabs-bar {
    border-bottom: 0px solid #e8e8e8;
    margin: 0 0px 10px 0 !important;
    outline: none;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-tab {
    width: auto !important;
    font-size: 16px !important;
    margin-left: 0.5em !important;
    margin-top: 0.5em !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    font-weight: 400 !important;
    color: rgb(19, 36, 64) !important;
    background: transparent !important;
    border: 0px solid #00cc70 !important;
  }
  .ant-tabs-tab-active {
    width: auto !important;
    border-radius: 0em !important;
    padding: 0px 20px !important;
    height: 45px;
    color: #00cc70 !important;
  }
`;

const StyledProgress = styled(Progress)`
  .ant-progress-success-bg,
  .ant-progress-bg {
    position: relative;
    background-color: #00cc70;
    border-radius: "none";
    -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  }
  .ant-progress-text {
    margin-top: -0.2em;
    color: rgb(74, 64, 64);
    font-size: 0.9em;
    margin-left:1.5em;
  
}
.ant-progress-inner {
 
  background-color: rgba(229, 233, 242, 0.5);

`;
const StyledButton = styled(Button)`
  .ant-btn:hover .ant-btn:focus .ant-btn:active .ant-btn.active {
    border-radius: 3em;
    color: white !important;
    background-color: #132440 !important;
    border-color: #132440;
    box-shadow: none;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    text-decoration: none;
    color: rgb(0, 204, 112) !important;
    background: #ffffff !important;
  }
`;
const StyledList = styled(List)`
  .ant-list-header,
  .ant-list-footer {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;
const styles = (theme) => ({
  cardTitleDarkgrey: {
    color: "black",
    margin: "0",
    fontSize: "32px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTileButton: {
    display: "grid",
  },
});

const scale = {
  value: {
    alias: "Amount",
    formatter: function (val) {
      return "R " + (val ? val.toFixed("2") : "0.00");
    },
  },
  day: {
    range: [0, 1],
  },
};

const scalePhone = {
  value: {
    alias: "Amount",
    formatter: function (val) {
      return "R " + (val ? val.toFixed("2") : "0.00");
    },
  },
  day: {
    range: [0, 1],
  },
};

const Skeleton = () => (
  <ContentLoader
    speed={0.5}
    width={"100%"}
    height={"80vh"}
    // viewBox="0 0 100% 80vh"
    backgroundColor="#f3f3f3"
    foregroundColor="#c0c0c0"
  >
    <rect x="5" y="0" rx="2" ry="2" width="265" height="120" />
    <rect x="280" y="0" rx="2" ry="2" width="265" height="120" />
    <rect x="565" y="0" rx="2" ry="2" width="265" height="120" />
    <rect x="850" y="0" rx="2" ry="2" width="265" height="120" />
    <rect x="5" y="130" rx="2" ry="2" width="1106" height="300" />
  </ContentLoader>
);
const customizeRenderEmptyNoButton = () => (
  <div style={{ textAlign: "center" }}>
    <Empty
      imageStyle={{
        marginBottom: "0.5em",
        height: 90,
      }}
    />
  </div>
);
const { Header } = Layout;

class AdminHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: {},
      salesT: [],
      itemT: [],
      selectedDate: "c",
      changeView: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getReports(
      moment().subtract(30, "d").format(dateFormatSearch),
      moment().format(dateFormatSearch),
      "c"
    );
  }

  handleAddBreadCrumb = (crumb) => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  getReports = (start, end, date) => {
    // this.setState({ loading: true });
    var that = this;
    const token = this.props.user.token;
    this.props.getReports(token, start, end).then(
      (result) => {
        if (!result.data.results.itemT) {
          this.setState({
            selectedDate: date,
            sales: result.data.results,
            salesT: [],
            itemT: [],
            itemB: [],
            items: [],
            loading: false,
          });
        } else {
          var total = 0;
          for (var i = 0; i < result.data.results.itemT.length; i++) {
            total = total + result.data.results.itemT[i].totalAmount;
          }
          for (var i = 0; i < result.data.results.itemT.length; i++) {
            result.data.results.itemT[i].percent =
              (100 / total) * result.data.results.itemT[i].totalAmount;
          }
          var total2 = 0;
          for (var i = 0; i < result.data.results.itemB.length; i++) {
            total2 = total2 + result.data.results.itemB[i].totalAmount;
          }
          for (var i = 0; i < result.data.results.itemB.length; i++) {
            result.data.results.itemB[i].percent =
              (100 / total2) * result.data.results.itemB[i].totalAmount;
          }
          this.setState({
            selectedDate: date,
            sales: result.data.results,
            salesT: result.data.results.report,
            itemT: result.data.results.itemT,
            itemB: result.data.results.itemB,
            items: result.data.results.items,
            loading: false,
          });
        }
      },
      function (error) {
        if (error.includes("Login Expired")) {
          that.props.resetUser();
        } else {
          // this.setState({
          //   sales: {},
          //   salesT: [],
          //   itemT: [],
          //   loading: false
          // });
          alert(error);
        }
      }
    );
  };

  changeView = () => {
    this.setState({
      changeView: !this.state.changeView,
    });
  };

  handleChangeSelect = (value) => {
    if (value) {
      if (value === "day") {
        this.getReports(
          moment().format(dateFormatSearch),
          moment().format(dateFormatSearch),
          "a"
        );
      } else if (value === "week") {
        this.getReports(
          moment().subtract(7, "d").format(dateFormatSearch),
          moment().format(dateFormatSearch),
          "b"
        );
      } else if (value === "month") {
        this.getReports(
          moment().subtract(30, "d").format(dateFormatSearch),
          moment().format(dateFormatSearch),
          "c"
        );
      }
    }
  };

  render() {
    const StyledStatistic = styled(Statistic)`
      .ant-statistic-title {
        position: relative;
        top: -10px;
        box-shadow: 0 6px 4px -2px rgb(220, 225, 238);
        margin-bottom: -0.7em;
        padding-bottom: ${window.isPhone ? "8px" : "1em"};
        border-bottom: 5px solid grey;
        color: black;
        font-weight: 600;
      }
    `;
    let dv = new DataSet.View().source(this.state.salesT);
    dv.transform({
      type: "fold",
      fields: ["Gross Sales", "Net Sales", "Profit"],
      key: "type",
      value: "value",
    });
    const chartTimes = (
      <div>
        <Button onClick={() => this.handleChangeSelect("day")}>Today</Button>
        <Button onClick={() => this.handleChangeSelect("week")}>7 Days</Button>
        <Button onClick={() => this.handleChangeSelect("month")}>
          30 Days
        </Button>
      </div>
    );
    return (
      <div>
        <Loading
          show={this.state.loading}
          showSpinner={false}
          color="rgb(15, 135, 123)"
        />
        {this.state.loading ? (
          <div style={{ position: "sticky", marginTop: "6em", left: 0 }}>
            <Skeleton />
          </div>
        ) : (
          <div>
            {/* <IconButton
              style={{
                marginTop: "-1em",
                marginBottom: "-0.8em",
                width: "2.05em",
                height: "2.05em",
                position: 'relative',
                boxShadow: "rgb(220,225,238) 0px 5px 10px 3px",
              }}
              onClick={() => this.changeView()}
            >
              {
                <Icon
                  type="eye"
                  style={{
                    position: "absolute",
                    boxShadow: "rgb(220,225,238)  0px 2px 3px 1px",
                    borderRadius: "3em",
                    padding: "0.3em",
                    backgroundColor: this.state.changeView === true ? "rgba(0, 204, 112, 0.898)" : "white",
                    color: this.state.changeView === true ? "white" : "rgba(0, 204, 112, 0.898)"
                  }}
                />
              }
            </IconButton> <span style={{ color: "black", fontWeight: 600, fontSize: "17px", paddingLeft: "0.3em", }}>Smart Switch</span> */}
            <StyledTabsMain defaultActiveKey="1" type="card">
              <TabPane
                tab={
                  <p style={{ fontFamily: "Mulish" }}>
                    <MdDashboard
                      style={{
                        fontSize: "20px",
                        marginBottom: "-0.2em",
                        marginRight: "0.4em",
                      }}
                    />
                    Dashboard
                  </p>
                }
                key="1"
              >
                <div
                  style={{
                    marginTop: 20,
                    background: "transparent",
                  }}
                >
                  <div>
                    <div className="stat-display-card">
                      {/* <Row style={window.isPhone ? { transform: "scale(1.1)" } : null}> */}
                      <Row>
                        <Col span={window.isPhone ? 12 : 6}>
                          <Card
                            onClick={
                              () => {
                                //if (!window.isPhone) {
                                this.props.history.push("/admin/reportsale");
                              }
                              //}
                            }
                            className="stat-display-card-today"
                            style={{
                              cursor: "pointer",
                              overflow: "hidden",
                              boxShadow: "0px 5px 10px 2px #E5E9F2",
                              borderRadius: "1.2em",
                              height: window.isPhone ? 72 : "120px",
                              width: "97%",
                              border: "0px solid lightgrey",
                              background: "white",
                              textAlign: window.isPhone ? "center" : "left",
                              marginBottom: 10,
                            }}
                            bodyStyle={
                              window.isPhone ? { padding: 12 } : { padding: 24 }
                            }
                          >
                            <StyledStatistic
                              className="stat-display-card-today-sales"
                              title={
                                !window.isPhone ? (
                                  <span style={{ fontSize: "20px" }}>
                                    Today's Sales &nbsp;&nbsp;
                                    <div
                                      style={{
                                        marginTop: "-0.1em",
                                        backgroundColor: "",
                                        float: "right",
                                        //paddingRight: "0.58em",
                                        //paddingTop: "0.4em",
                                        borderRadius: "3em",
                                        //paddingBottom: "0.3em",
                                        //paddingLeft: "0.58em"
                                      }}
                                    >
                                      <FaCoins
                                        className="stat-display-open-bills"
                                        style={{
                                          color: "#00CC70",
                                          fontSize: "25px",
                                        }}
                                      />
                                    </div>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      fontSize: 15,
                                      textAlign: "center",
                                    }}
                                  >
                                    Today's Sales
                                  </span>
                                )
                              }
                              value={
                                this.state.sales.today
                                  ? this.state.sales.today.toFixed("2")
                                  : "0.00"
                              }
                              valueStyle={{
                                fontFamily: "Montserrat,sans-serif!important",
                                color: "#4A4040",
                                position: "relative",
                                whiteSpace: "nowrap",
                                bottom: window.isPhone ? -5 : -10,
                                fontWeight: 600,
                                textAlign: window.isPhone ? "center" : "left",
                                fontSize: window.isPhone ? 18 : null,
                              }}
                              prefix="R"
                            />{" "}
                          </Card>
                        </Col>
                        <Col span={window.isPhone ? 12 : 6}>
                          <Card
                            className="stat-display-card-profit"
                            style={{
                              overflow: "hidden",
                              boxShadow: "0px 5px 10px 2px #E5E9F2",
                              borderRadius: "1.2em",
                              height: window.isPhone ? 72 : "120px",
                              width: "97%",
                              border: "0px solid lightgrey",
                              background: "white",
                              marginBottom: 10,
                              textAlign: window.isPhone ? "center" : "left",
                            }}
                            bodyStyle={
                              window.isPhone ? { padding: 12 } : { padding: 24 }
                            }
                          >
                            {" "}
                            <StyledStatistic
                              className="stat-display-card-profit"
                              title={
                                !window.isPhone ? (
                                  <span style={{ fontSize: "20px" }}>
                                    Total Profit &nbsp;&nbsp;
                                    <span style={{ fontSize: "15px" }}>
                                      {" "}
                                      {this.state.selectedDate === "a"
                                        ? "Today"
                                        : this.state.selectedDate === "b"
                                        ? "7 Days"
                                        : this.state.selectedDate === "c"
                                        ? "30 Days"
                                        : ""}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                      style={{
                                        marginTop: "-0.1em",
                                        backgroundColor: "",
                                        float: "right",
                                        //paddingRight: "0.58em",
                                        //paddingTop: "0.4em",
                                        borderRadius: "3em",
                                        //paddingBottom: "0.3em",
                                        //paddingLeft: "0.58em"
                                      }}
                                    >
                                      <FaWallet
                                        className="stat-display-open-bills"
                                        style={{
                                          color: "rgb(31, 169, 246)",
                                          fontSize: "25px",
                                        }}
                                      />
                                    </div>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      fontSize: 15,
                                      textAlign: "center",
                                    }}
                                  >
                                    Total Profit
                                  </span>
                                )
                              }
                              value={
                                this.state.sales.total
                                  ? (
                                      this.state.sales.total -
                                      (this.state.sales.cost || 0) -
                                      (this.state.sales.discount || 0)
                                    ).toFixed("2")
                                  : "0.00"
                              }
                              valueStyle={{
                                fontFamily: "Montserrat,sans-serif!important",
                                color: "#4A4040",
                                position: "relative",
                                whiteSpace: "nowrap",
                                bottom: window.isPhone ? -5 : -10,
                                fontWeight: 600,
                                textAlign: window.isPhone ? "center" : "left",
                                fontSize: window.isPhone ? 18 : null,
                              }}
                              prefix="R "
                            />{" "}
                          </Card>
                        </Col>
                        <Col span={window.isPhone ? 12 : 6}>
                          <Card
                            onClick={() => {
                              this.props.history.push("/admin/reportopenbills");
                            }}
                            className="stat-display-card-net"
                            style={{
                              cursor: "pointer",
                              boxShadow: "0px 5px 10px 2px #E5E9F2",
                              overflow: "hidden",
                              borderRadius: "1.2em",
                              height: window.isPhone ? 72 : "120px",
                              width: "97%",
                              border: "0px solid lightgrey",
                              background: "white",
                              textAlign: window.isPhone ? "center" : "left",
                            }}
                            bodyStyle={
                              window.isPhone ? { padding: 12 } : { padding: 24 }
                            }
                          >
                            <StyledStatistic
                              className="stat-display-card-open-bills"
                              title={
                                !window.isPhone ? (
                                  <span style={{ fontSize: "20px" }}>
                                    Open Sales
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div
                                      className="stat-display-open-bills"
                                      style={{
                                        marginTop: "-0.2em",
                                        backgroundColor: "",
                                        float: "right",
                                        fontWeight: 700,
                                        //paddingRight: "0.77em",
                                        //paddingTop: "0.2em",
                                        borderRadius: "3em",
                                        fontFamily: "Roboto",
                                        //paddingBottom: "0.4em",
                                        //paddingLeft: "0.77em"
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "grey",
                                          fontSize: "21px",
                                        }}
                                      >
                                        {this.state.sales.openBills > 0
                                          ? this.state.sales.openBills
                                          : "0"}
                                      </span>
                                    </div>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      fontSize: 15,
                                      textAlign: "center",
                                    }}
                                  >
                                    Open Sales
                                    <div
                                      style={{
                                        position: "absolute",
                                        backgroundColor: "#1D51A7",
                                        borderRadius: "3em",
                                        right: -2,
                                        top: 2,
                                        width: 23,
                                        height: 23,
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "white",
                                          //position: "absolute",
                                          //right: 6,
                                          //bottom: 0
                                        }}
                                      >
                                        {this.state.sales.openBills > 0
                                          ? this.state.sales.openBills
                                          : "0"}
                                      </span>
                                    </div>
                                  </span>
                                )
                              }
                              value={
                                this.state.sales.openBillsTotal
                                  ? this.state.sales.openBillsTotal.toFixed("2")
                                  : "0.00"
                              }
                              valueStyle={{
                                fontFamily: "Montserrat,sans-serif!important",
                                color: "#4A4040",
                                position: "relative",
                                whiteSpace: "nowrap",
                                bottom: window.isPhone ? -5 : -10,
                                fontWeight: 600,
                                textAlign: window.isPhone ? "center" : "left",
                                fontSize: window.isPhone ? 18 : null,
                              }}
                              prefix="R "
                            />{" "}
                          </Card>
                        </Col>
                        {!this.state.changeView ? (
                          <Col span={window.isPhone ? 12 : 6}>
                            <Card
                              onClick={() => {
                                if (!window.isPhone) {
                                  this.props.history.push("/admin/stockitem");
                                }
                              }}
                              className="stat-display-card-profit"
                              style={{
                                cursor: "pointer",
                                overflow: "hidden",
                                boxShadow: "0px 5px 10px 2px #E5E9F2",
                                borderRadius: "1.2em",
                                height: window.isPhone ? 72 : "120px",
                                width: "97%",
                                border: "0px solid lightgrey",
                                background: "white",
                                textAlign: window.isPhone ? "center" : "left",
                              }}
                              bodyStyle={
                                window.isPhone
                                  ? { padding: 12 }
                                  : { padding: 24 }
                              }
                            >
                              {" "}
                              <StyledStatistic
                                className="stat-display-card-stock"
                                title={
                                  !window.isPhone ? (
                                    <span style={{ fontSize: "20px" }}>
                                      Stock on Hand
                                      <div
                                        style={{
                                          marginTop: "-0.1em",
                                          backgroundColor: "",
                                          float: "right",
                                          //paddingRight: "0.58em",
                                          //paddingTop: "0.4em",
                                          borderRadius: "3em",
                                          //paddingBottom: "0.3em",
                                          //paddingLeft: "0.58em"
                                        }}
                                      >
                                        <BsGrid3X3GapFill
                                          className="stat-display-open-bills"
                                          style={{
                                            color: "#979797",
                                            fontSize: "25px",
                                          }}
                                        />
                                      </div>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontSize: 15,
                                        textAlign: "center",
                                      }}
                                    >
                                      Stock on Hand
                                    </span>
                                  )
                                }
                                value={
                                  this.state.sales.stockTotal
                                    ? this.state.sales.stockTotal.toFixed("2")
                                    : "0.00"
                                }
                                valueStyle={{
                                  fontFamily: "Montserrat,sans-serif!important",
                                  color:
                                    this.state.sales.stockTotal > -1
                                      ? "#4A4040"
                                      : "red",
                                  position: "relative",
                                  whiteSpace: "nowrap",
                                  bottom: window.isPhone ? -5 : -10,
                                  fontWeight: 600,
                                  textAlign: window.isPhone ? "center" : "left",
                                  fontSize: window.isPhone ? 18 : null,
                                }}
                                prefix="R "
                              />{" "}
                            </Card>
                          </Col>
                        ) : (
                          <Col span={window.isPhone ? 12 : 6}>
                            <Card
                              className="stat-display-icon-gross"
                              style={{
                                overflow: "hidden",
                                boxShadow: "0px 5px 10px 2px #E5E9F2",
                                borderRadius: "1.2em",
                                height: window.isPhone ? 72 : "120px",
                                width: "97%",
                                border: "0px solid lightgrey",
                                background: "white",
                                position: "absolute",
                                textAlign: window.isPhone ? "center" : "left",
                              }}
                              bodyStyle={
                                window.isPhone
                                  ? { padding: 12 }
                                  : { padding: 24 }
                              }
                            >
                              <StyledStatistic
                                className="stat-display-card-content"
                                value={
                                  this.state.sales.total
                                    ? this.state.sales.total.toFixed("2")
                                    : "0.00"
                                }
                                title={
                                  !window.isPhone ? (
                                    <span style={{ fontSize: "20px" }}>
                                      Gross Sales
                                      <div
                                        style={{
                                          marginTop: "-0.1em",
                                          backgroundColor: "",
                                          float: "right",
                                          //paddingRight: "0.58em",
                                          //paddingTop: "0.4em",
                                          borderRadius: "3em",
                                          //paddingBottom: "0.3em",
                                          //paddingLeft: "0.58em"
                                        }}
                                      >
                                        <FaCoins
                                          className="stat-display-open-bills"
                                          style={{
                                            color: "grey",
                                            fontSize: "25px",
                                          }}
                                        />
                                      </div>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontSize: 15,
                                        textAlign: "center",
                                      }}
                                    >
                                      Gross Sales
                                    </span>
                                  )
                                }
                                valueStyle={{
                                  fontFamily: "Montserrat,sans-serif!important",
                                  color: "#4A4040",
                                  position: "relative",
                                  whiteSpace: "nowrap",
                                  bottom: window.isPhone ? -5 : -10,
                                  fontWeight: 600,
                                  textAlign: window.isPhone ? "center" : "left",
                                  fontSize: window.isPhone ? 18 : null,
                                }}
                                prefix="R"
                              />{" "}
                              <CardFooter
                                className="stat-display-card-footer"
                                style={{
                                  marginTop: "10px",
                                  marginLeft: -25,
                                  width: "125%",
                                  height: "3em",
                                }}
                              />
                            </Card>
                          </Col>
                        )}
                      </Row>
                    </div>
                    {window.isPhone ? (
                      <Row style={{ top: 10, width: "98%" }}>
                        <Col span={24}>
                          <Collapse
                            style={{
                              background: "white",
                              borderRadius: 10,
                              border: 0,
                            }}
                            expandIconPosition={"right"}
                          >
                            <Panel
                              header={
                                <div
                                  style={{ textAlign: "center", width: "100%" }}
                                >
                                  <span
                                    style={{
                                      textAlign: "center",
                                      color: "black",
                                      fontSize: 16,
                                      padding: 0,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Top Selling Products
                                  </span>
                                </div>
                              }
                              key="1"
                            >
                              <Col span={24}>
                                <ConfigProvider
                                  renderEmpty={customizeRenderEmptyNoButton}
                                >
                                  <StyledList
                                    style={{
                                      border: "none",
                                      color: "grey",
                                      fontSize: "15px",
                                      fontWeight: "500",
                                    }}
                                    size="medium"
                                    bordered
                                    dataSource={this.state.itemT}
                                    renderItem={(item, key) => (
                                      <List.Item
                                        style={{
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          paddingTop: "1.29em",
                                          paddingBottom: "1.29em",
                                          border: "none",

                                          background:
                                            key % 2 === 0
                                              ? "#1d52a70e"
                                              : "white",
                                          color:
                                            key % 2 === 0
                                              ? "#4A4040"
                                              : "#4A4040",
                                        }}
                                      >
                                        {key + 1 + ". " + item.name}{" "}
                                        <span
                                          style={{
                                            float: "right",
                                            marginRight: "1em",
                                          }}
                                        >
                                          {item.totalAmount}
                                        </span>
                                      </List.Item>
                                    )}
                                  />
                                </ConfigProvider>
                              </Col>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                    ) : null}

                    <Row
                      style={{
                        top: window.isPhone ? 20 : "20px",
                        width: window.isPhone ? "calc(100vw - 36px)" : null,
                      }}
                    >
                      <Col span={window.isPhone ? 24 : 17}>
                        <Chart
                          className="stat-display-chart"
                          style={{
                            background: "white",
                            height: window.isPhone ? "26em" : "26em",
                            borderRadius: "1.2em 1.2em 1.2em 1.2em",
                            border: "0px solid lightgrey",
                            boxShadow: "0px 5px 10px 2px #E5E9F2",
                            marginTop: window.isPhone ? "" : "",
                            paddingTop: "1em",
                          }}
                          forceFit
                          height={window.isPhone ? 250 : 300}
                          data={dv}
                          scale={window.isPhone ? scalePhone : scale}
                        >
                          {!window.isPhone ? (
                            <h3
                              style={{
                                color: "black",
                                fontSize: "22px",
                                paddingLeft: "10px",
                                //marginLeft: "9px",
                                // marginTop: '-2.8em',
                                borderBottom:
                                  "1px solid rgba(229, 233, 242, 0.5)",
                                paddingBottom: "10px",
                              }}
                              className="chart-header"
                            >
                              Sales Overview{" "}
                            </h3>
                          ) : (
                            <h3
                              style={{
                                color: "black",
                                fontSize: 18,
                                paddingLeft: 10,
                                paddingTop: 10,
                                paddingBottom: 15,
                              }}
                            >
                              Sales Overview{" "}
                            </h3>
                          )}
                          {!window.isPhone ? (
                            <div
                              style={{
                                float: "right",
                                right: "0em",
                                position: "absolute",
                                marginTop: "-51px",
                                marginBottom: "20px",
                                //marginRight: '19px',
                              }}
                            >
                              <Radio.Group defaultValue="c" size="large">
                                <Radio.Button
                                  value="a"
                                  style={{
                                    textAlign: "center",
                                    margin: "auto",
                                    marginRight: "0.5em",
                                    border: "none",
                                    color: "lightgrey",
                                    borderRadius: "0px",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={() => this.handleChangeSelect("day")}
                                >
                                  Today
                                </Radio.Button>
                                <Radio.Button
                                  value="b"
                                  style={{
                                    textAlign: "center",
                                    margin: "auto",
                                    marginRight: "0.5em",
                                    border: "none",
                                    color: "lightgrey",
                                    borderRadius: "0px",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={() =>
                                    this.handleChangeSelect("week")
                                  }
                                >
                                  7 Days
                                </Radio.Button>
                                <Radio.Button
                                  value="c"
                                  style={{
                                    textAlign: "center",
                                    margin: "auto",
                                    marginRight: "1em",
                                    border: "none",
                                    color: "lightgrey",
                                    borderRadius: "0px",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={() =>
                                    this.handleChangeSelect("month")
                                  }
                                >
                                  30 Days
                                </Radio.Button>
                              </Radio.Group>{" "}
                            </div>
                          ) : (
                            <div
                              style={{
                                position: "absolute",
                                marginTop: -25,
                                width: "100%",
                                display: "flex",
                              }}
                            >
                              <StyledButton
                                style={{
                                  color: "#00CC70",
                                  border: "none",
                                  boxShadow: "none",
                                }}
                                onClick={() => this.handleChangeSelect("day")}
                              >
                                Today
                              </StyledButton>
                              <StyledButton
                                style={{
                                  color: "#00CC70",
                                  border: "none",
                                  boxShadow: "none",
                                }}
                                onClick={() => this.handleChangeSelect("week")}
                              >
                                7 Days
                              </StyledButton>
                              <StyledButton
                                style={{ border: "none", boxShadow: "none" }}
                                onClick={() => this.handleChangeSelect("month")}
                              >
                                30 Days
                              </StyledButton>{" "}
                            </div>
                          )}
                          <Tooltip crosshairs />
                          <Axis />
                          <Legend />
                          <Geom
                            type="point"
                            position="day*value"
                            color={["type", "dodgerblue-#1FA9F6-#00CC70"]}
                            shape="smooth"
                            size={2}
                          />
                          <Geom
                            type="line"
                            position="day*value"
                            color={["type", "dodgerblue-#1FA9F6-#00CC70"]}
                            shape="smooth"
                            size={2}
                          />
                        </Chart>
                      </Col>
                      {!window.isPhone ? (
                        <Col span={7}>
                          <div
                            style={{
                              overflow: "hidden",
                              marginLeft: "1em",
                              backgroundColor: "white",
                              borderRadius: "1.2em",
                              height: "26em",
                              boxShadow: "0px 5px 10px 2px #E5E9F2",
                            }}
                            className="stat-display-top-selling"
                          >
                            {/*<h3
                          style={{
                            textAlign: "center",
                            color: "black",
                            fontSize: "20px",
                            padding: "10px 10px"
                          }}
                        >
                          Best Sellers
                  </h3> */}
                            <StyledTabs
                              defaultActiveKey="1"
                              onChange={callback}
                            >
                              <TabPane
                                tab={
                                  <p style={{ fontWeight: 600 }}>
                                    Best Selling
                                  </p>
                                }
                                key="1"
                              >
                                <Col span={24}>
                                  <ConfigProvider
                                    renderEmpty={customizeRenderEmptyNoButton}
                                  >
                                    <StyledList
                                      style={{
                                        border: "none",
                                        color: "grey",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                      }}
                                      size="medium"
                                      bordered
                                      dataSource={this.state.itemT}
                                      renderItem={(item, key) => (
                                        <List.Item
                                          style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            paddingRight: "3em",
                                            paddingTop: "0.56em",
                                            paddingBottom: "0.56em",
                                            border: "none",
                                            background:
                                              key % 2 === 0
                                                ? "#1d52a70e"
                                                : "white",
                                            color:
                                              key % 2 === 0
                                                ? "#4A4040"
                                                : "#4A4040",
                                          }}
                                        >
                                          {key + 1 + ". " + item.name}
                                          {key === 0 ? (
                                            <div
                                              class="fav"
                                              style={{
                                                top: "-0.9em",
                                                right: "-0.4em",
                                                position: "relative",
                                                display: "inline-block",
                                              }}
                                            >
                                              <svg
                                                class="fav-star"
                                                viewBox="0 0 114 110"
                                              >
                                                <path d="M48.7899002,5.95077319 L39.3051518,35.1460145 L8.60511866,35.1460145 C4.87617094,35.1519931 1.57402643,37.5554646 0.422104463,41.1020351 C-0.7298175,44.6486057 0.529798011,48.5337314 3.54354617,50.7297298 L28.3840111,68.7758317 L18.8992627,97.971073 C17.7496089,101.520283 19.0141379,105.406227 22.0323508,107.599168 C25.0505637,109.792109 29.1370771,109.794067 32.1573906,107.604021 L56.9864557,89.5693186 L81.8269206,107.615421 C84.8472342,109.805467 88.9337475,109.803509 91.9519605,107.610568 C94.9701734,105.417627 96.2347024,101.531683 95.0850486,97.9824729 L85.6003001,68.7986315 L110.440765,50.7525296 C113.466376,48.5582894 114.732852,44.663975 113.576698,41.1097771 C112.420545,37.5555791 109.105303,35.1516627 105.367793,35.1574144 L74.6677595,35.1574144 L65.1830111,5.96217312 C64.0286485,2.41064527 60.7208743,0.00457304502 56.9864557,5.53367114e-06 C53.2527571,-0.00420898295 49.9421526,2.39931752 48.7899002,5.95077319 Z"></path>
                                              </svg>
                                              <span class="fav-round"></span>
                                              <span class="fav-sparkle">
                                                <span class="fav-sparkle-i"></span>
                                                <span class="fav-sparkle-i"></span>
                                                <span class="fav-sparkle-i"></span>
                                                <span class="fav-sparkle-i"></span>
                                                <span class="fav-sparkle-i"></span>
                                              </span>
                                            </div>
                                          ) : null}
                                          <br />
                                          <StyledProgress
                                            strokeColor={{
                                              "0%": "rgba(0, 204, 112, 0.898)",
                                              "100%":
                                                "rgba(0, 204, 112, 0.898)",
                                            }}
                                            status="active"
                                            percent={item.percent}
                                            format={(percent) =>
                                              `${item.totalAmount} Sold`
                                            }
                                          ></StyledProgress>
                                        </List.Item>
                                      )}
                                    />
                                  </ConfigProvider>
                                </Col>
                              </TabPane>
                              <TabPane
                                tab={
                                  <p style={{ fontWeight: 600 }}>
                                    Least Selling
                                  </p>
                                }
                                key="2"
                              >
                                <Col span={24}>
                                  <ConfigProvider
                                    renderEmpty={customizeRenderEmptyNoButton}
                                  >
                                    <StyledList
                                      style={{
                                        border: "none",
                                        color: "grey",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                      }}
                                      size="medium"
                                      bordered
                                      dataSource={this.state.itemB}
                                      renderItem={(item, key) => (
                                        <List.Item
                                          style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            paddingRight: "3em",
                                            paddingTop: "0.56em",
                                            paddingBottom: "0.56em",
                                            border: "none",
                                            background:
                                              key % 2 === 0
                                                ? "#1d52a70e"
                                                : "white",
                                            color:
                                              key % 2 === 0
                                                ? "#4A4040"
                                                : "#4A4040",
                                          }}
                                        >
                                          {key + 1 + ". " + item.name}
                                          <br />
                                          <StyledProgress
                                            strokeColor={{
                                              "0%": "rgba(0, 204, 112, 0.898)",
                                              "100%":
                                                "rgba(0, 204, 112, 0.898)",
                                            }}
                                            status="active"
                                            percent={item.percent}
                                            format={(percent) =>
                                              `${item.totalAmount} Sold`
                                            }
                                          ></StyledProgress>
                                        </List.Item>
                                      )}
                                    />
                                  </ConfigProvider>
                                </Col>
                              </TabPane>
                            </StyledTabs>
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                </div>
              </TabPane>
              {/* <TabPane
                id="dashTabs"
                tab={
                  <p style={{ fontFamily: "Mulish" }}>
                    <AiFillFund
                      style={{
                        fontSize: "20px",
                        marginBottom: "-0.2em",
                        marginRight: "0.4em",
                      }}
                    />
                    Activity Feed
                  </p>
                }
                key="2"
              >
            
                <Row style={{ textAlign: "center" }}>
                  <img
                    style={{
                      width: "12em",
                      marginBottom: "-10em",
                      marginTop: "3em",
                    }}
                    src={YocoImg}
                  ></img>
                  <h1
                    style={{
                      fontSize: window.isPhone ? "50px" : "60px",
                      fontFamily: "Nunito",
                      marginTop: window.isPhone ? "2.6em" : "2em",
                      lineHeight: window.isPhone ? "1em" : "",
                      marginBottom: "-0.2em",
                      color: "#06a0df",
                    }}
                  >
                    VPOS is going Yoco
                  </h1>
                  <br />
                  <p
                    style={{
                      fontSize: "17px",
                      fontFamily: "Nunito",
                      color: "black",
                    }}
                  >
                    Yoco Payment integration Launching soon !
                  </p>
                  <img
                    style={{
                      marginTop: "-1em",
                      width: "15em",
                    }}
                    src={Yoco1}
                  ></img>
                </Row>
              </TabPane> */}
            </StyledTabsMain>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.backend.user,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb,
});

const mapDispatchToProps = {
  getReports,
  resetUser,
  setBreadCrumbs,
  resetActionBreadCrumb,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AdminHome));
