import withStyles from '@material-ui/core/styles/withStyles';
import {
  Col,
  DatePicker,
  Icon,
  Input,
  Popover,
  Row,
  Spin,
  Table,
  Card,
  Radio,
  Empty,
  ConfigProvider,
  Button,
  Select,
  Statistic,
  Upload,
  Checkbox,
  Tooltip
} from 'antd';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import styled from 'styled-components';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  addOrderItem,
  getOrderItems,
  getSupplierItems,
  updatePo,
  getPOReport,
  resendPurchaseOrder,
  sendProofOfPayment,
  convertPOToDraft
} from 'src/redux/actions/backend';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import convert from 'convert-units';
import { formaterCheck } from 'src/utils/formater.js';

const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const StyledStatistic = styled(Statistic)`
  .ant-statistic-title {
    position: relative;
    top: -10px;
    box-shadow: 0 6px 4px -2px rgb(220, 225, 238);
    margin-bottom: -0.7em;
    padding-bottom: 1em;
    border-bottom: 5px solid grey;
    color: black;
    font-size: 19px;
    font-weight: 600;
  }
`;
const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(0, 204, 112, 0.898);
  }
`;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  tableScroll: {
    width: '100%',
    height: '55vh',
    overflow: 'auto'
  }
});

const dimTypesFormat = {
  l: ' L',
  ml: ' ml',
  kg: ' Kg',
  g: ' g',
  m: ' m',
  cm: ' cm',
  mm: ' mm',
  unit: ' Units'
};

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}
class PurchaseOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      _id: '',
      name: '',
      email: '',
      supplier: '',
      supname: '',
      ref: '',
      note: '',
      total: 0,
      orders: [],
      suppliers: [],
      supplierStock: [],
      types: [],
      stockitems: {},
      stock: {},
      supStock: {},
      startDate: moment().format(dateFormatSearch),
      expDate: moment().format(dateFormatSearch),
      reportStartDate: moment()
        .subtract(30, 'd')
        .format(dateFormatSearch),
      reportEndDate: moment().format(dateFormatSearch),
      pickerStart: moment(),
      pickerExp: moment(),
      loading: true,
      main: true,
      switch: false,
      userlisthead: 'Add Purchase Order',
      userlistsub: 'Add a New Purchase Order',
      userlistbutton: 'ADD PURCHASE ORDER',
      alert: false,
      msg: [],
      head: '',
      activeFilter: true,
      activeFilterStatus: [''],
      activeFilterQty: false,
      itemFilters: [],
      filteredInfo: {},
      report: {
        totalAmount: '0',
        totalCount: '0',
        pendingAmount: '0',
        pendingCount: '0',
        completeAmount: '0',
        completeCount: '0'
      }
    };
  }
  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value
    });
  };
  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.getOrderItems();
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    this.setState({ isScrolling: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 2000);
  };
  getOrderItems = msg => {
    const token = this.props.local.user.token;
    this.getPOReport();
    this.props
      .getOrderItems(
        token,
        this.state.reportStartDate,
        this.state.reportEndDate
      )
      .then(result => {
        this.handleRemoveBreadCrumb();
        let itemFiltersPrep = [];
        let types = result.data.results.types;
        for (var i = 0; i < types.length; i++) {
          if (itemFiltersPrep.indexOf(types[i].name) === -1) {
            itemFiltersPrep.push(types[i].name);
          }
        }
        let itemFilters = [];
        for (var i = 0; i < itemFiltersPrep.length; i++) {
          itemFilters.push({
            text: itemFiltersPrep[i],
            value: itemFiltersPrep[i]
          });
        }
        this.setState({
          orders: result.data.results.orders,
          types: result.data.results.types,
          itemFilters: itemFilters,
          name: '',
          email: '',
          supplier: '',
          supname: '',
          ref: '',
          note: '',
          total: 0,
          startDate: moment().format(dateFormatSearch),
          expDate: moment().format(dateFormatSearch),
          pickerStart: moment(),
          pickerExp: moment(),
          supplierStock: [],
          main: true,
          switch: false,
          loading: false,
          activeFilter: true,
          activeFilterQty: false,
          orderId: undefined
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  resendPurchaseOrder = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .resendPurchaseOrder(this.state._id, token)
      .then(res => {
        this.getOrderItems('PO resent to supplier');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  addOrderItems = email => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .addOrderItem(this.state, token, email)
      .then(res => {
        this.getOrderItems('PO Success');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  convertPOToDaft = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .convertPOToDraft(this.state, token, false)
      .then(res => {
        this.props
          .getSupplierItems(token)
          .then(result => {
            let supplierStock = [];
            if (result.data.results.supstock[this.state.supplier]) {
              supplierStock =
                result.data.results.supstock[this.state.supplier].stock || [];
              this.state.stockitems.forEach(stock => {
                result.data.results.stock[this.state.supplier][
                  stock.stockid
                ] = stock;
              });
            } else {
              supplierStock = this.state.stockitems;
              this.state.stockitems.forEach(stock => {
                result.data.results.stock[this.state.supplier][
                  stock.stockid
                ] = stock;
              });
            }
            this.setState({
              supplierStock: supplierStock,
              suppliers: result.data.results.suppliers,
              stock: result.data.results.stock,
              supStock: result.data.results.supstock,
              stockitems: result.data.results.stockitems,
              filteredInfo: {},
              main: false,
              switch: false,
              activeFilter: undefined,
              activeFilterQty: false,
              loading: false,
              pageSize: 25
            });
          })
          .catch(err => {
            this.onError(err);
          });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  updatePo = () => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .updatePo(this.state, token)
      .then(res => {
        this.getOrderItems('Stock Update Success');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  getSupplierItems = msg => {
    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });
    this.props
      .getSupplierItems(token)
      .then(result => {
        this.handleAddBreadCrumb({ method: 'switch', crumb: 'Purchase Order' });
        this.setState({
          supplier: '',
          supname: '',
          ref: '',
          note: '',
          startDate: moment().format(dateFormatSearch),
          expDate: moment().format(dateFormatSearch),
          pickerStart: moment(),
          pickerExp: moment(),
          supplierStock: [],
          name: result.data.results.name,
          email: '',
          total: 0,
          suppliers: result.data.results.suppliers,
          stock: result.data.results.stock,
          supStock: result.data.results.supstock,
          stockitems: result.data.results.stockitems,
          filteredInfo: {},
          main: false,
          switch: false,
          activeFilter: undefined,
          activeFilterQty: false,
          loading: false,
          pageSize: 25
        });
        var sup = {};
        sup.total = 0;
        sup.supplier = 'none';
        sup.supname = 'none';
        sup.email = '';
        sup.supplierStock = [];
        if (result.data.results.supstock['none']) {
          sup.email = result.data.results.supstock['none'].email || '';
          sup.supplierStock = result.data.results.supstock['none'].stock || [];
        }
        this.setState(sup);
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  showOrder = r => {
    this.handleAddBreadCrumb({ method: 'switch', crumb: 'Purchase Order' });
    if (r.status === 'Draft') {
      const token = this.props.local.user.token;
      this.setState({
        loading: true
      });
      this.props
        .getSupplierItems(token)
        .then(result => {
          let supplierStock = [];
          if (result.data.results.supstock[r.supplier]) {
            supplierStock =
              result.data.results.supstock[r.supplier].stock || [];
            r.stockitems.forEach(stock => {
              result.data.results.stock[r.supplier][stock.stockid] = stock;
            });
          } else {
            supplierStock = r.stockitems;
            r.stockitems.forEach(stock => {
              result.data.results.stock[r.supplier][stock.stockid] = stock;
            });
          }
          let tempMail = '';
          if (Array.isArray(r.email)) {
            for (var i = 0; i < r.email.length; i++) {
              tempMail = tempMail + r.email[i] + ';';
            }
            r.email = tempMail;
          }
          this.setState({
            orderId: r._id,
            supplier: r.supplier,
            supname: r.supname,
            ref: r.ref,
            note: r.note,
            startDate: r.startDate,
            expDate: r.expDate,
            pickerStart: moment(),
            pickerExp: moment(),
            supplierStock: supplierStock,
            name: r.name,
            email: r.email,
            total: r.total,
            suppliers: result.data.results.suppliers,
            stock: result.data.results.stock,
            supStock: result.data.results.supstock,
            stockitems: result.data.results.stockitems,
            filteredInfo: {},
            main: false,
            switch: false,
            activeFilter: undefined,
            activeFilterQty: false,
            loading: false,
            pageSize: 25
          });
        })
        .catch(err => {
          this.onError(err);
        });
    } else {
      const token = this.props.local.user.token;
      this.setState({
        loading: true
      });
      this.props
        .getSupplierItems(token)
        .then(result => {
          let tempMail = '';
          if (Array.isArray(r.email)) {
            for (var i = 0; i < r.email.length; i++) {
              tempMail = tempMail + r.email[i] + ';';
            }
            r.email = tempMail;
          }
          this.setState({
            stockadjustview: r.stockitems,
            orderId: r._id,
            supplier: r.supplier,
            supname: r.supname,
            _id: r._id,
            ref: r.ref,
            note: r.note,
            startDate: r.startDate,
            expDate: r.expDate,
            pickerStart: moment(),
            pickerExp: moment(),
            name: r.name,
            email: r.email,
            total: r.total,
            status: r.status,
            suppliers: result.data.results.suppliers,
            stock: result.data.results.stock,
            supStock: result.data.results.supstock,
            stockitems: r.stockitems,
            main: true,
            switch: true,
            loading: false
          });
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  switch = () => {
    this.getOrderItems();
  };

  handleChangeDateStart = value => {
    if (value) {
      this.setState({
        startDate: value.format(dateFormatSearch),
        pickerStart: value
      });
    }
  };

  handleChangeDateExp = value => {
    if (value) {
      this.setState({
        expDate: value.format(dateFormatSearch),
        pickerExp: value
      });
    }
  };

  handleCheck = () => {
    this.setState({ cooking: !this.state.cooking });
  };

  handleChange = event => {
    if (event.target.name === 'supplier') {
      var sup = {};
      sup.total = 0;
      sup.supplier = event.target.value;
      sup.supname = event.currentTarget.textContent;
      sup.email = '';
      sup.supplierStock = [];
      if (this.state.supStock[event.target.value]) {
        let tempMail = '';
        if (Array.isArray(this.state.supStock[event.target.value].email)) {
          for (
            var i = 0;
            i < this.state.supStock[event.target.value].email.length;
            i++
          ) {
            tempMail =
              tempMail + this.state.supStock[event.target.value].email[i] + ';';
          }
          this.state.supStock[event.target.value].email = tempMail;
        }
        sup.email = this.state.supStock[event.target.value].email || '';
        sup.supplierStock = this.state.supStock[event.target.value].stock || [];
      }
      this.setState(sup);
    }
  };

  tableAmountCheck = (record, e) => {
    var stock = this.state.stock;
    var supplier = this.state.supplier;
    stock[this.state.supplier][record.stockid].select = e.target.checked;
    var ttotal = 0;
    Object.keys(stock[supplier]).forEach(function(key) {
      if (stock[supplier][key].select) {
        if (stock[supplier][key].total) {
          ttotal = ttotal + stock[supplier][key].total;
        }
      }
    });
    this.setState({ supStock: stock, total: ttotal });
  };

  pressQtyEnter = (record, event) => {
    if (event.target.parentElement.parentElement.parentElement.nextSibling) {
      event.target.parentElement.parentElement.parentElement.nextSibling.childNodes[5].children[0].children[0].focus();
    }
  };

  pressQtyEnter2 = (record, event) => {
    if (event.target.parentElement.parentElement.parentElement.nextSibling) {
      event.target.parentElement.parentElement.parentElement.nextSibling.childNodes[4].children[0].children[0].focus();
    }
  };

  tableAmount = (record, e, dimChange) => {
    let { value, name } = e.target;
    var stock = this.state.stock;
    var supplier = this.state.supplier;
    if (value || dimChange) {
      if (dimChange) {
        stock[supplier][record.stockid].amount = '1';
        stock[supplier][record.stockid].cost = '';
        stock[supplier][record.stockid].dimTypeOrder = dimChange;
      } else {
        value = formaterCheck(value || '0');
        if (value >= 0) {
          if (e.target.name === 'qty') {
            stock[supplier][record.stockid].amount = value;
          }
          if (e.target.name === 'cost') {
            if (stock[supplier][record.stockid].costChange > 0) {
              stock[supplier][record.stockid].costChange = value;
            } else {
              stock[supplier][record.stockid].cost = value;
            }
          }
          if (e.target.name === 'tax') {
            stock[supplier][record.stockid].tax = value;
          }
        }
      }
      var costPrice = '0';
      if (stock[supplier][record.stockid].dimTypeOrder === 'packsize') {
        costPrice = this.state.stockitems[record.stockid].packBuyPrice;
        stock[supplier][record.stockid].costChange = false;
      } else if (stock[supplier][record.stockid].dimTypeOrder === 'unit') {
        costPrice =
          stock[supplier][record.stockid].cost ||
          this.state.stockitems[record.stockid].buyPrice ||
          '0';
        stock[supplier][record.stockid].costChange = false;
      } else if (
        record.stockSize &&
        record.sizeObject[stock[supplier][record.stockid].dimTypeOrder] &&
        record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].packSize
      ) {
        costPrice =
          stock[supplier][record.stockid].cost ||
          this.state.stockitems[record.stockid].buyPrice ||
          '0';
        stock[supplier][record.stockid].costChange =
          parseFloat(this.state.stockitems[record.stockid].packBuyPrice) *
          (record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].qty /
            this.state.stockitems[record.stockid].packSize);
      } else {
        if (
          stock[supplier][record.stockid].dimTypeOrder !==
            this.state.stockitems[record.stockid].dimType &&
          dimTypesFormat[stock[supplier][record.stockid].dimTypeOrder]
        ) {
          var fromMetric =
            stock[supplier][record.stockid].dimTypeOrder ||
            this.state.stockitems[record.stockid].dimType;
          var toMetric = this.state.stockitems[record.stockid].dimType;
          var metricCost = convert(1)
            .from(fromMetric)
            .to(toMetric);
          costPrice =
            stock[supplier][record.stockid].cost ||
            this.state.stockitems[record.stockid].buyPrice ||
            '0';
          stock[supplier][record.stockid].costChange =
            parseFloat(costPrice) * parseFloat(metricCost);
        } else {
          costPrice =
            stock[supplier][record.stockid].cost ||
            this.state.stockitems[record.stockid].buyPrice ||
            '0';
          stock[supplier][record.stockid].costChange = false;
        }
      }

      stock[supplier][record.stockid].cost = costPrice;

      var metricAmount = stock[supplier][record.stockid].amount;
      if (
        record.stockSize &&
        record.sizeObject[stock[supplier][record.stockid].dimTypeOrder] &&
        record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].packSize
      ) {
        metricAmount =
          record.sizeObject[stock[supplier][record.stockid].dimTypeOrder].qty *
          (stock[supplier][record.stockid].amount || 1);
      } else if (stock[supplier][record.stockid].dimension !== 'unit') {
        var qty = stock[supplier][record.stockid].amount || 1;
        var fromMetric =
          stock[supplier][record.stockid].dimTypeOrder !== 'packsize'
            ? stock[supplier][record.stockid].dimTypeOrder
            : this.state.stockitems[record.stockid].dimType;
        var toMetric = this.state.stockitems[record.stockid].dimType;
        if (
          record.stockSize &&
          record.sizeObject[stock[supplier][record.stockid].dimTypeOrder]
        ) {
          qty =
            record.sizeObject[stock[supplier][record.stockid].dimTypeOrder]
              .qty * metricAmount;
          fromMetric =
            record.sizeObject[stock[supplier][record.stockid].dimTypeOrder]
              .dimType;
        }
        metricAmount = convert(qty)
          .from(fromMetric)
          .to(toMetric);
      } else {
        if (
          record.stockSize &&
          record.sizeObject[stock[supplier][record.stockid].dimTypeOrder]
        ) {
          metricAmount =
            record.sizeObject[stock[supplier][record.stockid].dimTypeOrder]
              .qty * metricAmount;
        }
      }

      var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
      stock[supplier][record.stockid].total = totalCost;

      if (
        stock[supplier][record.stockid].total > 0 ||
        stock[supplier][record.stockid].amount !== ''
      ) {
        stock[supplier][record.stockid].select = true;
      } else {
        stock[supplier][record.stockid].select = false;
      }
      var ttotal = 0;
      Object.keys(stock[supplier]).forEach(function(key) {
        if (stock[supplier][key].select) {
          if (stock[supplier][key].total) {
            ttotal = ttotal + stock[supplier][key].total;
          }
        }
      });

      this.setState({ supStock: stock, total: ttotal });
    } else {
      if (e.target.name === 'qty') {
        stock[supplier][record.stockid].amount = '';
      }
      if (e.target.name === 'cost') {
        stock[supplier][record.stockid].cost = '';
      }
      if (e.target.name === 'tax') {
        stock[supplier][record.stockid].tax = '15';
      }
      var cost = stock[supplier][record.stockid].cost || '0';
      var amount = stock[supplier][record.stockid].amount || '0';
      var tax = stock[supplier][record.stockid].tax || '15';
      var total = parseFloat(cost) * parseFloat(amount);
      var taxt = (total * parseFloat(tax)) / 100;
      stock[supplier][record.stockid].total = total;
      if (
        stock[supplier][record.stockid].total > 0 ||
        stock[supplier][record.stockid].amount !== ''
      ) {
        stock[supplier][record.stockid].select = true;
      } else {
        stock[supplier][record.stockid].select = false;
      }
      var ttotal = 0;
      Object.keys(stock[supplier]).forEach(function(key) {
        if (stock[supplier][key].select) {
          if (stock[supplier][key].total) {
            ttotal = ttotal + stock[supplier][key].total;
          }
        }
      });

      this.setState({ supStock: stock, total: ttotal });
    }
  };

  tableAmountPo = (record, e) => {
    var stock = this.state.stockadjustview;
    const { value } = e.target;
    if (value) {
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        for (var i = 0; i < stock.length; i++) {
          if (stock[i].stockid === record.stockid) {
            if (stock[i].canSupply) {
              if (
                parseFloat(value) >
                (stock[i].canSupply ? parseFloat(stock[i].canSupply) : 0)
              ) {
                break;
              } else {
                stock[i].received = parseFloat(value);
                stock[i].torec =
                  (stock[i].canSupply ? parseFloat(stock[i].canSupply) : 0) -
                  stock[i].received;
                break;
              }
            } else {
              stock[i].canSupply = stock[i].amount;
              if (
                parseFloat(value) >
                (stock[i].canSupply ? parseFloat(stock[i].canSupply) : 0)
              ) {
                break;
              } else {
                stock[i].received = parseFloat(value);
                stock[i].torec =
                  (stock[i].canSupply ? parseFloat(stock[i].canSupply) : 0) -
                  stock[i].received;
                break;
              }
            }
          }
        }
        this.setState({ stockadjustview: stock });
      }
    } else {
      for (var i = 0; i < stock.length; i++) {
        if (stock[i].stockid === record.stockid) {
          stock[i].received = 0;
          stock[i].torec = stock[i].Qty ? parseFloat(stock[i].Qty) : 0;
          break;
        }
      }
      this.setState({ stockadjustview: stock });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Purchase Order Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  submitForm = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    if (oEvent.currentTarget.innerText === 'ADD PURCHASE ORDER') {
      this.props
        .addMenuItem(this.state, token)
        .then(res => {
          this.setState({
            main: true,
            loading: false
          });
          this.getOrderItems('Purchase Order Creation Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    } else if (oEvent.currentTarget.innerText === 'EDIT PURCHASE ORDER') {
      this.props
        .editMenuItem(this.state, token)
        .then(res => {
          this.setState({
            main: true,
            loading: false
          });
          this.getOrderItems('Purchase Order Update Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  onChangeEvt = e => {
    if (e.target.id === 'email') {
      this.setState({ email: e.currentTarget.value });
    }
    if (e.target.id === 'description') {
      this.setState({ note: e.currentTarget.value });
    }
    if (e.target.id === 'reference') {
      this.setState({ ref: e.currentTarget.value });
    }
  };

  handleEditMenuItem = oMenu => {
    this.props.handleOpenAddMenuItems();
    this.setState({
      main: false,
      filteredInfo: {},
      userlisthead: 'Edit Purchase Order',
      userlistsub: 'Edit a Purchase Order',
      userlistbutton: 'EDIT PURCHASE ORDER',
      pageSize: 25
    });
  };

  handleFormatListQty = () => {
    this.setState({ activeFilterQty: true, activeFilter: true });
  };
  handleFormatList = format => {
    this.setState({ activeFilter: format, activeFilterQty: false });
  };

  handleFormatListStatus = format => {
    this.setState({ activeFilterStatus: format });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSort = (pagination, filters, sorter) => {
    this.setState(prevState => ({
      ...prevState,
      filteredInfo: filters,
      sortedInfo: sorter
    }));
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnFilterProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <Select
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Filter ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e ? [e] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {this.state.types.map((items, key) => {
              return (
                <Option key={key} value={items.value || items._id}>
                  {items.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (!visible) {
        setTimeout(() => this.searchInput.rcSelect.setOpenState(false));
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />

      <button
        style={{
          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',
          width: '12em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',
          borderRadius: '3em',
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => this.getSupplierItems()}
        color="primary"
      >
        {'Add Purchase Order'}
      </button>
    </div>
  );
  getPOReport = (start, end) => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    this.props
      .getPOReport(token, this.state.reportStartDate, this.state.reportEndDate)
      .then(result => {
        if (result.data.results === 'NoBills') {
          this.setState({
            report: {
              totalAmount: '0',
              totalCount: '0',
              pendingAmount: '0',
              pendingCount: '0',
              completeAmount: '0',
              completeCount: '0'
            },
            loading: false
          });
        } else {
          this.setState({
            report: result.data.results,
            loading: false
          });
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        reportStartDate: value[0].format(dateFormatSearch),
        reportEndDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1]
      });
    }
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'yester') {
        this.setState(
          {
            reportStartDate: moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            reportEndDate: moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            datePicker: false
          },
          () => {
            this.getOrderItems();
          }
        );
      } else if (value === 'today') {
        this.setState(
          {
            reportStartDate: moment().format(dateFormatSearch),
            reportEndDate: moment().format(dateFormatSearch),
            datePicker: false
          },
          () => {
            this.getOrderItems();
          }
        );
      } else if (value === 'last7') {
        this.setState(
          {
            reportStartDate: moment()
              .subtract(7, 'd')
              .format(dateFormatSearch),
            reportEndDate: moment().format(dateFormatSearch),
            datePicker: false
          },
          () => {
            this.getOrderItems();
          }
        );
      } else if (value === 'last30') {
        this.setState(
          {
            reportStartDate: moment()
              .subtract(30, 'd')
              .format(dateFormatSearch),
            reportEndDate: moment().format(dateFormatSearch),
            datePicker: false
          },
          () => {
            this.getOrderItems();
          }
        );
      } else if (value === 'thism') {
        this.setState(
          {
            reportStartDate: moment()
              .startOf('month')
              .format(dateFormatSearch),
            reportEndDate: moment().format(dateFormatSearch),
            datePicker: false
          },
          () => {
            this.getOrderItems();
          }
        );
      } else if (value === 'lastm') {
        this.setState(
          {
            reportStartDate: moment()
              .subtract(1, 'months')
              .startOf('month')
              .format(dateFormatSearch),
            reportEndDate: moment()
              .subtract(1, 'months')
              .endOf('month')
              .format(dateFormatSearch),
            datePicker: false
          },
          () => {
            this.getOrderItems();
          }
        );
      } else if (value === 'custom') {
        this.setState({
          datePicker: true
        });
      }
    }
  };

  sendProofOfPayment = ({ onSuccess, file }) => {
    const reader = new FileReader();
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    reader.addEventListener('load', event => {
      let b64file = event.target.result;
      this.props
        .sendProofOfPayment(this.state._id, b64file.split('base64,')[1], token)
        .then(res => {
          this.setState({
            main: true,
            loading: false
          });
          this.getOrderItems('Successfully sent proof of payment to supplier');
        })
        .catch(err => {
          this.onError(err);
        });
    });
    reader.readAsDataURL(file);
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };

  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onChangeEvt: this.onChangeEvt,
      onHelpMarker: this.onHelpMarker
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',
            color: 'rgb(15, 135, 123)',
            height: '2.5em',
            width: '6em',
            margin: '0.3em',
            borderRadius: '3em',
            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',
            color: 'rgb(15, 135, 123)',
            height: '2.5em',
            width: '6em',
            margin: '0.3em',
            borderRadius: '3em',
            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '2.5em',
            width: '6.5em',
            margin: '0.3em',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['Pending'])}
        >
          Pending
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '2.5em',
            width: '6.5em',
            margin: '0.3em',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['Partial'])}
        >
          Partial
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '2.5em',
            width: '6.5em',
            margin: '0.3em',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus(['Complete'])}
        >
          Complete
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30, 161, 169)',
            boxShadow: 'none',
            color: 'white',
            height: '2.5em',
            width: '6em',
            margin: '0.3em',
            borderRadius: '3em'
          }}
          onClick={() => this.handleFormatListStatus([''])}
        >
          All
        </Button>
      </div>
    );
    let supplierStock = [];
    for (var i = 0; i < this.state.supplierStock.length; i++) {
      if (this.state.activeFilterQty) {
        if (
          this.state.supplierStock[i].active &&
          this.state.supplierStock[i].qty <= this.state.supplierStock[i].lowqty
        ) {
          supplierStock.push(this.state.supplierStock[i]);
        }
      } else if (this.state.activeFilter === true) {
        if (this.state.supplierStock[i].active) {
          supplierStock.push(this.state.supplierStock[i]);
        }
      } else if (this.state.activeFilter === false) {
        if (!this.state.supplierStock[i].active) {
          supplierStock.push(this.state.supplierStock[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        supplierStock.push(this.state.supplierStock[i]);
      }
    }
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <div />
        {this.state.main ? (
          <div>
            {!this.state.switch ? (
              <div>
                <div>
                  <Row>
                    <div
                      style={{
                        float: 'left'
                      }}
                    >
                      {this.state.datePicker ? (
                        <div>
                          <RangePicker
                            allowClear={false}
                            className={this.props.classes.datePicker}
                            onChange={this.handleChangeDate}
                            value={[
                              this.state.pickerStart,
                              this.state.pickerEnd
                            ]}
                            format={dateFormat}
                          />
                        </div>
                      ) : null}

                      {this.state.datePicker ? (
                        <Button
                          style={{
                            backgroundColor: '#00CC70',
                            color: 'white',
                            top: '-0.05em',
                            height: '40px',
                            width: '120px',
                            border: '1px solid #00CC70',
                            borderRadius: '3em',
                            fontSize: '12px',
                            paddingBottom: '0.1em',
                            fontWeight: '600'
                          }}
                          onClick={() => this.getOrderItems()}
                        >
                          Get Report
                        </Button>
                      ) : null}
                    </div>
                  </Row>

                  <Row>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-today-sales"
                          title={
                            <span>
                              Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div
                                style={{
                                  marginTop: '-0.1em',
                                  backgroundColor: '#00CC70',
                                  float: 'right',
                                  paddingRight: '0.77em',
                                  paddingTop: '0.2em',
                                  borderRadius: '3em',
                                  paddingBottom: '0.4em',
                                  paddingLeft: '0.77em'
                                }}
                              >
                                <span
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.report.totalCount > 0
                                    ? this.state.report.totalCount
                                    : '0'}
                                </span>
                              </div>
                            </span>
                          }
                          value={this.state.report.totalAmount}
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            bottom: -10,
                            fontWeight: 600
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-profit"
                          title={
                            <span>
                              Pending &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div
                                style={{
                                  marginTop: '-0.1em',
                                  backgroundColor: '#1FA9F6',
                                  float: 'right',
                                  paddingRight: '0.77em',
                                  paddingTop: '0.2em',
                                  borderRadius: '3em',
                                  paddingBottom: '0.4em',
                                  paddingLeft: '0.77em'
                                }}
                              >
                                <span
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.report.pendingCount > 0
                                    ? this.state.report.pendingCount
                                    : '0'}
                                </span>
                              </div>
                            </span>
                          }
                          value={this.state.report.pendingAmount}
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            bottom: -10,
                            fontWeight: 600
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        className="stat-display-card-net"
                        style={{
                          boxShadow: '0px 5px 10px 2px #E5E9F2',
                          overflow: 'hidden',
                          borderRadius: '1.2em',
                          height: '120px',
                          width: '97%',
                          border: '0px solid lightgrey',
                          background: 'white'
                        }}
                      >
                        <StyledStatistic
                          className="stat-display-card-open-bills"
                          title={
                            <span>
                              Completed
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div
                                style={{
                                  marginTop: '-0.1em',
                                  backgroundColor: '#1D51A7',
                                  float: 'right',
                                  paddingRight: '0.77em',
                                  paddingTop: '0.2em',
                                  borderRadius: '3em',
                                  paddingBottom: '0.4em',
                                  paddingLeft: '0.77em'
                                }}
                              >
                                <span
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.report.completeCount > 0
                                    ? this.state.report.completeCount
                                    : '0'}
                                </span>
                              </div>
                            </span>
                          }
                          value={this.state.report.completeAmount}
                          precision={2}
                          valueStyle={{
                            fontFamily: 'Montserrat,sans-serif!important',
                            color: '#4A4040',
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            bottom: -10,
                            fontWeight: 600
                          }}
                          prefix="R "
                        />
                      </Card>
                    </Col>
                  </Row>
                </div>
                <Button
                  style={{
                    position: 'fixed',
                    right: '0.8em',
                    bottom: '0.8em',
                    background: '#132440',
                    border: '1px solid transparent',
                    color: 'white',
                    padding: '0em',
                    width: '3.3em',
                    height: '3.3em',
                    borderRadius: '5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                  onClick={() => this.getStockItems()}
                  color="primary"
                >
                  <Icon
                    type="plus"
                    style={{
                      paddingTop: '0.2em',
                      fontSize: '1.3em'
                    }}
                  />
                </Button>

                {/*} <Button
                  style={{
                    position: 'fixed',
                    right: '0.5em',
                    bottom: '0.5em',
                    background: '#132440',
                    border: '1px solid transparent',
                    color: 'white',
                    padding: '0em',
                    width: '3.5em',
                    height: '3.5em',
                    borderRadius: '5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                  onClick={() => this.getSupplierItems()}
                  color="primary"
                >
                  <Icon type="plus"
                    style={{
                      position: 'absolute',
                      paddingLeft: 3
                    }}
                  >
                  </Icon >
                  </Button> */}
                {this.state.orders.length > 0 ? (
                  <Row style={{ float: 'right', marginRight: '17.5em' }}>
                    <button
                      style={{
                        marginTop: '1em',
                        backgroundColor: '#00CC70',
                        border: '0px solid rgb(30, 161, 169)',
                        color: 'white',

                        width: '12em',
                        height: '2.7em',
                        fontSize: 16,
                        fontFamily: '',

                        borderRadius: '3em',
                        zIndex: 99,
                        position: 'absolute'
                      }}
                      onClick={() => this.getSupplierItems()}
                      color="primary"
                    >
                      {'Add Purchase Order'}
                    </button>
                  </Row>
                ) : null}
                <Row>
                  <Select
                    id="filterSelect"
                    style={{
                      width: '150px',
                      paddingRight: '10px',
                      marginTop: '1em',
                      marginLeft: '1em'
                    }}
                    defaultValue="last30"
                    onChange={this.handleChangeSelect}
                  >
                    <Option value="today">Today</Option>
                    <Option value="yester">Yesterday</Option>
                    <Option value="last7">Last 7 Days</Option>
                    <Option value="last30">Last 30 Days</Option>
                    <Option value="thism">This Month</Option>
                    <Option value="lastm">Last Month</Option>
                    <Option value="custom">Custom</Option>
                  </Select>
                  <div
                    style={{
                      float: 'right',
                      marginTop: '1em',
                      marginBottom: '1em'
                    }}
                  >
                    <Popover
                      placement="left"
                      content={EyeContent}
                      title=""
                      trigger="click"
                    >
                      <Button
                        style={{
                          backgroundColor: '#00CC70',
                          border: '0px solid rgb(30, 161, 169)',
                          color: 'white',
                          marginBottom: '-0.5em',
                          height: '3.2em',
                          marginLeft: '-0.27em',
                          borderRadius: '3em',

                          width: '3.2em'
                        }}
                      >
                        <Icon style={{}} type="setting" />
                      </Button>
                    </Popover>
                    <Popover
                      placement="bottomRight"
                      content={GearContent}
                      title=""
                      trigger="click"
                    >
                      <Button
                        style={{
                          display: 'none',
                          backgroundColor: 'white',
                          border: '1px solid rgb(15, 135, 123)',
                          color: 'rgb(15, 135, 123)',
                          marginBottom: '-0.5em',
                          height: '3.5em',
                          marginLeft: '-0.27em',
                          borderRadius: '0.3em',
                          transform: 'scale(0.9)',
                          width: '2em'
                        }}
                      >
                        <Icon style={{}} type="setting" />
                      </Button>
                    </Popover>
                  </div>
                </Row>
                <Row>
                  <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                    <StyledTable
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      dataSource={this.state.orders}
                      pagination={{
                        locale: { items_per_page: ' Items' },
                        showSizeChanger: true,
                        onShowSizeChange: this.onShowSizeChange,
                        pageSizeOptions: ['10', '25', '50', '100'],
                        hideOnSinglePage: true,
                        pageSize: this.state.pageSize || 25,
                        itemRender: (current, type, originalElement) => {
                          if (type === 'prev') {
                            return <a>Previous</a>;
                          }
                          if (type === 'next') {
                            return <a>Next</a>;
                          }
                          return originalElement;
                        },
                        showTotal: (total, range) =>
                          `Showing ${range[0]} - ${
                            range[1]
                          }  of ${total} items`,
                        defaultCurrent: 1
                      }}
                      onChange={this.handleSort}
                      onRow={r => ({
                        onClick: () => this.showOrder(r)
                      })}
                    >
                      <Column
                        {...this.getColumnSearchProps('name')}
                        title="Purchase Orders"
                        dataIndex="name"
                        key="name"
                      />
                      <Column
                        {...this.getColumnSearchProps('startDate')}
                        title="Date"
                        render={(text, record) => (
                          <span>
                            {record.startDate
                              ? record.startDate.slice(0, 4) +
                                '-' +
                                record.startDate.slice(4, 6) +
                                '-' +
                                record.startDate.slice(6, 8)
                              : 'N/A'}
                          </span>
                        )}
                        dataIndex="startDate"
                        key="startDate"
                      />
                      <Column
                        {...this.getColumnSearchProps('supname')}
                        title="Supplier"
                        dataIndex="supname"
                        key="supname"
                      />
                      <Column
                        {...this.getColumnSearchProps('status')}
                        title="Status"
                        filtered={true}
                        filteredValue={this.state.activeFilterStatus}
                        dataIndex="status"
                        key="status"
                      />
                      <Column
                        {...this.getColumnSearchProps('expDate')}
                        title="Expected"
                        render={(text, record) => (
                          <span>
                            {record.expDate
                              ? record.expDate.slice(0, 4) +
                                '-' +
                                record.expDate.slice(4, 6) +
                                '-' +
                                record.expDate.slice(6, 8)
                              : 'N/A'}
                          </span>
                        )}
                        dataIndex="expDate"
                        key="expDate"
                      />
                      <Column
                        {...this.getColumnSearchProps('total')}
                        title="Total"
                        render={(text, record) => (
                          <span>
                            {record.total
                              ? 'R ' + record.total.toFixed('2')
                              : 'R 0.00'}
                          </span>
                        )}
                        dataIndex="total"
                        key="total"
                      />
                    </StyledTable>
                  </ConfigProvider>
                </Row>
              </div>
            ) : (
              <div style={{ paddingBottom: '5em' }}>
                <Row>
                  <h4 className={this.props.classes.cardTitleWhite}>
                    {this.state.name}
                  </h4>
                  <button
                    style={{
                      marginTop: -40,
                      right: 0,
                      backgroundColor: '#00CC70',
                      border: '0px solid rgb(30, 161, 169)',
                      color: 'white',
                      width: '12em',
                      height: '2.7em',
                      fontSize: 16,
                      fontFamily: '',
                      borderRadius: '3em',
                      zIndex: 99,
                      position: 'absolute'
                    }}
                    onClick={() => this.convertPOToDaft()}
                    color="primary"
                  >
                    {'Edit Purchase Order'}
                  </button>
                </Row>
                <Row>
                  <Col span={6}>
                    <CustomInput
                      labelText="Supplier"
                      id="supplier"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.handleChange(e),
                        onClick: () => this.onHelpMarker('stockitemtype'),
                        value: this.state.supname
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CustomInput
                      labelText="Issued on"
                      id="Issued"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.startDate
                          ? this.state.startDate.slice(0, 4) +
                            '-' +
                            this.state.startDate.slice(4, 6) +
                            '-' +
                            this.state.startDate.slice(6, 8) +
                            ' '
                          : 'N/A'
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={6}>
                    <CustomInput
                      labelText="Expected on"
                      id="Expected"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.expDate
                          ? this.state.expDate.slice(0, 4) +
                            '-' +
                            this.state.expDate.slice(4, 6) +
                            '-' +
                            this.state.expDate.slice(6, 8) +
                            ' '
                          : 'N/A'
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CustomInput
                      labelText="Reference"
                      id="reference"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.ref
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={6}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.email
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={6}>
                    <CustomInput
                      labelText="Notes To Supplier"
                      id="description"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        onChange: e => this.onChangeEvt(e),
                        onClick: () => this.onHelpMarker('stockitemdesc'),
                        value: this.state.note
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ top: '5px' }}>
                    <h2>{'TOTAL: R' + this.state.total.toFixed('2')}</h2>
                  </Col>
                </Row>

                <Row>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.stockadjustview}
                    pagination={{
                      locale: { items_per_page: ' Items' },
                      showSizeChanger: true,
                      onShowSizeChange: this.onShowSizeChange,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      hideOnSinglePage: true,
                      pageSize: this.state.pageSize || 25,
                      itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                          return <a>Previous</a>;
                        }
                        if (type === 'next') {
                          return <a>Next</a>;
                        }
                        return originalElement;
                      },
                      showTotal: (total, range) =>
                        `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                      defaultCurrent: 1
                    }}
                    onChange={this.handleSort}
                  >
                    <Column
                      {...this.getColumnSearchProps('name')}
                      title="Name"
                      dataIndex="name"
                      key="name"
                    />
                    <Column
                      {...this.getColumnFilterProps('type')}
                      title="Type"
                      dataIndex="type"
                      key="type"
                    />
                    <Column
                      {...this.getColumnSearchProps('amount')}
                      title="Ordered"
                      render={(text, record) => {
                        return (
                          <span>
                            {record.amount
                              ? record.amount +
                                (record.dimTypeOrder !== 'packsize'
                                  ? record.sizeObject &&
                                    record.sizeObject[record.dimTypeOrder]
                                    ? 'x' + record.dimTypeOrder
                                    : dimTypesFormat[record.dimTypeOrder]
                                    ? dimTypesFormat[record.dimTypeOrder]
                                    : record.dimTypeOrder || ' Units'
                                  : 'x' +
                                    record.packSize +
                                    (dimTypesFormat[record.dimType]
                                      ? dimTypesFormat[record.dimType]
                                      : record.dimType || ' Units'))
                              : '0'}
                          </span>
                        );
                      }}
                      dataIndex="amount"
                      key="amount"
                    />
                    <Column
                      {...this.getColumnSearchProps('canSupply')}
                      title="Supplier Supply"
                      render={(text, record) => {
                        return (
                          <span>
                            {record.canSupply
                              ? record.canSupply === 0
                                ? '0'
                                : record.canSupply +
                                  (record.dimTypeOrder !== 'packsize'
                                    ? record.sizeObject &&
                                      record.sizeObject[record.dimTypeOrder]
                                      ? 'x' + record.dimTypeOrder
                                      : dimTypesFormat[record.dimTypeOrder]
                                      ? dimTypesFormat[record.dimTypeOrder]
                                      : record.dimTypeOrder || ' Units'
                                    : 'x' +
                                      record.packSize +
                                      (dimTypesFormat[record.dimType]
                                        ? dimTypesFormat[record.dimType]
                                        : record.dimType || ' Units'))
                              : 'Waiting Approval'}
                          </span>
                        );
                      }}
                      dataIndex="canSupply"
                      key="canSupply"
                    />
                    {/* <Column
                      title="Unit"
                      align="center"
                      render={(text, record, key) => (
                        <CustomSelect
                          labelText=""
                          id="dimentiontype"
                          formControlProps={{
                            style: { margin: '10px 0 0 0' },
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: record.dimension === 'unit' && !record.stockSize ? true : false,
                            style: { padding: 0, margin: 0 },
                            onChange: event => this.tableAmount(record, event),
                            value: this.state.stock[this.state.supplier][record.stockid].dimTypeOrder || 'unit'
                          }}
                          menuItems={record.dimObject}
                        />
                      )}
                    /> */}
                    <Column
                      {...this.getColumnSearchProps('dimTypeOrder')}
                      title="Unit"
                      render={(text, record) => {
                        return (
                          <span>
                            {record.dimTypeOrder
                              ? record.dimTypeOrder !== 'packsize'
                                ? dimTypesFormat[record.dimTypeOrder]
                                : record.packSize + record.dimType
                              : 'Unit'}
                          </span>
                        );
                      }}
                      dataIndex="dimTypeOrder"
                      key="dimTypeOrder"
                    />
                    <Column
                      width="150px"
                      title="Received"
                      render={(text, record) => (
                        <div>
                          <Input
                            // disabled={
                            //   this.state.status
                            // ||
                            // this.state.status === 'Awaiting Approval'
                            //   ? true
                            //   : false
                            // }
                            name="received"
                            placeholder="Received amount"
                            value={record.received}
                            onPressEnter={event =>
                              this.pressQtyEnter2(record, event)
                            }
                            onChange={event =>
                              this.tableAmountPo(record, event)
                            }
                          />
                        </div>
                      )}
                      key="received"
                    />
                    <Column
                      title="To Receive"
                      render={(text, record) => {
                        return (
                          <span>
                            {record.torec
                              ? record.torec
                              : record.canSupply && record.received === 0
                              ? parseFloat(record.canSupply)
                              : record.amount - record.received}
                          </span>
                        );
                      }}
                      dataIndex="toreceive"
                      key="Receive"
                    />
                    <Column
                      {...this.getColumnSearchProps('cost')}
                      title="Cost"
                      render={(text, record) => {
                        return (
                          <span>
                            {record.cost
                              ? 'R ' + parseFloat(record.cost).toFixed(2)
                              : 'R 0.00'}
                          </span>
                        );
                      }}
                      dataIndex="cost"
                      key="cost"
                    />
                    <Column
                      {...this.getColumnSearchProps('total')}
                      title="Total"
                      render={(text, record) => {
                        return (
                          <span>
                            {record.total
                              ? 'R ' + record.total.toFixed(2)
                              : 'R 0.00'}
                          </span>
                        );
                      }}
                      dataIndex="total"
                      key="total"
                    />
                  </StyledTable>
                </Row>
                <div
                  style={{
                    transition: '1s',
                    opacity: this.state.isScrolling ? 0 : 1,
                    position: 'fixed',
                    right: 5,
                    bottom: 5,
                    background: 'rgb(19, 36, 64)',
                    padding: 0,
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    borderRadius: '3em',
                    // width: "calc(100% - 80px)",
                    height: '3.4em',
                    zIndex: 99
                  }}
                  color="primary"
                >
                  <Button
                    onClick={() => this.switch()}
                    style={{
                      float: 'right',
                      marginTop: 8,
                      color: 'white',
                      borderRadius: '3em',
                      border: '1px solid white',
                      backgroundColor: 'transparent',
                      width: '6em'
                    }}
                    color="primary"
                  >
                    Back
                  </Button>
                  <Button
                    onClick={() => this.updatePo()}
                    style={{
                      float: 'right',
                      marginTop: 8,
                      marginRight: 8,
                      color: 'white',
                      borderRadius: '3em',
                      border: '1px solid rgba(0, 255, 112, 0.898)',
                      backgroundColor: 'transparent'
                    }}
                    color="primary"
                    disabled={
                      this.state.status === 'Complete'
                      // ||
                      // this.state.status === 'Awaiting Approval'
                      //   ? true
                      //   : false
                    }
                  >
                    Update Stock
                  </Button>
                  {// this.state.status === 'Awaiting Approval' ||
                  this.state.status === 'Pending' ? (
                    <Button
                      onClick={() => this.resendPurchaseOrder()}
                      style={{
                        float: 'right',
                        marginTop: 8,
                        marginRight: 8,
                        color: 'white',
                        borderRadius: '3em',
                        border: '1px solid rgba(0, 255, 112, 0.898)',
                        backgroundColor: 'transparent'
                      }}
                      color="primary"
                    >
                      Resend Purchase Order
                    </Button>
                  ) : null}
                  {// this.state.status === 'Awaiting Payment' ||
                  this.state.status === 'Pending' ? (
                    <div style={{ float: 'right' }}>
                      <Upload
                        style={{ float: 'right', marginTop: 8, marginRight: 8 }}
                        name="POP"
                        showUploadList={false}
                        customRequest={this.sendProofOfPayment}
                        accept=".pdf"
                      >
                        <Button
                          style={{
                            float: 'right',
                            marginTop: 8,
                            marginRight: 8,
                            color: 'white',
                            borderRadius: '3em',
                            border: '1px solid rgba(0, 255, 112, 0.898)',
                            backgroundColor: 'transparent'
                          }}
                          color="primary"
                        >
                          Send Proof of Payment
                        </Button>
                      </Upload>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {' '}
            <Card style={{ paddingBottom: '5em' }}>
              <Row>
                <Col span={6}>
                  <CustomInput
                    labelText="Purchase Order"
                    id="purchaseordername"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.name
                    }}
                  />
                </Col>
                <Col span={1} />
                <Col span={6}>
                  <CustomSelect
                    labelText="Supplier"
                    id="supplier"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.handleChange(e),
                      onClick: () => this.onHelpMarker('stockitemtype'),
                      value: this.state.supplier
                    }}
                    menuItems={this.state.suppliers}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ top: '15px', width: '20em' }}>
                  {'Issued on '}
                  <DatePicker
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDateStart}
                    value={this.state.pickerStart}
                    format={dateFormat}
                  />
                </Col>
                <Col span={1} />
                <Col span={6} style={{ top: '15px', width: '20em' }}>
                  {'Expected on '}
                  <DatePicker
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDateExp}
                    value={this.state.pickerExp}
                    format={dateFormat}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <CustomInput
                    labelText="Reference"
                    id="reference"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      onClick: () => this.onHelpMarker('stockitemdesc'),
                      value: this.state.ref
                    }}
                  />
                </Col>
                <Col span={1} />
                <Col span={5}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      onClick: () => this.onHelpMarker('stockitemdesc'),
                      value: this.state.email
                    }}
                  />
                </Col>
                <Col span={1}>
                  <Tooltip
                    placement="top"
                    title={
                      'Use the semicolon (;) character to separate multiple email addresses'
                    }
                  >
                    <IconButton style={{ marginTop: '1.6em' }}>
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: '17px',
                          color: 'grey'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Col>
                <Col span={6}>
                  <CustomInput
                    labelText="Notes To Supplier"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      onClick: () => this.onHelpMarker('stockitemdesc'),
                      value: this.state.note
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ top: '5px' }}>
                  <h2>{'TOTAL: R' + this.state.total.toFixed('2')}</h2>
                </Col>
              </Row>
              <Row />
              <Row>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  pagination={{
                    locale: { items_per_page: ' Items' },
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: itemRender,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  dataSource={supplierStock}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (rowIndex % 2 == 0) {
                      return { style: { background: 'rgb(247, 247, 247)' } };
                    }
                  }}
                >
                  <Column
                    title="#"
                    align="center"
                    width="1em"
                    render={(text, record) => (
                      <div>
                        <StyledCheckbox
                          onChange={event =>
                            this.tableAmountCheck(record, event)
                          }
                          checked={
                            this.state.stock[this.state.supplier][
                              record.stockid
                            ].select || false
                          }
                        />
                      </div>
                    )}
                    key="select"
                  />
                  <Column
                    {...this.getColumnSearchProps('name')}
                    sorter={(a, b) => a.name.localeCompare(b.name)}
                    sortDirections={['descend', 'ascend']}
                    title="Item Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    sorter={(a, b) => a.type.localeCompare(b.type)}
                    sortDirections={['descend', 'ascend']}
                    filters={this.state.itemFilters}
                    filteredValue={this.state.filteredInfo.type}
                    onFilter={(value, record) => record.type.includes(value)}
                    title="Type"
                    dataIndex="type"
                    key="type"
                  />
                  <Column
                    title="In Stock"
                    render={(text, record) => {
                      return (
                        <span>
                          {Math.round(record.qty * 1000) / 1000 +
                            (dimTypesFormat[record.dimType]
                              ? dimTypesFormat[record.dimType]
                              : ' Units')}
                        </span>
                      );
                    }}
                    dataIndex="qty"
                    key="stock"
                  />
                  <Column
                    title="Unit Size"
                    align="center"
                    render={(text, record, key) => (
                      <div>
                        {record.dimObject &&
                          record.dimObject.map((dim, key) => (
                            <a
                              style={{
                                whiteSpace: 'nowrap',
                                border:
                                  (this.state.stock[this.state.supplier][
                                    record.stockid
                                  ].dimTypeOrder || 'unit') === dim.value
                                    ? '1px solid #00CC70'
                                    : '1px solid',
                                backgroundColor:
                                  (this.state.stock[this.state.supplier][
                                    record.stockid
                                  ].dimTypeOrder || 'unit') === dim.value
                                    ? '#00CC70'
                                    : 'transparent',
                                color:
                                  (this.state.stock[this.state.supplier][
                                    record.stockid
                                  ].dimTypeOrder || 'unit') === dim.value
                                    ? 'white'
                                    : '#00CC70',
                                borderRadius: '3em',
                                padding: '5px',
                                paddingRight: '1.5em',
                                paddingLeft: '1.5em',
                                margin: '3px'
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                this.tableAmount(record, e, dim.value);
                              }}
                            >
                              {dim.name}
                            </a>
                          ))}
                        {/* <CustomSelect
                          labelText=""
                          id="dimentiontype"
                          formControlProps={{
                            style: { margin: '10px 0 0 0' },
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: record.dimension === 'unit' && !record.stockSize ? true : false,
                            style: { padding: 0, margin: 0 },
                            onChange: event => this.tableAmount(record, event),
                            value: this.state.stock[this.state.supplier][record.stockid].dimTypeOrder || 'unit'
                          }}
                          menuItems={record.dimObject}
                        />
                        */}{' '}
                      </div>
                    )}
                  />
                  <Column
                    title="Qty"
                    width="7em"
                    align="center"
                    render={(text, record) => (
                      <div>
                        <Input
                          name="qty"
                          placeholder="Input Qty"
                          value={
                            this.state.stock[this.state.supplier][
                              record.stockid
                            ].amount
                          }
                          onPressEnter={event =>
                            this.pressQtyEnter(record, event)
                          }
                          onChange={event => this.tableAmount(record, event)}
                        />
                      </div>
                    )}
                    key="qty"
                  />
                  <Column
                    title="Cost per Dim"
                    width="13em"
                    align="center"
                    render={(text, record) => (
                      <div>
                        <Input
                          name="cost"
                          placeholder="Input Cost"
                          prefix="R"
                          //disabled={true}
                          suffix={
                            record.dimension !== 'unit'
                              ? '/ ' +
                                (this.state.stock[this.state.supplier][
                                  record.stockid
                                ].dimTypeOrder !== 'packsize'
                                  ? dimTypesFormat[
                                      this.state.stock[this.state.supplier][
                                        record.stockid
                                      ].dimTypeOrder
                                    ] ||
                                    this.state.stock[this.state.supplier][
                                      record.stockid
                                    ].dimTypeOrder
                                  : this.state.stock[this.state.supplier][
                                      record.stockid
                                    ].packSize +
                                    this.state.stock[this.state.supplier][
                                      record.stockid
                                    ].dimType)
                              : '/ Unit'
                          }
                          value={
                            this.state.stock[this.state.supplier][
                              record.stockid
                            ].costChange > 0
                              ? parseFloat(
                                  this.state.stock[this.state.supplier][
                                    record.stockid
                                  ].costChange
                                ).toFixed(2)
                              : this.state.stock[this.state.supplier][
                                  record.stockid
                                ].cost
                              ? parseFloat(
                                  this.state.stock[this.state.supplier][
                                    record.stockid
                                  ].cost
                                ).toFixed(2)
                              : this.state.stockitems[record.stockid].buyPrice
                              ? parseFloat(
                                  this.state.stockitems[record.stockid].buyPrice
                                ).toFixed(2)
                              : '0'
                          }
                          onChange={event => this.tableAmount(record, event)}
                        />
                      </div>
                    )}
                    key="cost"
                  />
                  {/*<Column
                  title="Tax %"
                  render={(text, record) => (
                    <div>
                      <Input
                        name="tax"
                        placeholder="Input Tax %"
                        value={
                          this.state.stock[this.state.supplier][record.stockid]
                            .tax
                        }
                        onChange={event => this.tableAmount(record, event)}
                      />
                    </div>
                  )}
                  key="tax"
                      /> */}
                  <Column
                    title="Total"
                    width="10em"
                    render={(text, record) => {
                      return (
                        <span>
                          {'R ' +
                            (this.state.stock[this.state.supplier][
                              record.stockid
                            ].total
                              ? this.state.stock[this.state.supplier][
                                  record.stockid
                                ].total.toFixed('2')
                              : '0.00')}
                        </span>
                      );
                    }}
                    key="total"
                  />
                </StyledTable>
              </Row>{' '}
            </Card>
            <div
              style={{
                transition: '1s',
                opacity: this.state.isScrolling ? 0 : 1,
                position: 'fixed',
                right: 5,
                bottom: 5,
                background: 'rgb(19, 36, 64)',
                padding: 0,
                paddingLeft: '10px',
                paddingRight: '10px',
                borderRadius: '3em',
                // width: "calc(100% - 80px)",
                height: '3.4em',
                zIndex: 99
              }}
              color="primary"
            >
              <Button
                style={{
                  float: 'right',
                  marginTop: 8,
                  color: 'white',
                  borderRadius: '3em',
                  border: '1px solid white',
                  backgroundColor: 'transparent',
                  width: '6em'
                }}
                onClick={() => this.getOrderItems()}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: 8,
                  marginRight: 8,
                  color: 'white',
                  borderRadius: '3em',
                  border: '1px solid rgba(0, 255, 112, 0.898)',
                  backgroundColor: 'transparent'
                }}
                onClick={() => this.addOrderItems(false)}
                color="primary"
              >
                Save As Draft
              </Button>
              {this.state.email !== '' ? (
                <Button
                  style={{
                    float: 'right',
                    marginTop: 8,
                    marginRight: 8,
                    color: 'white',
                    borderRadius: '3em',
                    border: '1px solid rgba(0, 255, 112, 0.898)',
                    backgroundColor: 'transparent'
                  }}
                  onClick={() => this.addOrderItems(true)}
                  color="primary"
                >
                  Save And Send
                </Button>
              ) : null}
            </div>
          </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  getOrderItems,
  getSupplierItems,
  addOrderItem,
  resendPurchaseOrder,
  updatePo,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  getPOReport,
  sendProofOfPayment,
  convertPOToDraft
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PurchaseOrder));
