import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon, Input, Row, Spin, Table, Popover, ConfigProvider, Empty, Radio } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  addUserGroup,
  deleteGroup,
  editGroup,
  getGroups
} from 'src/redux/actions/backend';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import {
  handleCloseAddGroupUser,
  handleCloseTableGroup,
  handleOpenAddGroupUser,
  handleOpenTableGroup,
  resetGroups
} from 'src/redux/actions/subnav';
import StyledTable from 'src/components/Table/StyledTable';
import Loading from 'react-loading-bar';
import ContentLoader from 'react-content-loader';
import GroupsForm from 'src/views/Admin/Groups/GroupForm';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import {
  FiEdit
} from 'react-icons/fi';
const { Column } = Table;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  }
});

let roles = [
  {
    value: 'isClerk',
    name: 'Sales'
  },
  {
    value: 'isAdmin',
    name: 'Admin'
  },
  {
    value: 'isKitchen',
    name: 'Kitchen'
  },
  {
    value: 'isBar',
    name: 'Bar'
  },
  {
    value: 'isDriver',
    name: 'Driver'
  }
];

const Skeleton = () => (
  <ContentLoader
    speed={0.5}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#c0c0c0"
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="46" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="92" rx="2" ry="2" width="100%" height="45" />
  </ContentLoader>
);

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 'isClerk',
      userGroupRoles: [],
      groupName: '',
      direct: false,
      groupRoles: [],
      groupRolesAdmin: {},
      groupRolesClerk: {},
      selectGroupRoles: [],
      loading: true,
      userlisthead: 'Add VPOS Group',
      userlistsub: 'Add a New Group',
      userlistbutton: 'ADD VPOS GROUP',
      alert: false,
      msg: [],
      head: '',
      main: true,
      multiOpen: false,
      activeFilter: true
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb)
    this.props.setBreadCrumbs(breadCrumbs)
  }
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop()
    this.props.setBreadCrumbs(breadCrumbs)
  }

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false)
      this.props.resetActionBreadCrumb()
      this.handleRemoveBreadCrumb()
    }
  }

  componentDidMount() {
    this.getGroups();
    this.props.handleOpenTableGroup();
  }

  getGroups = msg => {
    const token = this.props.local.user.token;
    this.props
      .getGroups(token)
      .then(result => {
        var oMode = result.data.results.mode[0].mode;
        var oRolesAdmin = [];
        Object.keys(oMode).map(
          key =>
            oMode[key] &&
            oRolesAdmin.push({
              name: key.charAt(0).toUpperCase() + key.slice(1),
              value: key
            })
        );
        if (!this.props.local.user.pack.isFull) {
          roles.splice(0, 1);
        }
        this.handleRemoveBreadCrumb()
        this.setState({
          role: this.props.local.user.pack.isFull ? 'isClerk' : 'isKitchen',
          userGroupRoles: result.data.results.roles,
          groupRoles: oRolesAdmin,
          groupRolesAdmin: result.data.results.mode[0].mode,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleOpenGroup = open => {
    this.handleAddBreadCrumb({ method: "handleCloseGroup", crumb: "Group" })
    this.setState({
      _id: '',
      role: this.props.local.user.pack.isFull ? 'isClerk' : 'isKitchen',
      groupName: '',
      direct: false,
      selectGroupRoles: [],
      userlisthead: 'Add VPOS Group',
      userlistsub: 'Add a New Group',
      userlistbutton: 'ADD VPOS GROUP',
      main: false
    });
  };

  handleCloseGroup = open => {
    this.handleRemoveBreadCrumb()
    this.setState({
      main: true
    });
  };

  handleSwitchGroup = on => {
    this.setState({
      direct: on
    });
  };

  handleOpenTable = open => {
    if (open) {
      this.props.handleOpenTableGroup();
    } else {
      this.props.handleCloseTableGroup();
    }
  };

  handleChangeRoleType = event => {
    this.setState({ role: event.target.value });
  };

  // handleChangeRoles = event => {
  //   if (!event.target.value[event.target.value.length - 1]) {
  //     this.setState({ multiOpen: false });
  //   } else {
  //     this.setState({ selectGroupRoles: event.target.value });
  //   }
  // };

  handleChangeRoles = value => {
    this.setState({ selectGroupRoles: value });
  };

  changeMultiOpen = multi => {
    this.setState({ multiOpen: multi });
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'User Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  submitFormGroup = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true, submitInProgress: true });
    if (this.state.userlistbutton === 'ADD VPOS GROUP') {
      this.props
        .addUserGroup(this.state, token)
        .then(res => {
          this.setState({
            role: this.props.local.user.pack.isFull ? 'isClerk' : 'isKitchen',
            groupName: '',
            direct: false,
            selectGroupRoles: [],
            main: true,
            submitInProgress: false
          });
          this.getGroups('User Group Creation Successful');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false })
          }
        });
    } else if (this.state.userlistbutton === 'EDIT VPOS GROUP') {
      this.props
        .editGroup(this.state, token)
        .then(res => {
          this.props.handleOpenTableGroup();
          this.setState({
            _id: '',
            role: this.props.local.user.pack.isFull ? 'isClerk' : 'isKitchen',
            groupName: '',
            submitInProgress: false
          });
          this.getGroups('Group Update Successful');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false })
          }
        });
    }
  };

  onChangeEvt = e => {
    if (e.target.id === 'groupname') {
      this.setState({ groupName: e.currentTarget.value });
    }
  };

  handleEditUser = oUser => {
    this.handleAddBreadCrumb({ method: "handleCloseGroup", crumb: "Group" })
    this.setState({
      _id: oUser._id,
      role: oUser.role,
      groupName: oUser.name,
      direct: oUser.direct,
      selectGroupRoles: oUser.modes,
      userlisthead: 'Edit VPOS Group',
      userlistsub: 'Edit a Users Group',
      userlistbutton: 'EDIT VPOS GROUP',
      main: false
    });
  };

  handleDeleteUser = oUser => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteGroup(oUser, token)
      .then(res => {
        this.getGroups('Group Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleRestoreUser = oUser => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteGroup(oUser, token)
      .then(res => {
        this.getGroups('Group Restore Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };
  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: "3em",
          marginBottom: "0.5em",
          height: 90,
        }} />

      <button
        style={{

          backgroundColor: '#00CC70',
          border: '0px solid rgb(30,161,169)',
          color: 'white',
          width: '12em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',
          borderRadius: '3em',
          marginTop: "1em",
          marginBottom: "1.5em",
        }}
        onClick={() => this.handleOpenGroup(!this.state.main)}
        color="primary"
      >
        {'Add Group'}
      </button>
    </div>
  );
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditUser: this.handleEditUser,
      handleDeleteUser: this.handleDeleteUser,
      handleRestoreUser: this.handleRestoreUser,
      handleChangeRoleType: this.handleChangeRoleType,
      handleChangeRoles: this.handleChangeRoles,
      submitFormGroup: this.submitFormGroup,
      changeMultiOpen: this.changeMultiOpen,
      onHelpMarker: this.onHelpMarker,
      onChangeEvt: this.onChangeEvt,
      handleCloseGroup: this.handleCloseGroup,
      handleSwitchGroup: this.handleSwitchGroup,
      roles: roles
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div style={{ width: "8em" }}>
        <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
          <Radio value={1}
            style={{
              paddingBottom: "0.5em",
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => this.handleFormatList(true)}
          >
            Active
          </Radio>
          <Radio value={2}
            style={{
              paddingBottom: "0.5em",
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => this.handleFormatList(false)}
          >
            Inactive
          </Radio>
          <Radio value={3}
            style={{
              paddingBottom: "0.5em",
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => this.handleFormatList(undefined)}
          >
            All
          </Radio>
        </Radio.Group>
      </div>
    );
    let groupsList = [];
    for (var i = 0; i < this.state.userGroupRoles.length; i++) {
      if (this.state.activeFilter === true) {
        if (this.state.userGroupRoles[i].active) {
          groupsList.push(this.state.userGroupRoles[i]);
        }
      } else if (this.state.activeFilter === false) {
        if (!this.state.userGroupRoles[i].active) {
          groupsList.push(this.state.userGroupRoles[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        groupsList.push(this.state.userGroupRoles[i]);
      }
    }

    return (
      <div>
        <Loading
          show={this.state.loading}
          showSpinner={false}
          color="rgb(15, 135, 123)"
        />
        {this.state.main ? (
          <div>
            <Button
              style={{
                position: 'fixed',
                right: '-0.6em',
                bottom: '5em',
                background: 'rgb(0, 204, 112)',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em 0em 0em 5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.handleOpenGroup(!this.state.main)}
              color="primary"
            >
              <Icon type="plus"
                style={{
                  position: 'absolute',
                  paddingLeft: 3
                }}
              >
              </Icon >
            </Button>
            {this.state.userGroupRoles.length > 0 ? <Row>
              <button
                style={{
                  marginTop: '-0.7em',
                  backgroundColor: '#00CC70',
                  border: '0px solid rgb(30,161,169)',
                  color: 'white',

                  width: '10.5em',
                  height: '2.7em',
                  fontSize: 16,
                  fontFamily: '',

                  borderRadius: '3em',
                  zIndex: 9,
                  position: 'absolute'
                }}
                onClick={() => this.handleOpenGroup(!this.state.main)}
                color="primary"
              >
                {'Add Group'}
              </button>
            </Row> : null}

            <Row>
              <div
                style={{
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                <Popover
                  placement="bottomRight"
                  content={GearContent}
                  title=""
                  trigger="click"
                >
                  <Button
                    style={{
                      visibility: 'hidden',
                      backgroundColor: 'white',
                      border: '1px solid rgb(15, 135, 123)',
                      color: 'rgb(15, 135, 123)',
                      marginBottom: '-0.5em',
                      height: '3.5em',
                      marginLeft: '-0.27em',
                      borderRadius: '0.3em',
                      transform: 'scale(0.9)',
                      width: '2em'
                    }}
                  >
                    <Icon type="setting" />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Row>
              <div
                style={{
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                <Popover overlayStyle={{ borderRadius: "3em" }}
                  placement="left"
                  content={EyeContent}
                  title="Table settings"
                  trigger="click"
                >
                  <Button
                    style={{
                      position: 'fixed',
                      right: '-0.6em',
                      bottom: '9em',
                      background: 'rgba(229, 233, 242, 1)',
                      border: '1px solid transparent',
                      color: 'rgba(0, 0, 0, 0.54)',
                      padding: '0em',
                      width: '3.5em',
                      height: '3.5em',
                      boxShadow: "none",
                      borderRadius: '5em 0em 0em 5em',
                      fontSize: '1em',
                      zIndex: 1000
                    }}
                  >
                    <Icon
                      style={{
                        paddingLeft: '2px'
                      }}
                      theme="filled"
                      type="setting"
                    />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Row>
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  locale={
                    this.state.loading
                      ? {
                        emptyText: <Skeleton />
                      }
                      : {}
                  }
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={groupsList}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (rowIndex % 2 === 0) {
                      return {
                        onClick: (e) => {
                          e.stopPropagation()
                          oState.handleEditUser(record)
                        }, style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: (e) => {
                          e.stopPropagation()
                          oState.handleEditUser(record)
                        },
                      }
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getColumnSearchProps('active')}
                    title="Active"
                    dataIndex="active"
                    key="active"
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div>
                        <IconButton style={{
                          width: "3em",
                          padding: "0.35em",
                          borderRadius: "3em",
                          backgroundColor: "rgb(0, 204, 112)",
                          border: '0px solid rgb(0, 204, 112)'
                        }} onClick={(e) => {
                          e.stopPropagation()
                          oState.handleEditUser(record)
                        }}>
                          {
                            <p style={{
                              fontSize: 13,
                              color: "white",
                              fontWeight: 700,
                              fontFamily: "Mulish , sans-serif",
                              margin: "auto"
                            }}>Edit</p>
                          }
                        </IconButton>
                        {record.active ?
                          <IconButton
                            style={{
                              marginRight: '0em',
                              marginLeft: '0.4em',
                              borderRadius: "4em",
                              backgroundColor: "rgba(229, 233, 242, 0.9)",
                              border: '1px solid rgba(229, 233, 242, 1)'
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              oState.handleDeleteUser(record)
                            }}
                          >
                            {
                              <Icon
                                type="delete"
                                style={{
                                  fontSize: 15,
                                }}
                              />
                            }
                          </IconButton>
                          :
                          <IconButton
                            style={{
                              border: '1px solid green',
                              marginLeft: '1em', padding: '16px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              oState.handleDeleteUser(record)
                            }}
                          >
                            {
                              <Icon
                                type="rollback"
                                style={{

                                  position: 'absolute',
                                  fontSize: '15px',
                                  color: 'green',

                                }}
                              />
                            }
                          </IconButton>
                        }
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : (
          <GroupsForm {...oState} />
        )}
        <Snackbar {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  groups: state.groups,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addUserGroup,
  getGroups,
  editGroup,
  deleteGroup,
  openSnackbar,
  closeSnackbar,
  resetGroups,
  handleOpenAddGroupUser,
  handleCloseAddGroupUser,
  handleOpenTableGroup,
  handleCloseTableGroup,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Groups));
