/* eslint-disable no-loop-func */
import { Button, Col, Layout, List, Row, notification } from 'antd';
import $ from 'jquery';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
const apiCall = 'vpos/api/vpos/bill/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Content } = Layout;

let loading = false;
let setLoading;
const deliverOrder = (order, props) => {
  if (window.cordova) {
    window.cordova.plugins.diagnostic.isGpsLocationEnabled(
      function(enabled) {
        if (enabled) {
          setDeliverOrder(order, props);
        } else {
          notification.error({
            message: 'Error',
            description: 'GPS not found, Turn on GPS'
          });
          return;
        }
      },
      function(error) {
        alert('The following error occurred: ' + error);
      }
    );
  } else {
    setDeliverOrder(order, props);
  }
};

const setDeliverOrder = (order, props) => {
  setLoading(true);
  var options = {
    enableHighAccuracy: true
  };

  navigator.geolocation.getCurrentPosition(
    position => {
      const currentLocation = new window.google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      const destination = new window.google.maps.LatLng(
        order.location.lat,
        order.location.lng
      );

      let service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [currentLocation],
          destinations: [destination],
          travelMode: 'DRIVING'
        },
        (response, status) => {
          let travelMatrix = response.rows[0].elements[0];
          travelMatrix.initialLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          $.ajax({
            type: 'POST',
            url: server + apiCall + 'driverupdateorder',
            data: JSON.stringify({
              travelMatrix: travelMatrix,
              billid: order.billid,
              state: 'delivery'
            }),
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            beforeSend: function(xhr) {
              xhr.setRequestHeader(
                'Authorization',
                'Bearer ' + props.user.token
              );
            },
            success: async data => {
              setLoading(false);
              notification.success({
                message: 'Success',
                description: 'Updated order status'
              });
              props.navToView('deliver', order);
            },
            error: async (xhr, ajaxOptions, thrownError) => {
              setLoading(false);
              if (xhr.responseText) {
                notification.error({
                  message: 'Error',
                  description: xhr.responseText
                });
              } else {
                notification.error({
                  message: 'Error',
                  description: 'Error placing order'
                });
              }
            }
          });
        }
      );
    },
    null,
    options
  );
};
const Orders = props => {
  [loading, setLoading] = useState(loading);
  useEffect(() => {
    props.getOrders(props);
    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white', paddingBottom: 170 }}>
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <h2
            style={{
              fontSize: 17,
              paddingLeft: '1em',
              margin: 'auto',
              marginBottom: '0.7em'
            }}
          >
            Active Orders
          </h2>
        </Col>
      </Row>
      <Row>
        <List
          dataSource={props.activeOrders}
          renderItem={item => (
            <List.Item
              style={{
                padding: '1em',
                backgroundColor: item.driverId ? '#c1ffc1' : 'white',
                marginBottom: '0.5em',
                boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.205)'
              }}
              key={item._id}
            >
              <List.Item.Meta
                id="MenuItemName"
                description={
                  <div>
                    <p style={{ margin: 0 }}>
                      <strong>Order:</strong> {item.orderno}
                    </p>
                    <p style={{ margin: 0 }}>
                      <strong>Name:</strong> {item.customerDetails.name}
                    </p>
                    <p style={{ margin: 0 }}>
                      <strong>Address:</strong> {item.address}
                    </p>
                    <p style={{ margin: 0 }}>
                      <strong>Number:</strong> {item.customerDetails.number}
                    </p>
                  </div>
                }
              />
              <div style={{ display: 'grid' }}>
                <Row type="flex" justify="space-around">
                  <span>{moment(item.proctime, 'HHmmss').format('HH:mm')}</span>
                </Row>
                <Row type="flex" justify="space-around">
                  <span
                    style={{
                      margin: 'auto',
                      marginTop: '0.7em',
                      fontSize: '1.2em',
                      color: 'black'
                    }}
                  >
                    <Button onClick={e => props.viewOrder(item)}>View</Button>
                  </span>
                </Row>
                <Row type="flex" justify="space-around">
                  <span
                    style={{
                      margin: 'auto',
                      marginTop: '0.7em',
                      fontSize: '1.2em',
                      color: 'black'
                    }}
                  >
                    {item.driverId ? (
                      <Button
                        loading={loading}
                        disabled={loading}
                        onClick={e => props.navToView('deliver', item)}
                      >
                        View Map
                      </Button>
                    ) : (
                      <Button
                        loading={loading}
                        disabled={loading}
                        onClick={e => deliverOrder(item, props)}
                      >
                        Deliver
                      </Button>
                    )}
                  </span>
                </Row>
              </div>
            </List.Item>
          )}
        />
      </Row>
    </Content>
  );
};

export default Orders;
