import React from 'react';
import {
  Spin,
  Table,
  Input,
  Icon,
  Row,
  Select,
  DatePicker,
  Button,
  Popover
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { getStockReport } from 'src/redux/actions/backend';
import withStyles from '@material-ui/core/styles/withStyles';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { openSnackbar, closeSnackbar } from 'src/redux/actions/snackbar';
import Highlighter from 'react-highlight-words';
import { CSVLink } from 'react-csv';
import stockreport from './stockreport.js';
// import Table from 'src/views/Admin/Reports/StockReport/Table.jsx';
import { setHelpMarker } from 'src/redux/actions/help';
import StyledTable from 'src/components/Table/StyledTable';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import convert from 'convert-units';
import {
  primaryColor,
  grayColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx';
const { Column } = Table;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { RangePicker } = DatePicker;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'inline'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    height: '150px',
    overflow: 'auto'
  },
  tableScroll: {
    width: '100%',
    height: '60vh',
    overflow: 'auto'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  datePicker: {
    marginRight: '10px',
    '& span': {
      height: '45px'
    }
  },
  noStock: {
    background: 'mistyrose'
  }
});

const dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: []
};

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stock: [],
      datePicker: false,
      startDate: moment().format(dateFormatSearch),
      endDate: moment().format(dateFormatSearch),
      pickerStart: moment().startOf('month'),
      pickerEnd: moment(),
      csvData: undefined,
      alert: false,
      msg: [],
      head: ''
    };
  }

  componentDidMount() {
    this.getStockReport();
  }

  getStockReport = (msg, startDate, endDate) => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    var start = startDate;
    var end = endDate;
    if (!start) {
      start = this.state.startDate;
    }
    if (!endDate) {
      end = this.state.endDate;
    }

    this.props
      .getStockReport(token, start, end)
      .then(result => {
        let tableBody = [];
        tableBody.push([
          'Name',
          'Type',
          'Opening Stock',
          'Sold',
          'Sold Stock Value',
          'Closing Stock',
          'Retail Price',
          'Total Retail'
        ]);
        result.data.results.forEach(record => {
          let lineItem = [];

          lineItem.push(record.name);
          lineItem.push(record.type);
          lineItem.push(
            (((record.qty ? record.qty : 0) + record.stockUsed)
              .toFixed('2')
              .includes('.00')
              ? ((record.qty ? record.qty : 0) + record.stockUsed).toFixed('0')
              : ((record.qty ? record.qty : 0) + record.stockUsed).toFixed(
                  '2'
                )) +
              ' ' +
              (record.dimType ? record.dimType : record.dimension)
          );
          lineItem.push(
            ((record.stockUsed ? record.stockUsed : 0)
              .toFixed('2')
              .includes('.00')
              ? (record.stockUsed ? record.stockUsed : 0).toFixed('0')
              : (record.stockUsed ? record.stockUsed : 0).toFixed('2')) +
              ' ' +
              (record.dimType ? record.dimType : record.dimension)
          );
          lineItem.push(
            (
              (record.buyPrice ? parseFloat(record.buyPrice) : 0) *
              (record.stockUsed ? record.stockUsed : 0)
            ).toFixed('2')
          );
          lineItem.push(
            ((record.qty ? record.qty : 0).toFixed('2').includes('.00')
              ? (record.qty ? record.qty : 0).toFixed('0')
              : (record.qty ? record.qty : 0).toFixed('2')) +
              ' ' +
              (record.dimType ? record.dimType : record.dimension)
          );
          lineItem.push(
            record.sellPrice
              ? (record.sellPrice ? record.sellPrice : 0)
                  .toFixed('2')
                  .includes('.00')
              : '0.00'
          );
          lineItem.push(
            record.sellPrice
              ? (record.stockUsed ? record.sellPrice * record.stockUsed : 0)
                  .toFixed('2')
                  .includes('.00')
              : '0.00'
          );

          tableBody.push(lineItem);
        });
        this.setState({
          csvData: tableBody,
          stock: result.data.results,
          loading: false,
          filteredValue: undefined
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  exportPDF = () => {
    this.setState({ loading: true });
    const pdfMake = require('pdfmake/build/pdfmake');
    const vfsFonts = require('pdfmake/build/vfs_fonts');
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let pdfDocGenerator = pdfMake.createPdf(
      stockreport(
        this.state.currentTableData
          ? this.state.currentTableData
          : this.state.stock,
        this.state.startDate,
        this.state.endDate
      )
    );
    pdfDocGenerator.download(
      'Stock Report - ' +
        moment(this.state.startDate, 'YYYYMMDD').format('YYYY/MM/DD') +
        ' to ' +
        moment(this.state.endDate, 'YYYYMMDD').format('YYYY/MM/DD')
    );
    this.setState({ loading: false });
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        startDate: value[0].format(dateFormatSearch),
        endDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1]
      });
    }
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'yester') {
        this.setState(
          {
            startDate: moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            endDate: moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            datePicker: false
          },
          this.getStockReport(
            false,
            moment()
              .subtract(1, 'days')
              .format(dateFormatSearch),
            moment()
              .subtract(1, 'days')
              .format(dateFormatSearch)
          )
        );
      } else if (value === 'today') {
        this.setState({
          startDate: moment().format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getStockReport(
          false,
          moment().format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'last7') {
        this.setState({
          startDate: moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getStockReport(
          false,
          moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'last30') {
        this.setState({
          startDate: moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getStockReport(
          false,
          moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'thism') {
        this.setState({
          startDate: moment()
            .startOf('month')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
        this.getStockReport(
          false,
          moment()
            .startOf('month')
            .format(dateFormatSearch),
          moment().format(dateFormatSearch)
        );
      } else if (value === 'lastm') {
        const start = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format(dateFormatSearch);
        const end = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format(dateFormatSearch);
        this.setState({
          startDate: start,
          endDate: end,
          datePicker: false
        });
        this.getStockReport(false, start, end);
      } else if (value === 'custom') {
        this.setState({
          datePicker: true
        });
      }
    }
  };

  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Menu Items Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };
  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker
    };
    let filters = [];
    for (var i = 0; i < this.state.stock.length; i++) {
      if (this.state.stock[i].type) {
        if (
          filters.findIndex(
            filter => filter.text === this.state.stock[i].type
          ) === -1
        ) {
          filters.push({
            text: this.state.stock[i].type,
            value: this.state.stock[i].type
          });
        }
      }
    }
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '3em',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '3em',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <div>
          <Row>
            <div
              style={{
                float: 'left',
                marginTop: '-10px',
                marginBottom: '30px'
              }}
            >
              {this.state.datePicker ? (
                <div>
                  <RangePicker
                    allowClear={false}
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDate}
                    value={[this.state.pickerStart, this.state.pickerEnd]}
                    format={dateFormat}
                  />
                  <Button
                    style={{
                      backgroundColor: '#00CC70',
                      color: 'white',
                      top: '-0.05em',
                      height: '40px',
                      width: '120px',
                      border: '1px solid #00CC70',
                      borderRadius: '3em',
                      fontSize: '12px',
                      paddingBottom: '0.1em',
                      fontWeight: '600'
                    }}
                    onClick={() => this.getStockReport()}
                  >
                    Get Report
                  </Button>
                </div>
              ) : null}
              <Select
                id="filterSelect"
                style={{
                  width: '150px',
                  paddingRight: '10px'
                }}
                defaultValue="today"
                onChange={this.handleChangeSelect}
              >
                <Option value="today">Today</Option>
                <Option value="yester">Yesterday</Option>
                <Option value="last7">Last 7 Days</Option>
                <Option value="last30">Last 30 Days</Option>
                <Option value="thism">This Month</Option>
                <Option value="lastm">Last Month</Option>
                <Option value="custom">Custom</Option>
              </Select>
            </div>
            {this.state.stock.length ? (
              <div
                style={{
                  float: 'right'
                }}
              >
                <Button
                  onClick={this.exportPDF}
                  style={{
                    backgroundColor: '#00CC70',
                    border: '0px solid rgb(30, 161, 169)',
                    color: 'white',
                    marginBottom: '-0.5em',
                    height: '40px',
                    marginLeft: '-0.27em',
                    borderRadius: '3em'
                  }}
                >
                  Export to PDF
                </Button>
              </div>
            ) : null}
            {this.state.stock.length ? (
              <div
                style={{
                  float: 'right'
                }}
              >
                <Button
                  style={{
                    backgroundColor: '#00CC70',
                    border: '0px solid rgb(30, 161, 169)',
                    color: 'white',
                    marginBottom: '-0.5em',
                    height: '40px',
                    marginRight: 20,
                    borderRadius: '3em'
                  }}
                >
                  <CSVLink
                    filename={
                      'Stock Report - ' +
                      moment(this.state.startDate, 'YYYYMMDD').format(
                        'YYYY/MM/DD'
                      ) +
                      ' to ' +
                      moment(this.state.endDate, 'YYYYMMDD').format(
                        'YYYY/MM/DD'
                      ) +
                      '.csv'
                    }
                    data={
                      this.state.csvData ? this.state.csvData : [['No', 'Data']]
                    }
                  >
                    Export to CSV
                  </CSVLink>
                </Button>
              </div>
            ) : null}
          </Row>
          <Card className={this.props.classes.cardExpand}>
            <CardBody>
              <StyledTable
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                pagination={{
                  locale: { items_per_page: ' Items' },
                  showSizeChanger: true,
                  onShowSizeChange: this.onShowSizeChange,
                  pageSizeOptions: ['10', '25', '50', '100'],
                  hideOnSinglePage: true,
                  pageSize: this.state.pageSize || 25,
                  itemRender: itemRender,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                  defaultCurrent: 1
                }}
                dataSource={this.state.stock}
                onChange={(a, b, c, currentData) => {
                  let tableBody = [];
                  tableBody.push([
                    'Name',
                    'Type',
                    'Opening Stock',
                    'Sold',
                    'Sold Stock Value',
                    'Closing Stock',
                    'Retail Price',
                    'Total Retail'
                  ]);
                  currentData.currentDataSource.forEach(record => {
                    let lineItem = [];

                    lineItem.push(record.name);
                    lineItem.push(record.type);
                    lineItem.push(
                      (((record.qty ? record.qty : 0) + record.stockUsed)
                        .toFixed('2')
                        .includes('.00')
                        ? (
                            (record.qty ? record.qty : 0) + record.stockUsed
                          ).toFixed('0')
                        : (
                            (record.qty ? record.qty : 0) + record.stockUsed
                          ).toFixed('2')) +
                        ' ' +
                        (record.dimType ? record.dimType : record.dimension)
                    );
                    lineItem.push(
                      ((record.stockUsed ? record.stockUsed : 0)
                        .toFixed('2')
                        .includes('.00')
                        ? (record.stockUsed ? record.stockUsed : 0).toFixed('0')
                        : (record.stockUsed ? record.stockUsed : 0).toFixed(
                            '2'
                          )) +
                        ' ' +
                        (record.dimType ? record.dimType : record.dimension)
                    );
                    lineItem.push(
                      (
                        (record.buyPrice ? parseFloat(record.buyPrice) : 0) *
                        (record.stockUsed ? record.stockUsed : 0)
                      ).toFixed('2')
                    );
                    lineItem.push(
                      ((record.qty ? record.qty : 0)
                        .toFixed('2')
                        .includes('.00')
                        ? (record.qty ? record.qty : 0).toFixed('0')
                        : (record.qty ? record.qty : 0).toFixed('2')) +
                        ' ' +
                        (record.dimType ? record.dimType : record.dimension)
                    );
                    lineItem.push(
                      record.sellPrice
                        ? (record.sellPrice ? record.sellPrice : 0)
                            .toFixed('2')
                            .includes('.00')
                        : '0.00'
                    );
                    lineItem.push(
                      record.sellPrice
                        ? (record.stockUsed
                            ? record.sellPrice * record.stockUsed
                            : 0
                          )
                            .toFixed('2')
                            .includes('.00')
                        : '0.00'
                    );
                    tableBody.push(lineItem);
                  });
                  this.setState({
                    currentTableData: currentData.currentDataSource,
                    csvData: tableBody
                  });
                }}
                onRow={(record, rowIndex) => {
                  if (record.active && record.qty <= record.lowqty) {
                    if (record.qty <= 0) {
                      return {
                        style: {
                          background: 'rgba(255, 4, 4, 0.063)',
                          color: 'red'
                        }
                      };
                    } else {
                      return { style: { background: 'lightyellow' } };
                    }
                  }
                }}
              >
                <Column
                  {...this.getColumnSearchProps('name')}
                  title="Name"
                  dataIndex="name"
                  key="name"
                />
                <Column
                  //{...this.getColumnSearchProps('type')}
                  filters={filters}
                  onFilter={(value, record) => {
                    if (record.type) {
                      return record.type.includes(value);
                    } else return false;
                  }}
                  title="Type"
                  dataIndex="type"
                  key="type"
                />
                <Column
                  title="Opening Stock"
                  render={(text, record) => (
                    <span>
                      {(((record.qty ? record.qty : 0) + record.stockUsed)
                        .toFixed('2')
                        .includes('.00')
                        ? (
                            (record.qty ? record.qty : 0) + record.stockUsed
                          ).toFixed('0')
                        : (
                            (record.qty ? record.qty : 0) + record.stockUsed
                          ).toFixed('2')) +
                        ' ' +
                        (record.dimType ? record.dimType : record.dimension)}
                    </span>
                  )}
                />
                <Column
                  title="Sold"
                  render={(text, record) => (
                    <span>
                      {((record.stockUsed ? record.stockUsed : 0)
                        .toFixed('2')
                        .includes('.00')
                        ? (record.stockUsed ? record.stockUsed : 0).toFixed('0')
                        : (record.stockUsed ? record.stockUsed : 0).toFixed(
                            '2'
                          )) +
                        ' ' +
                        (record.dimType ? record.dimType : record.dimension)}
                    </span>
                  )}
                />
                <Column
                  title="Sold Stock Value"
                  render={(text, record) => (
                    <span>
                      R
                      {(
                        (record.buyPrice ? parseFloat(record.buyPrice) : 0) *
                        (record.stockUsed ? record.stockUsed : 0)
                      ).toFixed('2')}
                    </span>
                  )}
                />
                <Column
                  title="Closing Stock"
                  render={(text, record) => (
                    <span>
                      {((record.qty ? record.qty : 0)
                        .toFixed('2')
                        .includes('.00')
                        ? (record.qty ? record.qty : 0).toFixed('0')
                        : (record.qty ? record.qty : 0).toFixed('2')) +
                        ' ' +
                        (record.dimType ? record.dimType : record.dimension)}
                    </span>
                  )}
                />
                <Column
                  title="Retail Price"
                  render={(text, record) => (
                    <span>
                      R
                      {record.sellPrice
                        ? (record.sellPrice ? record.sellPrice : 0)
                            .toFixed('2')
                            .includes('.00')
                        : '0.00'}
                    </span>
                  )}
                />
                <Column
                  title="Total Retail"
                  render={(text, record) => (
                    <span>
                      R
                      {record.sellPrice
                        ? (record.stockUsed
                            ? record.sellPrice * record.stockUsed
                            : 0
                          )
                            .toFixed('2')
                            .includes('.00')
                        : '0.00'}
                    </span>
                  )}
                />
              </StyledTable>
            </CardBody>
          </Card>
          <Snackbar {...this.props} />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar
});

const mapDispatchToProps = {
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  getStockReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Reports));
