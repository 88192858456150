import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  List,
  Row,
  Select,
  Spin,
  Table
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { MdKeyboardBackspace } from 'react-icons/md';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  getBillItemsCashup,
  getUsers,
  getUsersVoidReport
} from 'src/redux/actions/backend';
import { openSnackbar } from 'src/redux/actions/snackbar';
const { Option } = Select;
const { Column } = Table;
const dateFormat = 'YYYY/MM/DD';
const dateFormatSearch = 'YYYYMMDD';
const { RangePicker } = DatePicker;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'whitesmoke',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  },
  show: {
    display: 'inline'
  },
  datePicker: {
    marginRight: '10px',
    '& span': {
      height: '45px'
    }
  }
});

class Void extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      datePicker: false,
      startDate: moment().format(dateFormatSearch),
      endDate: moment().format(dateFormatSearch),
      pickerStart: moment().startOf('month'),
      pickerEnd: moment(),
      loading: true,
      alert: false,
      selectedUser: 'all',
      selectedUserName: '',
      msg: [],
      head: '',
      main: true,
      showBill: false
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    this.props
      .getUsers(token)
      .then(result => {
        if (!result.data.results.users) {
          result.data.results.users = [];
        }
        result.data.results.users.unshift({ name: 'All', _id: 'all' });
        this.setState({
          users: result.data.results.users,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  }

  getReport = msg => {
    this.setState({
      loading: true
    });
    const token = this.props.local.user.token;
    this.props
      .getUsersVoidReport(
        token,
        this.state.startDate,
        this.state.endDate,
        this.state.selectedUser
      )
      .then(result => {
        this.setState({
          bills: result.data.results.bills,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleChangeUser = (value, eventN) => {
    let event = eventN;
    let name = '';
    if (event.props.children !== 'All') {
      name = event.props.children;
    }
    this.setState({ selectedUser: value, selectedUserName: name, bills: [] });
  };

  handleChangeSelect = value => {
    if (value) {
      if (value === 'today') {
        this.setState({
          startDate: moment().format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
      } else if (value === 'yester') {
        this.setState({
          startDate: moment()
            .subtract(1, 'days')
            .format(dateFormatSearch),
          endDate: moment()
            .subtract(1, 'days')
            .format(dateFormatSearch),
          datePicker: false
        });
      } else if (value === 'last7') {
        this.setState({
          startDate: moment()
            .subtract(7, 'd')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
      } else if (value === 'last30') {
        this.setState({
          startDate: moment()
            .subtract(30, 'd')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
      } else if (value === 'thism') {
        this.setState({
          startDate: moment()
            .startOf('month')
            .format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false
        });
      } else if (value === 'lastm') {
        this.setState({
          startDate: moment()
            .subtract(1, 'months')
            .startOf('month')
            .format(dateFormatSearch),
          endDate: moment()
            .subtract(1, 'months')
            .endOf('month')
            .format(dateFormatSearch),
          datePicker: false
        });
      } else if (value === 'custom') {
        this.setState({
          datePicker: true
        });
      }
    }
  };

  handleChangeDate = value => {
    if (value) {
      this.setState({
        startDate: value[0].format(dateFormatSearch),
        endDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1]
      });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'User Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  back = () => {
    this.setState({ showBill: false });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getBillItems = bill => {
    const token = this.props.local.user.token;
    this.props
      .getBillItemsCashup(token, bill._id)
      .then(result => {
        this.setState({
          bill: result.data.results.bill,
          main: false,
          showBill: true,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(prevState => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize
    }));
  };
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditUser: this.handleEditUser
    };
    let items = [];
    let total = 0;
    if (this.state.bill && this.state.bill.items) {
      this.state.bill.items.forEach(item => {
        if (item.voidReasons) {
          const a = _(item.voidReasons)
            .groupBy('reason')
            .values()
            .map(group => {
              return { ...group[0], qty: group.length * item.initQty || 1 };
            })
            .value();
          const b = _(item.voidReasons)
            .groupBy('waste')
            .values()
            .map(group => {
              return { ...group[0], qty: group.length * item.initQty || 1 };
            })
            .value();
          b.forEach(reason => {
            total = total + reason.qty * item.price;
            items.push({
              name: item.name,
              price: item.price,
              ...reason
            });
          });
        }
      });
    }

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.showBill ? (
          <div>
            <Card style={{ marginTop: 0 }}>
              {/*}  <CardHeader
                style={{
                  borderRadius: '0.5em',
                  background:
                    'linear-gradient(90deg, rgba(38,125,117,1) 0%, rgba(12,152,139,1) 35%, rgba(19,173,159,1) 100%)'
                }}
              >
                {' '}
                <h4
                  style={{ color: 'white' }}
                  className={this.props.classes.cardTitleWhite}
                >
                  Bill #{this.state.bill.orderno}
                </h4>
                <p
                  style={{ color: 'rgba(255, 255, 255, 0.63)' }}
                  className={this.props.classes.cardCategoryWhite}
                >
                  Bill details
                </p>
              </CardHeader> */}
              <CardBody>
                <GridContainer>
                  <MdKeyboardBackspace
                    style={{
                      color: '#132440',
                      fontSize: '25px',
                      marginBottom: '-0.5em',
                      marginLeft: '0.5em'
                    }}
                    onClick={() => this.back()}
                    color="primary"
                  />
                  {/*<Button
                    style={{ 
                      left: '10px',
                      width:"6.3em",
                      height:"3em",
                    fontSize: "13px",
                    float: "right",
                    borderRadius: "3.5em",
                    backgroundColor: "rgba(229, 233, 242, 0.5)",
                    border: "1px solid rgba(229, 233, 242, 0.5)",
                    color: "black",
                    boxShadow:"none",
                    paddingBottom:"0.2em",
                  position:"absolute" }}
                    onClick={() => this.back()}
                    color="primary"
                  >
                    <Icon type="arrow-left" />
                  </Button>*/}
                  {/*<h4
                    style={{ color: 'black',margin:"auto" }}
                    className={this.props.classes.cardTitleWhite}
                  >
                    Bill #{this.state.bill.orderno}
                  </h4>*/}
                </GridContainer>

                <Card
                  style={{
                    boxShadow: 'none',
                    borderRadius: '6px',
                    padding: '7px',
                    margin: 'auto',
                    width: '50%'
                  }}
                >
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{
                      height: 70,
                      fontWeight: 600,
                      background: '#272930',
                      paddingLeft: '14px',
                      paddingRight: '14px',
                      border: 'solid 1px lightgray',
                      borderRadius: '6px',
                      borderBottomLeftRadius: '0px',
                      borderBottomRightRadius: '0px'
                    }}
                  >
                    <Col
                      span={6}
                      style={{
                        fontSize: '13px',
                        marginTop: 15,
                        color: 'white'
                      }}
                    >
                      Order <br /> #{this.state.bill.orderno}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: '13px',
                        marginTop: 15,
                        color: 'white'
                      }}
                    >
                      Type <br />
                      {this.state.bill.table
                        ? 'Table #' + this.state.bill.table
                        : this.state.bill.type}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: '13px',
                        marginTop: 15,
                        color: 'white'
                      }}
                    >
                      Description <br />
                      {this.state.bill.desc}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: '13px',
                        marginTop: 15,
                        color: 'white'
                      }}
                    >
                      {' '}
                      Date <br />
                      {this.state.bill.date
                        ? this.state.bill.date.slice(0, 4) +
                          '-' +
                          this.state.bill.date.slice(4, 6) +
                          '-' +
                          this.state.bill.date.slice(6, 8) +
                          ' '
                        : 'N/A'}
                    </Col>
                    {/* <Col
                      span={2}
                      style={{
                        marginTop: 5,
                        display:"flex",
                        fontSize: '25px',
                        fontWeight: 'bold',
                        color: 'white'
                      }}
                      onClick={this.emailBill}
                    >
                      <Icon style={{margin:"auto"}} theme="filled" type="mail" />
                    </Col>
                    <Col
                      span={2}
                      style={{
                        display:"flex",
                        marginTop: 5,
                        fontSize: '25px',
                        fontWeight: 'bold',
                        color: 'white'
                      }}
                      onClick={this.printBill}
                    >
                      <Icon style={{margin:"auto"}} theme="filled" type="printer" />
                    </Col> */}
                  </Row>

                  <List
                    style={{
                      background: 'rgba(229, 233, 242, 0.5)',
                      paddingLeft: '1em',
                      paddingRight: '1em',
                      border: 'solid 0px lightgray',
                      borderRadius: '6px',
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '0px',
                      marginTop: '0.4em'
                    }}
                    className="bill-item-list"
                    itemLayout="horizontal"
                    dataSource={items}
                    renderItem={(item, iKey) => (
                      <List.Item>
                        <Row
                          style={{ width: '100%' }}
                          type="flex"
                          justify="space-around"
                        >
                          <Col span={6}>{item.name}</Col>
                          <Col span={2}>{item.qty}</Col>
                          <Col span={4}>{'R ' + item.qty * item.price}</Col>
                          <Col span={4}>{item.reason}</Col>
                          <Col span={4}>{item.waste ? 'Made' : 'Unmade'}</Col>
                        </Row>
                      </List.Item>
                    )}
                  />

                  <div
                    style={{
                      textAlign: 'right',
                      paddingTop: '0%',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      fontWeight: 600
                    }}
                  >
                    <p
                      className="bill-sub-total"
                      style={{
                        marginLeft: '0.5em',
                        fontSize: '24px'
                      }}
                    >
                      R {total}
                    </p>
                    <p
                      className="bill-sub-label"
                      style={{
                        fontSize: '24px'
                      }}
                    >
                      Total :
                    </p>
                  </div>
                </Card>
              </CardBody>
            </Card>
          </div>
        ) : (
          <div>
            <Row>
              <div
                style={{
                  float: 'left',
                  marginTop: '-10px',
                  marginBottom: '10px'
                }}
              >
                <div
                  className={
                    this.state.datePicker
                      ? this.props.classes.show
                      : this.props.classes.hide
                  }
                >
                  <RangePicker
                    allowClear={false}
                    className={this.props.classes.datePicker}
                    onChange={this.handleChangeDate}
                    value={[this.state.pickerStart, this.state.pickerEnd]}
                    format={dateFormat}
                  />
                </div>
                <Select
                  showSearch
                  id="filterSelect"
                  style={{
                    width: '150px',
                    paddingRight: '10px'
                  }}
                  value={this.state.selectedUser}
                  placeholder="Select user..."
                  onChange={this.handleChangeUser}
                >
                  {this.state.users.map(user => {
                    return (
                      <Option key={user._id} value={user._id}>
                        {user.name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  id="filterSelect"
                  style={{
                    width: '150px',
                    paddingRight: '10px'
                  }}
                  defaultValue="today"
                  onChange={this.handleChangeSelect}
                >
                  <Option value="today">Today</Option>
                  <Option value="yester">Yesterday</Option>
                  <Option value="last7">Last 7 Days</Option>
                  <Option value="last30">Last 30 Days</Option>
                  <Option value="thism">This Month</Option>
                  <Option value="lastm">Last Month</Option>
                  <Option value="custom">Custom</Option>
                </Select>
                <Button
                  style={{
                    backgroundColor: '#00CC70',
                    color: 'white',
                    top: '-0.05em',
                    height: '40px',
                    width: '120px',
                    border: '1px solid #00CC70',
                    borderRadius: '3em',
                    fontSize: '12px',
                    paddingBottom: '0.1em',
                    fontWeight: '600'
                  }}
                  onClick={() => this.getReport()}
                >
                  Get Report
                </Button>
              </div>
            </Row>
            <Row>
              <StyledTable
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                dataSource={this.state.bills}
                pagination={{
                  locale: { items_per_page: ' Items' },
                  showSizeChanger: true,
                  onShowSizeChange: this.onShowSizeChange,
                  pageSizeOptions: ['10', '25', '50', '100'],
                  hideOnSinglePage: true,
                  pageSize: this.state.pageSize || 25,
                  itemRender: (current, type, originalElement) => {
                    if (type === 'prev') {
                      return <a>Previous</a>;
                    }
                    if (type === 'next') {
                      return <a>Next</a>;
                    }
                    return originalElement;
                  },
                  showTotal: (total, range) =>
                    `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                  defaultCurrent: 1
                }}
                onChange={this.handleSort}
                onRow={(record, rowIndex) => {}}
              >
                <Column title="Bill #" dataIndex="orderno" key="orderno" />
                <Column
                  title="User"
                  dataIndex="name"
                  key="name"
                  render={(text, record) => (
                    <span>
                      {record.name
                        ? record.name
                        : this.state.selectedUserName
                        ? this.state.selectedUserName
                        : 'User not Found'}
                    </span>
                  )}
                />
                <Column
                  title="Date Created"
                  render={(text, record) => (
                    <span>
                      {record.date.slice(0, 4) +
                        '-' +
                        record.date.slice(4, 6) +
                        '-' +
                        record.date.slice(6, 8) +
                        ' ' +
                        record.date.slice(8, 10) +
                        ':' +
                        record.date.slice(10, 12)}
                    </span>
                  )}
                />
                <Column
                  title="Date End"
                  render={(text, record) => (
                    <span>
                      {record.moddate.slice(0, 4) +
                        '-' +
                        record.moddate.slice(4, 6) +
                        '-' +
                        record.moddate.slice(6, 8) +
                        ' ' +
                        record.moddate.slice(8, 10) +
                        ':' +
                        record.moddate.slice(10, 12)}
                    </span>
                  )}
                />
                <Column
                  title="Transaction Type"
                  render={(text, record) => (
                    <span>
                      {record.cash > 0 && record.card > 0
                        ? 'Cash and Card'
                        : record.cash > 0
                        ? 'Cash'
                        : record.card > 0
                        ? 'Card'
                        : record.tab > 0
                        ? 'Tab'
                        : record.yoco > 0
                        ? 'Yoco'
                        : 'None'}
                    </span>
                  )}
                />
                <Column
                  title="Void Total"
                  render={(text, record) => {
                    let total = 0;
                    record.items.map(item => {
                      total =
                        total +
                        item.voidReasons.length *
                          (item.price * (item.initQty || 1));
                    });
                    return <span>R {total}</span>;
                  }}
                />
                <Column
                  title="Actions"
                  render={(text, record) => (
                    <div>
                      <IconButton
                        style={{
                          marginRight: '0em',
                          marginLeft: '0.4em',
                          borderRadius: '4em',
                          backgroundColor: 'rgba(229, 233, 242, 0.9)',
                          border: '1px solid rgba(229, 233, 242, 1)'
                        }}
                        onClick={() => this.getBillItems(record)}
                      >
                        {
                          <Icon
                            type="eye"
                            style={{
                              fontSize: 13
                            }}
                          />
                        }
                      </IconButton>
                    </div>
                  )}
                />
              </StyledTable>
            </Row>
          </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  users: state.users,
  orgData: state.backend.orgname
});

const mapDispatchToProps = {
  getUsers,
  getUsersVoidReport,
  openSnackbar,
  getBillItemsCashup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Void));
