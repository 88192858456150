import styled from 'styled-components';

export default styled.div`
  padding: 90px 1rem 0;
  margin: 0 auto;
  // @media (min-width: 801px) {
  //   width: 80vw;
  // }
  max-width: 1500px;
`;
