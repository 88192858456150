export const initialState = {
  breadCrumbs: [],
  actionCrumb: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BREADCRUMBS': {
      return {
        ...state,
        breadCrumbs: action.data
      };
    }
    case 'ACTION_BREADCRUMB': {
      return {
        ...state,
        actionCrumb: action.data
      };
    }
    case 'RESET_ACTION_BREADCRUMB': {
      return {
        ...state,
        actionCrumb: undefined
      };
    }
    default:
      return state;
  }
};
