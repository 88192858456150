import React, { memo } from "react";
import KeyPad from "./KeyPad.jsx";

const NumberInput = ({click,clear,backspace}) => {

  return (
    <div>
      <KeyPad click={click} clear={clear} backspace={backspace}/>
    </div>
  );
};

export default memo(NumberInput);
