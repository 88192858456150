import { Col, Drawer, Input, Layout, Menu, Row } from 'antd';
import React, { memo } from 'react';
import logo from 'src/static/vpos-logo-white-1.png';
import styled from 'styled-components';
import './Header.css';
const { Header, Content } = Layout;
const { SubMenu } = Menu;
const { Search } = Input;
const StyledSearch = styled(Search)`
  .ant-input-clear-icon {
    font-size: 1.8em;
    color: white;
    padding: 0.18em 0.1em 1em 0em;
    border-radius: 0em 1em 1em 0em;
    height: 1.38em;
    margin: auto;
    background-color: #00cc702d;
    border-left: 1px solid #00cc70a6;
    margin-right: -0.48em;
    width: 2.3em;
    text-align: center;
  }
  .ant-input-search-icon {
    display: none;
  }
  .ant-input {
    border: 1px solid #00cc70;
    border-radius: 4em;
    color: white !important;
    background-color: transparent;
  }
  .ant-input::placeholder {
    color: #00cc70;
  }
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
  }
`;
const DashHead = ({ ...oState }) => {
  return (
    <Header className={'sales-header-' + oState.themeMode}>
      <Row style={{ height: 40 }}>
        <Col span={1} style={{ display: 'flex', height: '100%' }}>
          <img
            className={'sales-header-logo-' + oState.themeMode}
            src={logo}
            alt="Logo"
          />
        </Col>
        <Col span={3} />
        <Col span={10} />
        <StyledSearch
          placeholder="Search item"
          onChange={input => oState.search(input.target.value)}
          onSearch={value => oState.search(value)}
          allowClear={true}
          style={{
            width: '39%',
            height: '2.5em',
            position: 'absolute',
            left: '17em',
            marginTop: '0.25em'
          }}
        />

        <Col span={7} style={{ display: 'flex' }} />
      </Row>
    </Header>
  );
};
const DashHeader = memo(DashHead);
export default DashHeader;
