/* eslint-disable no-loop-func */
import { Button, Col, Layout, notification, Row } from 'antd';
import { InfoWindow, Map, Marker } from 'google-maps-react';
import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
import markerIcon from 'src/static/marker-icon.svg';

const apiCall = 'vpos/api/vpos/bill/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Content } = Layout;
let order;
let user;
const init = (map, markerRef) => {
  setInfo({
    marker: markerRef.current.marker,
    visible: true
  });
  mapRef = map;
  map.fitBounds(bounds);
};
let setInfo;
let infoState = {
  visible: false,
  marker: undefined
};
let bounds;
let locationState;
let marker;
let mapRef;
let update = false;
let setUpdate;
var options = {
  enableHighAccuracy: true
};
let liveLocationInterval;
const updateLiveLocation = position => {
  if (locationState === undefined) {
    // eslint-disable-next-line
    bounds.extend(
      new window.google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      )
    );
    mapRef.fitBounds(bounds);
  }
  // eslint-disable-next-line
  locationState = new google.maps.LatLng(
    position.coords.latitude,
    position.coords.longitude
  );
  setUpdate(!update);
};
const MyMapComponent = props => {
  const markerRef = props.markerRef;
  [infoState, setInfo] = useState(infoState);
  [update, setUpdate] = useState(update);
  if (locationState !== undefined) {
    if (marker === undefined) {
      // eslint-disable-next-line
      marker = new google.maps.Marker({
        map: mapRef,
        icon: markerIcon,
        position: locationState
      });
    }
  }
  if (marker) {
    marker.setPosition(locationState);
  }
  useEffect(() => {
    liveLocationInterval = navigator.geolocation.watchPosition(
      position => {
        updateLiveLocation(position);
      },
      null,
      options
    );
    // eslint-disable-next-line
    bounds = new window.google.maps.LatLngBounds();
    // eslint-disable-next-line
    bounds.extend(
      new window.google.maps.LatLng(order.location.lat, order.location.lng)
    );
    return () => {
      navigator.geolocation.clearWatch(liveLocationInterval);
    };
  }, []);
  return (
    <Map
      google={window.google}
      onReady={(props, map) => init(map, markerRef)}
      style={{
        width: '100%',
        height: '100%'
      }}
      initialCenter={order.location}
      disableDefaultUI={true}
    >
      <Marker
        onClick={(props, marker, e) => {
          setInfo({
            marker: markerRef.current.marker,
            visible: false
          });
          setInfo({
            marker: markerRef.current.marker,
            visible: true
          });
        }}
        ref={markerRef}
        position={order.location}
        id={1}
      />
      <InfoWindow visible={infoState.visible} marker={infoState.marker}>
        <div>
          <p style={{ margin: 0 }}>
            <strong>Address:</strong> {order.address}
          </p>
          <p style={{ margin: 0 }}>
            <strong>Building Type:</strong> {order.buildingType}
          </p>
          <p style={{ margin: 0 }}>
            <strong>Address Details:</strong> {order.addressDetails}
          </p>
        </div>
      </InfoWindow>
    </Map>
  );
};
const confirmDelivery = props => {
  setLoading(true);
  $.ajax({
    type: 'POST',
    url: server + apiCall + 'driverupdateorder',
    data: JSON.stringify({ billid: props.order.billid, state: 'delivered' }),
    contentType: 'application/json',
    dataType: 'json',
    crossDomain: true,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
    },
    success: async data => {
      setLoading(false);
      notification.success({
        message: 'Success',
        description: 'Confirmed delivery'
      });
      props.navToView('orders');
    },
    error: async (xhr, ajaxOptions, thrownError) => {
      setLoading(false);
      if (xhr.responseText) {
        notification.error({
          message: 'Error',
          description: xhr.responseText
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error placing order'
        });
      }
    }
  });
};
let loading = false;
let setLoading;
const Track = props => {
  order = props.order;
  user = props.user;
  [loading, setLoading] = useState(loading);
  const markerRef = useRef();
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white' }}>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <h2
            style={{
              fontSize: 17,
              paddingLeft: '1em',
              margin: 'auto',
              marginBottom: '0.7em'
            }}
          >
            Order #{props.order.orderno}
          </h2>
        </Col>
        <Col span={12}>
          <a
            style={{
              float: 'right',
              paddingRight: '1em',
              paddingTop: '0.5em',
              fontFamily: 'Roboto',
              fontSize: '1em'
            }}
            onClick={e => props.navToView('vieworder', props.order)}
          >
            View Order
          </a>
        </Col>
      </Row>
      <Row
        style={{ height: 'calc(100vh - 90px)' }}
        type="flex"
        justify="space-around"
      >
        <Col span={24}>
          <MyMapComponent markerRef={markerRef} />
        </Col>
      </Row>
      <Row
        style={{
          position: 'absolute',
          width: '100%'
        }}
        type="flex"
        justify="space-around"
      />
      <Row
        style={{
          position: 'absolute',
          width: '100%'
        }}
        type="flex"
        justify="space-around"
      >
        <Button
          loading={loading}
          disabled={loading}
          style={{
            zIndex: 999,
            position: 'Fixed',
            backgroundColor: '#21ABB6',
            fontWeight: 700,
            height: '4em',
            fontFamily: 'Roboto',
            fontSize: '18px',
            color: 'white',
            width: '100%',
            bottom: 0,
            marginLeft: '-0.8em'
          }}
          onClick={e => confirmDelivery(props)}
        >
          Confirm Delivery
        </Button>
      </Row>
    </Content>
  );
};

export default Track;
