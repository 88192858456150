import React, { useState, useEffect } from 'react';
import { Icon, Table, Select, Tooltip } from 'antd';
import styled, { keyframes } from 'styled-components';
import Button from 'src/components/CustomButtons/Button.jsx';
import IconButton from '@material-ui/core/IconButton';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import StyledTableNoPagi from 'src/components/Table/StyledTableNoPagi';
import CustomAddSelect from 'src/components/CustomAddSelect/CustomAddSelect.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import convert from 'convert-units';
import { formaterCheck } from 'src/utils/formater.js';
import MiniStockForm from 'src/views/Admin/Menuitems/MiniStockForm';
const { Column } = Table;
const { Option } = Select;

var dimSelectTypes = {
  volume: [
    {
      name: 'ml',
      value: 'ml'
    },
    {
      name: 'L',
      value: 'l'
    }
  ],
  mass: [
    {
      name: 'g',
      value: 'g'
    },
    {
      name: 'Kg',
      value: 'kg'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: [
    {
      name: 'Each',
      value: 'unit'
    }
  ]
};

const shadowPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const StyledButton = styled(Button)`
  animation: ${shadowPulse} 1s infinite;
`;

const TableBody = allprops => {
  let oState;
  let setState;

  const handleChangeSelect = (value, event, key) => {
    if (value) {
      var stockitems = oState.state.stockitems;
      var stock = oState.state.stockitems[key];
      if (value !== 'Add ' + event.key + ' Stock Item') {
        var newvalue = event.key.split('|');
        stock._id = newvalue[0];
        stock.name = value;
        stock.dimension = newvalue[1];
        stock.dimType = dimSelectTypes[newvalue[1]][0].value;
        stock.qty = oState.state.cookingState.stockitems[stock._id].servingType
          ? oState.state.cookingState.stockitems[stock._id].serving.toString()
          : '1';
        var metricAmount = stock.qty;
        if (stock.dimension !== 'unit') {
          metricAmount = convert(stock.qty)
            .from(stock.dimType)
            .to(oState.state.cookingState.stockitems[stock._id].dimType);
        }
        var costPrice = stock._id
          ? oState.state.cookingState.stockitems[stock._id].buyPrice
          : '0';
        var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
        stock.cost = totalCost.toFixed('2');
        stockitems[key] = stock;
        var costTotal = 0;
        if (oState.state.stockitems.length > 0) {
          for (var i = 0; i < oState.state.stockitems.length; i++) {
            if (oState.state.stockitems[i].cost) {
              costTotal += parseFloat(oState.state.stockitems[i].cost);
            }
          }
          setState(prevState => ({
            ...prevState,
            stockitems: stockitems,
            costPrice: costTotal.toFixed(2),
            totalCostPrice: costTotal
          }));
          oState.updateMenuItemCost(costTotal.toFixed(2));
        }
      } else {
        stock.name = event.key;
        stock._id = 'addnew';
        stockitems[key] = stock;
        setStateStock(prevState => ({
          ...prevState,
          name: stock.name
        }));
        setState(prevState => ({
          ...prevState,
          stockitems: stockitems
        }));
      }
    }
  };

  const handleCompoSelectFilter = (valueN, keyN) => {
    let value = valueN;
    let selectitemsFilter = [];
    let key = keyN;
    let selectitems = Object.assign([], oState.state.cookingState.selectitems);
    let stockitems = oState.state.stockitems;
    if (value) {
      stockitems[key].name = value[0].toUpperCase() + value.slice(1);
      for (var i = 0; i < selectitems.length; i++) {
        if (selectitems[i].value.toUpperCase().includes(value.toUpperCase())) {
          selectitemsFilter.push(selectitems[i]);
        }
      }
      if (selectitemsFilter.length === 0) {
        selectitemsFilter.push({
          value:
            'Add ' + value[0].toUpperCase() + value.slice(1) + ' Stock Item',
          key: value[0].toUpperCase() + value.slice(1)
        });
      }
    } else {
      stockitems[key].name = '';
      selectitemsFilter = selectitems;
    }
    setItemsFilter(selectitemsFilter);
    setState(prevState => ({
      ...prevState,
      stockitems: stockitems
    }));
  };

  const resetFilterOnCancel = key => {
    let selectitems = Object.assign([], oState.state.cookingState.selectitems);
    oState.state.stockitems[key].name = '';
    oState.state.stockitems[key]._id = '';
    setItemsFilter(selectitems);
    setState(prevState => ({
      ...prevState,
      stockitems: oState.state.stockitems
    }));
    setStateStock(oState.initState);
  };

  const handleSelectStockDim = (event, key) => {
    if (event.target.name === 'dimentiontype') {
      var stockitems = oState.state.stockitems;
      var stock = oState.state.stockitems[key];
      stock.dimType = event.target.value;
      stock.qty = '1';
      var metricAmount = stock.qty;
      if (stock.dimension !== 'unit') {
        metricAmount = convert(stock.qty)
          .from(stock.dimType)
          .to(oState.state.stockList[stock._id].dimType);
      }
      var costPrice = stock._id
        ? oState.state.stockList[stock._id].buyPrice
        : '0';
      var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
      stock.cost = totalCost.toFixed('2');
      stockitems[key] = stock;
      setState(prevState => ({
        ...prevState,
        stockitems: stockitems
      }));
      var costTotal = 0;
      if (oState.state.stockitems.length > 0) {
        for (var i = 0; i < oState.state.stockitems.length; i++) {
          if (oState.state.stockitems[i].cost) {
            costTotal += parseFloat(oState.state.stockitems[i].cost);
          }
        }
        setState(prevState => ({
          ...prevState,
          costPrice: costTotal.toFixed(2),
          totalCostPrice: costTotal
        }));
        oState.updateMenuItemCost(costTotal.toFixed(2));
      }
    }
  };

  const onChangeEvtMenuStock = (e, key) => {
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    var stockitems = oState.state.stockitems;
    var stock = oState.state.stockitems[key];

    if (e.currentTarget.value) {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        if (e.target.id === 'qty') {
          stock.qty = value;
        }
        if (e.target.id === 'cost') {
          stock.cost = value;
        }
        if (e.target.id !== 'cost') {
          var metricAmount =
            Math.round(parseFloat(stock.qty || '0') * 1000) / 1000;
          if (stock.dimension !== 'unit') {
            metricAmount = convert(stock.qty)
              .from(stock.dimType)
              .to(oState.state.stockList[stock._id].dimType);
          }
          var costPrice = stock._id
            ? oState.state.stockList[stock._id].buyPrice
            : '0';
          var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
          stock.cost = totalCost.toFixed('2');
        }
        stockitems[key] = stock;
      }
    } else {
      if (e.target.id === 'qty') {
        stock.qty = '';
        stock.cost = '';
      }
      if (e.target.id === 'cost') {
        stock.cost = '';
      }
      stockitems[key] = stock;
    }
    var costTotal = 0;
    if (oState.state.stockitems.length > 0) {
      for (var i = 0; i < oState.state.stockitems.length; i++) {
        if (oState.state.stockitems[i].cost) {
          costTotal += parseFloat(oState.state.stockitems[i].cost);
        }
      }
      setState(prevState => ({
        ...prevState,
        stockitems: stockitems,
        costPrice: costTotal.toFixed(2),
        totalCostPrice: costTotal
      }));
      oState.updateMenuItemCost(costTotal.toFixed(2));
    }
  };

  const addNewStock = () => {
    var stock = oState.state.stockitems;
    if (
      !stock.length ||
      (stock[stock.length - 1] &&
        stock[stock.length - 1]._id &&
        stock[stock.length - 1]._id !== 'addnew')
    ) {
      let newStockMenu = {
        _id: '',
        name: '',
        active: true,
        qty: '1',
        dimension: '',
        dimType: '',
        code: '',
        cost: ''
      };
      stock.push(newStockMenu);
      setState(prevState => ({
        ...prevState,
        stockitems: stock
      }));
    }
  };

  const onCompleteStockAdd = (newStockItem, key) => {
    var stock = oState.state.stockitems;
    var stockItems = newStockItem.stockItems;
    var stockList = newStockItem.stockList;
    var NewStock = newStockItem.newstockItem;

    stock[key]._id = NewStock._id;
    stock[key].name = NewStock.name;
    stock[key].dimension = NewStock.dimension;
    stock[key].dimType = NewStock.dimType;
    stock[key].code = NewStock.code;
    stock[key].cost = NewStock.buyPrice;
    var costTotal = 0;
    if (stock.length > 0) {
      for (var i = 0; i < stock.length; i++) {
        if (stock[i].cost) {
          costTotal += parseFloat(stock[i].cost);
        }
      }
    }
    setState(prevState => ({
      ...prevState,
      selectitems: stockItems,
      stockList: stockList,
      stockitems: stock,
      costPrice: costTotal.toFixed(2),
      totalCostPrice: costTotal,
      cookingState: {
        ...prevState.cookingState,
        selectitems: stockItems,
        stockitems: stockList
      }
    }));
    oState.updateMenuItemCost(costTotal.toFixed(2));
  };

  const handleEditStockItem = key => {
    if (oState.state.stockitems.length > 1) {
      oState.state.stockitems.splice(key, 1);

      var costTotal = 0;
      if (oState.state.stockitems.length > 0) {
        for (var i = 0; i < oState.state.stockitems.length; i++) {
          if (oState.state.stockitems[i].cost) {
            costTotal += parseFloat(oState.state.stockitems[i].cost);
          }
        }
        setState(prevState => ({
          ...prevState,
          stockitems: oState.state.stockitems,
          costPrice: costTotal.toFixed(2),
          totalCostPrice: costTotal
        }));
        oState.updateMenuItemCost(costTotal.toFixed(2));
      }
    } else {
      let newStockMenu = {
        _id: '',
        name: '',
        active: true,
        qty: '1',
        dimension: '',
        dimType: '',
        code: '',
        cost: ''
      };
      setState(prevState => ({
        ...prevState,
        stockitems: [newStockMenu],
        totalCostPrice: 0
      }));
      oState.updateMenuItemCost((0).toFixed(2));
    }
  };

  /*******ADD STOCK*******/
  const handleChange = eventN => {
    let event = Object.assign({}, eventN);
    if (event.target.name === 'Type') {
      let stockitems = oState.state.stockList;
      var skuheader = event.currentTarget.firstChild.data
        .replace(/\s/g, '')
        .slice(0, 3)
        .toUpperCase();
      var footer = '10000';
      var counter = 0;
      Object.keys(stockitems).map((stockid, key) => {
        if (stockitems[stockid].code.slice(0, 3).toUpperCase() === skuheader) {
          counter = counter + 1;
        }
      });
      footer =
        footer.slice(0, -(counter + 1).toString().length) +
        (counter + 1).toString();
      skuheader = skuheader + footer;
      setStateStock(prevState => ({
        ...prevState,
        type: event.target.value,
        code: skuheader
      }));
    }
    if (event.target.name === 'supplier') {
      setStateStock(prevState => ({
        ...prevState,
        supplier: event.target.value
      }));
    }
    if (event.target.name === 'dimension') {
      var serving = '';
      if (event.target.value === 'unit') {
        serving = '1';
      }
      setStateStock(prevState => ({
        ...prevState,
        stockSize: false,
        sizes: [],
        serving: serving,
        dimension: event.target.value,
        dimtypes: dimSelectTypes[event.target.value],
        servingType: dimSelectTypes[event.target.value][0]
          ? dimSelectTypes[event.target.value][0].value
          : '',
        dimType: dimSelectTypes[event.target.value][0]
          ? dimSelectTypes[event.target.value][0].value
          : ''
      }));
    }
    if (event.target.name === 'dimentiontype') {
      setStateStock(prevState => ({
        ...prevState,
        dimType: event.target.value
      }));
    }
    if (event.target.name === 'servingtype') {
      setStateStock(prevState => ({
        ...prevState,
        servingType: event.target.value
      }));
    }
  };

  const onChangeEvt = eN => {
    let e = Object.assign({}, eN);
    if (e.target.id === 'qty') {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        setStateStock(prevState => ({
          ...prevState,
          qty: value
        }));
      }
    } else if (e.target.id === 'lowqty') {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        setStateStock(prevState => ({
          ...prevState,
          lowqty: value
        }));
      }
    } else if (e.target.id === 'serving') {
      var value = e.currentTarget.value;
      if (value[value.length - 1] === ',') {
        value = value.substr(0, value.length - 1) + '.';
      }
      if (!value) {
        value = '0';
      }
      if (!isNaN(value)) {
        if (value !== '0.0') {
          value = (Math.round(parseFloat(value) * 100) / 100).toString();
        }
        setStateStock(prevState => ({
          ...prevState,
          serving: value
        }));
      }
    } else if (e.target.id === 'code') {
      setStateStock(prevState => ({
        ...prevState,
        code: e.currentTarget.value
      }));
    } else if (e.target.id === 'description') {
      setStateStock(prevState => ({
        ...prevState,
        description: e.currentTarget.value
      }));
    } else if (e.target.id === 'stockname') {
      if (e.currentTarget.value.length === 1) {
        e.currentTarget.value = e.currentTarget.value.toUpperCase();
      }
      setStateStock(prevState => ({
        ...prevState,
        name: e.currentTarget.value
      }));
    } else if (e.target.id === 'buyPrice') {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        setStateStock(prevState => ({
          ...prevState,
          buyPrice: value
        }));
      }
    }
  };

  const submitForm = (props, onError, onCompleteStockAdd, key) => {
    setState(prevState => ({
      ...prevState,
      loading: true
    }));
    let stockitem = stockState;
    const token = props.user.token;
    if (!stockitem.name) {
      props.openSnackbar('No Name Found');
      return;
    }
    if (!stockitem.type) {
      props.openSnackbar('No Type Found');
      return;
    }
    delete stockitem.key;
    delete stockitem.types;
    delete stockitem.dimtypes;
    delete stockitem.modKeyList;
    stockitem.supplier =
      oState.state.supplier &&
      oState.state.supplier.length > 0 &&
      oState.state.supplier[0] !== 'none'
        ? oState.state.supplier
        : [];
    if (!stockitem.buyPrice) stockitem.buyPrice = '0';
    if (!stockitem.dimension) stockitem.dimension = 'unit';
    if (stockitem.dimension !== 'unit' && !stockitem.dimType)
      stockitem.dimension = 'unit';
    if (!stockitem.qty) stockitem.qty = '0';
    if (!stockitem.serving) stockitem.serving = '1';
    if (!stockitem.lowqty) stockitem.lowqty = '10';
    if (!stockitem.code) stockitem.code = '';
    if (stockitem.supplier === 'none') stockitem.supplier = [];
    for (var su = 0; su < stockitem.supplier.length; su++) {
      if (stockitem.supplier[su] === 'none') {
        stockitem.supplier = [];
        break;
      }
    }
    if (
      stockitem.dimension !== 'unit' &&
      stockitem.serving &&
      !stockitem.servingType
    )
      stockitem.servingType = stockitem.dimType;
    props
      .addStockItem(stockitem, token)
      .then(res => {
        setState(prevState => ({
          ...prevState,
          loading: false
        }));
        setStateStock(oState.initState);
        if (res.data.results.error) {
          onError(res.data.results.error);
        } else {
          onCompleteStockAdd(res.data.results, key);
        }
      })
      .catch(err => {
        setState(prevState => ({
          ...prevState,
          loading: false
        }));
        onError(err);
      });
  };

  let itemsFilter = [];
  let setItemsFilter;
  let dimCheck = allprops.state.useDim;

  oState = {
    ...allprops
  };

  [oState.state, setState] = useState(oState.state);
  [itemsFilter, setItemsFilter] = useState([]);
  let [openCheck, setOpenCheck] = useState([false, 0]);
  let [stockState, setStateStock] = useState(oState.initState);
  let [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (dimCheck) {
      if (
        oState.state.alterStockState &&
        oState.state.alterStockState.name &&
        !processing
      ) {
        setProcessing(true);
        setStateStock(oState.state.alterStockState);
      } else if (!processing) {
        setProcessing(true);
        setStateStock(oState.initState);
      }
    }
  }, []);
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      useDim: dimCheck
    }));
  }, [dimCheck]);
  return (
    <Card>
      <CardBody>
        <Card
          className={oState.props.classes.cardExpand}
          style={{ marginTop: '1em' }}
        >
          <CardHeader
            style={{
              height: '4em',
              background: 'transparent',
              borderBottom: '1px solid lightgrey',
              marginRight: '2em',
              marginLeft: '1.5em',
              marginBottom: '0.5em'
            }}
          >
            {' '}
            <div style={{ display: 'inline-flex' }}>
              <h4
                style={{
                  color: 'rgba(0, 0, 0, 0.85)',
                  marginLeft: '-0.8em',
                  fontSize: '24px'
                }}
                className={oState.props.classes.cardTitleWhite}
              >
                {'Composite Items'}
              </h4>
            </div>
          </CardHeader>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <StyledTableNoPagi
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                pagination={false}
                dataSource={oState.state.stockitems}
                footer={() => (
                  <div
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    {!oState.state.useDim ? (
                      <a
                        onClick={e => addNewStock()}
                        style={{
                          textAlign: 'center',
                          fontSize: '15px',
                          color: 'green'
                        }}
                      >
                        <Icon
                          type="plus"
                          style={{
                            fontSize: '16px',
                            color: 'green'
                          }}
                        />
                        {' Add Composite Item'}
                      </a>
                    ) : null}
                  </div>
                )}
              >
                <Column
                  title="Name"
                  align="center"
                  render={(text, record, key) => (
                    <div>
                      {record._id !== 'addnew' ? (
                        <CustomAddSelect
                          dataSource={itemsFilter.map(
                            item =>
                              item && (
                                <Option key={item.key} value={item.value}>
                                  {item.value}
                                </Option>
                              )
                          )}
                          formControlProps={{
                            fullWidth: true,
                            style: {
                              height:
                                openCheck[0] && openCheck[1] === key ? 200 : 40,
                              minWidth: '8em'
                            }
                          }}
                          style={{ width: '100%' }}
                          optionLabelProp="value"
                          selectProps={{
                            value: record.name
                          }}
                          onSelect={(v, e) => handleChangeSelect(v, e, key)}
                          onSearch={v => handleCompoSelectFilter(v, key)}
                          onFocus={() => handleCompoSelectFilter(null, key)}
                          placeholder={'Stock Item name'}
                        />
                      ) : (
                        <CustomInput
                          labelText="New Stock Item Name"
                          id="stockname"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => onChangeEvt(e),
                            value: stockState.name
                          }}
                        />
                      )}
                    </div>
                  )}
                />
                <Column
                  title="Type"
                  width="100px"
                  align="center"
                  render={(text, record, key) => (
                    <div style={{ padding: 0 }}>
                      {record._id && record._id !== 'addnew' ? (
                        <CustomInput
                          labelText=""
                          id="type"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            style: { padding: 0, margin: 0 },
                            value:
                              oState.state.stockList &&
                              oState.state.stockList[record._id]
                                ? oState.state.stockList[record._id].type
                                : ''
                          }}
                        />
                      ) : null}
                      {record._id === 'addnew' ? (
                        <CustomSelect
                          labelText={undefined}
                          id="Type"
                          formControlProps={{
                            //style: { marginTop: "3.1em", },
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: record._id !== 'addnew' ? true : false,
                            style: { padding: 0, margin: 0 },
                            onChange: e => handleChange(e),
                            value: stockState.type,
                            placeholder: 'Type'
                          }}
                          menuItems={oState.state.stockTableTypes || []}
                        />
                      ) : null}
                    </div>
                  )}
                />
                <Column
                  title="SKU"
                  width="200px"
                  align="center"
                  render={(text, record, key) => (
                    <div>
                      {record._id && record._id !== 'addnew' ? (
                        <CustomInput
                          labelText=""
                          id="code"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            style: { padding: 0, margin: 0 },
                            value:
                              oState.state.stockList &&
                              oState.state.stockList[record._id]
                                ? oState.state.stockList[record._id].code
                                : ''
                          }}
                        />
                      ) : null}
                      {record._id === 'addnew' ? (
                        <CustomInput
                          labelText={undefined}
                          id="code"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => onChangeEvt(e),
                            value: stockState.code,
                            placeholder: 'SKU code'
                          }}
                        />
                      ) : null}
                    </div>
                  )}
                />
                <Column
                  title="Dimension"
                  width="100px"
                  align="center"
                  render={(text, record, key) => (
                    <div>
                      {record._id && record._id !== 'addnew' ? (
                        <CustomSelect
                          labelText=""
                          id="dimentiontype"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled:
                              record.dimension === 'unit' || !record.name
                                ? true
                                : false,
                            style: { padding: 0, margin: 0 },
                            onChange: e => handleSelectStockDim(e, key),
                            value: record.dimType || record.dimension
                          }}
                          menuItems={dimSelectTypes[record.dimension] || []}
                        />
                      ) : null}
                      {record._id === 'addnew' ? (
                        <CustomSelect
                          labelText={undefined}
                          id="dimension"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => handleChange(e),
                            value: stockState.dimension,
                            placeholder: 'Dimension'
                          }}
                          menuItems={[
                            {
                              name: 'Volume',
                              value: 'volume'
                            },
                            {
                              name: 'Mass',
                              value: 'mass'
                            },
                            {
                              name: 'Length',
                              value: 'length'
                            },
                            {
                              name: 'Each',
                              value: 'unit'
                            }
                          ]}
                        />
                      ) : null}
                    </div>
                  )}
                />
                <Column
                  title="QTY"
                  width="100px"
                  align="center"
                  render={(text, record, key) => (
                    <div>
                      {record._id && record._id !== 'addnew' ? (
                        <CustomInput
                          labelText=""
                          id="qty"
                          showSufix={
                            record.dimType && record.dimType !== 'unit'
                              ? record.dimType
                              : ''
                          }
                          formControlProps={{
                            style: { whiteSpace: 'nowrap' },
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled:
                              !record.name ||
                              (oState.state.useDim &&
                                oState.state.userlistbuttonmenu !==
                                  'EDIT PRODUCT')
                                ? true
                                : false,
                            style: { padding: 0, margin: 0 },
                            onChange: e => onChangeEvtMenuStock(e, key),
                            value: record.qty
                          }}
                        />
                      ) : null}
                      {record._id === 'addnew' ? (
                        <CustomInput
                          labelText={undefined}
                          id="qty"
                          formControlProps={{
                            style: { whiteSpace: 'nowrap' },
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => onChangeEvt(e),
                            value: stockState.qty,
                            placeholder: 'In Stock'
                          }}
                        />
                      ) : null}
                    </div>
                  )}
                />
                <Column
                  title="Cost"
                  width="100px"
                  align="center"
                  render={(text, record, key) => (
                    <div>
                      {record._id && record._id !== 'addnew' ? (
                        <CustomInput
                          labelText=""
                          id="cost"
                          showPrefix={'R'}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: !record.name ? true : false,
                            style: { padding: 0, margin: 0 },
                            onChange: e => onChangeEvtMenuStock(e, key),
                            value: record.cost || ''
                          }}
                        />
                      ) : null}
                      {record._id === 'addnew' ? (
                        <CustomInput
                          labelText={undefined}
                          id="buyPrice"
                          showPrefix={'R'}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: stockState.buyPrice,
                            onChange: e => onChangeEvt(e),
                            placeholder: 'Cost'
                          }}
                        />
                      ) : null}
                    </div>
                  )}
                />
                <Column
                  title="Action"
                  width="200px"
                  align="center"
                  render={(text, record, key) => (
                    <div>
                      {record.name &&
                      record._id !== 'addnew' &&
                      !oState.state.useDim ? (
                        <div>
                          {record.dimChange ? (
                            <Tooltip
                              placement="top"
                              title={
                                'Stock Item Change Detected, Review Composite Item'
                              }
                            >
                              <IconButton
                                style={{
                                  border: '1px solid red',
                                  padding: '17.5px'
                                }}
                              >
                                {
                                  <Icon
                                    type="exclamation"
                                    style={{
                                      position: 'absolute',
                                      fontSize: '16px',
                                      color: 'coral'
                                    }}
                                  />
                                }
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          <IconButton onClick={() => handleEditStockItem(key)}>
                            {
                              <Icon
                                type="delete"
                                style={{
                                  fontSize: '20px',
                                  color: 'red',
                                  border: '1px solid',
                                  borderRadius: '3em',
                                  padding: '0.4em'
                                }}
                              />
                            }
                          </IconButton>
                        </div>
                      ) : null}
                      {record._id === 'addnew' ? (
                        <div>
                          <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                              <StyledButton
                                style={{
                                  height: '3.3em',
                                  marginLeft: '-0.2em',
                                  marginBottom: '-1.4em',
                                  width: '8.5em',
                                  borderRadius: '3em'
                                }}
                                onClick={() =>
                                  submitForm(
                                    oState.props,
                                    oState.onError,
                                    onCompleteStockAdd,
                                    key
                                  )
                                }
                                color="primary"
                                loading={oState.state.loading}
                              >
                                {'Add Stock'}
                              </StyledButton>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <Button
                                style={{
                                  marginLeft: '-2em',
                                  marginBottom: '-1.4em',
                                  height: '3.2em',
                                  padding: '0.4em',
                                  boxShadow: 'none',
                                  backgroundColor: 'white',
                                  color: 'red',
                                  border: '1.5px solid red',
                                  borderRadius: '3em'
                                }}
                                onClick={() => resetFilterOnCancel(key)}
                                color="primary"
                              >
                                <Icon
                                  style={{
                                    height: ' 1.6em',
                                    paddingLeft: '0.4em'
                                  }}
                                  type="redo"
                                />
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </div>
                      ) : null}
                    </div>
                  )}
                />
              </StyledTableNoPagi>
              <GridContainer>
                <GridItem xs={8} sm={8} md={8} />
                <GridItem xs={3} sm={3} md={3} style={{ marginLeft: '1vw' }}>
                  <CustomInput
                    labelText="Total Cost"
                    id="totalcost"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      style: {
                        fontWeight: 'bold',
                        fontSize: '25px',
                        color: 'black'
                      },
                      disabled: true,
                      value: oState.state.totalCostPrice
                        ? 'R ' + oState.state.totalCostPrice.toFixed('2')
                        : 'R0.00'
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} sm={2} md={2}>
              <Button
                onClick={() => oState.handleOpenMenu(false)}
                color="primary"
                style={{
                  textTransform: 'initial',
                  marginTop: '2em',
                  marginLeft: '2em',
                  backgroundColor: 'white',
                  border: '1px solid red',
                  color: 'red',
                  borderRadius: '3.5em',
                  width: '9em',
                  boxShadow: 'none'
                }}
              >
                Cancel
              </Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} />
            {oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' ? (
              <GridItem xs={2} sm={2} md={2}>
                <Button
                  onClick={() => {
                    oState.setState(prevState => ({
                      ...prevState,
                      itemasmenulast: false,
                      itemasmenu: false
                    }));
                  }}
                  color="primary"
                  style={{
                    textTransform: 'initial',
                    backgroundColor: 'white',
                    border: '1px solid #00CC70',
                    color: '#00CC70',
                    borderRadius: '3em',
                    width: '100%',
                    marginTop: '2em'
                  }}
                >
                  Back
                </Button>
              </GridItem>
            ) : null}
            {(oState.state.cooking ||
              oState.state.itemasstockformformenuitem) &&
            oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' ? (
              <GridItem xs={2} sm={2} md={2}>
                <Button
                  disabled={oState.state.loading}
                  onClick={e => {
                    oState.changeViewMods(oState.state);
                  }}
                  color="primary"
                  style={{
                    textTransform: 'initial',
                    backgroundColor: '#00CC70',
                    border: '1px solid #00CC70',
                    color: 'white',
                    borderRadius: '3em',
                    width: '100%',
                    marginTop: '2em'
                  }}
                >
                  {'Continue Setup'}
                </Button>
              </GridItem>
            ) : (
              <GridItem xs={2} sm={2} md={2}>
                <Button
                  disabled={oState.state.loading}
                  onClick={e => oState.submitForm(e, oState.state)}
                  color="primary"
                  style={{
                    textTransform: 'initial',
                    backgroundColor: '#00CC70',
                    border: '1px solid #00CC70',
                    color: 'white',
                    borderRadius: '3em',
                    width: '100%',
                    marginTop: '2em'
                  }}
                >
                  {oState.state.userlistbuttonmenu
                    .toLowerCase()
                    .replace(/^(.)|\s+(.)/g, c => c.toUpperCase())}
                </Button>
              </GridItem>
            )}
          </GridContainer>
        </Card>
      </CardBody>
    </Card>
  );
};

export default TableBody;
