export function PrintJS(aData, sIp, cash, callback, logo) {
  var printer = null;
  try {
    var printer = new window.epson.ePOSBuilder();

    function addTextToPrinter() {
      printer.addTextStyle(false, false, false);
      printer.addTextLang('en');
      printer.addTextFont(printer.FONT_A);
      printer.addTextSmooth(false);
      for (var i = 0; i < aData.length; i++) {
        if (aData[i].includes('{B}')) {
          aData[i] = aData[i].substring(3);
          printer.addTextStyle(false, false, true);
        } else {
          printer.addTextStyle(false, false, false);
        }
        if (aData[i].includes('{S}')) {
          var size = parseInt(aData[i][0]);
          aData[i] = aData[i].substring(4);
          printer.addTextSize(size, 1);
        } else {
          printer.addTextSize(1, 1);
        }
        printer.addText(aData[i].replace('\\n', '\n'));
      }
      printer.addCut();
      if (cash) {
        printer.addPulse(printer.DRAWER_1, printer.PULSE_100);
      }
      // printer.addPulse(printer.DRAWER_1, printer.PULSE_100);
      // printer.addPulse(printer.DRAWER_2, printer.PULSE_100);
      // printer.addSound(printer.PATTERN_1, 3, printer.PARAM_DEFAULT);
      // Registers the print complete event

      var address =
        'http://' +
        sIp +
        '/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000';
      var epos = new window.epson.ePOSPrint(address);
      var request = printer.toString();
      epos.onerror = function(response) {
        callback('Printer : ' + response);
      };
      epos.onreceive = function(response) {
        if (response.success) {
          callback(null, { message: 'Done' });
        } else {
          callback('Printer : ' + response);
        }
      };
      epos.send(request);
    }
    if (logo && logo.base64) {
      printer.addTextAlign(printer.ALIGN_CENTER);
      var canvas = document.createElement('canvas');
      canvas.height = logo.height;
      canvas.width = logo.width;
      var context = canvas.getContext('2d');
      var image = new Image();
      image.onload = function() {
        context.drawImage(image, 0, 0);
        printer.addImage(
          context,
          0,
          0,
          canvas.width,
          canvas.height,
          printer.COLOR_1
        );
        printer.addTextAlign(printer.ALIGN_LEFT);
        addTextToPrinter();
      };
      image.src = logo.base64;
    } else {
      addTextToPrinter();
    }
  } catch (e) {
    callback(e);
  }
}
