/* global window */
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createEncryptor from 'redux-persist-transform-encrypt'
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/es/storage'; // default: localStorage if web, AsyncStorage if react-native
import thunk from 'redux-thunk';

import reducers from 'src/redux/reducers';

const configureStore = () => {
  const history = createBrowserHistory({ basename: '/vpos' });
  const middleware = [thunk, routerMiddleware(history)];
  // let uuid;
  // if (window.cordova) {
  //   uuid = window.device.uuid;
  // } else {
  //   uuid = navigator.platform;
  // }
  const encryptor = createEncryptor({
    secretKey: 'thisisakeyblabla',
    onError: function(error) {
      console.log(error)
    }
  })
  const config = {
    key: 'root',
    storage,
    blacklist: [],
    transforms: [encryptor]
  };
  const reducer = persistCombineReducers(config, {
    router: connectRouter(history),
    ...reducers
  });
  if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require('redux-logger');
    const logger = createLogger({
      collapsed: true,
      level: 'warn'
    });
    middleware.push(logger);
  }

  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    connectRouter(history)(reducer),
    composeEnhancer(applyMiddleware(...middleware))
  );

  const persistor = persistStore(store, null, () => {
    store.getState();
  });

  return { persistor, store, history };
};

export default configureStore;
