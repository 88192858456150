import React from "react";
import toy1 from "src/static/buttonIcons/toy1.png";
import toy2 from "src/static/buttonIcons/toy2.png";
import toy3 from "src/static/buttonIcons/toy3.png";
import toy4 from "src/static/buttonIcons/toy4.png";
import toy5 from "src/static/buttonIcons/toy5.png";
import toy6 from "src/static/buttonIcons/toy6.png";
import toy7 from "src/static/buttonIcons/toy7.png";
import toy8 from "src/static/buttonIcons/toy8.png";
import toy9 from "src/static/buttonIcons/toy9.png";
import toy10 from "src/static/buttonIcons/toy10.png";
import toy11 from "src/static/buttonIcons/toy11.png";
import toy12 from "src/static/buttonIcons/toy12.png";
import toy13 from "src/static/buttonIcons/toy13.png";
import toy14 from "src/static/buttonIcons/toy14.png";
import toy15 from "src/static/buttonIcons/toy15.png";

const foodList = [
  {
    value: toy1,
    label: <img alt="img" src={toy1} style={{ width: 20 }} />,
    //name: "A",
  },
  {
    value: toy2,
    label: <img alt="img" src={toy2} style={{ width: 20 }} />,
  },
  {
    value: toy3,
    label: <img alt="img" src={toy3} style={{ width: 20 }} />,
  },
  {
    value: toy4,
    label: <img alt="img" src={toy4} style={{ width: 20 }} />,
  },
  {
    value: toy5,
    label: <img alt="img" src={toy5} style={{ width: 20 }} />,
  },
  {
    value: toy6,
    label: <img alt="img" src={toy6} style={{ width: 20 }} />,
  },
  {
    value: toy7,
    label: <img alt="img" src={toy7} style={{ width: 20 }} />,
  },
  {
    value: toy8,
    label: <img alt="img" src={toy8} style={{ width: 20 }} />,
  },
  {
    value: toy9,
    label: <img alt="img" src={toy9} style={{ width: 20 }} />,
  },
  {
    value: toy10,
    label: <img alt="img" src={toy10} style={{ width: 20 }} />,
  },
  {
    value: toy11,
    label: <img alt="img" src={toy11} style={{ width: 20 }} />,
  },
  {
    value: toy12,
    label: <img alt="img" src={toy12} style={{ width: 20 }} />,
  },
  {
    value: toy13,
    label: <img alt="img" src={toy13} style={{ width: 20 }} />,
  },
  {
    value: toy14,
    label: <img alt="img" src={toy14} style={{ width: 20 }} />,
  },
  {
    value: toy15,
    label: <img alt="img" src={toy15} style={{ width: 20 }} />,
  },
];

export default foodList;
