import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Icon, Modal, Row, Spin } from 'antd';
import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Page from 'src/components/Layout/Page';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  renewOrgPass,
  resetPass,
  signinCustomer,
  signupCustomer
} from 'src/redux/actions/backend';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import DeliveryBack from 'src/static/delivery-login-back.png';
import LogoGrey from 'src/static/vpos-logo-grey.png';

const styles = theme => ({
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '250px'
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: '350px'
    },
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '450px'
    },
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: '0.02'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: 'black',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '100',
    fontSize: '30px',
    fontFamily: 'Nunito',
    marginBottom: '3px',
    textDecoration: 'none',
    textAlign: 'center'
  },
  loginBackground: {
    backgroundImage: 'url(' + DeliveryBack + ')',

    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    overflow: 'scroll'
  },
  cardTileButton: {
    color: 'red',
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },

  card: {
    [theme.breakpoints.down('sm')]: {
      top: '10vw',
      margin: '0% 7.5%',
      width: '85%',
      boxShadow: 'none'
    },
    [theme.breakpoints.up('md')]: {
      margin: '10% 20%',
      width: '60%',
      boxShadow: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '10% 30% ',
      width: '40%',
      boxShadow: 'none'
    }
  }
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      passwordConfirm: '',
      repass: '',
      email: '',
      name: '',
      number: '',
      loading: false,
      alert: false,
      fdopen: false,
      rfdopen: false,
      showpass: true,
      showpassConfirm: true,
      msg: [],
      head: 'Login'
    };
  }

  componentDidMount() {
    $('#splash-screen')[0].style.opacity = '0';
    setTimeout(function() {
      $('#splash-screen')[0].style.display = 'none';
    }, 2000);
  }

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  closeFormDialog = () => {
    this.setState({
      fdopen: false,
      rfdopen: false
    });
  };

  onError = err => {
    this.setState({
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onChangeEvt(e) {
    if (e.target.id === 'username') {
      this.setState({ username: e.currentTarget.value });
    }
    if (e.target.id === 'password') {
      this.setState({ password: e.currentTarget.value });
    }
    if (e.target.id === 'passwordConfirm') {
      this.setState({ passwordConfirm: e.currentTarget.value });
    }
    if (e.target.id === 'email') {
      this.setState({ email: e.currentTarget.value });
    }
    if (e.target.id === 'repass') {
      this.setState({ repass: e.currentTarget.value });
    }
    if (e.target.id === 'name') {
      this.setState({ name: e.currentTarget.value });
    }
    if (e.target.id === 'number') {
      this.setState({ number: e.currentTarget.value });
    }
  }

  onKeyEvt(e) {
    if (e.key === 'Enter') {
      this.submitForm();
    }
  }

  forgotPassword = () => {
    if (this.state.email) {
      this.setState({ loading: true });
      this.props
        .resetPass(this.state)
        .then(res => {
          this.setState({
            alert: true,
            fdopen: false,
            msg: ['Password Reset has been sent to your email'],
            loading: false
          });
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  forgotPasswordDialog = () => {
    this.setState({ fdopen: true });
  };

  submitForm = () => {
    this.setState({ submitRequest: true });
    if (this.state.username === '' || this.state.password === '') return;
    this.setState({ loading: true });
    this.props
      .signinCustomer(this.state)
      .then(res => {
        this.setState({ loading: false });
        this.props.changeViewLoginCustomer();
      })
      .catch(err => {
        this.onError(err);
      });
  };

  submitFormSignUp = () => {
    this.setState({ submitUpRequest: true });
    if (
      this.state.username === '' ||
      this.state.name === '' ||
      this.state.number === '' ||
      this.state.password === ''
    ) {
      return;
    }
    this.setState({ loading: true });
    this.props
      .signupCustomer(this.state)
      .then(res => {
        this.props.openSnackbar('Successfully Registered');
        this.setState({ signUp: false, loading: false });
      })
      .catch(err => {
        this.props.openSnackbar('Registration Failed');
        this.onError(err);
      });
  };

  renewPass = () => {
    const token = this.state.token;
    this.setState({ loading: true });
    if (this.state.repass === 'pass') {
      this.onError('Enter A new Password');
      return;
    }
    if (this.state.repass.length < 6) {
      this.onError('Password to short, Min 6');
      return;
    }
    this.props
      .renewOrgPass(this.state, token)
      .then(res => {
        this.setState({
          loading: false,
          rfdopen: false,
          password: this.state.repass,
          repass: ''
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  showCustomerTC(e) {
    e.preventDefault();
    Modal.info({
      width: '90%',
      style: { top: 20 },
      title: 'Terms and Conditions',
      iconType: 'file-done',
      content: (
        <div style={{ height: '60vh', overflow: 'auto' }}>
          <iframe width="100%" height="100%" src="./tc.html" />
        </div>
      ),
      onOk() {}
    });
  }

  showCustomerPriv(e) {
    e.preventDefault();
    Modal.info({
      width: '90%',
      style: { top: 20 },
      title: 'Privacy Policy',
      iconType: 'file-done',
      content: (
        <div style={{ height: '60vh', overflow: 'auto' }}>
          <iframe width="100%" height="100%" src="./privacy.html" />
        </div>
      ),
      onOk() {}
    });
  }

  render() {
    const oState = {
      state: this.state,
      closeDialog: this.closeDialog
    };

    return (
      <Page className={this.props.classes.loginBackground}>
        {' '}
        <img
          src={LogoGrey}
          alt="Logo"
          style={{
            width: '50%',
            minWidth: '5em',
            left: '4%',
            top: '2%',
            marginTop: '10vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            //position: 'absolute',
            zIndex: 9
          }}
        />
        <Spin spinning={this.state.loading}>
          {!this.state.signUp ? (
            <Card
              className="sign-in-button"
              style={{
                backgroundColor: 'transparent',
                position: 'absolute',
                borderRadius: '2em',
                zIndex: 999,
                opacity: 1,
                marginTop: '0%'
              }}
              className={this.props.classes.card}
            >
              <CardHeader
                className="sign-in-header"
                color="transparent"
                style={{ marginBottom: '-1em' }}
              >
                {' '}
                <h2
                  style={{
                    marginTop: '0em',
                    marginBottom: '-0em',
                    fontWeight: 700
                  }}
                  className={this.props.classes.cardTitleWhite}
                >
                  Sign in
                </h2>
                <p
                  style={{
                    textAlign: 'center',
                    margin: 'auto',
                    color: 'black'
                  }}
                  className={this.props.classes.cardCategoryWhite}
                >
                  Please enter your details below to login
                </p>
              </CardHeader>
              <CardBody className="sign-in-body">
                <div
                  className={this.props.classes.background}
                  style={{ backgroundImage: 'url(' + +')' }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Email"
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={
                        this.state.username === '' && this.state.submitRequest
                      }
                      errorText={'Email required'}
                      inputProps={{
                        style: { color: 'black' },
                        onChange: e => this.onChangeEvt(e),
                        value: this.state.username,
                        onKeyDown: e => this.onKeyEvt(e),
                        autoFocus: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={
                        this.state.password === '' && this.state.submitRequest
                      }
                      errorText={'Password required'}
                      inputProps={{
                        style: { color: 'black' },
                        onChange: e => this.onChangeEvt(e),
                        type: this.state.showpass ? 'password' : '',
                        value: this.state.password,
                        onKeyDown: e => this.onKeyEvt(e)
                      }}
                    />{' '}
                  </GridItem>
                  <Row
                    style={{
                      width: '100%'
                    }}
                    gutter={24}
                  >
                    {' '}
                    <Col span={12} style={{ padding: '0px' }}>
                      <Button
                        onClick={e => this.setState({ signUp: true })}
                        color="transparent"
                        style={{
                          color: 'grey',

                          fontSize: '11px'
                        }}
                      >
                        Not Registered?
                      </Button>
                    </Col>
                    <Col style={{ textAlign: 'right' }} span={12}>
                      <Button
                        onClick={this.forgotPasswordDialog}
                        color="transparent"
                        style={{
                          color: 'grey',

                          fontSize: '11px'
                        }}
                      >
                        Forgot Password?
                      </Button>
                    </Col>
                  </Row>
                </GridContainer>
                <Button
                  onClick={() =>
                    this.setState({ showpass: !this.state.showpass })
                  }
                  color="primery"
                  style={{
                    position: 'absolute',
                    background: 'transparent',
                    boxShadow: 'none',
                    top: '11em',
                    right: '0px'
                  }}
                >
                  <Icon
                    style={{
                      color: 'lightgrey'
                    }}
                    type="eye"
                  />
                </Button>
                <Button
                  className="sign-in-button"
                  onClick={this.submitForm}
                  color="primary"
                  style={{
                    textTransform: 'initial',
                    backgroundColor: 'rgb(0, 204, 112)',
                    color: 'white',
                    fontWeight: 600,
                    letterSpacing: '0.04em',
                    fontFamily: 'Nunito',
                    height: '3.5em',
                    borderRadius: '3em',
                    width: '100%',
                    marginTop: '1em',
                    fontSize: '16px',
                    zIndex: 999
                  }}
                >
                  Login
                </Button>
              </CardBody>
            </Card>
          ) : (
            <Card
              className="sign-in-button"
              style={{
                position: 'absolute',
                zIndex: 999,
                backgroundColor: 'transparent',
                opacity: 1,
                marginTop: '5%'
              }}
              className={this.props.classes.card}
            >
              <CardHeader className="sign-in-header" color="transparent">
                {/*<img
                    src={LogoGrey}
                    alt="Logo"
                    style={{
                      width: '45%',
                      maxWidth: '10em',
                      minWidth: '9em',
                      left: '4%',
                      top: '2%',
                      marginTop: '2em',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      display: 'block',

                      zIndex: 9
                    }}
                  />*/}
                <h4
                  style={{
                    marginTop: '-2em',
                    marginBottom: '0em',
                    fontWeight: 700
                  }}
                  className={this.props.classes.cardTitleWhite}
                >
                  Sign Up
                </h4>
                <p
                  style={{
                    textAlign: 'center',
                    margin: 'auto',
                    color: 'black'
                  }}
                  className={this.props.classes.cardCategoryWhite}
                >
                  Please fill out all fields to sign up{' '}
                </p>
              </CardHeader>
              <CardBody className="sign-in-body">
                <div
                  className={this.props.classes.background}
                  style={{ backgroundImage: 'url(' + +')' }}
                />
                <CustomInput
                  labelText="Full Name"
                  id="name"
                  formControlProps={{
                    style: { marginTop: '-2em' },
                    fullWidth: true
                  }}
                  error={this.state.name === '' && this.state.submitUpRequest}
                  errorText={'Full name required'}
                  inputProps={{
                    onChange: e => this.onChangeEvt(e),
                    value: this.state.name,
                    onKeyDown: e => this.onKeyEvt(e)
                  }}
                />
                <CustomInput
                  labelText="Email"
                  id="username"
                  formControlProps={{
                    style: { marginTop: '-0em' },
                    fullWidth: true
                  }}
                  error={
                    this.state.username === '' && this.state.submitUpRequest
                  }
                  errorText={'Email required'}
                  inputProps={{
                    onChange: e => this.onChangeEvt(e),
                    value: this.state.username,
                    onKeyDown: e => this.onKeyEvt(e)
                  }}
                />
                <CustomInput
                  labelText="Cell Number"
                  id="number"
                  formControlProps={{
                    style: { marginTop: '-0em' },
                    fullWidth: true
                  }}
                  error={this.state.number === '' && this.state.submitUpRequest}
                  errorText={'Cell number required'}
                  inputProps={{
                    onChange: e => this.onChangeEvt(e),
                    value: this.state.number,
                    onKeyDown: e => this.onKeyEvt(e),
                    placeholder: '0123456789',
                    maxlength: 10
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    style: { marginTop: '-0em' },
                    fullWidth: true
                  }}
                  error={
                    this.state.password === '' && this.state.submitUpRequest
                  }
                  errorText={'Password required'}
                  inputProps={{
                    onChange: e => this.onChangeEvt(e),
                    type: this.state.showpass ? 'password' : '',
                    value: this.state.password,
                    onKeyDown: e => this.onKeyEvt(e)
                  }}
                />{' '}
                <CustomInput
                  labelText="Confirm Password"
                  id="passwordConfirm"
                  formControlProps={{
                    style: { marginTop: '-0em' },
                    fullWidth: true
                  }}
                  error={
                    this.state.passwordConfirm !== this.state.password &&
                    this.state.submitUpRequest
                  }
                  errorText={'Passwords do not match'}
                  inputProps={{
                    onChange: e => this.onChangeEvt(e),
                    type: this.state.showpassConfirm ? 'password' : '',
                    value: this.state.passwordConfirm,
                    onKeyDown: e => this.onKeyEvt(e)
                  }}
                />{' '}
                <Row style={{ textAlign: 'center' }}>
                  <span
                    className="sign-in-button"
                    style={{
                      color: 'grey',
                      width: '100%',
                      fontSize: '11px'
                    }}
                  >
                    By signing up you agree to the{' '}
                    <a onClick={this.showCustomerTC}>Terms and Conditions</a>{' '}
                    and <a onClick={this.showCustomerPriv}>Privacy Policy</a>
                  </span>
                </Row>
                <Button
                  className="sign-in-button"
                  onClick={e => this.setState({ signUp: false })}
                  color="transparent"
                  style={{
                    color: 'grey',
                    width: '100%',
                    fontSize: '11px'
                  }}
                >
                  Already Registered?
                </Button>
                <Button
                  onClick={() =>
                    this.setState({ showpass: !this.state.showpass })
                  }
                  color="primary"
                  style={{
                    position: 'absolute',
                    background: 'transparent',
                    boxShadow: 'none',
                    top: '14.3em',
                    right: '0px'
                  }}
                >
                  <Icon
                    style={{
                      color: 'lightgrey'
                    }}
                    type="eye"
                  />
                </Button>
                <Button
                  onClick={() =>
                    this.setState({ showpass: !this.state.showpassConfirm })
                  }
                  color="primary"
                  style={{
                    position: 'absolute',
                    background: 'transparent',
                    boxShadow: 'none',
                    top: '32.1em',
                    right: '0px'
                  }}
                >
                  <Icon
                    style={{
                      color: 'grey'
                    }}
                    type="eye"
                  />
                </Button>
                <Button
                  className="sign-in-button"
                  onClick={this.submitFormSignUp}
                  color="primary"
                  style={{
                    textTransform: 'initial',
                    backgroundColor: 'rgb(0, 204, 112)',
                    color: 'white',
                    letterSpacing: '0.04em',
                    fontWeight: 600,
                    fontFamily: 'Nunito',
                    height: '3.5em',
                    borderRadius: '3em',
                    width: '100%',
                    marginTop: '0em',
                    fontSize: '16px',
                    zIndex: 999
                  }}
                >
                  Sign Up
                </Button>
              </CardBody>
            </Card>
          )}

          <AlertDialog {...oState} />
          <FormDialog
            head="Password Reset"
            msg="Enter your Email Address"
            formElements={[
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => this.onChangeEvt(e),
                  value: this.state.email,
                  autoFocus: true
                }}
              />
            ]}
            open={this.state.fdopen}
            close={this.closeFormDialog}
            actionBtn="Reset"
            onClick={this.forgotPassword}
          />
          <FormDialog
            head="New Password"
            msg="Enter your New Password"
            formElements={[
              <CustomInput
                labelText="Password"
                id="repass"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => this.onChangeEvt(e),
                  value: this.state.repass,
                  autoFocus: true
                }}
              />
            ]}
            open={this.state.rfdopen}
            close={this.closeFormDialog}
            actionBtn="Change Password"
            onClick={this.renewPass}
          />
        </Spin>
        <Snackbar {...this.props} />{' '}
        {/*<div className="waveWrapper waveAnimation">
          <div className="waveWrapperInner bgTop">
            <div
              className="wave waveTop"
              style={{
                backgroundImage: 'url(' + WaveTop + ')'
              }}
            />
          </div>
          <div className="waveWrapperInner bgMiddle">
            <div
              className="wave waveMiddle"
              style={{
                backgroundImage: 'url(' + WaveMid + ')'
              }}
            />
          </div>
          <div className="waveWrapperInner bgBottom">
            <div
              className="wave waveBottom"
              style={{
                backgroundImage: 'url(' + WaveBot + ')'
              }}
            />
          </div>
        </div>*/}
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  local: state,
  snackbar: state.snackbar
});

const mapDispatchToProps = {
  signinCustomer,
  openSnackbar,
  closeSnackbar,
  signupCustomer,
  resetPass,
  renewOrgPass
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Login));
