import withStyles from '@material-ui/core/styles/withStyles';
import {
  Badge as AntBadge,
  Button as AntButton,
  Spin,
  Steps,
  Tabs as AntTabs
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  getBilling,
  paySubscription,
  updateBilling,
  cancelSubscription,
  activateSubscription
} from 'src/redux/actions/backend';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import styled from 'styled-components';
import CompletedContainer from './completed';
import History from './History';
import SelectionContainer from './selection';
import SummaryContainer from './summary';

const { Step } = Steps;
const { TabPane } = AntTabs;
const Badge = styled(AntBadge)`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
    .ant-badge-status-text {
      font-size: 15px;
    font-weight: 700;
      margin-left: 0;
      margin-right: 8px;
     
    }
  }
`;
const TabPanes = styled(TabPane)`
  .ant-tabs-nav-container {
    text-align: center;
  }
`;
const StyledSteps = styled(Steps)`
@media screen and (max-width: 768px) {
  display:none !important;
}
  
`;
const Tabs = styled(AntTabs)`
  .ant-tabs-bar {
    display: none;
  }
`;

const MetaContainer = styled.div`
  display: grid;
  @media screen and (min-width: 768px) {
    grid-template-columns: minmax(200px, 1fr) 250px;
  }
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05) h {
    &1,
    &2,
    &3,
    &4,
    &5 {
      margin: 0;
    }
  }
  .left-container {
    
    @media screen and (min-width: 768px) {
      grid-template-columns: minmax(200px, 1fr) 180px;
      padding: 30px 40px;
    }

    justify-content: center;
    .meta-content {
      justify-content: space-evenly;
      display: flex;
      flex-direction: column;
    }
    .title {
      color: rgb(19, 36, 64);
      font-weight: 300;
      font-size: 1.75rem;
    }
    .sub-title {
      margin: 0;
      color: #b0aeae;
      font-size: 14px;
    }
    .sub-value {
      font-size: 18px;
      color: #707070;
    }
    .link {
      color: rgb(0, 204, 112);
    }
    .price-content {
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
      text-align: right;
      > div {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
      }
      .price {
        color: #707070;
        font-size: 40px;
        font-weight: 600;
        line-height: 52px;
      }
    }
  }
  .right-container {
    font-family: sans-serif;
    border-radius:0.7em;
    background-color: white;
    border:1px solid rgb(19, 36, 64);
    color: #ffffff;
    width: 100%;
    height: 100%;
   
    
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas:
      'date date status'
      'button button button';
    .date {
      color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid rgb(19, 36, 64);
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 700;
    }
    .status {
      color: rgba(0, 0, 0, 0.65);
      border-bottom: 1px solid rgb(19, 36, 64);
      padding-left: 10px;
      padding-bottom: 10px;
      padding-top: 10px;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    display: inline-flex;
    }
    .button {
      grid-area: button;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .ant-badge-status-dot {
      margin-top: 0.15em;
      float: right !important;
      width: 0.7em;
      text-align: right;
      height: 0.69em;
      
  }
 
  }
`;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  tableScroll: {
    width: '100%',
    height: '55vh',
    overflow: 'auto'
  }
});
const daysLeft = date => {
  let days;
  let status;
  const today = moment();
  const endDate = moment(date);

  days = endDate.diff(today, 'days');
  status = today.isBefore(endDate);

  return status
    ? `${days} ${'Days remaining'}`
    : `${endDate.format('YYYY/MM/DD')}`;
};
const returnStatus = date => {
  let status;
  const today = moment();
  const endDate = moment(date);
  status = today.isBefore(endDate);
  return status ? (
    <Badge status="success"  text="Active" />
  ) : (
      <Badge status="error" text="Expired" />
    );
};
class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      loading: true,
      subscriptionView: true,
      payment: false,
      alert: false,
      msg: [],
      head: '',
      view: '0',
      current: 0
    };
  }

  componentDidMount() {
    this.getBilling();
  }

  getBilling = msg => {
    const token = this.props.local.user.token;
    this.props
      .getBilling(token)
      .then(result => {
        this.setState({
          ...result.data.results,
          loading: false
        });
        if (window.location.search.search("completed") > -1 || window.location.search.search("cancelled") > -1) {
          this.setState({
            subscriptionView: false,
            payment: true,
            current: 2,
            view: "2"
          });
        }
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Billings Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleSteps = e => {
    const current = parseInt(e.currentTarget.getAttribute('data'));
    const view = e.currentTarget.getAttribute('data');
    this.setState({ view, current });
  };

  handleContinue = () => {
    window.location.search = ''
  };

  handlePaymentHistory = () => {
    this.setState({
      subscriptionView: false,
      payment: false
    });
  };

  handleCancel = () => {
    this.setState({
      subscriptionView: true,
      payment: false,
      loading: true
    });
    this.getBilling();
  };

  handlePay = e => {
    const token = this.props.local.user.token;
    const orgid = this.props.local.user.orgid;
    this.setState({ loading: true });
    this.props
      .paySubscription(this.state, orgid, token)
      .then((e) => {
        if (e.message === 'cancelled') {
          this.getBilling('Payment Failed');
          const current = 2;
          const view = '2';
          this.setState({ view, current, loading: false, resultStatus: 'cancelled' });
        } else if (e.message === 'completed') {
          this.getBilling('Payment Successful');
          const current = 2;
          const view = '2';
          this.setState({ view, current, loading: false, resultStatus: 'completed' });
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleCancelSub = e => {
    const token = this.props.local.user.token;
    const orgid = this.props.local.user.orgid;
    this.setState({ loading: true, pdopen: false });
    this.props
      .cancelSubscription(this.state, orgid, token)
      .then((res) => {
        this.getBilling();
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
        this.onError(err);
      });
  };

  handleRePay = e => {
    const token = this.props.local.user.token;
    const orgid = this.props.local.user.orgid;
    this.setState({ loading: true, reopen: false });
    this.props
      .activateSubscription(this.state, orgid, token)
      .then((e) => {
        if (e.message === 'cancelled') {
          this.getBilling('Payment Failed');
          const current = 2;
          const view = '2';
          this.setState({ view, current, loading: false, resultStatus: 'cancelled' });
        } else if (e.message === 'completed') {
          this.getBilling('Payment Successful');
          const current = 2;
          const view = '2';
          this.setState({ view, current, loading: false, resultStatus: 'completed' });
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleSubscription = () => {
    if (this.state.status === "Subscribed") {
      this.setState({
        pdopen: true
      });
    } else if (this.state.status === "Cancelled") {
      this.setState({
        reopen: true
      });
    } else {
      this.setState({
        subscriptionView: false,
        payment: true
      });
    }
  };
  closeFormDialog = () => {
    this.setState({
      pdopen: false
    });
  };
  closeReFormDialog = () => {
    this.setState({
      reopen: false
    });
  };
  cancelRetry = () => {
    window.location.search = ""
  }
  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleChange: this.handleChange,
      submitForm: this.submitFormBilling
    };
    const { view, current } = this.state;
    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {oState.state._id && oState.state.subscriptionView ? (
          <Card style={{maxWidth:"100vw"}}>
            <CardBody>
              <MetaContainer>
                <div className="left-container">
                  <div className="meta-content">
                    <h3 className="title">{oState.state.name}</h3>
                    <div>
                      <p className="sub-title">Subscription Type</p>
                      <span className="sub-value">{this.state.subscription ? this.state.subscription.paid ? "Monthly" : this.state.subscription.trial ? "Trial" : this.state.subscription.free ? "Free" : this.state.subscription.cash ? "Cash" : "None" : "None"} </span>
                    
                    { this.state.subscription && this.state.subscription.paid ? <div>
                      <p className="sub-title">Monthly Price</p>
                      <span className="sub-value">
                        {'R' + this.state.subscription.monthlyPrice}
                      </span>
                    </div> : null}
                    </div>
                    {this.state.subscription && this.state.subscription.paid ?<div>
                      <p className="sub-title">Date Subscribed</p>
                      <span className="sub-value">
                        {this.state.subscriptionStart
                          ? moment(this.state.subscriptionStart).format(
                            'YYYY/MM/DD'
                          )
                          : 'No Subscription'}
                      </span>
                    </div> : null}
                    {this.state.subscription && this.state.subscription.paid ? <div>
                      <p>
                        <a
                          onClick={this.handlePaymentHistory}
                          className="sub-title link"
                        >
                          View Payment History
                        </a>
                      </p>
                    </div> : null}
                  </div>
                </div>
                {this.state.subscription && (this.state.subscription.paid || this.state.subscription.trial || this.state.subscription.cash) ? 
                
                <div className="right-container">
                  <h2 style={{
                    color:"white",
                    fontWeight:700,
                    fontSize:"19px",
                    backgroundColor:"rgb(19, 36, 64)",
                    paddingLeft: "0.5em",
                    borderRadius: "0.3em 0.3em 0em 0em",    
                    paddingBottom: "0.4em",
                    paddingTop: "0.4em"}}>
                      Account Status
                  </h2>
                 {this.state.subscription.cash ? null : <div className="date">
                    {this.state.subscriptionEnd && !this.state.subscription.cash
                      ? daysLeft(this.state.subscriptionEnd)
                      : 'No Subscription'}
                  </div> }
                  <div className="status">
                    {this.state.status ?
                      this.state.status === 'Cancelled' ? (
                        <Badge status="warning" text="Cancelled" />
                      ) : this.state.status === 'Subscribed' ?
                          this.state.subscription.cashActive ? <Badge status="success" text="Active" /> : (
                            returnStatus(this.state.subscriptionEnd)
                          ) : (
                            <Badge status="error" text="Suspended" />
                          ) : null}
                  </div>
                  <div className="button">
                    {this.state.subscription && this.state.subscription.paid ? (
                      <AntButton style={{fontWeight:700,bottom: "-7em",backgroundColor:"rgb(0, 204, 112)",borderRadius:"3em",color:"white",border:"1px solid rgb(0, 204, 112)",fontSize:"14px"}} 
                      onClick={this.handleSubscription} size="large">
                        {this.state.status === 'Cancelled'
                          ? 'Resubscribe'
                          : this.state.status === 'No Subscription'
                            ? 'Subscribe'
                            : 'Cancel Subscription'}
                      </AntButton>
                    ) : null}
                  </div>
                </div> : null}
              </MetaContainer>
            </CardBody>
          </Card>
        ) : this.state.payment ? (
          <Card style={{maxWidth:"92vw"}}>
            <CardBody style={{maxWidth:"92vw"}}>
              <StyledSteps progressDot current={current} style={{ marginTop: 40,maxWidth:"80vw" }}>
                <Step  key="0" className="step" title="Selection" />
                <Step key="1" className="step" title="Summary" />
                <Step key="2" className="step" title="Confirmation" />
              </StyledSteps>
              <Tabs defaultActiveKey={'0'} activeKey={view} noNav>
                <TabPanes tab="Selection" key="0">
                  <SelectionContainer   
                  style={{maxWidth:"92vw"}}
                    paymentProfile={this.state}
                    {...this}
                    nextButton={
                      current === 0 && (
                        <AntButton style={{borderRadius:"3em",marginTop: 20,paddingBottom:"0.2em",width:"6.5em",marginLeft:"1em"}}
                          type="primary"
                          data={current + 1}
                          onClick={this.handleSteps}
                          size="large"
                          block
                        >
                          Next
                        </AntButton>
                      )
                    }
                    cancelButton={
                      <AntButton 
                        type="primary"
                        onClick={this.handleCancel}
                        size="large"
                        style={{ marginRight: 5,borderRadius:"3em" ,marginTop: 20,paddingBottom:"0.2em",width:"6.5em",marginLeft:"-2em"}}
                        block
                      >
                        Cancel
                      </AntButton>
                    }
                  />
                </TabPanes>
                <TabPanes tab="Summary" key="1">
                  <SummaryContainer
                    paymentProfile={this.state}
                    {...this}
                    prevButton={
                      current !== 0 && (
                        <AntButton
                          type="primary"
                          data={current - 1}
                          onClick={this.handleSteps}
                          size="large"
                          style={{ marginRight: 5,borderRadius:"3em" ,marginTop: 20,paddingBottom:"0.2em"}}
                        >
                          Cancel
                        </AntButton>
                      )
                    }
                    paymentButton={
                      current === 1 && (
                        <AntButton
                          type="primary"
                          onClick={this.handlePay}
                          size="large"
                          style={{ borderRadius:"3em" ,marginTop: 20,paddingBottom:"0.2em",marginLeft:"0.5em"}}
                        >
                          Make Payment
                        </AntButton>
                      )
                    }
                  />
                </TabPanes>
                <TabPanes tab="Payment Status" key="2">
                  <CompletedContainer
                    paymentProfile={this.state}
                    {...this}
                    history={this.props.history}
                    continueButton={
                      current === 2 && (
                        <AntButton
                          type="primary"
                          onClick={this.handleContinue}
                          size="large"
                          style={{ marginRight: 5 }}
                        >
                          Continue
                        </AntButton>
                      )
                    }
                    cancelButton={
                      current === 2 && (
                        <AntButton
                          type="primary"
                          onClick={this.cancelRetry}
                          size="large"
                          style={{ marginRight: 5 }}
                        >
                          Cancel
                        </AntButton>
                      )
                    }
                    tryAgainButton={
                      current === 2 && (
                        <AntButton
                          type="primary"
                          onClick={this.handlePay}
                          size="large"
                          style={{ marginRight: 5 }}
                        >
                          Try Again
                        </AntButton>
                      )
                    }
                  />
                </TabPanes>
              </Tabs>
            </CardBody>
          </Card>
        ) : (
              <Card>
                <CardBody>
                  <History billingHistory={this.state.history} />
                  <CardFooter>
                    <Button
                      onClick={() => this.setState({ subscriptionView: true })}
                      color="primary"
                    >
                      Close
                </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            )}
        <FormDialog
          head="Cancel Subscription"
          msg="Are you sure you want to cancel your subscription? Account functionality will deactivate after the remaining days expire."
          formElements={[]}
          open={this.state.pdopen}
          close={this.closeFormDialog}
          actionBtn="Yes"
          closeBtn="No"
          onClick={this.handleCancelSub}
        />
        <FormDialog
          head="Resubscribe"
          msg="Do you want to resubscribe?"
          formElements={[]}
          open={this.state.reopen}
          close={this.closeReFormDialog}
          actionBtn="Yes"
          closeBtn="No"
          onClick={this.handleRePay}
        />
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems
});

const mapDispatchToProps = {
  getBilling,
  updateBilling,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  paySubscription,
  cancelSubscription,
  activateSubscription
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Billing));
