const initialState = {
  nav: {
    addstock: false,
    liststock: true
  }
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case 'RESET_STOCK_ITEMS': {
      return {
        nav: {
          addstock: false,
          liststock: true
        }
      };
    }
    case 'OPEN_ADD_STOCK_ITEMS': {
      return {
        nav: {
          addstock: true,
          liststock: false
        }
      };
    }
    case 'CLOSE_ADD_STOCK_ITEMS': {
      return {
        nav: {
          addstock: false,
          liststock: false
        }
      };
    }
    case 'OPEN_TABLE_STOCK_ITEMS': {
      return {
        nav: {
          addstock: false,
          liststock: true
        }
      };
    }
    case 'CLOSE_TABLE_STOCK_ITEMS': {
      return {
        nav: {
          addstock: false,
          liststock: false
        }
      };
    }
    default:
      return state;
  }
}
