import LogoBack from 'src/static/LogoBack.png';
import { red } from '@material-ui/core/colors';
import Wave1 from 'src/static/wave1.png';
import Wave2 from 'src/static/wave2.png';

var drawerWidth = 0;

var drawerWidthMini = 60;

const textColorBar = 'black';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0 1px 0px 0 rgba(0, 0, 0, 0.14)',
    width: window.isPhone ? "100%" : 'calc(100% - 80px)',

    border: 'none'
  },
  appBarS: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0 1px 0px 0 rgba(0, 0, 0, 0.14)',
    width: 'calc(100%)',
    border: 'none'
  },
  appBarShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: window.isPhone ? "100%" : 'calc(100% - 267px)',
    marginLeft: window.isPhone ? 0 : 100
  },
  menuButton: {
    marginRight: window.isPhone ? 0 : 20,
    color: textColorBar
  },

  personButton: {
    marginLeft: 'auto',
    marginRight: window.isPhone ? 8 : 20,
    color: textColorBar
  },
  helpButton: {
    marginRight: 20,
    color: textColorBar
  },
  helpText: {
    padding: '10px',
    color: textColorBar
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    marginTop: '64px',
    width: drawerWidth,
    overflow: 'visible !important'
  },
  drawerPaperSub: {
    marginTop: '64px',
    width: drawerWidth,
    marginLeft: '55px'
  },
  drawerPaperMini: {
    marginTop: '56px'
  },
  drawerHeader: {
    display: 'flex',
    ...theme.mixins.toolbar
  },
  drawerIcon: {
    position: 'inherit',
    float: 'right',
    color: '#00B4BC',
    fontSize: '15px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.data * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('lg')]: {
      marginLeft: window.isPhone ? 0 : 80
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: window.isPhone ? 0 : 80
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: window.isPhone ? 0 : 80
    }
  },
  backgroundimage: {
    backgroundColor: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(244,245,250,1) 60%, rgba(244,245,250,1) 100%)',
    position: 'fixed',
    height: '100%',
    zIndex:-5,
    width: '100%',
    right: '0em !important',
    display: 'block',
    //backgroundImage: "url(" + LogoBack + ")",
        //backgroundImage: "url(" + "https://i.ibb.co/JjLq5vp/Group-3705.png" + ")",
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    opacity: 0.7,
    backgroundPosition: 'bottom '
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: window.isPhone ? 0 : 256
  },
  iconButton: {
    marginRight: window.isPhone ? 0 : 20,
    color: textColorBar
  },
  logo: {
    color: textColorBar,
    'font-size': '60px'
  },
  background: {
    position: 'absolute',
    zIndex: '-1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '-1',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: 'white',
      opacity: '.8'
    }
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {}
  },
  itemIcon: {
    width: '24px',
    height: '30px',
    fontSize: '24px',
    lineHeight: '30px',
    float: 'left',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  rightIcon: {
    width: '24px',
    height: '30px',
    fontSize: '15px',
    lineHeight: '30px',
    float: 'right',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: textColorBar
  },
  drawerOpen: {
    width: drawerWidthMini,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    width: drawerWidthMini,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden'
  },
  backgroundMini: {
    position: 'absolute',
    zIndex: '-1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '-1',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: 'white'
    }
  }
});

export default styles;
