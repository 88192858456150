/* eslint-disable no-loop-func */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Radio,
  Row,
  Select,
  notification
} from 'antd';
import $ from 'jquery';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
import styled from 'styled-components';
const { Content } = Layout;
const apiCall = 'vpos/api/customer/sub/modules/customers/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const FormItem = Form.Item;
const StyledSelect = styled(Select)`
  .ant-select-selection {
    box-shadow: none !important;
    border: 0px solid #e9e9e9;
  }
`;
const StyledDatePicker = styled(DatePicker)`
  input {
    box-shadow: none !important;
    border: none;
  }
`;
const placeOrder = props => {
  setLoading(true);
  props.form.validateFieldsAndScroll(
    { scroll: { offsetTop: 170, offsetBottom: 170 } },
    (err, values) => {
      if (!err) {
        setLoading(true);
        let bill = props.bill;
        bill.tip = parseFloat((props.bill.total * tip).toFixed(2));
        bill.total = parseFloat(
          (props.bill.total + deliveryCost + serviceFee).toFixed(2)
        );
        bill.deliveryCost = deliveryCost;
        bill.paymentType = paymentType;
        bill.serviceFee = serviceFee;
        bill.buildingType = values.buildingType;
        bill.addressDetails = values.addressDetails;
        bill.deliveryType = deliveryType;
        bill.altNumber = altNumber;
        bill.instruction = instruction;
        bill.address = props.address;
        bill.collectionTime = collectionTime;
        bill.location = { lat: props.location.lat, lng: props.location.lng };
        $.ajax({
          type: 'POST',
          url: server + apiCall + 'placeorder',
          data: JSON.stringify(props.bill),
          contentType: 'application/json',
          dataType: 'json',
          crossDomain: true,
          beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
          },
          success: async data => {
            setLoading(false);
            notification.success({
              message: 'Success',
              description: 'Successfully Placed Order'
            });
            props.setBill(data.results);
            props.createCart();
            props.navToView('status');
          },
          error: async (xhr, ajaxOptions, thrownError) => {
            setLoading(false);
            if (xhr.responseText) {
              notification.error({
                message: 'Error',
                description: xhr.responseText
              });
            } else {
              notification.error({
                message: 'Error',
                description: 'Error placing order'
              });
            }
          }
        });
      } else {
        setError(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
  );
};
let buildingType;
let setBuildingType;
let altNumber;
let setAltNumber;
let deliveryType = 'Meet at door';
let setDeliveryType;
let instruction;
let setInstruction;
let tip = 0;
let setTip;
let paymentType;
let setPaymentType;
let serviceFee = 0;
let setServiceFee;
let addressDetails;
let setAddressDetails;
let loading = false;
let setLoading;
let error = false;
let setError;
let deliveryCost;
let collectionTime;
let setCollectionTime;
const Checkout = props => {
  [buildingType, setBuildingType] = useState(buildingType);
  [altNumber, setAltNumber] = useState(altNumber);
  [collectionTime, setCollectionTime] = useState(collectionTime);
  [deliveryType, setDeliveryType] = useState(deliveryType);
  [instruction, setInstruction] = useState(instruction);
  [tip, setTip] = useState(tip);
  [paymentType, setPaymentType] = useState(paymentType);
  [serviceFee, setServiceFee] = useState(serviceFee);
  [loading, setLoading] = useState(loading);
  [error, setError] = useState(error);
  [addressDetails, setAddressDetails] = useState(addressDetails);
  if (props.address === 'Collect') {
    deliveryCost = 0;
  } else {
    deliveryCost = props.bill.deliveryCost;
  }
  const { getFieldDecorator } = props.form;
  useEffect(() => {
    setDeliveryType('Meet at door');
    setTip(0);
    setPaymentType(undefined);
    setServiceFee(0);
    setAltNumber(undefined);
    setCollectionTime(undefined);
    return () => {};
  }, []);
  return (
    <Content
      style={{
        marginTop: 50,
        background: 'white',
        padding: '1em',
        paddingBottom: 220
      }}
    >
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <h2
            style={{
              fontFamily: 'Roboto',
              fontSize: 17,
              fontWeight: 700,

              paddingTop: '0.5em'
            }}
          >
            {props.address === 'Collect' ? 'Collection' : 'Delivery'} Details
          </h2>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: 16,
              fontWeight: 700
            }}
          >
            {props.address}
          </span>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: 16,
              fontWeight: 700
            }}
          >
            {props.user.name}
          </span>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: 16,
              fontWeight: 700
            }}
          >
            {props.user.number}
          </span>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <Input
            style={{
              boxShadow: 'none',
              marginTop: '1em',
              marginBottom: '0.7em',
              border: 'none',
              borderRadius: 0,
              borderBottom: '1px solid lightgrey'
            }}
            onChange={e => setAltNumber(e.currentTarget.value)}
            placeholder="Add alternative number"
          />
        </Col>
      </Row>
      {props.address === 'Collect' ? (
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <StyledDatePicker
              showTime
              style={{
                marginTop: '1em',
                marginBottom: '0.7em',
                border: 'none',
                borderRadius: 0,
                borderBottom: '1px solid lightgrey',
                width: '100%'
              }}
              onChange={e =>
                setCollectionTime(moment(e).format('YYYY/MM/DD HH:mm'))
              }
              placeholder="Select collection time"
            />
          </Col>
        </Row>
      ) : null}
      {props.address !== 'Collect' ? (
        <Row
          type="flex"
          justify="space-between"
          style={{ marginTop: '0.5em', marginBottom: '-1em' }}
        >
          <Col span={24}>
            <FormItem>
              {getFieldDecorator('buildingType', {
                rules: [
                  {
                    required: true,
                    message: 'Select building type'
                  }
                ]
              })(
                <StyledSelect
                  showSearch
                  //autoFocus
                  style={{
                    width: '100%',

                    marginBottom: '0.7em',
                    border: 'none',

                    borderRadius: 0,

                    borderBottom: '1px solid lightgrey'
                  }}
                  placeholder="Select building type"
                >
                  <Select.Option value={'Complex / Estate'}>
                    Complex / Estate
                  </Select.Option>
                  <Select.Option value={'Apartment'}>Apartment</Select.Option>
                  <Select.Option value={'House'}>House</Select.Option>
                  <Select.Option value={'Office'}>Office</Select.Option>
                  <Select.Option value={'Hotel / B&B'}>
                    Hotel / B&B
                  </Select.Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
        </Row>
      ) : null}
      {props.form.getFieldValue('buildingType') ? (
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <FormItem>
              {getFieldDecorator('addressDetails', {
                rules: [
                  {
                    required: true,
                    message: 'Fill in address details'
                  }
                ]
              })(
                <Input
                  onFocus={e => {
                    window.scrollTo({
                      top: 150,
                      behavior: 'smooth'
                    });
                  }}
                  style={{
                    width: '100%',
                    marginBottom: '0.7em',
                    border: 'none',
                    boxShadow: 'none',
                    borderRadius: 0,
                    borderBottom: '1px solid lightgrey'
                  }}
                  placeholder={
                    props.form.getFieldValue('buildingType') ===
                    'Complex / Estate'
                      ? 'Complex name, street number'
                      : props.form.getFieldValue('buildingType') === 'Apartment'
                      ? 'Complex name, unit number, street number'
                      : props.form.getFieldValue('buildingType') === 'House'
                      ? 'Street number'
                      : props.form.getFieldValue('buildingType') === 'Office'
                      ? 'Company name, street number'
                      : props.form.getFieldValue('buildingType') ===
                        'Hotel / B&B'
                      ? 'Hotel name, room number, street number'
                      : ''
                  }
                />
              )}
            </FormItem>
          </Col>
        </Row>
      ) : null}
      {props.address !== 'Collect' ? (
        <Row>
          <Col span={24}>
            <h2
              style={{
                fontFamily: 'Roboto',
                fontSize: 17,
                fontWeight: 700,
                marginTop: '0.5em'
              }}
            >
              Delivery Instructions
            </h2>
          </Col>
          <Col span={24}>
            <Radio.Group
              id="RadioSelect"
              value={deliveryType}
              onChange={e => setDeliveryType(e.target.value)}
              style={{
                width: '100%'
              }}
              buttonStyle="solid"
            >
              <Radio.Button
                style={{
                  padding: '0.2em',
                  textAlign: 'center',
                  width: '33%',
                  height: '2.5em',
                  borderRadius: 0,
                  border: 'none'
                  //color: 'white',
                  //backgroundColor: '#00CC70'
                }}
                value="Leave at door"
              >
                Leave at door
              </Radio.Button>
              <Radio.Button
                style={{
                  paddingTop: '0.2em',
                  textAlign: 'center',
                  width: '33%',
                  height: '2.5em',
                  borderRadius: 0,
                  border: 'none'
                  //color: 'white',
                  //backgroundColor: '#00CC70'
                }}
                value="Meet outside"
              >
                Meet outside
              </Radio.Button>
              <Radio.Button
                style={{
                  paddingTop: '0.2em',
                  textAlign: 'center',
                  width: '33%',
                  height: '2.5em',
                  borderRadius: 0,
                  //color: 'white',
                  //backgroundColor: '#00CC70'
                  border: 'none'
                }}
                value="Meet at door"
              >
                Meet at door
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      ) : null}
      {props.address !== 'Collect' ? (
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <Input
              onFocus={e => {
                window.scrollTo({
                  top: 300,
                  behavior: 'smooth'
                });
              }}
              style={{
                boxShadow: 'none',
                marginTop: '1.5em',
                marginBottom: '1.5em',
                border: 'none',
                borderRadius: 0,

                borderBottom: '1px solid lightgrey'
              }}
              onChange={e => setInstruction(e.currentTarget.value)}
              placeholder="Add instruction"
            />
          </Col>
        </Row>
      ) : null}
      {props.address !== 'Collect' ? (
        <Row>
          <Col span={24}>
            <h2
              style={{
                fontFamily: 'Roboto',
                fontSize: 17,
                fontWeight: 700
              }}
            >
              Driver Tip
            </h2>
          </Col>
          <Col span={24}>
            <Radio.Group
              id="RadioSelect"
              value={tip}
              onChange={e => {
                setTip(e.target.value);
                if (paymentType === 'Online') {
                  setServiceFee(
                    e.target.value
                      ? (props.bill.total +
                          props.bill.total * e.target.value +
                          deliveryCost) *
                          0.035 +
                          2
                      : (props.bill.total + deliveryCost) * 0.035 + 2
                  );
                }
              }}
              style={{ width: '100%', paddingBottom: '1em' }}
              buttonStyle="solid"
            >
              <Radio.Button
                style={{
                  paddingTop: '0.2em',
                  textAlign: 'center',
                  width: '20%',
                  height: '2.5em',
                  borderRadius: 0,
                  border: 'none'
                  //color: 'white',
                  //backgroundColor: '#00CC70'
                }}
                value={0}
              >
                0%
              </Radio.Button>
              <Radio.Button
                style={{
                  paddingTop: '0.2em',
                  textAlign: 'center',
                  width: '20%',
                  height: '2.5em',
                  //color: 'white',
                  borderRadius: 0,
                  //backgroundColor: '#00CC70',
                  border: 'none'
                }}
                value={0.05}
              >
                5%
              </Radio.Button>
              <Radio.Button
                style={{
                  paddingTop: '0.2em',
                  textAlign: 'center',
                  width: '20%',
                  height: '2.5em',
                  //color: 'white',
                  borderRadius: 0,
                  //backgroundColor: '#00CC70',
                  border: 'none'
                }}
                value={0.1}
              >
                10%
              </Radio.Button>
              <Radio.Button
                style={{
                  paddingTop: '0.2em',
                  textAlign: 'center',
                  width: '20%',
                  height: '2.5em',
                  //color: 'white',
                  borderRadius: 0,
                  //backgroundColor: '#00CC70',
                  border: 'none'
                }}
                value={0.15}
              >
                15%
              </Radio.Button>
              <Radio.Button
                style={{
                  paddingTop: '0.2em',
                  textAlign: 'center',
                  width: '20%',
                  height: '2.5em',
                  //color: 'white',
                  borderRadius: 0,
                  //backgroundColor: '#00CC70',
                  border: 'none'
                }}
                value={0.2}
              >
                20%
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col span={24}>
          <h2
            style={{
              fontFamily: 'Roboto',
              fontSize: 17,
              fontWeight: 700
            }}
          >
            Payment Type
          </h2>
        </Col>
        <Col span={24}>
          <FormItem>
            {getFieldDecorator('paymentType', {
              rules: [
                {
                  required: true,
                  message: 'Choose payment type'
                }
              ]
            })(
              <Radio.Group
                id="RadioSelect"
                onChange={e => {
                  if (e.target.value === 'Online') {
                    setServiceFee(
                      tip
                        ? (props.bill.total +
                            props.bill.total * tip +
                            deliveryCost) *
                            0.035 +
                            2
                        : (props.bill.total + deliveryCost) * 0.035 + 2
                    );
                  } else {
                    setServiceFee(0);
                  }
                  props.form.setFieldsValue({ paymentType: e.target.value });
                  setPaymentType(e.target.value);
                }}
                style={{
                  width: '100%',

                  paddingBottom: '1em'
                }}
                buttonStyle="solid"
              >
                <Radio.Button
                  style={{
                    paddingTop: '0.2em',
                    textAlign: 'center',
                    width: props.selectedCompany.disableOnlinePayment
                      ? '50%'
                      : '33.3%',
                    height: '2.5em',
                    borderRadius: 0,
                    border: 'none',
                    //color: 'white',
                    backgroundColor:
                      paymentType === 'Cash' ? 'transparent' : 'transparent'
                  }}
                  value={'Cash'}
                >
                  Cash
                </Radio.Button>
                <Radio.Button
                  style={{
                    paddingTop: '0.2em',
                    textAlign: 'center',
                    width: props.selectedCompany.disableOnlinePayment
                      ? '50%'
                      : '33.3%',
                    height: '2.5em',
                    //color: 'white',
                    borderRadius: 0,
                    backgroundColor:
                      paymentType === 'Card' ? 'transparent' : 'transparent',
                    border: 'none'
                  }}
                  value={'Card'}
                >
                  Card
                </Radio.Button>
                {props.selectedCompany.disableOnlinePayment ? null : (
                  <Radio.Button
                    style={{
                      paddingTop: '0.2em',
                      textAlign: 'center',
                      width: props.selectedCompany.disableOnlinePayment
                        ? '50%'
                        : '33.3%',
                      height: '2.5em',
                      //color: 'white',
                      borderRadius: 0,
                      backgroundColor:
                        paymentType === 'Online'
                          ? 'transparent'
                          : 'transparent',
                      border: 'none'
                    }}
                    value={'Online'}
                  >
                    Online
                  </Radio.Button>
                )}
              </Radio.Group>
            )}
          </FormItem>
        </Col>
      </Row>

      <div
        style={{
          position: 'fixed',
          zIndex: 2,
          marginLeft: '-1em',
          padding: '1em',
          width: '100%',
          bottom: '6em',
          backgroundColor: 'white'
        }}
      >
        <Row style={{}} type="flex" justify="space-between">
          <Col span={12}>
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              Subtotal
            </span>
          </Col>
          <Col span={12}>
            <span
              style={{
                float: 'right',

                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              R {props.bill.total.toFixed(2)}
            </span>
          </Col>
        </Row>
        {props.address !== 'Collect' && tip ? (
          <Row style={{}} type="flex" justify="space-between">
            <Col span={12}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                Tip
              </span>
            </Col>
            <Col span={12}>
              <span
                style={{
                  float: 'right',

                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                R {(props.bill.total * tip).toFixed(2)}
              </span>
            </Col>
          </Row>
        ) : null}
        {props.address !== 'Collect' ? (
          <Row style={{}} type="flex" justify="space-between">
            <Col span={12}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                Delivery fee
              </span>
            </Col>
            <Col span={12}>
              {props.address === 'Collect' ? (
                <span>Collect</span>
              ) : (
                <span
                  style={{
                    float: 'right',

                    fontFamily: 'Roboto',
                    fontSize: '1.2em',
                    color: 'black'
                  }}
                >
                  {deliveryCost === 0 ? 'Free' : 'R ' + deliveryCost.toFixed(2)}
                </span>
              )}
            </Col>
          </Row>
        ) : null}
        {serviceFee > 0 ? (
          <Row style={{}} type="flex" justify="space-between">
            <Col span={12}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                Service fee
              </span>
            </Col>
            <Col span={12}>
              <span
                style={{
                  float: 'right',

                  fontFamily: 'Roboto',
                  fontSize: '1.2em',
                  color: 'black'
                }}
              >
                R {serviceFee.toFixed(2)}
              </span>
            </Col>
          </Row>
        ) : null}
        <Row style={{}} type="flex" justify="space-between">
          <Col span={12}>
            <span
              style={{
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: '1.4em',
                color: 'black'
              }}
            >
              Total
            </span>
          </Col>
          <Col span={12}>
            <span
              style={{
                fontWeight: 700,
                float: 'right',

                fontFamily: 'Roboto',
                fontSize: '1.2em',
                color: 'black'
              }}
            >
              R{' '}
              {tip
                ? (
                    serviceFee +
                    (props.bill.total + props.bill.total * tip + deliveryCost)
                  ).toFixed(2)
                : (serviceFee + (props.bill.total + deliveryCost)).toFixed(2)}
            </span>
          </Col>
        </Row>{' '}
      </div>
      <Row
        style={{
          position: 'absolute',
          width: '100%'
        }}
        type="flex"
        justify="space-around"
      >
        <Button
          disabled={loading ? true : false}
          loading={loading}
          style={{
            zIndex: 999,
            position: 'Fixed',
            backgroundColor: '#00CC70',
            fontWeight: 700,
            height: '4.6em',
            fontFamily: 'Roboto',
            fontSize: '18px',
            color: 'white',
            width: '100%',
            bottom: 0,
            marginLeft: '-0.8em',
            borderRadius: 0
          }}
          onClick={e => placeOrder(props)}
        >
          <span>Place Order</span>
        </Button>
      </Row>
    </Content>
  );
};
const WrappedCheckout = Form.create()(Checkout);
export default WrappedCheckout;
