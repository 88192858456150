import React, { memo, useState } from 'react';

import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';

import { Icon } from 'antd';

var dimSelectTypes = {
    volume: [
        {
            name: 'L',
            value: 'l'
        },
        {
            name: 'ml',
            value: 'ml'
        }
    ],
    mass: [
        {
            name: 'Kg',
            value: 'kg'
        },
        {
            name: 'g',
            value: 'g'
        }
    ],
    length: [
        {
            name: 'm',
            value: 'm'
        },
        {
            name: 'cm',
            value: 'cm'
        },
        {
            name: 'mm',
            value: 'mm'
        }
    ],
    unit: [
        {
            name: 'Unit',
            value: 'unit'
        }
    ]
};

const addSizeInput = () => {
    var sizes = sizesState.sizes;
    sizes.push({
        dimension: sizesState.dimension,
        dimType: dimSelectTypes[sizesState.dimension][1]
            ? dimSelectTypes[sizesState.dimension][1].value
            : '',
        qty: 1,
        dimtypes: dimSelectTypes[sizesState.dimension],
        name: ''
    });
    setState(prevState => ({
        ...prevState,
        sizes: sizes
    }));
};

const removeSizeInput = eN => {
    let e = eN;
    var sizes = sizesState.sizes;
    if (sizes.length > 1) {
        sizes.splice(e, 1);
        setState(prevState => ({
            ...prevState,
            sizes: sizes
        }));
    }
};

const onChangeSizeInt = (eN, iN) => {
    let e = Object.assign({}, eN);
    var i = iN;
    const newIds = sizesState.sizes.slice();
    newIds[i].name = e.currentTarget.value;
    setState(prevState => ({
        ...prevState,
        sizes: newIds
    }));
};

const onChangeEvtSizeQTY = (eN, iN) => {
    let e = Object.assign({}, eN);
    var i = iN;
    const newIds = sizesState.sizes.slice();
    if (e.currentTarget.value) {
        newIds[i].qty = parseInt(e.currentTarget.value);
    }
    setState(prevState => ({
        ...prevState,
        sizes: newIds
    }));
};

const handleSelectStockSizeDim = (eN, iN) => {
    let e = Object.assign({}, eN);
    var i = iN;
    let { value } = e.target;
    const newIds = sizesState.sizes.slice();
    newIds[i].dimType = value;
    setState(prevState => ({
        ...prevState,
        sizes: newIds
    }));
};

let setState;
let sizesState = {};
const InputsSizes = ( allprops ) => {
    let newState = {
        ...allprops
    };
    [sizesState, setState] = useState(newState);
    return (
        <div>
            {sizesState.sizes.length > 0 &&
                sizesState.sizes.map(
                    (size, key) => (
                        <div key={'divsizeinputs' + key}>
                            <GridContainer key={'gridinputs' + key} style={{ maxHeight: '6em' }}>
                                <GridItem xs={5} sm={5} md={5}>
                                    <CustomInput
                                        labelText={'Product Pack Size ' + (key + 1) + '*'}
                                        id={'label' + key}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: e => onChangeSizeInt(e, key),
                                            value: size.name || ''
                                        }}
                                    />
                                </GridItem>
                                <GridItem
                                    xs={1}
                                    sm={1}
                                    md={1}
                                >
                                    <CustomInput
                                        labelText="Quantity"
                                        id={'q' + key}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'number',
                                            onChange: e => onChangeEvtSizeQTY(e, key),
                                            value: size.qty || 0
                                        }}
                                    />
                                </GridItem>
                                {key > 0 ?
                                    <GridItem xs={1} sm={1} md={1} style={{ marginTop: '3em' }}>
                                        <Icon
                                            type="delete"
                                            style={{
                                                position: 'absolute',
                                                fontSize: '25px',
                                                color: 'red'
                                            }}
                                            onClick={e => removeSizeInput(key)}
                                        />
                                    </GridItem>
                                    : null}
                            </GridContainer>
                        </div>
                    )
                )}
            <GridItem xs={3} sm={3} md={3}>
                <Button style={{ border: "1px solid #00CC70", color: "#00CC70", backgroundColor: "white", marginLeft: "-1.2em", marginTop: "1em" }}
                    onClick={() => {
                        sizesState.sizes.push({
                            dimension: '',
                            dimType: '',
                            qty: 1,
                            name: ''
                        });
                        setState(prevState => ({
                            ...prevState,
                            ...sizesState
                        }));
                    }}
                    className={newState.props.classes.buttonAdd}
                    color="primary"
                >Add Another Size</Button>
            </GridItem>
        </div>
    );
};

const MemoInputsSizes = memo(InputsSizes);

export { MemoInputsSizes };