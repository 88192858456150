export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.addEventListener('message', e => {
    // eslint-disable-line no-restricted-globals
    if (!e) return;
    var sIp = e.data.sIp;
    var pSize = e.data.size;
    var receipt = [];
    try {
      if (sIp.head && sIp.heading) {
        if (sIp.heading.length > 0) {
          if (typeof sIp.heading === 'string') {
            var heading = sIp.heading.split('\n');
            for (var i = 0; i < heading.length; i++) {
              receipt.push(heading[i]);
            }
          } else {
            for (var head = 0; head < sIp.heading.length; head++) {
              var text = '';
              if (sIp.heading[head].bold) {
                text += '{B}';
              }
              if (sIp.heading[head].size) {
                text += '3{S}';
              }
              receipt.push(text + sIp.heading[head].text);
            }
          }
        }
      }
      if (pSize === 'small') {
        receipt.push('-------------------------------\\n');
        receipt.push('Invoice # : 00010 0101 \\n');
        if (sIp.desc) {
          receipt.push('Desc: Test Print \n');
        }
        receipt.push('{left}Qty Item               Price\n');
        receipt.push('-------------------------------\\n');
        receipt.push('\\n');
        receipt.push('-------------------------------\\n');
        if (sIp.vat) {
          receipt.push('VAT: R 0.00 \n');
        }
        receipt.push('Discount: R0.00 \n');
        receipt.push('{B}Total:R0.00 \n');
        if (sIp.subs) {
          receipt.push('-------------------------------\n');
          receipt.push('\n');
          receipt.push('Gratuity :___________________\n');
          receipt.push('\n');
          receipt.push('Total Due:___________________\n');
          receipt.push('\n');
        }
      } else {
        receipt.push('----------------------------------------\\n');
        receipt.push('Invoice # : 00010 0101 \\n');
        if (sIp.desc) {
          receipt.push('Desc: Test Print \n');
        }
        receipt.push('Qty Item                     Price\n');
        receipt.push('----------------------------------------\\n');
        receipt.push('\\n');
        receipt.push('----------------------------------------\\n');
        if (sIp.vat) {
          receipt.push('VAT: R 0.00 \n');
        }
        receipt.push('Discount: R0.00 \n');
        receipt.push('{B}Total:R0.00 \n');
        if (sIp.subs) {
          receipt.push('----------------------------------------\\n');
          receipt.push('\n');
          receipt.push('Gratuity :___________________\n');
          receipt.push('\n');
          receipt.push('Total Due:___________________\n');
          receipt.push('\n');
        }
      }
      if (sIp.foot && sIp.footer) {
        if (sIp.footer.length > 0) {
          if (typeof sIp.footer === 'string') {
            var footer = sIp.footer.split('\n');
            for (var i = 0; i < footer.length; i++) {
              receipt.push(footer[i]);
            }
          } else {
            for (var head = 0; head < sIp.footer.length; head++) {
              var text = '';
              if (sIp.footer[head].bold) {
                text += '{B}';
              }
              if (sIp.footer[head].size) {
                text += '3{S}';
              }
              receipt.push(text + sIp.footer[head].text);
            }
          }
        }
      }
      for (var i = 0; i < 4; i++) {
        receipt.push('\n');
      }
      var data = {
        aData: receipt,
        logo: sIp.logo,
        sIp: sIp.printbill,
        cash: true,
        model: e.data.model
      };
      postMessage(data);
    } catch (error) {
      var data = {
        error: error
      };
      postMessage(data);
    }
  });
};
