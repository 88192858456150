import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ConfigProvider,
  Empty,
  Icon,
  Input,
  Popover,
  Row,
  Select,
  Spin,
  Table
} from 'antd';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { FiEdit } from 'react-icons/fi';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  addVoidReason,
  deleteVoidReason,
  editVoidReason,
  getVoidReasons,
  restoreVoidReason
} from 'src/redux/actions/backend';
import {
  resetActionBreadCrumb,
  setBreadCrumbs
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';

const { Column } = Table;
const { Search } = Input;
const { Option } = Select;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  tableScroll: {
    width: '100%',
    height: '55vh',
    overflow: 'auto'
  }
});
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}
class Void extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      loading: true,
      main: true,
      userlisthead: 'Add Void Reason',
      userlistsub: 'Add a New Void Reason',
      userlistbutton: 'ADD VOID REASON',
      alert: false,
      msg: [],
      head: '',
      activeFilter: true
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }

  componentDidMount() {
    this.getVoidReasons();
    this.setState({ main: true });
  }

  getVoidReasons = msg => {
    const token = this.props.local.user.token;
    this.props
      .getVoidReasons(token)
      .then(result => {
        this.setState({
          voidReasons: result.data.results,
          selectitem: undefined,
          qtymenu: '',
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleOpenMenu = open => {
    if (open) {
      this.handleAddBreadCrumb({
        method: 'handleOpenMenu',
        crumb: 'Void Reason'
      });
      this.setState({
        main: false,
        userlisthead: 'Add Void Reason',
        userlistsub: 'Add a New Void Reason',
        userlistbutton: 'ADD VOID REASON'
      });
    } else {
      this.handleRemoveBreadCrumb();
      this.setState({
        main: true,
        _id: '',
        name: '',
        description: '',
        userlisthead: 'Add Void Reason',
        userlistsub: 'Add a New Void Reason',
        userlistbutton: 'ADD VOID REASON'
      });
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Void Reasons Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onComplete = res => {
    this.setState({
      _id: '',
      name: '',
      description: '',
      userlisthead: 'Add Void Reason',
      userlistsub: 'Add a New Void Reason',
      userlistbutton: 'ADD VOID REASON',
      main: true,
      submitInProgress: false
    });
    this.getVoidReasons('Void Reason Creation Successful');
  };

  submitFormVoidReason = oEvent => {
    const token = this.props.local.user.token;
    this.setState({ loading: true, submitInProgress: true });
    if (oEvent.currentTarget.innerText === 'ADD VOID REASON') {
      this.props
        .addVoidReason(this.state, token)
        .then(res => {
          this.handleRemoveBreadCrumb();
          this.onComplete(res);
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    } else if (oEvent.currentTarget.innerText === 'EDIT VOID REASON') {
      this.props
        .editVoidReason(this.state, token)
        .then(res => {
          this.handleRemoveBreadCrumb();
          this.setState({
            _id: '',
            name: '',
            description: '',
            userlisthead: 'Add Void Reason',
            userlistsub: 'Add a New Void Reason',
            userlistbutton: 'ADD VOID REASON',
            main: true,
            submitInProgress: false
          });
          this.getVoidReasons('Void Reason Update Successful');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  handleEditVoidReason = oMenu => {
    this.handleAddBreadCrumb({
      method: 'handleOpenMenu',
      crumb: 'Void Reason'
    });
    this.setState({
      _id: oMenu._id,
      name: oMenu.name,
      description: oMenu.description,
      main: false,
      userlisthead: 'Edit Void Reason',
      userlistsub: 'Edit a Void Reason',
      userlistbutton: 'EDIT VOID REASON'
    });
  };

  handleEditStockItem = oStock => {
    const items = this.state.stockitems.filter(item => item._id !== oStock._id);
    this.setState({ stockitems: items });
  };

  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };

  handleDeleteVoidReason = oMenu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteVoidReason(oMenu, token)
      .then(res => {
        this.getVoidReasons('Void Reason Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };
  addInput = () => {
    this.setState({
      inputCount: this.state.inputCount + 1
    });
  };

  handleRestoreVoidReason = oMenu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .restoreVoidReason(oMenu, token)
      .then(res => {
        this.getVoidReasons('Void Reason Restore Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onEditBtnDown = key => {
    var cookIns = this.state.cookIns;
    var f = cookIns.splice(key, 1)[0];
    key = key + 1;
    cookIns.splice(key, 0, f);
    this.setState({
      cookIns: cookIns
    });
  };

  onEditBtnUp = key => {
    var cookIns = this.state.cookIns;
    var f = cookIns.splice(key, 1)[0];
    key = key - 1;
    cookIns.splice(key, 0, f);
    this.setState({
      cookIns: cookIns
    });
  };
  IDGenerator() {
    this.length = 8;
    this.timestamp = moment().format('YYYYMMDDHHmm');

    var _getRandomInt = function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    var ts = this.timestamp.toString();
    var parts = ts.split('').reverse();
    var id = '';

    for (var i = 0; i < this.length; ++i) {
      var index = _getRandomInt(0, parts.length - 1);
      id += parts[index];
    }

    return id;
  }

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        description={false}
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />

      <button
        style={{
          marginTop: '-0.7em',
          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',

          width: '12em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',

          borderRadius: '3em',
          zIndex: 99,
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => this.handleOpenMenu(true)}
        color="primary"
      >
        {'Add Void Reason'}
      </button>
    </div>
  );
  render() {
    const {
      addmenuitem,
      addstockitem,
      menuitemlist
    } = this.props.menuitems.nav;

    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditVoidReason: this.handleEditVoidReason,
      handleDeleteVoidReason: this.handleDeleteVoidReason,
      handleRestoreVoidReason: this.handleRestoreVoidReason,
      handleChange: this.handleChange,
      submitForm: this.submitFormVoidReason,
      onHelpMarker: this.onHelpMarker,
      onEditBtnUp: this.onEditBtnUp,
      onEditBtnDown: this.onEditBtnDown
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    let voidReasons = [];
    if (this.state.voidReasons) {
      for (var i = 0; i < this.state.voidReasons.length; i++) {
        if (this.state.activeFilter === true) {
          if (this.state.voidReasons[i].active) {
            voidReasons.push(this.state.voidReasons[i]);
          }
        } else if (this.state.activeFilter === false) {
          if (!this.state.voidReasons[i].active) {
            voidReasons.push(this.state.voidReasons[i]);
          }
        } else if (this.state.activeFilter === undefined) {
          voidReasons.push(this.state.voidReasons[i]);
        }
      }
    }

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />

        {this.state.main ? (
          <div>
            <Button
              style={{
                position: 'fixed',
                right: '0.5em',
                bottom: '0.5em',
                background: '#132440',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.handleOpenMenu(true)}
              color="primary"
            >
              <Icon
                type="plus"
                style={{
                  position: 'absolute',
                  paddingLeft: 3
                }}
              ></Icon>
            </Button>
            {voidReasons.length > 0 ? (
              <Row>
                <button
                  style={{
                    marginTop: '-0.7em',
                    backgroundColor: '#00CC70',
                    border: '0px solid rgb(30, 161, 169)',
                    color: 'white',

                    width: '12em',
                    height: '2.7em',
                    fontSize: 16,
                    fontFamily: '',

                    borderRadius: '3em',
                    zIndex: 99,
                    position: 'absolute'
                  }}
                  onClick={() => this.handleOpenMenu(true)}
                  color="primary"
                >
                  {'Add Void Reason'}
                </button>
              </Row>
            ) : null}
            <Row>
              <div
                style={{
                  float: 'right',
                  marginTop: '-1em',
                  marginBottom: '1.6em'
                }}
              >
                <Popover
                  placement="bottomRight"
                  content={GearContent}
                  title=""
                  trigger="click"
                >
                  <Button
                    style={{
                      visibility: 'hidden',
                      backgroundColor: 'white',
                      border: '1px solid rgb(15, 135, 123)',
                      color: 'rgb(15, 135, 123)',
                      marginBottom: '-0.5em',
                      height: '3.5em',
                      marginLeft: '-0.27em',
                      borderRadius: '0.3em',
                      transform: 'scale(0.9)',
                      width: '2em'
                    }}
                  >
                    <Icon type="setting" />
                  </Button>
                </Popover>
              </div>
            </Row>
            <Row>
              {' '}
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  dataSource={voidReasons}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }
                      if (type === 'next') {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (!record.active) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.handleEditVoidReason(record);
                        },
                        style: {
                          background: 'rgba(255, 4, 4, 0.063)',
                          color: 'red'
                        }
                      };
                    } else if (rowIndex % 2 == 0) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.handleEditVoidReason(record);
                        },
                        style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.handleEditVoidReason(record);
                        }
                      };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    {...this.getColumnSearchProps('description')}
                    title="Description"
                    dataIndex="description"
                    key="description"
                  />
                  <Column
                    title="Actions"
                    render={(text, record) => (
                      <div>
                        <IconButton
                          style={{
                            padding: '8px',
                            border: '1px solid #21B41E'
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            oState.handleEditVoidReason(record);
                          }}
                        >
                          {
                            <FiEdit
                              style={{
                                fontSize: 16,

                                color: '#21B41E'
                              }}
                            />
                          }
                        </IconButton>
                        {record.active ? (
                          <IconButton
                            style={{
                              border: '1px solid red',
                              padding: '16px',
                              marginLeft: '1em'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              oState.handleDeleteVoidReason(record);
                            }}
                          >
                            {
                              <Icon
                                type="delete"
                                style={{
                                  position: 'absolute',
                                  fontSize: '16px',
                                  color: 'red'
                                }}
                              />
                            }
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{
                              border: '1px solid green',
                              marginLeft: '1em',
                              padding: '16px'
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              oState.handleRestoreVoidReason(record);
                            }}
                          >
                            {
                              <Icon
                                type="rollback"
                                style={{
                                  position: 'absolute',
                                  fontSize: '15px',
                                  color: 'green'
                                }}
                              />
                            }
                          </IconButton>
                        )}
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : (
          <Card>
            {/*<CardHeader
              style={{
                borderRadius: '0.5em',
                background: 'rgb(30, 161, 169)'
              }}
            >
              {' '}
              <h4
                style={{ color: 'white' }}
                className={oState.props.classes.cardTitleWhite}
              >
                {oState.state.userlisthead}
              </h4>
              <p
                style={{ color: 'rgba(255,255,255,.62)' }}
                className={oState.props.classes.cardCategoryWhite}
              >
                {oState.state.userlistsub}
              </p>
            </CardHeader> */}
            <CardBody />
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} style={{ marginTop: '-2em' }}>
                  <CustomInput
                    labelText="Name*"
                    id="name"
                    autoFocus={true}
                    error={!oState.state.name && oState.state.submitInProgress}
                    errorText={'Void Name required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e =>
                        this.setState({ name: e.currentTarget.value }),
                      value: oState.state.name
                    }}
                  />
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={5}
                  style={{ marginTop: '-2em', marginBottom: '1em' }}
                >
                  <CustomInput
                    labelText="Description"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e =>
                        this.setState({ description: e.currentTarget.value }),
                      value: oState.state.description
                    }}
                  />
                </GridItem>
              </GridContainer>
              <CardFooter style={{ display: 'inline' }}>
                {' '}
                <Button
                  style={{
                    float: 'right',
                    borderRadius: '3.5em',
                    border: '1px solid #00CC70',
                    marginLeft: '1em'
                  }}
                  onClick={oState.submitForm}
                  color="primary"
                >
                  {oState.state.userlistbutton}
                </Button>
                <Button
                  style={{
                    float: 'right',
                    borderRadius: '3.5em',
                    color: '#00CC70',
                    border: '1px solid #00CC70',
                    backgroundColor: 'white'
                  }}
                  onClick={() => this.handleOpenMenu(false)}
                  color="primary"
                >
                  Cancel
                </Button>
              </CardFooter>
            </CardBody>
          </Card>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addVoidReason,
  getVoidReasons,
  editVoidReason,
  deleteVoidReason,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  restoreVoidReason,
  setBreadCrumbs,
  resetActionBreadCrumb
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Void));
