import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Card, Col, Modal, Row, Spin } from 'antd';
import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import 'src/components/Layout/Dashboard/sales-screen.css';
import Page from 'src/components/Layout/Page';
import { login, renewOrgPass, resetPass } from 'src/redux/actions/backend';
import VposLoginBack from 'src/static/vpos-login-background.png';
import Logo from 'src/static/vpos-main-logo.svg';
const styles = theme => ({
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '250px'
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: '350px'
    },
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '450px'
    },
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: '0.02'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '100',
    fontSize: '25px',
    marginBottom: '3px',
    textDecoration: 'none',
    textAlign: 'center'
  },
  loginBackground: {
    [theme.breakpoints.down('sm')]: {
      background: '#11141A',
      height: '110vh'
    },
    [theme.breakpoints.up('md')]: {
      background: '#11141A',
      height: '100vh'
    },
    [theme.breakpoints.up('lg')]: {
      background: '#11141A',
      height: '100vh'
    }
  },

  cardTileButton: {
    color: 'red',
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },

  card: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '60%',
      boxShadow: 'none',
      border: 'none'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '5%',

      width: '60%',
      minWidth: '35em',
      boxShadow: 'none',
      border: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '5%',
      width: '35%',
      minWidth: '35em',
      boxShadow: 'none',
      border: 'none'
    }
  }
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      repass: '',
      email: '',
      loading: false,
      alert: false,
      fdopen: false,
      rfdopen: false,
      tcopen: false,
      resetApproveOpen: false,
      showpass: true,
      msg: [],
      head: 'Login'
    };
  }

  componentDidMount() {
    $('#splash-screen')[0].style.opacity = '0';
    setTimeout(function() {
      $('#splash-screen')[0].style.display = 'none';
    }, 2000);
    if (window.cordova) {
      window.cordova.plugins.diagnostic.camera.isCameraAuthorized(status => {
        if (!status) {
          window.cordova.plugins.diagnostic.requestCameraAuthorization(
            status => {},
            error => {}
          );
        }
      });
    }
  }

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  closeFormDialog = () => {
    this.setState({
      fdopen: false,
      rfdopen: false,
      tcopen: false
    });
  };

  onError = err => {
    this.setState({
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onChangeEvt(e) {
    if (e.target.id === 'username') {
      this.setState({ username: e.currentTarget.value.replace(/\s+/g, '') });
    }
    if (e.target.id === 'password') {
      this.setState({ password: e.currentTarget.value });
    }
    if (e.target.id === 'email') {
      this.setState({ email: e.currentTarget.value });
    }
    if (e.target.id === 'repass') {
      this.setState({ repass: e.currentTarget.value });
    }
  }

  onKeyEvt(e) {
    if (e.key === 'Enter') {
      this.submitForm();
    }
  }

  forgotPassword = () => {
    if (this.state.email) {
      this.setState({ loading: true });
      this.props
        .resetPass(this.state)
        .then(res => {
          this.setState({
            // alert: true,
            fdopen: false,
            // msg: ['Password Reset has been sent to your email'],
            resetApproveOpen: true,
            loading: false
          });
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  forgotPasswordDialog = () => {
    this.setState({ fdopen: true });
  };
  termsConditionsDialog = () => {
    this.setState({ tcopen: true });
  };
  renewPassCancel = () => {
    this.setState({ fdopen: false });
  };
  resetApproveClose = () => {
    this.setState({ resetApproveOpen: false });
  };
  submitForm = () => {
    this.setState({ submitRequest: true });
    if (this.state.username === '' || this.state.password === '') return;
    this.setState({ loading: true });
    this.props
      .login(this.state)
      .then(res => {
        if (this.state.password === 'pass') {
          this.setState({
            loading: false,
            submitRequest: false,
            rfdopen: true,
            token: res.data.token
          });
        } else {
          this.setState({ loading: false, submitRequest: false });
          this.props.changeView(this.props);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  renewPass = () => {
    const token = this.state.token;
    this.setState({ loading: true });
    if (this.state.repass === 'pass') {
      this.onError('Enter A new Password');
      return;
    }
    if (this.state.repass.length < 6) {
      this.onError('Password to short, Min 6');
      return;
    }
    this.props
      .renewOrgPass(this.state, token)
      .then(res => {
        this.setState({
          loading: false,
          rfdopen: false,
          password: this.state.repass,
          repass: ''
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  render() {
    const oState = {
      state: this.state,
      closeDialog: this.closeDialog
    };

    return (
      <Page className={this.props.classes.loginBackground}>
        <img
          src={VposLoginBack}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh'
          }}
          alt="ship"
          border="0"
        />
        <Spin spinning={this.state.loading}>
          {this.state.resetApproveOpen ? (
            <Row>
              <Card
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  maxWidth: '40em',
                  top: '3vh'
                }}
                className={this.props.classes.card}
              >
                <CardHeader className="sign-in-header" color="transparent">
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{
                      width: '13em',
                      minWidth: '5em',
                      marginTop: '2em',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      display: 'block'
                    }}
                  />
                  <h1
                    style={{
                      margin: 'auto',
                      fontFamily: 'Nunito',
                      fontSize: '48px',
                      color: 'white',
                      marginTop: '1em',
                      fontWeight: 500,
                      marginBottom: '-0.2em',
                      lineHeight: '1em',
                      textAlign: 'center'
                    }}
                  >
                    <span>
                      Reset <span style={{ color: '#00CC70' }}>Password</span>
                    </span>
                  </h1>
                  <h4
                    style={{
                      color: 'lightgrey',
                      marginTop: '2em',
                      fontFamily: 'Nunito',
                      fontSize: '18px',
                      marginBottom: '-0em',
                      textAlign: 'center'
                    }}
                    className={this.props.classes.cardTitleWhite}
                  >
                    <span>
                      If we found an eligible account associated with the <br />
                      email address, we've sent password reset instructions to
                      the primary email address on the account.
                    </span>
                    <br />
                    <br />
                    <span>
                      Still having trouble logging in?{' '}
                      <a
                        href="mailto:sales@getvpos.com"
                        style={{ color: '#00CC70' }}
                      >
                        Contact Support.
                      </a>
                    </span>
                  </h4>
                </CardHeader>

                <CardBody
                  className="sign-in-body"
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    className="sign-in-button"
                    onClick={this.resetApproveClose}
                    color="primary"
                    style={{
                      backgroundColor: '#00CC70',
                      border: '0px solid #11141A',
                      height: '3.2em',
                      borderRadius: '3em',
                      width: '100%',
                      marginTop: '1em',
                      fontSize: '19px',
                      color: 'white',
                      fontWeight: 700
                    }}
                  >
                    Return to login
                  </Button>
                </CardBody>
              </Card>
            </Row>
          ) : (
            <Row>
              <Col xs={0} md={12}>
                <h1
                  style={{
                    margin: 'auto',
                    fontFamily: 'Nunito',
                    fontSize: '48px',
                    textAlign: 'center',
                    color: 'white',
                    marginTop: '37vh',
                    fontWeight: 500,
                    marginBottom: '-0.2em',
                    lineHeight: '1em'
                  }}
                >
                  <span style={{ color: '#00CC70' }}>Welcome</span> to
                </h1>
                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    width: '13em',
                    minWidth: '5em',
                    marginTop: '2em',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block'
                  }}
                />
              </Col>
              <Col
                xs={24}
                md={12}
                style={{ backgroundColor: '#0F0F0F', height: '100vh' }}
              >
                <Card
                  style={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    maxWidth: '40em',
                    top: '10vh'
                  }}
                  className={this.props.classes.card}
                >
                  <CardHeader className="sign-in-header" color="transparent">
                    <h1
                      style={{
                        margin: 'auto',
                        fontFamily: 'Nunito',
                        fontSize: '48px',
                        color: 'white',
                        marginTop: '1em',
                        fontWeight: 500,
                        marginBottom: '-0.2em',
                        lineHeight: '1em'
                      }}
                    >
                      {this.state.fdopen ? (
                        <span>
                          Reset{' '}
                          <span style={{ color: '#00CC70' }}>Password</span>
                        </span>
                      ) : (
                        <span>
                          Excel Your{' '}
                          <span style={{ color: '#00CC70' }}>Business</span>
                        </span>
                      )}
                    </h1>
                    <h4
                      style={{
                        color: 'white',
                        marginTop: '0.6em',
                        fontFamily: 'Nunito',
                        fontSize: '24px',
                        marginBottom: '-0.3em',
                        textAlign: 'left'
                      }}
                      className={this.props.classes.cardTitleWhite}
                    >
                      {this.state.fdopen ? (
                        <span>Enter your Email Address</span>
                      ) : (
                        <span>Sign in to boost your sales</span>
                      )}
                    </h4>
                    <p className={this.props.classes.cardCategoryWhite} />
                  </CardHeader>
                  <CardBody
                    className="sign-in-body"
                    style={{ textAlign: 'center' }}
                  >
                    <div
                      className={this.props.classes.background}
                      style={{ backgroundImage: 'url(' + +')' }}
                    />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {this.state.fdopen ? (
                          <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              style: { color: 'white' },
                              onChange: e => this.onChangeEvt(e),
                              value: this.state.email,
                              autoFocus: true
                            }}
                          />
                        ) : (
                          <CustomInput
                            labelText="Email"
                            id="username"
                            formControlProps={{
                              style: { color: 'white' },
                              fullWidth: true
                            }}
                            error={
                              this.state.username === '' &&
                              this.state.submitRequest
                            }
                            errorText={'Email required'}
                            labelProps={{ style: { color: 'white' } }}
                            inputProps={{
                              style: { color: 'white' },
                              onChange: e => this.onChangeEvt(e),
                              value: this.state.username,
                              onKeyDown: e => this.onKeyEvt(e),
                              autoFocus: true
                            }}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ marginTop: '0em' }}
                      >
                        {this.state.fdopen ? null : (
                          <CustomInput
                            labelText="Password"
                            id="password"
                            formControlProps={{
                              fullWidth: true
                            }}
                            error={
                              this.state.password === '' &&
                              this.state.submitRequest
                            }
                            errorText={'Password required'}
                            inputProps={{
                              style: { color: 'white' },
                              onChange: e => this.onChangeEvt(e),
                              type: this.state.showpass ? 'password' : '',
                              value: this.state.password,
                              onKeyDown: e => this.onKeyEvt(e),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      this.setState({
                                        showpass: !this.state.showpass
                                      })
                                    }
                                  >
                                    {this.state.showpass ? (
                                      <Visibility
                                        style={{ color: '#00CC70' }}
                                      />
                                    ) : (
                                      <VisibilityOff
                                        style={{ color: '#00CC70' }}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                        {/* <Button
                    onClick={() =>
                      this.setState({ showpass: !this.state.showpass })
                    }
                    color="primery"
                    style={{
                      position: "absolute",
                      background: "transparent",
                      boxShadow: "none",
                      top: "11em",
                      right: "0px"
                    }}
                  >
                    <Icon
                      style={{
                        color: "grey"
                      }}
                      type="eye"
                    />
                  </Button> */}
                      </GridItem>
                    </GridContainer>
                    {this.state.fdopen ? null : (
                      <Button
                        className="sign-in-button"
                        onClick={this.forgotPasswordDialog}
                        color="transparent"
                        style={{
                          backgroundColor: 'transparent',
                          textAlign: 'right',
                          border: 'none',
                          color: 'lightgrey',
                          width: '100%',
                          fontSize: '15px'
                        }}
                      >
                        Forgot Password?
                      </Button>
                    )}
                    {this.state.fdopen ? (
                      <span>
                        <Button
                          className="sign-in-button"
                          onClick={this.renewPassCancel}
                          color="primary"
                          style={{
                            backgroundColor: '#191D23',
                            border: '0px solid #11141A',
                            height: '3.2em',
                            borderRadius: '3em',
                            width: '45%',
                            marginTop: '1.5em',
                            fontSize: '19px',
                            color: 'white',
                            fontWeight: 700
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="sign-in-button"
                          onClick={this.forgotPassword}
                          color="primary"
                          style={{
                            backgroundColor: '#00CC70',
                            border: '0px solid #11141A',
                            height: '3.2em',
                            borderRadius: '3em',
                            width: '45%',
                            marginLeft: '5%',
                            marginTop: '1.5em',
                            fontSize: '19px',
                            color: 'white',
                            fontWeight: 700
                          }}
                        >
                          Reset
                        </Button>
                      </span>
                    ) : (
                      <span>
                        <Button
                          className="sign-in-button"
                          onClick={this.submitForm}
                          color="primary"
                          style={{
                            backgroundColor: '#00CC70',
                            border: '0px solid #11141A',
                            height: '3.2em',
                            borderRadius: '3em',
                            width: '100%',
                            marginTop: '0.5em',
                            fontSize: '19px',
                            color: 'white',
                            fontWeight: 700
                          }}
                        >
                          Sign In
                        </Button>{' '}
                        <p
                          style={{
                            position: 'relative',
                            backgroundColor: 'transparent',
                            textAlign: 'center',
                            marginTop: '1em',
                            border: 'none',
                            color: 'lightgrey',
                            width: '100%',
                            fontSize: '13px'
                          }}
                        >
                          By submitting this form, you agree to our{' '}
                          <span
                            style={{ color: '#00CC70' }}
                            onClick={this.termsConditionsDialog}
                          >
                            Terms of Use.
                          </span>
                        </p>
                      </span>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <AlertDialog {...oState} />
          {/* <FormDialog
            head="Password Reset"
            msg="Enter your Email Address"
            formElements={[
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => this.onChangeEvt(e),
                  value: this.state.email,
                  autoFocus: true
                }}
              />
            ]}
            open={this.state.fdopen}
            close={this.closeFormDialog}
            actionBtn="Reset"
            onClick={this.forgotPassword}
          /> */}
          <Modal
            title="VPOS Terms of use"
            visible={this.state.tcopen}
            onOk={this.handleOk}
            onCancel={this.closeFormDialog}
            footer={[
              <Button
                style={{ backgroundColor: '#00CC70', color: 'white' }}
                key="back"
                onClick={this.closeFormDialog}
              >
                I Accept
              </Button>
            ]}
            style={{
              height: '75vh',
              marginTop: '2.5vh',
              minWidth: '100vw'
            }}
          >
            <iframe
              style={{ width: '95vw', height: '77vh' }}
              src="https://www.getvpos.com/Terms1"
            />
          </Modal>
          <FormDialog
            head="New Password"
            msg="Enter your New Password"
            formElements={[
              <CustomInput
                labelText="Password"
                id="repass"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => this.onChangeEvt(e),
                  value: this.state.repass,
                  autoFocus: true
                }}
              />
            ]}
            open={this.state.rfdopen}
            close={this.closeFormDialog}
            actionBtn="Change Password"
            onClick={this.renewPass}
          />
        </Spin>
        {/*<div className='animation-wrapper' style={{ zIndex: 0 }}>
          <div id='stars'></div>
          <div id='stars2'></div>
          <div id='stars3'></div>
        </div>*/}
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  local: state
});

const mapDispatchToProps = {
  login,
  resetPass,
  renewOrgPass
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Login));
