import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import customSelectStyle from 'src/assets/jss/material-dashboard-react/components/customSelectStyle.jsx';

const CustomSelect = memo(({ ...props }) => {
  const {
    classes,
    noMargin,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    menuItems,
    valueObject,
    error,
    errorText,
    success
  } = props;

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined && !noMargin
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        multiple
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(value => (
              <Chip
                key={value}
                label={valueObject ? valueObject[value] ? valueObject[value].name : value : value}
                className={classes.chip}
                style={{ height: '20px', fontSize: '0.9125rem' }}
              />
            ))}
          </div>
        )}
        classes={{
          root: marginTop,
          disabled: classes.disabled
        }}
        name={id}
        {...inputProps}
        input={
          <Input
            classes={{
              underline: underlineClasses
            }}
          />
        }
      >
        <div style={{
          float: 'right', marginRight: '15px', backgroundColor: "#00CC70",
          color: "white",
          padding: "0.5em",
          width: "5em",
          textAlign: "center",
          borderRadius: "3em"
        }}>DONE</div>
        {menuItems.map((items, key) => (
          <MenuItem
            style={{ backgroundColor: 'white' }}
            key={key}
            value={items.value || items.name}
          >
            <Checkbox
              style={{ color: '#00CC70' }}
              checked={
                inputProps.value.indexOf(
                  items.value || items.name || items.text
                ) > -1
              }
            />
            <ListItemText primary={items.name || items.text} />
          </MenuItem>
        ))}
      </Select>
      {/*{error ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null} */}
      {error ? (
        <FormHelperText error={error}>{errorText}</FormHelperText>
      ) : success ? (
        <FormHelperText>{success}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
);

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  menuItems: PropTypes.array,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};

export default withStyles(customSelectStyle)(CustomSelect);
