import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { Badge, Icon, List, Row, Spin, Table, Skeleton, Col } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import _ from 'lodash';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  doCashup,
  doCashupReset,
  getTotalVoidBillsCashup,
  getBillItemsCashup
} from 'src/redux/actions/backend';
import { setHelpMarker } from 'src/redux/actions/help';
import { openSnackbar } from 'src/redux/actions/snackbar';
import Loading from 'react-loading-bar';
import ContentLoader from 'react-content-loader';

import { PrintBillReceipt } from 'src/config/Printer';

const { Column } = Table;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'whitesmoke',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  }
});

const SkeletonTable = () => (
  <ContentLoader
    speed={0.5}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#c0c0c0"
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="46" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="92" rx="2" ry="2" width="100%" height="45" />
  </ContentLoader>
);

class Cashup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bills: [],
      bill: {},
      totals: {},
      main: true,
      loading: true,
      alert: false,
      msg: [],
      head: ''
    };
  }

  componentDidMount() {
    this.getBills();
  }

  getBills = msg => {
    const token = this.props.local.user.token;
    this.props
      .getTotalVoidBillsCashup(token, this.props.user._id)
      .then(result => {
        this.setState({
          main: false,
          cashup: false,
          bills: result.data.results.bills,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  getBillItems = bill => {
    const token = this.props.local.user.token;
    this.props
      .getBillItemsCashup(token, bill._id)
      .then(result => {
        this.setState({
          bill: result.data.results.bill,
          main: false,
          cashup: false,
          showBill: true,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Bill Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  back = () => {
    this.setState({ showBill: false });
  };
  printBill = () => {
    var copyBill = JSON.parse(JSON.stringify(this.state.bill));
    var receipt = JSON.parse(JSON.stringify(copyBill));
    let printersInfo = JSON.parse(JSON.stringify(this.props.local.user.printer));
    let printer = {};
    if (printersInfo && printersInfo.printers && printersInfo.printers.length) {
      for (var i = 0; i < printersInfo.printers.length; i++) {
        if (printersInfo.printers[i].printList.includes("Cashup") && printersInfo.printers[i].address) {
          printer = printersInfo.printers[i];
          break;
        }
      }
    }
    PrintBillReceipt(
      receipt,
      printer,
      printersInfo,
      this.props.local.user.name
    )
      .then(result => { })
      .catch(err => {
        this.onError(err);
      });
  };
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditBill: this.handleEditBill
    };
    let items = [];
    let total = 0;
    if (this.state.bill && this.state.bill.items) {
      this.state.bill.items.forEach(item => {
        if (item.voidReasons) {
          const a = _(item.voidReasons)
            .groupBy('reason')
            .values()
            .map(group => {
              return { ...group[0], qty: group.length };
            })
            .value();
          const b = _(item.voidReasons)
            .groupBy('waste')
            .values()
            .map(group => {
              return { ...group[0], qty: group.length };
            })
            .value();
          b.forEach(reason => {
            total = total + reason.qty * item.price;
            items.push({
              name: item.name,
              price: item.price,
              ...reason
            });
          });
        }
      });
    }
    return (
      <div>
        <Loading
          show={this.state.loading}
          showSpinner={false}
          color="rgb(15, 135, 123)"
        />
        <AlertDialog {...oState} />
        {this.state.showBill ? (
          <div>
            <Card style={{ marginTop: 0 }}>
              {/*}  <CardHeader
              style={{
                borderRadius: '0.5em',
                background:
                  'linear-gradient(90deg, rgba(38,125,117,1) 0%, rgba(12,152,139,1) 35%, rgba(19,173,159,1) 100%)'
              }}
            >
              {' '}
              <h4
                style={{ color: 'white' }}
                className={this.props.classes.cardTitleWhite}
              >
                Bill #{this.state.bill.orderno}
              </h4>
              <p
                style={{ color: 'rgba(255, 255, 255, 0.63)' }}
                className={this.props.classes.cardCategoryWhite}
              >
                Bill details
              </p>
            </CardHeader> */}
              <CardBody>
                <GridContainer>
                  <h4
                    style={{ color: 'black' }}
                    className={this.props.classes.cardTitleWhite}
                  >
                    Bill #{this.state.bill.orderno}
                  </h4>
                  <Button
                    style={{ left: '10px' }}
                    onClick={() => this.back()}
                    color="primary"
                  >
                    Back
                  </Button>
                </GridContainer>

                <Card
                  style={{
                    borderRadius: '6px',
                    padding: '7px',
                    margin: 'auto',
                    width: '50%'
                  }}
                >
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{
                      height: 49,
                      background: 'rgba(76, 87, 92, 0.822)',
                      paddingLeft: '14px',
                      paddingRight: '14px',
                      border: 'solid 1px lightgray',
                      borderRadius: '6px',
                      borderBottomLeftRadius: '0px',
                      borderBottomRightRadius: '0px'
                    }}
                  >
                    <Col
                      span={6}
                      style={{
                        fontSize: '12px',
                        marginTop: 6,
                        color: 'white',
                        fontFamily: 'helvetica'
                      }}
                    >
                      Order <br /> #{this.state.bill.orderno}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: '12px',
                        marginTop: 6,
                        color: 'white',
                        fontFamily: 'helvetica'
                      }}
                    >
                      Type <br />
                      {this.state.bill.table
                        ? 'Table #' + this.state.bill.table
                        : this.state.bill.type}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: '12px',
                        marginTop: 6,
                        color: 'white',
                        fontFamily: 'helvetica'
                      }}
                    >
                      Description <br />
                      {this.state.bill.desc}
                    </Col>
                    <Col
                      span={4}
                      style={{
                        fontSize: '12px',
                        marginTop: 6,
                        color: 'white',
                        fontFamily: 'helvetica'
                      }}
                    >
                      {' '}
                      Date <br />
                      {this.state.bill.date
                        ? this.state.bill.date.slice(0, 4) +
                        '-' +
                        this.state.bill.date.slice(4, 6) +
                        '-' +
                        this.state.bill.date.slice(6, 8) +
                        ' '
                        : 'N/A'}
                    </Col>
                  </Row>
                  <List
                    style={{
                      background: '#f5f5f5',

                      border: 'solid 1px lightgray',
                      borderRadius: '6px',
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '0px',
                      marginTop: '0.4em'
                    }}
                    className="bill-item-list"
                    itemLayout="horizontal"
                    dataSource={items}
                    renderItem={(item, iKey) => (
                      <List.Item>
                        <Row
                          style={{ width: '100%' }}
                          type="flex"
                          justify="space-around"
                        >
                          <Col span={6}>{item.name}</Col>
                          <Col span={2}>{item.qty}</Col>
                          <Col span={4}>{'R ' + item.qty * item.price}</Col>
                          <Col span={4}>{item.reason}</Col>
                          <Col span={4}>{item.waste ? 'Made' : 'Unmade'}</Col>
                        </Row>
                      </List.Item>
                    )}
                  />

                  <div
                    style={{
                      paddingTop: '0%',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      fontWeight: 600
                    }}
                  >
                    <p
                      className="bill-sub-total"
                      style={{
                        fontSize: '24px'
                      }}
                    >
                      R {total}
                    </p>
                    <p
                      className="bill-sub-label"
                      style={{
                        fontSize: '24px'
                      }}
                    >
                      Total
                    </p>
                  </div>
                </Card>
              </CardBody>
            </Card>
          </div>
        ) : (
          <div>
            <h4
              style={{ color: 'black' }}
              className={this.props.classes.cardTitleWhite}
            >
              Total Void Bills
            </h4>
            <Button
              style={{
                position: 'fixed',
                right: '0.5em',
                bottom: '0.5em',
                background: 'rgba(0, 204, 112, 0.898)',
                border: '1px solid transparent',
                color: 'white',
                padding: '0em',
                width: '3.5em',
                height: '3.5em',
                borderRadius: '5em',
                fontSize: '1em',
                zIndex: 1000
              }}
              onClick={() => this.props.back()}
              color="primary"
            >
              <Icon
                style={{
                  position: 'absolute',
                  paddingLeft: 3
                }}
                type="arrow-left"
              />
            </Button>
            <GridContainer>
            </GridContainer>
            <Row>
              <StyledTable
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                dataSource={this.state.bills}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 25,
                  itemRender: (current, type, originalElement) => {
                    if (type === 'prev') {
                      return <a>Previous</a>;
                    }
                    if (type === 'next') {
                      return <a>Next</a>;
                    }
                    return originalElement;
                  },
                  showTotal: (total, range) =>
                    `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                  defaultCurrent: 1
                }}
                onChange={this.handleSort}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      e.stopPropagation()
                      this.getBillItems(record)
                    }
                  }
                }}
              >
                <Column title="Bill #" dataIndex="orderno" key="orderno" />
                <Column
                  title="User"
                  dataIndex="waiter" key="waiter"
                />
                <Column
                  title="Date Created"
                  render={(text, record) => (
                    <span>
                      {record.date.slice(0, 4) +
                        '-' +
                        record.date.slice(4, 6) +
                        '-' +
                        record.date.slice(6, 8) +
                        ' ' +
                        record.date.slice(8, 10) +
                        ':' +
                        record.date.slice(10, 12)}
                    </span>
                  )}
                />
                <Column
                  title="Date End"
                  render={(text, record) => (
                    <span>
                      {record.moddate.slice(0, 4) +
                        '-' +
                        record.moddate.slice(4, 6) +
                        '-' +
                        record.moddate.slice(6, 8) +
                        ' ' +
                        record.moddate.slice(8, 10) +
                        ':' +
                        record.moddate.slice(10, 12)}
                    </span>
                  )}
                />
                <Column
                  title="Transaction Type"
                  render={(text, record) => (
                    <span>
                      {record.cash > 0 && record.card > 0
                        ? 'Cash and Card'
                        : record.cash > 0
                          ? 'Cash'
                          : record.card > 0
                            ? 'Card'
                            : record.tab > 0
                              ? 'Tab'
                              : record.yoco > 0
                                ? 'Yoco'
                                : 'None'}
                    </span>
                  )}
                />
                <Column
                  title="Void Total"
                  render={(text, record) => {
                    let total = 0;
                    record.items.map(item => {
                      total = total + item.voidReasons.length * item.price;
                    });
                    return <span>R {total}</span>;
                  }}
                />
                <Column
                  title="Actions"
                  render={(text, record) => (
                    <div>
                      <IconButton onClick={(e) => {
                        e.stopPropagation()
                        this.getBillItems(record)
                      }}>
                        {
                          <Icon
                            type="eye"
                            style={{
                              color: 'green'
                            }}
                          />
                        }
                      </IconButton>
                    </div>
                  )}
                />
              </StyledTable>
            </Row>
          </div>
        )}
        <Snackbar {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  orgData: state.backend.orgname
});

const mapDispatchToProps = {
  getTotalVoidBillsCashup,
  getBillItemsCashup,
  setHelpMarker,
  doCashup,
  doCashupReset,
  openSnackbar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Cashup));
