import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { Badge, Icon, List, Row, Spin, Table, Skeleton, Col } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import _ from 'lodash';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  doCashup,
  doCashupReset,
  getCashPaymentsCashup,
  getBillItemsCashup
} from 'src/redux/actions/backend';
import { setHelpMarker } from 'src/redux/actions/help';
import { openSnackbar } from 'src/redux/actions/snackbar';
import Loading from 'react-loading-bar';
import ContentLoader from 'react-content-loader';
import moment from 'moment';

import { PrintBillReceipt } from 'src/config/Printer';

const { Column } = Table;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'whitesmoke',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '34px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  }
});

const SkeletonTable = () => (
  <ContentLoader
    speed={0.5}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#c0c0c0"
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="46" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="92" rx="2" ry="2" width="100%" height="45" />
  </ContentLoader>
);

class Cashup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cashPayments: [],
      bill: {},
      totals: {},
      main: true,
      loading: true,
      alert: false,
      msg: [],
      head: ''
    };
  }

  componentDidMount() {
    this.getBills();
  }

  getBills = msg => {
    const token = this.props.local.user.token;

    var date = '';
    var id = this.props.user._id;
    if (this.props.user.history) {
      date = moment(this.props.user.timestamp._d).format("YYYYMMDD");
      id = this.props.user.session.userid;
    }

    this.props
      .getCashPaymentsCashup(token, id, date)
      .then(result => {
        this.setState({
          main: false,
          cashup: false,
          cashPayments: result.data.results.cashPayments,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  getBillItems = bill => {
    const token = this.props.local.user.token;
    this.props
      .getBillItemsCashup(token, bill._id)
      .then(result => {
        this.setState({
          bill: result.data.results.bill,
          main: false,
          cashup: false,
          showBill: true,
          loading: false
        });
      })
      .catch(err => {
        this.onError(err);
      });
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Bill Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          ''
        )
  });
  back = () => {
    this.setState({ showBill: false });
  };
  printBill = () => {
    var copyBill = JSON.parse(JSON.stringify(this.state.bill));
    var receipt = JSON.parse(JSON.stringify(copyBill));
    let printersInfo = JSON.parse(JSON.stringify(this.props.local.user.printer));
    let printer = {};
    if (printersInfo && printersInfo.printers && printersInfo.printers.length) {
      for (var i = 0; i < printersInfo.printers.length; i++) {
        if (printersInfo.printers[i].printList.includes("Cashup") && printersInfo.printers[i].address) {
          printer = printersInfo.printers[i];
          break;
        }
      }
    }
    PrintBillReceipt(
      receipt,
      printer,
      printersInfo,
      this.props.local.user.name
    )
      .then(result => { })
      .catch(err => {
        this.onError(err);
      });
  };
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditBill: this.handleEditBill
    };
    return (
      <div>
        <Loading
          show={this.state.loading}
          showSpinner={false}
          color="rgb(15, 135, 123)"
        />
        <AlertDialog {...oState} />

        <div>

          <h4
            style={{ color: 'black' }}
            className={this.props.classes.cardTitleWhite}
          >
            <span style={{ color: "rgba(0, 204, 112, 0.898)" }}>{this.props.user.name}</span> Cash Payments
                </h4>
          <Button
            style={{
              position: 'fixed',
              right: '0.5em',
              bottom: '0.5em',
              background: 'rgba(0, 204, 112, 0.898)',
              border: '1px solid transparent',
              color: 'white',
              padding: '0em',
              width: '3.5em',
              height: '3.5em',
              borderRadius: '5em',
              fontSize: '1em',
              zIndex: 1000
            }}
            onClick={() => this.props.back()}
            color="primary"
          >
            <Icon
              style={{
                position: 'absolute',
                paddingLeft: 3
              }}
              type="arrow-left"
            />
          </Button>
          <Row>
            <StyledTable
              style={{ background: 'white' }}
              rowKey="_id"
              size="middle"
              dataSource={this.state.cashPayments}
              pagination={{
                hideOnSinglePage: true,
                pageSize: 25,
                itemRender: (current, type, originalElement) => {
                  if (type === 'prev') {
                    return <a>Previous</a>;
                  }
                  if (type === 'next') {
                    return <a>Next</a>;
                  }
                  return originalElement;
                },
                showTotal: (total, range) =>
                  `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                defaultCurrent: 1
              }}
              onChange={this.handleSort}

            >
              <Column title="Reason" dataIndex="reason" key="reason" />
              <Column
                title="Amount"
                render={(text, record) => (
                  <span>
                    R {record.amount.toFixed(2)}
                  </span>
                )}
              />
            </StyledTable>
          </Row>
        </div>

        <Snackbar {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  orgData: state.backend.orgname
});

const mapDispatchToProps = {
  getCashPaymentsCashup,
  getBillItemsCashup,
  setHelpMarker,
  doCashup,
  doCashupReset,
  openSnackbar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Cashup));
