import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import { Col } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import LoginWave from 'src/static/login-back-wave.png';
import Logo from 'src/static/vpos-logo-white-new.png';
import {
  NumberButtons,
  NumberButtonsChange
} from 'src/views/LoginUser/NumberButtons';

const vertical = 'bottom';
const horizontal = 'right';

const NewCardHeader = memo(props => {
  return (
    <CardHeader className="user-reset-area-header">
      <img
        src={Logo}
        alt="Logo"
        style={{
          width: '10em',
          marginTop: '1em',
          marginLeft: '2.4em',
          opacity: 1
        }}
      />{' '}
      <h4
        style={{
          paddingTop: '0.3em',
          marginLeft: '1.7em',
          color: 'white',
          fontWeight: '700',
          fontSize: '20px',
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
        }}
      >
        {props.userLoginState.loginType === 'Pin'
          ? 'Change login pin'
          : 'Change login pattern'}
      </h4>
      <h2
        className="sub-head-login"
        style={{
          marginLeft: '2.1em',
          marginTop: '-0.8em',
          color: 'white',
          fontWeight: '100',
          fontSize: '16px',
          fontFamily: 'Arial, Helvetica, sans-serif',

          paddingBottom: '1em',
          width: '95%'
        }}
      >
        {props.userLoginState.loginType === 'Pin'
          ? 'Please Enter your new pin'
          : 'Please swipe your new pattern'}
      </h2>
    </CardHeader>
  );
});

const UserLogin = memo(
  ({
    setUserLogin,
    setButtons,
    onError,
    renewPass,
    resetUser,
    dashState,
    backToLogin,
    numberState,
    userLoginState,
    setDash,
    submitForm
  }) => {
    let [loadTimeout, setLoadTimeout] = useState(true);
    const [scanVisible, setScanVisible] = useState(false);
    const [snackBarProps, setSnackBar] = useState({ open: false, message: '' });
    [userLoginState, setUserLogin.Provider] = useState(userLoginState);
    [numberState, setButtons.Provider] = useState(numberState);

    const startScan = () => {
      setScanVisible(true);
    };
    const handleScan = data => {
      if (data && !dashState.loading) {
        setDash(prevState => ({
          ...prevState,
          loading: true
        }));
        setScanVisible(false);
        submitForm(data, false, userLoginState);
      }
    };
    const handleError = err => {
      setScanVisible(false);
    };
    const cancelScan = () => {
      if (this.props.loggedUser) {
        this.props.push('/screener/surveys');
      } else {
        this.setState({ start: false });
        this.props.push('/addsurvey?cancel');
      }
    };
    const QRScanner = () => {
      return (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%', margin: 'auto' }}
        />
      );
    };

    const doRenewPass = numberButtonsProps => {
      const token = userLoginState.token;
      setDash(prevState => ({
        ...prevState,
        loading: true
      }));
      if (numberButtonsProps.passReset.length < 4) {
        setButtons.Provider(prevState => ({
          ...prevState,
          passReset: []
        }));
        onError('Password must be 4 dots or more');
        return;
      }
      userLoginState.passReset = numberButtonsProps.passReset
        .toString()
        .replace(/,/g, '');

      if (userLoginState.passReset === '0123') {
        setButtons.Provider(prevState => ({
          ...prevState,
          passReset: []
        }));
        onError('Default password found');
        return;
      }
      renewPass(userLoginState, token)
        .then(res => {
          //props.removeLoader();
          setDash(prevState => ({
            ...prevState,
            loading: false,
            reset: false
          }));
          if (!window.isPhone) {
            setUserLogin.Provider(prevState => ({
              ...prevState,
              username: '',
              name: '',
              id: ''
            }));
          }
          setButtons.Provider(prevState => ({
            ...prevState,
            password: [],
            passReset: []
          }));
          resetUser();
        })
        .catch(err => {
          onError(err);
        });
    };

    useEffect(() => {
      loadTimeout = true;
      setTimeout(() => {
        setLoadTimeout(false);
      }, 700);
    }, []);
    return (
      <div>
        {dashState.reset ? (
          <div>
            <NewCardHeader userLoginState={userLoginState} />
            <GridItem
              style={{
                position: 'absolute',
                minHeight: '20em',
                top: '59%',
                left: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%) '
              }}
            >
              <NumberButtonsChange
                doRenewPass={doRenewPass}
                numberState={numberState}
                backToLogin={backToLogin}
                userLoginState={userLoginState}
              />
            </GridItem>
          </div>
        ) : (
          <GridItem
            style={{
              marginTop: '20vh'
              // position: "absolute",
              // minHeight: "20em",
              // top: "50%",
              // left: "66%",
              // transform: "translate(-50%, -50%)",
            }}
          >
            <div className="login-Logo">
              <div className="user-login-area-numbers">
                {/*<Avatar
                  className="login-username"
                  style={
                    !dashState.loading
                      ? {
                          opacity: 1,
                          fontSize: "18px",
                          fontWeight: "700",
                          position: "absolute",
                          top: "-5.6em",
                          fontFamily: "Nunito",
                          width: "3.1em",
                          height: "3.1em",
                          left: "10.05em",
                          background: "#11141A",
                          marginRight: "-50%",
                          border: "0px solid #00CC70",
                          transform: "translate(-50%, -50%)",
                          color: "white",
                        }
                      : {opacity: 0}
                  }
                >
                  {getInitials(userLoginState.name)}
                  
                </Avatar>*/}
                <p
                  className="login-username"
                  style={
                    !dashState.loading
                      ? {
                          opacity: 1,
                          fontSize: '18px',
                          fontWeight: '700',
                          // position: "absolute",
                          // top: "-4em",
                          fontFamily: 'Nunito',
                          // width: "15em",
                          height: '3.1em',
                          // left: "10.05em",
                          // background: "#11141A",
                          // marginRight: "-50%",
                          // border: "0px solid #00CC70",
                          // transform: "translate(-50%, -50%)",
                          color: 'white',
                          textAlign: 'center'
                        }
                      : { opacity: 0 }
                  }
                >
                  {userLoginState.name ? (
                    <span>
                      <span
                        style={{
                          fontWeight: '100',
                          fontSize: '18px',
                          color: 'rgb(0, 204, 112)',
                          fontFamily: 'Nunito'
                        }}
                      >
                        {userLoginState.name === 'Super Admin' ? (
                          <span>{userLoginState.name}</span>
                        ) : (
                          <span>
                            Hi,{' '}
                            {userLoginState.name
                              .split(' ')
                              .slice(0, -1)
                              .join(' ')}
                          </span>
                        )}
                      </span>
                      <br />
                      <span
                        style={{
                          fontWeight: '100',
                          fontSize: '16px',
                          fontFamily: 'Nunito'
                        }}
                      >
                        {userLoginState.loginType === 'Pin'
                          ? ' Please enter your pin to login'
                          : 'Please swipe your pattern to login'}
                      </span>
                      <img
                        src={
                          LoginWave
                          //userLoginState.loginError === true ? LoginShipError : LoginShip
                        }
                        style={{
                          position: 'absolute',
                          opacity: 0.1,
                          left: 0,
                          width: '100%'
                        }}
                        alt="ship"
                        border="0"
                      />
                    </span>
                  ) : (
                    <img
                      src={
                        LoginWave
                        //userLoginState.loginError === true ? LoginShipError : LoginShip
                      }
                      style={{
                        position: 'absolute',

                        left: 0,
                        width: '100%'
                      }}
                      alt="ship"
                      border="0"
                    />
                  )}
                </p>
              </div>
            </div>{' '}
            {userLoginState.username ? (
              <div
                style={
                  dashState.loading
                    ? {
                        transition: '0.3s all',
                        opacity: 0,
                        maxWidth: '23em',
                        minWidth: '23em'
                        // position: "absolute",
                        // top: "-2em",
                      }
                    : {
                        display: userLoginState.username ? 'block' : 'none',
                        transition: '0.2s all',
                        opacity: userLoginState.username ? 1 : 0,
                        maxWidth: '23em',
                        minWidth: '23em',
                        margin: 'auto'
                        // position: "absolute",
                        // top: "-2em",
                      }
                }
              >
                <NumberButtons
                  submitForm={submitForm}
                  userLoginState={userLoginState}
                  numberState={numberState}
                />
              </div>
            ) : null}
            {userLoginState.username &&
            userLoginState.loginType &&
            userLoginState.loginType === 'QR Code' ? (
              <div
                className="login-qr-code"
                style={
                  dashState.loading
                    ? {
                        transition: '0.3s all',
                        opacity: 0,
                        maxWidth: '23em',
                        minWidth: '23em',
                        position: 'absolute',
                        top: '-2em'
                      }
                    : {
                        transition: '0.2s all',
                        opacity: loadTimeout ? 0 : 1,
                        maxWidth: '23em',
                        minWidth: '23em',
                        position: 'absolute',
                        top: '-2em'
                      }
                }
              >
                <QRScanner />{' '}
              </div>
            ) : null}
            {/*<img className="ship-image" 
                    src={LoginShip
                        //userLoginState.loginError === true ? LoginShipError : LoginShip
                    }
                        style={{
                            position: "absolute",
                            marginLeft: "-32.67em",
                            bottom: "-42.7em",
                            width: "90em",
                            zIndex: -1
                        }} alt="ship" border="0" />*/}
          </GridItem>
        )}{' '}
        <Snackbar
          open={snackBarProps.open}
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          autoHideDuration={3000}
          onClose={() =>
            setSnackBar({
              open: false,
              message: ''
            })
          }
          TransitionComponent={Fade}
          style={{
            color: '#fff',
            background: '#1ea1a9'
          }}
          ContentProps={{
            'aria-describedby': 'message-id',
            style: {
              color: '#fff',
              background: '#1ea1a9'
            }
          }}
          message={<span id="message-id">{snackBarProps.message}</span>}
        />
      </div>
    );
  }
);

const PhoneUserLogin = memo(
  ({
    setUserLogin,
    setButtons,
    onError,
    renewPass,
    resetUser,
    userLoginState,
    dashState,
    numberState,
    setDash,
    submitForm
  }) => {
    let [loadTimeout, setLoadTimeout] = useState(true);
    const [scanVisible, setScanVisible] = useState(false);
    const [snackBarProps, setSnackBar] = useState({ open: false, message: '' });
    [userLoginState, setUserLogin.Provider] = useState(userLoginState);
    [numberState, setButtons.Provider] = useState(numberState);
    const startScan = () => {
      setScanVisible(true);
    };
    const handleScan = data => {
      if (data && !dashState.loading) {
        setDash(prevState => ({
          ...prevState,
          loading: true
        }));
        setScanVisible(false);
        submitForm(data, false, userLoginState);
      }
    };
    const handleError = err => {
      setScanVisible(false);
    };
    const cancelScan = () => {
      if (this.props.loggedUser) {
        this.props.push('/screener/surveys');
      } else {
        this.setState({ start: false });
        this.props.push('/addsurvey?cancel');
      }
    };
    const QRScanner = () => {
      return (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%', margin: 'auto' }}
        />
      );
    };

    const doRenewPass = numberButtonsProps => {
      const token = userLoginState.token;
      setDash(prevState => ({
        ...prevState,
        loading: true
      }));
      if (numberButtonsProps.passReset.length < 4) {
        setButtons.Provider(prevState => ({
          ...prevState,
          passReset: []
        }));
        onError('Password must be 4 dots or more');
        return;
      }
      userLoginState.passReset = numberButtonsProps.passReset
        .toString()
        .replace(/,/g, '');

      if (userLoginState.passReset === '0123') {
        setButtons.Provider(prevState => ({
          ...prevState,
          passReset: []
        }));
        onError('Default password found');
        return;
      }
      renewPass(userLoginState, token)
        .then(res => {
          //props.removeLoader();
          setDash(prevState => ({
            ...prevState,
            loading: false,
            reset: false
          }));
          if (!window.isPhone) {
            setUserLogin.Provider(prevState => ({
              ...prevState,
              username: '',
              name: '',
              id: ''
            }));
          }
          setButtons.Provider(prevState => ({
            ...prevState,
            password: [],
            passReset: []
          }));
          resetUser();
        })
        .catch(err => {
          onError(err);
        });
    };

    useEffect(() => {
      loadTimeout = true;
      setTimeout(() => {
        setLoadTimeout(false);
      }, 700);
    }, []);
    return (
      <div>
        {dashState.reset ? (
          <GridItem
            style={{
              width: '100%',
              position: 'absolute',
              minHeight: '20em',
              top: '20%'
            }}
          >
            <NumberButtonsChange
              doRenewPass={doRenewPass}
              numberState={numberState}
              userLoginState={userLoginState}
            />
          </GridItem>
        ) : (
          <Col
            span={24}
            style={{
              textAlign: 'center',
              width: '100%',
              position: 'absolute',
              minHeight: '20em',
              top: '40%',
              left: '-0.2em'
            }}
          >
            {userLoginState.username ? (
              <div
                style={
                  dashState.loading
                    ? {
                        opacity: 0,
                        maxWidth: '23em',
                        minWidth: '23em'
                      }
                    : {
                        display: userLoginState.username ? 'block' : 'none',
                        margin: 'auto',
                        opacity: userLoginState.username ? 1 : 0,
                        maxWidth: '23em',
                        minWidth: '23em'
                      }
                }
              >
                <NumberButtons
                  submitForm={submitForm}
                  userLoginState={userLoginState}
                  numberState={numberState}
                />
              </div>
            ) : null}
            {userLoginState.username &&
            userLoginState.loginType &&
            userLoginState.loginType === 'QR Code' ? (
              <div
                style={
                  dashState.loading
                    ? {
                        transition: '0.3s all',
                        opacity: 0,
                        maxWidth: '23em',
                        minWidth: '23em'
                      }
                    : {
                        transition: '0.2s all',
                        opacity: loadTimeout ? 0 : 1,
                        maxWidth: '23em',
                        margin: 'auto',
                        minWidth: '23em'
                      }
                }
              >
                <QRScanner />
              </div>
            ) : null}
          </Col>
        )}{' '}
        <Snackbar
          open={snackBarProps.open}
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          autoHideDuration={3000}
          onClose={() =>
            setSnackBar({
              open: false,
              message: ''
            })
          }
          TransitionComponent={Fade}
          style={{
            color: '#fff',
            background: '#1ea1a9'
          }}
          ContentProps={{
            'aria-describedby': 'message-id',
            style: {
              color: '#fff',
              background: '#1ea1a9'
            }
          }}
          message={<span id="message-id">{snackBarProps.message}</span>}
        />
      </div>
    );
  }
);

const PhoneUserLoginHeader = ({
  localUserState,
  setLocalUserState,
  userLoginState,
  setUser,
  state
}) => {
  return (
    <CustomSelect
      labelText={<span style={{ color: 'white' }}>Select User</span>}
      id="mainmenu"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: e => {
          const value = e.target.value;
          setUser(value, true);
        },
        value: localUserState,
        style: { zIndex: 9, color: 'white' }
      }}
      menuItems={state.users}
    />
  );
};

export { PhoneUserLogin, PhoneUserLoginHeader, UserLogin };
