import { Badge, Col, Layout, Row, notification } from 'antd';
import React from 'react';
import {
  AiOutlineFileText,
  AiOutlineHome,
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineUser
} from 'react-icons/ai';

const { Footer } = Layout;
const CustomerFooter = props => {
  return (
    <Footer
      style={{
     zIndex:5,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: 'white',
        lineHeight: '40px',
        padding: 0,
        boxShadow: '0px 11px 20px 0'
      }}
    >
      <Row type="flex" justify="space-around" style={{  }}>
        <Col span={4} style={{ display: 'flex' }}>
          <div
            onClick={e => props.navToView('companyList')}
            style={{ margin: 'auto',marginBottom:"-0.2em", display: 'grid', cursor: 'pointer' }}
          >
            <AiOutlineHome
              style={{
                margin: 'auto',
                color:
                  props.currentView === 'companyList' && !props.search
                    ? '#00CC70'
                    : 'grey',
                fontSize: 20
              }}
            />
            <span
              style={{
                marginTop: -11,
                fontSize: 12,
                color:
                  props.currentView === 'companyList' && !props.search
                    ? '#00CC70'
                    : 'grey'
              }}
            >
              Home
            </span>
          </div>
        </Col>
        <Col span={4} style={{ display: 'flex' }}>
          <div
            onClick={e => props.navToView('companyList', true)}
            style={{ margin: 'auto', marginBottom:"-0.2em",display: 'grid' }}
          >
            <AiOutlineSearch
              style={{
                margin: 'auto',
                color:
                  props.currentView === 'companyList' && props.search
                    ? '#00CC70'
                    : 'grey',
                fontSize: 20
              }}
            />
            <span
              style={{
                marginTop: -11,
                fontSize: 12,
                color:
                  props.currentView === 'companyList' && props.search
                    ? '#00CC70'
                    : 'grey'
              }}
            >
              Search
            </span>
          </div>
        </Col>
        <Col span={4} style={{ display: 'flex' }}>
          {!props.fabActive ? <div
            onClick={e => {
              if (props.cart.items && props.cart.items.length > 0) {
                props.setFabActive(true)
                setTimeout(() => {
                  props.setFabActiveAnimate(true)
                },50)
              } else {
                notification.info({
                  message: 'Empty Cart',
                  description: 'Cart is empty'
                });
              }
              
            }}
            style={{
              margin: 'auto',
              background: '#00CC70',
              borderRadius: '50%',
              width: 54,
              height: 54,
              bottom: 25,
              position: 'relative',
              display: 'flex'
            }}
          >
            {props.cart.items && props.cart.items.length > 0 ? (
              <div style={{ position: 'absolute', bottom: 25, right: -5}}>
                <Badge
                  style={{
                  
                    background: 'red',
                    borderRadius: '50%', 
                    
                  }}
                  count={props.cart.items.length}
                />
              </div>
            ) : null}
            <AiOutlineShoppingCart
              style={{
                margin: 'auto',
                color: 'white',
                fontSize: 30
              }}
            />
          </div> : null}
          {props.cart.items && props.cart.items.length > 0 ? (
            <div
              style={{
                position: 'absolute',
                bottom: -5,
                fontWeight: 700,
                width: '104%',
                color: '#383838',
             textAlign:"center"
                
              }}
            >
              <span>R {props.cart.total.toFixed(2)}</span>
            </div>
          ) : null}
        </Col>
        <Col span={4} style={{ display: 'flex' }}>
          <div
            onClick={e => props.navToView('myOrders')}
            style={{ margin: 'auto',marginBottom:"-0.2em", display: 'grid' }}
          >
            <AiOutlineFileText
              style={{
                margin: 'auto',
                color:
                  props.currentView === 'myOrders' ? '#00CC70' : 'grey',
                fontSize: 20
              }}
            />
            <span
              style={{
                marginTop: -11,
                fontSize: 12,
                color: props.currentView === 'myOrders' ? '#00CC70' : 'grey'
              }}
            >
              My Orders
            </span>
          </div>
        </Col>
        <Col span={4} style={{ display: 'flex' }}>
          <div
            onClick={e => props.navToView('profile')}
            style={{ margin: 'auto',marginBottom:"-0.2em", display: 'grid' }}
          >
            <AiOutlineUser
              style={{
                margin: 'auto',
                color: props.currentView === 'profile' ? '#00CC70' : 'grey',
                fontSize: 20
              }}
            />
            <span
              style={{
                marginTop: -11,
                fontSize: 12,
                color: props.currentView === 'profile' ? '#00CC70' : 'grey'
              }}
            >
              Profile
            </span>
          </div>
        </Col>
      </Row>
    </Footer>
  );
};
export default CustomerFooter;
