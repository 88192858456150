import React, { Component } from 'react';
import moment from 'moment';
import { Icon, List } from 'antd';
import styled from 'styled-components';
const { Item } = List;
const ListItem = styled(Item)`
  && {
    padding: 0px !important;
  }
  @media screen and (max-width: 576px)
.ant-list-item {
    margin-left: -5.5em;
    padding-right: 2em !important;
    width: 100vw !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
`;

const MetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 200px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
  h {
    &1,
    &2,
    &3,
    &4,
    &5 {
      margin: 0;
    }
  }
  .title-container {
    height: 50px;
    background: rgb(19, 36, 64);
    color: white;
    padding: 22px;
    align-items: center;
    display: flex;
  }
  .meta-content,
  .footer-container {
    text-align: center;
    padding: 20px;
  }
  .color-primary {
    color: rgb(19, 36, 64);
  }
  .color-secondary {
    color: rgb(19, 36, 64);
  }
`;

const DescriptionItem = ({ title, content }) => (
  <div
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
      color: 'rgba(0,0,0,0.65)',
      display: 'flex'
    }}
  >
    <div
      style={{
        marginRight: 50,
        width: '50%',
        color: 'rgba(0,0,0,0.85)',
        textAlign: 'right'
      }}
    >
      {title}:
    </div>
    <div
      style={{
        width: '50%',
        textAlign: 'left',
        fontWeight: 700
      }}
    >
      {content}
    </div>
  </div>
);
class selection extends Component {
  state = {
    loading: false
  };
  render() {
    const {
      _id,
      devices,
      pricePerDevice,
      subscription,
      name,
      price,
      subscriptionStart,
      subscriptionEnd
    } = this.props.paymentProfile;
    const PrevButton = () => this.props.prevButton;
    const PayButton = () => this.props.paymentButton;
    return (
      <div
        style={{
          width: "100%",
          maxWidth: '70em',
          padding: '50px 10px',
          margin: 'auto'
        }}
      >
        <ListItem key={_id}>
          <MetaContainer>
            <div className="title-container">
              <h4 style={{ color: 'white' }}>Subscription Summary</h4>
            </div>
            <div className="meta-content">
              <DescriptionItem title="Company Name" content={name} />
              <DescriptionItem title="Payment Type" content={"Monthly"} />
              <DescriptionItem title="Subscription Start" content={moment().format('YYYY/MM/DD')} />
              <DescriptionItem
                title="Pro-rata Price (for the remaining days in the month)"
                content={<span className="color-secondary">R{(parseFloat(subscription.monthlyPrice) / moment().daysInMonth() * (moment().daysInMonth() - moment().date())).toFixed(2)}</span>}
              />
              <DescriptionItem
                title="Monthly Price (on the 1st of every month)"
                content={<span className="color-secondary">R{parseFloat(subscription.monthlyPrice).toFixed(2)}</span>}
              />
            </div>
            <div className="footer-container">
              <PrevButton block />
              <PayButton />
            </div>
          </MetaContainer>
        </ListItem>
      </div>
    );
  }
}

export default selection;
