import { Checkbox, Icon, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import GridItem from 'src/components/Grid/GridItem.jsx';

const ReceiptCreator = allprops => {
  let oState;
  let setState;
  const [update, setUpdate] = useState(false);
  const onChangeEvtMenuRec = (eN, key, type, footer) => {
    let e = Object.assign({}, eN);
    let copyState = JSON.parse(JSON.stringify(oState.state));
    let heading = JSON.parse(JSON.stringify(oState.state.heading80));
    let headingDup = JSON.parse(JSON.stringify(oState.state.heading58));
    if (footer === 'heading58') {
      heading = JSON.parse(JSON.stringify(oState.state.heading58));
      headingDup = JSON.parse(JSON.stringify(oState.state.heading80));
    } else if (footer === 'footer80') {
      heading = JSON.parse(JSON.stringify(oState.state.footer80));
      headingDup = JSON.parse(JSON.stringify(oState.state.footer58));
    } else if (footer === 'footer58') {
      heading = JSON.parse(JSON.stringify(oState.state.footer58));
      headingDup = JSON.parse(JSON.stringify(oState.state.footer80));
    }
    let style = {};
    if (key >= 0) {
      style = JSON.parse(JSON.stringify(heading[key].style));
    }
    var change = '';
    if (type === 'text') {
      change = e.currentTarget.value;
      heading[key].text = change + '\\n';
      headingDup[key].text = change + '\\n';
    } else if (type === 'up') {
      var f = heading.splice(key, 1)[0];
      var fd = headingDup.splice(key, 1)[0];
      var keyh = key - 1;
      heading.splice(keyh, 0, f);
      var keyhd = key - 1;
      headingDup.splice(keyhd, 0, fd);
    } else if (type === 'down') {
      var f = heading.splice(key, 1)[0];
      var fd = headingDup.splice(key, 1)[0];
      var keyh = key + 1;
      heading.splice(keyh, 0, f);
      var keyhd = key + 1;
      headingDup.splice(keyhd, 0, fd);
    } else if (type === 'left') {
      var trimHead = heading[key].text.trim();
      heading[key].text = trimHead;
      headingDup[key].text = trimHead;
    } else if (type === 'center') {
      var trimHead = heading[key].text.trim();
      var trimHeadDup = headingDup[key].text.trim();
      if (oState.state.papSize === '70mm') {
        var spaces = Math.floor((33 - trimHead.length) / 2);
        if (spaces > 0) {
          for (var i = 0; i < spaces; i++) {
            trimHead = ' ' + trimHead;
          }
        }
        spaces = Math.floor((42 - trimHeadDup.length) / 2);
        if (spaces > 0) {
          for (var i = 0; i < spaces; i++) {
            trimHeadDup = ' ' + trimHeadDup;
          }
        }
      } else {
        var spaces = Math.floor((42 - trimHead.length) / 2);
        if (spaces > 0) {
          for (var i = 0; i < spaces; i++) {
            trimHead = ' ' + trimHead;
          }
        }
        spaces = Math.floor((33 - trimHeadDup.length) / 2);
        if (spaces > 0) {
          for (var i = 0; i < spaces; i++) {
            trimHeadDup = ' ' + trimHeadDup;
          }
        }
      }
      heading[key].text = trimHead;
      headingDup[key].text = trimHeadDup;
    } else if (type === 'right') {
      var trimHead = heading[key].text.trim();
      var trimHeadDup = headingDup[key].text.trim();
      if (oState.state.papSize === '70mm') {
        var spaces = 33 - trimHead.length;
        if (spaces > 0) {
          for (var i = 0; i < spaces; i++) {
            trimHead = ' ' + trimHead;
          }
        }
        spaces = 42 - trimHeadDup.length;
        if (spaces > 0) {
          for (var i = 0; i < spaces; i++) {
            trimHeadDup = ' ' + trimHeadDup;
          }
        }
      } else {
        var spaces = 42 - trimHead.length;
        if (spaces > 0) {
          for (var i = 0; i < spaces; i++) {
            trimHead = ' ' + trimHead;
          }
        }
        spaces = 33 - trimHeadDup.length;
        if (spaces > 0) {
          for (var i = 0; i < spaces; i++) {
            trimHeadDup = ' ' + trimHeadDup;
          }
        }
      }
      heading[key].text = trimHead;
      console.log(heading[key].text);
      headingDup[key].text = trimHeadDup;
      console.log(heading[key].text);
      console.log(headingDup[key].text);
    } else if (type === 'bold') {
      if (!heading[key].bold) {
        style.fontWeight = 'bold';
        heading[key].bold = true;
        heading[key].style = style;
        headingDup[key].bold = true;
        headingDup[key].style = style;
      } else {
        style.fontWeight = 400;
        heading[key].bold = false;
        heading[key].style = style;
        headingDup[key].bold = false;
        headingDup[key].style = style;
      }
    } else if (type === 'size') {
      if (!heading[key].size) {
        style.fontSize = '7.1mm';
        heading[key].size = true;
        heading[key].style = style;
        headingDup[key].size = true;
        headingDup[key].style = style;
      } else {
        style.fontSize = '3.66mm';
        heading[key].size = false;
        heading[key].style = style;
        headingDup[key].size = false;
        headingDup[key].style = style;
      }
    } else if (type === 'line-') {
      var trimHead = '';
      var trimHeadDup = '';
      if (oState.state.papSize === '70mm') {
        for (var i = 0; i < 31; i++) {
          trimHead += '-';
        }
        trimHead += '\\n';
        for (var i = 0; i < 40; i++) {
          trimHeadDup += '-';
        }
        trimHeadDup += '\\n';
      } else {
        for (var i = 0; i < 40; i++) {
          trimHead += '-';
        }
        trimHead += '\\n';
        for (var i = 0; i < 31; i++) {
          trimHeadDup += '-';
        }
        trimHeadDup += '\\n';
      }
      heading[key].text = trimHead;
      headingDup[key].text = trimHeadDup;
    } else if (type === 'line=') {
      var trimHead = '';
      var trimHeadDup = '';
      if (oState.state.papSize === '70mm') {
        for (var i = 0; i < 31; i++) {
          trimHead += '=';
        }
        trimHead += '\\n';
        for (var i = 0; i < 40; i++) {
          trimHeadDup += '=';
        }
        trimHeadDup += '\\n';
      } else {
        for (var i = 0; i < 40; i++) {
          trimHead += '=';
        }
        trimHead += '\\n';
        for (var i = 0; i < 31; i++) {
          trimHeadDup += '=';
        }
        trimHeadDup += '\\n';
      }
      heading[key].text = trimHead;
      headingDup[key].text = trimHeadDup;
    } else if (type === 'remove') {
      heading.splice(key, 1);
      headingDup.splice(key, 1);
    } else if (type === 'add') {
      heading[heading.length] = {
        text: '\\n',
        bold: false,
        size: false,
        style: {
          width: '101%',
          height: '25px',
          border: 'none',
          paddingLeft: 0,
          paddingRight: 0,
          fontSize: '3.66mm',
          fontWeight: 400
        }
      };
      headingDup[headingDup.length] = {
        text: '\\n',
        bold: false,
        size: false,
        style: {
          width: '101%',
          height: '25px',
          border: 'none',
          paddingLeft: 0,
          paddingRight: 0,
          fontSize: '3.66mm',
          fontWeight: 400
        }
      };
    }

    if (footer === 'footer80') {
      oState.setState(prevState => ({
        ...prevState,
        footer80: heading,
        footer58: headingDup
      }));
    } else if (footer === 'footer58') {
      oState.setState(prevState => ({
        ...prevState,
        footer80: headingDup,
        footer58: heading
      }));
    } else if (footer === 'heading58') {
      oState.setState(prevState => ({
        ...prevState,
        heading58: heading,
        heading80: headingDup
      }));
    } else {
      oState.setState(prevState => ({
        ...prevState,
        heading58: headingDup,
        heading80: heading
      }));
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 400 / 1024 < 4;
    if (!isLt2M) {
      oState.onError('Image Lager then 400kb');
    }
    return false;
  };

  const handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };

  const getBase64 = (img, callback) => {
    var type = img.type.replace('image/', '');
    Resizer.imageFileResizer(img, 500, 500, type, 100, 0, uri => {
      var image = new Image();
      image.onload = function() {
        var canvas = document.createElement('canvas');
        canvas.height = image.height;
        canvas.width = image.width;
        var context = canvas.getContext('2d');
        context.fillStyle = '#fff'; /// set white fill style
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0);
        var imageData = canvas.toDataURL('image/jpeg');
        callback(imageData, canvas.height, canvas.width);
      };
      image.src = uri;
    });
  };

  const handleLogoImage = info => {
    const imageUrl = (imageData, height, width) => {
      oState.setState(prevState => ({
        ...prevState,
        logo: {
          ...prevState.logo,
          base64: imageData,
          width: width,
          height: height
        }
      }));
    };
    getBase64(info.file, imageUrl);
  };

  oState = {
    ...allprops
  };
  const imgDivRef = useRef(null);
  useEffect(() => {
    setUpdate(!update);
  }, [oState.state.logo]);
  useEffect(() => {
    setUpdate(!update);
  }, [oState.state.papSize]);
  useEffect(() => {
    setUpdate(!update);
  }, [
    imgDivRef && imgDivRef.current
      ? imgDivRef.current.getBoundingClientRect().height
      : 0
  ]);
  return (
    <GridItem
      xs={12}
      sm={12}
      md={12}
      style={{
        textAlign: 'center',
        marginBottom: '2em'
        //,margin:"auto"
      }}
    >
      <div style={{ display: 'inline-flex', textAlign: 'left' }}>
        <div
          class="container"
          style={{
            background: 'white',
            marginLeft: '-0.50em',
            boxShadow: 'rgb(229 233 242) 0px 5px 10px 2px',
            borderRadius: '1em',
            marginRight: '1em'
          }}
        >
          <div
            ref={imgDivRef}
            style={{ textAlign: 'left', background: 'white', margin: 'auto' }}
          >
            <Upload
              name="logo"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={handleImageUpload}
              onChange={handleLogoImage}
              accept="image/*"
            >
              <button
                style={{
                  width: oState.state.papSize || '89mm',
                  border: 'none',
                  backgroundColor: 'rgb(0, 204, 112)',
                  borderRadius: '0.6em 0.6em 0em 0em',
                  height: '2.5em',
                  color: 'white'
                }}
              >
                {'Add/Edit Logo'}
              </button>
            </Upload>{' '}
            <br />
            {oState.state.logo && oState.state.logo.base64 ? (
              <div
                style={{
                  width: oState.state.papSize || '89mm',
                  border: '0px solid black',
                  borderBottom: 'none',
                  textAlign: 'center'
                }}
              >
                <img
                  src={oState.state.logo.base64}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
              </div>
            ) : null}{' '}
          </div>
          <div>
            <form
              style={{
                fontFamily: 'Courier, monospace',
                width: oState.state.papSize || '89mm',
                border: '0px solid black',
                borderTop: 'none'
              }}
              autoComplete={'off'}
              method={'post'}
            >
              <input
                autoComplete={'false'}
                name={'hidden'}
                type={'text'}
                style={{ display: 'none' }}
              />
              {oState.state.papSize !== '89mm'
                ? oState.state.heading58 &&
                  oState.state.heading58.map((line, key) => (
                    <input
                      key={'heading58' + key}
                      type={'text'}
                      maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                      style={line.style}
                      onChange={e =>
                        onChangeEvtMenuRec(e, key, 'text', 'heading58')
                      }
                      value={line.text.replace(/\\n/g, '')}
                    />
                  ))
                : oState.state.heading80 &&
                  oState.state.heading80.map((line, key) => (
                    <input
                      key={'heading80' + key}
                      type={'text'}
                      maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                      style={line.style}
                      onChange={e =>
                        onChangeEvtMenuRec(e, key, 'text', 'heading80')
                      }
                      value={line.text.replace(/\\n/g, '')}
                    />
                  ))}
              <div style={{ bordeRight: '1px solid black', width: '99%' }}>
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={'----------------------------------------'}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={'Invoice # : 00010 0100'}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={oState.state.desc ? 'Desc: Test Print' : ''}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={'Qty Item               Price'}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={'----------------------------------------'}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={''}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={'----------------------------------------'}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={oState.state.vat ? 'VAT: R 0.00' : ''}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={'Discount: R0.00'}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={'Total:R0.00'}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={'----------------------------------------'}
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={
                    oState.state.subs ? 'Gratuity :___________________' : ''
                  }
                />
                <input
                  disable="true"
                  type={'text'}
                  maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                  style={{
                    width: '101%',
                    height: '25px',
                    border: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '3.66mm',
                    fontWeight: 400
                  }}
                  value={
                    oState.state.subs ? 'Total Due:___________________' : ''
                  }
                />
              </div>
              {oState.state.papSize !== '89mm'
                ? oState.state.footer58 &&
                  oState.state.footer58.map((line, key) => (
                    <input
                      key={'footer58' + key}
                      type={'text'}
                      maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                      style={line.style}
                      onChange={e =>
                        onChangeEvtMenuRec(e, key, 'text', 'footer58')
                      }
                      value={line.text.replace(/\\n/g, '')}
                    />
                  ))
                : oState.state.footer80 &&
                  oState.state.footer80.map((line, key) => (
                    <input
                      key={'footer80' + key}
                      type={'text'}
                      maxLength={oState.state.papSize !== '89mm' ? '31' : '40'}
                      style={line.style}
                      onChange={e =>
                        onChangeEvtMenuRec(e, key, 'text', 'footer80')
                      }
                      value={line.text.replace(/\\n/g, '')}
                    />
                  ))}
            </form>
            <button
              style={{
                width: oState.state.papSize || '89mm',
                border: 'none',
                backgroundColor: 'rgb(0, 204, 112)',
                //borderRadius: "0em 0em 0.6em 0.6em",
                height: '2.5em',
                color: 'white'
              }}
              onClick={e =>
                onChangeEvtMenuRec(
                  null,
                  -1,
                  'add',
                  oState.state.papSize !== '89mm' ? 'footer58' : 'footer80'
                )
              }
            >
              Add Footer Line
            </button>
          </div>
        </div>

        <div
          style={{
            whiteSpace: 'nowrap',
            borderLeft: '1.5px solid rgba(229, 233, 242, 0.9)',
            marginLeft: '1em',
            paddingLeft: '0.9em'
          }}
        >
          <div
            style={{
              height:
                imgDivRef && imgDivRef.current
                  ? imgDivRef.current.getBoundingClientRect().height
                  : 0
            }}
          />

          {oState.state.papSize !== '89mm'
            ? oState.state.heading58 &&
              oState.state.heading58.map((line, key) => (
                <div key={'heading58map' + key}>
                  <div
                    style={{
                      display: 'block',
                      height: '25px',
                      display: 'inline-flex'
                    }}
                  >
                    <div>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'left', 'heading58')
                        }
                      >
                        <Icon type="align-left" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'center', 'heading58')
                        }
                      >
                        <Icon type="align-center" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'right', 'heading58')
                        }
                      >
                        <Icon type="align-right" />
                      </button>
                    </div>
                    <div style={{ paddingLeft: '5px' }}>
                      <button
                        style={
                          line.bold
                            ? {
                                borderStyle: 'inset',
                                background: 'white',
                                border: '1px solid black',
                                color: 'black'
                              }
                            : {
                                background: 'white',
                                border: '1px solid black',
                                color: 'black'
                              }
                        }
                        onClick={e =>
                          onChangeEvtMenuRec(
                            !line.bold,
                            key,
                            'bold',
                            'heading58'
                          )
                        }
                      >
                        <Icon type="bold" />
                      </button>
                      <button
                        style={
                          line.size
                            ? {
                                borderStyle: 'inset',
                                background: 'white',
                                border: '1px solid black',
                                color: 'black'
                              }
                            : {
                                background: 'white',
                                border: '1px solid black',
                                color: 'black'
                              }
                        }
                        onClick={e =>
                          onChangeEvtMenuRec(
                            !line.size,
                            key,
                            'size',
                            'heading58'
                          )
                        }
                      >
                        <Icon type="font-size" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'line-', 'heading58')
                        }
                      >
                        <Icon type="minus" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'line=', 'heading58')
                        }
                      >
                        {'='}
                      </button>
                    </div>
                    <div style={{ paddingLeft: '5px' }}>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        disabled={key != 0 ? false : true}
                        onClick={e =>
                          onChangeEvtMenuRec(false, key, 'up', 'heading58')
                        }
                      >
                        <Icon type="caret-up" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        disabled={
                          key != oState.state.heading.length - 1 ? false : true
                        }
                        onClick={e =>
                          onChangeEvtMenuRec(false, key, 'down', 'heading58')
                        }
                      >
                        <Icon type="caret-down" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'remove', 'heading58')
                        }
                      >
                        <Icon type="delete" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            : oState.state.heading80 &&
              oState.state.heading80.map((line, key) => (
                <div key={'heading80map' + key}>
                  <div
                    style={{
                      display: 'block',
                      height: '25px',
                      display: 'inline-flex'
                    }}
                  >
                    <div>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'left', 'heading80')
                        }
                      >
                        <Icon type="align-left" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'center', 'heading80')
                        }
                      >
                        <Icon type="align-center" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'right', 'heading80')
                        }
                      >
                        <Icon type="align-right" />
                      </button>
                    </div>
                    <div style={{ paddingLeft: '5px' }}>
                      <button
                        style={
                          line.bold
                            ? {
                                borderStyle: 'inset',
                                background: 'white',
                                border: '1px solid black',
                                color: 'black'
                              }
                            : {
                                background: 'white',
                                border: '1px solid black',
                                color: 'black'
                              }
                        }
                        onClick={e =>
                          onChangeEvtMenuRec(
                            !line.bold,
                            key,
                            'bold',
                            'heading80'
                          )
                        }
                      >
                        <Icon type="bold" />
                      </button>
                      <button
                        style={
                          line.size
                            ? {
                                borderStyle: 'inset',
                                background: 'white',
                                border: '1px solid black',
                                color: 'black'
                              }
                            : {
                                background: 'white',
                                border: '1px solid black',
                                color: 'black'
                              }
                        }
                        onClick={e =>
                          onChangeEvtMenuRec(
                            !line.size,
                            key,
                            'size',
                            'heading80'
                          )
                        }
                      >
                        <Icon type="font-size" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'line-', 'heading80')
                        }
                      >
                        <Icon type="minus" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'line=', 'heading80')
                        }
                      >
                        {'='}
                      </button>
                    </div>
                    <div style={{ paddingLeft: '5px' }}>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        disabled={key != 0 ? false : true}
                        onClick={e =>
                          onChangeEvtMenuRec(false, key, 'up', 'heading80')
                        }
                      >
                        <Icon type="caret-up" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        disabled={
                          key != oState.state.heading.length - 1 ? false : true
                        }
                        onClick={e =>
                          onChangeEvtMenuRec(false, key, 'down', 'heading80')
                        }
                      >
                        <Icon type="caret-down" />
                      </button>
                      <button
                        style={{
                          background: 'white',
                          border: '1px solid black',
                          color: 'black'
                        }}
                        onClick={e =>
                          onChangeEvtMenuRec(null, key, 'remove', 'heading80')
                        }
                      >
                        <Icon type="delete" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          <button
            style={{
              textAlign: 'left',
              paddingLeft: '0.8em',
              border: 'none',
              width: '75.5mm',
              backgroundColor: 'transparent',
              borderRadius: '0em 0em 1em 0em',
              height: '2.5em',
              color: 'rgb(0, 204, 112)'
            }}
            onClick={e =>
              onChangeEvtMenuRec(
                null,
                -1,
                'add',
                oState.state.papSize !== '89mm' ? 'heading58' : 'heading80'
              )
            }
          >
            <Icon
              type="plus"
              style={{
                fontSize: '16px',
                color: 'rgb(0, 204, 112)'
              }}
            />{' '}
            Add Heading Line
          </button>
          <div>
            <div style={{ display: 'block', height: '6px' }} />
            <Checkbox
              id="desc"
              onChange={oState.onChange}
              checked={oState.state.desc}
              className={oState.props.classes.checkboxtop}
              style={{ paddingLeft: '10px' }}
            >
              Show Description on Receipt
            </Checkbox>
            <div style={{ display: 'block', height: '20px' }} />
            <Checkbox
              id="sku"
              onChange={oState.onChange}
              checked={oState.state.sku}
              className={oState.props.classes.checkboxtop}
              style={{ paddingLeft: '10px' }}
            >
              Show Item SKU on Receipt
            </Checkbox>
            <div style={{ display: 'block', height: '75px' }} />
            <Checkbox
              id="vat"
              onChange={oState.onChange}
              checked={oState.state.vat}
              className={oState.props.classes.checkboxtop}
              style={{ paddingLeft: '10px' }}
            >
              Show Vat on Receipt
            </Checkbox>
            <div style={{ display: 'block', height: '55px' }} />
            <Checkbox
              id="subs"
              onChange={oState.onChange}
              checked={oState.state.subs}
              className={oState.props.classes.checkboxtop}
              style={{ paddingLeft: '10px' }}
            >
              Show Gratuity and Total due on Receipt
            </Checkbox>
            <div style={{ display: 'block', height: '33px' }} />
            {oState.state.papSize !== '89mm'
              ? oState.state.footer58 &&
                oState.state.footer58.map((line, key) => (
                  <div key={'footer58map' + key}>
                    <div
                      style={{
                        display: 'block',
                        height: '25px',
                        display: 'inline-flex'
                      }}
                    >
                      <div>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'left', 'footer58')
                          }
                        >
                          <Icon type="align-left" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'center', 'footer58')
                          }
                        >
                          <Icon type="align-center" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'right', 'footer58')
                          }
                        >
                          <Icon type="align-right" />
                        </button>
                      </div>
                      <div style={{ paddingLeft: '5px' }}>
                        <button
                          style={
                            line.bold
                              ? {
                                  borderStyle: 'inset',
                                  background: 'white',
                                  border: '1px solid black',
                                  color: 'black'
                                }
                              : {
                                  background: 'white',
                                  border: '1px solid black',
                                  color: 'black'
                                }
                          }
                          onClick={e =>
                            onChangeEvtMenuRec(
                              !line.bold,
                              key,
                              'bold',
                              'footer58'
                            )
                          }
                        >
                          <Icon type="bold" />
                        </button>
                        <button
                          style={
                            line.size
                              ? {
                                  borderStyle: 'inset',
                                  background: 'white',
                                  border: '1px solid black',
                                  color: 'black'
                                }
                              : {
                                  background: 'white',
                                  border: '1px solid black',
                                  color: 'black'
                                }
                          }
                          onClick={e =>
                            onChangeEvtMenuRec(
                              !line.size,
                              key,
                              'size',
                              'footer58'
                            )
                          }
                        >
                          <Icon type="font-size" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'line-', 'footer58')
                          }
                        >
                          <Icon type="minus" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'line=', 'footer58')
                          }
                        >
                          {'='}
                        </button>
                      </div>
                      <div style={{ paddingLeft: '5px' }}>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          disabled={key != 0 ? false : true}
                          onClick={e =>
                            onChangeEvtMenuRec(false, key, 'up', 'footer58')
                          }
                        >
                          <Icon type="caret-up" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          disabled={
                            key != oState.state.heading.length - 1
                              ? false
                              : true
                          }
                          onClick={e =>
                            onChangeEvtMenuRec(false, key, 'down', 'footer58')
                          }
                        >
                          <Icon type="caret-down" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'remove', 'footer58')
                          }
                        >
                          <Icon type="delete" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              : oState.state.footer80 &&
                oState.state.footer80.map((line, key) => (
                  <div key={'footer80map' + key}>
                    <div
                      style={{
                        display: 'block',
                        height: '25px',
                        display: 'inline-flex'
                      }}
                    >
                      <div>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'left', 'footer80')
                          }
                        >
                          <Icon type="align-left" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'center', 'footer80')
                          }
                        >
                          <Icon type="align-center" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'right', 'footer80')
                          }
                        >
                          <Icon type="align-right" />
                        </button>
                      </div>
                      <div style={{ paddingLeft: '5px' }}>
                        <button
                          style={
                            line.bold
                              ? {
                                  borderStyle: 'inset',
                                  background: 'white',
                                  border: '0px solid black',
                                  color: 'black'
                                }
                              : {
                                  background: 'white',
                                  border: '1px solid black',
                                  color: 'black'
                                }
                          }
                          onClick={e =>
                            onChangeEvtMenuRec(
                              !line.bold,
                              key,
                              'bold',
                              'footer80'
                            )
                          }
                        >
                          <Icon type="bold" />
                        </button>
                        <button
                          style={
                            line.size
                              ? {
                                  borderStyle: 'inset',
                                  background: 'white',
                                  border: '1px solid black',
                                  color: 'black'
                                }
                              : {
                                  background: 'white',
                                  border: '1px solid black',
                                  color: 'black'
                                }
                          }
                          onClick={e =>
                            onChangeEvtMenuRec(
                              !line.size,
                              key,
                              'size',
                              'footer80'
                            )
                          }
                        >
                          <Icon type="font-size" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'line-', 'footer80')
                          }
                        >
                          <Icon type="minus" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'line=', 'footer80')
                          }
                        >
                          {'='}
                        </button>
                      </div>
                      <div style={{ paddingLeft: '5px' }}>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          disabled={key != 0 ? false : true}
                          onClick={e =>
                            onChangeEvtMenuRec(false, key, 'up', 'footer80')
                          }
                        >
                          <Icon type="caret-up" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          disabled={
                            key != oState.state.heading.length - 1
                              ? false
                              : true
                          }
                          onClick={e =>
                            onChangeEvtMenuRec(false, key, 'down', 'footer80')
                          }
                        >
                          <Icon type="caret-down" />
                        </button>
                        <button
                          style={{
                            background: 'white',
                            border: '1px solid black',
                            color: 'black'
                          }}
                          onClick={e =>
                            onChangeEvtMenuRec(null, key, 'remove', 'footer80')
                          }
                        >
                          <Icon type="delete" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </GridItem>
  );
};

export default ReceiptCreator;
