import React from "react";
import {
  Spin,
  Table,
  Input,
  Icon,
  Row,
  Col,
  Select,
  DatePicker,
  List,
  Skeleton,
  Badge,
  Button,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  getOpenBillReport,
  getBillItemsCashup,
} from "src/redux/actions/backend";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "src/components/Grid/GridContainer.jsx";
import GridItem from "src/components/Grid/GridItem.jsx";
import CustomInput from "src/components/CustomInput/CustomInput.jsx";
import Card from "src/components/Card/Card.jsx";
import CardHeader from "src/components/Card/CardHeader.jsx";
import CardBody from "src/components/Card/CardBody.jsx";
import AlertDialog from "src/components/Dialogs/Alert.jsx";
import Snackbar from "src/components/Snackbar/Snackbar.jsx";
import { openSnackbar, closeSnackbar } from "src/redux/actions/snackbar";
import Highlighter from "react-highlight-words";
import StyledTable from "src/components/Table/StyledTable";
import { setHelpMarker } from "src/redux/actions/help";
import IconButton from "@material-ui/core/IconButton";
import { MdKeyboardBackspace } from "react-icons/md";
import {
  primaryColor,
  grayColor,
  defaultFont,
} from "src/assets/jss/material-dashboard-react.jsx";
const { Column } = Table;
const dateFormat = "YYYY/MM/DD";
const dateFormatSearch = "YYYYMMDD";
const { RangePicker } = DatePicker;

const { Option } = Select;

const styles = (theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardExpand: {
    marginTop: "10px",
    marginBottom: "5px",
  },
  cardExpandBody: {
    margin: "0px",
  },
  cardExpandTop: {
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    height: "4.5em",
    marginTop: "0%",
    marginBottom: "0px",
  },
  cardEmployeeWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    fontSize: "25px",

    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTileButton: {
    display: "grid",
  },
  cardTileIcon: {
    color: "white",
    padding: 0,
  },
  show: {
    display: "inline",
  },
  hide: {
    display: "none",
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
  },
  cardBody: {
    "-webkit-transition": "1s",
    "-moz-transition": "1s",
    "-o-transition": "1s",
    transition: "1s",
    opacity: 1,
    height: "auto",
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    height: "150px",
    overflow: "auto",
  },
  tableScroll: {
    width: "100%",
    height: "45vh",
    overflow: "auto",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em",
    "& th": {
      "text-align": "center",
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    verticalAlign: "middle",
    "& td": {
      "word-break": "break-all",
      "text-align": "center",
    },
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.data * 3,
    overflowX: "auto",
  },
  snackbar: {
    color: "#fff",
    background: "#1ea1a9",
  },
  headingText: {
    fontSize: "20px",
    paddingTop: "10px",
  },
  buttonAdd: {
    height: "34px",
    marginTop: "43px",
    marginLeft: "-90%",
    width: "80%",
  },
  datePicker: {
    marginRight: "10px",
    "& span": {
      height: "45px",
    },
  },
});

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      employee: {},
      items: [],
      datePicker: false,
      startDate: moment().startOf("month").format(dateFormatSearch),
      endDate: moment().format(dateFormatSearch),
      pickerStart: moment().startOf("month"),
      pickerEnd: moment(),
      alert: false,
      msg: [],
      head: "",
    };
  }

  componentDidMount() {
    this.getOpenBillReport();
  }
  back = () => {
    this.setState({ showBill: false });
  };
  getOpenBillReport = (msg) => {
    this.setState({
      loading: true,
    });
    const token = this.props.local.user.token;
    this.props
      .getOpenBillReport(token, this.state.startDate, this.state.endDate)
      .then((result) => {
        if (result.data.results === "NoBills") {
          this.setState({
            items: [],
            loading: false,
          });
        } else {
          this.setState({
            items: result.data.results,
            loading: false,
          });
        }
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch((err) => {
        this.onError(err);
      });
  };

  handleChangeDate = (value) => {
    if (value) {
      this.setState({
        startDate: value[0].format(dateFormatSearch),
        endDate: value[1].format(dateFormatSearch),
        pickerStart: value[0],
        pickerEnd: value[1],
      });
    }
  };

  handleChangeSelect = (value) => {
    if (value) {
      if (value === "today") {
        this.setState({
          startDate: moment().format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false,
        });
      } else if (value === "last7") {
        this.setState({
          startDate: moment().subtract(7, "d").format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false,
        });
      } else if (value === "last30") {
        this.setState({
          startDate: moment().subtract(30, "d").format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false,
        });
      } else if (value === "thism") {
        this.setState({
          startDate: moment().startOf("month").format(dateFormatSearch),
          endDate: moment().format(dateFormatSearch),
          datePicker: false,
        });
      } else if (value === "lastm") {
        this.setState({
          startDate: moment()
            .subtract(1, "months")
            .startOf("month")
            .format(dateFormatSearch),
          endDate: moment()
            .subtract(1, "months")
            .endOf("month")
            .format(dateFormatSearch),
          datePicker: false,
        });
      } else if (value === "custom") {
        this.setState({
          datePicker: true,
        });
      }
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false,
    });
  };

  onError = (err) => {
    this.setState({
      head: "Menu Items Error",
      alert: true,
      msg: [err],
      loading: false,
    });
  };

  onHelpMarker = (marker) => {
    this.props.setHelpMarker(marker);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  getBillItems = (bill) => {
    const token = this.props.local.user.token;
    this.props
      .getBillItemsCashup(token, bill._id)
      .then((result) => {
        this.setState({
          bill: result.data.results.bill,
          main: false,
          showBill: true,
          loading: false,
        });
      })
      .catch((err) => {
        this.onError(err);
      });
  };
  showCooking = (selectedItem, key) => {
    var item = JSON.parse(JSON.stringify(selectedItem));
    let bill = JSON.parse(JSON.stringify(this.state.bill));
    if (item.show) {
      delete item.show;
    } else {
      item.show = true;
    }
    bill.items[key] = item;
    this.setState({
      bill: bill,
    });
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: current,
      pageSize: pageSize,
    }));
  };
  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker,
    };

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        {this.state.showBill ? (
          <div>
            <Card style={{ marginTop: 0 }}>
              {/*<CardHeader
                style={{
                  borderRadius: '0.5em',
                  background:
                    'rgb(19, 36, 64)'
                }}
              >
                {' '}
                <h4
                  style={{ color: 'white' }}
                  className={this.props.classes.cardTitleWhite}
                >
                  Bill #{this.state.bill.orderno}
                </h4>
                <p
                  style={{ color: 'rgba(255, 255, 255, 0.63)' }}
                  className={this.props.classes.cardCategoryWhite}
                >
                  Bill details
                </p>
              </CardHeader> */}
              <CardBody style={{ padding: "0px", paddingTop: "1em" }}>
                <GridContainer>
                  <MdKeyboardBackspace
                    style={{
                      color: "#132440",
                      fontSize: "25px",
                      //marginBottom: "-0.5em",
                      marginLeft: "1em",
                    }}
                    onClick={() => this.back()}
                    color="primary"
                  />
                  {/*<Button
                    style={{
                      left: '10px',
                      width: "6.3em",
                      height: "3em",
                      fontSize: "13px",
                      float: "right",
                      borderRadius: "3.5em",
                      backgroundColor: "rgba(229, 233, 242, 0.5)",
                      border: "1px solid rgba(229, 233, 242, 0.5)",
                      color: "black",
                      boxShadow: "none",
                      paddingBottom: "0.2em",
                      position: "absolute"
                    }}
                    onClick={() => this.back()}
                    color="primary"
                  >
                    <Icon type="arrow-left" />Back
                  </Button>*/}
                </GridContainer>

                <Card
                  style={{
                    boxShadow: "none",
                    borderRadius: "6px",
                    padding: "7px",
                    margin: "auto",
                    width: window.isPhone ? "100%" : "50%",
                    minWidth: "24em",
                  }}
                >
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{
                      textAlign: window.isPhone ? "center" : "left",
                      height: window.isPhone ? "none" : 60,
                      fontWeight: 600,
                      background: "rgb(39, 41, 48)",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      paddingBottom: window.isPhone ? 10 : 0,
                      border: "solid 1px lightgray",
                      borderRadius: "6px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                  >
                    <Col
                      span={window.isPhone ? 12 : 6}
                      style={{
                        fontSize: "14px",
                        marginTop: window.isPhone ? 10 : 19,
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      Inv #{this.state.bill.orderno}
                    </Col>
                    <Col
                      span={window.isPhone ? 12 : 2}
                      style={{
                        fontSize: "14px",
                        marginTop: window.isPhone ? 10 : 19,
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      {this.state.bill.table
                        ? "Table #" + this.state.bill.table
                        : this.state.bill.type}
                    </Col>
                    <Col
                      span={window.isPhone ? 12 : 6}
                      style={{
                        fontSize: "14px",
                        marginTop: window.isPhone ? 5 : 19,
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      {this.state.bill.desc}
                    </Col>
                    <Col
                      span={window.isPhone ? 12 : 4}
                      style={{
                        fontSize: "14px",
                        marginRight: window.isPhone ? "0em" : "2em",
                        marginTop: window.isPhone ? 5 : 19,
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      {" "}
                      {this.state.bill.date
                        ? this.state.bill.date.slice(0, 4) +
                          "-" +
                          this.state.bill.date.slice(4, 6) +
                          "-" +
                          this.state.bill.date.slice(6, 8) +
                          " " +
                          this.state.bill.date.slice(8, 10) +
                          ":" +
                          this.state.bill.date.slice(10, 12)
                        : "N/A"}
                    </Col>
                  </Row>
                  <List
                    style={{
                      background: "rgba(229, 233, 242, 0.5)",
                      paddingLeft: "1em",
                      paddingRight: "1em",
                      border: "solid 0px lightgray",
                      borderRadius: "6px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                      marginTop: "0.4em",
                    }}
                    className="bill-item-list"
                    itemLayout="horizontal"
                    dataSource={this.state.bill.items}
                    renderItem={(item, iKey) => (
                      <List.Item
                        style={{
                          paddingTop: "0.3em",
                          paddingBottom: "0.3em",
                        }}
                      >
                        <Skeleton title={"Items"} loading={false} active>
                          <List.Item.Meta
                            // onClick={e => oState.handleSelectItem(item, iKey)}
                            title={<p>{item.name}</p>}
                            description={
                              item.price ? (
                                item.desc ? (
                                  <div
                                    onClick={() => this.showCooking(item, iKey)}
                                  >
                                    R{" "}
                                    {(item.price * item.qty).toFixed(2) + "  "}
                                    <div
                                      style={{
                                        color: "lightBlue",
                                      }}
                                    >
                                      {item.desc}
                                    </div>
                                    <List
                                      size="small"
                                      dataSource={item.cookIns}
                                      renderItem={(item) => (
                                        <List.Item
                                          style={{ padding: 0, border: 0 }}
                                        >
                                          {item.name} @ R
                                          {item.price
                                            ? item.price.toFixed(2)
                                            : "0.00"}
                                        </List.Item>
                                      )}
                                      style={
                                        item.show
                                          ? {
                                              display: "block",
                                              fontSize: "11px",
                                            }
                                          : { display: "none" }
                                      }
                                    />
                                  </div>
                                ) : (
                                  "R " + (item.price * item.qty).toFixed(2)
                                )
                              ) : (
                                ""
                              )
                            }
                          />
                          {item.qty !== 0 ? (
                            <div>
                              <Badge
                                count={item.qty}
                                style={{
                                  backgroundColor: "transparent",
                                  color: "black",
                                  borderRadius: "0px",
                                  boxShadow: "none",
                                  top: "-4px",
                                  width: "28px",
                                  fontSize: "1.1em",
                                  margin: "10px",
                                  fontWeight: "600",
                                }}
                              />
                            </div>
                          ) : (
                            "Void"
                          )}
                        </Skeleton>
                      </List.Item>
                    )}
                  />

                  <div
                    style={{
                      textAlign: "right",
                      paddingTop: "0%",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      fontWeight: 600,
                    }}
                  >
                    <p
                      className="bill-sub-total"
                      style={{
                        marginLeft: "0.5em",
                        fontSize: "24px",
                      }}
                    >
                      R {this.state.bill.total}
                    </p>
                    <p
                      className="bill-sub-label"
                      style={{
                        fontSize: "24px",
                      }}
                    >
                      Total :
                    </p>
                  </div>
                </Card>
              </CardBody>
            </Card>
          </div>
        ) : (
          <div>
            <GridContainer>
              <Row style={{ width: "100%" }}>
                <StyledTable
                  style={{
                    background: "white",
                    whiteSpace: window.isPhone ? "nowrap" : "normal",
                    marginLeft: window.isPhone ? "1em" : "",
                    marginRight: window.isPhone ? "1em" : "",
                  }}
                  rowKey="_id"
                  size="middle"
                  dataSource={this.state.items}
                  pagination={{
                    locale: { items_per_page: " Items" },
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    pageSizeOptions: ["10", "25", "50", "100"],
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: (current, type, originalElement) => {
                      if (type === "prev") {
                        return <a>Previous</a>;
                      }
                      if (type === "next") {
                        return <a>Next</a>;
                      }
                      return originalElement;
                    },
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                    defaultCurrent: 1,
                  }}
                  onChange={this.handleSort}
                  onRow={(record, rowIndex) => {
                    if (record.qty <= record.lowqty) {
                      return {
                        onClick: (e) => {
                          e.stopPropagation();
                          this.getBillItems(record);
                        },
                        style: { backgroundColor: "lightyellow" },
                      };
                    } else if (rowIndex % 2 == 0) {
                      return {
                        onClick: (e) => {
                          e.stopPropagation();
                          this.getBillItems(record);
                        },
                        style: { background: "rgb(247, 247, 247)" },
                      };
                    } else {
                      return {
                        onClick: (e) => {
                          e.stopPropagation();
                          this.getBillItems(record);
                        },
                      };
                    }
                  }}
                >
                  {window.isPhone ? null : (
                    <Column
                      {...this.getColumnSearchProps("orderno")}
                      title="Invoice #"
                      dataIndex="orderno"
                      key="orderno"
                    />
                  )}
                  {window.isPhone ? null : (
                    <Column
                      {...this.getColumnSearchProps("waiterName")}
                      title="Sales Person"
                      dataIndex="waiterName"
                      key="waiterName"
                    />
                  )}
                  {window.isPhone ? null : (
                    <Column
                      {...this.getColumnSearchProps("type")}
                      title="Type"
                      render={(text, record) => (
                        <span>{record.type ? record.type : "Quick Sale"}</span>
                      )}
                      dataIndex="type"
                      key="type"
                    />
                  )}

                  <Column
                    {...this.getColumnSearchProps("desc")}
                    title="Description"
                    dataIndex="desc"
                    key="desc"
                  />
                  {window.isPhone ? null : (
                    <Column
                      title="Date Created"
                      render={(text, record) => (
                        <span>
                          {record.date.slice(0, 4) +
                            "-" +
                            record.date.slice(4, 6) +
                            "-" +
                            record.date.slice(6, 8) +
                            " " +
                            record.date.slice(8, 10) +
                            ":" +
                            record.date.slice(10, 12)}
                        </span>
                      )}
                    />
                  )}
                  <Column
                    title="Amount"
                    dataIndex="total"
                    key="total"
                    render={(text, record) => (
                      <span>{"R " + record.total.toFixed("2")}</span>
                    )}
                  />
                  <Column
                    title="Time Open"
                    render={(text, record) => {
                      let createDate = moment(record.date, "YYYYMMDDHHmm");
                      let currentDate = moment();
                      let difference = currentDate.diff(createDate, "m");
                      return (
                        <span>{(difference / 60).toFixed(0) + " Hours"}</span>
                      );
                    }}
                  />
                  {window.isPhone ? null : (
                    <Column
                      title="Actions"
                      render={(text, record) => (
                        <div>
                          <IconButton
                            style={{
                              marginRight: "0em",
                              marginLeft: "0.4em",
                              borderRadius: "4em",
                              backgroundColor: "rgba(229, 233, 242, 0.9)",
                              border: "1px solid rgba(229, 233, 242, 1)",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.getBillItems(record);
                            }}
                          >
                            {
                              <Icon
                                type="eye"
                                style={{
                                  fontSize: 13,
                                }}
                              />
                            }
                          </IconButton>
                        </div>
                      )}
                    />
                  )}
                </StyledTable>
              </Row>
            </GridContainer>
          </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  local: state.backend,
  snackbar: state.snackbar,
});

const mapDispatchToProps = {
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  getOpenBillReport,
  getBillItemsCashup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Reports));
