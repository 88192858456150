import React, { memo, useEffect, useState } from 'react';
import { Collapse, Icon, Switch, Table, AutoComplete, Select } from 'antd';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import GridItem from 'src/components/Grid/GridItem.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import StyledTableNoPagi from 'src/components/Table/StyledTableNoPagi';
import CustomAddSelect from 'src/components/CustomAddSelect/CustomAddSelect.jsx';
import { formaterCheck } from 'src/utils/formater.js';
import Button from 'src/components/CustomButtons/Button.jsx';
import MiniStockForm from 'src/views/Admin/Menuitems/MiniStockForm';
import convert from 'convert-units';
import ObjectID from 'bson-objectid';
import { FiEdit } from 'react-icons/fi';

const { Panel } = Collapse;
const { Option } = Select;
const { Column } = Table;

var dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: [
    {
      name: 'Unit',
      value: 'unit'
    }
  ]
};

let oCookingRef = {};
const CreateInputs = allprops => {
  let {
    cookInsKey,
    modifiersKey,
    modifiers,
    setCookingName,
    cookState,
    stockVariant,
    selectitemsFilter,
    onError,
    types,
    props
  } = allprops;

  const onChangeEvtPriceCooking = (eN, iN, modKeyN) => {
    let e = Object.assign({}, eN);
    let i = iN;
    let key = modKeyN;
    var value = formaterCheck(e.currentTarget.value || '0');
    if (value >= 0) {
      oCookingRef['p' + key + 'p' + i].value = value;
      oState.modstate.modifiers[key].cookIns[i].price = parseFloat(value);
    }
  };

  const onChangeEvtCostCooking = (eN, iN, modKeyN) => {
    let e = Object.assign({}, eN);
    var i = iN;
    let key = modKeyN;
    var value = formaterCheck(e.currentTarget.value || '0');
    if (value >= 0) {
      oCookingRef['c' + key + 'c' + i].value = value;
      oState.modstate.modifiers[key].cookIns[i].cost = value;
    }
  };

  const onChangeEvtQTYCooking = (eN, iN, keyN, modKeyN, cookStateN) => {
    let e = Object.assign({}, eN);
    var i = iN;
    var key = keyN;
    let modKey = modKeyN;
    let cookState = cookStateN;
    let modifiers = oState.modstate.modifiers;
    const newIds = modifiers[modKey].cookIns.slice();
    const stockitems = cookState.stockitems;
    if (e.currentTarget.value) {
      var value = formaterCheck(e.currentTarget.value || '0');
      if (value >= 0) {
        newIds[i].stockList[key].qty = parseFloat(value);
        oCookingRef['q' + modKey + 'q' + key + 'q' + i].value = value;
        var metricAmount = newIds[i].stockList[key].qty;
        if (newIds[i].stockList[key].dimension !== 'unit') {
          metricAmount = convert(newIds[i].stockList[key].qty)
            .from(newIds[i].stockList[key].dimType)
            .to(stockitems[newIds[i].stockList[key].stockId].dimType);
        }
        var costPrice = newIds[i].stockList[key].stockId
          ? stockitems[newIds[i].stockList[key].stockId].buyPrice
          : '0';
        var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
        newIds[i].stockList[key].cost = totalCost.toFixed('2');
        oCookingRef[
          'cost' + modKey + 'cost' + key + 'cost' + i
        ].value = totalCost.toFixed('2');
      }
    } else {
      newIds[i].stockList[key].cost = '';
      newIds[i].stockList[key].qty = '';
      oCookingRef['cost' + modKey + 'cost' + key + 'cost' + i].value = '';
      oCookingRef['q' + modKey + 'q' + key + 'q' + i].value = '';
    }
    var costTotal = 0;
    if (newIds[i].stockList.length > 0) {
      for (var j = 0; j < newIds[i].stockList.length; j++) {
        if (newIds[i].stockList[j].cost) {
          costTotal += parseFloat(newIds[i].stockList[j].cost || '0');
        }
      }
      newIds[i].cost = costTotal.toFixed('2');
      oCookingRef['c' + modKey + 'c' + i].value = newIds[i].cost;
    }
    modifiers[modKey].cookIns = newIds;
    // let newCook = newIds[i];
    // newCook.setModState(prevState => ({
    //   ...prevState,
    //   ...newCook
    // }));
  };

  const handleSelectStockDimCooking = (eN, iN, keyN, modKeyN, cookStateN) => {
    let e = Object.assign({}, eN);
    var i = iN;
    var key = keyN;
    let modKey = modKeyN;
    let cookState = cookStateN;
    let modifiers = oState.modstate.modifiers;
    const newIds = modifiers[modKey].cookIns.slice();
    const stockitems = cookState.stockitems;
    let { value, name } = e.target;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if (name === 'dimentiontype') {
      newIds[i].stockList[key].dimType = value;
      newIds[i].stockList[key].qty = 1;
      var metricAmount = newIds[i].stockList[key].qty;
      if (newIds[i].stockList[key].dimension !== 'unit') {
        metricAmount = convert(newIds[i].stockList[key].qty)
          .from(newIds[i].stockList[key].dimType)
          .to(stockitems[newIds[i].stockList[key].stockId].dimType);
      }
      var costPrice = newIds[i].stockList[key].stockId
        ? stockitems[newIds[i].stockList[key].stockId].buyPrice
        : '0';
      var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
      newIds[i].stockList[key].cost = totalCost.toFixed('2');
      oCookingRef[
        'cost' + modKey + 'cost' + key + 'cost' + i
      ].value = totalCost.toFixed('2');
      var costTotal = 0;
      if (newIds[i].stockList.length > 0) {
        for (var j = 0; j < newIds[i].stockList.length; j++) {
          if (newIds[i].stockList[j].cost) {
            costTotal += parseFloat(newIds[i].stockList[j].cost || '0');
          }
        }
        newIds[i].cost = costTotal.toFixed('2');
        oCookingRef['c' + modKey + 'c' + i].value = newIds[i].cost;
      }
      modifiers[modKey].cookIns = newIds;
      let newCook = newIds[i];
      newCook.setModState(prevState => ({
        ...prevState,
        ...newCook
      }));
    } else if (value && name !== 'dimentiontype') {
      var newvalue = formaterCheck(value);
      if (newvalue >= 0) {
        if (name === 'cost') {
          newIds[i].stockList[key].cost = value;
          oCookingRef[
            'cost' + modKey + 'cost' + key + 'cost' + i
          ].value = value;
        }
      }
    } else {
      newIds[i].stockList[key].cost = '';
      oCookingRef['cost' + modKey + 'cost' + key + 'cost' + i].value = '';
    }
    var costTotal = 0;
    if (newIds[i].stockList.length > 0) {
      for (var j = 0; j < newIds[i].stockList.length; j++) {
        if (newIds[i].stockList[j].cost) {
          costTotal += parseFloat(newIds[i].stockList[j].cost || '0');
        }
      }
      newIds[i].cost = costTotal.toFixed('2');
      oCookingRef['c' + modKey + 'c' + i].value = newIds[i].cost;
    }
    modifiers[modKey].cookIns = newIds;
  };

  const addInputCooking = modKeyN => {
    let key = modKeyN;
    let modifiers = oState.modstate.modifiers;
    var cooking = modifiers[key].cookIns;
    cooking.push({
      name: '',
      price: 0,
      cost: '0',
      stockList: []
    });
    modifiers[key].cookIns = cooking;
    setModState(prevState => ({
      ...prevState,
      modifiers: modifiers
    }));
  };

  const removeInputCooking = (iN, modKeyN) => {
    let i = iN;
    let key = modKeyN;
    let modifiers = oState.modstate.modifiers;
    var cooking = modifiers[key].cookIns;
    if (cooking.length > 0) {
      cooking.splice(i, 1);
      modifiers[key].cookIns = cooking;
    }
    if (cooking.length === 0) {
      modifiers[key].cookIns.push({
        name: '',
        price: 0,
        cost: '0',
        stockList: []
      });
    }
    setModState(prevState => ({
      ...prevState,
      modifiers: modifiers
    }));
  };

  const addInputStockCooking = (iN, modKeyN) => {
    var i = iN;
    let key = modKeyN;
    let modifiers = Object.assign([], oState.modstate.modifiers);
    var cooking = modifiers[key].cookIns;
    cooking[i].stockList.push({
      stock: '',
      qty: 0,
      cost: '0'
    });
    modifiers[key].cookIns = cooking;
    let newCook = modifiers[key].cookIns[i];
    modifiers[key].cookIns[i].setModState(prevState => ({
      ...prevState,
      ...newCook
    }));
  };

  const removeInputStockCooking = (iN, keyN, modKeyN, allN) => {
    let i = iN;
    let key = keyN;
    let modKey = modKeyN;
    let all = allN;
    let modifiers = Object.assign([], oState.modstate.modifiers);
    var cooking = modifiers[modKey].cookIns;
    if (cooking[i].stockList.length > 1 && !all) {
      cooking[i].stockList.splice(key, 1);
    } else {
      cooking[i].stockList = [];
    }
    var costTotal = 0;
    if (cooking[i].stockList.length > 0) {
      for (var j = 0; j < cooking[i].stockList.length; j++) {
        if (cooking[i].stockList[j].cost) {
          costTotal += parseFloat(cooking[i].stockList[j].cost || '0');
        }
      }
      cooking[i].cost = costTotal.toFixed('2');
    } else {
      cooking[i].cost = '0';
    }
    modifiers[modKey].cookIns = cooking;
    let newCook = modifiers[modKey].cookIns[i];
    modifiers[modKey].cookIns[i].setModState(prevState => ({
      ...prevState,
      ...newCook
    }));
  };

  const onEditBtnDownCooking = (key, modKeyN, stockVariantN) => {
    let modKey = modKeyN;
    let modifiers = oState.modstate.modifiers;
    let stockVariants = stockVariantN;
    var cookIns = modifiers[modKey].cookIns;
    var oldCook = cookIns[key + 1];
    var newCook = cookIns[key];
    for (let i = 0; i < stockVariants.length; i++) {
      if (stockVariants[i].modKeyList) {
        for (let j = 0; j < stockVariants[i].modKeyList.length; j++) {
          if (stockVariants[i].modKeyList[j]) {
            if (oldCook.stockList) {
              for (let old = 0; old < oldCook.stockList.length; old++) {
                if (oldCook.stockList[old]) {
                  if (
                    stockVariants[i].modKeyList[j].varientid ===
                      oldCook.stockList[old].stockId &&
                    stockVariants[i].modKeyList[j].opsname === oldCook.name
                  ) {
                    stockVariants[i].modKeyList[j].ops = key;
                  }
                }
              }
            }
            if (newCook.stockList) {
              for (let nc = 0; nc < newCook.stockList.length; nc++) {
                if (newCook.stockList[nc]) {
                  if (
                    stockVariants[i].modKeyList[j].varientid ===
                      newCook.stockList[nc].stockId &&
                    stockVariants[i].modKeyList[j].opsname === newCook.name
                  ) {
                    stockVariants[i].modKeyList[j].ops = key + 1;
                  }
                }
              }
            }
          }
        }
      }
    }
    oldCook.setModState(prevState => ({
      ...prevState,
      ...newCook
    }));
    newCook.setModState(prevState => ({
      ...prevState,
      ...oldCook
    }));
  };

  const onEditBtnUpCooking = (key, modKeyN, stockVariantN) => {
    let modKey = modKeyN;
    let modifiers = oState.modstate.modifiers;
    let stockVariants = stockVariantN;
    var cookIns = modifiers[modKey].cookIns;
    var oldCook = cookIns[key - 1];
    var newCook = cookIns[key];
    for (let i = 0; i < stockVariants.length; i++) {
      if (stockVariants[i].modKeyList) {
        for (let j = 0; j < stockVariants[i].modKeyList.length; j++) {
          if (stockVariants[i].modKeyList[j]) {
            if (oldCook.stockList) {
              for (let old = 0; old < oldCook.stockList.length; old++) {
                if (oldCook.stockList[old]) {
                  if (
                    stockVariants[i].modKeyList[j].varientid ===
                      oldCook.stockList[old].stockId &&
                    stockVariants[i].modKeyList[j].opsname === oldCook.name
                  ) {
                    stockVariants[i].modKeyList[j].ops = key;
                  }
                }
              }
            }
            if (newCook.stockList) {
              for (let nc = 0; nc < newCook.stockList.length; nc++) {
                if (newCook.stockList[nc]) {
                  if (
                    stockVariants[i].modKeyList[j].varientid ===
                      newCook.stockList[nc].stockId &&
                    stockVariants[i].modKeyList[j].opsname === newCook.name
                  ) {
                    stockVariants[i].modKeyList[j].ops = key - 1;
                  }
                }
              }
            }
          }
        }
      }
    }
    oldCook.setModState(prevState => ({
      ...prevState,
      ...newCook
    }));
    newCook.setModState(prevState => ({
      ...prevState,
      ...oldCook
    }));
  };

  const handleChangeSelectCooking = (
    valueN,
    eventN,
    modKeyN,
    cookInsKeyN,
    stockKeyN,
    setCookingName,
    cookStateN,
    stockVariantN
  ) => {
    let value = valueN;
    let modKey = modKeyN;
    let cookInsKey = cookInsKeyN;
    let stockKey = stockKeyN;
    let cookState = cookStateN;
    let event = Object.assign({}, eventN);
    let newModifiers = Object.assign([], oState.modstate.modifiers);
    if (value) {
      if (value !== 'Add ' + event.key + ' Stock Item') {
        const stockitems = cookState.stockitems;
        const newIds = newModifiers[modKey].cookIns.slice();
        var newvalue = event.key.split('|');
        newIds[cookInsKey].stockList[stockKey].stock = value;
        newIds[cookInsKey].stockList[stockKey].stockId = newvalue[0];
        newIds[cookInsKey].stockList[stockKey].dimension = newvalue[1];
        newIds[cookInsKey].stockList[stockKey].dimtypes =
          dimSelectTypes[newvalue[1]];
        if (stockitems[newvalue[0]] && stockitems[newvalue[0]].dimType) {
          newIds[cookInsKey].stockList[stockKey].dimType =
            stockitems[newvalue[0]].dimType;
        } else {
          newIds[cookInsKey].stockList[stockKey].dimType =
            dimSelectTypes[newvalue[1]][0].value;
        }
        newIds[cookInsKey].stockList[stockKey].qty = 1;
        var metricAmount = newIds[cookInsKey].stockList[stockKey].qty;
        if (newIds[cookInsKey].stockList[stockKey].dimension !== 'unit') {
          metricAmount = convert(newIds[cookInsKey].stockList[stockKey].qty)
            .from(newIds[cookInsKey].stockList[stockKey].dimType)
            .to(
              stockitems[newIds[cookInsKey].stockList[stockKey].stockId].dimType
            );
        }
        var costPrice = newIds[cookInsKey].stockList[stockKey].stockId
          ? stockitems[newIds[cookInsKey].stockList[stockKey].stockId].buyPrice
          : '0';
        var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
        newIds[cookInsKey].stockList[stockKey].cost = totalCost.toFixed('2');
        var costTotal = 0;
        if (newIds[cookInsKey].stockList.length > 0) {
          for (var j = 0; j < newIds[cookInsKey].stockList.length; j++) {
            if (newIds[cookInsKey].stockList[j].cost) {
              costTotal += parseFloat(
                newIds[cookInsKey].stockList[j].cost || '0'
              );
            }
          }
          newIds[cookInsKey].cost = costTotal.toFixed('2');
        }
        newModifiers[modKey].cookIns = newIds;
        let stockVariants = stockVariantN;
        if (stockVariants) {
          for (let i = 0; i < stockVariants.length; i++) {
            if (
              stockVariants[i]._id ===
              newIds[cookInsKey].stockList[stockKey].stockId
            ) {
              if (!stockVariants[i].modKeyList) {
                stockVariants[i].modKeyList = [];
              }
              var shotcut = {
                mod: modKey,
                ops: cookInsKey,
                opsname: newIds[cookInsKey].name,
                stock: stockKey,
                varientid: stockVariants[i]._id
              };
              stockVariants[i].modKeyList.push(shotcut);
              break;
            }
          }
        }
        let newCook = newModifiers[modKey].cookIns[cookInsKey];
        newModifiers[modKey].cookIns[cookInsKey].setModState(prevState => ({
          ...prevState,
          ...newCook
        }));
      } else {
        newModifiers[modKey].cookIns[cookInsKey].stockList[stockKey].stock =
          event.key;
        newModifiers[modKey].cookIns[cookInsKey].stockList[stockKey].stockId =
          'addnew';
        setCookingName(event.key);
        let newCook = newModifiers[modKey].cookIns[cookInsKey];
        newModifiers[modKey].cookIns[cookInsKey].setModState(prevState => ({
          ...prevState,
          ...newCook
        }));
      }
    }
  };

  const resetFilterOnCancel = (
    blank1,
    modKey,
    cookInsKey,
    stockKey,
    cookState
  ) => {
    let newModifiers = Object.assign([], oState.modstate.modifiers);
    let selectitems = Object.assign([], cookState.selectitems);
    setItemsFilter(selectitems);
    newModifiers[modKey].cookIns[cookInsKey].stockList[stockKey].stock = '';
    newModifiers[modKey].cookIns[cookInsKey].stockList[stockKey].stockId = '';
    let newCook = newModifiers[modKey].cookIns[cookInsKey];
    newModifiers[modKey].cookIns[cookInsKey].setModState(prevState => ({
      ...prevState,
      ...newCook
    }));
  };

  const handleCookingSelectFilter = (
    valueN,
    modKeyN,
    cookInsKeyN,
    stockKeyN,
    clearN,
    cookStateN
  ) => {
    let value = valueN;
    let modKey = modKeyN;
    let cookInsKey = cookInsKeyN;
    let stockKey = stockKeyN;
    let clear = clearN;
    let cookState = cookStateN;
    let selectitemsFilter = [];
    let selectitems = Object.assign([], cookState.selectitems);
    let newModifiers = Object.assign([], oState.modstate.modifiers);
    if (value) {
      newModifiers[modKey].cookIns[cookInsKey].stockList[stockKey].stock =
        value[0].toUpperCase() + value.slice(1);
      for (var i = 0; i < selectitems.length; i++) {
        if (selectitems[i].value.toUpperCase().includes(value.toUpperCase())) {
          selectitemsFilter.push(selectitems[i]);
        }
      }
      if (selectitemsFilter.length === 0) {
        selectitemsFilter.push({
          value:
            'Add ' + value[0].toUpperCase() + value.slice(1) + ' Stock Item',
          key: value[0].toUpperCase() + value.slice(1)
        });
      }
    } else {
      if (!clear) {
        newModifiers[modKey].cookIns[cookInsKey].stockList[stockKey] = {
          stock: '',
          qty: 0,
          cost: '0'
        };
      }
      selectitemsFilter = selectitems;
    }
    setItemsFilter(selectitemsFilter);
    let newCook = newModifiers[modKey].cookIns[cookInsKey];
    newModifiers[modKey].cookIns[cookInsKey].setModState(prevState => ({
      ...prevState,
      ...newCook
    }));
  };

  const onChangeIntCooking = (eN, iN, modKeyN) => {
    let e = Object.assign({}, eN);
    let i = iN;
    let key = modKeyN;
    //oCookingRef["cookname" + key + "cookname" + i].value = e.currentTarget.value;
    oState.modstate.modifiers[key].cookIns[i].name = e.currentTarget.value;
  };

  const onCompleteStockAdd = (
    StockItem,
    blank,
    modKey,
    cookInsKey,
    stockKey,
    cookState,
    stockVariants
  ) => {
    let NewStock = StockItem.newstockItem;
    let newModifiers = Object.assign([], oState.modstate.modifiers);
    const newIds = newModifiers[modKey].cookIns.slice();
    newIds[cookInsKey].stockList[stockKey].stock = NewStock.name;
    newIds[cookInsKey].stockList[stockKey].stockId = NewStock._id;
    newIds[cookInsKey].stockList[stockKey].dimension = NewStock.dimension;
    newIds[cookInsKey].stockList[stockKey].dimtypes =
      dimSelectTypes[NewStock.dimension];
    newIds[cookInsKey].stockList[stockKey].dimType =
      dimSelectTypes[NewStock.dimension][0].value;
    newIds[cookInsKey].stockList[stockKey].qty = 1;
    var metricAmount = newIds[cookInsKey].stockList[stockKey].qty;
    var costPrice = NewStock.buyPrice;
    var totalCost = parseFloat(costPrice) * parseFloat(metricAmount);
    newIds[cookInsKey].stockList[stockKey].cost = totalCost.toFixed('2');
    var costTotal = 0;
    if (newIds[cookInsKey].stockList.length > 0) {
      for (var j = 0; j < newIds[cookInsKey].stockList.length; j++) {
        if (newIds[cookInsKey].stockList[j].cost) {
          costTotal += parseFloat(newIds[cookInsKey].stockList[j].cost || '0');
        }
      }
      newIds[cookInsKey].cost = costTotal.toFixed('2');
    }
    newModifiers[modKey].cookIns = newIds;
    if (stockVariants) {
      for (let i = 0; i < stockVariants.length; i++) {
        if (
          stockVariants[i]._id ===
          newIds[cookInsKey].stockList[stockKey].stockId
        ) {
          if (!stockVariants[i].modKeyList) {
            stockVariants[i].modKeyList = [];
          }
          var shotcut = {
            mod: modKey,
            ops: cookInsKey,
            opsname: newIds[cookInsKey].name,
            stock: stockKey,
            varientid: stockVariants[i]._id
          };
          stockVariants[i].modKeyList.push(shotcut);
          break;
        }
      }
    }
    let newCook = newModifiers[modKey].cookIns[cookInsKey];
    newModifiers[modKey].cookIns[cookInsKey].setModState(prevState => ({
      ...prevState,
      ...newCook
    }));
  };

  let itemsFilter;
  let setItemsFilter;
  [itemsFilter, setItemsFilter] = useState(selectitemsFilter);
  [
    modifiers[modifiersKey].cookIns[cookInsKey],
    modifiers[modifiersKey].cookIns[cookInsKey].setModState
  ] = useState(modifiers[modifiersKey].cookIns[cookInsKey]);

  useEffect(() => {
    if (
      modifiers[modifiersKey].cookIns[cookInsKey].name &&
      oCookingRef[
        'cookname' + modifiers[modifiersKey].modKey + 'cookname' + cookInsKey
      ]
    ) {
      oCookingRef[
        'cookname' + modifiers[modifiersKey].modKey + 'cookname' + cookInsKey
      ].value = modifiers[modifiersKey].cookIns[cookInsKey].name;
    }
  }, [modifiers[modifiersKey].cookIns[cookInsKey].name]);

  useEffect(() => {
    if (
      modifiers[modifiersKey].cookIns[cookInsKey].price &&
      oCookingRef['p' + modifiers[modifiersKey].modKey + 'p' + cookInsKey]
    ) {
      if (modifiers[modifiersKey].cookIns[cookInsKey].price) {
        oCookingRef[
          'p' + modifiers[modifiersKey].modKey + 'p' + cookInsKey
        ].value = modifiers[modifiersKey].cookIns[cookInsKey].price;
      } else {
        oCookingRef[
          'p' + modifiers[modifiersKey].modKey + 'p' + cookInsKey
        ].value = 0;
      }
    }
  }, [modifiers[modifiersKey].cookIns[cookInsKey].price]);

  useEffect(() => {
    if (
      modifiers[modifiersKey].cookIns[cookInsKey].cost &&
      oCookingRef['c' + modifiers[modifiersKey].modKey + 'c' + cookInsKey]
    ) {
      if (modifiers[modifiersKey].cookIns[cookInsKey].cost) {
        oCookingRef[
          'c' + modifiers[modifiersKey].modKey + 'c' + cookInsKey
        ].value = modifiers[modifiersKey].cookIns[cookInsKey].cost;
      } else {
        oCookingRef[
          'c' + modifiers[modifiersKey].modKey + 'c' + cookInsKey
        ].value = 0;
      }
    }
  }, [modifiers[modifiersKey].cookIns[cookInsKey].cost]);
  useEffect(() => {
    for (
      let sKey = 0;
      sKey < modifiers[modifiersKey].cookIns[cookInsKey].stockList.length;
      sKey++
    ) {
      if (modifiers[modifiersKey].cookIns[cookInsKey].stockList[sKey]) {
        if (
          oCookingRef[
            'q' + modifiers[modifiersKey].modKey + 'q' + sKey + 'q' + cookInsKey
          ]
        ) {
          if (modifiers[modifiersKey].cookIns[cookInsKey].stockList[sKey].qty) {
            oCookingRef[
              'q' +
                modifiers[modifiersKey].modKey +
                'q' +
                sKey +
                'q' +
                cookInsKey
            ].value =
              modifiers[modifiersKey].cookIns[cookInsKey].stockList[sKey].qty;
          } else {
            oCookingRef[
              'q' +
                modifiers[modifiersKey].modKey +
                'q' +
                sKey +
                'q' +
                cookInsKey
            ].value = 0;
          }
        }
        if (
          oCookingRef[
            'cost' +
              modifiers[modifiersKey].modKey +
              'cost' +
              sKey +
              'cost' +
              cookInsKey
          ]
        ) {
          if (
            modifiers[modifiersKey].cookIns[cookInsKey].stockList[sKey].cost
          ) {
            oCookingRef[
              'cost' +
                modifiers[modifiersKey].modKey +
                'cost' +
                sKey +
                'cost' +
                cookInsKey
            ].value =
              modifiers[modifiersKey].cookIns[cookInsKey].stockList[sKey].cost;
          } else {
            oCookingRef[
              'cost' +
                modifiers[modifiersKey].modKey +
                'cost' +
                sKey +
                'cost' +
                cookInsKey
            ].value = '';
          }
        }
      }
    }
  }, [JSON.stringify(modifiers[modifiersKey].cookIns[cookInsKey].stockList)]);
  let key = cookInsKey;
  var instruc = 'Option Name ';
  return (
    <div key={'divinputs' + key} style={{ marginTop: '-1em' }}>
      <GridContainer key={'gridinputs' + key}>
        <GridItem xs={3} sm={3} md={3}>
          <CustomInput
            labelText={instruc + (key + 1) + '*'}
            id={instruc + key}
            formControlProps={{
              fullWidth: true
            }}
            inputRef={el =>
              (oCookingRef[
                'cookname' + modifiers[modifiersKey].modKey + 'cookname' + key
              ] = el)
            }
            inputProps={{
              defaultValue:
                modifiers[modifiersKey].cookIns[cookInsKey].name || '',
              onChange: e =>
                onChangeIntCooking(e, key, modifiers[modifiersKey].modKey)
            }}
          />
        </GridItem>
        {oState.listing !== 'ecommerce' ? (
          <GridItem xs={2} sm={2} md={2}>
            <CustomInput
              labelText="Price"
              id={'p' + key}
              showPrefix={'R'}
              formControlProps={{
                fullWidth: true
              }}
              inputRef={el =>
                (oCookingRef[
                  'p' + modifiers[modifiersKey].modKey + 'p' + key
                ] = el)
              }
              inputProps={{
                defaultValue:
                  modifiers[modifiersKey].cookIns[cookInsKey].price || 0,
                onChange: e =>
                  onChangeEvtPriceCooking(
                    e,
                    key,
                    modifiers[modifiersKey].modKey
                  )
              }}
            />
          </GridItem>
        ) : null}
        <GridItem
          xs={2}
          sm={2}
          md={2}
          key={'C' + modifiers[modifiersKey].cookIns[cookInsKey].cost}
        >
          <CustomInput
            labelText="Cost"
            id={'C' + key}
            showPrefix={'R'}
            formControlProps={{
              fullWidth: true
            }}
            inputRef={el =>
              (oCookingRef[
                'c' + modifiers[modifiersKey].modKey + 'c' + key
              ] = el)
            }
            inputProps={{
              defaultValue:
                modifiers[modifiersKey].cookIns[cookInsKey].cost || '',
              onChange: e =>
                onChangeEvtCostCooking(e, key, modifiers[modifiersKey].modKey)
            }}
          />
        </GridItem>
        {oState.listing !== 'ecommerce' ? (
          <GridItem
            xs={2}
            sm={2}
            md={2}
            style={{ marginTop: '3.3em', width: '11em' }}
          >
            <FormControlLabel
              control={
                <Switch
                  style={{ marginRight: '0.5em' }}
                  checked={
                    modifiers[modifiersKey].cookIns[cookInsKey].stockList &&
                    modifiers[modifiersKey].cookIns[cookInsKey].stockList
                      .length > 0
                      ? true
                      : false
                  }
                  onChange={e => {
                    if (e) {
                      addInputStockCooking(key, modifiers[modifiersKey].modKey);
                    } else {
                      removeInputStockCooking(
                        key,
                        false,
                        modifiers[modifiersKey].modKey,
                        true
                      );
                    }
                  }}
                />
              }
              label="Link Stock"
            />
          </GridItem>
        ) : null}
        <GridItem xs={3} sm={3} md={3}>
          <GridContainer>
            {key + 1 === modifiers[modifiersKey].cookIns.length ? (
              <GridItem xs={3} sm={3} md={3} style={{ marginTop: '3.1em' }}>
                <Icon
                  type="plus"
                  style={{
                    padding: '0.7em',
                    marginTop: '-0.45em',
                    borderRadius: '4em',
                    backgroundColor: 'rgb(0, 204, 112)',
                    color: 'white',
                    border: '1px solid rgb(0, 204, 112)'
                  }}
                  onClick={() =>
                    addInputCooking(modifiers[modifiersKey].modKey)
                  }
                  //className={props.classes.buttonAddInputCooking}
                />
              </GridItem>
            ) : null}
            {key + 1 === modifiers[modifiersKey].cookIns.length ? (
              <GridItem xs={3} sm={3} md={3} style={{ marginTop: '3.1em' }}>
                <Icon
                  type="delete"
                  style={{
                    padding: '0.7em',
                    marginTop: '-0.45em',
                    marginLeft: '-1em',
                    borderRadius: '4em',
                    backgroundColor: 'rgba(229, 233, 242, 0.9)',
                    border: '1px solid rgba(229, 233, 242, 1)'
                  }}
                  onClick={e =>
                    removeInputCooking(key, modifiers[modifiersKey].modKey)
                  }
                />
              </GridItem>
            ) : null}
            <GridItem xs={6} sm={6} md={6}>
              <div style={{ paddingTop: '30%' }}>
                {key !== modifiers[modifiersKey].cookIns.length &&
                modifiers[modifiersKey].cookIns.length > 1 &&
                modifiers[modifiersKey].cookIns[cookInsKey].name &&
                modifiers[modifiersKey].cookIns[cookInsKey + 1] &&
                modifiers[modifiersKey].cookIns[cookInsKey + 1].name ? (
                  <IconButton
                    onClick={() =>
                      onEditBtnDownCooking(
                        key,
                        modifiers[modifiersKey].modKey,
                        stockVariant
                      )
                    }
                  >
                    {
                      <Icon
                        type="down"
                        style={{
                          color: '#00CC70'
                        }}
                      />
                    }
                  </IconButton>
                ) : null}
                {key !== 0 &&
                modifiers[modifiersKey].cookIns[cookInsKey].name ? (
                  <IconButton
                    onClick={() =>
                      onEditBtnUpCooking(
                        key,
                        modifiers[modifiersKey].modKey,
                        stockVariant
                      )
                    }
                  >
                    {
                      <Icon
                        type="up"
                        style={{
                          color: '#00CC70'
                        }}
                      />
                    }
                  </IconButton>
                ) : null}
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={10} sm={10} md={10} style={{ marginTop: '0.5em' }}>
          {modifiers[modifiersKey].cookIns[cookInsKey].stockList &&
          modifiers[modifiersKey].cookIns[cookInsKey].stockList.length > 0 ? (
            <Collapse defaultActiveKey={['1']}>
              <Panel header={' Link Stock Item'} key={1}>
                {modifiers[modifiersKey].cookIns[cookInsKey].stockList &&
                  Object.keys(
                    modifiers[modifiersKey].cookIns[cookInsKey].stockList
                  ).map((stock, sKey) => (
                    <GridContainer key={'gridinputsAction' + key + sKey}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ marginTop: '-2.5em' }}
                      >
                        <div
                          style={{
                            padding: '1.5em',
                            paddingBottom: '0.5em'
                          }}
                        >
                          {modifiers[modifiersKey].cookIns[cookInsKey]
                            .stockList[sKey].stockId !== 'addnew' ? (
                            <GridContainer>
                              <GridItem
                                xs={5}
                                sm={5}
                                md={5}
                                style={{ marginTop: '2em' }}
                              >
                                <AutoComplete
                                  dataSource={itemsFilter.map(
                                    item =>
                                      item && (
                                        <Option
                                          key={item.key}
                                          value={item.value}
                                        >
                                          {item.value}
                                        </Option>
                                      )
                                  )}
                                  style={{ width: '100%' }}
                                  optionLabelProp="value"
                                  value={
                                    modifiers[modifiersKey].cookIns[cookInsKey]
                                      .stockList[sKey].stock || ''
                                  }
                                  onSelect={(v, e) =>
                                    handleChangeSelectCooking(
                                      v,
                                      e,
                                      modifiers[modifiersKey].modKey,
                                      key,
                                      sKey,
                                      setCookingName,
                                      cookState,
                                      stockVariant
                                    )
                                  }
                                  onSearch={v =>
                                    handleCookingSelectFilter(
                                      v,
                                      modifiers[modifiersKey].modKey,
                                      key,
                                      sKey,
                                      false,
                                      cookState
                                    )
                                  }
                                  onFocus={() =>
                                    handleCookingSelectFilter(
                                      null,
                                      modifiers[modifiersKey].modKey,
                                      key,
                                      sKey,
                                      true,
                                      cookState
                                    )
                                  }
                                  placeholder={'Stock Item name ' + (sKey + 1)}
                                />
                              </GridItem>
                              <GridItem
                                xs={7}
                                sm={7}
                                md={7}
                                style={{ marginTop: '-1em' }}
                              >
                                {modifiers[modifiersKey].cookIns[cookInsKey]
                                  .stockList[sKey].stockId ? (
                                  <GridContainer>
                                    <GridItem xs={3} sm={3} md={3}>
                                      <CustomInput
                                        labelText="QTY"
                                        id={'q' + key}
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputRef={el =>
                                          (oCookingRef[
                                            'q' +
                                              modifiers[modifiersKey].modKey +
                                              'q' +
                                              sKey +
                                              'q' +
                                              key
                                          ] = el)
                                        }
                                        inputProps={{
                                          defaultValue:
                                            modifiers[modifiersKey].cookIns[
                                              cookInsKey
                                            ].stockList[sKey].qty || '',
                                          disabled: modifiers[modifiersKey]
                                            .cookIns[cookInsKey].stockList[sKey]
                                            .stock
                                            ? false
                                            : true,
                                          onChange: e =>
                                            onChangeEvtQTYCooking(
                                              e,
                                              key,
                                              sKey,
                                              modifiers[modifiersKey].modKey,
                                              cookState
                                            )
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={4} sm={4} md={4}>
                                      <CustomSelect
                                        labelText="Dim*"
                                        id="dimentiontype"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          disabled: modifiers[modifiersKey]
                                            .cookIns[cookInsKey].stockList[sKey]
                                            .stock
                                            ? false
                                            : true,
                                          onChange: e =>
                                            handleSelectStockDimCooking(
                                              e,
                                              key,
                                              sKey,
                                              modifiers[modifiersKey].modKey,
                                              cookState
                                            ),
                                          value:
                                            modifiers[modifiersKey].cookIns[
                                              cookInsKey
                                            ].stockList[sKey].dimType ||
                                            modifiers[modifiersKey].cookIns[
                                              cookInsKey
                                            ].stockList[sKey].dimension ||
                                            ''
                                        }}
                                        menuItems={
                                          modifiers[modifiersKey].cookIns[
                                            cookInsKey
                                          ].stockList[sKey].dimtypes || []
                                        }
                                      />
                                    </GridItem>
                                    <GridItem xs={3} sm={3} md={3}>
                                      <CustomInput
                                        labelText="Cost Price"
                                        id="cost"
                                        showPrefix={'R'}
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputRef={el =>
                                          (oCookingRef[
                                            'cost' +
                                              modifiers[modifiersKey].modKey +
                                              'cost' +
                                              sKey +
                                              'cost' +
                                              key
                                          ] = el)
                                        }
                                        inputProps={{
                                          defaultValue:
                                            modifiers[modifiersKey].cookIns[
                                              cookInsKey
                                            ].stockList[sKey].cost || '',
                                          name: 'cost',
                                          disabled: modifiers[modifiersKey]
                                            .cookIns[cookInsKey].stockList[sKey]
                                            .stock
                                            ? false
                                            : true,
                                          onChange: e =>
                                            handleSelectStockDimCooking(
                                              e,
                                              key,
                                              sKey,
                                              modifiers[modifiersKey].modKey,
                                              cookState
                                            )
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      style={{ marginTop: '3.3em' }}
                                    >
                                      <Icon
                                        type="delete"
                                        style={{
                                          position: 'absolute',
                                          fontSize: '25px',
                                          color: 'red'
                                        }}
                                        onClick={e =>
                                          removeInputStockCooking(
                                            key,
                                            sKey,
                                            modifiers[modifiersKey].modKey
                                          )
                                        }
                                      />
                                    </GridItem>
                                  </GridContainer>
                                ) : null}
                              </GridItem>
                            </GridContainer>
                          ) : (
                            <MiniStockForm
                              props={props}
                              modKey={modifiers[modifiersKey].modKey}
                              cookInsKey={key}
                              stockKey={sKey}
                              onCompleteStockAdd={onCompleteStockAdd}
                              resetFilterOnCancel={resetFilterOnCancel}
                              stockname={
                                modifiers[modifiersKey].cookIns[cookInsKey]
                                  .stockList[sKey].stock
                              }
                              types={types}
                              onError={onError}
                              cookState={cookState}
                              stockVariant={stockVariant}
                            />
                          )}
                        </div>
                      </GridItem>
                    </GridContainer>
                  ))}
                <GridContainer>
                  <Button
                    style={{
                      textTransform: 'initial',
                      borderRadius: '3em',
                      boxShadow: 'none',
                      marginLeft: '3em',
                      marginBottom: '1em',
                      border: '1px solid #00CC70',
                      color: '#00CC70',
                      backgroundColor: 'white'
                    }}
                    onClick={() =>
                      addInputStockCooking(key, modifiers[modifiersKey].modKey)
                    }
                    color="primary"
                  >
                    <Icon
                      style={{ color: '#00CC70', marginRight: '0.5em' }}
                      type="plus-circle"
                    />{' '}
                    Add another Stock Item
                  </Button>
                </GridContainer>
              </Panel>
            </Collapse>
          ) : null}
        </GridItem>
      </GridContainer>
    </div>
  );
};

const CreateInput = memo(CreateInputs);

let setModState;
let oState = {};
let processing;
let setProcessing;
const InputsModifiers = allprops => {
  const setCookingName = name => {
    oState.setState(prevState => ({
      ...prevState,
      name: name
    }));
  };

  const onHelpMarker = marker => {
    oState.setHelpMarker(marker);
  };

  const handleModSelectFilter = (valueN, keyN, clearN) => {
    let value = valueN;
    let modKey = keyN;
    let clear = clearN;
    let cookInsFilter = [];
    let cookInsList = Object.assign([], oState.modstate.cookInsList);
    let newModifiers = oState.modstate.modifiers;
    if (value) {
      newModifiers[modKey].cookName = value[0].toUpperCase() + value.slice(1);
      newModifiers[modKey].cookIns = [];
      for (var i = 0; i < cookInsList.length; i++) {
        if (cookInsList[i].name.toUpperCase().includes(value.toUpperCase())) {
          cookInsFilter.push(cookInsList[i]);
        }
      }
      if (cookInsFilter.length === 0) {
        cookInsFilter.push({
          name: 'Add ' + value[0].toUpperCase() + value.slice(1) + ' Modifier',
          _id: value[0].toUpperCase() + value.slice(1)
        });
      }
    } else {
      if (!clear) {
        newModifiers[modKey].cookName = '';
      }
      cookInsFilter = cookInsList;
    }

    setModState(prevState => ({
      ...prevState,
      cookInsFilter: cookInsFilter,
      modifiers: newModifiers
    }));
  };

  const handleChangeSelectMod = (valueN, eventN, keyN) => {
    let value = valueN;
    let modKey = keyN;
    let event = Object.assign({}, eventN);
    let newModifiers = oState.modstate.modifiers;
    if (value) {
      if (value !== 'Add ' + event.key + ' Modifier') {
        var cookitem = Object.assign(
          {},
          oState.modstate.cookingObject[event.key]
        );
        if (cookitem) {
          for (let i = 0; i < cookitem.cookIns.length; i++) {
            if (cookitem.cookIns[i].stock) {
              cookitem.cookIns[i].stockList = [
                {
                  stock: '',
                  qty: 0,
                  cost: '0'
                }
              ];
              cookitem.cookIns[i].stockList[0].stock =
                cookitem.cookIns[i].stock;
              cookitem.cookIns[i].stockList[0].stockId =
                cookitem.cookIns[i].stockId;
              cookitem.cookIns[i].stockList[0].dimension =
                cookitem.cookIns[i].dimension;
              cookitem.cookIns[i].stockList[0].dimType =
                cookitem.cookIns[i].dimType;
              cookitem.cookIns[i].stockList[0].cost = cookitem.cookIns[i].cost;
              cookitem.cookIns[i].stockList[0].qty = cookitem.cookIns[i].qty;
              delete cookitem.cookIns[i].stock;
              delete cookitem.cookIns[i].stockId;
              delete cookitem.cookIns[i].dimension;
              delete cookitem.cookIns[i].dimType;
              delete cookitem.cookIns[i].cost;
              delete cookitem.cookIns[i].qty;
            } else {
              if (!cookitem.cookIns[i].stockList) {
                cookitem.cookIns[i].stockList = [
                  {
                    stock: '',
                    qty: 0,
                    cost: '0'
                  }
                ];
                delete cookitem.cookIns[i].stock;
                delete cookitem.cookIns[i].qty;
                delete cookitem.cookIns[i].cost;
              } else if (cookitem.cookIns[i].stockList.length === 0) {
                cookitem.cookIns[i].stockList = [
                  {
                    stock: '',
                    qty: 0,
                    cost: '0'
                  }
                ];
              }
            }
            for (let j = 0; j < cookitem.cookIns[i].stockList.length; j++) {
              if (cookitem.cookIns[i].stockList[j].dimension) {
                cookitem.cookIns[i].stockList[j].dimtypes =
                  dimSelectTypes[cookitem.cookIns[i].stockList[j].dimension];
              }
            }
          }
          cookitem.cookName = cookitem.name;
          cookitem.modKey = modKey;
          newModifiers[modKey] = cookitem;
          setModState(prevState => ({
            ...prevState,
            modifiers: newModifiers
          }));
        }
      } else {
        var newCookIns = {
          _id: ObjectID().toHexString(),
          modKey: modKey,
          cookName: event.key[0].toUpperCase() + event.key.slice(1),
          cookIns: [
            {
              cookid: '',
              limit: 0,
              flow: true,
              force: false,
              stockList: []
            }
          ]
        };
        newModifiers[modKey] = newCookIns;
        setModState(prevState => ({
          ...prevState,
          modifiers: newModifiers
        }));
      }
    }
  };

  const onChangeEvtMenuCook = (eN, iN) => {
    let e = Object.assign({}, eN);
    var i = iN;
    var newModifiers = oState.modstate.modifiers;
    if (e.currentTarget.valueAsNumber < 0) {
      e.currentTarget.valueAsNumber = 0;
    }
    newModifiers[i].limit = e.currentTarget.valueAsNumber;
    setModState(prevState => ({
      ...prevState,
      modifiers: newModifiers
    }));
  };

  const onEditBtnDownCook = (modKeyN, stockVariantsN) => {
    let key = modKeyN;
    let modifiers = oState.modstate.modifiers;
    let stockVariants = stockVariantsN;
    var newCook = key + 1;
    for (let i = 0; i < stockVariants.length; i++) {
      if (stockVariants[i].modKeyList) {
        for (let j = 0; j < stockVariants[i].modKeyList.length; j++) {
          if (stockVariants[i].modKeyList[j]) {
            if (stockVariants[i].modKeyList[j].mod === key) {
              stockVariants[i].modKeyList[j].mod = newCook;
            } else if (stockVariants[i].modKeyList[j].mod === newCook) {
              stockVariants[i].modKeyList[j].mod = key;
            }
          }
        }
      }
    }
    var f = modifiers.splice(key, 1)[0];
    modifiers.splice(newCook, 0, f);
    oState.setState(prevState => ({
      ...prevState,
      stockVariants: stockVariants
    }));
    setModState(prevState => ({
      ...prevState,
      modifiers: modifiers
    }));
  };

  const onEditBtnUpCook = (modKeyN, stockVariantsN) => {
    let key = modKeyN;
    let modifiers = oState.modstate.modifiers;
    let stockVariants = stockVariantsN;
    var newCook = key - 1;
    for (let i = 0; i < stockVariants.length; i++) {
      if (stockVariants[i].modKeyList) {
        for (let j = 0; j < stockVariants[i].modKeyList.length; j++) {
          if (stockVariants[i].modKeyList[j]) {
            if (stockVariants[i].modKeyList[j].mod === key) {
              stockVariants[i].modKeyList[j].mod = newCook;
            } else if (stockVariants[i].modKeyList[j].mod === newCook) {
              stockVariants[i].modKeyList[j].mod = key;
            }
          }
        }
      }
    }
    var f = modifiers.splice(key, 1)[0];
    modifiers.splice(newCook, 0, f);
    oState.setState(prevState => ({
      ...prevState,
      stockVariants: stockVariants
    }));
    setModState(prevState => ({
      ...prevState,
      modifiers: modifiers
    }));
  };

  const addModifier = () => {
    var newModifiers = oState.modstate.modifiers;
    var newCookIns = {
      _id: ObjectID().toHexString(),
      modKey: newModifiers.length,
      cookName: '',
      cookIns: [],
      limit: 1
    };
    newModifiers.push(newCookIns);
    setModState(prevState => ({
      ...prevState,
      modifiers: newModifiers
    }));
  };

  const changePage = page => {
    setModState(prevState => ({
      ...prevState,
      currentPageStock: page
    }));
  };

  oState = {
    ...allprops
  };
  oState.modstate.currentPageMods = 1;
  [oState.modstate, setModState] = useState(oState.modstate);
  [processing, setProcessing] = useState(false);
  useEffect(() => {
    setProcessing(false);
  }, []);
  return (
    <div>
      {processing === false ? (
        <StyledTableNoPagi
          style={{ background: 'white' }}
          rowKey="_id"
          size="middle"
          pagination={false}
          // pagination={{
          //   onChange: changePage,
          //   current: oState.modstate.currentPageMods,
          //   hideOnSinglePage: true,
          //   pageSize: 5,
          //   itemRender: (current, type, originalElement) => {
          //     if (type === 'prev') {
          //       return <a>Previous</a>;
          //     }
          //     if (type === 'next') {
          //       return <a>Next</a>;
          //     }
          //     return originalElement;
          //   },
          //   showTotal: (total, range) =>
          //     `Showing ${range[0]} - ${range[1]}  of ${total} items`
          // }}
          dataSource={oState.modstate.modifiers}
          footer={() => (
            <div
              style={{
                textAlign: 'center'
              }}
            >
              <a
                onClick={e => addModifier()}
                style={{
                  textAlign: 'center',
                  fontSize: '15px',
                  color: 'green'
                }}
              >
                <Icon
                  type="plus"
                  style={{
                    fontSize: '16px',
                    color: 'green'
                  }}
                />
                {oState.listing === 'ecommerce'
                  ? ' Add Variant'
                  : ' Add Modifier'}
              </a>
            </div>
          )}
        >
          <Column
            title="Name"
            align="center"
            render={(text, record, key) => (
              <div>
                <CustomAddSelect
                  dataSource={oState.modstate.cookInsFilter.map(
                    (item, nkey) =>
                      item && (
                        <Option key={item._id} value={item.name}>
                          {item.name}
                        </Option>
                      )
                  )}
                  formControlProps={{
                    fullWidth: true
                  }}
                  selectProps={{
                    value: record.cookName || ''
                  }}
                  style={{ width: '100%' }}
                  optionLabelProp="value"
                  onSelect={(v, e) => handleChangeSelectMod(v, e, key)}
                  onSearch={v => handleModSelectFilter(v, key)}
                  onFocus={() => handleModSelectFilter(null, key, true)}
                  placeholder={
                    oState.listing === 'ecommerce'
                      ? 'Variant name ' + (key + 1)
                      : 'Modifier name ' + (key + 1)
                  }
                />
              </div>
            )}
          />
          <Column
            title="Limit"
            align="center"
            width="50px"
            render={(text, record, key) => (
              <div>
                <CustomInput
                  labelText="Limit"
                  id="limit"
                  formControlProps={{
                    fullWidth: true,
                    style: { margin: '0', marginTop: '0.8em' }
                  }}
                  inputProps={{
                    onClick: () => onHelpMarker('menuitemssell'),
                    onChange: e => onChangeEvtMenuCook(e, key),
                    value: record.limit >= 0 ? record.limit : 1,
                    type: 'number'
                  }}
                />
              </div>
            )}
          />
          <Column
            title="Options"
            align="center"
            width="150px"
            render={(text, record, key) => <span>{record.cookIns.length}</span>}
          />
          <Column
            title="Actions"
            align="center"
            width="5em"
            render={(text, record, key) => (
              <div
                style={{
                  whiteSpace: 'nowrap'
                }}
              >
                <IconButton
                  style={{
                    width: '3em',
                    padding: '0.35em',
                    borderRadius: '3em',
                    backgroundColor: 'rgb(0, 204, 112)',
                    border: '0px solid rgb(0, 204, 112)'
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    var newModifiers = oState.modstate.modifiers;
                    if (newModifiers[key].cookIns.length === 0) {
                      newModifiers[key].cookIns.push({
                        name: '',
                        price: 0,
                        cost: '0',
                        stockList: []
                      });
                    }
                    setModState(prevState => ({
                      ...prevState,
                      modifiers: newModifiers
                    }));
                    setProcessing(key);
                  }}
                >
                  {
                    <p
                      style={{
                        fontSize: 13,
                        color: 'white',
                        fontWeight: 700,
                        fontFamily: 'Mulish , sans-serif',
                        margin: 'auto'
                      }}
                    >
                      {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                      Edit
                    </p>
                  }
                </IconButton>
                <IconButton
                  style={{
                    marginRight: '0em',
                    marginLeft: '0.4em',
                    borderRadius: '4em',
                    backgroundColor: 'rgba(229, 233, 242, 0.9)',
                    border: '1px solid rgba(229, 233, 242, 1)'
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    var newModifiers = oState.modstate.modifiers;
                    newModifiers.splice(key, 1);
                    if (newModifiers.length === 0) {
                      var newCookIns = {
                        _id: ObjectID().toHexString(),
                        modKey: oState.modstate.modifiers.length,
                        cookName: '',
                        cookIns: [],
                        limit: 1
                      };
                      newModifiers.push(newCookIns);
                    }
                    setModState(prevState => ({
                      ...prevState,
                      modifiers: newModifiers
                    }));
                  }}
                >
                  {
                    <Icon
                      type="delete"
                      style={{
                        fontSize: 13
                      }}
                    />
                  }
                </IconButton>
                {/* key === (oState.modstate.modifiers.length - 1) ?
                  <IconButton
                    style={{
                      border: '1px solid red',
                      padding: "16px",
                      marginLeft: '1em'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      var newModifiers = oState.modstate.modifiers;
                      var newCookIns = {
                        _id: ObjectID().toHexString(),
                        modKey: (newModifiers.length),
                        cookName: '',
                        cookIns: [],
                        limit: 1
                      }
                      newModifiers.push(newCookIns);
                      setModState(prevState => ({
                        ...prevState,
                        modifiers: newModifiers
                      }));
                    }}
                  >
                    {
                      <Icon
                        type="plus"
                        style={{
                          position: 'absolute',
                          fontSize: '16px',
                          color: 'red'
                        }}
                      />
                    }
                  </IconButton>
                  : null */}
              </div>
            )}
          />
          <Column
            title="Position"
            align="center"
            render={(text, record, key) => (
              <div>
                {key !== oState.modstate.modifiers.length - 1 &&
                oState.modstate.modifiers.length > 1 ? (
                  <IconButton
                    onClick={() => onEditBtnDownCook(key, oState.stockVariants)}
                    style={{ zIndex: 8, cursor: 'pointer' }}
                  >
                    {
                      <Icon
                        type="down"
                        style={{
                          color: 'green'
                        }}
                      />
                    }
                  </IconButton>
                ) : null}
                {key !== 0 ? (
                  <IconButton
                    onClick={() => onEditBtnUpCook(key, oState.stockVariants)}
                    style={{ zIndex: 8, cursor: 'pointer' }}
                  >
                    {
                      <Icon
                        type="up"
                        style={{
                          color: 'green'
                        }}
                      />
                    }
                  </IconButton>
                ) : null}
              </div>
            )}
          />
        </StyledTableNoPagi>
      ) : null}
      {oState.modstate.modifiers.length > 0 &&
        oState.modstate.modifiers.map((modfier, modifiersKey) => (
          <div key={'mod' + modifiersKey}>
            {processing !== modifiersKey ? null : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '1em' }}>
                  <Button
                    onClick={() => {
                      setProcessing(false);
                    }}
                    color="primary"
                    style={{
                      textTransform: 'initial',
                      fontSize: '13px',
                      boxShadow: 'none',
                      backgroundColor: 'rgba(229, 233, 242, 0.9)',
                      border: '1px solid rgba(229, 233, 242, 0.9)',
                      color: 'black',
                      borderRadius: '3em'
                    }}
                  >
                    {oState.listing === 'ecommerce'
                      ? 'Back To Variants'
                      : 'Back To Modifiers'}
                  </Button>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{ marginTop: '1em' }}>
                  <CustomInput
                    labelText={
                      oState.listing === 'ecommerce'
                        ? 'Variant Name'
                        : 'Modifier Name'
                    }
                    id={'modifierName' + modifiersKey}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: modfier.cookName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '1em' }}>
                  {modfier.cookIns.length > 0 &&
                    modfier.cookIns.map((mod, key) => (
                      <CreateInput
                        modifiersKey={modifiersKey}
                        props={allprops}
                        modifiers={oState.modstate.modifiers}
                        cookInsKey={key}
                        setCookingName={setCookingName}
                        cookState={oState.cookingState}
                        stockVariant={oState.stockVariants}
                        selectitemsFilter={oState.modstate.selectitemsFilter}
                        onError={oState.onError}
                        types={oState.types}
                      />
                    ))}
                </GridItem>
              </GridContainer>
            )}
          </div>
        ))}
    </div>
  );
};

const InputsModifier = memo(InputsModifiers);
export { InputsModifier };
