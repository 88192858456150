import React from "react";
import a from "src/static/buttonIcons/1.png";
import b from "src/static/buttonIcons/2.png";
import c from "src/static/buttonIcons/3.png";
import d from "src/static/buttonIcons/4.png";
import e from "src/static/buttonIcons/5.png";
import f from "src/static/buttonIcons/6.png";
import g from "src/static/buttonIcons/7.png";
import h from "src/static/buttonIcons/8.png";
import i from "src/static/buttonIcons/9.png";
import j from "src/static/buttonIcons/10.png";
import k from "src/static/buttonIcons/11.png";
import l from "src/static/buttonIcons/12.png";
import m from "src/static/buttonIcons/13.png";
import n from "src/static/buttonIcons/14.png";
import o from "src/static/buttonIcons/15.png";
import p from "src/static/buttonIcons/16.png";
import q from "src/static/buttonIcons/17.png";
import r from "src/static/buttonIcons/18.png";
import s from "src/static/buttonIcons/19.png";
import t from "src/static/buttonIcons/20.png";
import u from "src/static/buttonIcons/21.png";
import v from "src/static/buttonIcons/22.png";
import w from "src/static/buttonIcons/23.png";
import x from "src/static/buttonIcons/24.png";
import y from "src/static/buttonIcons/25.png";
import z from "src/static/buttonIcons/26.png";
import aa from "src/static/buttonIcons/27.png";
import ab from "src/static/buttonIcons/28.png";
import ac from "src/static/buttonIcons/29.png";
import ad from "src/static/buttonIcons/30.png";
import ae from "src/static/buttonIcons/31.png";
import af from "src/static/buttonIcons/32.png";
import ag from "src/static/buttonIcons/33.png";
import ah from "src/static/buttonIcons/34.png";
import ai from "src/static/buttonIcons/35.png";
import aj from "src/static/buttonIcons/36.png";
import ak from "src/static/buttonIcons/37.png";
import al from "src/static/buttonIcons/38.png";
import am from "src/static/buttonIcons/39.png";
import an from "src/static/buttonIcons/40.png";
import ao from "src/static/buttonIcons/41.png";
import ap from "src/static/buttonIcons/42.png";
import aq from "src/static/buttonIcons/43.png";
import ar from "src/static/buttonIcons/44.png";
import ass from "src/static/buttonIcons/45.png";
import at from "src/static/buttonIcons/46.png";
import au from "src/static/buttonIcons/47.png";
import av from "src/static/buttonIcons/48.png";
import aw from "src/static/buttonIcons/49.png";
import ax from "src/static/buttonIcons/50.png";
import ay from "src/static/buttonIcons/51.png";
import az from "src/static/buttonIcons/52.png";
import aaa from "src/static/buttonIcons/53.png";
import aab from "src/static/buttonIcons/54.png";
import aac from "src/static/buttonIcons/55.png";
import aad from "src/static/buttonIcons/56.png";
import aae from "src/static/buttonIcons/57.png";
import aaf from "src/static/buttonIcons/58.png";
import aag from "src/static/buttonIcons/59.png";
import aah from "src/static/buttonIcons/60.png";
import aai from "src/static/buttonIcons/61.png";
import aaj from "src/static/buttonIcons/62.png";
import aak from "src/static/buttonIcons/63.png";
import aal from "src/static/buttonIcons/64.png";
import aam from "src/static/buttonIcons/65.png";
import aan from "src/static/buttonIcons/66.png";
import aao from "src/static/buttonIcons/67.png";
import aap from "src/static/buttonIcons/68.png";
import aaq from "src/static/buttonIcons/69.png";
import aar from "src/static/buttonIcons/70.png";
import aas from "src/static/buttonIcons/54.png";
import aat from "src/static/buttonIcons/55.png";
import aau from "src/static/buttonIcons/56.png";

const iconsList = [
  //{ value: a, label: <img alt="img" src={a} style={{ width: 20 }} />, name: 'A' },
  //{ value: b, label: <img alt="img" src={b} style={{ width: 20 }} /> },
  //{ value: c, label: <img alt="img" src={c} style={{ width: 20 }} /> },
  //{ value: d, label: <img alt="img" src={d} style={{ width: 20 }} /> },
  //{ value: e, label: <img alt="img" src={e} style={{ width: 20 }} /> },
  //{ value: f, label: <img alt="img" src={f} style={{ width: 20 }} /> },
  //{ value: g, label: <img alt="img" src={g} style={{ width: 20 }} /> },
  //{ value: h, label: <img alt="img" src={h} style={{ width: 20 }} /> },
  //{ value: i, label: <img alt="img" src={i} style={{ width: 20 }} /> },
  // { value: j, label: <img alt="img" src={j} style={{ width: 20 }} /> },
  // { value: k, label: <img alt="img" src={k} style={{ width: 20 }} /> },
  //{ value: l, label: <img alt="img" src={l} style={{ width: 20 }} /> },
  //{ value: m, label: <img alt="img" src={m} style={{ width: 20 }} /> },
  //{ value: n, label: <img alt="img" src={n} style={{ width: 20 }} /> },
  //{ value: o, label: <img alt="img" src={o} style={{ width: 20 }} /> },
  //{ value: p, label: <img alt="img" src={p} style={{ width: 20 }} /> },
  //{ value: q, label: <img alt="img" src={q} style={{ width: 20 }} /> },
  //{ value: r, label: <img alt="img" src={r} style={{ width: 20 }} /> },
  //{ value: s, label: <img alt="img" src={s} style={{ width: 20 }} /> },
  //{ value: t, label: <img alt="img" src={t} style={{ width: 20 }} /> },
  //{ value: u, label: <img alt="img" src={u} style={{ width: 20 }} /> },
  //{ value: v, label: <img alt="img" src={v} style={{ width: 20 }} /> },
  //{ value: w, label: <img alt="img" src={w} style={{ width: 20 }} /> },
  //{ value: x, label: <img alt="img" src={x} style={{ width: 20 }} /> },
  //{ value: y, label: <img alt="img" src={y} style={{ width: 20 }} /> },
  //{ value: z, label: <img alt="img" src={z} style={{ width: 20 }} /> },
  //{ value: aa, label: <img alt="img" src={aa} style={{ width: 20 }} /> },
  //{ value: ab, label: <img alt="img" src={ab} style={{ width: 20 }} /> },
  // { value: ac, label: <img alt="img" src={ac} style={{ width: 20 }} /> },
  //{ value: ad, label: <img alt="img" src={ad} style={{ width: 20 }} /> },
  // { value: ae, label: <img alt="img" src={ae} style={{ width: 20 }} /> },
  //{ value: af, label: <img alt="img" src={af} style={{ width: 20 }} /> },
  //{ value: ag, label: <img alt="img" src={ag} style={{ width: 20 }} /> },
  // { value: ah, label: <img alt="img" src={ah} style={{ width: 20 }} /> },
  //{ value: ai, label: <img alt="img" src={ai} style={{ width: 20 }} /> },
  //{ value: aj, label: <img alt="img" src={aj} style={{ width: 20 }} /> },
  //{ value: ak, label: <img alt="img" src={ak} style={{ width: 20 }} /> },
  //{ value: al, label: <img alt="img" src={al} style={{ width: 20 }} /> },
  //{ value: am, label: <img alt="img" src={am} style={{ width: 20 }} /> },
  //{ value: an, label: <img alt="img" src={an} style={{ width: 20 }} /> },
  //{ value: ao, label: <img alt="img" src={ao} style={{ width: 20 }} /> },
  //{ value: ap, label: <img alt="img" src={ap} style={{ width: 20 }} /> },
  // { value: aq, label: <img alt="img" src={aq} style={{ width: 20 }} /> },
  // { value: ar, label: <img alt="img" src={ar} style={{ width: 20 }} /> },
  // { value: ass, label: <img alt="img" src={ass} style={{ width: 20 }} /> },
  // { value: at, label: <img alt="img" src={at} style={{ width: 20 }} /> },
  // { value: au, label: <img alt="img" src={au} style={{ width: 20 }} /> },
  // { value: av, label: <img alt="img" src={av} style={{ width: 20 }} /> },
  // { value: aw, label: <img alt="img" src={aw} style={{ width: 20 }} /> },
  // { value: ax, label: <img alt="img" src={ax} style={{ width: 20 }} /> },
  // { value: ay, label: <img alt="img" src={ay} style={{ width: 20 }} /> },
  // { value: az, label: <img alt="img" src={az} style={{ width: 20 }} /> },
  // { value: aaa, label: <img alt="img" src={aaa} style={{ width: 20 }} /> },
  // { value: aab, label: <img alt="img" src={aab} style={{ width: 20 }} /> },
  //{ value: aac, label: <img alt="img" src={aac} style={{ width: 20 }} /> },
  //{ value: aad, label: <img alt="img" src={aad} style={{ width: 20 }} /> },
  { value: aae, label: <img alt="img" src={aae} style={{ width: 20 }} /> },
  { value: aaf, label: <img alt="img" src={aaf} style={{ width: 20 }} /> },
  { value: aag, label: <img alt="img" src={aag} style={{ width: 20 }} /> },
  { value: aah, label: <img alt="img" src={aah} style={{ width: 20 }} /> },
  { value: aai, label: <img alt="img" src={aai} style={{ width: 20 }} /> },
  { value: aaj, label: <img alt="img" src={aaj} style={{ width: 20 }} /> },
  { value: aak, label: <img alt="img" src={aak} style={{ width: 20 }} /> },
  { value: aal, label: <img alt="img" src={aal} style={{ width: 20 }} /> },
  { value: aam, label: <img alt="img" src={aam} style={{ width: 20 }} /> },
  { value: aan, label: <img alt="img" src={aan} style={{ width: 20 }} /> },
  { value: aao, label: <img alt="img" src={aao} style={{ width: 20 }} /> },
  { value: aap, label: <img alt="img" src={aap} style={{ width: 20 }} /> },
  { value: aaq, label: <img alt="img" src={aaq} style={{ width: 20 }} /> },
  { value: aar, label: <img alt="img" src={aar} style={{ width: 20 }} /> },
  { value: aas, label: <img alt="img" src={aas} style={{ width: 20 }} /> },
];
export default iconsList;
