import styled from 'styled-components';
import { Table } from 'antd';
const StyledTableNoPagi = styled(Table)`
  .ant-table-thead > tr > th {
    background: transparent;
    color: white;
  }
  thead {
    box-shadow: 0px 4px 10px 2px #8888883a;
    background: #272930;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-size: 14px;
    padding: 0px 8px !important;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset;
  }
  tbody {
    box-shadow: 0px 4px 10px 2px #8888883a;
  }
`;
export default StyledTableNoPagi;
