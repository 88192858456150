/* eslint-disable no-loop-func */
import { Button, Col, Icon, Layout, Row, Steps, notification } from 'antd';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { FaTruck } from 'react-icons/fa';
import { GiCardPlay, GiPayMoney, GiReceiveMoney } from 'react-icons/gi';
import { IoIosHourglass } from 'react-icons/io';
import { MdGetApp, MdMyLocation, MdRoomService } from 'react-icons/md';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
const apiCall = 'vpos/api/customer/';
const apiCall2 = 'vpos/api/customer/sub/modules/payment/';
const apiCall3 = 'vpos/api/customer/sub/modules/customers/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Content } = Layout;
const { Step } = Steps;
let bill;
let setBill;
let user;
const pubnubHandler = {
  signal: () => {
    $.ajax({
      type: 'GET',
      url: server + apiCall + 'getorder?id=' + bill._id,
      dataType: 'json',
      crossDomain: true,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      },
      success: async data => {
        setBill(data.results);
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          notification.error({
            message: 'Error',
            description: xhr.responseText
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Error retrieving order'
          });
        }
      }
    });
  }
};
const makePayment = () => {
  var params = {
    merchant_id: '10016040',
    merchant_key: 'h11fymv7cw1io',
    return_url: window.cordova
      ? 'https://www.vcsappcloud.com/vpos/android/user?status=completed&id=' +
        bill._id
      : 'https://www.vcsappcloud.com/vpos/webapp/user?status=completed&id=' +
        bill._id,
    cancel_url: window.cordova
      ? 'https://www.vcsappcloud.com/vpos/android/user?status=cancelled&id=' +
        bill._id
      : 'https://www.vcsappcloud.com/vpos/webapp/user?status=cancelled&id=' +
        bill._id,
    notify_url: server + apiCall2 + 'payorder',
    amount: (bill.total + bill.tip).toFixed(2),
    item_name: bill.orgName + ' payment',
    item_description: 'Order #' + bill.orderno,
    custom_str1: bill._id
  };
  $.ajax({
    type: 'POST',
    url: server + apiCall + 'gethash',
    data: JSON.stringify(params),
    contentType: 'application/json',
    dataType: 'json',
    crossDomain: true,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
    },
    success: async data => {
      // params.signature = data.hash;
      var form = document.createElement('form');
      form.setAttribute('method', 'post');
      // form.setAttribute("action", "https://www.payfast.co.za/eng/process");
      form.setAttribute('action', 'https://sandbox.payfast.co.za/eng/process');
      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          var hiddenField = document.createElement('input');
          hiddenField.setAttribute('type', 'hidden');
          hiddenField.setAttribute('name', key);
          hiddenField.setAttribute('value', params[key]);
          form.appendChild(hiddenField);
        }
      }
      if (window.cordova) {
        form.setAttribute('id', 'payForm');
        var pageContent =
          '<html><head></head><body>' +
          form.outerHTML +
          '<script type="text/javascript">document.getElementById("payForm").submit();</script></body></html>';
        var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);

        var inAppBrowserRef = window.cordova.InAppBrowser.open(
          pageContentUrl,
          '_blank',
          'hidden=no,location=no,hardwareback=no'
        );
        inAppBrowserRef.addEventListener('loadstart', loadStartCallBack);
        inAppBrowserRef.addEventListener('exit', exitCallBack);
        function loadStartCallBack(event) {
          if (
            event.url ===
            'https://www.vcsappcloud.com/vpos/android/user?status=cancelled&id=' +
              bill._id
          ) {
            inAppBrowserRef.removeEventListener('exit', exitCallBack);
            inAppBrowserRef.close();
            inAppBrowserRef = undefined;
            notification.error({
              message: 'Error',
              description: 'Cancelled payment'
            });
          } else if (
            event.url ===
            'https://www.vcsappcloud.com/vpos/android/user?status=completed&id=' +
              bill._id
          ) {
            inAppBrowserRef.removeEventListener('exit', exitCallBack);
            inAppBrowserRef.close();
            inAppBrowserRef = undefined;
            $.ajax({
              type: 'GET',
              url: server + apiCall + 'getorder?id=' + bill._id,
              dataType: 'json',
              crossDomain: true,
              beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
              },
              success: async data => {
                setBill(data.results);
              },
              error: async (xhr, ajaxOptions, thrownError) => {
                if (xhr.responseText) {
                  notification.error({
                    message: 'Error',
                    description: xhr.responseText
                  });
                } else {
                  notification.error({
                    message: 'Error',
                    description: 'Error retrieving order'
                  });
                }
              }
            });
            notification.success({
              message: 'Success',
              description: 'Payment success'
            });
          }
        }
        function exitCallBack(event) {
          inAppBrowserRef = undefined;
          notification.error({
            message: 'Error',
            description: 'Cancelled payment'
          });
        }
      } else {
        document.body.appendChild(form);
        form.submit();
      }
    },
    error: async (xhr, ajaxOptions, thrownError) => {
      if (xhr.responseText) {
        notification.error({
          message: 'Error',
          description: xhr.responseText
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error retrieving order'
        });
      }
    }
  });
};
const getBillState = () => {
  if (bill.state === 'await confirmation') return 0;
  if (bill.state === 'await payment') return 1;
  if (bill.state === 'paid') return 2;
  if (bill.state === 'preparing') return 3;
  if (bill.state === 'delivery' && bill.driverId) return 4;
  if (bill.state === 'delivery') return 3;
  if (bill.state === 'collect') return 4;
  if (bill.state === 'closed') return 5;
  if (bill.state === 'delivered') return 5;
};
const getCashBillState = () => {
  if (bill.state === 'await confirmation') return 0;
  if (bill.state === 'preparing') return 1;
  if (bill.state === 'delivery' && bill.driverId) return 2;
  if (bill.state === 'delivery') return 1;
  if (bill.state === 'collect') return 2;
  if (bill.state === 'closed') return 3;
  if (bill.state === 'delivered') return 3;
};
const completeOrder = props => {
  setLoading(true);
  $.ajax({
    type: 'POST',
    url: server + apiCall3 + 'completeorder',
    data: JSON.stringify(props.bill),
    contentType: 'application/json',
    dataType: 'json',
    crossDomain: true,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
    },
    success: async data => {
      setLoading(false);
      notification.success({
        message: 'Success',
        description: 'Successfully Completed Order'
      });
      props.setBill(data.results);
    },
    error: async (xhr, ajaxOptions, thrownError) => {
      setLoading(false);
      if (xhr.responseText) {
        notification.error({
          message: 'Error',
          description: xhr.responseText
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error completing order'
        });
      }
    }
  });
};
let loading = false;
let setLoading;
const Status = props => {
  bill = props.bill;
  setBill = props.setBill;
  user = props.user;
  [loading, setLoading] = useState(loading);
  useEffect(() => {
    let search = new URLSearchParams(window.location.search);
    if (search.has('status')) {
      bill = {};
      bill._id = search.get('id');
    }
    $.ajax({
      type: 'GET',
      url: server + apiCall + 'getorder?id=' + bill._id,
      dataType: 'json',
      crossDomain: true,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      },
      success: async data => {
        setBill(data.results);
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          notification.error({
            message: 'Error',
            description: xhr.responseText
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Error retrieving order'
          });
        }
      }
    });
    window.pubnub.addListener(pubnubHandler);
    return () => {
      window.pubnub.removeListener(pubnubHandler);
    };
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white', paddingBottom: 170 }}>
      <Row type="flex" justify="space-between">
        {' '}
        <Col span={24}>
          <h1
            style={{
              fontWeight: 500,
              textAlign: 'center',
              margin: 'auto',
              paddingTop: '0.5em',
              paddingBottom: '1em',
              fontFamily: 'Roboto',
              fontSize: '1.5em'
            }}
          >
            {props.bill.orgName}
          </h1>
        </Col>
        <Col span={12}>
          <h2
            style={{
              fontSize: 17,
              paddingLeft: '1em',
              margin: 'auto',
              marginBottom: '0.7em'
            }}
          >
            Order #{props.bill.orderno}
          </h2>
        </Col>
        <Col span={12}>
          <a
            style={{
              float: 'right',
              paddingRight: '1em',
              paddingTop: '0.5em',
              fontFamily: 'Roboto',
              fontSize: '1em'
            }}
            onClick={e => props.navToView('orderDetails')}
          >
            View Order
          </a>
        </Col>
      </Row>
      {bill.state !== 'void' ? (
        <Row type="flex" justify="space-around" style={{ marginTop: '3em' }}>
          <Col span={20}>
            {bill.paymentType !== 'Cash' && bill.paymentType !== 'Card' ? (
              <Steps direction="vertical" size="small" current={getBillState()}>
                <Step
                  title="Awaiting order confirmation"
                  icon={<IoIosHourglass />}
                />
                <Step title="Awaiting payment" icon={<GiPayMoney />} />
                <Step title="Payment confirmed" icon={<GiReceiveMoney />} />
                <Step title="Order being prepared" icon={<MdRoomService />} />
                {bill.address !== 'Collect' ? (
                  <Step title="Out for delivery" icon={<FaTruck />} />
                ) : (
                  <Step title="Ready for collection" icon={<MdGetApp />} />
                )}
                {bill.address !== 'Collect' ? (
                  <Step title="Delivered" icon={<MdMyLocation />} />
                ) : (
                  <Step title="Collected" icon={<GiCardPlay />} />
                )}
              </Steps>
            ) : (
              <Steps
                direction="vertical"
                size="small"
                current={getCashBillState()}
              >
                <Step
                  title="Awaiting order confirmation"
                  icon={<IoIosHourglass />}
                />
                <Step title="Order being prepared" icon={<MdRoomService />} />
                {bill.address !== 'Collect' ? (
                  <Step title="Out for delivery" icon={<FaTruck />} />
                ) : (
                  <Step title="Ready for collection" icon={<MdGetApp />} />
                )}
                {bill.address !== 'Collect' ? (
                  <Step title="Delivered" icon={<MdMyLocation />} />
                ) : (
                  <Step title="Collected" icon={<GiCardPlay />} />
                )}
              </Steps>
            )}
          </Col>
        </Row>
      ) : (
        <Row type="flex" justify="space-around">
          <Col span={20}>
            <Steps direction="vertical" size="small" current={getBillState()}>
              <Step
                title="Order Rejected"
                icon={<Icon type="close-circle" />}
              />
            </Steps>
          </Col>
          <Row type="flex" justify="space-around">
            <Col span={20}>
              <span>Unfortunately your order has been rejected</span>
            </Col>
          </Row>
        </Row>
      )}

      {bill.state !== 'void' ? (
        <Row
          style={{
            position: 'absolute',
            width: '100%'
          }}
          type="flex"
          justify="space-around"
        >
          {bill.paymentType !== 'Cash' && bill.paymentType !== 'Card' ? (
            <Button
              loading={loading}
              disabled={loading}
              style={{
                zIndex: 999,
                position: 'Fixed',
                backgroundColor: '#00CC70',
                fontWeight: 700,
                height: '4.6em',
                fontFamily: 'Roboto',
                fontSize: '18px',
                color: 'white',
                width: '100%',
                bottom: 0
              }}
              onClick={
                getBillState() === 4 && bill.address !== 'Collect'
                  ? e => props.navToView('track')
                  : getBillState() === 1
                  ? makePayment
                  : getBillState() === 4
                  ? completeOrder(props)
                  : props.navToView('myOrders')
              }
            >
              {getBillState() === 1
                ? 'Make Payment'
                : getBillState() === 4 && bill.address !== 'Collect'
                ? 'Track Delivery'
                : getBillState() === 4
                ? 'Complete Order'
                : getBillState() === 5
                ? 'Order Complete'
                : 'Ok'}
            </Button>
          ) : (
            <Button
              loading={loading}
              disabled={loading}
              style={{
                zIndex: 999,
                position: 'Fixed',
                backgroundColor: '#00CC70',
                fontWeight: 700,
                height: '4.6em',
                fontFamily: 'Roboto',
                fontSize: '18px',
                color: 'white',
                width: '100%',
                bottom: 0
              }}
              onClick={e => {
                if (getCashBillState() === 2 && bill.address !== 'Collect') {
                  props.navToView('track');
                } else if (getCashBillState() === 2) {
                  completeOrder(props);
                } else {
                  props.navToView('myOrders');
                }
              }}
            >
              {getCashBillState() === 2 && bill.address !== 'Collect'
                ? 'Track Delivery'
                : getCashBillState() === 2
                ? 'Complete Order'
                : getCashBillState() === 3
                ? 'Order Complete'
                : 'Ok'}
            </Button>
          )}
        </Row>
      ) : (
        <Row
          style={{
            position: 'absolute',
            width: '100%'
          }}
          type="flex"
          justify="space-around"
        >
          <Button
            loading={false}
            disabled={true}
            style={{
              zIndex: 999,
              position: 'Fixed',
              backgroundColor: '#00CC70',
              fontWeight: 700,
              height: '4em',
              fontFamily: 'Roboto',
              fontSize: '18px',
              color: 'white',
              width: '100%',
              bottom: 0
            }}
          >
            Rejected
          </Button>
        </Row>
      )}
    </Content>
  );
};

export default Status;
