export function resetHelpMarker(marker) {
  return {
    type: 'RESET_HELP_MARKER'
  };
}

export function setHelpMarker(marker) {
  return {
    type: 'SET_HELP_MARKER',
    payload: marker
  };
}

export function setBreadCrum(crum) {
  return {
    type: 'SET_BREAD_CRUM',
    payload: crum
  };
}

export function resetBreadCrum() {
  return {
    type: 'SET_BREAD_CRUM'
  };
}