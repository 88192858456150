import { Switch, Icon, Input, Select } from 'antd';
import React, { memo, useEffect, useState } from 'react';

import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { MemoInputsSizes } from 'src/views/Admin/Menuitems/Form';

import _ from 'lodash';

const { Option } = Select;
const InputGroup = Input.Group;

const disableInput = () => {
    const selects = document.getElementsByClassName(`ant-select-search__field`)
    for (let el of selects) {
        el.setAttribute(`maxlength`, 0)
    }
}
class TagSelect extends Select {
    componentDidMount() {
        disableInput()
    }

};

let oVariantsRef = {};
const VarientsBody = allprops => {
    let oState;
    let setState;
    oState = {
        ...allprops
    };
    const handleChange = (key, value) => {
        if (oState.state.variants[key] && oState.state.variants[key].values) {
            oState.state.variants[key].values.push(value);
            oState.state.variants[key].tempValue = '';
            oVariantsRef["optionValues" + key].rcSelect.state.value = oState.state.variants[key].values;
            oVariantsRef["tempValue" + key].setValue('');
            oVariantsRef["optionValues" + key].forceUpdate();
            oVariantsRef["tempValue" + key].input.focus();
        }
    };
    useEffect(() => {
        if (oVariantsRef["optionValues" + 0]) {
            for (var j = 0; j < oState.state.variants.length; j++) {
                oVariantsRef["optionValues" + j].rcSelect.state.value = oState.state.variants[j].values;
                oVariantsRef["optionValues" + j].forceUpdate();
            }
        } else {
            oVariantsRef = {};
        }
    }, []);
    [oState.state, setState] = useState(oState.state);
    return (
        <div>
            <Card className={oState.props.classes.cardExpand} style={{ marginTop: '1em' }}>
                <CardHeader
                    style={{ height: '4em', background: 'transparent', borderBottom: "1px solid lightgrey", marginRight: "2em", marginLeft: "1.5em", marginBottom: "0.5em" }}
                >
                    {' '}
                    <div style={{ display: 'inline-flex' }}>
                        <h4 style={{ color: "rgba(0, 0, 0, 0.85)", marginLeft: "-0.8em", fontSize: "24px" }}
                            className={oState.props.classes.cardTitleWhite}
                        >
                            {'Product Variants'}
                        </h4>
                        <p style={{ color: "rgba(0, 0, 0, 0.85)" }}
                            className={oState.props.classes.cardCategoryWhite}
                        >
                            {'A single variant will be created automatically unless you enable the multiple variant option.'}
                        </p>
                    </div>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={5} sm={5} md={5} style={{ marginTop: '1em', marginBottom: "1em", marginLeft: 17 }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        style={{ marginRight: '1em' }}
                                        checked={oState.state.addVariant}
                                        onChange={(e) => {
                                            var newVariants = [];
                                            var createModsFromVariants = oState.state.createModsFromVariants;
                                            if (!e) {
                                                createModsFromVariants = false;
                                            }
                                            newVariants = [{
                                                values: []
                                            }]
                                            setState(prevstate => ({
                                                ...prevstate, addVariant: e, variants: newVariants, createModsFromVariants: createModsFromVariants
                                            }))
                                        }}
                                    />
                                }
                                label="This product has multiple variants"
                            />
                        </GridItem>
                        {oState.state.listing !== "ecommerce" && oState.state.addVariant && oState.state.mainmenusmenu && oState.state.mainmenusmenu !== 'No Catalogue' && oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' ?
                            <GridItem xs={5} sm={5} md={5}>
                                <FormControlLabel
                                    style={{ marginTop: '1em' }}
                                    control={
                                        <Switch
                                            style={{ marginRight: '1em' }}
                                            checked={oState.state.createModsFromVariants}
                                            onChange={(e) => {
                                                var combineStock = oState.state.combineStock;
                                                var createModsFromVariants = e;
                                                if (combineStock) {
                                                    createModsFromVariants = false;
                                                }
                                                setState(prevstate => ({
                                                    ...prevstate, createModsFromVariants: createModsFromVariants
                                                }))
                                            }}
                                        />
                                    }
                                    label="Would you like to make Modifiers of the Variants?"
                                />
                            </GridItem>
                            : null}
                        {oState.state.addVariant ?
                            <GridItem xs={12} sm={12} md={12}>
                                {oState.state.variants.map((variant, key) => (
                                    <GridContainer style={{ maxHeight: '5em' }} key={'Variant' + key}>
                                        <GridItem xs={2} sm={2} md={2} style={{ marginTop: '2em' }}>
                                            <CustomInput
                                                labelText="Variant Option"
                                                id="varop"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    style: { top: '-3em' }
                                                }}
                                                inputRef={el => oVariantsRef["varop" + key] = el}
                                                inputProps={{
                                                    defaultValue: oState.state.variants[key].option || '',
                                                    onFocus: e => {
                                                        oState.state.variants[key].option = '';
                                                        oVariantsRef["varop" + key].value = '';
                                                    },
                                                    onChange: eN => {
                                                        let e = Object.assign({}, eN);
                                                        oState.state.variants[key].option = e.target.value;
                                                        oVariantsRef["varop" + key].value = e.target.value;
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8} style={{ marginTop: '2em' }}>
                                            <InputGroup compact>
                                                <Input
                                                    style={{ width: '30%' }}
                                                    placeholder={'e.g. Large'}
                                                    controlled={undefined}
                                                    onFocus={e => {
                                                        oState.state.variants[key].tempValue = '';
                                                        oVariantsRef["tempValue" + key].setValue('');
                                                    }}
                                                    ref={el => {
                                                        oVariantsRef["tempValue" + key] = el
                                                    }}
                                                    onChange={eN => {
                                                        let e = Object.assign({}, eN);
                                                        let { value } = e.target;
                                                        oState.state.variants[key].tempValue = value;
                                                        oVariantsRef["tempValue" + key].input.value = value;
                                                    }}
                                                    onPressEnter={e => {
                                                        if (oState.state.variants[key].tempValue) {
                                                            handleChange(key, oState.state.variants[key].tempValue);
                                                        }
                                                    }}
                                                    suffix={
                                                        <Icon type="plus" onClick={e => {
                                                            if (oState.state.variants[key].tempValue) {
                                                                handleChange(key, oState.state.variants[key].tempValue);
                                                            }
                                                        }} />
                                                    }
                                                />
                                                <TagSelect
                                                    style={{ width: '70%' }}
                                                    placeholder="Option Values"
                                                    mode="multiple"
                                                    open={false}
                                                    showSearch={false}
                                                    ref={el => {
                                                        oVariantsRef["optionValues" + key] = el
                                                    }}
                                                    onDeselect={(v) => {
                                                        var rkey = 0;
                                                        for (var j = 0; j < oState.state.variants[key].values.length; j++) {
                                                            if (oState.state.variants[key].values[j] === v) {
                                                                rkey = j;
                                                                break;
                                                            }
                                                        }
                                                        oState.state.variants[key].values.splice(rkey, 1);
                                                        oVariantsRef["optionValues" + key].rcSelect.state.value = oState.state.variants[key].values;
                                                    }}
                                                >
                                                    {oState.state.variants[key].values.map((sitem, skey) => (
                                                        <Option key={skey}>{sitem}</Option>
                                                    ))}
                                                </TagSelect>
                                            </InputGroup>
                                        </GridItem>
                                        {key === oState.state.variants.length - 1 ?
                                            <GridItem xs={1} sm={1} md={1} style={{ marginTop: '2.2em' }}>
                                                <Icon
                                                    type="delete"
                                                    style={{
                                                        position: 'absolute',
                                                        fontSize: '25px',
                                                        color: 'red'
                                                    }}
                                                    onClick={e => {
                                                        if (oState.state.variants[oState.state.variants.length - 1]) {
                                                            oState.state.variants.splice(oState.state.variants.length - 1, 1);
                                                            setState(prevstate => ({
                                                                ...prevstate, variants: oState.state.variants
                                                            }));
                                                        }
                                                    }}
                                                />
                                            </GridItem>
                                            : null}
                                    </GridContainer>
                                ))}
                                <GridItem xs={3} sm={3} md={3}>
                                    <Button style={{ border: "1px solid #00CC70", color: "#00CC70", backgroundColor: "white", marginLeft: "-1.2em", marginTop: "1em" }}
                                        onClick={() => {
                                            oState.state.variants.push({
                                                values: []
                                            });
                                            setState(prevstate => ({
                                                ...prevstate, variants: oState.state.variants
                                            }));
                                        }}
                                        className={oState.props.classes.buttonAdd}
                                        color="primary"
                                    >
                                        Add Another Option
                                     </Button>
                                </GridItem>
                            </GridItem>
                            : null}
                    </GridContainer>
                </CardBody>
            </Card>
            <Card className={oState.props.classes.cardExpand} style={{ marginTop: '1em' }}>
                <CardHeader
                    style={{ height: '4em', background: 'transparent', borderBottom: "1px solid lightgrey", marginRight: "2em", marginLeft: "1.5em", marginBottom: "0.5em" }}
                >
                    {' '}
                    <div style={{ display: 'inline-flex' }}>
                        <h4 style={{ color: "rgba(0, 0, 0, 0.85)", marginLeft: "-0.8em", fontSize: "24px" }}
                            className={oState.props.classes.cardTitleWhite}
                        >
                            {'Product Pack sizes'}
                        </h4>
                        <p style={{ color: "rgba(0, 0, 0, 0.85)" }}
                            className={oState.props.classes.cardCategoryWhite}
                        >
                            {'Create sets of options that can be applied to items'}
                        </p>
                    </div>
                </CardHeader>
                <CardBody>
                    <GridItem style={{ marginTop: '1em' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    style={{ marginRight: '1em' }}
                                    checked={oState.state.addSizes}
                                    onChange={(e) => {
                                        var newSizes = [];
                                        if (e) {
                                            newSizes = [{
                                                dimension: '',
                                                dimType: '',
                                                qty: 1,
                                                dimtypes: [],
                                                name: ''
                                            }]
                                        }
                                        setState(prevState => ({
                                            ...prevState, addSizes: e
                                        }));
                                        oState.setSizes(newSizes);
                                    }}
                                />
                            }
                            label="This product is bought in and/or sold in packs"
                        />
                    </GridItem>
                    {oState.state.addSizes ?
                        <div>
                            <MemoInputsSizes dimension={oState.state.dimension} sizes={oState.sizes} props={oState.props} />
                        </div>
                        : null}
                </CardBody>
            </Card>
            <GridContainer>
                <GridItem xs={8} sm={8} md={8} />
                <GridItem xs={2} sm={2} md={2} style={{ marginTop: '3em' }}>
                    {oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' ?
                        <Button
                            onClick={() => oState.setState(prevState => ({
                                ...prevState, itemasmenu: !oState.state.itemasmenu
                            }))}
                            color="primary" style={{ backgroundColor: "white", color: "#00CC70", border: "1px solid #00CC70", borderRadius: "3em", width: '100%' }}>Back</Button>
                        : null}
                </GridItem>
                <GridItem xs={2} sm={2} md={2} style={{ marginTop: '3em' }}>
                    <button onClick={() => {
                        if (oState.state.listing === "ecommerce") {
                            oState.createVariantsEcom(oState.state, (oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' ? false : true));
                        } else {
                            oState.createVariants(oState.state, (oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' ? false : true));
                        }

                    }} color="primary" style={{ color: "white", paddingTop: "0.6em", paddingBottom: "0.6em", border: "1px solid #00CC70", backgroundColor: "#00CC70", borderRadius: "3em", width: '100%' }}>
                        {oState.state.userlistbuttonmenu !== 'EDIT PRODUCT' ? 'Continue Setup' : 'Create Variants'}
                    </button>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default memo(VarientsBody);