import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon, Input, Statistic, Switch, Tooltip } from 'antd';
import convert from 'convert-units';
import React, { useEffect, useState } from 'react';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import CustomSelectMulti from 'src/components/CustomSelectMulti/CustomSelectMulti.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import CirclesPic from 'src/static/circles-shape.svg';
import RocketIconPic from 'src/static/rocket-icon-shape.svg';
import RocketPic from 'src/static/rocket-shape.svg';
import StockPic from 'src/static/stock-shape.svg';
import { formaterCheck } from 'src/utils/formater.js';
import styled from 'styled-components';
const InputGroup = Input.Group;

const dimSelectTypes = {
  volume: [
    {
      name: 'ml',
      value: 'ml'
    },
    {
      name: 'L',
      value: 'l'
    }
  ],
  mass: [
    {
      name: 'g',
      value: 'g'
    },
    {
      name: 'Kg',
      value: 'kg'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: [
    {
      name: 'Each',
      value: 'unit'
    }
  ]
};

const dimSelectTypesFormat = {
  l: 'L',
  kg: 'Kg',
  unit: 'Each'
};

const Inputs = ({ ...oState }) => {
  var inputs = [];
  for (let i = 0; i < oState.state.sizes.length; i++) {
    inputs.push(
      <div key={'divsizeinputs' + i}>
        <GridContainer key={'gridinputs' + i}>
          <GridItem xs={3} sm={3} md={3}>
            <CustomInput
              labelText={'Label ' + (i + 1) + '*'}
              id={'label' + i}
              error={
                !oState.state.sizes[i].name && oState.state.submitInProgress
              }
              errorText={'Pack Size name required'}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeSizeInt(e, i),
                value: oState.state.sizes[i].name || ''
              }}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <CustomInput
              labelText="Stock Qty"
              id={'q' + i}
              formControlProps={{
                style: { whiteSpace: 'nowrap' },
                fullWidth: true
              }}
              inputProps={{
                type: 'number',
                onChange: e => oState.onChangeEvtSizeQTY(e, i),
                value: oState.state.sizes[i].qty || 0
              }}
            />
          </GridItem>
          {oState.state.sizes[i].dimension !== 'unit' ? (
            <GridItem xs={2} sm={2} md={2}>
              <CustomSelect
                labelText="Unit"
                id={'dimentiontype' + i}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled:
                    oState.state.sizes[i].dimension !== 'unit' ? false : true,
                  onChange: e => oState.handleSelectStockSizeDim(e, i),
                  value: oState.state.sizes[i].dimType
                }}
                menuItems={oState.state.sizes[i].dimtypes || []}
              />
            </GridItem>
          ) : null}
          <GridItem xs={2} sm={2} md={2}>
            <Button
              style={{
                boxShadow: 'none',
                border: '1px solid red',
                backgroundColor: 'transparent',
                fontSize: '20.5px',
                marginTop: '43px',
                height: '35px',
                padding: '1em',
                borderRadius: '3em'
              }}
              onClick={e => oState.removeSizeInput(i)}
              color="primary"
            >
              <Icon
                type="minus"
                style={{
                  position: 'absolute',
                  color: 'red',
                  paddingLeft: 3
                }}
              />
            </Button>

            <Button
              style={{
                border: '1px solid #00CC70',
                backgroundColor: 'transparent',
                fontSize: '20px',
                marginTop: '43px',
                height: '35px',
                marginLeft: '1.5em',
                padding: '1em',
                borderRadius: '3em'
              }}
              onClick={() => oState.addSizeInput()}
              color="primary"
            >
              <Icon
                type="plus"
                style={{
                  position: 'absolute',
                  color: '#00CC70',
                  paddingLeft: 3
                }}
              />
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
  return inputs;
};

const handleChange = eventN => {
  let event = Object.assign({}, eventN);
  let value = event.target.value;
  if (event.target.name === 'Type') {
    var stockitems = oState.state.stockitems;
    var skuheader = value
      .replace(/\s/g, '')
      .slice(0, 3)
      .toUpperCase();
    var footer = '10000';
    var counter = 0;
    for (var i = 0; i < stockitems.length; i++) {
      if (stockitems[i].code.slice(0, 3).toUpperCase() === skuheader) {
        counter = counter + 1;
      }
    }
    footer =
      footer.slice(0, -(counter + 1).toString().length) +
      (counter + 1).toString();
    skuheader = skuheader + footer;
    setState(prevState => ({
      ...prevState,
      type: value,
      code: skuheader
    }));
  }
  if (event.target.name === 'supplier') {
    if (!value[value.length - 1]) {
      setState(prevState => ({
        ...prevState,
        multiOpen: false
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        supplier: value
      }));
    }
  }
  if (event.target.name === 'dimension') {
    var serving = '1';
    var packSize = 1;
    var dimChangeCheck = false;
    var qty = oState.state.qty || 0;
    if (value && value !== 'unit') {
      if (oState.state.dimType) {
        if (oState.state.dimType === 'kg') {
          var newDimType = dimSelectTypes[value][0].value;
          var convertOldType = 'l';
          qty = convert(qty)
            .from(convertOldType)
            .to(newDimType);
        }
        if (oState.state.dimType === 'l') {
          var newDimType = dimSelectTypes[value][0].value;
          var convertOldType = 'kg';
          qty = convert(qty)
            .from(convertOldType)
            .to(newDimType);
        }
      }
    } else {
      qty = 0;
      dimChangeCheck = true;
    }
    setState(prevState => ({
      ...prevState,
      stockSize: false,
      sizes: [],
      packSize: packSize,
      serving: serving,
      dimension: value,
      qty: qty,
      dimtypes: dimSelectTypes[value],
      servingType:
        dimSelectTypes[value][0] && value !== 'unit'
          ? dimSelectTypes[value][0].value
          : '',
      dimType:
        dimSelectTypes[value][0] && value !== 'unit'
          ? dimSelectTypes[value][0].value
          : 'unit',
      dimChangeCheck: dimChangeCheck
    }));
  }
  if (event.target.name === 'dimentiontype') {
    var qty = oState.state.qty || 0;
    if (oState.state.sizes) {
      for (let i = 0; i < oState.state.sizes.length; i++) {
        if (
          oState.state.sizes[i].dimtypes &&
          oState.state.sizes[i].dimtypes[0].value === 'packsize'
        ) {
          oState.state.sizes[i].dimtypes[0].name =
            oState.state.packSize +
            (dimSelectTypesFormat[value] ? dimSelectTypesFormat[value] : value);
        }
      }
    }
    if (
      value &&
      oState.state.dimType &&
      value !== 'unit' &&
      oState.state.dimType !== 'unit'
    ) {
      qty = convert(qty)
        .from(oState.state.dimType)
        .to(value);
    }
    setState(prevState => ({
      ...prevState,
      dimType: value,
      qty: qty
    }));
  }
  if (event.target.name === 'servingtype') {
    setState(prevState => ({
      ...prevState,
      servingType: value
    }));
  }
};

const onChangeEvt = eN => {
  let e = Object.assign({}, eN);
  if (e.target.id === 'qty') {
    var value = e.currentTarget.value;
    if (value[value.length - 1] === ',') {
      value = value.substr(0, value.length - 1) + '.';
    }
    if (!value) {
      value = '0';
    }
    if (!isNaN(value)) {
      if (value !== '0.0') {
        value = (Math.round(parseFloat(value) * 1000) / 1000).toString();
      }
      setState(prevState => ({
        ...prevState,
        qty: value
      }));
    }
  } else if (e.target.id === 'packsize') {
    var value = e.currentTarget.value || '0';
    if (oState.state.sizes) {
      for (let i = 0; i < oState.state.sizes.length; i++) {
        if (
          oState.state.sizes[i].dimtypes &&
          oState.state.sizes[i].dimtypes[0].value === 'packsize'
        ) {
          if (value > 1) {
            oState.state.sizes[i].dimtypes[0].name =
              Math.round(parseFloat(value) * 1000) / 1000 +
              (dimSelectTypesFormat[oState.state.dimType]
                ? dimSelectTypesFormat[oState.state.dimType]
                : oState.state.dimType);
          } else {
            oState.state.sizes[i].dimtypes.splice(0, 1);
            oState.state.sizes[i].packSize = false;
            if (oState.state.sizes[i].dimType === 'packsize') {
              oState.state.sizes[i].dimType = oState.state.dimType;
            }
          }
        } else if (oState.state.sizes[i].dimtypes && value > 1) {
          var dimensions = Object.assign(
            [],
            dimSelectTypes[oState.state.dimension]
          );
          dimensions.unshift({
            name:
              value +
              (dimSelectTypesFormat[oState.state.dimType]
                ? dimSelectTypesFormat[oState.state.dimType]
                : oState.state.dimType),
            value: 'packsize'
          });
          oState.state.sizes[i].dimtypes = dimensions;
        }
      }
    }
    setState(prevState => ({
      ...prevState,
      packSize: Math.round(parseFloat(value) * 1000) / 1000,
      sizes: oState.state.sizes
    }));
  } else if (e.target.id === 'lowqty') {
    var value = e.currentTarget.value;
    if (value[value.length - 1] === ',') {
      value = value.substr(0, value.length - 1) + '.';
    }
    if (!value) {
      value = '0';
    }
    if (!isNaN(value)) {
      if (value !== '0.0') {
        value = (Math.round(parseFloat(value) * 1000) / 1000).toString();
      }
      setState(prevState => ({
        ...prevState,
        lowqty: value
      }));
    }
  } else if (e.target.id === 'serving') {
    var value = e.currentTarget.value;
    if (value[value.length - 1] === ',') {
      value = value.substr(0, value.length - 1) + '.';
    }
    if (!value) {
      value = '0';
    }
    if (!isNaN(value)) {
      if (value !== '0.0') {
        value = (Math.round(parseFloat(value) * 1000) / 1000).toString();
      }
      setState(prevState => ({
        ...prevState,
        serving: value
      }));
    }
  } else if (e.target.id === 'code') {
    setState(prevState => ({
      ...prevState,
      code: e.currentTarget.value
    }));
  } else if (e.target.id === 'description') {
    setState(prevState => ({
      ...prevState,
      description: e.currentTarget.value
    }));
  } else if (e.target.id === 'name') {
    if (e.currentTarget.value.length === 1) {
      e.currentTarget.value = e.currentTarget.value.toUpperCase();
    }
    setState(prevState => ({
      ...prevState,
      name: e.currentTarget.value
    }));
  } else if (e.target.id === 'buyPrice') {
    var value = formaterCheck(e.currentTarget.value || '0');
    if (value >= 0) {
      setState(prevState => ({
        ...prevState,
        packBuyPrice: parseFloat(value)
      }));
    }
    // var check = false;
    // if (!value) {
    //   value = '0';
    // }
    // if (value[value.length - 1] === ',' || value[value.length - 1] === '.') {
    //   value = value.replace(',','').replace('.','');
    //   check = true;
    // }
    // if (!value.includes('.0')) {
    //   value = (Math.round(value * 1000) / 1000).toString();
    // }
    // if (check && !value.includes('.')) {
    //   value = value + '.';
    // }
    // const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    // if (!isNaN(parseFloat(value) && reg.test(value)) || value === '') {
    //   setState(prevState => ({
    //     ...prevState,
    //     packBuyPrice: value
    //   }));
    // }
  }
};

const switchStockSize = () => {
  var sw = !oState.state.stockSize;
  var sizes = oState.state.sizes;
  if (sw) {
    var dimensions = Object.assign([], dimSelectTypes[oState.state.dimension]);
    var dimType = oState.state.dimType
      ? oState.state.dimType
      : dimensions[0]
      ? dimensions[0].value
      : '';
    if (oState.state.packSize > 1) {
      dimensions.unshift({
        name:
          oState.state.packSize +
          (dimSelectTypesFormat[oState.state.dimType]
            ? dimSelectTypesFormat[oState.state.dimType]
            : oState.state.dimType),
        value: 'packsize'
      });
      dimType = 'packsize';
    }
    var sizeObject = {
      dimension: oState.state.dimension,
      dimType: dimType,
      qty: 1,
      dimtypes: dimensions,
      name: ''
    };
    if (dimensions[0].value === 'packsize') {
      sizeObject.packSize = true;
    }
    sizes.push(sizeObject);
  } else {
    sizes = [];
  }
  setState(prevState => ({
    ...prevState,
    stockSize: sw,
    sizes: sizes
  }));
};

const addSizeInput = () => {
  var sizes = oState.state.sizes;
  var dimensions = Object.assign([], dimSelectTypes[oState.state.dimension]);
  var dimType = oState.state.dimType
    ? oState.state.dimType
    : dimensions[0]
    ? dimensions[0].value
    : '';
  if (oState.state.packSize > 1) {
    dimensions.unshift({
      name:
        oState.state.packSize +
        (dimSelectTypesFormat[oState.state.dimType]
          ? dimSelectTypesFormat[oState.state.dimType]
          : oState.state.dimType),
      value: 'packsize'
    });
    dimType = 'packsize';
  }
  var sizeObject = {
    dimension: oState.state.dimension,
    dimType: dimType,
    qty: 1,
    dimtypes: dimensions,
    name: ''
  };
  if (dimensions[0].value === 'packsize') {
    sizeObject.packSize = true;
  }
  sizes.push(sizeObject);
  setState(prevState => ({
    ...prevState,
    sizes: sizes
  }));
};

const removeSizeInput = eN => {
  var e = eN;
  var sizes = oState.state.sizes;
  if (oState.state.sizes.length > 1) {
    sizes.splice(e, 1);
    setState(prevState => ({
      ...prevState,
      sizes: sizes
    }));
  }
};

const onChangeSizeInt = (eN, iN) => {
  var e = eN;
  var i = iN;
  const newIds = oState.state.sizes.slice();
  newIds[i].name = e.currentTarget.value;
  setState(prevState => ({
    ...prevState,
    sizes: newIds
  }));
};

const onChangeEvtSizeQTY = (eN, iN) => {
  var e = eN;
  var i = iN;
  const newIds = oState.state.sizes.slice();
  if (e.currentTarget.value) {
    newIds[i].qty = parseFloat(e.currentTarget.value);
  }
  setState(prevState => ({
    ...prevState,
    sizes: newIds
  }));
};

const handleSelectStockSizeDim = (eN, iN) => {
  var e = eN;
  var i = iN;
  let { value } = e.target;
  const newIds = oState.state.sizes.slice();
  if (value === 'packsize') {
    newIds[i].packSize = true;
  } else {
    newIds[i].packSize = false;
  }
  newIds[i].dimType = value;
  setState(prevState => ({
    ...prevState,
    sizes: newIds
  }));
};

let setState;
let setAlert;
let oState = {};
let alert = {};

const Stockitemsform = allprops => {
  oState = {
    state: allprops.state,
    props: allprops.props,
    submitForm: allprops.submitForm,
    handleOpenStock: allprops.handleOpenStock,
    onHelpMarker: allprops.onHelpMarker,
    handleDeleteStockItem: allprops.handleDeleteStockItem,
    handleRestoreStockItem: allprops.handleRestoreStockItem,
    handleChange: handleChange,
    onChangeEvt: onChangeEvt,
    switchStockSize: switchStockSize,
    addSizeInput: addSizeInput,
    removeSizeInput: removeSizeInput,
    onChangeSizeInt: onChangeSizeInt,
    onChangeEvtSizeQTY: onChangeEvtSizeQTY,
    handleSelectStockSizeDim: handleSelectStockSizeDim
  };

  [oState.state, setState] = useState(oState.state);

  useEffect(() => {
    // componentDidMount();
  }, []);
  const StyledStatistic = styled(Statistic)`
    .ant-statistic-title {
      position: absolute;
      padding-right: 1em;
      /* top: -1px; */
      /*box-shadow: 0 6px 4px -2px rgb(220, 225, 238);*/
      margin-bottom: -0.7em;
      padding-bottom: ${window.isPhone ? '8px' : '0.3em'};
      /* border-bottom:5px solid grey;*/
      color: black;
      font-weight: 600;
    }
  `;
  return (
    <Card>
      {' '}
      <Button
        style={{
          position: 'fixed',
          right: '-0.6em',
          bottom: '5em',
          background: 'rgb(0, 204, 112)',
          border: '1px solid transparent',
          color: 'white',
          padding: '0em',
          width: '3.5em',
          height: '3.5em',
          borderRadius: '5em 0em 0em 5em',
          fontSize: '1em',
          zIndex: 1000
        }}
        onClick={() => oState.submitForm(oState.state)}
        color="primary"
      >
        <Icon style={{ paddingLeft: 3 }} type="check" />{' '}
        <h1 style={{ fontSize: 0 }}> {oState.state.userlistbutton} </h1>
      </Button>
      {/*} <CardHeader
        style={{
          borderRadius: '0.5em',
          background: 'rgb(30, 161, 169)'
        }}
      >
        {' '}
        <h4
          style={{ color: 'white' }}
          className={oState.props.classes.cardTitleWhite}
        >
          {oState.state.userlisthead}
        </h4>
        <p
          style={{ color: 'rgba(255,255,255,.62)' }}
          className={oState.props.classes.cardCategoryWhite}
        >
          {oState.state.userlistsub}
        </p>
      </CardHeader> */}
      <CardBody />
      <CardBody style={{ marginTop: '-4em' }}>
        {oState.state.userlistbutton === 'EDIT STOCK ITEM' ? (
          <div
            style={{ position: 'absolute', right: '10px', marginTop: '1em' }}
          >
            {oState.state.active ? (
              <Tooltip placement="top" title={'Delete Item From Stock List'}>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    oState.handleDeleteStockItem(oState.state);
                  }}
                  style={{
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '15px'
                  }}
                >
                  <Button
                    style={{
                      textTransform: 'initial',
                      border: '1.5px solid red',
                      marginLeft: '2px',
                      color: 'red',
                      padding: '1em',
                      width: '3.5em',
                      backgroundColor: 'white',
                      borderRadius: '3em'
                    }}
                  >
                    <Icon
                      type="delete"
                      style={{
                        marginTop: '-0.1em',
                        marginRight: '-0.2em',
                        fontSize: '16px',
                        color: 'red'
                      }}
                    />
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={'Restore Item To Stock List'}>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    oState.handleRestoreStockItem(oState.state);
                  }}
                  style={{
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '15px'
                  }}
                >
                  <Button
                    style={{
                      border: '1px solid green',
                      marginLeft: '2px',
                      color: 'green',
                      backgroundColor: 'transparent',
                      borderRadius: '3em',
                      width: '8.5em'
                    }}
                  >
                    {
                      <Icon
                        type="rollback"
                        style={{
                          marginTop: '-0.2em',
                          fontSize: '16px',
                          color: 'green'
                        }}
                      />
                    }
                    Restore
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        ) : null}
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Name*"
              id="name"
              autoFocus={true}
              error={!oState.state.name && oState.state.submitInProgress}
              errorText={'Item name required'}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemname'),
                value: oState.state.name
              }}
            />
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Description*"
              id="description"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemdesc'),
                value: oState.state.description
              }}
            />
            </GridItem> */}
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Type*"
              id="Type"
              error={!oState.state.type && oState.state.submitInProgress}
              errorText={'Item Type required'}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.handleChange(e),
                onClick: () => oState.onHelpMarker('stockitemtype'),
                value: oState.state.type
              }}
              menuItems={oState.state.types}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            {oState.state.userlistbutton === 'EDIT STOCK ITEM' ? (
              <GridContainer>
                <GridItem
                  style={{
                    margin: 'auto',
                    marginTop: 28,
                    marginBottom: '-3em',
                    marginLeft: '20px'
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <h1
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '22px',
                      fontWeight: 700,
                      fontFamily: 'Roboto',
                      color: '#5D656F'
                    }}
                  >
                    {' '}
                    <img
                      src={RocketIconPic}
                      style={{
                        width: '1em',
                        marginRight: '0.4em'
                      }}
                    />
                    Stats
                  </h1>
                </GridItem>
                <GridItem
                  style={{
                    margin: 'auto',
                    marginBottom: '-13em',
                    marginLeft: '30px',
                    borderRadius: '0.7em',
                    boxShadow: 'rgb(229, 233, 242) 0px 5px 10px 2px',
                    minWidth: '20em',
                    maxWidth: '20em'
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <StyledStatistic
                    style={{
                      minWidth: '16em',
                      padding: '1em',
                      paddingTop: '1em',
                      paddingBottom: '9em',
                      background: 'white'
                    }}
                    className="stat-display-card-stock-value"
                    title={
                      <span style={{ fontSize: '20px', fontFamily: 'Roboto' }}>
                        Stock on Hand
                        <img
                          src={StockPic}
                          style={{
                            width: '2em',
                            position: 'absolute',
                            right: '-3.6em'
                          }}
                        />
                        <br />
                        <p
                          style={{
                            fontFamily: 'Roboto',
                            color: 'lightgrey',
                            fontWeight: 100,
                            fontSize: '16px'
                          }}
                        >
                          Existing Usable Stock
                        </p>
                        <img
                          src={CirclesPic}
                          style={{
                            position: 'absolute',
                            right: '-3.2em',
                            width: '1.5em',
                            marginTop: '-1.6em',
                            clip: 'rect(0px,50px,88px,0px)'
                          }}
                        />
                      </span>
                    }
                    value={oState.state.qty}
                    valueStyle={{
                      color: 'rgb(0, 204, 112)',
                      fontSize: '30px',
                      textAlign: 'right',
                      //fontFamily: 'Montserrat,sans-serif',
                      position: 'absolute',
                      marginLeft: 0,
                      marginTop: '2.2em',
                      whiteSpace: 'nowrap',
                      fontWeight: 600
                    }}
                    suffix={
                      dimSelectTypesFormat[oState.state.dimType]
                        ? dimSelectTypesFormat[oState.state.dimType]
                        : oState.state.dimType === 'unit'
                        ? 'Units'
                        : oState.state.dimType || ''
                    }
                  />
                </GridItem>
              </GridContainer>
            ) : null}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="SKU"
              id="code"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemcode'),
                value: oState.state.code
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelectMulti
              labelText="Suppliers"
              id="supplier"
              valueObject={oState.state.oSupplier || false}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                open: oState.state.multiOpen,
                value: oState.state.supplier,
                onChange: e => oState.handleChange(e),
                onClick: () => oState.onHelpMarker('stockitemtype'),
                onClose: e =>
                  setState(prevState => ({
                    ...prevState,
                    multiOpen: false
                  })),
                onOpen: e =>
                  setState(prevState => ({
                    ...prevState,
                    multiOpen: true
                  }))
              }}
              menuItems={oState.state.suppliers || []}
            />
          </GridItem>
          {oState.state.userlistbutton === 'EDIT STOCK ITEM' ? (
            <GridItem
              style={{
                margin: 'auto',
                marginBottom: '-15em',
                marginLeft: '30px',
                borderRadius: '0.7em',
                boxShadow: 'rgb(229, 233, 242) 0px 5px 10px 2px',
                minWidth: '20em',
                maxWidth: '20em'
              }}
              xs={12}
              sm={12}
              md={3}
            >
              <StyledStatistic
                style={{
                  minWidth: '16em',
                  padding: '1em',
                  paddingTop: '1em',
                  paddingBottom: '9em',
                  background: 'white'
                }}
                className="stat-display-card-stock-value"
                title={
                  <span style={{ fontSize: '20px', fontFamily: 'Roboto' }}>
                    Stock Value
                    <img
                      src={StockPic}
                      style={{
                        width: '2em',
                        position: 'absolute',
                        right: '-0.5em'
                      }}
                    />
                    <br />
                    <p
                      style={{
                        fontFamily: 'Roboto',
                        color: 'lightgrey',
                        fontWeight: 100,
                        fontSize: '17px'
                      }}
                    >
                      Total Value of Existing Stock
                    </p>
                    <img
                      src={RocketPic}
                      style={{
                        position: 'absolute',
                        right: '-1.1em',
                        width: '3.5em',
                        marginTop: '-0.85em',
                        clip: 'rect(0px,90px,71px,0px)'
                      }}
                    />
                  </span>
                }
                value={(
                  (oState.state.qty || 0) * (oState.state.buyPrice || 0)
                ).toFixed('2')}
                valueStyle={{
                  color: 'rgb(0, 204, 112)',
                  fontSize: '30px',
                  textAlign: 'right',
                  //fontFamily: 'Montserrat,sans-serif',
                  position: 'absolute',
                  marginLeft: 0,
                  marginTop: '2.2em',
                  whiteSpace: 'nowrap',
                  fontWeight: 600
                }}
                prefix="R "
              />
            </GridItem>
          ) : null}
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <CustomSelect
              labelText="Dimension*"
              id="dimension"
              error={!oState.state.dimension && oState.state.submitInProgress}
              errorText={'Item Dimension required'}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.handleChange(e),
                onClick: () => oState.onHelpMarker('stockitemtype'),
                value: oState.state.dimension
              }}
              menuItems={[
                {
                  name: 'Volume',
                  value: 'volume'
                },
                {
                  name: 'Mass',
                  value: 'mass'
                },
                {
                  name: 'Length',
                  value: 'length'
                },
                {
                  name: 'Each',
                  value: 'unit'
                }
              ]}
            />
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
            <CustomInput
              labelText={
                oState.state.dimension && oState.state.dimension === 'unit'
                  ? 'Cost Price Each'
                  : 'Cost Price'
              }
              id="buyPrice"
              formControlProps={{
                fullWidth: true
              }}
              showPrefix={'R'}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitembuy'),
                defaultValue: oState.state.packBuyPrice
              }}
            />
          </GridItem>
          {/*oState.state.dimension && oState.state.dimension !== 'unit' ? (
            <div style={{ marginTop: '45px', fontSize: '17px' }}>Per</div>
          ) : null*/}
          {oState.state.dimension && oState.state.dimension !== 'unit' ? (
            <GridItem xs={4} sm={4} md={4}>
              <InputGroup>
                <CustomInput
                  labelText="Package Size"
                  id="packsize"
                  showPrefix={'Per'}
                  formControlProps={{
                    style: { width: '60%' },
                    fullWidth: false
                  }}
                  inputProps={{
                    onChange: e => oState.onChangeEvt(e),
                    onClick: () => oState.onHelpMarker('stockitembuy'),
                    value: oState.state.packSize.toString(),
                    type: 'number'
                  }}
                />
                <CustomSelect
                  labelText="Unit*"
                  id="dimentiontype"
                  formControlProps={{
                    style: { width: '40%' },
                    fullWidth: false
                  }}
                  inputProps={{
                    disabled: oState.state.dimension === 'unit' ? true : false,
                    onChange: e => oState.handleChange(e),
                    onClick: () => oState.onHelpMarker('stockitemtype'),
                    value: oState.state.dimType
                  }}
                  menuItems={oState.state.dimtypes}
                />
              </InputGroup>
            </GridItem>
          ) : oState.state.dimension && oState.state.dimension === 'unit' ? (
            <div>
              {' '}
              {/*
            <GridItem xs={2} sm={2} md={2}>
              <InputGroup>
                <CustomInput
                  labelText="Package Size"
                  id="packsize"
                  formControlProps={{
                    style: { width: '55%' },
                    fullWidth: false
                  }}
                  inputProps={{
                    disabled: oState.state.dimType === 'unit' ? true : false,
                    onChange: e => oState.onChangeEvt(e),
                    onClick: () => oState.onHelpMarker('stockitembuy'),
                    value: oState.state.packSize.toString(),
                    type: 'number'
                  }}
                />
                <CustomSelect
                  labelText="Dim Type"
                  id="dimentiontype"
                  formControlProps={{
                    style: { width: '45%' },
                    fullWidth: false
                  }}
                  inputProps={{
                    onChange: e => oState.handleChange(e),
                    onClick: () => oState.onHelpMarker('stockitemtype'),
                    value: oState.state.dimType
                  }}
                  menuItems={oState.state.dimtypes}
                />
              </InputGroup>
            </GridItem>
                */}
            </div>
          ) : null}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {oState.state.dimension ? (
              <div>
                {oState.state.userlistbutton === 'EDIT STOCK ITEM' &&
                !oState.state.dimChangeCheck ? (
                  <GridContainer>
                    <GridItem xs={3} sm={3} md={3}>
                      <InputGroup>
                        <CustomInput
                          labelText="Serving Size"
                          id="serving"
                          formControlProps={{
                            style: { width: '60%' },
                            fullWidth: false
                          }}
                          inputProps={{
                            onChange: e => oState.onChangeEvt(e),
                            onClick: () => oState.onHelpMarker('stockitemhand'),
                            value: oState.state.serving
                          }}
                        />
                        {oState.state.servingType ? (
                          <CustomSelect
                            labelText="Unit"
                            id="servingtype"
                            formControlProps={{
                              style: { width: '40%' },
                              fullWidth: false
                            }}
                            inputProps={{
                              disabled:
                                oState.state.dimension &&
                                oState.state.dimension === 'unit'
                                  ? true
                                  : false,
                              onChange: e => oState.handleChange(e),
                              onClick: () =>
                                oState.onHelpMarker('stockitemtype'),
                              value: oState.state.servingType
                            }}
                            menuItems={oState.state.dimtypes}
                          />
                        ) : null}
                      </InputGroup>
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <CustomInput
                        labelText="Re-Order Level"
                        id="lowqty"
                        showSufix={oState.state.dimType || ''}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => oState.onChangeEvt(e),
                          onClick: () => oState.onHelpMarker('stockitemhand'),
                          value: oState.state.lowqty
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem xs={2} sm={2} md={2}>
                      <CustomInput
                        labelText="Stock on Hand"
                        id="qty"
                        showSufix={
                          dimSelectTypesFormat[oState.state.dimType]
                            ? dimSelectTypesFormat[oState.state.dimType]
                            : oState.state.dimType === 'unit'
                            ? 'Units'
                            : oState.state.dimType || ''
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => oState.onChangeEvt(e),
                          onClick: () => oState.onHelpMarker('stockitemhand'),
                          value: oState.state.qty
                        }}
                      />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <InputGroup>
                        <CustomInput
                          labelText="Serving Size"
                          id="serving"
                          formControlProps={{
                            style: { width: '60%' },
                            fullWidth: false
                          }}
                          inputProps={{
                            onChange: e => oState.onChangeEvt(e),
                            onClick: () => oState.onHelpMarker('stockitemhand'),
                            value: oState.state.serving
                          }}
                        />
                        {oState.state.servingType ? (
                          <CustomSelect
                            labelText="Unit"
                            id="servingtype"
                            formControlProps={{
                              style: { width: '40%' },
                              fullWidth: false
                            }}
                            inputProps={{
                              disabled:
                                oState.state.dimension &&
                                oState.state.dimension === 'unit'
                                  ? true
                                  : false,
                              onChange: e => oState.handleChange(e),
                              onClick: () =>
                                oState.onHelpMarker('stockitemtype'),
                              value: oState.state.servingType
                            }}
                            menuItems={oState.state.dimtypes}
                          />
                        ) : null}
                      </InputGroup>
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <CustomInput
                        labelText="Re-Order Level"
                        id="lowqty"
                        showSufix={oState.state.dimType || ''}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => oState.onChangeEvt(e),
                          onClick: () => oState.onHelpMarker('stockitemhand'),
                          value: oState.state.lowqty
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              </div>
            ) : null}
          </GridItem>
        </GridContainer>
        <GridContainer>
          {oState.state.dimension ? (
            <GridItem
              style={{
                marginLeft: '1em',
                marginTop: '1em',
                whiteSpace: 'nowrap'
              }}
              xs={3}
              sm={3}
              md={3}
            >
              <FormControlLabel
                control={
                  <Switch
                    style={{ marginRight: '1em' }}
                    checked={oState.state.stockSize}
                    onChange={oState.switchStockSize}
                  />
                }
                label={
                  <span style={{ color: 'rgb(0, 204, 112)' }}>
                    <span style={{ color: 'black' }}>Product</span> Pack Sizes
                  </span>
                }
              />
              <Tooltip
                placement="top"
                title={
                  'Pack sizes allow you to buy or sell products in custom units, such as a case or a carton.'
                }
              >
                <IconButton
                  style={{
                    left: '-0.5em'
                  }}
                >
                  <Icon
                    type="info-circle"
                    style={{
                      fontSize: '17px'
                    }}
                  />
                </IconButton>
              </Tooltip>
            </GridItem>
          ) : null}
        </GridContainer>
        {oState.state.stockSize ? <Inputs {...oState} /> : null}
        <CardFooter
          style={{
            marginLeft: '-0.4em',
            display: 'block',
            borderTop: '1px solid rgba(229, 233, 242, 0.9)',
            marginTop: '0.8em'
          }}
        >
          <button
            style={{
              width: '10em',
              marginTop: '1em',
              backgroundColor: '#00CC70',
              color: 'white',
              paddingTop: '0.6em',
              paddingBottom: '0.6em',
              boxShadow: 'none',
              marginLeft: '1em',
              float: 'right',
              border: '1px solid #00CC70',
              borderRadius: '3.5em'
            }}
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                submitInProgress: true
              }));
              oState.submitForm(oState.state);
            }}
            color="primary"
          >
            {oState.state.userlistbutton
              .toLowerCase()
              .replace(/^(.)|\s+(.)/g, c => c.toUpperCase())}
          </button>
          <Button
            style={{
              fontSize: '13.2px',
              marginTop: '1.15em',
              textTransform: 'initial',
              float: 'right',
              border: '1px solid rgba(229, 233, 242, 0.9)',
              borderRadius: '3.5em',
              color: 'black',
              backgroundColor: 'rgba(229, 233, 242, 0.9)',
              boxShadow: 'none'
            }}
            onClick={() => oState.handleOpenStock(false)}
            color="primary"
          >
            Cancel
          </Button>
        </CardFooter>
      </CardBody>
    </Card>
  );
};

const MiniFormBody = allprops => {
  oState = {
    ...allprops,
    handleChange: handleChange,
    onChangeEvt: onChangeEvt,
    switchStockSize: switchStockSize,
    addSizeInput: addSizeInput,
    removeSizeInput: removeSizeInput,
    onChangeSizeInt: onChangeSizeInt,
    onChangeEvtSizeQTY: onChangeEvtSizeQTY,
    handleSelectStockSizeDim: handleSelectStockSizeDim
  };

  [oState.state, setState] = useState(oState.state);

  useEffect(() => {}, []);

  return (
    <Card className={oState.props.classes.cardExpand}>
      <CardHeader style={{ height: '3em', background: 'rgb(30, 161, 169)' }}>
        {' '}
        <div style={{ display: 'inline-flex' }}>
          <h4 className={oState.props.classes.cardTitleWhite}>
            {'Add Stock Item'}
          </h4>
        </div>
        <Button
          onClick={() =>
            oState.handleCookingSelectFilter(
              null,
              oState.modKey,
              oState.cookInsKey,
              oState.stockKey
            )
          }
          color="primary"
          style={{
            textTransform: 'initial',
            backgroundColor: 'white',
            color: 'rgb(30, 161, 169)',
            borderRadius: '1em',
            float: 'right',
            height: '30px',
            bottom: '0.5em'
          }}
        >
          Cancel
        </Button>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} style={{ marginTop: '-2.5em' }}>
            <CustomInput
              labelText="Item Name*"
              id="stockname"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.name
              }}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6} style={{ marginTop: '-2.5em' }}>
            <CustomSelect
              labelText="Type*"
              id="Type"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.handleChange(e),
                onClick: () => oState.onHelpMarker('stockitemtype'),
                value: oState.state.type
              }}
              menuItems={oState.state.types}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4} style={{ marginTop: '-2.5em' }}>
            <CustomInput
              labelText="SKU"
              id="code"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.code
              }}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4} style={{ marginTop: '-2.5em' }}>
            <CustomInput
              labelText="Initial Stock*"
              id="qty"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.qty
              }}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4} style={{ marginTop: '-2.5em' }}>
            <CustomSelect
              labelText="Dimension*"
              id="dimension"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.handleChange(e),
                onClick: () => oState.onHelpMarker('stockitemtype'),
                value: oState.state.dimension
              }}
              menuItems={[
                {
                  name: 'Volume',
                  value: 'volume'
                },
                {
                  name: 'Mass',
                  value: 'mass'
                },
                {
                  name: 'Unit',
                  value: 'unit'
                }
              ]}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export { MiniFormBody, Stockitemsform };
