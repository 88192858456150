import moment from 'moment';
const stockreport = (items, startDate, endDate) => {
  let tableBody = [];
  tableBody.push([
    { text: 'Name', color: 'white', bold: true },
    { text: 'Type', color: 'white', bold: true },
    { text: 'Opening Stock', color: 'white', bold: true },
    { text: 'Sold', color: 'white', bold: true },
    { text: 'Sold Stock Value', color: 'white', bold: true },
    { text: 'Closing Stock', color: 'white', bold: true },
    { text: 'Retail Price', color: 'white', bold: true },
    { text: 'Total Retail', color: 'white', bold: true }
  ]);
  items.forEach(record => {
    let lineItem = [];
    lineItem.push({ text: record.name, bold: true });
    lineItem.push({ text: record.type, bold: true });
    lineItem.push({
      text:
        (((record.qty ? record.qty : 0) + record.stockUsed)
          .toFixed('2')
          .includes('.00')
          ? ((record.qty ? record.qty : 0) + record.stockUsed).toFixed('0')
          : ((record.qty ? record.qty : 0) + record.stockUsed).toFixed('2')) +
        ' ' +
        (record.dimType ? record.dimType : record.dimension),
      bold: true
    });
    lineItem.push({
      text:
        ((record.stockUsed ? record.stockUsed : 0).toFixed('2').includes('.00')
          ? (record.stockUsed ? record.stockUsed : 0).toFixed('0')
          : (record.stockUsed ? record.stockUsed : 0).toFixed('2')) +
        ' ' +
        (record.dimType ? record.dimType : record.dimension),
      bold: true
    });
    lineItem.push({
      text: (
        (record.buyPrice ? parseFloat(record.buyPrice) : 0) *
        (record.stockUsed ? record.stockUsed : 0)
      ).toFixed('2'),
      bold: true
    });
    lineItem.push({
      text:
        ((record.qty ? record.qty : 0).toFixed('2').includes('.00')
          ? (record.qty ? record.qty : 0).toFixed('0')
          : (record.qty ? record.qty : 0).toFixed('2')) +
        ' ' +
        (record.dimType ? record.dimType : record.dimension),
      bold: true
    });
    lineItem.push({
      text: record.sellPrice
        ? (record.sellPrice ? record.sellPrice : 0).toFixed('2').includes('.00')
        : '0.00',
      bold: true
    });
    lineItem.push({
      text: record.sellPrice
        ? (record.stockUsed ? record.sellPrice * record.stockUsed : 0)
            .toFixed('2')
            .includes('.00')
        : '0.00',
      bold: true
    });
    tableBody.push(lineItem);
  });
  return {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    content: [
      {
        text: 'Stock Report ',
        style: 'header'
      },
      {
        text:
          moment(startDate, 'YYYYMMDD').format('YYYY/MM/DD') +
          ' - ' +
          moment(endDate, 'YYYYMMDD').format('YYYY/MM/DD'),
        style: 'subheader'
      },
      '\n\n',
      {
        style: 'tableExample',
        table: {
          widths: [
            'auto',
            'auto',
            'auto',
            'auto',
            'auto',
            'auto',
            'auto',
            'auto'
          ],
          heights: [20],
          headerRows: 1,
          body: tableBody
        },
        layout: {
          hLineWidth: function(i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function(i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 0;
          },
          // paddingTop: function(i, node) {
          //     return (i === 0) ? 8 : 2;
          // },
          fillColor: function(rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#132440' : null;
          }
        }
      }
    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'center'
      },

      subheader: {
        fontSize: 14,
        bold: true,
        alignment: 'center'
      }
    }
  };
};
export default stockreport;
