import { Button, Col, Input, Layout, Menu, Row } from 'antd';

import React, { memo } from 'react';

import styled from 'styled-components';
const { Header, Content } = Layout;
const { SubMenu } = Menu;
const { Search } = Input;
const StyledSearch = styled(Search)`
  .ant-input {
    border: 1px solid rgba(15, 135, 123, 0.725);
    border-radius: 0.4em;
    color: black !important;
  }
  .ant-input::placeholder {
    opacity: 0.4;
    color: grey;
  }
`;
const AdminHead = ({ ...oState }) => {
  return (
    <Header
      className="kitchen-header-mobile"
      style={{
        height: 40,
        lineHeight: '40px',
        backgroundColor: 'white',
        zIndex: 100,
        margin: 'auto',
        textAlign: 'center',
        position: 'fixed',

        borderRadius: '3em',
        padding: '0 0px',
        background: '#132440',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.205)',
        marginTop: 0
      }}
    >
      <Row
        style={{
          height: 40,
          width: '100vw',
          maxWidth: '40em',
          textAlign: 'center',
          margin: 'auto'
        }}
      >
        <Col span={6}>
          <Button
            className="kitchen-button-mobile"
            style={{
              fontWeight: 700,
              width: '95%',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '3em'
            }}
            onClick={() => oState.onFilterClick('open')}
          >
            Pending{' '}
            {oState.state.opencount > 0
              ? '(' + oState.state.opencount + ')'
              : ''}
          </Button>{' '}
        </Col>{' '}
        <Col span={6}>
          <Button
            className="kitchen-button-mobile"
            style={{
              fontWeight: 700,
              width: '95%',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '3em'
            }}
            onClick={() => oState.onFilterClick('proc')}
          >
            Busy
            {oState.state.proccount > 0
              ? '(' + oState.state.proccount + ')'
              : ''}
          </Button>
        </Col>{' '}
        <Col span={6}>
          <Button
            className="kitchen-button-mobile"
            style={{
              fontWeight: 700,
              width: '95%',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '3em'
            }}
            onClick={() => oState.onFilterClick('comp')}
          >
            Completed{' '}
            {oState.state.compcount > 0
              ? '(' + oState.state.compcount + ')'
              : ''}
          </Button>
        </Col>{' '}
        <Col span={6}>
          <Button
            className="kitchen-button-mobile"
            style={{
              fontWeight: 700,
              width: '95%',
              marginLeft: '0.2em',
              marginRight: '0.2em',
              borderRadius: '3em'
            }}
            onClick={() => oState.onFilterClick('void')}
          >
            Rejected{' '}
            {oState.state.voidcount > 0
              ? '(' + oState.state.voidcount + ')'
              : ''}
          </Button>
        </Col>
      </Row>
    </Header>
  );
};
const AdminHeader = memo(AdminHead);
export default AdminHeader;
