/* eslint-disable no-loop-func */
import { Button, Col, Layout, List, Radio, Row, notification } from 'antd';
import $ from 'jquery';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/components/Layout/Dashboard/sales-screen.css';
const apiCall = 'vpos/api/customer/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const { Content } = Layout;
const changeView = view => {
  setView(view.target.value);
};
let currentOrders = [];
let setCurrentOrders;
let pastOrders = [];
let setPastOrders;
let view = 'current';
let setView;
const trackOrder = (item, props) => {
  props.setBill(item);
  if (item.state === 'delivery' && item.driverId) {
    props.navToView('track');
  } else {
    props.navToView('status');
  }
};
const returnStatus = bill => {
  if (bill.state === 'await confirmation' || bill.state === 'open')
    return 'Awaiting order confirmation';
  if (bill.state === 'await payment') return 'Awaiting payment';
  if (bill.state === 'paid') return 'Payment confirmed';
  if (bill.state === 'preparing') return 'Order being prepared';
  if (bill.state === 'delivery' && bill.driverId && bill.address !== 'Collect')
    return 'Out for delivery';
  if (bill.state === 'delivery') return 'Order being prepared';
  if (bill.state === 'collect') return 'Ready for collection';
  if (bill.state === 'void') return 'Order Rejected';
};
const MyOrders = props => {
  [currentOrders, setCurrentOrders] = useState(currentOrders);
  [pastOrders, setPastOrders] = useState(pastOrders);
  [view, setView] = useState(view);
  useEffect(() => {
    setPastOrders([]);
    setCurrentOrders([]);
    $.ajax({
      type: 'GET',
      url: server + apiCall + 'getmyorders',
      dataType: 'json',
      crossDomain: true,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
      },
      success: async data => {
        setPastOrders(data.results.pastOrders);
        setCurrentOrders(data.results.currentOrders);
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          notification.error({
            message: 'Error',
            description: xhr.responseText
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Error retrieving list'
          });
        }
      }
    });
    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white', paddingBottom: 170 }}>
      <Row>
        <Col span={24}>
          <Radio.Group
            id="RadioSelect"
            value={view}
            onChange={e => changeView(e)}
            style={{ width: '100%' }}
            buttonStyle="solid"
          >
            <Radio.Button
              style={{
                paddingTop: '0.2em',
                textAlign: 'center',
                width: '50%',
                height: '2.5em',
                borderRadius: 0,
                border: 'none'
              }}
              value="current"
            >
              Current Orders
            </Radio.Button>
            <Radio.Button
              style={{
                paddingTop: '0.2em',
                textAlign: 'center',
                width: '50%',
                height: '2.5em',

                borderRadius: 0,

                border: 'none'
              }}
              value="past"
            >
              Past Orders
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {view === 'current' ? (
        <Row>
          <List
            dataSource={currentOrders}
            renderItem={item => (
              <List.Item
                style={{
                  padding: '1em',
                  backgroundColor: 'white',
                  marginBottom: '0.5em',
                  boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.205)'
                }}
                key={item._id}
              >
                <List.Item.Meta
                  id="MenuItemName"
                  title={item.orgName}
                  description={
                    <div>
                      <p>
                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                          {' '}
                          Date:
                        </span>{' '}
                        {moment(item.date, 'YYYYMMDDHHmm').format(
                          'YYYY/MM/DD HH:mm'
                        )}
                      </p>

                      <p>
                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                          Order no:
                        </span>{' '}
                        {item.orderno}
                      </p>
                      <p>
                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                          Status:
                        </span>{' '}
                        {returnStatus(item)}
                      </p>
                      <p style={{ fontWeight: 'bold', color: 'black' }}>
                        Total: R {item.total.toFixed(2)}
                      </p>
                    </div>
                  }
                />
                <div style={{ display: 'grid' }}>
                  <Row type="flex" justify="space-around">
                    <span
                      style={{
                        margin: 'auto',
                        marginTop: '8em',
                        fontSize: '1.2em'
                      }}
                    >
                      {item && item.state !== 'void' ? (
                        <Button
                          style={{
                            color: 'white',
                            height: '3em',
                            width: '8em',
                            borderRadius: '3em',
                            backgroundColor: '#00CC70'
                          }}
                          onClick={e => trackOrder(item, props)}
                        >
                          Track Order
                        </Button>
                      ) : null}
                    </span>
                  </Row>
                </div>
              </List.Item>
            )}
          />
        </Row>
      ) : (
        <Row>
          <List
            dataSource={pastOrders}
            renderItem={item => (
              <List.Item
                onClick={e => props.navToView('orderDetails', null, item)}
                style={{
                  padding: '1em',
                  backgroundColor: 'white',
                  marginBottom: '0.5em',
                  boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.205)'
                }}
                key={item._id}
              >
                <List.Item.Meta
                  id="MenuItemName"
                  title={item.orgName}
                  description={
                    <div>
                      <p>
                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                          {' '}
                          Date:
                        </span>{' '}
                        {moment(item.date, 'YYYYMMDDHHmm').format(
                          'YYYY/MM/DD HH:mm'
                        )}
                      </p>
                      <p>
                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                          Order no:
                        </span>{' '}
                        {item.orderno}
                      </p>
                    </div>
                  }
                />
                <div style={{ display: 'grid' }}>
                  <Row type="flex" justify="space-around">
                    <span
                      style={{
                        margin: 'auto',
                        marginTop: '0.7em',
                        fontSize: '1.2em',
                        color: 'black'
                      }}
                    >
                      {item.state === 'void' ? 'Rejected' : 'Complete'}
                    </span>
                  </Row>
                </div>
              </List.Item>
            )}
          />
        </Row>
      )}
    </Content>
  );
};

export default MyOrders;
