import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon, Input, Select, Spin, Switch, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import { getEmailSettings, saveEmailSettings } from 'src/redux/actions/backend';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';

import { setHelpMarker } from 'src/redux/actions/help';

import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
const { TextArea } = Input;
const { Option } = Select;
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    margin: 'auto',
    color: 'grey',

    borderRadius: '0.2em'
  },
  cardExpandBody: {
    margin: '0px'
  },
  cardExpandTop: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    height: '4.5em',
    marginTop: '0%',
    marginBottom: '0px'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  headingText: {
    fontSize: '20px',
    paddingTop: '10px'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '43px',
    marginLeft: '-90%',
    width: '80%'
  },
  checkboxtop: {
    display: 'inlineBlock',
    paddingTop: '50px'
  }
});

const times = [
  {
    value: '01',
    name: '01:00'
  },
  {
    value: '02',
    name: '02:00'
  },
  {
    value: '03',
    name: '03:00'
  },
  {
    value: '04',
    name: '04:00'
  },
  {
    value: '05',
    name: '05:00'
  },
  {
    value: '06',
    name: '06:00'
  },
  {
    value: '07',
    name: '07:00'
  },
  {
    value: '08',
    name: '08:00'
  },
  {
    value: '09',
    name: '09:00'
  },
  {
    value: '10',
    name: '10:00'
  },
  {
    value: '11',
    name: '11:00'
  },
  {
    value: '12',
    name: '12:00'
  },
  {
    value: '13',
    name: '13:00'
  },
  {
    value: '14',
    name: '14:00'
  },
  {
    value: '15',
    name: '15:00'
  },
  {
    value: '16',
    name: '16:00'
  },
  {
    value: '17',
    name: '17:00'
  },
  {
    value: '18',
    name: '18:00'
  },
  {
    value: '19',
    name: '19:00'
  },
  {
    value: '20',
    name: '20:00'
  },
  {
    value: '21',
    name: '21:00'
  },
  {
    value: '22',
    name: '22:00'
  },
  {
    value: '23',
    name: '23:00'
  },
  {
    value: '00',
    name: '00:00'
  }
];
let changeCheck = false;
class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remail: '',
      rhour: '',
      report: false,
      semail: '',
      shour: '',
      lemail: '',
      lhour: '',
      stock: false,
      cashup: false,
      cemail: '',
      loading: true,
      userlisthead: 'Email Settings',
      userlistsub: 'Email Report Settings',
      alert: false,
      msg: [],
      activeFilter: true
    };
  }

  componentDidMount() {
    this.getEmailSettings();
  }

  componentWillUnmount() {
    if (changeCheck) {
      changeCheck = false;
      this.props.openSnackbar('You have unsaved changes');
      return;
    }
  }

  getEmailSettings = msg => {
    const token = this.props.local.user.token;
    changeCheck = false;
    this.props
      .getEmailSettings(token)
      .then(result => {
        var remail = '';
        var semail = '';
        var lemail = '';
        var cemail = '';
        if (result.data.results.length > 0) {
          if (result.data.results[0].remail) {
            for (let i = 0; i < result.data.results[0].remail.length; i++) {
              remail += result.data.results[0].remail[i];
              if (i < result.data.results[0].remail.length - 1) {
                remail += ';';
              }
            }
          }
          if (result.data.results[0].semail) {
            for (let i = 0; i < result.data.results[0].semail.length; i++) {
              semail += result.data.results[0].semail[i];
              if (i < result.data.results[0].semail.length - 1) {
                semail += ';';
              }
            }
          }
          if (result.data.results[0].lemail) {
            for (let i = 0; i < result.data.results[0].lemail.length; i++) {
              lemail += result.data.results[0].lemail[i];
              if (i < result.data.results[0].lemail.length - 1) {
                lemail += ';';
              }
            }
          }
          if (result.data.results[0].cemail) {
            for (let i = 0; i < result.data.results[0].cemail.length; i++) {
              cemail += result.data.results[0].cemail[i];
              if (i < result.data.results[0].cemail.length - 1) {
                cemail += ';';
              }
            }
          }
        }
        this.setState({
          lemail: lemail,
          lhour:
            result.data.results.length > 0
              ? result.data.results[0].lhour
              : this.state.lhour,
          remail: remail,
          rhour:
            result.data.results.length > 0
              ? result.data.results[0].rhour
              : this.state.rhour,
          report:
            result.data.results.length > 0
              ? result.data.results[0].report
              : this.state.report,
          semail: semail,
          shour:
            result.data.results.length > 0
              ? result.data.results[0].shour
              : this.state.shour,
          stock:
            result.data.results.length > 0
              ? result.data.results[0].stock
              : this.state.stock,
          lowstock:
            result.data.results.length > 0
              ? result.data.results[0].lowstock
              : this.state.lowstock,
          cashup:
            result.data.results.length > 0
              ? result.data.results[0].cashup
              : this.state.cashup,
          cemail: cemail,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  saveEmailSettings = () => {
    const token = this.props.local.user.token;
    this.props
      .saveEmailSettings(this.state, token)
      .then(result => {
        changeCheck = false;
        this.setState({
          loading: false
        });
        this.props.openSnackbar('Save Complete');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Printer Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onChangeEvtMenu(e) {
    changeCheck = true;
    if (e.target.id === 'remail') {
      this.setState({ remail: e.currentTarget.value });
    }
    if (e.target.id === 'semail') {
      this.setState({ semail: e.currentTarget.value });
    }
    if (e.target.id === 'lemail') {
      this.setState({ lemail: e.currentTarget.value });
    }
    if (e.target.id === 'cemail') {
      this.setState({ cemail: e.currentTarget.value });
    }
  }

  onChange = e => {
    changeCheck = true;
    this.setState({ report: !this.state.report });
  };

  onChangeStock = e => {
    changeCheck = true;
    this.setState({ stock: !this.state.stock });
  };

  onChangeLowStock = e => {
    changeCheck = true;
    this.setState({ lowstock: !this.state.lowstock });
  };

  onChangeCashup = e => {
    changeCheck = true;
    this.setState({ cashup: !this.state.cashup });
  };

  handleChange = (event, key) => {
    changeCheck = true;
    if (event.target.name) {
      this.setState({
        rhour: event.target.value
      });
    }
  };

  handleChangeStock = (event, key) => {
    changeCheck = true;
    if (event.target.name) {
      this.setState({
        shour: event.target.value
      });
    }
  };

  handleChangeLowStock = (event, key) => {
    changeCheck = true;
    if (event.target.name) {
      this.setState({
        lhour: event.target.value
      });
    }
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  render() {
    const oState = {
      that: this,
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      onHelpMarker: this.onHelpMarker
    };

    return (
      <Spin spinning={this.state.loading}>
        <AlertDialog {...oState} />
        <Card className={this.props.classes.cardExpand}>
          {/*}  <CardHeader
            style={{
              borderRadius: "0.5em",
              background:
                "linear-gradient(90deg, rgba(38,125,117,1) 0%, rgba(12,152,139,1) 35%, rgba(19,173,159,1) 100%)",
            }}
          >
            {" "}
            <h4
              className={this.props.classes.cardTitleWhite}
              style={{ color: "white" }}
            >
              {this.state.userlisthead}
            </h4>
            <p
              style={{ color: "rgba(255,255,255,.62" }}
              className={this.props.classes.cardCategoryWhite}
            >
              {this.state.userlistsub}
            </p>
          </CardHeader> */}
          <CardBody style={{ marginLeft: '8%' }}>
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                <FormControlLabel
                  style={{ marginTop: '3.6em', whiteSpace: 'nowrap' }}
                  control={
                    <Switch
                      style={{ marginRight: '0.5em' }}
                      id="report"
                      onChange={this.onChange}
                      checked={this.state.report}
                    />
                  }
                  label="Daily Sales Report"
                />
              </GridItem>
              <GridItem xs={11} sm={11} md={3}>
                <CustomInput
                  labelText="Email"
                  id="remail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onClick: () => this.onHelpMarker('menuitemsname'),
                    onChange: e => this.onChangeEvtMenu(e),
                    value: this.state.remail
                  }}
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <Tooltip
                  placement="top"
                  title={
                    'Use the semicolon (;) character to separate multiple email addresses'
                  }
                >
                  <IconButton
                    style={{ marginTop: '1.8em', marginLeft: '-1.2em' }}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        fontSize: '17px',
                        color: 'grey'
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </GridItem>
              <GridItem xs={11} sm={11} md={3}>
                <CustomSelect
                  labelText="Set time"
                  id="emailtime"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onClick: () => this.onHelpMarker('menuitems'),
                    onChange: (e, key) => this.handleChange(e, key),
                    value: this.state.rhour
                  }}
                  menuItems={times}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                <FormControlLabel
                  style={{ marginTop: '3.6em', whiteSpace: 'nowrap' }}
                  control={
                    <Switch
                      style={{ marginRight: '0.5em' }}
                      id="stock"
                      onChange={this.onChangeStock}
                      checked={this.state.stock}
                    />
                  }
                  label="Daily Stock Report"
                />
              </GridItem>
              <GridItem xs={11} sm={11} md={3}>
                <CustomInput
                  labelText="Email"
                  id="semail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onClick: () => this.onHelpMarker('menuitemsname'),
                    onChange: e => this.onChangeEvtMenu(e),
                    value: this.state.semail
                  }}
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <Tooltip
                  placement="top"
                  title={
                    'Use the semicolon (;) character to separate multiple email addresses'
                  }
                >
                  <IconButton
                    style={{ marginTop: '1.8em', marginLeft: '-1.2em' }}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        fontSize: '17px',
                        color: 'grey'
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </GridItem>
              <GridItem xs={11} sm={11} md={3}>
                <CustomSelect
                  labelText="Set time"
                  id="emailtimestock"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onClick: () => this.onHelpMarker('menuitems'),
                    onChange: (e, key) => this.handleChangeStock(e, key),
                    value: this.state.shour
                  }}
                  menuItems={times}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                <FormControlLabel
                  style={{
                    marginTop: '3.6em',
                    whiteSpace: 'nowrap'
                  }}
                  control={
                    <Switch
                      style={{ marginRight: '0.5em' }}
                      id="lowstock"
                      onChange={this.onChangeLowStock}
                      checked={this.state.lowstock}
                    />
                  }
                  label="Daily Low Stock Report"
                />
              </GridItem>
              <GridItem xs={11} sm={11} md={3}>
                <CustomInput
                  labelText="Email"
                  id="lemail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onClick: () => this.onHelpMarker('menuitemsname'),
                    onChange: e => this.onChangeEvtMenu(e),
                    value: this.state.lemail
                  }}
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <Tooltip
                  placement="top"
                  title={
                    'Use the semicolon (;) character to separate multiple email addresses'
                  }
                >
                  <IconButton
                    style={{ marginTop: '1.8em', marginLeft: '-1.2em' }}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        fontSize: '17px',
                        color: 'grey'
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </GridItem>
              <GridItem xs={11} sm={11} md={3}>
                <CustomSelect
                  labelText="Set time"
                  id="emailtimelstock"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onClick: () => this.onHelpMarker('menuitems'),
                    onChange: (e, key) => this.handleChangeLowStock(e, key),
                    value: this.state.lhour
                  }}
                  menuItems={times}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                <FormControlLabel
                  style={{ marginTop: '3.6em', whiteSpace: 'nowrap' }}
                  control={
                    <Switch
                      style={{ marginRight: '0.5em' }}
                      id="cashup"
                      onChange={this.onChangeCashup}
                      checked={this.state.cashup}
                    />
                  }
                  label="Cashup Report"
                />
              </GridItem>
              <GridItem xs={11} sm={11} md={3}>
                <CustomInput
                  labelText="Email"
                  id="cemail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onClick: () => this.onHelpMarker('menuitemsname'),
                    onChange: e => this.onChangeEvtMenu(e),
                    value: this.state.cemail
                  }}
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <Tooltip
                  placement="top"
                  title={
                    'Use the semicolon (;) character to separate multiple email addresses'
                  }
                >
                  <IconButton
                    style={{ marginTop: '1.8em', marginLeft: '-1.2em' }}
                  >
                    <Icon
                      type="info-circle"
                      style={{
                        fontSize: '17px',
                        color: 'grey'
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={10} sm={10} md={10}>
                <Button
                  style={{
                    marginTop: '2.5em',
                    float: 'right',
                    borderRadius: '3em',
                    backgroundColor: '#00CC70',

                    color: 'white'
                  }}
                  onClick={() => this.saveEmailSettings()}
                  className={this.props.classes.buttonAddInput}
                >
                  Save Settings
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  menuitems: state.menuitems
});

const mapDispatchToProps = {
  getEmailSettings,
  saveEmailSettings,
  openSnackbar,
  closeSnackbar,
  setHelpMarker
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Email));
