import moment from 'moment';
const stockreport = (items) => {
    let tableBody = [];
    let length = items[0].length;
    let headers = [];
    let headerWidths = [];
    let width = (500 / (length - 1));
    for (var a = 0; a < length; a++) {
        headers.push({ text: items[0][a], color: "white", bold: true })
        headerWidths.push( a ? width : 200);
    }
    tableBody.push(headers)
    for (var b = 1; b < items.length; b++) {
        let lineItem = [];
        for (var c = 0; c < length; c++) {
            lineItem.push({ text: items[b][c], bold: true })
        }
        tableBody.push(lineItem)
    };
    return {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
            {
                text: "Stock Report ",
                style: "header",
            },
            {
                text: moment().format("YYYY/MM/DD"),
                style: "subheader",
            },
            "\n\n",
            {
                style: 'tableExample',
                table: {
                    widths: headerWidths,
                    heights: [20],
                    headerRows: 1,
                    body: tableBody
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 0;
                    },
                    // paddingTop: function(i, node) { 
                    //     return (i === 0) ? 8 : 2; 
                    // },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? "#132440" : null;
                    }
                }
            },

        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center"
            },
            subheader: {
                fontSize: 14,
                bold: true,
                alignment: "center"
            }
        },
    }
};
export default stockreport
