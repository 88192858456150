import React, { useState, useEffect } from 'react';
import {
  Icon,
  Tooltip,
  Menu,
  Popover,
  Row,
  Col,
  Button,
  Avatar,
  Badge,
  List as AntList
} from 'antd';
import styled, { css, keyframes } from 'styled-components';
import sidebarImg from 'src/static/sidebar-3.jpg';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Style from 'src/components/Layout/Admin/Style';
import Logo from 'src/static/vpos-logo-white-1.png';
import VLogo from 'src/static/vpos-logo-white1.png';
import AdminRoutes from 'src/config/AdminRoutes';
import HelpMarkers from 'src/config/Help';
import zIndex from '@material-ui/core/styles/zIndex';
import {
  BsChatDots
} from 'react-icons/bs';
import { FiBell } from 'react-icons/fi';
import './Admin.css';
var Routes = '';
var heading = '';
const getRoutes = routes => {
  Routes = routes;
};
const { SubMenu } = Menu;
class SideMenu extends React.Component {
  rootSubmenuKeys = Object.keys(Routes);
  state = {
    openKeys: []
  };
  // componentDidMount = () => {
  //   if (this.props.state.expired) {
  //     this.props.props.props.history.push("/admin/billing")
  //   }
  //   if (window.location.search.search("completed") > -1 || window.location.search.search("cancelled") > -1) {
  //     this.props.props.props.history.push("/admin/billing")
  //   }
  // }
  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  };
  renderSettings = () => {
    let main = Routes['mainSettings'].name;
    if (!this.props.mode[main]) {
      for (var prop in this.props.mode) {
        if (prop.split(" - ")[1]) {
          if (prop.split(" - ")[0] === main) {
            main = prop
          }
        }
      }
    }
    if (this.props.mode.groups) {
      this.props.mode.settings = true;
    }
    if ((this.props.mode["settings"] && Routes['mainSettings'].sub) || (this.props.mode[main] && Routes['mainSettings'].sub)) {
      return (
        <SubMenu style={{ marginTop: "1em" }}
          key={'settings'}
          title={
            <span>
              {Routes['mainSettings'].icon ? (
                <Icon style={iconStyle} type={Routes['mainSettings'].icon} />
              ) : (
                <Icon
                  component={Routes['mainSettings'].customicon}
                  alt="Icon"
                  style={{
                    opacity: 1,
                    fontSize: '24px',
                    paddingRight: '-0.1em'
                  }}
                />
              )}
              <span style={menuTextStyle}>{Routes['mainSettings'].name}</span>
            </span>
          }
        >
          {Routes['mainSettings'].subs.map(link => {
            let subShouldRender = true;
            let main = Routes['mainSettings'].name;
            if (!this.props.mode[main]) {
              subShouldRender = false;
            }
            for (var prop in this.props.mode) {
              if (prop.split(" - ")[1]) {
                if (prop.split(" - ")[1] === link.name) {
                  subShouldRender = true;
                }
              }
            }

            if (this.props.mode['settings'] || subShouldRender) {
              return (
                <Menu.Item key={link.name}>
                  {!link.sublink ? (
                    <span onClick={link.open}>
                      <Icon style={iconStyle} type={link.icon} />

                      <span style={menuTextStyle}>{link.name}</span>
                    </span>
                  ) : (
                    <NavLink
                      key={link.name}
                      to={link.path}
                      activeClassName="active"
                      onClick={
                        () => {
                          this.props.props.props.resetActionBreadCrumb()
                          this.props.props.props.setBreadCrumbs([])
                          if (window.isPhone) {
                            this.props.props.handleDrawerClose()
                          }
                        }
                      }
                    >
                      <span>
                        {link.icon ? (
                          <Icon style={iconStyle} type={link.icon} />
                        ) : (
                          <Icon
                            component={link.customicon}
                            alt="Icon"
                            style={{
                              opacity: 1,
                              fontSize: '25px',
                              paddingRight: '-0.1em'
                            }}
                          />
                        )}
                        <span style={menuTextStyle}>{link.name}</span>
                      </span>
                    </NavLink>
                  )}
                </Menu.Item>
              );
            } else {
              return null;
            }

          })}
        </SubMenu>
      );
    }
  }
  render() {
    const iconStyle = {
      color: '#00CC70',
      fontSize: 18,
      verticalAlign: "-0.15em",
    };
    const menuTextStyle = {
      letterSpacing: '.2px',
      marginLeft: '0.3em',
      color: 'white',
      fontSize: '1rem',
      verticalAlign: "-0em",
      height: "50px",
      position: 'relative',

    };
    if (this.props.state.expired) {
      for (var prop in Routes) {
        Routes[prop].shouldRender = false;
        Routes[prop].shouldRenderPhone = false;
      }
    }
    return (
      <div
        style={{
          width: this.props.state.open ? window.isPhone ? 80 : 266 : window.isPhone ? 0 : 80,
          transition: "all 0.3s",
          background: 'white',
          position: 'fixed',
          overflow: "hidden",
          zIndex: 9
        }}
      >
        <Menu
          mode="inline"
          style={{
            height: '100vh',
            backgroundColor: 'rgb(17, 20, 26)',
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
            border: 'none',
            overflow: "hidden"
          }}
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          inlineCollapsed={window.isPhone ? true : !this.props.state.open}

        >

          {this.props.state.open ? (
            <div style={{backgroundColor:"rgba(0, 204, 112, 0.898)"}} className={this.props.classes.drawerHeader}>
              {' '}
              <img
                src={Logo}
                alt="Logo"
                style={{
                  maxWidth: '100px',
                  margin: 'auto'
                }}
              />
            </div>
          ) : (
            <div style={{backgroundColor:"rgba(0, 204, 112, 0.898)"}} className={this.props.classes.drawerHeader}>

              <img
                src={VLogo}
                alt="Logo"
                style={{
                  margin: 'auto'
                }}
              />
            </div>
          )}

          {Object.keys(Routes).map(key => {
            if ((!window.isPhone && Routes[key].shouldRender) || (window.isPhone && Routes[key].shouldRenderPhone)) {
              let main = Routes[key].name;
              if (!this.props.mode[main]) {
                for (var prop in this.props.mode) {
                  if (prop.split(" - ")[1]) {
                    if (prop.split(" - ")[0] === main) {
                      main = prop
                    }
                  }
                }
              }
              if (this.props.mode.groups) {
                this.props.mode.settings = true;
              }
              if ((this.props.mode[key] && Routes[key].sub) || (this.props.mode[main] && Routes[key].sub) || (key === "ecommerce" && this.props.props.props.user.ecommerceActive)) {
                return (
                  <SubMenu style={{ marginTop: "1em" }}
                    key={key}
                    title={
                      <span>
                        {Routes[key].icon ? (
                          <Icon style={iconStyle} type={Routes[key].icon} />
                        ) : (
                          <Icon
                            component={Routes[key].customicon}
                            alt="Icon"
                            style={{
                              opacity: 1,
                              fontSize: '25px',
                              paddingRight: '-0.1em'
                            }}
                          />
                        )}
                        <span style={menuTextStyle}>{Routes[key].name}</span>
                      </span>
                    }
                  >
                    {Routes[key].subs.map(link => {
                      let subShouldRender = true;
                      let main = Routes[key].name;
                      if (!this.props.mode[key] && !this.props.mode[main]) {
                        subShouldRender = false;
                      }
                      for (var prop in this.props.mode) {
                        if (prop.split(" - ")[1]) {
                          if (prop.split(" - ")[1] === link.name) {
                            subShouldRender = true;
                          }
                        }
                      }
                      if ((window.isPhone && link.shouldRenderPhone) || (key === "ecommerce" && this.props.props.props.user.ecommerceActive)) {
                        subShouldRender = true;
                      }
                      if (!window.isPhone && link.ecommerceLink && !this.props.props.props.user.ecommerceActive ) {
                        subShouldRender = false;
                      }
                      if ((!window.isPhone && subShouldRender) || (window.isPhone && subShouldRender && link.shouldRenderPhone)) {
                        return (
                          <Menu.Item key={link.name}>
                            {!link.sublink ? (
                              <span onClick={link.open}>
                                <Icon style={iconStyle} type={link.icon} />
                                <span style={menuTextStyle}>{link.name}</span>
                              </span>
                            ) : (
                              <NavLink
                                key={link.name}
                                to={link.path}
                                activeClassName="active"
                                onClick={
                                  () => {
                                    
                                    if (window.isPhone) {
                                      this.props.props.handleDrawerClose()
                                    } else {
                                      this.props.props.props.resetActionBreadCrumb()
                                      this.props.props.props.setBreadCrumbs([])
                                    }
                                  }
                                }
                              >
                                <span>
                                  {link.icon ? (
                                    <Icon style={iconStyle} type={link.icon} />
                                  ) : (
                                    <Icon
                                      component={link.customicon}
                                      alt="Icon"
                                      style={{
                                        opacity: 1,
                                        fontSize: '25px',
                                        paddingRight: '-0.1em'
                                      }}
                                    />
                                  )}
                                  <span style={menuTextStyle}>{link.name}</span>
                                </span>
                              </NavLink>
                            )}
                          </Menu.Item>
                        );
                      } else {
                        return null;
                      }

                    })}
                  </SubMenu>
                );
              } else if (this.props.mode[key] || this.props.mode[main]) {
                return (
                  <Menu.Item key={key}>
                    <NavLink
                      to={Routes[key].path}
                      activeClassName="active"
                      key={Routes[key].path}
                      onClick={
                        () => {
                          this.props.props.props.resetActionBreadCrumb()
                          this.props.props.props.setBreadCrumbs([])
                          if (window.isPhone) {
                            this.props.props.handleDrawerClose()
                          }
                        }
                      }
                    >
                      <span>
                        {Routes[key].icon ? (
                          <Icon style={iconStyle} type={Routes[key].icon} />
                        ) : (
                          <Icon
                            component={Routes[key].customicon}
                            alt="Icon"
                            style={{
                              opacity: 1,
                              fontSize: '27px',
                              paddingRight: '-0.1em'
                            }}
                          />
                        )}
                        <span style={menuTextStyle}>{Routes[key].name}</span>
                      </span>
                    </NavLink>
                  </Menu.Item>
                );
              }
            }
          })}
          {this.props.props.props.user.deliveryActive ?
            <Menu.Item key={Routes['kitchen'].name}>
              <NavLink
                key={Routes['kitchen'].name}
                to={Routes['kitchen'].path}
                activeClassName="active"
                onClick={
                  () => {
                    this.props.props.props.resetActionBreadCrumb()
                    this.props.props.props.setBreadCrumbs([])
                    if (window.isPhone) {
                      this.props.props.handleDrawerClose()
                    }
                  }
                }
              >
                <Badge
                  style={{
                    position: 'absolute',
                    marginTop: '-1.5em',
                    marginRight: '-0.5em'
                  }}
                  count={this.props.props.props.orderCount}
                />
                {Routes['kitchen'].icon ? (
                  <Icon style={iconStyle} type={Routes['kitchen'].icon}>
                    {' '}
                  </Icon>
                ) : (
                  <Icon
                    component={Routes['kitchen'].customicon}
                    alt="Icon"
                    style={{
                      opacity: 1,
                      fontSize: '25px',
                      paddingRight: '-0.1em'
                    }}
                  />
                )}
                <span style={menuTextStyle}>{Routes['kitchen'].name}</span>
              </NavLink>
            </Menu.Item> : null}
          {!window.isPhone ? this.renderSettings() : null}

          <div style={{ position: "absolute", display: "flex", width: "100%", bottom: 20 }} key={Routes['logoutorg'].name}>

            <HeaderComponent props={this.props.props} />


            {/* <NavLink
              key={Routes['logoutorg'].name}
              to={Routes['logoutorg'].path}
              onClick={() => this.props.props.handleLogout()}
              activeClassName="active"
            >
              <span>
                {Routes['logoutorg'].icon ? (
                  <Icon style={iconStyle} type={Routes['logoutorg'].icon} />
                ) : (
                    <Icon
                      component={Routes['logoutorg'].customicon}
                      alt="Icon"
                      style={{

                        verticalAlign: '-4em',
                        opacity: 1,
                        fontSize: '27px',
                      
                        paddingRight: '-0.1em'
                      }}
                    />
                  )}
                <span style={menuTextStyle}>{Routes['logoutorg'].name}</span>
              </span>
            </NavLink> */}
          </div>
          {window.location.pathname.search('user') === -1 ? (
            <div
              style={{
                margin: "auto",
                textAlign: "center",
                position: "absolute",
                bottom: "10px",
                width: "100%",
                /* left: 5px; */
                fontSize: "9px",
                color: "lightgrey",
                zIndex: 9999999,
              }}
            >
              {process.env.REACT_APP_VERSION_CONTROL}
            </div>
          ) : null}
        </Menu>
      </div>
    );
  }
}

const MenuComponent = ({ props }) => {
  const { classes, Mode } = props.props;
  return (
    <SideMenu props={props} state={props.state} classes={classes} mode={Mode} />
  );
};

const MenuComponentLogout = ({ props }) => {
  const { classes } = props.props;
  return (
    <List>
      <Tooltip
        placement="topLeft"
        title={'Logout User'}
        overlayStyle={{ zIndex: 9999999 }}
      >
        <NavLink
          to={Routes['logout'].path}
          className={classes.item}
          activeClassName="active"
          key={Routes['logout'].path}
        >
          <ListItem button onClick={() => props.handleLogout()}>
            <Icon className={classes.itemIcon} type={Routes['logout'].icon} />
            <ListItemText
              primary={Routes['logout'].name}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      </Tooltip>
    </List>
  );
};

const MenuComponentLogoutOrg = ({ props }) => {
  const { classes } = props.props;
  return (
    <List>
      <Tooltip
        placement="topLeft"
        title={'Logout Organisation'}
        overlayStyle={{ zIndex: 9999999 }}
      >
        <NavLink
          to={Routes['logoutorg'].path}
          className={classes.item}
          activeClassName="active"
          key={Routes['logoutorg'].path}
        >
          <ListItem button onClick={() => props.handleLogoutOrg()}>
            <Icon
              className={classes.itemIcon}
              type={Routes['logoutorg'].icon}
            />
            <ListItemText
              primary={Routes['logoutorg'].name}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      </Tooltip>
    </List>
  );
};

const MenuComponentSubs = ({ props }) => {
  const { classes } = props.props;
  var subs = props.state.subs;

  return (
    <List>
      {subs.map(link => (
        <div key={link.name}>
          {!link.sublink ? (
            <ListItem
              key={link.name}
              button
              className={classes.item}
              onClick={link.open}
            >
              <Icon className={classes.itemIcon} type={link.icon} />
              <ListItemText primary={link.name} disableTypography={true} />
            </ListItem>
          ) : (
            <NavLink
              key={link.name}
              to={link.path}
              className={classes.item}
              activeClassName="active"
            >
              <ListItem button>
                <Icon className={classes.itemIcon} type={link.icon} />
                <ListItemText
                  style={{ padding: 0 }}
                  primary={link.name}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          )}
        </div>
      ))}
    </List>
  );
};

const HeaderComponent = ({ props }) => {
  const getInitials = function (sName) {
    var names = sName.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  return (
    <Popover
      placement="topRight"
      content={
        <div>
          <Row type="flex" justify="space-between" align="middle" style={{ zIndex: 9999999999 }}>
            <Col span={6}>
              <div
                // onClick={e => this.setState({ imageVisible: true })}
                style={{
                  cursor: 'pointer',
                  height: 48,
                  width: 49,
                  margin: 'auto',
                  borderRadius: '50%',
                 background: 'rgb(0, 204, 112)',
                  display: 'flex'
                }}
              >
                <span
                  style={{
                    margin: 'auto',
                    paddingBottom: 0,
                    fontSize: 15,
                    color: 'white',
                    fontWeight: 700
                  }}
                >
                  {getInitials(props.props.user.name)}
                </span>
              </div>
            </Col>
            <Col span={17}>
              <div style={{ margin: '0px 0px 8px 0px', width: 250 }}>
                <span style={{ fontSize: 13 }}>{props.props.user.name}</span>
              </div>

              <Button
                style={{ height: 25 }}
                onClick={e => {
                  props.setProfileVisible(false);
                  props.props.history.push(Routes.profile.path);
                  if (window.isPhone) {
                    props.handleDrawerClose()
                  }
                }}
                type="primary"
              >
                My Profile
                </Button>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginTop: 20 }}
          >
            <Menu style={{ backgroundColor: 'white' }} theme="light" mode="inline">
              {/*}  <Menu.Item onClick={() => props.handleSwitch()} key="switch">
                  <Icon type="poweroff" />
                  <span>Switch to Kitchen</span>
                </Menu.Item> */}
              <Menu.Item style={{ backgroundColor: 'white' }} onClick={() => props.handleLogoutOrg()} key="signout">
                <Icon type="poweroff" />
                <span>Sign Out Org</span>
              </Menu.Item>
            </Menu>
          </Row>
        </div>
      }
      trigger="click"
      onVisibleChange={e => props.setProfileVisible(e)}
      visible={props.state.profileVisible}
    >
      <div
        // onClick={e => this.setState({ imageVisible: true })}
        style={{
          margin: 'auto',
          cursor: 'pointer',
          height: 50,
          width: 52,
          textAlign: 'center',
          borderRadius: '50%',
          border: ' 2px solid rgb(0, 204, 112)',
          display: 'flex'
        }}
      >
        <span
          style={{
            margin: 'auto',
            paddingBottom: 2,
            fontSize: "1rem",
            color: 'rgb(0, 204, 112)',
            fontWeight: 700
          }}
        >
          {getInitials(props.props.user.name)}
        </span>

      </div>

    </Popover>

  );
};

const Notifications = ({ props }) => {
  if (window.isPhone) {
    return (
      <Popover
        style={{}}
        className="phone-notifications"
        placement="bottomRight"
        title="Notifications"
        content={
          <div>
            <a
              onClick={props.clearNotifications}
              style={{
                position: 'absolute',
                right: 10,
                top: 15,
                display: props.state.notifications.length === 0 ? 'none' : 'block'
              }}
            >
              Clear All
            </a>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={24}>
                <AntList
                  locale={{ emptyText: 'No Notifications' }}
                  pagination={{
                    onChange: page => {
                      console.log(page);
                    },
                    pageSize: 5
                  }}
                  itemLayout="horizontal"
                  dataSource={props.state.notifications}
                  renderItem={item => (
                    <AntList.Item
                      style={{ cursor: 'pointer' }}
                      data={JSON.stringify(item)}
                      onClick={props.handleNotificationClick}
                    >
                      <Col span={5}>
                        <div
                          style={{
                            cursor: 'pointer',
                            height: 35,
                            width: 35,
                            margin: 'auto',
                            marginLeft: '0px',
                            borderRadius: '50%',
                            background: '#132440',
                            display: 'flex'
                          }}
                        >
                          <span
                            style={{
                              margin: 'auto',
                              marginLeft: '6px',
                              lineHeight: '0px',
                              fontSize: 20,
                              color: 'white'
                            }}
                          >
                            <Icon type="notification" />
                          </span>
                        </div>
                      </Col>
                      <AntList.Item.Meta
                        title={<div><p style={{ margin: 0, width: "max-content" }}>{item.message.timeStamp}</p><p style={{ margin: 0, width: "max-content" }}>{item.message.sentBy}</p></div>}
                        description={item.message.message}
                      />
                    </AntList.Item>
                  )}
                />
              </Col>
            </Row>
          </div>
        }
        trigger="click"
      >
        <Badge
          style={{ right: 3, top: 5 }}
          count={props.state.notifications.length}
        >
          <FiBell
            style={{
              margin: 'auto',
              fontSize: 32,
              marginLeft: '0.7em',
              padding: "0.2em",
              borderRadius: "3em",
              color: " rgb(19, 36, 64)",
              backgroundColor: props.state.notifications && props.state.notifications.length > 0 ? "#00cc70e5" : "rgb(229, 233, 242 ,0.5)",
              boxShadow: "rgb(229, 233, 242) 0px 5px 10px 2px",

            }}
          />
        </Badge>
      </Popover>
    );
  } else {
    return (
      <Popover
        className={'terminal-log-tab-notifications-admin'}
        placement="bottomRight"
        title="Notifications"
        content={
          <div>
            <a
              onClick={props.clearNotifications}
              style={{
                position: 'absolute',
                right: 10,
                top: 15,
                display: props.state.notifications.length === 0 ? 'none' : 'block'
              }}
            >
              Clear All
            </a>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={24}>
                <AntList
                  locale={{ emptyText: 'No Notifications' }}
                  pagination={{
                    onChange: page => {
                      console.log(page);
                    },
                    pageSize: 5
                  }}
                  itemLayout="horizontal"
                  dataSource={props.state.notifications}
                  renderItem={item => (
                    <AntList.Item
                      style={{ cursor: 'pointer' }}
                      data={JSON.stringify(item)}
                      onClick={props.handleNotificationClick}
                    >
                      <Col span={5}>
                        <div
                          style={{
                            cursor: 'pointer',
                            height: 35,
                            width: 35,
                            margin: 'auto',
                            marginLeft: '0px',
                            borderRadius: '50%',
                            background: '#132440',
                            display: 'flex'
                          }}
                        >
                          <span
                            style={{
                              margin: 'auto',
                              marginLeft: '6px',
                              lineHeight: '0px',
                              fontSize: 20,
                              color: 'white'
                            }}
                          >
                            <Icon type="notification" />
                          </span>
                        </div>
                      </Col>
                      <AntList.Item.Meta
                        title={<div><p style={{ margin: 0, width: "max-content" }}>{item.message.timeStamp}</p><p style={{ margin: 0, width: "max-content" }}>{item.message.sentBy}</p></div>}
                        description={item.message.message}
                      />
                    </AntList.Item>
                  )}
                />
              </Col>
            </Row>
          </div>
        }
        trigger="click"
      >
        <Badge
          style={{ marginTop: '0.8em', marginRight: '-0.4em' }}
          count={props.state.notifications.length}
        > <div
          style={{

            paddingRight: "0.6em"
          }}>
            <FiBell
              style={{
                margin: 'auto',
                fontSize: window.isPhone ? 32 : 40,
                marginLeft: '0.9em',
                marginBottom: '-0.8em',
                padding: "0.2em",
                borderRadius: "3em",
                color: " rgb(19, 36, 64)",
                backgroundColor: props.state.notifications && props.state.notifications.length > 0 ? "#00cc70e5" : "rgb(229, 233, 242 ,0.5)",
                boxShadow: "rgb(229, 233, 242) 0px 5px 10px 2px",

              }}
            />
          </div>
        </Badge>
      </Popover>
    );
  }

};

const HelpIcon = ({ props }) => {
  return (
    <div>
      {/*} <IconButton
        color="inherit"
        onClick={() =>
          !props.state.openHelp
            ? props.handleDrawerOpenHelp()
            : props.handleDrawerCloseHelp()
        }
      >
        {<Icon type="question-circle" />}
      </IconButton> */}
      {/*} <Button
        onClick={() =>
          !props.state.openHelp
            ? props.handleDrawerOpenHelp()
            : props.handleDrawerCloseHelp()
        }
        color="primary" style={{ zIndex: 2, boxShadow: "none", textShadow: "none", backgroundColor: "#132440", color: 'white', borderRadius: "0.8em 0.8em 0 0 ", float: 'right', height: '35px', bottom: '0.5em', transform: "rotate(-90deg)", position: "absolute", right: "-2.15em", top: "11em", width: "7em", border: "1px solid #132440", borderBottom: "none", fontFamily: "Roboto", fontSize: "small" }}
      >
        Help
      </Button> */}
    </div>
  );
};

const HelpComponent = ({ props }) => {
  const { classes } = props.props;
  var help = HelpMarkers();
  var marker = props.props.local.help.help;

  return (
    <div style={{ width: '20vw', display: "block", textAlign: "center" }}>
      <IconButton style={{ position: "absolute", left: 0, top: 0, color: "red" }}
        color="inherit"
        onClick={() =>
          !props.state.openHelp
            ? props.handleDrawerOpenHelp()
            : props.handleDrawerCloseHelp()
        }
      >
        {<Icon type="close-circle" />}
      </IconButton>
      <div className={classes.drawerHeader} style={{ fontSize: "20px", display: "block", paddingTop: "0.6em", color: "black" }}>
        {help[marker.helpheadname] && help[marker.helpheadname].name}
      </div>
      <Divider />
      <p className={classes.helpText}>
        {help[marker.helpheadname] && help[marker.helpheadname].awnser}
      </p>
    </div>
  );
};

const BreadCrums = ({ props }) => {
  let breadCrumbs = props.props.breadCrumbs;
  let renderCrumbs = []
  Object.keys(Routes).forEach((key, index) => {
    if (Routes[key].path === props.props.history.location.pathname) {
      if (Routes[key].name !== 'Logout' &&
        Routes[key].name !== 'Sign Out') {
        if (Routes[key].main && !window.isPhone) {
          renderCrumbs.push(<span>{Routes[key].main + ' / '}</span>)
        }
        if (breadCrumbs.length > 0) {
          renderCrumbs.push(<span><a onClick={e => props.props.actionBreadCrumb(breadCrumbs[0].method)}>{Routes[key].subName || Routes[key].name}</a> / </span>)
        } else {
          renderCrumbs.push(<span>{Routes[key].subName || Routes[key].name}</span>)
        }
        breadCrumbs.forEach((crumb, index) => {
          if (breadCrumbs[index + 1] && breadCrumbs[index + 1].method) {
            renderCrumbs.push(<span><a onClick={e => props.props.actionBreadCrumb(breadCrumbs[index + 1].method)}>{crumb.crumb}</a> / </span>)
          } else {
            renderCrumbs.push(<span>{crumb.crumb}</span>)
          }
        })
      }
    }
  })
  return (
    <div>
      {renderCrumbs.map((render, index) => {
        return <span key={index}>{render}</span>
      })}
    </div>
  );
};

const shadowPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px white;
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
`;
const StyledIconButton = styled(IconButton)`
  animation: ${shadowPulse} 1.3s infinite;
  animation-iteration-count: 3;
  animation-delay: 2s;
`;
const iconStyle = {
  color: '#00CC70',
  fontSize: 18,
  verticalAlign: "-0.35em",
};
const menuTextStyle = {
  letterSpacing: '.2px',
  marginLeft: '0.3em',
  color: 'white',
  fontSize: '1rem',
  verticalAlign: "-0.27em",
  height: "50px",
  position: 'relative',

};
const DrawerL = ({ ...propstate }) => {
  const [clickedOnce, setClickedOnce] = useState(false);
  const { props, state } = propstate;
  const { classes, theme } = props;
  const { open, close, openSub, openHelp } = state;
  useEffect(() => {
    props.resetHelpMarker();
    propstate.handleDrawerCloseHelp();
  }, [props.history.location.pathname])
  return (
    <div>
      <AdminRoutes newobjectroutes={getRoutes} />
      <AppBar
        style={{ zIndex: 10, boxShadow: '0 2px 30px 2px rgba(0,0,0,.1)' }}
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar style={window.isPhone ? { padding: 0 } : null} className="top-drawer">
          {!window.isPhone ? <div className="drawer-open-circle">
            {/* {clickedOnce ? ( */}
              <IconButton
                style={{
                  position: 'absolute',
                  border: '0px solid #00CC70',
                  backgroundColor: 'transparent',
                  width: 42,
                  height: 42,
                  left: -60.7,
                  marginTop: -22,
                  paddingBottom: '1.1em'
                }}
                color="inherit"
                aria-label="Open drawer"
                onClick={propstate.handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              />
            {/* ) : (
              <StyledIconButton
                style={{
                  position: 'absolute',
                  border: '0px solid #00CC70',
                  backgroundColor: 'transparent',
                  width: 42,
                  height: 42,
                  left: -60.7,
                  marginTop: -22,
                  paddingBottom: '1.1em'
                }}
                color="inherit"
                aria-label="Open drawer"
                onClick={e => {
                  setClickedOnce(true);
                  propstate.handleDrawerOpen();
                }}
                className={classNames(classes.menuButton, open && classes.hide)}
              />
            )} */}
          </div> : <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={propstate.handleDrawerOpen}
            className={classNames(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>}
          {!window.isPhone ? <IconButton
            style={{
              border: '1.5px solid #00CC70',
              backgroundColor: 'transparent',

            }}
            onClick={propstate.handleDrawerClose}
            className={classNames(classes.iconButton, close && classes.hide)}
          >
            <Icon
              style={{
                position: 'relative',
                fontSize: 15,
                color: '#00CC70'
              }}
              type="close"
            />
          </IconButton> : <IconButton
            onClick={propstate.handleDrawerClose}
            className={classNames(
              classes.iconButton,
              close && classes.hide
            )}
          >
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>}
          <Typography
            variant="h6"
            style={{
              color: 'black',
              backgroundColor: 'transparent',
              borderRadius: '0.8em 0.8em 0em 0.8em',
              padding: '0.5em',
              paddingLeft: '0em',
              paddingRight: '1em'
            }}
            noWrap
          >
            <BreadCrums props={propstate} />
          </Typography>
          {props.local.help.help.showHelpIcon ? (
            <div className={classes.helpButton}>
              <HelpIcon props={propstate} />
            </div>
          ) : (
            <div />
          )}
          <div
            className={classes.personButton}
            style={{ zIndex: 99999, position: "absolute", right: window.isPhone ? 80 : 145 }}
            onClick={e => {
              var CryptoJS = require("crypto-js");
              var result = CryptoJS.HmacSHA256(props.user.email, "f346faec1df60450a967b8cf02c12884032d29d0")
                window.Tawk_API.setAttributes({
                    name: props.user.orgname,
                    email: props.user.email,
                    hash: result.toString()
                }, function (error) {
                  
                 });
                 window.Tawk_API.toggleVisibility();
                 window.Tawk_API.toggle();
              
            }}
          >
            <BsChatDots style={{
              margin: 'auto',
              fontSize: window.isPhone ? 32 : 40,
              marginLeft: '0.79em',
              marginBottom: window.isPhone ? '-0.15em' : '-0.05em',
              padding: "0.2em",
              borderRadius: "3em",
              color: " rgb(19, 36, 64)",
              backgroundColor: "rgb(229, 233, 242 ,0.5)",
              boxShadow: "rgb(229, 233, 242) 0px 5px 10px 2px",

            }} />
          </div>
          <div className={classes.helpButton}>
            <Notifications props={propstate} />
          </div>
          <div className={classes.personButton}>
            {/* <HeaderComponent props={propstate} /> */}
            {Routes['logoutorg'] ?

              Routes['logoutorg'].icon ? (
                <Icon onClick={() => propstate.handleLogout()} style={iconStyle} type={Routes['logoutorg'].icon} />
              ) : (
                <Icon
                  onClick={() => propstate.handleLogout()}
                  component={Routes['logoutorg'].customicon}
                  alt="Icon"
                  style={{
                    opacity: 1,
                    fontSize: '25px',
                    paddingRight: '-0.1em'
                  }}
                />
              )
              : null}
          </div>
        </Toolbar>
      </AppBar>
      <MenuComponent props={propstate} />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={openHelp}
      >
        <HelpComponent props={propstate} />
      </Drawer>
    </div>
  );
};

DrawerL.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(Style, { withTheme: true })(DrawerL);
