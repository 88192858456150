/* eslint-disable no-loop-func */
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Icon,
  Input,
  Layout,
  List,
  Row
} from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import 'src/components/Layout/Dashboard/extra.css';
import 'src/views/Customer/customer-styles.css';
import styled from 'styled-components';
const { Content } = Layout;
const { Panel } = Collapse;
let quantity = { qty: 1, total: 0 };
let setQuantity;
let itemPrice;
let item;
const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00cc70;
    border-color: #00cc70;
    border-radius: 3em;
    transform: scale(1.3);
  }
  .ant-checkbox-inner {
    background-color: #ffffff;
    border-color: lightgrey;
    border-radius: 3em;
  }
  .ant-checkbox {
    transform: scale(1.3);
    padding: 1em;
  }
  .ant-checkbox-checked::after {
    border: 1px solid rgba(30, 162, 169, 0);
  }
`;
const selectInstruction = (e, cook, instruction, props, setIsSelected) => {
  if (e.target.checked) {
    if (instruction.limit === 1) {
      let selectedCook = {};
      for (
        var i = 0;
        i < props.menu.cooking[instruction.cookid].cookIns.length;
        i++
      ) {
        for (var j = 0; j < item.cookIns.length; j++) {
          if (
            item.cookIns[j].id ===
            props.menu.cooking[instruction.cookid].cookIns[i].id
          ) {
            selectedCook[item.cookIns[j].id] = false;
            itemPrice = itemPrice - parseFloat(item.cookIns[j].price, 2);
            item.cookIns.splice(j, 1);
            break;
          }
        }
      }
      cook.selected = true;
      item.cookIns.push(cook);
      itemPrice = itemPrice + parseFloat(cook.price, 2);
      selectedCook[cook.id] = true;
      setIsSelected(prevState => ({
        ...prevState,
        ...selectedCook
      }));
      setQuantity(prevState => ({
        total: itemPrice,
        qty: prevState.qty
      }));
    } else if (instruction.limit > 1) {
      let currentlySelected = 0;
      let selectedCook = {};
      for (
        var i = 0;
        i < props.menu.cooking[instruction.cookid].cookIns.length;
        i++
      ) {
        for (var j = 0; j < item.cookIns.length; j++) {
          if (
            item.cookIns[j].id ===
            props.menu.cooking[instruction.cookid].cookIns[i].id
          ) {
            currentlySelected++;
            break;
          }
        }
      }
      if (currentlySelected < instruction.limit) {
        cook.selected = true;
        item.cookIns.push(cook);
        itemPrice = itemPrice + parseFloat(cook.price, 2);
        selectedCook[cook.id] = true;
        setIsSelected(prevState => ({
          ...prevState,
          ...selectedCook
        }));
        setQuantity(prevState => ({
          total: itemPrice,
          qty: prevState.qty
        }));
      }
    } else {
      let selectedCook = {};
      selectedCook[cook.id] = true;
      setIsSelected(prevState => ({
        ...prevState,
        ...selectedCook
      }));
      cook.selected = true;
      item.cookIns.push(cook);
      itemPrice = itemPrice + parseFloat(cook.price, 2);
      setQuantity(prevState => ({
        total: itemPrice,
        qty: prevState.qty
      }));
    }
  } else {
    for (var i = 0; i < item.cookIns.length; i++) {
      if (item.cookIns[i].id === cook.id) {
        item.cookIns.splice(i, 1);
        break;
      }
    }
    let selectedCook = {};
    selectedCook[cook.id] = false;
    setIsSelected(prevState => ({
      ...prevState,
      ...selectedCook
    }));
    itemPrice = itemPrice - parseFloat(cook.price, 2);
    setQuantity(prevState => ({
      total: itemPrice,
      qty: prevState.qty
    }));
  }
};
const increaseQuantity = props => {
  setQuantity(prevState => ({
    total: itemPrice,
    qty: prevState.qty + 1
  }));
};
const decreaseQuantity = props => {
  if (quantity.qty > 1) {
    setQuantity(prevState => ({
      total: itemPrice,
      qty: prevState.qty - 1
    }));
  }
};
const ItemInstructions = props => {
  [quantity, setQuantity] = useState(quantity);
  const [keys, setKeys] = useState([
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20
  ]);
  const [headerText, setHeaderText] = useState({});
  const [isSelected, setIsSelected] = useState({});
  if (item) item.qty = quantity.qty;
  useEffect(() => {
    let text = {};
    for (var i = 0; i < props.selectedItem.cookinslist.length; i++) {
      let subString =
        props.menu.cooking[props.selectedItem.cookinslist[i].cookid].name
          .toLowerCase()
          .substr(
            props.menu.cooking[
              props.selectedItem.cookinslist[i].cookid
            ].name.toLowerCase().length - 1,
            1
          ) !== 's'
          ? 's'
          : '';
      let name =
        props.selectedItem.cookinslist[i].limit > 1
          ? 'Choose up to ' +
            props.selectedItem.cookinslist[i].limit +
            ' ' +
            props.menu.cooking[
              props.selectedItem.cookinslist[i].cookid
            ].name.toLowerCase() +
            subString
          : props.selectedItem.cookinslist[i].limit === 1
          ? 'Select one of the following ' +
            props.menu.cooking[
              props.selectedItem.cookinslist[i].cookid
            ].name.toLowerCase() +
            subString
          : props.selectedItem.cookinslist[i].limit === 0
          ? 'Choose any of the following ' +
            props.menu.cooking[
              props.selectedItem.cookinslist[i].cookid
            ].name.toLowerCase() +
            subString
          : '';
      text[props.selectedItem.cookinslist[i].cookid] = name;
    }
    setHeaderText(text);
    itemPrice = parseFloat(props.selectedItem.sellPrice, 2);
    item = props.selectedItem;
    item.cookIns = [];
    item.qty = 1;
    setQuantity({
      total: itemPrice,
      qty: 1
    });
    return () => {};
  }, []);
  return (
    <Content style={{ marginTop: 50, background: 'white', paddingBottom: 180 }}>
      <Row
        style={{
          backgroundColor: 'white',
          margin: '1.5em',
          marginBottom: '1.5em',
          borderRadius: 5,
          border: '0.5px solid rgba(211, 211, 211, 0.658)',
          boxShadow: '2px 2px 5px 2px rgba(227,227,227,0.50)'
        }}
      >
        <List.Item
          style={{
            color: '#3b3b36e8',
            paddingLeft: '1em',
            paddingTop: '0.5em',
            paddingBottom: '0em'
          }}
        >
          <List.Item.Meta
            id="MenuItemName"
            title={props.selectedItem.name}
            description={
              <div>
                <p
                  style={{
                    color: '#3b3b36e8',
                    fontSize: '13px',
                    fontFamily: 'Roboto'
                  }}
                >
                  {props.selectedItem.description}
                </p>
              </div>
            }
          />
        </List.Item>
      </Row>
      <Row>
        <Collapse
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
          defaultActiveKey={keys}
          style={{
            backgroundColor: 'white',
            border: '0.5px solid rgba(211, 211, 211, 0.658)',
            margin: '1.5em',
            marginTop: '-1em',
            borderRadius: 5,
            boxShadow: '2px 2px 5px 2px rgba(227,227,227,0.50)'
          }}
        >
          {props.selectedItem.cookinslist.map((instruction, index) => {
            return (
              <Panel
                style={{
                  padding: '0em',
                  border: 'none',
                  borderBottom: '1px solid rgba(211, 211, 211, 0.658)',
                  borderRadius: '5px',
                  backgroundColor: 'white',
                  boxShadow: 'none',
                  color: 'rgb(56, 56, 56)'
                }}
                id="InstructionsCollapse"
                header={headerText[instruction.cookid]}
                key={index}
              >
                {props.menu.cooking[instruction.cookid].cookIns.map(cook => {
                  return (
                    <Row key={cook.name}>
                      <StyledCheckbox
                        checked={isSelected[cook.id]}
                        style={{
                          color: '#4d4d4d',
                          fontSize: '16px',
                          padding: '3px 15px',
                          fontWeight: 'bolder',
                          borderBottom: '0px solid rgb(241, 241, 241)',
                          width: '100vw'
                        }}
                        id="InstructionsChecbox"
                        onChange={e =>
                          selectInstruction(
                            e,
                            cook,
                            instruction,
                            props,
                            setIsSelected
                          )
                        }
                      >
                        {cook.name}{' '}
                        {cook.price > 0 ? (
                          <span
                            style={{
                              color: '#4d4d4d',
                              fontSize: '17px',

                              float: 'right',
                              marginRight: '4.5em',
                              marginTop: '0.5em'
                            }}
                          >
                            R {cook.price} ea
                          </span>
                        ) : null}
                      </StyledCheckbox>
                    </Row>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
      </Row>
      <Row
        style={{
          padding: '1em'
        }}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: '17px',
            fontFamily: 'Rubik, sans-serif,San Francisco, Helvetica, Arial ',
            color: '#3b3b36e8',
            width: '100%'
          }}
        >
          Special Instructions
        </span>
        <Input
          style={{
            marginTop: '0.2em',
            border: 'none',
            borderRadius: 0,
            borderBottom: '1px solid grey'
          }}
          onChange={e => (item.desc = e.currentTarget.value)}
          placeholder="Add instruction (no salt , no tamato etc.)"
        />
      </Row>
      <Row
        type="flex"
        justify="space-around"
        style={{
          paddingBottom: '0.8em',
          backgroundColor: 'white',
          boxShadow: '2px 2px 5px 2px rgba(227,227,227,0.50)',
          zIndex: 999,
          position: 'Fixed',
          width: '100%',
          bottom: 79
        }}
      >
        <Col style={{ display: 'flex' }}>
          <MdRemoveCircleOutline
            onClick={e => decreaseQuantity(props)}
            style={{
              marginLeft: '1.8em',
              marginTop: '0.4em',
              fontSize: '3.4em',
              color: 'lightgrey'
            }}
          />
        </Col>
        <Col>
          <div style={{ display: 'grid', textAlign: 'center' }}>
            <span
              style={{
                color: '#3b3b36e8',
                fontWeight: 700,

                fontSize: '14px',
                marginTop: '0.5em'
              }}
            >
              Quantity
            </span>
            <span
              style={{
                fontWeight: 500,
                fontSize: '1.8em',
                color: '#00CC70'
              }}
            >
              {quantity.qty}
            </span>
          </div>
        </Col>
        <Col style={{ display: 'flex' }}>
          <MdAddCircleOutline
            onClick={e => increaseQuantity(props)}
            style={{
              marginRight: '1.8em',
              marginTop: '0.4em',
              fontSize: '3.4em',
              color: 'lightgrey'
            }}
          />
        </Col>
      </Row>
      <Row
        style={{
          position: 'absolute',
          width: '100%'
        }}
        type="flex"
        justify="space-around"
      >
        <Button
          style={{
            zIndex: 999,
            position: 'Fixed',
            backgroundColor: '#00CC70',
            fontWeight: 700,
            height: '4.4em',
            fontFamily: 'Roboto',
            fontSize: '18px',
            color: 'white',
            width: '100%',
            bottom: 0,
            borderRadius: 0
          }}
          onClick={e => props.addItemToCart(item, quantity.total)}
        >
          Add {quantity.qty} to cart R{' '}
          {(quantity.total * quantity.qty).toFixed(2)}
        </Button>
      </Row>
    </Content>
  );
};

export default ItemInstructions;
