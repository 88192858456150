import { Badge, Col, Drawer, Input, Layout, Menu, Row } from 'antd';
import { WiDirectionLeft } from 'react-icons/wi';

import React, { memo } from 'react';
import logo from 'src/static/vpos-logo-white1.png';
import styled from 'styled-components';
const { Header, Content } = Layout;
const { SubMenu } = Menu;
const { Search } = Input;
const StyledSearch = styled(Search)`
  .ant-input {
    margin-top: 0.5em;
    width: 93%;
    margin-left: 1em;
    border: 1px solid rgba(15, 135, 123, 0.725);
    border-radius: 0.4em;
    color: black !important;
  }
  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    position: absolute;
    top: 60% !important;
  }
  .ant-input::placeholder {
    opacity: 0.8;
    color: grey;
  }
  .ant-input-clear-icon {
    font-size: 1.6em;
    color: #cc0000ef;
    padding: 0.15em 0.1em 1em 0em;
    border-radius: 0em 1em 1em 0em;
    height: 1.28em;
    margin: auto;
    background-color: #00cc702d;
    border-left: 1px solid #00cc70a6;
    margin-right: -0.48em;
    width: 2.3em;
    text-align: center;
  }
  .ant-input-search-icon {
    display: none;
  }
  .ant-input {
    border: 1px solid #00cc70;
    border-radius: 4em;
    color: white !important;
    background-color: transparent;
  }
  .ant-input::placeholder {
    color: #00cc70;
  }
`;
const StyledBadge = styled(Badge)`
  display: flex !important;
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
  }
`;
const DashHead = ({ ...oState }) => {
  return (
    <Header
      className={'sales-header-' + oState.themeMode}
      style={{
        height: 50,
        lineHeight: '50px',
        paddingLeft: 5,
        paddingRight: 5,
        marginBottom: 0
      }}
    >
      <Row style={{ height: 40 }}>
        <Col span={2} style={{ display: 'flex', height: '100%' }}>
          {(oState.itemMenuStates.createBill &&
            oState.itemMenuStates.billType) ||
          oState.currentView === 'pay' ||
          oState.receiptView ? (
            <WiDirectionLeft
              style={{ fontSize: 40, color: 'white', marginTop: '0.10em' }}
              onClick={() => {
                if (oState.backFromTakeAwayType) {
                  oState.setBackFromTakeAwayType(false);
                } else if (oState.tenderView || oState.splitView) {
                  oState.setTenderView(false);
                  oState.setSplitView(false);
                } else if (oState.currentView === 'pay') {
                  oState.setTenderView(false);
                  oState.setSplitView(false);
                  oState.setPaymentType(undefined);
                  oState.backToSales();
                  oState.navToView('bills');
                } else if (
                  oState.receiptView &&
                  oState.currentView === 'bill'
                ) {
                  oState.navToView('receipts');
                } else if (oState.receiptView) {
                  oState.backToBills();
                } else {
                  oState.billBack(true);
                }
              }}
            />
          ) : (
            <div>
              <img
                style={{
                  margin: 'auto',
                  height: '25px',
                  top: '0.535em',
                  paddingLeft: 42
                }}
                src={logo}
                alt="Logo"
              />
            </div>
          )}
        </Col>
        <Col span={20} style={{ display: 'flex' }}>
          {oState.searchActive ? (
            <StyledSearch
              placeholder="Search item"
              autoFocus
              onChange={input => oState.search(input.target.value)}
              onSearch={value => oState.search(value)}
              allowClear={true}
              style={{
                width: '82.5%',
                margin: 'auto',
                marginRight: '1.5em',
                marginTop: '0.25em'
              }}
            />
          ) : (
            <span
              style={{
                margin: 'auto',
                fontWeight: 'bold',
                fontSize: '1.15em',
                color: 'white'
              }}
            >
              {oState.itemMenuStates.createBill
                ? 'Create Bill'
                : oState.currentView === 'bills'
                ? 'My Bills'
                : oState.currentView === 'menu'
                ? 'Menu'
                : oState.bill && oState.currentView === 'bill'
                ? 'Bill #' + oState.bill.orderno
                : oState.tenderView
                ? 'Tender'
                : oState.splitView
                ? 'Split Bill'
                : oState.currentView === 'pay'
                ? 'Pay'
                : oState.receiptView
                ? 'Receipts'
                : 'My Bills'}
            </span>
          )}
        </Col>
        <Col span={2} style={{ display: 'flex', height: '100%' }}></Col>
      </Row>
    </Header>
  );
};
const PhoneDashHeader = memo(DashHead);
export default PhoneDashHeader;
