import withStyles from '@material-ui/core/styles/withStyles';
import {
  ConfigProvider,
  Empty,
  Icon,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tooltip
} from 'antd';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import Button from 'src/components/CustomButtons/Button.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import {
  addStockItem,
  deleteStockItem,
  editStockItem,
  getReports,
  getStockItems
} from 'src/redux/actions/backend';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
// import Table from 'src/views/Admin/Stockitems/Table';
import convert from 'convert-units';
import { CSVLink } from 'react-csv';
import { AiOutlineAreaChart } from 'react-icons/ai';
import {
  defaultFont,
  grayColor,
  primaryColor
} from 'src/assets/jss/material-dashboard-react.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import {
  resetActionBreadCrumb,
  setBreadCrumbs
} from 'src/redux/actions/breadcrumbs';
import { setHelpMarker } from 'src/redux/actions/help';
import { Stockitemsform } from 'src/views/Admin/Stockitems/Stockitemsform';
import stockreport from './stockreport.js';
const { Column } = Table;
const { Search } = Input;
const { Option } = Select;
const dateFormatSearch = 'YYYYMMDD';
const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardExpand: {
    marginTop: '10px',
    marginBottom: '5px',

    border: '1px solid grey',
    color: 'grey',

    borderRadius: '0.2em'
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  }
});

const dimSelectTypes = {
  volume: [
    {
      name: 'L',
      value: 'l'
    },
    {
      name: 'ml',
      value: 'ml'
    }
  ],
  mass: [
    {
      name: 'Kg',
      value: 'kg'
    },
    {
      name: 'g',
      value: 'g'
    }
  ],
  length: [
    {
      name: 'm',
      value: 'm'
    },
    {
      name: 'cm',
      value: 'cm'
    },
    {
      name: 'mm',
      value: 'mm'
    }
  ],
  unit: []
};
const dimSelectTypesFormat = {
  l: 'L',
  kg: 'Kg',
  unit: 'Each'
};
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a>Previous</a>;
  }
  if (type === 'next') {
    return <a>Next</a>;
  }
  return originalElement;
}
let filterdData = [];
class Stockitems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      _id: '',
      type: '',
      code: '',
      name: '',
      description: '',
      supplier: [],
      buyPrice: '0',
      packBuyPrice: '0',
      dimension: '',
      dimType: '',
      packSize: 1,
      serving: '',
      servingType: '',
      qty: '',
      lowqty: '10',
      csvData: undefined,
      main: true,
      mainSub: false,
      multiOpen: false,
      stockitems: [],
      menuitems: [],
      suppliers: [],
      types: [],
      dimtypes: [],
      loading: true,
      userlisthead: 'Add Stock Item',
      userlistsub: 'Add a New Stock Item',
      userlistbutton: 'ADD STOCK ITEM',
      stockSize: false,
      sizes: [],
      alert: false,
      msg: [],
      head: '',
      activeFilter: true,
      activeFilterQty: false,
      currentPage: 1,
      sales: { stockTotal: 0.0 }
    };
  }
  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value
    });
  };
  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb);
    this.props.setBreadCrumbs(breadCrumbs);
  };
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop();
    this.props.setBreadCrumbs(breadCrumbs);
  };

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false);
      this.props.resetActionBreadCrumb();
      this.handleRemoveBreadCrumb();
    }
  }
  componentDidMount() {
    this.getStockItems();
  }

  handleOpen = open => {
    if (open) {
      this.handleRemoveBreadCrumb();
      this.setState({
        main: true,
        stockSize: false,
        sizes: []
      });
    } else {
      this.handleAddBreadCrumb({
        method: 'handleOpenTable',
        crumb: 'Add Stock Item'
      });

      this.setState({
        main: false,
        stockSize: false,
        packSize: 1,
        sizes: [],
        _id: '',
        type: '',
        code: '',
        name: '',
        description: '',
        supplier: [],
        buyPrice: '0',
        packBuyPrice: '0',
        dimension: '',
        dimType: '',
        serving: '',
        servingType: '',
        qty: '',
        lowqty: '10',
        userlisthead: 'Add Stock Item',
        userlistsub: 'Add a New Stock Item',
        userlistbutton: 'ADD STOCK ITEM',
        main: false
      });
    }
  };

  handleOpenTable = open => {
    if (open) {
      this.handleAddBreadCrumb({
        method: 'handleOpenTable',
        crumb: 'Edit Stock Item'
      });
      this.setState({
        main: false,
        stockSize: false,
        sizes: []
      });
    } else {
      this.handleRemoveBreadCrumb();
      this.getStockItems();
    }
  };

  closeDialog = () => {
    this.setState({
      alert: false
    });
  };

  onError = err => {
    this.setState({
      head: 'Stock Items Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onComplete = res => {
    this.setState({
      _id: '',
      type: '',
      code: '',
      name: '',
      description: '',
      supplier: [],
      oSupplier: {},
      packBuyPrice: '0',
      buyPrice: '0',
      dimension: '',
      dimType: '',
      packSize: 1,
      serving: '',
      servingType: '',
      qty: '',
      lowqty: '10',
      listActive: 'a',
      main: true,
      stockSize: false,
      sizes: [],
      dimChangeCheck: false
    });
    this.getStockItems('Stock Item Creation Successful');
  };

  submitForm = oState => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    let newStockItem = Object.assign({}, oState);

    if (newStockItem.supplier[0] === 'none') {
      newStockItem.supplier = [];
    }
    if (newStockItem.packBuyPrice < 0) {
      newStockItem.packBuyPrice = '0';
    }
    if (newStockItem.packSize < 0) {
      newStockItem.packSize = 1;
    }
    if (newStockItem.dimension !== 'unit' && newStockItem.packBuyPrice > 0) {
      newStockItem.buyPrice = (
        parseFloat(newStockItem.packBuyPrice) /
        parseFloat(newStockItem.packSize) /
        1
      ).toString();
    } else {
      newStockItem.buyPrice = newStockItem.packBuyPrice;
    }
    if (oState.userlistbutton === 'ADD STOCK ITEM') {
      this.props
        .addStockItem(newStockItem, token)
        .then(res => {
          if (res.data.results.error) {
            this.onError(res.data.results.error);
          } else {
            this.handleRemoveBreadCrumb();
            this.onComplete(res);
          }
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    } else if (oState.userlistbutton === 'EDIT STOCK ITEM') {
      this.props
        .editStockItem(newStockItem, token)
        .then(res => {
          this.setState({
            main: true,
            stockSize: false,
            packSize: 1,
            sizes: [],
            _id: '',
            type: '',
            code: '',
            name: '',
            description: '',
            supplier: [],
            buyPrice: '0',
            packBuyPrice: '0',
            dimension: '',
            dimType: '',
            serving: '',
            servingType: '',
            qty: '',
            lowqty: '10',
            userlisthead: 'Add Stock Item',
            userlistsub: 'Add a New Stock Item',
            userlistbutton: 'ADD STOCK ITEM',
            main: true,
            dimChangeCheck: false
          });
          this.handleRemoveBreadCrumb();
          this.getStockItems('Stock Item Update Successful');
        })
        .catch(err => {
          if (err) {
            this.onError(err);
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  exportData = stockitems => {
    if (!stockitems.length) return [['No', 'Data']];
    let tableBody = [];
    tableBody.push([
      'Name',
      'Type',
      'Buy Price',
      'In Stock',
      'Stock Value',
      'Servings',
      'Re-Order Level'
    ]);
    stockitems.forEach(record => {
      if (record.active) {
        let lineItem = [];
        lineItem.push(record.name);
        lineItem.push(record.type);
        lineItem.push(
          record.buyPrice && record.packSize > 1
            ? 'R ' + (record.buyPrice * record.packSize).toFixed('2')
            : record.buyPrice
            ? 'R ' + parseFloat(record.buyPrice).toFixed('2')
            : 'R 0.00'
        );
        lineItem.push(
          record.qty === 0
            ? '0 ' +
                (record.dimType
                  ? record.dimType
                  : record.dimension === 'unit'
                  ? ' Unit'
                  : record.dimension)
            : record.dimension === 'unit'
            ? record.qty > 1
              ? record.qty + ' Units'
              : record.qty + ' Unit'
            : record.packSize > 1 && record.qty > record.packSize
            ? Math.round((record.qty / record.packSize) * 100) / 100 +
              'x' +
              record.packSize +
              record.dimType
            : record.qty > 999
            ? (convert(record.qty)
                .from(record.dimType)
                .to(dimSelectTypes[record.dimension][0].value)
                .toFixed('2')
                .includes('.00')
                ? convert(record.qty)
                    .from(record.dimType)
                    .to(dimSelectTypes[record.dimension][0].value)
                    .toFixed('0')
                : convert(record.qty)
                    .from(record.dimType)
                    .to(dimSelectTypes[record.dimension][0].value)
                    .toFixed('2')) +
              ' ' +
              dimSelectTypes[record.dimension][0].name
            : (record.qty.toFixed('2').includes('.00')
                ? record.qty.toFixed('0')
                : record.qty.toFixed('2')) +
              ' ' +
              (dimSelectTypesFormat[record.dimType]
                ? dimSelectTypesFormat[record.dimType]
                : record.dimType
                ? record.dimType
                : record.dimension)
        );
        lineItem.push(
          ((record.qty || 0) * (record.buyPrice || 0)).toFixed('2')
        );
        lineItem.push(
          !record.serving || !record.qty
            ? '0'
            : record.dimType &&
              record.servingType &&
              record.dimension !== 'unit'
            ? (
                convert(record.qty)
                  .from(record.dimType)
                  .to(record.servingType) / record.serving
              ).toFixed('0')
            : record.qty / record.serving
        );

        lineItem.push(
          record.lowqty === 0
            ? '0 ' + (record.dimType ? record.dimType : record.dimension)
            : record.dimension === 'unit'
            ? record.lowqty > 1
              ? record.lowqty + ' Units'
              : record.lowqty + ' Unit'
            : record.lowqty > 999
            ? (convert(record.lowqty)
                .from(record.dimType)
                .to(dimSelectTypes[record.dimension][0].value)
                .toFixed('2')
                .includes('.00')
                ? convert(record.lowqty)
                    .from(record.dimType)
                    .to(dimSelectTypes[record.dimension][0].value)
                    .toFixed('0')
                : convert(record.lowqty)
                    .from(record.dimType)
                    .to(dimSelectTypes[record.dimension][0].value)
                    .toFixed('2')) +
              ' ' +
              dimSelectTypes[record.dimension][0].name
            : (record.lowqty.toFixed('2').includes('.00')
                ? record.lowqty.toFixed('0')
                : record.lowqty.toFixed('2')) +
              ' ' +
              (record.dimType ? record.dimType : record.dimension)
        );
        tableBody.push(lineItem);
      }
    });
    return tableBody;
  };

  getStockItems = msg => {
    const token = this.props.local.user.token;
    this.setState({
      loading: true
    });
    this.props
      .getReports(
        token,
        moment()
          .subtract(30, 'd')
          .format(dateFormatSearch),
        moment().format(dateFormatSearch)
      )
      .then(result => {
        this.setState({
          sales: result.data.results
        });
      });
    this.props
      .getStockItems(token)
      .then(result => {
        result.data.results.suppliers.unshift({
          value: 'none',
          name: 'None'
        });
        let itemFiltersPrep = [];
        let types = result.data.results.types.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        for (var i = 0; i < types.length; i++) {
          if (itemFiltersPrep.indexOf(types[i].name) === -1) {
            itemFiltersPrep.push(types[i].name);
          }
        }
        let itemFilters = [];
        for (var i = 0; i < itemFiltersPrep.length; i++) {
          itemFilters.push({
            text: itemFiltersPrep[i],
            value: itemFiltersPrep[i]
          });
        }
        //**********Export************/
        let tableBody = [];
        tableBody.push([
          'Name',
          'Type',
          'Buy Price',
          'In Stock',
          'Stock Value',
          'Servings',
          'Re-Order Level'
        ]);
        result.data.results.stockitems.forEach(record => {
          if (record.active) {
            let lineItem = [];
            lineItem.push(record.name);
            lineItem.push(record.type);
            lineItem.push(
              record.buyPrice && record.packSize > 1
                ? 'R ' + (record.buyPrice * record.packSize).toFixed('2')
                : record.buyPrice
                ? 'R ' + parseFloat(record.buyPrice).toFixed('2')
                : 'R 0.00'
            );
            lineItem.push(
              record.qty === 0
                ? '0 ' +
                    (record.dimType
                      ? record.dimType
                      : record.dimension === 'unit'
                      ? ' Unit'
                      : record.dimension)
                : record.dimension === 'unit'
                ? record.qty > 1
                  ? record.qty + ' Units'
                  : record.qty + ' Unit'
                : record.packSize > 1 && record.qty > record.packSize
                ? Math.round((record.qty / record.packSize) * 100) / 100 +
                  'x' +
                  record.packSize +
                  record.dimType
                : record.qty > 999
                ? (convert(record.qty)
                    .from(record.dimType)
                    .to(dimSelectTypes[record.dimension][0].value)
                    .toFixed('2')
                    .includes('.00')
                    ? convert(record.qty)
                        .from(record.dimType)
                        .to(dimSelectTypes[record.dimension][0].value)
                        .toFixed('0')
                    : convert(record.qty)
                        .from(record.dimType)
                        .to(dimSelectTypes[record.dimension][0].value)
                        .toFixed('2')) +
                  ' ' +
                  dimSelectTypes[record.dimension][0].name
                : (record.qty.toFixed('2').includes('.00')
                    ? record.qty.toFixed('0')
                    : record.qty.toFixed('2')) +
                  ' ' +
                  (dimSelectTypesFormat[record.dimType]
                    ? dimSelectTypesFormat[record.dimType]
                    : record.dimType
                    ? record.dimType
                    : record.dimension)
            );
            lineItem.push(
              ((record.qty || 0) * (record.buyPrice || 0)).toFixed('2')
            );
            lineItem.push(
              !record.serving || !record.qty
                ? '0'
                : record.dimType &&
                  record.servingType &&
                  record.dimension !== 'unit'
                ? (
                    convert(record.qty)
                      .from(record.dimType)
                      .to(record.servingType) / record.serving
                  ).toFixed('0')
                : record.qty / record.serving
            );

            lineItem.push(
              record.lowqty === 0
                ? '0 ' + (record.dimType ? record.dimType : record.dimension)
                : record.dimension === 'unit'
                ? record.lowqty > 1
                  ? record.lowqty + ' Units'
                  : record.lowqty + ' Unit'
                : record.lowqty > 999
                ? (convert(record.lowqty)
                    .from(record.dimType)
                    .to(dimSelectTypes[record.dimension][0].value)
                    .toFixed('2')
                    .includes('.00')
                    ? convert(record.lowqty)
                        .from(record.dimType)
                        .to(dimSelectTypes[record.dimension][0].value)
                        .toFixed('0')
                    : convert(record.lowqty)
                        .from(record.dimType)
                        .to(dimSelectTypes[record.dimension][0].value)
                        .toFixed('2')) +
                  ' ' +
                  dimSelectTypes[record.dimension][0].name
                : (record.lowqty.toFixed('2').includes('.00')
                    ? record.lowqty.toFixed('0')
                    : record.lowqty.toFixed('2')) +
                  ' ' +
                  (record.dimType ? record.dimType : record.dimension)
            );
            tableBody.push(lineItem);
          }
        });
        this.setState({
          csvData: tableBody,
          stockitems: result.data.results.stockitems,
          menuitems: result.data.results.menuitems,
          suppliers: result.data.results.suppliers,
          oSupplier: result.data.results.oSupplier || {},
          types: result.data.results.types,
          itemFilters: itemFilters,
          itemFiltersPrep: itemFiltersPrep,
          main: true,
          stockSize: false,
          sizes: [],
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
      })
      .catch(err => {
        this.onError(err);
      });
  };

  exportPDF = stockItems => {
    debugger;
    //if(!filterdData.length) return;
    this.setState({ loading: true });
    let csvData = this.exportData(stockItems);
    const pdfMake = require('pdfmake/build/pdfmake');
    const vfsFonts = require('pdfmake/build/vfs_fonts');
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let pdfDocGenerator = pdfMake.createPdf(stockreport(csvData));
    pdfDocGenerator.download('Stock Items - ' + moment().format('YYYY/MM/DD'));
    this.setState({ loading: false });
  };

  handleEditStockItem = oStock => {
    this.handleAddBreadCrumb({
      method: 'handleOpenTable',
      crumb: 'Edit Stock Item'
    });
    var sizes = oStock.sizes || [];

    for (var i = 0; i < sizes.length; i++) {
      var dimensions = Object.assign([], dimSelectTypes[oStock.dimension]);
      if (oStock.packSize > 1) {
        dimensions.unshift({
          name:
            oStock.packSize +
            (dimSelectTypesFormat[oStock.dimType]
              ? dimSelectTypesFormat[oStock.dimType]
              : oStock.dimType),
          value: 'packsize'
        });
      }
      sizes[i].dimtypes = dimensions;
      if (sizes[i].packSize) {
        sizes[i].qty = sizes[i].qty / oStock.packSize;
        sizes[i].dimType = 'packsize';
      }
    }

    if (!oStock.supplier) {
      oStock.supplier = [];
    }
    if (typeof oStock.supplier === 'string') {
      oStock.supplier = [oStock.supplier];
    }
    this.setState({
      main: false,
      stockSize: oStock.stockSize || false,
      packSize: oStock.packSize
        ? oStock.packSize
        : oStock.dimType === 'unit' || !oStock.dimType
        ? 1
        : 1,
      sizes: sizes,
      _id: oStock._id,
      type: oStock.type,
      code: oStock.code,
      name: oStock.name,
      description: oStock.description,
      supplier: oStock.supplier,
      packBuyPrice: oStock.packBuyPrice || oStock.buyPrice || '0',
      buyPrice: oStock.buyPrice,
      dimension: oStock.dimension,
      dimType: oStock.dimType || '',
      serving: oStock.serving,
      servingType: oStock.servingType,
      dimtypes: dimSelectTypes[oStock.dimension],
      qty: oStock.qty || 0,
      lowqty: oStock.lowqty || 10,
      active: oStock.active || false,
      userlisthead: 'Edit Stock Item',
      userlistsub: 'Edit a Stock Item',
      userlistbutton: 'EDIT STOCK ITEM',
      main: false
    });
  };

  handleDeleteStockItem = oMenu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteStockItem(oMenu, token)
      .then(res => {
        this.handleRemoveBreadCrumb();
        this.getStockItems('Stock Item Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  handleRestoreStockItem = oMenu => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteStockItem(oMenu, token)
      .then(res => {
        this.handleRemoveBreadCrumb();
        this.getStockItems('Stock Item Restored Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };
  handleFormatListQty = () => {
    this.setState({ activeFilterQty: true, activeFilter: true });
  };
  handleFormatList = format => {
    this.setState({ activeFilter: format, activeFilterQty: false });
  };
  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };
  handleSort = (pagination, filters, sorter) => {
    this.setState(prevState => ({
      ...prevState,
      filteredInfo: filters,
      sortedInfo: sorter
    }));
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (value) {
        if (
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getColumnFilterProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <Select
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Filter ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e ? [e] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {this.state.types.map((items, key) => {
              return (
                <Option key={key} value={items.value || items._id}>
                  {items.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="sm"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="sm"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (!visible) {
        setTimeout(() => this.searchInput.rcSelect.setOpenState(false));
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  getActiveSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => <div style={{ padding: 8 }} />,
    filterIcon: filtered => <div />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  changePage = page => {
    this.setState({ currentPage: page });
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState({ currentPage: current, pageSize: pageSize });
  };
  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: '3em',
          marginBottom: '0.5em',
          height: 90
        }}
      />

      <button
        style={{
          backgroundColor: '#00CC70',
          border: '0px solid rgb(30, 161, 169)',
          color: 'white',
          width: '10.5em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',
          borderRadius: '3em',
          marginTop: '1em',
          marginBottom: '1.5em'
        }}
        onClick={() => this.handleOpen(!this.state.main)}
        color="primary"
      >
        {'Add Stock Item'}
      </button>
    </div>
  );
  render() {
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditStockItem: this.handleEditStockItem,
      handleDeleteStockItem: this.handleDeleteStockItem,
      handleRestoreStockItem: this.handleRestoreStockItem,
      submitForm: this.submitForm,
      handleOpenStock: this.handleOpenTable,
      onHelpMarker: this.onHelpMarker
    };
    const GearContent = (
      <div>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',

            boxShadow: 'none'
          }}
        >
          <Icon type="up-circle" />
          Export
        </Button>
        <Button
          style={{
            backgroundColor: 'white',
            border: '1px solid rgb(15, 135, 123)',

            color: 'rgb(15, 135, 123)',

            height: '100%',
            borderRadius: '0.3em',

            transform: 'scale(0.9)',
            boxShadow: 'none'
          }}
        >
          <Icon type="down-circle" /> Import
        </Button>
      </div>
    );
    const EyeContent = (
      <div style={{ width: '8em' }}>
        <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
          <Radio
            value={1}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(true)}
          >
            Active
          </Radio>
          <Radio
            value={2}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(false)}
          >
            Inactive
          </Radio>
          <Radio
            value={3}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatList(undefined)}
          >
            All
          </Radio>
          <Radio
            value={4}
            style={{
              paddingBottom: '0.5em',
              backgroundColor: 'transparent',
              border: '0px solid rgb(30, 161, 169)',
              boxShadow: 'none',
              color: 'black',
              fontSize: '16px',
              fontWeight: '500'
            }}
            onClick={() => this.handleFormatListQty()}
          >
            Low Stock
          </Radio>
        </Radio.Group>
      </div>
    );
    let stockitems = [];
    for (var i = 0; i < this.state.stockitems.length; i++) {
      if (this.state.activeFilter === true) {
        if (this.state.stockitems[i].active) {
          if (this.state.activeFilterQty) {
            if (
              this.state.stockitems[i].qty <= this.state.stockitems[i].lowqty
            ) {
              stockitems.push(this.state.stockitems[i]);
            }
          } else {
            stockitems.push(this.state.stockitems[i]);
          }
        }
      } else if (this.state.activeFilter === false) {
        if (!this.state.stockitems[i].active) {
          stockitems.push(this.state.stockitems[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        stockitems.push(this.state.stockitems[i]);
      }
    }
    return (
      <Spin spinning={this.state.loading}>
        <Button
          style={{
            position: 'fixed',
            right: '-0.6em',
            bottom: '5em',
            background: 'rgb(0, 204, 112)',
            border: '1px solid transparent',
            color: 'white',
            padding: '0em',
            width: '3.5em',
            height: '3.5em',
            borderRadius: '5em 0em 0em 5em',
            fontSize: '1em',
            zIndex: 1000
          }}
          onClick={() => this.handleOpen(!this.state.main)}
          color="primary"
        >
          <Icon
            type="plus"
            style={{
              position: 'absolute',
              paddingLeft: 3
            }}
          />
        </Button>
        <AlertDialog {...oState} />
        {this.state.main ? (
          <div>
            {' '}
            {stockitems.length > 0 ? (
              <Row
                style={{
                  marginTop: '-0.8em',
                  marginBottom: '0.5em'
                }}
              >
                <div
                  style={{
                    display: 'inline-block'
                  }}
                >
                  <button
                    style={{
                      backgroundColor: '#00CC70',
                      border: '0px solid rgb(30, 161, 169)',
                      color: 'white',
                      width: '10.5em',
                      height: '2.7em',
                      fontSize: 16,
                      fontFamily: '',
                      borderRadius: '3em',
                      marginTop: '0.8em',
                      marginRight: 10
                    }}
                    onClick={() => this.handleOpen(!this.state.main)}
                    color="primary"
                  >
                    {'Add Stock Item'}
                  </button>
                  {this.state.stockitems.length ? (
                    <button
                      onClick={e => this.exportPDF(stockitems)}
                      style={{
                        backgroundColor: '#00CC70',
                        border: '0px solid rgb(30, 161, 169)',
                        color: 'white',
                        width: '10.5em',
                        height: '2.7em',
                        fontSize: 16,
                        fontFamily: '',
                        borderRadius: '3em',
                        marginTop: '0.8em',
                        marginRight: 10
                      }}
                    >
                      Export to PDF
                    </button>
                  ) : null}
                  {this.state.stockitems.length ? (
                    <button
                      style={{
                        backgroundColor: '#00CC70',
                        border: '0px solid rgb(30, 161, 169)',
                        color: 'white',
                        width: '10.5em',
                        height: '2.7em',
                        fontSize: 16,
                        fontFamily: '',
                        borderRadius: '3em',
                        marginTop: '0.8em',
                        marginRight: 10
                      }}
                    >
                      <CSVLink
                        style={{ color: 'white' }}
                        filename={
                          'Stock Items - ' +
                          moment().format('YYYY/MM/DD') +
                          '.csv'
                        }
                        data={this.exportData(stockitems)}
                      >
                        Export to CSV
                      </CSVLink>
                    </button>
                  ) : null}
                </div>
                <div
                  style={{
                    float: 'right',
                    //background: "white",
                    padding: '0.8em',
                    //boxShadow: "rgb(229, 233, 242) 0px 5px 10px 2px",
                    borderRadius: '3em'
                  }}
                >
                  <AiOutlineAreaChart
                    style={{
                      marginTop: '-0.08em',
                      marginBottom: '-0.08em',
                      boxShadow: 'rgb(229, 233, 242) 0px 5px 10px 2px',
                      padding: '0.3em',
                      borderRadius: '3em',
                      height: '1.5em',
                      width: '1.5em',
                      background: 'rgb(0, 204, 112)',
                      fontSize: 30,
                      color: 'white',
                      float: 'right'
                    }}
                  />
                  <h2
                    style={{
                      float: 'right',
                      lineHeight: '1.2em',
                      margin: 0,
                      padding: 0,
                      fontSize: '18px',
                      textAlign: 'right',
                      border: '0px solid rgba(0, 204, 112, 0.898)',
                      borderRadius: '3em',
                      padding: '0em',
                      paddingRight: '1em',
                      paddingLeft: '1em'
                    }}
                  >
                    Total stock value
                    <br />{' '}
                    <span
                      style={{
                        color:
                          this.state.sales.stockTotal > -1
                            ? 'rgba(0, 204, 112, 0.898)'
                            : 'red',
                        fontSize: '15px'
                      }}
                    >
                      R{' '}
                      {this.state.sales.stockTotal
                        ? this.state.sales.stockTotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '0.00'}{' '}
                    </span>
                  </h2>
                </div>
              </Row>
            ) : null}
            <div
              style={{
                float: 'right',
                marginTop: '-1em',
                marginBottom: '1em'
              }}
            >
              <Popover
                overlayStyle={{ borderRadius: '3em' }}
                placement="left"
                content={EyeContent}
                title="Table settings"
                trigger="click"
              >
                <Button
                  style={{
                    position: 'fixed',
                    right: '-0.6em',
                    bottom: '9em',
                    background: 'rgba(229, 233, 242, 0.9)',
                    border: '1px solid transparent',
                    color: 'rgba(0, 0, 0, 0.54)',
                    padding: '0em',
                    width: '3.5em',
                    height: '3.5em',
                    boxShadow: 'none',
                    borderRadius: '5em 0em 0em 5em',
                    fontSize: '1em',
                    zIndex: 1000
                  }}
                >
                  <Icon
                    style={{
                      paddingLeft: '2px'
                    }}
                    theme="filled"
                    type="setting"
                  />
                </Button>
              </Popover>
              <Popover
                placement="bottomRight"
                content={GearContent}
                title=""
                trigger="click"
              >
                <Button
                  style={{
                    display: 'none',
                    backgroundColor: 'white',
                    border: '1px solid rgb(15, 135, 123)',
                    color: 'rgb(15, 135, 123)',
                    marginBottom: '-0.5em',
                    height: '3.5em',
                    marginLeft: '-0.27em',
                    borderRadius: '0.3em',
                    transform: 'scale(0.9)',
                    width: '2em'
                  }}
                >
                  <Icon
                    style={{
                      paddingLeft: '2px'
                    }}
                    type="setting"
                  />
                </Button>
              </Popover>
            </div>
            <Row>
              <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  pagination={{
                    locale: { items_per_page: ' Items' },
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    onChange: this.changePage,
                    current: this.state.currentPage,
                    hideOnSinglePage: true,
                    pageSize: this.state.pageSize || 25,
                    itemRender: itemRender,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} - ${range[1]}  of ${total} items`
                  }}
                  dataSource={stockitems}
                  onChange={this.handleSort}
                  footer={currentPageData => {
                    filterdData = currentPageData;
                    return null;
                  }}
                  onRow={(record, rowIndex) => {
                    if (rowIndex % 2 == 0) {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.handleEditStockItem(record);
                        },
                        style: { background: 'rgb(247, 247, 247)' }
                      };
                    } else {
                      return {
                        onClick: e => {
                          e.stopPropagation();
                          oState.handleEditStockItem(record);
                        }
                      };
                    }
                  }}
                >
                  <Column
                    {...this.getColumnSearchProps('name')}
                    sorter={(a, b) => a.name.localeCompare(b.name)}
                    sortDirections={['descend', 'ascend']}
                    filteredValue={[this.state.searchText]}
                    title="Name"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    filters={this.state.itemFilters}
                    filteredValue={
                      this.state.filteredInfo
                        ? this.state.filteredInfo.type
                        : null
                    }
                    onFilter={(value, record) => record.type.includes(value)}
                    sorter={(a, b) => a.type.localeCompare(b.type)}
                    sortDirections={['descend', 'ascend']}
                    title="Type"
                    dataIndex="type"
                    key="type"
                  />
                  {/*   <Column
                  {...this.getColumnSearchProps('code')}
                  title="Code"
                  dataIndex="code"
                  key="code"
                />
                <Column
                  {...this.getColumnSearchProps('description')}
                  title="Description"
                  dataIndex="description"
                  key="description"
             /> */}
                  <Column
                    title="Buy Price"
                    render={(text, record) => (
                      <span>
                        {record.buyPrice && record.packSize > 1
                          ? 'R ' +
                            (record.buyPrice * record.packSize).toFixed('2')
                          : record.buyPrice
                          ? 'R ' + parseFloat(record.buyPrice).toFixed('2')
                          : 'R 0.00'}
                      </span>
                    )}
                    dataIndex="buyPrice"
                    key="buyPrice"
                  />
                  <Column
                    title="In Stock"
                    render={(text, record) => {
                      return (
                        <span>
                          {record.qty === 0
                            ? '0 ' +
                              (record.dimType
                                ? record.dimType
                                : record.dimension === 'unit'
                                ? ' Unit'
                                : record.dimension)
                            : record.dimension === 'unit'
                            ? record.qty > 1
                              ? record.qty + ' Units'
                              : record.qty + ' Unit'
                            : record.packSize > 1 &&
                              record.qty > record.packSize
                            ? Math.round((record.qty / record.packSize) * 100) /
                                100 +
                              'x' +
                              record.packSize +
                              record.dimType
                            : record.qty > 999
                            ? (convert(record.qty)
                                .from(record.dimType)
                                .to(dimSelectTypes[record.dimension][0].value)
                                .toFixed('2')
                                .includes('.00')
                                ? convert(record.qty)
                                    .from(record.dimType)
                                    .to(
                                      dimSelectTypes[record.dimension][0].value
                                    )
                                    .toFixed('0')
                                : convert(record.qty)
                                    .from(record.dimType)
                                    .to(
                                      dimSelectTypes[record.dimension][0].value
                                    )
                                    .toFixed('2')) +
                              ' ' +
                              dimSelectTypes[record.dimension][0].name
                            : (record.qty.toFixed('2').includes('.00')
                                ? record.qty.toFixed('0')
                                : record.qty.toFixed('2')) +
                              ' ' +
                              (dimSelectTypesFormat[record.dimType]
                                ? dimSelectTypesFormat[record.dimType]
                                : record.dimType
                                ? record.dimType
                                : record.dimension)}
                        </span>
                      );
                    }}
                    dataIndex="qty"
                    key="qty"
                  />
                  <Column
                    title={'Stock Value'}
                    sorter={(a, b) => a.qty * a.buyPrice - b.qty * b.buyPrice}
                    sortDirections={['descend', 'ascend']}
                    render={(text, record) => (
                      <span>
                        <Tooltip title="The Sum of the Stock`s Value">
                          {'R ' +
                            (
                              (record.qty || 0) * (record.buyPrice || 0)
                            ).toFixed('2')}
                        </Tooltip>
                      </span>
                    )}
                  />
                  <Column
                    title="Servings"
                    render={(text, record) => {
                      return (
                        <span>
                          {!record.serving || !record.qty
                            ? '0'
                            : record.dimType &&
                              record.servingType &&
                              record.dimension !== 'unit'
                            ? (
                                convert(record.qty)
                                  .from(record.dimType)
                                  .to(record.servingType) / record.serving
                              ).toFixed('0')
                            : record.qty / record.serving}
                        </span>
                      );
                    }}
                    dataIndex="serving"
                    key="serving"
                  />
                  <Column
                    title="Re-Order Level"
                    render={(text, record) => (
                      <span>
                        {record.lowqty === 0
                          ? '0 ' +
                            (record.dimType ? record.dimType : record.dimension)
                          : record.dimension === 'unit'
                          ? record.lowqty > 1
                            ? record.lowqty + ' Units'
                            : record.lowqty + ' Unit'
                          : record.lowqty > 999
                          ? (convert(record.lowqty)
                              .from(record.dimType)
                              .to(dimSelectTypes[record.dimension][0].value)
                              .toFixed('2')
                              .includes('.00')
                              ? convert(record.lowqty)
                                  .from(record.dimType)
                                  .to(dimSelectTypes[record.dimension][0].value)
                                  .toFixed('0')
                              : convert(record.lowqty)
                                  .from(record.dimType)
                                  .to(dimSelectTypes[record.dimension][0].value)
                                  .toFixed('2')) +
                            ' ' +
                            dimSelectTypes[record.dimension][0].name
                          : (record.lowqty.toFixed('2').includes('.00')
                              ? record.lowqty.toFixed('0')
                              : record.lowqty.toFixed('2')) +
                            ' ' +
                            (record.dimType
                              ? record.dimType
                              : record.dimension)}
                      </span>
                    )}
                  />
                  <Column
                    title="Last Modified"
                    render={(text, record) => (
                      <span>
                        {record.moddate
                          ? record.moddate.slice(0, 4) +
                            '/' +
                            record.moddate.slice(4, 6) +
                            '/' +
                            record.moddate.slice(6, 8)
                          : 'No Date'}
                      </span>
                    )}
                    sorter={(a, b, sortOrder) => {
                      if (a.moddate && b.moddate) {
                        return a.moddate.localeCompare(b.moddate);
                      }
                      if (a.moddate && !b.moddate) {
                        return sortOrder === 'ascend' ? -1 : 1;
                      }
                      if (b.moddate && !a.moddate) {
                        return sortOrder === 'ascend' ? 1 : -1;
                      }
                      return 0;
                    }}
                    sortDirections={['descend', 'ascend']}
                    dataIndex="moddate"
                    key="moddate"
                  />
                  {/*    <Column
                  title="Dim"
                  render={(text, record) => (
                    <span>
                      {record.dimType ? record.dimType : record.dimension}
                    </span>
                  )}
                  /> */}
                  {/* <Column
                  {...this.getActiveSearchProps('active')}
                  filterDropdownVisible={false}
                  title="Active"
                  filtered={true}
                  filteredValue={this.state.activeFilter}
                  dataIndex="active"
                  render={(text, record) => (
                    <span>{record.active.toString()}</span>
                  )}
                  /> */}
                  <Column
                    title="Status"
                    align="center"
                    render={(text, record) => (
                      <div
                        style={
                          record.qty <= 0
                            ? record.qty === 0
                              ? {
                                  border: '1px solid red',
                                  color: 'red',
                                  padding: 6,
                                  borderRadius: '3em',
                                  width: '6.7em',
                                  margin: 'auto'
                                }
                              : {
                                  border: '1px solid red',
                                  color: 'red',
                                  padding: 6,
                                  borderRadius: '3em',
                                  width: '8.7em',
                                  margin: 'auto'
                                }
                            : record.qty <= record.lowqty
                            ? {
                                border: '1px solid orange',
                                color: 'orange',
                                padding: 6,
                                borderRadius: '3em',
                                width: '6.7em',
                                margin: 'auto'
                              }
                            : {
                                border: '1px solid rgb(0, 204, 112)',
                                color: 'rgb(0, 204, 112)',
                                padding: 6,
                                borderRadius: '3em',
                                width: '6.7em',
                                margin: 'auto'
                              }
                        }
                      >
                        <span>
                          {record.qty <= 0
                            ? record.qty === 0
                              ? 'No Stock'
                              : 'Negative Stock'
                            : record.qty <= record.lowqty
                            ? 'Low Stock'
                            : 'Ready'}
                        </span>
                      </div>
                    )}
                  />
                </StyledTable>
              </ConfigProvider>
            </Row>
          </div>
        ) : (
          <div>
            <h4
              style={{
                color: 'rgba(0, 0, 0, 0.85)',
                position: 'absolute',
                right: '5px',
                fontWeight: 700,
                top: '-1.5em',
                zIndex: '9',
                fontSize: '25px'
              }}
              className={oState.props.classes.cardTitleWhite}
            >
              {oState.state.name ? oState.state.name : ''}
            </h4>
            <Stockitemsform {...oState} />
          </div>
        )}
        <Snackbar {...this.props} />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  stockitems: state.stockitems,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  addStockItem,
  getStockItems,
  editStockItem,
  deleteStockItem,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  getReports
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Stockitems));
