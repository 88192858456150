import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon, Switch, Tooltip } from 'antd';
import React from 'react';
import Card from 'src/components/Card/Card.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import Button from 'src/components/CustomButtons/Button.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import GridItem from 'src/components/Grid/GridItem.jsx';

const FormBody = ({ ...oState }) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Supplier Name*"
              id="name"
              autoFocus={true}
              error={!oState.state.name && oState.state.submitInProgress}
              errorText={'Supplier Name required'}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemcode'),
                value: oState.state.name
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Contact"
              id="contact"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemname'),
                value: oState.state.contact
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={11} sm={11} md={5}>
            <CustomInput
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemdesc'),
                value: oState.state.email
              }}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <Tooltip
              placement="top"
              title={
                'Use the semicolon (;) character to separate multiple email addresses'
              }
            >
              <IconButton style={{ marginTop: '1.8em', marginLeft: '-1.2em' }}>
                <Icon
                  type="info-circle"
                  style={{
                    fontSize: '17px',
                    color: 'grey'
                  }}
                />
              </IconButton>
            </Tooltip>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Phone Number"
              id="number"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: 'number',
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitembuy'),
                value: oState.state.number
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Street Address"
              id="address1"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.address1
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="City"
              id="city"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.city
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Postal/Zip Code"
              id="postal"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: 'number',
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.postal
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Country"
              id="country"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.country
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Region/State/Province"
              id="province"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.province
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="VAT Number"
              id="vatNumber"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: 'number',
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.vatNumber
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Note"
              id="note"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => oState.onChangeEvt(e),
                onClick: () => oState.onHelpMarker('stockitemhand'),
                value: oState.state.note
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem
            style={{
              display: 'flex',
              marginTop: '1.5em',
              marginBottom: '1.5em'
            }}
            xs={12}
            sm={12}
            md={12}
          >
            <FormControlLabel
              style={{ margin: 'auto' }}
              control={
                <Switch
                  style={{ marginRight: '1em' }}
                  checked={oState.state.bankDetails}
                  onChange={oState.switchBankDetails}
                />
              }
              label="Bank Details"
            />
          </GridItem>
        </GridContainer>
        {oState.state.bankDetails ? (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Bank Name"
                  id="bankName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => oState.onChangeEvt(e),
                    onClick: () => oState.onHelpMarker('stockitemhand'),
                    value: oState.state.bankName
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Account Number"
                  id="bankAccount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => oState.onChangeEvt(e),
                    onClick: () => oState.onHelpMarker('stockitemhand'),
                    value: oState.state.bankAccount
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Branch"
                  id="bankBranch"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => oState.onChangeEvt(e),
                    onClick: () => oState.onHelpMarker('stockitemhand'),
                    value: oState.state.bankBranch
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Account Type"
                  id="bankAccountType"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => oState.onChangeEvt(e),
                    onClick: () => oState.onHelpMarker('stockitemhand'),
                    value: oState.state.bankAccountType
                  }}
                />
              </GridItem>
            </GridContainer>
          </div>
        ) : null}
        <CardFooter
          style={{
            display: 'block',
            borderTop: '1px solid rgb(229, 233, 242)',
            marginTop: '1em'
          }}
        >
          <Button
            style={{
              marginLeft: '1em',
              float: 'right',
              fontWeight: 700,
              fontFamily: 'Mulish , sans-serif',
              fontSize: '13px',
              textTransform: 'initial',
              marginTop: '1em',
              backgroundColor: '#00CC70',
              border: '1px solid #00CC70',
              color: 'white',
              borderRadius: '3em'
            }}
            onClick={oState.submitForm}
            color="primary"
          >
            {oState.state.userlistbutton}
          </Button>
          <Button
            style={{
              float: 'right',
              fontWeight: 700,
              fontFamily: 'Mulish , sans-serif',
              fontSize: '13px',
              marginLeft: '-2.5em',
              marginTop: '1em',
              textTransform: 'initial',
              borderRadius: '3em',
              border: '1px solid rgba(229, 233, 242, 0.9)',
              color: 'black',
              backgroundColor: 'rgba(229, 233, 242, 0.9)',
              boxShadow: 'none'
            }}
            onClick={() => oState.handleOpenStock(false)}
            color="primary"
          >
            Cancel
          </Button>{' '}
        </CardFooter>
      </CardBody>
    </Card>
  );
};

export default FormBody;
