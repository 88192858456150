import { PrintJS } from 'src/config/PrintScript';
import moment from 'moment';

function Print(aData, sIp, type, model) {
  for (var i = 0; i < 4; i++) {
    aData.push('\n');
  }
  return new Promise(async (resolve, reject) => {
    if (window.device) {
      if (
        window.device.platform === 'Android' ||
        window.device.platform === 'iOS'
      ) {
        if (window.device.platform === 'Android' && model === 'impact') {
          for (var i = 0; i < aData.length; i++) {
            aData[i] = aData[i].replace('\\n', '\n');
            if (aData[i].search('{B}') > -1) {
              aData[i] = `<b>${aData[i].replace('{B}', '')}</b>`;
            }
            if (aData[i].search('3{S}') > -1) {
              aData[i] = `<font size='big'>${aData[i].replace(
                '3{S}',
                ''
              )}</font>`;
            }
            if (aData[i].search('2{S}') > -1) {
              aData[i] = `<font size='big'>${aData[i].replace(
                '2{S}',
                ''
              )}</font>`;
            }
            aData[i] = aData[i].replace('1{S}', '');
            aData[i] = aData[i].replace('{L}', '');
          }
          window.ThermalPrinter.printFormattedTextAndCut(
            {
              type: 'tcp',
              address: sIp,
              port: 9100,
              id: sIp,
              text: aData,
              printerWidthMM: 80
            },
            function() {
              window.ThermalPrinter.disconnectPrinter(
                {
                  type: 'tcp',
                  address: sIp,
                  port: 9100,
                  id: sIp
                },
                function() {},
                function(error) {
                  alert(error);
                }
              );
            },
            function(error) {
              window.ThermalPrinter.disconnectPrinter(
                {
                  type: 'tcp',
                  address: sIp,
                  port: 9100,
                  id: sIp
                },
                function() {
                  alert(error);
                },
                function(error) {
                  alert(error);
                }
              );
            }
          );
        } else {
          window.epos2.connectPrinter(
            sIp,
            function() {
              for (var i = 0; i < aData.length; i++) {
                aData[i] = aData[i].replace('\\n', '\n');
              }
              window.epos2.print(
                aData,
                function(output) {
                  return resolve({ message: 'Done' });
                },
                function(error) {
                  alert('' + error);
                  return resolve({ message: 'Done' });
                }
              );
            },
            function(error) {
              alert('' + error);
              return resolve({ message: 'Done' });
            }
          );
        }
      } else {
        PrintJS(aData, sIp, type, function(msg) {
          return resolve(msg);
        });
      }
    } else {
      PrintJS(aData, sIp, type, function(msg) {
        return resolve(msg);
      });
    }
  }).catch(async err => {
    alert(err.message);
    throw err.message;
  });
}

export function PrintBillReceipt(copyBill, printer, sIp, sUser) {
  var receipt = [];
  try {
    if (sIp.head && sIp.heading) {
      if (sIp.heading.length > 0) {
        if (typeof sIp.heading === 'string') {
          var heading = sIp.heading.split('\n');
          for (var i = 0; i < heading.length; i++) {
            receipt.push(heading[i]);
          }
        } else {
          for (var head = 0; head < sIp.heading.length; head++) {
            var text = '';
            if (sIp.heading[head].bold) {
              text += '{B}';
            }
            if (sIp.heading[head].size) {
              text += '3{S}';
            }
            receipt.push(text + sIp.heading[head].text);
          }
        }
      }
    }
    receipt.push('========================================\n');
    receipt.push(
      'Date :' +
        moment().format('DD/MM/YYYY HH:mm') +
        '  Order #:' +
        copyBill.orderno +
        '\n'
    );
    receipt.push('Served by : ' + sUser + ' \n');
    if (copyBill.desc) {
      receipt.push('Desc   :' + copyBill.desc + '\n');
    }
    receipt.push('\n');
    receipt.push('Item                        Price\n');
    receipt.push('----------------------------------------\n');
    for (var i = 0; i < copyBill.items.length; i++) {
      if (copyBill.items[i].state !== 'payed') {
        var length = copyBill.items[i].name.length + 1;
        receipt.push(
          copyBill.items[i].name +
            '                           '.slice(0, -length) +
            '  R' +
            (copyBill.items[i].qty * copyBill.items[i].price).toFixed(2) +
            '\n'
        );
        if (copyBill.items[i].cookIns) {
          if (copyBill.items[i].cookIns.length > 0) {
            for (var j = 0; j < copyBill.items[i].cookIns.length; j++) {
              if (copyBill.items[i].cookIns[j].price > 0) {
                receipt.push(
                  '       ' +
                    copyBill.items[i].cookIns[j].name +
                    ' @ R' +
                    copyBill.items[i].cookIns[j].price.toFixed(2) +
                    '\n'
                );
              }
            }
          }
        }
        if (copyBill.items[i].qty > 1) {
          receipt.push(
            '  ' +
              copyBill.items[i].qty +
              ' ' +
              '@' +
              ' R' +
              copyBill.items[i].price.toFixed(2) +
              '\n'
          );
        }
      }
    }
    receipt.push('----------------------------------------\n');
    if (sIp.vat) {
      var VAT = (copyBill.total * 15) / 100;
      receipt.push('VAT: R' + VAT.toFixed(2) + '\n');
    }
    if (copyBill.discount > 0) {
      receipt.push('Discount: R ' + copyBill.discount.toFixed(2) + '\n');
    }
    receipt.push('Total: R' + copyBill.total.toFixed(2) + '\n');
    receipt.push('========================================\n');
    receipt.push('\n');
    receipt.push('Gratuity :___________________\n');
    receipt.push('\n');
    receipt.push('Total Due:___________________\n');
    receipt.push('\n');
    receipt.push('----------------------------------------\n');
    if (sIp.foot && sIp.footer) {
      if (sIp.footer.length > 0) {
        if (typeof sIp.footer === 'string') {
          var footer = sIp.footer.split('\n');
          for (var i = 0; i < footer.length; i++) {
            receipt.push('{left}' + footer[i]);
          }
        } else {
          for (var foot = 0; foot < sIp.footer.length; foot++) {
            var preText = '{left}';
            var postText = '';
            if (sIp.footer[foot].bold) {
              preText += '{b}';
              postText += '{/b}';
            }
            if (sIp.footer[foot].size) {
              preText += '{h}{w}';
              postText += '{/h}{/w}';
            }
            receipt.push(preText + sIp.footer[foot].text + postText);
          }
        }
      }
    }
  } catch (error) {
    alert('' + error);
  }
  Print(receipt, printer.address, undefined, printer.model)
    .then(result => {})
    .catch(err => {
      alert('' + err);
    });
}

export function PrintPayReceipt(copyBill, sIp, sUser) {
  var receipt = [];
  try {
    if (copyBill.amountLeft < 1) {
      if (sIp.head && sIp.heading) {
        var heading = sIp.heading.split('\n');
        receipt = heading;
      }
      receipt.push('========================================\n');
      receipt.push(
        'Date :' +
          moment().format('DD/MM/YYYY HH:mm') +
          '  Order #:' +
          copyBill.orderno +
          '\n'
      );
      receipt.push('Served by : ' + sUser + ' \n');
      if (copyBill.desc) {
        receipt.push('Desc   :' + copyBill.desc + '\n');
      }
      receipt.push('\n');
      receipt.push('Item                        Price\n');
      receipt.push('----------------------------------------\n');
      for (var i = 0; i < copyBill.items.length; i++) {
        if (copyBill.items[i].state !== 'payed') {
          var length = copyBill.items[i].name.length + 1;
          receipt.push(
            copyBill.items[i].name +
              '                           '.slice(0, -length) +
              '  R' +
              (copyBill.items[i].qty * copyBill.items[i].price).toFixed(2) +
              '\n'
          );
          if (copyBill.items[i].cookIns) {
            if (copyBill.items[i].cookIns.length > 0) {
              for (var j = 0; j < copyBill.items[i].cookIns.length; j++) {
                if (copyBill.items[i].cookIns[j].price > 0) {
                  receipt.push(
                    '       ' +
                      copyBill.items[i].cookIns[j].name +
                      ' @ R' +
                      copyBill.items[i].cookIns[j].price.toFixed(2) +
                      '\n'
                  );
                }
              }
            }
          }
          if (copyBill.items[i].qty > 1) {
            receipt.push(
              '  ' +
                copyBill.items[i].qty +
                ' ' +
                '@' +
                ' R' +
                copyBill.items[i].price.toFixed(2) +
                '\n'
            );
          }
        }
      }
      receipt.push('----------------------------------------\n');
      if (sIp.vat) {
        var VAT = (copyBill.total * 15) / 100;
        receipt.push('VAT: R' + VAT.toFixed(2) + '\n');
      }
      receipt.push('Total: R' + copyBill.total.toFixed(2) + '\n');
      receipt.push('========================================\n');
      //  receipt.push('--------------Full Payment--------------\n');
      receipt.push('\n');
      if (copyBill.tip > 0) {
        receipt.push('     Tip: R ' + copyBill.tip.toFixed(2) + '\n');
      }
      if (copyBill.discount > 0) {
        receipt.push('Discount: R ' + copyBill.discount.toFixed(2) + '\n');
      }
      if (copyBill.method === 'cash') {
        receipt.push(
          '    Cash: R ' + copyBill.receivedAmount.toFixed(2) + '\n'
        );
      }
      if (copyBill.method === 'card') {
        receipt.push(
          '    Card: R ' + copyBill.receivedAmount.toFixed(2) + '\n'
        );
      }
      receipt.push('\n');
      receipt.push('----------------------------------------\n');
      if (sIp.foot && sIp.footer) {
        var footer = sIp.footer.split('\n');
        for (i = 0; i < footer.length; i++) {
          receipt.push(footer[i]);
        }
      }
    } else {
      if (sIp.head && sIp.heading) {
        var heading = sIp.heading.split('\n');
        receipt = heading;
      }
      receipt.push('========================================\n');
      receipt.push(
        'Date :' +
          moment().format('DD/MM/YYYY HH:mm') +
          '  Order #:' +
          copyBill.orderno +
          '\n'
      );
      receipt.push('Served by : ' + sUser + ' \n');
      if (copyBill.desc) {
        receipt.push('Desc   :' + copyBill.desc + '\n');
      }
      receipt.push('\n');
      receipt.push('Item                        Price\n');
      receipt.push('----------------------------------------\n');
      for (var i = 0; i < copyBill.items.length; i++) {
        if (copyBill.items[i].state !== 'payed') {
          var length = copyBill.items[i].name.length + 1;
          receipt.push(
            copyBill.items[i].name +
              '                           '.slice(0, -length) +
              '  R' +
              (copyBill.items[i].qty * copyBill.items[i].price).toFixed(2) +
              '\n'
          );
          if (copyBill.items[i].cookIns) {
            if (copyBill.items[i].cookIns.length > 0) {
              for (var j = 0; j < copyBill.items[i].cookIns.length; j++) {
                if (copyBill.items[i].cookIns[j].price > 0) {
                  receipt.push(
                    '       ' +
                      copyBill.items[i].cookIns[j].name +
                      ' @ R' +
                      copyBill.items[i].cookIns[j].price.toFixed(2) +
                      '\n'
                  );
                }
              }
            }
          }
          if (copyBill.items[i].qty > 1) {
            receipt.push(
              '  ' +
                copyBill.items[i].qty +
                ' ' +
                '@' +
                ' R' +
                copyBill.items[i].price.toFixed(2) +
                '\n'
            );
          }
        }
      }
      receipt.push('----------------------------------------\n');
      if (sIp.vat) {
        var VAT = (copyBill.total * 15) / 100;
        receipt.push('VAT: R' + VAT.toFixed(2) + '\n');
      }
      receipt.push('Total: R' + copyBill.total.toFixed(2) + '\n');
      receipt.push('========================================\n');
      receipt.push('------------Partial Payment-------------\n');
      receipt.push('\n');
      if (copyBill.method === 'cash') {
        receipt.push('    Cash: R' + copyBill.receivedAmount.toFixed(2) + '\n');
      }
      if (copyBill.method === 'card') {
        receipt.push('    Card: R' + copyBill.receivedAmount.toFixed(2) + '\n');
      }
      receipt.push('----------------------------------------\n');
      if (sIp.foot && sIp.footer) {
        var footer = sIp.footer.split('\n');
        for (i = 0; i < footer.length; i++) {
          receipt.push(footer[i]);
        }
      }
    }
  } catch (error) {
    alert('' + error);
  }
  var cashCheck = false;
  if (copyBill.method === 'cash') {
    cashCheck = true;
  }
  Print(receipt, sIp.printbill, cashCheck)
    .then(result => {})
    .catch(err => {
      alert('' + err);
    });
}

export function PrintProcess(copyBill, sIp, sUser) {
  try {
    if (sIp.kitch) {
      var receipt = [];
      var count = 0;
      receipt.push('========================================\n');
      receipt.push('Kichen Order\n');
      receipt.push('Date   :' + moment().format('DD/MM/YYYY HH:mm') + '\n');
      receipt.push('Order #:' + copyBill.orderno + '\n');
      receipt.push('Waiter :' + sUser + '\n');
      if (copyBill.desc) {
        receipt.push('Desc   :' + copyBill.desc + '\n');
      }
      receipt.push('\n');
      receipt.push('Type   :' + copyBill.type + '\n');
      receipt.push('========================================\n');
      receipt.push('Qty  Item\n');
      receipt.push('========================================\n');
      for (var i = 0; i < copyBill.items.length; i++) {
        if (
          !copyBill.items[i].processed &&
          copyBill.items[i].printto === 'Kitchen'
        ) {
          var length = copyBill.items[i].name.length + 1;
          var item =
            copyBill.items[i].procount +
            ' X ' +
            copyBill.items[i].name +
            '                         '.slice(0, -length) +
            '\n';
          receipt.push(item);
          if (copyBill.items[i].cookIns) {
            if (copyBill.items[i].cookIns.length > 0) {
              for (var j = 0; j < copyBill.items[i].cookIns.length; j++) {
                receipt.push(
                  '      ' + copyBill.items[i].cookIns[j].name + '\n'
                );
              }
            }
          }
          count = count + 1;
        }
      }
      receipt.push('========================================\n');
      if (count > 0) {
        Print(receipt, sIp.printkitch)
          .then(result => {
            if (sIp.bar) {
              var receipt = [];
              var count = 0;
              receipt.push('========================================\n');
              receipt.push('Bar Order\n');
              receipt.push(
                'Date   :' + moment().format('DD/MM/YYYY HH:mm') + '\n'
              );
              receipt.push('Order #:' + copyBill.orderno + '\n');
              receipt.push('Waiter :' + sUser + '\n');
              if (copyBill.desc) {
                receipt.push('Desc   :' + copyBill.desc + '\n');
              }
              receipt.push('\n');
              receipt.push('Type   :' + copyBill.type + '\n');
              receipt.push('========================================\n');
              receipt.push('Qty  Item\n');
              receipt.push('========================================\n');
              for (var i = 0; i < copyBill.items.length; i++) {
                if (
                  !copyBill.items[i].processed &&
                  copyBill.items[i].printto === 'Bar'
                ) {
                  var length = copyBill.items[i].name.length + 1;
                  var item =
                    copyBill.items[i].procount +
                    ' X ' +
                    copyBill.items[i].name +
                    '                         '.slice(0, -length) +
                    '\n';
                  receipt.push(item);
                  if (copyBill.items[i].cookIns) {
                    if (copyBill.items[i].cookIns.length > 0) {
                      for (
                        var j = 0;
                        j < copyBill.items[i].cookIns.length;
                        j++
                      ) {
                        receipt.push(
                          '      ' + copyBill.items[i].cookIns[j].name + '\n'
                        );
                      }
                    }
                  }
                  count = count + 1;
                }
              }
              receipt.push('========================================\n');
              if (count > 0) {
                Print(receipt, sIp.printbar)
                  .then(result => {})
                  .catch(err => {
                    alert('' + err);
                  });
              }
            } else {
              if (sIp.bar) {
                var receipt = [];
                var count = 0;
                receipt.push('========================================\n');
                receipt.push('Bar Order\n');
                receipt.push(
                  'Date   :' + moment().format('DD/MM/YYYY HH:mm') + '\n'
                );
                receipt.push('Order #:' + copyBill.orderno + '\n');
                receipt.push('Waiter :' + sUser + '\n');
                if (copyBill.desc) {
                  receipt.push('Desc   :' + copyBill.desc + '\n');
                }
                receipt.push('\n');
                receipt.push('Type   :' + copyBill.type + '\n');
                receipt.push('========================================\n');
                receipt.push('Qty  Item\n');
                receipt.push('========================================\n');
                for (var i = 0; i < copyBill.items.length; i++) {
                  if (
                    !copyBill.items[i].processed &&
                    copyBill.items[i].printto === 'Bar'
                  ) {
                    var length = copyBill.items[i].name.length + 1;
                    var item =
                      copyBill.items[i].procount +
                      ' X ' +
                      copyBill.items[i].name +
                      '                         '.slice(0, -length) +
                      '\n';
                    receipt.push(item);
                    if (copyBill.items[i].cookIns) {
                      if (copyBill.items[i].cookIns.length > 0) {
                        for (
                          var j = 0;
                          j < copyBill.items[i].cookIns.length;
                          j++
                        ) {
                          receipt.push(
                            '      ' + copyBill.items[i].cookIns[j].name + '\n'
                          );
                        }
                      }
                    }
                    count = count + 1;
                  }
                }
                receipt.push('========================================\n');
                if (count > 0) {
                  Print(receipt, sIp.printbar)
                    .then(result => {})
                    .catch(err => {
                      alert('' + err);
                    });
                }
              }
            }
          })
          .catch(err => {
            alert('' + err);
          });
      } else {
        if (sIp.bar) {
          var receipt = [];
          var count = 0;
          receipt.push('========================================\n');
          receipt.push('Bar Order\n');
          receipt.push('Date   :' + moment().format('DD/MM/YYYY HH:mm') + '\n');
          receipt.push('Order #:' + copyBill.orderno + '\n');
          receipt.push('Waiter :' + sUser + '\n');
          if (copyBill.desc) {
            receipt.push('Desc   :' + copyBill.desc + '\n');
          }
          receipt.push('\n');
          receipt.push('Type   :' + copyBill.type + '\n');
          receipt.push('========================================\n');
          receipt.push('Qty  Item\n');
          receipt.push('========================================\n');
          for (var i = 0; i < copyBill.items.length; i++) {
            if (
              !copyBill.items[i].processed &&
              copyBill.items[i].printto === 'Bar'
            ) {
              var length = copyBill.items[i].name.length + 1;
              var item =
                copyBill.items[i].procount +
                ' X ' +
                copyBill.items[i].name +
                '                         '.slice(0, -length) +
                '\n';
              receipt.push(item);
              if (copyBill.items[i].cookIns) {
                if (copyBill.items[i].cookIns.length > 0) {
                  for (var j = 0; j < copyBill.items[i].cookIns.length; j++) {
                    receipt.push(
                      '      ' + copyBill.items[i].cookIns[j].name + '\n'
                    );
                  }
                }
              }
              count = count + 1;
            }
          }
          receipt.push('========================================\n');
          if (count > 0) {
            Print(receipt, sIp.printbar)
              .then(result => {})
              .catch(err => {
                alert('' + err);
              });
          }
        }
      }
    } else if (sIp.bar) {
      var receipt = [];
      var count = 0;
      receipt.push('========================================\n');
      receipt.push('Bar Order\n');
      receipt.push('Date   :' + moment().format('DD/MM/YYYY HH:mm') + '\n');
      receipt.push('Order #:' + copyBill.orderno + '\n');
      receipt.push('Waiter :' + sUser + '\n');
      if (copyBill.desc) {
        receipt.push('Desc   :' + copyBill.desc + '\n');
      }
      receipt.push('\n');
      receipt.push('Type   :' + copyBill.type + '\n');
      receipt.push('========================================\n');
      receipt.push('Qty  Item\n');
      receipt.push('========================================\n');
      for (var i = 0; i < copyBill.items.length; i++) {
        if (
          !copyBill.items[i].processed &&
          copyBill.items[i].printto === 'Bar'
        ) {
          var length = copyBill.items[i].name.length + 1;
          var item =
            copyBill.items[i].procount +
            ' X ' +
            copyBill.items[i].name +
            '                         '.slice(0, -length) +
            '\n';
          receipt.push(item);
          if (copyBill.items[i].cookIns) {
            if (copyBill.items[i].cookIns.length > 0) {
              for (var j = 0; j < copyBill.items[i].cookIns.length; j++) {
                receipt.push(
                  '      ' + copyBill.items[i].cookIns[j].name + '\n'
                );
              }
            }
          }
          count = count + 1;
        }
      }
      receipt.push('========================================\n');
      if (count > 0) {
        Print(receipt, sIp.printbar)
          .then(result => {})
          .catch(err => {
            alert('' + err);
          });
      }
    }
  } catch (error) {
    alert('' + error);
  }
}
