/* eslint-disable no-loop-func */
import withStyles from '@material-ui/core/styles/withStyles';
import { Layout, Modal, notification } from 'antd';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'src/components/Layout/Dashboard/extra.css';
import { endSession, resetUser } from 'src/redux/actions/backend';
import {
  addToCart,
  createBill,
  createCart,
  setSelectedCompany,
  setSelectedDelivery,
  updateBill,
  updateCart
} from 'src/redux/actions/customer';
import 'src/views/Customer/customer-styles.css';

import { initLoader, removeLoader } from 'src/redux/actions/initdashloader';
import {
  findNotifications,
  removeNotification,
  sendNotification
} from 'src/redux/actions/notifications';
import { closeSnackbar, openSnackbar } from 'src/redux/actions/snackbar';
import Deliver from './Deliver.jsx';
import CustomerFooter from './Footer.jsx';
import CustomerHeader from './Header.jsx';
import OrderDetails from './OrderDetails.jsx';
import Orders from './Orders.jsx';
const apiCall = 'vpos/api/vpos/bill/';
const server = process.env.REACT_APP_GLOBAL_SERVER;
const styles = theme => ({
  snackbar: {
    color: '#fff',
    background: 'rgb(33, 171, 182)'
  }
});
const { confirm } = Modal;
let setAlert = undefined;
let setDash = undefined;
let setHeader = undefined;
var props = {};
var checkOnlineInterval;

const closeDialog = () => {
  setAlert(prevState => ({ ...prevState, alert: false }));
};

const componentWillMount = props => {
  const token = props.local.user.token;
  // props.findNotifications(token).then(() => {
  //   setHeader(prevState => ({
  //     ...prevState,
  //     notifications: props.notifications
  //   }));
  // });
  var element = document.getElementById('root');
  element.classList.add('dash-root');
  componentDidMount(props);
};

const componentDidMount = props => {
  document.addEventListener('offline', setOffline, false);
  document.addEventListener('online', setOnline, false);
  const token = props.local.user.token;
  window.pubnub.addListener({
    message: msg => {
      props.findNotifications(token).then(() => {
        setHeader(prevState => ({
          ...prevState,
          notifications: props.notifications
        }));
      });
    }
  });
  if (window.cordova) {
    window.FirebasePlugin.onMessageReceived(
      notif => {
        notif.message = JSON.parse(notif.message);
        if (notif.tap === 'background') {
          const token = props.local.user.token;
          props.removeNotification(notif.notId, token).then(() => {
            props.findNotifications(token).then(() => {
              setHeader(prevState => ({
                ...prevState,
                notifications: props.notifications
              }));
            });
          });
        }
      },
      error => {
        console.error(error);
      }
    );
  }
  window.addEventListener('resize', handleResize);
};

// const handleNotificationClick = e => {
//   const token = props.local.user.token;
//   let item = JSON.parse(e.currentTarget.getAttribute('data'));
//   props.removeNotification(item._id, token).then(() => {
//     props.findNotifications(token).then(() => {
//       setHeader(prevState => ({
//         ...prevState,
//         notifications: props.notifications
//       }));
//     });
//   });
// };
// const clearNotifications = e => {
//   const token = props.local.user.token;
//   props.removeNotification(undefined, token).then(() => {
//     props.findNotifications(token).then(() => {
//       setHeader(prevState => ({
//         ...prevState,
//         notifications: props.notifications
//       }));
//     });
//   });
// };

const setOffline = () => {
  window.offline = true;
  setDash(prevState => ({ ...prevState, offline: true }));
};

const setOnline = () => {
  window.offline = false;
  setDash(prevState => ({ ...prevState, offline: false }));
};

const componentWillUnmount = () => {
  clearInterval(checkOnlineInterval);
  window.removeEventListener('resize', handleResize);
  document.removeEventListener('offline', setOffline, false);
  document.removeEventListener('online', setOnline, false);
};

const handleResize = e => {
  setDash(prevState => ({ ...prevState, windowHeight: window.innerHeight }));
};

let dashState = {
  windowHeight: window.innerHeight,
  delivery: true,
  loading: false,
  offline: false
};

let currentView = 'orders';
let setCurrentViewState;
let previousView = 'orders';
let setPreviousView;

const signOut = () => {
  localStorage.clear();
  window.location.reload();
};
let orderDetails;
let setOrderDetails;
let activeOrders = [];
let setActiveOrders;
const getOrders = props => {
  $.ajax({
    type: 'GET',
    url: server + apiCall + 'getdriveractiveorders',
    dataType: 'json',
    crossDomain: true,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + props.local.user.token);
    },
    success: async data => {
      setActiveOrders(data.results);
    },
    error: async (xhr, ajaxOptions, thrownError) => {
      if (xhr.responseText) {
        notification.error({
          message: 'Error',
          description: xhr.responseText
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Error retrieving list'
        });
      }
    }
  });
};
const navToView = (view, order) => {
  if (view === 'signOut') {
    signOut();
  } else {
    if (order) {
      setOrderDetails(order);
    }
    setCurrentView(view);
  }
};

let searchValue = {
  search: false,
  value: undefined
};
let setSearchValue;
const searchPlace = value => {
  setSearchValue(value);
};

const setCurrentView = view => {
  setPreviousView(currentView);
  setCurrentViewState(view);
};

const viewOrder = order => {
  setOrderDetails(order);
  setCurrentView('vieworder');
};
const updateLiveLocation = position => {
  activeOrders.forEach(order => {
    if (order.driverId) {
      window.pubnub.signal({
        channel: order.customerId,
        message:
          position.coords.latitude.toFixed(7) +
          ',' +
          position.coords.longitude.toFixed(7)
      });
    }
  });
};
let liveLocationInterval;
var options = {
  enableHighAccuracy: true
};
const Customer = props => {
  [currentView, setCurrentViewState] = useState(currentView);
  [activeOrders, setActiveOrders] = useState(activeOrders);
  [orderDetails, setOrderDetails] = useState(orderDetails);
  [previousView, setPreviousView] = useState(previousView);
  [dashState, setDash] = useState(dashState);
  [searchValue, setSearchValue] = useState(searchValue);
  useEffect(() => {
    props.removeLoader();
    componentWillMount(props);
    setActiveOrders([]);
    getOrders(props);
    liveLocationInterval = navigator.geolocation.watchPosition(
      position => {
        updateLiveLocation(position);
      },
      null,
      options
    );
    return () => {
      navigator.geolocation.clearWatch(liveLocationInterval);
      componentWillUnmount(props);
    };
  }, []);
  return (
    <Layout>
      <CustomerHeader
        user={props.local.user}
        navToView={navToView}
        currentView={currentView}
        previousView={previousView}
        signOut={signOut}
      />

      {currentView === 'orders' ? (
        <Orders
          navToView={navToView}
          viewOrder={viewOrder}
          getOrders={getOrders}
          activeOrders={activeOrders}
          local={props.local}
          user={props.local.user}
        />
      ) : currentView === 'vieworder' ? (
        <OrderDetails
          navToView={navToView}
          user={props.local.user}
          order={orderDetails}
        />
      ) : currentView === 'deliver' ? (
        <Deliver
          navToView={navToView}
          user={props.local.user}
          order={orderDetails}
        />
      ) : null}

      <CustomerFooter navToView={navToView} />
    </Layout>
  );
};

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  notifications: state.notifications.notifications || [],
  cart: state.customer.cart || {},
  bill: state.customer.bill || {},
  selectedCompany: state.customer.selectedCompany,
  selectedDelivery: state.customer.selectedDelivery
});

const mapDispatchToProps = {
  setSelectedCompany,
  setSelectedDelivery,
  createCart,
  createBill,
  updateBill,
  addToCart,
  updateCart,
  resetUser,
  endSession,
  openSnackbar,
  closeSnackbar,
  sendNotification,
  findNotifications,
  removeNotification,
  initLoader,
  removeLoader
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Customer));
