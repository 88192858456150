const initialState = {
  dash: {
    snackmsg: '',
    opensnackbar: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'TOAST_DASH_MESSAGE': {
      return {
        ...state,
        dash: {
          snackmsg: action.payload,
          opensnackbar: true
        }
      };
    }
    case 'TOAST_DASH_CLEAR': {
      return {
        ...state,
        dash: {
          message: '',
          opensnackbar: false
        }
      };
    }
    default:
      return state;
  }
}
