

export function setBreadCrumbs(breadCrumbs) {
  return dispatch =>
    dispatch({
      type: 'SET_BREADCRUMBS',
      data: breadCrumbs
    });
}

export function actionBreadCrumb(crumb) {
  return dispatch =>
    dispatch({
      type: 'ACTION_BREADCRUMB',
      data: crumb
    });
}

export function resetActionBreadCrumb() {
  return dispatch =>
    dispatch({
      type: 'RESET_ACTION_BREADCRUMB',
    });
}



