import React from 'react';
import {
  Spin,
  Select,
  Table,
  Input,
  Icon,
  Row,
  Col,
  Popover,
  Switch,
  ConfigProvider,
  Empty,
  Button,
  Modal,
  Tabs
} from 'antd';
import { connect } from 'react-redux';
import {
  getCustomers,
  addCustomers,
  editCustomers,
  deleteCustomers,
  addImportCustomer
} from 'src/redux/actions/backend';
import { FileDrop } from 'react-file-drop';
import { ExcelRenderer } from 'react-excel-renderer';
import UiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Highlighter from 'react-highlight-words';
import GridItem from 'src/components/Grid/GridItem.jsx';
import GridContainer from 'src/components/Grid/GridContainer.jsx';
import CustomInput from 'src/components/CustomInput/CustomInput.jsx';
import FormDialog from 'src/components/Dialogs/Form.jsx';
import Card from 'src/components/Card/Card.jsx';
import CardHeader from 'src/components/Card/CardHeader.jsx';
import CardBody from 'src/components/Card/CardBody.jsx';
import CardFooter from 'src/components/Card/CardFooter.jsx';
import AlertDialog from 'src/components/Dialogs/Alert.jsx';
import Snackbar from 'src/components/Snackbar/Snackbar.jsx';
import CustomSelect from 'src/components/CustomSelect/CustomSelect.jsx';
import { openSnackbar, closeSnackbar } from 'src/redux/actions/snackbar';
import IconButton from '@material-ui/core/IconButton';
import {
  setBreadCrumbs,
  resetActionBreadCrumb
} from 'src/redux/actions/breadcrumbs';
import {
  FiEdit
} from 'react-icons/fi';
import { RiDatabase2Line } from "react-icons/ri";
import { setHelpMarker } from 'src/redux/actions/help';

import {
  primaryColor,
  grayColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx';
import StyledTable from 'src/components/Table/StyledTable';
import { formaterCheckNum } from 'src/utils/formater.js';
import Loading from 'react-loading-bar';
import ContentLoader from 'react-content-loader';
import Receipts from './Receipts';
import Statements from './Statements';
import styled, { css } from 'styled-components';
const { Column } = Table;
const { Option } = Select;
const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
.ant-tabs-nav-wrap {
  text-align: center;
}
.ant-tabs-bar {
  border-bottom: 0px solid #e8e8e8;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-tab{
  width:auto !important;
  font-size: 16px !important;
  margin-left:0.5em !important;
margin-top:0.5em !important;
  border-radius: 0em !important;
  padding: 0px 20px !important;
 height: 45px;
 font-weight:400 !important;
 color:rgb(19, 36, 64) !important;
 background: transparent !important;
 border: 0px solid #00CC70 !important;
}
 .ant-tabs-tab-active {
  width: auto !important;
  border-radius: 0em !important;
  padding: 0px 20px !important;
  height: 45px;
  color: #00CC70 !important;
  border-bottom: 1px solid #00CC70 !important;
}
`;

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    fontSize: '25px',

    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTileButton: {
    display: 'grid'
  },
  cardTileIcon: {
    color: 'white',
    padding: 0
  },
  hide: {
    display: 'none'
  },
  cardBodyHide: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  },
  cardBody: {
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-o-transition': '1s',
    transition: '1s',
    opacity: 1,
    height: 'auto'
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  tableHeadCell: {
    color: 'inherit',
    ...defaultFont,
    fontSize: '1em',
    '& th': {
      'text-align': 'center'
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: '1.42857143',
    verticalAlign: 'middle',
    '& td': {
      'word-break': 'break-all',
      'text-align': 'center'
    }
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.data * 3,
    overflowX: 'auto'
  },
  snackbar: {
    color: '#fff',
    background: '#00CC70'
  },
  buttonAdd: {
    height: '38px',
    marginTop: '43px',
    marginLeft: '-40%',
    width: '80%'
  }
});

const colSelectItems = [
  {
    name: 'NONE',
    value: 'none'
  },
  {
    name: 'Column 1',
    value: 0
  },
  {
    name: 'Column 2',
    value: 1
  },
  {
    name: 'Column 3',
    value: 2
  },
  {
    name: 'Column 4',
    value: 3
  },
  {
    name: 'Column 5',
    value: 4
  },
  {
    name: 'Column 6',
    value: 5
  },
  {
    name: 'Column 7',
    value: 6
  },
  {
    name: 'Column 8',
    value: 7
  },
  {
    name: 'Column 9',
    value: 8
  },
  {
    name: 'Column 10',
    value: 9
  },
  {
    name: 'Column 11',
    value: 10
  },
  {
    name: 'Column 12',
    value: 11
  },
  {
    name: 'Column 13',
    value: 12
  },
  {
    name: 'Column 14',
    value: 13
  },
  {
    name: 'Column 15',
    value: 14
  },
  {
    name: 'Column 16',
    value: 15
  },
  {
    name: 'Column 17',
    value: 16
  },
  {
    name: 'Column 18',
    value: 17
  },
  {
    name: 'Column 19',
    value: 18
  },
  {
    name: 'Column 20',
    value: 19
  },
];

const columnHeader = {
  'ID no': 0,
  Name: 1,
  Mobile: 2,
  Email: 3,
  Balance: 4
};

const tempState = {
  columnID: 0,
  columnName: 1,
  columnMobile: 2,
  columnEmail: 3,
  columnBalance: 4
};

const Skeleton = () => (
  <ContentLoader
    speed={0.5}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#c0c0c0"
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="46" rx="2" ry="2" width="100%" height="45" />
    <rect x="0" y="92" rx="2" ry="2" width="100%" height="45" />
  </ContentLoader>
);

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      cusid: '',
      orgName: this.props.orgData,
      idno: '',
      tabNo: '',
      tab: false,
      tabAmount: 0.0,
      payAmount: 0,
      newTabAmount: 0.0,
      type: 'Cash',
      email: '',
      name: '',
      mobile: '',
      active: false,
      customers: [],
      impPass: '',
      impPassDialog: false,
      upload: false,
      filename: 'Drop File here!',
      file: '',
      createTabs: false,
      columnID: 0,
      columnName: 1,
      columnMobile: 2,
      columnEmail: 3,
      columnBalance: 4,
      start: 1,
      end: 10,
      dialogState: [],
      loading: true,
      userlisthead: 'Add Account',
      userlistsub: 'Add a New Account',
      userlistbutton: 'Add Account',
      alert: false,
      msg: [],
      head: '',
      main: true,
      payView: false,
      activeFilter: true,
      submitInProgress: false
    };
  }

  handleAddBreadCrumb = crumb => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.push(crumb)
    this.props.setBreadCrumbs(breadCrumbs)
  }
  handleRemoveBreadCrumb = () => {
    let breadCrumbs = this.props.breadCrumbs;
    breadCrumbs.pop()
    this.props.setBreadCrumbs(breadCrumbs)
  }

  componentDidUpdate() {
    if (this.props.actionCrumb) {
      this[this.props.actionCrumb](false)
      this.props.resetActionBreadCrumb()
      this.handleRemoveBreadCrumb()
    }
  }

  componentDidMount() {
    this.getCustomers();
  }

  getCustomers = msg => {
    const token = this.props.local.user.token;
    this.props
      .getCustomers(token)
      .then(result => {
        this.setState({
          customers: result.data.results,
          loading: false
        });
        if (msg) {
          this.props.openSnackbar(msg);
        }
        this.handleRemoveBreadCrumb()
      })
      .catch(err => {
        this.onError(err);
      });
  };

  showCustomerReceipts = (customer) => {
    this.setState(prevState => ({
      ...prevState,
      cusid: customer._id,
      email: customer.email,
      main: false
    }));
  };

  backFromReceipts = () => {
    this.setState(prevState => ({
      ...prevState,
      cusid: '',
      email: '',
      main: true
    }));
  };

  cancelAddCustomer = () => {
    this.handleRemoveBreadCrumb()
    this.setState({ main: true })
  }

  cancelPayCustomer = () => {
    this.handleRemoveBreadCrumb()
    this.setState({ payView: false })
  }

  handleOpen = open => {
    this.handleAddBreadCrumb({ method: "cancelAddCustomer", crumb: "Customer" })
    var cusLength = this.state.customers.length;
    var name = 'CUST0000';
    name =
      cusLength > 0
        ? name.slice(0, -(cusLength + 1).toString().length) +
        (cusLength + 1).toString()
        : 'CUST0001';
    this.setState({
      _id: '',
      cusid: '',
      idno: cusLength + 1,
      tabNo: name,
      tab: false,
      tabAmount: 0.0,
      payAmount: 0,
      newTabAmount: 0.0,
      type: 'Cash',
      email: '',
      name: '',
      mobile: '',
      active: false,
      userlisthead: 'Add Account',
      userlistsub: 'Add a New Account',
      userlistbutton: 'Add Account',
      main: open,
      submitInProgress: false
    });
  };

  switchUpload = change => {
    if (change) {
      this.setState(prevState => ({
        ...prevState,
        impPassDialog: true,
        impPass: '',
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        upload: !this.state.upload,
        filename: 'Drop File here!',
        createTabs: false,
        file: '',
        columnID: 0,
        columnName: 1,
        columnMobile: 2,
        columnEmail: 3,
        columnBalance: 4,
        start: 1,
        end: 10,
        dialogState: [],
      }));
    }
  };

  uploadFile = () => {
    if (this.state.filename === 'Drop File here!') {
      this.onError('No File Found?');
      return;
    }
    this.setState(prevState => ({
      ...prevState, loading: true,
    }));
    ExcelRenderer(this.state.file, (err, resp) => {
      if (err) {
        this.setState(prevState => ({
          ...prevState,
          filename: 'Drop File here!',
          file: '',
          loading: false
        }));
        this.onError('Wrong file Format');
      } else {
        if (!resp.rows[0]) {
          this.setState(prevState => ({
            ...prevState,
            filename: 'Drop File here!',
            file: '',
            loading: false
          }));
          this.onError('Wrong file Format');
          return;
        }
        if (!resp.rows[1]) {
          this.setState(prevState => ({
            ...prevState,
            filename: 'Drop File here!',
            file: '',
            loading: false
          }));
          this.onError('Data not found');
          return;
        }
        const token = this.props.local.user.token;
        let startRow = this.state.start - 1;
        let endRow = this.state.end - startRow;
        resp.rows = resp.rows.splice(startRow, endRow);
        this.props.addImportCustomer(resp, this.state, token)
          .then(res => {
            if (res.data.results.error) {
              this.onError(res.data.results.error);
            } else {
              this.setState(prevState => ({
                ...prevState,
                filename: 'Drop File here!',
                file: '',
                upload: false,
                createTabs: false,
                columnID: 0,
                columnName: 1,
                columnMobile: 2,
                columnEmail: 3,
                columnBalance: 4,
                start: 1,
                end: 10,
                dialogState: [],
              }));
              this.getCustomers();
            }
          })
          .catch(err => {
            this.onError(err);
          });
      }
    });
  };

  checkImportPassword = (e) => {
    if (this.state.impPass === '#VCS@Importer@Open@2021!#') {
      this.setState(prevState => ({
        ...prevState,
        impPass: '',
        impPassDialog: false
      }));
      this.switchUpload();
    }
  };

  fileHandler = eventN => {
    let event = Object.assign({}, eventN);
    if (!event.target.files[0].type.includes('spreadsheetml')) {
      this.setState(prevState => ({
        ...prevState,
        filename: 'Drop File here!',
        file: '',
        loading: false,
      }));
      this.onError('Wrong file Format');
      return;
    }
    let fileObj = event.target.files[0];
    this.setState(prevState => ({
      ...prevState, filename: fileObj.name, file: fileObj
    }));
  };

  fileHandlerDrag = (file, event) => {
    if (!file[0].type.includes('spreadsheetml')) {
      this.setState(prevState => ({
        ...prevState,
        filename: 'Drop File here!',
        file: '',
        loading: false
      }));
      this.onError('Wrong file Format');
      return;
    }
    let fileObj = file[0];
    this.setState(prevState => ({
      ...prevState, filename: fileObj.name, file: fileObj
    }));
  };

  previewFile = () => {
    if (this.state.filename === 'Drop File here!') {
      this.onError('No File Found?');
      return;
    }
    this.setState(prevState => ({
      ...prevState, loading: true
    }));
    ExcelRenderer(this.state.file, (err, resp) => {
      if (err) {
        this.setState(prevState => ({
          ...prevState, loading: false
        }));
        alert(err);
        console.log(err);
      } else {
        if (!resp.rows[0]) {
          this.setState(prevState => ({
            ...prevState,
            filename: 'Drop File here!',
            file: '',
            loading: false
          }));
          this.onError('Wrong file Format');
          return;
        }
        if (!resp.rows[1]) {
          this.setState(prevState => ({
            ...prevState,
            filename: 'Drop File here!',
            file: '',
            loading: false
          }));
          this.onError('Data not found');
          return;
        }
        let startRow = this.state.start - 1;
        let endRow = this.state.end - startRow;
        resp.rows = resp.rows.splice(startRow, endRow);
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          dialogState: resp.rows,
        }));
      }
    });
  };

  handleChangeTempCol = (event, index) => {
    let copyTempState = {};
    copyTempState[index] = parseFloat(event);
    this.setState(prevState => ({
      ...prevState,
      ...copyTempState,
    }));
  };

  closeDialog = () => {
    this.setState({
      alert: false,
      impPassDialog: false,
      dialogState: []
    });
  };

  onError = err => {
    this.setState({
      head: 'User Error',
      alert: true,
      msg: [err],
      loading: false
    });
  };

  onComplete = res => {
    this.setState({
      idno: '',
      tabNo: '',
      tab: false,
      tabAmount: 0.0,
      payAmount: 0,
      newTabAmount: 0.0,
      type: 'Cash',
      email: '',
      name: '',
      mobile: '',
      active: false,
      customers: [],
      head: 'Customer Details',
      main: true,
      payView: false,
      submitInProgress: false
    });
    this.getCustomers('Customer Creation Successful');
  };

  submitForm = (oEvent, pay) => {
    const token = this.props.local.user.token;
    this.setState({ loading: true, submitInProgress: true });
    if (pay) {
      var customer = this.state;
      // if (customer.newTabAmount < 0) {
      //   this.setState({ loading: false });
      //   this.props.openSnackbar('Amount greater then Tab Total');
      //   return;
      // }
      this.props
        .editCustomers(customer, token, pay)
        .then(res => {
          this.setState({
            _id: '',
            cusid: '',
            idno: '',
            tabNo: '',
            tab: false,
            tabAmount: 0.0,
            payAmount: 0,
            newTabAmount: 0.0,
            type: 'Cash',
            email: '',
            name: '',
            mobile: '',
            active: false,
            main: true,
            payView: false,
            submitInProgress: false
          });
          this.getCustomers('Customer Payment Successful');
        })
        .catch(err => {
          this.onError(err);
        });
    } else {
      if (oEvent.currentTarget.innerText === 'Add Account') {
        this.props
          .addCustomers(this.state, token)
          .then(res => {
            if (res.data.results === 'idnofound') {
              this.onError('Customer No. Duplicate Found');
            } else {
              this.onComplete(res);
            }
          })
          .catch(err => {
            if (err) {
              this.onError(err);
            } else {
              this.setState({ loading: false });
            }
          });
      } else if (oEvent.currentTarget.innerText === 'Edit Customer') {
        this.props
          .editCustomers(this.state, token)
          .then(res => {
            this.setState({
              _id: '',
              cusid: '',
              idno: '',
              tabNo: '',
              tab: false,
              tabAmount: 0.0,
              payAmount: 0,
              newTabAmount: 0.0,
              type: 'Cash',
              email: '',
              name: '',
              mobile: '',
              active: false,
              userlisthead: 'Add Account',
              userlistsub: 'Add a New Account',
              userlistbutton: 'Add Account',
              main: true,
              payView: false,
              submitInProgress: false
            });
            this.getCustomers('Customer Update Successful');
          })
          .catch(err => {
            if (err) {
              this.onError(err);
            } else {
              this.setState({ loading: false });
            }
          });
      }
    }
  };

  handleCheck = () => {
    this.setState({ tab: !this.state.tab });
  };

  onChangeEvt(e) {
    if (e.target.id === 'tabno') {
      this.setState({ tabNo: e.currentTarget.value });
    }
    if (e.target.id === 'email') {
      this.setState({ email: e.currentTarget.value });
    }
    if (e.target.id === 'name') {
      if (e.currentTarget.value.length === 1) {
        e.currentTarget.value = e.currentTarget.value.toUpperCase();
      }
      this.setState({ name: e.currentTarget.value });
    }
    if (e.target.id === 'mobile') {
      this.setState({ mobile: e.currentTarget.value });
    }
    if (e.target.id === 'payamount') {
      var value = formaterCheckNum(e.currentTarget.value || '0');
      var amount = this.state.tabAmount;
      if (value) {
        amount = amount - value;
        this.setState({
          payAmount: value,
          newTabAmount: amount
        });
      }
    }
    if (e.target.name === 'type') {
      this.setState({
        type: e.target.value,
      });
    }
  }

  handleEditUser = (oUser, pay) => {
    if (pay) {
      this.handleAddBreadCrumb({ method: "cancelPayCustomer", crumb: "Pay" })
      this.setState({
        _id: oUser._id,
        idno: oUser.idno,
        tabNo: oUser.tabno,
        tab: oUser.tab,
        tabAmount: oUser.tabAmount,
        payAmount: 0,
        newTabAmount: oUser.tabAmount,
        type: 'Cash',
        email: oUser.email,
        name: oUser.name,
        mobile: oUser.mobile,
        active: oUser.active,
        payView: true
      });
    } else {
      this.handleAddBreadCrumb({ method: "cancelAddCustomer", crumb: "Customer" })
      this.setState({
        _id: oUser._id,
        idno: oUser.idno,
        tabNo: oUser.tabno,
        tab: oUser.tab,
        tabAmount: oUser.tabAmount,
        payAmount: 0,
        newTabAmount: oUser.tabAmount,
        type: 'Cash',
        email: oUser.email,
        name: oUser.name,
        mobile: oUser.mobile,
        active: oUser.active,
        userlisthead: 'Edit Account',
        userlistsub: 'Edit a Account Details',
        userlistbutton: 'Edit Customer',
        main: false,
        payView: false
      });
    }
  };

  handleDeleteCustomer = oUser => {
    const token = this.props.local.user.token;
    this.setState({ loading: true });
    this.props
      .deleteCustomers(oUser, token)
      .then(res => {
        this.getCustomers('Customer Delete Successful');
      })
      .catch(err => {
        this.onError(err);
      });
  };

  onHelpMarker = marker => {
    this.props.setHelpMarker(marker);
  };

  handleFormatList = format => {
    this.setState({ activeFilter: format });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="sm"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        imageStyle={{
          margin: "3em",
          marginBottom: "0.5em",
          height: 90,
        }} />

      <button
        style={{
          backgroundColor: '#00CC70',
          border: '0px solid rgb(30,161,169)',
          color: 'white',
          width: '12em',
          height: '2.7em',
          fontSize: 16,
          fontFamily: '',
          borderRadius: '3em',
          marginTop: "1em",
          marginBottom: "1.5em",

        }}
        onClick={() => this.handleOpen(!this.state.main)}
        color="primary"
      >
        {'Add Account'}
      </button>
    </div>
  );

  render() {
    const EyeContent = (
      <div>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30,161,169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => {
            this.handleFormatList(true);
          }}
        >
          Active
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30,161,169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(false)}
        >
          Inactive
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30,161,169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList('tab')}
        >
          Tab
        </Button>
        <Button
          style={{
            backgroundColor: '#00CC70',
            border: '0px solid rgb(30,161,169)',
            boxShadow: 'none',
            color: 'white',
            height: '100%',
            borderRadius: '0.3em'
          }}
          onClick={() => this.handleFormatList(undefined)}
        >
          All
        </Button>
      </div>
    );
    const oState = {
      state: this.state,
      props: this.props,
      closeDialog: this.closeDialog,
      handleEditUser: this.handleEditUser
    };

    let customers = [];
    let stateCustomers = this.state.customers;
    for (var i = 0; i < stateCustomers.length; i++) {
      if (this.state.activeFilter === true) {
        if (stateCustomers[i].active) {
          customers.push(stateCustomers[i]);
        }
      } else if (this.state.activeFilter === false) {
        if (!stateCustomers[i].active) {
          customers.push(stateCustomers[i]);
        }
      } else if (this.state.activeFilter === 'tab') {
        if (stateCustomers[i].tab) {
          customers.push(stateCustomers[i]);
        }
      } else if (this.state.activeFilter === undefined) {
        customers.push(stateCustomers[i]);
      }
    }

    return (
      <div>
        <Loading
          show={this.state.loading}
          showSpinner={false}
          color="rgb(15, 135, 123)"
        />
        <AlertDialog {...oState} />
        <FormDialog
          head="Import Password"
          msg="Enter the VPOS Import Password"
          formElements={[
            <CustomInput
              labelText="Password"
              id="impPass"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: e => {
                  const event = e.currentTarget.value;
                  this.setState(prevState => ({
                    ...prevState,
                    impPass: event,
                  }));
                },
                value: this.state.impPass,
                autoFocus: true
              }}
            />
          ]}
          open={this.state.impPassDialog}
          close={this.closeDialog}
          actionBtn="Open"
          onClick={e => this.checkImportPassword(e, this)}
        />
        <Modal
          width={window.innerWidth}
          title="Data Preview"
          visible={this.state.dialogState.length}
          onOk={this.closeDialog}
          onCancel={this.closeDialog}>
          <UiTable style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                {Object.keys(columnHeader).map((item, index) => index < 7 && (
                  <TableCell style={{
                    maxWidth: '200px',
                    borderWidth: "1px",
                    borderColor: "#aaaaaa",
                    borderStyle: 'solid',
                    fontSize: '15px',
                    background: 'black',
                    color: 'white'
                  }}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dialogState.map((item, index) => (
                <TableRow key={'tr' + index}>
                  {Object.keys(tempState).map((temp, index) => index < 7 && (
                    <TableCell style={{ maxWidth: '200px', borderWidth: "1px", borderColor: "#aaaaaa", borderStyle: 'solid' }}>
                      {item[this.state[temp]] ? item[this.state[temp]] : 'No Data'}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </UiTable>
        </Modal>
        {this.state.main ? (
          <div>
            {this.state.payView ? (
              <Card>
                {/*  <CardHeader
                  style={{
                    borderRadius: '0.5em',
                    background: 'rgb(30,161,169)'
                  }}
                >
                 
                  <p
                    style={{ color: 'rgba(255,255,255,.62)' }}
                    className={this.props.classes.cardCategoryWhite}
                  >
                    Tab Payment
                  </p>
                </CardHeader> */}
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        style={{ color: 'black' }}
                        className={this.props.classes.cardTitleWhite}
                      >
                        {'Payment'}  <span style={{ color: "#00CC70" }}> {this.state.tabNo} </span>
                      </h4>
                      <h4
                        style={{ color: 'black' }}
                        className={this.props.classes.cardTitleWhite}
                      >
                        {'Total: '} <span style={{ color: "#00CC70" }}>R {this.state.newTabAmount.toFixed('2')} </span>
                      </h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomSelect
                        labelText="Payment Type"
                        id="type"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => this.onChangeEvt(e),
                          value: this.state.type
                        }}
                        menuItems={[
                          {
                            value: 'Cash',
                            name: 'Cash'
                          }, {
                            value: 'Card',
                            name: 'Card'
                          }, {
                            value: 'EFT',
                            name: 'EFT'
                          }]}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Payment Amount"
                        id="payamount"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => this.onChangeEvt(e),
                          type: 'number',
                          value: this.state.payAmount,
                          onClick: () => this.onHelpMarker('usersnumber')
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <CardFooter style={{display:"block"}}>
                    <Button style={{
                      float:"right",
                      fontSize:"13px", 
                      textTransform: "initial",
                      boxShadow: "none",
                      width: "8em",
                      marginRight: "1em",
                      color: "white",
                      backgroundColor: "#00CC70",
                      border: "0px solid #00CC70",
                      borderRadius: "3em",
                      height: "2.7em",

                    }}
                      onClick={e => this.submitForm(e, true)}
                      color="primary"
                    >
                      Pay Amount
                    </Button>
                    <Button style={{
                         float:"right",
                    fontSize:"13px", 
                    textTransform: "initial",
                    boxShadow: "none",
                    width: "7em",
                    marginRight: "1em",
                    color: "black",
                    backgroundColor: "rgba(229, 233, 242, 0.9)",
                    border: "0px solid rgba(229, 233, 242, 0.9)",
                    borderRadius: "3em",
                    height: "2.7em",

                    }}
                      onClick={() => this.cancelPayCustomer()}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            ) : (
              <div>
                 <Button
                      style={{
                        position: 'fixed',
                        right: '-0.6em',
                        bottom: '5em',
                        background: 'rgb(0, 204, 112)',
                        border: '1px solid transparent',
                        color: 'white',
                        padding: '0em',
                        width: '3.5em',
                        height: '3.5em',
                        borderRadius: '5em 0em 0em 5em',
                        fontSize: '1em',
                        zIndex: 1000
                      }}
                    onClick={() => this.handleOpen(!this.state.main)}
                      color="primary"
                    >
                      <Icon type="plus"
                        style={{
                        
                        }}
                      >
                      </Icon >
                    </Button>
       
                {customers.length > 0 ? <Row>
                  <button
                    style={{
                      backgroundColor: '#00CC70',
                      border: '0px solid grey',
                      color: 'white',
                      width: '10em',
                      height: '2.5em',
                      fontSize: "16px",
                      fontFamily:
                        'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif',
                      fontWeight: 600,
                      borderRadius: '3em',
                      zIndex: 1
                    }}
                    onClick={() => this.handleOpen(!this.state.main)}
                    color="primary"
                  >
                    {'Add Account'}
                  </button>
                  <Button    
                   onClick={() => this.switchUpload(true)}
                    style={{
                      position: 'fixed',
                      right: '-0.6em',
                      bottom: '9em',
                      background: 'rgb(0, 204, 112)',
                      border: '1px solid transparent',
                      color: 'white',
                      padding: '0em',
                      width: '3.5em',
                      height: '3.5em',
                      borderRadius: '5em 0em 0em 5em',
                      fontSize: '1em',
                      zIndex: 1000
                    }}
                  >
                    <Icon
                      style={{
                        fontSize:"1.1em",
                      }}
                     
                    type="import"
                    /></Button>
                </Row> : <Row><button
                  onClick={() => this.switchUpload(true)}
                  style={{
                    marginLeft: "0.8em",
                    backgroundColor: '#00CC70',
                    border: '0px solid grey',
                    color: 'white',
                    width: '10em',
                    height: '2.5em',
                    fontSize: "16px",
                    fontFamily:
                      'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Helvetica Neue, Arial, sans-serif',
                    fontWeight: 600,
                    borderRadius: '3em',
                    zIndex: 1
                  }}
                ><Icon type="import" /> Import</button></Row>}
                <Row>
                  <div
                    style={{
                      float: 'right',
                      marginTop: '-1em',
                      marginBottom: '1.6em'
                    }}
                  >
                    <Popover
                      placement="left"
                      content={EyeContent}
                      title=""
                      trigger="click"
                    >
                       <Button
                    style={{
                      position: 'fixed',
                      right: '-0.6em',
                      bottom: '13em',
                      background: 'rgba(229, 233, 242, 1)',
                      border: '1px solid transparent',
                      color: 'rgba(0, 0, 0, 0.54)',
                      padding: '0em',
                      width: '3.5em',
                      height: '3.5em',
                      boxShadow: "none",
                      borderRadius: '5em 0em 0em 5em',
                      fontSize: '1em',
                      zIndex: 1000
                    }}
                  >
                    <Icon
                      style={{
                    
                      }}
                      theme="filled"
                      type="setting"
                    />
                  </Button>
                    </Popover>
                  </div>
                </Row>
                {this.state.upload ? (
                  <div>
                    <Row>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ margin: '10px' }}>
                          <FormControlLabel
                            control={
                              <Switch
                                style={{ marginRight: '1em' }}
                                checked={this.state.createTabs}
                                onChange={(e) => {
                                  this.setState(prevState => ({
                                    ...prevState, createTabs: e,
                                  }))
                                }}
                              />
                            }
                            label={"Do this Accounts have Tabs ?"}
                          />
                        </GridItem>
                      </GridContainer>
                    </Row>
                    <FileDrop
                      onDrop={(files, event) =>
                        this.fileHandlerDrag(files, event)
                      }
                    >
                      <div
                        style={{
                          border: '1px solid black',
                          width: '350px',
                          color: 'black',
                          height: '100px',
                          textAlign: 'center'
                        }}
                      >
                        {this.state.filename}
                        <div>OR</div>
                        <div>
                          <input
                            type="file"
                            onChange={this.fileHandler.bind(this)}
                            style={{ padding: '10px' }}
                          />
                        </div>
                      </div>
                    </FileDrop>
                    <div style={{ padding: '20px' }}>
                      <h3>Select Excel Columns for Corresponding Values</h3>
                      <p>* Values are Mandatory</p>
                      <Row style={{ width: '240px', paddingBottom: '5px' }}><span>Data Start Row</span>
                        <input type='number' style={{ width: 120, float: 'right', top: '-3px' }} value={this.state.start} onChange={(e) => {
                          let value = e.target.value;
                          this.setState(prevState => ({
                            ...prevState,
                            start: value
                          }));
                        }} /></Row>
                      <Row style={{ width: '240px', paddingBottom: '5px' }}><span>Data End Row</span>
                        <input type='number' style={{ width: 120, float: 'right', top: '-3px' }} value={this.state.end} onChange={(e) => {
                          let value = e.target.value;
                          this.setState(prevState => ({
                            ...prevState,
                            end: value
                          }));
                        }} /></Row>
                      <Row style={{ width: '240px' }}><span>Account #</span>
                        <Select style={{ width: 120, float: 'right', top: '-3px' }} defaultValue={'Column 1'} onChange={(e) => this.handleChangeTempCol(e, 'columnID')}>
                          {colSelectItems.map(item => (
                            <Option key={item.value}>{item.name}</Option>
                          ))}
                        </Select></Row>
                      <Row style={{ width: '240px' }}><span>Name*</span>
                        <Select style={{ width: 120, float: 'right', top: '-3px' }} defaultValue={'Column 2'} onChange={(e) => this.handleChangeTempCol(e, 'columnName')}>
                          {colSelectItems.map(item => (
                            <Option key={item.value}>{item.name}</Option>
                          ))}
                        </Select></Row>
                      <Row style={{ width: '240px' }}><span>Mobile Number</span>
                        <Select style={{ width: 120, float: 'right', top: '-3px' }} defaultValue={'Column 3'} onChange={(e) => this.handleChangeTempCol(e, 'columnMobile')}>
                          {colSelectItems.map(item => (
                            <Option key={item.value}>{item.name}</Option>
                          ))}
                        </Select></Row>
                      <Row style={{ width: '240px' }}><span>Email</span>
                        <Select style={{ width: 120, float: 'right', top: '-3px' }} defaultValue={'Column 4'} onChange={(e) => this.handleChangeTempCol(e, 'columnEmail')}>
                          {colSelectItems.map(item => (
                            <Option key={item.value}>{item.name}</Option>
                          ))}
                        </Select></Row>
                      <Row style={{ width: '240px' }}><span>Balance</span>
                        <Select style={{ width: 120, float: 'right', top: '-3px' }} defaultValue={'Column 5'} onChange={(e) => this.handleChangeTempCol(e, 'columnBalance')}>
                          {colSelectItems.map(item => (
                            <Option key={item.value}>{item.name}</Option>
                          ))}
                        </Select></Row>
                      <button
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid rgb(15, 135, 123) ',
                          color: 'rgb(15, 135, 123)',
                          fontWeight: '700',
                          width: '240px',
                          height: '2.7em',
                          fontSize: 16,
                          fontFamily: '',
                          borderRadius: '0.5em',
                          zIndex: 99
                        }}
                        onClick={() => this.previewFile()}
                        color="primary"
                      >
                        {'Preview'}
                      </button>
                    </div>
                    <div>
                      <button
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid rgb(15, 135, 123) ',
                          color: 'rgb(15, 135, 123)',
                          fontWeight: '700',
                          width: '10.5em',
                          height: '2.7em',
                          fontSize: 16,
                          fontFamily: '',
                          borderRadius: '0.5em',
                          zIndex: 99
                        }}
                        onClick={() => this.uploadFile()}
                        color="primary"
                      >
                        {'Import'}
                      </button>
                      <button
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid rgb(15, 135, 123) ',
                          color: 'rgb(15, 135, 123)',
                          fontWeight: '700',
                          width: '10.5em',
                          height: '2.7em',
                          fontSize: 16,
                          fontFamily: '',
                          borderRadius: '0.5em',
                          zIndex: 99
                        }}
                        onClick={() => this.switchUpload(false)}
                        color="primary"
                      >
                        {'Cancel'}
                      </button>
                    </div>
                  </div>) : null}
                <Row>
                  <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                    <StyledTable
                      locale={
                        this.state.loading
                          ? {
                            emptyText: <Skeleton />
                          }
                          : {}
                      }
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      dataSource={customers}
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: 25,
                        itemRender: (current, type, originalElement) => {
                          if (type === 'prev') {
                            return <a>Previous</a>;
                          }
                          if (type === 'next') {
                            return <a>Next</a>;
                          }
                          return originalElement;
                        },
                        showTotal: (total, range) =>
                          `Showing ${range[0]} - ${range[1]}  of ${total} items`,
                        defaultCurrent: 1
                      }}
                      onChange={this.handleSort}
                      onRow={(record, rowIndex) => {
                        if (!record.active) {
                          return {
                            style: { background: 'rgba(255, 4, 4, 0.063)', color: 'red' }
                          };
                        } else if (rowIndex % 2 === 0) {
                          return {
                            style: { background: 'rgb(247, 247, 247)' }
                          };
                        }
                      }}
                    >
                      {/*  <Column
                        {...this.getColumnSearchProps('idno')}
                        title="Customer No."
                        dataIndex="idno"
                        key="idno"
                    /> */}
                      <Column
                        {...this.getColumnSearchProps('tabno')}
                        title="Account #"
                        dataIndex="tabno"
                        key="tabno"
                      />
                      <Column
                        {...this.getColumnSearchProps('name')}
                        title="Full Name"
                        dataIndex="name"
                        key="name"
                      />
                      <Column
                        {...this.getColumnSearchProps('mobile')}
                        title="Mobile"
                        dataIndex="mobile"
                        key="mobile"
                      />
                      <Column
                        {...this.getColumnSearchProps('email')}
                        title="Email"
                        dataIndex="email"
                        key="email"
                      />
                      {/*<Column
                        {...this.getColumnSearchProps('tab')}
                        title="Tab"
                        render={(text, record) => (
                          <span>{record.tab.toString()}</span>
                        )}
                        dataIndex="tab"
                        key="tab"
                        />*/}
                      <Column
                        {...this.getColumnSearchProps('tabAmount')}
                        title="Amount"
                        render={(text, record) => (
                          <span>
                            {record.tab
                              ? 'R ' + record.tabAmount.toFixed('2')
                              : 'R 0.00'}
                          </span>
                        )}
                        dataIndex="tabAmount"
                        key="tabAmount"
                      />
                      <Column
                        title="Actions"
                        render={(text, record) => (
                          <div>
                         
                            <IconButton style={{
                                 width: "3em",
                                 padding: "0.25em",
                                 borderRadius: "3em",
                                 backgroundColor: "rgb(0, 204, 112)",
                                 border: '1px solid rgb(0, 204, 112)'
                            }}
                              onClick={(e) => {
                                e.stopPropagation()
                                this.handleEditUser(record)
                              }}
                            >
                               {<p style={{
                            fontSize: 13,
                            color: "white",
                            //fontWeight: 700,
                            //fontFamily: "Mulish , sans-serif",
                            margin: "auto"
                          }}>

                            {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                            Edit</p>
                          }
                        </IconButton>
                        {record.tab ? (
                              <IconButton
                                style={{
                                  width: "3em",
                                  padding: "0.25em",
                                  borderRadius: "3em",
                                  marginLeft:"0.2em",
                                  backgroundColor: "rgba(229, 233, 242, 0.9)",
                                  border: '1px solid rgba(229, 233, 242, 1)'
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  this.showCustomerReceipts(record)
                                }}
                              >
                          {<p style={{
                            fontSize: 13,
                         
                            //fontWeight: 700,
                            //fontFamily: "Mulish , sans-serif",
                            margin: "auto"
                          }}>

                            {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                           View</p>
                          }
                          
                                
                              </IconButton>
                            ) : null}
                            {record.tab ? (
                              <IconButton
                                style={{
                                  width: "3em",
                                  padding: "0.25em",
                                  borderRadius: "3em",
                                  marginLeft:"0.2em",
                                  backgroundColor: "rgba(229, 233, 242, 0.9)",
                                  border: '1px solid rgba(229, 233, 242, 1)'
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  this.handleEditUser(record, true)
                                }}
                              >
                                    {<p style={{
                            fontSize: 13,
                         
                            //fontWeight: 700,
                            //fontFamily: "Mulish , sans-serif",
                            margin: "auto"
                          }}>

                            {/*<FiEdit
                              style={{
                                fontSize: 12,
                                color: "white",
                                margin: "auto"
                              }}
                            />*/}
                          Pay</p>
                          }
                              </IconButton>
                            ) : null}
                            {record.active ? (
                              <IconButton
                                style={{
                                  marginRight: '0em',
                                  marginLeft: '0.2em',
                                  height:"1.3em",
                                  width:"1.3em",
                                  borderRadius: "4em",
                                  backgroundColor: "rgba(229, 233, 242, 0.9)",
                                  border: '1px solid rgba(229, 233, 242, 1)'
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  this.handleDeleteCustomer(record)
                                }}
                              >
                                {
                               <Icon
                               type="delete"
                               style={{
                                 fontSize: 13,
                               }}
                             />
                                }
                              </IconButton>
                            ) : (
                              <IconButton style={{
                                marginRight: '0em',
                                marginLeft: '0.4em',
                                borderRadius: "4em",
                                backgroundColor: "rgba(229, 233, 242, 0.9)",
                                border: '1px solid rgba(229, 233, 242, 1)'
                              }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  this.handleDeleteCustomer(record)
                                }}
                              >
                                {
                                  <Icon
                                    type="rollback"
                                    style={{
                                      fontSize: 15,
                                    }}
                                  />
                                }
                              </IconButton>
                            )}
                          </div>
                        )}
                      />
                    </StyledTable>
                  </ConfigProvider>
                </Row>
              </div>
            )}
          </div>
        ) : !this.state.main && !this.state.cusid ? (
          <Card>
            {/*} <CardHeader
              style={{
                borderRadius: '0.5em',
                background: 'rgb(30,161,169)'
              }}
            >
              <h4
                style={{ color: 'white' }}
                className={this.props.classes.cardTitleWhite}
              >
                {this.state.userlisthead + ' ' + this.state.tabNo}
              </h4>
              <p
                style={{ color: 'rgba(255,255,255,.62)' }}
                className={this.props.classes.cardCategoryWhite}
              >
                {this.state.userlistsub}
              </p>
            </CardHeader> */}
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Account #"
                    id="tabno"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      value: this.state.tabNo,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Full Name"
                    id="name"
                    error={!this.state.name && this.state.submitInProgress}
                    errorText={'Full Name required'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      value: this.state.name,
                      onClick: () => this.onHelpMarker('usersname')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      type: 'email',
                      value: this.state.email,
                      onClick: () => this.onHelpMarker('usersemail')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Mobile No."
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onChangeEvt(e),
                      type: 'number',
                      value: this.state.mobile,
                      onClick: () => this.onHelpMarker('usersnumber')
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    marginLeft: '1em',
                    marginBottom: '1.5em',
                    marginTop: '1.5em'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        style={{ marginRight: '1em' }}
                        checked={this.state.tab}
                        onChange={this.handleCheck}
                      />
                    }
                    label=" Tab"
                  />
                </GridItem>
              </GridContainer>
              <CardFooter style={{ display: "inline" }}>
                <Button style={{textTransform: 'initial',fontSize:"13px",  marginLeft: "1em", float: "right", borderRadius: "3.5em", border: "1px solid #00CC70", backgroundColor: "#00CC70", width: "10em", height: "2.8em", color: "white" }} onClick={this.submitForm} color="primary">
                  {this.state.userlistbutton}
                </Button>
                <Button style={{ float:"right",fontSize:"13px", 
                            textTransform: "initial",
                            boxShadow: "none",
                            width: "7em",
                       
                            color: "black",
                            backgroundColor: "rgba(229, 233, 242, 0.9)",
                            border: "0px solid rgba(229, 233, 242, 0.9)",
                            borderRadius: "3em",
                            height: "2.8em", }}
                  onClick={() => this.cancelAddCustomer()}
                  color="primary"
                >
                  Cancel
                </Button>
              </CardFooter>
            </CardBody>
          </Card>
        ) : (
          <StyledTabs defaultActiveKey='1' type="card">
            <TabPane tab="Receipts" key="1">
              <Receipts id={this.state.cusid} email={this.state.email} backFromReceipts={this.backFromReceipts} />
            </TabPane>
            <TabPane tab="Statements" key="2">
              <Statements id={this.state.cusid} email={this.state.email} backFromReceipts={this.backFromReceipts} />
            </TabPane>
          </StyledTabs>
        )}
        <Snackbar {...this.props} />{' '}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  local: state.backend,
  snackbar: state.snackbar,
  users: state.users,
  orgData: state.backend.orgname,
  breadCrumbs: state.breadcrumbs.breadCrumbs,
  actionCrumb: state.breadcrumbs.actionCrumb
});

const mapDispatchToProps = {
  getCustomers,
  addCustomers,
  editCustomers,
  deleteCustomers,
  openSnackbar,
  closeSnackbar,
  setHelpMarker,
  setBreadCrumbs,
  resetActionBreadCrumb,
  addImportCustomer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Customers));
